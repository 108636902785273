import React from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import _ from 'lodash';
import { getBruksenhetSlettetLogg } from 'api/enhetApi.js';
import ReactTable from '@norkart/react-table';


class SlettedeElementerLogg extends React.Component {
  state = {
    data: null,
    id: this.props.location ? this.props.location.state.id : this.props.id,
    historikk: [],
    startDate: moment(Date.now()),
    erFritidsbygg: false,
    lagrer: false
  };

  componentDidMount() {
    getBruksenhetSlettetLogg(this.props.brid, this.loggHentet);
  }

  loggHentet = (err, res) => {
    this.setState({ data: res });
  }

  formatDato = (datostring) => {
    let date = new Date(datostring);
    var dateString = 
      ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
    return dateString;
  }
  
  getColumns = () => {
    return [
      {
        id: 'tidspunkt',
        name: 'Tidspunkt',
        sortParams: 'tidspunkt',
        isSorted: true,
        filterable: false,
        sortDirection: 'desc',
        formatter: function(item) {
          if (item.tidspunkt === null) {
            return '-';
          } else {
            var dato = new Date(item.tidspunkt);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            var time = dato.getHours().toString();
            var min = dato.getMinutes().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }

            return dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
          }
        },
      },
      {
        id: 'objektType',
        name: 'ObjektType',
        sortParams: 'objektType',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'bruker',
        name: 'Bruker',
        sortParams: 'bruker',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'brukerSql',
        name: 'BrukerSql',
        sortParams: 'brukerSql',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'objektId',
        name: 'ObjektId',
        sortParams: 'objektId',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'objektReferanseId',
        name: 'ObjektReferanseId',
        sortParams: 'objektReferanseId',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'konverteringId',
        name: 'KonverteringId',
        sortParams: 'konverteringId',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'objektInfo',
        name: 'Info',
        sortParams: 'objektInfo',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
    
    ];
  };

  render() {
    var columns = this.getColumns();
    if (!this.state.data) {
      return <div>Laster...</div>;
    }
    return (
      <div className="margin-top-15">
        <div>
          <div style={{ fontSize: '12px' }}>
          <ReactTable
          items={this.state.data}
          showIndex={false}
          filterable={false}
          columns={columns}
          onRowClick={null}
        />
            
          </div>
        </div>
        
        
      </div>
    );
  }
}

export default SlettedeElementerLogg;
