import React, { Component, Fragment } from 'react';
import CONFIG from 'lib/CONFIG';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import './dokeditor.css';

import ReactFileReader from 'react-file-reader';
import { debugFactory } from 'utils';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/plugins/image.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import Forhandsvisning from './Forhandsvisning.jsx';
import TabellBorderDialog from './TabellBorderDialog.jsx';
import {
  checkEditorNormal,
  skjulTabellKantlinjer,
  visTabellKantlinjerIgjen,
  getMottakerVarselEierDialog,
  getMalDokumentPrMottaker,
} from './EditorNormal.js';
import Komment from 'images/Annet/kommentar.png';
import {
  PanelKommentar,
  visPinkKommentar,
  visIkkeKommentar,
  lastTestData,
  onKommentarClick,
  onNyKommentar,
} from './Kommentar.js';

const debug = debugFactory('nk:DokEditor');
const FROALA_KEY = CONFIG.REACT_APP_FROALA;
const bStyle = '1px dotted white';
const cStyle = '1px dotted lightgray';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),

  ForhandsvisningDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <Forhandsvisning
        onClose={onClose}
        model={owner.state.model}
        // visToppTekstButton= {true}
      />
    </NKModal>
  ),
  BorderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <TabellBorderDialog
        onClose={onClose}
        element={dialogData.element}
        elementNavn={dialogData.elementNavn}
      />
    </NKModal>
  ),
};

class TestEditor extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.froalaRef = null;
    this.kommentarRef = null;
    this.fileReader = null;
    this.state = {
      model: this.props.model,
      maler: null,
      originalModel: '',
      eierDataListe: [],
      ikkeFlettet: '',
      visKommentarPanel: false,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
/*       let model = this.props.model;
      model = lastTestData(model);
      this.setState({ model }); */
  };

  //*********************KOMMENTAR*************************************************************************************** */

  visKommentarPanel = () => {
    if (!this.state.visKommentarPanel) {
      this.props.toggleDialogWidth();
      this.setState({ visKommentarPanel: true });
      visPinkKommentar(this.froalaRef.editor, this.KommentarRef, false);
      /*       setTimeout(() => {
         
        }, 3000); */
    } else {
      this.props.toggleDialogWidth();
      this.setState({ visKommentarPanel: false });
      visIkkeKommentar(this.froalaRef.editor);
    }
  };

  setNyKommentar = () => {
    if (!this.state.visKommentarPanel) {
      this.visKommentarPanel();
    }
    let editor = this.froalaRef.editor;

    editor.selection.restore();
    let tekst = editor.selection.text();
    if (!tekst) {
      alert('Ingen tekst merket ut..!');
      return;
    }
    onNyKommentar(tekst, this.KommentarRef, editor);
  };

  //************************************************************************************************************ */

  getModel = () => {
    let s = '<p>Dette er en <b>tekst</b></p>';
    return s;
  };

  componentDidMount = () => {
    this.editor = this.froalaRef;
    let model = this.getModel();
    this.setState({ model });
    model = checkEditorNormal(model);
    this.makeFroalaToolButtons();
    let bruksenhetids = [];
    bruksenhetids.push(this.props.bruksenhetId.toString());
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.model) {
      let modelP = prevProps.model;
      let modelC = this.props.model;
      if (modelP !== modelC) {
        this.setState({ model: modelC });
      }
    }
  };

  makeFletteOptions = () => {
    let liste = this.props.fletteOptions;
    let fletteOptions = {};
    for (let i = 0; i < liste.length; i++) {
      const el = liste[i];
      if (el.result) {
        fletteOptions[el.result + ' '] = el.text;
      }
    }
  };

  makeFroalaToolButtons = () => {
    // Froalaeditor.DefineIcon('tags', { NAME: 'tags', SVG_KEY: 'tags' });

    Froalaeditor.DefineIcon('kommentar', {
      SRC: Komment,
      ALT: 'Kommentar',
      template: 'image',
    });
    Froalaeditor.RegisterCommand('kommentar', {
      title: 'Sideperson kommentar',
      icon: 'kommentar',
      callback: () => {
        this.visKommentarPanel();
      },
    });

    Froalaeditor.DefineIcon('star', {
      NAME: 'star',
      SVG_KEY: 'star',
    });
    Froalaeditor.RegisterCommand('star', {
      title: 'Testknapp',
      icon: 'star',
      callback: () => {
        alert('Test..');
      },
    });

    Froalaeditor.DefineIcon('cellbordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'cellStyle',
    });
    Froalaeditor.RegisterCommand('cellbordersDialog', {
      title: 'Kantlinjer celle',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var celle = celleArray[0];
        this.dialogs.setOpenDialog('BorderDialog', {
          element: celle,
          elementNavn: ' celle',
        });
      },
    });

    Froalaeditor.DefineIcon('tablebordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'tableStyle',
    });
    Froalaeditor.RegisterCommand('tablebordersDialog', {
      title: 'Kantlinjer tabell',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var parentTable = celleArray[0].offsetParent;
        this.dialogs.setOpenDialog('BorderDialog', {
          element: parentTable,
          elementNavn: ' tabell',
        });
      },
    });

    Froalaeditor.DefineIcon('pageBreaker', {
      NAME: 'Sideskift',
      SVG_KEY: 'pageBreaker',
    });
    Froalaeditor.RegisterCommand('pageBreaker', {
      title: 'Sideskift',
      icon: 'pageBreaker',
      callback: () => {
        this.sideskift();
      },
    });

    Froalaeditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
    Froalaeditor.RegisterCommand('clear', {
      title: 'Fjern alt',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      callback: function() {
        this.html.set('');
        this.events.focus();
      },
    });
  };

  forhandsvisning = () => {
    this.dialogs.setOpenDialog('ForhandsvisningDialog');
  };

  openFileReader = () => {
    let fileReader = this.fileReaderRef;
    fileReader.click();
  };

  removeHTML = (str) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  };

  getDokumentTekst = () => {
    return this.state.model;
  };

  handleModelChange = (model) => {
    this.setState({ model });
  
   /*  debug('AAA2', this.editor.editor.toolbar);
    this.editor.editor.toolbar.open= false; */
  };

  render() {
    if (this.state.model === ''){
      return <div style={{ padding: '40px' }}>Vent...</div>;
    }

    let visHTML = 'html';
    let visStar = 'star';

    let visKommentar = 'kommentar';
    let visMalKnapp = 'maltekst';
    if (this.props.skalLukkes) {
      visMalKnapp = 'brukmaltekst';
    }

    const config = {
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'Test-tekst..',
      autoFocus: true,
      fontFamilySelection: true,
      fontSizeSelection: true,
      /*       paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
      }, */
      paragraphFormatSelection: false,
      quickInsertEnabled: false,
      tableEditButtons: [
        'tableHeader',
        'tableRemove',
        '|',
        'tableRows',
        'tableColumns',
        'tablebordersDialog',
        '-',
        'tableCells',
        'tableCellBackground',
        '|',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign',
        'cellbordersDialog',
      ],

      tableColors: [
        '#61BD6D',
        '#1ABC9C',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#CCCCCC',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#000000',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#FFFFFF',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
        'REMOVE',
      ],
      listAdvancedTypes: false,
      spellcheck: false,
      tabSpaces: 4,
      imagePaste: false,
      language: 'nb',
      heightMin: 200,
      heightMax: 'calc(100vh - 200px)',
/*       height: 'calc(100vh - 100px)', */
      lineheight: '1.15',
      charCounterCount: true,
      lineHeights: {
        Standard: '1',
        '1.15': '1.15',
        '1.5': '1.5',
        Dobbel: '2',
      },
      events: {
        'commands.before': (e) => {
          if (e === 'print') {
            let editor = this.froalaRef.editor;
            this.setState({ originalModel: this.state.model });
            return this.skjulTabellKantlinjerOgMottaker(editor);
            //return skjulTabellKantlinjer(editor);
          }
        },
        'commands.after': (f) => {
          if (f === 'print') {
            this.setState({ model: this.state.originalModel });
            let editor = this.froalaRef.editor;
            return visTabellKantlinjerIgjen(editor);
          }
        },
        mousedown: (e) => {
          if (e.currentTarget.nodeName === 'SPAN') {
            onKommentarClick(e, this.KommentarRef, this.froalaRef.editor);
          }
        },
      },

      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Verdana,Geneva,sans-serif': 'Verdana',
        'Open Sans': 'Open Sans',
        'Calibri': 'Calibri',
        'Times New Roman,Times,serif': 'Times New Roman',
        'Roboto,sans-serif': 'Roboto',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        'Oswald,sans-serif': 'Oswald',
        'Montserrat,sans-serif': 'Montserrat',
        Handlee: 'Handlee',
      },
      fontSize: [
        '8',
        '10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '20',
        '24',
        '30',
        '60',
      ],
      toolbarButtons: {
        moreText: {
          buttons: [
            visMalKnapp,
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'fontFamily',
            '|',
            'fontSize',
            '|',
            'paragraphFormat',
            '|',
            'textColor',
            'backgroundColor',
          ],
          align: 'left',
          buttonsVisible: 4,
        },
        moreMisc: {
          buttons: [
            'undo',
            'redo',
            'specialCharacters',
          
            visStar,
            visKommentar,
            visHTML,
          ],
          align: 'right',
          buttonsVisible: 2,
        },
        moreParagraph: {
          buttons: [
            'alignLeft',
            'alignCenter',
            'formatOL',
            'formatUL',
            'alignRight',
            'alignJustify',
            'lineHeight',
            'outdent',
            'indent',
          ],
          align: 'left',
          buttonsVisible: 4,
        },
      },
    };
    return (
      <div>
        <Fragment>
          <table>
            <tbody>
              <tr>
                <td style={{ maxWidth: '776px', minWidth: '776px' }}>
                  <FroalaEditorComponent
                    ref={(el) => {
                      this.froalaRef = el;
                    }}
                    id='editor'
                    useClasses={false}
                    config={config}
                    documentReady={true}
                    model={this.state.model}
                    onModelChange={this.handleModelChange}
                  />
                </td>
                {this.state.visKommentarPanel && (
                  <td
                    style={{
                      padding: '4px',
                      verticalAlign: 'top',
                      width: '300px',
                      maxWidth: '300px', minWidth: '300px' 
                    }}>
                    <PanelKommentar
                      ref={(el) => {
                        this.KommentarRef = el;
                      }}
                      editor={this.froalaRef}
                      panel={this.KommentarRef}
                      visKommentarPanel={this.visKommentarPanel}
                      setNyKommentar={this.setNyKommentar}
                    />
                  </td>
                )}

                <tr />
              </tr>
            </tbody>
          </table>

          <ReactFileReader
            base64={true}
            multipleFiles={false}
            fileTypes={['.gif, .jpg, .png, .jpeg']}
            handleFiles={this.handleFiles}>
            <button
              ref={(fl) => {
                this.fileReaderRef = fl;
              }}
              className='btn'
              style={{ display: 'none' }}>
              Last opp image
            </button>
          </ReactFileReader>

          {this.dialogs.renderOpenDialog()}
        </Fragment>
      </div>
    );
  }
}

export default TestEditor;
