import React from 'react';
import NumericInput from 'react-numeric-input';
import 'react-select/dist/react-select.css';
import royklopApi from 'api/royklopApi';
import moment from 'moment';   
import 'moment/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'react-select/dist/react-select.css';
import 'modules/Gjennomforing/gjennomforing.css';
import { debugFactory, validatableMoment } from 'utils';
import RestrictedDatePicker from 'components/RestrictedDatePicker';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { getContextRoyklopOppslag } from 'context/contextOppslag';



const debug = debugFactory('nk:modules:gjennomforing:nyttroyklop');
var defaultModel = null;

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Lagre endringer'
        melding='Du har ikke valgt en modell.  Ønsker du å lagre likevel? (Produsent blir ikke lagret heller.)'
        onJa={owner.lagreJa}
        onNei={onClose}
      />
    );
  },
};

class NyttRoyklop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      royklopTypedata: null,
      selectedProdusent: '',
      selectedType: null,
      modeller: null,
      produktUrl: '',
      startDate: moment(),
      endDate: moment().add(2, 'month'),
      endreRisiko: false,
      risikoverdiOverstyrAarsak: '',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    getContextRoyklopOppslag(this.gotRoyklopTypeData);
  }

  gotRoyklopTypeData = (typeData) => {
    this.setState({ royklopTypedata: typeData });
    //if (this.state.royklopTypedata.produsentListe.length > 0) {
    this.setInitielData();
    //}
  };

  setInitielData = () => {
    var mod = [];
    // for (var i = 0; i < this.state.royklopTypedata.modellListe.length; i++) {
    //   if (this.state.royklopTypedata.modellListe[i].fremmedNokkel === val) {
    //     mod.push(this.state.royklopTypedata.modellListe[i]);
    //   }
    // }
    // defaultModel = mod.length > 0 ? mod[0].id : null;
    this.setState({
      modeller: mod,
      //defaultModel: mod[0].id,
      produsentId: null,
      plasseringId: null,
      modellId: null,
      rehabilitert: false,
      dimensjonId: null,
      pipebrann: null,
      adkomstId: null,
      sotuttakId: null,
      typeId: null,
      merknad: null,
      lengdeHoyde: '',
      risikoverdi: '',
      dokumentasjon: ''
    });
  };

  produsentChange = (val) => {
    var mod = [];
    var prodId = null;
    if (val.target.value !== 0) {
      prodId = val.target.value;
      for (var i = 0; i < this.state.royklopTypedata.modellListe.length; i++) {
        if (
          this.state.royklopTypedata.modellListe[i].fremmedNokkel.toString() ===
          val.target.value
        ) {
          mod.push(this.state.royklopTypedata.modellListe[i]);
        }
      }
      mod.unshift({ id: null, tekst: '', fremmedNokkel: '' });
    }

    this.setState({
      selectedProdusent: val,
      selectedType: null,
      modeller: mod,
      produktUrl: val.link,
    });
    this.setState({
      produsentId: prodId,
      modellId: null,
    });
  };

  modellChange = (val) => {
    this.setState({ dokumentasjon: '' });
    this.setState({ modellId: val.target.value })
    for (let i = 0; i < this.state.royklopTypedata.modellListe.length; i++) {
      let element = this.state.royklopTypedata.modellListe[i];
      if (element.id == val.target.value) {
        this.setState({ dokumentasjon: element.tekst2}); ;
      }
    } 
  };

  plasseringChange = (val) => {
    this.setState({ plasseringId: val.target.value });
  };

  typeChange = (val) => {
    this.setState({ typeId: val.target.value });
  };

  rehabChange = (val) => {
    this.setState({
      rehabilitert: val.currentTarget.value === 'ja' ? true : false,
    });
  };

  dimensjonChange = (val) => {
    this.setState({ dimensjonId: val.target.value });
  };

  pipebrannChange = (val) => {
    const date = validatableMoment(val);
    this.setState({ pipebrann: date });
  };

  feietilkomstChange = (val) => {
    this.setState({ adkomstId: val.target.value });
  };

  sotuttakChange = (val) => {
    this.setState({ sotuttakId: val.target.value });
  };

  merknadChange = (val) => {
    this.setState({ merknad: val.target.value });
  };

  lengdeChange = (val) => {
    this.setState({ lengdeHoyde: val });
  };

  risikoChange = (val) => {
    this.setState({ risikoverdi: val });
  };

  endreRisikoChange = () => {
    let endreRisiko = !this.state.endreRisiko;
    this.setendreRisiko(endreRisiko);
  };

  setendreRisiko = (endreRisiko) => {
    if (endreRisiko) {
      this.setState({
        endreRisiko: true,
        endreRisikoText: 'Ja',
        risikoverdiOverstyr: true,
      });
    } else {
      this.setState({
        endreRisiko: false,
        endreRisikoText: 'Nei',
        risikoverdiOverstyr: false,
      });
    }
  };

  risikoverdiOverstyrAarsakChange = (val) => {
    this.setState({ risikoverdiOverstyrAarsak: val.currentTarget.value });
  };

  submit = () => {
    if (this.state.modellId == null || this.state.modellId === '') {
      this.dialogs.setOpenDialog('JaNeiDialog');
    } else {
      this.lagre();
    }
  };

  lagre = (nyttRoyklop) => {
    var nyttRoyklop = {
      typeId: this.state.typeId != '0' ? this.state.typeId : null,
      plasseringId:
        this.state.plasseringId != '0' ? this.state.plasseringId : null,
      produsentId:
        this.state.produsentId != '0' ? this.state.produsentId : null,
      modellId: this.state.modellId != '0' ? this.state.modellId : null,
      adkomstId: this.state.adkomstId != '0' ? this.state.adkomstId : null,
      sotuttakId: this.state.sotuttakId != '0' ? this.state.sotuttakId : null,
      sotmengdeId: null,
      dimensjonId:
        this.state.dimensjonId != '0' ? this.state.dimensjonId : null,
      lengdeHoyde: this.state.lengdeHoyde,
      pipebrann: this.state.pipebrann,
      risikoverdi: this.state.risikoverdi,
      rehabilitert: this.state.rehabilitert,
      merknad: this.state.merknad,
      BruksenhetIdNyttLop: this.props.enhetId,
      risikoverdiOverstyr: this.state.risikoverdiOverstyr,
      risikoverdiOverstyrAarsak: this.state.risikoverdiOverstyrAarsak,
    };
    this.props.onLagreNyttRoyklop(nyttRoyklop);
    this.dialogs.closeDialog();
  };

  lagreJa = () => {
    this.lagre();
  };

  render() {
    if (!this.state.royklopTypedata) {
      return <div>Laster...</div>;
    }
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Nytt røykløp</h4>
        </div>
        <div className='modal-body'>
          <form>
            <div className='panel panel-default' style={{ marginBottom: '0' }}>
              <div className='panel-heading'>Produktinfo</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Type</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.typeChange}
                        defaultValue={null}>
                        {_.map(this.state.royklopTypedata.typeListe, function(
                          o,
                          i
                        ) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Produsent</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.produsentChange}
                        defaultValue={null}>
                        {_.map(
                          this.state.royklopTypedata.produsentListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Modell</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.modellChange}
                        defaultValue={defaultModel}>
                        {_.map(this.state.modeller, function(o, i) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-12'>
                    <label>Dokumentasjon</label>
                    <br />
                    {this.state.dokumentasjon != '' && this.state.dokumentasjon != null ? (
                      <a href={this.state.dokumentasjon} target='_blank'>
                        {this.state.dokumentasjon.length > 60
                          ? this.state.dokumentasjon.substring(0, 60) + '...'
                          : this.state.dokumentasjon}
                      </a>
                    ) : (
                      <span>Ikke tilgjengelig</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='panel panel-default' style={{ marginBottom: '0' }}>
              <div className='panel-heading'>Størrelse og plassering</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Dimensjon</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.dimensjonChange}
                        defaultValue={
                          this.state.royklopTypedata.dimensjonListe !== null
                            ? this.state.royklopTypedata.dimensjonListe[0].id
                            : null
                        }>
                        {_.map(
                          this.state.royklopTypedata.dimensjonListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Lengde (meter)</label>
                      <br />
                      <div>
                        <NumericInput
                          type='number'
                          mobile={true}
                          className='width100 hoyde34'
                          min={0}
                          max={200}
                          value={this.state.lengdeHoyde}
                          defaultValue=''
                          onChange={this.lengdeChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Plassering</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.plasseringChange}
                        defaultValue={
                          this.state.royklopTypedata.plasseringListe !== null
                            ? this.state.royklopTypedata.plasseringListe[0].id
                            : null
                        }>
                        {_.map(
                          this.state.royklopTypedata.plasseringListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Sotuttak</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.sotuttakChange}
                        defaultValue={
                          this.state.royklopTypedata.sotuttakListe !== null
                            ? this.state.royklopTypedata.sotuttakListe[0].id
                            : null
                        }>
                        {_.map(
                          this.state.royklopTypedata.sotuttakListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='panel panel-default'>
              <div className='panel-heading'>Annet</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Rehabilitert</label>
                      <br />
                      <div className='radio inline margin4'>
                        <label>
                          <input
                            type='radio'
                            name='rehabRadio'
                            value='ja'
                            defaultChecked={false}
                            onChange={this.rehabChange}
                          />
                          Ja
                        </label>
                      </div>
                      <div className='radio inline margin-left-15'>
                        <label>
                          <input
                            type='radio'
                            name='rehabRadio'
                            value='nei'
                            defaultChecked={true}
                            onChange={this.rehabChange}
                          />
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Pipebrann</label>
                      <br />
                      <RestrictedDatePicker
                        className='width120Heigth32'
                        showYearDropdown
                        locale='no-nb'
                        todayButton={'I dag'}
                        isClearable={true}
                        placeholderText='Velg dato'
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={this.pipebrannChange}
                        onBlur={this.pipebrannChange}
                        selected={this.state.pipebrann}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Feieadkomst</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.feietilkomstChange}
                        defaultValue={
                          this.state.royklopTypedata.adkomstListe !== null
                            ? this.state.royklopTypedata.adkomstListe[0].id
                            : null
                        }>
                        {_.map(
                          this.state.royklopTypedata.adkomstListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Risikoverdi</label>
                      <br />
                      <div>
                        <NumericInput
                          mobile={true}
                          className='width100 height38'
                          min={0}
                          max={10}
                          type='number'
                          defaultValue=''
                          value={this.state.risikoverdi}
                          onChange={this.risikoChange}
                          disabled={!this.state.endreRisiko}
                        />
                        {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallAnnet} onChange={this.annetChange} />*/}
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Overstyr risiko</label>
                      <br />
                      <div className='radio inline margin4'>
                        <label>
                          <input
                            type='radio'
                            name='overstyrRadio'
                            value='ja'
                            defaultChecked={this.state.endreRisiko === true}
                            onChange={this.endreRisikoChange}
                          />
                          Ja
                        </label>
                      </div>
                      <div className='radio inline margin-left-15'>
                        <label>
                          <input
                            type='radio'
                            name='overstyrRadio'
                            value='nei'
                            defaultChecked={this.state.endreRisiko === false}
                            onChange={this.endreRisikoChange}
                          />
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.state.endreRisiko ? (
                    <div className='col-sm-6'>
                      <label>Årsak til overstyring</label>
                      <textarea
                        className='form-control'
                        type='textarea'
                        id='message'
                        placeholder='Årsak ...'
                        maxLength='140'
                        rows='1'
                        value={this.state.risikoverdiOverstyrAarsak}
                        onChange={this.risikoverdiOverstyrAarsakChange}
                      />
                    </div>
                  ) : null}
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <label>Merknad</label>
                    <textarea
                      className='form-control textareaNoresize'
                      type='textarea'
                      id='message'
                      placeholder='Merknad'
                      maxLength='2000'
                      rows='4'
                      onChange={this.merknadChange}
                    />
                  </div>
                </div>
                <br />
                <div className='row'>
                  <div className='col-sm-12 col-lg-12 col-md-12'>
                    <div className='pull-right'>
                      <input
                        type='button'
                        className='btn btn-primary height48'
                        value='OK'
                        onClick={this.submit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NyttRoyklop;
