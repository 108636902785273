import React, { Component } from 'react';
import { getMaler, slettMal } from 'api/malerApi';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import ReactModal from 'react-modal';
import DialogStore from 'lib/DialogStore';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

import { debugFactory } from 'utils';
import MalTable from './MalTable.jsx';
import MalDialog from './MalDialog.jsx';
import './mal.css';
import Button from 'components/generics/Button';
const debug = debugFactory('nk:Dokument');


const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  MalDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className='malModalMediumX'>
      <MalDialog
        onClose={onClose}
        mal={dialogData.mal}
        onOppdatert={owner.oppdaterListe}
        gjelder={dialogData.gjelder}
        norkartbruker={contextInnstillingerPerson._currentValue.norkartAdmin}
        pilotbruker={contextInnstillingerPerson._currentValue.pilotBruker}
        modus='Innstillinger'
      />
    </ReactModal>
  ),
  SletteDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Slette?'}
        melding={dialogData.melding}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};

class Dokument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planMaler: [],
      gjennomforingMaler: [],
      oppfolgingMaler: [],
      generelleMaler: []
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    this.hentData();
  }

  stripTekst = (tekst) => {
    tekst = this.goReplace(tekst, '<h2>', '');
    tekst = this.goReplace(tekst, '</h2>', '');
    tekst = this.goReplace(tekst, '<ul>', '');
    tekst = this.goReplace(tekst, '</ul>', '');
    tekst = this.goReplace(tekst, '<strong>', '');
    tekst = this.goReplace(tekst, '</strong>', '');
    tekst = this.goReplace(tekst, '<li>', ' -');
    tekst = this.goReplace(tekst, '</li>', '');
    tekst = this.goReplace(tekst, '<p>', '');
    tekst = this.goReplace(tekst, '</p>', '\n');
    tekst = this.goReplace(tekst, '<br>', '\n');
    tekst = this.goReplace(tekst, '\n\n', '\n');
    tekst = this.goReplace(tekst, '&nbsp;', ' ');
    tekst = tekst.trim();
    return tekst;
  };

  stripListe = (liste) => {
    for (let i = 0; i < liste.length; i++) {
      let tekst = liste[i].tekst;
      if (tekst !== '') {
        liste[i].striptekst = this.stripTekst(tekst);
      }
    }
    return liste;
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replace(new RegExp(find, 'g'), repl);
    return str;
  };

  hentData = () => {
    this.dialogs.closeDialog();
    this.hentDataPlanlegging();
    this.hentDataGjennomforing();
    this.hentDataOppfolging();
    this.hentDataGenerelle();
  };

  hentDataPlanlegging = () => {
    getMaler('P', this.gotPlanleggingMaler);
  };

  gotPlanleggingMaler = (err, data) => {
    console.log('%c RIENI Maler ', 'font-size: 14px; background: firebrick; color: white',  data );  
    this.setState({ planMaler: this.stripListe(data) });
  };

  hentDataGjennomforing = () => {
    getMaler('G', this.gotGjennomforingMaler);
  };

  gotGjennomforingMaler = (err, data) => {
    this.setState({ gjennomforingMaler: this.stripListe(data) });
  };

  hentDataOppfolging = () => {
    getMaler('O', this.gotOppfolgingMaler);
  };

  gotOppfolgingMaler = (err, data) => {
    this.setState({ oppfolgingMaler: this.stripListe(data) });
  };

  hentDataGenerelle = () => {
    getMaler('F', this.gotGenerelleMaler);
  };

  gotGenerelleMaler = (err, data) => {
    this.setState({ generelleMaler: this.stripListe(data) });
  };

  onRowClick = (tab, item) => {
    this.dialogs.setOpenDialog('MalDialog', {
      mal: item,
      gjelder: tab
    });
  };

  slettMal = (tab, item) => {
    this.dialogs.setOpenDialog('SletteDialog', {
      melding: 'Ønsker du å slette malen "' + item.tittel + '"',
      onJa: () => {
        switch (tab) {
          case 'P':
            slettMal(item.id, this.etterSlettPlan);
            break;
          case 'G':
            slettMal(item.id, this.etterSlettGjen);
            break;
          case 'O':
            slettMal(item.id, this.etterSlettOppf);
            break;
          case 'F':
            slettMal(item.id, this.etterSlettGenerelle);
            break;
          default:
            return;
        }
      },
    });
  };

  oppdaterListe = (tab) => {
    debug('OOSS', tab);
    switch (tab) {
      case 'P':
        this.etterSlettPlan();
        break;
      case 'G':
        this.etterSlettGjen();
        break;
      case 'O':
        this.etterSlettOppf();
        break;
      case 'F':
        this.etterSlettGenerelle();
        break;
      default:
        return;
    }
  };

  etterSlettPlan = () => {
    this.hentDataPlanlegging();
  };

  etterSlettGjen = () => {
    this.hentDataGjennomforing();
  };

  etterSlettOppf = () => {
    this.hentDataOppfolging();
  };

  etterSlettGenerelle = () => {
    this.hentDataGenerelle();
  };

  nyMal = (tab) => {
    let mal = {
      id: 0,
      funksjonId: 0,
      funksjon: '',
      gjelderKode: tab,
      tekst: '',
      tittel: '',
      beskrivelse: '',
      tiltak: [],
      hoveddokument: false,
    };
    this.onRowClick(tab, mal);
  };

  AlleEksempelmal = () => {
    this.dialogs.setOpenDialog('AlleEksempelmalDialog');
  };

  render() {
    return (
      <div>
        <h3>Dokumentmaler</h3>

        <div className='panel panel-default'>
          <ul className='nav nav-tabs'>
            <li className='active'>
              <a data-toggle='tab' href='#plantab'>
                Planlegging
              </a>
            </li>
            <li>
              <a data-toggle='tab' href='#gjentab'>
                Gjennomføring
              </a>
            </li>
            <li>
              <a data-toggle='tab' href='#oppftab'>
                Oppfølging
              </a>
            </li>
            <li>
              <a data-toggle='tab' href='#gentab'>
                Generelle funksjoner
              </a>
            </li>
          </ul>
          <div className='panel-body' style={{ paddingTop: '4px' }}>
            <div className='tab-content'>
              {/* ******** TAB ************************************** */}
              <div id='plantab' className='tab-pane fade in active'>
                <Button icon='pluss' onClick={() => this.nyMal('P')}>
                  Ny mal
                </Button>
                <MalTable
                  modul='P'
                  onRowClick={this.onRowClick.bind(this, 'P')}
                  slettMal={this.slettMal.bind(this, 'P')}
                  items={this.state.planMaler}
                  oppdaterListe={this.oppdaterListe.bind(this, 'P')}
                />
              </div>
              {/* ******** TAB ************************************** */}
              <div id='gjentab' className='tab-pane fade'>
                <Button icon='pluss' onClick={() => this.nyMal('G')}>
                  Ny mal
                </Button>
                <MalTable
                  modul='G'
                  onRowClick={this.onRowClick.bind(this, 'G')}
                  slettMal={this.slettMal.bind(this, 'G')}
                  items={this.state.gjennomforingMaler}
                  oppdaterListe={this.oppdaterListe.bind(this, 'G')}
                />
              </div>
              {/* ******** TAB ************************************** */}
              <div id='oppftab' className='tab-pane fade '>
                <Button icon='pluss' onClick={() => this.nyMal('O')}>
                  Ny mal
                </Button>
                <MalTable
                  modul='O'
                  onRowClick={this.onRowClick.bind(this, 'O')}
                  slettMal={this.slettMal.bind(this, 'O')}
                  items={this.state.oppfolgingMaler}
                  oppdaterListe={this.oppdaterListe.bind(this, 'O')}
                />
              </div>
              {/* ******** TAB ************************************** */}
              <div id='gentab' className='tab-pane fade '>
                <Button icon='pluss' onClick={() => this.nyMal('F')}>
                  Ny mal
                </Button>
                <MalTable
                  modul='F'
                  onRowClick={this.onRowClick.bind(this, 'F')}
                  slettMal={this.slettMal.bind(this, 'F')}
                  items={this.state.generelleMaler}
                  oppdaterListe={this.oppdaterListe.bind(this, 'F')}
                />
              </div>
            </div>
          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Dokument;
