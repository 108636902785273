/* eslint-disable radix */
import React from 'react';
import { getMatrikkelnummer, getAdresse } from 'modules/felles/AdresseHelper';
import { SearchkitComponent } from 'searchkit';
import * as _ from 'lodash';
import moment from 'moment';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import ContextMenu from 'components/ContextMenu';
import enhetApi from 'api/enhetApi';
import '@norkart/react-table/dist/react-table.css';
import ReactTable from '@norkart/react-table';
import { Pagination } from 'searchkit';
import InfoDialog from 'components/Dialogs/InformationDialog.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
import CONFIG from 'lib/CONFIG';
import { reloadSearch, search } from 'utils/searchKitHelper';
// Utils
import { makeLockReasonsTooltip } from '../lib';
import { debugFactory, objGetDeep } from 'utils';
import GebyrDialog from 'modules/BraGe/GebyrDialog.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:panelbruksenheter'
);

class PanelBruksenheter extends SearchkitComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      hideMap: this.props.isMapCollapsed ? this.props.isMapCollapsed : false,
      previewObjekt: null,
      itemsSituasjonAdresse: null,
      itemSituasjonId: null,
      columns: [],
      visGebyr: false
    };

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.mapWasCollapsed = false;
    this.listeMedEtablerte = [];
    this.valgteBruksenheter = [];

    this.rowClick = this.rowClick.bind(this);
    this.onVisKartClicked = this.onVisKartClicked.bind(this);
    this.onVisMatrikkelinfo = this.onVisMatrikkelinfo.bind(this);
    this.addToWorklist = this.addToWorklist.bind(this);
    this.onBoundsFilter = this.onBoundsFilter.bind(this);
    this.onBrukPolygon = this.onBrukPolygon.bind(this);
    this.onPolygonFjernet = this.onPolygonFjernet.bind(this);
    this.onBoundsFilterBruk = this.onBoundsFilterBruk.bind(this);
    this.onBrukPrecision = this.onBrukPrecision.bind(this);
    this.getValgteEnheter = this.getValgteEnheter.bind(this);
    this.shouldHideMap = this.shouldHideMap.bind(this);
    this.onPolygonFilterRemoved = this.onPolygonFilterRemoved.bind(this);
    this.onRegistrerCompleted = this.onRegistrerCompleted.bind(this);
    this.oppdaterLagrede = this.oppdaterLagrede.bind(this);
    this.isRowSelectable = this.isRowSelectable.bind(this);
    this.rowFormat = this.rowFormat.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);
    // Flemming, quick & dirty
    this.RT_columns = get_RT_columns.call(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.shouldHideMap);
    this.setState({ columns: this.RT_columns.getAdresseColumns() });
    
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
  }

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  componentWillUnmount() {
    // window.removeEventListener('resize', this.shouldHideMap);
    // this.props.searchkit.geoAccessor.setPolygon(null);
  }

  onCloseDialog() {
    reloadSearch(this.props.searchkit);
  }

  oppdaterLagrede() {
    let k = this.kart;
    k.oppdaterLagrede();
  }

  shouldHideMap() {
    if (this.mapWasCollapsed) {
      return;
    }
    this.setState({
      hideMap: !window.matchMedia('(min-width: 1200px)').matches,
    });
  }

  onPolygonFilterRemoved() {
    let k = this.kart;
    if (k !== null) {
      k.fjernAllePolygonLayers();
    } else {
      // debug('Feil PanelBruksenheter', 'onPolygonFilterRemoved');
    }
  }

  onVisKartClicked() {
    if (this.props.mapWasCollapsed) {
      this.mapWasCollapsed = true;
      this.props.mapWasCollapsed();
    }
    this.setState({
      hideMap: !this.state.hideMap,
    });
  }

/*   getSituasjon = (item) => {
    debug('RRRR ITEM', item);
    promisifyApiCall(enhetApi.getBruksenhetSituasjon)(3834, null).then(
      (data) => {
        debug('RRRR TT', data);
        return data;
      }
    );
  }; */

  addToWorklist(object) {
    debug('FLINT BB', object);
    let objektliste = this.props.getTiltakListe({ bruksenheter: true });
    let valgteEnheter = this.getValgteEnheter(this.props.enheter, objektliste);

    let lockReasons = { fraApi: false, varslet: false };
    let row = {
      bruksenhetId: object.bruksenhetId,
      id: object.id,
      lockReasons: lockReasons,
      source: object.source,
      //situasjon: this.getSituasjon(object.bruksenhetId),
    };

    let selectedRows = [];
    for (let i = 0; i < valgteEnheter.length; i++) {
      selectedRows.push(valgteEnheter[i]);
    }
    selectedRows.push(row);
    //debug('onRowSelectionChange selectedRows New', selectedRows);

    this.onRowSelectionChange({
      selectedRows,
      prevSelectedRows: valgteEnheter,
    });
  }

  //BOUNDSFILTER *********
  onBoundsFilter(boundsfilter) {
    //this.props.geoAccessor.setArea(boundsfilter);
    search(this.searchkit);
  }
  onBoundsFilterBruk(bruk) {
    if (!bruk) {
      //this.props.geoAccessor.setArea(null);
      search(this.searchkit);
    }
  }
  //POLYGON **************
  onBrukPolygon(poly) {
    this.props.searchkit.geoAccessor.setPolygon(
      poly,
      this.onPolygonFilterRemoved
    );
    search(this.searchkit);
  }

  onPolygonFjernet() {
    this.props.searchkit.geoAccessor.setPolygon(null);
    search(this.searchkit);
  }

  //geoHash Precision
  onBrukPrecision(p) {
    if (this.props.geoAccessor) {
      this.props.geoAccessor.setPrecision(p);
    }
  }

  //Preview spretter
  rowClick(row, e) {
    let bygg = row.bygg ? row.bygg : row.source.bygg;
    this.setState({ bygningsNr: bygg.bygningsnummer });
    if (this.props.onRowClick) {
      this.props.onRowClick(row.id, bygg.bygningsnummer);
    }
    /*  let k = this.kart;
    if (!k) {
      return;
    }
    k.visPreviewObjekt(row, true); */
    this.props.onSpretter(row);
  }

  onClickSituasjon(item) {
    let enhetid = item.source.skybrannData.bruksenhet.fagId;
    let adresse = item.source ? getAdresse(item.source) : 'null';
    this.setState({
      itemSituasjonId: enhetid,
      matrikkelenhetId: item.source.id,
      itemsSituasjonAdresse: adresse,
    });
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemSituasjonId: enhetid,
      matrikkelenhetId: item.source.id,
      itemsSituasjonAdresse: adresse,
    });
  }
  onRegistrer(item) {
    // debug('FagdataX2', item);
    if (
      _.some(this.listeMedEtablerte, function(o) {
        return o === parseInt(item.id, 10);
      })
    ) {
      this.dialogs.setOpenDialog('InfoDialog');
      return;
    }
    let adresse = 'null';
    if (item.source) {
      if (item.source.adresse) {
        adresse = item.source.adresse.gatenavn;
      }
    }
    //let adresse = item.source ? item.source.adresse.gatenavn : 'null';

    this.setState({ itemsSituasjonAdresse: adresse });

    enhetApi.createBruksenhet(
      { BruksenhetIdMa: item.id },
      this.onRegistrerCompleted
    );
  }

  onVisMatrikkelinfo(item) {
    let bygg = item.bygg ? item.bygg : item.source.bygg;
    this.setState({
      bygningsNr: bygg.bygningsnummer,
    });
    this.dialogs.setOpenDialog('MatrikkelDialog', {
      matrikkelinfoId: item.id,
      bygningsNr: bygg.bygningsnummer,
    });
  }

  onRegistrerCompleted(err, result) {
    if (err) {
      return;
    }
    this.listeMedEtablerte.push(result.bruksenhetIdMa); //For å hindre at man skal kunne dobbeltregistrere
    this.setState({ itemSituasjonId: result.id });
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemSituasjonId: result.id,
      matrikkelenhetId: this.state.matrikkelenhetId,
      itemsSituasjonAdresse: this.state.itemsSituasjonAdresse,
    });
  }

  isVurdert = (itemId) => {
    if (!this.props.vurderte) {
      return false;
    }
    let i = parseInt(itemId);
    var inVurdert = this.props.vurderte.includes(i);
    return inVurdert;
  };

  rowFormat = (row) => {
    let blueTekst = '';
    let blueClass = '';

    let ff = _.get(row, 'source.skybrannData.bruksenhet.fyringsforbud');
   
    if (ff) {
      blueClass = ' fyringsforbudBackground ';
      blueTekst = ' - Fyringsforbud';
    }

    let cc = '';
    let tt = '';
    if (_.isUndefined(row.lockReasons)) {
      row.lockReasons = this.props.getObjektLockReasons(row);
    }
    if (row.lockReasons) {
      if (row.lockReasons.manglerFagData) {
        cc = 'missingSkybranndata';
        if (this.isVurdert(row.id)) {
          blueClass = ' blueBackgroundLight ';
          blueTekst = ' - Vurdert';
        }
      }
      tt = makeLockReasonsTooltip(row.lockReasons);
    } else {
      cc = 'harSkybranndata';
    }

    return { tooltip: tt + blueTekst, className: cc + blueClass };
  };

  isRowSelectable = (row) => {
    // NOTE! See `rowFormat`
    return !row.lockReasons;
  };

  getValgteEnheter(enheter, objektliste) {
    objektliste = objektliste.filter((item) => item.slett === false);

    const valgteEnheter = enheter.filter((parsedHit) => {
      const bruksenhetId = objGetDeep(
        parsedHit,
        'source.skybrannData.bruksenhet.fagId'
      );
      const objekt = objektliste.find((objekt) => {
        // eslint-disable-next-line radix
        const found = parseInt(objekt.bruksenhetId) === parseInt(bruksenhetId);
        //debug('getValgteEnheter', { found, objekt, parsedHit})
        return found;
      });
      return !!objekt;
    });

    // debug('getValgteEnheter', { enheter, objektliste, valgteEnheter });

    return valgteEnheter;
  }

  velgFraKart = (data) => {
    debug('FLINT kart', data);
    this.addToWorklist(data);
  };

  onRowSelectionChange = ({ prevSelectedRows, selectedRows }) => {
    const objektliste = this.props.getTiltakListe({ bruksenheter: true });

    let updatedObjektliste;

    /*    debug('onRowSelectionChange', {
      selectedRows: selectedRows.length,
      prevSelectedRows: prevSelectedRows.length
    });

    debug('onRowSelectionChange', selectedRows); */

    if (selectedRows.length > prevSelectedRows.length) {
      // Adding newly selected
      const nyeObjekter = selectedRows
        .filter((row) => !this.props.getObjektLockReasons(row))
        .map((row) => {
          let objekter = objektliste
            // eslint-disable-next-line radix
            .filter(
              (objekt) =>
                parseInt(objekt.bruksenhetId) === parseInt(row.bruksenhetId)
            );

          if (!_.isEmpty(objekter)) {
            objekter.forEach((objekt) => {
              // Reset to-delete flag
              objekt.slett = false;
            });
            // Return false to skip adding `row`
            return false;
          } else {
            // Make a new `objekt`
            const template = objektliste.find(
              (objekt) => objekt.bruksenhetId == row.bruksenhetId
            );

            const objekt = {
              ..._.cloneDeep(template),
              //`row` is a parsed ES Searchkit-item (See "./LeggTilBruksenheter:PanelBruksenheter")
              ..._.cloneDeep(row),
              // NOTE! Messy stuff below! Temper with it at your own risk, cause it might be used elsewhere "as-is"
              // eslint-disable-next-line radix
              id: parseInt(objGetDeep(row, 'source.id', 0)),
              adresse: getAdresse(row.source),
              // eslint-disable-next-line radix
              bruksenhetId: parseInt(
                objGetDeep(row, 'source.skybrannData.bruksenhet.fagId', 0)
              ),
              // eslint-disable-next-line radix
              bruksenhetIdMa: parseInt(objGetDeep(row, 'source.id', 0)),
              ny: true,
              slett: false,
              tiltakstekst: '',
              varselSendt: false,
              lokasjon:
                row.source !== undefined
                  ? row.source.bygg.lokasjon
                  : row.bygg.lokasjon,
              orgId: row.id, // This is just wrong, but probably used in a way that works...
            };
            return objekt;
          }
        })
        .filter((objekt) => objekt);

      updatedObjektliste = objektliste.concat(nyeObjekter);
    } else if (selectedRows.length < prevSelectedRows.length) {
      // Removing deselected
      const removedIds = _.uniqBy(
        _.difference(prevSelectedRows, selectedRows),
        'bruksenhetId'
      ).map((row) => row.bruksenhetId);

      updatedObjektliste = objektliste
        .map((objekt) => {
          if (this.props.getObjektLockReasons(objekt)) {
            // Locked => not deselectable
            return objekt; // Still selected
          }

          if (removedIds.includes(objekt.bruksenhetId)) {
            // TODO! Not working (@Rieni)
            //  this.kart.setMarkerBlueFraArbeidslisteRemoved(objekt.id);

            if (objekt.ny) {
              // Not in DB, return false to just filter it away next
              return false;
            } else {
              // Flag for Db deletion
              objekt.slett = true;
            }
          }
          return objekt;
        })
        .filter((objekt) => objekt);
    }

    this.props.setArbeidslisteTiltakLister({
      tiltakTypeBruksenhetListe: updatedObjektliste,
    });
  };


 /*  onRowSelectionChange = ({ prevSelectedRows, selectedRows }) => {
    for (let i = 0; i < selectedRows.length; i++) {
      const element = selectedRows[i];
      let tempId = parseInt(
        objGetDeep(element, 'source.skybrannData.bruksenhet.fagId', 0)
      );
      if (!element.situasjon) {
        promisifyApiCall(enhetApi.getBruksenhetSituasjon)(tempId, null).then(
          (situasjon) => {
            debug('HOX finner situasjon for ' + tempId, situasjon);
            this.onRowSelectionChange2(
              prevSelectedRows,
              selectedRows,
              situasjon
            );
          }
        );
      } else {
        this.onRowSelectionChange2(
          prevSelectedRows,
          selectedRows,
          null
        );
      }

    }
  }; */

  /* onRowSelectionChange2 = (prevSelectedRows, selectedRows, situasjon) => {
    const objektliste = this.props.getTiltakListe({ bruksenheter: true });
    let updatedObjektliste;

    if (selectedRows.length > prevSelectedRows.length) {
      // Adding newly selected
      const nyeObjekter = selectedRows
        .filter((row) => !this.props.getObjektLockReasons(row))
        .map((row) => {
          let objekter = objektliste
            // eslint-disable-next-line radix
            .filter(
              (objekt) =>
                parseInt(objekt.bruksenhetId) === parseInt(row.bruksenhetId)
            );

          if (!_.isEmpty(objekter)) {
            objekter.forEach((objekt) => {
              // Reset to-delete flag
              objekt.slett = false;
            });
            // Return false to skip adding `row`
            return false;
          } else {
            // Make a new `objekt`
            const template = objektliste.find(
              (objekt) => objekt.bruksenhetId == row.bruksenhetId
            );

            const objekt = {
              ..._.cloneDeep(template),
              //`row` is a parsed ES Searchkit-item (See "./LeggTilBruksenheter:PanelBruksenheter")
              ..._.cloneDeep(row),
              // NOTE! Messy stuff below! Temper with it at your own risk, cause it might be used elsewhere "as-is"
              // eslint-disable-next-line radix
              id: parseInt(objGetDeep(row, 'source.id', 0)),
              adresse: getAdresse(row.source),
              // eslint-disable-next-line radix
              bruksenhetId: parseInt(
                objGetDeep(row, 'source.skybrannData.bruksenhet.fagId', 0)
              ),
              // eslint-disable-next-line radix
              bruksenhetIdMa: parseInt(objGetDeep(row, 'source.id', 0)),
              ny: true,
              slett: false,
              situasjon: situasjon,
              tiltakstekst: '',
              varselSendt: false,
              lokasjon:
                row.source !== undefined
                  ? row.source.bygg.lokasjon
                  : row.bygg.lokasjon,
              orgId: row.id, // This is just wrong, but probably used in a way that works...
            };
            return objekt;
          }
        })
        .filter((objekt) => objekt);

      updatedObjektliste = objektliste.concat(nyeObjekter);
    } else if (selectedRows.length < prevSelectedRows.length) {
      // Removing deselected
      const removedIds = _.uniqBy(
        _.difference(prevSelectedRows, selectedRows),
        'bruksenhetId'
      ).map((row) => row.bruksenhetId);

      updatedObjektliste = objektliste
        .map((objekt) => {
          if (this.props.getObjektLockReasons(objekt)) {
            // Locked => not deselectable
            return objekt; // Still selected
          }

          if (removedIds.includes(objekt.bruksenhetId)) {
            // TODO! Not working (@Rieni)
            //  this.kart.setMarkerBlueFraArbeidslisteRemoved(objekt.id);

            if (objekt.ny) {
              // Not in DB, return false to just filter it away next
              return false;
            } else {
              // Flag for Db deletion
              objekt.slett = true;
            }
          }
          return objekt;
        })
        .filter((objekt) => objekt);
    }

    this.props.setArbeidslisteTiltakLister({
      tiltakTypeBruksenhetListe: updatedObjektliste,
    });
  }; */

  setColumns = (modus) => {
    if (modus === 'stor') {
      this.setState({ columns: this.RT_columns.getAllColumns() });
    } else {
      this.setState({ columns: this.RT_columns.getAdresseColumns() });
    }
  };

  onVisGebyr = (item) => {
    // matrikkelinfoId: item.id,
    debug('MAIDX xx', item);

    let adresse = item.source ? getAdresse(item.source) : 'ukjent adresse';
    let fagbruksenhetId = item.bruksenhetId;
    let matrikkelId = item.source.matrikkelenhet.id;
    let maid = null;

    if (!fagbruksenhetId) {
      maid = item.id;
    }

    this.dialogs.setOpenDialog('GebyrDialog', {
      adresse: adresse,
      matrikkelId: matrikkelId,
      fagbruksenhetId: fagbruksenhetId,
      maid: maid,
    });
  };

  render() {
    //  debug('render', { ...this });

    const objektliste = this.props.getTiltakListe({ bruksenheter: true });

    const valgteEnheter = this.getValgteEnheter(
      this.props.enheter,
      objektliste
    );

    return (
      <div>
        <div>
          <div>
            <div
              className='font-14'
              style={{
                height: 'calc(100vh - 274px)',
                overflow: 'auto',
                overflowX: 'hidden',
              }}>
              {/*   <span style= {{ height: '20px', fontSize: '14px', marginLeft: '16px'}}>{this.props.antallroyklopfunnet}</span> */}
              <ReactTable
                idProperty='id'
                showIndex={false}
                columns={this.state.columns}
                items={this.props.enheter}
                selection={valgteEnheter}
                selectableRows={true}
                rowFormat={this.rowFormat}
                onRowSelectionChange={(selectedRows) =>
                  this.onRowSelectionChange({
                    selectedRows,
                    prevSelectedRows: valgteEnheter,
                    fraKart: false,
                  })
                }
                isRowSelectable={this.isRowSelectable}
                onRowClick={this.rowClick}
                allSelected={false}
                toggleAll={true}
                itemsPrPage={0}
              />
            </div>
            {/* <Pagination className="pull-left" style={{ width: '150px'}} /> */}
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default PanelBruksenheter;

// Declarative stuff

const get_RT_columns = function() {
  const getAdresseColumns = () => {
    if (this.props.kobleTilReelEnhet) {
      //Kommer fra fiktiv enhetstabell
      return this.getAdresseColumnsWithMenu();
    }
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          /*  let un = '';
          if (_.has(item, 'source.bruksenhetstype')) {
            if (item.source.bruksenhetstype === 'Unummerert bruksenhet') {
              un = '*';
            }
          } */
          return (
           // (CONFIG.debugMode ? `${item.tableId}: ` : '') +
            getAdresse(item.source)
          );
        },
      },
    ];
  };

  //Fiktive enheter
  const getAdresseColumnsWithMenu = () => {
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getAdresse(item.source);
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              {this.props.kobleTilReelEnhet ? (
                <ContextMenu.Item
                  onClick={this.props.kobleTilReelEnhet.bind(this, item)}>
                  Koble til denne bruksenheten
                </ContextMenu.Item>
              ) : (
                ''
              )}
              {item.source.skybrannData ? (
                <ContextMenu.Item
                  onClick={this.onClickSituasjon.bind(this, item)}>
                  Vis situasjon
                </ContextMenu.Item>
              ) : (
                ''
              )}
              {item.source.skybrannData && !this.props.kobleTilReelEnhet ? (
                <ContextMenu.Item onClick={this.onRegistrer.bind(this, item)}>
                  Etabler fagdata
                </ContextMenu.Item>
              ) : (
                ''
              )}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  const getAllColumns = () => {
    return [
      {
        id: 'kommune',
        name: 'Kommune',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.source.kommune.kommuneNavn;
        },
      },
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getAdresse(item.source);
        },
      },
      {
        id: 'gnr',
        name: 'Eiendom',
        sortParams: 'gnr',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getMatrikkelnummer(item.source);
        },
      },

      {
        id: 'bretype',
        name: 'Bruksenhetstype',
        sortParams: 'bretype',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let bet = '';
          if (_.has(item, 'source.bruksenhetstype')) {
            bet = item.source.bruksenhetstype;
          }
          return bet;
        },
      },

      {
        id: 'nesteTiltak',
        name: 'Neste tiltakstype',
        sortParams: 'nesteTiltak',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (
            _.isEmpty(item.source.skybrannData) ||
            _.isEmpty(item.source.skybrannData.bruksenhetTiltakType)
          ) {
            return '';
          }

          let tiltakstype = _.head(
            _.orderBy(
              item.source.skybrannData.bruksenhetTiltakType,
              ['nesteDato'],
              ['asc']
            )
          );
          return tiltakstype.tiltakType;
        },
      },
      {
        id: 'antattDato',
        name: 'Neste dato',
        sortParams: 'antattDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (
            _.isEmpty(item.source.skybrannData) ||
            _.isEmpty(item.source.skybrannData.bruksenhetTiltakType)
          ) {
            return '';
          }
          let tiltakstype = _.head(
            _.orderBy(
              item.source.skybrannData.bruksenhetTiltakType,
              ['nesteDato'],
              ['asc']
            )
          );
          return tiltakstype.nesteDato
            ? moment(tiltakstype.nesteDato).format('DD.MM.YYYY')
            : '';
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              {this.props.kobleTilReelEnhet ? (
                <ContextMenu.Item
                  onClick={this.props.kobleTilReelEnhet.bind(this, item)}>
                  Koble til denne bruksenheten
                </ContextMenu.Item>
              ) : (
                ''
              )}
              {item.source.skybrannData ? (
                <ContextMenu.Item
                  onClick={this.onClickSituasjon.bind(this, item)}>
                  Vis situasjon
                </ContextMenu.Item>
              ) : (
                <ContextMenu.Item onClick={this.onRegistrer.bind(this, item)}>
                  Etabler fagdata
                </ContextMenu.Item>
              )}

              <ContextMenu.Item
                onClick={this.onVisMatrikkelinfo.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item>
              {this.state.visGebyr && (
                <ContextMenu.Item onClick={this.onVisGebyr.bind(this, item)}>
                  Vis gebyr
                </ContextMenu.Item>
              )}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  return {
    getAdresseColumns,
    getAdresseColumnsWithMenu,
    getAllColumns,
  };
};

// Declarative stuff

const modalDialogs = {
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose}
      itemId={dialogData.itemSituasjonId}
      bruksenhetIdMa={dialogData.matrikkelenhetId}
      itemAdresse={dialogData.itemsSituasjonAdresse}
      fraFiktive={false}
    />
  ),
  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <InfoDialog
      isOpen={isOpen}
      onClose={onClose}
      tittel='Fagdata allerede etablert'
      melding='Det er allerede etablert fagdata for denne enheten. Søk på nytt for å vise oppdaterte data for bruksenheten.'
    />
  ),
  MatrikkelDialog: (
    { isOpen, onClose, dialogData, owner } //visMatrikkelinfo
  ) => (
    <ModalContainer
      size='medium'
      buttonText={'Lukk'}
      tittel='Matrikkelinfo'
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}>
      <Matrikkelinfo
        id={dialogData.matrikkelinfoId}
        bygningsNr={dialogData.bygningsNr}
      />
    </ModalContainer>
  ),
  GebyrDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrDialog
        isOpen={isOpen}
        onClose={onClose}
        adresse={dialogData.adresse}
        matrikkelId={dialogData.matrikkelId}
        fagbruksenhetId={dialogData.fagbruksenhetId}
        fraEtablerFag={false}
        maid={dialogData.maid}
      />
    </NKModal>
  ),
};
