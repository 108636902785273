/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import * as _ from 'lodash';
import Editor from 'components/Editor/TestEditor';
import { debugFactory } from 'utils';
import './test.css';
import raw from './test.txt';

class TestEditorDialog extends Component {
  constructor(props) {
    super(props);
    this.editor = null;

    this.state = {
      model: '',
      logoUrl: '',
      originalMode: '',
      kontakter: [],
      dialogWidth: '800px',
      visKommentarPanel: false,
    };
  }

  componentDidMount = () => {
    this.editor = this.froalaRef;
    fetch(raw)
      .then((r) => r.text())
      .then((text) => {
        this.setEditorTekst(text);
      });
  };

  setEditorTekst = (model) => {
    this.setState({ model });
  };

  toggleDialogWidth = () => {
    /*  let el = document.getElementsByClassName('malModalSvarut');
      debug('SIDEX toggle', el); */
    let dialogWidth = this.state.dialogWidth;
    if (dialogWidth === '800px') {
      dialogWidth = '1100px';
    } else {
      dialogWidth = '800px';
    }
    //el.width = dialogWidth;
    this.props.toggleKommentar();
    this.setState({ dialogWidth });
  };

  render() {
    if (this.state.model === ''){
      return <div style={{ padding: '40px' }}>Vent...</div>;
    }
    return (
      <div style={{ width: this.state.dialogWidth }}>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{'Test sidemannskontroll'}</h4>
        </div>

        <div
          style={{ marginTop: '2px', maxHeight: '100%' }}
          className='panel panel-default'>
          <div className='panel-body'>
            <div>
              <Editor
                minHeight={'600px'}
                ref={(el) => {
                  this.editorRef = el;
                }}
                model={this.state.model}
                onChange={(val) => this.setEditorTekst(val)}
                fletteOptions={[]}
                ikkeFlettModus={true}
                bruksenhetId={''}
                toggleDialogWidth={this.toggleDialogWidth}
              />
            </div>
          </div>
        </div>

        <div className='malModalFooter'>
          <button
            className='btn btn-primary pull-right'
            onClick={() => alert('Dette er en test..')}>
            Gjør noe med teksten..
          </button>
        </div>
      </div>
    );
  }
}

export default TestEditorDialog;
