import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import _ from 'lodash';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import DialogStore from 'lib/DialogStore';
import InfoDialog from 'components/Dialogs/InformationDialog.jsx';
import { getAdresse } from 'modules/felles/AdresseHelper';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import { getContextGebyrInnstillinger  } from 'context/contextInnstillinger.js';
import { debugFactory } from 'utils';
import GebyrDialog from 'modules/BraGe/GebyrDialog.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';

const debug = debugFactory('nk:planlegging:VelgKnapp');

const modalDialogs = {
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose}
      itemId={dialogData.itemSituasjonId}
      bruksenhetIdMa={dialogData.matrikkelenhetId}
      itemAdresse={dialogData.itemsSituasjonAdresse}
      matrikkelenhetId={dialogData.matrikkelenhetId}
      fraFiktive={false}
      // eiendom={dialogData.eiendom}  //Burde kanskje ikke være med? Ifm. feil Bronnøy..
    />
  ),

  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <InfoDialog
      isOpen={isOpen}
      onClose={onClose}
      tittel='Enheten mangler fagdata'
      melding={dialogData.melding}
    />
  ),

  MatrikkelDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      buttonText={'Lukk'}
      tittel='Matrikkelinfo'
      isOpen={isOpen}
      onClose={onClose}>
      <Matrikkelinfo
        id={dialogData.matrikkelinfoId}
        bygningsNr={dialogData.bygningsNr}
      />
    </ModalContainer>
  ),
  GebyrDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrDialog
        isOpen={isOpen}
        onClose={onClose}
        adresse={dialogData.adresse}
        matrikkelId={dialogData.matrikkelId}
        fagbruksenhetId={dialogData.fagbruksenhetId}
        fraEtablerFag={false}
        maid={dialogData.maid}
      />
    </NKModal>
  ),
};

class VelgKnappControl extends Component {
  state = { visGebyr: false };

  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  onVisSituasjon = () => {
    let item = this.props.enhet;

    if (!item.lockReasons) {
      let enhetid = item.source.skybrannData.bruksenhet.fagId;
      let adresse = item.source ? getAdresse(item.source) : 'null';

      this.dialogs.setOpenDialog('EnhetDialog', {
        itemSituasjonId: enhetid,
        matrikkelenhetId: item.source.matrikkelenhet.id,
        itemsSituasjonAdresse: adresse,
        eiendom: this.props.enhet.source.matrikkelenhet,
      });
    } else {
      this.dialogs.setOpenDialog('InfoDialog', {
        melding: 'Situasjon for enheten kan ikke vises. Fagdata mangler.',
      });
    }
  };

  onVisMatrikkelinfo = () => {
    let item = this.props.enhet.source;
    debug('matr', item);
    let bygg = item.bygg ? item.bygg : item.source.bygg;
    debug('bygg', bygg);
    this.dialogs.setOpenDialog('MatrikkelDialog', {
      matrikkelinfoId: item.id,
      bygningsNr: bygg.bygningsnummer,
    });
  };

  onVisGebyr = () => {
    let item = this.props.enhet.source;
    debug('MAIDX', this.props.enhet);
    let adresse = getAdresse(item);
    let fagbruksenhetId = null;
    let maid = null;
    if (item.skybrannData) {
      fagbruksenhetId = item.skybrannData.bruksenhet.fagId;
    } else {
      maid = item.id;
    }
    let matrikkelId = item.matrikkelenhet.id;

    this.dialogs.setOpenDialog('GebyrDialog', {
      adresse: adresse,
      matrikkelId: matrikkelId,
      fagbruksenhetId: fagbruksenhetId,
      maid: maid,
    });
  };

  makeDato(dat) {
    if (dat === undefined) {
      return '';
    }
    let dato = new Date(dat);
    let y = dato.getFullYear();
    let m = dato.getMonth() + 1; //Tataaaaa!
    if (m < 10) {
      m = '0' + m;
    }
    let d = dato.getDate();
    if (d < 10) {
      d = '0' + d;
    }
    let strDato = d + '.' + m + '.' + y;
    return strDato;
  }

  velg = () => {
    let item = this.props.enhet;
    if (!item.lockReasons) {
      if (this.props.kartVisning === 'Røykløp') {
        let adresse = getAdresse(this.props.enhet.source);
        item.adresse = adresse;
      }
      debug('props:', this.props);
      debug('item: ', item);
      this.props.valgtFraKart(item);
      this.props.lukk();
    } else {
      this.dialogs.setOpenDialog('InfoDialog', {
        melding: 'Enheten kan ikke velges. Fagdata mangler.',
      });
    }
  };

  render() {
    let adresse = '...';
    let eiendom = '';
    let risiko = '';
    let tt = '';
    let ad = '';
    let ttr = '';
    let adr = '';
    let grovdato;
    if (this.props.enhet) {
      let source = this.props.enhet.source;
      if (source) {
        adresse = getAdresse(source);
        let matrikkelenhet = this.props.enhet.source.matrikkelenhet;
        eiendom =
          matrikkelenhet.gardsnummer +
          '/' +
          matrikkelenhet.bruksnummer +
          '/' +
          matrikkelenhet.seksjonsnummer;
        let skybrann = this.props.enhet.source.skybrannData;
        if (skybrann) {
          risiko = skybrann.bruksenhet.risikovurdering;

          if (skybrann.bruksenhetTiltakType !== undefined) {
            let tiltak = _.head(
              _.orderBy(skybrann.bruksenhetTiltakType, ['nesteDato'], ['asc'])
            );
            tt = tiltak.tiltakType;
            grovdato = tiltak.nesteDato;
            ad = this.makeDato(grovdato);
          }

          if (skybrann.royklopTiltakType !== undefined) {
            let tiltakR = _.head(
              _.orderBy(skybrann.royklopTiltakType, ['nesteDato'], ['asc'])
            );

            ttr = tiltakR.tiltakType;
            grovdato = tiltakR.nesteDato;
            adr = this.makeDato(grovdato);
          }
        }
      }
    }
    return (
      <div>
        <Control
          style={{ valign: 'bottom', marginBottom: '0px' }}
          position='bottomright'>
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid gray',
              width: '200px',
              height: 'auto',
            }}>
            <table style={{ marginLeft: '4px', width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <label
                      style={{ margin: '0px', padding: '4px 2px 0px 4px' }}>
                      {adresse}
                    </label>
                  </td>
                  <td>
                    <button
                      onClick={this.props.lukk}
                      title='Lukk'
                      className='pull-right'>
                      X
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <table style={{ marginLeft: '4px' }}>
              <tbody>
                <tr>
                  <td>Eiendom:</td>
                  <td>{eiendom}</td>
                </tr>
                <tr>
                  <td>Risiko:</td>
                  <td>{risiko}</td>
                </tr>
                {tt !== '' && (
                  <React.Fragment>
                    <tr>
                      <td>Neste tiltak:</td>
                      <td>{tt}</td>
                    </tr>
                    <tr>
                      <td>Antatt dato:</td>
                      <td>{ad}</td>
                    </tr>
                  </React.Fragment>
                )}
                {ttr !== '' && (
                  <React.Fragment>
                    <tr>
                      <td>Neste tiltak:</td>
                      <td>{ttr}</td>
                    </tr>
                    <tr>
                      <td>Antatt dato:</td>
                      <td>{adr}</td>
                    </tr>
                  </React.Fragment>
                )}
              </tbody>
            </table>
            <button
              type='button'
              onClick={this.velg}
              style={{ margin: '2px 5px 2px 5px', width: '186px' }}
              className='btn btn-success'>
              Velg
            </button>

            <button
              onClick={this.onVisMatrikkelinfo}
              type='button'
              style={{ margin: '2px 5px 2px 5px', width: '186px' }}
              className='btn btn-default'>
              Vis matrikkelinfo
            </button>

            <button
              onClick={this.onVisSituasjon}
              type='button'
              style={{ margin: '2px 5px 2px 5px', width: '186px' }}
              className='btn btn-default'>
              Vis situasjon
            </button>
            {this.props.visGebyr && (
              <button
                onClick={this.onVisGebyr}
                type='button'
                style={{ margin: '2px 5px 2px 5px', width: '186px' }}
                className='btn btn-default'>
                Vis gebyr
              </button>
            )}
          </div>
        </Control>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default VelgKnappControl;
