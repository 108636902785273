import React from 'react';
import arkivApi from 'api/arkivApi';
import '@norkart/react-table/dist/react-table.css';
//import { getOrganisasjonArkivInfo } from 'api/organisasjonApi';
import { getOrgArkivInfo } from 'context/contextInnstillinger.js';
let tittel = 'Test sak Brannforebygging - 43/263/0/0';
var nokkel;
let arkivInfo = null;
//let result = <div><b>-</b></div>;

class NySak extends React.Component {
  constructor(props) {
    super(props);

    //this.hentSakstyper = this.hentSakstyper.bind(this);
    this.gotSakstyper = this.gotSakstyper.bind(this);

    this.submit = this.submit.bind(this);
    this.sakLagret = this.sakLagret.bind(this);
    this.state = {
      sakstyper: [],
      defaultValue: null,
      lagretSak: null,
      sakLagret: false,
      kommunenr: '1432',
      lagrer: false,
      tittel: '',
      result: (
        <div>
          <b>Sak ikke lagret</b>
        </div>
      )
    };
    
  }

  componentDidMount = () => {
    arkivApi.getAlleSakskategorier(null, this.gotSakstyper);
    getOrgArkivInfo(this.gotOrgArkivInfo);
  }

  gotSakstyper(err, sakstyper) {
    console.log(sakstyper);
    this.setState({
      sakstyper: sakstyper,
      typeId: sakstyper[0].id,
      valgtType: sakstyper[0]
    });
  }

  gotOrgArkivInfo = (res) => {
    arkivInfo = res.data;
    this.formatTittel(arkivInfo);
  }

  handleknrChange = val => {
    this.setState({ kommunenr: val.target.value });
  };

  submit() {
    var kategori = this.state.sakstyper.find(x => x.Navn === 'BF');
    var klasseliste = null;
    if (kategori === undefined) {
      this.setState({
        result: (
          <div>
            For å kunne arkivere sak må sakskategori med navn 'BF' opprettes
          </div>
        )
      });
    } else {
      this.setState({ kategori: kategori });
      console.log(kategori);
      var klId='';
      var tittel1 = null;
      var tittel2 = null;
      // var formTittel1 = null;
      // if (arkivInfo.arkivKlasseTittel == 1) {
      //   formTittel1 = 'Gamle Hjemmevei 43 H0101';
      // }
      // if (arkivInfo.arkivKlasseTittel == 2) {
      //   formTittel1 = this.state.kommunenr;
      // }

      if (kategori.KlasseListe.length == 1) {
        klasseliste = [
          {
            Klassifikasjonssystem:
              kategori.KlasseListe[0].Klassifikasjonssystem,
            //KlasseID: '43/263/0/0',
            Rekkefolge: kategori.KlasseListe[0].Rekkefolge
          }
        ];

        // Verdi
        if (kategori.KlasseListe[0].KlasseID === '[ eiendom ]') {
          klId = '43/263/0/0';
        
        } else if (kategori.KlasseListe[0].KlasseID === '[ knr/eiendom ]') {
          klId = this.state.kommunenr + '/43/263/0/0';
          
        } else if (kategori.KlasseListe[0].KlasseID == '[ knr ]') {
          klId = this.state.kommunenr;

        } else if (kategori.KlasseListe[0].KlasseID == '' || kategori.KlasseListe[0].KlasseID == null ) {
          klId = '43/263/0/0';
          
        } else {
          klId = kategori.KlasseListe[0].KlasseID;
        }
        // Tittel/beskrivelse
        if (kategori.KlasseListe[0].Tittel === '[ eiendom ]'){
          tittel1 = '43/263/0/0';
        }
        else if (kategori.KlasseListe[0].Tittel === '[ knr/eiendom ]') {
          tittel1 = this.state.kommunenr + '/43/263/0/0';
        }
        else if (kategori.KlasseListe[0].Tittel === '[ knr ]') {
          tittel1 = this.state.kommunenr;
        }
        else if (kategori.KlasseListe[0].Tittel === '[ adresse ]') {
          tittel1 = 'Hjemveien 43 A';
        } else {
          tittel1 = kategori.KlasseListe[0].Tittel;
        }

        klasseliste[0].KlasseID = klId;
        klasseliste[0].Tittel= tittel1;
      }


      if (kategori.KlasseListe.length == 2) {
        klasseliste = [
          {
            Klassifikasjonssystem:
              kategori.KlasseListe[0].Klassifikasjonssystem,
            //KlasseID: '43/263/0/0',
            Rekkefolge: kategori.KlasseListe[0].Rekkefolge
          },
          {
            Klassifikasjonssystem:
              kategori.KlasseListe[1].Klassifikasjonssystem,
            //KlasseID: kategori.KlasseListe[1].KlasseID,
            Rekkefolge: kategori.KlasseListe[1].Rekkefolge
          }
        ];
        // verdi
        if (kategori.KlasseListe[0].KlasseID === '[ eiendom ]') {
          klId = '43/263/0/0';
          
        } else if (kategori.KlasseListe[0].KlasseID === '[ knr/eiendom ]') {
          klId = this.state.kommunenr + '/43/263/0/0';
          
        } else if (kategori.KlasseListe[0].KlasseID == '[ knr ]') {
          klId = this.state.kommunenr;
        } else if (kategori.KlasseListe[0].KlasseID == '' || kategori.KlasseListe[0].KlasseID == null ) {
          klId = '43/263/0/0';
          
        } else {
          klId = kategori.KlasseListe[0].KlasseID;
        }
        // beskrivelse
        if (kategori.KlasseListe[0].Tittel === '[ eiendom ]'){
          tittel1 = '43/263/0/0';
        }
        else if (kategori.KlasseListe[0].Tittel === '[ knr/eiendom ]') {
          tittel1 = this.state.kommunenr + '/43/263/0/0';
        }
        else if (kategori.KlasseListe[0].Tittel === '[ knr ]') {
          tittel1 = this.state.kommunenr;
        }
        else if (kategori.KlasseListe[0].Tittel === '[ adresse ]') {
          tittel1 = 'Hjemveien 43 A';
        } else {
          tittel1 = kategori.KlasseListe[0].Tittel;
        }

        klasseliste[0].KlasseID = klId;
        klasseliste[0].Tittel= tittel1;
        
        // verdi 2
        if (kategori.KlasseListe[1].KlasseID === '[ eiendom ]') {
          klId = '43/263/0/0';
          
        } else if (kategori.KlasseListe[1].KlasseID === '[ knr/eiendom ]') {
          klId = this.state.kommunenr + '/43/263/0/0';
          
        } else if (kategori.KlasseListe[1].KlasseID == '[ knr ]') {
          klId = this.state.kommunenr;
        } else {
          klId = kategori.KlasseListe[1].KlasseID;
        }
        // beskrivelse 2
        if (kategori.KlasseListe[1].Tittel === '[ eiendom ]'){
          tittel2 = '43/263/0/0';
        }
        else if (kategori.KlasseListe[1].Tittel === '[ knr/eiendom ]') {
          tittel2 = this.state.kommunenr + '/43/263/0/0';
        }
        else if (kategori.KlasseListe[1].Tittel === '[ knr ]') {
          tittel2 = this.state.kommunenr;
        }
        else if (kategori.KlasseListe[1].Tittel === '[ adresse ]') {
          tittel2 = 'Hjemveien 43 A';
        } else {
          tittel2 = kategori.KlasseListe[1].Tittel;
        }

        klasseliste[1].KlasseID = klId;
        klasseliste[1].Tittel= tittel2;
      }
      let matrNrListe = null;
      if (!arkivInfo.arkivIkkeArkiverMatrNr) {
        matrNrListe = [
          {
            kommunenr: this.state.kommunenr,
            gnr: '43',
            bnr: '263',
            fnr: '0',
            snr: '0'
          }
        ];
      }


      

      

      var nySak = {
        tittel: this.state.tittel == '' ? 'Test sak' : this.state.tittel,
        saksstatus: kategori.StatusNySaksmappe,
        referanseArkivdel: kategori.Arkivdel,
        sakMappetype: kategori.Mappetype,
        referanseNoekkel: nokkel,
        tilgangsgruppeNavn: kategori.TilgangsgruppeNavn,
        matrikkelnummerListe: matrNrListe,
        klasseListe: klasseliste
      };
      console.log(nySak);
      arkivApi.nyArkivSak(nySak, this.sakLagret);
      this.setState({ lagrer: true });
    }

    console.log(nySak);
  }

  formatTittel = (arkivInfo) => {
    console.log(arkivInfo);
    if (arkivInfo.arkivSakTittel != '' && arkivInfo.arkivSakTittel != null && arkivInfo.arkivSakTittel != undefined) {
      let result = arkivInfo.arkivSakTittel;
      if (result.includes(('#Adresse#'))) {
          let tmp = result.replace('#Adresse#', 'Hjemveien 43 A');
          result = tmp;
      }
      if (result.includes('#arstall#')) {
          let tmp = result.replace('#arstall#', '2022');
          result = tmp;
      }
      if (result.includes(('#GNR/BNR/FNR/SNR#'))) {
          let tmp = result.replace('#GNR/BNR/FNR/SNR#', '43/263/0/0');
          result = tmp;
      }
      if (result.includes(('#KNR/GNR/BNR/FNR/SNR#'))) {
        let tmp = result.replace('#KNR/GNR/BNR/FNR/SNR#', '5230/43/263/0/0');
        result = tmp;
      }
      if (result.includes(('#KNR#'))) {
        let tmp = result.replace('#KNR#', '5230');
        result = tmp;
      }
      if (result.includes(('#GNR#'))) {
        let tmp = result.replace('#GNR#', '43');
         result = tmp;
      }
      if (result.includes(('#BNR#'))) {
            let tmp = result.replace('#BNR#', '263');
              result = tmp;
      }
      if (result.includes(('#SNR#'))) {
            let tmp = result.replace('#SNR#', '0');
              result = tmp;
      }
      if (result.includes(('#FNR#'))) {
            let tmp = result.replace('#FNR#', '0');
              result = tmp;
      }
      if (result.includes(('#AVVIKTYPE#'))) {
            let tmp = result.replace('#AVVIKTYPE#', 'Mangler slukkeutstyr');
              result = tmp;
      }
      tittel = result;
    } else {
      if (arkivInfo.arkivTittelFormatSak == 0) {
        tittel = 'Test sak Brannforebygging - 43/263/0/0';
      }
      if (arkivInfo.arkivTittelFormatSak == 1) {
        tittel = 'Test sak Brannforebygging - ' + this.state.kommunenr + '/43/263/0/0';
      }
      if (arkivInfo.arkivTittelFormatSak == 2) {
        tittel = '43/263/0/0 - Test sak Brannforebygging';
      }
      if (arkivInfo.arkivTittelFormatSak == 3) {
        tittel = this.state.kommunenr + '/43/263/0/0 - Test sak Brannforebygging';
      }
      if (arkivInfo.arkivTittelFormatSak == 4) {
        tittel = 'Test sak Brannforebygging - 43/263/0/0 - Gamle Hjemmevei 43 H0101';
      }
      if (arkivInfo.arkivTittelFormatSak == 5) {
        tittel = 'Test sak Brannforebygging - ' + this.state.kommunenr + '/43/263/0/0 - Gamle Hjemmevei 43 H0101';
      }
      if (arkivInfo.arkivTittelFormatSak == 6) {
        tittel = 'Test sak Brannforebygging - Gamle Hjemmevei 43 H0101';
      }
    }
    console.log(tittel);
    this.setState({ tittel: tittel });  
  } 
  sakLagret(err, lagretSak) {
    console.log(lagretSak);
    this.setState({ lagrer: false });
    this.setState({
      lagretSak: lagretSak,
      sakLagret: true,
      result: (
        <div>
          <b>
            Sak lagret med saksnummer: {lagretSak.saksaar}/
            {lagretSak.sakssekvensnummer}
          </b>
        </div>
      )
    });
    console.log(lagretSak);
    console.log(err);
  }

  render() {
    if (this.state.lagrer) {
      return <div className='loader'></div>;
    }
    return (
      <div style={{ margin: '30px' }}>
        <h2>Test lagre sak</h2>
        <div className='form'>
          <div className='row'>
            <div className='col-sm-12'>
              Ved å trykke lagre vil det opprettes en sak i arkivet med følende
              metadata:
              <br />
              <br />
              Tittel: {this.state.tittel}
              <br />
              <br />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label>Kommune nummer</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Kommune nummer'
                  onChange={this.handleknrChange}
                />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label></label>
                <div>
                  {' '}
                  Kommune nummer som brukes i matrikkelnummeret. Hvis kommunenummer ikke oppgis brukes 1432. Noen systemer sjekker på
                  at gyldig verdi oppgis.
                </div>
              </div>
            </div>
          </div>

          <hr />
          {this.state.result}
        </div>
      </div>
    );
  }
}

export default NySak;
