/* eslint-disable default-case */
// UTILS
import _ from 'lodash';
import 'array-flat-polyfill';
import {
    objGetDeep,
    parseMultiLineString,
    getDistinctsByKeys,
    jsonpath,
} from 'utils';

export const iconTypeReset = 'soppelbotte';

// L O C K E D  R E A S O N S

const lockedReasonTexts = {
    varslet: parseMultiLineString(`
        Objektet er varslet.        
    `),
    manglerFagData: parseMultiLineString(`
        Mangler fagdata. 
        For å kunne legge objektet til på arbeidsliste:
        velg "Etabler fagdata" fra kontekst-menyen på denne raden.
    `),
    fraApi: parseMultiLineString(`
        Objektet er lagt til fra før og kan ikke fjernes.
        For å fjerne objektet fra arbeidslisten:
        velg "Fjern enhet" fra kontekst-menyen på arbeidslisten.
    `),
    erTilordnetTiltak: parseMultiLineString(`
        Enheten har allerede fått tilordnet tiltak og kan ikke fjernes. 
        For å gjøre endringer på valgte tiltak:
        velg "Endre tiltak" fra kontekst-menyen på arbeidslisten.
    `)
}

export const makeLockReasonsKey = ({ bruksenhetId, royklopId }) => {
    return `${parseInt(bruksenhetId)}-${parseInt(royklopId)}`;
}

export const makeLockReasonsTooltip = (lockReasons) => {

    const texts = Object.entries(lockReasons)
        .reduce((acc, [reasonKey, isLocked]) => {
            if (isLocked && lockedReasonTexts[reasonKey]) {
                acc.push(lockedReasonTexts[reasonKey])
            };
            return acc;
        }, [])

    return texts.join(' ');
}

// H I T S

export const parseHits = (hits, { debug = () => { } } = {}) => {
 //   debug('parseHits', { hits });

    return hits.map((hit) => {
        const bruksenhetId = objGetDeep(hit, '_source.skybrannData.bruksenhet.fagId');

        const parsedHit = {
            id: hit._source.id,
            source: hit._source,
            // Flemming
            inner_hits: hit.inner_hits,
            // `parseInt` to get `undefined` as `NaN` (Just to get the int/string issue consistent to int...)
            bruksenhetId: parseInt(bruksenhetId),
            tableId: bruksenhetId
        };

        return parsedHit;
    });
};

const royklopPostFilterParsedHit = (parsedHit, { debug }) => {
    /*
    Remove unwanted deep data by "Post-filtering" away parts of the hit based on the data in `inner_hits`.        
    - NOTE! Assumes that the data we get in `inner_hits` is *only* used to filter `royklop` by `id`...
    - Search "src/components/Search/Filters.jsx" for "inner_hits"; the result turns into some json-path like this:
        - "hit.inner_hits.<post_filter_key>.hits.hits[<n>].fields.'skybrannData.royklop.id.keyword'[<values>]", where `values`
          is an object with keys correpoonding to the `inner_hits.name` set in "Filters.jsx"...
    */
    const { skybrannData } = parsedHit.source;

    // Parse "inner_hit-fields" to `filters`
    const filters = jsonpath.nodes(parsedHit, '$.inner_hits.*.hits..fields')
        .reduce((acc, node) => {
            const filterName = node.path[2].replace('inner_hit_', '');
            const filterValue = Object.values(node.value);
            acc[filterName] = (acc[filterName] || []).concat(filterValue).flatMap(x => x);
            return acc;
        }, {});

   // debug('royklopPostFilterParsedHitX', parsedHit.tableId, { filters, skybrannData });

    // Apply filters to `parsedHit` and find which `royklop` that should *not* be filtered away
    const allowedRoyklopIds = Object.entries(filters)
        .flatMap(([key, value]) => {
            switch (key) {
                case 'iildstedIBruk':
                    /*
                    NOTE! `value` contains `ildsted`-ids!!
                    Check the active-status of `ildsteder` connected to a `royklop`:
                    - "ildstedIBruk: JA" => At least one should be active, `some(ildsted.iBruk == true)`
                    - "ildstedIBruk: NEI" => None should be active, `!some(ildsted.iBruk == true)`
                    */

                    // Determine if filter is to keep active or inactive `royklop` by checking `ildsted.iBruk` on first value-id-match
                   const keepActiveRoyklop = skybrannData.ildsteder.find(ildsted => value.includes(ildsted.id)).iBruk; 
                 

                    return skybrannData.royklop
                        .filter(royklop => {
                            const atLeastOneIldstedIsActive = skybrannData.ildsteder
                                .some(ildsted => royklop.id === ildsted.royklopId && ildsted.iBruk);  

                            return keepActiveRoyklop
                                ? atLeastOneIldstedIsActive // "ildstedIBruk": "JA"
                                : !atLeastOneIldstedIsActive; // ildstedIBruk: NEI"
                        })
                        .map(royklop => royklop.id);

                default:
                    // `value` contains `royklop`-ids, and refers to allowed ones!
                    return value;
            }
        });

  //  debug('royklopPostFilterParsedHit', { filters, allowedRoyklopIds });

    if (allowedRoyklopIds.length == skybrannData.royklop.length) {
        // Nothing to do
        return parsedHit;
    }
    else {
        // Filter royklop
        const filteredRoyklop = skybrannData.royklop
            .filter(royklop => allowedRoyklopIds.includes(royklop.id))

      //  debug('royklopPostFilterParsedHit:done', { filteredRoyklop });

        // TODO! Propably no need to clone here, just wasted cpu-cycles, so
        // - Just keep it until this works 100% 
        const filteredHit = _.cloneDeep(parsedHit)
        filteredHit.source.skybrannData.royklop = filteredRoyklop;

        return filteredHit;
    }
};

export const parsedHits2RoyklopHits = (parsedHits, { debug = () => { } } = {}) => {

  //  debug('parsedHits2RoyklopHits', { parsedHits });

    let royklopliste = parsedHits
        .filter((parsedHit) => !!objGetDeep(parsedHit, 'source.skybrannData.royklop'))
        .map(parsedHit => parsedHit.inner_hits ? royklopPostFilterParsedHit(parsedHit, { debug }) : parsedHit)
        .flatMap(parsedHit => {
            return parsedHit.source.skybrannData.royklop.map(royklop => {
                const royklopHit = {
                    // From parsedHit`
                    id: parseInt(royklop.id),
                    tableId: `${parsedHit.bruksenhetId}-${royklop.id}`,
                    matrikkelId: parsedHit.id,
                    kommuneNavn: parsedHit.source.kommune.kommuneNavn,
                    adresse: parsedHit.source.adresse,
                    etasjeplankode: parsedHit.source.etasjeplankode,
                    etasjenummer: parsedHit.source.etasjenummer,
                    lopenummer: parsedHit.source.lopenummer,
                    bygg: parsedHit.source.bygg,
                    royklopTiltakType: parsedHit.source.skybrannData.royklopTiltakType,
                    // From `royklop` ("BruksenhetTiltak")
                    type: royklop.type,
                    plassering: royklop.plassering,
                    // Flemming
                    source: parsedHit.source,
                    royklopId: parseInt(royklop.id),
                    bruksenhetId: parsedHit.bruksenhetId,
                    ildsteder: parsedHit.source.skybrannData.ildsteder
                };

                return royklopHit;
            });
        });

    return royklopliste;
};

// T I L T A K S T Y P E

const getArbeidslistObjektSortIdentity = ({ adresse }) => {
    let identity = '';
    adresse= adresse.replace('-', ' ');
    if (adresse !== null) {
        const matches = adresse.match(/^(\D+) (\d*)(\w*) (\w{1})([0-9]{4})/i);
        if (matches) {
            let [ adresse, gatenavn, husnummer, bokstav, etasjeplankode, etasjenummer] = matches;
            husnummer = husnummer.padStart(5, '0').slice(-5);
            bokstav = bokstav || '-';
            switch (etasjeplankode) {
                case 'K': etasjeplankode = '0'; break;
                case 'U': etasjeplankode = '1'; break;
                case 'H': etasjeplankode = '2'; break;
                case 'L': etasjeplankode = '3'; break;
            };
            identity = `${gatenavn}:${husnummer}:${bokstav}:${etasjeplankode}:${etasjenummer}`;
        }
        else {
            console.error(`getArbeidslistObjektSortIdentity: regexp failed for address '${adresse}'`);
        }
    } else {
        console.error('getArbeidslistObjektSortIdentity: regexp failed, adresse er null');
    }
    return identity;
};



const sortArbeidslisteObjekter = (objektliste, { debug }) => {

    objektliste = _.sortBy(objektliste, getArbeidslistObjektSortIdentity);

    // Repostition newly added objekter
    objektliste = objektliste.reduce(([head, tail], item) => {
        (item.ny)
            ? head.push(item)
            : tail.push(item);
        return [head, tail];
    }, [[], []]).flatMap(arr => arr);

    return objektliste;
}

export const tiltakTypeBruksenhetListe2ArbeidslisteBruksenhetListe = (tiltakListe, {
    uniqBy = 'bruksenhetId',
    debug = () => { },
    bypass = false
} = {}) => {

    if (bypass) {
        // Mainly for debugging, just return as is 
        return tiltakListe;
    }

    let objektliste = tiltakListe
        .filter(item => !(item.slett === true))
        .map(item => {
            item.tableId = item.tableId || item.bruksenhetId;
            debug('tiltakTypeBruksenhetListe2ArbeidslisteBruksenhetListe:map', { item });
            return item;
        });

    if (uniqBy) {
        objektliste = getDistinctsByKeys(objektliste, uniqBy);
    }

    return sortArbeidslisteObjekter(objektliste, { debug: (...args) => debug('bruksenheter', ...args) });
};

export const tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe = (tiltakListe, {
    uniqBy = 'royklopId',
    debug = () => { },
    bypass = false
} = {}) => {

    if (bypass) {
        // Mainly for debugging, just return as is 
        return tiltakListe;
    }

    let objektliste = tiltakListe
        .filter(item => !(item.slett === true))
        .map(item => {
            if (item.adresse){
                if (item.adresse.includes('undefined')){
                    item.adresse= item.adresse.replace('undefined', '');
                }
            } else {
                item.adresse = '';
            }
            item.tableId = item.tableId || `${item.bruksenhetId}-${item.royklopId}`;
         //   debug('tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe:map', { item });
            return item;
        })
        .reduce((acc, item) => {
            // Filter away "delte royklop" as they represent duplicate rows
            if (!item.bruksenhetIdDelerLop){
                item.bruksenhetIdDelerLop = '';
            }
            const bruksenhetIdDelerLop = (item.bruksenhetIdDelerLop || '')
                .split(',')
                .map(id => parseInt(id))
                .filter(id => id);

            const isAlreadyAdded = !!acc.find(existingItem => bruksenhetIdDelerLop.includes(existingItem.bruksenhetId));
          //  debug('tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe:bruksenhetIdDelerLop', { item, bruksenhetIdDelerLop, isAlreadyAdded });

            if (!isAlreadyAdded) {
                acc.push(item);
            }
            return acc;

        }, []);

    if (uniqBy) {
        objektliste = getDistinctsByKeys(objektliste, uniqBy);
    }

    

    return sortArbeidslisteObjekter(objektliste, { debug: (...args) => debug('royklop', ...args) });
};