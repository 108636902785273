import React from 'react';
import { withRouter } from 'lib/enhancers';
import ContextMenu from 'components/ContextMenu';
import arkivApi from 'api/arkivApi';
import { getOrganisasjonArkivSkjerming } from 'api/organisasjonApi';
import _ from 'lodash';
import {
  uuid
} from 'utils' ;

let lukk = ['Nei', 'Ja'];
let rekkefolge= ['', '1', '2', '3'];
let dummyArkivdel = {
  kodeverdi: 'Ikke i bruk',
  kodebeskrivelse: 'Ikke i bruk',
  erGyldig: false
};
let klasseVerdi = ['', '[ eiendom ]', '[ knr/eiendom ]', '[ knr ]', '[ adresse ]'];
const beskrivelseVerdi = [
  '',
  '[ eiendom ]',
  '[ knr/eiendom ]',
  '[ knr ]',
  '[ adresse ]',
];

class EndreSakstype extends React.Component {
  constructor(props) {
    super(props);
    this.gotArkivdel = this.gotArkivdel.bind(this);
    this.gotJournalenhet = this.gotJournalenhet.bind(this);
    this.gotMappetype = this.gotMappetype.bind(this);
    this.gotKlasser = this.gotKlasser.bind(this);
    this.gotStatuser = this.gotStatuser.bind(this);
    this.sakstypeLagret = this.sakstypeLagret.bind(this);
    this.submit = this.submit.bind(this);
    this.nyKategori = this.nyKategori.bind(this);
    this.endreKategori = this.endreKategori.bind(this);
    this.slettKategori = this.slettKategori.bind(this);
    this.arkivDelChange = this.arkivDelChange.bind(this);
    this.mappetypeChange = this.mappetypeChange.bind(this);
    this.klasseChange = this.klasseChange.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.statusAvsluttChange = this.statusAvsluttChange.bind(this);
    this.handleEkspChange = this.handleEkspChange.bind(this);
    this.gotJPStatuser = this.gotJPStatuser.bind(this);
    this.jpstatusChange = this.jpstatusChange.bind(this);

    this.state = {
      sakstyper: [],
      ferdigLastet: false,
      kundeId: this.props.location.state.kundeId,
      valgt: this.props.location.state.valgt,
      klasseverdi1manuell: false,
      klasseverdi2manuell: false
    };
    this.hentArkivdeler();
    
  }
  
  componentDidMount() {
    console.log(this.state.valgt);
    this.setState({
      klasse: this.state.valgt.KlasseListe != null ? this.state.valgt.KlasseListe[0].Klassifikasjonssystem : null,
      klasse1verdi: this.state.valgt.KlasseListe != null ? this.state.valgt.KlasseListe[0].KlasseID : null,
      klasse1beskrivelse: this.state.valgt.KlasseListe != null ? this.state.valgt.KlasseListe[0].Tittel : null,
      klasse2: this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 1 ? this.state.valgt.KlasseListe[1].Klassifikasjonssystem : '',
      klasse2verdi: this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 1 ? this.state.valgt.KlasseListe[1].KlasseID : null,
      klasse2beskrivelse: this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 1 ? this.state.valgt.KlasseListe[1].Tittel : null,
      klasse3: this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 2 ? this.state.valgt.KlasseListe[2].Klassifikasjonssystem : '',
      klasse3verdi: this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 2 ? this.state.valgt.KlasseListe[2].KlasseID : null,
      klasse3beskrivelse: this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 2 ? this.state.valgt.KlasseListe[2].Tittel : null,
      manuell: this.state.valgt.ManuellKlassering == null || this.state.valgt.ManuellKlassering == undefined ? false : this.state.valgt.ManuellKlassering,
      LukkSak: this.state.valgt.LukkSak == undefined || this.state.valgt.LukkSak == null || this.state.valgt.LukkSak == true ? 'Ja' : 'Nei',
      tilgangsgruppesak: this.state.valgt.TilgangsgruppeNavn == undefined || this.state.valgt.TilgangsgruppeNavn == null ? null : this.state.valgt.TilgangsgruppeNavn,
      tilgangsgruppejp: null,
      hjemmel: this.state.valgt.Skjermingshjemmel,
      restriksjon: this.state.valgt.Tilgangsrestriksjon,
      avsluttStatus: this.state.valgt.StatusAvsluttSaksmappe == null ? '' : this.state.valgt.StatusAvsluttSaksmappe,
      mappeType: this.state.valgt.mappetype,
      status: this.state.valgt.StatusNySaksmappe
    });
    if (this.state.valgt.KlasseListe != null) {
      this.setState({
      Rekkefolge: this.state.valgt.KlasseListe[0].Rekkefolge == null ? '' : this.state.valgt.KlasseListe[0].Rekkefolge,
      Rekkefolge2: this.state.valgt.KlasseListe.length > 1 && this.state.valgt.KlasseListe[1].Rekkefolge != null ? this.state.valgt.KlasseListe[1].Rekkefolge : '',
      Rekkefolge3: this.state.valgt.KlasseListe.length > 2 && this.state.valgt.KlasseListe[2].Rekkefolge != null ? this.state.valgt.KlasseListe[2].Rekkefolge : ''
    });
    }
    if (this.state.valgt.JournalpostKategori != null) {
      if (this.state.valgt.JournalpostKategori[0].TilgangsgruppeNavn != undefined && this.state.valgt.JournalpostKategori[0].TilgangsgruppeNavn != null) {
        this.setState({ tilgangsgruppejp: this.state.valgt.JournalpostKategori[0].TilgangsgruppeNavn });
      }
    }
    if (this.state.valgt.KlasseListe != null) {
      let verdi = this.state.valgt.KlasseListe[0].KlasseID;
      if (verdi != null && verdi != '' && verdi != '[ eiendom ]' && verdi != '[ knr/eiendom ]' && verdi != '[ knr ]' && verdi != '[ adresse ]') {
        this.setState({ klasseverdi1manuell: true });
      }
      let beskr = this.state.valgt.KlasseListe[0].Tittel;
      if (beskr != null && beskr != '' && beskr != '[ eiendom ]' && beskr != '[ knr/eiendom ]' && beskr != '[ knr ]' && beskr != '[ adresse ]') {
        this.setState({ klassebeskrivelse1manuell: true });
      }
    }
    if (this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 1) {
      let verdi = this.state.valgt.KlasseListe[1].KlasseID;
      if (verdi != null && verdi != '' && verdi != '[ eiendom ]' && verdi != '[ knr/eiendom ]' && verdi != '[ knr ]' && verdi != '[ adresse ]') {
        this.setState({ klasseverdi2manuell: true });
      }
      let beskr = this.state.valgt.KlasseListe[1].Tittel;
      if (beskr != null && beskr != '' && beskr != '[ eiendom ]' && beskr != '[ knr/eiendom ]' && beskr != '[ knr ]' && beskr != '[ adresse ]') {
        this.setState({ klassebeskrivelse2manuell: true });
      }
    }
    if (this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 2) {
      let verdi = this.state.valgt.KlasseListe[2].KlasseID;
      if (verdi != null && verdi != '' && verdi != '[ eiendom ]' && verdi != '[ knr/eiendom ]' && verdi != '[ knr ]' && verdi != '[ adresse ]') {
        this.setState({ klasseverdi3manuell: true });
      }
      let beskr = this.state.valgt.KlasseListe[2].Tittel;
      if (beskr != null && beskr != '' && beskr != '[ eiendom ]' && beskr != '[ knr/eiendom ]' && beskr != '[ knr ]' && beskr != '[ adresse ]') {
        this.setState({ klassebeskrivelse3manuell: true });
      }
    }
  }

  hentArkivdeler() {
    console.log(this.state.valgt);
    getOrganisasjonArkivSkjerming(this.gotOrg);
    arkivApi.getKodeliste('Arkivdel', uuid(), this.gotArkivdel);
    arkivApi.getKodeliste('Journalenhet', uuid(), this.gotJournalenhet);
    arkivApi.getKodeliste('Mappetype', uuid(), this.gotMappetype);
    arkivApi.getKodeliste('Klassifikasjonssystem', uuid(), this.gotKlasser);
    arkivApi.getKodeliste('Saksstatus', uuid(), this.gotStatuser);
    arkivApi.getKodeliste('Journalstatus', uuid(), this.gotJPStatuser);
    arkivApi.getKodeliste('SkjermingsHjemmel', uuid(), this.gotHjemmler);
    arkivApi.getKodeliste('Tilgangsrestriksjon', uuid(), this.gotRestriksjoner);
  }

  gotOrg = (err, arkivSkjerming) => {
    this.setState({ skjerming: arkivSkjerming });
  };

  gotHjemmler = (err, hjemmler) => {
    var tmp = hjemmler;
    var k = {
      kodeverdi: '',
      kodebeskrivelse: '',
      erGyldig: false
    };
    tmp.unshift(k);
    this.setState({
      hjemmler: tmp
    });
  }

  gotRestriksjoner = (err, restriksjoner) => {
    var tmp = restriksjoner;
    var k = {
      kodeverdi: '',
      kodebeskrivelse: '',
      erGyldig: false
    };
    tmp.unshift(k);
    this.setState({
      restriksjoner: tmp
    });
  }


  gotArkivdel(err, arkivDeler) {
    let a = arkivDeler;
    a.push(dummyArkivdel);
    this.setState({
      arkivDeler: a
    });
  }
  gotJournalenhet(err, journalenheter) {
    this.setState({
      journalenheter: journalenheter
    });
  }
  gotMappetype(err, mappetyper) {
    this.setState({
      mappetyper: mappetyper
    });
  }
  gotKlasser(err, klasser) {
    var tmp = klasser;
    var k = {
      kodeverdi: '',
      kodebeskrivelse: '',
      erGyldig: false
    };
    tmp.unshift(k);
    this.setState({
      klasser: tmp
    });
  }
  gotStatuser(err, statuser) {
    let s = statuser;
    let dummy = {
      kodeverdi: '',
      kodebeskrivelse: '',
      erGyldig: false
    };
    s.unshift(dummy);
    this.setState({
      statuser: s
    });
  }
  gotJPStatuser(err, statuser) {
    this.setState({
      jpstatuser: statuser
    });
  }

  arkivDelChange(val) {
    let valgtChange = { ...this.state.valgt };
    valgtChange.arkivdel = val.target.value;
    this.setState({ valgt: valgtChange });
  }

  mappetypeChange(val) {
    this.setState({ mappeType: val.target.value });
  }
  klasseChange(val) {
    this.setState({ klasse: val.target.value });
  }
  klasse2Change = (val) => {
    this.setState({ klasse2: val.target.value });
  }
  klasse3Change = (val) => {
    this.setState({ klasse3: val.target.value });
  }
  statusChange(val) {
    this.setState({ status: val.target.value });
  }
  statusAvsluttChange(val) {
    this.setState({ avsluttStatus: val.target.value });
  }
  
  jpstatusChange(val) {
    if (this.state.valgt.JournalpostKategori === null) {
      // var jpkat = [{
      //   'NyJournalpostArkiveringStatus': val.target.value,
      // }];
    }
    this.state.valgt.JournalpostKategori[0].NyJournalpostArkiveringStatus =
      val.target.value;
  }
  handleEkspChange(val) {
    this.state.valgt.JournalpostKategori[0].EkspederFoerEndeligStatus =
      val.target.value;
  }
  manuellKlasseTxtChange = (val) => {
    this.setState({ klasse: val.target.value });
  }
  manuellKlasse2TxtChange = (val) => {
    this.setState({ klasse2: val.target.value });
  }
  lukkChange = val => {
    this.setState( { LukkSak: val.target.value });
  }
  rekkefolgeChange = val => {
    this.setState({ Rekkefolge: val.target.value }); 
  }
  rekkefolge2Change = val => {
    this.setState({ Rekkefolge2: val.target.value }); 
  }
  rekkefolge3Change = val => {
    this.setState({ Rekkefolge3: val.target.value }); 
  }
  klasse3verdiChange = val => {
    this.setState({ klasse3verdi: val.target.value }); 
  }
  klasse2verdiChange = val => {
    this.setState({ klasse2verdi: val.target.value }); 
  }
  klasse1verdiChange = val => {
    this.setState({ klasse1verdi: val.target.value }); 
  }
  klasse3beskrivelseChange = val => {
    this.setState({ klasse3beskrivelse: val.target.value }); 
  }
  klasse2beskrivelseChange = val => {
    this.setState({ klasse2beskrivelse: val.target.value }); 
  }
  klasse1beskrivelseChange = val => {
    this.setState({ klasse1beskrivelse: val.target.value }); 
  }
  tilgangsgruppesakChange = val => {
    this.setState({ tilgangsgruppesak: val.target.value });
  }
  tilgangsgruppejpChange = val => {
    this.setState({ tilgangsgruppejp: val.target.value });
  }
  hjemmlerChange = val => {
    this.setState({ hjemmel: val.target.value });
  }
  restriksjonerChange = (val) => {
    this.setState({ restriksjon: val.target.value });
  }

  sakstypeLagret(err, nySakstype) {
    console.log('Sakstype lagret');
    this.props.router.push({
      pathname: 'innstillinger/SakArkiv/Sakstyper'
    });
  }
  nyKategori() {}
  endreKategori() {}
  slettKategori() {}
  submit() {
    console.log(this.state.klasse2);
    console.log(this.state.Rekkefolge);
    var type = this.state.valgt;
    type.ManuellKlassering = this.state.manuell;
    type.KlasseListe = [
      {
        Klassifikasjonssystem: this.state.klasse,
        KlasseID: this.state.klass1verdi == '' ? null : this.state.klasse1verdi,
        Rekkefolge: this.state.Rekkefolge == '' ? null : this.state.Rekkefolge,
        Tittel: this.state.klasse1beskrivelse == '' ? null : this.state.klasse1beskrivelse
      }
    ];
    if (this.state.klasse2 != '' && this.state.klasse2 != null) {
      var k2 = {
        Klassifikasjonssystem: this.state.klasse2,
        KlasseID: this.state.klasse2verdi,
        Rekkefolge: this.state.Rekkefolge2 == '' ? null : this.state.Rekkefolge2,
        Tittel: this.state.klasse2beskrivelse == '' ? null : this.state.klasse2beskrivelse
      };
      type.KlasseListe.push(k2);
    }
    if (this.state.klasse3 != '' && this.state.klasse2 != null) {
      var k3 = {
        Klassifikasjonssystem: this.state.klasse3,
        KlasseID: this.state.klasse3verdi,
        Rekkefolge: this.state.Rekkefolge3 == '' ? null : this.state.Rekkefolge3,
        Tittel: this.state.klasse3beskrivelse == '' ? null : this.state.klasse3beskrivelse
      };
      type.KlasseListe.push(k3);
    }
    type.LukkSak = this.state.LukkSak == 'Ja' ? true : false;
    type.TilgangsgruppeNavn = this.state.tilgangsgruppesak;
    if (this.state.tilgangsgruppejp != null) {
      type.JournalpostKategori[0].TilgangsgruppeNavn = this.state.tilgangsgruppejp;
    }
    type.Tilgangsrestriksjon = this.state.restriksjon;
    type.Skjermingshjemmel = this.state.hjemmel;
    type.StatusAvsluttSaksmappe = this.state.avsluttStatus == '' ? null : this.state.avsluttStatus;
    type.mappetype = this.state.mappeType;
    type.StatusNySaksmappe = this.state.status;
    console.log(type);
    arkivApi.endreSakskategori('requestId', type, this.sakstypeLagret);
  }
  
  render() {
    if (
      !this.state.arkivDeler ||
      !this.state.journalenheter ||
      !this.state.mappetyper ||
      !this.state.klasser ||
      !this.state.statuser ||
      !this.state.jpstatuser ||
      !this.state.hjemmler ||
      !this.state.restriksjoner
    ) {
      return <div className='loader' />;
    }
    

    return (
      <div>
        <h2>Endre sakstype</h2>
        <div>
          <form>
          <div
                className="col-sm-6"
                style={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'lightgray',
                }}
              >
                <br/>
                <div className="form-group">
                  <label>Sakstype navn</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={this.state.valgt.Navn}
                    readOnly
                    ref="nameInput"
                  />
                </div>
                <div className="form-group ">
                  <label>Arkivdel</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.arkivDelChange}
                    defaultValue={this.state.valgt.Arkivdel}
                  >
                    {_.map(this.state.arkivDeler, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Mappetype</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.mappetypeChange}
                    defaultValue={this.state.valgt.Mappetype}
                  >
                    {_.map(this.state.mappetyper, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Status ny sak</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.statusChange}
                    defaultValue={this.state.valgt.StatusNySaksmappe}
                  >
                    {_.map(this.state.statuser, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Status avslutt sak</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.statusAvsluttChange}
                    defaultValue={this.state.valgt.StatusAvsluttSaksmappe == null ? this.state.statuser[0] : this.state.valgt.StatusAvsluttSaksmappe}
                  >
                    {_.map(this.state.statuser, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Lukk sak når siste avvik lukkes</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.lukkChange}
                    defaultValue={this.state.LukkSak}
                  >
                    {_.map(lukk, function(o, i) {
                      return (
                        <option key={i} value={o}>
                          {o}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Tilgangsgruppenavn (sak)</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={this.state.tilgangsgruppesak}
                    onChange={this.tilgangsgruppesakChange}
                  />
                </div>
                {this.state.skjerming ? (
                  <div>
                    <div className="form-group">
                      <label>Skjermingshjemmel</label>
                      <select
                        className="form-control"
                        id="hjemmler"
                        onChange={this.hjemmlerChange}
                        defaultValue={this.state.hjemmel}
                      >
                        {_.map(this.state.hjemmler, function(o, i) {
                          return (
                            <option key={i} value={o.kodeverdi}>
                              {o.kodeverdi} - {o.kodebeskrivelse}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
                {this.state.skjerming ? (
                  <div>
                    <div className="form-group">
                      <label>Tilgangsrestriksjon</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.restriksjonerChange}
                        defaultValue={this.state.restriksjon}
                      >
                        {_.map(this.state.restriksjoner, function(o, i) {
                          return (
                            <option key={i} value={o.kodeverdi}>
                              {o.kodeverdi} - {o.kodebeskrivelse}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>

              {/* Høyre side */}
              <div
                className="col-sm-6"
                style={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'lightgray',
                }}
              >
                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 1</label>
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasseChange}
                            defaultValue={
                              this.state.klasse
                            }
                          >
                            {_.map(this.state.klasser, function(o, i) {
                              return (
                                <option key={i} value={o.kodeverdi}>
                                  {o.kodeverdi} - {o.kodebeskrivelse}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 1 rekkefølge</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.rekkefolgeChange}
                        defaultValue={this.state.Rekkefolge}>
                      
                        {_.map(rekkefolge, function(o, i) {
                          return (
                            <option key={i} value={o}>
                              {o}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 1 verdi</label>
                      {this.state.klasseverdi1manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse1verdi}
                            onChange={this.klasse1verdiChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse1verdiChange}
                            defaultValue={this.state.valgt.KlasseListe != null ? this.state.valgt.KlasseListe[0].KlasseID : klasseVerdi[0]}>
                          >
                            {_.map(klasseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Legg inn egen klasse verdi</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klasseverdi1manuell}
                        onChange={(e) => {
                          this.setState({
                            klasseverdi1manuell: !this.state
                              .klasseverdi1manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 1 beskrivelse</label>
                      {this.state.klassebeskrivelse1manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse1beskrivelse}
                            onChange={this.klasse1beskrivelseChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse1beskrivelseChange}
                            defaultValue={this.state.klasse1beskrivelse}
                          >
                            {_.map(beskrivelseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bruk egen beskrivelse</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klassebeskrivelse1manuell}
                        onChange={(e) => {
                          this.setState({
                            klassebeskrivelse1manuell: !this.state
                              .klassebeskrivelse1manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 2</label>
                      
                        <div>
                          <select
                            className="form-control"
                            onChange={this.klasse2Change}
                            defaultValue={this.state.klasse2}
                          >
                            {_.map(this.state.klasser, function(o, i) {
                              return (
                                <option key={i} value={o.kodeverdi}>
                                  {o.kodeverdi} - {o.kodebeskrivelse}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 2 rekkefølge</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.rekkefolge2Change}
                        defaultValue={this.state.Rekkefolge2}
                      >
                        {_.map(rekkefolge, function(o, i) {
                          return (
                            <option key={i} value={o}>
                              {o}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 2 verdi</label>
                      {this.state.klasseverdi2manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse2verdi}
                            onChange={this.klasse2verdiChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse2verdiChange}
                            defaultValue={this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 1 ? this.state.valgt.KlasseListe[1].KlasseID : klasseVerdi[0]}
                          >
                            {_.map(klasseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Legg inn egen klasse verdi</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klasseverdi2manuell}
                        onChange={(e) => {
                          this.setState({
                            klasseverdi2manuell: !this.state
                              .klasseverdi2manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 2 beskrivelse</label>
                      {this.state.klassebeskrivelse2manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse2beskrivelse}
                            onChange={this.klasse2beskrivelseChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse2beskrivelseChange}
                            defaultValue={this.state.klasse2beskrivelse}
                          >
                            {_.map(beskrivelseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bruk egen beskrivelse</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klassebeskrivelse2manuell}
                        onChange={(e) => {
                          this.setState({
                            klassebeskrivelse2manuell: !this.state
                              .klassebeskrivelse2manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 3</label>

                      <div>
                        <select
                          className="form-control"
                          onChange={this.klasse3Change}
                          defaultValue={this.state.klasse3}
                        >
                          {_.map(this.state.klasser, function(o, i) {
                            return (
                              <option key={i} value={o.kodeverdi}>
                                {o.kodeverdi} - {o.kodebeskrivelse}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 3 verdi</label>
                      {this.state.klasseverdi3manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse3verdi}
                            onChange={this.klasse3verdiChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse3verdiChange}
                            defaultValue={this.state.valgt.KlasseListe != null && this.state.valgt.KlasseListe.length > 2 ? this.state.valgt.KlasseListe[2].KlasseID : klasseVerdi[0]}
                          >
                            {_.map(klasseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Legg inn egen klasse verdi</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klasseverdi3manuell}
                        onChange={(e) => {
                          this.setState({
                            klasseverdi3manuell: !this.state
                              .klasseverdi3manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 3 rekkefølge</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.rekkefolge3Change}
                        defaultValue={this.state.Rekkefolge3}
                      >
                        {_.map(rekkefolge, function(o, i) {
                          return (
                            <option key={i} value={o}>
                              {o}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 3 beskrivelse</label>
                      {this.state.klassebeskrivelse3manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse3beskrivelse}
                            onChange={this.klasse3beskrivelseChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse3beskrivelseChange}
                            defaultValue={this.state.klasse3beskrivelse}
                          >
                            {_.map(beskrivelseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bruk egen beskrivelse</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klassebeskrivelse3manuell}
                        onChange={(e) => {
                          this.setState({
                            klassebeskrivelse3manuell: !this.state
                              .klassebeskrivelse3manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            
              
            
            <hr />
            <h3>Journalpostkategori</h3>
            <div className="row" style={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'lightgray',
                }}>
              <p/>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label>Journalpostkategori navn</label>
                <input
                  type='text'
                  className='form-control'
                  defaultValue={
                    this.state.valgt.JournalpostKategori !== null
                      ? this.state.valgt.JournalpostKategori[0].Navn
                      : null
                  }
                  readOnly
                  ref='jpnameInput'
                />
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='form-group '>
                <label>Status ny journalpost</label>
                <select
                  className='form-control'
                  id='type'
                  onChange={this.jpstatusChange}
                  defaultValue={
                    this.state.valgt.JournalpostKategori !== null
                      ? this.state.valgt.JournalpostKategori[0]
                          .NyJournalpostArkiveringStatus
                      : null
                  }>
                  {_.map(this.state.jpstatuser, function(o, i) {
                    return (
                      <option key={i} value={o.kodeverdi}>
                        {o.kodeverdi} - {o.kodebeskrivelse}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
              
            <div className='col-sm-6'>
              <div className='form-group'>
                <label>Ekspeder før endelig status</label>
                <div>
                  {' '}
                  (Settes til 'true' KUN når arkivsystem er ESA og man VET at
                  denne skal brukes. Default: 'false. Bakgrunn: ESA kan
                  konfigureres til å kreve at ekspedertDato må være satt før
                  journalpoststatus kan settes til 'J - Journalført'. )
                </div>
                <input
                  type='text'
                  className='form-control'
                  defaultValue='false'
                  onChange={this.handleEkspChange}
                  ref='jpekspInput'
                />
              </div>
            </div>

            <div className='col-sm-6'>
              <div className='form-group'>
                <label>
                  Bruk alternativ input for klassering(true eller false)
                </label>
                <div>
                  <p/>
                  {' '}
                  (Pga feil i P360 er det mulighet for å legge inn klassering
                  manuelt da valgene som vises er feil. Vil bli fjernet når P369
                  har rettet sin feil. )
                  <p/>
                </div>
                <input
                  className='checkbox30'
                  type='checkbox'
                  checked={this.state.manuell}
                  onChange={e => {
                    this.setState({
                      manuell: !this.state.manuell
                      });
                  }}
                />
              </div>
            </div>
            <div className='col-sm-6'>
                <div className='form-group'>
                  <label>Tilgangsgruppenavn (journalpost)</label>
                  <input
                    type='text'
                    className='form-control'
                    defaultValue={this.state.tilgangsgruppejp}
                    onChange={this.tilgangsgruppejpChange}
                  />
                </div>
              </div>
              </div>
            <hr />

            <div className='row'>
              <div className='col-sm-12 col-lg-12 col-md-12'>
                <div className='pull-left'>
                  <input
                    type='button'
                    className='btn btn-primary'
                    value='Lagre sakstype'
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EndreSakstype);
