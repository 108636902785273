import React from "react";
import { updateTiltakType } from 'api/oppslagsdataApi.js';

class EndreTiltakRisiko extends React.Component {
  constructor(props) {
    super(props);
    this.state = { risikovurdering: this.props.risiko };
  }

  endreRisikoChange = () => {
    let endreRisiko = !this.state.risikovurdering;
    this.setendre(endreRisiko);
  };

  setendre = (endreRisiko) => {
    if (endreRisiko) {
      this.setState({
        risikovurdering: true,
      });
    } else {
      this.setState({
        risikovurdering: false,
      });
    }
  };

  onLagre = () => {
      let tmp = this.props.tiltak;
      tmp.risikovurdering = this.state.risikovurdering;
      updateTiltakType(tmp.id, tmp, this.oppdatert);
  }

  oppdatert = (err, res) => {
      this.props.lagret();
  }

  render() {
    console.log(this.props.melding);
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">{this.props.tittel}</h4>
        </div>
        <div className="modal-body">
          <div className="form margin-left-15 margin-right-15">
            <div className="row">
              <p />
              <div className="col-sm-12">
                <div className="form-group">
                  {this.props.melding !== null ?
                  <div>
                    <label>{this.props.melding}</label>
                  </div>
                  :
                  <div> 
                    <label>Skal tiltakstypen risikovurderes</label>
                  <br />
                  <div className="radio inline" style={{ paddingLeft: "5px" }}>
                    <label>
                      <input
                        type="radio"
                        name="overstyrRadio"
                        value="ja"
                        defaultChecked={this.state.risikovurdering === true}
                        onChange={this.endreRisikoChange}
                      />
                      <span style={{ margin: "0 5px" }}>Ja</span>
                    </label>
                  </div>
                  <div className="radio inline margin-left-10">
                    <label>
                      <input
                        type="radio"
                        name="overstyrRadio"
                        value="nei"
                        defaultChecked={this.state.risikovurdering === false}
                        onChange={this.endreRisikoChange}
                      />
                      <span style={{ marginLeft: "5px" }}>Nei</span>
                    </label>
                  </div>
                    
                  </div>}
                  
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary"
                    value="OK"
                    onClick={this.onLagre}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EndreTiltakRisiko;
