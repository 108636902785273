import React from 'react';
import { withRouter } from 'lib/enhancers';
import moment from 'moment';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ReactModal from 'react-modal';
import AlertClosable from 'components/exceptions/AlertClosable.jsx';
import ArbeidslisteFritekst from 'modules/felles/ArbeidslisteFritekst';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer';
import FlyttTiltak from 'modules/felles/FlyttTiltak';
import ManglerKontaktDialog from 'modules/felles/ManglerKontaktDialog.jsx';
import BruksenheterTabell from './BruksenheterTabell.jsx';
import EndreTiltakObjekt from './EndreTiltakObjekt.jsx';
import MerInfoDialog from './MerInfoDialog.jsx';
import VarsleManuell from './VarsleManuell.jsx';
import SkrivSvarutDialogStep1 from './SkrivSvarutDialogStep1.jsx';
import SkrivSvarutDialogStep2 from './SkrivSvarutDialogStep2.jsx';
import kontaktApi from 'api/kontaktApi';
import enhetApi from 'api/enhetApi.js';
import DialogStore from 'lib/DialogStore';
import {
  debugFactory,
  getDerivedChanges,
  promisifyApiCall,
  objPickPropsDeep,
} from 'utils';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:arbeidslisteplanlegger'
);

class ArbeidslistePlanlegger extends React.Component {
  state = {
    enheterErValgt: false,
    enheter: this.props.enheter,
    orgKommuner: this.props.orgKommuner,
    arbeidsliste: this.props.arbeidsliste,
    eierkontakt: null,
    item: null,
  };

   static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedChanges(
      ['arbeidsliste', 'enheter', 'orgKommuner'],
      nextProps,
      prevState,
      debug
    );
  } 

  constructor(props) {
    super(props);
   
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });

  }

  //  S L E T T  E N H E T

  // B E S Ø K  M E R K N A D
  onEndreBesokMerknad = (item) => {
    promisifyApiCall(enhetApi.getBesokMerknad)(item.id).then((merknad) => {
      this.dialogs.setOpenDialog('ModalArbeidslisteFritekstMerknad', {
        item,
        merknad,
      });
    });
  };

  lagreBesokMerknad = (item, merknad) => {
    promisifyApiCall(enhetApi.saveBesokMerknad)(item.id, merknad).then(
      this.props.refreshArbeidsliste
    );
  };

  // E N D R E  T I L T A K
  endreTiltak = (item) => {
    debug('DDD1', item);
    debug('DDD2', this.props);

    promisifyApiCall(enhetApi.getBruksenhetSituasjonTiltakById)(
      item.id,
      this.props.arbeidsliste.id
    ).then((situasjonMedTiltak) => {
      this.dialogs.setOpenDialog('ModalEndreTiltakObjekt', {
        item: item,
        situasjonMedTiltak: situasjonMedTiltak,
      });
    });
  };

  lagreTiltak = (item, nyTiltakliste) => {
    var alle = true;
    var bhTiltak = nyTiltakliste.tiltakTypeBruksenhetListe;
    var royTiltak = nyTiltakliste.tiltakTypeRoyklopListe;

    if (bhTiltak.length > 0) {
      for (var i = 0; i < bhTiltak.length; i++) {
        if (!bhTiltak[i].slett || bhTiltak[i].ny) {
          alle = false;
        }
      }
    }

    if (royTiltak.length > 0) {
      for (var y = 0; y < royTiltak.length; y++) {
        if (!royTiltak[y].slett) {
          alle = false;
        }
        if (royTiltak[y].ny) {
          alle = false;
        }
      }
    }
    if (alle && item.varselstatusId > 1 && item.varselstatusId < 6) {
      this.dialogs.setOpenDialog('OkDialogFjernAlleTiltak', { item: item });
    } else {
      promisifyApiCall(enhetApi.validertiltakendret)(
        item.id,
        this.props.arbeidsliste.id
      ).then((res) => {
        if (res === null || res.length === 0) {
          // Validering ok
          promisifyApiCall(enhetApi.updateTiltakArbeidslisteBruksenhet)(
            nyTiltakliste
          ).then(this.props.refreshArbeidsliste);
        } else {
          // Validering ikke ok, varsling sendt
          this.dialogs.setOpenDialog('JaNeiDialogValiderEndreTiltak', {
            melding: res,
            onJa: () => {
              promisifyApiCall(enhetApi.updateTiltakArbeidslisteBruksenhet)(
                nyTiltakliste
              ).then(this.props.refreshArbeidsliste);
            },
          });
        }
      });
    }
  };

  okFjernAlleTiltak = (item) => {
    let liste = [];
    liste.push(item.id.toString());
    promisifyApiCall(kontaktApi.getBruksenhetKontakter2)(liste).then((res) => {
      console.log(res);
      if (res != null && res.length > 0) {
        let eierkontakt = res[0];
        let result = false;
        eierkontakt.kontakt.forEach((ko) => {
          if (
            ko.telefon != null &&
            ko.telefon !== '' &&
            ko.ugyldigTelefonNr === false
          ) {
            result = true;
          }
        });
        if (result) {
          this.okFjernAlleTiltak2(item, eierkontakt);
        } else {
          this.dialogs.setOpenDialog('ManglerKontaktInfoTiltak', {
            item,
            eierkontakt,
          });
        }
      }
    });
  };

  okFjernAlleTiltak2 = (item, eierkontakt) => {
    let obj = {
      ArbeidslisteId: this.props.arbeidsliste.id,
      ...objPickPropsDeep(item, {
        BruksenhetId: 'id',
        BruksenhetIdMa: 'bruksenhetIdMa',
        MatrikkelEnhetId: 'matrikkelenhetId',
        Tiltaktyper: 'tiltakBruksenhet',
        Adresse: 'adresse',
      }),
      kontakt: eierkontakt.kontakt,
    };
    if (item.planlagtDato !== null) {
      obj.VarselDato = item.planlagtDato;
    }
    debug('fjerntiltak etter obj-telefon', obj);

    promisifyApiCall(enhetApi.fjernTiltakBruksenhet2)(obj).then((res) => {
      if (res) {
        // Could not delete `item`, show reason
        if (res.tekst){
          this.dialogs.setOpenDialog('AlertClosableValideringsMelding', {
            message: res.tekst,
          });
        }
      } else {
        // `item` was successfully deleted
        const enheter = this.state.enheter.filter((x) => x.id !== item.id);
        this.setState({ enheter }, this.props.refreshArbeidsliste);
      }
    });
  };

  onOpenSlettEnhet = (item) => {
    this.dialogs.setOpenDialog('OkDialogSlettEnhet', { item });
  };

  slettEnhet = (item) => {
    let liste = [];
    liste.push(item.id.toString());
    promisifyApiCall(kontaktApi.getBruksenhetKontakter2)(liste).then((res) => {
      console.log(res);
      if (res != null && res.length > 0) {
        let eierkontakt = res[0];
        debug('fjerntiltak a1', eierkontakt);
        let result = false;
        eierkontakt.kontakt.forEach((ko) => {
          if (
            ko.telefon != null &&
            ko.telefon !== '' &&
            ko.ugyldigTelefonNr === false
          ) {
            result = true;
          }
        });
        if (result) {
          debug('fjerntiltak a2 telefon funnet', eierkontakt);
          this.slettEnhet2(item, eierkontakt);
        } else if (!result && !item.varselstatus) {
          debug(
            'fjerntiltak a2 telefon ikke funnet men er ikke varslet',
            eierkontakt
          );
          this.slettEnhet2(item, eierkontakt);
        } else {
          debug('fjerntiltak a2 telefon feil', eierkontakt);
          this.dialogs.setOpenDialog('ManglerKontaktInfo', {
            item,
            eierkontakt,
          });
        }
      }
    });
  };

  rensTittakstyper = (item) => {
    let tiltakstyper = '';
    //Tiltakstyper må 'renses'
    let tb = item.tiltakBruksenhet == null ? '' : item.tiltakBruksenhet;
    let tr = item.tiltakRoyklop == null ? '' : item.tiltakRoyklop;
    if (tb[0] === ',') {
      tb = tb.substring(1);
    }
    if (tr[0] === ',') {
      tr = tr.substring(1);
    }
    let ab = tb.split(',');
    let ar = tr.split(',');
    for (let i = 0; i < ab.length; i++) {
      ab[i] = ab[i].trim();
    }
    for (let i = 0; i < ar.length; i++) {
      ar[i] = ar[i].trim();
    }
    tiltakstyper = ab.concat(ar).join();
    if (tiltakstyper[0] === ',') {
      tiltakstyper = tiltakstyper.substring(1);
    }
    if (tiltakstyper[tiltakstyper.length - 1] === ',') {
      tiltakstyper = tiltakstyper.substring(0, tiltakstyper.length - 1);
    }

    return tiltakstyper;
  };

  slettEnhet2 = (item, eierkontakt) => {
    debug('slettenhet 1:', item);
    let tiltakstyper = this.rensTittakstyper(item);

    /*    const tiltakstyper = [item.tiltakBruksenhet, item.tiltakRoyklop]
            .flatMap(tiltaktekst => {
                return (tiltaktekst || '')
                    .split(',')
                    .map(tekst => tekst.trim());
            })
            .join(','); */

    eierkontakt.kontakt.forEach((ko) => {
      if (ko.telefon == null || ko.telefon === '' || ko.ugyldigTelefonNr) {
        ko.smsUtelat = true;
      }
    });
    let obj = {
      ArbeidslisteId: this.props.arbeidsliste.id,
      ...objPickPropsDeep(item, {
        BruksenhetId: 'id',
        BruksenhetIdMa: 'bruksenhetIdMa',
        MatrikkelEnhetId: 'matrikkelenhetId',
        /* 'VarselDato': 'planlagtDato', */
        Adresse: 'adresse',
      }),
      Tiltaktyper: tiltakstyper,
      kontakt: eierkontakt.kontakt,
      // smsUtelat: eierkontakt.telefon == null ? true : false,
      // navn: eierkontakt.navn,
      // telefon: eierkontakt.telefon
    };
    if (item.planlagtDato !== null) {
      obj.VarselDato = item.planlagtDato;
    }

    debug('slettenhet objekt', { obj });

    promisifyApiCall(enhetApi.fjernTiltakBruksenhet2)(obj).then((res) => {
      if (res) {
        // Could not delete `item`, show reason
        if (res.tekst){
        this.dialogs.setOpenDialog('AlertClosableValideringsMelding', {
          message: res.tekst,
        });
      }
      } else {
        // `item` was successfully deleted
        const enheter = this.state.enheter.filter((x) => x.id !== item.id);
        this.setState({ enheter }, this.props.refreshArbeidsliste);
      }
    });
  };

  onEnheterValgt = (rows) => {
    this.props.onValgteEnheter(rows); // Temp-fix while refactoring
  };

  // V i S  M E R  I N F O
  onVismerInfo = (item) => {
    // TODO: Hente SMS varsel info og sende med til dialog
    this.dialogs.setOpenDialog('ModalMerInfoDialog', { item });
  };

  //  F L Y T T  T I L T A K
  onVisFlyttTiltak = (item) => {
    //Ta vare på item
    this.setState({ item: item });
    this.setState({ eierkontakt: null });
    //Check om det finnes eierinfo/tlf
    let liste = [];
    liste.push(item.id.toString());
    kontaktApi.getBruksenhetKontakter2(
      liste,
      this.gotEierkontakterFortsettFlytt
    );
  };

  gotEierkontakterFortsettFlytt = (err, eierData) => {
    let eierkontakt = null;
    //Ta vare på eier
    if (eierData != null && eierData.length > 0) {
      eierkontakt = eierData[0];
      this.setState({ eierkontakt: eierkontakt });
    }
    this.dialogs.setOpenDialog('ModalContainerFlyttTiltak', {
      item: this.state.item,
      eierkontakt: eierkontakt,
    });
  };

  okEierFinnesIkke = (item, eierkontakt) => {
    debug('Eier fortsett: ', item);
    debug('Eier fortsett: ', eierkontakt);
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('ModalContainerFlyttTiltak', {
      item: this.state.item,
      eierkontakt: eierkontakt,
    });
  };

  onFlyttTiltakCompleted = () => {
    this.dialogs.closeDialog();
    let item = this.state.item;
    debug('item: ', item);
    let eierInfo = '';
    let telefon = false;
    this.state.eierkontakt.kontakt.forEach((ko) => {
      if (ko.telefon != null) {
        telefon = true;
      }
    });
    //console.log(visMeldingOmEierkontakt);
    // if (this.state.eierkontakt !== null) {
    //     if (this.state.eierkontakt.telefon === null) {
    //         if (item.varselstatus !== null) {
    //             visMeldingOmEierkontakt= item.varselstatus;
    //         }
    //         eierInfo = this.state.eierkontakt.adresse ? eierInfo + this.state.eierkontakt.adresse + ' ' : eierInfo;
    //         eierInfo = this.state.eierkontakt.navn ? eierInfo + '(' + this.state.eierkontakt.navn + ')' : eierInfo;
    //     }
    // } else {
    //         visMeldingOmEierkontakt= true;
    // }

    debug('item 2: ', telefon);

    if (!telefon && item.varselstatus !== null) {
      this.dialogs.setOpenDialog('ManglerKontaktInfoFlytte', {
        eierInfo: eierInfo,
      });
      promisifyApiCall(enhetApi.getArblisteBrukenheterPlanlegging)(
        this.props.arbeidsliste.id
      ).then(this.props.refreshArbeidsliste);
    } else {
      this.dialogs.setOpenDialog('OkDialogSlettEnhetFlytt', { item });
      promisifyApiCall(enhetApi.getArblisteBrukenheterPlanlegging)(
        this.props.arbeidsliste.id
      ).then(this.props.refreshArbeidsliste);
    }
  };

  // V A R S L E  M A N U E L L
  onMarkerVarslet = (item) => {
    this.dialogs.setOpenDialog('ModalVarsleManuell', { item });
  };

  onMarkerVarsletNy = (item) => {
    this.dialogs.setOpenDialog('ModalVarsleManuell', { item });
  };

  onPdfExport = (enhet) => {
    this.dialogs.setOpenDialog('ModalSkrivSvarUtStep1', {
      enhet: enhet,
      arbeidslisteId: this.props.arbeidsliste.id,
      modus: 'PDF',
    });
  };

  onSkrivSvarUt = (enhet) => {
    this.dialogs.setOpenDialog('ModalSkrivSvarUtStep1', {
      enhet: enhet,
      arbeidslisteId: this.props.arbeidsliste.id,
      modus: 'SVARUT',
    });
  };

  onNextSvarUtStep = (enhet, fletteOptions, data, modus) => {
    //Nå åpnes modaldialog for step 2: teksten
    this.dialogs.closeDialog();
    let feil = true;

    if (data) {
      if (data.malTekst) {
        feil = false;
        this.dialogs.setOpenDialog('ModalSkrivSvarUtStep2', {
          enhet: enhet,
          fletteOptions: fletteOptions,
          data: data,
          modus: modus,
        });
      }
    }

    if (feil) {
      this.dialogs.closeDialog();
      this.dialogs.setOpenDialog('MalenKanIkkeBrukes');
    }
  };

  onSendSvarUt = (enhet, tekst, kontaktliste) => {
    this.dialogs.closeDialog();
    let pld = moment(enhet.planlagtDato)
      .add(3, 'h')
      .toDate();
    let param = {
      bruksenhetId: enhet.id,
      bruksenhetAdresse: enhet.adresse,
      arbeidslisteId: this.props.arbeidsliste.id,
      planlagtDato: pld,
      besokFra: enhet.planlagtBesokFra,
      besokTil: enhet.planlagtBesokTil,
      kontakt: kontaktliste,
      malTekst: tekst,
    };
    promisifyApiCall(enhetApi.postVarslingInnbyggerSvarUt)(param).then(() => {
      this.props.refreshArbeidsliste();
    });
  };

  okVarsleManuellDialogNy = (item, besoksTid) => {
    promisifyApiCall(kontaktApi.getBruksenhetKontakter)([
      objPickPropsDeep(item, {
        bruksenhetFagId: 'id',
        bruksenhetMaId: 'bruksenhetIdMa',
        bruksenhetAdresse: 'adresse',
      }),
    ]).then((kontaktInfo) => {
      console.log(kontaktInfo);
      let param = {
        bruksenhetId: kontaktInfo[0].bruksenhetFagId,
        bruksenhetAdresse: kontaktInfo[0].bruksenhetAdresse,
        arbeidslisteId: this.props.arbeidsliste.id,
        planlagtDato: besoksTid.dato,
        besokFra: besoksTid.startFra,
        besokTil: besoksTid.startTil,
        kontakt: kontaktInfo[0].kontakt,
        matrikkelenhetId: item.matrikkelenhetId,
      };
      promisifyApiCall(enhetApi.postVarslingInnbyggerManuell)([param]).then(
        () => {
          const enheter = this.state.enheter.map((mapItem) =>
            mapItem.id === item.id
              ? Object.assign(mapItem, {
                  varselstatus: 'OK - manuell',
                  varselstatusId: 5,
                  planlagtDato: moment(besoksTid.dato).format('YYYY-MM-DD'),
                })
              : mapItem
          );

          debug('999', enheter);
          this.setState({ enheter });
          this.props.refreshArbeidsliste();
        }
      );
    });
  };

  render() {
    debug('render', { ...this });
    return (
      <React.Fragment>
        <BruksenheterTabell
          laderStatus={ this.props.laderStatus }
          hasExtra={this.props.hasExtra}
          endreTiltak={this.endreTiltak}
          enheter={this.state.enheter}
          arbeidsliste={this.state.arbeidsliste}
          slettEnhet={this.slettEnhet}
          fjernEnhet={this.props.fjernEnhet}
          flyttEnhet={this.props.flyttEnhet}
          flyttTiltak={this.onVisFlyttTiltak}
          visMer={this.onVismerInfo}
          besokMerknad={this.onEndreBesokMerknad}
          enheterValgt={this.onEnheterValgt}
          markerVarslet={this.onMarkerVarslet}
          markerVarsletNy={this.onMarkerVarsletNy}
          /* rettigheter={this.props.rettigheter} */
          svarUt={this.onSvarUt}
          skrivSvarUt={this.onSkrivSvarUt}
          pdfExport={this.onPdfExport}
        />

        {this.dialogs.renderOpenDialog()}
      </React.Fragment>
    );
  }
}

export default withRouter(ArbeidslistePlanlegger);

// Declarative parts

const modalDialogs = {
  AlertClosableValideringsMelding: ({ isOpen, onClose, dialogData, owner }) => {
    // Was: `slettValideringsMelding` (Truthy content flagged `isOpen`...)
    const { message } = dialogData;
    return (
      <AlertClosable
        header={'Kan ikke fjerne enhet'}
        message={message}
        onClose={onClose}
      />
    );
  },
  JaNeiDialogValiderEndreTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    // Was: `this.endreTiltakValidertOk`
    const { melding, onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        isOpen={isOpen}
        /*  isOpen={owner.props.isOpen} */
        tittel={'Enhet er varslet'}
        melding={melding + '\nVil du fortsette?'}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
  ModalEndreTiltakObjekt: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <EndreTiltakObjekt
          onClose={onClose}
          lagreTiltak={(nyTiltakliste) => {
            onClose();
            owner.lagreTiltak(dialogData.item, nyTiltakliste);
          }}
          enhet={dialogData.item}
          arbeidslisteId={owner.props.arbeidsliste.id}
          situasjonMedTiltak={dialogData.situasjonMedTiltak}
        />
      </NKModal>
    );
  },
  ModalMerInfoDialog: ({ isOpen, onClose, dialogData, owner }) => {
    // Was: `this.state.modalInfoIsOpen`
    const { item } = dialogData;
    return (
      <NKModal size='small' isOpen={isOpen}>
        <MerInfoDialog onOk={onClose} onAvbryt={onClose} enhet={item} />
      </NKModal>
    );
  },
  ModalVarsleManuell: ({ isOpen, onClose, dialogData, owner }) => {
    // Was: `this.state.modalVarsleManuellIsOpen`
    const { item } = dialogData;
    return (
      <NKModal size='small' isOpen={isOpen}>
        <VarsleManuell
          onAvbryt={onClose}
          // onOk={onClose((tid) => owner.okVarsleManuellDialog(item, tid))}
          enhet={item}
          onOkNy={onClose((tid) => owner.okVarsleManuellDialogNy(item, tid))}
        />
      </NKModal>
    );
  },
  ModalSkrivSvarUtStep1: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className='malModalSvarut'>
      <SkrivSvarutDialogStep1
        onClose={onClose}
        enhet={dialogData.enhet}
        modus={dialogData.modus}
        arbeidslisteId={dialogData.arbeidslisteId}
        onNextSvarUtStep={(enhet, fletteOptions, data) =>
          owner.onNextSvarUtStep(enhet, fletteOptions, data, dialogData.modus)
        }
      />
    </ReactModal>
  ),
  ModalSkrivSvarUtStep2: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className='malModalSvarut'>
      <SkrivSvarutDialogStep2
        onClose={onClose}
        enhet={dialogData.enhet}
        modus={dialogData.modus}
        /* norkartbruker={owner.props.rettigheter.norkartAdminBruker} */
        norkartbruker={contextInnstillingerPerson._currentValue.norkartAdmin}
        fletteOptions={dialogData.fletteOptions}
        data={dialogData.data}
        onSendSvarUt={(enhet, tekst, kontaktliste) =>
          owner.onSendSvarUt(enhet, tekst, kontaktliste)
        }
      />
    </ReactModal>
  ),
  ModalArbeidslisteFritekstMerknad: ({
    isOpen,
    onClose,
    dialogData,
    owner,
  }) => {
    // Was: `this.state.InfoDialogOpen`
    const { item, merknad } = dialogData;
    debug('ModalArbeidslisteFritekstMerknad', { dialogData });
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <ArbeidslisteFritekst
          onClose={onClose}
          merknad={merknad.tekst}
          lagreBesokMerknad={onClose((merknad) =>
            owner.lagreBesokMerknad(item, merknad)
          )}
        />
      </NKModal>
    );
  },
  OkDialogVarsleEnheterIngentingValgt: ({
    isOpen,
    onClose,
    dialogData,
    owner,
  }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Varsle enheter'
        melding='Du må først velge en eller flere enheter som skal få varsel'
        onOk={onClose}
      />
    );
  },
  OkDialogEierFinnesIkke: ({ isOpen, onClose, dialogData, owner }) => {
    const { item, eierkontakt } = dialogData;
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='En feil har oppstått'
        melding='Vi finner ikke en registrert eier i matrikklen.'
        onOk={onClose(() => owner.okEierFinnesIkke(item, eierkontakt))}
      />
    );
  },
  OkDialogFjernAlleTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Endre tiltak'
        melding='Du har fjernet alle varslede tiltak. Eier av bruksenheten vil bli informert om at tiltakene er kansellert.'
        onOk={onClose(() => owner.okFjernAlleTiltak(dialogData.item))}
      />
    );
  },
  OkDialogSlettEnhet: ({ isOpen, onClose, dialogData, owner }) => {
    const { item } = dialogData;
    const melding = item.varselstatus
      ? 'Du fjerner en enhet med varslede tiltak. Eier av bruksenheten vil bli informert om at alle tiltak på enheten er kansellert.'
      : 'Du fjerner nå valgt enhet fra arbeidslista.';
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Fjern enhet'
        melding={melding}
        onOk={onClose(() => owner.slettEnhet(item))}
      />
    );
  },
  OkDialogSlettEnhetFlytt: ({ isOpen, onClose, dialogData, owner }) => {
    const { item } = dialogData;
    const melding =
      item.varselstatus != null
        ? 'Du fjerner en enhet med varslede tiltak fra arbeidslista. Eier av bruksenheten vil bli informert om at alle tiltak på enheten er kansellert.'
        : 'Du fjerner nå valgt enhet fra arbeidslista.';
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Fjern/flytt enhet'
        melding={melding}
        onOk={onClose}
      />
    );
  },
  ModalContainerFlyttTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    const { item, eierkontakt } = dialogData;
    return (
      <ModalContainer
        size='medium'
        isOpen={isOpen}
        onClose={onClose}
        buttonText='Flytt'
        onSubmit={onClose(() => {
          owner.refs.flyttTiltak.submit(
            item.id,
            eierkontakt,
            owner.onFlyttTiltakCompleted
          );
        })}
        tittel={'Flytt fra arbeidsliste ' + owner.state.arbeidsliste.navn}>
        <FlyttTiltak
          ref='flyttTiltak'
          varselstatusId={item.varselstatusId}
          erVarslet={!!item.varselstatus}
          enhetValgtForFlytt={item}
          arbeidslisteId={owner.state.arbeidsliste.id}
          eierkontakt={eierkontakt}
        />
      </ModalContainer>
    );
  },
  ManglerKontaktInfo: ({ isOpen, onClose, dialogData, owner }) => {
    const { item, eierkontakt } = dialogData;
    return (
      <ModalContainer
        size='medium'
        isOpen={isOpen}
        onClose={onClose}
        buttonText='OK'
        onSubmit={onClose(() => {
          owner.slettEnhet2(item, eierkontakt);
        })}
        tittel={'Fjern fra arbeidsliste ' + owner.state.arbeidsliste.navn}>
        <ManglerKontaktDialog
          adresse={item.adresse}
          eierkontakt={eierkontakt}
        />
      </ModalContainer>
    );
  },
  ManglerKontaktInfoTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    const { item, eierkontakt } = dialogData;
    debug('fjerntiltak kontaktdialog', item);
    return (
      <ModalContainer
        size='medium'
        isOpen={isOpen}
        onClose={onClose}
        buttonText='OK'
        onSubmit={onClose(() => {
          owner.okFjernAlleTiltak2(item, eierkontakt);
        })}
        tittel='Verifiser kontaktinformasjon'>
        <ManglerKontaktDialog
          adresse={item.adresse}
          eierkontakt={eierkontakt}
        />
      </ModalContainer>
    );
  },
  ManglerKontaktInfoFlytte: ({ isOpen, onClose, dialogData, owner }) => {
    const { eierInfo } = dialogData;
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Kansellering ' + eierInfo}
        melding='Vi fant ikke telefonnummeret til eierkontakten. Melding om
                kansellering vil likevel bli publisert til Min eiendom. Ta
                kontakt med eieren manuelt for å informere om kansellering.'
        onOk={onClose}
      />
    );
  },
  MalenKanIkkeBrukes: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Mal'}
        melding='Malen kan ikke brukes. Sjekk malen i "Innstillinger - Maloppsett".
        Malen mangler kanskje tekst eller en mal til aktuell "tiltak" finnes ikke. '
        onOk={onClose}
      />
    );
  },

};
