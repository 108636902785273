import React, { Component } from 'react';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NKIcon from 'nka-icons';
import {
  getContextFilters,
  hentFiltersIgjen,
  fjernStandardFilter,
} from 'context/contextFilters.js';
import setDefault_icon from 'images/48/setdefault.png';
import { deleteSokLagret, setStandardSokFilter } from 'api/sokLagretApi';
import { promisifyApiCall } from 'utils';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Bruk valgt søk som standard?'
        isOpen={isOpen}
        onClose={onClose}
        melding={dialogData.melding}
        melding2={dialogData.melding2}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
  JaNeiSlettDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Slette valgt søk?'
        isOpen={isOpen}
        onClose={onClose}
        melding={dialogData.melding}
        melding2={dialogData.melding2}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
  JaNeiFjernMinStandardDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Deaktiver min "Standard søk" ?'
        isOpen={isOpen}
        onClose={onClose}
        melding={dialogData.melding}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};

class LagredeFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kunMine: false,
      orgFilters: [],
      filters: [],
      standardFilterId: 0,
      filtersInit: false,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    getContextFilters(this.onGotFilters);
  }

  onGotFilters = (data) => {
    let filters = data.filters;
    this.setState({
      filters,
      standardFilterId: data.standardFilterId,
      filtersInit: true,
    });
  };

  brukFilter = (filter) => {
    this.props.brukLagretSok(filter);
  };

  visLagreSokDialog = (filter) => {
    this.props.visLagreSokDialog();
  };

  deleteSok = (filter) => {
    this.dialogs.setOpenDialog('JaNeiSlettDialog', {
      melding: 'Du har valgt: ' + filter.navn,
      melding2: 'Ønsker du å slette valgt søk?',
      onJa: () => {
        this.deleteSok2(filter);
      },
    });
  };

  deleteSok2 = (filter) => {
    promisifyApiCall(deleteSokLagret)(filter.id).then(() => {
      let filters = this.state.filters;
      for (var i = 0; i < filters.length; i++) {
        var f = filters[i];

        if (f.id === filter.id) {
          filters.splice(i, 1);
        }
      }
      this.setState({ filters });
      hentFiltersIgjen();
    });
  };

  setDefault = (filter, standard) => {
    if (standard) {
      this.fjernStandardFilter();
    } else {
      this.dialogs.setOpenDialog('JaNeiDialog', {
        melding: 'Du har valgt: ' + filter.navn,
        melding2: 'Ønsker du å bruke valgt søk som din "Standard søk"?',
        onJa: () => {
          this.setStandardFilter(filter);
        },
      });
    };
  };

  setStandardFilter = (filter) => {
    promisifyApiCall(setStandardSokFilter)(filter.id).then((data) => {
      this.props.onValgtStandard(filter);
      this.brukFilter(filter);
    });
  };

  fjernStandardFilter = () => {
    this.dialogs.setOpenDialog('JaNeiFjernMinStandardDialog', {
      melding: 'Ønsker du å deaktivere din "Standard søk"?',
      onJa: () => {
        this.setState({ standardFilterId: null });
        fjernStandardFilter();
      },
    });
  };

  render() {
    let x = 0;
    if (!this.state.filtersInit) {
      return <div>Vent litt..</div>;
    }
    return (
      <NKModal size='medium' isOpen={this.props.isOpen}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              type='button'
              onClick={this.props.onClose}>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'> Lagrede søk </h4>
          </div>
          <div>
            <div className='modal-body'>
              {/* 
                  <label>
                    <input
                      style={{ height: '20px', width: '20px' }}
                      type='checkbox'
                      name='checkMine'
                      onChange={this.onCheckChange}
                    />
                    &nbsp;&nbsp;
                    <span>Vis kun 'mine' lagrede søk.</span>
                  </label>
                  */}

              <button
                onClick={this.visLagreSokDialog}
                className='btn btn-default pull-right'>
                Lagre søk som brukes nå
              </button>

{/*               {this.state.standardFilterId && this.state.standardFilterId > 0 && (
                <button
                  style={{ marginRight: '4px' }}
                  onClick={this.fjernStandardFilter}
                  className='btn btn-default pull-right'>
                  Fjern min "Standard søk"
                </button>
              )} */}

              <br />
              <br />

              <div
                className='list-group'
                style={{ maxHeight: '360px', overflow: 'auto' }}>
                {this.state.filters.map(function(filter) {
                  var tittel = filter.navn;
                  var tooltip = 'Set som min "Standard søk"';
                  let klasse = 'btn btn-default pull-right';
                  let standard = false;
                  if (filter.id === this.state.standardFilterId) {
                    klasse = 'btn btn-success pull-right';
                    tooltip = 'Min "Standard søk"';
                    standard = true;
                  }
                  // var alle = filter.forAlle;
                  x = x + 1;
                  return (
                    <div
                      className='list-group-item list-group-item-action'
                      key={x}
                      style={{
                        borderWidth: 0,
                        height: '40px',
                        paddingTop: '6px',
                      }}>
                      <button
                        className='btn btn-default'
                        style={{ width: '432px', height: '30px' }}
                        type='button'
                        onClick={this.brukFilter.bind(this, filter)}>
                        <span className='pull-left'>{tittel}</span>
                      </button>

                      <button
                        onClick={this.deleteSok.bind(this, filter)}
                        title={'Fjern'}
                        style={{
                          height: '30px',
                          width: '50px',
                          paddingTop: '3px',
                        }}
                        className='btn btn-default pull-right'>
                        <NKIcon
                          icon='soppelbotte'
                          size='0.8'
                          color='nk-black'
                        />
                      </button>

                      <button
                        onClick={this.setDefault.bind(this, filter, standard)}
                        title={tooltip}
                        style={{
                          height: '30px',
                          width: '50px',
                          marginRight: '4px',
                          paddingTop: '3px',
                        }}
                        className={klasse}>
                        <img
                          height='20'
                          width='20'
                          src={setDefault_icon}
                          alt=''
                          title={tooltip}
                        />
                      </button>
                    </div>
                  );
                }, this)}
              </div>
            </div>
          </div>
          <div className='modal-footer' />
        </div>
        {this.dialogs.renderOpenDialog()}
      </NKModal>
    );
  }
}

export default LagredeFilters;
