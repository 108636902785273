import React, { Component } from 'react';
import LinearRuler from 'react-leaflet-linear-ruler';
import 'components/Kart/Controls/linear-ruler.css';

const options = {  
  position: 'bottomleft',
  unitSystem: 'metric', 
  /* color: '#FF0080', color: '#4D90FE', */
 // type: 'line',
  color: '#FF00FF',
  fillColor: '#fff',
  type: 'node',
  features: ['node', 'line', 'polygon', 'ruler', 'paint', 'drag', 'rotate', 'nodedrag', 'trash'],
  pallette: ['#FF0080', '#4D90FE', 'red', 'blue', 'green', 'orange', 'black'],
  dashArrayOptions: ['5, 5', '5, 10', '10, 5', '5, 1', '1, 5', '0.9', 
            '15, 10, 5', '15, 10, 5, 10', '15, 10, 5, 10, 15', '5, 5, 1, 5'],
  fill: true,
  stroke: true,
  dashArray: [5, 5],
  weight: 2,
  opacity: 1,
  fillOpacity: 0.5,  
  radius: 3,
  doubleClickSpeed: 1000
};

class OppmaalingControl extends Component {

  getOptions = () => {
    return options;
  };

  render() {
    
    return (
      <div> 
        <LinearRuler {... options } />
      </div>
    );
  }
}

export default OppmaalingControl;
