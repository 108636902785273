import React from 'react';
import { withRouter } from 'lib/enhancers';

import ContextMenu from 'components/ContextMenu';
import '.././gjennomforing.css';
import ReactTable from '@norkart/react-table';
import 'moment/locale/nb';
import enhetApi from 'api/enhetApi';
import royklopApi from 'api/royklopApi';
import NKIcon from 'nka-icons';
import MeldingDialog from './melding.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import NyTiltakstype from './NyTiltakstype.jsx';
import EndreTiltakstype from './EndreTiltakstype.jsx';
import TiltakstypeLogg from './TiltakstypeLogg.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import * as _ from 'lodash';
import DialogStore from 'lib/DialogStore';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import { sjekkBruksenhetRisiko, sjekkRoyklopRisiko } from 'api/risikoApi.js';

var meldingHeader = '';
var melding = '';
var valgt = null;
var valgtId = null;

const modalDialogs = {
  MeldingDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <MeldingDialog
          onClose={onClose}
          onOK={owner.closeMelding}
          meldingHeader={dialogData.meldingHeader}
          melding={dialogData.melding}
        />
      </NKModal>
    );
  },
  modalNyttTiltakIsOpen: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="medium"
        tittel="Nytt regelmessig tiltak"
        onClose={onClose}
      >
        <NyTiltakstype
          type={dialogData.type}
          items={dialogData.items}
          id={dialogData.id}
          brId={dialogData.brId}
          onCloseModal={owner.closeNyModal}
        />
      </ModalContainer>
    );
  },
  modalLoggIsOpen: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="medium"
        tittel={dialogData.tittel}
        onClose={onClose}
      >
        <TiltakstypeLogg
          item={dialogData.item}
          onCloseModal={owner.closeNyModal}
        />
      </ModalContainer>
    );
  },
  modalEndreTiltakIsOpen: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="medium"
        tittel="Endre regelmessige tiltak"
        onClose={onClose}
      >
        <EndreTiltakstype
          type={dialogData.type}
          valgt={dialogData.valgt}
          items={dialogData.items}
          id={dialogData.id}
          bruksEnhetId={dialogData.bruksEnhetId}
          onCloseModal={owner.closeNyModal}
          orgRisiko={dialogData.orgRisiko}
          overstyrt={dialogData.overstyrt}
        />
      </ModalContainer>
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OKDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={onClose}
      />
    );
  },
};

class TiltakstyperTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      slett: false,
      risikoSjekk: false
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    this.getTiltakstyper();
  }

  getTiltakstyper = () => {
    if (this.props.type === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakstyper(this.props.id, this.gotTiltakstyper);
      //enhetApi.getBruksenhetTiltakTypeOppslagdata(this.gotOppslagsdata);
      sjekkBruksenhetRisiko(this.props.id, this.gotRisikoEnhetRoyk);
    } else {
      enhetApi.getRoyklopTiltakstyper(this.props.id, this.gotTiltakstyper);
      //enhetApi.getRoyklopTiltakTypeOppslagdata(this.gotOppslagsdata);
      sjekkRoyklopRisiko(this.props.id, this.props.brId, this.gotRisikoEnhetRoyk);
    }
  };

  gotTiltakstyper = (err, tiltakstyper) => {
    this.setState({ items: tiltakstyper });
  };

  // gotOppslagsdata = (err, data) => {
  //   this.setState({ oppslagsdata: data });
  // };

  gotRisikoEnhetRoyk = (err, res) => {
    this.setState( { risikoSjekk: res });
  }

  ny = () => {
    if (this.props.type !== 'bruksenhet' && this.props.royklop.ibruk == false) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Tiltakstype',
        melding:
          'Tiltaktype kan ikke opprettes da røykløpet ikke er i bruk.',
      });
    } else {
      
        this.dialogs.setOpenDialog('modalNyttTiltakIsOpen', {
          type: this.props.type,
          items: this.state.items,
          id: this.props.id,
          brId: this.props.brId
        });
      
    }
  };

  logg = (item) => {
    this.dialogs.setOpenDialog('modalLoggIsOpen', {
      item: item,
      tittel: 'Regelmessig tiltak logg - ' + item.tiltaktype
      
    });
  }

  endre = (item) => {
    valgt = item;
    if (item.risikovurdering && this.state.risikoSjekk) {
      if (this.props.type == 'bruksenhet') {
        enhetApi.risikooverstyrtBruksenhet(
          this.props.id,
          this.sjekkRisikoEndre
        );
      } else {
        royklopApi.risikooverstyrtRoyklop(this.props.id, this.sjekkRisikoEndre);
      }
    } else {
      this.fortsettEndre(item);
    }
  };

  sjekkRisikoEndre = (err, res) => {
    if (!res) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Tiltakstype',
        melding:
          'Tiltaktypen kan ikke endres da den er tilknyttet automatisert risikovurdering.',
      });
    } else {
      this.fortsettEndre(valgt, res);
    }
  };

  fortsettEndre = (item, overstyrt) => {
    valgtId = item.id;
    valgt = item;
    this.setState({ tiltakNavn: item.tiltak });
    this.dialogs.setOpenDialog('modalEndreTiltakIsOpen', {
      type: this.props.type,
      items: this.state.items,
      id: this.props.id,
      valgt: item,
      bruksEnhetId: this.props.id,
      orgRisiko: this.props.orgRisiko,
      overstyrt: overstyrt
    });
  };

  slett = (item) => {
    melding = 'Du sletter nå valgt regelmessig tiltak. Vil du fortsette?';
    valgt = item;
    if (item.risikovurdering) {
      if (this.props.type == 'bruksenhet') {
        enhetApi.risikooverstyrtBruksenhet(
          this.props.id,
          this.sjekkRisikoSlett
        );
      } else {
        royklopApi.risikooverstyrtRoyklop(this.props.id, this.sjekkRisikoSlett);
      }
    } else {
      this.fortsettSlett(item);
    }
  };

  sjekkRisikoSlett = (err, res) => {
    if (!res) {
      melding =
        'Merk! Tiltakstypen du ønsker å slette er tilknyttet automatisert risikovurdering. Vil du fortsette?';
    }
    this.fortsettSlett(valgt);
    // if (!res) {
    //   this.dialogs.setOpenDialog('OkDialog', { tittel: 'Tiltakstype', melding: 'Tiltaktypen kan ikke endres eller slettes da den er tilknyttet automatisert risikovurdering.' });
    // } else {
    //   this.fortsettSlett(valgt);
    // }
  };

  fortsettSlett = (item) => {
    valgt = item;
    valgtId = item.id;
    this.setState({ tiltakNavn: item.tiltak });
    //melding = 'Du sletter nå valgt regelmessig tiltak. Vil du fortsette?';
    meldingHeader = 'Slett tiltakstype';
    this.setState({ slett: true });
    this.dialogs.setOpenDialog('MeldingDialog', {
      melding: melding,
      meldingHeader: meldingHeader,
    });
  };

  bekreftSlett = () => {
    if (this.props.type === 'bruksenhet') {
      enhetApi.slettBruksenhetTiltakType(valgtId, this.onTiltaktypeSlettet);
    } else {
      enhetApi.slettRoyklopTiltakType(valgtId, this.onTiltaktypeSlettet);
    }
  };

  onTiltaktypeSlettet = () => {
    if (this.props.type === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakstyper(this.props.id, this.gotTiltakstyper);
    } else {
      enhetApi.getRoyklopTiltakstyper(this.props.id, this.gotTiltakstyper);
    }
  };

  closeMelding = () => {
    if (this.state.slett) {
      this.bekreftSlett();
    }
    this.setState({ slett: false });
    this.dialogs.closeDialog();
  };

  avbryt = () => {
    this.setState({ slett: false });
    this.dialogs.closeDialog();
  };
  closeNyModal = () => {
    this.dialogs.closeDialog();
    this.getTiltakstyper();
  };
  getColumns = () => {
    return [
      {
        id: 'tiltaktype',
        name: 'Type tiltak',
        sortParams: 'tiltaktype',
        isSorted: true,
        filterable: false,
        sortDirection: 'asc',
      },
      {
        id: 'hyppighet',
        name: 'Hyppighet',
        sortParams: 'hyppighet',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
      },
      {
        id: 'nesteDato',
        name: 'Antatt neste dato',
        sortParams: 'nesteDato',
        isSorted: true,
        filterable: false,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.nesteDato === null) {
            return '-';
          } else {
            var dato = new Date(item.nesteDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'sisteDato',
        name: 'Siste dato',
        sortParams: 'sisteDato',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
        formatter: function(item) {
          if (item.sisteDato === null) {
            return '-';
          } else {
            var dato = new Date(item.sisteDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'sisteStatus',
        name: 'Siste status',
        sortParams: 'sisteStatus',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false,
        formatter: function(item) {
          if (item.sisteDato === null) {
            return '-';
          } else {
            if (item.sisteStatus) {
              return 'Utført';
            } else {
              return 'Ikke utført';
            }
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn"
            >
              <ContextMenu.Item onClick={this.logg.bind(this, item)}>
                Logg
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  render() {
    var columns = this.getColumns();
    let pType = this.props.type;
    if (pType === 'royklop') {
      pType = 'røykløp';
    }
    return (
      <div>
        <h2>Regelmessige tiltak {pType}</h2>
        <hr />

        <div className="btn-toolbar">
          <button
            className="btn btn-default button-arbliste-enhet"
            onClick={this.ny}
          >
            <span className="pull-left">
              <NKIcon className="pull-left" icon="pluss" color="nk-black" />
            </span>
            <span className="pull-left margin-left-10 ">Nytt</span>
          </button>
        </div>

        <hr />
        <ReactTable
          items={this.state.items}
          idProperty="id"
          showIndex={false}
          filterable={false}
          columns={columns}
          onRowClick={null}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(TiltakstyperTable);
