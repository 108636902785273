/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import * as _ from 'lodash';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import ReactTable from '@norkart/react-table';
import NKIcon from 'nka-icons';
import NKModal from 'components/Dialogs/NKModal.jsx';
import MalDialog from 'modules/Innstillinger/Maloppsett/MalDialog.jsx';
import {
  ToolbarButton,
  ToolbarButtonDrop
} from 'components/generics/Button.jsx';
import {
  GetAntallMaler,
  getEksempelMaler,
  getMalerOppslagsdata,
} from 'api/malerApi';
import { checkEditorNormal } from 'components/Editor/EditorNormal.js';
import AlleEksempelDialog from './AlleEksempelDialog.jsx';
import ContextMenu from 'components/ContextMenu';
import './mal.css';
import ReactModal from 'react-modal';

const modalDialogs = {
  AlleEksempelmalDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <AlleEksempelDialog
        onClose={onClose}
        etterLastingAvMaler={owner.etterLastingAvMaler}
        modus='Dokument'
      />
    </NKModal>
  ),
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  MalDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className='malModalMediumX'>
      <MalDialog
        onClose={onClose}
        mal={dialogData.mal}
        onOppdatert={owner.oppdaterListe}
        gjelder={dialogData.gjelder}
        norkartbruker={true}
        modus='Admin'
      />
    </ReactModal>
  ),
};

class Dokumentmaler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      maler: null,
      oppslagP: null,
      oppslagG: null,
      oppslagO: null,
      oppslagF: null,
      swap: true,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    getMalerOppslagsdata('P', 'T', this.gotgOppslagDataP);
    getMalerOppslagsdata('G', 'T', this.gotgOppslagDataG);
    getMalerOppslagsdata('O', 'T', this.gotgOppslagDataO);
    getMalerOppslagsdata('F', 'T', this.gotgOppslagDataF);
  };

  gotgOppslagDataP = (err, data) => {
    this.setState({ oppslagP: data });
  };

  gotgOppslagDataG = (err, data) => {
    this.setState({ oppslagG: data });
  };

  gotgOppslagDataO = (err, data) => {
    this.setState({ oppslagO: data });
  };

  gotgOppslagDataF = (err, data) => {
    this.setState({ oppslagF: data }, this.getEksempelMalerx());
  };


  getEksempelMalerx = () => {
    setTimeout(() => {
      getEksempelMaler(this.gotEksempelmaler);
    }, 1000);
  };

  gotEksempelmaler = (err, maler) => {
    if (maler) {
      for (let i = 0; i < maler.length; i++) {
        let mal = maler[i];
        maler[i].funksjon = this.getFunksjon(mal.gjelderKode, mal.funksjonId);
      }
      this.setState({ maler });
    }
  };

  etterLastingAvMaler = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Eksempelmaler..',
      melding: 'Eksempelmalene for "Dokument" har nå blitt lastet inn.',
    });
    getEksempelMaler(this.gotEksempelmaler);
  };

  oppdaterListe = () => {
    getEksempelMaler(this.gotEksempelmaler);
  };

  alleEksempelmal = () => {
    GetAntallMaler(this.gotAntallMaler);
  };

  gotAntallMaler = (err, antall) => {
    if (antall > 0 && this.state.swap) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Du kan ikke bruke funksjonen..',
        melding: 'Organisasjonen har allerede definert ' + antall + ' maler.',
      });
    } else {
      this.dialogs.setOpenDialog('AlleEksempelmalDialog');
    }
    this.setState({ swap: !this.state.swap });
  };

  getFunksjon = (gjelder, funksjonId) => {
   let  result = null;
    switch (gjelder) {
      case 'P':
        result = _.filter(this.state.oppslagP.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }
      case 'G':
        result = _.filter(this.state.oppslagG.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }
      case 'O':
        result = _.filter(this.state.oppslagO.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }
        case 'F':
        result = _.filter(this.state.oppslagF.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }
      default:
        return '';
    }
  };

  getColumns() {
    let columns = [
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'beskrivelse',
        name: 'Beskrivelse',
        sortParams: 'beskrivelse',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return (
            <span title={item.beskrivelse}>
              {_.truncate(item.beskrivelse, { length: 35 })}
            </span>
          );
        },
      },
      {
        id: 'gjelderKode',
        name: 'Gjelder',
        sortParams: 'gjelderKode',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          switch (item.gjelderKode) {
            case 'P':
              return 'Planlegging';

            case 'G':
              return 'Gjennomføring';

            case 'O':
              return 'Oppfølging';

            case 'F':
              return 'Gen.funksjoner';

            default:
              return 'Planlegging';
          }
        },
      },
      {
        id: 'side',
        name: 'Første side',
        sortParams: 'side',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.hoveddokument) {
            return 'ja';
          } else {
            return 'nei';
          }
        },
      },
      {
        id: 'funksjon',
        name: 'Funksjon',
        sortParams: 'funksjon',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.funksjon.includes('!!')) {
            return <span style={{ color: 'red' }}>{item.funksjon}</span>;
          } else {
            return <span>{item.funksjon}</span>;
          }
        },
      },
      {
        id: 'tekst',
        name: 'Tekstx',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.truncate(item.tekst, { length: 1400 });
          let z = { __html: s };
          let tooltip = <div dangerouslySetInnerHTML={z} />;
          if (s === '') {
            s = 'Tekst mangler..';
          }
          return (
            <span className='text'>
              {' '}
              <NKIcon icon='kommentar' size='0.8' color='nk-black' />
              <span className='text-tooltip'>{tooltip}</span>
            </span>
          );
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => this.rowClick(item)}>
                Rediger mal
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => this.slettMal(item)}>
                Slett mal
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
    return columns;
  }

  rowClick = (mal) => {
    this.dialogs.setOpenDialog('MalDialog', {
      mal: mal,
      gjelder: mal.gjelderKode,
      norkartbruker: true,
    });
  };

  slettMal = (mal) => {
    alert('Slett eksempelmal er ikke implementert enda..');
  };

  nyMal = (gjelderKode, gjelder) => {
    let m = checkEditorNormal('');
    let mal = {
      id: 0,
      funksjonId: 0,
      funksjon: '',
      gjelderKode: gjelderKode,
      tekst: m,
      beskrivelse: '',
      hoveddokument: false,
      tittel: gjelder + ' - Ny eksempelmal ',
    };
    this.rowClick(mal);
  };

  render() {
    if (!this.state.maler) {
      return <div style={{ margin: '30px' }}>Vent, laster data..</div>;
    }
    return (
      <div style={{ margin: '10px' }}>
        <h2>Eksempelmaler Dokument</h2>
        <div style={{ margin: '0' }}>
          <React.Fragment>
            <ToolbarButtonDrop style={{ marginRight: 4 }} icon='pluss'>
              Ny mal
            </ToolbarButtonDrop>
            <div className='btn-group' style={{ marginBottom: '2px' }}>
              <button
                style={{
                  marginRight: '3px',
                  marginBottom: '2px',
                  marginTop: 0,
                  borderColor: 'darkGray',
                  height: '48px',
                }}
                type='button'
                aria-haspopup='true'
                className='btn btn-default dropdown-toggle'
                data-toggle='dropdown'>
                <span className='caret' />
              </button>
              <ul
                className='dropdown-menu'
                style={{ width: '90px' }}
                role='menu'>
                <li  key='u1'>
                  <a onClick={this.nyMal.bind(this, 'P', 'Planlegging')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Planlegging
                    </span>
                  </a>
                </li>
                <li  key='u2'>
                  <a onClick={this.nyMal.bind(this, 'G', 'Gjennomføring')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Gjennomføring
                    </span>
                  </a>
                </li>
                <li  key='u3'>
                  <a onClick={this.nyMal.bind(this, 'O', 'Oppfølging')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Oppfølging
                    </span>
                  </a>
                </li>
                <li  key='u4'>
                  <a onClick={this.nyMal.bind(this, 'F', 'Generelle funksjoner')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                    Generelle funksjoner
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </React.Fragment>
          <ToolbarButton
            style={{ marginRight: 0 }}
            icon='kopier'
            className='pull-right'
            onClick={this.alleEksempelmal}>
            &nbsp;&nbsp;Last inn alle eksempelmaler for "Dokument"
          </ToolbarButton>
        </div>
        <br />
        <ReactTable
          toggleAllDefault={false}
          showIndex={false}
          columns={this.getColumns(this)}
          items={this.state.maler}
          selectableRows={false}
          onRowClick={(row) => this.rowClick(row)}
        />
        <br />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Dokumentmaler;
