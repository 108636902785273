import React from 'react';
import NKModal from './NKModal.jsx';
import { PropTypes } from 'prop-types';

class ModalContainer extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired
  };

  state = { isOpen: true };

  render() {
    let classNameButton = this.props.isEnabled === false
      ? 'btn btn-lg font16 btn-primary disabled'
      : 'btn btn-lg font16 btn-primary';

    return (
      <NKModal size={this.props.size} isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">{this.props.tittel}</h4>
          </div>
          <div className="modal-body">{this.props.children}</div>
          <div className="modal-footer">
            { this.props.buttonText ?
            <button
              className={classNameButton}
              onClick={
                this.props.onSubmit ? this.props.onSubmit : this.props.onClose
              }>
              {this.props.buttonText}
            </button>
            : ''
            }
          </div>
        </div>
      </NKModal>
    );
  }
}

export default ModalContainer;
