import callApi from './api';

export function grunnbokRapport(id, callback) {
    callApi('GET', '/matrikkelgrunnbok/rapport/' + id, null, callback);
};

        /// <param name="borettslagid"></param>
        /// <param name="andelsnummer"></param>

export function grunnbokGetBruksenhetids(  borettslagid, andelsNummer, callback) {
    callApi('GET', '/matrikkelgrunnbok/borett/bruksenhetids/' + borettslagid + '/' + andelsNummer + '/', null, callback);
};



