/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import { getContextBrukere } from 'context/contextOppslag.js';
import { getArbeidslisteByAktiv } from 'api/arbeidslisteApi';
import { GetBruksenhetRoyklopTiltakStatus } from 'api/enhetApi';
import { postVarslingFlytt } from 'api/enhetApi';
import { debugFactory } from 'utils';
import './dialog.css';

const debug = debugFactory('nk:FlyttDialogOppgaverFerdig');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  )
};

class FlyttDialogOppgaverFerdig extends Component {
  constructor(props) {
    super(props);
    this.smseditorRef = null;
    this.state = {
      valgteEnheter: [],
      manuellKansellering: false,
      enheterMedEiere: [],
      mal: null,
      flettefelt: [],
      editortext: '',
      tittel: '..',
      ekstraTittel: '',
      knapp: 'Kanseller',
      adresse: '',
      filtrerteArbeidslister: [],
      arbeidslister: [],
      defaultAnsvarlig: '',
      ansvarlig: '',
      valgtArbeidsliste: '',
      flytt: false,
      ikkeUtf: null
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    getArbeidslisteByAktiv(false, this.gotArbeidslisterLister);
    
    //Trenger ikke å hente derom det ikke skal brukes
    // if (this.props.skalVarsles) {
    //   getSmsKanselleringMal(this.gotMal);
    // }
    this.makeDtoListe();
  }

  fjernThisArbeidsliste = arbeidslister => {
    var lister = arbeidslister.filter(x => {
      return x.id !== this.props.arbeidsliste.id;
    });
    return lister;
  };

  gotArbeidslisterLister = (err, result) => {
    //Fjern aktelle arbeidsliste (hvor man skal flytte fra) fra liste
    let liste = this.fjernThisArbeidsliste(result);
    this.setState({ arbeidslister: liste });
    this.setState({ filtrerteArbeidslister: this.state.arbeidslister });
    console.log(this.state.arbeidslister);
    let defaultId = _.isEmpty(this.state.arbeidslister)
      ? null
      : _.first(this.state.arbeidslister).id;
    this.setState({ valgtArbeidsliste: defaultId });

    getContextBrukere( this.gotPersoner);
  };

  gotPersoner = (personer) => {
    var tmp = personer.brukere;
    var tom = { id: '-1', navn: 'Alle' };
    tmp.unshift(tom);

    let paaLogget = tmp.find(x => x.paaLogget);
    if (paaLogget !== undefined) {
      this.setState({ defaultAnsvarlig: paaLogget });
      this.setState({ ansvarlig: paaLogget.id }, function() {
        this.onSearch();
      });
    }

    this.setState({ personer: tmp });
  };

  onSearch = () => {
    var filtrerteLister = this.filterAnsvarlige(
      this.state.ansvarlig.toString(),
      this.state.arbeidslister
    );
    this.setState({ filtrerteArbeidslister: filtrerteLister });
    let defaultId = _.isEmpty(filtrerteLister)
      ? null
      : _.first(filtrerteLister).id;
    this.setState({ valgtArbeidsliste: defaultId });
  };

  filterAnsvarlige = (input, arblister) => {
    var res = [];

    if (input === '-1') {
      return arblister;
    }
    if (arblister === null || arblister.length === 0) {
      return res;
    }
    arblister.forEach(function(liste) {
      if (liste.ansvarligIds !== null) {
        var ansvarlige = liste.ansvarligIds.split(',');
        if (ansvarlige.filter(x => x === input).length > 0) {
          if (res.length === 0) {
            res.push(liste);
          } else {
            if (res.filter(y => y.id === liste.id).length === 0) {
              res.push(liste);
            }
          }
        }
      }
    });
    return res;
  };

  handleAnsvarligChange = e => {
    this.setState({ defaultAnsvarlig: e.target.value });
    this.setState({ ansvarlig: e.target.value }, function() {
      this.onSearch();
    });
  };

  handleArbeidslisteChange = e => {
    this.setState({ valgtArbeidsliste: e.target.value });
  };

  makeDtoListe = () => {
    let liste = this.props.valgteEnheter;
    let valgteEnheter = [];
    for (let i = 0; i < liste.length; i++) {
      let e = liste[i];
      let obj = {
        bruksenhetId: e.id,
        bruksenhetIdMa: e.bruksenhetIdMa,
        arbeidslisteId: this.props.arbeidsliste.id,
        matrikkelEnhetId: e.matrikkelenhetId,
        tiltaktyper: 'this.makeTiltak(e)',
        varselDato: e.planlagtDato,
        varselstatusId: e.varselstatusId,
        besokFra: e.planlagtBesokFra ? e.planlagtBesokFra : '',
        besokTil: e.planlagtBesokTil ? e.planlagtBesokTil : '',
        adresse: e.adresse,
        oppgaver: e.oppgaver,
        smsTekst: ''
      };
      valgteEnheter.push(obj);
    }
    console.log(valgteEnheter);
    this.setState({ valgteEnheter });
    let enhet = valgteEnheter[0];
    this.setState({
      tittel: 'Flytt "' + enhet.adresse + '" til en annen arbeidsliste'
    });
    GetBruksenhetRoyklopTiltakStatus(enhet.bruksenhetId, enhet.arbeidslisteId, this.gotHistorikk);
    // if (enhet.oppgaver === 'UnderArbeid') {
    //   this.setState({ infoTekst3: 'Arbeid er påbegynt.' });
    // }
  };

  gotHistorikk = (err, res) => {
    console.log(res);
    let statuser = res;
    let ikkeUtfort = [];
    statuser.forEach(element => {
      if (element.statusTekst === 'Ikke utført') {
        this.setState({ flytt: true });
        ikkeUtfort.push(element);
      }
    });
    this.setState( { ikkeUtf: ikkeUtfort });
  }

  makeTiltak = enhet => {
    console.log(enhet);
    let s = '';
    if (enhet.tiltakBruksenhet) {
      s = enhet.tiltakBruksenhet.trim() + ',';
    }
    if (enhet.tiltakRoyklop) {
      s = s + enhet.tiltakRoyklop.trim();
    }
    if (s.charAt(s.length - 1) === ',') {
      s = s.substr(0, s.length - 1);
    }
    s = s.replace(' ', '');
    s = s.replace(',', ', ');
    return s;
  };

  flytt = () => {
    this.setState({ sendInProgress: true });

    let varsles = false;
    let editorText = '';
    // if (this.props.skalVarsles) {
    //   varsles = !this.state.manuellKansellering;
    // } else {
    //   varsles = false;
    // }

    // if (varsles) {
    //   editorText = this.getEditorTekst();
    // }
    // if (editorText === 'ERROR') {
    //   this.dialogs.setOpenDialog('OkDialog', {
    //     tittel: 'For mange tegn i sms',
    //     melding:
    //       'For å unngå feil ved sending av sms og for å unngå ekstra kostnader håndterer vi en begrensing av 500 tegn.'
    //   });
    //   return;
    // }

    let obj = this.state.valgteEnheter[0];
    obj.smsTekst = editorText;
    obj.varselKanseller = varsles;
    obj.ingenValidering = false;
    obj.arbeidslisteIdFlytt = this.state.valgtArbeidsliste;
    if (obj) {
      this.fortsettFlytt(obj);
    }
  };

  fortsettFlytt = obj => {
    debug('flytt sendes nå', obj);
    postVarslingFlytt(obj, this.sendSmsFlyttHarBlittSend);
  };

  sendSmsFlyttHarBlittSend = (err, melding) => {
    debug('flytt blitt sendt 1?', err);
    debug('flytt blitt sendt 2?', melding);
    this.setState({ sendInProgress: false }, this.props.refreshArbeidslisteFlytt(melding));
  };

  avslutt = () => {
    this.props.onClose();
  }; 

  render() {
    
    return (
      <div>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <table>
            <tbody>
              <tr>
                <td>
                  <h4 className='modal-title'>{this.state.tittel}</h4>
                </td>
                <td>&nbsp;&nbsp;</td>
                <td style={{ fontSize: '12px', color: 'blue' }}>
                  {this.state.infoTekst3}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* BODY */}
        <div
          className='modal-body'
          style={{
            height: '90%',
            paddingTop: '4px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '4px'
          }}>
          {!this.state.flytt && (
            <div>
              <h4>Alle oppgaver er utført</h4>
            </div>
          )}

        {this.state.flytt && (
          <React.Fragment>
            <p>Velg en arbeidsliste:</p>

            <div className='row'>
              <div className='col-sm-8'>
                <label>Ansvarlig</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 '>
                <select
                  className='form-control'
                  id='type'
                  style={{ width: '100%' }}
                  onChange={this.handleAnsvarligChange}
                  value={
                    this.state.defaultAnsvarlig !== ''
                      ? this.state.defaultAnsvarlig.id
                      : ''
                  }>
                  {_.map(this.state.personer, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className='row' style={{ marginTop: '10px' }}>
              <div className='col-sm-8'>
                <label>Flytt til arbeidsliste</label>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <select
                  className='form-control'
                  style={{ width: '100%' }}
                  id='arbliste'
                  onChange={this.handleArbeidslisteChange}
                  value={
                    this.state.valgtArbeidsliste !== ''
                      ? this.state.valgtArbeidsliste
                      : ''
                  }

                  /*   defaultValue={
                    _.isEmpty(this.state.filtrerteArbeidslister)
                      ? ''
                      : _.first(this.state.filtrerteArbeidslister).id
                  } */
                >
                  {_.map(this.state.filtrerteArbeidslister, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn} ({o.antallBruksenheter})
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <br />
          </React.Fragment>
        )}
        {this.state.ikkeUtf &&  (
          <div>
            <br/>
            <h4>Tiltak som ikke er utført:</h4>
             {this.state.ikkeUtf.map(function(x) {
               return (<div>{x.tiltak}</div>);
                  
                })
              }
          </div>
        )}
        
        </div>

        <div className='malModalFooter'>
        {this.state.flytt && (
          <button onClick={this.flytt} className='btn btn-primary pull-right'>
          Flytt
        </button>
        )}
        {!this.state.flytt && (
          <button onClick={this.avslutt} className='btn btn-primary pull-right'>
          Avslutt
        </button>
        )}
          
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default FlyttDialogOppgaverFerdig;
