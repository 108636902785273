import React from 'react';
import './AvvikAnmerkninger.css';
import anmerkningApi from 'api/anmerkningApi';
import avvikApi from 'api/avvikApi';
import NKIcon from 'nka-icons';
import NKModal from 'components/Dialogs/NKModal.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import RedigerTekstbit from './RedigerTekstbit.jsx';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:tekstbit');


const modalDialogs = {
  SletteDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Slette'
      melding='Ønsker du å slette teksten?'
      isOpen={isOpen}
      onClose={onClose}
      onOk={owner.slettOk.bind(this)}
    />
  ),
  RedigerDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <RedigerTekstbit onClose={onClose} 
        lagreTekstbit= {owner.lagreTekstbit}
        tittel='Rediger tekstbit'
        bit={dialogData.bit}/>
      </NKModal>
    );
  },
};

class SoekTekstbit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tittel: 'Søk i tekstbiter for ' + this.props.modul,
      tekstData: [],
      tekstFilterData: [],
      soktekst: '',
      slettId: null,
      hideNull: 'hide',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.slettOk = this.slettOk.bind(this);
  }

  componentDidMount() {
    this.hentData();
  }

  hentData() {
    switch (this.props.modul) {
      case 'anmerkninger':
        // anmerkningApi.getAnmerkningAdmBeskrivelse(this.gotTekstbiter.bind(this));
        oppslagsdataApi.getAnmerkningBeskrivelse(this.gotTekstbiter.bind(this));
        break;
      case 'avvik':
        // avvikApi.getAvvikAdmBeskrivelse(this.gotTekstbiter.bind(this));
        oppslagsdataApi.getAvvikBeskrivelse(this.gotTekstbiter.bind(this));
        break;
      default:
        return;
    }
  }

  gotTekstbiter(err, txtData) {
    if (txtData !== undefined && txtData.length > 0) {
      this.setState({ tekstData: txtData });
    } else {
      this.setState({ hideNull: '' });
    }
    var newArray = txtData.filter(function(el) {
      return el.aktiv === true;
    });
    if (this.state.soktekst !== ''){
      this.sok2();
    }
  }

  sok(event) {
    var soktekst = event.target.value;
    this.setState({ soktekst: soktekst });
    var matches = [];
    if (soktekst.length > 0) {
      this.state.tekstData.forEach(function(a) {
        if (a.tekst) {
          if (
            a.tekst.toLowerCase().indexOf(soktekst.toLowerCase()) > -1 ||
            a.id.toString() === soktekst
          ) {
            matches.push(a);
          }
        }
      });
    }
    this.setState({ tekstFilterData: matches });
  }

  
  sok2= () => {
    var soktekst = this.state.soktekst;
    var matches = [];
    if (soktekst.length > 0) {
      this.state.tekstData.forEach(function(a) {
        if (a.tekst) {
          if (
            a.tekst.toLowerCase().indexOf(soktekst.toLowerCase()) > -1 ||
            a.id.toString() === soktekst
          ) {
            matches.push(a);
          }
        }
      });
    }
    this.setState({ tekstFilterData: matches });
  }

  green(txt) {
    let s = this.state.soktekst;
    let x = '<mark>' + s + '</mark>';
    let nyText = txt.toLowerCase().replace(new RegExp(s, 'g'), x);
    return { __html: nyText };
  }

  slettSoekTekst() {
    this.refs.sokboks.value = '';
    this.setState({ tekstFilterData: [] });
    this.refs.sokboks.focus();
  }

  brukTekst = (txt) => {
    this.props.onVelgTekst(txt);
  };

  slettTekst(tid) {
    this.setState({ slettId: tid });
    this.dialogs.setOpenDialog('SletteDialog');
  }

  redigerTekst= (bit) => {
    this.dialogs.setOpenDialog('RedigerDialog', { bit: bit });
  }

  lagreTekstbit= (bit) => {
    this.dialogs.closeDialog();
    switch (this.props.modul) {
      case 'anmerkninger':
        anmerkningApi.updateAdmBeskrivelse( bit.id,
          bit,
          this.oppdaterEtterRediger
        );
        break;
      case 'avvik':
        avvikApi.updateAdmBeskrivelse( bit.id,
          bit,
          this.oppdaterEtterRediger
        );
        break;
      default:
        anmerkningApi.updateAdmBeskrivelse( bit.id,
          bit,
          this.oppdaterEtterRediger
        );
    } 
  };

  oppdaterEtterRediger= (err, data) => {
    this.hentData();
  };

  oppdaterEtterSlett(tid) {
    let itemsO = this.state.tekstData;
    for (var i = 0; i < itemsO.length; i++) {
      if (itemsO[i].id && itemsO[i].id === tid) {
        itemsO.splice(i, 1);
        break;
      }
    }

    this.setState({ tekstData: itemsO });

    let items = this.state.tekstFilterData;
    for (var y = 0; y < items.length; y++) {
      if (items[y].id && items[y].id === tid) {
        items.splice(y, 1);
        break;
      }
    }

    this.setState({ tekstFilterData: items });
  }

  slettOk() {
    this.dialogs.closeDialog();
    switch (this.props.modul) {
      case 'anmerkninger':
        anmerkningApi.deleteAdmBeskrivelse(
          this.state.slettId,
          this.oppdaterEtterSlett.bind(this, this.state.slettId)
        );
        break;
      case 'avvik':
        avvikApi.deleteAdmBeskrivelse(
          this.state.slettId,
          this.oppdaterEtterSlett.bind(this, this.state.slettId)
        );
        break;
      default:
        anmerkningApi.deleteAdmBeskrivelse(
          this.state.slettId,
          this.oppdaterEtterSlett.bind(this, this.state.slettId)
        );
    }
    this.setState({ slettId: null });
  }

  render() {
    return (
      <div className='modal-content height700'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {this.state.tittel} </h4>
        </div>
        <div className='modal-body'>
          <div>
            <input
              autoFocus
              type='text'
              ref='sokboks'
              className='form-control w95 h34 samme'
              placeholder='Søk...'
              onChange={this.sok.bind(this)}
            />
            <button
              className='btn btn-default h48 samme marginBottom2'
              onClick={this.slettSoekTekst.bind(this)}>
              <span className='glyphicon glyphicon-erase' />
            </button>
          </div>
          <br />
          <label className={this.state.hideNull}>
            Ingen tekstbiter definert enda..{' '}
          </label>
          <div className='scrollerTekster'>
            {this.state.tekstFilterData === null
              ? ''
              : this.state.tekstFilterData.map(function(tekst) {
                  var t = this.green(tekst.tekst);
                  var txt = tekst.tekst;
                  var tid = tekst.id;

                  return (
                    <div className='row' key={'row' + tid}>
                      <div className='m15'>
                        <button
                          className='btn btn-default w95b pull-left'
                          onClick={this.brukTekst.bind(this, txt)}
                          key={'sa' + tid}>
                          <div ref='texta' id='texta'>
                            <span dangerouslySetInnerHTML={t} />
                          </div>
                        </button>
                        <button
                          className='btn btn-default h48'
                          onClick={this.redigerTekst.bind(this, tekst)}>
                          <NKIcon icon='rediger' size='0.8' color='nk-black' />
                        </button>
                        <button
                          className='btn btn-default h48'
                          onClick={this.slettTekst.bind(this, tid)}>
                          <NKIcon
                            icon='soppelbotte'
                            size='0.8'
                            color='nk-black'
                          />
                        </button>
                      </div>
                    </div>
                  );
                }, this)}
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SoekTekstbit;
