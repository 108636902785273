import CONFIG from 'lib/CONFIG';

const FROALA_KEY = CONFIG.REACT_APP_FROALA;

const getStar = () => {
    let visStar = 'starX';
  /*   if (this.state.isNorkartBruker) {
      visStar = 'starX';
    } */
    return visStar;
};
  
export const configX = () => {
    return {
    toolbarInline: false,
    toolbarVisibleWithoutSelection: false,
    key: FROALA_KEY,
    attribution: false,
    placeholderText: 'Skriv en kommentar..',
    autoFocus: true,
    tabSpaces: 4,
    imagePaste: false,
    listAdvancedTypes: false,
    spellcheck: false,
    language: 'nb',
    heightMin: '140px',
    heightMax: '200px',
    quickInsertEnabled: false,
    lineheight: '150%', 
    emoticonsStep: 4,
    emoticonsSet: [
      {
        id: 'people',
        name: 'Ansikt',
        code: '1f642',
        emoticons: [
          { code: '1f642', desc: 'Smilende ansikt' },
          { code: '1f641', desc: 'Trist ansikt' },
          { code: '1F609', desc: 'Winking face' },
          { code: '1f600', desc: 'Grinning face' },
          { code: '1f601', desc: 'Grinning face with smiling eyes' },
          { code: '1f602', desc: 'Face with tears of joy' },
          { code: '1f615', desc: 'Confused face' },
          {
            code: '1f604',
            desc: 'Smiling face with open mouth and smiling eyes',
          },
          {
            code: '1f605',
            desc: 'Smiling face with open mouth and cold sweat',
          },
        ],
      },
      {
        id: 'hands',
        name: 'Hånd',
        code: '1F44D',
        emoticons: [
          { code: '1F44D', desc: 'Tommel opp' },
          { code: '1F44E', desc: 'Tommel ned' },
          { code: '1F44C', desc: 'Perfekt' },
          { code: '1F44E', desc: 'Tommel ned' },
          { code: '1F44F', desc: 'Applaus' },
          { code: '1F525', desc: 'Brann' },
          { code: '26A0', desc: 'Varsel' },
        ],
      },
    ],
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          '|',
          'formatOL',
          'formatUL',
          '|',
          'specialCharacters',
        /*   'emoticons', */
          'undo',
          'redo',
          getStar(),
        ],
        align: 'left',
        buttonsVisible: 3,
      },
    },
    toolbarButtonsMD: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          '|',
          'formatOL',
          'formatUL',
          '|',
          'specialCharacters',
          'emoticons',
        ],
        align: 'left',
        buttonsVisible: 3,
      },
    },
    toolbarButtonsXS: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          '|',
          'formatOL',
          'formatUL',
          '|',
          'specialCharacters',
         
        ],
        align: 'left',
        buttonsVisible: 3,
      },
    },
    charCounterCount: false,
};
  };