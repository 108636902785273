import React from 'react';
import _ from 'lodash';
import Tiltakshistorikk from './Tiltakshistorikk';

export default class extends React.Component {
  state = {
    laster: true,
    id: null,
    breadcrumbname: 'Laster....',
    type: _.get(this.props, 'location.state.type'),
  };

  componentDidMount() {
    let id = this.props.location.state ? this.props.location.state.id : this.props.id;
    console.log('FREX', this.props);
    console.log('FREX', id);
    this.setState({ id });
    setTimeout(() => {
      this.setState({ laster: false });
    }, 1000);
    //SteinType: her har jeg litt ekstra tid for å være sikkert at type har kommet på plass.
  }

  render() {
    if (this.state.laster) {
      return <div className='loader' />;
    }
    return (
      <div>
        <Tiltakshistorikk
          type={this.state.type}
          id={this.state.id}
          base_path={
            '/gjennomforing/' +
            this.props.params.arbeidslisteId +
            '/' +
            this.props.params.arbeidslisteNavn
          }
        />
      </div>
    );
  }
}
