import React from 'react';
import DialogTable from './DialogTable.jsx';

class DialogHistorikk extends React.Component {
  state = {
    type: this.props.location ? this.props.location.state.type : this.props.type,
    adresse: this.props.location ? this.props.location.state.adresse : this.props.adresse,
    id: this.props.location ? this.props.location.state.id : this.props.id
  };

  render() {    
    return (
      <div>
        <DialogTable
          type={this.state.type}
          id={this.state.id ? this.state.id : this.props.id}
          adresse={this.state.adresse ? this.state.adresse : this.props.adresse}

          base_path={
            this.props.params
              ? '/gjennomforing/' +
                this.props.params.arbeidslisteId +
                '/' +
                this.props.params.arbeidslisteNavn
              : null
          }
        />
      </div>
    );
  }
}

export default DialogHistorikk;