import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';
import { objGetDeep } from 'utils';

export default function(props) {
  const pathname = objGetDeep(props, 'location.pathname');

  return (
    <div>
      <div className='row'>
        <div className='col-sm-12 col-md-8 col-lg-6'>
          <LinkList title='Systeminformasjon' columns={1}>
            <LinkListItem title='System stop ved innlogging' path={`${pathname}/DriftsmeldingSystem`} />
            <LinkListItem title='Driftsmeldinger startside' path={`${pathname}/DriftsmeldingerHome`} />
            <LinkListItem title='Informasjon startside' path={`${pathname}/DriftsmeldingerKunde`} /> {/*  Kanskje endre etterhvert */}
          </LinkList>
        </div>
      </div>
    </div>
  );
}