import React, { useState, useEffect, createContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import IconButton from '@mui/material/IconButton';
import NKIcon from 'nka-icons';
import './dialogs.css';

const JaNeiDialog = ({
  tittel,
  tekst,
  tekst2,
  isOpen,
  handleClose,
  visClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          m: 10,
          top: 100,
          left: 200,
        },
      }}
      onClose={handleClose}>
      <DialogTitle>
        <span style={{ fontSize: '16px', paddingTop: '24px' }}>{tittel}</span>

        {/* Desrsom visClose, da viser vi en close-button X */}
        {visClose && (
          <span className='closeButton'>
            <IconButton
              aria-label='close'
              onClick={() => handleClose(null)}>
              <NKIcon icon='lukk' size='1' color='nk-black' />
            </IconButton>
          </span>
        )}
      </DialogTitle>
      <DialogContent style={{ width: '400px' }}>
        <DialogContentText style={{ fontSize: '16px' }}>
          {tekst}
          <br /> 
          {tekst2}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '0px', borderTop: '1px solid #51a026' }}>
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose('ja')}
          buttontype='primary'
          autoFocus>
          <span>Ja</span>
        </NkaButton>
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose('nei')}
          buttontype='default'>
          Nei
        </NkaButton>
      </DialogActions>
    </Dialog>
  );
};

export default JaNeiDialog;
