import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { updateKommune } from 'api/kommuneApi';

class EndreKommuner extends React.Component {
  constructor(props) {
    super();
    this.state = {
      kommune: props.kommune
    };
    this.onUpdatedKommune = this.onUpdatedKommune.bind(this);
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps !== prevProps) {
      this.setState({ kommune: nextProps.kommune });
    }
  }

  onUpdatedKommune(err, result) {
    if (err) {
      alert('Kunne ikke lagre. Sjekk opplysningene!');
    } else {
      this.props.oppdater(true);
    }
  }
 
  handleChangeKtfMeldingstype = (e) => {
    let value = e.target.value.replace(/\D/g, '');

   let kommune = this.state.kommune;
   kommune.ktfMeldingstypeId= value;
   this.setState({ kommune });
  };

  handleBlurKtfMeldingstype = (e) => {
    let value = e.target.value;
    if (value === '' ){
      value = 0;
    }
    let kommune = this.state.kommune;
    kommune.ktfMeldingstypeId= value;
    this.setState({ kommune });
  };

  render() {
    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header modal-header-info">
            <button
              className="close"
              onClick={() => {
                this.setState({ kommune: null });
                this.props.onClose();
              }}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">{this.props.tittel}</h4>
          </div>
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="form">
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Aktiv</label>
                    </div>
                    <div className="col-sm-8 form-check">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        checked={this.state.kommune.aktiv}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              aktiv: !this.state.kommune.aktiv
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Kommunenummer</label>
                    </div>
                    <div className="col-sm-4 form-group">
                      <input
                        maxLength="50"
                        className="form-control"
                        type="text"
                        value={this.state.kommune.kommuneId}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              kommuneId: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Kommunenavn</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={this.state.kommune.kommuneNavn}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              kommuneNavn: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>ReplikKundeId</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={this.state.kommune.replikKundeId}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              replikKundeId: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Ktf MeldingstypeId</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={this.state.kommune.ktfMeldingstypeId}
                        onChange={this.handleChangeKtfMeldingstype}
                        onBlur={this.handleBlurKtfMeldingstype}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Ktf røykløpmelding</label> 
                    </div>
                    <div className="col-sm-8 form-check">
                      <input
                        className="form-check-input"
                        style={{ width: '20px', height: '20px' }}
                        type="checkbox"
                        checked={this.state.kommune.royklopMelding}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              royklopMelding: !this.state.kommune.royklopMelding
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <hr></hr>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Gammel Kom.nummer</label>
                    </div>
                    <div className="col-sm-4 form-group">
                      <input
                        maxLength="50"
                        className="form-control"
                        type="text"
                        value={this.state.kommune.kommuneIdGammel}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              kommuneIdGammel: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <label>Gammel Kom.navn</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={this.state.kommune.kommuneNavnGammel}
                        onChange={e => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              kommuneNavnGammel: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary pull-right"
              onClick={() =>
                updateKommune(
                  this.state.kommune.id,
                  this.state.kommune,
                  this.onUpdatedKommune
                )
              }>
              OK
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default EndreKommuner;
