import React from 'react';
import NumericInput from 'react-numeric-input';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'react-select/dist/react-select.css';
import { isPersonNorkartAdmin } from 'context/contextInnstillinger.js';
import moment from 'moment';
import { getRoyklopById } from 'api/royklopApi';
import PrikProdusentModell from 'components/Dialogs/PrikProdusentModell.js';
import 'moment/locale/nb';
import 'modules/Gjennomforing/gjennomforing.css';
import _ from 'lodash';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import { debugFactory, validatableMoment } from 'utils';
import RestrictedDatePicker from 'components/RestrictedDatePicker';
import DialogStore from 'lib/DialogStore';
import { getContextRoyklopOppslag } from 'context/contextOppslag';
import 'react-datepicker/dist/react-datepicker.css';


const debug = debugFactory('nk:modules:gjennomforing:endreroyklop');

var endretRoyklop = null;

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Lagre endringer'
        melding='Du har ikke valgt en modell.  Ønsker du å lagre likevel? (Produsent blir ikke lagret heller.)'
        onJa={owner.lagreJa}
        onNei={onClose}
      />
    );
  },
};

class EndreRoyklop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      laster: false,
      royklop: null,
      royklopTypedata: null,
      selectedProdusent: '',
      selectedType: null,
      selectedModell: '',
      modeller: null,
      produktUrl: '',
      startDate: moment(),
      endDate: moment().add(2, 'month'),
      pipebrann: null,
      error: null,
      dokumentasjon: '',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }
  componentDidMount() {
    this.setState({ laster: true  });
    getRoyklopById(this.props.royklopId, this.gotRoyklop);

  }

  gotRoyklop = (err, royklop) => {
    endretRoyklop = royklop;
    this.setState({
      royklop: royklop,
      endreRisiko: royklop.risikoverdiOverstyr,
      pipebrann: royklop.pipebrann !== null ? moment(royklop.pipebrann) : null,
      opprinneligOverstyr: royklop.risikoverdiOverstyr,
      opprinneligRisikoVerdi: royklop.risikoverdi,
      selectedModell: royklop.modellId,
    });

    getContextRoyklopOppslag(this.gotRoyklopTypeData);
    royklop.risikoverdiOverstyr
      ? this.setState({ endreRisikoText: 'Ja' })
      : this.setState({ endreRisikoText: 'Nei' });
  };

  gotRoyklopTypeData = (data) => {
    debug('FFF 1', data);
    this.setState({ royklopTypedata: data });
    var mod = [];
    for (var i = 0; i < data.modellListe.length; i++) {
      if (
        data.modellListe[i].fremmedNokkel === this.state.royklop.produsentId
      ) {
        mod.push(this.state.royklopTypedata.modellListe[i]);
      }
    }
    this.setState({
      modeller: mod,
      selectedModell: this.state.royklop.modellId,
    });
    this.getDokumentasjon(this.state.royklop.modellId);
    this.setState({ laster: false  });
  };

  gotRoyklopTypeDataOld = (data) => {
    debug('FFF 2', data);
    data.produsentListe.unshift({ id: 0, tekst: '' });
    data.typeListe.unshift({ id: 0, tekst: '' });
    data.sotmengdeListe.unshift({ id: 0, tekst: '' });
    data.sotuttakListe.unshift({ id: 0, tekst: '' });
    data.plasseringListe.unshift({ id: 0, tekst: '' });
    data.dimensjonListe.unshift({ id: 0, tekst: '' });
    data.adkomstListe.unshift({ id: 0, tekst: '' });
    this.setState({ royklopTypedata: data });
    var mod = [];
    for (var i = 0; i < data.modellListe.length; i++) {
      if (
        data.modellListe[i].fremmedNokkel === this.state.royklop.produsentId
      ) {
        mod.push(this.state.royklopTypedata.modellListe[i]);
      }
    }
    this.setState({
      modeller: mod,
      selectedModell: this.state.royklop.modellId,
    });
    this.getDokumentasjon(this.state.royklop.modellId);
  };

  typeChange = (val) => {
    endretRoyklop.typeId = val.target.value === '0' ? null : val.target.value;
  };

  produsentChange = (val) => {
    var mod = [];
    for (var i = 0; i < this.state.royklopTypedata.modellListe.length; i++) {
      if (
        this.state.royklopTypedata.modellListe[i].fremmedNokkel.toString() ===
        val.target.value
      ) {
        mod.push(this.state.royklopTypedata.modellListe[i]);
      }
    }
    this.setState({
      selectedProdusent: val,
      selectedModell: '',
      modeller: mod,
      produktUrl: val.link,
    });
    endretRoyklop.produsentId = parseInt(val.target.value);
    endretRoyklop.modellId = mod.length > 0 ? mod[0].id : null;
  };

  modellChange = (val) => {
    let modellId = val.target.value;
    endretRoyklop.modellId = val.target.value;
    this.setState({ selectedModell: val.target.value });
    this.getDokumentasjon(modellId);
  };

  getDokumentasjon = (modellId) => {
    this.setState({ dokumentasjon: '' });
    if (this.state.royklopTypedata.modellListe) {
      for (let i = 0; i < this.state.royklopTypedata.modellListe.length; i++) {
        let element = this.state.royklopTypedata.modellListe[i];
        if (element.id === modellId) {
          this.setState({ dokumentasjon: element.tekst2 });
        }
      }
    }
  };

  rehabChange = (val) => {
    endretRoyklop.rehabilitert =
      val.currentTarget.value === 'ja' ? true : false;
  };

  dimensjonChange = (val) => {
    endretRoyklop.dimensjonId =
      val.target.value !== '0' ? val.target.value : null;
  };

  pipebrannChange = (val) => {
    const date = validatableMoment(val);
    this.setState({ pipebrann: date });
    endretRoyklop.pipebrann = date.isValid()
      ? date.format('YYYY-MM-DDTHH:00:00')
      : null;
  };

  feietilkomstChange = (val) => {
    endretRoyklop.adkomstId =
      val.target.value !== '0' ? val.target.value : null;
  };

  sotuttakChange = (val) => {
    endretRoyklop.sotuttakId =
      val.target.value !== '0' ? val.target.value : null;
  };

  sotmengdeChange = (val) => {
    endretRoyklop.sotmengdeId =
      val.target.value !== '0' ? val.target.value : null;
  };

  merknadChange = (val) => {
    endretRoyklop.merknad = val.target.value;
  };
  lengdeChange = (val) => {
    endretRoyklop.lengdeHoyde = val;
  };

  plasseringChange = (val) => {
    endretRoyklop.plasseringId =
      val.target.value !== '0' ? val.target.value : null;
  };

  risikoChange = (val) => {
    val > 100
      ? (endretRoyklop.risikoverdi = 100)
      : (endretRoyklop.risikoverdi = val);
  };

  endreRisikoChange = () => {
    let endreRisiko = !this.state.endreRisiko;
    this.setendreRisiko(endreRisiko);
  };

  setendreRisiko = (endreRisiko) => {
    if (endreRisiko) {
      this.setState({
        endreRisiko: true,
        endreRisikoText: 'Ja',
      });
      endretRoyklop.risikoverdiOverstyr = true;
    } else {
      this.setState({
        endreRisiko: false,
        endreRisikoText: 'Nei',
      });
      endretRoyklop.risikoverdiOverstyr = false;
    }
  };

  risikoverdiOverstyrAarsakChange = (val) => {
    endretRoyklop.risikoverdiOverstyrAarsak = val.currentTarget.value;
  };

  submit = () => {
    if (endretRoyklop.modellId == null || endretRoyklop.modellId === '') {
      this.dialogs.setOpenDialog('JaNeiDialog');
    } else {
      this.lagre();
    }
  };

  lagre = () => {
    if (endretRoyklop.risikoverdi !== this.state.opprinneligRisikoVerdi) {
      if (!endretRoyklop.risikoverdiOverstyr) {
        endretRoyklop.risikoverdi = this.state.opprinneligRisikoVerdi;
      }
    }
    this.props.onEndreRoyklop(endretRoyklop);
  };

  lagreJa = () => {
    this.lagre();
    this.dialogs.closeDialog();
  };

  onValgtModell = (item) => {
    endretRoyklop.produsentId = item.produsentId;
    endretRoyklop.modellId = item.modellId;
    this.setState({ dokumentasjon: item.dokumentasjon });
  };

  render() {
    if (!this.state.royklop || this.state.laster ) {
      return <div className='loader'>Laster...</div>;
    }
    if (this.state.error) {
      return (
        <div>
          {'En feil har oppstått, forsøk å laste siden på nytt (F5). ' +
            this.state.error.message}
        </div>
      );
    }

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Endre røykløp</h4>
        </div>
        <div className='modal-body'>
          <form>
            <div className='panel panel-default' style={{ marginBottom: '0' }}>
              <div className='panel-heading'>Produktinfo</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group '>
                      <label>Type</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.typeChange}
                        defaultValue={this.state.royklop.typeId}>
                        {_.map(this.state.royklopTypedata.typeListe, function(
                          o,
                          i
                        ) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group '>
                      <label>Produsent</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.produsentChange}
                        defaultValue={this.state.royklop.produsentId}>
                        {_.map(
                          this.state.royklopTypedata.produsentListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group '>
                      <label>Modell</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.modellChange}
                        value={this.state.selectedModell}>
                        {_.map(this.state.modeller, function(o, i) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

     {/*            {isPersonNorkartAdmin && (
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form-group '>
                        <label>Type</label>
                        <select
                          className='form-control'
                          id='type'
                          onChange={this.typeChange}
                          defaultValue={this.state.royklop.typeId}>
                          {_.map(this.state.royklopTypedata.typeListe, function(
                            o,
                            i
                          ) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className='col-sm-8'>
                      <div className='form-group '>
                        <label>Produsent / Modell</label>
                        <PrikProdusentModell
                          type='Royklop'
                          width='330px'
                          value={this.state.royklop.modellId}
                          onValgtModell={this.onValgtModell}
                        />
                      </div>
                    </div>
                  </div>
                )} */}

                <div className='row'>
                  <div className='col-sm-12'>
                    <label>Dokumentasjon</label>
                    <br />
                    {this.state.dokumentasjon !== '' &&
                    this.state.dokumentasjon != null ? (
                      <a href={this.state.dokumentasjon} target='_blank'>
                        {this.state.dokumentasjon.length > 60
                          ? this.state.dokumentasjon.substring(0, 60) + '...'
                          : this.state.dokumentasjon}
                      </a>
                    ) : (
                      <span>Ikke tilgjengelig</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='panel panel-default' style={{ marginBottom: '0' }}>
              <div className='panel-heading'>Størrelse og plassering</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Dimensjon</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.dimensjonChange}
                        defaultValue={this.state.royklop.dimensjonId}>
                        {_.map(
                          this.state.royklopTypedata.dimensjonListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Lengde (meter)</label>
                      <br />
                      <div>
                        <NumericInput
                          type='number'
                          mobile={true}
                          className='width100 hoyde34'
                          min={0}
                          max={200}
                          defaultValue=''
                          value={this.state.royklop.lengdeHoyde}
                          onChange={this.lengdeChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Plassering</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.plasseringChange}
                        defaultValue={this.state.royklop.plasseringId}>
                        {_.map(
                          this.state.royklopTypedata.plasseringListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>

                  <div className='col-sm-3'>
                    <div className='form-group'>
                      <label>Sotuttak</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.sotuttakChange}
                        defaultValue={this.state.royklop.sotuttakId}>
                        {_.map(
                          this.state.royklopTypedata.sotuttakListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='panel panel-default' style={{ marginBottom: '0' }}>
              <div className='panel-heading'>Annet</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Rehabilitert</label>
                      <br />
                      <div className='radio inline'>
                        <label>
                          <input
                            type='radio'
                            name='sentralRadio'
                            value='ja'
                            defaultChecked={
                              this.state.royklop.rehabilitert === true
                            }
                            onChange={this.rehabChange}
                          />
                          Ja
                        </label>
                      </div>
                      <div className='radio inline margin-left-15'>
                        <label>
                          <input
                            type='radio'
                            name='sentralRadio'
                            value='nei'
                            defaultChecked={
                              this.state.royklop.rehabilitert === false
                            }
                            onChange={this.rehabChange}
                          />
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Pipebrann</label>
                      <RestrictedDatePicker
                        className='width120Heigth32'
                        showYearDropdown
                        locale='no-nb'
                        todayButton={'I dag'}
                        isClearable={true}
                        placeholderText='Velg dato'
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={this.pipebrannChange}
                        onBlur={this.pipebrannChange}
                        selected={this.state.pipebrann}
                      />
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <div className='form-group'>
                        <label>Feieadkomst</label>
                        <select
                          className='form-control'
                          id='type'
                          onChange={this.feietilkomstChange}
                          defaultValue={this.state.royklop.adkomstId}>
                          {_.map(
                            this.state.royklopTypedata.adkomstListe,
                            function(o, i) {
                              return (
                                <option key={i} value={o.id}>
                                  {o.tekst}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Sotmengde (siste)</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.sotmengdeChange}
                        disabled='true'
                        defaultValue={this.state.royklop.sotmengdeId}>
                        {_.map(
                          this.state.royklopTypedata.sotmengdeListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Risikoverdi</label>
                      <br />
                      <div>
                        <NumericInput
                          type='number'
                          mobile={true}
                          className='width100 height38'
                          min={0}
                          max={100}
                          defaultValue=''
                          value={this.state.royklop.risikoverdi}
                          onChange={this.risikoChange}
                          disabled={!this.state.endreRisiko}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <div className='form-group'>
                        <label>Overstyr risiko</label>
                        <br />
                        <div className='radio inline'>
                          <label>
                            <input
                              type='radio'
                              name='overstyrRadio'
                              value='ja'
                              defaultChecked={this.state.endreRisiko === true}
                              onChange={this.endreRisikoChange}
                            />
                            Ja
                          </label>
                        </div>
                        <div className='radio inline margin-left-15'>
                          <label>
                            <input
                              type='radio'
                              name='overstyrRadio'
                              value='nei'
                              defaultChecked={this.state.endreRisiko === false}
                              onChange={this.endreRisikoChange}
                            />
                            Nei
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.endreRisiko ? (
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Årsak til overstyring</label>
                        <textarea
                          className='form-control'
                          type='textarea'
                          id='message'
                          placeholder='Årsak ...'
                          maxLength='140'
                          rows='1'
                          defaultValue={
                            this.state.royklop.risikoverdiOverstyrAarsak
                          }
                          onChange={this.risikoverdiOverstyrAarsakChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <label>Merknad</label>
                    <textarea
                      className='form-control textareaNoresize'
                      type='textarea'
                      id='message'
                      placeholder='Merknad'
                      maxLength='2000'
                      rows='4'
                      onChange={this.merknadChange}
                      defaultValue={this.state.royklop.merknad}
                    />
                  </div>
                </div>
                <br />
                <div className='row'>
                  <div className='col-sm-12 col-lg-12 col-md-12'>
                    <div className='pull-right'>
                      <input
                        type='button'
                        className='btn btn-primary height48'
                        value='OK'
                        onClick={this.submit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default EndreRoyklop;
