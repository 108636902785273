import React from 'react';
import NKIcon from 'nka-icons';
//Utils
import { intersection } from 'lodash';
import { debugFactory } from 'utils';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:lib:enhancers:withauthorization');

const Authorize = (props) => {
  debug('props', { ...props });

  const { allowedRoles, Component, AccessDenied } = props;

  let userRoles = [];

  if (contextInnstillingerPerson._currentValue) {
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      userRoles.push('norkartAdmin');
     //test  userRoles.push('organisasjonAdmin');
    }

    if (contextInnstillingerPerson._currentValue.organisasjonAdmin) {
      userRoles.push('organisasjonAdmin');
    }

    if (contextInnstillingerPerson._currentValue.norkartBruker) {
      userRoles.push('norkartBruker');
    }
  }

  const isAuthorized = !!(intersection(allowedRoles, userRoles).length > 0);

  debug('Authorize', { allowedRoles, userRoles, isAuthorized });

  return isAuthorized ? (
    <Component {...props.componentProps} />
  ) : AccessDenied ? (
    <div style={{ padding: '50px' }}>
      <br />
      <br />
      <span style={{ marginRight: '20px' }}>
        {' '}
        <NKIcon icon='lukket' size='1.2' color='nk-black' />
      </span>
      Obs! Ingen tilgang!
    </div>
  ) : null;
};

const AuthorizeWithUserPermissions = Authorize;

export const withAuthorization = (allowedRoles) => (
  Component,
  AccessDenied = null
) => (props) => (
  <AuthorizeWithUserPermissions
    {...props}
    Component={Component}
    AccessDenied={AccessDenied}
    allowedRoles={allowedRoles}
  />
);

export default withAuthorization;
