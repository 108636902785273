export function getTranslations(key){
    let translations = {
      'pagination.previous': '<',
      'pagination.next': '>',
      'facets.view_more': 'Vis flere',
      'facets.view_less': 'Vis færre',
      'facets.view_all': 'Vis alle',    
      'NoHits.DidYouMean': 'Søk  på {suggestion}',
      'hitstats.results_found': '{hitCount} enh. funnet',
      'NoHits.NoResultsFound': 'Fant ingen bruksenheter for søket.',
      'NoHits.NoResultsFoundDidYouMean':
      'Fant ingen bruksenheter for {query}. Mente du {suggestion}?'
    };
    return translations[key];
  };
  