import React, { Component, Fragment } from 'react';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import { debugFactory } from 'utils';
import NKModal from 'components/Dialogs/NKModal.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import GebyrMeldingDialog from 'modules/BraGe/GebyrMeldingDialog.jsx';
import GebyrTable from './GebyrTable.jsx';
import SistFakturertTable from './SistFakturertTable.jsx';
import FakturertHittilTable from './FakturertHittilTable.jsx';
import { harGebyrOgReplikk } from 'modules/BraGe/gebyrHelper.js';
import { getGebyrForBruksenhet, getGebyrForMaid } from 'api/gebyrInnsynApi.js';
import { postBruksenhetAvtaleKobling } from 'api/gebyrInnsynApi.js';
import { getBruksenhetEiendom } from 'api/enhetApi.js';
import ReactTable from '@norkart/react-table';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import Spinner from 'components/generics/Spinner';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
const debug = debugFactory('nk:Gebyr:GebyrDialog');

const modalDialogs = {
  MeldingGebyrAvvikDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrMeldingDialog
        isOpen={isOpen}
        onClose={onClose}
        adkomtekReplikKundeidesse={dialogData.komtekReplikKundeid}
        fakturaavtalekobling={dialogData.fakturaavtalekobling}
        valgtavtale={dialogData.valgtavtale}
        meldingstype={dialogData.type}
        brId={dialogData.brId}
        avtalemelding={owner.avtalemelding}
      />
    </NKModal>
  ),
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        tittel='Tilknytt avtale'
        isOpen={isOpen}
        onClose={onClose}
        melding={dialogData.melding}
        onJa={owner.brukJa}
        onNei={onClose}
      />
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={'Melding'}
      melding={'Meldingen til Komtek Forvaltning har blitt sendt.'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class GebyrDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      harGebyr: true,
      data: null,
      antallBruksenheheterEiendom: 0,
      antallRoyklopIbrukEiendom: 0,
      antallRoyklopIbrukBruksenhet: 0,
      eiendom: '',
      avtaleNr: 'ukjent',
      bruksenhetadresse: '',
      avtaler: [],
      visDetaljer: false,
      visGjeldende: true,
      valgt: null,
      laster: true,
      ktfMeldingstypeId: 0,
      replikKundeId: 0,
      visKnapper: true,
      matrikkelId: null
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    //Sjekk om kommunen har Replikk
    if (!this.props.matrikkelId){
      getBruksenhetEiendom(this.props.fagbruksenhetId, this.gotMatrikkelId);
    } else {
      this.setState({ matrikkelId: this.props.matrikkelId });
      setTimeout(() => {
        getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
      }, 2000);
    }
  }

  gotMatrikkelId = (err, data) => {
    this.setState({ matrikkelId: data.id });
    setTimeout(() => {
      getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
    }, 2000);
    
  };

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
    this.sjekkReplikk(innst);
  };

  sjekkReplikk = (gebyrInnstillinger) => {
    let harGebyr = harGebyrOgReplikk( gebyrInnstillinger, this.state.matrikkelId);

    this.setState({ harGebyr });

    
    if (harGebyr) {
      this.hentData();
    } else {
      this.setState({ laster: false });
    }
  };

  hentData = () => {
    let id = this.props.fagbruksenhetId;
    if (id) {
      getGebyrForBruksenhet(id, this.gotData);
    } else {
      //Fra EtablerFagdata
      id = this.props.maid;
      if (id) {
        debug('MAID', id);
        getGebyrForMaid(id, this.gotData);
      }
    }
  };

  gotData = (err, data) => {
    let tmp = data;
    if (tmp.fakturaAvtaleMedGebyr != null) {
      tmp.fakturaAvtaleMedGebyr.forEach((element) => {
        console.log(element.bruksenhetAdresser);
        let t = element.bruksenhetAdresser.replaceAll(
          ', <mangler adresseinformasjon>  Unum.',
          ''
        );
        element.bruksenhetAdresser = t;
        console.log(element.bruksenhetAdresser);
      });
    }

    let tmpBygnr = tmp.bygningsNr.replace('/', '');
    tmp.bygningsNr = tmpBygnr;
    let antallBruksenheheterEiendom = tmp.antallBruksenheheterEiendom;
    let antallRoyklopIbrukEiendom = tmp.antallRoyklopIbrukEiendom;
    let antallRoyklopIbrukBruksenhet = tmp.antallRoyklopIbrukBruksenhet;
    let eiendom = tmp.eiendom;
    let avtaleNr = 'ukjent';
    let bruksenhetadresse = tmp.bruksenhetadresse;
    let bygningsNr = tmp.bygningsNr;
    let bruksenhetIdMatrikkel = tmp.bruksenhetIdMatrikkel;

    this.setState({ data: tmp });
    if (tmp.fakturaAvtaleMedGebyr != null) {
      if (tmp.fakturaAvtaleMedGebyr.length != 0) {
        this.setState({ valgt: tmp.fakturaAvtaleMedGebyr[0] });
        if (tmp.fakturaAvtaleMedGebyr.length > 1) {
          for (
            let index = 0;
            index < tmp.fakturaAvtaleMedGebyr.length;
            index++
          ) {
            if (tmp.fakturaAvtaleMedGebyr[index].bruksenhetAvtaleKobling) {
              this.setState({ valgt: tmp.fakturaAvtaleMedGebyr[index] });
            }
          }
        }
      }
    } else {
      tmp.fakturaAvtaleMedGebyr = [];
    }

    this.setState({ laster: false });

    this.setState({
      antallBruksenheheterEiendom,
      antallRoyklopIbrukEiendom,
      antallRoyklopIbrukBruksenhet,
      eiendom,
      avtaleNr,
      bruksenhetadresse,
      bygningsNr,
      bruksenhetIdMatrikkel,
      tmp,
    });

    if (this.props.fraEtablerFag) {
      this.setState({ visKnapper: false });
    }
    if (!this.props.fagbruksenhetId && antallRoyklopIbrukBruksenhet === 0) {
      this.setState({ visKnapper: false });
    }
  };

  tilknytt = (item) => {
    this.dialogs.setOpenDialog('JaNeiDialog', {
      melding: 'Ønsker du å koble bruksenhet mot valgt avtale',
    });
  };

  brukJa = () => {
    this.setState({ laster: true });
    let data = {
      bruksenhetId: this.state.data.bruksenhetIdMatrikkel,
      komtekReplikKundeId: this.state.data.komtekReplikKundeid,
      fakturaavtaleNr: this.state.valgt.avtaleNr,
      globalId: this.state.valgt.globalId,
    };
    postBruksenhetAvtaleKobling(data, this.koblingOppdatert);
    this.dialogs.closeDialog();
  };

  koblingOppdatert = (err, res) => {
    this.hentData();
  };

  onRowClick = (item) => {
    this.setState({ valgt: item });
  };

  avtalemelding = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('OkDialog');
  };

  getColumns = () => {
    return [
      {
        id: 'avtaleNr',
        name: 'Avtale',
        sortParams: 'avtaleNr',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <div style={{ minWidth: '80px' }}>{item.avtaleNr}</div>;
        },
      },
      {
        id: 'avtalenGjelder',
        name: 'Avtalen gjelder',
        sortParams: 'avtalegjelder',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'regningsmottaker',
        name: 'Regningsmottaker',
        sortParams: 'regnMott',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'bruksenhetAdresser',
        name: 'Bruksenheter',
        sortParams: 'bruksenhetAdresser',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <div style={{ maxWidth: '180px', fontSize: '12px' }}>
              {item.bruksenhetAdresser}
            </div>
          );
        },
      },
      {
        id: 'knapp',
        name: '',
        sortParams: 'knapp',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          //Ikke vis dersom det kommer fra Etabler Fagdata
          if (!this.state.visKnapper) {
            return <div />;
          }

          if (item.bruksenhetAvtaleKobling) {
            return (
              <div style={{ marginRight: '4px' }}>
                <b>Tilknyttet</b>
              </div>
            );
          } else {
            return (
              <button
                className='btn btn-default'
                style={{
                  marginTop: '2px',
                  minWidth: '100px',
                  maxHeight: '40px',
                }}
                onClick={this.tilknytt.bind(this, item)}>
                Tilknytt&nbsp;&nbsp;
                <NKIcon icon={'lenke'} size='0.8' color='nk-black' />
              </button>
            );
          }
        }.bind(this),
      },
      {
        id: 'knapp2',
        name: '',
        sortParams: 'knapp2',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (
            !this.state.visKnapper ||
            this.state.data.ktfMeldingstypeId == 0
          ) {
            return <div />;
          }

          return (
            <button
              className='btn btn-default'
              style={{ marginTop: '2px', minWidth: '100px', maxHeight: '40px' }}
              onClick={() =>
                this.dialogs.setOpenDialog('MeldingGebyrAvvikDialog', {
                  komtekReplikKundeid: this.state.data.komtekReplikKundeid,
                  fakturaavtalekobling: this.state.data,
                  valgtavtale: this.state.valgt,
                  type: 'avtale',
                  brId: this.props.fagbruksenhetId,
                })
              }>
              Avtalemelding
              {/* <NKIcon icon={'lenke'} size='0.8' color='nk-black' /> */}
            </button>
          );
        }.bind(this),
      },
    ];
  };

  rowFormat = (item) => {
    console.log(item);
    if (this.state.valgt.avtaleNr == item.avtaleNr) {
      return { style: { backgroundColor: '#ebeae8' } };
    } else {
      return { style: { backgroundColor: '#ffffff' } };
    }
  };

  render() {
    let btnStyle = {
      width: 'calc(100%)',
      backgroundColor: '#F8F7F9',
      borderColor: '#999999',
      paddingRight: '0px',
      paddingLeft: '4px',
      maxHeight: '42px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    };
    let propilStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      width: '60px',
      height: '40px',
      lineHeight: '40px',
      float: 'right',
    };
    let propilStyleAfter = {
      float: 'right',
      marginTop: '10px',
      marginRight: '4px',
    };
    let ellipsedStyle = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 100px)',
      textAlign: 'center',
      display: 'inline-block',
    };
    var columns = this.getColumns();
    if (this.state.laster) {
      return (
        <div>
          <Spinner className='h4' />
        </div>
      );
    }
    return (
      <div className='modal-content'>
        <div className='modal-header modal-header-info'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {this.state.bruksenhetadresse +
              '  -  Bygningsnr: ' +
              this.state.bygningsNr}
          </h4>
        </div>
        {/* <table>
          <tbody>
            <tr>
              <td>Vis knapper</td>
              <td>{this.state.visKnapper.toString()}</td>
            </tr>
            <tr>
              <td>Melding</td>
              <td>{this.state.data.ktfMeldingstypeId}</td>
            </tr>
          </tbody>
        </table> */}
        {!this.state.harGebyr && (
          <Fragment>
            <div
              className='modal-body'
              style={{ minHeight: '100px', maxHeight: '100px' }}>
              <label style={{ margin: '30px' }}>
                Det finnes ingen gebyroppkobling (replikk-id) til adressen.
              </label>
            </div>
          </Fragment>
        )}
        {this.state.harGebyr && (
          <Fragment>
            <br />
            <div className='modal-body' style={{ minHeight: '600px' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <div className='row'>
                        <div className='col-xs-12 col-sm-6 col-lg-4'>
                          <span>
                            &nbsp;&nbsp;<label>Eiendom:</label>&nbsp;&nbsp;
                            {this.state.eiendom}
                          </span>
                          <br />
                          <span>
                            &nbsp;&nbsp;<label>Antall bruksenheter:</label>
                            &nbsp;&nbsp;{this.state.antallBruksenheheterEiendom}
                          </span>
                        </div>

                        <div className='col-xs-12 col-sm-6 col-lg-4'>
                          <span>
                            &nbsp;&nbsp;<label>Ant. røykløp enhet:</label>
                            &nbsp;&nbsp;&nbsp;
                            {this.state.antallRoyklopIbrukBruksenhet}
                          </span>
                          <br />
                          <span>
                            &nbsp;&nbsp;<label>Ant. røykløp eiend.:</label>
                            &nbsp;&nbsp;{this.state.antallRoyklopIbrukEiendom}
                          </span>
                        </div>

                        <div className='col-xs-12 col-sm-6 col-lg-4'>
                          {/* <span>&nbsp;&nbsp;</span><br/> */}
                          <span>
                            &nbsp;&nbsp;
                            {this.state.visKnapper &&
                              this.state.data.ktfMeldingstypeId > 0 && (
                                <button
                                  className='btn btn-default'
                                  style={{ height: '36px' }}
                                  onClick={() =>
                                    this.dialogs.setOpenDialog(
                                      'MeldingGebyrAvvikDialog',
                                      {
                                        komtekReplikKundeid: this.state.data
                                          .komtekReplikKundeid,
                                        fakturaavtalekobling: this.state.data,
                                        type: 'eiendom',
                                        brId: this.props.fagbruksenhetId,
                                      }
                                    )
                                  }>
                                  <span>Eiendomsmelding</span>
                                </button>
                              )}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*  test replikk
              {this.state.data.komtekReplikKundeid}
              meldingstype
              {this.state.data.ktfMeldingstypeId} */}

              <hr />
              <div>
                <ReactTable
                  items={this.state.data.fakturaAvtaleMedGebyr}
                  columns={columns}
                  rowFormat={this.rowFormat}
                  showIndex={false}
                  filterable={false}
                  onRowClick={this.onRowClick}
                />
              </div>
              <div
                style={btnStyle}
                className='container-fluid btn btn-default sbpilpanel collapsed'
                href='#gjeldende'
                data-toggle='collapse'>
                <span />
                <h4 style={ellipsedStyle}>
                  Gjeldende gebyr - Avtalenr:{' '}
                  {this.state.valgt != null ? this.state.valgt.avtaleNr : null}
                </h4>
                <div style={propilStyle}>
                  <span
                    style={propilStyleAfter}
                    className='chevron fa fa-fw fa-lg'
                  />
                </div>
              </div>
              <div
                id='gjeldende'
                style={{
                  width: 'calc(100%)',
                  paddingLeft: '30px',
                  background: '#FFFFFF',
                  border: '1px solid #999999',
                }}
                className='collapse in'>
                <br />
                <GebyrTable
                  gebyrer={
                    this.state.valgt != null
                      ? this.state.valgt.gebyrInfo.gebyrlinjeDtos
                      : []
                  }
                />
              </div>
              <p />
              <div
                style={btnStyle}
                className='container-fluid btn btn-default sbpilpanel collapsed'
                href='#sist'
                data-toggle='collapse'>
                <span />
                <h4 style={ellipsedStyle}>
                  Sist fakturert - Avtalenr:{' '}
                  {this.state.valgt != null ? this.state.valgt.avtaleNr : null}
                </h4>
                <div style={propilStyle}>
                  <span
                    style={propilStyleAfter}
                    className='chevron fa fa-fw fa-lg'
                  />
                </div>
              </div>
              <div
                id='sist'
                style={{
                  width: 'calc(100%)',
                  paddingLeft: '30px',
                  background: '#FFFFFF',
                  border: '1px solid #999999',
                }}
                className='collapse'>
                <br />
                <SistFakturertTable
                  sistFakturert={
                    this.state.valgt != null
                      ? this.state.valgt.gebyrInfo.sisteFakturertlinjeDtos
                      : []
                  }
                />
              </div>
              <p />
              <div
                style={btnStyle}
                className='container-fluid btn btn-default sbpilpanel collapsed'
                href='#totalt'
                data-toggle='collapse'>
                <span />
                <h4 style={ellipsedStyle}>
                  Fakturert hittil i år - Avtalenr:{' '}
                  {this.state.valgt != null ? this.state.valgt.avtaleNr : null}
                </h4>
                <div style={propilStyle}>
                  <span
                    style={propilStyleAfter}
                    className='chevron fa fa-fw fa-lg'
                  />
                </div>
              </div>
              <div
                id='totalt'
                style={{
                  width: 'calc(100%)',
                  paddingLeft: '30px',
                  background: '#FFFFFF',
                  border: '1px solid #999999',
                }}
                className='collapse'>
                <br />
                <FakturertHittilTable
                  sistFakturert={
                    this.state.valgt != null
                      ? this.state.valgt.gebyrInfo.fakturertlinjeHittilDtos
                      : []
                  }
                />
              </div>
            </div>

            {this.dialogs.renderOpenDialog()}
          </Fragment>
        )}
      </div>
    );
  }
}

export default GebyrDialog;
