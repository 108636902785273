import React, { Component } from 'react';
import { debugFactory } from 'utils';
import Fast from 'images/Annet/fastP.png';
import Dato from 'images/Annet/datoP.png';
import Interval from 'images/Annet/intervalP.png';
const debug = debugFactory('nk:VelgModusDialog');
//kopier: import VelgModusDialog from './VelgModusDialog.jsx';

 
 
class VelgModusDialog extends Component {
  constructor(props) {
  super(props);
  this.state = { id: 0 };
  }

componentDidMount() {
  
}

onClickModus = (m) => {
    this.props.onModus(m, this.props.data);
};


render() {
    let btnStyle = { width: '260px', marginLeft: '10px', marginBottom: '10px', marginTop: '10px' };
   return (
    <div className="modal-content">
    <div className="modal-header">
        <button className="close" onClick={this.props.onClose} type="button">
            <span>x</span>
            <span className="sr-only">Lukk</span>
        </button>
        <h4 className="modal-title">Velg ønsket modus</h4>
    </div>
    <div className="modal-body">
        <div >
            <button style={btnStyle} className='btn btn-default' onClick={this.onClickModus.bind( null, 'intervall')}>
                <span className='pull-left'>
                <img
                  height="40"
                  width="40"
                  src={Interval}
                  alt=""
                />
                </span>
                <span className='pull-left' style={{ marginLeft: '30px', marginTop: '10px' }}>Tidsplan med intervall</span>
                </button>
            
            <button style={btnStyle} className='btn btn-default' onClick={this.onClickModus.bind( null, 'dato')}>
            <span className='pull-left'>
                <img
                  height="40"
                  width="40"
                  src={Dato}
                  alt=""
                />
                </span>
                <span className='pull-left' style={{ marginLeft: '30px', marginTop: '10px' }}>Kun dato</span>
                </button>
           
            <button style={btnStyle} className='btn btn-default' onClick={this.onClickModus.bind( null, 'fasttid')}>
            <span className='pull-left'>
                <img
                  height="40"
                  width="40"
                  src={Fast}
                  alt=""
                />
                </span>
                <span className='pull-left' style={{ marginLeft: '30px', marginTop: '10px' }}>Fast dato og tidspunkt</span>
                </button>
        </div>
    </div>
</div >
    );
  }
}

export default VelgModusDialog;