import React, { useState, useEffect } from 'react';
import { getContextBrukere } from 'context/contextOppslag.js';
import _ from 'underscore';
import { byttSaksbehandler, byttSaksbehandlerPaAvvik } from 'api/smkApi.js';
let blank = {
  id: 0,
  navn: '-',
};

function ByttSaksbehandler(props) {
  const [personer, setPersoner] = useState(null);
  const [person, setPerson] = useState(null);
  const [isInit, setInit] = useState(true);
  const [changed, setChanged] = useState(false);
  const [userOk, setUserOk] = useState(false);

  useEffect(() => {
    if (personer) {
      return;
    }
    if (isInit) {
      // ellers blir det en infin. loop
      setInit(false);
      //let brukerListe = [];
      getContextBrukere(function(brukere) {
        // let saksbeh = _.filter(
        //   brukere.brukere,
        //   (item) => item.paaLogget === false
        // );
        //brukerListe = brukere.brukere;
        let sidemenn = brukere.brukere;
        if (sidemenn.length){
          if (sidemenn[0] !== blank){
            sidemenn.unshift(blank);
          }
        } 
       
        setPersoner(sidemenn);
      });
    }
  }, []);

  useEffect(() => {
    if (changed) {
      if (props.type === 'avvik') {
        byttSaksbehandlerPaAvvik(props.valgtItem.id, person, props.onClose);
      } else {
        byttSaksbehandler(props.valgtItem.id, person, props.onClose);
      }
    }
  });

  const handleAnsvarligChange = (item) => {
    setPerson(item.target.value);
    console.log(item.target.value);
    if (item.target.value !== 0) {
      setUserOk(true);
    } 
    if (item.target.value == 0) {
      setUserOk(false);
    }
  };

  const onOk = () => {
    console.log('ok bytt saksbehandler');
    if (person == null && personer != null && personer.length > 0) {
      setPerson(personer[0].id);
      // vent på at personer er endret.
      setChanged(true);
    } else {
      if (props.type === 'avvik') {
        byttSaksbehandlerPaAvvik(props.valgtItem.id, person, props.onClose);
      } else {
        byttSaksbehandler(props.valgtItem.id, person, props.onClose);
      }
    }
  };

  return (
    <div
      style={{
        margin: '4px',
        padding: '10px',
      }}>
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {props.tittel} </h4>
        </div>
        <div className='modal-body'>
          <div className='form'>
            <div className='row'>
              <div className='col-sm-12'>
                <p />
                <div className='form-group'>
                  <label className='top-margin-4'>
                    &nbsp;&nbsp;Velg ny saksbehandler&nbsp;&nbsp;&nbsp;
                  </label>
                  <select
                    className='h30 width220'
                    value={person}
                    id='type'
                    onChange={handleAnsvarligChange}>
                    {_.map(personer, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.navn}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          {userOk ? <div>
            <button className='btn btn-primary' onClick={onOk}>
            OK
          </button>
          </div> : <div>
          <button className='btn btn-primary' onClick={onOk} disabled>
            OK
          </button>
            </div>}
          

        </div>
      </div>
    </div>
  );
}

export default ByttSaksbehandler;
