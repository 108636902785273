import React from 'react';
import {
  getNotaterForBruksenhet,
  postNotatForBruksenhet,
  slettNotatForBruksenhet,
} from 'api/enhetApi';
import NKIcon from 'nka-icons';
import _ from 'underscore';
import Spinner from 'components/generics/Spinner';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import DialogStore from 'lib/DialogStore';
import moment from 'moment';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:Notater');

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Slette notat?'
        isOpen={isOpen}
        onClose={onClose}
        melding={'Er du sikker at du ønsker å slette notatet?'}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};

class Notater extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, nyttNotat: null, notater: [] };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    getNotaterForBruksenhet(this.props.brid, this.gotNotater);
  }

  gotNotater = (err, res) => {
    this.setState({
      notater: res === null || res === undefined ? [] : res,
      isLoading: false,
    });
    if (res) {
      this.props.oppdaterAntall(res.length);
    }
  };

  notatChange = (val) => {
    this.setState({ nyttNotat: val.target.value });
  };

  lagreNotat = () => {
    let data = {
      bruksenhetId: this.props.brid,
      notat: this.state.nyttNotat,
      registrertDato: moment
        .utc()
        .local()
        .format('YYYY-MM-DD HH:mm'),
    };
    postNotatForBruksenhet(data, this.notatLagret);
    this.setState({ isLoading: true });
  };

  notatLagret = (err, res) => {
    getNotaterForBruksenhet(this.props.brid, this.gotNotater);
    this.setState({ nyttNotat: null });
  };

  slettNotat = (notat) => {
    this.dialogs.setOpenDialog('JaNeiDialog', {
      onJa: () => {
        slettNotatForBruksenhet(notat.id, this.notatLagret);
      },
    });
  };

  render() {
    if (this.state.isLoading === true) {
      return (
        <div>
          <Spinner className='h4' />
        </div>
      );
    }

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Notater</h4>
        </div>
        <div className='modal-body' style={{ padding: '20px' }}>
          <div className='row idiv'>
            <div className='col-sm-11'>
              <div className='form-group '>
                <textarea
                  autoFocus
                  className='form-control textareaNoresize'
                  type='textarea'
                  id='message'
                  placeholder='Nytt notat'
                  rows='2'
                  onChange={this.notatChange}
                />
              </div>
            </div>
            <div className='col-sm-1'>
              {this.state.nyttNotat != null && this.state.nyttNotat != '' ? (
                <div>
                  <div>
                    <span title='Lagre notat'>
                      <NKIcon
                        className='ml3 pointer'
                        icon='lagre'
                        onClick={(e) => {
                          e.stopPropagation();
                          this.lagreNotat();
                        }}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <span title='Lagre innlegg'>
                      <NKIcon className='ml3 pointer' icon='lagre' />
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.notater.length === 0 ? (
            <div>
              <div className='row idiv'>
                <div className='col-sm-12'>
                  Ingen lagrede notater på bruksenheten
                </div>
              </div>
            </div>
          ) : (
            <div className='row idiv'>
              <div className='col-lg-11'>
                {_.map(
                  this.state.notater,
                  function(notat, ix) {
                    return (
                      <div key={'dd' + ix}>
                        <table
                          key={'t1' + ix}
                          style={{
                            backgroundColor: '#F8F8F8',
                            width: '100%',
                          }}>
                          <tbody>
                            <tr>
                              <td>
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '5px',
                                    fontSize: '12px',
                                  }}>
                                  <b>{notat.registrertAv}</b>
                                </span>
                              </td>

                              <td>
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '5px',
                                    fontSize: '12px',
                                    width: '80%',
                                  }}>
                                  <b>
                                    {moment(notat.registrertDato).format(
                                      'DD.MM.YYYY'
                                    )}{' '}
                                    &nbsp; &nbsp; &nbsp;
                                    <b>
                                      {moment(notat.registrertDato).format(
                                        'H:mm'
                                      )}
                                    </b>
                                  </b>
                                </span>
                              </td>
                              <td
                                style={{
                                  paddingTop: '8px',
                                  width: '20%',
                                  textAlign: 'right',
                                }}>
                                <span title='Slett notat'>
                                  <NKIcon
                                    className='ml3 pointer'
                                    icon='soppelbotte'
                                    size='0.8'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.slettNotat(notat);
                                    }}
                                  />
                                  &nbsp;
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table
                          key={'t2' + ix}
                          style={{
                            backgroundColor: '#F8F8F8',
                            width: '100%',
                          }}>
                          <tbody>
                            <tr>
                              <td>
                                <span
                                  style={{
                                    marginLeft: '10px',
                                    marginTop: '15px',
                                    marginRight: '10px',
                                    marginBottom: '20px',
                                  }}>
                                  {notat.notat}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />
                      </div>
                    );
                  },
                  this
                )}
              </div>
            </div>
          )}
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Notater;
