import React, { Component } from 'react';
import * as _ from 'lodash';
import CONFIG from 'lib/CONFIG';
//import Tt from 'images/24/topptekst24.png';
import { debugFactory } from 'utils';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import './dokeditor.css';
import { checkEditorNormal, skjulTabellKantlinjer, visTabellKantlinjerIgjen } from './EditorNormal.js';
import { times } from 'lodash';
//import { getTopptekst } from 'api/malerApi';
const debug = debugFactory('nk:Forhandsvisning');
const FROALA_KEY = CONFIG.REACT_APP_FROALA;
//const toppCom1 = ' <!-- Topptekst Start --> ';
//const toppCom2 = ' <!-- Topptekst End --> ';

const modalDialogs = {
  ReadOnlyDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={'Kan ikke redigere'}
      melding={'Teksten kan ikke redigeres her.'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class Forhandsvisning extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.froalaRef = null;
    this.state = {
      model: 'Vent..',
      //topptekst: null,
      visMottakere: false,
      valgteKontakter: [],
      orgModel: '',
      visMelding: false,
      ferdig: false,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    this.makeFroalaToolButtons();


    if (this.props.kontakter) {
      debug('Zara Kontakter', this.props.kontakter.length);
      let valgteKontakter = [];
      for (let i = 0; i < this.props.kontakter.length; i++) {
        const k = this.props.kontakter[i];
        k.checked = true;
        valgteKontakter.push(k);
      }
      this.setState({ valgteKontakter });
    }

    setTimeout(() => {
      this.cleanModel();
    }, 2000);
  };

  cleanModel = () => {
    let model = this.props.model;
    model = checkEditorNormal(model);
    model = this.settMottakerTilbake(model);
    model = model.replace(new RegExp('-sideshift-', 'g'), '');
    let orgModel = _.cloneDeep(model);
    this.setState({ orgModel });
    this.setState({ model });

    this.flettFlereKontakter(orgModel);
  };

  settMottakerTilbake = (txt) => {
    let ff =
      '<!-- mott1 --><span contenteditable="false" style="color: blue;">Her kommer mottaker(e).</span><!-- mott2 -->';
    let gg = '#mottaker# ';
    txt = txt.replaceAll(ff, gg);
    return txt;
  };

  flettFlereKontakter = (orgModel) => {
    let model = '';
    debug('siepje A', this.state.valgteKontakter);
    if (this.props.visValgMottaker) {
      if (this.props.kontakter) {
        for (let i = 0; i < this.state.valgteKontakter.length; i++) {
          let shift = '';
          if (i > 0) {
            shift = '<p style="page-break-before: always;"></p><p/>';
          }
          const kontakt = this.state.valgteKontakter[i];
          if (kontakt.checked) {
            let flettetTekst = this.goFlettMottaker(orgModel, kontakt);
            model = model + shift + flettetTekst;
          }
        }

        this.setState({ model });
      }
    }
    this.setState({ ferdig: true });
  };

  tilbakeOppdaterBtn = () => {
    this.setState({ visMelding: false });
    if (this.checkCheck()) {
      this.setState({ visMottakere: false });
      this.flettFlereKontakter(this.state.orgModel);
    } else {
      this.setState({ visMelding: true });
    }
  };

  checkCheck = () => {
    let ok = false;
    if (this.props.kontakter) {
      for (let i = 0; i < this.state.valgteKontakter.length; i++) {
        const kontakt = this.state.valgteKontakter[i];
        if (kontakt.checked) {
          ok = true;
        }
      }
    }
    return ok;
  };

  goFlettMottaker = (orgModel, kontakt) => {
    let flettefelt = '#mottaker#';
    let kontaktBlokk =
      '<p>' +
      kontakt.navn +
      '<br/>' +
      kontakt.adresse +
      '<br/>' +
      kontakt.poststed +
      '</p>';
    if (orgModel.includes(flettefelt)) {
      orgModel = this.goReplace(orgModel, flettefelt, kontaktBlokk);
    }
    return orgModel;
  };

  mottaker = () => {
    this.setState({ visMottakere: true });
  };

  tilbake = () => {
    this.setState({ visMottakere: false });
  };

  goFlettMottakerOld = (orgModel, kontakt) => {
    let flettefelt = '#mottaker#';
    let kontaktBlokk =
      '<p>' +
      kontakt.navn +
      '<br/>' +
      kontakt.adresse +
      '<br/>' +
      kontakt.poststed +
      '</p>';
    if (orgModel.includes(flettefelt)) {
      orgModel = this.goReplace(orgModel, flettefelt, kontaktBlokk);
    }
    return orgModel;
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replaceAll(new RegExp(find, 'g'), repl);
    return str;
  };

  makeFroalaToolButtons = () => {
    /*     Froalaeditor.DefineIcon('topptekst', {
      SRC: Tt,
      ALT: 'Topptekst',
      template: 'image',
    });
    Froalaeditor.RegisterCommand('topptekst', {
      title: 'Vis/skjul topptekst',
      icon: 'topptekst',
      callback: () => {
        this.visTopptekst();
      },
    }); */

    Froalaeditor.DefineIcon('mottaker', {
      NAME: 'Velg mottaker(e)',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('mottaker', {
      title: 'Velg mottaker(e)',
      icon: 'mottaker',
      callback: () => {
        this.mottaker();
      },
    });
  };

  /*   getToppTekst = () => {
    getTopptekst(this.gotTopptekst);
  };

  gotTopptekst = (err, topptekst) => {
    if (topptekst) {
      this.setState({ topptekst.tekst });
      this.goVisTopptekst(topptekst.tekst);
    }
  };

  visTopptekst = () => {
    if (!this.topptekstVisesFraFor()) {
      let topptekst = this.state.topptekst;
      if (topptekst) {
        this.goVisTopptekst(topptekst);
      } else {
        this.getToppTekst();
      }
    }
  };

  topptekstVisesFraFor = () => {
    let m = this.state.model;
    if (m.includes(toppCom1)) {
      let p1 = m.indexOf(toppCom1);
      let p2 = m.indexOf(toppCom2) + 24;
      m = m.slice(p2);
      this.setState({ model: m });
      return true;
    } else {
      return false;
    }
  };

  goVisTopptekst = (topptekst) => {
    topptekst = toppCom1 + topptekst + toppCom2;
    let m = this.state.model;
    m = topptekst + ' ' + m;
    this.setState({ model: m });
  }; */

  handleModelChange = () => {
    if (this.state.ferdig) {
      this.dialogs.setOpenDialog('ReadOnlyDialog');
      let s = this.state.model;
      this.setState({ model: s });
    }
  };

  getButtons = () => {
    //let res = ['getPDF'];
    let res = [];

    /*     if (this.props.visToppTekstButton) {
      res.push('topptekst');
    } */

    if (this.props.visValgMottaker) {
      if (this.props.kontakter) {
        if (this.props.kontakter.length > 1) {
          res.push('mottaker');
        }
      }
    }
    return res;
  };

  handleCheckbox = (e, index) => {
    let valgteKontakter = this.state.valgteKontakter;
    let ch = valgteKontakter[index].checked;
    valgteKontakter[index].checked = !ch;
    this.setState({ valgteKontakter });
  };


  render() {
    const config = {
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'Mal..',
      autoFocus: false,
      tabSpaces: 4,
      imagePaste: false,
      language: 'nb',
      heightMin: 50,
      quickInsertEnabled: false,
      tableEditButtons: [],
      height: '540px',
      heightMax: '540px',
      lineheight: '1',
      charCounterCount: true,  
      lineHeights: {
        Standard: '1',
        '1.15': '1.15',
        '1.5': '1.5',
        Dobbel: '2',
      },
      events: {
        'commands.before': function(e) {
          if (e === 'print') {
            return skjulTabellKantlinjer(this);
          }
        },
        'commands.after': function(e) {
          if (e === 'print') {
            return visTabellKantlinjerIgjen(this);
          }
        },
      },
      toolbarButtons: {
        moreRich: {
          buttons: this.getButtons(),
          align: 'left',
        },
        moreText: {
          buttons: ['print'],
        },
      },
    };
    return (
      <div
        style={{ height: '700px', width: '760px' }}
        className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Forhåndsvisning </h4>
        </div>
        <div className='modal-body'>
          {this.state.visMottakere && (
            <React.Fragment>
              <div className='kontaktScroll'>
                <label style={{ marginLeft: '20px', marginBottom: '14px' }}>
                  Velg mottaker(e)
                </label>
                <button
                  onClick={this.tilbakeOppdaterBtn}
                  className='btn btn-primary pull-right'
                  style={{ marginRight: '10px', marginBottom: '2px' }}>
                  Tilbake og oppdater tekst
                </button>
                <br />
                {this.state.visMelding && (
                  <label style={{ marginLeft: '40px', color: 'darkRed' }}>
                    Velg minst 1 mottaker.
                  </label>
                )}
                <div className='container-fluid'>
                  <div className='row'>
                    {this.props.kontakter.map((kontakt, index) => (
                      <div className='col-md-6'>
                        <div
                          style={{
                            margin: '2px',
                            padding: '4px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'lightgray',
                          }}>
                          <span>
                            <input
                              alt={index}
                              className='checkbox20'
                              style={{ marginLeft: '4px' }}
                              ref={index}
                              id={index}
                              key={index}
                              onChange={(e) => this.handleCheckbox(e, index)}
                              checked={
                                this.state.valgteKontakter[index].checked
                              }
                              type='checkbox'
                            />
                          </span>
                          <span style={{ marginLeft: '10px' }}>
                            <b>{kontakt.navn}</b>
                          </span>
                          <br />
                          <span style={{ marginLeft: '34px' }}>
                            {kontakt.adresse}
                          </span>
                          <br />
                          <span style={{ marginLeft: '34px' }}>
                            {kontakt.poststed}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}

          {!this.state.visMottakere && (
            <FroalaEditorComponent
              ref={(el) => {
                this.froalaRef = el;
              }}
              id='editor'
              documentReady={true}
              useClasses= {false}
              config={config}
              model={this.state.model}
              onModelChange={this.handleModelChange}
            />
          )}
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Forhandsvisning;
