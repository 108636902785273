var React = require('react');
var Modal = require('react-modal');

require('./NKModal.css');

var NKModal = function(props) {

    //For å unngå en warning:
    //Warning: modal: App element is not defined.
    Modal.setAppElement('body');  

    if (!props.isOpen) {
        return null;
    };
    let className = 'Modal__Bootstrap ';
    if (props.size) {
        if (props.size === 'small') {
            className += ' modal-dialog modal-sm';
        }
        if (props.size === 'medium') {
            className += ' modal-dialog';
        }

        if (props.size === 'large') {
            className += ' modal-dialog modal-lg';
        }
        if (props.size === 'large-wide') {
            className += ' modal-dialog modal-lg modal-wide';
        }

        if (props.size === 'xl') {
            className += ' modal-xl ';
        }
        if (props.size === 'fullscreen') {
            className += ' modal-fullscreen ';
        }
    }
    else{
        className += ' modal-dialog';
    }
    return <Modal shouldCloseOnOverlayClick={false} {...props} contentLabel='' className={className} />;
};

export default NKModal;