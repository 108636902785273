import React, { Component } from 'react';
import { debugFactory, promisifyApiCall } from 'utils';
import './diagram.css';
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from 'react-component-export-image';
import {
  BarChart,
  Bar,
  Legend,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { getGrunndataAnalyseIldsted } from 'api/grunndataAnalyseApi';

const debug = debugFactory('nk:Diagram');

class BarIldsted5 extends Component {
  //const [chart, setChart] = React.useState();
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = { data5: [], count: 5, chart: null };
  }

  componentDidMount() {
    this.getSiste5YearStart();
  }

  getSiste5YearStart = () => {
    let current_date = new Date();
    let year = current_date.getFullYear();
    let dataArray = [];
    for (let i = 0; i < 5; i++) {
      let obj = {
        aar: year - i,
        kommuneId: this.state.kommuneId,
        d1: new Date(year - i, 0, 1),
        d2: new Date(year - i, 11, 31),
      };
      dataArray.push(obj);
    }
    this.getSiste5Year(dataArray);
  };

  getSiste5Year = (dataArray) => {
    let data5 = [];
    let count = 5;
    dataArray.forEach((element) => {
      let param = {
        kommunenr: element.kommuneId,
        datoFra: element.d1,
        datoTil: element.d2,
      };
      return promisifyApiCall(getGrunndataAnalyseIldsted)(param).then((res) => {
        let o = {
          name: element.aar,
          'i bruk': res.antallIbruk,
          'ikke i bruk': res.antallIkkeIbruk,
          antall: res.antallIldsted,
        };
        data5.push(o);
        count = count - 1;
        this.setState({ count });
      });
    });

    this.setState({ data5 });
  };

  render() {
    if (this.state.count > 0) {
      return <div>Henter diagram.. {this.state.count}</div>;
    }
    const data5 = this.state.data5.sort((a, b) => (a.name > b.name ? 1 : -1));
    const valueAccessor = (entry) => {
      return entry ? entry.antall : 0; // eller: ibruk + ikkeibruk
    };
    const options1 = 'Ildsted5';
    const options2 = { fileName: 'Ildsted5' };
    return (
      <div
        style={{ maxWidth: '600px', width: '90%', height: '390px' }}
        ref={this.componentRef}>
        <h4>Oversikt over registrerte ildsted siste 5 år.</h4>
        <ResponsiveContainer width='99%'>
          <React.Fragment>
            <BarChart
              width={400}
              height={300}
              data={data5}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='name' />
              <YAxis />
              <Tooltip />
              <Legend />

              <Bar dataKey='i bruk' stackId='a' fill='#51a026' />
              <Bar dataKey='ikke i bruk' stackId='a' fill='#e96767'>
                <LabelList position='top' valueAccessor={valueAccessor} />
              </Bar>
            </BarChart>
            <button
              className='btn btn-link'
              onClick={() =>
                exportComponentAsPNG(this.componentRef, {
                  fileName: 'IldstedSiste5Aar',
                })
              }>
              Last ned som bilde
            </button>
          </React.Fragment>
        </ResponsiveContainer>

        {/*   <button className='btn btn-link' onClick={() => exportComponentAsJPEG(this.componentRef, {fileName:'RoyklopSiste5Aar'} )}>
          Last ned som bilde
        </button>
        <button onClick={() => exportComponentAsPDF(this.componentRef, {fileName:'RoyklopSiste5Aar'} )}>
          Export As PDF
        </button> */}
      </div>
    );
  }
}

export default BarIldsted5;
