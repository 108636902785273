import React, { Component } from 'react';

import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import ContextMenu from 'components/ContextMenu';
import filters from 'images/Annet/filters.png';
import LagredeFilters from 'components/Search/LagredeFilters.jsx';
import { debugFactory } from 'utils';
import { lagreValgtFilter } from 'context/contextFilters.js';
const debug = debugFactory('nk:lagredeFiltersMenu');
//kopier: import lagredFiltersMenu from './lagredFiltersMenu.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  FilterDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <LagredeFilters
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}
      onOk={onClose}
      brukLagretSok={owner.brukLagretSok}
      visLagreSokDialog={owner.visLagreSokDialog}
      onDeletedSok={owner.onDeletedSok}
      onValgtStandard={owner.onValgtStandard}
    />
  ),
};

class LagredeFiltersMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 0 };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {}

  onCloseDialog() {
    //
  }

  lagre= () => {

  }

  brukLagretSok = (filter) => {
    this.dialogs.closeDialog();
    this.props.brukLagretSok(filter);
  };

  visLagreSokDialog = () => {
    this.dialogs.closeDialog();
    this.props.visLagreSokDialog();
  };

  onDeletedSok = (filter) => {
    this.dialogs.closeDialog();
    this.props.onDeletedSok(filter);
  };

  onValgtStandard = (filter) => {
    lagreValgtFilter(filter);
    this.dialogs.closeDialog();
  };

  open= () => {
    this.dialogs.setOpenDialog('FilterDialog');
}

  render() {
    return (
      <div>
       {/*  <button style={{ maxHeight: '30px', maxWidth: '30px' }} className='btn btn-default'
          onClick= {this.open} >
         
        </button> */}
        <button onClick= {this.open} title='Lagrede søk'>
          <img src={filters} alt='pdf' width='24' height='24' ></img>
        </button>
        
        {/*  <ContextMenu.Button
          width='260'
          title='Meny'
          icon='meny'
          position='center'
          iconColor='nk-black'
          onClick= {this.open}
          className='inlinebtn'>
          <ContextMenu.Item onClick= {this.lagre} >Lagre søk...</ContextMenu.Item>
          <ContextMenu.Item onClick= {this.open} >Vis lagrede søk</ContextMenu.Item> }
        </ContextMenu.Button>  */}
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default LagredeFiltersMenu;
