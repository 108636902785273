import React from 'react';

class NySmsVarselDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: 0 };
  }

  componentDidMount() {
    
  }

  render() {
    let btnStyle = {
      width: '360px',
      marginLeft: '8px',
      marginBottom: '0px',
      marginTop: '6px',
    };
    return (
      <div>
        <div className='modal-header'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Generer ny varsel mal basert på..</h4>
        </div>
        <div>
          <div style={{ overflowY: 'auto', height: '260px' }}>
            {this.props.liste.map((mal, idx) => {
              return <button key={'btn_'+ idx} onClick={this.props.onGenererMalFraDialog.bind(this, mal)} style= {btnStyle}><span className='pull-left'>"{mal.tittel}"</span></button>;
            }, this)}
            <br/><br/>
          </div>
        </div>
      </div>
    );
  }
}

export default NySmsVarselDialog;
