import React from 'react';
import NKModal from './NKModal.jsx';
import { PropTypes } from 'prop-types';

class PromptDialog extends React.Component {
    static propTypes = {
        prompt: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired
    };

    state = { isOpen: true, resultString: '' };

    handleInputChange = (e) => {
        this.setState({ resultString: e.target.value });
    };

    keyDown = (event) => {
        if (!this.props.omraader){
            return;
        }
        const pressedChar = event.key;
         // Allow only numbers and letters (alphabets)
         if (!/[A-Za-z0-9\sÅÆØåæø]/.test(pressedChar)) {
         event.preventDefault();
       }
     }

    onOk = () => {
        this.props.onOk(this.state.resultString);      
    };

    render() {                

        return (
            <NKModal size='medium' isOpen={this.props.isOpen}   >
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" onClick={this.props.onClose} type="button">
                            <span>x</span>
                            <span className="sr-only">Lukk</span>
                        </button>
                        <h4 className="modal-title">{this.props.tittel}</h4>
                    </div>
                    <div className="modal-body" style={{ paddingLeft: '20px' }}>
                        <br/>
                        <label>{this.props.prompt}</label>
                        <br/>
                        <input autoFocus style={{ resize: 'none', width: '560px', height: '36px' }} 
                        type="text" id="resultText" onChange={this.handleInputChange} onKeyDown={(evt) => {this.keyDown(evt);}}
                              value={this.state.resultString}></input>
                    </div>
                    <div className="modal-footer">
                        {this.props.omraader &&
                            <span style={{ color: 'blue', marginLeft: '10px' }} className='pull-left'>Bruk kun bokstaver og nummer.</span>
                        }
                        
                        <button style={{ margin: '2px', width: '50px', height: '48px' }} className="btn btn-primary pull-right" onClick={this.onOk}>OK</button>
                    </div>   
                </div>     
            </NKModal>   
        );  
    }
}

export default PromptDialog;