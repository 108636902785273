import React from 'react';
import 'react-toggle-switch/dist/css/switch.min.css';
import '.././grunndata.css';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
import ReactSelect from 'react-select';
import 'react-select/dist/react-select.css';
var _ = require('underscore');

var id = 0;
var opprinnelig = null;
var valgteParagrafer = [];
class NyEndreOppslagsdataAvvikType extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleTekstChange = this.handleTekstChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.dataLagret = this.dataLagret.bind(this);
    this.objektChange = this.objektChange.bind(this);
    this.handleParagraf = this.handleParagraf.bind(this);
    this.state = {
      lukket: true,
      tekst: ''
    };
  }
  componentDidMount() {
    this.tekstInput.focus();
    if (this.props.nyEndre === 'Endre') {
      id = this.props.valgtItem.id;
      this.setState({
        tekst: this.props.valgtItem.tekst,
        lukket: this.props.valgtItem.aktiv,
        objektReferanseType: this.props.valgtItem.objektReferanseType,
        frist: this.props.valgtItem.fristDefault
      });
      var valgte = [];
      if (this.props.valgtItem.paragrafer !== null) {
        this.props.valgtItem.paragrafer.forEach(function(paragraf) {
          var x = {
            paragrafId: paragraf.paragrafId,
            paragafTekst: paragraf.paragafTekst
          };
          valgte.push(x);
        });
      }
      this.setState({ selectedPargrafer: valgte });
    } else {
      this.setState({ objektReferanseType: this.props.objektTyper[0].kode, frist: '21' });
    }
  }

  handleParagraf(options) {
    this.setState({
      selectedPargrafer: options
    });
  }

  handleTekstChange(data) {
    if (data.target.value.includes('\\')) {
    } else {
      this.setState({ tekst: data.target.value });
    }
  }

  objektChange(data) {
    this.setState({ objektReferanseType: data.target.value });
  }

  fristChange = (data) => {
    this.setState({ frist: data.target.value });
  }
  submit() {
    this.checkParagrafer();
  }
  checkParagrafer() {
    // sjekker paragrafer på type, sette true false på slett/ny
    if (this.props.nyEndre === 'Endre') {
      opprinnelig = this.props.valgtItem.paragrafer;
    } else {
      opprinnelig = [];
    }
    valgteParagrafer = this.state.selectedPargrafer;

    // Sjekk om noen er fjernet, hvis endre
    if (this.props.nyEndre === 'Endre') {
      let avvikTypeId = this.props.valgtItem.id;

      if (valgteParagrafer === null || valgteParagrafer !== undefined) {
        valgteParagrafer.forEach(function(p) {
          p.slett = false;
          p.ny = false;
          p.avvikTypeId = avvikTypeId;
        });

        for (let index = 0; index < opprinnelig.length; index++) {
          let par = opprinnelig[index];
          if (!this.sjekkInclude(valgteParagrafer, par)) {
            par.slett = true;
            par.ny = false;
            par.avvikTypeId = this.props.valgtItem.id;
            valgteParagrafer.push(par);
          }
        }
        let tempListe = valgteParagrafer;
        for (let index = 0; index < tempListe.length; index++) {
          let par = tempListe[index];
          if (!this.sjekkInclude(opprinnelig, par)) {
            par.ny = true;
            par.slett = false;
            par.avvikTypeId = this.props.valgtItem.id;
            par.id = 0;
          }
        }
        valgteParagrafer = tempListe;
        this.setState({ selectedPargrafer: valgteParagrafer });
      }
    }

    this.paragraferSjekket();
  }
  sjekkInclude(arr, par) {
    if (arr !== null) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].paragrafId === par.paragrafId) {
          return true;
        }
      }
    }
    return false;
  }
  paragraferSjekket() {
    var type = {
      id: id,
      tekst: this.state.tekst,
      aktiv: this.state.lukket,
      objektReferanseType: this.state.objektReferanseType,
      paragrafer: this.state.selectedPargrafer,
      fristDefault: this.state.frist
    };

    if (this.props.nyEndre === 'Ny') {
      oppslagsdataApi.saveAvvikType(type, this.dataLagret);
    } else {
      oppslagsdataApi.updateAvvikType(id, type, this.dataLagret);
    }
  }
  dataLagret(err, nyEndreitem) {
    this.props.dataLagret(nyEndreitem);
  }
  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    this.setState({ utfort: lukket });
  }

  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">
            {this.props.nyEndre} oppslagsdata for: {this.props.type} -{' '}
            {this.props.tabell}
          </h4>
        </div>
        <div className="modal-body">
          <div className="form margin-left-15 margin-right-15">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group ">
                  <br />
                  <input
                    className="checkbox30"
                    type="checkbox"
                    onClick={this.lukketChange}
                    checked={this.state.lukket}
                  />
                  <span className="checkboxtext18">Aktiv</span>
                </div>
                <div className="form-group">
                  <label>Objekt</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.objektChange}
                    value={this.state.objektReferanseType}>
                    {_.map(this.props.objektTyper, function(o, i) {
                      return (
                        <option key={i} value={o.kode}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Paragrafer</label>
                  <ReactSelect
                    multi
                    id="id"
                    valueKey="paragrafId"
                    labelKey="paragafTekst"
                    name="Paragraf"
                    value={this.state.selectedPargrafer}
                    onChange={this.handleParagraf}
                    options={this.props.paragrafer}
                    placeholder="Velg paragraf"
                  />
                </div>
                <div className="form-group">
                  <label>Tekst</label>
                  <textarea
                    ref={input => {
                      this.tekstInput = input;
                    }}
                    className="form-control textareaNoresize"
                    type="textarea"
                    id="message"
                    placeholder="Tekst"
                    rows="2"
                    onChange={this.handleTekstChange}
                    value={this.state.tekst}
                    maxLength="2000"
                  />
                </div>
                <div className="form-group">
                  <label>Frist antall dager</label>
                  <input
                    className="form-control"
                    type="text"
                    id="frist"
                    onChange={this.fristChange}
                    value={this.state.frist}
                  />
                </div>
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NyEndreOppslagsdataAvvikType;
