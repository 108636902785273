import NKModal from 'components/Dialogs/NKModal.jsx';
import React from 'react';
import _ from 'underscore';
import  Tiltakstyper from './index.jsx';

class className extends React.Component {
  render() {
    return <NKModal size="large" isOpen={this.props.isOpen}>
    <div className="modal-content">
      <div className="modal-header modal-header-info">
        <button
          className="close"
          onClick={this.props.onClose}
          type="button">
          <span>x</span>
          <span className="sr-only">Lukk</span>
        </button>
        <h4 className="modal-title">Regelmessige tiltak</h4>
      </div>
      <div className="modal-body">
        <div className="scroller">
        <Tiltakstyper id={this.props.id} type={this.props.type} royklop={this.props.royklop} brId={this.props.brId} orgRisiko={this.props.orgRisiko}/>
        </div>
      </div>
      <div className="modal-footer">
        <button
          style={{ margin: '2px', width: '80px', height: '40px' }}
          className="btn btn-primary pull-right"
          onClick={this.props.onClose}>
          Lukk
        </button>
      </div>
    </div>
  </NKModal>;
  }
}
export default className;
