import React from 'react';
import enhetApi from 'api/enhetApi';
import royklopApi from 'api/royklopApi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/nb';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';

var hyppighetListe = null;



class TiltakstypeLogg extends React.Component {
  state = {
    breadcrumbname: 'Laster....',
    selectedDate: null,
    id: this.props.location ? this.props.location.state.id : this.props.id,
    historikk: [],
    startDate: moment(Date.now()),
    erFritidsbygg: false,
    lagrer: false
  };

  constructor(props) {
    super(props);
    
  };

  componentDidMount() {
    
  }

  formatDato = (datostring) => {
    if (datostring == null || datostring == '' || datostring == undefined) {
      return '';
    } else {
      let date = new Date(datostring);
      var dateString = 
         ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
      return dateString;
    }
    
}
  

  render() {
    
    return (
      <div className="margin-top-15">
        <div>
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12">
              <div className="form-group margin-left-10 margin-right-15">
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: '150px' }}>Opprettet av:</td>
                      <td>{this.props.item.registrertAv}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '150px' }}>Opprettet dato:</td>
                      <td>{this.formatDato(this.props.item.registrertDato)}</td>
                    </tr>
                   
                    <tr>
                      <td style={{ width: '150px' }}>Endret av</td>
                      <td>{this.props.item.endretAv}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '150px' }}>Endret dato:</td>
                      <td>{this.formatDato(this.props.item.endretDato)}</td>
                    </tr>
                    </tbody>
                    </table>
                    <hr/>
                    <table>
                    <tbody>
                    <tr>
                      <td style={{ width: '150px' }}>Info:</td>
                      <td>{this.props.item.logg}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
          </div>
        </div>
        
        
      </div>
    );
  }
}

export default TiltakstypeLogg;
