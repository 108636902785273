import React from 'react';
import { withRouter } from 'lib/enhancers';
import ContextMenu from 'components/ContextMenu';
import arkivApi from 'api/arkivApi';
import ReactTable from '@norkart/react-table';
import '@norkart/react-table/dist/react-table.css';
import NKIcon from 'nka-icons';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import DialogStore from 'lib/DialogStore';
import {
  uuid
} from 'utils' ;

var kundeId = null;

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Slette sakskategori?'
        isOpen={isOpen}
        onClose={onClose}
        melding={'Er du sikker på at du ønsker å slette sakskategorien?'}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};


class Sakstyper extends React.Component {
  constructor(props) {
    super(props);
    this.gotSakstyper = this.gotSakstyper.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.endre = this.endre.bind(this);
    this.ny = this.ny.bind(this);
    this.slett = this.slett.bind(this);
    this.sakstypeSlettet = this.sakstypeSlettet.bind(this);
    this.hent = this.hent.bind(this);
    // this.handleNameChange = this.handleNameChange.bind(this);
    this.state = {
      sakstyper: [],
      ferdigLastet: false,
      arkivDeler: []
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.hent();
  }
  hent() {
    this.state = {
      sakstyper: [],
      ferdigLastet: false,
      arkivDeler: []
    };
    arkivApi.getAlleSakskategorier(uuid(), this.gotSakstyper);
  }
  getColumns() {
    return [
      {
        id: 'Navn',
        name: 'Navn',
        sortParams: 'Navn',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
        id: 'Mappetype',
        name: 'Mappetype',
        sortParams: 'Mappetype',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
        id: 'Arkivdel',
        name: 'Arkivdel',
        sortParams: 'Arkivdel',
        filterable: false,
        isSorted: false,
        sortable: false
      },

      // {
      //   id: 'Klassering',
      //   name: 'Klassering',
      //   sortParams: 'Klassering',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: false,
      //   formatter: function(item) {
      //     console.log(item);
      //     if (item.KlasseListe !== null) {
      //       // if (item.KlasseListe.length > 1) {
      //       //   let res = item.KlasseListe[0].Klassifikasjonssystem;
      //       //   for (var i = 1; i < item.KlasseListe.length; i++) {
      //       //     res = res + ' ,' + item[i].Klassifikasjonssystem;
      //       //   }
      //       //   return res;
      //       // } else {
      //       //   return item.KlasseListe[0].Klassifikasjonssystem;
      //       // }
      //     }
      //   }
      // },
      {
        id: 'StatusNySaksmappe',
        name: 'Status ny',
        sortParams: 'StatusNySaksmappe',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
        id: 'StatusAvsluttSaksmappe',
        name: 'Status avslutt',
        sortParams: 'StatusAvsluttSaksmappe',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre sakstype...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett sakstype...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      
    ];
  }

  gotSakstyper(err, sakstyper) {
    this.setState({ sakstyper: sakstyper, ferdigLastet: true });
    console.log(sakstyper);
  }

  handleNameChange(val) {
    kundeId = val.target.value;
  }

  ny() {
    this.props.router.push({
      pathname: 'innstillinger/SakArkiv/Sakstyper/Ny',
      state: {
        kundeId: kundeId
      }
    });
  }
  endre(item) {
    this.props.router.push({
      pathname: 'innstillinger/SakArkiv/Sakstyper/Endre',
      state: {
        kundeId: kundeId,
        valgt: item
      }
    });
  }
  slett(item) {
    this.dialogs.setOpenDialog('JaNeiDialog', {
      onJa: () => {
        arkivApi.slettSakskategori(uuid(), item.Navn, this.sakstypeSlettet);
      },
    });
  }
  sakstypeSlettet() {
    this.hent();
  }
  render() {
    var columns = this.getColumns();
    return (
      <div>
        <h2>Sakskategorier</h2>

        <div className="row">
          <div className="col-sm-12">
            <div className="btn-toolbar">
              <button
                style={{ margin: '5px'}}
                className="btn btn-default button-arbliste-enhet"
                onClick={this.ny}>
                <span className="pull-left">
                  <NKIcon
                    className="pull-left"
                    icon="pluss"
                    color="nk-black"
                  />
                </span>
                <span className="pull-left margin-left-10 ">Ny</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-sm-12">
              <ReactTable
                items={this.state.sakstyper}
                idProperty="id"
                showIndex={false}
                filterable={false}
                columns={columns}
                selectableRows={false}
              />
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(Sakstyper);
