import React from 'react';
import enhetApi from 'api/enhetApi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { sjekkBruksenhetRisiko, sjekkRoyklopRisiko } from 'api/risikoApi.js';


var valgt;
var hyppighetListe= null;

const modalDialogs = {
    DatoDialog: ({ isOpen, onClose, dialogData, owner }) => (
      <OKDialog
        tittel="Dato"
        melding="Datofeltet må fylles ut."
        isOpen={isOpen}
        onOk={onClose}
        onClose={onClose}
      />
    ),
    DatoTidligDialog: ({ isOpen, onClose, dialogData, owner }) => (
        <OKDialog
          tittel="Dato"
          melding="Dato kan ikke være før dagens dato."
          isOpen={isOpen}
          onOk={onClose}
          onClose={onClose}
        />
      ),
    HyppighetDialog: ({ isOpen, onClose, dialogData, owner }) => (
        <OKDialog
          tittel="Hyppighet"
          melding="Hyppighet må fylles ut."
          isOpen={isOpen}
          onOk={onClose}
          onClose={onClose}
        />
      ),
    RisikoDialog: ({ isOpen, onClose, dialogData, owner }) => (
        <OKDialog
          tittel="Risiko"
          melding="Tiltaktypen kan ikke endres da den er tilknyttet automatisert risikovurdering."
          isOpen={isOpen}
          onOk={onClose}
          onClose={onClose}
        />
      )
  };

class EndreTiltakstype extends React.Component {
    state = {
        selectedDate: this.props.valgt.nesteDato != null ? moment(this.props.valgt.nesteDato) : null,
        hyppighetId: this.props.valgt.hyppighetId,
        risikoSjekk: false
    };

    constructor(props) {
        super(props);
        this.dialogs = new DialogStore({
          owner: this,
          dialogs: modalDialogs
        });
      };

    componentDidMount() {
        valgt = this.props.valgt;
        console.log(valgt);
        if (this.props.type === 'bruksenhet') {
            enhetApi.getBruksenhetTiltakTypeOppslagdata(this.gotOppslagsdata);
            sjekkBruksenhetRisiko(this.props.id, this.gotRisikoEnhetRoyk);
        } else {
            enhetApi.getRoyklopTiltakTypeOppslagdata(this.gotOppslagsdata);
            sjekkRoyklopRisiko(this.props.id, this.props.bruksEnhetId, this.gotRisikoEnhetRoyk);
        }
    }

    gotOppslagsdata = (err, data) => {
        this.setState({ oppslagsdata: data });
        hyppighetListe = this.state.oppslagsdata.tiltakHyppighetListe;
        let blank = {
            'id': null,
            'tekst': null,
            'tekst2': null,
            'fremmedNokkel': null
          };
        hyppighetListe.unshift(blank);
        console.log(hyppighetListe);
        this.setTiltakTypeListe();
    };

    gotRisikoEnhetRoyk = (err, res) => {
        this.setState( { risikoSjekk: res });
    }

    setTiltakTypeListe = () => {
        var typeListe = this.state.oppslagsdata.tiltakTypeListe;

        var items = this.props.items;
        var tmp = [];
        for (var i = 0; i < typeListe.length; i++) {
            var tt = _.find(items, function(valgt) { return valgt.tiltakTypeId === typeListe[i].id; });
            if (tt === undefined) {
                tmp.push(typeListe[i]);
            }
        }
        this.setState({ tiltakTypeListe: tmp });
        if (tmp.length > 0) {
            this.setState({ tiltakId: tmp[0].id });
        }
    };

    handleTypeChange = (data) => {
        this.setState({
            tiltakId: data.target.value
        });
    };

    handleHyppighetChange = (data) => {
        console.log(data.target.value);
        this.setState({
            hyppighetId: data.target.value,
        });
        let antallDager  = _.find(hyppighetListe, function(x) {
            return x.id == data.target.value;
          }).tekst2;

         let tmp = moment(this.props.valgt.sisteDato != null && this.props.valgt.sisteDato != undefined ? this.props.valgt.sisteDato : moment(new Date())).add(antallDager, 'days');
         this.setState({ selectedDate: tmp });
    };

    handleDatePickerChange = (date) => {
        this.setState({
            selectedDate: date
        });
    };

    onLagreClick = () => {
       
        // if ((this.state.risikoSjekk && !this.props.overstyrt)) {
        //     this.dialogs.setOpenDialog('RisikoDialog');
        //     return;
        // }
        if (this.state.hyppighetId == null) {
            this.dialogs.setOpenDialog('HyppighetDialog');
            return;
        };
        if (this.state.selectedDate == null) {
            this.dialogs.setOpenDialog('DatoDialog');
            return;
        };
        if (this.state.selectedDate < moment(Date.now())) {
            this.dialogs.setOpenDialog('DatoTidligDialog');
            return;
        }

        if (this.props.type === 'bruksenhet') {
            valgt.hyppighetid = this.state.hyppighetId;
            valgt.nesteDato = moment(this.state.selectedDate).format('YYYY-MM-DDTHH:00:00');
            enhetApi.oppdaterBruksenhetTiltakType(valgt.id, valgt, this.onTiltaktypeLagret);
        } else {
            valgt.hyppighetid = this.state.hyppighetId;
            valgt.nesteDato = moment(this.state.selectedDate).format('YYYY-MM-DDTHH:00:00');
            enhetApi.oppdaterRoyklopTiltakType(valgt.id, valgt, this.onTiltaktypeLagret);
        }
    };

    onTiltaktypeLagret = (err, nyEnhet) => {
        this.props.onCloseModal();
    };

    render() {
        if (!this.state.oppslagsdata) {
            return (
                <div>Laster...</div>
            );
        }
        return <div>
            <div
                className="margin-top-15">
                <div className="row">
                    <div className="col-sm-5">
                        <div className="form-group margin-left-10 margin-right-15">
                            <label>Type tiltak</label>
                            <input type="text" className="form-control" readOnly={true} value={this.props.valgt.tiltaktype} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group margin-left-10 margin-right-15">
                            <label>Hyppighet</label>
                            <select className="form-control" id='type' onChange={this.handleHyppighetChange} value={this.state.hyppighetId}>
                                {_.map(hyppighetListe, function(o, i) {
                                    return <option key={i} value={o.id}>{o.tekst}</option>;
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group margin-left-10 margin-right-15">
                            <label>Dato</label>
                            <br />
                            <DatePicker className='hoyde34 width120'    
                                selected={this.state.selectedDate}
                                startDate={moment(Date.now())}
                                onChange={this.handleDatePickerChange}
                                minDate={moment(Date.now())}
                                todayButton={'I dag'}
                                filterDate={this.isWeekday}
                                isClearable={false} 
                                showYearDropdown/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-lg-12 col-md-12" >
                    <div className='pull-right margin-right-15'>
                        <input type='button' className='btn btn-primary height48' value='OK' onClick={this.onLagreClick} />
                    </div>

                </div>
            </div>
            {this.dialogs.renderOpenDialog()}
        </div>;
    }
}

export default EndreTiltakstype;