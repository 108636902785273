import React, { Component } from 'react';
import { getSakskategorierForKunde } from 'api/arkivApi';
import ReactTable from '@norkart/react-table';
var _ = require('underscore');

class VisSakskategori extends Component {
  constructor(props) {
  super(props);
  this.state = { kat: null };
  }

componentDidMount() {
    getSakskategorierForKunde(
              this.props.kundeId,
              '123',
              (err, res) => {
                this.setState({ kat: res });
                console.log(res);
              }
            );
}

getColumns() {
  return [
    {
      id: 'Navn',
      name: 'Navn',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'Mappetype',
      name: 'Mappetype',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'StatusNySaksmappe',
      name: 'StatusNySak',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'StatusAvsluttSaksmappe',
      name: 'StatusAvsluttSak',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'Arkivdel',
      name: 'Arkivdel',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'LukkSak',
      name: 'Lukk sak',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        if (item.LukkSak) {return 'Ja'; } 
        else { return 'Nei';}
      }
    },
    {
      id: 'NyJp',
      name: 'Ny JP status',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        if (item.JournalpostKategori != null && item.JournalpostKategori.length > 0) {
          return item.JournalpostKategori[0].NyJournalpostArkiveringStatus;
        }
      }
    },
    {
      id: 'Skjermingshjemmel',
      name: 'Skjermingshjemmel',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'TilgangsgruppeNavn',
      name: 'Tilgangsgruppe navn',
      filterable: false,
      isSorted: false,
      sortable: false,
    },
    {
      id: 'Tilgangsrestriksjon',
      name: 'Tilgangsrestriksjon',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'KlasseListe',
      name: 'KlasseListe',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        console.log(item);
        if (item.KlasseListe != null) {
          return (
            <div>
              <table style={{ fontSize: '10px' }}>
              <tbody>
                <tr>
                <th style={{ width: '100px' }}>Klasse </th>
                
                <th style={{ width: '100px', textAlign: 'center' }}>Verdi </th>

                <th style={{ width: '100px', textAlign: 'center' }}>Tittel </th>
                
                <th style={{ width: '50px', textAlign: 'center' }}>Rekkefølge </th>
                </tr>
               {_.map(item.KlasseListe, function(k) {
                 return (
                  
                    <tr>
                      <td style={{ width: '100px' }}>{k.Klassifikasjonssystem}</td>
                      <td style={{ width: '100px', textAlign: 'center' }}>{k.KlasseID}</td>
                      <td style={{ width: '100px', textAlign: 'center' }}>{k.Tittel}</td>
                      <td style={{ width: '50px', textAlign: 'center' }}>{k.Rekkefolge}</td>
                    </tr>
                  
                 );
               })} 
               </tbody>
              </table>
             
            </div>

          );
        }
        
      }
    }
  ];
}

render() {
    if(!this.state.kat) { return null; }
   return (
    <div style={{ margin: '30px', fontSize: '10px' }}>
        <h4>Sakskategorier</h4>
        <ReactTable
            items={this.state.kat}
            columns={this.getColumns()}
            showIndex={false}
          />
        {/* <table border='1'>
        {_.map(
            this.state.kat,
            function(k) {
              console.log(k);
              return (
                
                <div>
                  
                  <tr>
                    <td>Navn</td>
                    <td>{k.Navn}</td>
                  </tr>
                  <tr>
                    <td>Mappetype</td>
                    <td>{k.Mappetype}</td>
                  </tr>
                  <tr>
                    <td>StatusNySaksmappe</td>
                    <td>{k.StatusNySaksmappe}</td>
                  </tr>
                  <tr>
                    <td>StatusAvsluttSaksmappe</td>
                    <td>{k.StatusAvsluttSaksmappe}</td>
                  </tr>
                  <tr>
                    <td>Klasse</td>
                    <td>{k.KlasseListe != null && k.KlasseListe.length > 0 ? k.KlasseListe[0].Klassifikasjonssystem : null}</td>
                  </tr>
                  <tr>
                    <td>Arkivdel</td>
                    <td>{k.Arkivdel}</td>
                  </tr>
                
                  
                </div>
              );
            },
            this
          )}
        </table> */}
    </div>
    );
  }
}

export default VisSakskategori;