import React from 'react';
import _ from 'lodash';
import KartDialog from 'components/Kart/KartDialog.jsx';
import ReactModal from 'react-modal';
import ContextMenu from 'components/ContextMenu';
import DialogStore from 'lib/DialogStore';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import NKIcon from 'nka-icons';
import ReactTable from '@norkart/react-table';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import EndreKommuner from './EndreKommuner.jsx';
import NyKommune from './NyKommune.jsx';
import './orgdetaljer.css';
import { getOrganisasjonTiltakRisikovurdering } from 'api/oppslagsdataApi.js';
import { getAlleKommunerForOrg } from 'api/kommuneApi';
import { getBruksenhetStatus } from 'api/arbeidslisteApi.js';
import { orgstartautomatiseringvarselimmediately } from 'api/innbyggerDialogApi.js';
import { contextOppslagKategorier } from 'context/contextOppslag.js';
import { getKundekonfigurasjon } from 'api/svarUtApi.js';

import {
  updateContextInnstillingerKart,
  updateOrganisasjonData,
  createOrganisasjonData,
} from 'context/contextInnstillinger.js';
import {
  statusKomtekVms,
  statusAnalyseplattform,
  statusAnalyseplattformFmeservers,
  statusRisikoanalyser,
  getBruksenhetMatrise,
  getBruksenhetMatriseFritid,
  getRoyklopmatrise,
  getRoyklopmatriseFritid
} from 'api/risikoApi.js';
// Utils
import { debugFactory } from 'utils';

const debug = debugFactory('nk:organisasjoner:orgdetaljer');

class OrgDetaljerNK extends React.Component {
  state = {
    organisasjon: null,
    orgKategorier: contextOppslagKategorier._currentValue.kategorier,
    svarutKonfigurasjon: '',
    kommune: [],
    selectedKommune: null,
    medFyring: true,
    risikoDataStatus: null,
    sisteLastet: null,
    startLastet: null,
    komtekWMSstatus: '--',
    Analyseplatformstatus: '--',
    AnalyseplatformstatusFME: '--',
    FeilAnalyser: '--',
    lastetRisiko: null,
    slettetRisiko: false,
    BruksenhetRisikoMatrise: 'Nei',
    RoklopRisikoMatrise: 'Nei',
    RoklopFritidRisikoMatrise: 'Nei',
    BruksenhetFritidRisikoMatrise: 'Nei',
    lastIkkeFritid: true,
    lastFritid: true,
    massivstatus: 'Ikke startet',
    massivskrivstatus: 'Ikke startet',
    AnalysedataStatus: null,
    laster: false,
  };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  isLoading = () => this.state.orgKategorier === [];

  componentDidMount() {
    if (!this.props.organisasjon) {
      let org = {
        ny: true,
        telefonBrukesVedPublisering: true,
        risikovurdering: false,
        beskrivelse: 'Ny organisasjon',
        kategoriId: 3,
        fontSize: '16px',
        fontType: 'Arial',
        smsAvvikLukkGjennomforing: true,
        smsAvvikLukkOppfolging: true,
        smsAvsender: null,
      };
      this.setState({ organisasjon: org });
    } else {
      this.setState({ organisasjon: this.props.organisasjon });
      this.getAlleKommunerForOrg();
      //this.sjekkRisikoMatriser();
    }
  }

  getAlleKommunerForOrg() {
    getAlleKommunerForOrg(this.props.organisasjon.id, (err, data) => {
      if (err) {
        alert(err.respons);
      }
      if (data) {
        this.setState({ kommune: data });
      }
    });
  }

  sjekkRisikoMatriser = () => {
    getBruksenhetMatrise((err, res) => {
      if (res.data != undefined && res.data != null) {
        this.setState({ BruksenhetRisikoMatrise: 'Ja' });
      }
    });
    getRoyklopmatrise((err, res) => {
      if (res.data != undefined && res.data != null) {
        this.setState({ RoklopRisikoMatrise: 'Ja' });
      }
    });
    getBruksenhetMatriseFritid((err, res) => {
      if (res.data != undefined && res.data != null) {
        this.setState({ BruksenhetFritidRisikoMatrise: 'Ja' });
      }
    });
    getRoyklopmatriseFritid((err, res) => {
      if (res.data != undefined && res.data != null) {
        this.setState({ RoklopFritidRisikoMatrise: 'Ja' });
      }
    });
  };

  oppdater = () => {
    this.getAlleKommunerForOrg();
    this.dialogs.closeDialog();
  };

  gotSenterpunkt = (lat, long, zoom) => {
    let org = this.state.organisasjon;
    debug('orgkart organisasjon', org);
    org.latitude = lat;
    org.longitude = long;
    org.zoomLevel = zoom;
    this.setState({ organisasjon: org });
    this.setState({ brukKartIsOpen: false });
    this.dialogs.closeDialog();
  };

  handleKategoriChange = (e) => {
    let org = this.state.organisasjon;
    org.kategoriId = e.target.value;
    this.setState({ organisasjon: org });
  };

  validerOk = () => {
    let ok = true;
    if (_.isEmpty((this.state.organisasjon.navn || '').trim())) {
      ok = false;
    }
    if (_.isEmpty((this.state.organisasjon.kortnavn || '').trim())) {
      ok = false;
    }

    if (!ok) {
      this.dialogs.setOpenDialog('ManglerDialog');
    }
    if (this.state.organisasjon.smsAvsender != null) {
      var Exp = /^([0-9]|[a-z])+([0-9a-z]+)$/i;
      if (!this.state.organisasjon.smsAvsender.match(Exp)) {
        this.dialogs.setOpenDialog('SMSAvsenderDialog');
        ok = false;
      }
    }
    return ok;
  };

  sjekkRisikoTiltaksyperDone = (err, res) => {
    if (res) {
      this.dialogs.setOpenDialog('ErTilknyttetRisikoDialog');
    } else {
      debug('ORG_X 88', this.state.organisasjon);
      if (this.state.organisasjon.id) {
        updateOrganisasjonData(this.state.organisasjon, this.props.onOk);
      } else {
        //Ny
        createOrganisasjonData(this.state.organisasjon, this.props.onOk);
      }
    }
  };

  lagre = () => {
    let org = this.state.organisasjon;
    if (!org.fontSize) {
      org.fontSize = '16px';
    }
    if (!org.fontType) {
      org.fontType = 'Arial';
    }
    if (this.validerOk()) {
      this.oppdaterKartInnstillingerContext();
      if (!org.ny && this.props.organisasjon.risikovurdering) {
        if (!org.risikovurdering) {
          getOrganisasjonTiltakRisikovurdering(
            org.id,
            this.sjekkRisikoTiltaksyperDone
          );
        } else {
          updateOrganisasjonData(org, this.props.onOk);
        }
      } else {
        if (org.id) {
          updateOrganisasjonData(org, this.props.onOk);
        } else {
          //Ny
          createOrganisasjonData(org, this.props.onOk);
        }
      }
    }
  };

  /*   lagreOld = () => {
    if (this.validerOk()) {
      if (
        !this.state.organisasjon.ny &&
        this.props.organisasjon.risikovurdering
      ) {
        if (!this.state.organisasjon.risikovurdering) {
          getOrganisasjonTiltakRisikovurdering(
            this.state.organisasjon.id,
            this.sjekkRisikoTiltaksyperDone
          );
        } else {
          debug('organisasjon lagre', this.state.organisasjon);
          this.oppdaterKartInnstillingerContext();
          this.props
            .updateOrganisasjon(this.state.organisasjon)
            .then(this.props.onOk);
        }
      } else {
        debug('organisasjon lagre zz', this.state.organisasjon);
        this.oppdaterKartInnstillingerContext();
        this.props
          .updateOrganisasjon(this.state.organisasjon)
          .then(this.props.onOk);
      }
    }
  }; */

  oppdaterKartInnstillingerContext = () => {
    updateContextInnstillingerKart({
      latitude: this.state.organisasjon.latitude,
      longitude: this.state.organisasjon.longitude,
      zoomLevel: this.state.organisasjon.zoomLevel,
    });
  };

  purringEtterChange = (e) => {
    debug('purring etter dager', e.target.value);
    let organisasjon = this.state.organisasjon;
    organisasjon.smsPurringVarselForsteDag = e.target.value;
    this.setState({ organisasjon });
  };

  purringAntallChange = (e) => {
    debug('purring antall ganger', e.target.value);
    let organisasjon = this.state.organisasjon;
    organisasjon.smsPurringVarselAntallganger = e.target.value;
    this.setState({ organisasjon });
  };

  varselUtsettChange = (e) => {
    let organisasjon = this.state.organisasjon;
    let utsettPaa = organisasjon.varselUtsettSending;
    if (!utsettPaa) {
      organisasjon.varselUtsettSending = true;
      this.setState({ organisasjon });
    } else {
      //Check først om det finnes enheter som har Planlagt status
      //Hvor mange ligger der som er planlagt?
      this.getAntallPlanlagtFirst();
    }
  };

  getAntallPlanlagtFirst = () => {
    getBruksenhetStatus(
      14,
      this.state.organisasjon.id,
      this.getAntallPlanlagtFirstGotResult
    );
  };

  getAntallPlanlagtFirstGotResult = (err, res) => {
    debug('planlagt antall', res);
    let antall = 0;
    if (res) {
      antall = res;
    }
    if (antall > 0) {
      //Kan ikke gjennomføres => melding
      this.dialogs.setOpenDialog('AntallPlanlagtDialog', {
        melding:
          'Du har ' +
          antall +
          ' bruksenheter med status "planlagt" (utsatt).' +
          '  Før du kan slå av "Utsatt sending" må dette endres først.' +
          '  Du kan sende gjeldende varsel manuellt eller applikasjonen kan gjøre det for deg.',
        melding2: 'Ønsker du at applikasjonen sender gjeldende varsel nå?',
      });
    } else {
      let organisasjon = this.state.organisasjon;
      organisasjon.varselUtsettSending = false;
      this.setState({ organisasjon });
    }
  };

  utsettAntallChange = (e) => {
    let organisasjon = this.state.organisasjon;
    organisasjon.varselUtsettSendingAntalldager = e.target.value;
    this.setState({ organisasjon });
  };

  planlagtDialogJa = () => {
    this.dialogs.closeDialog();
    let organisasjon = this.state.organisasjon;
    organisasjon.varselUtsettSending = false;
    this.setState({ organisasjon });
    orgstartautomatiseringvarselimmediately(
      this.state.organisasjon.id,
      this.orgstartautomatiseringvarselDone
    );
  };

  orgstartautomatiseringvarselDone = (err, res) => {
    if (err) {
      debug('automatiseringvarsel feil', err);
    }
    if (res) {
      debug('automatiseringvarsel', res);
    }
  };

  onKeyPress = (event) => {
    event.preventDefault();
    /*   const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) {
      event.preventDefault();
    } */
  };

  sprakChange = (val) => {
    // this.setState({
    //   organisasjon: {
    //     ...organisasjon,
    //     malFormBokmal: val.currentTarget.value === 'ja' ? true : false
    //   }
    // });
  };

  handleformatChange = (val) => {
    let organisasjon = this.state.organisasjon;
    organisasjon.arkivTittelFormatSak = val.target.value;
    this.setState({ organisasjon: organisasjon });
  };

  handleformatJpChange = (val) => {
    let organisasjon = this.state.organisasjon;
    organisasjon.arkivTittelFormatJp = val.target.value;
    this.setState({ organisasjon: organisasjon });
  };

  handleTittelChange = (val) => {
    let organisasjon = this.state.organisasjon;
    organisasjon.arkivKlasseTittel = val.target.value;
    this.setState({ organisasjon: organisasjon });
  };

  sjekkKonfigurasjonSvarut = () => {
    this.setState({ svarutKonfigurasjon: '' });
    getKundekonfigurasjon(
      this.state.organisasjon.kundeId,
      this.gotKonfigurasjon
    );
  };
  gotKonfigurasjon = (err, data) => {
    if (!data) {
      data = 'Ukjent..';
    }
    data = 'Svar-Ut-konfigurasjon:  ' + data.tekst;
    debug('KONF', data.tekst);
    this.setState({ svarutKonfigurasjon: data });
  };

  getColumns = () => {
    return [
      {
        id: 'kommuneNavn',
        name: 'Kommune',
        sortParams: 'kommuneNavn',
        isSorted: true,
        sortable: true,
        formatter: function(item) {
          return (
            <>
              <table>
                <tbody>
                  <tr>
                    <td>{item.kommuneId}</td>
                  </tr>
                  <tr>
                    <td>{item.kommuneNavn}</td>
                  </tr>
                </tbody>
              </table>
            </>
          );
        },
      },
      {
        id: 'x',
        name: 'Div..',
        sortParams: null,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let r = 'Nei';
          if (item.royklopMelding) {
            r = 'Ja';
          }
          let rp = '0';
          if (item.replikKundeId) {
            rp = item.replikKundeId;
          }
          return (
            <>
              <table style={{ fontSize: '14px' }}>
                <tbody>
                  <tr>
                    <td>ReplikKunde</td>
                    <td style={{ width: '4px' }} />
                    <td>{rp}</td>
                  </tr>
                  <tr>
                    <td>KtfMeldingstype</td>
                    <td style={{ width: '4px' }} />
                    <td>{item.ktfMeldingstypeId}</td>
                  </tr>
                  <tr>
                    <td>Røykløpmelding</td>
                    <td style={{ width: '4px' }} />
                    <td>{r}</td>
                  </tr>
                </tbody>
              </table>
            </>
          );
        },
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let ak = 'Ja';
          if (!item.aktiv) {
            ak = 'Nei';
          }
          return <span>{ak}</span>;
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn"
            >
              <ContextMenu.Item onClick={this.onEndreKommune.bind(this, item)}>
                Endre kommune
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
    ];
  };

  onEndreKommune = (row) => {
    this.setState({ selectedKommune: row });
    this.dialogs.setOpenDialog('EndreKommuneDialog', {
      kommune: row,
    });
  };

  onOpprettKommune = () => {
    this.dialogs.setOpenDialog('NyKommuneDialog');
  };

  formaterDato = (dato) => {
    if (dato == null) {
      return null;
    }
    var date = new Date(dato);
    if (date.getFullYear().toString() === '1') {
      return null;
    }
    var dag = date.getDate().toString();
    var mnd = (date.getMonth() + 1).toString();
    var aar = date.getFullYear().toString();
    var time = date.getHours().toString();
    var min = date.getMinutes().toString();
    if (dag.length === 1) {
      dag = '0' + dag;
    }
    if (mnd.length === 1) {
      mnd = '0' + mnd;
    }
    if (time.length === 1) {
      time = '0' + time;
    }
    if (min.length === 1) {
      min = '0' + min;
    }
    return dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
  };

  sjekkStatusKomtekWMS = () => {
    statusKomtekVms(this.wmsChecked);
  };
  wmsChecked = (err, res) => {
    let tmp = res ? 'OK' : 'Ikke OK';
    this.setState({ komtekWMSstatus: tmp });
  };

  sjekkStatusAnalyseplattform = () => {
    statusAnalyseplattform(this.analysePlattformChecked);
  };

  analysePlattformChecked = (err, res) => {
    let tmp = res ? 'OK' : 'Ikke OK';
    this.setState({ Analyseplatformstatus: tmp });
  };

  sjekkStatusAnalyseplattformFME = () => {
    statusAnalyseplattformFmeservers(this.fmeChecke);
  };

  fmeChecke = (err, res) => {
    let tmp = res ? 'OK' : 'Ikke OK';
    this.setState({ AnalyseplatformstatusFME: tmp });
  };

  sjekkStatusAnalyser = () => {
    statusRisikoanalyser(this.analyserChecked);
  };

  analyserChecked = (err, res) => {
    let tmp = res ? 'OK' : 'Ikke OK';
    this.setState({ FeilAnalyser: tmp });
  };

  onChangeSidemann = () => {
    if (this.state.organisasjon.dokumentkontroll) {
      this.dialogs.setOpenDialog('FjernSidemannDialog');
    } else {
      let organisasjon = this.state.organisasjon;
      this.setState({
        organisasjon: {
          ...organisasjon,
          dokumentkontroll: !organisasjon.dokumentkontroll,
        },
      });
    }
  };

  onSidemannAv = () => {
    let organisasjon = this.state.organisasjon;
    this.setState({
      organisasjon: {
        ...organisasjon,
        dokumentkontroll: !organisasjon.dokumentkontroll,
      },
    });
    this.dialogs.closeDialog();
  };

  render() {
    debug('render', { ...this });

    if (this.isLoading()) {
      return null;
    }

    // if (this.state.laster) {
    //   return (
    //     <div>
    //       Laster....
    //     </div>
    //   );
    // }

    let organisasjon, tittel;

    if (this.props.nyOrganisasjon) {
      tittel = '(NK) Ny organisasjon';
      organisasjon = this.state.organisasjon || {};
    } else {
      tittel = '(NK) Endre organisasjon';
      organisasjon = this.state.organisasjon || this.props.organisasjon;
    }

    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title"> {tittel} </h4>
        </div>
        <div className="modal-body">
          <div className="panel panel-default">
            <ul className="nav nav-tabs">
              <li className="active">
                <a data-toggle="tab" href="#orgtab">
                  Organisasjon
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#generelttab">
                  Generelt
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#noklertab">
                  Nøkler
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#smstab">
                  Innstilling SMS
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#karttab">
                  Kart
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#arkivtab">
                  Arkiv
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#risikotab">
                  Risikovurdering
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#kommunertab">
                  Kommuner
                </a>
              </li>
            </ul>
            <div className="panel-body">
              <div className="tab-content">
                {/* ******** TAB ************************************** */}
                <div id="orgtab" className="tab-pane fade in active">
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Aktiv</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.aktiv}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              aktiv: !organisasjon.aktiv,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Navn</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        autoFocus
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={organisasjon.navn}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              navn: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Kortnavn</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="10"
                        className="form-control"
                        type="text"
                        value={organisasjon.kortnavn}
                        onChange={(e) => {
                          debug('onChange', e.target.value);
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              kortnavn: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Kategori</label>
                    </div>
                    <div className="col-sm-8 form-check ">
                      <select
                        className="form-control"
                        value={organisasjon.kategoriId}
                        id="KategoriId"
                        onChange={this.handleKategoriChange}
                      >
                        {_.map(this.state.orgKategorier, function(o, i) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <br />
                  </div>
                  <br />
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Adresse</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.adresse}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              adresse: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Postnr. og poststed</label>
                    </div>
                    <div className="col-sm-2 form-group">
                      <input
                        maxLength="4"
                        className="form-control postnr"
                        type="text"
                        value={organisasjon.postnr}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              postnr: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.poststed}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              poststed: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Telefon</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                maxLength="12"
                                style={{
                                  width: '100px',
                                  marginRight: '50px',
                                }}
                                className="form-control"
                                type="text"
                                value={organisasjon.telefon}
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      telefon: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <div
                                className="tooltipI"
                                style={{ marginRight: '10px' }}
                              >
                                <NKIcon size="1" color="nk-black" icon="info" />
                                <span className="tooltiptextI">
                                  Telefonnummeret benyttes ved varsling. Fjern
                                  haken hvis du ønsker at feiernes mobilnummer
                                  benyttes istedenfor. Feiernes mobilnummer må
                                  da være lagt inn i feltet Mobil under
                                  Innstillinger\Brukere
                                </span>
                              </div>
                            </td>
                            <td>
                              <span style={{ marginRight: '10px' }}>
                                Bruk ved varsling
                              </span>
                            </td>
                            <td>
                              <input
                                className="checkbox30"
                                type="checkbox"
                                checked={
                                  organisasjon.telefonBrukesVedPublisering
                                }
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      telefonBrukesVedPublisering: !organisasjon.telefonBrukesVedPublisering,
                                    },
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Epost</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.epost}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              epost: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Webside</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.webside}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              webside: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Beskrivelse</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="250"
                        className="form-control"
                        type="text"
                        value={organisasjon.beskrivelse || ''}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              beskrivelse: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id="generelttab" className="tab-pane fade">
                  {/* *********/}
                  {/* <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '8px' }}>
                      <label>Risikovurdering</label>
                    </div>
                    <div className="col-sm-6 form-check ">
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.risikovurdering}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              risikovurdering: !organisasjon.risikovurdering,
                            },
                          });
                        }}
                      />
                    </div>
                  </div> */}
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '8px' }}>
                      <label>SvarUt</label>
                    </div>
                    <div className="col-sm-2 form-check ">
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.svarUt}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              svarUt: !organisasjon.svarUt,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-4">
                      <button
                        className="btn btn-default"
                        onClick={this.sjekkKonfigurasjonSvarut}
                        title="Sjekk konfigurasjon Svar Ut"
                      >
                        <NKIcon
                          size="0.8"
                          color="nk-black"
                          icon="lenke"
                          style={{ marginRight: '10px' }}
                        />
                        Ping
                      </button>
                    </div>
                  </div>
                  <span
                    style={{
                      color: 'blue',
                      fontSize: '12px',
                      marginLeft: '4px',
                    }}
                  >
                    {this.state.svarutKonfigurasjon}
                  </span>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '8px' }}>
                      <label>
                        {' '}
                        SvarUt - ikke send dersom en eier er digital (gjelder
                        varsel om besøk)
                      </label>
                    </div>
                    <div className="col-sm-6 form-check ">
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.svarUtSendesIkkeVedEnDigitalEier}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              svarUtSendesIkkeVedEnDigitalEier: !organisasjon.svarUtSendesIkkeVedEnDigitalEier,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '8px' }}>
                      <label>Varsle flere eiere</label>
                    </div>
                    <div className="col-sm-6 form-check ">
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={!organisasjon.smsDialogEnEier}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              smsDialogEnEier: !organisasjon.smsDialogEnEier,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '11px' }}>
                      <label>Målform</label>
                    </div>
                    <div
                      className="radio inline"
                      style={{ paddingLeft: '15px' }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="sprakRadio"
                          value="Bokmål"
                          defaultChecked={organisasjon.malFormBokmal === true}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                malFormBokmal: true,
                              },
                            });
                          }}
                        />
                        <span>Bokmål</span>
                      </label>
                    </div>
                    <div className="radio inline margin-left-15">
                      <label>
                        <input
                          type="radio"
                          name="sprakRadio"
                          value="Nynorsk"
                          defaultChecked={organisasjon.malFormBokmal === false}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                malFormBokmal: false,
                              },
                            });
                          }}
                        />
                        <span style={{ marginLeft: '5px' }}>Nynorsk</span>
                      </label>
                    </div>
                  </div>
                  {/* *********/}
                  {/*                  <div className='row'>
                    <div className='col-sm-4' style={{ paddingTop: '8px'}}>
                      <label >Oppfølging avvik per bruksenhet</label>
                    </div>
                    <div className='col-sm-8 form-check ' style={{ paddingTop: '8px'}}>
                      <input
                        className='checkbox30'
                        type='checkbox'
                        checked={organisasjon.oppfolgingAvvikBruksenhet}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              oppfolgingAvvikBruksenhet: !organisasjon.oppfolgingAvvikBruksenhet,
                            },
                          });
                        }}
                      />
                    </div>
                  </div> */}

                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '8px' }}>
                      <label>Gebyrkobling</label>
                    </div>
                    <div
                      className="col-sm-6 form-check "
                      style={{ paddingTop: '2px' }}
                    >
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.gebyrkobling}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              gebyrkobling: !organisasjon.gebyrkobling,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-6" style={{ paddingTop: '8px' }}>
                      <label>Dokumentkontroll</label>
                    </div>
                    <div
                      className="col-sm-6 form-check "
                      style={{ paddingTop: '2px' }}
                    >
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.dokumentkontroll}
                        onChange={(e) => {
                          this.onChangeSidemann(e);
                          // this.setState({
                          //   organisasjon: {
                          //     ...organisasjon,
                          //     dokumentkontroll: !organisasjon.dokumentkontroll,
                          //   },
                          // });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id="noklertab" className="tab-pane fade">
                  <div className="row" style={{ marginTop: '8px' }}>
                    <div className="col-sm-4 labelmarg">
                      <label>Organisasjon Id</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.id}
                        readOnly
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              kundeId: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {!this.props.nyOrganisasjon && (
                    <div className="row">
                      <div className="col-sm-4 labelmarg">
                        <label>Organisasjon Guid</label>
                      </div>
                      <div className="col-sm-8 form-group">
                        <input
                          maxLength="9"
                          className="form-control"
                          type="text"
                          value={organisasjon.globalOrgId}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Organisasjonsnummer</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="9"
                        className="form-control"
                        type="text"
                        value={organisasjon.organisasjonsnr}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              organisasjonsnr: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>AuthTokenTid</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='100'
                        className='form-control'
                        type='text'
                        value={organisasjon.authTokenTid}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              authTokenTid: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>KundeId Norkart</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.kundeId}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              kundeId: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>KundeId Sms</label>
                    </div>
                    <div className="col-sm-8 form-group">
                      <input
                        maxLength="100"
                        className="form-control"
                        type="text"
                        value={organisasjon.smsKundeId}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              smsKundeId: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id="smstab" className="tab-pane fade">
                  {/* *********/}
                  <div className="row">
                    <table style={{ marginLeft: '15px' }}>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              className="checkbox30"
                              type="checkbox"
                              checked={organisasjon.smsSkarp}
                              onChange={(e) => {
                                this.setState({
                                  organisasjon: {
                                    ...organisasjon,
                                    smsSkarp: !organisasjon.smsSkarp,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>
                              SMS skarp (OBS! SMS sendes ved avhuking)
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <input
                              className="checkbox30"
                              type="checkbox"
                              checked={organisasjon.smsTilbakemeldingInnbygger}
                              onChange={(e) => {
                                this.setState({
                                  organisasjon: {
                                    ...organisasjon,
                                    smsTilbakemeldingInnbygger: !organisasjon.smsTilbakemeldingInnbygger,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <span
                              style={{ fontWeight: 'bold', color: 'darkred' }}
                            >
                              Eier kan svare med SMS
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              className="checkbox30"
                              type="checkbox"
                              checked={organisasjon.smsPurringVarselInnbygger}
                              onChange={(e) => {
                                this.setState({
                                  organisasjon: {
                                    ...organisasjon,
                                    smsPurringVarselInnbygger: !organisasjon.smsPurringVarselInnbygger,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <span
                              style={{ fontWeight: 'bold', color: 'darkred' }}
                            >
                              Bruk purring
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {organisasjon.smsPurringVarselInnbygger && (
                      <React.Fragment>
                        <table style={{ marginLeft: '15px' }}>
                          <tbody>
                            <tr>
                              <td style={{ marginRight: '8px' }}>
                                Bruk purring etter &nbsp;
                              </td>
                              <td>
                                <input
                                  value={
                                    this.state.organisasjon
                                      ? this.state.organisasjon
                                          .smsPurringVarselForsteDag
                                      : 1
                                  }
                                  style={{
                                    paddingLeft: '2px',
                                    width: '44px',
                                  }}
                                  onChange={this.purringEtterChange}
                                  min="1"
                                  max="30"
                                  type="number"
                                  onKeyPress={this.onKeyPress.bind(this)}
                                />
                              </td>
                              <td>&nbsp; dager, og gjenta &nbsp;</td>
                              <td>
                                {' '}
                                <input
                                  value={
                                    this.state.organisasjon
                                      ? this.state.organisasjon
                                          .smsPurringVarselAntallganger
                                      : 1
                                  }
                                  style={{
                                    paddingLeft: '2px',
                                    width: '40px',
                                  }}
                                  onChange={this.purringAntallChange}
                                  min="1"
                                  max="3"
                                  type="number"
                                  onKeyPress={this.onKeyPress.bind(this)}
                                />
                              </td>
                              <td>&nbsp;ganger</td>
                            </tr>
                          </tbody>
                        </table>
                      </React.Fragment>
                    )}
                    <br />
                    <table style={{ marginLeft: '15px' }}>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              className="checkbox30"
                              type="checkbox"
                              checked={organisasjon.smsPaminnelseInnbygger}
                              onChange={(e) => {
                                this.setState({
                                  organisasjon: {
                                    ...organisasjon,
                                    smsPaminnelseInnbygger: !organisasjon.smsPaminnelseInnbygger,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <span
                              style={{ fontWeight: 'bold', color: 'darkred' }}
                            >
                              Send påminnelse på SMS dagen før
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />

                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            className="checkbox30"
                            type="checkbox"
                            checked={organisasjon.varselUtsettSending}
                            onChange={this.varselUtsettChange}
                            /*                             onChange={(e) => {
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  varselUtsettSending: !organisasjon.varselUtsettSending,
                                },
                              });
                            }} */
                          />
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <span
                            style={{ fontWeight: 'bold', color: 'darkred' }}
                          >
                            Utsatt sending
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {organisasjon.varselUtsettSending && (
                    <React.Fragment>
                      <table style={{ marginLeft: '42px' }}>
                        <tbody>
                          <tr>
                            <td style={{ marginRight: '8px' }}>
                              Antall dager &nbsp;
                            </td>
                            <td>
                              {' '}
                              <input
                                value={
                                  this.state.organisasjon
                                    ? this.state.organisasjon
                                        .varselUtsettSendingAntalldager
                                    : 0
                                }
                                style={{
                                  paddingLeft: '2px',
                                  width: '40px',
                                }}
                                onChange={this.utsettAntallChange}
                                min="0"
                                max="60"
                                type="number"
                                onKeyPress={this.onKeyPress.bind(this)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </React.Fragment>
                  )}
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            className="checkbox30"
                            type="checkbox"
                            checked={organisasjon.smsAvvikLukkGjennomforing}
                            onChange={(e) => {
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  smsAvvikLukkGjennomforing: !organisasjon.smsAvvikLukkGjennomforing,
                                },
                              });
                            }}
                          />
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Send SMS (eller SvarUt) ved lukk avvik i
                            Gjennomføring
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input
                            className="checkbox30"
                            type="checkbox"
                            checked={organisasjon.smsAvvikLukkOppfolging}
                            onChange={(e) => {
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  smsAvvikLukkOppfolging: !organisasjon.smsAvvikLukkOppfolging,
                                },
                              });
                            }}
                          />
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <span
                            style={{
                              fontWeight: 'bold',
                            }}
                          >
                            Send SMS (eller SvarUt) ved lukk avvik i Oppfølging
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <div className="row">
                    <div className="col-sm-3 labelmarg">
                      <label>SMS avsender</label>
                    </div>
                    <div className="col-sm-3 form-group">
                      <input
                        tooltip="Test"
                        maxLength="11"
                        className="form-control"
                        type="text"
                        value={organisasjon.smsAvsender}
                        onChange={(e) => {
                          debug('onChange', e.target.value);
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              smsAvsender: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                </div>

                {/* ******** TAB ************************************** */}
                <div id="karttab" className="tab-pane fade">
                  <div className="row">
                    <div className="col-sm-4 labelmarg">
                      <label>Startlokasjon ved åpning av kart</label>
                    </div>

                    <div
                      className="col-sm-8 form-group"
                      style={{ marginBottom: '60px' }}
                    >
                      <button
                        className="btn btn-default"
                        onClick={() =>
                          this.dialogs.setOpenDialog('KartDialog', {
                            organisasjon,
                          })
                        }
                      >
                        Finn startlokasjon på kart
                      </button>
                      <br />
                      <div className="input-group">
                        <span className="input-group-addon koordinat">Lat</span>

                        <input
                          maxLength="250"
                          className="form-control"
                          type="text"
                          value={organisasjon.latitude}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                latitude: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className="input-group">
                        <span className="input-group-addon koordinat">Lng</span>
                        <input
                          maxLength="250"
                          className="form-control"
                          type="text"
                          value={organisasjon.longitude}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                longitude: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className="input-group">
                        <span className="input-group-addon koordinat">
                          Zoom
                        </span>
                        <input
                          maxLength="250"
                          className="form-control"
                          type="text"
                          value={organisasjon.zoomLevel}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                zoomLevel: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* ******** TAB ************************************** */}
                <div id="arkivtab" className="tab-pane fade">
                  {/* *********/}
                  <div className="row">
                    <div className="col-sm-4">
                      <label>Ikke Arkivering</label>
                    </div>
                    <div className="col-sm-2 form-check ">
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={organisasjon.arkivIkkeAktiv}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              arkivIkkeAktiv: !organisasjon.arkivIkkeAktiv,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="col-sm-6">(Slå av arkivering)</div>
                  </div>

                  {organisasjon.arkivIkkeAktiv ? null : (
                    <div>
                      <br />
                      {/* *********/}
                      <div className="row">
                        <div className="col-sm-4">
                          <label>Aktiver arkiv funksjonalitet</label>
                        </div>
                        <div className="col-sm-1 form-check ">
                          <input
                            className="checkbox30"
                            type="checkbox"
                            checked={organisasjon.arkivFunksjonalitetArkivert}
                            onChange={(e) => {
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  arkivFunksjonalitetArkivert: !organisasjon.arkivFunksjonalitetArkivert,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="col-sm-7">
                          (Gjelder GUI for arkiv funksjonalitet under
                          Gjennomføring)
                        </div>
                      </div>
                      <br />
                    </div>
                  )}
                </div>

                {/* ******** TAB ************************************** */}
                <div id="risikotab" className="tab-pane fade">
                  {/* *********/}

                  <div class="panel-group">
                    <div class="panel panel-default">
                      <div class="panel-heading"><b>Risikovurdering oppsett</b></div>
                      <div class="panel-body">
                        <div className="row">
                          <div
                            className="col-sm-6"
                            style={{ paddingTop: '8px' }}
                          >
                            <div style={{ paddingLeft: '15px' }}>
                              Risikovurdering
                            </div>
                          </div>
                          <div className="col-sm-1 form-check ">
                            <input
                              className="checkbox30"
                              type="checkbox"
                              checked={organisasjon.risikovurdering}
                              onChange={(e) => {
                                this.setState({
                                  organisasjon: {
                                    ...organisasjon,
                                    risikovurdering: !organisasjon.risikovurdering,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        {organisasjon.risikovurdering ? (
                          <div>
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ paddingTop: '8px' }}
                              >
                                <div style={{ paddingLeft: '15px' }}>
                                  Risikovurdering på bruksenhet tilknyttet
                                  fritidsbygg
                                </div>
                              </div>
                              <div className="col-sm-1 form-check ">
                                <input
                                  className="checkbox30"
                                  type="checkbox"
                                  checked={
                                    organisasjon.risikovurderingBruksenhetFritid
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      organisasjon: {
                                        ...organisasjon,
                                        risikovurderingBruksenhetFritid: !organisasjon.risikovurderingBruksenhetFritid,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ paddingTop: '8px' }}
                              >
                                <div style={{ paddingLeft: '15px' }}>
                                  Risikovurdering på røykløp tilknyttet
                                  fritidsbygg
                                </div>
                              </div>
                              <div className="col-sm-1 form-check ">
                                <input
                                  className="checkbox30"
                                  type="checkbox"
                                  checked={
                                    organisasjon.risikovurderingRoyklopFritid
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      organisasjon: {
                                        ...organisasjon,
                                        risikovurderingRoyklopFritid: !organisasjon.risikovurderingRoyklopFritid,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ paddingTop: '8px' }}
                              >
                                <div style={{ paddingLeft: '15px' }}>
                                  Risikovurdering på bruksenheter uten
                                  fyringsanlegg
                                </div>
                              </div>
                              <div className="col-sm-1 form-check ">
                                <input
                                  className="checkbox30"
                                  type="checkbox"
                                  checked={
                                    organisasjon.risikovurderingUtenFyringsanlegg
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      organisasjon: {
                                        ...organisasjon,
                                        risikovurderingUtenFyringsanlegg: !organisasjon.risikovurderingUtenFyringsanlegg,
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>

                    <div class="panel panel-default">
                      <div class="panel-heading"><b>Status integrasjoner</b></div>
                      <div class="panel-body">
                        <div className="row">
                          <div
                            className="col-sm-4"
                            style={{ paddingTop: '8px', paddingLeft: '30px' }}
                          >
                            <button
                              className="btn btn-default "
                              style={{ paddingLeft: '15px', width: '250px' }}
                              title="Status Komtek WMS"
                              onClick={this.sjekkStatusKomtekWMS}
                            >
                              <span style={{ marginLeft: '5px' }}>
                                Sjekk status Komtek WMS
                              </span>
                            </button>
                          </div>
                          <div
                            className="col-sm-2"
                            style={{ paddingTop: '14px', paddingLeft: '30px' }}
                          >
                            {this.state.komtekWMSstatus}
                          </div>

                          <div
                            className="col-sm-4"
                            style={{ paddingTop: '8px', paddingLeft: '30px' }}
                          >
                            <button
                              className="btn btn-default "
                              style={{ paddingLeft: '15px', width: '250px' }}
                              title="Status analyseplatform"
                              onClick={this.sjekkStatusAnalyseplattform}
                            >
                              <span style={{ marginLeft: '5px' }}>
                                Sjekk status analyseplatform
                              </span>
                            </button>
                          </div>
                          <div
                            className="col-sm-2"
                            style={{ paddingTop: '14px', paddingLeft: '30px' }}
                          >
                            {this.state.Analyseplatformstatus}
                          </div>
                        </div>

                        <div className="row">
                          <div
                            className="col-sm-4"
                            style={{ paddingTop: '8px', paddingLeft: '30px' }}
                          >
                            <button
                              className="btn btn-default"
                              style={{ paddingLeft: '15px', width: '250px' }}
                              title="Status analyseplatform"
                              onClick={this.sjekkStatusAnalyseplattformFME}
                            >
                              <span style={{ marginLeft: '5px' }}>
                                Sjekk status analyseplatform FME
                              </span>
                            </button>
                          </div>
                          <div
                            className="col-sm-2"
                            style={{ paddingTop: '14px', paddingLeft: '30px' }}
                          >
                            {this.state.AnalyseplatformstatusFME}
                          </div>

                          <div
                            className="col-sm-4"
                            style={{ paddingTop: '8px', paddingLeft: '30px' }}
                          >
                            <button
                              className="btn btn-default"
                              style={{ paddingLeft: '15px', width: '250px' }}
                              title="Status analyseplatform"
                              onClick={this.sjekkStatusAnalyser}
                            >
                              <span style={{ marginLeft: '5px' }}>
                                Sjekk status analyse
                              </span>
                            </button>
                          </div>
                          <div
                            className="col-sm-2"
                            style={{ paddingTop: '14px', paddingLeft: '30px' }}
                          >
                            {this.state.FeilAnalyser}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id="kommunertab" className="tab-pane fade">
                  <>
                    {' '}
                    <span>
                      <label>
                        Kommuner i organisasjon {organisasjon.navn}{' '}
                      </label>
                    </span>
                    <span style={{ marginLeft: '40px' }}>
                      <button
                        onClick={this.onOpprettKommune}
                        style={{ fontWeight: 'Bold', fontSize: '16px' }}
                        type="button"
                        className="btn btn-link"
                      >
                        Ny kommune
                      </button>
                    </span>
                  </>{' '}
                  <br />
                  <ReactTable
                    items={this.state.kommune}
                    idProperty="id"
                    showIndex={false}
                    filterable={true}
                    columns={this.getColumns(this)}
                    selectableRows={false}
                    selectAllLabel="Alle"
                    onRowClick={this.onEndreKommune}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="btn-toolbar pull-right" role="toolbar">
            <button
              type="button"
              onClick={this.lagre.bind(this)}
              className="btn btn-primary h48"
              style={{ padding: '0 25px' }}
            >
              OK
            </button>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default OrgDetaljerNK;

const modalDialogs = {
  KartDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal
      isOpen={isOpen}
      onClose={onClose}
      className="FModal"
      contentLabel=""
    >
      <KartDialog
        visning="startpunkt"
        onClose={onClose}
        objektIn={dialogData.organisasjon}
        objektUt={owner.gotSenterpunkt}
        tittel={'Startpunkt for ' + dialogData.organisasjon.navn}
        minimap={true}
      />
      {/* gotSenterpunkt={this.gotSenterpunkt}  */}
    </ReactModal>
  ),

  EndreKommuneDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EndreKommuner
      isOpen={isOpen}
      tittel="Endre kommune"
      onClose={onClose}
      oppdater={owner.oppdater}
      kommune={dialogData.kommune}
    />
  ),

  NyKommuneDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NyKommune
      isOpen={isOpen}
      tittel="Ny kommune"
      onClose={onClose}
      oppdater={owner.oppdater}
    />
  ),

  ManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel="Fyll ut navn"
      melding="Både Navn og Kortnavn må fylles ut."
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  SMSAvsenderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel="SMS avsender"
      melding="Avsender kan bare innholde bokstaver og tall"
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  ErTilknyttetRisikoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel="Risikovurdering"
      melding="Det finnes tiltaksyper som er tilknyttet risikovurdering. Disse tilknytningene må fjernes før risikovurdering fjernes fra organisasjonen."
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  AntallPlanlagtDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel="Bruksenheter med status planlagt"
        melding={dialogData.melding}
        melding2={dialogData.melding2}
        onJa={owner.planlagtDialogJa}
        onNei={onClose}
      />
    );
  },

  FjernSidemannDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel="Deaktiver dokumentkontroll"
      melding="Ved å deaktivere dokumentkontroll vil dokumentkontroll bli slått av for alle brukere"
      isOpen={isOpen}
      onClose={onClose}
      onOk={owner.onSidemannAv}
    />
  ),
};
