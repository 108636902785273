import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
//import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/align.min.js';
import {
  contextInnstillingerMalinfo,
  getContextInnstillingerMalinfo,
} from 'context/contextInnstillinger.js';
import { debugFactory, promisifyCall } from 'utils';
const debug = debugFactory('nk:EditorNormal');

/*  const NORMALSTYLE =
  '<!-- -- BFNORMAL-START ----><div style="font-family: Arial; font-size: 16px; color: black; line-height: 1.5;"><p></p><!-- -- BFNORMAL-END ---->';  */
const MottakerVarselEierDialog =
  '<!-- mott1 --><span contenteditable="false" style="background-color: yellow; color: blue;">Ved &quot;Send svar til eier&quot;<br>vises mottakerne her..<br>Vennligst ikke fjern.</span><!-- mott2 -->';

export const getMalinfo = () => {
  getContextInnstillingerMalinfo();
};

export const getFonter = () => {
  return null;
};

export const getMalDokumentPrMottaker = () => {
  debug('MMMM normal', contextInnstillingerMalinfo._currentValue);
  if (contextInnstillingerMalinfo._currentValue.init === true) {
    return contextInnstillingerMalinfo._currentValue.malDokumentPrMottaker;
  } else {
    return false;
  }
};

export const checkEditorNormalX = (model) => {
  promisifyCall(getContextInnstillingerMalinfo)().then((f) => { 
    let ff = f.fontType;
    if (ff === 'Calibri') {
      ff = 'Verdana';
    }
    let fs = f.fontSize;
    let NORMALSTYLE =
      '<!-- -- BFNORMAL-START ----><div style="font-family: ' +
      ff +
      '; font-size: ' +
      fs +
      '; color: black; line-height: 1.5;"><p></p><!-- -- BFNORMAL-END ---->';
  
    if (!model.includes('BFNORMAL-START')) {
      model = NORMALSTYLE + model;
    }
    return model;
  });
};

export const checkEditorNormal = (model) => {
  let f = contextInnstillingerMalinfo._currentValue;
  console.log('Zorro normal', getContextInnstillingerMalinfo());
  let ff = f.fontType;
  if (ff === 'Calibri') {
    ff = 'Verdana';
  }
  let fs = f.fontSize;
  let NORMALSTYLE =
    '<!-- -- BFNORMAL-START ----><div style="font-family: ' +
    ff +
    '; font-size: ' +
    fs +
    '; color: black; line-height: 1.5;"><p></p><!-- -- BFNORMAL-END ---->';

  if (!model.includes('BFNORMAL-START')) {
    model = NORMALSTYLE + model;
  }
  return model;
};

export const skjulTabellKantlinjer = (editor) => {
  //Finn alle tabeller i editor.el
  //Har de ingen border, da gir dem midtlertidig border
  let bStyle = '1px dotted white';
  Array.from(editor.el.querySelectorAll('table')).forEach(function(tab) {
    if (
      tab.style.border === '' ||
      tab.style.border === 'none' ||
      tab.style.border === '1px solid rgb(221, 221, 221)'
    ) {
      tab.style.border = bStyle;
    }
    for (let y = 0; y < tab.rows.length; y++) {
      let row = tab.rows[y];
      for (let z = 0; z < row.children.length; z++) {
        let celle = row.children[z];
        if (
          celle.style.border === '' ||
          celle.style.border === 'none' ||
          celle.style.border === '1px solid rgb(221, 221, 221)'
        ) {
          celle.style.border = bStyle;
        }
      }
    }
  });
  return true;
};

export const visTabellKantlinjerIgjen = (editor) => {
  //Finn alle tabeller i editor.el
  //Har de en bestemt border, da gir dem ingen border
  let bStyle = '1px dotted white';
  Array.from(editor.el.querySelectorAll('table')).forEach(function(tab) {
    if (tab.style.border === bStyle) {
      tab.style.border = '';
    }
    for (let y = 0; y < tab.rows.length; y++) {
      let row = tab.rows[y];
      for (let z = 0; z < row.children.length; z++) {
        let celle = row.children[z];
        if (celle.style.border === bStyle) {
          celle.style.border = '';
        }
      }
    }
  });
  return true;
};

export const getHtmlBeforeSaveOld = (editor) => {
  Array.from(editor.el.querySelectorAll('table')).forEach(function(tab) {
    if (
      tab.style.border === '1px solid rgb(221, 221, 221)' ||
      tab.style.border === '1px dotted white'
    ) {
      tab.style.border = 'none';
    }
    for (let y = 0; y < tab.rows.length; y++) {
      let row = tab.rows[y];
      for (let z = 0; z < row.children.length; z++) {
        let celle = row.children[z];
        if (
          (celle.style.border === '1px solid rgb(221, 221, 221)',
          celle.style.border === '1px dotted white')
        ) {
          celle.style.border = 'none';
        }
      }
    }
  });
  return editor.html.get();
};

export const getHtmlBeforeSave = (txt) => {
  let s = txt.replaceAll('1px solid rgb(221, 221, 221', 'none');
  s = s.replaceAll('1px dotted white', 'none');
  return s;
};

export const getMottakerVarselEierDialog = () => {
  return MottakerVarselEierDialog;
};
