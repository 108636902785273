/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import NKIcon from 'nka-icons';
import { PropTypes } from 'prop-types';
import { AuthContext } from 'norkartidjs2';
import { useContext } from 'react';

const LoginComponent = ({ router }) => {
    const authMsal = useContext(AuthContext);

     const login = (e) => {
        e.preventDefault();
        authMsal.logIn();
    };
    const logout = (e) => {
        e.preventDefault();
        authMsal.logOut();
    };

    if (authMsal.isAuthenticated) {
        return (
     
            <li>
                <a href="#" onClick={logout}>
                    Logg ut
                        <span className="pull-right">
                        <NKIcon icon="logg-ut" size="1" color="nk-white" />
                    </span>
                </a>
            </li>
        );
    }
    return (
        <li>
       
            <a href="#" onClick={login}>
                Logg inn
                    <span className="pull-right">
                    <NKIcon icon="bruker" size="1" color="nk-white" />
                </span>
            </a>
        </li>
    );
};
LoginComponent.contextTypes = {
    router: PropTypes.object.isRequired
};

export default LoginComponent;