import React from 'react';
import { debugFactory, parseMultiLineString } from 'utils';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:components:exceptions:routefallbackui');

const RouteFallbackUi = (props) => {
  debug('RouteFallbackUi', { props });

  const { handledAt, error, info } = props;
  let navn = contextInnstillingerPerson._currentValue.navn; 
  let orgNavn = contextInnstillingerPerson._currentValue.orgNavn;
  const user = parseMultiLineString( navn + ' ' + orgNavn );

  debug('RouteFallbackUi', { props, handledAt, user });

  return (
    <div className='panel'>
      <div className='panel-heading'>
        <div>
          <h2>En feil oppstod!</h2>

          <div>
            {handledAt && <span>[ handledAt: {handledAt} ]</span>}
            <br />
            {user && <span>[ user: {user} ]</span>}
            <br />[ {String(error)} ]
          </div>
          <br />
          <strong>
            Noen ganger vil problemet løse seg ved å hente data på nytt ved å
            trykke F5 på tastaturet.
          </strong>
        </div>
      </div>

      <div className='panel-heading'>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          <summary>Detaljer</summary>
          {error.toString()}
          <br />
          {info.componentStack}
        </details>
      </div>
    </div>
  );
};

export default RouteFallbackUi;
