import * as actionTypes from './actionTypes';

const setFetchState = (state, { fetchKey, fetchState }) => ({
    ...state.fetchStates,
    [fetchKey]: fetchState,
});

const initialState = {
    fetchStates: {},
    stateSnapshots: {},
    requestCount: 0,  
    exception: null,  
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INCREMENT_REQUEST_COUNT:
            return {
                ...state,
                requestCount: (state.requestCount + 1),
            };
        case actionTypes.DECREMENT_REQUEST_COUNT:
            return {
                ...state,
                requestCount: Math.max(state.requestCount - 1, 0),
            };
        case actionTypes.EXCEPTION_OCCURED:
            return {
                ...state,
                requestCount: 0,
                exception: action.payload,
            };
        case actionTypes.UPDATE_FETCH_STATE:
            return {
                ...state,
                fetchStates: setFetchState(state, action.payload),
            };
        case actionTypes.SET_STATE_SNAPSHOT:
            return {
                ...state,
                stateSnapshots: {
                    ...state.stateSnapshots,
                    [action.payload.snapshotKey]: action.payload.snapshot,
                }
            };
        default:
            return state;
    }
};