import reqwest from 'reqwest';
import norkartIdJs from 'auth/auth';
import HandleErrors from '../errorHandler';
import CONFIG from 'lib/CONFIG';

let HidePersonNummer =  '?HidePersonNummer=true';
let profile = CONFIG.REACT_APP_PROFILE;

const getCurrentCustomerId = () => {
  let cust = norkartIdJs.getCurrentCustomerContext();
  return cust !== undefined ? cust.CustomerId : '';
};

const makeCall= function(method, path, data, callback, token, Base_Url, isFritekstSok) {
  var headers = {
    Accept: 'application/json; charset=utf-8'
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
    headers['X-WAAPI-Profile'] = profile;
    headers['X-WAAPI-CONTEXT'] = getCurrentCustomerId();
}
  reqwest({
    url: isFritekstSok ? Base_Url + path + data + HidePersonNummer : Base_Url + path,
    crossOrigin: true,
    type: 'json',
    method: method,
    contentType: 'application/json; charset=utf-8',
    headers: headers,    
    error: function(err) {
      try {
        HandleErrors(err);
        callback(JSON.parse(err.response));
      } catch (e) {
        //HandleErrors(err);
        callback(err);
      }
    },
    success: function(resp) {      
      callback(null, resp);
    }
  });
};

export default function callApi(method, path, data, callback, Base_Url, isFritekstSok) {
  norkartIdJs.getToken().then(token => {
    makeCall(method, path, data, callback, token, Base_Url, isFritekstSok);
  }).catch(err => callback(err));
};
