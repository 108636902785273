import React from 'react';

class MeldingDialog extends React.Component {
        render() {
            return (
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" type="button" onClick={this.props.onClose}>
                        <span>x</span>
                        <span className="sr-only">Lukk</span>
                    </button>
                        <h4 className="modal-title">{this.props.meldingHeader}</h4>
                    </div>
                    <div className="modal-body" style={{marginLeft: '5%'}}>
                        {this.props.melding}
                    </div>
                    <div className="modal-footer">
                        <div className='pull-left'>
                        <button className='btn btn-primary height48' onClick={this.props.onOK}>OK</button>
                        </div>
                    </div>
                </div>
            );
        }
};
export default MeldingDialog;