import React from 'react';
import { asRouteComponent } from 'components/Router';
import MinSide from './MinSide';

import { debugFactory } from 'utils';

const debug = debugFactory('nk:tilgang:index')

export default asRouteComponent((props) => {
    debug('props', { props });
    return (
        <MinSide {...props} />
    )
});