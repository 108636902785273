import React from 'react';
import Prosesslogg from 'modules/Prosesslogg/Prosesslogg.jsx';


class index extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
    
  render() {
    return <Prosesslogg type = 'alle'></Prosesslogg>; 
  }
}
export default index;