import React from 'react';
import { getTiltakType } from 'api/oppslagsdataApi.js';
import ContextMenu from 'components/ContextMenu';
import ReactTable from '@norkart/react-table';
import NKModal from 'components/Dialogs/NKModal.jsx';
import EndreTiltakArkiv from './EndreTiltakArkiv.jsx';
import DialogStore from 'lib/DialogStore';

const modalDialogs = {
    EndreTiltakArkivDialog: ({ isOpen, onClose, dialogData, owner }) => (
      <NKModal size="medium" isOpen={isOpen} onClose={onClose}>
        <EndreTiltakArkiv
          onClose={onClose}
          tittel={dialogData.tittel}
          arkivere={dialogData.arkivere}
          tiltak= {dialogData.tiltak}
          lagret={owner.lagret}
        />
      </NKModal>
    ),
  };

class Tiltakstyper extends React.Component {
    constructor(props) {
        super(props);
        this.dialogs = new DialogStore({
          owner: this,
          dialogs: modalDialogs,
        });
      }
  state = { tiltakstyper: null };

  componentDidMount() {
    getTiltakType(this.gotTyper);
  }

  gotTyper = (err, res) => {
    this.setState({ tiltakstyper: res });
  };

  endre = (item) => {
    this.dialogs.setOpenDialog('EndreTiltakArkivDialog', {
        tittel: 'Endre tiltakstype ' + item.tekst,
        arkivere: item.arkiv,
        tiltak: item
      });
  };

  lagret = () => {
    getTiltakType(this.gotTyper);
    this.dialogs.closeDialog();
  }

  render() {
    if (!this.state.tiltakstyper) {
      return <div className="loader" />;
    }
    let kol = this.getColumnsTiltakType();
    return (
      <div>
          <div className="row">
          <div className="col-sm-12">
            <h2>Tiltakstyper</h2>
          </div>
          </div>
        <div className="row">
          <div className="col-sm-12">
            <ReactTable
              items={this.state.tiltakstyper}
              idProperty="id"
              showIndex={false}
              filterable={false}
              columns={kol}
              onRowClick={this.endre}
            />
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  getColumnsTiltakType = () => {
    return [
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'tiltakObjekt',
        name: 'Objekt',
        sortParams: 'tiltakObjekt',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
    //   {
    //     id: "aktiv",
    //     name: "Aktiv",
    //     sortParams: "aktiv",
    //     filterable: false,
    //     isSorted: false,
    //     sortable: true,
    //     formatter: function(item) {
    //       if (item.aktiv) {
    //         return "Ja";
    //       } else {
    //         return "Nei";
    //       }
    //     },
    //   },
    //   {
    //     id: "standard",
    //     name: "Standard",
    //     sortParams: "standard",
    //     filterable: false,
    //     isSorted: false,
    //     sortable: true,
    //     formatter: function(item) {
    //       if (item.standard) {
    //         return "Ja";
    //       } else {
    //         return "Nei";
    //       }
    //     },
    //   },
    //   {
    //     id: "risikovurdering",
    //     name: "Risikovurdering",
    //     sortParams: "risikovurdering",
    //     filterable: false,
    //     isSorted: false,
    //     sortable: true,
    //     formatter: function(item) {
    //       if (item.risikovurdering) {
    //         return "Ja";
    //       } else {
    //         return "Nei";
    //       }
    //     },
    //   },
      {
        id: 'arkiv',
        name: 'Arkiv',
        sortParams: 'arkiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.arkiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn"
            >
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };
}

export default Tiltakstyper;
