import React from 'react';
import norkartIdJs from 'auth/auth';
import moment from 'moment';
import { systemcheck, updateorganisasjon, innlogging } from 'api/innloggingApi';
// Obs!
import BrukernavnKobler from './dialogs/BrukernavnKobler2.jsx';
// Obs! Ny
import LogOut from './dialogs/LogOut.jsx';
import Organisasjoner from './dialogs/Organisasjoner.jsx';
import Context from './dialogs/Context.jsx';
import brann from 'images/Brannforebygging.png';
import { getPersonNew } from 'api/personApi';
import {
  oppdaterOrgNavn,
  setContextInnstillingerPerson,
  setContextInnstillingerDatoInnlogging,
} from 'context/contextInnstillinger.js';
import { debugFactory, promisifyApiCall } from 'utils';
import { AuthContext } from 'norkartidjs2';
import CONFIG from 'lib/CONFIG';

let version = CONFIG.REACT_APP_VERSION;

const debug = debugFactory('nk:auth.requiresauth');

const loginStates = Object.freeze({
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  // Obs! Ny
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  IS_LOADING_USERDATA: 'IS_LOADING_USERDATA',
  SELECT_ORGANISATION: 'SELECT_ORGANISATION',
  IS_READY: 'IS_READY',
  HAS_ERROR: 'HAS_ERROR',
  SYSTEMSTATE_FALSE: 'SYSTEMSTATE_FALSE',
  FAILED_LOADING_USERDATA: 'FAILED_LOADING_USERDATA',
  LOGIN_NOT_CONNECTED_TO_USERDATA: 'LOGIN_NOT_CONNECTED_TO_USERDATA',
  // Multicontext
  SELECT_CONTEXT: 'SELECT_CONTEXT',
});

let userPermissions = null;

function requiresAuth(Component, { LoadingScreen, LoginScreen, ...options }) {
  class AuthenticatedComponent extends React.Component {
    static contextType = AuthContext;

    constructor() {
      super();

      this.state = {
        organisasjonValgt: false,
        error: null,
        statusSystem: null,
        // Obs!
        triggerRender: false,
        context: null,
        contextInitiert: false,
        brukContext: false,
      };

      this.LOGIN_STATE = 'INITIAL';
    }

    // componentDidMount() {
    //   //systemcheck(this.gotCheck);
    //   this._checkAndRedirect();
    // }

    gotCheck = (err, data) => {
      this.setState({ statusSystem: data });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (!prevState.error) {
        this._checkAndRedirect();
      }
    }

    // Henter context under oppstart
    getMultiCustomerContexts = () => {
      this.context.getCustomerContexts().then((contexts) => {
        this.setState({ context: contexts });
        if (contexts.length > 1) {
          this.setState({ brukContext: true });
        }
        this.setState({ contextInitiert: true });
        if (this.state.contextInitiert && this.state.statusSystem === null) {
          systemcheck(this.gotCheck);
        }
      });
    };

    _checkAndRedirect() {
      this.setLoginState();

      if (this.context.isAuthorized) {
        if (!this.state.contextInitiert) {
          this.getMultiCustomerContexts();
        }

        if (this.state.contextInitiert && !this.state.brukContext) {
          this.context.getUserData().then((user) => {
            if (user && !userPermissions) {
              return promisifyApiCall(innlogging)().then((res) => {
                userPermissions = res;
                promisifyApiCall(getPersonNew)().then((data) => {
                  setContextInnstillingerPerson(data);
                  //************************************************************************** */
                  setContextInnstillingerDatoInnlogging();
                  //************************************************************************** */
                  this.setLoginState();
                  // Obs!
                  this.setState({ triggerRender: !this.state.triggerRender });
                  this.setState({ triggerRender: !this.state.triggerRender });
                });
                // Obs!
                this.setState({ triggerRender: !this.state.triggerRender });
                this.setState({ triggerRender: !this.state.triggerRender });
              });
            }
          });
        }
      }
    }

    onOrganisasjonValgt = (item) => {
      return promisifyApiCall(updateorganisasjon)(item.id).then(() => {
        this.setState({ organisasjonValgt: true });
        //Endre i ContextInnstillinger
        oppdaterOrgNavn(item);
        // Obs!  Du hadde endret navn på 'test'
        this.setLoginState();
        this.test();
      });
    };

    onContextValgt = (item) => {
      this.setState({ brukContext: false });
      this.context.setCurrentCustomerContext(item).then((res) => {
        if (res) {
          this.setState({ organisasjonValgt: true });
          //Endre i ContextInnstillinger
          //oppdaterOrgNavn(item);
          this.setLoginState();
          this.test();
        }
      });
    };

    // Obs!  Du hadde endret navn på 'test'
    test = () => {
      promisifyApiCall(innlogging)().then((res) => {
        let obj = {
          navn: res.orgNavn,
          id: res.orgId,
        };
        oppdaterOrgNavn(obj);
        this.setState({ triggerRender: !this.state.triggerRender });
      });
    };

    logout(e) {
      e.preventDefault();
      norkartIdJs.logout();
    }

    fortsett = () => {
      let status = this.state.statusSystem;
      status.ok = true;
      this.setState({ statusSystem: status });
      this.setLoginState();

      // Obs!
      this.setState({ triggerRender: !this.state.triggerRender });
    };

    //  Obs! Ikke sikkert om du har akkurat denne metoden..
    HasSystemStatusFalse = () => (
      <div style={{ marginLeft: '40px' }}>
        <div style={{ margin: '40px' }}>
          <table>
            <tbody>
              <tr>
                <th>
                  {' '}
                  <img
                    src={brann}
                    style={{ width: '40px', height: '40px' }}
                    alt=''
                  />
                </th>
                <th style={{ width: '20px' }} />
                <th>
                  <h2>Melding fra Brannforebygging</h2>
                </th>
              </tr>
              <tr>
                <td />
                <td />
                <td style={{ fontSize: '20px' }}>
                  {moment(this.state.statusSystem.endretDato).format(
                    'DD.MM.YYYY'
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p style={{ fontSize: '22px', color: 'darkRed', maxWidth: '800px' }}>
            {this.state.statusSystem.tekst}
          </p>
          <br />
          Les mer på{' '}
          <a href='https://komtek.zendesk.com/hc/no/' target='blank'>
            Norkart Kundesenter
          </a>
        </div>

        <p style={{ margin: '40px' }}>
          {userPermissions.norkartAdmin && (
            <span>
              <button onClick={this.fortsett} className='btn btn-success'>
                Fortsett for NorkartBruker
              </button>
              <br />
              <br />
              <span style={{ marginLeft: '10px' }}>
                Endret av: {this.state.statusSystem.endretAv}
              </span>
            </span>
          )}
        </p>
      </div>
    );

    setLoginState() {
      this.LOGIN_STATE = (() => {
        // NOTE! Be *very* careful with the order of things below!!
        if (this.state.error) {
          return loginStates.HAS_ERROR;
        }
        if (!this.context.isAuthenticated) {
          return loginStates.NOT_LOGGED_IN;
        }
        // Obs! Ny
        if (!this.context.isAuthorized) {
          return loginStates.NOT_AUTHORIZED;
        }
        if (this.state.brukContext) {
          return loginStates.SELECT_CONTEXT;
        }
        if (!userPermissions) {
          return loginStates.IS_LOADING_USERDATA;
        }
        if (this.state.statusSystem) {
          if (this.state.statusSystem.ok === false) {
            return loginStates.SYSTEMSTATE_FALSE;
          }
        }
        if (userPermissions) {
          if (this.LOGIN_STATES == 'INITIAL') {
          }
          if (userPermissions.ok) {
            if (!userPermissions.norkartAdmin && this.state.organisasjonValgt) {
              return loginStates.IS_READY;
            }
            if (
              !userPermissions.norkartAdmin &&
              !this.state.organisasjonValgt
            ) {
              return loginStates.IS_READY;
            }
            if (userPermissions.norkartAdmin && !this.state.organisasjonValgt) {
              return loginStates.SELECT_ORGANISATION;
            }
            if (userPermissions.norkartAdmin && this.state.organisasjonValgt) {
              return loginStates.IS_READY;
            }
          } else {
            if (userPermissions.melding) {
              return loginStates.FAILED_LOADING_USERDATA;
            } else {
              return loginStates.LOGIN_NOT_CONNECTED_TO_USERDATA;
            }
          }
        }
      })();
      /*   debug('LoginX state', this.LOGIN_STATE);
      debug('LoginX userPermissions', userPermissions); */
      return this.LOGIN_STATE;
    }

    render() {
      switch (this.LOGIN_STATE) {
        // Obs! Ny: bruker nå LogOut-component
        case loginStates.HAS_ERROR:
          return <LogOut melding={this.state.error} />;
        case loginStates.SYSTEMSTATE_FALSE:
          return this.HasSystemStatusFalse();
        // Obs! Ny: bruker nå LogOut-component
        case loginStates.FAILED_LOADING_USERDATA:
          return (
            <LogOut
              extra={'Henting av bruker data feiler.'}
              melding={'Vennligst kontakt administrator for å få logget inn.'}
            />
          );
        case loginStates.IS_LOADING_USERDATA:
          return <LoadingScreen />;
        case loginStates.IS_READY:
          return (
            <Component
              userPermissions={userPermissions}
              children={this.props.children}
              //Merkelig, men fungerer!  :-)
              trigger={this.state.triggerRender}
            />
          );
        case loginStates.SELECT_ORGANISATION:
          return (
            <Organisasjoner
              onOrganisasjonValgt={this.onOrganisasjonValgt}
              organisasjoner={userPermissions.organisasjoner}
            />
          );
        case loginStates.SELECT_CONTEXT:
          return (
            <Context
              liste={this.state.context}
              onContextValgt={this.onContextValgt}
            />
          );
        case loginStates.LOGIN_NOT_CONNECTED_TO_USERDATA:
          return (
            <BrukernavnKobler brukere={userPermissions.personerIkkeAktivert} />
          );
        // Obs! Ny: NOT_AUTHORIZED hadde vi ikke fra før.. Bruker LogOut-component
        case loginStates.NOT_AUTHORIZED:
          return (
            <LogOut
              melding={'Brukeren er ikke autorisert.'}
              extra={'Vennligst kontakt administrator for å få logget inn.'}
            />
          );
        case loginStates.NOT_LOGGED_IN:
        default:
          return <LoginScreen />;
      }
    }
  }

  return AuthenticatedComponent;
}

export default requiresAuth;
