import React from 'react';
import { getPersoner, getNorkartPersoner } from 'api/personApi';
import {
  contextInnstillingerPerson,
  getOrganisasjonData,
} from 'context/contextInnstillinger.js';
import { getOrganisasjon } from 'api/organisasjonApi';
import { getKommunerForOrganisasjon } from 'api/kommuneApi';
import {
  getOrganisasjonKategorier,
  getOrganisasjonDokumentKontroll,
} from 'api/organisasjonApi';
import { getAvvikAdmLukkArsak } from 'api/oppslagsdataApi.js';
import { getIldstedOppslagdataNyEndre } from 'api/ildstedApi.js';
import { getRoyklopOppslagdataNyEndre } from 'api/royklopApi.js';
import _ from 'lodash';
import { debugFactory, promisifyApiCall } from 'utils';
const debug = debugFactory('nk:context CCC Oppslag');

let contextBrukere = { init: false, brukere: [] };
let contextOrgKommuner = { init: false, kommuner: [] };
let contextAvvikLukkArsak = { init: false, arsak: [] };
let contextKategorier = { init: false };
let contextIldsted = { init: false };
let contextRoyklop = { init: false };
let contextRoyklopKombi = [];
let contextIldstedKombi = [];

export const dox = () => {
  console.log('XXX HEPP');
};

export const getContextBrukere = (_callback) => {
  if (!contextBrukere.init) {
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      getOrganisasjonData((org) => {
        if (org.data.kategoriNavn === 'Demo') {
          getContextBrukereAlternativ(_callback);
          return;
        }
      });
    }

    promisifyApiCall(getPersoner)().then((data) => {
      contextBrukere.brukere = data;
      //Get pålogget brukerId
      let plId = 0;
      let pl = _.filter(data, (item) => item.paaLogget === true);
      if (pl && pl.length) {
        plId = pl[0].id;
      }

      contextBrukere.paalogget = plId;
      contextBrukere.harSMK = false;

      //Get sidemann
      //Sjekker først om noen har satt som 'Sidemann'
      let harKanskjeSMK = false;
      for (let i = 0; i < contextBrukere.brukere.length; i++) {
        let b = contextBrukere.brukere[i];
        if (b.sidemannkontroll) {
          harKanskjeSMK = true;
          break;
        }
      }

      //Det er ingen vits å sjekke dersom ingen har blitt satt som 'Sidemann'
      //Slik sparer vi mange unødvendige call
      if (harKanskjeSMK) {
        promisifyApiCall(getOrganisasjonDokumentKontroll)().then((data) => {
          contextBrukere.harSMK = data;
        });
        _callback(contextBrukere);
      } else {
        _callback(contextBrukere);
      }
    });
  } else {
    _callback(contextBrukere);
  }
};

export const getContextBrukereAlternativ = (_callback) => {
  promisifyApiCall(getNorkartPersoner)().then((data) => {
    contextBrukere.brukere = data;
    //Get pålogget brukerId
    let plId = 0;
    let pl = _.filter(data, (item) => item.paaLogget === true);
    if (pl && pl.length) {
      plId = pl[0].id;
    }
    contextBrukere.paalogget = plId;

    //Get sidemann
    contextBrukere.harSMK = false;
    getOrganisasjonData((org) => {
      if (org.data.dokumentkontroll) {
        let smk = _.filter(data, (item) => item.sidemannkontroll);
        if (smk && smk.length) {
          contextBrukere.harSMK = true;
        }
      }
    });

    contextBrukere.init = true;
    _callback(contextBrukere);
  });
};

export const getContextOrgKommuner = (_callback) => {
  if (!contextOrgKommuner.init) {
    promisifyApiCall(getKommunerForOrganisasjon)().then((data) => {
      contextOrgKommuner.kommuner = data;
      contextOrgKommuner.init = true;
      _callback(contextOrgKommuner);
    });
  } else {
    _callback(contextOrgKommuner);
  }
};

export const getContextAvvikLukkArsaker = (_callback) => {
  if (!contextAvvikLukkArsak.init) {
    promisifyApiCall(getAvvikAdmLukkArsak)().then((data) => {
      let a = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].aktiv) {
            a.push(data[i]);
          }
        }
      }
      contextAvvikLukkArsak.arsak = a;
      contextAvvikLukkArsak.init = true;
      _callback(contextAvvikLukkArsak);
    });
  } else {
    _callback(contextAvvikLukkArsak);
  }
};

export const getContextAvvikLukkArsak = () => {
  if (!contextAvvikLukkArsak.init) {
    promisifyApiCall(getAvvikAdmLukkArsak)().then((data) => {
      let a = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].aktiv) {
            a.push(data[i]);
          }
        }
      }
      contextAvvikLukkArsak.arsak = a;
      contextAvvikLukkArsak.init = true;
    });
    debug('CCCC', contextAvvikLukkArsak);
  }
};

export const getContextKategorier = () => {
  if (contextInnstillingerPerson._currentValue.norkartAdmin) {
    if (!contextKategorier.init) {
      promisifyApiCall(getOrganisasjonKategorier)().then((data) => {
        contextKategorier.kategorier = data;
        contextKategorier.init = true;
      });
    }
  }
};

export const getContextIldstedOppslag = (_callback) => {
  if (contextIldsted.init) {
    _callback(contextIldsted.ildstedTypeData);
  } else {
    promisifyApiCall(getIldstedOppslagdataNyEndre)().then((data) => {
      let d = data;
      d.produsentListe.unshift({ id: 0, tekst: '' });
      d.typeListe.unshift({ id: 0, tekst: '' });
      d.plasseringListe.unshift({ id: 0, tekst: '' });
      d.modellListe.unshift({ id: 0, tekst: '', tekst2: '', fremmedNokkel: 0 });
      contextIldsted.ildstedTypeData = d;
      contextIldsted.init = true;
      _callback(d);
    });
  }
};

export const getContextRoyklopOppslag = (_callback) => {
  if (contextRoyklop.init) {
    debug('FFF fantes');
    _callback(contextRoyklop.ildstedTypeData);
  } else {
    promisifyApiCall(getRoyklopOppslagdataNyEndre)().then((data) => {
      let d = data;
      d.produsentListe.unshift({ id: 0, tekst: '' });
      d.typeListe.unshift({ id: 0, tekst: '' });
      d.sotmengdeListe.unshift({ id: 0, tekst: '' });
      d.sotuttakListe.unshift({ id: 0, tekst: '' });
      d.plasseringListe.unshift({ id: 0, tekst: '' });
      d.dimensjonListe.unshift({ id: 0, tekst: '' });
      d.adkomstListe.unshift({ id: 0, tekst: '' });
      contextRoyklop.ildstedTypeData = d;
      contextRoyklop.init = true;
      debug('FFF hentes');
      _callback(d);
    });
  }
};

export const getContextcontextRoyklopKombi = (_callback) => {
  if (contextRoyklopKombi.length) {
    _callback(contextRoyklopKombi);
  } else {
    //Kombi
    promisifyApiCall(getRoyklopOppslagdataNyEndre)().then((data) => {
      let kombi = [];
      let modeller = data.modellListe;
      for (let z = 0; z < modeller.length; z++) {
        let mo = modeller[z];
        let pro = _.find(data.produsentListe, { id: mo.fremmedNokkel });
        if (pro.aktiv) {
          let doc = '';
          if (mo.tekst2) {
            doc = mo.tekst2;
          }
          let obj = {
            modell: mo.tekst,
            modellId: mo.id,
            produsent: pro.tekst,
            produsentId: pro.id,
            kombi: mo.tekst + '-' + pro.tekst,
            dokumentasjon: doc,
          };
          kombi.push(obj);
        }
      }

      kombi = _.sortBy(kombi, (o) => o.produsent);
      contextRoyklopKombi = kombi;
      _callback(kombi);
    });
  }
};

export const harSMK = (_callback) => {
  let r = false;
  if (contextBrukere) {
    r = contextBrukere.harSMK;
  }
  _callback(r);
};

export const getContextcontextIldstedKombi = (_callback) => {
  if (contextIldstedKombi.length) {
    _callback(contextIldstedKombi);
  } else {
    //Kombi
    promisifyApiCall(getIldstedOppslagdataNyEndre)().then((data) => {
      let kombi = [];
      let modeller = data.modellListe;
      for (let z = 0; z < modeller.length; z++) {
        let mo = modeller[z];
        let pro = _.find(data.produsentListe, { id: mo.fremmedNokkel });
        if (pro.aktiv) {
          let doc = '';
          if (mo.tekst2) {
            doc = mo.tekst2;
          }
          let obj = {
            modell: mo.tekst,
            modellId: mo.id,
            produsent: pro.tekst,
            produsentId: pro.id,
            kombi: mo.tekst + '-' + pro.tekst,
            dokumentasjon: doc,
          };
          kombi.push(obj);
        }
      }
      kombi = _.sortBy(kombi, (o) => o.produsent);
      contextIldstedKombi = kombi;
      _callback(kombi);
    });
  }
};

//Hva som exporteres
export const contextOppslagBrukere = React.createContext(contextBrukere);
export const contextOppslagKommuner = React.createContext(contextOrgKommuner);
export const contextOppslagKategorier = React.createContext(contextKategorier);
export const contextOppslagAvvikLukkArsak = React.createContext(
  contextAvvikLukkArsak
);

//export const contextOppslagIldsted = React.createContext(contextIldsted);
