import callApi from './api';

export function getSokLagrede(callback) {
    callApi('GET', '/soklagret/person', null, callback);
};

export function getSokLagredeOrganisasjon(callback) {
    callApi('GET', '/soklagret/organisasjon', null, callback);
};

export function createSokLagret(sokLagretDto, callback) {    
    callApi('POST', '/SokLagret/', sokLagretDto, callback);
};

export function deleteSokLagret(id, callback) {    
    callApi('DELETE', '/SokLagret/' + id, null, callback);
};

export function setStandardSokFilter(id, callback) {    
    callApi('PUT', '/sokLagret/sokefilterstandard/' + id, null, callback);
};
