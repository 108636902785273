import React, { Component } from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';

//import { getOrganisasjonArkivInfo } from 'api/organisasjonApi';
import { getOrgArkivInfo } from 'context/contextInnstillinger.js';
import {
  contextInnstillingerPerson,
  getOrgSvarUt,
  contextInnstillingerSvarut,
  getOrgRisiko
} from 'context/contextInnstillinger.js';
// Utils
import { debugFactory, objGetDeep } from 'utils';

const debug = debugFactory('nk:innstillinger:index');

let pathname = null;

class Index extends Component {
  //export default (props) => {

  constructor(props) {
    super(props);
    this.state = {
      initiert: false,
      arkivIkkeAktiv: false,
      svarut: false,
      svarutInit: false,
      org: null,
    };
    debug('props person', { props });
    pathname = objGetDeep(props, 'location.pathname');
  }

  componentDidMount() {
    //... vi starter med organisasjon
    getOrgRisiko(this.gotData1);
  };

  gotData1= (orgRisiko) => {
    this.setState({ risiko: orgRisiko });
    //this.setState({ org: org });
    //... vi fortsetter med SvarUt
    getOrgSvarUt(this.gotData2);
  };

  gotData2= (res) => {
    this.setState({ svarut: res.data });
      //... vi avslutter med Arkiv
    getOrgArkivInfo(this.gotData3);
  }

  gotData3= (res) => {
    if (res) {
      let arkivIkkeAktiv = res.data.arkivIkkeAktiv === 1;
     
      this.setState({ arkivIkkeAktiv: arkivIkkeAktiv });

      //Fått alt, da set ini
      this.setState({ svarutInit: true, initiert: true });
    }
  };

  render() {
    if (!this.state.initiert) {
      return <div>Vent..</div>;
    }

    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-md-8 col-lg-6'>
            <LinkList title='Innstillinger' columns={1}>
              <LinkListItem
                title='Oppslagsdata'
                path={`${pathname}/Oppslagsdata`}
              />

              <LinkListItem title='Kart' path={`${pathname}/Kart`} />

              {(contextInnstillingerPerson._currentValue.norkartAdmin ||
                contextInnstillingerPerson._currentValue.organisasjonAdmin ||
                contextInnstillingerPerson._currentValue.arkivAdmin) && (
                <>
                  {/* Vis kun dersom arkiv skal brukes (Lillehammer saken) */}
                  {!this.state.arkivIkkeAktiv && (
                    <LinkListItem title='Arkiv' path={`${pathname}/SakArkiv`} />
                  )}
                </>
              )}
              {(contextInnstillingerPerson._currentValue.norkartAdmin ||
                (contextInnstillingerPerson._currentValue.organisasjonAdmin &&
                  this.state.risiko)) && (
                <>
                  <LinkListItem
                    title='Risikovurdering'
                    path={`${pathname}/Risikovurdering`}
                  />
                </>
              )}
              {(contextInnstillingerPerson._currentValue.norkartAdmin ||
                contextInnstillingerPerson._currentValue.organisasjonAdmin) && (
                <>
                  <LinkListItem
                    title='Maloppsett'
                    path={`${pathname}/Maloppsett`}
                  />

                  {/*  <LinkListItem title='Gebyr' path={`${pathname}/Gebyr`} /> */}

                  <LinkListItem title='Brukere' path={`${pathname}/Brukere`} />
                  <LinkListItem
                    title='Organisasjon'
                    path={`${pathname}/Organisasjon`}
                  />
                </>
              )}

              {contextInnstillingerSvarut._currentValue.data && (
                <LinkListItem
                  title='SvarUt logg'
                  path={`${pathname}/SvarUtLogg`}
                />
              )}
            </LinkList>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;