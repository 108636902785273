import React, { useState, useEffect, useRef } from 'react';
import Child1 from './TestChilds/Child1';
import Child2 from './TestChilds/Child2';
import Child3 from './TestChilds/Child3';

function TestParentChilds() {
  const [currentVerdi, setCurrentVerdi] = useState('Abc');
  const [parentX, setParentX] = useState('');
  const child3Ref = useRef();

  useEffect(() => {
    //litt som componentDidMount
    console.log('XXXX useEffect Parent', currentVerdi);
  });

  const endreVerdi = (v) => {
    setCurrentVerdi(v);
  };

  const trigger3 = () => {
    child3Ref.current.childFunction1();
  };

  return (
    <div
      style={{
        margin: '4px',
        padding: '10px',
        border: '2px solid black',
        width: '300px',
      }}>

      <h3>Parent</h3>
      <label>{currentVerdi}</label>
      <br />
      <label>{parentX}</label>
      <br />
      <button onClick={() => endreVerdi('Fra Parent DEF')}>
        Endre verdi til DEF
      </button>
      <br />
      
      <button onClick={() => child3Ref.current.childFunction1()}>
        Trigger metode 1 i Child3 
      </button>{' '}
      <br />
      <button onClick={() => child3Ref.current.childFunction2()}>
        Trigger metode 2 i Child3 
      </button>{' '}
      <br /> <br />
     
      <Child1
        verdi={currentVerdi}
        endreVerdi={endreVerdi}
        trigger3={trigger3}></Child1>
      <br />
      <Child2 verdi={currentVerdi} endreVerdi={endreVerdi}></Child2>
      <br />
      <Child3 verdi={currentVerdi} ref={child3Ref}></Child3>
    </div>
  );
}

export default TestParentChilds;
