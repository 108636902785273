import React from 'react';
import { brukBreadcrumb } from 'components/Breadcrumbs.jsx';
import EnhetSituasjon from './EnhetSituasjon.jsx';
//import { getOrganisasjonRisikovurdering } from 'api/organisasjonApi';
import { getOrgRisiko } from 'context/contextInnstillinger.js';
import { getSituasjon } from 'context/contextGjennomforing.js';
import { debugFactory  } from 'utils';

const debug = debugFactory('nk:gjennomforing:enhet:index');


const Enhet = class extends React.Component {
  state = {
    form: null,
    situasjon: null,
    risiko: false
  };

  componentDidMount() {
    
    getSituasjon( 
      this.props.params.enhetId,
      this.props.params.arbeidslisteId,
      this.gotSituasjon);

    this.ammendBreadCrumbs();

    getOrgRisiko((res) => {
      this.setState({ risiko: res.data });
    });
    
  }

  gotSituasjon = (situasjon) => {
    this.setState({ situasjon });
  };

  componentDidUpdate(prevProps) {
    this.ammendBreadCrumbs();
  }

  ammendBreadCrumbs = () => {

    //============= REACT CONTEXT BREADCRUMBS  ====================
    brukBreadcrumb('Arbeidsliste', 'gjennomforing_arbeidsliste_navn');
    brukBreadcrumb('Enhet', 'gjennomforing_arbeidsliste_adresse');
  };

  closeForm = () => {
    this.setState({ form: null });
  };
  fetchSituasjonPaaNy = () => {
    this.setState({ situasjon: null });
    getSituasjon( 
      this.props.params.enhetId,
      this.props.params.arbeidslisteId,
      this.gotSituasjon);
  };
  refreshCache = () => {
//    this.props.clearCache();
  };
  render() {
    if (!this.state.situasjon) {
      return <div className='loader' />;
    } 
    return (
      <div>
        <EnhetSituasjon
          fetchSituasjonPaaNy={this.fetchSituasjonPaaNy}
          refreshCache={this.refreshCache}
          situasjon={this.state.situasjon}
          arbListeId={this.props.params.arbeidslisteId}
          enhetId={this.props.params.enhetId}
          adresse={this.state.situasjon.adresse !== null ?  this.state.situasjon.adresse : this.props.location.state.adresse}
          eiendom={this.state.situasjon.eiendom }
          reellBruksenhet={this.state.situasjon.reellBruksenhet }
          matrikkelenhetId={this.state.situasjon.matrikkelenhetId }
          bruksenhetIdMa={this.state.situasjon.bruksenhetIdMa }
          fagbruksenhetId={this.state.situasjon.id }  //Pfff
          risiko={this.state.risiko}
          planlagtDato={this.state.situasjon.planlagtDato}
          base_path={
            '/gjennomforing/' +
            this.props.params.arbeidslisteId +
            '/' +
            this.props.params.arbeidslisteNavn
          }
          modal={false}
        />

        <p />
      </div>
    );
  }
};

export default Enhet;
