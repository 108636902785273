import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';

class ZoomControl extends Component {

  zoomPluss = () => {
    if (this.props.kart) {
      this.props.kart.setZoom(this.props.kart.getZoom() + 1);
    }
  };

  zoomMinus = () => {
    if (this.props.kart) {
      this.props.kart.setZoom(this.props.kart.getZoom() - 1);
    }
  };

  render() {
    let position = 'topright';
    // eslint-disable-next-line no-lone-blocks
    {if (this.props.left === true) {
      position = 'topleft';
    }};
    return (
      <div>
        {/*Zoom info     */}
        {this.props.showZoom && (
        <Control position="bottomleft">
            <div>{'zoom: ' + this.props.zoom}</div>
        </Control>
        )}
        {/* zoom pluss / min */}  
        <Control position={position}>
          <div>
            <button type="button" className={'btnLag topmargin4'} title='zoom +' onClick={this.zoomPluss}>
              <NKIcon icon="pluss" color="nk-black" />
            </button>
            <br />
            <button type="button" className={'btnLag'} title='zoom -'  onClick={this.zoomMinus}>
              <NKIcon icon="minus" color="nk-black" />
            </button>
          </div>
        </Control>
      </div>
    );
  }
}

export default ZoomControl;
