import React from 'react';
import ReactTable from '@norkart/react-table';
import { getAlleKommunerForOrg, updateKommune } from 'api/kommuneApi';
import EndreKommuner from './EndreKommuner.jsx';
import NyKommune from './NyKommune.jsx';
import ContextMenu from 'components/ContextMenu';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:viskommuner');

const modalDialogs = {
  EndreKommuneDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EndreKommuner
      isOpen={isOpen}
      tittel='Endre kommune'
      onClose={onClose}
      oppdater={owner.oppdater}
      kommune={dialogData.kommune}
    />
  ),
  NyKommuneDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NyKommune
      isOpen={isOpen}
      tittel='Ny kommune'
      onClose={onClose}
      oppdater={owner.oppdater}
    />
  ),
};



class VisKommuner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kommune: [],
      selectedKommune: null,
      orgId: null,
      orgNavn: ''
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    let orgId = this.props.params.orgId;
    this.setState({ orgId });
    let orgNavn = this.props.params.orgNavn;
    this.setState({ orgNavn });
    this.getAlleKommunerForOrg();
  }

  getAlleKommunerForOrg() {
    getAlleKommunerForOrg( this.props.params.orgId, (err, data) => {
      if (err) {
        alert(err.respons);
      }
      if (data) {
        console.log(data);
        this.setState({ kommune: data });
      }
    });
  }

  onAktivEndret=(e, data) => {
    let endretAktiv = data;
    endretAktiv.aktiv = !data.aktiv;
    updateKommune(data.id, endretAktiv, this.getAlleKommunerForOrg);
  }

  onMenyKlikk = (row) => {
    this.setState({ selectedKommune: row });
    this.dialogs.setOpenDialog('EndreKommuneDialog', {
      kommune: row,
    });
  }

  onOpprettKommune= () => {
    this.dialogs.setOpenDialog('NyKommuneDialog');
  }

  oppdater = (shouldUpdate) => {
    if (shouldUpdate) {
      this.getAlleKommunerForOrg();
    }
    this.dialogs.closeDialog();
  }

  getColumns = () => {
    return [
      {
        id: 'kommuneId',
        name: 'Kommunenr',
        sortParams: 'kommuneId',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'kommuneNavn',
        name: 'Kommunenavn',
        sortParams: 'kommuneNavn',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'replikKundeId',
        name: 'ReplikKundeId',
        sortParams: 'replikKundeId',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'ktfMeldingstypeId',
        name: 'KtfMeldingstypeId',
        sortParams: 'ktfMeldingstypeId',
        isSorted: false,
        sortable: false,
      },

      {
        id: 'royklopMelding',
        name: 'Røykløpmelding',
        sortParams: 'royklopMelding',
        isSorted: false,
        sortable: false,
        formatter: function(data) {
          if (data.royklopMelding) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        },
      },

      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        isSorted: false,
        sortable: false,
        formatter: function(data) {
          return (
            <input
              type='checkbox'
              checked={data.aktiv}
              onChange={(e) => this.onAktivEndret(e, data)}
            />
          );
        }.bind(this),
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              <ContextMenu.Item onClick={this.onMenyKlikk.bind(this, item)}>
                Endre kommune
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  render() {
   
    return (
      <div>
        <h2>Kommuner i organisasjon {this.state.orgNavn}</h2>

        <div className='btn-toolbar'>
          <button
            style={{ margin: '5px' }}
            className='btn btn-default'
            onClick={this.onOpprettKommune}>
            Ny kommune
          </button>
        </div>

        <ReactTable
          items={this.state.kommune}
          idProperty='id'
          showIndex={false}
          filterable={true}
          columns={this.getColumns()}
          selectableRows={false}
          selectAllLabel='Alle'
          onRowClick={null}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default VisKommuner;
