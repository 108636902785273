import React, { Component } from 'react';
import bruksenhet_ikon from 'images/48/hjem.png';
import royk_ikon from 'images/48/nk_075-royklop-A-48px.png';
import ildsted_ikon from 'images/48/nk_076-ildsted-A-48px.png';
import {
  sendTilEierFraOppf2,
  sendTilSidemannFraOppf2,
  sendTilSaksbehandlerFraOppf,
} from 'api/innbyggerDialogApi.js';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
import ReactTable from '@norkart/react-table';
import NKIcon from 'nka-icons';
import 'modules/felles/avvikAnmerkninger/AvvikAnmerkninger.css';
import enhetApi from 'api/enhetApi';
import { debugFactory } from 'utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/nb';
import _ from 'underscore';

import { getContextBrukere } from 'context/contextOppslag.js';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import DeltPipe from 'images/deltpipe.png';
const debug = debugFactory('nk:oppfolging:sendtileier');

//let publiserAvvikListe = [];
let blank = {
  id: 0,
  navn: '-',
};

const modalDialogs = {
  SidemannDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={owner.onCloseVelgSidemann}
        tittel={'Send til ...'}
        melding={'Du må velge sideperson'}
        onOk={owner.onCloseVelgSidemann}
      />
    );
  },
};

class VerifiserEierkontakter2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      valgteAvvik: this.props.valgteAvvik,
      valgteAnm: this.props.valgteAnm,
      avvikMedEierkontakter: [],
      merketAnm: [],
      bruksenhetKontakt: [],
      publiserer: false,
      eierInfoMangler: false,
      eierkontaktMangler: false,
      laster: false,
      adresse: '',
      eier: '',
      telefon: '',
      startDate: moment(),
      datoer: null,
      tittel: 'Send til eier. Verifiser eierkontakter og avvik/anmerkninger.',
      knapp: 'Send til eier',
      sendTilEier: true,
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    this.hentEierInfo();
    this.setData();
  }

  setData = () => {
    if (this.props.sendtil === 'side' || this.props.sendtil === 'saks') {
      this.setState({ sendTilEier: false });
      getContextBrukere(
        function(brukere) {
          console.log(brukere);

          let sidemenn = _.filter(
            brukere.brukere,
            (item) => item.sidemannkontroll === true && item.paaLogget === false
          );
          sidemenn.unshift(blank);
          console.log(sidemenn);
          this.setState({
            personer: sidemenn,
            valgtSidemannId: sidemenn[0].id,
          });
        }.bind(this)
      );
    }

    if (this.props.sendtil === 'side') {
      this.setState({
        tittel: 'Send til sideperson. Verifiser avvik/anmerkninger',
        knapp: 'Send til sideperson',
      });
    }
    if (this.props.sendtil === 'saks') {
      this.setState({
        tittel: 'Send til saksbehandler. Verifiser avvik/anmerkninger',
        knapp: 'Send til saksbehandler',
      });
    }
  };

  handleDatePickerChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleDatoChange = (val) => {
    this.setState({ startDate: moment(val.target.value) });
  };

  hentEierInfo = () => {
    if (this.state.avvikerCount === 0 && this.state.anmerkningerCount === 0) {
      this.SendIngenAvvikAnm();
    } else {
      if (
        this.state.valgteAvvik !== null &&
        this.state.valgteAvvik.length > 0
      ) {
        this.setState({ laster: true });
        // Finne eiekontakterforbruksenheter
        let bruksenhetids = [];
        bruksenhetids.push(this.props.brId.toString());
        let avListe = this.state.valgteAvvik;
        console.log(avListe);
        avListe.forEach((element) => {
          if (element.bruksenhetIdsTilknyttet !== null) {
            if (element.bruksenhetIdsTilknyttet.includes(',')) {
              let temp = element.bruksenhetIdsTilknyttet.split(',');
              temp.forEach((tmp) => {
                if (!bruksenhetids.includes(tmp)) {
                  bruksenhetids.push(tmp);
                }
              });
            } else {
              if (!bruksenhetids.includes(element.bruksenhetIdsTilknyttet)) {
                if (element.bruksenhetIdsTilknyttet !== '') {
                  bruksenhetids.push(element.bruksenhetIdsTilknyttet);
                }
              }
            }
          }
        });
      // publiserAvvikListe = this.state.valgteAvvik;

        // Ny
        getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakter);
      } else {
        let bruksenhetids = [];
        bruksenhetids.push(this.props.brId.toString());
        getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakterBareAnm);
      }
      let merkStep1 = this.state.valgteAnm;
      this.setState({ merketAnm: merkStep1 });
    }
    let av = this.props.valgteAvvik;
    let an = this.props.valgteAnm;
    let list = [];
    av.forEach((element) => {
      list.push(element.tiltakTypeId);
    });
    an.forEach((element) => {
      list.push(element.tiltakTypeId);
    });
    let par = {
      Id: this.props.brId,
      List: list,
    };
    console.log(par);
    enhetApi.getTiltaksHistorikk(par, this.gotTiltaksHistorikk);
  };

  gotTiltaksHistorikk = (err, res) => {
    console.log(res);
    if (res != null && res.length > 0) {
      this.setState({ startDate: moment(res[0].dato) });
    }
    this.setState({ datoer: this.formater(res) });
  };

  formater = (list) => {
    if (list == null || list.length === 0) {
      return list;
    } else {
      let result = [];
      list.forEach((element) => {
        var t = '';
        var dato = new Date(element.dato);
        var dag = dato.getDate().toString();
        var mnd = (dato.getMonth() + 1).toString();
        var aar = dato.getFullYear().toString();
        if (dag.length === 1) {
          dag = '0' + dag;
        }
        if (mnd.length === 1) {
          mnd = '0' + mnd;
        }
        if (dag === '01' && mnd === '01' && aar === '1') {
          t = '-';
        } else {
          t = dag + '.' + mnd + '.' + aar;
        }
        var nytekst = t + ' - ' + element.tekst;
        var par = {
          dato: element.dato,
          tekst: nytekst,
        };
        result.push(par);
      });
      return result;
    }
  };

  gotEierkontakterBareAnm = (err, eierData) => {
    debug('verif kun anm', eierData);
    this.setState({ bruksenhetKontakt: eierData });
  };

  onCloseVelgSidemann = () => {
    this.dialogs.closeDialog();
  };

  publiser = () => {
    if (this.props.sendtil === 'side' && this.state.valgtSidemannId === 0) {
      this.dialogs.setOpenDialog('SidemannDialog');
    } else {
      this.setState({ publiserer: true });
      let dato = moment(this.state.startDate)
        .add(1, 'days')
        .toDate();
      let brk = this.state.bruksenhetKontakt;
      if (this.props.sendtil === 'side' || this.props.sendtil === 'saks') {
        if (this.state.bruksenhetKontakt.length > 1) {
          brk = _.filter(this.state.bruksenhetKontakt, (item) => item.bruksenhetFagId === this.props.brId);
        } 
      }
      let param = {
        bruksenhetId: this.props.brId,
        arbeidslisteId: null,
        bruksenhetKontakt: brk,
        avvikListe: this.state.valgteAvvik,
        anmerkningListe: this.state.valgteAnm,
        besokdato: dato,
        sidemannId: this.state.valgtSidemannId === 0 ? null : this.state.valgtSidemannId,
      };

      console.log('publiser', param);
      if (this.props.sendtil === 'side') {
        sendTilSidemannFraOppf2(param, this.sendTilEierDone);
      } else if (this.props.sendtil === 'saks') {
        sendTilSaksbehandlerFraOppf(param, this.sendTilEierDone);
      } else {
        sendTilEierFraOppf2(param, this.sendTilEierDone);
      }
    }
  };

  sendTilEierDone = (err, result) => {
    this.setState({ publiserer: false });
    this.props.onFerdig();
  };

  ingenValgtClose = () => {
    this.setState({ publiserer: false });
    this.props.onFerdig();
  };

  checkEierkontakter = (eierkontakter) => {
    let eierk = eierkontakter;
    var result = true;
    var ingenEierkontakt = true;
    if (eierk !== null && eierk.length > 0) {
      eierk.forEach((ek) => {
        let kontakter = ek.kontakt;
        if (kontakter !== null && kontakter.length > 0) {
          ingenEierkontakt = false;
        }
        kontakter.forEach((ko) => {
          if (ko.telefon !== null && ko.ugyldigTelefonNr === false) {
            result = false;
          }
          if (ko.telefon == null || ko.ugyldigTelefonNr === true) {
            ko.smsUtelat = true;
          }
        });
      });
      if (ingenEierkontakt) {
        this.setState({ eierkontaktMangler: true });
        this.setState({ eierInfoMangler: false });
      } else {
        this.setState({ eierInfoMangler: result });
      }
      return eierk;
    } else {
      this.setState({ eierkontaktMangler: true });
      this.setState({ eierInfoMangler: false });
    }
  };

  visadresserForDelt = (item) => {
    let str = item.bruksenhetIdsTilknyttet;
    let array = str.split(',');
    enhetApi.postBruksenhetAdresser2(array, this.onAdresserHentet);
  };
  onAdresserHentet = (err, data) => {};

  gotEierkontakter = (err, eierData) => {
    debug('verif avvik', eierData);
    this.setState({ bruksenhetKontakt: eierData });
    //let eierkontakter = eierData;
    let eierkontakter = this.checkEierkontakter(eierData);

    let avListe = this.state.valgteAvvik;
    avListe.forEach((av) => {
      av.eierkontakter = [];
      eierkontakter.forEach((ek) => {
        if (
          this.props.brId.toString().includes(ek.bruksenhetFagId.toString())
        ) {
          av.eierkontakter.push(ek);
        } else {
          if (av.bruksenhetIdsTilknyttet !== null) {
            if (av.bruksenhetIdsTilknyttet.includes(ek.bruksenhetFagId)) {
              av.eierkontakter.push(ek);
            }
          }
        }
      });
    });
    this.setState({ avvikMedEierkontakter: avListe, laster: false });
  };

  avvikRowSelected = (rows) => {
    this.setState({ valgteAvvik: rows });
  };
  anmRowSelected = (rows) => {
    this.setState({ valgteAnm: rows });
  };

  test = (ek) => {
    return <div />;
  };

  handleSidemannChange = (val) => {
    this.setState({ valgtSidemannId: val.target.value });
  };

  render() {
    if (this.state.laster) {
      return <div className="loader" />;
    }

    if (this.state.publiserer) {
      return <div className="loader" />;
    }

    var side2AvvikColums = this.getSide2AvvikKolloner();
    var side2AnmColums = this.getSide2AnmKolloner();
    return (
      <div>
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="close"
                type="button"
                onClick={this.props.onClose}
              >
                <span>x</span>
                <span className="sr-only">Lukk</span>
              </button>
              <h4 className="modal-title">{this.state.tittel}</h4>
            </div>
            <div className="modal-body" style={{ padding: '0 20px' }}>
              <div>
                <h3>{this.props.adresse}</h3>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <label>Velg besøksdato</label>
                  </div>
                </div>
                <table>
                  <tr>
                    <td style={{ width: '170px' }}>Utførte tiltaksdatoer: </td>
                    <td style={{ width: '200px' }}>
                      <select
                        className="h30"
                        id="type"
                        onChange={this.handleDatoChange}
                        defaultValue={
                          this.state.datoer != null
                            ? this.state.datoer[0]
                            : null
                        }
                      >
                        {_.map(this.state.datoer, function(o, i) {
                          return (
                            <option key={i} value={o.dato}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    <td style={{ width: '170px' }}>Velg dato manuelt: </td>
                    <td style={{ width: '130px' }}>
                      <DatePicker
                        className="width120Heigth32"
                        selected={this.state.startDate}
                        onChange={this.handleDatePickerChange}
                        isClearable={true}
                        showYearDropdown
                      />
                    </td>
                  </tr>
                </table>
                <br />
                {this.props.sendtil === 'side' ? (
                  <div>
                    <table>
                      <tr>
                        <td style={{ width: '170px' }}>
                          <b>Velg sideperson:</b>{' '}
                        </td>
                        <td style={{ width: '220px' }}>
                          <select
                            className="h30 width220"
                            value={this.state.valgtSidemannId}
                            id="type"
                            onChange={this.handleSidemannChange}
                          >
                            {_.map(this.state.personer, function(o, i) {
                              return (
                                <option key={i} value={o.id}>
                                  {o.navn}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <div></div>
                )}

                <br />

                <div className="row">
                  {this.props.sendtil === 'side' ? (
                    <div>
                      <span style={{ marginLeft: '14px' }}>
                        Dokumentet blir sendt til sideperson for kontroll.
                        Sideperson vil kunne endre dokumentet og legge inn
                        kommentarer.
                      </span>
                    </div>
                  ) : null}
                  {this.props.sendtil === 'saks' ? (
                    <div>
                      <span style={{ marginLeft: '14px' }}>
                        Dokumentet blir sendt til saksbehandler.
                      </span>
                    </div>
                  ) : null}
                </div>

                <hr />
                {this.state.valgteAvvik.length === 0 &&
                this.state.valgteAnm.length === 0 ? (
                  <div>Ingen avvik eller anmerkninger valgt</div>
                ) : (
                  <div>
                    {this.state.eierInfoMangler && this.state.sendTilEier ? (
                      <div>
                        <table>
                          <tbody>
                            <tr>
                              <td valign="top">
                                <span>
                                  <NKIcon
                                    icon="advarsel_roed"
                                    className="pull-left"
                                    size="1"
                                    color="nk-black"
                                  />
                                </span>
                              </td>
                              <td style={{ paddingLeft: '10px' }}>
                                <span>
                                  Et eller flere avvik har ikke treff på
                                  telefonnummer. Avvikene blir likevel publisert
                                  til Min eiendom. Ta kontakt med eierkontakten
                                  manuelt for å informere om de publiserte
                                  avvikene.
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        {this.state.eierkontaktMangler &&
                        this.state.sendTilEier ? (
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <span>
                                      <NKIcon
                                        icon="advarsel_roed"
                                        className="pull-left"
                                        size="1"
                                        color="nk-black"
                                      />
                                    </span>
                                  </td>
                                  <td style={{ paddingLeft: '10px' }}>
                                    <span>
                                      Finner ingen eierkontakt for bruksenheten.
                                      Avviket publiseres til min eiendom, men må
                                      håndteres manuelt videre.
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    )}
                    <br />

                    <h4>Avvik</h4>
                    <ReactTable
                      showIndex={false}
                      items={this.state.avvikMedEierkontakter}
                      columns={side2AvvikColums}
                      selectableRows={false}
                      onRowClick={null}
                      toggleAll={false}
                    />
                    <hr />
                    <div>
                      <h4>Anmerkninger</h4>
                      <ReactTable
                        showIndex={false}
                        items={this.state.merketAnm}
                        columns={side2AnmColums}
                        selectableRows={false}
                        onRowClick={null}
                        toggleAll={false}
                      />
                    </div>
                  </div>
                )}
                <div />
              </div>
            </div>

            <div className="modal-footer">
              {this.state.valgteAvvik.length === 0 &&
              this.state.valgteAnm.length === 0 ? (
                <div>
                  <button
                    onClick={this.ingenValgtClose}
                    className="btn btn-success pull-right btnBottomSend"
                  >
                    {this.state.knapp}
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    onClick={this.publiser}
                    className="btn btn-success pull-right btnBottomSend"
                  >
                    {this.state.knapp}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  getSide2AvvikKolloner = () => {
    return [
      {
        id: 'objektType',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var objectIcon = bruksenhet_ikon;
          switch (item.objektType) {
            case 'Røykløp':
              objectIcon = royk_ikon;
              break;
            case 'Ildsted':
              objectIcon = ildsted_ikon;
              break;
            default:
              objectIcon = bruksenhet_ikon;
          }
          return (
            <img
              height="24"
              width="24"
              src={objectIcon}
              className="app-preloader__img pull-left mr8"
              alt=""
            />
          );
        },
      },
      {
        id: 'delt',
        name: '',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
         if (item.bruksenhetIdsTilknyttet !== null) {
          return (
            <img
              height="24"
              width="24"
              src={DeltPipe}
              className="app-preloader__img pull-left mr8"
              alt=""
              title="Delt"
            />
          );       
         }
        },
      },
      {
        id: 'avvikType',
        name: 'Avvikstype',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Gjelder adresse',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let teller = 1;
          return (
            <table>
              <tbody>
                {item.eierkontakter.map(function(ek) {
                  teller = teller + 1;
                  let key = 'k' + teller.toString();
                  return (
                    <tr key={key}>
                      <td key={'td' + key}>{ek.bruksenhetAdresse}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        },
      },
      {
        id: 'eierkontakt',
        name: 'Eierkontakt',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let teller = 1;
          let t = 1;
          return (
            <table>
              <tbody>
                {item.eierkontakter.map(function(ek) {
                  teller = teller + 1;
                  let key = 'k' + teller.toString();
                  return ek.kontakt.map(function(ko) {
                    t = t + 1;
                    key = 'k' + teller.toString() + t.toString();
                    return (
                      <tr key={key}>
                        <td key={'td' + key}>
                          {ko.navn !== null ? (
                            ko.navn
                          ) : (
                            <div className="red">Mangler eierkontakt</div>
                          )}
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          );
        },
      },
      {
        id: 'telefon',
        name: 'Telefon',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let teller = 1;
          let t = 1;
          return (
            <table>
              <tbody>
                {item.eierkontakter.map(function(ek) {
                  teller = teller + 1;
                  let key = 'k' + teller.toString();
                  return ek.kontakt.map(function(ko) {
                    t = t + 1;
                    key = 'k' + teller.toString() + t.toString();
                    return (
                      <tr key={key}>
                        <td key={'td' + key}>
                          {ko.telefon !== null ? (
                            ko.ugyldigTelefonNr ? (
                              <div className="red">Ugyldig nummer</div>
                            ) : (
                              ko.telefon
                            )
                          ) : (
                            <div className="red">Mangler tlf</div>
                          )}
                        </td>
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          );
        },
      },
    ];
  };

  getSide2AnmKolloner = () => {
    return [
      {
        id: 'objektType',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var objectIcon = bruksenhet_ikon;
          switch (item.objektType) {
            case 'Røykløp':
              objectIcon = royk_ikon;
              break;
            case 'Ildsted':
              objectIcon = ildsted_ikon;
              break;
            default:
              objectIcon = bruksenhet_ikon;
          }
          return (
            <img
              height="24"
              width="24"
              src={objectIcon}
              className="app-preloader__img pull-left mr8"
              alt=""
            />
          );
        },
      },
      {
        id: 'delt',
        name: '',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
         if (item.bruksenhetIdsTilknyttet !== null) {
          return (
            <img
              height="24"
              width="24"
              src={DeltPipe}
              className="app-preloader__img pull-left mr8"
              alt=""
              title="Delt"
            />
          );       
         }
        },
      },
      {
        id: 'kategori',
        name: 'Kategori',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.kategori == null || item.kategori === '') {
            return 'Ikke oppgitt';
          } else {
            return item.kategori;
          }
        },
      },
    ];
  };
}
export default VerifiserEierkontakter2;
