import React from 'react';
import DeltPipe from 'images/deltpipe.png';
import withToggle from 'lib/enhancers/withToggle';
import CONFIG from 'lib/CONFIG';
// Utils
import _ from 'lodash';

const DeltPipeButton = ({ toggle }) => (
  <span onClick={toggle}>
    &nbsp;
    <img height='22' width='22' src={DeltPipe} alt='Delt' />
  </span>
);

const ObjektAdresse = withToggle(({ item, toggledOn, toggle }) => {
  const isShared = !_.isEmpty(item.bruksenheterSomDelerRoyklop);
/* 
  const label =
    (CONFIG.debugMode ? `${item.tableId}: ` : '') +
    (isShared ? 'Delt røykløp' : item.adresse); */

  const label = isShared ? 'Delt røykløp' : item.adresse;

  return (
    <React.Fragment>
      <span>
        {label}
        {isShared && <DeltPipeButton toggle={toggle} />}
      </span>
      {toggledOn &&
        (item.bruksenheterSomDelerRoyklop || []).map((bruksenhet, idx) => (
          <div key={idx} className='cf pl2'>
            {bruksenhet.adresse}
          </div>
        ))}
    </React.Fragment>
  );
});

export default ObjektAdresse;
