import { makeGetTypeKey } from 'redux/utils';
const getTypeKey = makeGetTypeKey('innstillinger');

export const ORG_KOMMUNER_FETCHED = getTypeKey('ORG_KOMMUNER_FETCHED');



// IKKE I BRUK LENGER
export const USER_PERSONALIA_FETCHED = getTypeKey('USER_PERSONALIA_FETCHED');
export const USER_PERMISSIONS_FETCHED = getTypeKey('USER_PERMISSIONS_FETCHED');
export const ORGANISASJON_FETCHED = getTypeKey('ORGANISASJON_FETCHED');
export const ORGANISASJONER_FETCHED = getTypeKey('ORGANISASJONER_FETCHED');
export const ORG_KARTSENTER_LOKASJON_FETCHED = getTypeKey('ORG_KARTSENTER_LOKASJON_FETCHED'); 
export const ORG_KATEGORIER_FETCHED = getTypeKey('ORG_KATEGORIER_FETCHED');
export const UPDATE_USER_PERSONALIA = getTypeKey('UPDATE_USER_PERSONALIA');
export const UPDATE_ORGANISASJON = getTypeKey('UPDATE_ORGANISASJON');
export const UPDATE_ORG_KARTSENTER_LOKASJON = getTypeKey('UPDATE_ORG_KARTSENTER_LOKASJON');
export const SAKSBEHANDLERE_FETCHED = getTypeKey('SAKSBEHANDLERE_FETCHED');
export const ARKIVINNSTILLINGER_FETCHED = getTypeKey('ARKIVINNSTILLINGER_FETCHED');