import React from 'react';
import ReactTable from '@norkart/react-table';
import { updatePersonAdmin, getPersonerAdmin, getNorkartBrukere } from 'api/personApi';
import ContextMenu from 'components/ContextMenu';
import EndreBrukerDetaljer from './EndreBrukerdetaljer.jsx';
import NyBruker from './NyBruker.jsx';
import DialogStore from 'lib/DialogStore';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js'; 

const modalDialogs = {
  EndreBrukerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EndreBrukerDetaljer
      isOpen={isOpen}
      onClose={onClose}
      oppdater={owner.oppdater}
      person={dialogData.person} 
      visNorkart={owner.props.norkartBrukere}     
      personer={dialogData.personer}
    />
  ),
  NyBrukerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NyBruker
      isOpen={isOpen}
      tittel="Ny bruker"
      onClose={onClose}
      oppdater={owner.oppdater}
    />
  )
  };
class EndreBrukere extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedRow: null
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.getPersoner = this.getPersoner.bind(this);
    this.getNorkartBrukere = this.getNorkartBrukere.bind(this);
    this.oppdater = this.oppdater.bind(this);
    this.onOpprettBruker = this.onOpprettBruker.bind(this);
  }

  componentDidMount() {       
    this.props.norkartBrukere ? this.getNorkartBrukere() : this.getPersoner();      
  }
  getPersoner() {
    getPersonerAdmin((err, data) => this.setState({ personer: data }));
  }

  getNorkartBrukere(){
    getNorkartBrukere((err, data) => this.setState({ personer: data }));
  }

  onAktivChanged(e, item) {      
    let oppdatertItem = item;
    oppdatertItem.aktiv = !item.aktiv;

    if (this.props.norkartBrukere) {
      updatePersonAdmin(item.id, oppdatertItem, this.getNorkartBrukere);
    } else {
      updatePersonAdmin(item.id, oppdatertItem, this.getPersoner);  
    }  
  }


  onRowClick= (row) => {    
    this.setState({ selectedRow: row });
    this.dialogs.setOpenDialog('EndreBrukerDialog', { 
      person: row, personer: this.state.personer });
  }

  onOpprettBruker() {
    this.dialogs.setOpenDialog('NyBrukerDialog');
  };

  oppdater(shouldUpdate){ 
    if (shouldUpdate ){
      this.props.norkartBrukere ? this.getNorkartBrukere() : this.getPersoner();
    }
    this.dialogs.closeDialog();
  }

  getColumns() {
    return [
      {
        id: 'navn',
        name: 'Navn',
        sortParams: 'navn',
        isSorted: true,
        sortDirection: 'asc'
      },     
      {
        id: 'telefon',
        name: 'Telefon',
        sortParams: 'telefon',
        isSorted: false
      },
      {
        id: 'mobil',
        name: 'Mobil',
        sortParams: 'mobil',
        isSorted: false
      },
      {
        id: 'epost',
        name: 'Epost',
        sortParams: 'epost',
        isSorted: false
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        formatter: function(item) {
          return (
            <input                
              type="checkbox"
              checked={item.aktiv}
              // onChange={(e) => this.onAktivChanged(e, item)}
            />
          );
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {          
          return (                        
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item
                onClick={this.onRowClick.bind(this, item)}>
                Endre bruker
              </ContextMenu.Item>
            </ContextMenu.Button>

          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

onRowFormat(item){  
  return  !item.aktiv && item.adKobling ? { className: 'norkartlysgronn', tooltip: 'Denne brukeren venter på å bli aktivert.' } : null; 
}

  render() {
    if (!contextInnstillingerPerson._currentValue.norkartAdmin && !contextInnstillingerPerson._currentValue.organisasjonAdmin) {  
      return <div>Du har ikke rettigheter til å endre brukerdetaljer</div>;
    }

    return this.state.personer  ? (
      <div>

        <h2>Brukere</h2>
        {contextInnstillingerPerson._currentValue.norkartAdmin && (
        <div className="btn-toolbar">
          <button
            style={{ margin: '5px' }}
            className='btn btn-default'
            onClick={this.onOpprettBruker}
            >
            Ny bruker
          </button>
        </div>
        )}
      
        <ReactTable
          items={this.state.personer}
          idProperty="id"
          showIndex={false}
          filterable={true}
          columns={this.getColumns()}
          selectableRows={false}
          selectAllLabel="Alle"
          rowFormat={this.onRowFormat}
          onRowClick={this.onRowClick}
        />

        {this.dialogs.renderOpenDialog()}
      </div>
    ) : (
      <div>Laster...</div>
    );
  }
}
export default EndreBrukere;
