/* eslint-disable eqeqeq */
import React from 'react';
import ReactModal from 'react-modal';
import { compose, withRouter } from 'lib/enhancers';
import 'components/Dialogs/NKFullModal.css';
import ContextMenu from 'components/ContextMenu';
import ModalContainer from 'components/Dialogs/ModalContainer';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { getBesokMerknad, saveBesokMerknad } from 'api/enhetApi.js';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import ArbeidslisteFritekst from 'modules/felles/ArbeidslisteFritekst';
import FlyttTiltak from 'modules/felles/FlyttTiltak';
import ReactTable from '@norkart/react-table';
import '@norkart/react-table/dist/react-table.css';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import DeltePiperButton from 'modules/felles/DeltePiperButton.jsx';
import { huskBreadcrumb } from 'components/Breadcrumbs.jsx';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import green from 'images/Annet/legendaBlokGreen.png';
import orange from 'images/Annet/legendaBlokOrange.png';


// Utils
import { debugFactory, getDerivedChanges } from 'utils';
const debug = debugFactory('nk:gjennomforing:enhetertable');

class EnheterTable extends React.Component {
  state = {
    enheterErValgt: false,
    enheter: this.props.enheter,
    norkartAdminBruker: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {

    return getDerivedChanges(['enheter'], nextProps, prevState, debug);
  }
  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    //For å unngå en warning:
    //Warning: react-modal: App element is not defined.
    ReactModal.setAppElement('body');
    this.setState({
      norkartAdminBruker: contextInnstillingerPerson._currentValue.norkartAdmin,
    });
  }

  refresh() {
    this.props.refresh();
  }

  /*   listeOppdatert = (err, arbListe) => {
    this.setState({ enheter: arbListe });
  }; */

  onClickInfo = (item) => {
    this.setState({ valgtEnhet: item });
    getBesokMerknad(item.id, this.gotBesokMerknad);
  };

  gotBesokMerknad = (err, merknad) => {
    this.dialogs.setOpenDialog('InfoDialog', { bruksEnhetMerknad: merknad.tekst });
    this.refresh();
  };

  lagreBesokMerknad = (merknad) => {
    saveBesokMerknad(
      this.state.valgtEnhet.id,
      merknad,
      this.merknadLagret
    );
  };

  merknadLagret = () => {
    this.dialogs.closeDialog();
    this.refresh();
  };

  flyttTiltak = (row) => {
    if (row.oppgaver === 'IkkeStartet') {
      this.dialogs.setOpenDialog('FlyttDialog', {
        enhet: row,
        arbeidslisteId: this.props.arbeidslisteId,
      });
    } else {
      this.dialogs.setOpenDialog('OkDialog', {
        melding:
          'En eller flere planlagte tiltak er påstartet på denne bruksenheten ' +
          ' og den kan derfor ikke flyttes/slettes fra arbeidslisten.',
      });
    }
  };

  onRowClick = (item) => {
    this.props.router.push({
      pathname:
        // this.props.base_path + '/' + this.props.arbeidslistenavn + '/' + item.id
        this.props.base_path +
        '/' +
        this.props.params.arbeidslisteNavn +
        '/' +
        item.id,

      // state: {
      //   adresse: item.adresse,
      //   eiendom: item.eiendom,
      //   reellBruksenhet: item.FromMatrikkel,
      //   matrikkelenhetId: item.matrikkelenhetId,
      //   bruksenhetIdMa: item.bruksenhetIdMa
      // }
    });
    //lagre selected listeenhet
    //this.props.setSelectedArbeidslisteEnhet(this.props.arbeidslisteId, item.id);
   

    //============= REACT CONTEXT BREADCRUMBS ====================
    huskBreadcrumb(
      'gjennomforing_arbeidsliste_navn',
      this.props.arbeidslisteNavn
    );
    huskBreadcrumb('gjennomforing_arbeidsliste_adresse', item.adresse);
  };

  onFlyttTiltakCompleted = (arbeidslisteIdTil) => {
    this.dialogs.closeDialog();
    this.refresh();
  };

  render() {
    var columns = this.getColumns(this);
    return (
      <div>
        <ReactTable
          items={this.props.enheter}
          idProperty='id'
          showIndex={false}
          filterable={false}
          columns={columns}
          selectableRows={false}
          onRowSelectionChange={this.noeErValgt}
          onRowClick={this.onRowClick}
          rowFormat={this.rowFormat}
        />

        {this.dialogs.renderOpenDialog()}

        <div><span><img height= '30px' src={orange} alt="" /></span><span style={{ marginLeft: '8px' }}>Oppgavene er gjennomført</span></div>
        <div style={{ marginTop: '4px' }}><span><img height= '30px' src={green} alt="" /></span><span style={{ marginLeft: '8px' }}>Rapporten har blitt publisert</span></div>
      </div>
    );
  }

  rowFormat = (item) => {
    var index1 = item.tiltakBrukenhetStatus.indexOf('/');
    var utf =
      // eslint-disable-next-line radix
      parseInt(item.tiltakBrukenhetStatus.substring(0, index1), 10) +
      item.antallLopPaaAlisteBesokt;
    var til =
      // eslint-disable-next-line radix
      parseInt(
        item.tiltakBrukenhetStatus.substring(
          index1 + 1,
          item.tiltakBrukenhetStatus.length
        ),
        10
      ) + item.antallLopPaaAliste;

    if (utf == til && utf > 0 && item.antallBruksenhetSendEier > 0) {
      return { style: { backgroundColor: '#dcedc0' } };
    } else {
      if (utf == til) {
        return { style: { backgroundColor: '#f5d9a6' } };
      }
    }
  };

  getColumns = (that) => {
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'adresse',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return item.bruksenhetIdDelerLop &&
            item.bruksenhetIdDelerLop !== null ? (
            <div>
              <DeltePiperButton fraOppfolging={false} item={item} />
            </div>
          ) : (
            item.adresse
          );
        },
      },
      {
        id: 'eiendom',
        name: 'Eiendom',
        sortParams: 'eiendom',
        isSorted: false,
        sortable: true,
      },

      {
        id: 'rekkefolgeNummer',
        name: 'Nr.',
        sortParams: 'rekkefolgeNummer',
        isSorted: false,
        sortable: that.state.norkartAdminBruker, // !!!!!!!!!!!!!!!!
      },

      /*    (that.state.norkartAdminBruker) && {
        id: 'rekkefolgeNummer',
        name: 'Nr.',
        sortParams: 'rekkefolgeNummer',
        isSorted: false,
        sortable: that.state.norkartAdminBruker,  // !!!!!!!!!!!!!!!!
      }, */

      {
        id: 'datotidvennlig',
        name: 'Dato/tid.',
        sortParams: 'datotid',
        filterable: false,
        isSorted: true,
        sortable: true,
        formatter: function(item) {
          if (item.datotidvennlig === 'Invalid date') {
            return '';
          } else {
            return item.datotidvennlig;
          }
        },
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.tiltakBruksenhet === null && item.tiltakRoyklop === null) {
            return '';
          }
          if (item.tiltakRoyklop === null) {
            return item.tiltakBruksenhet;
          }
          if (item.tiltakBruksenhet === null) {
            return item.tiltakRoyklop;
          } else {
            return item.tiltakBruksenhet + ',' + item.tiltakRoyklop;
          }
        },
      },
      {
        id: 'status',
        name: 'Gjennomført ',
        sortParams: 'status',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var index1 = item.tiltakBrukenhetStatus.indexOf('/');
          //var index2 = item.tiltakRoyklopStatus.indexOf('/');
          var utf =
            // eslint-disable-next-line radix
            parseInt(item.tiltakBrukenhetStatus.substring(0, index1), 10) +
            item.antallLopPaaAlisteBesokt;
          //parseInt(item.tiltakRoyklopStatus.substring(0, index2), 10);
          var til =
            // eslint-disable-next-line radix
            parseInt(
              item.tiltakBrukenhetStatus.substring(
                index1 + 1,
                item.tiltakBrukenhetStatus.length
              ),
              10
            ) + item.antallLopPaaAliste;

          if (utf === 0) {
            item.oppgaver = 'IkkeStartet';
          } else {
            if (utf > 0 && utf === til) {
              item.oppgaver = 'Ferdig';
            } else {
              item.oppgaver = 'UnderArbeid';
            }
          }

          return utf + '/' + til;
        },
      },
      /*    {
        id: 'planlagtDato',
        name: 'Besøksdato',
        sortParams: 'planlagtDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.planlagtDato === null) {
            return '-';
          }
          var dato = new Date(item.planlagtDato);
          var dag = dato.getDate().toString();
          var mnd = (dato.getMonth() + 1).toString();
          var aar = dato.getFullYear().toString();
          if (dag.length === 1) {
            dag = '0' + dag;
          }
          if (mnd.length === 1) {
            mnd = '0' + mnd;
          }
          return dag + '.' + mnd + '.' + aar;
        }
      },
      {
        id: 'planlagtTid',
        name: 'Besøkstid',
        sortParams: 'planlagtTid',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            (item.besokFra === null ? '' : item.besokFra) +
            '-' +
            (item.besokTil === null ? '' : item.besokTil)
          );
        }
      }, */
      {
        id: 'varselstatus',
        name: 'Varselstatus',
        sortParams: 'varselstatus',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'merknad',
        name: 'Melding',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.merknadBesok ? (
            <NKIcon
              className='pull-left'
              icon='kommentar'
              color='nk-black'
              size='0.8'
            />
          ) : null;
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={this.onClickInfo.bind(this, item)}>
                Melding til feier...
              </ContextMenu.Item>
              {/*   <ContextMenu.Item onClick={() => this.flyttTiltak(item)}>
                Flytt til arbeidsliste...
              </ContextMenu.Item> */}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };
}
const modalDialogs = {
  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <ArbeidslisteFritekst
        onClose={onClose}
        merknad={dialogData.bruksEnhetMerknad}
        lagreBesokMerknad={owner.lagreBesokMerknad}
      />
    </NKModal>
  ),
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Flytt enhet'
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  FlyttDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      isOpen={isOpen}
      onClose={onClose}
      buttonText='Flytt'
      onSubmit={() => {
        owner.refs.flyttTiltak.submit(
          dialogData.enhet.id,
          dialogData.arbeidslisteId,
          owner.onFlyttTiltakCompleted
        );
      }}
      tittel={'Flytt fra arbeidsliste ' + owner.props.arbeidslistenavn}>
      <FlyttTiltak
        ref='flyttTiltak'
        erVarslet={dialogData.enhet.planlagtDato !== null ? true : false}
        enhetValgtForFlytt={dialogData.enhet}
        arbeidslisteId={dialogData.arbeidslisteId}
      />
    </ModalContainer>
  ),
};
export default compose(withRouter)(EnheterTable);
