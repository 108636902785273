import React from 'react';
import PropTypes from 'prop-types';
import 'react-toggle-switch/dist/css/switch.min.css';
import Select from 'components/Select/NKReactSelect.jsx';
import { difference } from 'lodash';
import NKIcon from 'nka-icons';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import {
  getArbeidslisteBrukseneheterOgRoklop,
  updateArbeidsliste,
} from 'api/arbeidslisteApi';
import { getContextBrukere } from 'context/contextOppslag.js';


// Utils
import { debugFactory } from 'utils';

const debug = debugFactory('nk:planlegging:endrearbeidsliste');

class EndreArbeidsliste extends React.Component {
  static propTypes = {
    arbeidsliste: PropTypes.object.isRequired,
  };

  state = {
    selectedPersoner: [],
    navn: this.props.arbeidsliste.navn,
    aktiv: this.props.arbeidsliste.aktiv,
    id: this.props.arbeidsliste.id,
    varselVises: 'hide',
    disableCheckbox: true,
  };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    
    getContextBrukere(this.gotPersoner);

    getArbeidslisteBrukseneheterOgRoklop(
      this.props.arbeidsliste.id,
      this.gotArbeidsliste
    );
  }

  gotPersoner = (personer) => {
    this.availablePersoner = personer.brukere;
    this.origSelectedPersoner = this.availablePersoner.filter(
      (person) =>
        ~(this.props.arbeidsliste.ansvarligIds || []).indexOf(String(person.id))
    );

    this.setState({
      selectedPersoner: Array.from(this.origSelectedPersoner),
    });
  };

  gotArbeidsliste = (err, liste) => {
    let varselSendt = true;
    if (liste.tiltakTypeBruksenhetListe) {
      for (let i = 0; i < liste.tiltakTypeBruksenhetListe.length; i++) {
        let element = liste.tiltakTypeBruksenhetListe[i];
        if (element.varselSendt === false) {
          varselSendt = false;
          break;
        }
      }
    }
    if (liste.tiltakTypeRoyklopListe && varselSendt) {
      for (let i = 0; i < liste.tiltakTypeRoyklopListe.length; i++) {
        let element = liste.tiltakTypeRoyklopListe[i];
        if (element.varselSendt === false) {
          varselSendt = false;
          break;
        }
      }
    }
    if (varselSendt) {
      this.setState({ varselVises: 'hide', disableCheckbox: false });
    } else {
      this.setState({ varselVises: '', disableCheckbox: true });
    }

  };

  oppdaterArbeidsliste = () => {
    if (!this.state.selectedPersoner.length) {
      return;
    }
    const makeAnsvarligPerson = (person, props = {}) => ({
      navn: person.navn,
      personId: person.id,
      arbeidslisteId: this.props.arbeidsliste.id,
      ...props,
    });
    const ansvarligePersoner = this.origSelectedPersoner
      .concat(this.state.selectedPersoner)
      .reduce(
        (acc, person) => ({
          ...acc,
          [person.id]: makeAnsvarligPerson(person, {
            ny: !this.origSelectedPersoner.includes(person) ? true : false,
            slett: !this.state.selectedPersoner.includes(person) ? true : false,
          }),
        }),
        {}
      );
    const payload = {
      id: this.state.id,
      navn: this.state.navn,
      aktiv: this.state.aktiv,
      ansvarlige: Object.values(ansvarligePersoner),
    };

    updateArbeidsliste(this.state.id, payload, this.isOppdatert);
  };

  isOppdatert = (err, data) => {
    this.props.onClose();
  };

  handleToggleAktiv = () => {
    this.setState({
      aktiv: !this.state.aktiv,
    });
  };

  handleNameChange = (data) => {
    //skråstrek
    let s = data.target.value;
    if (s.includes('\\') || s.includes('/')) {
      // eslint-disable-next-line no-useless-escape
      s = s.replace(/[\/\\]/g, '');
      this.dialogs.setOpenDialog('SlashDialog');
    }
    this.setState({
      navn: s,
    });
  };

  handlePersonerSelected = (selectedPersoner) => {
    debug('handlePersonerSelected', { selectedPersoner });
    this.setState({ selectedPersoner });
  };

  handleAktivChange = (event) => {
    this.setState({ aktiv: event.target.checked });
  };

  render() {
    debug('render', { ...this });

    const selectablePersoner = difference(
      this.availablePersoner,
      this.state.selectedPersoner
    );

    //this.refs.nameInput.focus();

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Endre arbeidsliste</h4>
        </div>
        <div className='modal-body'>
          <div className='form'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group'>
                  <label>Navn</label>
                  <input
                    type='text'
                    className='form-control'
                    value={this.state.navn}
                    onChange={this.handleNameChange}
                    ref='nameInput'
                  />
                </div>
                <div className='form-group'>
                  <label>Velg ansvarlig</label>
                  <Select
                    name='Ansvarlige'
                    value={this.state.selectedPersoner}
                    options={selectablePersoner}
                    onChange={this.handlePersonerSelected}
                  />
                  {!this.state.selectedPersoner.length && (
                    <div className='alert alert-warning'>Må velges!</div>
                  )}
                </div>
                <div className={this.state.varselVises}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: '50px' }}>
                          <span>
                            <NKIcon icon='info' />
                          </span>
                        </td>
                        <td>
                          Alle enheter på arbeidslisten må varsles før listen
                          kan aktiveres.
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <br />
                  <br />
                </div>
                <div className={'form-group'}>
                  <input
                    type='checkbox'
                    disabled={this.state.disableCheckbox}
                    onClick={this.handleToggleAktiv}
                    onChange={this.handleToggleAktiv}
                    checked={this.state.aktiv}
                    style={{
                      verticalAlign: 'top',
                      marginTop: '4px',
                      fontSize: '16px',
                      marginLeft: '10px',
                      width: '28px',
                      height: '28px',
                    }}
                  />
                  <span
                    style={{
                      verticalAlign: 'bottom',
                      fontSize: '16px',
                      marginLeft: '10px',
                    }}>
                    Aktiv
                  </span>
                </div>
                <div className='pull-right'>
                  <input
                    type='button'
                    className='btn btn-primary'
                    value='OK'
                    onClick={this.oppdaterArbeidsliste}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default EndreArbeidsliste;

// Declarative stuff

const modalDialogs = {
  SlashDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Skråstrek'
      melding='Du kan ikke benytte skråstek i navnet. '
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
};
