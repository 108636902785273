import React from 'react';
import ContextMenu from 'components/ContextMenu';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NyEndreBrukerInnstilling from './NyEndreBrukerInnstilling.jsx';
import arkivApi from 'api/arkivApi';
import ReactTable from '@norkart/react-table';
import NKIcon from 'nka-icons';
import DialogStore from 'lib/DialogStore';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import {
  uuid
} from 'utils' ;

const modalDialogs = {
  NyEndreBrukerInnstilling: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" isOpen={isOpen} onClose={onClose}>
      <NyEndreBrukerInnstilling
        onClose={onClose}
        nyLagret={owner.nyLagret}
        type={dialogData.type}
        valgt={dialogData.valgt}
      />
    </NKModal>
  ),
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Slette bruker?'
        isOpen={isOpen}
        onClose={onClose}
        melding={'Er du sikker på at du ønsker å slette brukerinnstilling?'}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};

class BrukerInnstillinger extends React.Component {
  constructor(props) {
    super(props);
    this.gotInnstillinger = this.gotInnstillinger.bind(this);
    this.getColumns = this.getColumns.bind(this);
    this.endre = this.endre.bind(this);
    this.slett = this.slett.bind(this);
    this.nyLagret = this.nyLagret.bind(this);
    this.slettet = this.slettet.bind(this);
    this.hentData = this.hentData.bind(this);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.state = {
      innstillinger: [],
      valgt: null,
      isLoading: true
    };
    this.hentData();
  }

  hentData() {
    arkivApi.getAlleBrukerInnstillinger(uuid(), this.gotInnstillinger);
  }

  getColumns() {
    return [
      {
        id: 'Bruker',
        name: 'Bruker',
        sortParams: 'Bruker',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'AdministrativEnhetInit',
        name: 'AdministrativEnhetInit',
        sortParams: 'AdministrativEnhetInit',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'Journalenhet',
        name: 'Journalenhet',
        sortParams: 'Journalenhet',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'SaksbehandlerInit',
        name: 'SaksbehandlerInit',
        sortParams: 'SaksbehandlerInit',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  gotInnstillinger(err, innstillinger) {
      this.setState({ isLoading: false, innstillinger: innstillinger });
  }
  

  endre(item) {
    this.dialogs.setOpenDialog( 'NyEndreBrukerInnstilling', { type: 'endre', valgt: item });
  }

  slett(item) {
    this.dialogs.setOpenDialog('JaNeiDialog', {
      onJa: () => {
        arkivApi.slettBrukerInnstilling(uuid(), item.Bruker, this.slettet);
      },
    });
  }

  slettet() {
    arkivApi.getAlleBrukerInnstillinger(uuid(), this.gotInnstillinger);
  }

  nyLagret() {
    arkivApi.getAlleBrukerInnstillinger(uuid(), this.gotInnstillinger);
    this.dialogs.closeDialog();
  }

  render() {
    if (this.state.isLoading) {
      return <div className="loader" />;
    }
    var columns = this.getColumns();
    return (
      <div>
        <div className="form">
          <h2>Brukerinnstillinger</h2>

          <div className="row">
            <div className="col-sm-12">
              <div className="btn-toolbar">
                <button
                  style={{ margin: '5px' }}
                  className="btn btn-default button-arbliste-enhet"
                  onClick={() => this.dialogs.setOpenDialog( 'NyEndreBrukerInnstilling', 
                    { type: 'ny', valgt: null })}>
                  <span className="pull-left">
                    <NKIcon
                      className="pull-left"
                      icon="pluss"
                      color="nk-black"
                    />
                  </span>
                  <span className="pull-left margin-left-10 ">Ny</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <ReactTable
                items={this.state.innstillinger}
                idProperty="id"
                showIndex={false}
                filterable={false}
                columns={columns}
                selectableRows={false}
              />
            </div>
          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default BrukerInnstillinger;
