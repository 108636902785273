import React, { Component } from 'react';
import { debugFactory } from 'utils';
import moment from 'moment';
import   MarkdownInfo   from './MarkdownInfo';

const debug = debugFactory('nk:driftdialog');

/* "tittel": "string",
"tekst": "string",
"publisertDato": "2022-02-18T11:20:24.261Z",
"publisertAv": "string",
"historisk": true,
"historiskDato": "2022-02-18T11:20:24.261Z" */

class DriftDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { melding: null, ny: true };
  }

  componentDidMount() {
    debug('ZZZZ props', this.props);
    let melding;

    //New
    switch (this.props.modus) {
      case 'ny':
        melding = {
          tittel: '',
          tekst: '',
          publisertAv: '',
          publisertDato: moment(),
          historisk: false,
          historiskDato: null,
        };
        this.setState({ ny: true, melding });
        break;
      case 'kopier':
        melding = this.props.item;
        this.setState({ ny: true, melding });
        break;
      case 'endre':
        melding = this.props.item;
        this.setState({ ny: false, melding });
        break;
      default:
        break;
    }
  }

  historiskChange = () => {
    let melding = this.state.melding;
    let h = !melding.historisk;
    melding.historisk = h;
    if (h) {
      melding.historiskDato = moment();
    } else {
      melding.historiskDato = null;
    }

    this.setState({ melding });
  };

  render() {
    if (!this.state.melding) {
      return <div>Vent..</div>;
    }
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{this.props.tittel}</h4>
        </div>
        <div
          className='modal-body'
          style={{ marginLeft: '15px', marginRight: '15px' }}>
          <br />

          <div className='xradio-item'>
            <input
              type='radio'
              id='ritema'
              name='ritem'
              value='ropt1'
              checked={!this.state.melding.historisk}
              onChange={this.historiskChange}
            />
            <label for='ritema'>
              Ikke historisk. Teksten vises på startsiden.
            </label>
          </div>

          <br />
          <div className='yradio-item'>
            <input
              type='radio'
              id='ritemb'
              name='ritem'
              value='ropt2'
              checked={this.state.melding.historisk}
              onChange={this.historiskChange}
            />
            <label for='ritemb'>Historisk. Teksten vises ikke (lenger)</label>
          </div>

          <br />
          <br />
          <label>Tittel</label>
          <br />
          <input
            autoFocus
            placeholder='Tittel..'
            maxLength='250'
            value={this.state.melding.tittel}
            className='form-control'
            type='text'
            style={{ fontSize: '18px', color: 'darkred', width: '800px' }}
            onChange={(e) => {
              this.setState({
                melding: {
                  ...this.state.melding,
                  tittel: e.target.value,
                },
              });
            }}
          />
          <br />

          <label style={{ marginTop: '4px', fontSize: '16px' }}>Tekst:</label>
          <br />
          <textarea
            style={{
              padding: '6px',
              marginTop: '6px',
              resize: 'none',
              fontSize: '18px',
              color: 'darkred',
              width: '800px' 
            }}
            cols='90'
            rows='6'
            value={this.state.melding.tekst}
            placeholder='Driftsmelding..'
            onChange={(e) => {
              this.setState({
                melding: {
                  ...this.state.melding,
                  tekst: e.target.value,
                },
              });
            }}
          />
          <label style={{ marginTop: '4px', fontSize: '16px' }}>Merknad:</label>
          <br />
          <textarea
            style={{
              width: '800px',
              padding: '6px',
              marginTop: '6px',
              resize: 'none',
              fontSize: '16px',
              color: 'black',
            }}
            cols='90'
            rows='5'
            value={this.state.melding.merknad}
            placeholder='Bemerkning..'
            onChange={(e) => {
              this.setState({
                melding: {
                  ...this.state.melding,
                  merknad: e.target.value,
                },
              });
            }}
          />
          <br />
          <label>URL</label>
          <br />
          <input
            autoFocus
            placeholder='Url / Link..'
            maxLength='500'
            value={this.state.melding.url}
            className='form-control'
            type='text'
            style={{ fontSize: '18px', color: 'blue', width: '800px'  }}
            onChange={(e) => {
              this.setState({
                melding: {
                  ...this.state.melding,
                  url: e.target.value,
                },
              });
            }}
          />
          {/*   <br />
          <span style={{ marginLeft: '4px', fontStyle: 'italic',  color: 'blue' }}>Obs! En link til Norkart Kundesenter er alltid med.</span> */}
          <br />
          <MarkdownInfo></MarkdownInfo>
        </div>
        <div className='modal-footer'>
          {this.state.melding.tekst !== '' &&
            this.state.melding.tittel !== '' &&
            this.state.melding.url !== '' && (
              <div className='pull-right'>
                <button
                  className='btn btn-primary height48 '
                  onClick={this.props.onOK.bind(
                    this,
                    this.state.melding,
                    this.state.ny
                  )}>
                  OK
                </button>
              </div>
            )}
        </div>
      </div>
    );
  }
}
export default DriftDialog;
