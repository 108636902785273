import L from 'leaflet';
import { getRandomString } from 'utils';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:Kart:WmsUtils');

export function setWmsLayer(wmsGroup, wms, brukAlpha) {

  let wmsUrl = wms.groupurl + wms.style;

  let op = '';
  if (brukAlpha){
    let op = wms.alphablend;
    if (op > 0) {
      op = op / 100;
    } else {
      op = '';
    }
  } else {
    op = wms.opacity;
  }
 

  let wmsLayer = L.tileLayer.wms(wmsUrl, {
    layers: wms.layername,
    format: 'image/png',
    maxZoom: 20,
    transparent: true,
    opacity: op,
    tileSize: 1024,
    wmsTikker: getRandomString(20)
  });
  wmsLayer.setParams(
    {
      fake: Date.now()
    },
    false
  );
  wmsLayer.redraw();
  wmsLayer.addTo(wmsGroup);
  debug('PLO2', wmsLayer);
  return wmsGroup;
}
