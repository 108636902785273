import React from 'react';
import SearchkitFiktive from './SearchkitFagdata.jsx';
import * as _ from 'lodash';

class Fiktive extends React.Component {
  constructor() {
    super();        
  }
componentDidMount(){
  
}
  render() {
    return <SearchkitFiktive/>;
  }
}
export default Fiktive;
