import React from 'react';
import moment from 'moment';
import {
  getOrganisasjonMalinfo,
  getOrganisasjonGebyrkobling,
  getOrganisasjonLokasjon,
  getOrganisasjonNK,
  getOrganisasjon,
  getOrganisasjoner,
  updateOrganisasjonNk,
  updateOrganisasjon,
  createOrganisasjon,
  getOrganisasjonSvarUt,
  getOrganisasjonRisikovurdering,
  getOrganisasjonArkivInfo,
  /* getOrganisasjonDokumentKontroll */
} from 'api/organisasjonApi';
import { getKommunerForOrganisasjon } from 'api/kommuneApi.js';
import { debugFactory, promisifyApiCall } from 'utils';
import {} from 'api/organisasjonApi';

const debug = debugFactory('nk:CCC');

let contextTest = { test: 'Tarzan of the apes', teller: 0 };

let innloggetPerson = {
  norkartAdmin: false,
  norkartBruker: false,
  organisasjonAdmin: false,
  pilotBruker: false,
};
let viaStart ={ start: false };
let contextInnstillingerDatoInnlogging = { dato: null, usynk: false };
let organisasjonArkivInfo = { init: false };
let organisasjonSvarUt = { init: false };
//let organisasjonDokumentKontroll = { init: false };
let organisasjonRisiko = { init: false };
let organisasjonMalinfo = { init: false };
let organisasjonAvvikPerBruksenhet = { init: false };
let organisasjonGebyr = { init: false, gebyrkobling: false, kommuner: [] };
let organisasjonData = { init: false };
let organisasjonerData = { init: false };
let organisasjonKart = {
  init: false,
  latitude: 63.990556,
  longitude: 12.307778,
  zoomLevel: 5,
};
let kartContext = {
  leggtilobjekter: {},
  planlegging: {},
  gjennomforing: {},
  enhetersok: {},
};
let kartEgenskaperForGebyrKart = {
  enhetId: 0,
  arbeidslisteId: 0,
};

export const setViaStart = () => {
   viaStart.start = true;
};

//************************** DatoInnlogging ************************************************ */
export const setContextInnstillingerDatoInnlogging = () => {
  let d = moment(new Date()).format('DD.MM.YYYY');
  contextInnstillingerDatoInnlogging.dato = d;
  contextInnstillingerDatoInnlogging.usynk = false;
  //  console.log('%c XXXX ', 'font-size: 14px; background: black; color: white',  d );
};

export const checkContextInnstillingerDatoInnlogging = () => {
  let d = moment(new Date()).format('DD.MM.YYYY');
  contextInnstillingerDatoInnlogging.usynk =
    contextInnstillingerDatoInnlogging.dato !== d;
};

export const setContextInnstillingerDatoInnloggingTest = () => {
  let d = moment()
    .subtract(1, 'days')
    .format('DD.MM.YYYY');
  contextInnstillingerDatoInnlogging.dato = d;
  alert('TEST: DatoInnlogging sett til ' + d);
  console.log(
    '%c XXXX TEST ',
    'font-size: 14px; background: black; color: white',
    d
  );
};

export const contextDatoInnlogging = React.createContext(
  contextInnstillingerDatoInnlogging
);
//*********************** DatoInnlogging END *************************************************** */

export const isPersonNorkartAdmin = () => {
  if (!innloggetPerson) {
    return false;
  }
  if (!innloggetPerson.norkartAdmin) {
    return false;
  }
  if (innloggetPerson.norkartAdmin) {
    return true;
  }
};

//Denne henter veeeldig mange opplysninger om organisasjoner og skulle også vært utgangspunktet for..
// get.. svarUt,  ..getGebyr, ..getRisiko, ..getArkiv osv.
//men.. hva med vanlige brukere..?
export const getOrganisasjonData = (_callback) => { 
  if (!organisasjonData.init) {
    if (innloggetPerson.norkartAdmin) {
      let id = innloggetPerson.orgId;
      promisifyApiCall(getOrganisasjonNK)(id).then((data) => {
        organisasjonData.data = data;
        organisasjonData.init = true;
        _callback(organisasjonData);
      });
    } else {
      if (innloggetPerson.organisasjonAdmin) {
        promisifyApiCall(getOrganisasjon)().then((data) => {
          organisasjonData.data = data;
          organisasjonData.init = true;
        
          _callback(organisasjonData);
        });
      } else {
      
      }
    }
  } else {

    _callback(organisasjonData);
  }
};

export const getOrgSvarUt = (_callback) => {
  if (!organisasjonSvarUt.init) {
    promisifyApiCall(getOrganisasjonSvarUt)().then((data) => {
      organisasjonSvarUt.data = data;
      organisasjonSvarUt.init = true;
      return _callback(organisasjonSvarUt);
    });
  } else {
    return _callback(organisasjonSvarUt);
  }
};

export const getOrgRisiko = (_callback) => {
  if (!organisasjonRisiko.init) {
    promisifyApiCall(getOrganisasjonRisikovurdering)().then((data) => {
      organisasjonRisiko.data = data;
      organisasjonRisiko.init = true;
      return _callback(organisasjonRisiko);
    });
  } else {
    return _callback(organisasjonRisiko);
  }
};


export const getOrgArkivInfo = (_callback) => {
  //Se om det har blitt sammensatt før, else sett det sammen fra getOrganisasjonData
  if (!organisasjonArkivInfo.init) {

    promisifyApiCall(getOrganisasjonArkivInfo)().then((d) => {
      let data = {
        arkivAdresseIKlasseTittel: d.arkivAdresseIKlasseTittel,
        arkivBevaringstid: d.arkivBevaringstid,
        arkivForsendelsesmåte: d.arkivForsendelsesmåte,
        arkivFunksjonalitetArkivert: d.arkivFunksjonalitetArkivert,
        arkivIkkeAktiv: d.arkivIkkeAktiv,
        arkivIkkeArkiverMatrNr: d.arkivIkkeArkiverMatrNr,
        arkivJPTittel1: d.arkivJPTittel1,
        arkivJPTittel2: d.arkivJPTittel2,
        arkivJPTittel3: d.arkivJPTittel3,
        arkivKassasjonskode: d.arkivKassasjonskode,
        arkivKlasseTittel: d.arkivKlasseTittel,
        arkivSakTittel: d.arkivSakTittel,
        arkivSkjerming: d.arkivSkjerming,
        arkivTittelFormatJp: d.arkivTittelFormatJp,
        arkivTittelFormatSak: d.arkivTittelFormatSak,
      };
      organisasjonArkivInfo.data = data;
      organisasjonArkivInfo.init = true;
      return _callback(organisasjonArkivInfo);
    });
  } else {
    return _callback(organisasjonArkivInfo);
  }
};

export const oppdaterContextInnstillingerPersonFraMinSide = (data) => {
  innloggetPerson.epost = data.epost;
  innloggetPerson.mobil = data.mobil;
  innloggetPerson.navn = data.navn;
  innloggetPerson.telefon = data.telefon;
  innloggetPerson.visningKartStandard = data.visningKartStandard;
};

export const setContextInnstillingerPerson = (data) => {
  debug('SetContextInnstillingerPerson data ', data);
  innloggetPerson.id = data.id;
  innloggetPerson.epost = data.epost;
  innloggetPerson.mobil = data.mobil;
  innloggetPerson.navn = data.navn;
  innloggetPerson.telefon = data.telefon;
  innloggetPerson.orgId = data.orgId;
  innloggetPerson.orgNavn = data.orgNavn;
  innloggetPerson.visningKartStandard = data.visningKartStandard;
  innloggetPerson.sokFilterStandard = data.sokFilterStandard;
  innloggetPerson.norkartAdmin = data.norkartAdmin;
  innloggetPerson.norkartBruker = data.norkartBruker;
  innloggetPerson.organisasjonAdmin = data.organisasjonAdmin;
  innloggetPerson.pilotBruker = data.pilotBruker;
  try {
    innloggetPerson.arkivAdmin = data.arkivAdmin;
    innloggetPerson.sidemannkontroll = data.sidemannkontroll;
  } catch (err) {
    debug('Error i setContextInnstillingerPerson ', err);
  }
  debug('SetContextInnstillingerPerson innloggetPerson ', innloggetPerson);
};

export const oppdaterOrgNavn = (item) => {
  innloggetPerson.orgNavn = item.navn;
  innloggetPerson.orgId = item.id;
};

export const getInnloggetPerson = (_callback) => {
  _callback(innloggetPerson);
};

/* const msToTime = (duration) => {
  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return hours + ':' + minutes + ':' + seconds;
}; */

/* export const oppdaterExpire = (exp) => {
  let tid = exp * 1000 + 3600000 * 8;
  let tidString = msToTime(tid);
  innloggetPerson.exp = tidString;
  innloggetPerson.expMili = tid;
}; */

export const updateTelefonExport = (t) => {
  innloggetPerson.telefon = t;
  //evt updateInnstillingerPerson();
};

export const getContextInnstillingerMalinfo = () => {
  if (!organisasjonMalinfo.init) {
    getOrganisasjonMalinfo(gotOrganisasjonMalinfo);
  }
};

const makeModelStyle = (f) => {
  let ff = f.fontType;
  if (ff === 'Calibri') {
    ff = 'Verdana';
  }
  let fs = f.fontSize;
  let NORMALSTYLE =
    '<!-- -- BFNORMAL-START ----><div style="font-family: ' +
    ff +
    '; font-size: ' +
    fs +
    '; color: black; line-height: 1.5;"><p></p><!-- -- BFNORMAL-END ---->';

  return NORMALSTYLE;
};

export const getContextInnstillingerMalinfoNew = (_callback) => {
  if (!organisasjonMalinfo.init) {
    return promisifyApiCall(getOrganisasjonMalinfo)().then((data) => {
      organisasjonMalinfo.fontType = data.fontType;
      organisasjonMalinfo.fontSize = data.fontSize;
      organisasjonMalinfo.malDokumentPrMottaker = data.malDokumentPrMottaker;
      organisasjonMalinfo.malFormBokmal = data.malFormBokmal;
      organisasjonMalinfo.modelStyle = makeModelStyle(data);
      organisasjonMalinfo.init = true;

      console.log('Zorro insst 1', organisasjonMalinfo);
      _callback(organisasjonMalinfo);
    });
  } else {
    console.log('Zorro insst 2', organisasjonMalinfo);
    _callback(organisasjonMalinfo);
  }
};

const gotOrganisasjonMalinfo = (err, data) => {
  console.log('Zorro gotOrgMal', err);
  console.log('Zorro gotOrgMal', data);
  organisasjonMalinfo.fontType = data.fontType;
  organisasjonMalinfo.fontSize = data.fontSize;
  organisasjonMalinfo.malDokumentPrMottaker = data.malDokumentPrMottaker;
  organisasjonMalinfo.malFormBokmal = data.malFormBokmal;
  organisasjonMalinfo.init = true;
};

export const getContextGebyrInnstillinger = (_callback) => {
  if (!organisasjonGebyr.init) {
    return promisifyApiCall(getOrganisasjonGebyrkobling)().then((data) => {
      console.log(
        '%c GEBYRX tittel ',
        'font-size: 14px; background: black; color: white',
        data
      );
      organisasjonGebyr.gebyrkobling = data;
      return promisifyApiCall(getKommunerForOrganisasjon)().then((kommuner) => {
        organisasjonGebyr.kommuner = kommuner;
        organisasjonGebyr.init = true;
        //debug('testSide hentet for første gang',  organisasjonGebyr);
        _callback(organisasjonGebyr);
      });
    });
  } else {
    _callback(organisasjonGebyr);
  }
};

const makeBbox = (data) => {
  let lat1 = data.longitude - 0.0002;
  let lat2 = data.longitude + 0.0002;
  let lng1 = data.latitude - 0.0001;
  let lng2 = data.latitude + 0.0001;

  let bbox =
    '&BBOX=' +
    lat1.toString() +
    ',' +
    lng1.toString() +
    ',' +
    lat2.toString() +
    ',' +
    lng2.toString();

  return bbox;
};

//KART ******************************************************
export const getKartContext = (modul) => {
  switch (modul) {
    case 'pl':
      return kartContext.planlegging;
    case 'gj':
      return kartContext.gjennomforing;
    case 'leg':
      return kartContext.leggtilobjekter;
    case 'en':
      return kartContext.enhetersok;
    default:
      break;
  }
};

export const setKartContext = (
  modul,
  liste,
  layer,
  zoom,
  lastCenter,
  markerModus,
  tidsPunkt
) => {
  switch (modul) {
    case 'pl':
      kartContext.planlegging.wmsListe = liste;
      kartContext.planlegging.layer = layer;
      kartContext.planlegging.zoom = zoom;
      kartContext.planlegging.lastCenter = lastCenter;
      break;
    case 'leg':
      kartContext.leggtilobjekter.wmsListe = liste;
      kartContext.leggtilobjekter.layer = layer;
      kartContext.leggtilobjekter.zoom = zoom;
      kartContext.leggtilobjekter.lastCenter = lastCenter;
      break;
    case 'gj':
      kartContext.gjennomforing.wmsListe = liste;
      kartContext.gjennomforing.layer = layer;
      kartContext.gjennomforing.zoom = zoom;
      kartContext.gjennomforing.lastCenter = lastCenter;
      break;
    case 'gjPluss':
      kartContext.gjennomforing.markerModus = markerModus;
      kartContext.gjennomforing.tidsPunkt = tidsPunkt;
      break;
    case 'en':
      kartContext.enhetersok.wmsListe = liste;
      kartContext.enhetersok.layer = layer;
      kartContext.enhetersok.zoom = zoom;
      kartContext.enhetersok.lastCenter = lastCenter;
      break;

    default:
      break;
  }
  debug('GVW setKartContext', kartContext);
};

export const getContextInnstillingerKartNew = (_callback) => {
  let result = null;
  debug('ZAX 1 START', organisasjonKart);
  if (!organisasjonKart.init) {
    promisifyApiCall(getOrganisasjonLokasjon)().then((data) => {
      debug('ZAXAA XXX', data);
      organisasjonKart.latitude = data.latitude;
      organisasjonKart.longitude = data.longitude;
      organisasjonKart.zoomLevel = data.zoomLevel;
      organisasjonKart.bbox = makeBbox(data);
      organisasjonKart.test = 'Kart Test';
      organisasjonKart.init = true;
      result = organisasjonKart;
      debug('ZAX 2 NEW', organisasjonKart);
      _callback(result);
    });
  } else {
    result = organisasjonKart;
    debug('ZAX 3 EXIST', organisasjonKart);
    _callback(result);
  }
};
export const updateContextInnstillingerKart = (data) => {
  organisasjonKart.latitude = data.latitude;
  organisasjonKart.longitude = data.longitude;
  organisasjonKart.zoomLevel = data.zoomLevel;
};

//ORGANISASJONER  ************************************************

export const getOrganisasjonerData = () => {
  if (!organisasjonerData.init) {
    promisifyApiCall(getOrganisasjoner)().then((data) => {
      organisasjonerData.data = data;
      organisasjonerData.init = true;
    });
  }
};

export const updateOrganisasjonData = (org, _callback) => {
  let id = org.id;
  if (innloggetPerson.norkartAdmin) {
    console.log(org);
    promisifyApiCall(updateOrganisasjonNk)(id, org).then((data) => {
      organisasjonData.data = data;
      _callback(data);
    });
  } else {
    console.log(org);
    promisifyApiCall(updateOrganisasjon)(org).then((data) => {
      organisasjonData.data = data;
      _callback(data);
    });
  }
};

export const createOrganisasjonData = (org, _callback) => {
  promisifyApiCall(createOrganisasjon)(org).then((data) => {
    organisasjonData.data = data;
    _callback(data);
  });
};

export const setKartEgenskaperForGebyrKart = (enhetId, arbeidslisteId) => {
  kartEgenskaperForGebyrKart.enhetId = enhetId;
  kartEgenskaperForGebyrKart.arbeidslisteId = arbeidslisteId;
};

//Hva som exporteres
export const contextViaStart = React.createContext(viaStart);
export const contextInnstillingerTest = React.createContext(contextTest);
export const contextInnstillingerPerson = React.createContext(innloggetPerson);
export const contextInnstillingerMalinfo = React.createContext(
  organisasjonMalinfo
);
export const contextInnstillingerAvvikPerBruksenhet = React.createContext(
  organisasjonAvvikPerBruksenhet
);
export const contextInnstillingerSvarut = React.createContext(
  organisasjonSvarUt
);
export const contextInnstillingerGebyr = React.createContext(organisasjonGebyr);
export const contextInnstillingerKart = React.createContext(organisasjonKart);
export const contextInnstillingerKartContext = React.createContext(kartContext);
export const contextInnstillingerOrganisasjon = React.createContext(
  organisasjonData
);
export const contextInnstillingerOrganisasjoner = React.createContext(
  organisasjonerData
);
export const contextKartEgenskaperForGebyrKart = React.createContext(
  kartEgenskaperForGebyrKart
);
