import React from 'react';
import NKIcon from 'nka-icons';
import ContextMenu from 'components/ContextMenu';

const sp = '36px';


class SvarListe extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: [] };
    }

    componentDidMount() {
      this.setState({ data: this.props.data });
    }

    kopierItem=(item) => {  
      navigator.clipboard.writeText(`${item.Sporsmal}\n${item.Svar}`);
    };

  render() {
    return (
      <div style={{ maxWidth: '1200px' }}>
        {this.state.data.map((svar, i) => (
            
            <div key={'sv' + i} style={{ paddingTop: '4px', marginBottom: '28px', borderTop: '2px solid lightgray' }}>

                <div style={{ marginLeft: '8px' }}>
                    <span> <NKIcon
                        className='pull-left'
                        icon='hjelp'
                        color='nk-black'
                        size='0.8'
                        /></span>
                    <span style={{ marginLeft: '8px' }}><b>Spørsmål</b></span>
                    <span className='pull-right' style={{ marginRight: '12px' }}> 
                     <ContextMenu.Button
                        title='Meny'
                        icon='meny'
                        iconColor='nk-black'
                        className='inlinebtn'>
                          <ContextMenu.Item onClick={this.kopierItem.bind(this, svar)}>
                            Kopier spørsmål og svar
                          </ContextMenu.Item>
                          <ContextMenu.Item >
                            Eksporter til Excel
                          </ContextMenu.Item>
                      </ContextMenu.Button>
                    </span>
                    <br/>
                
                <span style={{ marginLeft: '30px' }}>{svar.Sporsmal}</span>
               <br/>
                </div>
                <div style={{ marginTop: '12px' }}>
                    <span style={{ marginLeft: sp }}><b>Svar</b></span><br/>
                    <div style={{ marginLeft: sp }}><span >{svar.Svar}</span><br/></div>
                </div>
            </div>
            
        ))}
      </div>
    );
  }
}

export default SvarListe;