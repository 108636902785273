import React from 'react';
import NKIcon from 'nka-icons';
import { Panel } from 'react-bootstrap';

const Hjemmelshavere = ({ hjemmelshavere, boretter }) => {

  return hjemmelshavere && hjemmelshavere.length > 0 ? (
    <Panel key="b1" eventKey="b1">
      <Panel.Heading>
        <Panel.Title toggle>
          <div className="vcenter">
            <NKIcon
              style={{ marginRight: '3px' }}
              icon="bruker"
              size="0.8"
              color="nk-black"
            />
            Borett bruksenhet
          </div>
        </Panel.Title>
      </Panel.Heading>
      {boretter && (
        <Panel.Body collapsible>
          <div>
            <strong>Borett for {boretter.bruksenhetsnummer}</strong>
            <ul>
            {hjemmelshavere.map((hj, idx) => (
              <li key={hj.id}>{hj.navn}</li>
            ))}
            </ul>
          </div>
          <div>
            <strong>Borettslag: </strong> {boretter.borettslag}
          </div>
        </Panel.Body>
      )}
    </Panel>
  ) : (
    <div>Hopp</div>
  );
};

export default Hjemmelshavere;