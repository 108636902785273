import React, { Component } from 'react';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';
import polygonOrange from 'images/Annet/polygonOrange.png';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import { debugFactory } from 'utils';
import VelgSone from 'components/Kart/VelgSone.jsx';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:planleggingskart'
);

let polygonLayer;
let soneLayer;

class PolygonControl extends Component {
  state = {
    panZoomRed: '',
    brukPanorer: false,
    workingLayer: [],
    currentPoly: [],
    currentSone: [],
    tegner: false,

    sone: 'btn btn-default w90',
    sonetr: 'hide',
    poly: 'btn btn-default w90',
    polytr: 'hide',

    hideSonePanel: 'hide',
    hidePolygonPanel: 'hide',
    visFilterPanTekst: 'hide',
  };

  componentDidMount = () => {
    polygonLayer = new L.FeatureGroup();
    this.props.kart.addLayer(polygonLayer);
    soneLayer = new L.FeatureGroup();
    this.props.kart.addLayer(soneLayer);
    this.props.kart.on('pm:create', (e) => {
      this.onPolygonTegned(e);
    });
    this.props.kart.on('pm:drawstart', (e) => {
      var layer = e.workingLayer;
      this.setState({ workingLayer: layer });
      layer.pm.enable(this.getPolyOptions());
    });
    this.props.kart.on('pm:drawend', (e) => {
      this.setState({ tegner: false }); //Rieni
    });

    //panorer
    this.props.kart.on('zoomend', () => {
      if (this.state.brukPanorer) {
        this.startScreenFilter();
      }
    });
    this.props.kart.on('moveend', () => {
      if (this.state.brukPanorer) {
        this.startScreenFilter();
      }
    });
  };

  startScreenFilter = () => {
    this.brukingen();
    let fbounds = this.props.kart.getBounds();
    let bounds = [];
    let b1 = [fbounds._northEast.lat, fbounds._northEast.lng];
    let b2 = [fbounds._southWest.lat, fbounds._southWest.lng];
    bounds.push(b2);
    bounds.push(b1);
    let box = new L.Rectangle(bounds);
    let geo = this.designRegionPolygon(box._latlngs[0]);
    this.props.onBrukPolygon(geo);
  };

  designRegionPolygon = (box) => {
    var coor = [
      [box[0].lng, box[1].lat],
      [box[0].lng, box[0].lat],
      [box[2].lng, box[0].lat],
      [box[2].lng, box[1].lat],
      //  [box[0].lng, box[1].lat]
    ];
    var geo = {
      name: 'LeggTilObjekter: GeoShape Polygon',
      location: {
        type: 'polygon',
        coordinates: coor,
      },
    };
    return geo;
  };

  getPolyOptions = () => {
    let options = {
      draggable: false,
      allowSelfIntersection: false,
      finishOnDoubleClick: true,
      cursorMarker: true,
      tooltips: false,
    };
    return options;
  };

  startPolygon = () => {
    if (polygonLayer) {
      polygonLayer.clearLayers();
    }
    this.setState({ tegner: true });
    this.props.kart.pm.enableDraw('Poly', this.getPolyOptions());
    this.setState({ hidePolygonPanel: 'hide' });
  };

  onPolygonTegned = (e) => {
    var layer = e.layer;
    this.addPolygon(layer);
  };

  addPolygon = (layer) => {
    this.setState({ currentPoly: layer });
    layer.setStyle({
      fillColor: 'yellow',
      color: 'red',
      weight: 2,
      title: 'Mitt polygon',
    });
    //layer.bindTooltip('Søk i polygon');
    polygonLayer.addLayer(layer);
    layer.pm.disable(this.getPolyOptions());

    var geo = this.getGeoObjekt(layer);
    this.initPoly(geo);

    //Høre på polygon-events
    layer.on('click', (e) => {
      if (this.state.tegner === false) {
        layer.pm.toggleEdit(this.getPolyOptions);

        if (layer.pm._enabled === false) {
          var geo = this.getGeoObjekt(layer);
          this.initPoly(geo);
        }
      }
    });

    layer.on('pm:edit', (e) => {
      console.log('edit');
      var geo = this.getGeoObjekt(layer);
      this.setState({ currentPoly: geo });
      this.setState({ polytr: '' });
      //Alleen als groen
      if (this.state.poly === 'btn btn-primary w90') {
        this.props.onBrukPolygon(geo);
      }
    });
  };

  getGeoObjekt = (aLayer) => {
    var coor = [];
    if (aLayer === undefined) {
      return 'Feil: Polygon er ikke komplett!';
    } else {
      var latlngs = aLayer._latlngs[0];
      for (let i = 0; i < latlngs.length; i++) {
        var cc = [latlngs[i].lng, latlngs[i].lat];
        coor.push(cc);
      }
      var geo = {
        name: 'Søk GeoShape Polygon',
        location: {
          type: 'polygon',
          coordinates: coor,
        },
      };
      return geo;
    }
  };

  initPoly = (geo) => {
    this.setState({ currentPoly: geo });
    this.setState({ polytr: '' });
    this.setState({ poly: 'btn btn-default w90' });
  };

  initSone = (geo) => {
    this.setState({ currentSone: geo });
    this.setState({ sonetr: '' });
    this.setState({ sone: 'btn btn-default w90' });
    this.props.kart.fitBounds(soneLayer.getBounds());
  };

  bruksone = () => {
    this.brukingen();
    this.setState({ sone: this.toggle(this.state.sone) }, this.handleSone());
  };

  handleSone = () => {
    if (this.state.sone !== 'btn btn-primary w90') {
      var geo = this.state.currentSone;
      this.props.onBrukPolygon(geo);
      this.props.setPolygonInUse(soneLayer);
    } else {
      this.props.onPolygonFjernet();
      this.props.setPolygonInUse(null);
    }
  };

  brukingen = () => {
    this.setState({ sone: 'btn btn-default w90' });
    this.setState({ poly: 'btn btn-default w90' });
  };

  toggle = (farge) => {
    if (farge === 'btn btn-primary w90') {
      farge = 'btn btn-default w90';
    } else {
      farge = 'btn btn-primary w90';
    }
    return farge;
  };

  brukpoly = () => {
    this.brukingen();
    this.setState({ poly: this.toggle(this.state.poly) }, this.handlePoly());
  };

  handlePoly = () => {
    if (this.state.poly !== 'btn btn-primary w90') {
      var geo = this.state.currentPoly;
      this.props.onBrukPolygon(geo);
      this.props.setPolygonInUse(polygonLayer);
    } else {
      this.props.onPolygonFjernet();
      this.props.setPolygonInUse(null);
    }
  };

  fjernAllePolygonLayers = () => {
    debug('fjern layers polygon');
    this.props.setPolygonInUse(null);
    polygonLayer.clearLayers();
    soneLayer.clearLayers();
    this.setState({ polytr: 'hide' });
    this.setState({ sonetr: 'hide' });
    this.setState({ poly: 'btn btn-default w90' });
    this.setState({ sone: 'btn btn-default w90' });
  };

  slettpoly = () => {
    this.setState({ polytr: 'hide' });
    if (this.state.poly !== 'btn btn-primary w90') {
      polygonLayer.clearLayers();
    } else {
      polygonLayer.clearLayers();
      this.props.onPolygonFjernet();
      this.props.setPolygonInUse(null);
    }
    this.setState({ poly: 'btn btn-default w90' });
  };

  slettsone = () => {
    this.setState({ sonetr: 'hide' });
    if (this.state.sone !== 'btn btn-primary w90') {
      soneLayer.clearLayers();
    } else {
      soneLayer.clearLayers();
      this.props.onPolygonFjernet();
      this.props.setPolygonInUse(null);
    }
    this.setState({ sone: 'btn btn-default w90' });
  };

  ScreenSomFilterPaaAv = () => {
    let pan = this.state.brukPanorer;
    this.props.setPolygonInUse(null);
    if (pan === true) {
      this.setState({ brukPanorer: false });
      this.props.setBrukPanorer(false);
      this.setState({ panZoomRed: '' });
      this.setState({ visFilterPanTekst: 'hide' });
      this.props.onPolygonFjernet();
    } else {
      this.setState({ brukPanorer: true });
      this.props.setBrukPanorer(true);
      this.setState({ panZoomRed: 'roed' });
      this.setState({ visFilterPanTekst: '' });
    }
    let center = this.props.kart.getCenter();
    let value = 0.0001;
    center.lat = center.lat + value;
    this.props.kart.panTo(center);
    this.setState({ hidePolygonPanel: 'hide' });
  };

  handleSonePanelHide = () => {
    var hide = this.state.hideSonePanel;
    if (hide === 'hide') {
      this.setState({ hideSonePanel: ' ' });
    } else {
      this.setState({ hideSonePanel: 'hide' });
    }
    this.setState({ hidePolygonPanel: 'hide' });
  };

  handlePolygonPanelHide = () => {
    this.setState({ hideSonePanel: 'hide' });
    this.props.kart.pm.disableDraw('Polygon');
    var hide = this.state.hidePolygonPanel;
    if (hide === 'hide') {
      this.setState({ hidePolygonPanel: ' ' });
    } else {
      this.setState({ hidePolygonPanel: 'hide' });
    }
  };

  soneValgt = (sone) => {
    this.setState({ hideSonePanel: 'hide' });
    soneLayer.clearLayers();
    let liste = this.makePunktListe(sone.polygon);
    let polygon = new L.Polygon([liste], {
      fillColor: sone.color,
      weight: 2,
      fillOpacity: 0.1,
      color: 'black',
    })
      .bindTooltip('Sone ' + sone.navn)
      .addTo(soneLayer);

    var geo = this.makeGeo(sone);
    this.initSone(geo);
  };

  makeGeo = (sone) => {
    let liste = this.makePunktListeElstic(sone.polygon);
    var geo = {
      name: 'LeggTilObjekter: GeoShape Polygon-' + sone.navn,
      location: {
        type: 'polygon',
        coordinates: liste,
      },
    };
    return geo;
  };

  //lat - long omvendt !!
  makePunktListeElstic = (polygon) => {
    let poly = [];
    let parse = JSON.parse(polygon);
    for (var i = 0; i < parse.length; i++) {
      var par = [parse[i].lng, parse[i].lat];
      poly.push(par);
    }
    return poly;
  };

  makePunktListe = (polygon) => {
    let poly = [];
    let parse = JSON.parse(polygon);
    for (var i = 0; i < parse.length; i++) {
      var par = [parse[i].lat, parse[i].lng];
      poly.push(par);
    }
    return poly;
  };

  closeMeny = () => {
    this.setState({ hideSonePanel: 'hide' });
  };

  render() {
    let figurPanel = (
      <div>
        <table>
          <tbody>
            <tr className={this.state.sonetr}>
              <td>
                <button
                  style={{ height: '30px', marginBottom: '4px' }}
                  onClick={this.bruksone}
                  className={this.state.sone}>
                  Bruk Sone
                </button>
              </td>
              <td>
                <button
                  style={{ height: '30px' }}
                  onClick={this.slettsone}
                  className='btn btn-default bslett'>
                  X
                </button>
              </td>
            </tr>
            <tr className={this.state.polytr}>
              <td>
                <button
                  style={{ height: '30px', marginBottom: '4px' }}
                  onClick={this.brukpoly}
                  className={this.state.poly}>
                  Bruk Poly
                </button>
              </td>
              <td>
                <button
                  style={{ height: '30px' }}
                  onClick={this.slettpoly}
                  className='btn btn-default bslett'>
                  X
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <label className={'zoomlabel ' + this.state.visFilterPanTekst}>
          Filter ved panorer/zoom på
        </label>
      </div>
    );

    let sonePanel = (
      <div className={'subPanel ' + this.state.hideSonePanel}>
        <VelgSone soneValgt={this.soneValgt} closeMeny={this.closeMeny} />
      </div>
    );

    return (
      <div>
        <Control position='topleft'>
          <div style={{ marginTop: '4px' }}>
            <button
              title='Bruk kart som søk/filter argument'
              type='button'
              className={'btnLag '}
              onClick={this.handlePolygonPanelHide}>
              {this.state.panZoomRed === '' && (
                <NKIcon icon='polygon' color='nk-black' />
              )}
              {this.state.panZoomRed === 'roed' && (
                <img height='22' width='22' src={polygonOrange} alt='' />
              )}
            </button>
            <div
              className={'layerComponent samme ' + this.state.hidePolygonPanel}>
              <button
                style={{ width: '80px' }}
                title='Tegn polygon'
                type='button'
                className={'btn btn-default'}
                onClick={this.startPolygon}>
                Polygon
              </button>
              <button
                style={{ width: '80px' }}
                title='Velg sone'
                type='button'
                className={'btn btn-default'}
                onClick={this.handleSonePanelHide}>
                Soner
              </button>
              <button
                style={{ width: '116px' }}
                title='Filtrer ved zoom og panorering'
                type='button'
                className={'btn btn-default ' + this.state.panZoomRed}
                onClick={this.ScreenSomFilterPaaAv}>
                Panorer/Zoom
              </button>
            </div>
            {sonePanel}
          </div>
        </Control>

        <Control position='bottomleft'>
          <div>
            <label className='zoomlabel'>
              {'zoom: ' + this.props.zoomLevel}
            </label>
            <br />
            {figurPanel}
          </div>
        </Control>
      </div>
    );
  }
}

export default PolygonControl;
