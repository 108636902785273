import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';
import { objGetDeep } from 'utils';

export default function(props) {
  const pathname = objGetDeep(props, 'location.pathname');

  return (
    <div>
      <div className='row'>
        <div className='col-sm-12 col-md-8 col-lg-6'>
          <LinkList title='Test' columns={1}>
            <LinkListItem title='Test 1' path={`${pathname}/Test1`} />
            <LinkListItem title='Test 2' path={`${pathname}/Test2`} />
            <LinkListItem title='Test 3' path={`${pathname}/Test3`} />
            <LinkListItem title='Test 4' path={`${pathname}/Test4`} />
            <LinkListItem title='Test func.comp.Dialogs' path={`${pathname}/TestFuncDialogs`} />
            <LinkListItem title='Test funct.comp' path={`${pathname}/TestFunc`} />
            <LinkListItem title='Test funct.comp2' path={`${pathname}/TestFunc2`} />
            <LinkListItem title='Test funct.comp3' path={`${pathname}/TestFunc3`} />
            <LinkListItem title='Test nka comp' path={`${pathname}/TestNka`} /> 
            <LinkListItem title='Ølbrygging' path={`${pathname}/TestStein`} /> 
            <LinkListItem title='Test parent childs' path={`${pathname}/TestParentChilds`} /> 
          </LinkList>
        </div>
      </div>
    </div>
  );
}
