import React, { Component } from 'react';
import L from 'leaflet';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import { debugFactory } from 'utils';
import { setWmsLayer } from './WmsUtils';
import $ from 'jquery';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import { Tab, Tabs } from 'react-bootstrap';
import './regioner.css';
import { contextOppslagKommuner } from 'context/contextOppslag.js';
import { getSimpleOrganisasjon } from 'api/organisasjonApi';
import CONFIG from 'lib/CONFIG';
import {
  contextInnstillingerPerson,
  getContextInnstillingerKartNew,
} from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:Kart:WmsControl');

let wmsGroup = L.featureGroup();

class WmsControl extends Component {
  state = {
    wmsListe: [],
    wmsListeCount: 0,
    hideWmsPanel: 'hide',
    wmsFInfoObjectListe: [],
    wmsGroups: [],
    teller: 0,
    kommuner: [],
    groupAdded: false,
    wmsTeller: 11,
    bbox: '',
    activeTab: 1,
    valgteWms: [],
    layerForOpasitet: null,
    sliderValue: 10,
  };

  componentDidMount = () => {
    getContextInnstillingerKartNew(this.gotKart);
    wmsGroup.eachLayer(function(layer) {
      wmsGroup.removeLayer(layer);
    });
    getSimpleOrganisasjon(this.onGotSimpleOrganisasjon);
    if (this.props.kart) {
      wmsGroup.addTo(this.props.kart);
      this.setState({ groupAdded: true });
    }

    //Så lenge kun til oss
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      this.getWmsForKommuner();
    }
  };

  gotKart = (data) => {
    this.setState({ bbox: data.bbox });
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.state.groupAdded === false) {
      if (nextProps.kart) {
        wmsGroup.addTo(nextProps.kart);
        this.setState({ groupAdded: true });
      }
    }
  };

  getWmsForKommuner = () => {
    // urlx = 'https://tjenestekatalogen.api.norkart.no/CatalogueService.svc/json/GetCapabilities?applicationID=cpc-Kommunekart-5037';  //Rieni  5037  4612
    let url = CONFIG.REACT_APP_WMS_URL;
    let appName = '=cpc-kommunekart-';

    let kommuner = contextOppslagKommuner._currentValue.kommuner;
    for (let i = 0; i < kommuner.length; i++) {
      let kom = kommuner[i];
      let urlx = url + appName + kom.kommuneId;
      this.setState({ komkom: kom.kommuneNavn });
      $.getJSON(urlx, this.gotWmsListeFraTjenestePanel);
    }
  };

  onGotSimpleOrganisasjon = (err, organisasjon) => {
    let urlConfig = CONFIG.REACT_APP_WMS_URL;
    let appName = '=cpc-Brannforebygging-';

    let urlx = urlConfig + appName + organisasjon.kortnavn;
    $.getJSON(urlx, this.gotWmsListeFraTjenestePanel); //Eiendomskart
  };

  gotWmsListeFraTjenestePanel = (data, x, z) => {
    debug('PLaX gotWmsListeFraTjenestePanel', data);
    debug('PLaX gotWmsListeFraTjenestePanel', x);
    debug('PLaX gotWmsListeFraTjenestePanel', z);
    let teller = this.state.teller;
    let wmsListe = this.state.wmsListe;
    for (let i = 0; i < data.length; i++) {
      const group = data[i];
      let groupname = group.Name;
      let groupurl = group.URL;
      let grouptitle = group.Title;
      let imageurl = this.makeImageUrl(groupurl);
      for (let y = 0; y < group.Layers.length; y++) {
        let layer = group.Layers[y];
        teller = teller + 1;
        let rad = {
          alphablend: layer.AlphaBlend,
          layerdescription: layer.Description,
          layername: layer.Name,
          id: teller,
          zoomMax: 20,
          zoomMin: 14,
          groupurl: groupurl,
          grouptitle: grouptitle,
          groupname: groupname,
          style: '',
          srs: layer.SRS,
          className: 'btn btn-default',
          imageurl: imageurl,
        };
        wmsListe.push(rad);
      }
    }
    wmsListe = _.sortBy(wmsListe, 'grouptitle');
    this.setState({ wmsListe: wmsListe });
    this.setState({ teller: teller });
    let wmsGroupsRaw = _.uniqBy(wmsListe, 'grouptitle');
    let wmsGroups = [];

    for (let x = 0; x < wmsGroupsRaw.length; x++) {
      wmsGroups.push(wmsGroupsRaw[x].grouptitle);
    }

    this.setState({ wmsGroups: wmsGroups });
  };

  makeImageUrl = (groupurl) => {
    let urlstring = decodeURI(groupurl);
    var deler = urlstring.split('?');
    urlstring = deler[0];
    urlstring = urlstring.toLowerCase();
    urlstring = urlstring.replace('waapi.', 'www.');
    return urlstring;
  };

  handleWmsPanelHide = () => {
    this.props.hidePanel();
  };

  velgWms = (wmsRad) => {
    debug('PLO', wmsRad);
    if (this.wmsVisesAllerede(wmsRad)) {
      return;
    }
    wmsGroup.on('click', (e) => {});

    wmsGroup = setWmsLayer(wmsGroup, wmsRad, true);
    debug('PLO', wmsGroup);
    this.setState({ hideWmsPanel: 'hide' });
    this.setFargePaaListe(true, wmsRad);
  };

  setFargePaaListe = (farge, wmsRad) => {
    let klasse = 'btn btn-default';

    if (farge) {
      klasse = 'btn btn-primary';
    }
    let liste = this.state.wmsListe;
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].id === wmsRad.id) {
        liste[i].className = klasse;
        break;
      }
    }
    this.setState({ wmsListe: liste });
    liste = this.filterPrimary(liste);
    this.props.setWmsListe(liste);
  };

  filterPrimary = (liste) => {
    let nyListe = [];
    for (let i = 0; i < liste.length; i++) {
      let element = liste[i];
      if (element.className.includes('primary')) {
        nyListe.push(element);
      }
    }
    this.setState({ wmsListeCount: nyListe.length });
    return nyListe;
  };

  wmsVisesAllerede = (wmsRad) => {
    let layerFjernes = null;
    wmsGroup.eachLayer(function(layer) {
      if (layer.options.layers === wmsRad.layername) {
        layerFjernes = layer;
      }
    });
    if (layerFjernes === null) {
      return false;
    } else {
      wmsGroup.removeLayer(layerFjernes);
      this.setFargePaaListe(false, wmsRad);
      return true;
    }
  };

  skjulPanel = () => {
    this.props.hidePanel();
  };

  skjulAlle = () => {
    wmsGroup.clearLayers();
    let liste = this.state.wmsListe;
    let klasse = 'btn btn-default';
    for (let i = 0; i < liste.length; i++) {
      liste[i].className = klasse;
    }
    this.setState({ wmsListe: liste });
    this.setState({ wmsListeCount: 0 });
    this.props.setWmsListe([]);
  };

  visInfo = () => {
    this.props.setVisSkjulLegend();
  };

  makeGroupkey(group) {
    let s = group.replace(/[\])}[{(]/g, '');
    let z = s.replace(/\s+/g, '');
    return z;
  }

  setOpasitet = () => {
    debug('KOLP 1-');
    this.props.kart.eachLayer(function(layer) {
      if (layer.wmsParams) {
        if (layer.wmsParams.layers === 'KPLAN') {
          let o = layer.options.opacity;
          debug('KOLP 1-', o);
          if (!o) {
            layer.setOpacity(0.1);
          } else {
            let x = o + 0.1;
            layer.setOpacity(x);
            debug('KOLP 2+', x);
          }
        }
      }
    });
  };

  handleSelectTab = (selectedTab) => {
    this.setState({ activeTab: selectedTab });
    if (selectedTab === 2) {
      this.makeValgteWms();
    }
  };

  makeValgteWms = () => {
    let array = [];
    this.props.kart.eachLayer(function(layer) {
      if (layer.wmsParams) {
        array.push(layer);
      }
    });

    this.setState({ valgteWms: array });
    return array;
  };

  radioOpasitetChange = (event) => {
    const { value } = event.target;
    /*  layerForOpasitet= value;
    this.setState({ xx: value }); */
    let L = null;
    this.props.kart.eachLayer(function(layer) {
      if (layer.wmsParams) {
        if (layer.wmsParams.layers === value) {
          L = layer;
        
        }
      }
    });
    this.setState({ layerForOpasitet: value });
    let op = L.options.opacity;
    if (op) {
      if (op === '') {
        op = 1;
      }
      this.setState({ sliderValue: op * 10 });
    }
  };

  slider = (e) => {
    let op = e.target.value;
    this.setState({ sliderValue: op });
    let L = null;
    let name = this.state.layerForOpasitet;
    this.props.kart.eachLayer(function(layer) {
      if (layer.wmsParams) {
        if (layer.wmsParams.layers === name) {
          L = layer;
        }
      }
    });
    if (L){
      L.setOpacity(op / 10);
    }
   
  };

  render() {
    let wmsPanel = (
      <div
        style={{
          minWidth: '360px',
          backgroundColor: 'white',
          marginLeft: '2px',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}>
        <button
          title='Lukk'
          style={{ height: '33px', width: '33px', float: 'right' }}
          onClick={this.handleWmsPanelHide}>
          X
        </button>
        {this.state.wmsListe.length > 0 ? (
          <Tabs
            id='wmstabs'
            activeKey={this.state.activeTab}
            onSelect={this.handleSelectTab}>
            <Tab eventKey={1} title='Velg Wms'>
              <React.Fragment>
                <div>
                  <div
                    style={{
                      maxHeight: '360px',
                      height: 'auto',
                      backgroundColor: 'white',
                      overflowY: 'scroll',
                      background: 'white',
                      padding: '6px',
                    }}>
                    <div
                      className='panel-group'
                      style={{ width: '360px' }}
                      id='accordion'>
                      <span>
                        {this.state.wmsGroups.map(function(group) {
                          var g = this.makeGroupkey(group);
                          var bb = 'key' + g;
                          var html;
                          html = (
                            <div key={bb} style={{ width: '360px' }}>
                              <button
                                className='btn btn-basic arrow-toggle collapsed'
                                style={{
                                  marginBottom: '4px',
                                  borderColor: 'darkgreen',
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  width: '360px',
                                }}
                                data-toggle='collapse'
                                data-target={'#' + g}>
                                <label className='pull-left'>{group}</label>
                                <NKIcon
                                  className='pull-right icon-arrow-up'
                                  icon='ned'
                                  size='1'
                                  color='nk-black'
                                />
                                <NKIcon
                                  className='pull-right icon-arrow-down'
                                  icon='opp'
                                  size='1'
                                  color='nk-black'
                                />
                              </button>

                              <div id={g} className='collapse w200'>
                                {this.state.wmsListe.map((wms) => {
                                  if (wms.grouptitle === group) {
                                    var bId = 'btn_' + wms.id;
                                    var dId = 'div_' + wms.id;
                                    var html;
                                    html = (
                                      <div key={dId}>
                                        <button
                                          style={{
                                            width: '340px',
                                            marginLeft: '10px',
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                          }}
                                          key={bId}
                                          type='button'
                                          onClick={this.velgWms.bind(this, wms)}
                                          className={wms.className}>
                                          {wms.layerdescription}
                                        </button>
                                        <br />
                                      </div>
                                    );
                                    return html;
                                  } return '';
                                }, this)}
                              </div>
                            </div>
                          );

                          return html;
                        }, this)}
                      </span>
                    </div>
                  </div>

                  {this.state.wmsListeCount < 1 && (
                    <div style={{ height: '8px', backgroundColor: 'white' }} />
                  )}
                </div>
              </React.Fragment>
            </Tab>
            {/* Opasitet */}
            <Tab
              style={{ width: '200px', height: '360px' }}
              eventKey={2}
              title='Gjennomsiktighet'>
              <div style={{ padding: '10px' }}>
                <table style={{ width: '340px' }}>
                   <tbody>
                      <tr>
                        <td style={{ width: '70%', fontSize: '18px', whiteSpace: 'nowrap'  }}>Velg Wms og gjennomsiktighet:</td>
                        <td style={{ width: '10%', whiteSpace: 'nowrap' }}></td>
                        <td style= {{ width: '20%', whiteSpace: 'nowrap'  }}>Gjenn: {this.state.sliderValue}</td>
                      </tr>
                    </tbody>
                </table>
                <br/>
              
                <div className='slidecontainer'>
                  <input
                    onInput={this.slider}
                    type='range'
                    min='1'
                    max='10'
                    value={this.state.sliderValue}
                    className='slider'
                    id='myRange'
                  />
                 
                </div>
                <br />
                <div   style={{
                      maxHeight: '244px',
                      height: 'auto',
                      backgroundColor: 'white',
                      overflowY: 'auto',
                      background: 'white',
                      padding: '6px',
                      width: '330px'
                    }}>
                  {this.state.valgteWms.map(function(layer, index) {
                    return (
                      <>
                        <input
                          type='radio'
                          className='form-check-input'
                          value={layer.options.layers}
                          onChange={(event) => this.radioOpasitetChange(event)}
                          name='q'
                          id={'pp' + index}
                        />
                        <label
                          style={{ marginLeft: '4px' }}
                          for={'pp' + index}
                          className='form-check-label'>
                          {layer.options.layers} 
                        </label>
                        <br />
                      </>
                    );
                  }, this)}
                </div>
              </div>
            </Tab>
            {/*   <Tab eventKey={2} title='Tegnforklaring'>
              <div
                style={{
                  maxHeight: '400px',
                  width: '340px',
                  backgroundColor: 'white',
                  overflowY: 'scroll',
                  background: 'white',
                  padding: '6px',
                }}>
                <React.Fragment>
                  {this.state.valgtWmsListe.length > 0 &&
                    this.state.visWmsLegend && (
                      <div
                        style={{
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: 'darkgray',
                          backgroundColor: 'white',
                        }}>
                        <div>
                          {this.state.valgtWmsListe.map(function(wms) {
                            let source = this.getImageSource(wms);
                            if (source === 'ingen') {
                              return '';
                            }
                            var imgId = 'img' + wms.id;
                            var dId = 'div' + wms.id;
                            var html;
                            html = (
                              <div key={dId}>
                                <div
                                  style={{
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingBottom: '5px',
                                    borderBottom: 'thin solid darkgray',
                                  }}>
                                  <label>{wms.layerdescription}</label>
                                  <br />
                                  <img
                                    key={imgId}
                                    src={source}
                                    alt={' Bilde mangler'}
                                  />
                                </div>
                              </div>
                            );
                            return html;
                          }, this)}
                        </div>
                      </div>
                    )}
                </React.Fragment>
              </div>
            </Tab> */}
          </Tabs>
        ) : (
          <div
            style={{
              padding: '14px',
              backgroundColor: 'white',
              borderStyle: 'solid',
              borderWidth: '1px',
            }}>
            <button
              title='Lukk'
              style={{ height: '33px', width: '33px', float: 'right' }}
              onClick={this.handleWmsPanelHide}>
              X
            </button>
            <label style={{ marginTop: '8px', marginRight: '8px' }}>
              Ingen Wms definert.
            </label>
          </div>
        )}
      </div>
    );

    return (
      <React.Fragment>
        <div>{wmsPanel}</div>
      </React.Fragment>
    );
  }
}

export default WmsControl;


