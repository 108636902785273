import React from 'react';
import Brukerinnstillinger from './Brukerinnstillinger.jsx';

export default class extends React.Component {
    state = { breadcrumbname: 'Laster....' };

    render() {
        return <div>
            <Brukerinnstillinger />
        </div>;
    }
}