import React, { Component } from 'react';
import { debugFactory } from 'utils';
import moment from 'moment';
import './driftsmeldinger.css';
import   MarkdownInfo   from './MarkdownInfo';
import { getKundeMelding, oppdaterKundeMelding } from 'api/innloggingApi.js';
const debug = debugFactory('nk:DriftsmeldingSystem');

class DriftsmeldingerKunde extends Component {
  constructor(props) {
    super(props);
    this.state = { orgmelding: null, melding: null };
  }

  componentDidMount() {
    getKundeMelding(this.gotMelding);
  }

  bekreftLagre = () => {
    oppdaterKundeMelding(this.state.melding, this.oppdatert);
  };

  oppdatert = (err, data) => {
    if (data) {
      let m = this.state.melding;
      this.setState({ orgmelding: m });
    }
  };

  gotMelding = (err, melding) => {
    debug('ZZZ info', melding);
    if (melding) {
      this.setState({ melding: melding, orgmelding: melding });
    } else {
      melding = {
        aktiv: false,
        tittel: 'tittel',
        tekst: 'tekst',
        url: '',
        urlLabel: '',
      };
      this.setState({ melding: melding, orgmelding: melding });
    }
  };

  lagre = () => {};

  render() {
    if (!this.state.melding) {
      return <div>Vent...</div>;
    }
    return (
      <div>
        <h2>
          <span>Informasjon startside</span>
        </h2>
        <h4>F.eks. melding om seminar, kurs, kundeundersøkelse mm.</h4>
        <br /> <br />
        <table style={{ marginLeft: '40px' }}>
          <tbody>
            <tr>
              <td>
                <label style={{ marginTop: '6px' }}>Aktiv</label>
              </td>
              <td style={{ width: '16px' }} />
              <td>
                {' '}
                <input
                  className='form-check-input'
                  style={{ width: '20px', height: '20px', marginLeft: '10px' }}
                  type='checkbox'
                  checked={this.state.melding.aktiv}
                  onChange={(e) => {
                    this.setState({
                      melding: {
                        ...this.state.melding,
                        aktiv: !this.state.melding.aktiv,
                      },
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <label style={{ marginTop: '6px' }}>Tittel</label>
              </td>
              <td style={{ width: '16px' }} />
              <td>
                {' '}
                <input
                  placeholder='Tittel..'
                  style={{
                    width: '800px',
                    marginLeft: '8px',
                    fontSize: '18px',
                  }}
                  maxLength='450'
                  className='form-control'
                  type='text'
                  value={this.state.melding.tittel}
                  onChange={(e) => {
                    this.setState({
                      melding: {
                        ...this.state.melding,
                        tittel: e.target.value,
                      },
                    });
                  }}
                />
              </td>
            </tr>
            <tr style={{ height: '10px' }} />
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <label style={{ marginTop: '6px' }}>Tekst</label>
              </td>
              <td style={{ width: '16px' }} />
              <td>
                {' '}
                <textarea
                  style={{
                    width: '800px',
                    padding: '6px',
                    marginTop: '6px',
                    resize: 'none',
                    fontSize: '18px',
                    marginLeft: '10px',
                  }}
                  /* cols='80' */
                  rows='7'
                  value={this.state.melding.tekst}
                  placeholder='Tekst..'
                  onChange={(e) => {
                    this.setState({
                      melding: {
                        ...this.state.melding,
                        tekst: e.target.value,
                      },
                    });
                  }}
                />
              </td>
            </tr>
            <tr style={{ height: '10px' }} />
            <tr>
              <td>
                <label style={{ marginTop: '6px' }}>Url / link</label>
              </td>
              <td style={{ width: '16px' }} />
              <td>
                {' '}
                <input
                  placeholder='Url / link..'
                  style={{
                    color: 'blue',
                    width: '800px',
                    marginLeft: '8px',
                    fontSize: '18px',
                  }}
                  maxLength='450'
                  className='form-control'
                  type='text'
                  value={this.state.melding.url}
                  onChange={(e) => {
                    this.setState({
                      melding: {
                        ...this.state.melding,
                        url: e.target.value,
                      },
                    });
                  }}
                />
              </td>
            </tr>
            <tr style={{ height: '10px' }} />
            <tr>
              <td>
                <label style={{ marginTop: '6px' }}>Url label</label>
              </td>
              <td style={{ width: '16px' }} />
              <td>
                {' '}
                <input
                  placeholder='Url label.'
                  style={{
                    width: '800px',
                    marginLeft: '8px',
                    fontSize: '18px',
                  }}
                  maxLength='450'
                  className='form-control'
                  type='text'
                  value={this.state.melding.urlLabel}
                  onChange={(e) => {
                    this.setState({
                      melding: {
                        ...this.state.melding,
                        urlLabel: e.target.value,
                      },
                    });
                  }}
                />
              </td>
            </tr>
            
            <tr style={{ height: '10px' }} />
            <tr style={{ color: 'gray' }}>
              <td>Sist endret</td>
              <td />
              <td>
                <span style={{ marginLeft: '12px' }}>
                  {moment(this.state.melding.endretDato).format('DD.MM.YYYY')}{' '}
                  av {this.state.melding.endretAv}
                </span>
              </td>
            </tr>
            <tr style={{ height: '30px' }} />
            <tr>
              <td />
              <td />
              <td>
                {this.state.melding !== this.state.orgmelding && (
                  <button
                    className='btn btn-success'
                    style={{ width: '800px', marginLeft: '8px' }}
                    onClick={this.bekreftLagre}>
                    Bekreft endringene
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
       < MarkdownInfo></MarkdownInfo>
        <br />
      </div>
    );
  }
}

export default DriftsmeldingerKunde;
