import React from 'react';
import ContextMenu from 'components/ContextMenu';
import ReactTable from '@norkart/react-table';
import OrgDetaljerNK from './OrgDetaljerNK';
import DialogStore from 'lib/DialogStore';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { getOrganisasjoner } from 'api/organisasjonApi';
import { withRouter } from 'lib/enhancers';
// Utils
import { debugFactory, promisifyApiCall } from 'utils';

const debug = debugFactory('nk:innstillinger:endreorganisasjoner');

class EndreOrganisasjoner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisasjoner: [],
    };

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  isLoading = () => this.state.organisasjoner === [];

  componentDidMount() {
    this.hentData();
  }

  hentData = () => {
    promisifyApiCall(getOrganisasjoner)().then((data) => {
      this.setState({ organisasjoner: data });
    });
  };

  onRowClick = (row) => {
    debug('row', row);

    this.dialogs.setOpenDialog('VisEndreOrganisasjon', {
      nyOrganisasjon: false,
      organisasjon: row,
    });
  };

  onMassivClick = (row) => {
    this.props.router.push({
      pathname: 'Admin/RisikoMassiv',
      state: { org: row },
    });
  };

  nyOrganisasjon = () => {
    this.dialogs.setOpenDialog('VisEndreOrganisasjon', {
      nyOrganisasjon: true,
      organisasjon: null,
    });
  };

  afterLagre() {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('LoggInnIgjen');
  }

  vennligListe = () => {
    let org = this.state.organisasjoner;
    let organisasjoner = [];
    let oss = org.find((x) => x.kategoriNavn === 'Demo');
    if (oss) {
      organisasjoner.push(oss);
    }

    for (let i = 0; i < org.length; i++) {
      let or = org[i];
      if (or.kategoriNavn !== 'Demo') {
        organisasjoner.push(or);
      }
    }
    return organisasjoner;
  };

  render() {
    debug('render', { ...this });

    if (this.isLoading()) {
      return null;
    }
    if (!this.state.organisasjoner) {
      return <div>Vent..</div>;
    }
    if (this.state.organisasjoner.length < 1) {
      return <div>Vent litt..</div>;
    }
    let listeO = this.vennligListe();

    return (
      <div>
        <h2>Organisasjoner</h2>

        <div >  {/* className='btn-toolbar' */}
          <button
            style={{ margin: '5px' }}
            className='btn btn-default'
            onClick={this.nyOrganisasjon}>
            Ny organisasjon
          </button>
          <span className='pull-right' style={{ color: 'blue' }}>For Risikovurdering massiv analyse → bruk contextmeny.  </span>
        </div>
        <ReactTable
          items={listeO}
          idProperty='id'
          showIndex={false}
          filterable={true}
          columns={getColumns(this)}
          selectableRows={false}
          selectAllLabel='Alle'
          onRowClick={(row) => this.onRowClick(row)}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(EndreOrganisasjoner);

const modalDialogs = {
  LoggInnIgjen: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel='Aktivere endringer'
      melding='Logg inn i KOMTEK Brannforebygging på nytt for å aktivere endringene du har gjort.'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),

  VisEndreOrganisasjon: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen} onClose={onClose}>
        <OrgDetaljerNK
          onClose={onClose}
          onOk={() => {
            owner.dialogs.closeDialog(); // Explicit close neede before opening a new dialog right away
            owner.dialogs.setOpenDialog('LoggInnIgjen');
            owner.hentData();
          }}
          organisasjon={dialogData.organisasjon}
          nyOrganisasjon={dialogData.nyOrganisasjon}
        />
      </NKModal>
    );
  },
};

const getColumns = (owner) => {
  return [
    {
      id: 'navn',
      name: 'Navn',
      sortParams: 'navn',
      isSorted: false,
      sortDirection: 'asc',
    },
    // {
    //     id: 'kortnavn',
    //     name: 'Kort navn',
    //     sortParams: 'kortnavn',
    //     isSorted: false
    // },
    {
      id: 'beskrivelse',
      name: 'Beskrivelse',
      sortParams: 'beskrivelse',
      isSorted: false,
    },
    {
      id: 'kategoriNavn',
      name: 'Kategori',
      sortParams: 'KategoriNavn',
      isSorted: false,
    },
    {
      id: 'aktiv',
      name: 'Aktiv',
      formatter: function(item) {
        return item.aktiv ? 'Ja' : 'Nei';
      },
    },
    {
      id: 'id',
      name: 'Id',
      sortParams: 'id',
      isSorted: false,
    },
    /*  {
          id: 'kategori',
          name: 'KategoriNavn',
          sortParams: 'KategoriNavn',
          isSorted: false
        }, */
    {
      id: 'organisasjonsnr',
      name: 'Organisasjonsnr',
      sortParams: 'organisasjonsnr',
      isSorted: false,
    },
    {
      id: 'smsSkarp',
      name: 'SMS Innst',
      formatter: function(item) {
        return item.smsSkarp ? 'Skarp' : 'Simulering';
      },
      sortable: false,
    },
    {
      id: 'svarUt',
      name: 'SvarUt',
      formatter: function(item) {
        return item.svarUt ? 'Ja' : 'Nei';
      },
    },
    {
      id: 'risiko',
      name: 'Risikov.',
      formatter: function(item) {
        return item.risikovurdering ? 'Ja' : 'Nei';
      },
    },
    {
      id: 'meny',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        return (
          <ContextMenu.Button
            title='Meny'
            icon='meny'
            iconColor='nk-black'
            className='inlinebtn'>
            <ContextMenu.Item onClick={() => owner.onRowClick(item)}>
              Endre organisasjon
            </ContextMenu.Item>
              <ContextMenu.Item onClick={() => owner.onMassivClick(item)}>
                Risikovurdering Massiv analyse
              </ContextMenu.Item>
          </ContextMenu.Button>
        );
      },
    },
  ];
};
