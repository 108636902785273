import React from 'react';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import {
  Panel
} from 'react-bootstrap';

class Eierinfo extends React.Component {

  render() {
    let { eiere, eventKeyIndex } = this.props;    
    let index = 0;
    
    let eierPanels = _.map(eiere, function(eier) {
        eventKeyIndex++;
      index++;
      return (
        <div key={eventKeyIndex}>
        <Panel eventKey={eventKeyIndex}>
          <Panel.Heading>
            <Panel.Title toggle>
            <div className="vcenter">
              <NKIcon style={{ marginRight: '3px' }} icon="bruker" size="0.8" color="nk-black" />
              Tinglyst eier ({index + '/' + eiere.length})
              </div>
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <p>{eier.navn}</p>
            <p>{eier.adresse}</p>
            <p>{eier.poststed}</p>
            <p><strong>Andel: </strong>{eier.andel}</p>
            <p><strong>Rolle: </strong>{eier.rolle}</p>
            <p><strong>Status: </strong>{eier.status}</p>          
          </Panel.Body>
        </Panel>
        </div>
      );
    });
    return <div>{eierPanels}</div>;
  }
}
export default Eierinfo;
