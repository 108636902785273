import React, { Component } from 'react';
import ReactTable from '@norkart/react-table';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:VelgRoyklop');


class VelgRoyklop extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 0, rader: [] };
  }

  componentDidMount() {
      let rader = this.props.item.source.skybrannData.royklop;
      debug('VELGR R', rader);
      this.setState({ rader });
  }

  Ok = () => {};


  render() {
    let columns = [
        {
          id: 'id',
          name: 'Id',
          sortParams: 'id',
          filterable: true,
          isSorted: false,
          sortable: true,
        },
        {
          id: 'plassering',
          name: 'Plassering',
          sortParams: 'plassering',
          filterable: false,
          isSorted: false,
          sortable: true,
        },
        {
            id: 'type',
            name: 'Type',
            sortParams: 'type',
            filterable: false,
            isSorted: false,
            sortable: true,
          }
        ];
    return (
      <div className='modal-content'>
        <div className='modal-header modal-header-info'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Velg røykløp</h4>
        </div>
        <div
          className='modal-body'
        />
          <ReactTable
            idProperty='tableId'
            showIndex={false}
            items={this.state.rader}
            columns={columns}
           // selection={valgteEnheter}
            toggleAll={true}
            selectableRows={true}
           
           /*  onRowSelectionChange={(selectedRows) =>
              this.onRowSelectionChange({
                selectedRows,
                prevSelectedRows: valgteEnheter,
              })
            } */
          />
        <div className='modal-footer'>
          <button
            style={{ margin: '2px', width: '80px', height: '40px' }}
            className='btn btn-primary pull-right'
            onClick={this.Ok.bind(this)}>
            Ok
          </button>
          <br />
        </div>
      </div>
    );
  }
}

export default VelgRoyklop;
