import React, { Component } from 'react';
import './diagram.css';
import {
  ResponsiveContainer,
  Cell,
  Tooltip,
  PieChart,
  Pie,
  Label,
  Legend,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';
import { exportComponentAsPNG } from 'react-component-export-image';

class DonutsRisikoAnalyse extends Component {
  constructor(props) {
    super(props);
    this.componentKakeRef = React.createRef();
    this.componentRef = React.createRef();
    this.state = {
      ddata: [],
      fdata: [],
      dgruppedata: [],
      fgruppedata: [],
      visLabel: true,
      visSomGruppe: true,
      horizontalBar: true,
      loaded: false,
    };
  }

  componentDidMount = () => {
    this.makeData(this.props.tilsyndata, this.props.feiingdata);
    this.makeGruppeData(this.props.tilsyndata, this.props.feiingdata);
  };

  makeData = (tdata, rdata) => {
    let ddata = [];
    for (let i = 0; i < tdata.length; i++) {
      let obj = {
        value: tdata[i].risikoverdiAntall,
        name: tdata[i].risikoverdi,
        color: tdata[i].color,
      };
      ddata.push(obj);
    }
    this.setState({ ddata });

    let fdata = [];
    for (let i = 0; i < rdata.length; i++) {
      let obj = {
        value: rdata[i].risikoverdiAntall,
        name: rdata[i].risikoverdi,
        color: rdata[i].color,
      };
      fdata.push(obj);
    }

    this.setState({ fdata });
  };

  makeGruppeData = (tdata, rdata) => {
    let blue = 0;
    let green = 0;
    let orange = 0;
    let red = 0;
    let ddata = [];
    let fdata = [];

    for (let i = 0; i < tdata.length; i++) {
      let gruppe = this.getRisikoGruppe(tdata[i].risikoverdi);
      switch (gruppe) {
        case 'blue':
          blue = blue + tdata[i].risikoverdiAntall;
          break;
        case 'green':
          green = green + tdata[i].risikoverdiAntall;
          break;
        case 'orange':
          orange = orange + tdata[i].risikoverdiAntall;
          break;
        case 'red':
          red = red + tdata[i].risikoverdiAntall;
          break;
        default:
          break;
      }
    }

    ddata.push({ value: blue, name: '0', color: '#abcdef' });
    ddata.push({ value: green, name: 'Lav', color: 'green' });
    ddata.push({ value: orange, name: 'Middels', color: '#FFAE42' });
    ddata.push({ value: red, name: 'Høy', color: 'red' });

    console.log(
      '%c RIENI Data ',
      'font-size: 14px; background: red; color: white',
      ddata
    );
    this.setState({ dgruppedata: ddata });

    blue = 0;
    green = 0;
    orange = 0;
    red = 0;

    for (let i = 0; i < rdata.length; i++) {
      let gruppe = this.getRisikoGruppe(rdata[i].risikoverdi);
      switch (gruppe) {
        case 'blue':
          blue = blue + rdata[i].risikoverdiAntall;
          break;
        case 'green':
          green = green + rdata[i].risikoverdiAntall;
          break;
        case 'orange':
          orange = orange + rdata[i].risikoverdiAntall;
          break;
        case 'red':
          red = red + rdata[i].risikoverdiAntall;
          break;
        default:
          break;
      }
    }

    fdata.push({ value: blue, name: '0', color: '#abcdef' });
    fdata.push({ value: green, name: 'Lav', color: 'green' });
    fdata.push({ value: orange, name: 'Middels', color: '#FFAE42' });
    fdata.push({ value: red, name: 'Høy', color: 'red' });

    console.log(
      '%c RIENI Data ',
      'font-size: 14px; background: green; color: white',
      fdata
    );
    this.setState({ fgruppedata: fdata });
    this.setState({ loaded: true });
  };

  inRange = (x, min, max) => {
    return min <= x && x <= max;
  };

  getRisikoColor = (tall) => {
    let col = '#abcdef ';
    if (tall === 0) {
      col = '#abcdef ';
    } else if (this.inRange(tall, 1, 4)) {
      col = 'green';
    } else if (this.inRange(tall, 5, 14)) {
      col = '#FFAE42';
    } else if (this.inRange(tall, 15, 25)) {
      col = 'red';
    } else {
      col = '#abcdef ';
    }
    return col;
  };

  getRisikoGruppe = (tall) => {
    if (tall === 0) {
      return 'blue';
    } else if (this.inRange(tall, 1, 4)) {
      return 'green';
    } else if (this.inRange(tall, 5, 14)) {
      return 'orange';
    } else if (this.inRange(tall, 15, 25)) {
      return 'red';
    } else {
      return 'blue';
    }
  };

  render() {
    return (
      <div style={{ maxWidth: '1400px', width: '90%' }}>
        {this.state.loaded && (
          <table>
            <tbody>
              <tr>
                <td
                  style={{ fontWeight: 'bold', fontSize: '16px' }}
                  colspan='3'
                >
                  Bruksenhet
                </td>
                <td style={{ width: '100px' }}></td>
                <td
                  style={{ fontWeight: 'bold', fontSize: '16px' }}
                  colspan='3'
                >
                  Røykløp
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: '#abcdef',
                  }}
                >
                  0
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.dgruppedata[0].value}
                </td>
                <td style={{ width: '100px' }}></td>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: '#abcdef',
                  }}
                >
                  0
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.fgruppedata[0].value}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: 'green',
                    color: 'white',
                  }}
                >
                  Lav
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.dgruppedata[1].value}
                </td>
                <td style={{ width: '100px' }}></td>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: 'green',
                    color: 'white',
                  }}
                >
                  Lav
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.fgruppedata[1].value}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: '#FFAE42',
                  }}
                >
                  Middels
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.dgruppedata[2].value}
                </td>
                <td style={{ width: '100px' }}></td>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: '#FFAE42',
                  }}
                >
                  Middels
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.fgruppedata[2].value}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                >
                  Høy
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.dgruppedata[3].value}
                </td>
                <td style={{ width: '100px' }}></td>
                <td
                  style={{
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: 'red',
                    color: 'white',
                  }}
                >
                  Høy
                </td>
                <td style={{ width: '8px' }}></td>
                <td style={{ textAlign: 'right' }}>
                  {this.state.fgruppedata[3].value}
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <br />
        <hr style={{ border: '1px solid gray' }} />
        <br />
        <p>
          <table>
            <tbody>
              <tr>
                <td style={{ paddingBottom: '6px' }}>
                  <span>Grupper kakediagram</span>
                </td>
                <td>
                  <input
                    className='checkbox20'
                    style={{ marginLeft: '8px', marginRight: '60px' }}
                    onChange={() =>
                      this.setState({ visSomGruppe: !this.state.visSomGruppe })
                    }
                    checked={this.state.visSomGruppe}
                    type='checkbox'
                  />
                </td>
                <td style={{ width: '60px' }}></td>
                <td style={{ paddingBottom: '6px' }}>
                  <span>Vis labels</span>
                </td>
                <td>
                  {' '}
                  <input
                    className='checkbox20'
                    style={{ marginLeft: '8px' }}
                    onChange={() =>
                      this.setState({ visLabel: !this.state.visLabel })
                    }
                    checked={this.state.visLabel}
                    type='checkbox'
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </p>
        <br />
        <div className='row' ref={this.componentKakeRef}>
          <div className='col-lg-6  col-md-4 col-sm-12'>
            <label>Bruksenhet</label>
            <React.Fragment>
              <ResponsiveContainer width={'100%'} height={550}>
                <PieChart height={450}>
                  <Pie
                    label={this.state.visLabel}
                    dataKey='value'
                    nameKey='name'
                    data={
                      this.state.visSomGruppe
                        ? this.state.dgruppedata
                        : this.state.ddata
                    }
                    cx='50%'
                    cy='50%'
                    innerRadius={70}
                    outerRadius={160}
                    fill='#51a026'
                    paddingAngle={2}
                  >
                    <Label
                      value='Bruksenhet'
                      position='centerTop'
                      className='label'
                    />
                    {this.state.visSomGruppe
                      ? this.state.dgruppedata.map((entry) => (
                          <Cell
                            key={entry.name}
                            fill={entry.color}
                            text={'black'}
                          />
                        ))
                      : this.state.ddata.map((entry) => (
                          <Cell
                            key={entry.name}
                            fill={entry.color}
                            text={'black'}
                          />
                        ))}
                  </Pie>
                  <Legend layout='vertical' verticalAlign='top' align='right' />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </React.Fragment>
          </div>

          <div className='col-lg-6 col-md-4 col-sm-12'>
            <label>Røykløp</label>
            <React.Fragment>
              <ResponsiveContainer width={'100%'} height={550}>
                <PieChart height={450}>
                  <Pie
                    label={this.state.visLabel}
                    dataKey='value'
                    nameKey='name'
                    data={
                      this.state.visSomGruppe
                        ? this.state.fgruppedata
                        : this.state.fdata
                    }
                    cx='50%'
                    cy='50%'
                    innerRadius={70}
                    outerRadius={160}
                    fill='#51a026'
                    paddingAngle={2}
                  >
                    <Label
                      value='Røykløp'
                      position='centerTop'
                      className='label'
                    />
                    {this.state.visSomGruppe
                      ? this.state.fgruppedata.map((entry) => (
                          <Cell
                            key={entry.name}
                            fill={entry.color}
                            text={'black'}
                          />
                        ))
                      : this.state.fdata.map((entry) => (
                          <Cell
                            key={entry.name}
                            fill={entry.color}
                            text={'black'}
                          />
                        ))}
                  </Pie>
                  <Legend layout='vertical' verticalAlign='top' align='right' />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </React.Fragment>
          </div>
        </div>

        <button
          style={{ fontSize: '16px' }}
          className='btn btn-link'
          onClick={() =>
            exportComponentAsPNG(this.componentKakeRef, {
              fileName: this.props.filnavn,
            })
          }
        >
          Last ned kakediagram som bilde
        </button>
        <br />
        <hr style={{ border: '1px solid gray' }} />
        <br />
        <table>
          <tbody>
            <tr>
              <td style={{ paddingBottom: '6px' }}>
                <span>Horizontal diagram</span>
              </td>
              <td>
                <input
                  className='checkbox20'
                  style={{ marginLeft: '8px', marginRight: '60px' }}
                  onChange={() =>
                    this.setState({ horizontalBar: !this.state.horizontalBar })
                  }
                  checked={this.state.horizontalBar}
                  type='checkbox'
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        {this.state.horizontalBar ? (
          <div className='row' ref={this.componentRef}>
            <div className='col-lg-6  col-md-4 col-sm-12'>
              <label>Bruksenhet</label>
              <React.Fragment>
                <ResponsiveContainer width={'100%'} height={200}>
                  <BarChart
                    width={600} // Set the maximum width here
                    height={200}
                    data={this.state.dgruppedata}
                    layout='vertical' // Use 'vertical' for horizontal bars
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis type='number' />
                    <YAxis dataKey='name' type='category' />
                    <Tooltip />
                    <Bar dataKey='value' fill='#8884d8'>
                      {this.state.dgruppedata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </React.Fragment>
            </div>
            <div className='col-lg-6  col-md-4 col-sm-12'>
              <label>Røykløp</label>
              <React.Fragment>
                <ResponsiveContainer width={'100%'} height={200}>
                  <BarChart
                    width={600} // Set the maximum width here
                    height={200}
                    data={this.state.fgruppedata}
                    layout='vertical' // Use 'vertical' for horizontal bars
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis type='number' />
                    <YAxis dataKey='name' type='category' />
                    <Tooltip />
                    <Bar dataKey='value' fill='#8884d8'>
                      {this.state.fgruppedata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </React.Fragment>
            </div>
          </div>
        ) : (
          <div className='row' ref={this.componentRef}>
            <div className='col-lg-6  col-md-4 col-sm-12'>
              <label>Bruksenhet</label>
              <React.Fragment>
                <ResponsiveContainer width={'100%'} height={200}>
                  <BarChart
                    width={600} // Set the maximum width here
                    height={200}
                    data={this.state.dgruppedata}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' label='' />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey='value' fill='#8884d8'>
                      {this.state.dgruppedata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </React.Fragment>
            </div>
            <div className='col-lg-6  col-md-4 col-sm-12'>
              <label>Røykløp</label>
              <React.Fragment>
                <ResponsiveContainer width={'100%'} height={200}>
                  <BarChart
                    width={600} // Set the maximum width here
                    height={200}
                    data={this.state.fgruppedata}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' label='' />
                    <YAxis />
                    <Tooltip />

                    <Bar dataKey='value' fill='#8884d8'>
                      {this.state.fgruppedata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </React.Fragment>
            </div>
          </div>
        )}
        <button
          style={{ fontSize: '16px' }}
          className='btn btn-link'
          onClick={() =>
            exportComponentAsPNG(this.componentRef, {
              fileName: this.props.filnavn,
            })
          }
        >
          Last ned søylediagram som bilde
        </button>
      </div>
    );
  }
}

export default DonutsRisikoAnalyse;
