import { makeGetTypeKey } from 'redux/utils';
const getTypeKey = makeGetTypeKey('gjennomforing');


//INGEN I BRUK LENGER
export const ARBEIDSLISTER_FETCHED = getTypeKey('ARBEIDSLISTER_FETCHED');
export const ARBEIDSLISTE_ENHETER_FETCHED = getTypeKey('ARBEIDSLISTE_ENHETER_FETCHED');
export const ARBEIDSLISTE_SELECTED = getTypeKey('ARBEIDSLISTE_SELECTED');
export const SITUASJON_FETCHED = getTypeKey('SITUASJON_FETCHED');
export const ENHET_SELECTED = getTypeKey('ENHET_SELECTED');
export const SET_SELECTED_ARBEIDSLISTE_ENHET = getTypeKey('SET_SELECTED_ARBEIDSLISTE_ENHET');
export const ARBEIDSLISTE_UPDATED = getTypeKey('ARBEIDSLISTE_UPDATED'); 

/* export const SET_VALGTENHETID_FOR_GEBYR = getTypeKey('SET_VALGTENHETID_FOR_GEBYR');
export const SET_VALGTTYPE_FOR_GEBYR = getTypeKey('SET_VALGTYPE_FOR_GEBYR');
export const SET_VALGTADRESSE_FOR_GEBYR = getTypeKey('SET_VALGTADRESSE_FOR_GEBYR');
export const SET_VALGTEIENDOM_FOR_GEBYR = getTypeKey('SET_VALGTEIENDOM_FOR_GEBYR');
export const SET_VALGTFAGENHET_FOR_GEBYR = getTypeKey('SET_VALGTFAGENHET_FOR_GEBYR'); */

 export const BRUKERE_FETCHED = getTypeKey('BRUKERE_FETCHED');
export const USER_PERSONALIA_GJENNOMFORING_FETCHED = getTypeKey('USER_PERSONALIA_GJENNOMFORING_FETCHED');
export const ILDSTEDOPPSLAGDATA_FETCHED = getTypeKey('ILDSTEDOPPSLAGDATA_FETCHED');
export const ROYKLOPOPPSLAGDATA_FETCHED = getTypeKey('ROYKLOPOPPSLAGDATA_FETCHED');
export const ILDSTED_FETCHED = getTypeKey('ILDSTED_FETCHED');
export const ROYKLOP_FETCHED = getTypeKey('ROYKLOP_FETCHED');

export const SET_KARTSTATE_ARBEIDSLISTE_LAYER = getTypeKey('SET_KARTSTATE_ARBEIDSLISTE_LAYER');
export const SET_KARTSTATE_ARBEIDSLISTE_LOKASJON = getTypeKey('SET_KARTSTATE_ARBEIDSLISTE_LOKASJON');
export const SET_KARTSTATE_ARBEIDSLISTE_MARKOERMODUS = getTypeKey('SET_KARTSTATE_ARBEIDSLISTE_MARKOERMODUS'); 





