import React from 'react';
import * as _ from 'lodash';
import { CustomQueryBuilderSearch } from 'components/Search/QueryBuilder.js';
import { CreateDefaultQueryMedUnummererte } from 'components/Search/DefaultQuery';
import {  createSokLagret } from 'api/sokLagretApi';
import { getContextFilters, hentFiltersIgjen } from 'context/contextFilters.js';
import LagreSokDialog from 'components/Search/LagreSok.jsx';
import { getTranslations } from 'components/Search/translations';
import { getSortOptions } from 'components/Search/searchkitOptions';
import { debugFactory } from 'utils';
import { GeoAccessor } from 'components/Search/Map/GeoAccessor';
import { getGeoObjekt } from 'components/Search/GeoAccessorHelper';
import { findProperty } from 'components/Search/FindInArray';
import BruksenhetTable from 'components/Search/Bruksenhetstabell.jsx';
import { timeout } from 'components/Utils/CancellationTimer';
import Filters from 'components/Search/Filters.jsx';
import CONFIG from 'lib/CONFIG';
import { getAccessToken } from 'auth/auth';
import { reloadSearch } from 'utils/searchKitHelper';
import { getVurderte } from 'api/grunndataApi';
import {
  SearchkitManager,
  SearchkitProvider,
  Select,
  SortingSelector,
  PageSizeSelector,
  HitsStats,
  NoHits,
  Tabs,
  ResetFilters,
  GroupedSelectedFilters,
  Layout,
  LayoutBody,
  LayoutResults,
  ViewSwitcherToggle,
  ActionBar,
  ActionBarRow,
  Hits,
  SearchBox,
} from 'searchkit';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import NKIcon from 'nka-icons';
import 'react-select/dist/react-select.css';
import 'css/generated/themingSearchkit/theme.css'; // Se https://github.com/searchkit/searchkit/blob/develop/theming/vars.scss
import 'css/generated/sokModal.css';
import DialogStore from 'lib/DialogStore';
import norkartIdJs from 'auth/auth';

const getCurrentCustomerId = () => {
  let cust = norkartIdJs.getCurrentCustomerContext();
  return cust !== undefined ? cust.CustomerId : '';
};
const debug = debugFactory('nk:objekter:searchkitenhetssok');

const ES_URL = CONFIG.REACT_APP_API_ENDPOINT;
const ES_INDEX = CONFIG.REACT_APP_ES_INDEX;
const WAAPI_PROFILE = CONFIG.REACT_APP_PROFILE;
var myTimer;

if (!ES_URL || !ES_INDEX) {
  throw new Error('Missing search index settings.');
}

const host = ES_URL + 'api/search/' + ES_INDEX;

let timeOutObj = null;

const modalDialogs = {
  LagreSokDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <LagreSokDialog
      isOpen={isOpen}
      onClose={onClose}
      onLagreSok={owner.onLagreSok}
    />
  ),
};
class searchkitEnhetssok extends React.Component {
  constructor() {
    super();
    this.state = {
      test: false,
      geoObjekt: [],
      organisasjonLokasjon: {},
      showSidebar: true,
      accessorState: [],
      sokLagrede: [],
      items: [],
      simpleSearch: false,
      showSidebarArrow: true,
      selectedRowId: null,
      vurderte: [],
      antallroyklopfunnet: '',
      filters: [],
      standardFilter: null,
      standardFilterId: 0,
      filtersInit: false,
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    
    this.userShowSidebar = false;
  }

  componentDidMount= () => {
    getContextFilters(this.onGotFilters);
  }

  onGotFilters = (contextFilters) => {
      this.setState(
      {
        filtersInit: true,
        filters: contextFilters.filters,
        standardFilterId: contextFilters.standardFilterId,
        standardFilter: contextFilters.standardFilter,
      },
      this.startSearchKit(contextFilters.standardFilter)
    ); 
  };

  startSearchKit = (standardFilter) => {
    this.searchkit = new SearchkitManager(host, {
      httpHeaders: {
        Authorization: getAccessToken(),
        'X-WAAPI-Profile': WAAPI_PROFILE,
        'X-WAAPI-CONTEXT': getCurrentCustomerId()
      },
      useHistory: false,
      searchOnLoad: true,
    });
    this.searchkit.translateFunction = getTranslations;

    this.searchkit.addDefaultQuery((query) => {
      return CreateDefaultQueryMedUnummererte(query, this.props.kommuner);
    });

    this.searchkit.addResultsListener((results) => {
      // this.formatResultTilGeo(results);
      this.formatResult(results);
      // this.getAntallRoyklopFraResults1(results);
    });
    getVurderte((err, result) => {
      this.setState({ vurderte: result });
    });

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

    //Til slutt ønsket vi ikke en standard søk her...
    reloadSearch(this.searchkit);
 /*   setTimeout(() => {
    this.brukLagretSok(standardFilter);
  }, 1000); */
    
  };

  getAntallRoyklopFraResults1 = (results) => {
    clearInterval(myTimer);
    if (this.state.antallroyklopfunnet !== '') {
      this.setState({ antallroyklopfunnet: '' });
    }
    myTimer = setInterval(
      this.getAntallRoyklopFraResults2.bind(null, results),
      2000
    );
  };

  getAntallRoyklopFraResults2 = (results) => {
    clearInterval(myTimer);
    if (results.aggregations) {
      if (results.aggregations.rehabilitert33) {
        if (results.aggregations.rehabilitert33.inner) {
          if (results.aggregations.rehabilitert33.inner.doc_count) {
            this.setState({
              antallroyklopfunnet:
                'Røykløp: ' +
                results.aggregations.rehabilitert33.inner.doc_count,
            });
          }
        }
      }
    }
  };

  componentDidUpdate= (prevProps, prevState, snapshot) => {
    if (prevState.simpleSearch && !this.state.simpleSearch) {
      reloadSearch(this.searchkit);
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions= () => {
    if (this.userShowSidebar) {
      return;
    } //Bruker har overstyrt synlighet av
    this.setState({
      showSidebar: window.matchMedia('(min-width: 1560px)').matches,
    });
    let min = window.matchMedia('(min-width: 1024px)').matches;

    this.setState({
      showSidebarArrow: this.props.isMapCollapsed,
  //    isMapCollapsed: !min,
      isMapCollapsed: this.props.isMapCollapsed
    });
  }

  formatResult= (results) => {
    var items = _.map(results.hits.hits, function(item) {
      return {
        id: item._source.id,
        source: item._source,
        innerHits: item.inner_hits,
      };
    });

    this.setState({ items: items });
    if (timeOutObj) {
      timeOutObj.cancel();
    }

    timeOutObj = timeout(1500);

    timeOutObj.promise.then(
      function(result) {
        let firstItem = null;
        if (items.length > 0) {
          firstItem = _.first(items);
        }
        this.setState({
          selectedRowId: firstItem ? firstItem.id : null,
          bygningsnummer: firstItem
            ? firstItem.source.bygg.bygningsnummer
            : null,
        });
      }.bind(this)
    );
  }

  formatResultTilGeo = (results) => {
    if (results.aggregations !== undefined) {
      if (results.aggregations.geo !== undefined) {
        let buckets = results.aggregations.geo.inner.areas.buckets;
        let total = results.aggregations.geo.doc_count;
        let geoObjekt = {
          total: total,
          bucketsCount: buckets.length,
          buckets: buckets,
        };
        this.setState({ geoObjekt: geoObjekt });
      }
    }
  }

  onGotOrganisasjonsLokasjon = (err, data) => {
    debug('onGotOrganisasjonsLokasjon', data);
    this.organisasjonLokasjon = data;
  }

  visLagreSokDialog= () => {
    this.dialogs.setOpenDialog('LagreSokDialog');
  }

  brukLagretSok = (item) => {
    if (!item){
      return;
    }
    this.searchkit.getQueryAccessor().keepOnlyQueryState();
    this.searchkit.accessors.setState(JSON.parse(item.state));
    let geoAccessor = this.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });

    if (item.polygon && geoAccessor) {
      let parsedPolygon = JSON.parse(item.polygon);
      let poly = getGeoObjekt(parsedPolygon.points);
      geoAccessor.setPolygon(null);
      geoAccessor.setPolygon(poly);
    } else if (!item.polygon && geoAccessor) {
      geoAccessor.setPolygon(null);
    }
    reloadSearch(this.searchkit);
  }

  onLagreSok = (soklagre) => {
    soklagre.state = JSON.stringify(this.searchkit.accessors.getState());
    let polygon = findProperty(this.searchkit.query.query.query, 'points');
    polygon = _.last(polygon);
    soklagre.polygon = JSON.stringify(polygon);

    //Call API
    this.dialogs.closeDialog();
    debug('LSLS skal lagres', soklagre);
    createSokLagret(soklagre, this.onSokLagret);
  }

  onSokLagret = (err, item) => {
   hentFiltersIgjen();
  }

  onSelectedPerson = () => {
    reloadSearch(this.searchkit);
  }

  bruksenhetstabell = () => {
    return (
      <BruksenhetTable
        searchkit={this.searchkit}
        isRowsSelectable={false}
        onRowClick={(rowId, bygningsNr) => {
          this.setState({ selectedRowId: rowId, bygningsnummer: bygningsNr });
        }}
        enheter={this.state.items}
        vurderte={this.state.vurderte}
        geoObjekt={this.state.geoObjekt}
        fraFiktiv={this.props.fraFiktiv}
        FIXME={this.organisasjonLokasjon}
        selectedRows={this.handleSelectEnheter}
        isMapCollapsed={this.state.isMapCollapsed}
        kobleTilReelEnhet={this.props.kobleTilReelEnhet}
        reloadSearch={() => reloadSearch(this.props.searchkit)}
        // antallroyklopfunnet= {this.state.antallroyklopfunnet}
        mapWasCollapsed={() => {
          this.userShowSidebar = true;
        }}
      />
    );
  }

  render = () => {
    if (!this.searchkit) {
      return <div>Lader søk...</div>;
    }
    if (!this.state.filtersInit) {
      return <div>Lader filter...</div>;
    }
    return (
      <div id='enhetssok'>
        <SearchkitProvider searchkit={this.searchkit}>
          <div className='row rowSok'>
            <div
              className={
                (this.state.showSidebar ? 'col-md-10' : 'col-md-12') +
                ' content-wrapper'
              }
              id='content'>
              <Layout>
                <LayoutBody>
                  {this.state.simpleSearch ? (
                    <div />
                  ) : (
                    <Filters
                      sokLagrede={this.state.sokLagrede}
                      brukLagretSok={this.brukLagretSok}
                      visLagreSokDialog={this.visLagreSokDialog}
                      onSelectedPerson={this.onSelectedPerson}
                    />
                  )}

                  <LayoutResults className='fixedresults'>
                    <ActionBar>
                      <ActionBarRow>
                        <div style={{ width: '100%' }} className='row'>
                          <div
                            className='col-md-1'
                            style={{ paddingLeft: '0px' }}>
                            <button
                              className='btn btn-default'
                              onClick={() =>
                                this.setState({
                                  simpleSearch: !this.state.simpleSearch,
                                })
                              }>
                              {this.state.simpleSearch ? 'Avansert' : 'Enkel'}
                            </button>
                          </div>

                          <div
                            className='col-md-4'
                            style={{ paddingLeft: '0px' }}>
                            <SearchBox
                              queryBuilder={CustomQueryBuilderSearch}
                              searchThrottleTime={400}
                              searchOnChange={true}
                              translations={{
                                'searchbox.placeholder':
                                  'Adressesøk: Storgata 1',
                              }}
                            />
                          </div>
                          {/*    <div
                            className='col-md-3'
                            style={{ paddingLeft: '0px' }}>
                            <PersonSok onSelected={this.onSelectedPerson} />
                          </div>  */}

                          {this.state.showSidebarArrow ? (
                            <span
                              className='hideButton pull-right'
                              onClick={() => {
                                this.setState({
                                  showSidebar: !this.state.showSidebar,
                                });
                                this.userShowSidebar = true;
                              }}>
                              {this.state.showSidebar ? (
                                <NKIcon
                                  icon='hoyrepil'
                                  size='1'
                                  color='nk-black'
                                />
                              ) : (
                                <NKIcon
                                  icon='venstrepil'
                                  size='1'
                                  color='nk-black'
                                />
                              )}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <GroupedSelectedFilters
                          translations={{
                            true: 'Ja',
                            false: 'Nei',
                          }}
                        />
                      </ActionBarRow>
                      <ActionBarRow>
                        <HitsStats />

                        <ResetFilters
                          translations={{
                            'reset.clear_all': 'Nullstill filtre',
                          }}
                        />

                        <SortingSelector options={getSortOptions()} />

                        <PageSizeSelector
                          className='pull-left'
                          options={[10, 50, 100, 250, 500, 1000, 10000]}
                          listComponent={Select}
                        />
                      </ActionBarRow>
                    </ActionBar>

                    <div className='searchkit-view-switcher'>
                      <ViewSwitcherToggle listComponent={Tabs} />
                      {/*   <NoHits suggestionsField='adresse.gatenavn' /> */}
                      <Hits
                        hitsPerPage={250}
                        sourceFilter={[
                          'id',
                          'fromMatrikkel',
                          'adresse.id',
                          'adresse.gatenavn',
                          'adresse.husnummer',
                          'adresse.bokstav',
                          'adresse.type',
                          'bygg.lokasjon',
                          'bygg.bygningsnummer',
                          'matrikkelenhet.id',
                          'matrikkelenhet.gardsnummer',
                          'matrikkelenhet.bruksnummer',
                          'matrikkelenhet.festenummer',
                          'matrikkelenhet.seksjonsnummer',
                          'matrikkelenhet.bruksnavn',
                          'skybrannData.bruksenhet.risikovurdering',
                          'skybrannData.bruksenhet.risikoverdiOverstyr',
                          'skybrannData.bruksenhet.fagId',
                          'skybrannData.bruksenhet.fyringsforbud',
                          'lokasjon',
                          'etasjeplankode',
                          'lopenummer',
                          'etasjenummer',
                          'bruksenhetstype',
                          'kommune.kommuneNavn',
                          'skybrannData.royklop.plassering',
                          'skybrannData.royklop.feieadkomst',
                          'skybrannData.royklop.id',
                          'skybrannData.royklop.type',
                          'skybrannData.royklop.risikoverdi',
                          'skybrannData.royklop.risikoverdiOverstyr',
                          'skybrannData.royklopTiltakType.nesteDato',
                          'skybrannData.royklopTiltakType.tiltakType',
                          'skybrannData.bruksenhetTiltakType.nesteDato',
                          'skybrannData.bruksenhetTiltakType.tiltakType',
                        ]}
                        listComponent={this.bruksenhetstabell}
                        scrollTo='body'
                      />
                    </div>
                  </LayoutResults>
                </LayoutBody>
              </Layout>
            </div>

            <div
              className={
                (this.state.showSidebar ? 'col-md-2' : 'collapse') +
                ' matrikkelinfo'
              }
              id='sidebar'>
              <h4>Matrikkelinfo</h4>
              <div className='scrollable'>
                {this.state.showSidebar && (
                  <Matrikkelinfo
                    id={
                      this.state.selectedRowId ? this.state.selectedRowId : null
                    }
                    bygningsNr={this.state.bygningsnummer}
                  />
                )}
              </div>
            </div>
          </div>
        </SearchkitProvider>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default searchkitEnhetssok;
