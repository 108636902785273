
//NB: Bruk OK-dialog i stedet. JaNei er i bruk noen få steder, men det er bestemt at OK-dialog skal brukes
import React from 'react';
import NKModal from './NKModal.jsx';
import { PropTypes } from 'prop-types';

class JaNeiDialog extends React.Component {
  static propTypes = {
    melding: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onJa: PropTypes.func.isRequired,
    onNei: PropTypes.func.isRequired
  };

  state = { isOpen: true };

  closeModal = () => {
    this.props.isOpen = false;
  };

  render() {
    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header modal-header-info">
            <h4 className="modal-title">{this.props.tittel}</h4>
          </div>
          <div className="modal-body">
            <br />
            <p style={{ margin: '4px' }}>{this.props.melding}</p>
            <p style={{ margin: '4px' }}>{this.props.melding2}</p>
            <p style={{ margin: '4px' }}>{this.props.melding3}</p>
          </div>
          <div className="modal-footer">
            <button
              style={{ margin: '2px', width: '50px', height: '40px' }}
              className="btn btn-primary pull-right"
              onClick={this.props.onJa}>
              Ja
            </button>
            <button
              style={{ margin: '2px', width: '50px', height: '40px' }}
              className="btn btn-default pull-right"
              onClick={this.props.onNei}>
              Nei
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default JaNeiDialog;
