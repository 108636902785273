/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import AnmerkningListe from '../avvikAnmerkning/AnmerkningListe.jsx';
import AvvikListe from '../avvikAnmerkning/AvvikListe.jsx';
import 'components/Dialogs/NKFullModal.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NKIcon from 'nka-icons';
import EndreIldsted from './EndreIldsted.jsx';
import ildstedApi from 'api/ildstedApi';
import '../gjennomforing.css';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import FlyttIldstedDialog from './FlyttIldstedDialog.jsx';
import DialogStore from 'lib/DialogStore';
import IldIkkeIBruk from 'images/IkkeiBrukIld.png';
import royklopApi from 'api/royklopApi';
import { getContextIldstedOppslag } from 'context/contextOppslag.js';

var tiltaktyper = [];

const modalDialogs = {
  EndreIldsted: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <EndreIldsted
          onClose={onClose}
          ildstedId={dialogData.ildstedId}
          nyendre='endre'
          onEndreIldsted={owner.endreIldsted}
          royklop={dialogData.royklop}
        />
      </NKModal>
    );
  },
  AnmerkningListe: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <AnmerkningListe
          onClose={onClose}
          tittel='Ildsted'
          emne='Ildsted'
          adresse={dialogData.adresse}
          identi={dialogData.identi}
          bruksenhetid={dialogData.bruksenhetid}
          objektReferanseType='I'
          objektReferanseId={dialogData.objektReferanseId}
          ildstedid={dialogData.ildstedid}
          info={dialogData.identi}
        />
      </NKModal>
    );
  },
  AvvikListe: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <AvvikListe
          onClose={onClose}
          tittel='Ildsted'
          emne='Ildsted'
          adresse={dialogData.adresse}
          identi={dialogData.identi}
          bruksenhetid={dialogData.bruksenhetid}
          objektReferanseType='I'
          objektReferanseId={dialogData.objektReferanseId}
          ildstedid={dialogData.ildstedid}
          info={dialogData.identi}
        />
      </NKModal>
    );
  },
  FlyttIldstedDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        size='medium'
        tittel='Velg røykløpet som ildstedet skal flyttes til'
        isOpen={isOpen}
        onClose={onClose}>
        <FlyttIldstedDialog
          selected={() => onClose}
          ildsted={dialogData.ildsted}
          oppdaterSituasjon={owner.oppdater}
          royklop={dialogData.royklop}
        />
      </ModalContainer>
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Slett ildsted'
        melding='Du sletter nå valgt ildsted. Vil du fortsette?'
        onOk={owner.closeSlettDialogOk}
      />
    );
  },
  OkDialogRegelTiltakSjekk: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Regelmessige tiltak'
        melding='Tilknyttet røykløp har regelmessige tiltak som vil bli slettet hvis du fullfører endringen. Ønsker du å fortsette?'
        onOk={owner.closeRegelmessigeTiltakDialogOk}
      />
    );
  },
};

class IldstedSituasjon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typevisning: this.props.ildsted.modell,
      plassering: this.props.ildsted.plassering,
      ildsted: this.props.ildsted,
      slettType: '',
      ibruk: this.props.ildsted.ibruk,
      plasseringer: [],
      produsenter: [],
      modeller: [],
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    getContextIldstedOppslag(this.gotIldstedTypeData);
  };

  gotIldstedTypeData = (data) => {
    if (data) {
      this.setState({
        plasseringer: data.plasseringListe,
        produsenter: data.produsentListe,
        modeller: data.modellListe,
      });
    }
  };

  openEndre = () => {
    this.dialogs.setOpenDialog('EndreIldsted', {
      ildstedId: this.state.ildsted.id,
      royklop: this.props.royklop,
    });
  };

  openAnmerkninger = () => {
    let identi = this.state.plassering + ' ' + this.state.typevisning;
    this.dialogs.setOpenDialog('AnmerkningListe', {
      adresse: this.props.adresse,
      identi: identi,
      bruksenhetid: this.state.ildsted.bruksenhetId,
      objektReferanseId: this.state.ildsted.id,
      ildstedid: this.state.ildsted.id,
    });
  };

  openAvvik = () => {
    let identi = this.state.plassering + ' ' + this.state.typevisning;
    this.dialogs.setOpenDialog('AvvikListe', {
      adresse: this.props.adresse,
      identi: identi,
      bruksenhetid: this.state.ildsted.bruksenhetId,
      objektReferanseId: this.state.ildsted.id,
      ildstedid: this.state.ildsted.id,
    });
  };

  endreIldsted = (oppdatertIldsted, valgttype) => {
    //console.log(this.props.royklop);
    //var sjekk = this.sjekkIldsteder();
    //console.log(sjekk, ibrukEndret);
    this.setState({ type: valgttype });
    // Hvis 1 bruk, så er det den det jobbes på
    // if (sjekk == 1 && ibrukEndret) {
    //   this.dialogs.setOpenDialog('OkDialogRegelTiltakSjekk');
    // }

    ildstedApi.updateIldsted(
      oppdatertIldsted.id,
      oppdatertIldsted,
      this.ildstedOppdatert
    );
    this.dialogs.closeDialog();
  };

  oppdater = () => {
    this.props.oppdater();
  };

  ildstedOppdatert = (err, ildsted) => {
    this.setState({
      typevisning: this.getModell(ildsted.modellId),
      plassering: this.getPlassering(ildsted.plasseringId),
      ibruk: ildsted.ibruk,
    });
    this.props.oppdater();
  };

  getPlassering = (plasseringId) => {
    let plasseringer = this.state.plasseringer;
    for (let i = 0; i < plasseringer.length; i++) {
      let row = plasseringer[i];
      if (row.id === plasseringId) {
        return row.tekst;
      }
    }
    return '';
  };

  getModell = (modellId) => {
    let modeller = this.state.modeller;
    for (let i = 0; i < modeller.length; i++) {
      let row = modeller[i];
      if (row.id === modellId) {
        return row.tekst;
      }
    }
    return '';
  };

  onSlettClick = () => {
    let res = this.sjekkIldsteder();
    if (res === 1 || res === 0) {
      // Ingen andre ildsteder i bruk, bare dette, derfor sjekk på 1
      if (this.props.royklop.antallBruksenheter < 2) {
        // Bare 1 eller ingen ildsted
        royklopApi.getRoyklopTiltakType(
          this.props.royklop.id,
          this.tiltakSjekket
        );
      } else {
        this.dialogs.setOpenDialog('OkDialog');
      }
    } else {
      this.dialogs.setOpenDialog('OkDialog');
    }
  };

  tiltakSjekket = (err, res) => {
    if (res.length > 0) {
      tiltaktyper = res;
      this.dialogs.setOpenDialog('OkDialogRegelTiltakSjekk');
    } else {
      this.dialogs.setOpenDialog('OkDialog');
    }
  };

  closeRegelmessigeTiltakDialogOk = () => {
    tiltaktyper.forEach((element) => {
      royklopApi.slettRoyklopTiltakType(element.id, this.slettet);
    });
    this.closeSlettDialogOk();
  };

  slettet = (err, res) => {};

  sjekkIldsteder = () => {
    let result = 0;
    let r = this.props.royklop;
    r.ildstedListe.forEach((i) => {
      if (i.ibruk) {
        result = result + 1;
      }
    });
    return result;
  };

  closeSlettDialogOk = () => {
    ildstedApi.deleteIldsted(this.props.ildsted.id, this.ildstedSlettet);
  };

  ildstedSlettet = (err, ildsted) => {
    this.props.oppdater();
    this.dialogs.closeDialog();
  };

  formatEgenskaper = (plass, type) => {
    let p = String(plass);
    if (p === 'null') {
      p = '';
    }
    let t = String(type);
    if (t === 'null') {
      t = '';
    }
    return (p + ' ' + t).trimLeft();
  };

  render() {
    /*     info =
      this.state.typevisning !== null
        ? this.state.typevisning
        : ''  + this.state.plassering !== null
        ? this.state.plassering
        : ''; */
    var ildsted = this.state.ildsted;
    var index = '#ildsted' + ildsted.id;
    var id = 'ildsted' + ildsted.id;
    let btnDivStyle = { width: '100%', paddingLeft: '30px' };
    let btnStyle = {
      width: 'calc(100% - 54px)',
      background: '#d9d9d9',
      borderColor: '#999999',
      paddingRight: '0px',
      paddingLeft: '4px',
      maxHeight: '42px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    };
    let ellipsedStyle = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 100px)',
      textAlign: 'left',
      display: 'inline-block',
    };
    let propilStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      width: '60px',
      height: '40px',
      lineHeight: '40px',
      float: 'right',
    };
    let propilStyleAfter = {
      float: 'right',
      marginTop: '10px',
      marginRight: '4px',
    };
    let menuDivStyle = {
      textAlign: 'center',
      verticalAlign: 'bottom',
      width: '40px',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      position: 'relative'
    };
    let menuButtonStyle = {
      border: 'none',
      background: 'transparent',
    };
    let sitKnappStyle = {
      float: 'left',
      marginLeft: '10px',
      marginTop: '4px',
    };
    return (
      <div key={ildsted.id}>
        <div>
          {ildsted.ingenIldsted ? (
            <div>
              <span style={{ marginLeft: '30px', marginTop: '5px' }}>
                Ingen ildsted
              </span>
            </div>
          ) : (
            <div style={btnDivStyle}>
              {/*Button*/}
              <div
                style={btnStyle}
                className='container-fluid btn btn-default sbpilpanel collapsed'
                href={index}
                data-toggle='collapse'>
                <span>
                  {this.state.ibruk ? (
                    <div>
                      <NKIcon
                        icon='ildsted'
                        size='0.8'
                        style={{
                          marginRight: '10px',
                          marginTop: '10px',
                          float: 'left',
                          display: 'inline-block',
                        }}
                        color='nk-black'
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        height='19'
                        width='19'
                        src={IldIkkeIBruk}
                        alt='Ikke i bruk'
                        style={{
                          marginRight: '10px',
                          marginTop: '10px',
                          float: 'left',
                          display: 'inline-block',
                        }}
                      />
                    </div>
                  )}
                </span>
                <h4 style={ellipsedStyle}>
                  {this.formatEgenskaper(
                    this.state.plassering,
                    this.state.typevisning
                  )}
                </h4>

                <div style={propilStyle}>
                  <span
                    style={propilStyleAfter}
                    className='chevron fa fa-fw fa-lg'
                  />
                </div>
              </div>
              {/* menu */}
              <div style={menuDivStyle}>  {/*  className='dropdown'> */}
                <button
                  style={menuButtonStyle}
                  className='dropdown-toggle'
                  data-toggle='dropdown'>
                  <span>
                    <NKIcon icon='meny' size='1.5' color='nk-black' />
                  </span>
                </button>
                <ul style={{ float: 'right' }} className='dropdown-menu'>
                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.dialogs.setOpenDialog('FlyttIldstedDialog', {
                          ildsted: this.props.ildsted,
                          royklop: this.props.royklop,
                        })
                      }>
                      Flytt ildsted
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={this.onSlettClick}>
                      Slett ildsted
                    </a>
                  </li>
                </ul>
              </div>
              {/* panel */}
              <div
                id={id}
                className='collapse'
                style={{ width: '84%', paddingLeft: '30px' }}>
                <br />
                <div className='row'>
                  <div className='col-sm-6 col-xs-12 col-lg-3'>
                    <button
                      className='btn btn-default'
                      style={{
                        height: '40px',
                        width: '100%',
                        marginBottom: '4px',
                      }}
                      onClick={this.openEndre}>
                      <span style={{ float: 'left' }}>
                        <NKIcon
                          style={{ float: 'left' }}
                          icon='rediger'
                          color='nk-black'
                        />
                      </span>
                      <span style={sitKnappStyle}>Endre</span>
                    </button>
                  </div>
                  <div
                    className='col-sm-6 col-xs-12 col-lg-3'
                    style={{ marginBottom: '4px' }}>
                    <button
                      title='Anmerkninger & andre forhold'
                      className='btn btn-default'
                      style={{ height: '40px', width: '100%' }}
                      onClick={this.openAnmerkninger}>
                      <span style={{ float: 'left' }}>
                        <NKIcon
                          style={{ float: 'left' }}
                          icon='anmerkninger'
                          color='nk-black'
                        />
                      </span>
                      <span className='knappEllipsis'>
                        &nbsp;&nbsp;Anmerkninger & andre forhold
                      </span>
                    </button>
                  </div>
                  <div
                    className='col-sm-6 col-xs-12 col-lg-3'
                    style={{ marginBottom: '4px' }}>
                    <button
                      className='btn btn-default'
                      style={{ height: '40px', width: '100%' }}
                      onClick={this.openAvvik}>
                      <span style={{ float: 'left' }}>
                        <NKIcon
                          style={{ float: 'left' }}
                          icon='risiko'
                          color='nk-black'
                        />
                      </span>
                      <span style={sitKnappStyle}>Avvik</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <p />

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
export default IldstedSituasjon;
