import callApi from './api';

export function getSmsMaler(callback) {
  callApi('GET', '/malsms/', null,  callback);
};

export function getSmsMalById(id, callback) {
  callApi('GET', '/malsms/' + id, null,  callback);
};

export function oppdaterSmsMal(id, mal, callback) {
  callApi('PUT', '/MalSms/' + id, mal, callback);
};

export function slettSmsMal(id, callback) {
  callApi('DELETE', '/MalSms/' + id, null, callback);
};

export function getSmsKanselleringMal(callback) {
  callApi('GET', '/malsms/malsms/malkansellering/', null, callback);  
};

//EKSEMPEL SMS **************

export function oppdaterSmsEksempelMal(id, mal, callback) {
  callApi('PUT', '/MalSms/eksempel/' + id, mal, callback);  
};

export function getSmsEksempelMalByFunksjon(funksjonid, callback) {
  callApi('GET', '/malsms/eksempel/funksjon/' + funksjonid, null,  callback);
};

export function getAlleSmsEksempelMal(callback) {
  callApi('GET', '/malsms/eksempel/alle/', null,  callback);
};

export function LastnedalleEksempelmalerSms( callback) {
  callApi('GET', '/malsms/eksempel/opprettalle/', null, callback);
};

//Hvor er DELETE og POST??

////////////////////////////////////////////////////////////////////////////////////////

export function getMaler(gjelder, callback) {
  callApi('GET', '/mal/maler/' + gjelder, null, callback);
};

export function getLukkAvvikMaler(tiltaktypeid, callback) {
  callApi('GET', '/mal/maler/lukkavvik/' + tiltaktypeid, null, callback);
};

export function getEksempelMaler( callback) {
  callApi('GET', '/mal/eksempel/alle/', null, callback);
};

export function getPurringMalerForFunksjon(funksjonId, callback) {
  callApi('GET', '/mal/maler/purring/' + funksjonId, null, callback);
};


export function getTopptekst(callback) {
  callApi('GET', '/mal/topptekst/', null,  callback);
};

export function oppdaterTopptekst(topptekst, callback) {
  callApi('POST', '/mal/topptekst/createorupdate', topptekst,  callback);
};

export function getMalById(id, callback) {
  callApi('GET', '/mal/' + id, null, callback);
};

export function nyMal(mal, callback) {
  callApi('POST', '/mal/', mal, callback);
};

   

export function nySmsMal(mal, callback) {
  callApi('POST', '/malsms/', mal, callback);
};


export function oppdaterMal(id, mal, callback) {
  callApi('PUT', '/Mal/' + id, mal, callback);
};

export function getMalerOppslagsdata(gjelder, type, callback) {
  callApi('GET', '/mal/nyendre/' + gjelder +'/' + type, null, callback);
};

export function slettMal(id, callback) {
  callApi('DELETE', '/Mal/' + id, null, callback);
};

export function getHurtigdeler( callback) {
  callApi('GET', '/malhurtigdeler/', null, callback);
};

export function nyHurtigdel(hurtigdel, callback) {
  callApi('POST', '/malhurtigdeler/', hurtigdel, callback);
};

export function oppdaterHurtigdel(id, hurtigdel, callback) {
  callApi('PUT', '/malhurtigdeler/' + id, hurtigdel,  callback);
};

export function slettHurtigdel(id, callback) {
  callApi('DELETE', '/malhurtigdeler/'+ id, null,  callback);
};


//EKSEMPEL **************

export function LastnedalleEksempelmaler( callback) {
  callApi('GET', '/mal/eksempel/opprettalle/', null, callback);
};

export function oppdaterEksempelMal(id, mal, callback) {  
  callApi('PUT', '/mal/eksempel/' + id, mal, callback);
};

export function nyEksempelMal(mal, callback) {
  callApi('POST', '/mal/eksempel/', mal, callback);
};

export function GetAntallMaler( callback) {
  callApi('GET', '/mal/antall/', null, callback);
};






