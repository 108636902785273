import React, { useEffect } from 'react';
import NKCollapsible from 'nka-collapsible';
import 'nka-collapsible/dist/style.css';
import NKAInput from 'nka-input';
import 'nka-input/dist/style.css';
import './test.css';

function TestComponent1({ user, tittel, sted, handleStedChange }) {
  //annerledes enn this.props

  useEffect(() => {
    console.log(
      'XXXX ______________useEffect__TestComponent___________________'
    );
  });

  return (
    <div>
      <NKCollapsible
       style={{ height: '40px' }}
        title='Her er TestComponent1'
        icon='kart'
        color='nk-black'
        defaultCollapsed={true}>
        <div>
          <label>{tittel}</label>
          <br />
          <NKAInput
            style={{ width: '200px' }}
            type='text'
            onChange={(evt) => handleStedChange('NoeExtra', evt)}
            value={sted}
            name='stedInput'
            placeholder='Skriv et sted'
            theme='nk-black'
          />
        </div>
      </NKCollapsible>
      <br/>
      <span>{`Hei ${user} fra useContext`}</span>
    </div>
  );
}

export default TestComponent1;
