import React from 'react';
import ReactModal from 'react-modal';
import { compose, withRouter } from 'lib/enhancers';
import 'components/Dialogs/NKFullModal.css';
/* import ModalContainer from 'components/Dialogs/ModalContainer'; */
import NKModal from 'components/Dialogs/NKModal.jsx';
import enhetApi from 'api/enhetApi.js';
import ArbeidslisteFritekst from 'modules/felles/ArbeidslisteFritekst';
/* import FlyttTiltak from 'modules/felles/FlyttTiltak'; */
import EnheterKart from './EnheterKart.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory, getDerivedChanges } from 'utils';
import { huskBreadcrumb } from 'components/Breadcrumbs.jsx';
const debug = debugFactory('nk:gjennomforing:enheterkart');



class EnheterKartView extends React.Component {
  state = {
    aktualEnhet: null,
    enheter: [],
    valgtEnhet: null
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedChanges(['enheter'], nextProps, prevState, debug);
  }
  constructor() {
    super();
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  componentDidMount() {
    //For å unngå en warning:
    //Warning: react-modal: App element is not defined.
    ReactModal.setAppElement('body');
    //  this.listenOrientation();
    this.hentData();
  }

  listenOrientation = () => {
    // Listen for orientation changes
    window.addEventListener(
      'orientationchange',
      () => {
        if (window.innerHeight > window.innerWidth) {
          // alert('horizontal');
          this.setState({ klasse: 'kartgjennomfoeringH' });
        } else {
          // alert('vertikal');
          this.setState({ klasse: 'kartgjennomfoeringV' });
        }
      },
      false
    );
  };

  hentData = () => {
    this.props.visKart();
  };

  refreshArbeidsliste = () => {
    this.hentData();
  };

  gotBesokMerknad= () => {
    console.log('melding: ' + this.state.valgtEnhet.id);
    enhetApi.getBesokMerknad(this.state.valgtEnhet.id, this.visDialog);
  };

  visDialog= (err, merknad) => {
    this.dialogs.setOpenDialog('InfoDialog', { bruksEnhetMerknad: merknad.tekst });
  };

  lagreBesokMerknad = merknad => {  
    enhetApi.saveBesokMerknad(
      this.state.valgtEnhet.id,
      merknad,
      this.merknadLagret
    );
  };

  merknadLagret = () => {
    this.dialogs.closeDialog();
    this.refreshArbeidsliste();
  };

/*   flyttTiltak = row => {
    this.dialogs.setOpenDialog('FlyttDialog', { enhet: row, arbeidslisteId: this.props.arbeidslisteId });
  }; */

  onRowClick = item => {
    this.props.router.push({
      pathname:
        this.props.base_path +
        '/' +
        this.props.arbeidslistenavn +
        '/' +
        item.id,
      state: {
        adresse: item.adresse,
        eiendom: item.eiendom,
        reellBruksenhet: item.FromMatrikkel,
        matrikkelenhetId: item.matrikkelenhetId,
        bruksenhetIdMa: item.bruksenhetIdMa
      }
    });
    //this.props.router.push(this.props.base_path +'/' + this.props.arbeidslistenavn +'/' + item.id);

       //============= REACT CONTEXT BREADCRUMBS ====================
       huskBreadcrumb('gjennomforing_arbeidsliste_navn', this.props.arbeidslisteNavn);  
       huskBreadcrumb('gjennomforing_arbeidsliste_adresse', item.adresse);
  };

/*   onFlyttTiltakCompleted = arbeidslisteIdTil => {
    this.dialogs.closeDialog();
    this.setState({ enheter: [] });
    enhetApi.getArblisteBrukenheterGjennomforing(
      this.props.arbeidslisteId,
      this.hentData()
    );
  }; */

  setEnhet= (enhet) => {
    this.setState({ valgtEnhet: enhet });  
  };

  render() {
    return (
      <div>
        <EnheterKart
          data={this.props.arbeidslisteId}
          enheter={this.props.enheter}
          visning="gjennomføring-kart"
          lesMelding={this.gotBesokMerknad}
          tilSituasjon={this.onRowClick}
          /* flyttTiltak={this.flyttTiltak} */
          tilbake={this.props.tilbake}
          setEnhet={this.setEnhet}
          arbeidslisteId={this.props.arbeidslisteId}
          refresh={this.props.refresh}
          timeTikker={this.props.timeTikker}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
const modalDialogs = {
  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" isOpen={isOpen} onClose={onClose}>
      <ArbeidslisteFritekst
        onClose={onClose}
        merknad={dialogData.bruksEnhetMerknad}
        lagreBesokMerknad={owner.lagreBesokMerknad}
      />
    </NKModal>
  )
/*   FlyttDialog: ({ isOpen, onClose, dialogData, owner }) => ( 
    <ModalContainer
      size="medium"
      isOpen={isOpen}
      onClose={onClose}
      buttonText="Flytt"
      onSubmit={() => {
        owner.refs.flyttTiltak.submit( 
          dialogData.enhet.id,
          dialogData.arbeidslisteId,
          owner.onFlyttTiltakCompleted
        );
      }}
      tittel={'Flytt fra arbeidsliste ' + owner.props.arbeidslistenavn}>
      <FlyttTiltak
        ref="flyttTiltak"
        erVarslet={
          dialogData.enhet
            ? dialogData.enhet.planlagtDato
            : false
        }
      />
    </ModalContainer>
  ) */
};
export default compose(withRouter)(EnheterKartView);
