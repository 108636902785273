import React from 'react';
import * as _ from 'lodash';
import { getContextBrukere } from 'context/contextOppslag.js';
import { flyttTiltakBruksenhet2 } from 'api/enhetApi';
import { getArbeidslisteByAktiv } from 'api/arbeidslisteApi';
import NKIcon from 'nka-icons';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:felles:FlyttTiltak');

let statusIdEnum = Object.freeze({
  BlankTekst: 1,
  Varslet: 2,
  VarsletUtropstegn: 3,
  Ok: 4,
  OkManuell: 5,
  MobilNrIkkeFunnet: 6
});

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Kan ikke flytte tiltak"
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  )
};
class FlyttTiltak extends React.Component {
  constructor() {
    super();
    this.state = {
      personer: [],
      defaultAnsvarlig: null,
      ansvarlig: null,
      arbeidslister: [],
      filtrerteArbeidslister: null,
      error: null,
      response: null
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.gotPersoner = this.gotPersoner.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleAnsvarligChange = this.handleAnsvarligChange.bind(this);
    this.gotLister = this.gotLister.bind(this);
    this.fjernThisArbeidsliste = this.fjernThisArbeidsliste.bind(this);
  }
  componentDidMount() {
    getArbeidslisteByAktiv(false, this.gotLister);
    
  }

  fjernThisArbeidsliste(arbeidslister) {
    var lister = arbeidslister.filter(x => {
      return x.id !== this.props.arbeidslisteId;   
    });
    return lister;
  }

  gotLister(err, result) {
    //Fjern aktelle arbeidsliste (hvor man skal flytte fra) fra liste
    let liste = this.fjernThisArbeidsliste(result);
    this.setState({ arbeidslister: liste });
    this.setState({ filtrerteArbeidslister: this.state.arbeidslister });
    console.log(this.state.arbeidslister);
    let defaultId = _.isEmpty(this.state.arbeidslister)
      ? null
      : _.first(this.state.arbeidslister).id;
    this.setState({ valgtArbeidsliste: defaultId });

    getContextBrukere( this.gotPersoner);
  }

  gotPersoner(personer) {
    var tmp = personer.brukere;
    var tom = { id: '-1', navn: 'Alle' };
    tmp.unshift(tom);

    let paaLogget = tmp.find(x => x.paaLogget);
    if (paaLogget !== undefined) {
      this.setState({ defaultAnsvarlig: paaLogget });
      this.setState({ ansvarlig: paaLogget.id }, function() {
        this.onSearch();
      });
    }

    this.setState({ personer: tmp });
  }

  onSearch() {
    var filtrerteLister = this.filterAnsvarlige(
      this.state.ansvarlig.toString(),
      this.state.arbeidslister
    );
    this.setState({ filtrerteArbeidslister: filtrerteLister });
    let defaultId = _.isEmpty(filtrerteLister)
      ? null
      : _.first(filtrerteLister).id;
    this.setState({ valgtArbeidsliste: defaultId });
    console.log(defaultId);
    console.log(filtrerteLister);
  }

  filterAnsvarlige(input, arblister) {
    var res = [];

    if (input === '-1') {
      return arblister;
    }
    if (arblister === null || arblister.length === 0) {
      return res;
    }
    arblister.forEach(function(liste) {
      if (liste.ansvarligIds !== null) {
        var ansvarlige = liste.ansvarligIds.split(',');
        if (ansvarlige.filter(x => x === input).length > 0) {
          if (res.length === 0) {
            res.push(liste);
          } else {
            if (res.filter(y => y.id === liste.id).length === 0) {
              res.push(liste);
            }
          }
        }
      }
    });
    return res;
  }

  handleAnsvarligChange(e) {
    this.setState({ defaultAnsvarlig: e.target.value });
    this.setState({ ansvarlig: e.target.value }, function() {
      this.onSearch();
    });
  }

  submit(bruksenhetsId, eierkontakt, callback) {
    
    var tiltaktyper = '';
    if (this.props.enhetValgtForFlytt.tiltakBruksenhet !== null) {
      tiltaktyper = this.props.enhetValgtForFlytt.tiltakBruksenhet;
      if (this.props.enhetValgtForFlytt.tiltakRoyklop !== null) {
        tiltaktyper =
          tiltaktyper + ', ' + this.props.enhetValgtForFlytt.tiltakRoyklop;
      }
    } else {
      if (this.props.enhetValgtForFlytt.tiltakRoyklop !== null) {
        tiltaktyper = this.props.enhetValgtForFlytt.tiltakRoyklop;
      }
    }

    // var telefon = null;
    // var smsutelat = true;
    // var navn = null;

    if (eierkontakt) {
      eierkontakt.kontakt.forEach(ko => {
        if (ko.telefon == null || ko.telefon === '') {
          ko.smsUtelat = true;
        }
      });
      // telefon = eierkontakt.telefon;
      // smsutelat = eierkontakt.telefon == null ? true : false;
      // navn = eierkontakt.navn;
    };

    var param = {
      BruksenhetId: bruksenhetsId,
      BruksenhetIdMa: this.props.enhetValgtForFlytt.bruksenhetIdMa,
      ArbeidslisteId: this.props.arbeidslisteId,
      ArbeidslisteIdFlytt: this.state.valgtArbeidsliste,
      MatrikkelEnhetId: this.props.enhetValgtForFlytt.matrikkelenhetId,
      Tiltaktyper: tiltaktyper,
      VarselDato: this.props.enhetValgtForFlytt.planlagtDato,
      Adresse: this.props.enhetValgtForFlytt.adresse,
      Kontakt: eierkontakt.kontakt,
      // SmsUtelat: smsutelat,
      // Telefon: telefon,
      // Navn: navn,
      IngenValidering: false
    };
    
    flyttTiltakBruksenhet2(param, (err, resp) => {
      if (resp) {
        console.log('her1');
        console.log(resp);
        this.setState({ error: err, response: resp.tekst, dialogOpen: true });
      } else {
        console.log('her2');
        callback();
      }
    });
  }

  showErrorDialog() {
    let melding = this.state.error ? this.state.error : this.state.response;
    this.dialogs.setOpenDialog('OkDialog', { melding: melding });

    this.setState({ error: null, response: null });
  }

  render() {
    let { varselstatusId } = this.props;
    if (this.state.error || this.state.response) {
      return this.showErrorDialog();
    }
    return (
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="form">
            {this.props.erVarslet ? (
              <p>
                <NKIcon
                  style={{ marginRight: '20px' }}
                  className="pull-left"
                  icon="advarsel"
                  color="nk-black"
                  size="1.7"
                />
                <span>
                  Bruksenheten er allerede varslet. Du kan angi nytt tidspunkt
                  ved å varsle på nytt i planlegging etter å ha flyttet enheten.
                </span>
              </p>
            ) : (
              ''
            )}
            <div className="row">
              <div className="col-sm-8">
                <label>Ansvarlig</label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 ">
                <select
                  className="form-control"
                  id="type"
                  style={{ width: '100%' }}
                  onChange={this.handleAnsvarligChange}
                  value={
                    this.state.defaultAnsvarlig !== null
                      ? this.state.defaultAnsvarlig.id
                      : null
                  }>
                  {_.map(this.state.personer, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row" style={{ marginTop: '10px' }}>
              <div className="col-sm-8">
                <label>Flytt til arbeidsliste</label>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <select
                  className="form-control"
                  style={{ width: '100%' }}
                  id="arbliste"
                  onChange={e => {
                    this.setState({ valgtArbeidsliste: e.target.value });
                  }}
                  value={this.state.valgtArbeidsliste}
                  defaultValue={
                    _.isEmpty(this.state.filtrerteArbeidslister)
                      ? null
                      : _.first(this.state.filtrerteArbeidslister).id}
                      >
                  {_.map(this.state.filtrerteArbeidslister, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn} ({o.antallBruksenheter})
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
export default FlyttTiltak;
