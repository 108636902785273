import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import NorkartBrukere from './NorkartBrukere';
import Organisasjoner from './Organisasjoner';
//import Pivot from './Organisasjoner/Pivot';
import VisKommuner from './Organisasjoner/VisKommuner.jsx';
import Logg from './Logg';
import SvarUtLogg from './SvarUtLogg';
import SvarUtFeilLogg from './SvarUtFeilLogg';
import SmsLogg from './SmsLogg';
import ProsessLogg from './ProsessLogg';
import Eksempelmaler from './Eksempelmaler';
import Dokumentmaler from './Eksempelmaler/Dokumentmaler.jsx';
import Smsmaler from './Eksempelmaler/Smsmaler.jsx';
import LoggBruksenheter from './Logg/LoggBruksenheter.jsx';
import { debugFactory, enhanceComponents } from 'utils';
import { AccessDenied } from 'components/exceptions/ErrorPages';
import withAccessLevel from 'modules/felles/withAccessLevel';
import Test from './Test';
import Test1 from './Test/Test1';
import Test2 from './Test/Test2';
import Test3 from './Test/Test3';
import Test4 from './Test/Test4';
import TestFuncDialogs from './Test/TestFuncDialogs';
import TestFunc from './Test/TestFunc';
import TestFunc2 from './Test/TestFunc2';
import TestFunc3 from './Test/TestFunc3';
import TestNka from './Test/TestNka';
import TestStein from './Test/TestStein';  
import TestParentChilds from './Test/TestParentChilds'; 
import BrukereNaa from './BrukereNaa';
import RisikoMassiv from './RisikoMassiv';
import Driftsmeldinger from './Driftsmeldinger';
import DriftsmeldingSystem from './Driftsmeldinger/DriftsmeldingSystem';
import  DriftsmeldingerHome from './Driftsmeldinger/DriftsmeldingerHome';
import  DriftsmeldingerKunde from './Driftsmeldinger/DriftsmeldingerKunde';

const debug = debugFactory('nk:admin:routes');

const enhanced = enhanceComponents(
  {
    Index,
    NorkartBrukere: withAccessLevel.nkAdmin(NorkartBrukere, AccessDenied),
    Organisasjoner: withAccessLevel.nkAdmin(Organisasjoner, AccessDenied),
    Logg: withAccessLevel.nkAdmin(Logg, AccessDenied),
    SvarUtLogg: withAccessLevel.nkAdmin(SvarUtLogg, AccessDenied),
    SvarUtFeilLogg: withAccessLevel.nkAdmin(SvarUtFeilLogg, AccessDenied),
    SmsLogg: withAccessLevel.nkAdmin(SmsLogg, AccessDenied),
    ProsessLogg: withAccessLevel.nkAdmin(ProsessLogg, AccessDenied),
    BrukereNaa: withAccessLevel.nkAdmin(BrukereNaa, AccessDenied),
    Eksempelmaler, //: withAccessLevel.nkAdmin(Eksempelmaler, AccessDenied),
    LoggBruksenheter, //: withAccessLevel.nkAdmin(LoggBruksenheter, AccessDenied),
    Test, //: withAccessLevel.nkAdmin(Test, AccessDenied),
    RisikoMassiv: withAccessLevel.nkAdmin(RisikoMassiv, AccessDenied),
    Driftsmeldinger, //: withAccessLevel.nkAdmin(Driftsmeldinger, AccessDenied),  http://localhost:8080/#/admin/Test/Test2
    VisKommuner,
    Test1: withAccessLevel.nkAdmin(Test1, AccessDenied),
    Test2: withAccessLevel.nkAdmin(Test2, AccessDenied),
    Test3: withAccessLevel.nkAdmin(Test3, AccessDenied),
    Test4: withAccessLevel.nkAdmin(Test4, AccessDenied),
    TestFuncDialogs: withAccessLevel.nkAdmin(TestFuncDialogs, AccessDenied),
    TestFunc: withAccessLevel.nkAdmin(TestFunc, AccessDenied),
    TestFunc2: withAccessLevel.nkAdmin(TestFunc2, AccessDenied),
    TestFunc3: withAccessLevel.nkAdmin(TestFunc3, AccessDenied),
    TestNka: withAccessLevel.nkAdmin(TestNka, AccessDenied),
    TestStein: withAccessLevel.nkAdmin(TestStein, AccessDenied), 
    TestParentChilds: withAccessLevel.nkAdmin(TestParentChilds, AccessDenied), 
    DriftsmeldingSystem: withAccessLevel.nkAdmin(DriftsmeldingSystem, AccessDenied),
    DriftsmeldingerHome: withAccessLevel.nkAdmin(DriftsmeldingerHome, AccessDenied),
    DriftsmeldingerKunde: withAccessLevel.nkAdmin(DriftsmeldingerKunde, AccessDenied),
    Dokumentmaler: withAccessLevel.nkAdmin(Dokumentmaler, AccessDenied),
    Smsmaler: withAccessLevel.nkAdmin(Smsmaler, AccessDenied),
  },
  [asRouteComponent]
);

export default (
  <Route
    path='admin'
    component={enhanced.Index}
    name='NK Admin'
    icon='hammer'
    inMenu={true}
    login={true}
    roles={[]}>
    <Route
      path='NorkartBrukere'
      component={enhanced.NorkartBrukere}
      name='NorkartBrukere'
    />
    <Route
      path='Organisasjoner'
      component={enhanced.Organisasjoner}
      name='Organisasjoner'>
  {/*     <Route
      //  path='VisKommuner'  <Route path=":arbeidslisteId" component={enhanced.Arbeidsliste} name={'Arbeidsliste'}>
        path=':orgId/:orgNavn'
        component={enhanced.VisKommuner}
        name='Vis kommuner'
      /> */}
      {/*   <Route
        path='Pivot'
        component={enhanced.Pivot}
        name='Pivot'
      /> */}
    </Route>

    <Route path='Logg' component={enhanced.Logg} name='Logg'>
      <Route
        path=':orgId/:orgNavn'
        component={enhanced.LoggBruksenheter}
        name={'Bruksenheter'}
      />
    </Route>
    <Route
      path='SvarUtLogg'
      component={enhanced.SvarUtLogg}
      name='SvarUtLogg'
    />
    <Route
      path='SvarUtFeilLogg'
      component={enhanced.SvarUtFeilLogg}
      name='SvarUtFeilLogg'
    />
    <Route path='SmsLogg' component={enhanced.SmsLogg} name='SmsLogg' />
    <Route path='ProsessLogg' component={enhanced.ProsessLogg} name='Systemmelding' />
    <Route
      path='Eksempelmaler'
      component={enhanced.Eksempelmaler}
      name='Eksempelmaler'>
      <Route
        path='Smsmaler'
        component={enhanced.Smsmaler}
        name='Eksempelmaler SMS'
      />
      <Route
        path='Dokumentmaler'
        component={enhanced.Dokumentmaler}
        name='Eksempelmaler Dokument'
      />
    </Route>
    <Route path='Test' component={enhanced.Test} name='Test'>
      <Route path='Test1' component={enhanced.Test1} name='Test 1' />
      <Route path='Test2' component={enhanced.Test2} name='Test 2' />
      <Route path='Test3' component={enhanced.Test3} name='Test 3' />
      <Route path='Test4' component={enhanced.Test4} name='Test 4' />
       <Route path='TestFuncDialogs' component={enhanced.TestFuncDialogs} name='Test Func Dialogs' />
      <Route path='TestFunc' component={enhanced.TestFunc} name='Test Func' />
      <Route path='TestFunc2' component={enhanced.TestFunc2} name='Test Func2' />
      <Route path='TestFunc3' component={enhanced.TestFunc3} name='Test Func3' />
      <Route path='TestNka' component={enhanced.TestNka} name='Test Nka' />  
      <Route path='TestStein' component={enhanced.TestStein} name='Test Stein' />  
      <Route path='TestParentChilds' component={enhanced.TestParentChilds} name='Test Parent Childs' />
    </Route>
    <Route
      path='BrukereNaa'
      component={enhanced.BrukereNaa}
      name='Brukere innlogget nå'
    />
    <Route path='RisikoMassiv' component={enhanced.RisikoMassiv} name='Risikovurdering massiv analyse' />
    <Route
      path='Driftsmeldinger'
      component={enhanced.Driftsmeldinger}
      name='Systeminformasjon'>
      <Route path='DriftsmeldingSystem' component={enhanced.DriftsmeldingSystem} name='System stop ved innlogging' />
      <Route path='DriftsmeldingerHome' component={enhanced.DriftsmeldingerHome} name='Driftsmeldinger startside' />
      <Route path='DriftsmeldingerKunde' component={enhanced.DriftsmeldingerKunde} name='Informasjon startside' /> {/* Kanskje endre navn senere */}
    </Route>
  </Route>
);
