import React from 'react';
import Gebyr from './Gebyr.jsx';

export default class extends React.Component {
  state = { breadcrumbname: 'Laster....' };

  render() {
    return (
      <div>
        <Gebyr />
      </div>
    );
  }
}