import React, { useState, useEffect } from 'react';
import {
  getAnmerkningBeskrivelse,
  saveAnmerkningBeskrivelse,
  updateAnmerkningBeskrivelse,
  slettAnmerkningBeskrivelse,
} from 'api/oppslagsdataApi';
import { promisifyApiCall } from 'utils';
import * as _ from 'lodash';
import Spinner from 'components/generics/Spinner';
import PromptDialog from './Dialogs13/PromptDialog';
import DialogStore from 'lib/DialogStore';

function TestFunc() {
  const [isLoading, setIsLoading] = useState(true);
  const [anmBeskrivelser, setAnmBeskrivelser] = useState([]);
  const [inputBeskrivelse, setInputBeskrivelse] = useState('Eksempel');
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [valgtRow, setValgtRow] = useState(null);

  useEffect(() => {
    //litt som componentDidMount
    console.log('XXXX useEffect slår til, slik som render');
    if (isLoading) {
      // ellers blir det en infin. loop
      hentData();
    }
  });

  const hentData = () => {
   setIsLoading(false);
    promisifyApiCall(getAnmerkningBeskrivelse)().then((data) => {
      console.log('XXXX data hentet 1');
      data = _.sortBy(data, 'id');
     
      setAnmBeskrivelser(data);
    });
    //Eksempel for å hente noe annet etter første henting
    /*       .then(() => {
        promisifyApiCall(getAvvikBeskrivelse)().then((data2) => {
          console.log('XYZ0 data hentet 2', data2);
        });
      }); */
  };

  const nyBeskrivelse = () => {
    let b = { tekst: inputBeskrivelse, aktiv: true };
    saveAnmerkningBeskrivelse(b, hentData);
  };

  const endreBeskrivelse = (row) => {
    setValgtRow(row);
    setIsPromptOpen(true);
  };

  const handlePromptClose = (tekst) => {
    setIsPromptOpen(false);
     if (tekst) {
      let id = valgtRow.id;
      let b = { id: valgtRow.id, tekst: tekst, aktiv: true };
      updateAnmerkningBeskrivelse(id, b, hentData);
    } 
  };

  const slettBeskrivelse = (row) => {
    let id = row.id;
    slettAnmerkningBeskrivelse(id, hentData);
  };

  const onChangeBeskrivelse = (event) => {
    setInputBeskrivelse(event.target.value);
  };

  

  return (
    <div>
      <h3>Test funct.comp. CRUD: AnmerkningBeskrivelser</h3>

     { isLoading ? (
      <React.Fragment>
        <Spinner className='h4' />
      </React.Fragment>
      ) :(
      <React.Fragment>
        <div style={{ padding: '20px' }}>
          <button onClick={nyBeskrivelse}>Ny beskrivelse</button>

          <input
            style={{ marginLeft: '8px' }}
            type='text'
            name='beskrivelse'
            onChange={onChangeBeskrivelse}
            value={inputBeskrivelse}
          />
          <br />
          <br />
          {anmBeskrivelser.map((row, idx) => (
            <div key={idx}>
              <span>
                <button onClick={() => slettBeskrivelse(row)}>Slett</button>
              </span>{' '}
              {/* viktig med arow her */}
              <span>
                <button onClick={() => endreBeskrivelse(row)}>Endre</button>
              </span>{' '}
              {/* viktig med arow her */}
              <span style={{ marginLeft: '8px' }}>{row.id}</span>
              <span style={{ marginLeft: '8px' }}>{row.tekst}</span>
            </div>
          ))}
        </div>
      </React.Fragment>
      )}
      <PromptDialog
        tittel='Endre beskrivelse'
        tekst='Beskrivelse'
        isOpen={isPromptOpen}
        handleClose={handlePromptClose}
      />
    </div>
  );
}

export default TestFunc;
