/* import { callApiGrunnbok } from './waapiGrunnbok'; */
import { callApiFritekst } from './waapiFritekst';

export function getEierSuggestion(value, callback) {
    callApiFritekst('GET', '/search/grunnbok/eier?', value, callback);
};

export function getEier(value, callback) {
    callApiFritekst('GET', '/data/eier/fysiskperson/', value, callback);
};

export function getJuridiskPerson(value, callback) {
    callApiFritekst('GET', '/data/eier/juridiskperson/', value, callback);
};

/*  export function getBorett(orgNr, andelsNummer, callback){
    callApiGrunnbok('GET', '/rapporter/kandidater/borett/' + orgNr + '/' + andelsNummer + '/', null, callback);
} */
/* export function getBorettForBruksenhet(bruksenhetsId, callback){        
    callApiGrunnbok('GET', '/borett/bruksenhetdatabaseid/' + bruksenhetsId, null, callback);
} */
