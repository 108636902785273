import 'lib/polyfills';
import { render } from 'react-dom';
import ReactModal from 'react-modal';
import { setUp as appInsightsSetUp } from 'lib/appInsights';
import App from './App';

//Warning: react-modal: App element is not defined. 
ReactModal.setAppElement('body');

appInsightsSetUp();

render(App, document.getElementById('app')); 

/*Evt. new ifm v18
 import React from 'react';
import { createRoot } from 'react-dom/client';
import 'lib/polyfills';
import ReactModal from 'react-modal';
import { setUp as appInsightsSetUp } from 'lib/appInsights';
import  App  from './App';

//Warning: react-modal: App element is not defined. 
ReactModal.setAppElement('body');

appInsightsSetUp();

const root = createRoot(document.getElementById('app'));

root.render(
      App 
  ); */


