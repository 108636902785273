import React, { Component } from 'react';
import ReactTable from '@norkart/react-table';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import ContextMenu from 'components/ContextMenu';
import './mal.css';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class SmsMalTable extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 0 };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {}

  getColumns = () => {
    let columns = [
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.standardMal) {
            let t = _.truncate(item.tittel, { length: 60 });
            return (
              <span>
                <span>{t}</span>
                <span style={{ marginLeft: '4px', color: 'blue' }}>
                  {'( Standardmal )'}
                </span>
              </span>
            );
          } else {
            return _.truncate(item.tittel, { length: 60 });
          }
        },
      },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.truncate(item.tekst, { length: 1400 });
          let z = { __html: s };
          let tooltip = <div dangerouslySetInnerHTML={z} />;

          if (s === '') {
            s = 'Tekst mangler..';
          }
          return (
            <span className='text'>
              {' '}
              <NKIcon icon='kommentar' size='0.8' color='nk-black' />
              <span className='text-tooltip-sms'>{tooltip}</span>
            </span>
          );
        },
      },
      {
        id: 'funksjon',
        name: 'Funksjon',
        sortParams: 'funksjon',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      (this.props.gjelderKode === 'P' || this.props.gjelderKode === 'G') &&
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let str = '';
          let first = true;
          if (item.tiltak) {
            for (let y = 0; y < item.tiltak.length; y++) {
              let til = item.tiltak[y].tiltak;
              if (!first) {
                if (til) {
                  til = til.toLowerCase();
                }
              }
              first = false;
              str = str + til + ', ';
            }
          }
          if (str.length > 2) {
            str = str.substring(0, str.length - 2);
          }
          //return str;
          return <span style={{ maxWidth: '200px' }}>{str}</span>;
        },
      },
      {
        id: 'beskrivelse',
        name: 'Beskrivelse',
        sortParams: 'beskrivelse',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return _.truncate(item.beskrivelse, { length: 45 });
        },
      },
      {
        id: 'aktiv',
        name: 'I bruk',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'ja';
          } else {
            return 'nei';
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return this.makeContextMenu(item);         
        }.bind(this),
      }
    ];
    return columns;
  };

  makeContextMenu = (item) => {
    if (item.funksjon === 'Varsel') {
      return <ContextMenu.Button>
          {!item.standardMal && (
            <ContextMenu.Item onClick={() => this.props.slettMal(item)}>
              Slett mal
            </ContextMenu.Item>
          )}
          <ContextMenu.Item
            onClick={() => this.props.genererNyMalFraMeny(item)}>
            Generer ny mal basert på "{item.tittel}""
          </ContextMenu.Item>
        </ContextMenu.Button>;
    }
    if (item.gjelderKode === 'G') {
      return <ContextMenu.Button>
          {!item.standardMal && (
            <ContextMenu.Item onClick={() => this.props.slettMal(item)}>
              Slett mal
            </ContextMenu.Item>
          )}
          <ContextMenu.Item
            onClick={() => this.props.genererNyMalFraMeny(item)}>
            Generer ny mal basert på "{item.tittel}""
          </ContextMenu.Item>
        </ContextMenu.Button>;
    }
  }

  rowClick = (row) => {
    this.props.onRowClick(row);
  };

  lesmer = () => {
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Endringer ifm. automatisering',
      melding: (
        <React.Fragment>
          Ifm. automatiseringen brukes det nå kun 7 standard SMS-maler:
          <ul>
            <li>for å varsle</li>
            <li>for purring ifm. varsel</li>
            <li>ifm. påminnelse</li>
            <li>ifm. kansellering</li>
          </ul>
          Malene kan redigeres, men man kan ikke opprette nye SMS-maler.
          <br />
          De malene kan ikke slettes.
        </React.Fragment>
      ),
    });
  };

  /*  rowFormat = item => {
    if (!item.standard) {
      return { style: { backgroundColor: '#f5d9a6' } };
    }
  }; */

  forhandsvisning = (mal) => {
    alert('Ikke implementert enda..');
  };

  render() {
    return (
      <div>
        <ReactTable
          toggleAllDefault={false}
          showIndex={false}
          columns={this.getColumns()}
          items={this.props.items}
          selectableRows={false}
          /*  rowFormat={this.rowFormat} */
          onRowClick={(row) => this.rowClick(row)}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SmsMalTable;
