import React from 'react';
import Oppsett from './Oppsett.jsx';

export default class extends React.Component {
    state = { breadcrumbname: 'Laster....' };

    render() {
        return <div>
            <Oppsett />
        </div>;
    }
}