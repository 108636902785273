import React from 'react';

var merknad = null;

class EndreTittel extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { tekst: this.props.tittel, vinduTittel: 'Rediger', visTest: false };
  }

  componentDidMount() { 
    if (this.props.type === 'sak') {
      this.setState({ vinduTittel: 'Rediger sakstittel' });
    }
    if (this.props.type === 'jp1') {
      this.setState({ vinduTittel: 'Rediger journalposttittel for resultat etter tilsyn' });
    }
    if (this.props.type === 'jp2') {
      this.setState({ vinduTittel: 'Rediger journalposttittel for dialog' });
    }
    if (this.props.type === 'jp3') {
      this.setState({ vinduTittel: 'Rediger journalposttittel for lukking av avvik' });
    }
  }
  
  onVelgFlettefelt = (data) => {
    var textfelt = document.getElementById('textfelt');
    this.insertText(textfelt, data.target.value);
  }

  insertText = (el, text) => {
    text = text + ' ';
    el.focus();
    var val = el.value,
      endIndex,
      range;
    if (
      typeof el.selectionStart != 'undefined' &&
      typeof el.selectionEnd != 'undefined'
    ) {
      endIndex = el.selectionEnd;
      el.value = val.slice(0, el.selectionStart) + text + val.slice(endIndex);
      el.selectionStart = el.selectionEnd = endIndex + text.length;
    } else if (
      typeof document.selection != 'undefined' &&
      typeof document.selection.createRange != 'undefined'
    ) {
      el.focus();
      range = document.selection.createRange();
      range.collapse(false);
      range.text = text;
      range.select();
    }
    this.setState({ tekst: el.value });
    console.log(el.value);
  }

  tekstChange = (val) => {
    this.setState({ tekst: val.target.value });
  }

  tilFletteTest = () => {
    var box = document.getElementById('textfelt');
    var s = box.value;
    this.setState({ tekst: s });
    s = this.flettText(s);
    this.setState({ flettetTekst: s });
    console.log(s);
    this.setState({ visTest: true });
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replace(new RegExp(find, 'g'), repl);
    return str;
  };

  flettText = (s) => {
    if (!this.props.flettefelt) {
      return;
    }
    for (let i = 0; i < this.props.flettefelt.length; i++) {
      let feltRow = this.props.flettefelt[i];
      s = this.goReplace(s, feltRow.flettefelt, feltRow.fletteEksempel);
    }
    return s;
  };

  tilbake = () => {
    this.setState({ visTest: false });
  }


  submit() {    
    this.props.lagre(this.state.tekst);     
  }
  
  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" onClick={this.props.onClose} type="button">
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">{this.state.vinduTittel}</h4>
        </div>
        <div className="modal-body">
        {!this.state.visTest && (
          <React.Fragment>
            <div className="form">
            <br/>
            <div className="row">
            <div className="col-sm-12">
            <span>
                <span style={{ margin: '15px 10px' }}>Sett inn:</span>
                <span>
                  <select
                    style={{
                      height: '32px',
                      marginTop: '2px',
                      fontSize: '16px',
                      width: '200px',
                      verticalAlign: 'middle',
                    }}
                    value='0'
                    onChange={this.onVelgFlettefelt}>
                    <option value='0'>Flettefelt..</option>
                    {this.props.flettefelt.map((felt, i) => (
                      <option
                        style={{ fontSize: '16px', color: 'blue' }}
                        key={'key' + i}
                        value={felt.flettefelt}>
                        {felt.navn}
                      </option>
                    ))}
                    ;
                  </select>
                  <button
                    onClick={this.tilFletteTest}
                    style={{ marginLeft: '8px', height: '32px' }}
                    className='btn btn-default'>
                    Test
                  </button>
                </span>
              </span>
              </div>


            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group"
                  style={{ margin: '15px 10px' }}>
                  <label>Tittel</label>
                  <input
                  id='textfelt'
                  type="text"
                    className="form-control"
                    onChange={this.tekstChange}
                    value= {this.state.tekst}
                    
                  />
                </div>
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>

          </React.Fragment>

        )}
         {this.state.visTest && (
          <React.Fragment>
            <div className="form">
            <br/>
            <div className="row">
            <div className="col-sm-12">
            <span>
                <span style={{ margin: '15px 10px' }}>Sett inn:</span>
                <span>
                  <select
                    disabled
                    style={{
                      height: '32px',
                      marginTop: '2px',
                      fontSize: '16px',
                      width: '200px',
                      verticalAlign: 'middle',
                    }}
                    value='0'
                    onChange={this.onVelgFlettefelt}>
                    <option value='0'>Flettefelt..</option>
                    {this.props.flettefelt.map((felt, i) => (
                      <option
                        style={{ fontSize: '16px', color: 'blue' }}
                        key={'key' + i}
                        value={felt.flettefelt}>
                        {felt.navn}
                      </option>
                    ))}
                    ;
                    
                  </select>
                  <button
                    onClick={this.tilbake}
                    style={{ marginLeft: '8px', height: '32px' }}
                    className='btn btn-default'>
                    Tilbake
                  </button>
                </span>
              </span>
              </div>


            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group"
                  style={{ margin: '15px 10px' }}>
                  <label>Tittel (Flettet tekst eksempel NB! Ikke reelle data!)</label>
                  <input
                  id='textfelt'
                  type="text"
                    className="form-control"
                    onChange={this.tekstChange}
                    value= {this.state.flettetTekst}
                    
                  />
                </div>
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>

          </React.Fragment>

        )} 

        </div>
      </div>
    );
  }
}

export default EndreTittel;
