import EnhetSituasjon from './EnhetSituasjon.jsx';
//import { getOrganisasjonRisikovurdering } from 'api/organisasjonApi';
import { getOrgRisiko } from 'context/contextInnstillinger.js';
import { getSituasjon } from 'context/contextGjennomforing.js';

var React = require('react');
var _ = require('underscore');
var api = require('api/enhetApi');
require('../gjennomforing.css');

class EnhetApp extends React.Component {
    state = { form: null, situasjon: null, risiko: null };

    componentDidMount() {
        getSituasjon(this.props.enhetid, this.props.arbListeId, this.gotSituasjon);
      //  api.getBruksenhetSituasjon(this.props.enhetid, this.props.arbListeId, this.gotSituasjon);
        getOrgRisiko( (res) => {
            this.setState({ risiko: res.data });
          });
    }

    gotSituasjon = (situasjon) => { 
        this.setState({ situasjon: situasjon });
    };

    gotSituasjonRefresh = (err, situasjon) => {
        this.setState({ situasjon: situasjon });
    }

    closeForm = () => {
        this.setState({ form: null });
    };
    refresh = () => { 
        this.setState({ situasjon: null });
        api.getBruksenhetSituasjon(this.props.enhetid, this.props.arbListeId, this.gotSituasjonRefresh);
    }

    render() {
        //if (!this.state.situasjon || !this.state.org) {
        if (!this.state.situasjon) {
            return (
                <div>Laster...</div>
            );
        }
        if (this.state.form) {
            return <this.state.form onClose={this.closeForm} />;
        }
        return (
            <div>
                <div>
                    <div>
                        <EnhetSituasjon onTilsynClick={this.showTilsyn}
                            situasjon={this.state.situasjon} 
                            arbListeId = {this.props.arbListeId}
                            adresse = {this.props.adresse}
                            eiendom = {this.props.eiendom}
                            enhetId = {this.props.enhetid}
                            reellBruksenhet = {this.props.reellBruksenhet}
                            matrikkelenhetId = {this.props.matrikkelenhetId}
                            bruksenhetIdMa = { this.state.situasjon.bruksenhetIdMa }
                            base_path = {this.props.base_path}
                            modal = {this.props.modal}
                            refresh = {this.refresh}
                            risiko={this.state.risiko}
                            fagbruksenhetId={this.props.enhetid}
                            fraFiktive= {this.props.fraFiktive}
                            />
                            
                        <p></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default EnhetApp;