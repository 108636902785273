import React, { Component } from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';

import {
  debugFactory,
  objGetDeep
} from 'utils';

const debug = debugFactory('nk:innstillinger:maloppsett:index');

  let pathname = null;

class Index extends Component {

  constructor(props) {
    super(props);
    pathname = objGetDeep(props, 'location.pathname');
  }


  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-md-8 col-lg-6'>
            <LinkList title='Maloppsett' columns={1}>
              <LinkListItem title='SMS-maler' path={`${pathname}/Sms`} />
              <LinkListItem
                title='Dokumentmaler'
                path={`${pathname}/Dokument`}
              />
            </LinkList>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
