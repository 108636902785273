import React from 'react';
import * as _ from 'lodash';
import EnhetTabell from './FiktivEnhetTabell.jsx';
import { CreateDefaultQueryFiktive } from 'components/Search/DefaultQuery';
import CONFIG from 'lib/CONFIG';
import { getTranslations } from 'components/Search/translations';
import {
  SearchkitManager,
  SearchkitProvider,
  Layout,
  Hits,
  LayoutBody,
  LayoutResults,
  ActionBar,
  ActionBarRow,
  HitsStats,
  ResetFilters,
  Pagination,
  Select,
  PageSizeSelector,
  SortingSelector
} from 'searchkit';
import { getKommunerForOrganisasjon } from 'api/kommuneApi';
import { getAccessToken } from 'auth/auth';
import { reloadSearch } from 'utils/searchKitHelper';
import 'css/generated/themingSearchkit/theme.css';
import norkartIdJs from 'auth/auth';

const getCurrentCustomerId = () => {
  let cust = norkartIdJs.getCurrentCustomerContext();
  return cust !== undefined ? cust.CustomerId : '';
};

const ES_URL = CONFIG.REACT_APP_API_ENDPOINT; 
const ES_INDEX = CONFIG.REACT_APP_ES_INDEX;
const WAAPI_PROFILE = CONFIG.REACT_APP_PROFILE;

if (!ES_URL || !ES_INDEX ) {
  throw new Error('Missing search index settings.');
}

const host = ES_URL + "api/search/" + ES_INDEX + "_bf_fiktiv"; 

let searchkit = null;

// let searchkit = new SearchkitManager(host, {
//   httpHeaders: {
//     'Authorization' : getAccessToken(),
//     'X-WAAPI-Profile': 'SkyBrann-test',
//     'X-WAAPI-CONTEXT' : getCurrentCustomerId()
//   }, 
//   useHistory: false 
// });

// searchkit.translateFunction = getTranslations;

class SearchkitFiktive extends React.Component {
  constructor() {
    super();
    this.state = {
      visEtablerfagdata: false,
      selectedRows: []
    };

    this.onGotKommuner = this.onGotKommuner.bind(this);
    this.getBruksenhetstabell = this.getBruksenhetstabell.bind(this);
    this.onEtablerFagdata = this.onEtablerFagdata.bind(this); 
    
    this.searchkit = new SearchkitManager(host, {
      httpHeaders: {
        'Authorization': getAccessToken(),
        'X-WAAPI-Profile': WAAPI_PROFILE,
        'X-WAAPI-CONTEXT': getCurrentCustomerId()
    }, 
      useHistory: false 
    });

    this.searchkit.translateFunction = getTranslations;
  }
  componentDidMount() {
    getKommunerForOrganisasjon(this.onGotKommuner);
  }

  onGotKommuner(err, kommuner) {
    this.searchkit.addDefaultQuery(query => {
      query = CreateDefaultQueryFiktive(query, kommuner);
      return query;
    }    
    );
    reloadSearch(this.searchkit);
  }

  onEtablerFagdata() {
    if (!_.isEmpty(this.state.selectedRows)) {
      this.setState({ visEtablerfagdata: true });
    }
  }

  getBruksenhetstabell() {
    return (
      <div>
        <EnhetTabell hits={this.searchkit.getHits()} refreshHits={() => reloadSearch(this.searchkit)} />
      </div>
    );
  }

  render() {
    let classNameButton = _.isEmpty(this.state.selectedRows)
      ? 'btn btn-primary btn-lg pull-right disabled'
      : 'btn btn-primary btn-lg pull-right';

    return (
      <div>
        <h3>Koble fiktiv enhet til matrikkelenhet</h3>
        <SearchkitProvider searchkit={this.searchkit}>
          <Layout>
            <LayoutBody>
              <LayoutResults>
                <ActionBar>
                  <ActionBarRow />
                  <ActionBarRow>
                    <HitsStats />
                    <ResetFilters
                      translations={{
                        'reset.clear_all': 'Nullstill filtre'
                      }}
                    />
                    <SortingSelector
                      options={[
                        {
                          label: 'Sorter på adresse',
                          key: 'adresse',

                          fields: [
                            {
                              field: 'skybrannData.bruksenhet.fGatenavn.keyword',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fHusNr',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fBokstav.keyword',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fEtasjeLopeNr',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fEtasjeNr',  
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            }
                          ]
                        },

                        {
                          label: 'Sorter på gårds- og bruksnummer',
                          key: 'gnr',
                          fields: [
                            {
                              field: 'skybrannData.bruksenhet.fGnr',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fBnr',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fSnr',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            },
                            {
                              field: 'skybrannData.bruksenhet.fFnr',
                              options: {
                                order: 'asc',
                                nested_path: 'skybrannData.bruksenhet'
                              }
                            }
                          ]
                        }
                      ]}
                    />

                    <PageSizeSelector options={[10, 100, 250, 500]} listComponent={Select} />
                  </ActionBarRow>
                </ActionBar>
                <Hits
                  hitsPerPage={100}
                  listComponent={this.getBruksenhetstabell}
                  sourceFilter={[
                    'id',
                    'skybrannData.bruksenhet.fGatenavn',
                    'skybrannData.bruksenhet.fEtasjeType',
                    'skybrannData.bruksenhet.fGnr',
                    'skybrannData.bruksenhet.fBnr',
                    'skybrannData.bruksenhet.fFnr',
                    'skybrannData.bruksenhet.fSnr',
                    'skybrannData.bruksenhet.fHusNr',
                    'skybrannData.bruksenhet.fBokstav',
                    'skybrannData.bruksenhet.fEtasjeLopeNr',
                    'skybrannData.bruksenhet.fEtasjeNr',
                    'skybrannData.bruksenhet.fKommuneNr',
                    'skybrannData.bruksenhet.fagId',
                    'skybrannData.bruksenhet.fyringsforbud',
                    'skybrannData.bruksenhet.fByggType',
                    'skybrannData.bruksenhet.fByggNr',
                    'skybrannData.bruksenhet.fGateType'
                  ]}
                />
                <Pagination showNumbers={true} />
              </LayoutResults>
            </LayoutBody>
          </Layout>
        </SearchkitProvider>
      </div>
    );
  }
}
export default SearchkitFiktive;
