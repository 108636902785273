import React from 'react';
import { PropTypes } from 'prop-types';
import { getTooltipForLeggTilOgSoekEnheter } from 'components/Kart/getTooltip.js';
import L from 'leaflet';
import { MapLayer, Popup } from 'react-leaflet';
import * as getIcon from '../geticon.js';
import 'leaflet.markercluster';
import _ from 'lodash';
/* import {
  getSoekEnheterVisRisiko,
  getPlanleggingVisRisiko
} from 'context/contextKart.js'; */
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';


class PlanClusterLayer extends MapLayer {
  constructor() {
    super();

    this.state = {
      popupObject: null,
      theBounds: null,
      huskPos: null,
    };

    this._clicked = this._clicked.bind(this);

    this._popupClosed = this._popupClosed.bind(this);
  }

  getChildContext() {
    return {
      layerContainer: this.leafletElement,
    };
  }

  getTheBounds() {
    return this.state.theBounds;
  }

  createLeafletElement() {
    let { clusterIcon, ...options } = this.props;
    if (!options.iconCreateFunction && clusterIcon) {
      options.iconCreateFunction = () => clusterIcon;
    }
    this.leafletElement = L.markerClusterGroup(options);
    return this.leafletElement;
  }

  componentDidMount() {
    super.componentDidMount();
    //  debug('modus clusterlayer', this.props.modus);
    this._addMarkers(this.props);
    this.leafletElement.on('click', this._clicked);
    this.context.map.on('popupclose', this._popupClosed);
  }

  componentWillReceiveProps(nextProps) {
    //  debug('modus clusterlayer receive X', this.props.modus);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.leafletElement.off('click', this._clicked);
    this.context.map.off('popupclose', this._popupClosed);
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.data !== fromProps.data) {
      // Alle markers fjernes fra laget og legges til på nytt. Dette er
      // ganske raskt når addLayers() brukes for å legge til alle i en
      // operasjon. Hvis vi får ytelsesproblemer kan vi prøve med å
      // oppdatere lag-listen i stedet med individuelle removeLayer og
      // addLayer. Kanskje vi kan bruke noen grove estimat for å vurdere
      // hva som er raskest, feks. antall dataobjekter i listen.
      this.leafletElement.clearLayers();
      this._addMarkers(toProps);

      // fjern popup hvis objektet som popup-en viser ikke lenger finnes i
      // data-listen
      if (
        this.state.popupObject &&
        !_.includes(toProps.data, this.state.popupObject)
      ) {
        this.setState({ popupObject: null });
      }
    }
  }

  inRange = (x, min, max) => {
    return min <= x && x <= max;
  };

  makeRisikoMarker = (obj) => {
    let icon;
    let risikoTall = 0;
    let flereRoyklop = false;
    let risikoOverstyrt = false;

    if (this.props.visRisiko === 'Tilsyn') {
      risikoTall = obj.risiko;
      if (_.get(obj, 'skybrannData.bruksenhet.risikoverdiOverstyr')) {
        risikoOverstyrt = obj.skybrannData.bruksenhet.risikoverdiOverstyr;
      }
    } else {
      //Her hentes info fra _source. Det kan være mange røykløp
      if (_.get(obj, 'source.skybrannData.royklop')) {
        if (obj.source.skybrannData.royklop.length === 1) {
          risikoTall = obj.source.skybrannData.royklop[0].risikoverdi;
          risikoOverstyrt = obj.source.skybrannData.royklop[0].risikoverdiOverstyr;
        } else {
          flereRoyklop = true;
          let tall = 0;
          for (let i = 0; i < obj.source.skybrannData.royklop.length; i++) {
            let r = obj.source.skybrannData.royklop[i];
            if (r.risikoverdi) {
              if (r.risikoverdi > tall) {
                tall = r.risikoverdi;
                risikoOverstyrt = r.risikoverdiOverstyr;
              }
            }
          }
          risikoTall = tall;
        }
      }
    }

    if (flereRoyklop) {
      //Flere røykløp
      if (!risikoOverstyrt) {
      if (risikoTall === 0) {
        icon = getIcon.risikoBluePlussIcon;
      } else if (this.inRange(risikoTall, 1, 4)) {
        icon = getIcon.risikoGreenPlussIcon;
      } else if (this.inRange(risikoTall, 5, 14)) {
        icon = getIcon.risikoYellowPlussIcon;
      } else if (this.inRange(risikoTall, 15, 25)) {
        icon = getIcon.risikoRedPlussIcon;
      } else {
        icon = getIcon.risikoBluePlussIcon;
      }
    } else {
      if (risikoTall === 0) {
        icon = getIcon.risikoBlueOverstyrtPlussIcon; 
      } else if (this.inRange(risikoTall, 1, 4)) {
        icon = getIcon.risikoGreenOverstyrtPlussIcon;
      } else if (this.inRange(risikoTall, 5, 14)) {
        icon = getIcon.risikoYellowOverstyrtPlussIcon;
      } else if (this.inRange(risikoTall, 15, 25)) {
        icon = getIcon.risikoRedOverstyrtPlussIcon;
      } else {
        icon = getIcon.risikoBlueOverstyrtPlussIcon;
      }
    }
    } else {
      //En røykløp, en enhet
      if (!risikoOverstyrt) {
        if (risikoTall === 0) {
          icon = getIcon.risikoBlueIcon;
        } else if (this.inRange(risikoTall, 1, 4)) {
          icon = getIcon.risikoGreenIcon;
        } else if (this.inRange(risikoTall, 5, 12)) {
          icon = getIcon.risikoYellowIcon;
        } else if (this.inRange(risikoTall, 15, 25)) {
          icon = getIcon.risikoRedIcon;
        } else {
          icon = getIcon.risikoBlueIcon;
        }
      } else {
        //RisikoOverstyrt
        if (risikoTall === 0) {
          icon = getIcon.risikoBlueOverstyrtIcon;  
        } else if (this.inRange(risikoTall, 1, 4)) {
          icon = getIcon.risikoGreenOverstyrtIcon;
        } else if (this.inRange(risikoTall, 5, 12)) {
          icon = getIcon.risikoYellowOverstyrtIcon;
        } else if (this.inRange(risikoTall, 15, 25)) {
          icon = getIcon.risikoRedOverstyrtIcon;
        } else {
          icon = getIcon.risikoBlueOverstyrtIcon;
        }
      }
    }

    return icon;
  };

  _addMarkers(props) {
    let { data } = props;
    if (!data || data.length === 0) {
      return;
    }
    let options = {
      interactive: Boolean(props.markerPopup),
    };

    if (props.markerIcon) {
      options.icon = props.markerIcon;
    }

    let itemsWithLocation = props.data;

    let markers = itemsWithLocation
      .map((obj) => {
        //Skal det vises vanlige markører, eller risikoMarkører?
        if (this.props.visRisiko !== 'Ingen') {
          //vis risiko-markører
          options.icon = this.makeRisikoMarker(obj);
        } else {
          //Vis de vanlige markører
          switch (this.props.modus) {
            case 'Enheter':
              if (props.markerIcon) {
                options.icon = props.markerIcon;
              }
              break;
            case 'Røykløp':
              if (props.alterIcon) {
                options.icon = props.alterIcon;
              }
              break;
            default:
              options.icon = props.markerIcon;
          }

          if (props.valgtIcon) {
            let valgt = obj.valgt;

            if (valgt === true) {
              options.icon = props.valgtIcon;
            }
          }
        }

        let position = this.props.latLngAccessor(obj);
        if (position[0] === undefined || position[1] === undefined) {
          return null;
        }
        if (position[0] === 0 || position[1] === 0) {
          return null;
        }

        let marker = L.marker(position, options);

        marker._popupData = obj;
        // marker.options.title = this.props.getMarkerTooltip(obj);

        //********************************************** TOOLTIP ********************************* */

        marker.bindPopup(
          getTooltipForLeggTilOgSoekEnheter(
            obj,
            this.props.modus,
            this.props.parent
          ),
          { closeButton: false, offset: [0, 30] }
        );
        marker.on('mouseover', function(e) {
          this.openPopup();
        });
        marker.on('mouseout', function(e) {
          this.closePopup();
        });

        //********************************************** TOOLTIP END ********************************* */

        // Fyringsforbud ******************************************
        let ff = _.get(
          marker._popupData,
          'source.skybrannData.bruksenhet.fyringsforbud'
        ); //.fyringsforbud

        if (ff) {
          marker.options.icon = props.forbudIcon;
        }
        // Fyringsforbud ******************************************
        return marker;
      })
      .filter(function(item) {
        return item !== (options.icon = null);
      });
    if (this.leafletElement) {
      this.leafletElement.addLayers(markers);
      let bounds = this.leafletElement.getBounds();

      this.setState({ theBounds: bounds });
      setTimeout(() => this.props.onClusterReady(bounds), 200);
    }
  }
  render() {
    let { markerPopup } = this.props;
    let { popupObject } = this.state;
    if (!markerPopup || !popupObject) {
      return null;
    }

    let popupPosition = [
      this.state.huskPos.latlng.lat,
      this.state.huskPos.latlng.lng,
    ];

    if (!popupPosition || popupPosition.length < 2) {
      return null;
    }

    let offset = [0, 10];

    //Rieni spiderfy

    return (
      <Popup
        position={popupPosition}
        className='noBlurry'
        offset={offset}
        closeOnClick={false}
        autoClose={false}>
        <this.props.markerPopup data={popupObject} />
      </Popup>
    );
  }

  _clicked(e) {
    this.setState({ huskPos: e });
    // this.props.onClickedGj(e);
    if (!this.props.markerPopup || !this.context.map || !e.layer._popupData) {
      return;
    }
    this.setState({ popupObject: e.layer._popupData });
  }

  _popupClosed() {
    //close
    this.setState({ popupObject: null });
  }
}

PlanClusterLayer.contextTypes = {
  layerContainer: PropTypes.shape({
    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
  }),
  map: PropTypes.instanceOf(L.Map),
};

PlanClusterLayer.childContextTypes = {
  layerContainer: PropTypes.shape({
    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
  }),
};

PlanClusterLayer.propTypes = {
  /**
   * The data set to generate markers from.
   * Optional (but nothing will be shown if null or empty).
   */
  data: PropTypes.array,

  /** Icon to use for marker clusters. Optional. */
  clusterIcon: PropTypes.instanceOf(L.Icon),

  /** Icon to use for individual markers. Optional. */
  markerIcon: PropTypes.instanceOf(L.Icon),

  /** Brukes i brannfoebygging  */
  brannIcon: PropTypes.instanceOf(L.Icon),
  brannBetingelse: PropTypes.any,
  brannVerdi: PropTypes.any,
  brannRisiko: PropTypes.any,
  brannRisikoMax: PropTypes.any,
  brannRisikoIcon: PropTypes.instanceOf(L.Icon),
  bulkfarge: PropTypes.any,

  /**
   * Popup content to show when a marker is clicked. The data object
   * corresponding to the clicked marker will be passed as property named
   * "data" to the content.
   * Optional.
   */
  markerPopup: PropTypes.any,
  markerTooltip: PropTypes.any,

  /**
   * Accessor function used to get geographic position of each item in "data".
   * Return value must on the form: [<latidute>, <longitude>].
   * Required.
   */
  latLngAccessor: PropTypes.func.isRequired,

  // MarkerClusterGroup options
  showCoverageOnHover: PropTypes.bool,
  zoomToBoundsOnClick: PropTypes.bool,
  spiderfyOnMaxZoom: PropTypes.bool,
  removeOutsideVisibleBounds: PropTypes.bool,
  animate: PropTypes.bool,
  animateAddingMarkers: PropTypes.bool,
  disableClusteringAtZoom: PropTypes.number,
  maxClusterRadius: PropTypes.number,
  polygonOptions: PropTypes.object,
  singleMarkerMode: PropTypes.bool,
  spiderLegPolylineOptions: PropTypes.object,
  spiderfyDistanceMultiplier: PropTypes.number,
  iconCreateFunction: PropTypes.func,
};

PlanClusterLayer.defaultProps = {
  // MarkerClusterGroup options
  showCoverageOnHover: true,
  zoomToBoundsOnClick: true,
  spiderfyOnMaxZoom: true,
  removeOutsideVisibleBounds: true,
  animate: true,
  animateAddingMarkers: false,
  disableClusteringAtZoom: null,
  maxClusterRadius: 10,
  polygonOptions: {},
  singleMarkerMode: false,
  spiderLegPolylineOptions: {},
  spiderfyDistanceMultiplier: 1,
  iconCreateFunction: null,
};

export default PlanClusterLayer;
