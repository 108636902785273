var React = require('react');
var risikoApi = require('api/risikoApi');
var ReactTable = require('@norkart/react-table');

require('@norkart/react-table/dist/react-table.css');
var Refresh = require('images/Annet/refresh.png');

class RisikovurderingLoggOld extends React.Component {
  state = { logg: [], dataHentet: false };

  componentDidMount() {
    risikoApi.getRisikoanalyseLogg(this.props.id, this.gotLogg);
  }

  gotLogg = (err, logg) => {
    this.setState({ logg: logg, dataHentet: true });
  };

  oppdater = () => {
    risikoApi.getRisikoanalyseLogg(this.props.id, this.gotLogg);
  };

  onRowFormat(item){  
    if (item.fmeJobStatusTxt.includes('SUCCESS')){
      return { className: 'norkartlysgronnLight' };
    } else {
      if (item.fmeJobStatusTxt.includes('FAILED')){
        return { className: 'norkartrodLight' };
      }
    }
  }


  render() {
    if (!this.state.dataHentet) {
      return <div>Laster data...</div>;
    }
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>Risikovurdering analyselogg</h4>
        </div>
        <div className='modal-body' style={{ padding: '20px' }}>
          <div className='btn-toolbar'>
            <button style={{ width: '170px'}}
              className='btn btn-success pull-left'
              onClick={this.oppdater}>
              <img height='30px' width='30px' src={Refresh} alt='' />
              <span style={{ marginLeft: '4px' }}>Oppdater</span>
            </button>
          </div>
          <br />
          <div style={{ height: '400px', overflow: 'auto' }}>
            <ReactTable
              items={this.state.logg}
              idProperty='id'
              showIndex={false}
              filterable={false}
              columns={columns}
              selectableRows={false}
              toggleAll={false}
              onRowClick={null}
              rowFormat={this.onRowFormat}
            />
          </div>
        </div>
      </div>
    );
  }
}

var columns = [
  {
    id: 'bruksenhetId',
    name: 'Bruksenhet',
    sortParams: 'bruksenhetId',
    filterable: true,
    isSorted: false,
    sortable: true,
  },
  {
    id: 'royklopId',
    name: 'Røyklop',
    sortParams: 'royklopId',
    filterable: false,
    isSorted: false,
    sortable: true,
  },
  {
    id: 'fmeJobStatusTxt',
    name: 'Status',
    sortParams: 'fmeJobStatusTxt',
    filterable: false,
    isSorted: false,
    sortable: true,
    formatter: function(item) {
      return <span title={item.fmeJobStatusTxt} className='ellipsed90' >{item.fmeJobStatusTxt}</span>;
    },
  },
  {
    id: 'objektTxt',
    name: 'Objekt',
    sortParams: 'objektTxt',
    filterable: false,
    isSorted: false,
    sortable: true,
  },
  {
    id: 'startJobb',
    name: 'Start',
    sortParams: 'startJobb',
    isSorted: false,
    //sortDirection: 'dsc',
    formatter: function(item) {
      if (item.dato === null) {
        return '-';
      } else {
        var dato = new Date(item.startJobb);
        var dag = dato.getDate().toString();
        var mnd = (dato.getMonth() + 1).toString();
        var aar = dato.getFullYear().toString();
        if (dag.length === 1) {
          dag = '0' + dag;
        }
        if (mnd.length === 1) {
          mnd = '0' + mnd;
        }
        return dag + '.' + mnd + '.' + aar;
      }
    },
  },
  {
    id: 'sluttJobb',
    name: 'Slutt',
    sortParams: 'sluttJobb',
    isSorted: false,
    //sortDirection: 'dsc',
    formatter: function(item) {
      if (item.dato === null) {
        return '-';
      } else {
        var dato = new Date(item.sluttJobb);
        var dag = dato.getDate().toString();
        var mnd = (dato.getMonth() + 1).toString();
        var aar = dato.getFullYear().toString();
        if (dag.length === 1) {
          dag = '0' + dag;
        }
        if (mnd.length === 1) {
          mnd = '0' + mnd;
        }
        return dag + '.' + mnd + '.' + aar;
      }
    },
  },
  {
    id: 'fmeJobId',
    name: 'Job id',
    sortParams: 'fmeJobId',
    filterable: false,
    isSorted: false,
    sortable: true,
    formatter: function(item) {
      return <span title={item.fmeJobId} className='ellipsed80' >{item.fmeJobId}</span>;
    },
  },
];




export default RisikovurderingLoggOld;  //failed  // success
