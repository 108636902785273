import React from 'react';
import Nokkeltall from './Nokkeltall.jsx';

export default class extends React.Component {
  state = { breadcrumbname: 'Laster....' };

  render() {
    return (
      <div>
        <Nokkeltall />
      </div>
    );
  }
}
