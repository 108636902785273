import React, { Component } from 'react';
import { debugFactory } from 'utils';
import NKIcon from 'nka-icons';
import * as _ from 'lodash';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import StatusDigitalButton from './StatusDigitalButton.jsx';
import './rekkefolge.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import RekkefolgeClusterListe from './RekkefolgeClusterListe.jsx';
import BasisKart from 'components/Kart/BasisKart.jsx';
import { getOrgSvarUt } from 'context/contextInnstillinger.js';
import DialogStore from 'lib/DialogStore';

const debug = debugFactory('nk:RekkefolgeDialog');

const modalDialogs = {
  ClusterIndexDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal style={{ width: '400px' }} isOpen={isOpen}>
        <RekkefolgeClusterListe
          onClose={onClose}
          ref='clusterliste'
          items={dialogData.items}
          bruk={owner.brukFraCluster}
          sorterNummer={dialogData.sorterNummer}
        />
      </NKModal>
    );
  },
};

class EndreRekkefolgeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      visKart: true,
      rListe: 'rListe',
      svarskriv: 'Varsles via SkrivUt.',
      select: '',
      doBound: true,
      brukKart: false,
      brukKartTekst: 'Start rekkefølge ved klikk på kart',
      brukColor: '#2e2d30',
      iconColor: 'nk-white',
      sorterNummer: 1,
    };

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    let enheter = this.props.enheter;

    for (let i = 0; i < enheter.length; i++) {
      const e = enheter[i];
      e.index = e.rekkefolgeNummer;
      e.nrf = 0;
      e.tiltak = this.getTiltak(e);
    }

    this.setState({ items: enheter });
    getOrgSvarUt(this.gotInfoOmSvarUt);
  }

  togglekartSortering = () => {
    let bruk = !this.state.brukKart;
    this.setState({ brukKart: bruk });
    let tekst = 'Start rekkefølge ved klikk på kart ';
    let color = '#2e2d30';
    let iColor = 'nk-white';
    if (bruk) {
      tekst = 'Avslutt rekkefølge på kart';
      color = '#A1C438';
      iColor = 'nk-black';
    }
    this.setState({
      brukKartTekst: tekst,
      brukColor: color,
      iconColor: iColor,
    });
    this.setState({ sorterNummer: 1 });
  };

  gotInfoOmSvarUt = (res) => {
    if (res.data === true) {
      this.setState({ svarskriv: 'Varsles via SvarUt' });
    }
  };

  onOk = () => {
    let items = this.state.items;
    let liste = [];

    for (let i = 0; i < items.length; i++) {
      const el = items[i];

      let obj = {
        //bruksenhetId: el.bruksenhetIdMa,
        bruksenhetId: el.id,
        arbeidslisteId: this.props.arbeidslisteId,
        rekkefolgeNummer: el.index,
      };

      liste.push(obj);
    }

    //setRekkefolgenummer
    this.props.onOk(liste);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let items = this.state.items;
    let item = items[oldIndex];
    if (!item) {
      return;
    }
    items = arrayMove(items, oldIndex, newIndex);
    items = this.giNyIndex(items);
    this.setState({ items });
  };

  giNyIndex = (liste) => {
    let teller = 1;
    for (let i = 0; i < liste.length; i++) {
      let a = liste[i];
      a.index = teller;
      teller = teller + 1;
    }
    return liste;
  };

  onVisKart = () => {
    if (this.state.visKart) {
      this.setState({ rListe: 'rListe100', visKart: false });
    } else {
      this.setState({ rListe: 'rListe', visKart: true });
    }
  };

  getTiltak = (item) => {
    if (item.tiltakBruksenhet === null && item.tiltakRoyklop === null) {
      return '';
    }
    if (item.tiltakRoyklop === null) {
      return item.tiltakBruksenhet;
    }
    if (item.tiltakBruksenhet === null) {
      return item.tiltakRoyklop;
    } else {
      return item.tiltakBruksenhet + ',' + item.tiltakRoyklop;
    }
  };

  sorterChange = (e, b) => {
    let select = e.target.value;
    this.setState({ select });
    let items = this.state.items;
    items = _.orderBy(items, select, 'asc');
    items = this.nummerer(items);
    this.setState({ items });
  };

  nummerer = (items) => {
    for (let i = 0; i < items.length; i++) {
      const e = items[i];
      e.index = i + 1;
    }
    return items;
  };

  onDoBound = () => {
    let doBound = !this.state.doBound;
    this.setState({ doBound });
  };

  sortByIndexAndNrf = (liste) => {
    liste = _.sortBy(liste, ['index', 'nrf']);
    return liste;
  };

  onClickedVarsel = (item) => {
    if (this.checkForCluster(item)) {
      if (this.state.sorterNummer >= this.state.items.length) {
        this.togglekartSortering();
        return;
      }
      let items = this.state.items;
      // 1. endre indexNr
      for (let i = 0; i < items.length; i++) {
        if (items[i].index === item.index) {
          items[i].index = this.state.sorterNummer - 1;
          items[i].nrf = 1;
        }
      }
      // 2. sorter
      items = this.sortByIndexAndNrf(items);
      // 3. Renummerering
      for (let i = 0; i < items.length; i++) {
        items[i].index = i + 1;
        items[i].nrf = 0;
      }
      this.setState({ items });
      this.setState({ sorterNummer: this.state.sorterNummer + 1 });
    }
  };

  checkForCluster = (item) => {
    let dobbele = [item];
    let lat = item.lokasjonLatitude;
    let lng = item.lokasjonLongitude;
    for (let i = 0; i < this.state.items.length; i++) {
      let enhet = this.state.items[i];
      if (enhet.id !== item.id) {
        if (enhet.lokasjonLatitude === lat && enhet.lokasjonLongitude === lng) {
          dobbele.push(enhet);
        }
      }
    }
    if (dobbele.length > 1) {
      const iteratees = (obj) => obj.adresse;
      dobbele = _.sortBy(dobbele, iteratees).reverse();
      this.dialogs.setOpenDialog('ClusterIndexDialog', {
        items: dobbele,
        sorterNummer: this.state.sorterNummer,
      });
      return false;
    } else {
      return true;
    }
  };

  clusterIndexResult = (e) => {
    debug('cluster result', e);
  };

  brukFraCluster = (blokk, index) => {
    let items = this.state.items;
    //startliste
    let startListe = [];
    for (let y = 0; y < index - 1; y++) {
      startListe.push(items[y]);
    }
    //blokk
    let teller = 0;
    for (let i = 0; i < blokk.length; i++) {
      blokk[i].index = index + teller;
      teller = teller + 1;
      startListe.push(blokk[i]);
    }
    //sluttliste
    let sluttListe = [];
    let t = startListe.length;
    for (let z = 0; z < items.length; z++) {
      let f = _.find(startListe, {
        id: items[z].id,
      });
      if (!f) {
        let rad = items[z];
        t = t + 1;
        rad.index = t;
        sluttListe.push(rad);
      }
    }
    for (let r = 0; r < sluttListe.length; r++) {
      startListe.push(sluttListe[r]);
    }
    this.setState({ items: startListe });
    this.setState({ sorterNummer: this.state.sorterNummer + blokk.length });
    this.dialogs.closeDialog();
  };

  render() {
    const SortableItem = SortableElement(({ item }) => (
      <li className='SortableItemRekke'>
        <table>
          <tbody>
            <tr>
              <td>
                <button className='numberCircle'>{item.index}</button>
              </td>
              <td style={{ width: '4px' }} />
              <td>
                {item.extra && (
                  <StatusDigitalButton
                    item={item}
                    svarskriv={this.state.svarskriv}
                  />
                )}
              </td>
              <td style={{ width: '300px' }}>
                <label style={{ marginLeft: '4px' }}>{item.adresse}</label>
              </td>
              <td style={{ width: '4px' }} />
              <td style={{ width: '150px' }}>
                <span style={{ marginLeft: '4px' }}>
                  {item.tiltak}
                  {/*  {this.getTiltak(item)} */}
                </span>
              </td>
              <td style={{ width: '4px' }} />
              <td style={{ width: '100px' }}>
                <span style={{ marginLeft: '4px' }}>{item.datoTid}</span>
              </td>

              {!this.state.visKart && (
                <>
                  <td style={{ width: '12px' }} />
                  <td style={{ width: '240px' }}>
                    <span>{'Varsel: ' + item.varselstatus}</span>
                  </td>
                  <td style={{ width: '4px' }} />
                  <td style={{ width: '100px' }}>
                    <span style={{ marginLeft: '4px' }}>
                      {'Gj.ført: ' + item.status}
                    </span>
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul className='SortableListRekke'>
          {items.map((item, index) => (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          ))}
        </ul>
      );
    });

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            Rekkefølge {this.props.arbeidslisteNavn}
          </h4>
        </div>
        <div style={{ paddingTop: '0', marginTop: '0' }} className='modal-body'>
          <div
            style={{
              height: '68vh',
              marginTop: '0',
              marginBottom: '20px',
              marginLeft: '20px',
              marginRight: '20px',
            }}>
            <div style={{ height: '48px', marginRight: '20px' }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        minWidth: '50px',
                        width: '50px',
                      }}>
                      <span>Sorter</span>
                    </td>

                    <td
                      style={{
                        whiteSpace: 'nowrap',
                        minWidth: '130px',
                        width: '130px',
                      }}>
                      <select
                        className='form-control'
                        onChange={this.sorterChange}
                        value={this.state.select}>
                        <option key={'a0'} value={''} />
                        <option key={'a1'} value={'adresse'}>
                          Adresse
                        </option>
                        <option key={'a2'} value={'tiltak'}>
                          Tiltak
                        </option>
                        <option key={'a3'} value={'digstatus'}>
                          Digital status
                        </option>
                      </select>
                    </td>

                    {/*               <td>
              {this.state.brukKart && (
                <label className='pull-right' style={{ marginLeft: '10px' }}>
                  {'Settes nå: ' + this.state.sorterNummer.toString() + '  →'}
                </label>
              )}</td> */}

                    <td
                      style={{
                        paddingLeft: '4px',
                        width: '290px',
                        whiteSpace: 'nowrap',
                      }}>
                      {' '}
                      {this.state.visKart && (
                        <button
                          onClick={this.togglekartSortering}
                          className='btn btn-default'
                          style={{
                            height: '36px',
                            width: '280px',
                            margin: '4px',
                            background: this.state.brukColor,
                            color: 'white',
                          }}>
                          <NKIcon
                            icon='kart'
                            size='1'
                            style={{ marginRight: '10px' }}
                            color={this.state.iconColor}
                          />
                          {this.state.brukKartTekst}
                        </button>
                      )}
                    </td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {this.state.brukKart && this.state.visKart && (
                        <span style={{ fontWeight: 'bold', color: 'darkRed' }}>
                          {'Settes nå: ' +
                            this.state.sorterNummer.toString() +
                            '  ↓'}
                        </span>
                      )}
                    </td>
                    <td style={{ width: '50%' }} />
                    <td style={{ whiteSpace: 'nowrap', width: '90px' }}>
                      <span>Kart: Sentrer enheter automatisk</span>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', width: '70px' }}>
                      {' '}
                      <input
                        className='form-check-input'
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px',
                        }}
                        type='checkbox'
                        checked={this.state.doBound}
                        onChange={this.onDoBound}
                      />
                    </td>
                    <td style={{ whiteSpace: 'nowrap', minWidth: '20px' }} />

                    <td style={{ whiteSpace: 'nowrap', width: '60px' }}>
                      <span>Vis kart</span>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', width: '70px' }}>
                      {' '}
                      <input
                        className='form-check-input'
                        style={{
                          width: '20px',
                          height: '20px',
                          marginLeft: '10px',
                        }}
                        type='checkbox'
                        checked={this.state.visKart}
                        onChange={this.onVisKart}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={this.state.rListe}>
              <SortableList
                items={this.state.items}
                onSortEnd={this.onSortEnd}
                helperClass='SortableRekkeHelper'
              />
            </div>
            {this.state.visKart && (
              <div className='rKart'>
                <BasisKart
                  kartClassName='rKart2'
                  id='kart'
                  ref={(ref) => (this.kart = ref)}
                  data={this.state.items}
                  visning={'planlegging-rekke'}
                  rekkeBound={this.state.doBound}
                  sorterModus={this.state.brukKart}
                  onClickedVarsel={this.onClickedVarsel}
                />
              </div>
            )}
          </div>
        </div>
        <div className='modal-footer'>
          <button
            style={{ marginRight: '20px', width: '100px', height: '48px' }}
            className='btn btn-primary pull-right'
            onClick={this.onOk}>
            OK
          </button>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default EndreRekkefolgeDialog;
