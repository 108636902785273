import { contextOppslagKommuner } from 'context/contextOppslag.js';

/*  'https://www.kommunekart.com?funksjon=VisEiendom&kommunenummer=3411&gaardsnummer=415&bruksnummer=21'; */

export const visEiendomKommunekart = (item, fra) => {
  let kommuner = contextOppslagKommuner._currentValue.kommuner;
  let kommunenr = '';
  let kommune = '';
  let gaardsnummer = '';
  let bruksnummer = '';
  let s = '';

  if (fra === 'situasjon') {
    kommune = item.split('-')[0];
    s = item.split('-')[1];
    gaardsnummer = s.split('/')[0];
    s = s.split('/')[1];
    bruksnummer = s.split('/')[0];

    for (let i = 0; i < kommuner.length; i++) {
      const k = kommuner[i];
      if (k.kommuneNavn === kommune) {
        kommunenr = k.kommuneId;
      }
    }
  } else {
    if (fra === 'fiktiv') {
      if (item._source) {
        if (item._source.skybrannData) {
          if (item._source.skybrannData.bruksenhet) {
            kommunenr = item._source.skybrannData.bruksenhet.fKommuneNr;
            if (kommunenr === '1432'){  //Førde-dritt
              kommunenr = '4647';

            }
            gaardsnummer = item._source.skybrannData.bruksenhet.fGnr;
            bruksnummer = item._source.skybrannData.bruksenhet.fBnr;
          }
        }
      }
    }
  }

  let url =
    'https://www.kommunekart.com?funksjon=VisEiendom&kommunenummer=' +
    kommunenr +
    '&gaardsnummer=' +
    gaardsnummer +
    '&bruksnummer=' +
    bruksnummer;

  window.open(url);
};

// onClick={ () => visEiendomKommunekart( this.props.eiendom, 'situasjon' )}

// import { visEiendomKommunekart } from 'utils/kommunekart.js';
