import React, { Component } from 'react';
import { Calendar, momentLocalizer, move } from 'react-big-calendar';
import moment from 'moment';
import './Kalender.css';
import 'moment/locale/nb';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import * as _ from 'lodash';
import {
  sjekkOmRoedDag,
  getTextColor,
  weekNummer,
  eventDateTimePluss
} from './KalenderUtils.js';
import NKIcon from 'nka-icons';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:kalendar');

moment.locale('nb', {
  week: {
    dow: 1,
    doy: 1
  }
});

const DragAndDropCalendar = withDragAndDrop(Calendar);

const localizer = momentLocalizer(moment);

const messages = {
  // new
  allDay: 'hele dagen',
  previous: 'forrige',
  next: 'neste',
  today: 'i dag',
  month: 'måned',
  week: 'uke',
  work_week: 'arbeidsuke',
  day: 'dag',
  agenda: 'agenda',
  date: 'dato',
  time: 'tid',
  event: 'besøk',
  more: 'flere',
  noEventsInRange: 'Ingen avtaler i denne perioden.'
};

class KalenderBasis extends Component {
  state = {
    events: [],
    eventsUten: [],
    viewState: 'month',
    selMonth: 'btn btn',
    selWeek: '',
    selDay: '',
    selAgenda: '',
    draggedEvent: null,
    hideUten: 'hide',
    hideUtenBtn: '',
    pictoModus: 'varselstatus', //'tiltak
    counters: {
      item1: 0,
      item2: 0
    }
  };

  handleDragStart = e => {
    e.dataTransfer.setData('text', e.target.id);
    this.setState({ draggedEvent: e.target.id });
  };

  customOnDragOver = event => {
    // check for undroppable is specific to this example
    // and not part of API. This just demonstrates that
    // onDragOver can optionally be passed to conditionally
    // allow draggable items to be dropped on cal, based on
    // whether event.preventDefault is called
    if (this.state.draggedEvent !== 'undroppable') {
      event.preventDefault();
    }
  };

  onDropFromOutside = ({ start, end, allDay }) => {
    debug('start: ', start);
    debug('end: ', end);

    if (this.state.draggedEvent === null) {
      return;
    }
    let startX;
    let endX;
    debug('View: ' + this.state.viewState);
    switch (this.state.viewState) {
      case 'month':
        startX = start;
        endX = eventDateTimePluss(start, 0, 1);
        break;
      case 'work_week':
        startX = start;
        endX = eventDateTimePluss(start, 0, 1);
        break;
      default:
        startX = start;
    }
    const { draggedEvent } = this.state;
    this.setState({ draggedEvent: null });

    let listeUten = this.state.eventsUten;
    let nyEvent = null;
    let xxx = -1;
    for (let i = 0; i < listeUten.length; i++) {
      if (listeUten[i].id.toString() === draggedEvent.toString()) {
        nyEvent = listeUten[i];
        xxx = i;
        break;
      }
    }
    if (nyEvent !== null) {
      nyEvent.start = startX;
      nyEvent.end = endX;

      listeUten.splice(xxx, 1);
      this.setState({ eventsUten: listeUten });

      this.newEvent(nyEvent);
    }
  };

  newEvent = event => {
    let ev = this.state.events;
    ev.push(event);
    this.setState({ events: ev });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ events: nextProps.events });
    this.setState({ eventsUten: nextProps.eventsUten });
  }

  onEventResize = ({ event, start, end, allDay }) => {
    this.endreEvent({ event, start, end, allDay });
  };

  onEventDrop = ({ event, start, end, allDay }) => {
    this.endreEvent({ event, start, end, allDay });
  };

  onEventClick = e => {
    console.log(e);
  };

  onSlotSelect = ({ start, end }) => {
    const title = window.prompt('Tittel');
    const farge = '#D3D3D3';
    const tiltakBruksenhet = null;
    const tiltakRoyklop = null;
    const varselStatus = null;
    if (title) {
      this.setState({
        events: [
          ...this.state.events,
          {
            start,
            end,
            title,
            farge,
            tiltakBruksenhet,
            tiltakRoyklop,
            varselStatus
          }
        ]
      });
    }
  };

  endreEvent = ({ event, start, end, allDay }) => {
    let events = this.state.events;
    var endreEvent = events.find(item => item.id === event.id);
    events = _.reject(events, endreEvent);

    event.start = start;
    event.end = end;
    //  event.farge = 'yellowgreen';
    events.push(event);

    this.setState({ events: events });
  };

  onVisUten = () => {
    let h = this.state.hideUten;
    if (h === 'hide') {
      this.setState({ hideUten: '' });
      this.setState({ hideUtenBtn: 'hide' });
    } else {
      this.setState({ hideUten: 'hide' });
      this.setState({ hideUtenBtn: '' });
    }
  };

  getWeekNr = date => {
    return '(uke ' + weekNummer(date) + ')';
  };

  getTilsynIcon = event => {
    if (this.state.pictoModus === 'tiltak') {
      if (event.tiltakBruksenhet !== null) {
        return (
          <NKIcon icon='tilsyn' size='0.6' color={getTextColor(event.farge)} />
        );
      }
    } else {
      return null;
    }
  };

  getRoyklopIcon = event => {
    if (this.state.pictoModus === 'tiltak') {
      if (event.tiltakRoyklop !== null) {
        return (
          <NKIcon icon='royklop' size='0.7' color={getTextColor(event.farge)} />
        );
      }
    } else {
      return null;
    }
  };

  getVarselStatusIcon = event => {
    if (this.state.pictoModus === 'varselstatus') {
      if (event.varselStatus !== null) {
        return (
          <span style= {{ width: '50px', height: '100%', backgroundColor: 'pink'}}>
            <NKIcon
              style={{ marginTop: '2px' }}
              icon='varsel'
              size='0.7'
              color='black'
            /> 
          </span>
        );
      }
    } else {
      return null;
    }
  };

  navigate = action => {
    this.props.onNavigate(action);
  };

  onModus = () => {
    let modus = this.state.pictoModus;
    if (modus === 'tiltak') {
      modus = 'varselstatus';
    } else {
      modus = 'tiltak';
    }
    this.setState({ pictoModus: modus });
  };

  getCustomToolbar = toolbar => {
    this.toolbarDate = toolbar.date;

    const goToDayView = () => {
      toolbar.onView('day');
      this.setState({ viewState: 'day' });
    };

    const goToWeekView = () => {
      toolbar.onView('work_week');
      this.setState({ viewState: 'work_week' });
    };

    const goToMonthView = () => {
      toolbar.onView('month');
      this.setState({ viewState: 'month' });
    };

    const goToAgendaView = () => {
      toolbar.onView('agenda');
      this.setState({ viewState: 'agenda' });
    };

    const goToBack = () => {
      let view = this.state.viewState;
      let mDate = toolbar.date;
      let newDate;
      if (view === 'month' || view === 'agenda') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
      } else if (view === 'work_week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() - 1,
          1
        );
      }
      toolbar.onNavigate('prev', newDate);
    };

    const goToNext = () => {
      let view = this.state.viewState;
      let mDate = toolbar.date;
      let newDate;
      if (view === 'month' || view === 'agenda') {
        newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
      } else if (view === 'work_week') {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 7,
          1
        );
      } else {
        newDate = new Date(
          mDate.getFullYear(),
          mDate.getMonth(),
          mDate.getDate() + 1,
          1
        );
      }
      toolbar.onNavigate('next', newDate);
    };

    const goToToday = () => {
      /*        const now = new Date();
        toolbar.date.setDate(now.getDate());
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        console.log(toolbar);
        toolbar.onNavigate('current', now);   */
      toolbar.onNavigate('TODAY');
    };

    const goToBackYear = () => {
      let mDate = toolbar.date;
      let newDate = new Date(mDate.getFullYear() - 1, 1);
      toolbar.onNavigate('prev', newDate);
    };

    const goToNextYear = () => {
      let mDate = toolbar.date;
      let newDate = new Date(mDate.getFullYear() + 1, 1);
      toolbar.onNavigate('next', newDate);
    };

    const month = () => {
      const date = moment(toolbar.date);
      let month = date.format('MMMM');

      return (
        <span style={{ marginLeft: '4px', marginRight: '4px' }}>{month}</span>
      );
    };

    const week = () => {
      if (this.state.viewState === 'work_week') {
        let weekNr = this.getWeekNr(toolbar.date);
        return (
          <span style={{ marginLeft: '4px', marginRight: '4px' }}>
            {weekNr}
          </span>
        );
      } else {
        return <span />;
      }
    };

    const year = () => {
      const date = moment(toolbar.date);
      let year = date.format('YYYY');

      return (
        <span className='rbc-btn-group'>
          {this.state.viewState === 'month' && (
            <button type='button' onClick={goToBackYear}>
              <span className='prev-icon'>&#8249;&#8249;</span>
            </button>
          )}
          <span style={{ marginLeft: '4px', marginRight: '4px' }}>{year}</span>
          {this.state.viewState === 'month' && (
            <button type='button' onClick={goToNextYear}>
              <span className='prev-icon'>&#8250;&#8250;</span>
            </button>
          )}
        </span>
      );
    };

    const day = () => {
      let view = this.state.viewState;
      const date = moment(toolbar.date);
      let day;
      if (view === 'day') {
        day = date.format('ddd') + ' ' + date.format('Do');
      }
      return <span>{day}</span>;
    };

    const onFilterClick = () => {
      this.onFilter();
    };

    const onVisUtenClick = () => {
      this.onVisUten();
    };

    const onModus = () => {
      this.onModus();
    };

    return (
      <div className='rbc-toolbar'>
        <span className='btn-group pull-left'>
          <button
            className={'btn btn-default ' + this.state.hideUtenBtn}
            style={{ marginRight: '8px', height: '32px' }}
            onClick={onVisUtenClick}>
            <span>Uten dato</span>
          </button>
          <button
            className=' btn btn-default'
            style={{ marginRight: '8px', height: '32px' }}
            onClick={onFilterClick}>
            <span>Filter</span>
          </button>
          <button
            className='btn btn-default'
            onClick={goToBack}
            style={{ height: '32px' }}>
            <span className='prev-icon'>&#8249;</span>
          </button>
          <button
            className='btn btn-default'
            onClick={goToToday}
            style={{ height: '32px' }}>
            <span className='next-icon'>I dag</span>
          </button>
          <button
            className='btn btn-default'
            onClick={goToNext}
            style={{ height: '32px' }}>
            <span className='next-icon'>&#8250;</span>
          </button>

          <button
            className='btn btn-default'
            onClick={onModus}
            style={{ height: '32px' }}>
            Modus
          </button>
        </span>

        <span className='rbc-toolbar-label'>
          {day()}
          {month()}
          {year()}
          {week()}
        </span>

        <span className='btn-group pull-right' data-toggle='buttons'>
          <button
            className='btn btn-default active'
            onClick={goToMonthView}
            style={{ height: '32px' }}>
            <input type='radio' checked />
            Måned
          </button>
          <button
            className='btn btn-default'
            onClick={goToWeekView}
            style={{ height: '32px' }}>
            <input type='radio' />
            Uke
          </button>
          <button
            className='btn btn-default'
            onClick={goToDayView}
            style={{ height: '32px' }}>
            <input type='radio' />
            Dag
          </button>
          <button
            className='btn btn-default'
            onClick={goToAgendaView}
            style={{ height: '32px' }}>
            <input type='radio' />
            Agenda
          </button>
        </span>
      </div>
    );
  };

  onFilter = () => {
    this.props.onFilter();
  };

  onDragOver = event => {
    event.preventDefault();
  };

  render() {
    const MonthEvent = ({ event }) => (
      <div style={{ padding: '0', margin: '0', verticalAlign: 'text-top' }}>
        {this.getTilsynIcon(event)}
        {this.getRoyklopIcon(event)}
       {/*  {this.getVarselStatusIcon(event)} */}
        <span style={{ width: '70px', height: '100%', color: 'black', backgroundColor: 'pink' }}>H</span>
        <span style={{ padding: '0', marginLeft: '2px' }}>{event.title}</span>
        {event.tiltakBruksenhet}
        {event.tiltakRoyklop}
       {/*  {event.varselStatus} */}
      </div>
    );

    const DayEvent = ({ event }) => (
      <div>
        {this.getTilsynIcon(event)}
        {this.getRoyklopIcon(event)}
        {this.getVarselStatusIcon(event)}
        <span style={{ marginLeft: '2px' }}>{event.title}</span>

        {event.tiltakBruksenhet}
        {event.tiltakRoyklop}
        {event.varselStatus}
      </div>
    );

    let today = moment();
    let am6 = today
      .set('hour', 6)
      .set('minutes', 0)
      .toDate();
    let pm8 = today
      .set('hour', 21)
      .set('minutes', 0)
      .toDate();
    let teller = 0;
    return (
      <div>
        <div>
          <br />
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td
                  className={this.state.hideUten}
                  style={{ width: '15%', verticalAlign: 'top' }}>
                  <div style={{ height: '600px', overflowY: 'auto' }}>
                    <button
                      className='btn btn-default pull-right'
                      onClick={this.onVisUten}
                      style={{ height: '32px', marginRight: '4px' }}>
                      X
                    </button>
                    <br />
                    <label style={{ fontSize: '18px' }}>Uten dato</label>

                    {this.state.eventsUten.map(function(event) {
                      teller++;
                      var spId = 'sp' + teller;
                      var iId = 'i' + teller;
                      return (
                        <div
                          style={{
                            margin: '4px',
                            borderColor: event.border,
                            height: '50px',
                            padding: '4px',
                            borderStyle: 'solid',
                            borderWidth: '3px',
                            backgroundColor: event.farge,
                            color: getTextColor(event.farge)
                          }}
                          id={event.id}
                          draggable='true'
                          onDragStart={this.handleDragStart}>
                          {event.title}
                        </div>
                      );
                    }, this)}
                  </div>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <DragAndDropCalendar
                    onDropFromOutside={this.onDropFromOutside}
                    onDragOver={this.customOnDragOver}
                    draggableAccessor={event => true}
                    defaultDate={new Date()}
                    defaultView='month'
                    views={['month', 'work_week', 'day', 'week', 'agenda']}
                    localizer={localizer}
                    popup={true}
                    /*  resources={this.state.resources}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle" */
                    events={this.state.events}
                    onEventResize={this.onEventResize}
                    onEventDrop={this.onEventDrop}
                    onSelectEvent={this.onEventClick}
                    //  onSelectSlot={(slotInfo) => this.onSlotSelect.bind(slotInfo)}
                    onSelectSlot={this.onSlotSelect}
                    startAccessor='start'
                    endAccessor='end'
                    resizable
                    selectable
                    toolbar={true}
                    min={am6}
                    max={pm8}
                    step={15}
                    showMultiDayTimes={true}  
                    messages={messages}
                    style={{ height: '100vh' }}
                    longPressThreshold={250}
                    eventPropGetter={event => ({
                      style: {
                        backgroundColor: event.farge,
                        borderStyle: 'solid',
                        borderWidth: '3px',
                        padding: '0px',
                        margin: '0px',
                        color: getTextColor(event.farge),
                        borderColor: event.border
                      }
                    })}
                    components={{
                      // event: MonthEvent,
                      toolbar: this.getCustomToolbar,
                      day: {
                        event: DayEvent
                      },
                      agenda: {
                        event: MonthEvent
                      },
                      month: {
                        event: MonthEvent,
                        dateHeader: ({ date, label }) => {
                          let highlight = sjekkOmRoedDag(date);
                          if (highlight === '') {
                            return <div>{label}</div>;
                          } else {
                            return (
                              <div style={{ color: 'red', fontWeight: 'bold' }}>
                                {label + ' (' + highlight + ')'}
                              </div>
                            );
                          }
                        }
                      }
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default KalenderBasis;
