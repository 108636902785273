import React from 'react';
import TimeInput from 'components/TimeInput/TimeInputFraTilUtenLabel_10min';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import InfoDialog from 'components/Dialogs/InformationDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:planlegging:varslemanuell');

moment.locale('nb', {
  week: {
    dow: 1,
    doy: 1,
  },
});

class VarsleManuell extends React.Component {
  state = {
    startDato: moment(),
    infoDialogIsOpen: false,
    startFra: '',
    startTil: '',
  };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    if (this.props.enhet.planlagtDato) {
      this.setState({ startDato: moment(this.props.enhet.planlagtDato) });
    }
    this.setState({ startFra: this.props.enhet.planlagtBesokFra });
    this.setState({ startTil: this.props.enhet.planlagtBesokTil });
  };

  onClickOkNy = () => {
    // moment("2013-11-18").tz("Europe/Berlin").format('Z');
    if (this.checkTimes(this.state.startFra, this.state.startTil)) {
      let d = moment(this.state.startDato)
        .add(3, 'h')
        .toDate();
      let data = {
        dato: d,
        startFra: this.state.startFra,
        startTil: this.state.startTil,
      };
      debug('999', data);
      this.props.onOkNy(data);
    }
  };

  DatoChange = (date) => {
    this.setState({ melding: '' });
    let valgtDag = date.format('DD.MM.YYYY');
    let now = moment().subtract(1, 'd');
    if (date < now) {
      this.setState({ melding: 'dato' });
    }
    this.setState({ valgtDag: valgtDag });
    this.setState({ startDato: date });
  };

  TilChange = (val) => {
    this.setState({ startTil: val.target.value });
  };

  FraChange = (val) => {
    this.setState({ startFra: val.target.value });
  };

  checkTimes = (fra, til) => {
    console.log(fra, til);
    if ( !fra  && !til ) {
      return true;
    }
    if (fra.indexOf(':') === -1 || fra.length !== 5) {
      this.dialogs.setOpenDialog('InfoDialog', {
        tittel: 'Tid',
        melding: 'Feil format på tid.',
      });
      return false;
    };
    if (til.indexOf(':') === -1 || til.length !== 5) {
      this.dialogs.setOpenDialog('InfoDialog', {
        tittel: 'Tid',
        melding: 'Feil format på tid.',
      });
      return false;
    };
    let fraD = new Date();
    fraD.setHours(fra.substr(0, fra.indexOf(':')));
    fraD.setMinutes(fra.substr(fra.indexOf(':') + 1));
    fraD.setSeconds(0);

    let tilD = new Date();
    tilD.setHours(til.substr(0, til.indexOf(':')));
    tilD.setMinutes(til.substr(til.indexOf(':') + 1));
    tilD.setSeconds(0);

    if (tilD < fraD) {
      this.dialogs.setOpenDialog('InfoDialog', {
        tittel: 'Tid',
        melding: 'Fra må komme etter Til.',
      });
      return false;
    }

    return true;
  };

  render() {
    return (
      <React.Fragment>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              onClick={this.props.onAvbryt}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'>Varsle bruksenhet manuelt</h4>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group '>
                  <label>Adresse</label>
                  <br />
                  <div>{this.props.enhet.adresse}</div>
                </div>
                <div className='form-group '>
                  <label>Dato</label>
                  <br />
                  <DatePicker
                    className='width90 h30 text-center '
                    readOnly={true}
                    selected={this.state.startDato}
                    onChange={this.DatoChange.bind(this)}
                    showYearDropdown
                  />

                  {this.state.melding && (
                    <fragment>
                     {/*  <br /> */}
                      <span style={{ color: 'blue' }}>
                        Datoen er tilbake i tid.
                      </span><br/>
                      <span style={{ color: 'blue' }}>
                        Men du kan fortsette.
                      </span>
                    </fragment>
                  )}
                </div>
                <div className='form-group '>
                  <label>Tidspunkt fra - til</label>
                  <br />
                  <TimeInput
                    className='mt4'
                    handleTilSelect={this.TilChange.bind(this)}
                    handleFraSelect={this.FraChange.bind(this)}
                    tilValue={this.state.startTil}
                    fraValue={this.state.startFra}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button className='btn btn-primary' onClick={this.onClickOkNy}>
              OK
            </button>
            {/* <NkAdminLiButton 
                    onClick={this.onClickOkNy}>
                      Test - OK 
            </NkAdminLiButton> */}
          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </React.Fragment>
    );
  }
}

export default VarsleManuell;

// Declarative parts

const modalDialogs = {
  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { tittel, melding } = dialogData;
    return (
      <InfoDialog
        isOpen={isOpen}
        tittel={tittel}
        melding={melding}
        onClose={onClose}
      />
    );
  },
};
