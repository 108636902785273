import React, { Component } from "react";
import { svarutTest } from "api/arkivApi";
import ReactFileReader from "react-file-reader";

// import VisBrukere from './VisBrukere.jsx';
// import VisSakskategori from './VisSakskategori.jsx';
var _ = require("underscore");
var obj;
let style1 = {
  height: "1px",
};
class SvarUtTest extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      fil: null,
      svar: null,
      id: null,
      status: null,
      laster: false
    };
  }

  componentDidMount() {
    
  }

  handleFiles = (files) => {
    console.log(files);
    this.setState({ fil: files, base64: files.base64 });
  };

  submit() {
    var a = this.state.base64;
    var b = a.indexOf(",");
    var base64string = a.substr(b + 1, a.length - 1);
    var filinnhold = {
      filnavn: this.state.fil.fileList[0].name,
      mimetype: this.state.fil.fileList[0].type,
      base64: base64string,
    };
    this.setState({ laster: true });
    svarutTest(filinnhold, this.svar);
  }
  svar = (err, svar) => {
    this.setState({ svar: svar, laster: false });
  };

  render() {
    if (this.state.laster) {
      return <div className='loader' />;
    }
    if (!this.state.fil) {
      var filInfo = <div>Fil ikke opplastet</div>;
    } else {
      filInfo = <div>{this.state.fil.fileList[0].name}</div>;
    }
    if (!this.state.svar) {
      var res = <div>Ikke sent</div>;
    } else {
      res = <div>
        id: {this.state.svar.id}
        <br/>
        statusQueryGetUri: <a>{this.state.svar.statusQueryGetUri}</a>
      </div>;
    }
    return (
      <div style={{ margin: '30px' }}>
        <h1>SvarUt test</h1>
        <hr />
        <p>
          Tester sending av pdf dokument til SvarUt api'et. Retur er et json objekt som inneholder id, url for å sjekke status på forsendelsen...
        </p>
        <p />
        <div className="row">
          <div className="col-sm-4">
            <div className="pull-left">
              <ReactFileReader
                base64={true}
                multipleFiles={false}
                fileTypes = "/*"
                handleFiles={this.handleFiles}
              >
                <button className="btn">Last opp fil</button>
              </ReactFileReader>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="pull-left">
              <div>{filInfo}</div>
              <br />
            </div>
          </div>
        </div>
        <hr/>

        <div>{res}</div>
             
            
      </div>
    );
  }
}

export default SvarUtTest;
