import moment from 'moment';
import 'moment/locale/nb';
import React from 'react';
import enhetApi from 'api/enhetApi';
import royklopApi from 'api/royklopApi.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'modules/Gjennomforing/gjennomforing.css';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { sjekkBruksenhetRisiko, sjekkRoyklopRisiko } from 'api/risikoApi.js';

var _ = require('underscore');

var paaLogget = null;
var hyppighetListe = null;
var aarsakListe = null;
var htekst = '';
var typeData= null;
var risikoOverstyr = false;

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={owner.closeSlettDialogOk}
      />
    );
  }
};

class nyttTiltakBruksenhet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeData: null,
      tiltakTypeId: null,
      dato: null,
      selectedRadio: 'utfort',
      hyppighet: '',
      utfortav: '',
      merknad: '',
      startDate: moment(),
      endDate: moment().add(2, 'month'),
      sotmengde: null,
      vissotmengde: null,
      arsakId: null,
      sotMengder: [],
      risiko: false,
      tiltakRisiko: false,
      fritidsbygg: [],
      byggkode: 0,
      risikoSjekk: false
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  componentDidMount = () => {
    var arbId =
      this.props.arbeidslisteId === undefined ||
      this.props.arbeidslisteId === null
        ? 0
        : this.props.arbeidslisteId;
    if (this.props.type === 'bruksenhet') {
      this.setState({ vissotmengde: 'hide' });
      enhetApi.getBruksenhetTiltakOppslagsdataForArbOgBr(
        arbId,
        this.props.id,
        this.gotOppslagsTypeData
      );
    } else {
      royklopApi.getRoyklopTiltakOppslagsdataForArbOgBr(
        arbId,
        this.props.id,
        this.gotOppslagsTypeData
      );
    }
  };

  gotOppslagsTypeData = (err, typedata) => {
    console.log(typedata);
    typeData = Object.assign({}, typedata);
    console.log(typeData);
    risikoOverstyr =
      this.props.type == 'bruksenhet'
        ? typedata.bruksenhetRisikoOverstyr
        : typedata.royklopRisikoOverstyr;
    let planlagtTekst = '(Planlagt)';
    let tmpTyper = null;
    if (typeData && typeData.tiltakTypeListe) {
      tmpTyper = typeData.tiltakTypeListe.map(item => {
        item.tekst += item.arbeidslisteIkkeUtfort ? '  ' + planlagtTekst : '';
        return item;
      });
    }
    if (tmpTyper) {
      //Sorter tekster med 'Planlagt' først
      let planlagtListe = tmpTyper.filter(item =>
        item.tekst.includes(planlagtTekst)
      );
      let ikkePlanlagtListe = tmpTyper.filter(
        item => !item.tekst.includes(planlagtTekst)
      );
      typeData.tiltakTypeListe = planlagtListe.concat(ikkePlanlagtListe);

      if (
        planlagtListe === undefined ||
        planlagtListe === null ||
        planlagtListe.length === 0
      ) {
        // Ingen planlagte, legg på blank
        let blank = {
          aktiv: false,
          arbeidslisteIkkeUtfort: false,
          hyppighetId: 0,
          id: 0,
          standard: true,
          tekst: '',
          tiltakObjekt: null,
          tiltakObjektId: 0
        };
        typeData.tiltakTypeListe.unshift(blank);
      }
    }
    if (typeData && typeData.tiltakSotmengde) {
      let listeSotmender = typedata.tiltakSotmengde;
      listeSotmender.unshift({ id: null, tekst: '', aktiv: true });
      this.setState({ sotMengder: listeSotmender });
    }
    this.setState({ typeData: typeData });
    if (this.props.type == 'bruksenhet') {
      sjekkBruksenhetRisiko(this.props.id, this.gotRisikoSjekk);
    } else {
      sjekkRoyklopRisiko(this.props.id, this.props.brId, this.gotRisikoSjekk);
    }
  };
  
  gotRisikoSjekk = (err, res) => {
    this.setState({ risikoSjekk: res });
    this.setDefaultData(typeData, res);
  }

  setDefaultData = (typeData, risikoSjekk) => {
    this.setState({ tiltakTypeId: typeData.tiltakTypeListe[0].id });

    // Legg til blank i hyppighet listen
    hyppighetListe = typeData.tiltakHyppighetListe;
    let blank = {
      fremmedNokkel: null,
      id: 0,
      tekst: '',
      tekst2: ''
    };
    hyppighetListe.unshift(blank);
    console.log(hyppighetListe);
    aarsakListe = typeData.tiltakArakIkkeUtfort;
    let bl = {
      tekst: '',
      id: 0,
      aktiv: false
    };
    aarsakListe.unshift(bl);
    console.log(hyppighetListe);
    // Sjekk hviken hyppighet som skal settes default
    let tiltak = typeData.tiltakTypeListe[0];
    this.setState({ tiltakRisiko: tiltak.risikovurdering });

    if (risikoOverstyr || !risikoSjekk) {
      this.setState({ risiko: false });
    } else {
      this.setState({
        risiko: tiltak.risikovurdering,
        hyppighet: 0,
        utfortav: typeData.tiltakPersoner[0].id
      });
    }
    

    if (tiltak.id === 0) {
      this.setState({ hyppighet: hyppighetListe[0].id });
    }
    if (tiltak.id !== 0 && tiltak.hyppighetId === 0) {
      this.setState({ hyppighet: hyppighetListe[0].id });
    } else {
      let id = 0;
      let h = _.find(hyppighetListe, function(x) {
        return x.id === tiltak.hyppighetId;
      });
      if (h) {
        id = h.id;
      }
      this.setState({ hyppighet: id });
    }
    console.log(tiltak);
    paaLogget = typeData.tiltakPersoner.findIndex(x => x.paaLogget);
    if (paaLogget !== -1) {
      this.setState({
        utfortav: typeData.tiltakPersoner[paaLogget].id
      });
    } else {
      this.setState({
        utfortav:
          typeData.tiltakPersoner != null ? typeData.tiltakPersoner[0].id : null
      });
    }

    if (this.props.type === 'royklop') {
      this.setState({ sotmengde: typeData.tiltakSotmengde[0].id });
    }
  };

  submit = data => {
    if (this.state.tiltakTypeId === 0) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Nytt tiltak',
        melding: 'Du må velge tiltak.'
      });
    } else {
      this.submitOk();
    }
  };
  submitOk = () => {
    var tid = this.state.tiltakTypeId;
    var hid = this.state.hyppighet;

    var tiltak = _.find(this.state.typeData.tiltakTypeListe, function(tiltak) {
      return tiltak.id === parseInt(tid, 10);
    });
    var hyppighet = _.find(this.state.typeData.tiltakHyppighetListe, function(
      hyppighet
    ) {
      return hyppighet.id === parseInt(hid, 10);
    });
    if (this.props.type === 'bruksenhet') {
      this.props.onNyttTiltak(
        {
          bruksenhetId: this.props.id,
          dato: new Date(this.state.startDate),
          tiltakTypeId: this.state.tiltakTypeId,
          utfort: this.state.selectedRadio === 'utfort' ? true : false,
          arsakIkkeUtfortId: this.state.arsakId,
          hyppighetId:
            this.state.hyppighet === 0 || this.state.hyppighet === '0'
              ? null
              : this.state.hyppighet,
          utfortAvId: this.state.utfortav,
          merknad: this.state.merknad,
          arbeidslisteId: null,
          arbeidslisteInfo: null
        },
        tiltak.tekst,
        hyppighet !== undefined ? hyppighet.tekst2 : null,
        this.state.risiko,
        this.state.risiko,
        this.state.risiko
      );
    } else {
      this.props.onNyttTiltak(
        {
          royklopId: this.props.id,
          tiltakTypeId: this.state.tiltakTypeId,
          dato: new Date(this.state.startDate),
          utfort: this.state.selectedRadio === 'utfort' ? true : false,
          arsakIkkeUtfortId: this.state.arsakId,
          hyppighetId:
            this.state.hyppighet === 0 || this.state.hyppighet === '0'
              ? null
              : this.state.hyppighet,
          utfortAvId: this.state.utfortav,
          sotmengdeId: this.state.sotmengde,
          merknad: this.state.merknad,
          arbeidslisteId: null,
          arbeidslisteInfo: null
        },
        tiltak.tekst,
        hyppighet !== undefined ? hyppighet.tekst2 : null,
        this.state.risiko,
        this.state.risiko,
        this.state.risiko
      );
    }
  };

  closeSlettDialogOk = () => {
    this.dialogs.closeDialog();
  };

  handleDatePickerChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleTypeChange = data => {
    console.log(this.state.risiko);
    this.setState({
      tiltakTypeId: data.target.value
    });
    // Sett hyppighet basert på tiltaktypeid
    var tiltak = _.find(this.state.typeData.tiltakTypeListe, function(x) {
      return x.id == data.target.value;
    });
    if (tiltak !== undefined && tiltak !== null) {
      if (tiltak.hyppighetId == 0) {
        this.setState({ hyppighet: hyppighetListe[0].id });
      } else {
        let id = 0;
        let h = _.find(hyppighetListe, function(x) {
          return x.id === tiltak.hyppighetId;
        });
        if (h) {
          id = h.id;
        }
        this.setState({ hyppighet: id });
      }
    }
    this.setState({ tiltakRisiko: tiltak.risikovurdering });
    if (risikoOverstyr || !this.state.risikoSjekk) {
      this.setState({ risiko: false });
    } else {
      this.setState({ risiko: tiltak.risikovurdering });
      if (tiltak.risikovurdering) {
        this.setState({ hyppighet: null });
        htekst = '';
      }
    }
    console.log(this.state.risiko);
  };

  handleArsakChange = data => {
    this.setState({
      arsakId: data.target.value == 0 ? null : data.target.value
    });
  };

  handleHyppighetChange = data => {
    this.setState({
      hyppighet: data.target.value
    });
  };

  handleUtfortavChange = data => {
    this.setState({
      utfortav: data.target.value
    });
  };

  handleSotmengdeChange = data => {
    this.setState({
      sotmengde: data.target.value
    });
  };

  handleMerknadChange = data => {
    this.setState({
      merknad: data.target.value
    });
  };

  handleRadioChange = event => {
    this.setState({ selectedRadio: event.currentTarget.value });
  };

  getIdFirstTiltaktype = () => {
    let { tiltakTypeListe } = this.state.typeData;
    //console.log(!_.isEmpty(tiltakTypeListe) ? tiltakTypeListe[0].id : null);
    return !_.isEmpty(tiltakTypeListe) ? tiltakTypeListe[0].id : null;
  };

  render() {
    if (!this.state.typeData) {
      return <div>Laster...</div>;
    }
    var aarsak = null;
    if (this.state.selectedRadio === 'ikkeutfort') {
      aarsak = (
        <div className='form-group'>
          <label>Årsak ikke utført</label>
          <select
            className='form-control'
            id='type'
            onChange={this.handleArsakChange}>
            {_.map(aarsakListe, function(o, i) {
              return (
                <option key={i} value={o.id}>
                  {o.tekst}
                </option>
              );
            })}
            {/* <option>Ingen hjemme</option>
                            <option>Ufremkommelig</option>
                            <option>Annen årsak</option> */}
          </select>
        </div>
      );
    }
    return (
      <div>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              onClick={this.props.onClose}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'>Tiltak</h4>
          </div>
          <div className='modal-body'>
            <div className='form'>
              <div className='row'>
                <div className='col-sm-12 pad32' style={{ paddingTop: '20px' }}>
                  <div className='form-group'>
                    <label>
                      Dato
                      <DatePicker
                        className='width120Heigth32'
                        selected={this.state.startDate}
                        onChange={this.handleDatePickerChange}
                        isClearable={true}
                        showYearDropdown
                      />
                    </label>
                  </div>
                  <div className='form-group'>
                    <label>Type tiltak</label>
                    <select
                      className='form-control'
                      id='type'
                      onChange={this.handleTypeChange}
                      defaultValue={this.getIdFirstTiltaktype()}>
                      {_.map(this.state.typeData.tiltakTypeListe, function(
                        o,
                        i
                      ) {
                        return (
                          <option key={i} value={o.id}>
                            {o.tekst}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {this.state.risiko ? (
                    <div className='form-group'>
                      Valgt tiltakstype er tilknyttet risikovurdering. Hvis det
                      er ønskelig å overstyre risikovurderingen kan dette gjøres
                      med knappen Endre på objektet.
                    </div>
                  ) : null}
                  <div className='form-group'>
                    <label>Hyppighet</label>
                    {this.state.risiko ? (
                      <div>
                        <input
                          className='form-control'
                          type='text'
                          id='message'
                          readOnly
                          value={htekst}
                        />
                      </div>
                    ) : (
                      <div>
                        <select
                          className='form-control'
                          id='type'
                          onChange={this.handleHyppighetChange}
                          value={this.state.hyppighet}>
                          {_.map(hyppighetListe, function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <label>Utført av</label>
                    <select
                      className='form-control'
                      id='type'
                      onChange={this.handleUtfortavChange}
                      value={this.state.utfortav}>
                      {_.map(this.state.typeData.tiltakPersoner, function(
                        o,
                        i
                      ) {
                        return (
                          <option key={i} value={o.id}>
                            {o.navn}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={'form-group ' + this.state.vissotmengde}>
                    <label>Sotmengde</label>
                    <select
                      className='form-control'
                      id='type'
                      onChange={this.handleSotmengdeChange}
                      defaultValue={this.state.sotmengde}>
                      {_.map(this.state.sotMengder, function(o, i) {
                        return (
                          <option key={i} value={o.id}>
                            {o.tekst}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className='form-group'>
                    <label>
                      <input
                        style={{ marginRight: '10px' }}
                        type='radio'
                        name='utfort'
                        value='utfort'
                        checked={this.state.selectedRadio === 'utfort'}
                        onChange={this.handleRadioChange}
                      />
                      Utført
                    </label>
                  </div>
                  <div className='form-group'>
                    <label>
                      <input
                        style={{ marginRight: '10px' }}
                        type='radio'
                        name='ikkeutfort'
                        value='ikkeutfort'
                        checked={this.state.selectedRadio === 'ikkeutfort'}
                        onChange={this.handleRadioChange}
                      />
                      Ikke utført
                    </label>
                  </div>
                  {/*<label className="margin-right-15">Utført
                                    <input
                                            type="radio"
                                            name="utfort"
                                            value="utfort"
                                            checked={this.state.selectedRadio === 'utfort'}
                                            onChange={this.handleRadioChange} />
                                    </label>
                                    <label>Ikke Utført
                                    <input
                                            type="radio"
                                            name="ikkeutfort"
                                            value="ikkeutfort"
                                            checked={this.state.selectedRadio === 'ikkeutfort'}
                                            onChange={this.handleRadioChange} />
                                    </label>*/}
                  <p />
                  {aarsak}
                  <div className='form-group'>
                    <label>Merknad</label>
                    <textarea
                      className='form-control textareaNoresize'
                      type='textarea'
                      id='message'
                      placeholder='Merknad'
                      maxLength='1000'
                      rows='4'
                      onChange={this.handleMerknadChange}
                    />
                  </div>
                  <div className='pull-right'>
                    <input
                      type='button'
                      className='btn btn-primary height48'
                      value='OK'
                      onClick={this.submit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default nyttTiltakBruksenhet;
