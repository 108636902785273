import React from 'react';
import { grunnbokRapport } from 'api/grunnbokApi';
import './matrikkelinfo.css';
import Adresseinfo from './Adresseinfo.jsx';
import Bygningsinfo from './Bygningsinfo.jsx';
import Eierinfo from './Eierinfo.jsx';
import IkkeTinglystEierinfo from './IkkeTinglystEierinfo.jsx';
import { PanelGroup } from 'react-bootstrap';
import Matrikkelenhet from './MatrikkelEnhet.jsx';
import Hjemmelshavere from './Hjemmelshavere.jsx';
import { createErrorBoundary } from 'components/exceptions/ErrorBoundary.jsx';
let eventKeyIndex = 1;

const EnhetBoundary = createErrorBoundary({
  handledAt: () => 'MatrikkelInfo:EnhetBoundary',
  FallbackUi: () => (<div>Feil ved uthenting av matrikkelenhet</div>),
});
const HjemmelsehavereBoundary = createErrorBoundary({
  handledAt: () => 'MatrikkelInfo:HjemmelsehavereBoundary',
  FallbackUi: () => (<div>Feil ved uthenting av hjemmelshavere</div>),
});
const AdresseBoundary = createErrorBoundary({
  handledAt: () => 'MatrikkelInfo:AdresseBoundary',
  FallbackUi: () => (<div>Feil ved uthenting av adresse</div>),
});
const BygningsBoundary = createErrorBoundary({
  handledAt: () => 'MatrikkelInfo:BygningsBoundary',
  FallbackUi: () => (<div>Feil ved uthenting av bygningsinfo</div>),
});
const EierBoundary = createErrorBoundary({
  handledAt: () => 'MatrikkelInfo:EierBoundary',
  FallbackUi: () => (<div>Feil ved uthenting av eierinfo</div>),
});

class Matrikkelinfo extends React.Component {
  state = {
    rapport: null,
  //  boretter: [],
    feilmelding: null
  };

  componentDidMount= () => {
    console.log('HUSX Mount');
    if (this.props.id) {
      //bruksenhetsIdMa
      grunnbokRapport(this.props.id, (err, data) => {
        if (err) {
          this.setState({
            feilmelding: 'Oppslaget mot matrikkel ga ingen treff '
          });
        }
        console.log('MATX', data);
        this.setState({ rapport: data });
      });
 //     getBorettForBruksenhet(this.props.id, this.onGotBorettForBruksenhet);
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('HUSX Receive');
    if (nextProps.id && this.props.id !== nextProps.id) {
      grunnbokRapport(nextProps.id, (err, data) => {
        this.setState({ rapport: data });
      });
//      getBorettForBruksenhet(nextProps.id, this.onGotBorettForBruksenhet);
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      feilmelding: 'Noe gikk dessverre galt under innlasting av matrikkeldata'
    });
  }

 /*  onGotBorettForBruksenhet = (err, data) => {
    console.log('MATX_2', data);
    let bruksenhetsId = parseInt(this.props.id);
    this.setState({ borett: data });
    if (data) {
      getBorett(
        data.Borett.BorettslagId,
        data.Borett.Andelsnummer,
        (err, data) => {
          if (!data) {
            return;
          }
          let boretter = _.map(data.Kandidater, item => {
            let hjemmelshavere = _.map(item.Hjemmelshavere, eier => eier.Navn);
            if (item.BruksenhetsId === bruksenhetsId) {
              return {
                borettslag: item.Borett.Borettslag,
                bruksenhetsNr: item.Bruksenhetsnummer,
                hjemmelshavere: hjemmelshavere
              };
            }
          });
          this.setState({ boretter: boretter });
        }
      );
    } else {
      if (this.state.boretter) {
        this.setState({ boretter: [] });
      }
    }
  }; */

  getIndex(inc) {
    eventKeyIndex += inc;
    return eventKeyIndex;
  }

  render() {
    if (this.state.feilmelding) {
      return <div>{this.state.feilmelding}</div>;
    }
    if (!this.state.rapport) {
      return <div className="panel-heading">Laster...</div>;
    }
    if (!this.props.id) {
      return <div className="panel-heading">Ingen rad er valgt</div>;
    }

    let {
      eiendomsdata,
      adresser,
      bygninger,
      boretter,
      tinglystEiere,
      ikkeTinglystEiere
    } = this.state.rapport;

/*     let hjemmelshavere = _.map(boretter, borett => {
      if (borett) {
        return _.map(borett.hjemmelshavere, hjemmelshaver => {
          return <li key={hjemmelshaver}>{hjemmelshaver}</li>;
        });
      }
    }); */
    return (
      <div className="scroller">
        <PanelGroup accordion={false} id="matrikkelinfo" defaultActiveKey="1">
          <EnhetBoundary><Matrikkelenhet eiendomsdata={eiendomsdata} /></EnhetBoundary>

          {boretter !== null && (
              <HjemmelsehavereBoundary>
              <Hjemmelshavere
              /*   hjemmelshavere={hjemmelshavere}
                boretter={this.state.boretter} */
                hjemmelshavere={boretter.hjemmelshavere}
                boretter={boretter} 
              />
            </HjemmelsehavereBoundary>
          )}
          
          <AdresseBoundary>
            <Adresseinfo adresser={adresser} eventKeyIndex={this.getIndex(0)} />
          </AdresseBoundary>
          <BygningsBoundary>
            <Bygningsinfo
              bygninger={bygninger}
              valgtBygningsnummer={this.props.bygningsNr}
              eventKeyIndex={this.getIndex(bygninger.length)}
            />
          </BygningsBoundary>
          <EierBoundary>
            <Eierinfo
              key="e1"
              eiere={tinglystEiere}
              eventKeyIndex={this.getIndex(tinglystEiere.length)}
            />
            <IkkeTinglystEierinfo
              key="e2"
              eiere={ikkeTinglystEiere}
              eventKeyIndex={this.getIndex(ikkeTinglystEiere.length)}
            />
          </EierBoundary>
        </PanelGroup>
      </div>
    );
  }
}

export default Matrikkelinfo;
