import React, { Component } from 'react';
import L from 'leaflet';
import moment from 'moment';
import 'moment/locale/nb';
import { Map } from 'react-leaflet';
import Control from 'react-leaflet-control';
import 'components/Dialogs/NKFullModal.css';
import 'components/Kart/KartEnheter.css';
import 'leaflet.fullscreen';
import $ from 'jquery';
import 'leaflet-minimap';
import 'leaflet-minimap/src/Control.MiniMap.css';
import NKIcon from 'nka-icons';
import FullscreenControl from 'components/Kart/Controls/FullscreenControl.jsx';
import LocateControl from 'components/Kart/Controls/LocateControl.jsx';
import GjLayerControl from 'components/Kart/Controls/GjLayerControl.jsx';
import GjMarkerClusterLayerControl from 'components/Kart/Controls/GjMarkerClusterLayerControl.jsx';
import { contextInnstillingerKart, setKartEgenskaperForGebyrKart, contextKartEgenskaperForGebyrKart } from 'context/contextInnstillinger.js';
import DeltPipe from 'images/deltpipe.png';
import Legenda from 'images/legenda1.png';
import ZoomControl from 'components/Kart/Controls/ZoomControl.jsx';
import _ from 'lodash';
import { debugFactory } from 'utils';

delete L.Icon.Default.prototype._getIconUrl;

const debug = debugFactory('nk:enheterkart');


var basisKart = null;

class EnheterKart extends Component {
  state = {
    isMounted: false,
    zoomLevel: 13,
    lat: 63.9905722,
    lng: 12.3077975,
    enheter: this.props.enheter,
    currentEnhet: null,
    visHotelsDiv: true,
    gotClickE: null,
    tikker: 0,
    timeTikker: null,
    visRisiko: 'Ingen',
  };

  componentWillReceiveProps = nextProps => {
     let t = nextProps.timeTikker;
     if (t !== this.state.timeTikker){
      if (!this.state.isMounted) {
        setTimeout(() => {
          this.start();
        }, 200);
      }
     }
  };

  start = () => {
    this.initierKart();
    if (this.state.isMounted === false) {
      this.initierKartEvents();
      this.getSistePosition();
    }
    if (basisKart) {
      basisKart.invalidateSize();
    }

    this.setState({ isMounted: true });
  };

  componentWillUnmount = () => {
    this.setState({ isMounted: false });
    basisKart = null;
    if (this.state.currentEnhet) {    
      setKartEgenskaperForGebyrKart( this.state.currentEnhet.id, this.props.arbeidslisteId);
    }
  };

  getSistePosition = () => {
     //Sjekk om vi er på samme arbeidsliste enn forrige gang
     //Vi ønsker å panorere til forrige position
    if ( contextKartEgenskaperForGebyrKart._currentValue.arbeidslisteId === this.props.arbeidslisteId) {
      this.denne(contextKartEgenskaperForGebyrKart._currentValue.enhetId);
    }
  };

  setKartStateLayer = le => {
    debug('gj kart kartstatelayer **********', le);
  };

  getSenterPunkt = () => {
    if (this.state.isMounted) {
      this.setState({
        lat: contextInnstillingerKart._currentValue.latitude,
        lng: contextInnstillingerKart._currentValue.longitude,
        zoomLevel: contextInnstillingerKart._currentValue.zoomLevel,
      });
    }
    let viewPosition = [
      contextInnstillingerKart._currentValue.latitude,
      contextInnstillingerKart._currentValue.longitude
    ];
    basisKart.setView(viewPosition, contextInnstillingerKart._currentValue.zoomLevel);
  };

  initierKart = () => {
    if (this.refs.basiskart) {
      basisKart = this.refs.basiskart.leafletElement;
      $('.leaflet-control-zoom-fullscreen').hide();

      //Attribution
      basisKart.attributionControl.setPrefix(false);
      basisKart.attributionControl.addAttribution('Norkart Brannforebygging');
    }
  };

  initierKartEvents = () => {
    basisKart.on('zoomend', () => {
      if (this.state.isMounted) {
        this.setState({ zoomLevel: basisKart.getZoom() });
      }
    });
    basisKart.on('click', e => {
      this.setState({ gotClickE: e });
      this.setState({ tikker: this.state.tikker + 1 });
    });
  };

  onClickedGj = (s, e) => {
    let enhet = null;
    for (let index = 0; index < this.state.enheter.length; index++) {
      let item = this.state.enheter[index];
      if (item.id === e.id) {
        enhet = item;
      }
    }
    this.setState({ currentEnhet: enhet });
    this.props.setEnhet(enhet);
    this.slowPanGj(enhet);
  };

  getIndex = id => {
    let result = 0;
    for (let index = 0; index < this.state.enheter.length; index++) {
      let item = this.state.enheter[index];
      if (item.id === id) {
        result = index;
      }
    }
    return result;
  };

  sentrer = () => {
    basisKart.closePopup();
    this.slowPan(this.state.currentEnhet);
  };

  denne = currentEnhetId => {
    let currentEnhet = _.find(this.state.enheter, { id: currentEnhetId });
    basisKart.closePopup();
    if (currentEnhet) {
      this.setState({ currentEnhet: currentEnhet }, this.denne2(currentEnhet));
    }
  };

  denne2 = currentEnhet => {
    this.setState({ currentEnhet: currentEnhet });
    setTimeout(() => {
      this.slowPan(currentEnhet);
    }, 800);
  };

  forrige = () => {
    basisKart.closePopup();
    let index = 0;
    if (this.state.currentEnhet) {
      let id = this.state.currentEnhet.id;
      index = this.getIndex(id) - 1;
      if (index < 0) {
        index = this.state.enheter.length - 1;
      }
    }

    this.setState({ currentEnhet: this.state.enheter[index] });
    this.slowPan(this.state.enheter[index]);
  };

  neste = () => {
    basisKart.closePopup();
    let index = 0;
    if (this.state.currentEnhet) {
      let id = this.state.currentEnhet.id;
      index = this.getIndex(id) + 1;
      if (index > this.state.enheter.length - 1) {
        index = 0;
      }
    }

    this.setState({ currentEnhet: this.state.enheter[index] });
    this.slowPan(this.state.enheter[index]);
  };

  slowPan = enhet => {
    if (enhet) {
      if (enhet.lokasjonLatitude === undefined) {
        return;
      }
      let viewPosition = [enhet.lokasjonLatitude, enhet.lokasjonLongitude];
      debug('SKIPP ', viewPosition);
      debug('SKIPP ', basisKart.getZoom());
      basisKart.setView(viewPosition, basisKart.getZoom(), {
        animate: true,
        pan: {
          duration: 1
        }
      });
      this.setState({ currentEnhet: enhet });
    }

    setTimeout(() => {
      this.alleIconsTilbake(enhet);
    }, 800);
    this.props.setEnhet(enhet);
  };

  slowPanGj = enhet => {
    if (enhet) {
      if (enhet.lokasjonLatitude === undefined) {
        return;
      }
      this.setState({ currentEnhet: enhet });
    }
    let viewPosition = [enhet.lokasjonLatitude, enhet.lokasjonLongitude];



    setTimeout(() => {
      this.alleIconsTilbake(enhet);
      basisKart.setView(viewPosition, basisKart.getZoom(), {
        animate: true,
        pan: {
          duration: 1
        }
      });
    }, 10);
   
    this.props.setEnhet(enhet);
  

  };

  alleIconsTilbake = enhet => {
    basisKart.eachLayer(function(layer) {
      if (layer._icon !== undefined && layer._icon != null) {
        layer._icon.style.width = '25px';
        layer._icon.style.height = '41px';
      }
    });
    setTimeout(() => {
      this.iconSpiderfy(enhet);
    }, 10);
  };

  iconSpiderfy = enhet => {
    basisKart.eachLayer(function(layer) {
      if (layer.getChildCount) {
        if (
          layer._cLatLng.lat === enhet.lokasjonLatitude &&
          layer._cLatLng.lng === enhet.lokasjonLongitude
        ) {
          layer.spiderfy();
        }
      }
    });
    setTimeout(() => {
      this.iconStor(enhet);
    }, 50);
  };

  iconStor = enhet => {
    basisKart.eachLayer(function(layer) {
      if (enhet !== undefined) {
        if (layer._popupData !== undefined && layer._popupData != null) {
          if (layer._popupData.id === enhet.id) {
            if (layer._icon !== undefined && layer._icon != null) {
              layer._icon.style.width = '37px';
              layer._icon.style.height = '61px';

              let zx = layer._zIndex;
              layer.setZIndexOffset(zx + 100);
            }
          }
        }
      }
    });
  };

  getStatus = data => {
    var status = '';
    var index1 = '';
    var utf = '';
    var til = '';
    if (data.tiltakBrukenhetStatus) {
      index1 = data.tiltakBrukenhetStatus.indexOf('/');
      utf =
        // eslint-disable-next-line radix
        parseInt(data.tiltakBrukenhetStatus.substring(0, index1), 10) +
        data.antallLopPaaAlisteBesokt;
      til =
        // eslint-disable-next-line radix
        parseInt(
          data.tiltakBrukenhetStatus.substring(
            index1 + 1,
            data.tiltakBrukenhetStatus.length
          ),
          10
        ) + data.antallLopPaaAliste;
      status = utf + '/' + til;
    }
    if (utf === til) {
      return (
        <span>
          Gjennomført:{' '}
          <b>
            <span className='font16 fargeGjGreen'>{status}</span>
          </b>
        </span>
      );
    } else {
      return (
        <span>
          Gjennomført:{' '}
          <b>
            <span className='font16 fargeGjRed'>{status}</span>
          </b>
        </span>
      );
    }
  };

  getMelding = data => {
    if (data.merknadBesok) {
      return (
        <span onClick={this.props.lesMelding}>
          <NKIcon icon='kommentar' size='1' color='nk-black' />
        </span>
      );
    } else {
      return <span />;
    }
  };

  tilSituasjon = () => {
    this.props.tilSituasjon(this.state.currentEnhet);
  };

/*   flyttTiltak = () => {
    this.props.flyttTiltak(this.state.currentEnhet);
  }; */

  makeTidspunktString = () => {
    let d = '';
    let f = '';
    let t = '';
    if (this.state.currentEnhet.planlagtDato != null) {
      d =  moment(this.state.currentEnhet.planlagtDato).format('DD.MM.YYYY');
    }
     if (this.state.currentEnhet.besokFra != null) {
      f = this.state.currentEnhet.besokFra;
    }
    if (this.state.currentEnhet.besokTil != null) {
      t = '-' + this.state.currentEnhet.besokTil;
    }
    t = f + t;
    return <span><span>{d}</span><span style={{ fontSize: '16px', marginLeft: '4px' }}>{t}</span></span>;
  };

  makeDeltPipe = item => {
    return item.bruksenhetIdDelerLop && item.bruksenhetIdDelerLop !== null ? (
      <div>
        <img
          height='22'
          width='22'
          src={DeltPipe}
          alt='Delt'
          /*  onClick = {this.visDelte(item)} */
        />
      </div>
    ) : (
      ''
    );
  };

  toggleHideHotel = () => {
    this.setState({ visHotelsDiv: !this.state.visHotelsDiv });
  };

  testMe = () => {
    debug('gj kart', 'her test');
  };

  visRisikoFraControl = (mode) => {
     let enheter = this.state.enheter;
    this.setState({ enheter: [] }); 
    this.setState({ visRisiko: mode });
         setTimeout(() => {
     this.setState({ enheter });
    }, 1000);  
    
 };

  render() {
    const position = [this.state.lat, this.state.lng];

    let map = (
      <Map
        center={position}
        zoom={this.state.zoomLevel}
        className='kartgjennomfoering'
        zoomControl={false}
        fullscreenControl={true}
        ref='basiskart'>
        <GjLayerControl
          kart={basisKart}
          modus={this.state.visning}
          visning={this.props.visning}
          contextOn={true}
          testMe={this.testMe}
          tikker={this.state.tikker}
          gotClickE={this.state.gotClickE}
          setKartStateLayer={this.setKartStateLayer}
          visRisikoFraControl={this.visRisikoFraControl}
          visRisiko={this.state.visRisiko}
        />
        <LocateControl kart={basisKart} left={true} />
        <GjMarkerClusterLayerControl
          left={true}
          data={this.props.data}
          enheter={this.state.enheter}
          visning={this.props.visning}
          kart={basisKart}
          onClickedGj={this.onClickedGj}
          tilSituasjon={this.tilSituasjon}
          contextOn={true}
          visRisiko={this.state.visRisiko}
        />
       
        <Control position='topright'>
          <div style={{ marginTop: '4px' }}>
            <button
              type='button'
              className={'btnLag'}
              onClick={this.props.tilbake}
              title='Tilbake til liste-modus'>
              <NKIcon icon='liste' color='nk-black' />
            </button>
          </div>  
        </Control>
       
        <FullscreenControl />
        <ZoomControl
          left={false}
          zoom={this.state.zoomLevel}
          showZoom={true}  
          kart={basisKart}
        />{' '}
         <Control position='bottomleft'>
          <div style={{ padding: '2px', marginTop: '4px', borderColor: 'black', borderWidth: '1px', borderStyle: 'solid' }}>
            <img style={{ height: '50px' }} src={Legenda} alt=''/>
          </div>
        </Control>
        {this.state.currentEnhet && (
          <Control position='bottomleft'>
            <div>
              {this.state.visHotelsDiv && (
                <div className={'hotels '}>
                  <label className='font16 fontblue ' onClick={this.sentrer}>
                    {this.state.currentEnhet.adresse}
                  </label>
                  <table className='t100'>
                    <tbody>
                      <tr>
                        <td className='hotelstop'>
                          <span onClick={this.forrige}>
                            <NKIcon
                              icon='venstrepil'
                              size='1.5'
                              color='nk-black'
                            />
                          </span>
                          <br />
                          <br />
                          {this.getMelding(this.state.currentEnhet)}
                          <br />
                          {this.makeDeltPipe(this.state.currentEnhet)}
                        </td>
                        <td>
                          <span>
                            {this.state.currentEnhet.eiendom} <br />
                            {this.getStatus(this.state.currentEnhet)}
                            <br />
                            Tiltak {
                              this.state.currentEnhet.tiltakBruksenhet
                            }{' '}
                            {this.state.currentEnhet.tiltakRoyklop}
                            <br />
                            {this.makeTidspunktString()}
                            <br />
                            <button
                              onClick={this.tilSituasjon}
                              className='btn btn-primary situasjonButton'>
                              Situasjon
                            </button>
                            <br />
                            <br />
                          </span>
                        </td>
                        <td className='hotelstop' onClick={this.neste}>
                          <NKIcon
                            className='pull-right'
                            icon='hoyrepil'
                            size='1.5'
                            color='nk-black'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {this.state.visHotelsDiv && (
                <div>
                  <label
                     style={{ border: '1px solid black', paddingLeft: '8px', paddingTop: '4px', paddingBottom: '4px', backgroundColor: 'white', width: '116px' }}
                    onClick={this.toggleHideHotel}
                    className='fontblue pull-right'>
                    Skjul navigering
                  </label>
                </div>
              )}
              {!this.state.visHotelsDiv && (
                <div >
                  <label
                    style={{ border: '1px solid black', paddingLeft: '8px', paddingTop: '4px', paddingBottom: '4px', backgroundColor: 'white', width: '116px' }}
                    onClick={this.toggleHideHotel}
                    className='fontblue pull-right'>
                    Vis navigering
                  </label>
                </div>
              )}
            </div>
          </Control>
        )}
      </Map>
    );

    return <div>{map}</div>;
  }
}

export default EnheterKart;
