import React, { useState, useEffect, createContext } from 'react';
import InfoDialog from './Dialogs13/InfoDialog';
import OkDialog from './Dialogs13/OkDialog';
import JaNeiDialog from './Dialogs13/JaNeiDialog';
import ListeDialog from './Dialogs13/ListeDialog';
import PromptDialog from './Dialogs13/PromptDialog';
import ModalDialog from './Dialogs13/ModalDialog';
import TestContent from './Dialogs13/TestContent.js';

const liste= [ { id: 1, pizza: 'Napoli' },  { id: 2, pizza: 'Roma' }, { id: 3, pizza: '3 colori' }, { id: 4, pizza: 'Del Mare' }, { id: 5, pizza: 'Fromagio' }, { id: 6, pizza: 'Commodora' }, { id: 7, pizza: 'Bambino' },
{ id: 1, pizza: 'Napoli' },  { id: 2, pizza: 'Roma' }, { id: 3, pizza: '3 colori' }, { id: 4, pizza: 'Del Mare' }, { id: 5, pizza: 'Fromagio' }, { id: 6, pizza: 'Commodora' }, { id: 7, pizza: 'Bambino' }];

function TestFuncDialogs() {

  const [isInfoPizzaOpen, setIsInfoPizzaOpen] = useState(false);
  const [isOkOpen, setIsOkOpen] = useState(false);
  const [isJaNeiPizzaOpen, setIsJaNeiPizzaOpen] = useState(false);
  const [isListeOpen, setIsListeOpen] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  // Buttons ***********************************************
  const openInfoPizzaDialog = () => {
    setIsInfoPizzaOpen(true);
  };

  const openOk = () => {
    setIsOkOpen(true);
  };

  const openPizzaJaNeDialog = () => {
    setIsJaNeiPizzaOpen(true);
  };

  const openPizzaListeDialog = () => {
    setIsListeOpen(true);
  };

  const openPromptDialog = () => {
    setIsPromptOpen(true);
  };
  
  const openModalDialog = () => {
    setIsModalOpen(true);
  };

  //*********************************************************** */

  const handleOkClose = (svar) => {
    setIsOkOpen(false);
    alert(svar);
  };

  const handlePizzaClose = (svar) => {
    setIsJaNeiPizzaOpen(false);
    alert(svar);
  };

  const handleListeClose = (svar) => {
    setIsListeOpen(false);
    console.log(svar);
    alert(svar);
  };

  const handlePromptClose = (svar) => {
    setIsPromptOpen(false);
    console.log(svar);
    alert(svar);
  };

  const handleModalClose = (svar) => {
    setIsModalOpen(false);
    console.log(svar);
    alert(svar);
  };

  const modalContent= () =>  {
      return <TestContent/>;
  };

  return (
    <div>
      <h3>Test funct.comp. Mui- Dialoger</h3>
      <br />
       {/*  Info-dialog */}
      <button onClick={openInfoPizzaDialog}>Åpne pizza info-dialog</button>
       {/*ok-dialog */}
       <br/><br/>
      <button onClick={openOk}>Åpne pizza ok-dialog</button>
      {/* Ja-nei-dialog */}
      <br/><br/>
      <button onClick={openPizzaJaNeDialog}>Åpne pizza ja-nei-dialog</button>
       {/* Liste-dialog */}
       <br/><br/>
      <button onClick={openPizzaListeDialog}>Åpne pizza liste dialog</button>
         {/* Prompt-dialog */}
         <br/><br/>
      <button onClick={openPromptDialog}>Åpne prompt dialog</button>
        {/* modal-dialog */}
        <br/><br/>
      <button onClick={openModalDialog}>Åpne modal dialog med Content</button>

      <InfoDialog
        tittel='Info om Pizza'
        tekst='I dag har vi pizza. Ulike typer og smaker.'
        tekst2='Det serveres mellom 11 og 12. '
        isOpen={isInfoPizzaOpen}
        handleClose={() => setIsInfoPizzaOpen(false)}
      />

      <OkDialog
        tittel='Pizza skal kastes'
        tekst='Det blir litt uryddig her.'
        tekst2='All pizza skal kastes nå. '
        isOpen={isOkOpen}
        handleClose={handleOkClose}
      />

      <JaNeiDialog
        tittel='Pizza?'
        tekst='I dag har vi pizza. Ulike typer og smaker.'
        tekst2='Har du lyst å spise pizza i dag? '
        isOpen={isJaNeiPizzaOpen}
        handleClose={handlePizzaClose}
        visClose={true}
      />

  <ListeDialog
        tittel='Velg en pizza'
        width= '400px'
        liste= {liste}
        felt= 'pizza'
        isOpen={isListeOpen}
        handleClose={handleListeClose}
        visClose={true}
      />

<PromptDialog
        tittel='Hvor skal pizza leveres?'
        tekst='Skriv din adresse her:'
        isOpen={isPromptOpen}
        handleClose={handlePromptClose}
      />

<ModalDialog
        tittel='Pizza modal med Content'
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        visClose={true}
        Content={modalContent}   //Stor bokstav C
      />

    </div>
  );
}

export default TestFuncDialogs;
