import React from 'react';
import { AuthContext } from 'norkartidjs2';
import { useContext, useEffect, useState } from 'react';
import brann from 'images/Brannforebygging.png';

const LogOutComponent = (props) => {
  const authMsal = useContext(AuthContext);
  const [vis, setVis] = useState(false);

 useEffect(() => {
  let timer1 = setTimeout(() => {
      setVis(true);
    }, 3000);

    return () => {
      clearTimeout(timer1);
    };
  }, []); 

  const logout = (e) => {
    e.preventDefault();
    authMsal.logOut();
  };

  return (
    <div style={{ padding: '50px' }}>
      {vis && (
        <>
          <img src={brann} style={{ width: '40px', height: '40px' }} alt='' />
          <h3>{props.melding}</h3>

          <h4 style={{ color: 'Gray' }}>{props.extra}</h4>
          <button
            style={{ width: '420px' }}
            className='btn btn-success'
            onClick={logout}>
            Logg ut
          </button>
        </>
      )}
    </div>
  );
};

export default LogOutComponent;
