import React from 'react';
import ildsted_ikon from 'images/48/nk_076-ildsted-A-48px.png';
import royklopApi from 'api/royklopApi';
import NKIcon from 'nka-icons';
var _ = require('underscore');

class RoyklopSituasjonTre extends React.Component {
  state = { royklopsituasjon: null };

  componentDidMount() {
    royklopApi.getRoyklopSituasjonTreById(
      this.props.royklopId,
      this.gotRoyklop
    );
  }

  gotRoyklop = (err, royklop) => {
    if (royklop !== null && royklop.bruksenhetListe.length > 0) {
      royklop.bruksenhetListe.forEach(function(element) {
        if (royklop.bruksenhetListe !== null) {
          if (element.adresse !== null && element.adresse.length > 30) {
            element.adresse = element.adresse.substring(0, 30) + '...';
          }
        }

        element.ildstedListe.forEach(function(ild) {
          if (ild.modell !== null) {
            if (ild.modell.length > 15) {
              ild.modell = ild.modell.substring(0, 15) + '...';
            }
          }
        }, this);
      }, this);
    }
    this.setState({ royklopsituasjon: royklop });
  };

  render() {
    if (!this.state.royklopsituasjon) {
      return <div>Laster...</div>;
    }
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">Situasjon røykløp</h4>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm-12">
              <span style={{ float: 'left', marginLeft: '15px' }}>
                <b>Plassering: {this.state.royklopsituasjon.plassering}</b>
              </span>
              <br />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div>
              {_.map(this.state.royklopsituasjon.bruksenhetListe, function(
                enhet
              ) {
                return (
                  <div>
                    <div
                      style={{
                        marginLeft: '15px',
                        marginRight: '15px',
                        height: '36px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        background: '#f2f2f2'
                      }}>
                      <span
                        style={{
                          float: 'left',
                          marginLeft: '15px',
                          marginTop: '5px'
                        }}>
                        <NKIcon icon="hjem" size="1" color="nk-black" />
                      </span>

                      <span
                        style={{
                          float: 'left',
                          marginLeft: '15px',
                          marginTop: '5'
                        }}>
                        {enhet.adresse}
                      </span>
                    </div>
                    {_.map(enhet.ildstedListe, function(ildsted) {
                      return (  
                        <div>
                          <div
                            style={{
                              marginLeft: '30px',
                              marginRight: '15px',
                              height: '36px',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                              background: '#f2f2f2'
                            }}>
                            <img
                              height="24"
                              width="24"
                              src={ildsted_ikon}
                              style={{ float: 'left', marginLeft: '15px', marginTop: '5px' }}
                              className="app-preloader__img"
                              alt=""
                            />
                            <span style={{ float: 'left', marginLeft: '15px', marginTop: '5px' }} >
                              {ildsted.modell} - {ildsted.plassering}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    <br />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoyklopSituasjonTre;
