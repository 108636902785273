import React, { Component } from 'react';
import moment from 'moment';
import { getOrganisasjonerLoggAutomatisering } from 'api/loggApi';
import { withRouter } from 'lib/enhancers';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import { debugFactory } from 'utils';
import './logg.css';
const debug = debugFactory('nk:LoggAutomatisering');
//kopier: import LoggAutomatisering from './LoggAutomatisering.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class LoggAutomatisering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisasjoner: [],
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    this.hentData();
  }

  hentData = () => {
    getOrganisasjonerLoggAutomatisering((err, data) => {
      this.setState({ organisasjoner: data });
      debug('Utsatt', data);
    });
  };

  rowClick = (org) => {
    debug('999', org);
    this.props.router.push(
      'Admin/Logg/' + org.organisasjonId + '/' + encodeURIComponent(org.orgNavn)
    );
  };

  render() {
    let ellipsStyle = {
      color: 'blue',
      fontSize: '14px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '170px',
      textAlign: 'left',
      display: 'inline-block',
      cursor: 'pointer',
    };
    let exceptionStyle = {
      color: 'darkred',
      fontSize: '14px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '170px',
      textAlign: 'left',
      display: 'inline-block',
      cursor: 'pointer',
    };
    return (
      <div>
        <span>
          <h2>Logg automatisering</h2>
        </span>
        <div
          style={{
            marginTop: '30px',
            borderBottomColor: 'green',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
          }}>
          {' '}
        </div>
        {this.state.organisasjoner.map((org, i) => {
          return (
            <React.Fragment key={'frag' + i}>
              <div
                key={'fdd' + i}
                style={{
                  marginTop: '10px',
                  borderBottomColor: 'green',
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                }}>
                <div key={'ddd' + i} className='row'>
                  <div
                    style={{
                      marginBottom: '4px',
                    }}
                    key={'d0' + i}
                    className='col-sm-2'>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ fontWeight: 'bold' }}>{org.orgNavn}</td>
                        </tr>
                        <tr>
                          <td>
                            Tid:{' '}
                            {moment(org.timeStampStart).format(
                              'DD.MM.YYYY HH:mm'
                            )}
                          </td>
                        </tr>
                        <tr>
                          {org.sisteStatus === 'Feilet' ? (
                            <td style={{ color: 'darkred' }}>
                              Siste status: {org.sisteStatus}
                            </td>
                          ) : (
                            <td>Siste status: {org.sisteStatus}</td>
                          )}
                        </tr>
                        <tr>
                          <td>
                            <React.Fragment>
                              <button
                                key={'butt' + i}
                                className='btn btn-info'
                                onClick={() => this.rowClick(org)}>
                                <NKIcon
                                  icon='hjem'
                                  size='0.8'
                                  color='nk-white'
                                />
                                <span style={{ marginLeft: '4px' }}>
                                  Bruksenheter
                                </span>
                              </button>
                            </React.Fragment>
                          </td>
                        </tr>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <div
                    style={{
                      marginBottom: '4px',
                      backgroundColor: '#DAF7A6',
                    }}
                    key={'d01' + i}
                    className='col-sm-2'>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '100px', fontWeight: 'bold' }}>
                            Purring{' '}
                          </td>
                        </tr>
                        <tr>
                          <td>Totalt:</td>
                          <td>{org.antallVarselPurringTotalt}</td>
                        </tr>
                        <tr>
                          <td>Siste:</td>
                          <td>{org.antallVarselPurringSiste}</td>
                        </tr>
                        <tr>
                          <td>Except:</td>
                          <td>
                            {org.exeptionPurring ? (
                              <span />
                            ) : (
                              <span>Ingen</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {org.exeptionPurring && (
                      <div className='tooltipx'>
                        <span style={exceptionStyle}>
                          {org.exeptionPurring}
                        </span>
                        <span className='tooltiptextx'>
                          {org.exeptionPurring}
                        </span>
                      </div>
                    )}
                    <div className='tooltipx'>
                      <span style={ellipsStyle}>{org.infoPurring}</span>
                      <span className='tooltiptextx'>{org.infoPurring}</span>
                    </div>
                  </div>
                  {/* pp */}

                  <div
                    style={{
                      marginBottom: '4px',
                    }}
                    key={'d02' + i}
                    className='col-sm-2'>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '100px', fontWeight: 'bold' }}>
                            Påminnelse
                          </td>
                        </tr>
                        <tr>
                          <td>Totalt:</td>
                          <td>{org.antallVarselPaminnelseTotalt}</td>
                        </tr>
                        <tr>
                          <td>Siste:</td>
                          <td>{org.antallVarselPaminnelseSiste}</td>
                        </tr>
                        <tr>
                          <td>Except:</td>
                          <td>
                            {org.exeptionPaminnelse ? (
                              <span>  
                              </span>
                            ) : (
                              <span>Ingen</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {org.exeptionPaminnelse && (
                      <div className='tooltipx'>
                        <span style={exceptionStyle}>
                          {org.exeptionPaminnelse}
                        </span>
                        <span className='tooltiptextx'>
                          {org.exeptionPaminnelse}
                        </span>
                      </div>
                    )}
                    <div className='tooltipx'>
                      <span style={ellipsStyle}>{org.infoPaminnelse}</span>
                      <span className='tooltiptextx'>{org.infoPaminnelse}</span>
                    </div>
                  </div>

                  {/* pp */}

                  <div
                    style={{
                      marginBottom: '4px',
                      backgroundColor: '#DAF7A6',
                    }}
                    key={'d03' + i}
                    className='col-sm-2'>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '120px', fontWeight: 'bold' }}>
                            Utsatt sending
                          </td>
                        </tr>
                        <tr>
                          <td>Totalt:</td>
                          <td>{org.antallVarselUtsattSendingTotalt}</td>
                        </tr>
                        <tr>
                          <td>Siste:</td>
                          <td>{org.antallVarselUtsattSendingSiste}</td>
                        </tr>
                        <tr>
                          <td>Except:</td>
                          <td>
                            {org.exeptionVarselUtsettSending ? (
                              <span>
                              </span>
                            ) : (
                              <span>Ingen</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {org.exeptionVarselUtsettSending && (
                      <div className='tooltipx'>
                        <span style={exceptionStyle}>
                          {org.exeptionVarselUtsettSending}
                        </span>
                        <span className='tooltiptextx'>
                          {org.exeptionVarselUtsettSending}
                        </span>
                      </div>
                    )}
                    <div className='tooltipx'>
                      <span style={ellipsStyle}>
                        {org.infoVarselUtsettSending}
                      </span>
                      <span className='tooltiptextx'>
                        {org.infoVarselUtsettSending}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      marginBottom: '4px',
                    }}
                    key={'d04' + i}
                    className='col-sm-2'>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ width: '120px', fontWeight: 'bold' }}>
                            SvarUt
                          </td>
                        </tr>
                        <tr>
                          <td>Totalt:</td>
                          <td>{org.antallSvarUtTotalt}</td>
                        </tr>
                        <tr>
                          <td>Siste:</td>
                          <td>{org.antallSvarUtSiste}</td>
                        </tr>
                        <tr>
                          <td>Except:</td>
                          <td>
                            {org.exeptionSvarUt ? (
                              <span>
                              </span>
                            ) : (
                              <span>Ingen</span>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {org.exeptionSvarUt && (
                      <div className='tooltipx'>
                        <span style={exceptionStyle}>
                          {org.exeptionSvarUt}
                        </span>
                        <span className='tooltiptextx'>
                          {org.exeptionSvarUt}
                        </span>
                      </div>
                    )}
                    <div className='tooltipx'>
                      <span style={ellipsStyle}>{org.infoSvarUt}</span>
                      <span className='tooltiptextx'>{org.infoSvarUt}</span>
                    </div>
                  </div>

                  {/* pp */}
                </div>
              </div>
              {/*  <div
                style={{
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderBottomColor: 'green',
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px'
                }}
                id={'collapse' + i}
                className='collapse'>
                <label>Bruksenheter</label>
                <table>
                  <tbody>
                    {org.bruksenheter &&
                      org.bruksenheter.map((enhet, i) => {
                        return (
                          <tr>
                            <td>
                              {moment(enhet.TimeStamp).format(
                                'DD.MM.YYYY HH:mm'
                              )}
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>{enhet.TypeAutomatisering}</td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td><b>{enhet.BruksenhetAdresse}</b></td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td><span style = {{ color: 'blue', fontSize: '14px' }}>{enhet.Informasjon}</span></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div> */}
            </React.Fragment>
          );
        })}
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(LoggAutomatisering);
