import callApi from './api';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:api:smk');

// G E T

//Oppfølging av oppgaver - oppgaver for bruker som saksbehandler eller sidemann
export function getOppgaverTilOppfolgingForBruker(brukerId, callback) {
    callApi('GET', '/ArbeidslisteBruksenhet/oppgave/ansvaroppfolging/' + brukerId, null, callback);
}

// Hent avvik for bruker - åpne og publiserte
export function getApneogPubliserteAvvikForBruker(brukerId, callback) {
    callApi('GET', '/avvik/apnepublisert/' + brukerId, null, callback);
}

// Hent avvik for bruker - ikke publiserte åpne avvik
export function getIkkePubliserteApneAvvikForBruker(brukerId, callback) {
    callApi('GET', '/avvik/anmerkning/apneikkepublisert/' + brukerId, null, callback);
}

// Hent antall sidemannsoppgaver på bruker - brukes i admin
export function getAntallSidemannsoppgaver(bruker, callback) {
    callApi('GET', '/person/person/antallsidemannoppgaver/' + bruker, null, callback);
}

// O P P G A V E R

export function slettBruksenhetOppgave(id, data, callback) {
    callApi('PUT', '/ArbeidslisteBruksenhet/oppgave/slett/' + id, data, callback);
}

export function byttSidemann(oppgaveId, sidemannId, callback) {
    callApi('PUT', '/ArbeidslisteBruksenhet/oppgave/sidemann/' + oppgaveId + '/' + sidemannId, null, callback);
}

export function byttSaksbehandler(oppgaveId, Id, callback) {
    callApi('PUT', '/ArbeidslisteBruksenhet/oppgave/saksbehandler/' + oppgaveId + '/' + Id, null, callback);
}

export function byttSaksbehandlerPaAvvik(avvikId, Id, callback) {
    callApi('PUT', '/avvik/saksbehandler/' + avvikId + '/' + Id, null, callback);
}

export function byttSidemannPaAvvik(avvikId, Id, callback) {
    callApi('PUT', '/avvik/sidemann/' + avvikId + '/' + Id, null, callback);
}

export function sjekkSistSendt(brId, arbListeId, callback) {
    callApi('GET', '/ArbeidslisteBruksenhet/oppgave/tidspunkt/' + brId + '/' + arbListeId, null, callback);
}

export function avbrytSidemannkontrollPaOppgave(id, data, callback) {
    callApi('PUT', '/ArbeidslisteBruksenhet/oppgave/sidemannkontrollavbryt/' + id, data, callback );
}

export function avbrytSidemannkontrollPaAvvik(id, callback) {
    callApi('PUT', '/avvik/sidemannkontrollavbryt/' + id, null, callback );
}

// Gammel
// export function getAntallAnsvarSmk(callback){  
//     callApi('GET', '/sidemannskontroll/antallansvaroppfolging/', null, callback);
// };

// Ny
export function getAntallOppfolging(callback){  
    callApi('GET', '/person/person/ansvaroppfolging/', null, callback);
};



export function getSmk(callback){
    callApi('GET', '/sidemannskontroll/ansvaroppfolging/', null, callback);
};

export function getSpesifikkSmk(id, callback){
    callApi('GET', '/sidemannskontroll/objekt/' + id, null, callback);
};

export function sendTilSmk(param, callback){
    callApi('POST', '/innbyggerdialog/gjennomforing/sendtilsidemannskontroll/ikkepublisert/', param, callback);
};

export function sendTilSaksbehandlerUpublisert(param, callback){
    callApi('POST', '/innbyggerdialog/gjennomforing/sendtilsaksbehandler/ikkepublisert/', param, callback);
};

export function forhandsvisningSmk(param, callback){
    debug('TORSDAG BEFORE API', param);
    callApi('POST', '/innbyggerdialog/sidemannskontroll/sendtileier/ikkepublisert', param, callback);
};

export function bytteSaksbehandler(oppId, saksbehId, callback){
    callApi('PUT', '/sidemannskontroll/saksbehandler/' + oppId + '/' + saksbehId, null, callback);
};

export function slettSmk(id, obj, callback){
    callApi('PUT', '/sidemannskontroll/slett/' + id, obj, callback);
};

export function nySidemannsKommentar(param, callback){
    callApi('POST', '/sidemannskontroll/sidemannskontrollkommentar/', param, callback);
};

export function endreSidemannsKommentar(id, data, callback){
    callApi('PUT', '/sidemannskontroll/sidemannskontrollkommentar/'+ id, data, callback);
};

export function slettSidemannsKommentar(id, callback) {
    callApi('DELETE', '/sidemannskontroll/sidemannskontrollkommentar/'+ id, null, callback);
};

export function endreSidemannsDokument(id, data, callback){
    callApi('PUT', '/sidemannskontroll/dokumenttekst/'+ id, data, callback);
};

export function sendTilSaksbehandler(id, callback){
    callApi('PUT', '/sidemannskontroll/oppfolgingsaksbehandler/'+ id, null, callback);
};

export function sendTilSidemann(id, callback){
    callApi('PUT', '/sidemannskontroll/oppfolgingsidemannskontrollor/'+ id, null, callback);
};

export function godkjennSidemannskontroll(id, godkjent, callback){
    callApi('PUT', '/sidemannskontroll/godkjent/' + id + '/' + godkjent, null, callback);
};

export function checkSidemannskontrollStatus(brId, arId, callback){
    callApi('GET', '/sidemannskontroll/bruksenhetarbeidsliste/' + brId + '/' + arId, null, callback);
};

export function checkSidemannskontrollStatusX(param, callback){
    callApi('GET', '/sidemannskontroll/bruksenhetarbeidsliste/', param, callback);
};

export function sendTilEierFraSidemann(param, callback){
    callApi('POST', '/innbyggerdialog/sidemannskontroll/sendtileier/ikkepublisert/', param, callback);
}

//Denne metoden er ikke laget enda. Bare returnerer tull
//Steinemann
export function sendTilSidemannFraAapenAvvik(param, callback) {
    callback('Hei', 'Tilbakemelding fra sendTilSidemannFraAapenAvvik.');
}

//api/ArbeidslisteBruksenhet/oppgave/objekt/5.
export function getOppgave(id, callback){
    callApi('GET', '/ArbeidslisteBruksenhet/oppgave/objekt/' + id, null, callback);
}

export function nyOppgaveKommentar(param, callback){
    callApi('POST', '/arbeidslistebruksenhet/oppgave/kommentar/', param, callback);
};

////////////////// OBS OBS Disse 4 metoder er lik det samme, men har en annen navn for å ha litt mer kontroll i MiniOppgaverArbeidA
export function getPreviewDokumentSaksbehandler(param, callback){
    callApi('POST', '/innbyggerdialog/saksbehandler/sendtileier/ikkepublisert', param, callback);
};

export function getPreviewDokumentSidemann(param, callback){
    callApi('POST', '/innbyggerdialog/sidemannskontroll/sendtileier/ikkepublisert', param, callback);
};

export function sendTilEierFraKlargjorRapportSaksbehandler(param, callback){
    callApi('POST', '/innbyggerdialog/saksbehandler/sendtileier/ikkepublisert', param, callback);
};

export function sendTilEierFraKlargjorRapportSidemann(param, callback){
    callApi('POST', '/innbyggerdialog/sidemannskontroll/sendtileier/ikkepublisert', param, callback);
};

////////////////////////////////////////////////////////////////////////////

export function godkjennOppgave(id, godkjent, ansvarTilSaksbeh, callback){
    callApi('PUT', '/arbeidslisteBruksenhet/oppgave/godkjent/' + id + '/' + godkjent +'/' + ansvarTilSaksbeh, null, callback);
};

export function endreOppgaveDokument(id, data, callback){
    callApi('PUT', '/arbeidslistebruksenhet/oppgave/dokumenttekst/'+ id, data, callback);
};

export function setOppgaveTilSidemann(id, data, callback){
    callApi('PUT', '/arbeidslisteBruksenhet/oppgave/oppfolgingsidemannskontroll/'+ id, data, callback);
};

















