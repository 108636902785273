import React from 'react';
import styled from 'styled-components';
import NKIcon from 'nka-icons';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import ItemRoyklop from './ItemRoyklop';
import ItemBruksenhet from './ItemBruksenhet';
import { Header } from '../../components/generics';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
// UTILS
import {
  iconTypeReset,
  tiltakTypeBruksenhetListe2ArbeidslisteBruksenhetListe,
  tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe,
} from '../../lib';
import _ from 'lodash';
import DialogStore from 'lib/DialogStore';
import { debugFactory, objGetDeep } from 'utils';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:sidebararbeidsliste:index'
);

const HoverBackground = styled.div.attrs({
  className: 'br3',
})`
  :hover {
    background-color: var(--nk-light-gray);
  }
`;

const makeDato = (dat) => {
  if (dat === undefined) {
    return '';
  }
  let dato = new Date(dat);
  let y = dato.getFullYear();
  let m = dato.getMonth() + 1; //Tataaaaa!
  if (m < 10) {
    m = '0' + m;
  }
  let d = dato.getDate();
  if (d < 10) {
    d = '0' + d;
  }
  let strDato = d + '.' + m + '.' + y;
  return strDato;
};

const makeSituasjon = (item) => {
  let ildsteder = _.get(item, 'source.skybrannData.ildsteder');
  let royklop = _.get(item, 'source.skybrannData.royklop');

  if (ildsteder) {
    ildsteder = 'Ildsted: ' + ildsteder.length;
  }

  if (royklop) {
    royklop = 'Røykløp: ' + royklop.length;
  }

  if (ildsteder || royklop) {
    return (
      <tr>
        <td>{royklop}</td>
        <td>{ildsteder} </td>
      </tr>
    );
  }
};

const Korter= (tekst) => {
  if (tekst.length > 14){
    tekst = tekst.substring(0, 14)+ '..';
  }
  return tekst;
};

const ObjektListe = ({
  items,
  fraEnhet,
  ItemComponent,
  onRemoveItem,
  onClick,
  onKopierTilRoyk,
  kopierModus,
  ...props
}) => {
  return _.isEmpty(items) ? (
    <center>Ingen valgte</center>
  ) : (
    <React.Fragment>
      {items.map((item, idx) => {
        let rammeFarge = '1px solid green';
        if (!item.ny) {
          rammeFarge = '1px solid red';
        }

        let situasjonRad = makeSituasjon(item);

        let risiko = _.get(
          item,
          'source.skybrannData.bruksenhet.risikovurdering'
        );
        let risikoRad = '';
        if (risiko) {
          risikoRad = (
            <tr style={{ color: 'darkred' }}>
              <td>Risiko:</td>
              <td style={{ width: '100px' }}>{risiko}</td>
            </tr>
          );
        }

        let bruksenhetTiltakTypeRad = [];
        let bruksenhetTiltakType = _.get(
          item,
          'source.skybrannData.bruksenhetTiltakType'
        );
        if (bruksenhetTiltakType) {
          bruksenhetTiltakType= _.orderBy(
            item.source.skybrannData.bruksenhetTiltakType,
            ['nesteDato'],
            ['asc']
          );
          for (let i = 0; i < bruksenhetTiltakType.length; i++) {
            const el = bruksenhetTiltakType[i];
            let rad = (
              <tr style={{ color: 'blue' }}>
                <td>{Korter(el.tiltakType)}</td>
                <td style={{ width: '100px' }}> {makeDato(el.nesteDato)}</td>
              </tr>
            );
            bruksenhetTiltakTypeRad.push(rad);
          }
        }

        let royklopTiltakTypeRad = [];
        let royklopTiltakType = _.get(
          item,
          'source.skybrannData.royklopTiltakType'
        );
        if (royklopTiltakType) {
          royklopTiltakType= _.orderBy(
            item.source.skybrannData.royklopTiltakType,
            ['nesteDato'],
            ['asc']
          );
          for (let i = 0; i < royklopTiltakType.length; i++) {
            const el = royklopTiltakType[i];
            let rad = (
              <tr style={{ color: 'darkmagenta' }}>
                <td>{Korter(el.tiltakType)}</td>
                <td style={{ width: '100px' }}> {makeDato(el.nesteDato)}</td>
              </tr>
            );
            royklopTiltakTypeRad.push(rad);
          }
        }

        //getSituasjon(item);

        return (
          <HoverBackground key={`itemHB-${idx}`}>
            <div style={{ paddingTop: 0, paddingBottom: 0, cursor: 'pointer' }}>
              {fraEnhet && kopierModus && (
                <React.Fragment>
                  <div
                    style={{
                      border: rammeFarge,
                      padding: '4px',
                      marginBottom: '4px',
                    }}>
                    <table
                      style={{
                        width: '100%',
                        textAlign: 'left',
                      }}>
                      <tbody>
                        <tr>
                          <td colspan='2'>
                            <ItemComponent
                              {
                                ...props /* Silver bullet =P */
                              }
                              key={`item-${idx}`}
                              item={item}
                              onClick={onClick}
                              onRemoveItem={onRemoveItem}
                            />
                          </td>
                        </tr>

                        <tr style={{ fontSize: '12px' }}>
                          {/*  <td style={{ width: '30px' }} /> */}
                          <td style={{ textAlign: 'left' }}>
                            <table
                              style={{
                                width: '100%',
                                textAlign: 'left',
                                marginTop: '4px'
                              }}>
                              <tbody>
                                {situasjonRad}
                                {risikoRad}
                                {bruksenhetTiltakTypeRad}
                                {royklopTiltakTypeRad}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {item.ny && (
                          <tr style={{ fontSize: '10px' }}>
                            <td colspan= '2'>
                              <div
                                title='Kopier til Røykløpliste'
                                style={{
                                  marginRight: '4px',
                                  marginTop: '4px',
                                  cursor: 'pointer',
                                }}>
                                <NKIcon
                                  icon='kopier'
                                  size='0.8'
                                  color='nk-black'
                                  onClick={onKopierTilRoyk.bind(null, item)}
                                />
                                <span
                                  style={{ fontWeight: 'bold' }}
                                  onClick={onKopierTilRoyk.bind(null, item)}>
                                  &nbsp;&nbsp;&nbsp;&nbsp;Kopier til røykløp-liste
                                </span>
                              </div>
                            </td>
                           
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              )}

              {fraEnhet && !kopierModus && (
                <ItemComponent
                  style={{ cursor: 'pointer'}}
                  {
                    ...props /* Silver bullet =P */
                  }
                  key={`item-${idx}`}
                  item={item}
                  onClick={onClick}
                  onRemoveItem={onRemoveItem}
                />
              )}

              {!fraEnhet && (
                <ItemComponent
                  style={{ cursor: 'pointer' }}
                  {
                    ...props /* Silver bullet =P */
                  }
                  key={`item-${idx}`}
                  item={item}
                  onClick={onClick}
                  onRemoveItem={onRemoveItem}
                />
              )}
            </div>
          </HoverBackground>
        );
      })}
    </React.Fragment>
  );
};

class SidebarArbeidsliste extends React.Component {
  constructor(props) {
    super(props);
    this.state = { kopierModus: false };

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  resetArbeidsliste = (tiltakListeKey) => {
    this.dialogs.setOpenDialog(`OkTilbakestill_${tiltakListeKey}`, {
      onOk: () => this.props.applyPageObjekterSnapshot(tiltakListeKey),
    });
  };

  arbeidslisteBruksenheterRemoveItem = (itemToRemove) => {
    // let objektliste = this.props.props({bruksenheter: true});

    let objektliste = this.props.getTiltakListe({ bruksenheter: true });

    // NOTE! `itemToRemove` might remove multiple `objekter`
    const objekter = objektliste.filter(
      (item) => item.bruksenhetId === itemToRemove.bruksenhetId
    );

    if (!_.isEmpty(objekter)) {
      objekter.forEach((objekt) => {
        if (objekt.ny) {
          // Fjern element fra lista
          objektliste = objektliste.filter(
            (item) => item.bruksenhetId !== objekt.bruksenhetId
          );
        } else {
          // Flagg for sletting
          objekt.slett = true;
        }
      });

      // Update
      debug('arbeidslisteBruksenheterRemoveItem:removed', {
        objekter,
        objektliste: {
          objekter: [...objektliste],
          noDeletedCount: [...objektliste].filter((x) => x.slett === false)
            .length,
        },
      });

      this.props.setArbeidslisteTiltakLister({
        tiltakTypeBruksenhetListe: objektliste,
      });
    } else {
      debug('arbeidslisteBruksenheterRemoveItem:notFound', { itemToRemove });
    }
  };

  arbeidslisteRoyklopRemoveItem = (itemToRemove) => {
    let objektliste = this.props.getTiltakListe({ royklop: true });

    debug('arbeidslisteRoyklopRemoveItem', {
      itemToRemove,
      objektliste: {
        objekter: [...objektliste],
        noDeletedCount: [...objektliste].filter((x) => x.slett === false)
          .length,
      },
    });

    // NOTE! `itemToRemove` might remove multiple `objekter`
    const objekter = objektliste.filter(
      (item) => item.royklopId === itemToRemove.royklopId
    );

    if (!_.isEmpty(objekter)) {
      objekter.forEach((objekt) => {
        if (objekt.ny) {
          // Fjern element fra lista
          objektliste = objektliste.filter(
            (item) => item.royklopId !== objekt.royklopId
          );
        } else {
          // Flagg for sletting
          objekt.slett = true;
        }
      });

      // Update
      debug('arbeidslisteRoyklopRemoveItem:removed', {
        objekter,
        objektliste: {
          objekter: [...objektliste],
          noDeletedCount: [...objektliste].filter((x) => x.slett === false)
            .length,
        },
      });

      this.props.setArbeidslisteTiltakLister({
        tiltakTypeRoyklopListe: objektliste,
      });
    } else {
      debug('arbeidslisteRoyklopRemoveItem:notFound', { itemToRemove });
    }
  };

  onClick = (row) => {
    this.props.onSpretterRight(row);
  };

  onKopierTilRoyk = (item) => {
    let royklop = _.get(item, 'source.skybrannData.royklop');
    if (!royklop || royklop === 0) {
      this.dialogs.setOpenDialog('JaNeiKopier', {
        onJa: () => this.props.onKopierTilRoyk(item),
      });
    } else {
      this.props.onKopierTilRoyk(item);
    }
  };

  render() {
    // Bruksenheter
    let bruksenhetliste = objGetDeep(
      this.props.store,
      'arbeidslisteTiltakLister.tiltakTypeBruksenhetListe',
      []
    );
    bruksenhetliste = tiltakTypeBruksenhetListe2ArbeidslisteBruksenhetListe(
      bruksenhetliste,
      {
        uniqBy: 'bruksenhetId',
        bypass: false,
        debug,
      }
    );

    // Royklop
    let royklopliste = objGetDeep(
      this.props.store,
      'arbeidslisteTiltakLister.tiltakTypeRoyklopListe',
      []
    );
    royklopliste = tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe(
      royklopliste,
      {
        uniqBy: 'royklopId',
        bypass: false,
        debug,
      }
    );

    const listHeaderStyle = 'f3 b pb2 pt2';

    return (
      <div>
       {/*  <label>
          <input
            style={{ height: '16px', width: '16px' }}
            type='checkbox'
            name='checkModus'
            onChange={(e) => {
              this.setState({
                kopierModus: !this.state.kopierModus,
              });
            }}
          />
          &nbsp;&nbsp;
          <span style={{ fontSize: '14px' }}>Kopier modus</span>
        </label> */}

        <Header
          className={listHeaderStyle}
          title={`Bruksenheter (${bruksenhetliste.length})`}
          iconType={iconTypeReset}
          onIconClick={() =>
            this.resetArbeidsliste('tiltakTypeBruksenhetListe')
          }
          iconTooltip={'Fjern bruksenheter'}
        />

        <ObjektListe
          {
            ...this.props /* Silver bullet =P */
          }
          fraEnhet={true}
          kopierModus={this.state.kopierModus}
          items={bruksenhetliste}
          ItemComponent={ItemBruksenhet}
          onRemoveItem={this.arbeidslisteBruksenheterRemoveItem}
          onClick={this.onClick}
          onKopierTilRoyk={this.onKopierTilRoyk}
        />

        <Header
          className={listHeaderStyle}
          title={`Røykløp (${royklopliste.length})`}
          iconType={iconTypeReset}
          onIconClick={() => this.resetArbeidsliste('tiltakTypeRoyklopListe')}
          iconTooltip={'Fjern røykløp'}
        />
        <ObjektListe
          {
            ...this.props /* Silver bullet =P */
          }
          fraEnhet={false}
          items={royklopliste}
          ItemComponent={ItemRoyklop}
          onRemoveItem={this.arbeidslisteRoyklopRemoveItem}
          onClick={this.onClick}
        />

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SidebarArbeidsliste;

// Declarative parts

const modalDialogs = {
  OkTilbakestill_tiltakTypeBruksenhetListe: ({
    isOpen,
    onClose,
    dialogData,
    owner,
  }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Fjern bruksenheter'
        melding='Alle enheter som ikke er låst vil bli fjernet fra listen. Trykk OK for å fortsette.'
        onOk={onClose(dialogData.onOk)}
      />
    );
  },
  JaNeiKopier: ({ isOpen, onClose, dialogData, owner }) => (
    <JaNeiDialog
      melding={
        'Enheten har ingen registrert røykløp. Ønsker du å kopere likevel'
      }
      tittel='Kopier til roykløp-liste?'
      isOpen={isOpen}
      onNei={onClose}
      onJa={onClose(dialogData.onJa)}
    />
  ),
  OkTilbakestill_tiltakTypeRoyklopListe: ({
    isOpen,
    onClose,
    dialogData,
    owner,
  }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Fjern røykløp'
        melding='Alle røykløp som ikke er låst vil bli fjernet fra listen. Trykk OK for å fortsette.'
        onOk={onClose(dialogData.onOk)}
      />
    );
  },
};
