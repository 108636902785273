import { assert } from "utils";
import { isFunction } from "lodash";
import {
    debugFactory,
    getBoundMethods,
} from 'utils';

const debug = debugFactory('nk:utils:dialogstore');

class DialogStore {

    Dialog = function (owner, component, data = {}) {
        this.data = {};
        this.render = (props) => {
            return component({ owner, data: { ...this.data }, ...props });
        }
    }

    constructor({ owner, dialogs = {}, onCloseAnyDialog = () => { } }) {
        this.owner = owner;

        this.dialogs = Object.entries(dialogs).reduce((acc, [key, component]) => ({
            ...acc,
            [key]: new (this.Dialog)(owner, component)
        }), {});

        this.openDialogKey = null;
        this.onCloseAnyDialog = onCloseAnyDialog;

        Object.assign(this, getBoundMethods(this, [
            'addDialog',
            'getOpenDialog',
            'onClose',
            'closeDialog',
            'setOpenDialog',
        ]));
    }

    addDialog(key, component, data = {}) {
        this.dialogs[key] = new this.Dialog(this.owner, component, data);
    }

    renderOpenDialog() {
        const dialog = this.dialogs[this.openDialogKey];
        return dialog
            ? dialog.render({
                isOpen: true,
                onClose: this.onClose,
                owner: this.owner,
                dialogData: dialog.data
            })
            : null;

    }

    getOpenDialog() {
        return this.dialogs[this.openDialogKey] || null;
    }

    onClose(...callbacks) {
        callbacks = callbacks.filter(isFunction); // Filter away any events 
        debug('onClose', { openDialogKey: this.openDialogKey, callbacks });
        if (callbacks.length) {
            // Return wrapper that executes every callback before closing the dialog
            return (...args) => {
                debug('onClose', 'running callbacks!');
                return Promise
                    .all(callbacks.map(callback => callback(...args)))
                    .then(this.closeDialog); // <- Not called on reject/error
            };
        }
        else {
            this.closeDialog();
            return Promise.resolve(false); // Just call `closeDialog`
        }
    }

    closeDialog() {
        debug('closeDialog', { openDialogKey: this.openDialogKey });
        if (!this.openDialogKey) { return; };
        const openDialog = this.dialogs[this.openDialogKey];
        openDialog.data = {};
        this.onCloseAnyDialog();
        this.openDialogKey = null;
        // Trigger rerender
        this.owner.setState({ __dialogStoreOpenDialog: null });
    }

    setOpenDialog(key, data = {}) {
        if (this.openDialogKey) { return; };
        this.openDialogKey = key;
        const dialog = this.dialogs[key];
        assert(dialog, `Unknown dialogKey: ${key}! (available: ${Object.keys(this.dialogs).join(', ')})`);
        Object.assign(dialog.data, data);
        // Trigger rerender
        this.owner.setState({ __dialogStoreOpenDialog: key });
    }
};

export default DialogStore;