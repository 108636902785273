import React from 'react';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import {
  Panel
} from 'react-bootstrap';

class Adresseinfo extends React.Component {

getMatrikkelnumre(adresse){
    let matrikkelIndex = 0;
    return _.map(adresse.matrikkelnummer, function(nummer) {
        matrikkelIndex++;
        return (
        <p key={matrikkelIndex}>
          {nummer.kommunenr +
            '/' +
            nummer.gardsnr +
            '/' +
            nummer.bruksnr +
            '/' +
            nummer.festenr +
            '/' +
            nummer.seksjonsnr}
        </p>
      );
    });
}

  render() {
    let { adresser, eventKeyIndex } = this.props;
    let that = this;    
    let index = 0;
    
    let adressePanels = _.map(adresser, function(adresse) {
        eventKeyIndex++;
      index++;
      return (
        <div key={eventKeyIndex}>
        <Panel eventKey={eventKeyIndex}>
          <Panel.Heading>
            <Panel.Title toggle>
            <div className="vcenter"><NKIcon style={{ marginRight: '3px', paddingBottom: '3px' }}  icon="epost" size="0.9" color="nk-black" />
            Adresse ({index + '/' + adresser.length})</div>
              
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <p>{adresse.adressenavn}</p>
            {adresse.altAdressenavn ? (
              <p>{adresse.altAdressenavn}</p>
            ) : (
              ''
            )}
            <p>{adresse.poststed}</p>
            <p><strong>Type: </strong>{adresse.type}</p>
            <p><strong>Tettsted: </strong>{adresse.tettsted}</p>            
            <p><strong>Kirkesogn: </strong>{adresse.kirkesogn}</p>
            <p><strong>Valgkrets: </strong>{adresse.valgkrets}</p>
            <p><strong>Grunnkrets: </strong>{adresse.grunnkrets}</p>
            <p><strong>{adresse.matrikkelnummer && adresse.matrikkelnummer.length > 1 ? 'Matrikkelnumre:' : 'Matrikkelnummer:' }</strong></p>
            {that.getMatrikkelnumre(adresse)}
          </Panel.Body>
        </Panel>
        </div>
      );
    });
    return <div>{adressePanels}</div>;
  }
}
export default Adresseinfo;
