import React from 'react';
import Arkiv from './Arkiv';

export default class extends React.Component {
    state = {
        breadcrumbname: 'Laster....',
        type: this.props.location.state.type,
        id: this.props.location ? this.props.location.state.id : this.props.id,
        eiendom: this.props.location.state.eiendom,
        adresse: this.props.location.state.adresse
    };

    render() {
        return <div>
            <Arkiv eiendom={this.state.eiendom} id={this.state.id}
                base_path={'/gjennomforing/' + this.props.params.arbeidslisteId + '/' + this.props.params.arbeidslisteNavn} />
        </div>;
    }
}