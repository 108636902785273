import reqwest from 'reqwest';
import CONFIG from 'lib/CONFIG';
import ErrorHandler from './errorHandler.js';
import { debugFactory } from 'utils';
import norkartIdJs from 'auth/auth';
import { checkContextInnstillingerDatoInnlogging } from 'context/contextInnstillinger.js';
const debug = debugFactory('nk:Innlogging:Org');

var BASE_URL = CONFIG.REACT_APP_API_URL;
let profile = CONFIG.REACT_APP_PROFILE;

if (!BASE_URL) {
  throw new Error('Missing required REACT_APP_API_URL config');
}

const getCurrentCustomerId = () => {
  let cust = norkartIdJs.getCurrentCustomerContext();

  /*  ************************************************************************************************** */
  checkContextInnstillingerDatoInnlogging();
  /*  ************************************************************************************************** */

  return cust !== undefined ? cust.CustomerId : '';
};

const makeCall = function(method, path, data, callback, token) {
  let burl = BASE_URL;
  if (path === '/status/system') {
    var to = burl.lastIndexOf('/');
    to = to == -1 ? burl.length : to + 1;
    burl = burl.substring(0, to - 1);
    debug('/status/system basisurl 2', burl);
  }
  var headers = {
    Accept: 'application/json; charset=utf-8',
  };

  if (token) {
    headers['Authorization'] = 'Bearer ' + token;
    headers['X-WAAPI-Profile'] = profile;
    headers['X-WAAPI-CONTEXT'] = getCurrentCustomerId();
  }

  if (data) {
    data = JSON.stringify(data);
  }

  var url = burl + path;
  //Noen ganger skal 'api' ikke være med:
  url = url.replaceAll('/api/felles', '/felles');
  url = url.replaceAll('/api/status/driftsmelding', '/status/driftsmelding');
  url = url.replaceAll(
    '/api/status/systeminformasjon',
    '/status/systeminformasjon'
  );
  url = url.replaceAll('/api/status/systemfelles', '/status/systemfelles');
  url = url.replaceAll('/api/status/komtekwms', '/status/komtekwms');
  url = url.replaceAll(
    '/api/status/analyseplatform',
    '/status/analyseplatform'
  );
  url = url.replaceAll(
    '/api/status/risikoanalyseplatformfmeservers',
    '/status/risikoanalyseplatformfmeservers'
  );
  url = url.replaceAll(
    '/api/status/risikoanalyserfeiler',
    '/status/risikoanalyserfeiler'
  );

  reqwest({
    url: url,
    crossOrigin: true,
    type: 'json',
    method: method,
    contentType: 'application/json; charset=utf-8',
    headers: headers,
    data: data,
    error: function(err) {
      try {
        ErrorHandler(err, url);
        callback(JSON.parse(err.response));
      } catch (_) {
        try {
          ErrorHandler(err, url);
          callback(err);
        } catch (_) {
          // At this level, better to blow up than fail silently!
          throw err;
        }
      }
    },
    success: function(resp) {
      callback(null, resp);
    },
  });
};

export default function callApi(method, path, data, callback) {
  let burl = BASE_URL;
  if (path === '/status/system') {
    var to = burl.lastIndexOf('/');
    to = to == -1 ? burl.length : to + 1;
    burl = burl.substring(0, to - 1);
    debug('/status/system basisurl 1', burl);
  }
  norkartIdJs
    .getToken()
    .then((token) => {
      makeCall(method, path, data, callback, token);
    })
    .catch((err) => callback(err));
}
