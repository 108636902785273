import React from 'react';
import { withRouter } from 'lib/enhancers';
import NKModal from 'components/Dialogs/NKModal.jsx';
import EndreTittel from './EndreTittel.jsx';
import DialogStore from 'lib/DialogStore';
import {
  getOrganisasjonData,
  updateOrganisasjonData,
} from 'context/contextInnstillinger.js';
import { getOrganisasjon } from 'api/organisasjonApi';
import { getKodeliste, getArkivInnstillinger, endreArkivInnstilling } from 'api/arkivApi.js';
import { uuid } from 'utils';
import _ from 'lodash';

const feltSak = [
  {
    id: 1,
    navn: 'Adresse',
    flettefelt: '#Adresse#',
    fletteEksempel: 'Mumidalen 10 C H0101',
  },
  { id: 2, navn: 'Årstall', flettefelt: '#arstall#', fletteEksempel: '2022' },
  { id: 3, navn: 'KNR', flettefelt: '#KNR#', fletteEksempel: '3405' },
  { id: 4, navn: 'GNR', flettefelt: '#GNR#', fletteEksempel: '221' },
  { id: 5, navn: 'BNR', flettefelt: '#BNR#', fletteEksempel: '23' },
  { id: 6, navn: 'FNR', flettefelt: '#FNR#', fletteEksempel: '2' },
  { id: 7, navn: 'SNR', flettefelt: '#SNR#', fletteEksempel: '1' },
  {
    id: 8,
    navn: 'GNR/BNR/FNR/SNR',
    flettefelt: '#GNR/BNR/FNR/SNR#',
    fletteEksempel: '221/23/2/1',
  },
  {
    id: 9,
    navn: 'KNR/GNR/BNR/FNR/SNR',
    flettefelt: '#KNR/GNR/BNR/FNR/SNR#',
    fletteEksempel: '3405/221/23/2/1',
  },
  {
    id: 10,
    navn: 'KommuneNavn',
    flettefelt: '#KOMMUNENAVN#',
    fletteEksempel: 'Andeby',
  }
];

const feltjp1 = [
  {
    id: 1,
    navn: 'Adresse',
    flettefelt: '#Adresse#',
    fletteEksempel: 'Mumidalen 10 C H0101',
  },
  { id: 2, navn: 'Årstall', flettefelt: '#arstall#', fletteEksempel: '2022' },
  { id: 3, navn: 'KNR', flettefelt: '#KNR#', fletteEksempel: '3405' },
  { id: 4, navn: 'GNR', flettefelt: '#GNR#', fletteEksempel: '221' },
  { id: 5, navn: 'BNR', flettefelt: '#BNR#', fletteEksempel: '23' },
  { id: 6, navn: 'FNR', flettefelt: '#FNR#', fletteEksempel: '2' },
  { id: 7, navn: 'SNR', flettefelt: '#SNR#', fletteEksempel: '1' },
  {
    id: 8,
    navn: 'GNR/BNR/FNR/SNR',
    flettefelt: '#GNR/BNR/FNR/SNR#',
    fletteEksempel: '221/23/2/1',
  },
  {
    id: 9,
    navn: 'KNR/GNR/BNR/FNR/SNR',
    flettefelt: '#KNR/GNR/BNR/FNR/SNR#',
    fletteEksempel: '3405/221/23/2/1',
  },
  {
    id: 10,
    navn: 'KommuneNavn',
    flettefelt: '#KOMMUNENAVN#',
    fletteEksempel: 'Andeby',
  }
];

const feltjp3 = [
  {
    id: 1,
    navn: 'Adresse',
    flettefelt: '#Adresse#',
    fletteEksempel: 'Mumidalen 10 C H0101',
  },
  { id: 2, navn: 'Årstall', flettefelt: '#arstall#', fletteEksempel: '2022' },
  { id: 3, navn: 'KNR', flettefelt: '#KNR#', fletteEksempel: '3405' },
  { id: 4, navn: 'GNR', flettefelt: '#GNR#', fletteEksempel: '221' },
  { id: 5, navn: 'BNR', flettefelt: '#BNR#', fletteEksempel: '23' },
  { id: 6, navn: 'FNR', flettefelt: '#FNR#', fletteEksempel: '2' },
  { id: 7, navn: 'SNR', flettefelt: '#SNR#', fletteEksempel: '1' },
  {
    id: 8,
    navn: 'GNR/BNR/FNR/SNR',
    flettefelt: '#GNR/BNR/FNR/SNR#',
    fletteEksempel: '221/23/2/1',
  },
  {
    id: 9,
    navn: 'KNR/GNR/BNR/FNR/SNR',
    flettefelt: '#KNR/GNR/BNR/FNR/SNR#',
    fletteEksempel: '3405/221/23/2/1',
  },
  {
    id: 10,
    navn: 'Avvikstype',
    flettefelt: '#AVVIKTYPE#',
    fletteEksempel: 'Røykvarsler mangler',
  },
  {
    id: 11,
    navn: 'KommuneNavn',
    flettefelt: '#KOMMUNENAVN#',
    fletteEksempel: 'Andeby',
  }
];

const tittelInnhold = [
  { id: 0, tekst: '' },
  { id: 1, tekst: 'Adresse' },
  { id: 2, tekst: 'Kommune nummer' },
];

class Titler extends React.Component {
  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }
  state = {
    org: null,
    type: null,
    lagreForsend: true,
    lagreKass: true,
    lagreBevaring: true,
    lagreTittel: true,
    lagreDokumenttype: true,
    lagreLandkode: true,
    init: true,
    kassasjon: true,
    forsendelse: true,
    dokumenttype: true,
    lasterFor: false,
    lasterKas: false,
    lasterDok: false
  };

  componentDidMount() {
    getOrganisasjon(this.gotData);
    getArkivInnstillinger(null, this.gotArkivInnstillinger);
    // getKodeliste('Forsendelsesmaate', uuid(), this.gotKodeliste);
    // getKodeliste('Kassasjonsvedtak', uuid(), this.gotKodeliste2);
  }

  gotData = (err, res) => {
    console.log(res);
    this.setState({ org: res });
  };

  gotArkivInnstillinger = (err, res) => {
    this.setState({ arkInst: res });
    console.log(res);
  }

  continue = () => {
    if (this.state.forsendelse) {
      getKodeliste('Forsendelsesmaate', uuid(), this.gotKodeliste);
      this.setState({ lasterFor: true });
    } 
    if (this.state.kassasjon) {
        getKodeliste('Kassasjonsvedtak', uuid(), this.gotKodeliste2);
        this.setState({ lasterKas: true });
    }
    if (this.state.dokumenttype) {
      getKodeliste('Dokumenttype', uuid(), this.gotKodeliste3);
      this.setState({ lasterDok: true });
  }
    this.setState({ init: false });
  }


  gotKodeliste = (err, res) => {
    let tmp = [];
    if (res !== null && res !== undefined) {
      tmp = res;
      var tom = { kodeverdi: '', kodebeskrivelse: '', erGyldig: false };
      tmp.unshift(tom);
    }
    this.setState({ forsendMaate: tmp, lasterFor: false });
  };

  gotKodeliste2 = (err, res) => {
    let tmp = [];
    if (res !== null && res !== undefined) {
      tmp = res;
      var tom = { kodeverdi: '', kodebeskrivelse: '', erGyldig: false };
      tmp.unshift(tom);
    }
    this.setState({ kassasjonsvedtak: tmp, lasterKas: false });
  };

  gotKodeliste3 = (err, res) => {
    let tmp = [];
    if (res !== null && res !== undefined) {
      tmp = res;
      var tom = { kodeverdi: '', kodebeskrivelse: '', erGyldig: false };
      tmp.unshift(tom);
    }
    this.setState({ dokumenttyper: tmp, lasterDok: false });
  };

  endreSakstittel = () => {
    this.setState({ type: 'sak' });
    this.dialogs.setOpenDialog('TittelDialog', {
      tittel: this.state.org.arkivSakTittel,
      type: 'sak',
      fletteFelt: feltSak,
    });
  };

  endreJptittel1 = () => {
    this.setState({ type: 'jp1' });
    this.dialogs.setOpenDialog('TittelDialog', {
      tittel: this.state.org.arkivJPTittel1,
      type: 'jp1',
      fletteFelt: feltjp1,
    });
  };

  endreJptittel2 = () => {
    this.setState({ type: 'jp2' });
    this.dialogs.setOpenDialog('TittelDialog', {
      tittel: this.state.org.arkivJPTittel2,
      type: 'jp2',
      fletteFelt: feltjp1,
    });
  };

  endreJptittel3 = () => {
    this.setState({ type: 'jp3' });
    this.dialogs.setOpenDialog('TittelDialog', {
      tittel: this.state.org.arkivJPTittel3,
      type: 'jp3',
      fletteFelt: feltjp3,
    });
  };

  handleForsendelseChange = (val) => {
    let o = this.state.org;
    o.arkivForsendelsesmåte = val.target.value;
    this.setState({ lagreForsend: false, org: o });
  };

  handleKassaChange = (val) => {
    let o = this.state.org;
    o.arkivKassasjonskode = val.target.value;
    this.setState({ lagreKass: false, org: o });
  };

  handleBevaringChange = (val) => {
    let o = this.state.org;
    o.arkivBevaringstid = val.target.value;
    this.setState({ lagreBevaring: false, org: o });
  };

  handleTittelChange = (val) => {
    let o = this.state.org;
    o.arkivKlasseTittel = val.target.value;
    this.setState({ lagreTittel: false, org: o });
  };

  handleDoctypeChange = (val) => {
    let ins = this.state.arkInst;
    console.log(val.target.value);
    ins.Dokumenttype = val.target.value;
    this.setState({ arkInst: ins, lagreDokumenttype: false });
  }

  handleLandkodeChange = (val) => {
    let ins = this.state.arkInst;
    ins.Landkode = val.target.value;
    this.setState({ arkInst: ins, lagreLandkode: false });
  }

  lagreForsendelse = () => {
    this.setState({ lagreForsend: true });
    updateOrganisasjonData(this.state.org, this.oppdatertOk);
  };

  lagreKass = () => {
    this.setState({ lagreKass: true });
    updateOrganisasjonData(this.state.org, this.oppdatertOk);
  };

  lagreBevaring = () => {
    this.setState({ lagreBevaring: true });
    updateOrganisasjonData(this.state.org, this.oppdatertOk);
  };

  lagreTittel = () => {
    this.setState({ lagreTittel: true });
    updateOrganisasjonData(this.state.org, this.oppdatertOk);
  };

  lagreDokummenttype = () => {
    this.setState({ lagreDokumenttype: true });
    endreArkivInnstilling(null, this.state.arkInst, this.insupdated);
  }

  lagreLandkode = () => {
    this.setState({ lagreLandkode: true });
    endreArkivInnstilling(null, this.state.arkInst, this.insupdated);
  }

  insupdated = (err, res) => {
    this.setState({ arkInst: res });
  }

  matrikkelChange = () => {
    let tmp = this.state.org;
    tmp.arkivIkkeArkiverMatrNr = !this.state.org.arkivIkkeArkiverMatrNr;
    this.setState({ org: tmp });
    updateOrganisasjonData(tmp, this.oppdatertOk);
  };

  skjermingChange = () => {
    let tmp = this.state.org;
    tmp.arkivSkjerming = !this.state.org.arkivSkjerming;
    this.setState({ org: tmp });
    updateOrganisasjonData(tmp, this.oppdatertOk);
  };

  

  
  lagre = (tittel) => {
    console.log(tittel);
    let o = this.state.org;
    if (this.state.type === 'sak') {
      o.arkivSakTittel = tittel;
    }
    if (this.state.type === 'jp1') {
      o.arkivJPTittel1 = tittel;
    }
    if (this.state.type === 'jp2') {
      o.arkivJPTittel2 = tittel;
    }
    if (this.state.type === 'jp3') {
      o.arkivJPTittel3 = tittel;
    }
    updateOrganisasjonData(o, this.oppdatertOk);
  };

  oppdatertOk = (data) => {
    this.setState({ org: data });
    this.dialogs.closeDialog();
  };

  render() {
    if (this.state.init) {
      return (
        <div>
          <h2>Rediger sak- og journalpost oppsett</h2>
          <br/>
          <h4>Hvilke kode lister skal leses fra arkiv? </h4>
          <div style={{ fontSize: '12px' }}>
          Hvis det ikke er ønskelig å hente bestemt kodeliste fra arkiv, fjern avhukning for kodeliste under. Dette er viktig hvis listen ikke er definert i arkiv. Applikasjone kan da feile.
          </div>
          <br/>
          <br/>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Kassasjonsvedtak</label>
            </div>
            <div className='col-sm-10 form-check '>
              <input
                className='checkbox30'
                type='checkbox'
                checked={this.state.kassasjon}
                onChange={ (e) => {
                  this.setState({ kassasjon: !this.state.kassasjon });
                }}
                />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Forsendelsesmåte</label>
            </div>
            <div className='col-sm-10 form-check '>
              <input
                className='checkbox30'
                type='checkbox'
                checked={this.state.forsendelse}
                onChange={ (e) => {
                  this.setState({ forsendelse: !this.state.forsendelse });
                }}
                />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-2'>
              <label>Dokumentype</label>
            </div>
            <div className='col-sm-10 form-check '>
              <input
                className='checkbox30'
                type='checkbox'
                checked={this.state.dokumenttype}
                onChange={ (e) => {
                  this.setState({ dokumenttype: !this.state.dokumenttype });
                }}
                />
            </div>
          </div>
          <br/>
          <div className='row'>
          <div className='col-sm-2'>
           <div className='btn-toolbar' role='toolbar'>
            <button
              type='button'
              onClick={this.continue}
              className='btn btn-primary h34'
              style={{ padding: '0 25px' }}>
              Fortsett
            </button>
            </div>
          </div>
          </div>
        </div>
      );
      
    }
    if (this.state.lasterKas || this.state.lasterFor || this.state.lasterDok || !this.state.arkInst) {
      return <div>Laster...</div>;
    }

    return (
      <div>
        <h2>Rediger sak- og journalpost oppsett</h2>
        <br />
        <div className="row">
          <div className="col-sm-6">
            <label title="Tittel på sak som opprettes i arkiv">
              Sakstittel
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <input
              title="Tittel på sak som opprettes i arkiv"
              type="text"
              className="form-control"
              value={this.state.org.arkivSakTittel}
              readOnly
            />
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.endreSakstittel}
              type="button"
            >
              Endre
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-6">
            <label title="Tittel på journalpost som opprettes i arkiv ifbm publisering av resultat etter brannforebygging">
              Journalpost tittel 1 - Resultat etter brannforebyggende tiltak{' '}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <input
              title="Tittel på journalpost som opprettes i arkiv ifbm publisering av resultat etter brannforebygging"
              type="text"
              className="form-control"
              value={this.state.org.arkivJPTittel1}
              readOnly
            />
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.endreJptittel1}
              type="button"
            >
              Endre
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-6">
            <label title="Tittel på journalpost som opprettes i arkiv ifbm med oppfølging av avvik">
              Journalpost tittel 2 - Avvik oppfølging på bruksenhet{' '}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <input
              title="Tittel på journalpost som opprettes i arkiv ifbm med oppfølging av avvik"
              type="text"
              className="form-control"
              value={this.state.org.arkivJPTittel2}
              readOnly
            />
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.endreJptittel2}
              type="button"
            >
              Endre
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-6">
            <label title="Tittel på journalpost som opprettes i arkiv ifbm med lukking av avvik">
              Journalpost tittel 3 - Lukking av avvik{' '}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <input
              title="Tittel på journalpost som opprettes i arkiv ifbm med lukking av avvik"
              type="text"
              className="form-control"
              value={this.state.org.arkivJPTittel3}
              readOnly
            />
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.endreJptittel3}
              type="button"
            >
              Endre
            </button>
          </div>
        </div>
        <hr />
        
        {/* Forsendelsesmåte */}
        { this.state.forsendelse ? 
        <div>
           <div className="row">
          <div className="col-sm-6">
            <label title="Forsendelsesmåte settes på korrespondanseparten hvis oppgitt.">
              Forsendelsesmåte
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <select
              className="form-control"
              id="type"
              onChange={this.handleForsendelseChange}
              defaultValue={this.state.org.arkivForsendelsesmåte}
            >
              {_.map(this.state.forsendMaate, function(o, i) {
                return (
                  <option key={i} value={o.kodeverdi}>
                    {o.kodeverdi} - {o.kodebeskrivelse}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.lagreForsendelse}
              type="button"
              disabled={this.state.lagreForsend}
            >
              Lagre
            </button>
          </div>
        </div>
        <br />
        </div> :
        null }
       
        {/* Bevaringstide */}
        <div className="row">
          <div className="col-sm-6">
            <label title="">Bevaringstid (antall år)</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control"
              value={this.state.org.arkivBevaringstid}
              onChange={this.handleBevaringChange}
            />
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.lagreBevaring}
              type="button"
              disabled={this.state.lagreBevaring}
            >
              Lagre
            </button>
          </div>
        </div>
        <br />
        {/* Kassasjonskode */}
        {this.state.kassasjon ?
        <div>
            <div className="row">
          <div className="col-sm-6">
            <label title="">Kassasjonskode</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <select
              className="form-control"
              id="type"
              onChange={this.handleKassaChange}
              value={this.state.org.arkivKassasjonskode}
            >
              {_.map(this.state.kassasjonsvedtak, function(o, i) {
                return (
                  <option key={i} value={o.kodeverdi}>
                    {o.kodeverdi} - {o.kodebeskrivelse}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.lagreKass}
              type="button"
              disabled={this.state.lagreKass}
            >
              Lagre
            </button>
          </div>
        </div>
        <br />
        </div> :
        null }
        
        {/* Klassering tittel */}
        <div className="row">
          <div className="col-sm-6">
            <label title="">Klassering tittel (Beskrivelse felt)</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <select
              className="form-control"
              id="type"
              onChange={this.handleTittelChange}
              defaultValue={this.state.org.arkivKlasseTittel}
            >
              {_.map(tittelInnhold, function(o, i) {
                return (
                  <option key={i} value={o.id}>
                    {o.tekst}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.lagreTittel}
              type="button"
              disabled={this.state.lagreTittel}
            >
              Lagre
            </button>
          </div>
        </div>
        <br/>
        {/* Dokumenttype */}
        {this.state.dokumenttype ? 
        <div>
          <div className="row">
          <div className="col-sm-6">
            <label title="">Dokumenttype</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
          <select
              className="form-control"
              id="type"
              onChange={this.handleDoctypeChange}
              defaultValue={this.state.arkInst.Dokumenttype}
            >
              {_.map(this.state.dokumenttyper, function(o, i) {
                return (
                  <option key={i} value={o.kodeverdi}>
                    {o.kodeverdi} - {o.kodebeskrivelse}
                  </option>
                );
              })}
            </select>
            {/* <input
              type="text"
              className="form-control"
              value={this.state.arkInst.Dokumenttype}
              onChange={this.handleDoctypeChange}
            /> */}
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.lagreDokummenttype}
              type="button"
              disabled={this.state.lagreDokumenttype}
            >
              Lagre
            </button>
          </div>
          
          
        </div>
        <br />
        </div> :
        null}
        
        {/* Landkode */}
        <div className="row">
          <div className="col-sm-6">
            <label title="">Landkode</label>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <input
              type="text"
              className="form-control"
              value={this.state.arkInst.Landkode}
              onChange={this.handleLandkodeChange}
            />
          </div>
          <div className="col-sm-2">
            <button
              className="btn btn-default"
              style={{
                background: '#51A026',
                color: 'white',
                height: '32px',
              }}
              onClick={this.lagreLandkode}
              type="button"
              disabled={this.state.lagreLandkode}
            >
              Lagre
            </button>
          </div>
        </div>

        <br />
        <div className="row">
          <div className="col-sm-3">
            <label>Ikke arkiver matrikkelnummer</label>
          </div>
          <div className="col-sm-1 form-check ">
            <input
              className="checkbox30"
              type="checkbox"
              checked={this.state.org.arkivIkkeArkiverMatrNr}
              onChange={this.matrikkelChange}
            />
          </div>
          {/* <div className='col-sm-7'>
                          (Matrikkelnr vil ikke bli lagt på saken i arkivet)
                        </div> */}
        </div>

        <br />
        <div className="row">
          <div className="col-sm-3">
            <label>Skjerming</label>
          </div>
          <div className="col-sm-1 form-check ">
            <input
              className="checkbox30"
              type="checkbox"
              checked={this.state.org.arkivSkjerming}
              onChange={this.skjermingChange}
            />
          </div>
          {/* <div className='col-sm-7'>
                          (Matrikkelnr vil ikke bli lagt på saken i arkivet)
                        </div> */}
        </div>
        
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

const modalDialogs = {
  TittelDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" isOpen={isOpen} onClose={onClose}>
      <EndreTittel
        onClose={onClose}
        tittel={dialogData.tittel}
        type={dialogData.type}
        lagre={owner.lagre}
        flettefelt={dialogData.fletteFelt}
      />
    </NKModal>
  ),
};

export default Titler;
