import React from 'react';
import styled from 'styled-components';
import NKIcon from 'nka-icons';
import { isFunction } from 'lodash';

export const Section = styled.div.attrs({
  className: 'cf',
})``;

export const StyledIcon = styled(NKIcon).attrs({
  className: 'fr',
})``;

export const StyledTitle = styled.div.attrs({
  className: 'fl',
})``;

export const Header = ({
  title,
  extraInfo,
  iconType = 'soppelbotte', //
  onIconClick,
  iconTooltip = '',
  ...props
}) => {
  return (
    <Section {...props}>
      <StyledTitle>
        <span style={{ fontSize: '14px' }}>{title}</span>
      </StyledTitle>
      <span title={iconTooltip}>
        {isFunction(onIconClick) ? (
          <StyledIcon
            icon={iconType}
            size='0.7'
            color='nk-black'
            style={{ cursor: 'pointer' }}
            onClick={onIconClick}
            tooltip={iconTooltip}
          />
        ) : (
          <StyledIcon
            icon='lukket'
            size='0.7'
            color='nk-black'
            style={{ cursor: 'not-allowed' }}
            tooltip={iconTooltip}
          />
        )}
      </span>
      {extraInfo && (
        <>
          <br />
          <span style={{ color: 'grey', fontSize: '12px' }}>{extraInfo}</span>
        </>
      )}
    </Section>
  );
};
