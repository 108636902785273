import * as React from 'react';
import * as moment from 'moment';
import { SearchkitComponent } from 'searchkit';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import './DateRangeFilter.css';
import { debugFactory } from 'utils';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';

const debug = debugFactory('nk:components:search:daterangecalendar');

const format = 'DD.MM.YYYY';
const locale = 'nb_NO';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      melding2={dialogData.melding2}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  )
};

export class Picker extends SearchkitComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isManuelt: false,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  ///Ifm. skuddår
  onKeyUp = (e) => {
    if (e.target.value.includes('_')) {
      return;
    }
    this.startTimerObs(e.target.value);
    let d = e.target.value.substring(0, 2);
    let m = e.target.value.substring(3, 5);
    if (d === '29' && m === '02') {
      let y = parseInt(e.target.value.substring(6, 10));
      if (!moment([y]).isLeapYear()) {
        alert('Februar i ' + y + ' har kun 28 dager!.');
      }
    }
  };

  startTimerObs = (tekst) => {
    if (!tekst) {
      return;
    }

    if (tekst !== ''){
      this.setState({ isManuelt: true });

      setTimeout(() => {
        this.setState({ isManuelt: false });
      }, 30000);
    };
  };

  infoOmdatoFelt= () => {
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Dato manuelt',
      melding: 'Du har skrevet datoen manuelt.',
      melding2: 'Ved nullstill av filter må du fjerne dato manuelt.',
    });
    this.setState({ isManuelt: false });
  };

  /*    isManuelt= e.target.value !=='';
    debug('XYZ XX', e.target.value); */
  //alert('Du har nå lagt inn en dato manuelt. Ved nullstill av filter: sjekk om du kanskje må fjerne datoen selv');

  render() {
    const props = this.props;
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');

    return (
      <>
        {this.state.isManuelt && (
           <button
           type='button'
           onClick={this.infoOmdatoFelt}
           className='btn btn-link'
           style={{ paddingTop: '2px', paddingBottom: '2px', paddingLeft: '2px', fontSize: '12px', color: 'red'  }}>
           Husk å fjerne. Les mer..
         </button>

        )}
        <DatePicker
          dateFormat={format}
          locale={locale}
          className='sk-input-filter'
          filterDate={props.disabledDate}
          scrollableYearDropdown
          placeholderText={props.placeholderText}
          isClearable={true}
          selectsEnd
          todayButton={'I dag'}
          showYearDropdown
          showWeekNumbers
          onChange={props.onChange}
          selected={props.selected}
          startDate={props.startDate}
          endDate={props.endDate}
          /*  onKeyDown={(e) => {
          e.preventDefault();
       }} */
          customInput={
            <MaskedInput
              style={{ maxWidth: '104px' }}
              pipe={autoCorrectedDatePipe}
              mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
              keepCharPositions={true}
              guide={true}
              onKeyUp={this.onKeyUp}
            />
          }
        />
            {this.dialogs.renderOpenDialog()}
      </>
    );
  }
}

export class DateRangeCalendar extends SearchkitComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      startOpen: false,
      endOpen: false,
    };
    this.onStartChange = this.onStartChange.bind(this);
    this.onEndOpenChange = this.onEndOpenChange.bind(this);
    this.onEndChange = this.onEndChange.bind(this);
    this.clearState = this.clearState.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onStartOpenChange = (startOpen) => {
    this.setState({
      startOpen,
    });
  };

  onEndOpenChange = (endOpen) => {
    this.setState({
      endOpen,
    });
  };

  onStartChange = (value) => {
    this.setState({
      startValue: value,
      startOpen: false,
      endOpen: false,
    });

    this.handleChange(value, this.state.endValue);
  };

  onEndChange = (value) => {
    this.setState({
      endValue: value,
      startOpen: false,
      endOpen: false,
    });
    debug(value);
    this.handleChange(this.state.startValue, value);
  };

  clearState = () => {
    const { onFinished } = this.props;
    this.setState({
      startValue: null,
      endValue: null,
    });
    onFinished({
      fromDate: null,
      toDate: null,
    });

    debug('clearState', { ...this });
  };

  // For disabling past dates
  disabledPastDate = (endValue) => {
    if (endValue.diff(moment(), 'days') < 0) {
      return true;
    }
    return false;
  };

  disabledStartDate = (endValue) => {
    if (!endValue) {
      return false;
    }
    const startValue = this.state.startValue;
    if (!startValue) {
      return false;
    }
    return endValue.diff(startValue, 'days') > 0;
  };

  handleChange = (startDate, endDate) => {
    if (startDate === undefined) {
      const date = '1970/1/2 00:00 +0900';
      startDate = moment(date);
    }
    const startValue = startDate;
    const endValue = endDate;
    const { onFinished } = this.props;
    const notToday =
      startValue > +moment().endOf('day') ||
      startValue < +moment().startOf('day');

    const value = {
      fromDate:
        (startValue && notToday && startValue.startOf('day')) || startValue,
      toDate: endValue && endValue.endOf('day'),
    };

    debug('handleChange', { value });

    onFinished(value);
  };

  render() {
    const { fromDate, toDate } = this.props;
    const fromLabel = 'Fra';
    const toLabel = 'Til';

    return (
      <div className='date-filter'>
        <Picker
          onOpenChange={this.onStartOpenChange}
          open={this.state.startOpen}
          type='start'
          selected={fromDate}
          startDate={fromDate}
          placeholderText={fromLabel}
          endDate={toDate}
          onChange={this.onStartChange}
        />
        <Picker
          onOpenChange={this.onEndOpenChange}
          placeholderText={toLabel}
          type='end'
          /* disabledDate={this.disabledStartDate} */
          selected={toDate}
          startDate={fromDate}
          endDate={toDate}
          onChange={this.onEndChange}
        />
      </div>
    );
  }
}
