/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import * as _ from 'lodash';
import Editor from 'components/Editor/MalEditor.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import {
  oppdaterMal,
  nyMal,
  getMalerOppslagsdata,
  oppdaterEksempelMal,
  nyEksempelMal,
} from 'api/malerApi';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:MalDialog');

class TiltakCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valgte: [],
      valgListe: [],
      mal: null,
    };
  }

  componentDidMount() {
    this.setState({ mal: this.props.mal });
    this.mergeData();
  }

  mergeData() {
    let valgte = this.props.mal.tiltak;
    let valgListe = this.props.tiltakListe;
    for (let y = 0; y < valgListe.length; y++) {
      let rad = valgListe[y];
      let elId = rad.id;
      let found = _.find(valgte, { tiltakTypeId: elId });
      if (found) {
        rad.valgt = true;
      } else {
        rad.valgt = false;
      }
    }
    this.setState({ valgListe });
    this.setState({ valgte });
  }

  handleCheckbox = (e, box) => {
    let valgListe = this.state.valgListe;
    for (let i = 0; i < valgListe.length; i++) {
      let rad = valgListe[i];
      if (rad.id === box.id) {
        rad.valgt = !rad.valgt;
      }
    }
    this.setState({ valgListe }, this.oppdaterMalTiltak(valgListe));
  };

  oppdaterMalTiltak = (valgListe) => {
    let valgte = [];
    for (let i = 0; i < valgListe.length; i++) {
      let rad = valgListe[i];
      if (rad.valgt == true) {
        valgte.push(rad);
      }
    }
    let valgteNy = [];
    for (let y = 0; y < valgte.length; y++) {
      let element = {
        //************************************************************************* mangler id og malId
        tiltakTypeId: valgte[y].id,
        tiltak: valgte[y].tekst,
        aktiv: valgte[y].aktiv,
        malId: this.props.mal.id,
      };
      if (this.state.mal.id == 0) {
        element.id = 0;
      }
      valgteNy.push(element);
    }
    this.setState({ valgte: valgteNy });
    this.props.oppdaterTiltak(valgteNy);
  };

  render() {
    return (
      <div>
        <table>
          <tbody>
            <React.Fragment>
              {this.state.valgListe.map(function(tiltak) {
                let key = 'row_' + tiltak.id;
                let keyCh = 'check_' + tiltak.id;
                let td1 = 'td1_' + tiltak.id;
                let td2 = 'td2_' + tiltak.id;
                let td3 = 'td3_' + tiltak.id;
                let td4 = 'td4_' + tiltak.id;
                return (
                  <tr key={key}>
                    <td key={td1}>
                      <input
                        alt={tiltak.paragrafId}
                        className='checkbox20'
                        ref={tiltak.id}
                        id={keyCh}
                        key={keyCh}
                        onChange={(e) => this.handleCheckbox(e, tiltak)}
                        checked={tiltak.valgt}
                        type='checkbox'
                      />
                    </td>
                    <td key={td2}>&nbsp;&nbsp;&nbsp;</td>
                    <td key={td3}>{tiltak.tekst}</td>
                    <td key={td4}>{tiltak.valgt}</td>
                  </tr>
                );
              }, this)}
            </React.Fragment>
          </tbody>
        </table>
      </div>
    );
  }
}

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class MalDialog extends Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.state = {
      mal: null,
      funksjonListe: null,
      fletters: null,
      tiltaktypeListe: null,
      tab: '',
      maltext: '',
      aktuelFunksjon: null,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    let m = _.cloneDeep(this.props.mal);
    this.setState({
      mal: m,
      gjelder: this.props.gjelder,
      norkartbruker: this.props.norkartbruker,
    });
    getMalerOppslagsdata(this.props.gjelder, 'T', this.gotgOppslagData);
  }

  gotgOppslagData = (err, data) => {
    let obj = {
      id: 0,
      tekst: 'Ingenting valgt..',
      aktiv: null,
      flettefelt: null,
    };

    let fliste = data.funksjonListe;
    if (
      fliste.filter(function(e) {
        return e.tekst === 'Ingenting valgt..';
      }).length == 0
    ) {
      fliste.unshift(obj);
    }

    let mal = _.cloneDeep(this.props.mal);
    let funksjonId = mal.funksjonId;
    let fletters = this.getFlettersFromList(funksjonId, data.funksjonListe);

    this.setState({
      tiltaktypeListe: data.tiltakTypeListe,
      funksjonListe: fliste,
      fletters: fletters,
    });
  };

  getAktuelleFlettefelt = (funksjonId) => {
    let fletters = this.getFlettersFromList(
      funksjonId,
      this.state.funksjonListe
    );
    this.setState({ fletters: fletters });
  };

  tabClick = (tab) => {
    let ikkeKomplett = false;
    if (this.props.modus === 'Innstillinger') {
      if (this.malIkkeKomplettFunksjon(this.state.mal)) {
        ikkeKomplett = true;
      }
      if (this.malIkkeKomplettTittel(this.state.mal)) {
        ikkeKomplett = true;
      }
      if (!ikkeKomplett) {
        if (this.malIkkeKomplettTiltak(this.state.mal)) {
          ikkeKomplett = true;
        }
      }

      if (ikkeKomplett) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler tittel, tiltak eller funksjon!',
          tittel: 'Sjekk malegenskaper',
        });
      }
    } else {
      if (this.malIkkeKomplettFunksjon(this.state.mal)) {
        ikkeKomplett = true;
      }
      if (this.malIkkeKomplettBeskrivelse(this.state.mal)) {
        ikkeKomplett = true;
      }
      if (ikkeKomplett) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler beskrivelse eller funksjon!',
          tittel: 'Sjekk malegenskaper',
        });
      }
    }

    switch (tab) {
      case 'egenskaper':
        break;
      case 'tekst':
        let editor = this.editorRef;
        editor.settModel();
        break;
      default:
        return;
    }
  };

  getValueFromList = (id, liste) => {
    let value = '';
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].id == id) {
        value = liste[i].tekst;
        break;
      }
    }
    return value;
  };

  getFlettersFromList = (id, liste) => {
    console.log('%c RIENI Fletters ', 'font-size: 14px; background: firebrick; color: white',  liste );  
    let value = '';
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].id == id) {
        value = liste[i].flettefelt;
        break;
      }
    }
    return value;
  };

  funksjonChange = (e) => {
    let mal = this.state.mal;
    mal.funksjonId = e.target.value;
    mal.funksjon = this.getValueFromList(
      e.target.value,
      this.state.funksjonListe
    );
    this.setState(mal);
    this.getAktuelleFlettefelt(e.target.value);
  };

  gjelderChange = (e) => {
    let mal = this.state.mal;
    mal.gjelderKode = e.target.value;
    this.setState(mal);
  };

  tittelChange = (e) => {
    let mal = this.state.mal;
    mal.tittel = e.target.value;
    this.setState(mal);
  };

  aktivChange = (e) => {
    let mal = this.state.mal;
    mal.aktiv = !mal.aktiv;
    this.setState(mal);
  };

  beskrivelseChange = (e) => {
    if (e.target.value.includes('\\')) {
      alert('Du kan ikke bruke tegn som \\');
    } else {
      let mal = this.state.mal;
      mal.beskrivelse = e.target.value;
      this.setState(mal);
    } 
  };

  synkroniserTiltakIdenter = (nyTiltakListe) => {
    let originalListe = _.cloneDeep(this.props.mal.tiltak);
    for (let i = 0; i < nyTiltakListe.length; i++) {
      let tiltakRad = nyTiltakListe[i];
      let found = _.find(originalListe, {
        tiltakTypeId: nyTiltakListe[i].tiltakTypeId,
      });
      if (found) {
        tiltakRad.id = found.id;
      }
    }
    return nyTiltakListe;
  };

  malIkkeKomplettTiltak = (mal) => {
    if (!mal.tiltak) {
      return true;
    }
    if (mal.tiltak.length == 0) {
      return true;
    }
    return false;
  };

  malIkkeKomplettFunksjon = (mal) => {
    if (!mal.funksjonId) {
      return true;
    }
    if (mal.funksjonId === '0') {
      return true;
    }
    return false;
  };

  malIkkeKomplettTittel = (mal) => {
    if (!mal.tittel) {
      return true;
    }
    if (mal.tittel === '') {
      return true;
    }
    return false;
  };

  malIkkeKomplettBeskrivelse = (mal) => {
    if (!mal.beskrivelse) {
      return true;
    }
    if (mal.beskrivelse === '') {
      return true;
    }
    return false;
  };

  lagre = () => {
    let mal = this.state.mal;
    let editor = this.editorRef;
    let txt = '';
    if (editor) {
      txt = editor.getMaltekst();
    }
    if (this.props.modus === 'Innstillinger') {
      /*  if (this.malIkkeKomplettTittel(mal)) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler en tittel!',
          tittel: 'Malen kan ikke lagres..',
        });
        return;
      } */

      if (this.malIkkeKomplettFunksjon(mal)) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler funksjon!',
          tittel: 'Malen kan ikke lagres..',
        });
        return;
      }

      if (this.malIkkeKomplettTiltak(mal)) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler tiltak!',
          tittel: 'Malen kan ikke lagres..',
        });
        return;
      }

      if (txt === undefined) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Malteksten kan ikke være tom!',
          tittel: 'Malen kan ikke lagres..',
        });
        return;
      }

      mal.tekst = txt;
      mal.tiltak = this.synkroniserTiltakIdenter(mal.tiltak);
      if (mal.id == 0) {
        nyMal(mal, this.malOppdatert);
      } else {
        oppdaterMal(mal.id, mal, this.malOppdatert);
      }
    } else {
      if (this.malIkkeKomplettBeskrivelse(mal)) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler en beskrivelse!',
          tittel: 'Malen kan ikke lagres..',
        });
        return;
      }

      if (this.malIkkeKomplettFunksjon(mal)) {
        this.dialogs.setOpenDialog('OkDialog', {
          melding: 'Mal mangler funksjon!',
          tittel: 'Malen kan ikke lagres..',
        });
        return;
      }
      mal.tekst = txt;
      if (mal.id == 0) {
        nyEksempelMal(mal, this.malOppdatert);
      } else {
        oppdaterEksempelMal(mal.id, mal, this.malOppdatert);
      }
    }
  };

  malOppdatert = () => {
    this.props.onOppdatert(this.props.gjelder);
    this.props.onClose();
  };

  oppdaterTiltak = (tiltakListe) => {
    let mal = this.state.mal;
    mal.tiltak = tiltakListe;
    this.setState({ mal });
  };

  changeHovedDokument = () => {
    let mal = this.state.mal;
    debug('MMMM', mal);
    if (mal.funksjon !== 'Purring') {
      mal.hoveddokument = !mal.hoveddokument;
      this.setState({ mal });
    } else {
      mal.hoveddokument = false;
      this.setState({ mal });
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Første side..',
        melding: 'Ved purring er "Første side" ikke mulig',
      });
    }
  };

  render() {
    if (
      !this.state.mal ||
      !this.state.tiltaktypeListe ||
      !this.state.funksjonListe
    ) {
      return 'Vent..';
    }
    return (
      <div style={{ width: '800px' }} className='malModalHeight'>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{this.state.mal.tittel}</h4>
        </div>

        <div
          style={{ marginTop: '2px', maxHeight: '100%' }}
          className='panel panel-default'>
          <ul className='nav nav-tabs'>
            <li className='active' onClick={() => this.tabClick('egenskaper')}>
              <a data-toggle='tab' href='#1tab'>
                Mal egenskaper
              </a>
            </li>
            <li onClick={() => this.tabClick('tekst')}>
              <a data-toggle='tab' href='#2tab'>
                Tekst
              </a>
            </li>
          </ul>
          <div className='panel-body'>
            <div className='tab-content'>
              {/* ******** TAB ************************************** */}
              <div id='1tab' className='tab-pane fade in active'>
                <div className='row malrowX'>
                  <table style={{ width: '700px' }}>
                    <tbody>
                      {/*     {this.props.modus === 'Innstillinger' && ( */}
                      <React.Fragment>
                        <tr style={{ width: '700px' }}>
                          <td>
                            <label>Tittel</label>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ verticalAlign: 'top' }}>
                            {this.state.mal.standard && (
                              <span>{this.state.mal.tittel}</span>
                            )}
                            {!this.state.mal.standard && (
                              <input
                                style={{
                                  width: '600px',
                                  marginBottom: '6px',
                                }}
                                autoFocus
                                maxLength='250'
                                className='form-control'
                                placeholder='Tittel..'
                                type='text'
                                value={this.state.mal.tittel}
                                onChange={this.tittelChange}
                              />
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                      {/*  )} */}

                      <tr>
                        <td>
                          <label>I bruk</label>
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <input
                            className='checkbox20'
                            checked={this.state.mal.aktiv}
                            type='checkbox'
                            onChange={this.aktivChange}
                          />
                        </td>
                      </tr>

                      <tr style={{ width: '700px' }}>
                        <td style={{ verticalAlign: 'top' }}>
                          <label style={{ marginTop: '12px' }}>
                            Beskrivelse
                          </label>
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <textarea
                            style={{
                              width: '600px',
                              marginTop: '6px',
                              resize: 'none',
                            }}
                            placeholder='Beskrivelse..'
                            cols='40'
                            rows='5'
                            className='form-control'
                            value={
                              this.state.mal.beskrivelse
                                ? this.state.mal.beskrivelse
                                : ''
                            }
                            onChange={this.beskrivelseChange}
                          />
                        </td>
                      </tr>
                      <tr style={{ height: '20px', width: '700px' }} />

                      <tr>
                        <td>
                          <label style={{ marginTop: '6px' }}>Funksjon</label>
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <select
                            className='form-control'
                            id='funksjonSelect'
                            style={{
                              width: '100% !important',
                              maxWidth: '100%',
                            }}
                            value={this.state.mal.funksjonId}
                            onChange={this.funksjonChange}>
                            {_.map(this.state.funksjonListe, function(o, i) {
                              return (
                                <option key={i} value={o.id}>
                                  {o.tekst}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      </tr>
                      {this.props.modus === 'Innstillinger' && (
                        <React.Fragment>
                          <tr style={{ height: '20px', width: '700px' }} />
                          <tr>
                            <td style={{ verticalAlign: 'top' }}>
                              <label style={{ marginTop: '2px' }}>Tiltak</label>
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <TiltakCheckList
                                mal={this.state.mal}
                                oppdaterTiltak={this.oppdaterTiltak}
                                tiltakListe={this.state.tiltaktypeListe}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      )}
                      <tr style={{ height: '20px', width: '700px' }} />

                      {this.props.gjelder !== 'O' &&
                        this.props.gjelder !== 'F' && (
                          <tr>
                            <td>
                              <label>Første side</label>
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <input
                                className='checkbox20'
                                checked={this.state.mal.hoveddokument}
                                type='checkbox'
                                onChange={this.changeHovedDokument}
                              />
                            </td>
                          </tr>
                        )}
                      <tr style={{ height: '20px', width: '700px' }} />
{/*                       {isPersonNorkartAdmin && this.props.gjelder !== 'P' && (
                        <tr>
                          <td>
                            <label>Sidemannskontroll</label>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <input
                              className='checkbox20'
                              checked={this.state.mal.aktiv}
                              type='checkbox'
                              onChange={this.aktivChange}
                            />
                          </td>
                        </tr>
                      )} */}
                    </tbody>
                  </table>
                </div>

                {this.props.modus === 'Admin' && (
                  <span
                    style={{
                      color: 'blue',
                      fontSize: '12px',
                      marginBottom: '60px',
                    }}>
                    Obs! Det vises ikke en tiltaksliste her. <br />
                    Vi vet ikke hvilke tiltak de ulike organisasjoner har
                    definert.
                  </span>
                )}
              </div>

              {/* ******** TAB ************************************** */}
              <div id='2tab' className='tab-pane fade '>
                {/* <div style={{ height: '50px', paddingLeft: '10px' }}> */}
                <div>
                  <Editor
                    ref={(el) => {
                      this.editorRef = el;
                    }}
                    maltekst={this.state.mal.tekst}
                    flettefeltListe={this.state.fletters}
                    norkartbruker={this.props.norkartbruker}
                    pilotbruker={this.props.pilotbruker}
                    gjelder={this.props.gjelder}
                    mal={this.state.mal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='malModalFooter'>
          <button onClick={this.lagre} className='btn btn-primary pull-right'>
            Lagre
          </button>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default MalDialog;
