import React, { Component } from 'react';

class InfoOmSendtTilInnbyggerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let teller = 0;
    return (
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              type="button"
              onClick={this.props.onClose}>
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Sendt fra før..</h4>
          </div>
          <div className="modal-body" style={{ padding: '0 20px' }}>
            <label>{ this.props.tittel }</label>
            <ul style={{ padding: '10px' }}>
              {this.props.datoer.map(function(d) {
                teller = teller + 1;
                let style = { color: 'black' };
                if (d.idag) {
                  style = { color: 'red' };
                }
                var inId = 'li' + teller;
                return <li style={style} key={inId}>{d.dato}</li>;
              }, this)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoOmSendtTilInnbyggerDialog;
