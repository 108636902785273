import * as actionTypes from './actionTypes';

const setIsCached = (state, key, boolIsCached = true) => ({
    ...state.isCached,
    [key]: boolIsCached,
});

const initialState = {
    isCached: {},
    brukere: {},
    userpersonalia: {},
    arbeidslisteEnheter: [],
    arbeidslister: [],
    situasjon: null,
    selectedEnhet: null,
    selectedArbeidsliste: null,
    selectedArbeidslisteEnhet: null,
    ildstedOppslagsData: {},
    royklopOppslagsData: {},
    ildsted: null,
    royklop: null,
    kartArbeidslisteLayerEgenskaper: {},
    kartArbeidslisteLokasjonEgenskaper: {},
    kartArbeidslisteMarkoerEgenskaper: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BRUKERE_FETCHED:
            return {
            ...state,
            brukere: action.payload,
            };
        case actionTypes.USER_PERSONALIA_GJENNOMFORING_FETCHED:
            return {
                ...state,
                userPersonalia: (action.payload),
            };
        case actionTypes.ARBEIDSLISTER_FETCHED:
            return {
                ...state,
                arbeidslister: (action.payload),
                isCached: setIsCached(state, action.type),
            };
        case actionTypes.ARBEIDSLISTE_ENHETER_FETCHED:
            return {
                ...state,
                arbeidslisteEnheter: (action.payload),
                isCached: setIsCached(state, action.type),
            };
        case actionTypes.SITUASJON_FETCHED:
            return {
                ...state,
                situasjon: (action.payload),
                isCached: setIsCached(state, action.type),
            };
        case actionTypes.ARBEIDSLISTE_SELECTED:
            return {
                ...state,
                selectedArbeidsliste: (action.payload)
            };
        case actionTypes.ENHET_SELECTED:
            return {
                ...state,
                selectedEnhet: (action.payload)
            };
        case actionTypes.SET_SELECTED_ARBEIDSLISTE_ENHET:
            return {
                ...state,
                selectedArbeidslisteEnhet: (action.payload)
            };
/*         case actionTypes.SET_VALGTENHETID_FOR_GEBYR:
            return {
                    ...state,
                    enhetIdGebyr: (action.payload)
                };
        case actionTypes.SET_VALGTTYPE_FOR_GEBYR:
            return {
                        ...state,
                        typeGebyr: (action.payload)
                    };
        case actionTypes.SET_VALGTADRESSE_FOR_GEBYR:
            return {
                            ...state,
                            adresseGebyr: (action.payload)
                        };

        case actionTypes.SET_VALGTEIENDOM_FOR_GEBYR:
            return {
                                ...state,
                                eiendomGebyr: (action.payload)
                            }; */

        case actionTypes.ILDSTEDOPPSLAGDATA_FETCHED:
            return {
                    ...state,
                    ildstedOppslagsData: (action.payload)
                };
        case actionTypes.ROYKLOPOPPSLAGDATA_FETCHED:
            return {
                ...state,
                royklopOppslagsData: (action.payload)
            };
/*         case actionTypes.SET_VALGTFAGENHET_FOR_GEBYR:
            return {
                ...state,
                fagenhetGebyr: (action.payload)
            }; */
        case actionTypes.ILDSTED_FETCHED:
            return {
                ...state,
                ildsted: (action.payload)
            };
        case actionTypes.ROYKLOP_FETCHED:
            return {
                ...state,
                royklop: (action.payload)
            };
        case actionTypes.SET_KARTSTATE_ARBEIDSLISTE_LAYER:
            return {
                ...state,
                kartArbeidslisteLayerEgenskaper: (action.payload)
            };
        case actionTypes.SET_KARTSTATE_ARBEIDSLISTE_LOKASJON:
            return {
                ...state,
                kartArbeidslisteLokasjonEgenskaper: (action.payload)
            };
        case actionTypes.SET_KARTSTATE_ARBEIDSLISTE_MARKOERMODUS:
            return {
                ...state,
                kartArbeidslisteMarkoerEgenskaper: (action.payload)
            };
        // Default: entire state-tree
        default:
            return state;
    }
};