import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';

import { debugFactory, objGetDeep } from 'utils';

const debug = debugFactory('nk:innstillinger:sakarkiv:index')

export default function (props) {
    debug('props', { props });
    const pathname = objGetDeep(props, 'location.pathname');
    return (
        <div>
            <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <LinkList title="Arkivinnstillinger" columns={1} >
                        <LinkListItem title="Arkivinnstillinger" path={`${pathname}/Arkiv`} />
                        <LinkListItem title="Arkiv brukerinnstillinger" path={`${pathname}/Bruker`} />
                        <LinkListItem title="Sakskategorier" path={`${pathname}/Sakstyper`} />
                        <LinkListItem title="Rediger sak- og journalpost oppsett" path={`${pathname}/Titler`} />
                        <LinkListItem title="Oppsett tiltakstyper" path={`${pathname}/Tiltakstyper`} />
                        <LinkListItem title="Test arkivoppsett" path={`${pathname}/Test`} />
                    </LinkList>
                </div>
            </div>
        </div>
    );
}