import withAuthorization from 'lib/enhancers/withAuthorization';

const accessLevels = {    
    admin: ['norkartAdmin', 'organisasjonAdmin'],
    nkAdmin: ['norkartAdmin']
   /*  pilotBruker: [ 'norkartAdmin', 'pilotBruker'] */
};

const enhancers = Object.entries(accessLevels).reduce((acc, [k, v]) => ({
    ...acc,
    [k]: withAuthorization(v),
}), {});

//debug({ accessLevels, enhancers });

export default enhancers;
