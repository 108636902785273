import React from 'react';
import Link from 'react-router/lib/Link';
import gjen_icon from 'images/48/gjennomforing-B-48.png';
import objsok_icon from 'images/48/database-GW.png';
import utropstegn_icon from 'images/48/utropstegn.png';
import cookieImg from 'images/48/cookie.png';
import rapp from 'images/24/statistikk24.png';
import krone from 'images/Annet/kron.png';
import sidemannskontroll from 'images/Annet/bruker_sidemann.png';
import 'css/generated/Site.css';
import NKIcon from 'nka-icons';

const LinkListItem = ({
  path,
  title,
  icon = 'hoyrepil',
  red,
  blue,
  tilOppf,
  info,
  cookie,
  antUnderBeh,
  prosessNotify
  
}) => {
  var fixIcon;
  switch (icon) {
    case 'gjennomforing':
      fixIcon = <img height='24' width='24' src={gjen_icon} alt='' />;
      break;
    case 'objektsok':
      fixIcon = <img height='24' width='24' src={objsok_icon} alt='' />;
      break;
    case 'cookie':
      fixIcon = <img height='24' width='24' src={cookieImg} alt='' />;
      break;
    case 'krone':
      fixIcon = <img height='24' width='24' src={krone} alt='' />;
      break;
    case 'sidemannskontroll':
      fixIcon = <img height='24' width='24' src={sidemannskontroll} alt='' />;
      break;
    case 'rapporter':
      fixIcon = <img height='24' width='24' src={rapp} alt='' />;
      break;
    default:
      fixIcon = <NKIcon icon={icon} size='1' color='nk-black' />;
  }
  var style = {};
  var klasse = 'btn btn-default btn-lg storbutton';
  if (red) {
    klasse = 'btn btn-danger btn-lg storbutton';
  }
  if (info) {
    klasse = 'btn btn-warning btn-lg storbutton';
  }
  if (cookie) {
    klasse = 'btn btn-warning btn-lg storbutton';
  }
  if (blue) {
    style = { backgroundColor: '#d0def5' };
  }
  if (title.includes('hide')) {
    klasse = 'btn btn-default btn-lg storbutton hide';
  }

  return (
    
    <p>
      <Link to={{ pathname: path, state: { fra: antUnderBeh } }} className={klasse} style={style} >

        <span>{title}</span>
        {tilOppf && title === 'Oppfølging' ? (
          <span
            style={{
              marginLeft: '2px',
              marginRight: '10px',
              marginTop: '10px',
            }}>
            <img
              height='16'
              width='16'
              src={utropstegn_icon}
              alt=''
              title='Det er avvik som krever oppfølging'
            />
          </span>
        ) : null}
        {prosessNotify && title === 'Systemmeldinger' ? (
          <span
            style={{
              marginLeft: '2px',
              marginRight: '10px',
              marginTop: '10px',
            }}>
            <img
              height='16'
              width='16'
              src={utropstegn_icon}
              alt=''
              title='Det er oppstått feil eller forsinkelser i systemet som krever din oppmerksomhet'
            />
          </span>
        ) : null}
        <span className='pull-right'>{fixIcon}</span>
      </Link>
      
    </p>
  );
};

const LinkLabel = ({ path, title }) => {
  return (
    <Link to={path}>
      <span>{title}</span>
    </Link>
  );
};

const LinkList = ({
  title,
  children,
  columns = 1,
  smallColumns,
  mediumColumns,
  largeColumns,
}) => {
  var className = `col-sm-${12 / (smallColumns || columns)} col-md-${12 /
    (mediumColumns || columns)} col-lg-${12 / (largeColumns || columns)}`;
  return (
    <div className='row'>
      <h2 className='marginleft'>{title}</h2>
      {children &&
        children.map((child, i) => (
          <div className={className} key={i}>
            {child}
          </div>
        ))}
    </div>
  );
};

export { LinkList, LinkListItem, LinkLabel };
