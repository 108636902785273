import React, { useState } from 'react';
import NKIcon from 'nka-icons';
import './BFButtonGroup.css';

const BFButton = ({
  buttonLabel,
  farget,
  afterClick,
  disabled,
  image,
  imageHover,
  buttonWidth,
  green,
  title,
  icon
}) => {

  const handleClick = (id, name) => {
    if (afterClick) {
      afterClick(id, name);
    }
  };

  const [imageSource, setImageSource] = useState(image);

  const changeHover = () => {
    if (imageSource === image) {
      setImageSource(imageHover);
    } else {
      setImageSource(image);
    }
  };

  const velgFarge = () => {
    if (disabled) {
      return 'bfButtonDisabled';
    }
    if (green) {
      return 'bfButtonGreen';
    }
    if (farget) {
      return 'bfButtonFarget';
    }
    return 'bfButton';
  };

  return (
    <>
      <button
        onMouseOver={changeHover}
        onMouseOut={changeHover}
        title={title}
        disabled={disabled}
        style={{ marginRight: '4px', width: buttonWidth }}
        name={buttonLabel}
        className={velgFarge()}
        onClick={() => handleClick(buttonLabel)}>
        {/*  className={farget ? 'bfButtonFarget':  'bfButton'}> */}
        {icon && (
           <span className='pull-left' style={{ marginRight: '4px' }}>
              <NKIcon  icon={icon} size="1" color="nk-black" />
           </span>
        )}
        {image && (
          <span className='pull-left' style={{ marginRight: '4px' }}>
            <img
              height='24'
              width='24'
              src={disabled ? imageHover : imageSource}
              alt=''
            />
          </span>
        )}

        {buttonLabel}
      </button>
    </>
  );
};

export default BFButton;
