// https://github.com/searchkit/searchkit/tree/v2.3.0/packages/searchkit-docs

import {
    SearchkitManager
} from 'searchkit';

import CONFIG from 'lib/CONFIG';
import { getAccessToken } from 'auth/auth';
import { reloadSearch } from 'utils/searchKitHelper';

import {
    incrementRequestCount,
    decrementRequestCount
} from 'redux/boundActions';

import {
    isFunction
} from 'lodash';
import norkartIdJs from 'auth/auth';

const getCurrentCustomerId = () => {
    let cust = norkartIdJs.getCurrentCustomerContext();
    return cust !== undefined ? cust.CustomerId : '';
};
const ES_URL = CONFIG.REACT_APP_API_ENDPOINT; 
const ES_INDEX = CONFIG.REACT_APP_ES_INDEX;
const WAAPI_PROFILE = CONFIG.REACT_APP_PROFILE;

if (!ES_URL || !ES_INDEX ) {
  throw new Error('Missing search index settings.');
}

const defaultHost = ES_URL + "api/search/" + ES_INDEX; 

  

export default ({
    host = defaultHost,
    searchOnLoad, // <= Overridden if `readyCheck` is passed
    // Custom stuff 
    readyCheck,
    onceWhenReady = () => { },
    debug = () => { },
    // The rest
    ...options
} = {}) => {
    const instance = new SearchkitManager(host, {
        httpHeaders: {
            Authorization: getAccessToken(),
            'X-WAAPI-Profile': WAAPI_PROFILE,
            'X-WAAPI-CONTEXT': getCurrentCustomerId(),
          },

        useHistory: false,
        searchOnLoad: (isFunction(readyCheck) ? false : searchOnLoad),
        ...options
    });

    /*
    TODO! `instance.loading` kicks in for too many events when searching (e.g incremental address-search)
    - Incremental search is anyway obscured by the loadingOverlay, which is bad
    - Need to debounce incremental searches    
    */
    // instance.emitter.addListener(() => {                        
    //     instance.loading
    //         ? incrementRequestCount()
    //         : decrementRequestCount();
    // });

    if (readyCheck) {
        // Initial loading overlay
        incrementRequestCount();

        //const retryTimeout = 30; //ms
        const retryTimeout = 60; //ms   //Ifm. juridisk person og mange matrikkelId's
        const maxRetryCount = parseInt(8000 / retryTimeout);
        let retryCount = 0;

        // TODO! Replace `setInterval` with sth. more tactile?
        const intervalId = setInterval(() => {
            const isReady = readyCheck(instance);

            if (retryCount >= maxRetryCount) {
                clearInterval(intervalId);
                // TODO! (Flemming) This needs to be handled better:
                // throw new Error("searchkit:readyCheck - Timeout");
            }
            retryCount++;

            if (isReady) {
                clearInterval(intervalId);

                // Add transaltions, set defaultQuery etc here:
                onceWhenReady(instance);

                instance.addResultsListener((results) => {
                    debug('searchkit:resultlistener', { ...results });
                    // Initial loading overlay                    
                    decrementRequestCount();
                });

                // Initiate the search
                reloadSearch(instance);
            }
        }, retryTimeout);

        instance.readyCheckClearInterval = (reason) => {
            debug('searchkit:readyCheckClearInterval', { reason, intervalId });
            clearInterval(intervalId);
            decrementRequestCount();
        }

        debug('searchkit:readyCheck', { intervalId })

    }

    return instance;
};