import React, { useState, useEffect, useRef } from 'react';


  const Child1 = React.forwardRef(({ verdi, endreVerdi, trigger3 }, ref) => {

  const [newVerdi, setNewVerdi] = useState('Abc');

  const prevVerdi = usePrevious(newVerdi);

  useEffect(() => {
      setNewVerdi(verdi + ' just new');
  });

  function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }



  return (
    <div style={{ margin: '4px', padding: '10px', border: '2px solid green', width: '250px' }}>
      <h3>Child1</h3>
      <label>{verdi}</label>
      <br />
      <label>{newVerdi}</label>
      <br /><br />
      <label style={{ fontSize: '12px' }}>{'Forrige var ' + prevVerdi}</label>
      <br />    <br />
      <button onClick={() => endreVerdi('Fra child1 XYZ')}>
        Endre verdi til XYZ
      </button>
      <br/>
      <button onClick={() => trigger3()}>Trigger metode i Child3 -2</button>  
      <br/>
    </div>
  );
});

export default Child1;
