import store from 'redux/store';
import { REQUEST_STATES } from 'redux/constants';
import * as appActionTypes from 'redux/app/actionTypes'; 
import 'array-flat-polyfill';

import {
    debugFactory,
    promisifyApiCall,
    objGetDeep,
} from 'utils';
var promiseFinally = require('promise.prototype.finally');
promiseFinally.shim();


const debug = debugFactory('nk:redux:boundActions');

export const incrementRequestCount = () => {
   // debug('incrementRequestCount', { appActionTypes, store });
    return store.dispatch({
        type: appActionTypes.INCREMENT_REQUEST_COUNT,
    });
};

export const decrementRequestCount = () => store.dispatch({
    type: appActionTypes.DECREMENT_REQUEST_COUNT,
});

export const exceptionOccured = ({ err, method, path, data }) => store.dispatch({
    type: appActionTypes.EXCEPTION_OCCURED,
    payload: { err, method, path, data },
});

export const fetch = (actionType, apiCall, {
    apiArgs = [],
    debugInstance = () => { }
}) => (dispatch) => {
    const label = `fetch:${String(actionType)}`;

  //  debug(`${label}:start`);

    dispatch({
        type: appActionTypes.UPDATE_FETCH_STATE,
        payload: {
            fetchKey: actionType,
            fetchState: REQUEST_STATES.IN_PROGRESS
        }
    });
    dispatch(incrementRequestCount);

    return promisifyApiCall(apiCall)(...apiArgs)
        .then((res) => {
            debugInstance(`${label}:success`, res);
            dispatch({
                type: appActionTypes.UPDATE_FETCH_STATE,
                payload: {
                    fetchKey: actionType,
                    fetchState: REQUEST_STATES.DONE
                }
            });
            dispatch(decrementRequestCount);
            return res;
        })
        .catch(err => {
            debugInstance(`${label}:error`, err);
            dispatch(exceptionOccured);
        })
        .finally(() => {
            debugInstance(`${label}:done`);
        });

};

export const shouldFetch = (fetchActionType, forceFetch = false) => {
    if (forceFetch) {
        debug('shouldFetch', { fetchActionType, forceFetch });
        return true;
    }
    // Figure if it should be fetched
    const state = store.getState();
    const fetchStates = objGetDeep(state, 'app.fetchStates', {});
    const fetchState = fetchStates[fetchActionType];
    const shouldFetch = (fetchState === REQUEST_STATES.NOT_STARTED);
  //  debug('shouldFetch', { forceFetch, fetchActionType, state, fetchState, shouldFetch });
    return shouldFetch;
};

export const isFetched = (...fetchActionTypes) => {
    const state = store.getState();
    const fetchStates = objGetDeep(state, 'app.fetchStates', {});
    fetchActionTypes = fetchActionTypes.flatMap(x => x);
    console.log('********************** FLATMAP redux/boundactions -1 ************************************');
    const isAllFetched = fetchActionTypes
        .every(fetchActionType => {
            const fetchState = fetchStates[fetchActionType];
            const isFetched = (fetchState === REQUEST_STATES.DONE);
            debug('isFetched', { fetchActionType, state, fetchState, isFetched });
            return isFetched;
        });

  //  debug('isFetched', { fetchActionTypes, isAllFetched });

    return isAllFetched;
};

export const resetFetchState = (...fetchActionTypes) => {
    fetchActionTypes = fetchActionTypes.flatMap(x => x);
    console.log('********************** FLATMAP redux/boundactions -2 ************************************');
    store.dispatch({
        type: appActionTypes.RESET_FETCH_STATES,
        payload: fetchActionTypes
    });
};
