import React from 'react';
import { useState } from 'react';
import NkaButton from 'nka-button'
import 'nka-button/dist/style.css'

function Test() {
    const [count, setCount] = useState(0);

    return (
        <div>
            
            <NkaButton onClick={() => setCount(count+1)} buttontype="secondary">Du har klikket {count} ganger</NkaButton>
        </div>
    );
}
export default Test;