import React from 'react';
import { asRouteComponent } from 'components/Router';
import KalenderMain from './KalenderMain.jsx';


class Kalender extends React.Component {

    render() {

        return (
            <div>
               <KalenderMain/>
            </div>
        );
    }
}

export default (asRouteComponent(Kalender));