import React from 'react';
import { withRouter } from 'lib/enhancers';
import arkivApi from 'api/arkivApi';
import { getOrganisasjonArkivSkjerming } from 'api/organisasjonApi';
import _ from 'lodash';
import { uuid } from 'utils';

let sakstype = {
  navn: null,
  klasseListe: null,
  
};
let lukk = ['Nei', 'Ja'];
let rekkefolge = ['', '1', '2', '3'];
let klasseVerdi = [
  '',
  '[ eiendom ]',
  '[ knr/eiendom ]',
  '[ knr ]',
  '[ adresse ]',
];
const beskrivelseVerdi = [
  '',
  '[ eiendom ]',
  '[ knr/eiendom ]',
  '[ knr ]',
  '[ adresse ]',
];

class NySakstype extends React.Component {
  constructor(props) {
    super(props);
    this.gotArkivdel = this.gotArkivdel.bind(this);
    //this.gotJournalenhet = this.gotJournalenhet.bind(this);
    this.gotMappetype = this.gotMappetype.bind(this);
    this.gotKlasser = this.gotKlasser.bind(this);
    this.gotStatuser = this.gotStatuser.bind(this);

    this.klasse1Change = this.klasse1Change.bind(this);
    this.klasse2Change = this.klasse2Change.bind(this);
    this.arkivDelChange = this.arkivDelChange.bind(this);
    this.journalenhetChange = this.journalenhetChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.mappetypeChange = this.mappetypeChange.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.handleAdmChange = this.handleAdmChange.bind(this);
    this.sakstypeLagret = this.sakstypeLagret.bind(this);
    this.handleEkspChange = this.handleEkspChange.bind(this);
    this.handleJpNameChange = this.handleJpNameChange.bind(this);
    this.gotJPStatuser = this.gotJPStatuser.bind(this);
    this.jpstatusChange = this.jpstatusChange.bind(this);
    this.statusAvsluttChange = this.statusAvsluttChange.bind(this);
    this.submit = this.submit.bind(this);
    // this.handleNameChange = this.handleNameChange.bind(this);
    this.state = {
      sakstyper: [],
      ferdigLastet: false,
      kundeId: this.props.location.state.kundeId,
      klasse1: null,
      klasse2: null,
      klasse3: null,
      jpstatuser: null,
      manuell: false,
      LukkSak: false,
      Rekkefolge: rekkefolge[0],
      Rekkefolge2: rekkefolge[0],
      Rekkefolge3: rekkefolge[0],
      klasse1verdi: null,
      klasse2verdi: null,
      klasse3verdi: null,
      klasse1beskrivelse: null,
      klasse2beskrivelse: null,
      klasse3beskrivelse: null,
      tilgangsgruppesak: null,
      tilgangsgruppejp: null,
      restriksjoner: null,
      hjemmler: null,
      klasseverdi1manuell: false,
      klasseverdi2manuell: false,
      klasseverdi3manuell: false,
      klassebeskrivelse1manuell: false,
      klassebeskrivelse2manuell: false,
      klassebeskrivelse3manuell: false,
      arkivdel: null,
      mappetype: null,
      statusNySaksmappe: null,
      statusAvsluttSaksmappe: null,
      journalpostKategoriNavn: null,
      journalpostKategoriStatus: null,
      ekspederFoerEndeligStatus: false
    };
    this.hentArkivdeler();
  }

  hentArkivdeler() {
    arkivApi.getKodeliste('Arkivdel', uuid(), this.gotArkivdel);
    getOrganisasjonArkivSkjerming(this.gotOrg);
    //arkivApi.getKodeliste('Journalenhet', null, this.gotJournalenhet);
  }

  gotOrg = (err, arkivSkjerming) => {
    this.setState({ skjerming: arkivSkjerming });
  };

  gotArkivdel(err, arkivDeler) {
    console.log(arkivDeler);
    this.setState({
      arkivDeler: arkivDeler,
      arkivdel: arkivDeler[0].kodeverdi
    });
    arkivApi.getKodeliste('Mappetype', uuid(), this.gotMappetype);
  }
  // gotJournalenhet(err, journalenheter) {
  //     this.setState({
  //         journalenheter: journalenheter
  //     });
  // }
  gotMappetype(err, mappetyper) {
    this.setState({
      mappetyper: mappetyper,
      mappetype: mappetyper[0].kodeverdi
    });
    arkivApi.getKodeliste('Klassifikasjonssystem', uuid(), this.gotKlasser);
  }
  gotKlasser(err, klasser) {
    console.log(klasser);
    var tmp = klasser;
    var tom = { kodeverdi: '', kodebeskrivelse: '', erGyldig: false };
    tmp.unshift(tom);
    this.setState({
      klasser: tmp,
    });
    console.log(tmp);
    arkivApi.getKodeliste('Saksstatus', uuid(), this.gotStatuser);
  }
  gotStatuser(err, statuser) {
    this.setState({
      statuser: statuser,
      statusNySaksmappe: statuser[0].kodeverdi,
      statusAvsluttSaksmappe: statuser[0].kodeverdi,

    });
    arkivApi.getKodeliste('Journalstatus', uuid(), this.gotJPStatuser);
  }
  gotJPStatuser(err, statuser) {
    this.setState({
      jpstatuser: statuser,
    });
    arkivApi.getKodeliste('SkjermingsHjemmel', uuid(), this.gotHjemmler);
  }
  gotHjemmler = (err, hjemmler) => {
    var tmp = hjemmler;
    var tom = { kodeverdi: '', kodebeskrivelse: '', erGyldig: false };
    tmp.unshift(tom);
    this.setState({
      hjemmler: tmp,
    });
    arkivApi.getKodeliste('Tilgangsrestriksjon', uuid(), this.gotRestriksjoner);
  };
  gotRestriksjoner = (err, restriksjoner) => {
    var tmp = restriksjoner;
    var tom = { kodeverdi: '', kodebeskrivelse: '', erGyldig: false };
    tmp.unshift(tom);
    this.setState({
      restriksjoner: tmp,
    });
  };
  arkivDelChange(val) {
    this.setState({ arkivdel: val.target.value });
  }
  journalenhetChange(val) {
    sakstype.journalenhet = val.target.value;
  }
  handleNameChange(val) {
    sakstype.Navn = val.target.value;
  }
  mappetypeChange(val) {
    this.setState({ mappetype: val.target.value });
  }
  klasse1Change(val) {
    this.setState({ klasse1: val.target.value });
  }
  klasse2Change(val) {
    this.setState({ klasse2: val.target.value });
  }
  klasse3Change = (val) => {
    this.setState({ klasse3: val.target.value });
  };
  klasse3verdiChange = (val) => {
    this.setState({ klasse3verdi: val.target.value });
  };
  klasse2verdiChange = (val) => {
    this.setState({ klasse2verdi: val.target.value });
  };
  klasse1verdiChange = (val) => {
    this.setState({
      klasse1verdi: val.target.value
    });
  };
  klasse1beskrivelseChange = (val) => {
    this.setState({ klasse1beskrivelse: val.target.value });
  };
  klasse2beskrivelseChange = (val) => {
    this.setState({ klasse2beskrivelse: val.target.value });
  };
  klasse3beskrivelseChange = (val) => {
    this.setState({ klasse3beskrivelse: val.target.value });
  };
  statusChange(val) {
    this.setState({ statusNySaksmappe: val.target.value });
  }
  statusAvsluttChange(val) {
    this.setState({ statusAvsluttSaksmappe: val.target.value });
  }
  handleJpNameChange(val) {
    this.setState({ journalpostKategoriNavn: val.target.value });
  }
  jpstatusChange(val) {
    this.setState({ journalpostKategoriStatus: val.target.value });
  }
  handleEkspChange(val) {
    this.setState({ ekspederFoerEndeligStatus: val.target.value });
  }
  handleAdmChange(val) {
    sakstype.administrativenhet = val.target.value;
  }
  handleManuellChange = (val) => {
    this.setState({ manuell: val.target.value });
    sakstype.ManuellKlassering = val.target.value;
  };
  manuellKlasseTxtChange = (val) => {
    this.setState({ klasse1: val.target.value });
  };
  manuellKlasse2TxtChange = (val) => {
    this.setState({ klasse2: val.target.value });
  };
  lukkChange = (val) => {
    this.setState({ LukkSak: val.target.value === 'Ja' ? true : false });
  };
  rekkefolgeChange = (val) => {
    this.setState({ Rekkefolge: val.target.value });
  };
  rekkefolge2Change = (val) => {
    this.setState({ Rekkefolge2: val.target.value });
  };
  rekkefolge3Change = (val) => {
    this.setState({ Rekkefolge3: val.target.value });
  };
  tilgangsgruppesakChange = (val) => {
    this.setState({ tilgangsgruppesak: val.target.value });
  };
  tilgangsgruppejpChange = (val) => {
    this.setState({ tilgangsgruppejp: val.target.value });
    //sakstype.journalpostKategori[0].TilgangsgruppeNavn = val.target.value;
  };
  hjemmlerChange = (val) => {
    sakstype.Skjermingshjemmel = val.target.value;
  };
  restriksjonChange = (val) => {
    sakstype.Tilgangsrestriksjon = val.target.value;
  };

  sakstypeLagret(err, nySakstype) {
    console.log('Sakstype lagret');
    this.props.router.push({
      pathname: 'innstillinger/SakArkiv/Sakstyper',
    });
  }
  submit() {
    console.log(sakstype);
    // Sjekk klassering
    if (this.state.klasse1 !== null && this.state.klasse1 !== '') {
      sakstype.klasseListe = [
        {
          Klassifikasjonssystem: this.state.klasse1,
          Rekkefolge:
            this.state.Rekkefolge === '' ? null : this.state.Rekkefolge,
          KlasseID:
            this.state.klass1verdi === '' ? null : this.state.klasse1verdi,
          Tittel:
            this.state.klasse1beskrivelse === ''
              ? null
              : this.state.klasse1beskrivelse,
        },
      ];
      if (this.state.klasse2 !== '' && this.state.klasse2 != null) {
        var k2 = {
          Klassifikasjonssystem: this.state.klasse2,
          KlasseID: this.state.klasse2verdi,
          Rekkefolge:
            this.state.Rekkefolge2 === '' ? null : this.state.Rekkefolge2,
            Tittel:
            this.state.klasse2beskrivelse === ''
              ? null
              : this.state.klasse2beskrivelse,
        };
        sakstype.klasseListe.push(k2);
      }
      if (this.state.klasse3 !== '' && this.state.klasse3 != null) {
        var k3 = {
          Klassifikasjonssystem: this.state.klasse3,
          KlasseID: this.state.klasse3verdi,
          Rekkefolge:
            this.state.Rekkefolge3 === '' ? null : this.state.Rekkefolge3,
            Tittel:
            this.state.klasse3beskrivelse === ''
              ? null
              : this.state.klasse3beskrivelse,
        };
        sakstype.klasseListe.push(k3);
      }
    }
    sakstype.StatusNySaksmappe = this.state.statusNySaksmappe;
    sakstype.StatusAvsluttSaksmappe = this.state.statusAvsluttSaksmappe;
    sakstype.Mappetype=this.state.mappetype;
    sakstype.Arkivdel = this.state.arkivdel;
    sakstype.ManuellKlassering = this.state.manuell;
    sakstype.LukkSak = this.state.LukkSak;
    sakstype.TilgangsgruppeNavn = this.state.tilgangsgruppesak;
    let jpkat = [
      {
        Navn: this.state.journalpostKategoriNavn,
        NyJournalpostArkiveringStatus: this.state.journalpostKategoriStatus,
        EkspederFoerEndeligStatus: this.state.ekspederFoerEndeligStatus,
        TilgangsgruppeNavn: this.state.tilgangsgruppejp,
      },
    ];
    sakstype.JournalpostKategori = jpkat;
    console.log(sakstype);
    arkivApi.saveSakskategori('requestId', sakstype, this.sakstypeLagret);
  }

  render() {
    if (
      !this.state.arkivDeler ||
      !this.state.mappetyper ||
      !this.state.klasser ||
      !this.state.statuser ||
      !this.state.jpstatuser ||
      !this.state.restriksjoner ||
      !this.state.hjemmler
    ) {
      return <div className="loader" />;
    }
    sakstype.arkivdel = this.state.arkivDeler[0].kodeverdi;
    //sakstype.klasseListe = this.state.klasser[0].kodeverdi;
    sakstype.mappetype = this.state.mappetyper[0].kodeverdi;
    sakstype.statusNySaksmappe = this.state.statuser[0].kodeverdi;

    return (
      <div>
        <h2>Ny sakskategori</h2>
        <div>
          <form>
            <div className="row">
              {/* Venstre side */}
              <div
                className="col-sm-6"
                style={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'lightgray',
                }}
              >
                <br/>
                <div className="form-group">
                  <label>Sakstype navn</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ugyldige tegn: ? # \ /"
                    onChange={this.handleNameChange}
                    ref="nameInput"
                  />
                </div>
                <div className="form-group ">
                  <label>Arkivdel</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.arkivDelChange}
                    defaultValue={this.state.arkivDeler[0]}
                  >
                    {_.map(this.state.arkivDeler, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Mappetype</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.mappetypeChange}
                    defaultValue={this.state.mappetyper[0]}
                  >
                    {_.map(this.state.mappetyper, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Status ny sak</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.statusChange}
                    defaultValue={this.state.statuser[0]}
                  >
                    {_.map(this.state.statuser, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Status avslutt sak</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.statusAvsluttChange}
                    defaultValue={this.state.statuser[0]}
                  >
                    {_.map(this.state.statuser, function(o, i) {
                      return (
                        <option key={i} value={o.kodeverdi}>
                          {o.kodeverdi} - {o.kodebeskrivelse}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group ">
                  <label>Lukk sak når siste avvik lukkes</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.lukkChange}
                    defaultValue={lukk[0]}
                  >
                    {_.map(lukk, function(o, i) {
                      return (
                        <option key={i} value={o}>
                          {o}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Tilgangsgruppenavn (sak)</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={this.state.tilgangsgruppesak}
                    onChange={this.tilgangsgruppesakChange}
                  />
                </div>
                {this.state.skjerming ? (
                  <div>
                    <div className="form-group">
                      <label>Skjermingshjemmel</label>
                      <select
                        className="form-control"
                        id="hjemmler"
                        onChange={this.hjemmlerChange}
                        defaultValue={this.state.hjemmler[0]}
                      >
                        {_.map(this.state.hjemmler, function(o, i) {
                          return (
                            <option key={i} value={o.kodeverdi}>
                              {o.kodeverdi} - {o.kodebeskrivelse}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
                {this.state.skjerming ? (
                  <div>
                    <div className="form-group">
                      <label>Tilgangsrestriksjon</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.restriksjonChange}
                        defaultValue={this.state.restriksjoner[0]}
                      >
                        {_.map(this.state.restriksjoner, function(o, i) {
                          return (
                            <option key={i} value={o.kodeverdi}>
                              {o.kodeverdi} - {o.kodebeskrivelse}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>

              {/* Høyre side */}
              <div
                className="col-sm-6"
                style={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'lightgray',
                }}
              >
                <br />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 1</label>
                      
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse1Change}
                            defaultValue={this.state.klasser[0]}
                          >
                            {_.map(this.state.klasser, function(o, i) {
                              return (
                                <option key={i} value={o.kodeverdi}>
                                  {o.kodeverdi} - {o.kodebeskrivelse}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 1 rekkefølge</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.rekkefolgeChange}
                        defaultValue={rekkefolge[0]}
                      >
                        {_.map(rekkefolge, function(o, i) {
                          return (
                            <option key={i} value={o}>
                              {o}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 1 verdi</label>
                      {this.state.klasseverdi1manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse1verdi}
                            onChange={this.klasse1verdiChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse1verdiChange}
                            defaultValue={klasseVerdi[0]}
                          >
                            {_.map(klasseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Legg inn egen klasse verdi</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klasseverdi1manuell}
                        onChange={(e) => {
                          this.setState({
                            klasseverdi1manuell: !this.state
                              .klasseverdi1manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 1 beskrivelse</label>
                      {this.state.klassebeskrivelse1manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={beskrivelseVerdi[0]}
                            onChange={this.klasse1beskrivelseChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse1beskrivelseChange}
                            defaultValue={beskrivelseVerdi[0]}
                          >
                            {_.map(beskrivelseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bruk egen beskrivelse</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klassebeskrivelse1manuell}
                        onChange={(e) => {
                          this.setState({
                            klassebeskrivelse1manuell: !this.state
                              .klassebeskrivelse1manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 2</label>
                      
                        <div>
                          <select
                            className="form-control"
                            id="klasse"
                            onChange={this.klasse2Change}
                            defaultValue={this.state.klasse2}
                          >
                            {_.map(this.state.klasser, function(o, i) {
                              return (
                                <option key={i} value={o.kodeverdi}>
                                  {o.kodeverdi} - {o.kodebeskrivelse}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 2 rekkefølge</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.rekkefolge2Change}
                        defaultValue={this.state.Rekkefolge2}
                      >
                        {_.map(rekkefolge, function(o, i) {
                          return (
                            <option key={i} value={o}>
                              {o}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 2 verdi</label>
                      {this.state.klasseverdi2manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse2verdi}
                            onChange={this.klasse2verdiChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse2verdiChange}
                            defaultValue={klasseVerdi[0]}
                          >
                            {_.map(klasseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Legg inn egen klasse verdi</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klasseverdi2manuell}
                        onChange={(e) => {
                          this.setState({
                            klasseverdi2manuell: !this.state
                              .klasseverdi2manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 2 beskrivelse</label>
                      {this.state.klassebeskrivelse2manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={beskrivelseVerdi[0]}
                            onChange={this.klasse2beskrivelseChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse2beskrivelseChange}
                            defaultValue={beskrivelseVerdi[0]}
                          >
                            {_.map(beskrivelseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bruk egen beskrivelse</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klassebeskrivelse2manuell}
                        onChange={(e) => {
                          this.setState({
                            klassebeskrivelse2manuell: !this.state
                              .klassebeskrivelse2manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 3</label>

                      <div>
                        <select
                          className="form-control"
                          id="klasse"
                          onChange={this.klasse3Change}
                          defaultValue={this.state.klasse3}
                        >
                          {_.map(this.state.klasser, function(o, i) {
                            return (
                              <option key={i} value={o.kodeverdi}>
                                {o.kodeverdi} - {o.kodebeskrivelse}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 3 verdi</label>
                      {this.state.klasseverdi3manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.klasse3verdi}
                            onChange={this.klasse3verdiChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse3verdiChange}
                            defaultValue={klasseVerdi[0]}
                          >
                            {_.map(klasseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Legg inn egen klasse verdi</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klasseverdi3manuell}
                        onChange={(e) => {
                          this.setState({
                            klasseverdi3manuell: !this.state
                              .klasseverdi3manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group ">
                      <label>Klassering 3 rekkefølge</label>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.rekkefolge3Change}
                        defaultValue={this.state.Rekkefolge3}
                      >
                        {_.map(rekkefolge, function(o, i) {
                          return (
                            <option key={i} value={o}>
                              {o}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Klassering 3 beskrivelse</label>
                      {this.state.klassebeskrivelse3manuell ? (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={beskrivelseVerdi[0]}
                            onChange={this.klasse3beskrivelseChange}
                          />
                        </div>
                      ) : (
                        <div>
                          <select
                            className="form-control"
                            id="type"
                            onChange={this.klasse3beskrivelseChange}
                            defaultValue={beskrivelseVerdi[0]}
                          >
                            {_.map(beskrivelseVerdi, function(o, i) {
                              return (
                                <option key={i} value={o}>
                                  {o}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Bruk egen beskrivelse</label>
                      <br />
                      <input
                        className="checkbox30"
                        type="checkbox"
                        checked={this.state.klassebeskrivelse3manuell}
                        onChange={(e) => {
                          this.setState({
                            klassebeskrivelse3manuell: !this.state
                              .klassebeskrivelse3manuell,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
            <hr />
            <h3>Journalpostkategori</h3>
            <div className="row" style={{
                  borderStyle: 'solid',
                  borderWidth: '2px',
                  borderColor: 'lightgray',
                }}>
                  <p/>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Journalpostkategori navn</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ugyldige tegn: ? # \ /"
                  onChange={this.handleJpNameChange}
                  ref="jpnameInput"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group ">
                <label>Status ny journalpost</label>
                <select
                  className="form-control"
                  id="type"
                  onChange={this.jpstatusChange}
                  defaultValue={this.state.jpstatuser[0]}
                >
                  {_.map(this.state.jpstatuser, function(o, i) {
                    return (
                      <option key={i} value={o.kodeverdi}>
                        {o.kodeverdi} - {o.kodebeskrivelse}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Ekspeder før endelig status</label>
                <div>
                  {' '}
                  (Settes til 'true' KUN når arkivsystem er ESA og man VET at
                  denne skal brukes. Default: 'false. Bakgrunn: ESA kan
                  konfigureres til å kreve at ekspedertDato må være satt før
                  journalpoststatus kan settes til 'J - Journalført'. )
                </div>
                <input
                  type="text"
                  className="form-control"
                  defaultValue="false"
                  onChange={this.handleEkspChange}
                  ref="jpekspInput"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>
                  Bruk alternativ input for klassering(true eller false)
                </label>
                <div>
                  <p /> (Pga feil i P360 er det mulighet for å legge inn
                  klassering manuelt da valgene som vises er feil. Vil bli
                  fjernet når P369 har rettet sin feil. )
                  <p />
                </div>
                <input
                  className="checkbox30"
                  type="checkbox"
                  checked={this.state.manuell}
                  onChange={(e) => {
                    this.setState({
                      manuell: !this.state.manuell,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Tilgangsgruppenavn (journalpost)</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={this.state.tilgangsgruppejp}
                  onChange={this.tilgangsgruppejpChange}
                />
              </div>
            </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-md-12">
                <div className="pull-left">
                  <input
                    type="button"
                    className="btn btn-primary"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(NySakstype);
