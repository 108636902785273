import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';

import { debugFactory, objGetDeep } from 'utils';

const debug = debugFactory('nk:innstillinger:index')

export default function (props) {
    debug('props', { props });
    const pathname = objGetDeep(props, 'location.pathname');
    return (
        <div>            
            <LinkList title="Kart" columns={2} >
                <LinkListItem title="Rediger områder og soner" path={`${pathname}/RedigerSoner`} />
                <LinkListItem title="Oversikt Wms" path={`${pathname}/AdministrerWms`} /> 
                {/* <LinkListItem title="Kartinnstillinger hide" path={`${pathname}/Kartinnstillinger`} /> */}
            </LinkList>
        </div>
    );
}