import React, { Component } from 'react';
import { postBruksenhetAdresser2 } from 'api/enhetApi.js';
import ReactTable from '@norkart/react-table';
import SituasjonButton from 'modules/felles/SituasjonButton.jsx';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:DeltAvvikValgModal');

class DeltAvvikValgModal extends Component {
  constructor(props) {
    super(props);
    this.state = { bruksenheter: [] };
  }

  componentDidMount = () => {
    this.init();
  };

  init = () => {
    console.log(this.props.bruksenheter);
    postBruksenhetAdresser2(this.props.bruksenheter, this.gotData);
  };

  gotData = (err, res) => {
    debug('BBBB', res);
    this.setState({ bruksenheter: res });
  };

  onRowClick = (item) => {
    this.props.onOk(item.bruksenhetIdFag, item.adresse);
  };

  render() {
    var colums = this.getColums();
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          {/* <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button> */}
          <h4 className='modal-title'>Delt avvik</h4>
        </div>
        <div className='modal-body' style={{ padding: '0 20px' }}>
          <br />
          Du har valgt å åpne et avvik som er delt mellom flere bruksenheter.
          Klikk på bruksenheten for å velge hvilken bruksenhet du vil vise avvik
          oversikt for.
          <br />
          <div>
            <ReactTable
              showIndex={false}
              items={this.state.bruksenheter}
              columns={colums}
              selectableRows={false}
              onRowClick={this.onRowClick}
              onSelectedItemChanged={this.rowSelected}
              toggleAll={false}
              toggleAllDefault={false}
              sortable={false}
              multiSort={false}
              filterable={false}
            />
          </div>
          <hr />
        </div>
      </div>
    );
  }
  getColums = (items) => {
    return [
      {
        id: 'situasjon',
        name: 'Sit.',
        sortParams: 'situasjon',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <div style={{ maxWidth: '30px' }}>
              <SituasjonButton
                fra='deltavvik'
                hoover={false}
                item={item}
                link={false}
              />
            </div>
          );
        },
      },
      {
        id: 'adresse',
        name: 'Bruksenhet',
        isSorted: false,
        sortable: false,
      },
    ];
  };
}

export default DeltAvvikValgModal;
