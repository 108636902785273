import { makeGetTypeKey } from 'redux/utils';
const getTypeKey = makeGetTypeKey('app');

export const INCREMENT_REQUEST_COUNT = getTypeKey('INCREMENT_REQUEST_COUNT');
export const DECREMENT_REQUEST_COUNT = getTypeKey('DECREMENT_REQUEST_COUNT');
export const EXCEPTION_OCCURED = getTypeKey('EXCEPTION_OCCURED');
export const UPDATE_FETCH_STATE = getTypeKey('UPDATE_FETCH_STATE');
export const RESET_FETCH_STATES = getTypeKey('RESET_FETCH_STATES');
export const SET_STATE_SNAPSHOT = getTypeKey('SET_STATE_SNAPSHOT');

