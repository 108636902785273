import React, { useState } from 'react';
 import NKACheckbox from 'nka-checkbox';
import 'nka-checkbox/dist/style.css';
import NkaFormElementDropdown from 'nka-form-element-dropdown';
import 'nka-form-element-dropdown/dist/style.css';
import NkaFormElementTextInput from 'nka-form-element-text-input';
import 'nka-form-element-text-input/dist/style.css';
import NkaFormElementRadioButtons from 'nka-form-element-radio-buttons';
import 'nka-form-element-radio-buttons/dist/style.css'; 


const moduler = [
  {
    modul: 1,
    label: 'Brannforebygging',
  },
  {
    modul: 2,
    label: 'Par. 13',
  },
  {
    modul: 3,
    label: 'Arkiv',
  },
];




function Test4() {
  const [dropValue, setDropValue] = useState({ modul: 1, label: 'Brannforebygging' });
  const [radioValue, setRadioValue] = useState('Knut');
  const [userValue, setUserValue] = useState('Anne Strand');

  const handleDropValueChange = (e) => {
    setDropValue(e);
  };

  const handleRadioValueChange = (e) => {
    setRadioValue(e);
  };

  const handleValueChangeUsername = (e) => {
    console.log('Zapp', e);
    setUserValue(e.target.value);
  };

  return (
    <div>
      <br /> <br />
       <NKACheckbox id='mycheck' name='mycheck' value='test'>
        Dette er en sjekkboks
      </NKACheckbox>
      <br /> <br />
      <div style={{ width: 400 }}>
        <NkaFormElementDropdown
          handleValueChange={handleDropValueChange}
          value={dropValue}
          values={moduler}
          other={{ placeholder: 'Velg noe' }}
        />
      </div>
      <br /> <br />
      <div style={{ width: 500 }}>
        <NkaFormElementRadioButtons
          checked={radioValue}
          values={[
            'Stein',
            'Knut',
            'Rieni',
          ]}
          handleValueChange={handleRadioValueChange}
        />
      </div>
      <br /> <br />
      <NkaFormElementTextInput
                    handleValueChange={handleValueChangeUsername}
                    value={userValue}
                    type={'text'}
                    placeholder={'Skriv Brukernavn'}
                    other={{
                        tabIndex: 1
                        }}
                /> 
                <br/><br/>
              {/*   <ExpandableTextList style={{ fontSize: '20px' }} sfont={ '16px' } items={items} title={'Min liste'} /> */}
    </div>
  );
}

export default Test4;
