import callApi from './api';  

// InnbyggerDialog   

export function nyInnbyggerDialog(innbyggerDialog, callback) {
    callApi('POST', '/innbyggerdialog/', innbyggerDialog, callback);
};

export function deleteInnbyggerDialog(bruksenhetId, callback) {
    callApi('DELETE', '/innbyggerdialog/' + bruksenhetId, null, callback);
};

export function getInnbyggerdialogByBruksenhetId(bruksenhetId, callback) {  
    callApi('GET', '/innbyggerdialog/bruksenhet/' + bruksenhetId, null, callback); 
}

export function getInfoOmSendtTilInnbygger(bruksenhetId, listeId, callback) {
    callApi('GET', '/bruksenhet/gjennomforing/sendttilinnbygger/' + bruksenhetId + '/' + listeId, null, callback);
}

export function getInfoOmSendtTilInnbyggerOld(bruksenhetId, listeId, callback) {
    callApi('GET', '/innbyggerdialog/gjennomforing/sendttilinnbygger/' + bruksenhetId + '/' + listeId, null, callback);
}

export function sendTilEierFraOppf(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendtileier/ikkepublisert/', param, callback);
    //callApi('POST', '/innbyggerdialog/oppfolging/sendTilEier/', param, callback);
}

export function sendTilEierFraOppf2(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendtileier/ikkepublisert/', param, callback);
}

export function sendTilSidemannFraOppf2(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendtilsidemannskontroll/ikkepublisert/', param, callback);
}

export function sendTilSaksbehandlerFraOppf(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendtilsaksbehandler/ikkepublisert/', param, callback);
}

export function sendTilEierFraOppfPublisert(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendTilEier/publisert/', param, callback);
}

/* export function sendTilEierFraOppfPublisert2(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendTilEier/publisert2/', param, callback);
} */

// export function sendTilEierFraOppfLukkPublisert(param, callback) {
//     callApi('POST', '/innbyggerdialog/oppfolging/sendtileier/publisertlukk/', param, callback);
// }

export function sendTilEierFraOppfLukkPublisert2(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendtileier/publisertlukk/', param, callback);
}

export function sendTilEierFraOppfPubliserBruksenhet(param, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/sendtileier/publisert/bruksenhet', param, callback);
}

export function sendTilEierFraGjennomforingLukkPublisert2(param, callback) {
    callApi('POST', '/innbyggerdialog/gjennomforing/sendtileier/publisertlukk/', param, callback);
}

export function getAvvikAnmerkningIkkePublisertForBruksenhet(bruksenhetId, callback) {
    callApi('GET', '/innbyggerdialog/oppfolging/avvikanmerkningikkepublisert/' + bruksenhetId, null, callback);
}

export function getPublisertAvvikDialogOppfolging(avvikId, objektType, callback) {
    callApi('GET', '/innbyggerdialog/oppfolging/avvikpublisert/' + avvikId + '/' + objektType, null, callback);
}

export function sendTilEierFraGjennomforing(param, callback) {
    callApi('POST', '/innbyggerdialog/gjennomforing/sendtileier/ikkepublisert/', param, callback);
    //callApi('POST', '/innbyggerdialog/bruksenhetsituasjon/sendTilEier/', param, callback);
}

// export function sendTilEierFraGjennomforing2(param, callback) {
//     callApi('POST', '/innbyggerdialog/gjennomforing/sendtileier/ikkepublisert2/', param, callback);
// }


// InnbyggerDialogAvvik

export function getInnbyggerdialogAvvikByAvvikId(avvikId, callback) {
    callApi('GET', '/innbyggerdialogavvik/avvik/' + avvikId, null, callback);
}

export function slettInnbyggerdialogAvvikDialog( innleggObj, callback) {
    callApi('POST', '/innbyggerdialogavvik/slett',  innleggObj , callback);
}

export function nyInnbyggerDialogAvvik(innlegg, callback) {
    callApi('POST', '/innbyggerdialogavvik/', innlegg, callback);
};

export function flettMal(dto, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/malfletting/', dto, callback);
};

//- returnerer maltekst ferdig flettet med data for lukk avvik
export function flettMalVedLukk(dto, callback) {
    callApi('POST', '/innbyggerdialog/oppfolging/lukkavvikmalfletting/', dto, callback);
};

export function deleteInnbyggerDialogAvvik(avvikId, callback) {
    callApi('DELETE', '/innbyggerdialogavvik/' + avvikId, null, callback);
};

export function endreInnbyggerdialogAvvik( oppdatertInnlegg, callback) {
    callApi('PUT', '/innbyggerdialogavvik/0', oppdatertInnlegg, callback);
};

export function orgstartautomatiseringvarselimmediately(orgId, callback) {
    callApi('GET', '/innbyggerdialog/orgstartautomatiseringvarselimmediately/' + orgId, null, callback);
};


// InnbyggerDialogMinEiendom