import React from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import { getPurringMalerForFunksjon, getMaler, getLukkAvvikMaler } from 'api/malerApi';
import './maleditor.css';

const debug = debugFactory('nk:DokEditor');

class Malsoek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maler: [],
    };
  }

  componentDidMount = () => {
    if (this.props.skalLukkes){
     getLukkAvvikMaler(this.props.tiltakTypeId, this.gotLukkAvvikMaler);
    } else {
      getPurringMalerForFunksjon(this.props.tiltakTypeId, this.gotMaler);
    }
  };


  gotMaler = (err, data) => {
    this.setState({ maler: data });
  };

  gotLukkAvvikMaler = (err, data) => {
    debug('Torsdag x', data);
    this.setState({ maler: data });
  };

  gotMalerO = (err, data) => {
    let m = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].funksjon === 'Lukk avvik') {
        m.push(data[i]);
      }
    }
    this.setState({ maler: m });
    debug('MalerXX -G', m);
  };

  brukMal = (mal) => {
    this.props.onInsertMal(mal);
  };

  render() {
    let x = 0;
    return (
      <div style={{ height: '500px' }} className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Velg mal </h4>
        </div>
        <div className='modal-body'>
          <div
            className='list-group'
            style={{ maxHeight: '390px', overflow: 'auto' }}>
            {this.state.maler.map(function(mal) {
              var tittel = mal.tittel;
              var harIngenTekst = mal.tekst === '';
              if (harIngenTekst) {
                tittel = tittel + ' (Mangler tekst)';
              }
              x = x + 1;
              if (mal) {
                return (
                  <button
                    key={x}
                    type='button'
                    onClick={this.brukMal.bind(this, mal)}
                    className='list-group-item list-group-item-action'>
                    {harIngenTekst && (
                      <span style={{ color: 'darkred' }}>{tittel}</span>
                    )}
                    {!harIngenTekst && <span>{tittel}</span>}
                  </button>
                );
              }
            }, this)}
          </div>
        </div>
      </div>
    );
  }
}

export default Malsoek;
