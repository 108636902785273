import React from 'react';
import pilot from 'images/Annet/pilot1.png';
import * as personApi from 'api/personApi';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { getInnloggetPerson, oppdaterContextInnstillingerPersonFraMinSide } from 'context/contextInnstillinger';
// Utils
import { debugFactory } from 'utils';

const debug = debugFactory('nk:tilgang:minside');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={'Oppdatert'}
      melding={'Dine opplysningene har blitt oppdatert.'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  )
};

class MinSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: null
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }


  componentDidMount() {
    getInnloggetPerson(this.gotPerson);
  }

  gotPerson= (person) => {
    this.setState({ person });
  };

  saveChanges = () => {
    debug('saveChanges', { ...this });
    personApi.updatePerson(this.state.person.id, this.state.person, this.personOppdatert);
  };

  personOppdatert= (err, data) => {
      oppdaterContextInnstillingerPersonFraMinSide(data);
      this.dialogs.setOpenDialog('OkDialog');
  };

  render() {
    debug('render', { ...this });

    const person = this.state.person;;

    if (!person) {
      return <div>Laster..</div>;
    } 

    return (
      <div className='panel panel-default'>
        <div className='panel-body'>
          <div className='form'>
            <h3>Mine opplysninger</h3>
            <div className='row'>
              <div className='col-sm-2'>
                <label>Navn</label>
              </div>
              <div className='col-sm-10 form-group'>
                <input
                  autoFocus
                  maxLength='250'
                  className='form-control'
                  type='text'
                  value={person.navn}
                  onChange={e => {
                    this.setState({
                      person: {
                        ...person,
                        navn: e.target.value
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-2 form-group'>
                <label>Telefonnummer</label>
              </div>
              <div className='col-sm-10 form-group'>
                <input
                  placeholder='Eks: 111 22 333'
                  maxLength='50'
                  className='form-control'
                  type='text'
                  value={person.telefon ? person.telefon : ''}
                  onChange={e => {
                    this.setState({
                      person: {
                        ...person,
                        telefon: e.target.value
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-2 form-group'>
                <label>Mobilnummer</label>
              </div>
              <div className='col-sm-10 form-group'>
                <input
                  placeholder='Eks: 111 22 333'
                  maxLength='50'
                  className='form-control'
                  type='text'
                  value={person.mobil ? person.mobil : ''}
                  onChange={e => {
                    this.setState({
                      person: {
                        ...person,
                        mobil: e.target.value
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-2 form-group'>
                <label>Epost</label>
              </div>
              <div className='col-sm-10 form-group'>
                <input
                  placeholder='Eks: ola.nordmann@gmail.com'
                  maxLength='50'
                  className='form-control'
                  type='email'
                  value={person.epost ? person.epost : ''}
                  onChange={e => {
                    this.setState({
                      person: {
                        ...person,
                        epost: e.target.value
                      }
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-2 form-group'>
                <label>Gjennomføring åpner standard kart først</label>
              </div>
              <div className='col-sm-10 form-group'>
                <input
                  className='form-check-input'
                  style={{ width: '20px', height: '20px' }}
                  type='checkbox'
                  checked={person.visningKartStandard}
                  onChange={e => {
                    this.setState({
                      person: {
                        ...person,
                        visningKartStandard: !person.visningKartStandard
                      }
                    });
                  }}
                />
              </div>

             {person.pilotBruker && (
                <span className='pull-right'>
                  <img style={{ height: '100px' }} src={pilot} alt='' />
                </span>
              )}
              
            </div>

            <br />
            <button
              className='btn btn-primary pull-right'
              onClick={this.saveChanges}>
              Oppdater
            </button>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
export default MinSide;
