import { makeGetTypeKey } from 'redux/utils';
const getTypeKey = makeGetTypeKey('planlegging');

export const PERSONER_FETCHED = getTypeKey('PERSONER_FETCHED');
export const BRUKERE_FETCHED = getTypeKey('BRUKERE_FETCHED');
export const TILTAKSTYPER_FETCHED = getTypeKey('TILTAKSTYPER_FETCHED');
export const ARBEIDSLISTER_FETCHED = getTypeKey('ARBEIDSLISTER_FETCHED');
export const ARBEIDSLISTE_ENHETER_FETCHED = getTypeKey('ARBEIDSLISTE_ENHETER_FETCHED');
export const ARBEIDSLISTE_TILTAKSLISTER_FETCHED = getTypeKey('ARBEIDSLISTE_TILTAKSLISTER_FETCHED');
export const ENHETER_MED_EIERE_FETCHED = getTypeKey('ENHETER_MED_EIERE_FETCHED');
export const ENHETER_MED_EIERE2_FETCHED = getTypeKey('ENHETER_MED_EIERE2_FETCHED');
export const ARBEIDSLISTE_SELECTED = getTypeKey('ARBEIDSLISTE_SELECTED');
export const UPDATE_ARBEIDSLISTE = getTypeKey('UPDATE_ARBEIDSLISTE');
export const SET_ARBEIDSLISTE_TILTAKSLISTER = getTypeKey('SET_ARBEIDSLISTE_TILTAKSLISTER');
export const INITIALIZE_LEGG_TIL_OBJEKTER = getTypeKey('INITIALIZE_LEGG_TIL_OBJEKTER');
export const REMOVE_ARBEIDSLISTE = getTypeKey('REMOVE_ARBEIDSLISTE');
export const ADD_NEW_ARBEIDSLISTE = getTypeKey('ADD_NEW_ARBEIDSLISTE');
export const UPDATE_OBJEKT_LOCK_REASONS = getTypeKey('ADD_ARBEIDSLISTE_BRUKSENHETID_LOCKED_REASONS');
export const SET_OBJEKT_LOCK_REASONS = getTypeKey('SET_OBJEKT_LOCK_REASONS');
export const ARBEIDSLISTE_UPDATED = getTypeKey('ARBEIDSLISTE_UPDATED');




