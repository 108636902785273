import callApi from './api';

//POST: api/bruksenhetvarsel - lagrer nye - bruksenheter for varsling
export function sendBruksenhetvarsel(BruksenhetVarselDtoer, callback) {
    callApi('POST', '/bruksenhetvarsel', BruksenhetVarselDtoer, callback); 
};

export function sendEierevarsel(BruksenhetVarselDtoer, callback) {
    callApi('POST', '/bruksenhetvarsel/innbygger', BruksenhetVarselDtoer, callback); 
};

export function sendEierevarsel2(BruksenhetVarselDtoer, callback) {
    callApi('POST', '/bruksenhetvarsel/innbygger2', BruksenhetVarselDtoer, callback); 
};

//Fra SmsController i SkyBrannApi
export function getSmsByArbeidslisteId(arbeidslisteId, callback) {
    callApi('GET', '/sms/GetSmsByArbeidslisteId/' + arbeidslisteId, null, callback);
};

export function sendSms(sms, callback) {
    callApi('POST', '/sms/PostSendSms/', sms, callback);
};

export function sendGenerellSmsTilBruksenheter(smsGenerellDto, callback) {
    callApi('POST', '/sms/bruksenhet/', smsGenerellDto, callback);
}

export function sendteSms(smsfilterDto, callback) {
    callApi('POST', '/sms/SendteSms/', smsfilterDto, callback);
}

