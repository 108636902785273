import React from 'react';
import {
  CheckboxFilter,
  RefinementListFilter,
  GroupedSelectedFilters,
  InputFilter,
  Panel,
  DynamicRangeFilter,
  RangeInput,
  TermQuery,
  MenuFilter,
  BoolMustNot,
  BoolMust,
  NestedQuery,
  BoolShould,
  Select,
  PageSizeSelector,
} from 'searchkit';
import './mobile.css';
import {
  CustomQueryBuilder,
  CustomQueryBuilderBygg,
  CustomQueryBuilderEnhet,
} from 'components/Search/QueryBuilder.js';
import { RefinementOption } from 'components/Search/RefinementOption.jsx';
import { RefinementOptionMinus } from 'components/Search/RefinementOptionMinus.jsx';
import {
  DateRangeFilter,
  DateRangeCalendar,
} from 'components/Search/Datefilter';
import LagredeFiltersMenu from 'components/Search/LagredeFiltersMenu.jsx';
import PersonSok from 'components/Search/PersonSok.jsx';
import { NestedAccessor } from 'components/Search/Accessors/NestedListAccessor.jsx';

class CustomRefinementFilter extends RefinementListFilter {
  accessor: NestedAccessor;

  getAccessorOptions() {
    const {
      field,
      id,
      operator,
      only,
      title,
      include,
      exclude,
      size,
      translations,
      orderKey,
      orderDirection,
      fieldOptions,
    } = this.props;
    return {
      id,
      operator,
      only,
      title,
      size,
      include,
      exclude,
      translations,
      orderKey,
      orderDirection,
      fieldOptions,
    };
  }
  defineAccessor() {
    return new NestedAccessor(this.props.field, this.getAccessorOptions());
  }
}

const FiltersMobile = ({
  brukLagretSok,
  visLagreSokDialog,
  onSelectedPerson,
}) => {
  return (
    <div style={{ marginLeft: '8px', width: '90%' }}>
      <div
        style={{
          marginLeft: '0px',
          marginBottom: '4px',
          width: '80%',
          padding: '4px',
          borderColor: 'blue',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}>
        <table style={{ width: '95%', margin: 0, padding: 0 }}>
          <tbody>
            <tr>
              <td>
                <h4 style={{ marginRight: '4px', marginTop: '2px' }}>
                  Søkefilter
                </h4>
              </td>
              <td style={{ verticalAlign: 'center' }}>
                <PageSizeSelector
                  className='pull-left'
                  options={[10, 50, 100, 250, 500, 1000, 2000]}
                  listComponent={Select}
                />
                <span
                  style={{
                    fontSize: '12px',
                    marginTop: '2px',
                    marginLeft: '4px',
                  }}>
                  pr.side
                </span>
              </td>
              <td style={{ verticalAlign: 'top' }}>
                <div className='pull-right'>
                  <LagredeFiltersMenu
                    brukLagretSok={brukLagretSok}
                    visLagreSokDialog={visLagreSokDialog}
                  />
             {/*      <ContextMenuSok
                    sokLagrede={sokLagrede}
                    brukLagretSok={brukLagretSok}
                    onDeletedSok={onDeletedSok}
                    visLagreSokDialog={visLagreSokDialog}
                  /> */}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <GroupedSelectedFilters
          translations={{
            true: 'Ja',
            false: 'Nei',
          }}
        />
      </div>

      <Panel title='Adresse' collapsable={true} defaultCollapsed={true}>
        <RefinementListFilter
          field='kommune.kommuneNavn.keyword'
          title='Kommune'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'kommune',
            },
          }}
          size={3}
          operator='OR'
          id='kommune'
        />

        <InputFilter
          id='gatenavn'
          title='Gatenavn'
          queryOptions={{ minimum_should_match: '100%' }}
          searchOnChange={true}
          searchThrottleTime={500}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
          queryFields={['gatenavn']}
          queryBuilder={CustomQueryBuilder}
        />

        <DynamicRangeFilter
          field='adresse.husnummer'
          rangeComponent={RangeInput}
          translations={{
            'range.submit': 'OK',
          }}
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'adresse',
            },
          }}
          title='Husnummer'
          id='husnummer'
        />

        <InputFilter
          id='bokstav'
          title='Bokstav'
          searchOnChange={true}
          queryBuilder={CustomQueryBuilder}
          queryFields={['bokstav']}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
        />

        <DynamicRangeFilter field='etasjenummer' id='etasje' title='Etasje' />

        <DynamicRangeFilter
          field='lopenummer'
          id='etasjenummer'
          title='Etasjenummer'
        />

        <RefinementListFilter
          field='etasjeplan.keyword'
          title='Etasjetype'
          size={5}
          operator='OR'
          id='Etasjetype'
        />
      </Panel>
      <Panel title='Eier' collapsable={true} defaultCollapsed={true}>
        <PersonSok
          placeholder='Ola Hansen, Oslo'
          onSelected={onSelectedPerson}
        />
      </Panel>
      <Panel title='Eiendom' collapsable={true} defaultCollapsed={true}>
        <InputFilter
          id='gnr'
          title='Gnr'
          searchOnChange={false}
          queryFields={['gardsnummer']}
          queryBuilder={CustomQueryBuilderEnhet}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
        />

        <InputFilter
          id='bnr'
          title='Bnr'
          searchOnChange={false}
          queryFields={['bruksnummer']}
          queryBuilder={CustomQueryBuilderEnhet}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
        />

        <InputFilter
          id='fnr'
          title='Fnr'
          searchOnChange={false}
          queryFields={['festenummer']}
          queryBuilder={CustomQueryBuilderEnhet}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
        />

        <InputFilter
          id='snr'
          title='Snr'
          searchOnChange={false}
          queryFields={['seksjonsnummer']}
          queryBuilder={CustomQueryBuilderEnhet}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
        />
      </Panel>

      <Panel title='Bygning' collapsable={true} defaultCollapsed={true}>
        <InputFilter
          id='bygningsnummer'
          title='Bygningsnummer'
          searchOnChange={false}
          queryFields={['bygningsnummer']}
          queryBuilder={CustomQueryBuilderBygg}
          translations={{
            'searchbox.placeholder': 'Søk',
          }}
        />

        <RefinementListFilter
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'bygg',
            },
          }}
          field='bygg.bygningsType'
          title='Bygningstype'
          size={6}
          operator='OR'
          id='type'
        />

        <RefinementListFilter
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'bygg',
            },
          }}
          field='bygg.bygningstatus'
          title='Bygningsstatus'
          size={6}
          operator='OR'
          id='bygningstatuskode'
        />
        <RefinementListFilter
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'bygg',
            },
          }}
          field='bygg.naringsgruppe'
          title='Næringsgruppe'
          size={6}
          operator='OR'
          id='naringsgruppe'
        />

        <CheckboxFilter
          id='sefrak'
          title=''
          label='Har SEFRAK-minne'
          filter={BoolMust(
            NestedQuery(
              'bygg',
              BoolShould([TermQuery('bygg.harSefrakminne', 'true')])
            )
          )}
        />
      </Panel>

      <Panel title='Bruksenhet' collapsable={true} defaultCollapsed={true}>
        {/*  <span className='spanunum'><b>Nummerert</b></span> */}
        <CheckboxFilter
          title=''
          id='-'
          label='Skjul unummererte bruksenheter'
          showCount={false}
          filter={BoolMustNot([TermQuery('bruksenhetstypekode', 'U')])}
        />

        {/*   <CheckboxFilter
          id='fyringsforbud'
          title=''
          label='Har fyringsforbud'
          filter={BoolMust(
            NestedQuery(
              'skybrannData.bruksenhet',
              BoolShould([
                TermQuery('skybrannData.bruksenhet.fyringsforbud', 'true'),
                BoolMust({
                  exists: {
                    field: 'skybrannData.bruksenhet.fyringsforbud',
                  },
                }),
              ])
            )
          )}
        /> */}

        <RefinementListFilter
          field='bruksenhetstype.keyword'
          title='Type bruksenhet'
          size={3}
          operator='OR'
          id='bruksenhettype'
        />

        <DynamicRangeFilter
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.bruksenhet',
            },
          }}
          field='skybrannData.bruksenhet.risikovurdering'
          title='Risikoverdi'
          showHistogram={false}
          id='risikovurdering'
        />

        <MenuFilter
          field='skybrannData.bruksenhet.risikoverdiOverstyr'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_brisikoover',
                _source: false,
                docvalue_fields: ['skybrannData.bruksenhet.id.keyword'],
              },
              path: 'skybrannData.bruksenhet',
            },
          }}
          title='Risikoverdi overstyrt'
          size={5}
          id='brisikoover'
          itemComponent={RefinementOption}
          translations={{ All: 'Alle' }}
        />

        <CheckboxFilter
          id='Registrert'
          title=''
          label='Bruksenheter registrert i brannforebygging'
          filter={BoolMust(
            NestedQuery('skybrannData.bruksenhet', {
              exists: {
                field: 'skybrannData.bruksenhet.fagId',
              },
            })
          )}
        />

        <CheckboxFilter
          id='Ikke registrert'
          title=''
          label='Bruksenheter ikke registrert i brannforebygging'
          filter={BoolMustNot(
            NestedQuery('skybrannData.bruksenhet', {
              exists: {
                field: 'skybrannData.bruksenhet.fagId',
              },
            })
          )}
        />

        {/* 
                <CheckboxFilter
                    id="Fiktiv"
                    title=""
                    label="Bruksenheter uten matrikkelknytning"
                    filter={TermQuery('fromMatrikkel', 'false')}
                />  
                */}
      </Panel>

      <Panel title='Røykløp' collapsable={true} defaultCollapsed={true}>
        <MenuFilter
          field='skybrannData.ildsteder.iBruk'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_iildstedIBruk',
                _source: false,
                docvalue_fields: ['skybrannData.ildsteder.id.keyword'],
                /*   docvalue_fields: ['skybrannData.ildsteder.royklopId.keyword'] */
              },
              path: 'skybrannData.ildsteder',
            },
          }}
          title='I bruk'
          size={2}
          id='iBruk2'
          /*    countFormatter={(count) => null}  Rieni*/
          //Ikke vis de aggregerte tall for at de er feil.
          itemComponent={RefinementOptionMinus}
          translations={{ All: 'Alle' }}
        />

        <RefinementListFilter
          field='skybrannData.royklop.feieadkomst.keyword'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_rfeieadkomst',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
              path: 'skybrannData.royklop',
            },
          }}
          title='Feieadkomst'
          operator='OR'
          size={5}
          id='feieadkomst'
        />

        <DynamicRangeFilter
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklop',
              inner_hits: {
                name: 'inner_hit_rrisikoverdi',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
            },
          }}
          field='skybrannData.royklop.risikoverdi'
          title='Risikoverdi'
          showHistogram={false}
          id='rrisikovurdering'
        />

        <MenuFilter
          field='skybrannData.royklop.risikoverdiOverstyr'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_rrisikoover',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
              path: 'skybrannData.royklop',
            },
          }}
          title='Risikoverdi overstyrt'
          size={5}
          id='rrisikoover'
          itemComponent={RefinementOption}
          translations={{ All: 'Alle' }}
        />

        <RefinementListFilter
          field='skybrannData.royklop.produsent'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklop',
              inner_hits: {
                name: 'inner_hit_rprodusent',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
            },
          }}
          title='Produsent'
          operator='OR'
          size={5}
          id='produsent'
        />

        <RefinementListFilter
          field='skybrannData.royklop.modell'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_rModell',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
              path: 'skybrannData.royklop',
            },
          }}
          title='Modell'
          operator='OR'
          size={5}
          id='modell'
        />

        <RefinementListFilter
          field='skybrannData.royklop.type'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_rType',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
              path: 'skybrannData.royklop',
            },
          }}
          title='Type'
          operator='OR'
          size={5}
          id='typeR'
        />

        <RefinementListFilter
          field='skybrannData.royklop.plassering'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_rplassering',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
              path: 'skybrannData.royklop',
            },
          }}
          title='Plassering'
          operator='OR'
          size={5}
          id='plassering'
        />

        <MenuFilter
          field='skybrannData.royklop.rehabilitert'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_rrehab',
                _source: false,
                docvalue_fields: ['skybrannData.royklop.id.keyword'],
              },
              path: 'skybrannData.royklop',
            },
          }}
          title='Rehabilitert'
          size={5}
          id='rehabilitert'
          itemComponent={RefinementOption}
          translations={{ All: 'Alle' }}
        />

        <DateRangeFilter
          id='eventdatefilter'
          title='Pipebrann'
          calendarComponent={DateRangeCalendar}
          field='skybrannData.royklop.pipebrannDato'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklop',
            },
          }}
        />
      </Panel>

      <Panel title='Ildsted' collapsable={true} defaultCollapsed={true}>
        <RefinementListFilter
          field='skybrannData.ildsteder.produsent'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_iproyklopId',
                _source: false,
                docvalue_fields: ['skybrannData.ildsteder.royklopId.keyword'],
              },
              path: 'skybrannData.ildsteder',
            },
          }}
          title='Produsent'
          operator='OR'
          size={5}
          id='produsentI'
        />

        <RefinementListFilter
          field='skybrannData.ildsteder.modell'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_imroyklopId',
                _source: false,
                docvalue_fields: ['skybrannData.ildsteder.royklopId.keyword'],
              },
              path: 'skybrannData.ildsteder',
            },
          }}
          title='Modell'
          operator='OR'
          size={5}
          id='modellI'
        />

        <RefinementListFilter
          field='skybrannData.ildsteder.type'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_itroyklopId',
                _source: false,
                docvalue_fields: ['skybrannData.ildsteder.royklopId.keyword'],
              },
              path: 'skybrannData.ildsteder',
            },
          }}
          title='Type'
          operator='OR'
          size={5}
          id='typeI'
        />

        <MenuFilter
          field='skybrannData.ildsteder.rentbrennende'
          fieldOptions={{
            type: 'nested',
            options: {
              inner_hits: {
                name: 'inner_hit_irroyklopId',
                _source: false,
                docvalue_fields: ['skybrannData.ildsteder.royklopId.keyword'],
              },
              path: 'skybrannData.ildsteder',
            },
          }}
          title='Rentbrennende'
          size={2}
          id='rentbrennende'
          itemComponent={RefinementOption}
          translations={{ All: 'Alle' }}
        />
      </Panel>

      <Panel
        title='Regelm. tiltak bruksenhet'
        collapsable={true}
        defaultCollapsed={true}>
        <CustomRefinementFilter
          field='skybrannData.bruksenhetTiltakType.tiltakType'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.bruksenhetTiltakType',
            },
          }}
          title='Type regelm.tiltak'
          size={5}
          operator='OR'
          id='btiltakstype'
        />

        <RefinementListFilter
          field='skybrannData.bruksenhetTiltakType.hyppighet'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.bruksenhetTiltakType',
            },
          }}
          title='Hyppighet'
          size={5}
          operator='OR'
          id='hyppighettiltakstype'
        />

        <DateRangeFilter
          id='nestedato'
          title='Neste besøk'
          calendarComponent={DateRangeCalendar}
          field='skybrannData.bruksenhetTiltakType.nesteDato'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.bruksenhetTiltakType',
            },
          }}
        />

        <DateRangeFilter
          id='sistedato'
          title='Sist besøkt'
          calendarComponent={DateRangeCalendar}
          field='skybrannData.bruksenhetTiltakType.sisteDato'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.bruksenhetTiltakType',
            },
          }}
        />

        <MenuFilter
          id='bSistestatus'
          field='skybrannData.bruksenhetTiltakType.sisteStatus'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.bruksenhetTiltakType',
            },
          }}
          title='Siste status'
          size={2}
          translations={{ All: 'Alle' }}
        />

        <CheckboxFilter
          id='bIkkeBesokt'
          title='Besøk'
          label='Ikke besøkt'
          filter={BoolMustNot({
            nested: {
              path: 'skybrannData.bruksenhetTiltakType',
              query: {
                terms: {
                  'skybrannData.bruksenhetTiltakType.sisteStatus': [
                    'Utført',
                    'Ikke utført',
                  ],
                },
              },
            },
          })}
        />
      </Panel>

      <Panel
        title='Regelm. tiltak røykløp'
        collapsable={true}
        defaultCollapsed={true}>
        <CustomRefinementFilter
          field='skybrannData.royklopTiltakType.tiltakType'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklopTiltakType',
            },
          }}
          title='Type regelm.tiltak'
          size={5}
          operator='OR'
          id='rtiltakstype'
        />

        <RefinementListFilter
          field='skybrannData.royklopTiltakType.hyppighet'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklopTiltakType',
            },
          }}
          title='Hyppighet'
          size={5}
          operator='OR'
          id='rHyppighettiltakstype'
        />

        <DateRangeFilter
          id='rNesteantattdato'
          title='Neste besøk'
          calendarComponent={DateRangeCalendar}
          field='skybrannData.royklopTiltakType.nesteDato'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklopTiltakType',
            },
          }}
        />

        <DateRangeFilter
          id='rSistedato'
          title='Sist besøkt'
          calendarComponent={DateRangeCalendar}
          field='skybrannData.royklopTiltakType.sisteDato'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklopTiltakType',
            },
          }}
        />

        <MenuFilter
          field='skybrannData.royklopTiltakType.sisteStatus'
          fieldOptions={{
            type: 'nested',
            options: {
              path: 'skybrannData.royklopTiltakType',
            },
          }}
          title='Siste status'
          size={2}
          id='rSistestatus'
          translations={{ All: 'Alle' }}
        />

        <CheckboxFilter
          id='rIkkeBesokt'
          title='Besøk'
          label='Ikke besøkt'
          filter={BoolMustNot({
            nested: {
              path: 'skybrannData.royklopTiltakType',
              query: {
                terms: {
                  'skybrannData.royklopTiltakType.sisteStatus': [
                    'Utført',
                    'Ikke utført',
                  ],
                },
              },
            },
          })}
        />
      </Panel>
      <Panel title='Arbeidsliste' collapsable={true} defaultCollapsed={true}>
        <CheckboxFilter
          id='ikkeTilknyttet'
          title=''
          label='Ikke tilknyttet arbeidsliste'
          filter={BoolMustNot(
            NestedQuery(
              'skybrannData.bruksenhet',
              BoolShould([
                TermQuery(
                  'skybrannData.bruksenhet.erTilknyttetArbeidsliste',
                  'true'
                ),
                BoolMustNot({
                  exists: {
                    field: 'skybrannData.bruksenhet.erTilknyttetArbeidsliste',
                  },
                }),
              ])
            )
          )}
        />
      </Panel>
    </div>
  );
};

export default FiltersMobile;
