import React, { Component } from 'react';
import L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';
import OrgDetaljer from './OrgDetaljer';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import * as getIcon from 'components/Kart/geticon.js';
import { hentLogo } from 'api/storageblobApi';
// Utils
import { debugFactory } from 'utils';
import {
  getContextInnstillingerKartNew,
  contextInnstillingerKart,
  getOrganisasjonData,
  contextInnstillingerPerson,
  contextInnstillingerOrganisasjon,
} from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:innstillinger:endreorganisasjon');
const markers = L.layerGroup();



class EndreOrganisasjon extends Component {
  state = { logoUrl: '', organisasjon: null, kart: null };

  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  /*   static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedChanges(
      {
        //'store.userPermissions': 'permissions',
       // 'store.userPersonalia': 'personalia',
        'store.organisasjon': 'organisasjon',
        'store.orgKartsenterLokasjon.latitude': 'latitude',
        'store.orgKartsenterLokasjon.longitude': 'longitude',
        'store.orgKartsenterLokasjon.zoomLevel': 'zoomLevel', 
      },
      nextProps,
      prevState,
      debug
    );
  } */

  componentDidMount() {
    //this.setState({ organisasjon: contextInnstillingerOrganisasjon._currentValue.data });
    debug('Hein', contextInnstillingerOrganisasjon._currentValue.data);
    hentLogo(this.gotLogo);
    this.hentData();
  }

  hentData= () => {
    if (
      contextInnstillingerPerson._currentValue.norkartAdmin ||
      contextInnstillingerPerson._currentValue.organisasjonAdmin
    ) {
      getOrganisasjonData(this.gotOrganisasjon);
    }
    getContextInnstillingerKartNew(this.gotKart);
  };

  gotLogo = (err, data) => {
    console.group('LOGOX');
    console.log( 'data:', data );
    let currentTimeInMilliseconds = Date.now();
    let logo = '';
    if (data.tekst){
      logo = data.tekst + '?' + currentTimeInMilliseconds;
    } else {
      logo = data+ '?' + currentTimeInMilliseconds;
    }
   
    console.log( logo );
    console.groupEnd();
    if (data) {
      this.setState({ logoUrl: logo });
    }
  };

  gotKart = (kart) => {
    this.setState({ kart });
    setTimeout(() => {
      this.setState({ kart });
    }, 200);
  };

  gotOrganisasjon = (organisasjon) => {
    if (organisasjon) {
      if (organisasjon.data) {
        this.setState({ organisasjon: organisasjon.data });
      }
    }
  };

  componentWillUnmount = () => {
    kart = null;
  };


  onEndreOrganisasjon = () => {
    if (
      contextInnstillingerPerson._currentValue.organisasjonAdmin
      // this.state.permissions.organisasjonAdmin === true //||
      // this.state.permissions.norkartBruker === true
    ) {
      this.dialogs.setOpenDialog('VisEndreOrganisasjon', {
        organisasjon: this.state.organisasjon,
        isEnabled: this.state.buttonEnabled,
      });
    } else {
      this.dialogs.setOpenDialog('IngenTilgang');
    }
  };


  oppdaterLogo = (logo) => {
    this.setState({ logoUrl: logo });
  };

  goOnOk = () => {
    this.dialogs.closeDialog(); // Explicit close neede before opening a new dialog right away
    this.dialogs.setOpenDialog('LoggInnIgjen');
    this.hentData();
  };

  render() {
    if (!this.state.organisasjon) {
      return <div>Laster organisasjon...</div>;
    }
    if (!this.state.kart) {
      return <div>Laster kart...</div>;
    }

    if (this.refs.kart && this.state.kart) {
      const latLng = [this.state.kart.latitude, this.state.kart.longitude];
      kart = this.refs.kart.leafletElement;
      kart.setView(latLng, 14);
      markers.clearLayers();
      var marker = L.marker(latLng, { icon: getIcon.redIcon });
      marker.addTo(markers);
      markers.addTo(kart);
    }

    let lisens = 'Ikke lisens.';
    if (this.state.organisasjon) {
      if (this.state.organisasjon.risikovurdering === true) {
        lisens = 'Har lisens';
      }
    }

    let flere = 'Ja';
    let toveis = 'Nei';
    let purringvarsel = 'Nei';
    let purringdager = '';
    let purringganger = '';
    let paaminnelse = 'Nei';
    if (this.state.organisasjon) {
      if (this.state.organisasjon.smsDialogEnEier === true) {
        flere = 'Nei';
      }
      if (this.state.organisasjon.smsTilbakemeldingInnbygger === true) {
        toveis = 'Ja';
      }
      if (this.state.organisasjon.smsPurringVarselInnbygger === true) {
        purringvarsel = 'Ja,';
        purringdager =
          'etter ' + this.state.organisasjon.smsPurringVarselForsteDag + 'd., ';
        purringganger =
          this.state.organisasjon.smsPurringVarselAntallganger + ' x';
      }
      if (this.state.organisasjon.smsPaminnelseInnbygger === true) {
        paaminnelse = 'Ja, dagen før besøket,';
      }
    }

    return (
      <div style={{ marginLeft: '20px' }}>
        <h2 style={{ marginLeft: '10px' }}>Organisasjon</h2>
        <h3 style={{ marginLeft: '10px' }}>{this.state.organisasjon.navn}</h3>
        <div className='row'>
          <div className='col-sm-6 col-md-6'>
            <table>
              <tbody>
                <tr>
                  <td>Adresse</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{this.state.organisasjon.adresse}</td>
                </tr>
                <tr>
                  <td>Poststed</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    {this.state.organisasjon.postnr}{' '}
                    {this.state.organisasjon.poststed}
                  </td>
                </tr>
                <tr>
                  <td>Telefon</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{this.state.organisasjon.telefon}</td>
                </tr>
                <tr>
                  <td>Epost</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{this.state.organisasjon.epost}</td>
                </tr>
                <tr>
                  <td>Webside</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{this.state.organisasjon.webside}</td>
                </tr>
                <tr>
                  <td>Risikovurdering</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{lisens}</td>
                </tr>
                <tr>
                  <td>Varsle flere eiere</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{flere}</td>
                </tr>
                <tr>
                  <td style={{ width: '300px' }}>
                    Innb. svarer via SMS på varsel
                  </td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{toveis}</td>
                </tr>
                <tr>
                  <td style={{ width: '300px' }}>
                    Det sendes purring etter varsel
                  </td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    {purringvarsel} {purringdager} {purringganger}{' '}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '300px' }}>Det sendes en påminnelse</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>{paaminnelse} </td>
                </tr>
                <tr>
                  <td style={{ width: '300px' }}>Skrifttype og -størrelse</td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                  <td>
                    {this.state.organisasjon.fontType}{' '}
                    {this.state.organisasjon.fontSize}
                  </td>
                </tr>
                <tr style={{ height: '10px' }} />

                <React.Fragment>
                  <tr>
                    <td>Logo / Topptekst:</td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td />
                  </tr>
                  <tr style={{ height: '10px' }} />
                  <tr>
                    <td colspan='3'>
                      <div
                        style={{
                          height: '100px',
                          width: '600px',
                          background: 'white',
                          borderColor: 'darkGray',
                          borderStyle: 'solid',
                          borderWidth: '1px',
                        }}>
                        <img
                          style={{ maxHeight: '100%' }}
                          src={this.state.logoUrl}
                          className='cover-image'
                          alt='Ingen bilde valgt'
                        />
                      </div>
                    </td>
                  </tr>
                </React.Fragment>

                <tr style={{ height: '30px' }} />
                <tr>
                  <button
                    // disabled={!this.isLoaded()}
                    className='btn btn-primary'
                    onClick={this.onEndreOrganisasjon}>
                    Endre egenskaper
                  </button>
                </tr>
                <tr style={{ height: '20px' }} />
                <tr />
              </tbody>
            </table>
          </div>
          <div className='col-sm-6 col-md-6'>
            <Map
              className='map'
              id='kart'
              ref='kart'
              zoomControl={false}
              maxZoom={20}>
              <TileLayer
                url='//www.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png'
                maxZoom={20}
              />
            </Map>
          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
export default EndreOrganisasjon;

// Declarative stuff

var kart = null;

const modalDialogs = {
  VisEndreOrganisasjon: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <OrgDetaljer
        onClose={onClose}
        oppdaterLogo={owner.oppdaterLogo}
        onOk={() => {
          owner.goOnOk();
        }}
        organisasjon={dialogData.organisasjon}
        isNorkartBruker={contextInnstillingerPerson._currentValue.norkartBruker}
        isNorkartAdmin={contextInnstillingerPerson._currentValue.norkartAdmin}
        isPilot={contextInnstillingerPerson._currentValue.pilotBruker}
      />
    </NKModal>
  ),
  IngenTilgang: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel='Ingen tilgang'
      melding='For å endre egenskaper trenger man admin-rettigheter.'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  LoggInnIgjen: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel='Aktivere endringer'
      melding='Logg inn i KOMTEK Brannforebygging på nytt for å aktivere endringene du har gjort.'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};
