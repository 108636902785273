import ReactSelect from 'react-select';
import React from 'react';
import bil from 'images/Annet/avvik2.gif';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { getContextBrukere } from 'context/contextOppslag.js';
require('react-select/dist/react-select.css');
var ansvarlige;

const modalDialogs = {
  SlashDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Skråstrek"
      melding="Du kan ikke benytte skråstek i navnet. "
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  )
};

class nyArbeidsliste extends React.Component {
  state = {
    navn: null,
    selectAnsvarlig: '',
    personer: [],
    hideImage: 'hide',
    hideBody: '',
    tittel: 'Ny arbeidsliste'
  };

  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  componentDidMount() {
    getContextBrukere( brukere => {
      this.setState({ personer: brukere.brukere });
    });  
  }

  componentDidUpdate() {
  }

  submit = data => {
    if (!this.state.navn || this.state.selectAnsvarlig.length === 0) {
      return;
    }
    this.props.onSettInnNyArbeidsliste({
      navn: this.state.navn,
      aktiv: false,
      ansvarlige: ansvarlige
    });
  };

  handleNameChange = data => {
    if (data.target.value.includes('xxxxx')) {
      this.setState({ hideImage: '', hideBody: 'hide', tittel: 'Hjeeeeeelllp !!!'  });
      return;  
    }
    //skråstrek
    let s = data.target.value;
    if (s.includes('\\') || s.includes('/')) {
      // eslint-disable-next-line no-useless-escape    
      s = s.replace(/[\/\\]/g, '');
      this.dialogs.setOpenDialog('SlashDialog');
    }
    this.setState({
      navn: s
    });
  };

  handlerPersoner = options => {
    this.setState({
      selectAnsvarlig: options
    });
    ansvarlige = options;
  };

  render() {
    let classNameButton =
      this.state.navn && this.state.selectAnsvarlig.length > 0
        ? 'btn btn-primary pull-right'
        : 'btn btn-primary pull-right disabled';

    return (
      <div className="modal-content"  style={{ width: '350px', maxWidth: '350px' }}>
        <div className="modal-header">
          <button className="close" onClick={this.props.onClose} type="button">
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>

          <h4 className="modal-title">{this.state.tittel}</h4>
        </div>
        <div className={this.state.hideImage}>
           <img src={bil} alt="" style={{ width: '100%' }}></img>
        </div>
        <div className={'modal-body ' + this.state.hideBody}>
          <div className="form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Navn</label>     
                  <input
                   autoFocus
                    type="text"
                    className="form-control"
                    placeholder="Navn på arbeidsliste"
                    onChange={this.handleNameChange}
                    ref="nameInput"
                  />
                </div>
                {/*<div className="form-group">
                                        <label>Antatt dato</label>
                                        <br />
                                        <DatePicker className="width120Heigth32"                                            
                                            placeholderText="Fra"
                                            selected={this.state.startDate}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleDatePickerChange}
                                            todayButton={'I dag'}
                                            filterDate={this.isWeekday}
                                            />
                                    </div>*/}
                <div className="form-group">
                  <label>Velg ansvarlige</label>
                  <ReactSelect
                    multi
                    id="ansvarlig"
                    valueKey="navn"
                    labelKey="navn"
                    name="selectAnsvarlige"
                    value={this.state.selectAnsvarlig}
                    onChange={this.handlerPersoner}
                    options={this.state.personer}
                    searchable={true}
                    placeholder="Velg ansvarlige"
                    noResultsText="Ingen resultat"
                    searchPromptText="Søk.."
                    openOnFocus={true}
                    isOpen={true}
                    autosize={false}
                    matchPos="start"
                    autoBlur={true}
                    inputProps={{ autoComplete: 'random-string', autofill: 'off' }}
                  />
                </div>
                {/*<div className="form-group">
                                        <label>Tiltak</label>
                                        <Select name='tiltak' value={this.state.selectTiltak} options={enhetTiltak} onChange={this.handleTiltak} />
                                    </div>*/}
                <div>
                  <input
                    type="button"
                    className={classNameButton}
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default nyArbeidsliste;
