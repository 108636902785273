import React, { Component } from 'react';
import omradeSoneApi from 'api/omradeSonerApi';
import _ from 'underscore';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Kan ikke lagre"
      melding="Sone kan ikke lagres uten navn."
      isOpen={isOpen} 
      onClose={onClose}
    />
  )
};
class LagreEndretRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      omrader: [],
      soner: [],
      originalNavn: '',
      varsel: '',
      omradeId: '',
      omradeNavn: '',
      id: '0',
      navn: '',
      orgnavn: '',
      orgomradeNavn: '',
      color: 'aqua'
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.lagre = this.lagre.bind(this);
    this.lagreKopi = this.lagreKopi.bind(this);
    this.omradeChange = this.omradeChange.bind(this);
    this.navnChange = this.navnChange.bind(this);
    this.colorChange = this.colorChange.bind(this);
  }

  componentDidMount() {
    let sone = this.props.currentSone;
    this.setState({ originalNavn: sone.navn });
    this.setState({ id: sone.id });
    this.setState({ navn: sone.navn });
    this.setState({ orgnavn: sone.navn });
    this.setState({ omradeId: sone.omradeId });
    this.setState({ omradeNavn: sone.omradeNavn });
    this.setState({ orgomradeNavn: sone.omradeNavn });
    this.setState({ color: sone.color });
    omradeSoneApi.getAlleOmrader(this.onGotAlleOmrader.bind(this));
    omradeSoneApi.getAlleSoner(this.onGotAlleSoner.bind(this));
  }

  onGotAlleOmrader(err, data) {
    let omrader = data;
    if (omrader !== undefined && omrader.length > 0) {
      omrader = _.sortBy(omrader, 'navn');
    }
    this.setState({ omrader: omrader });
  }

  onGotAlleSoner(err, data) {
    let soner = data;
    console.log(data);
    if (soner !== undefined && soner.length > 0) {
      soner = _.sortBy(data, 'navn');
      this.setState({ soner: soner });
    }
  }

  finnesSoneFraFor(navn, omradeNavn) {
    if (
      this.state.orgnavn === navn &&
      this.state.orgomradeNavn === omradeNavn
    ) {
      return false;
    }

    let finnes = false;
    let collection = this.state.soner;

    for (let i = 0; i < collection.length; i++) {
      let sone = collection[i];
      if (sone.navn === navn && sone.omradeNavn === omradeNavn) {
        finnes = true;
      }
    }
    if (finnes === true) {
      this.setState({ varsel: 'Sone finnes fra før. Velg et annet navn.' });
    }
    return finnes;
  }

  lagre() {
    if (this.state.navn === null || this.state.navn.trim() === '') {
      this.dialogs.setOpenDialog('OkDialog');
      return;
    }

    let finnes = this.finnesSoneFraFor(this.state.navn, this.state.omradeNavn);
    if (!finnes) {
      let sone = {
        id: this.state.id,
        navn: this.state.navn,
        omradeId: this.state.omradeId,
        omradeNavn: this.state.omradeNavn,
        color: this.state.color,
        originalNavn: this.state.originalNavn
      };
      this.props.onLagreEndretSoneOk(sone);
    }
  }

  lagreKopi() {
    if (this.state.navn === null || this.state.navn.trim() === '') {
      this.dialogs.setOpenDialog('OkDialog');
      return;
    }

    let finnes = this.finnesSoneFraFor(this.state.navn, this.state.omradeNavn);
    if (!finnes) {
      let sone = {
        navn: this.state.navn,
        omradeId: this.state.omradeId,
        omradeNavn: this.state.omradeNavn,
        color: this.state.color,
        originalNavn: this.state.originalNavn
      };
      this.props.onLagreKopi(sone);
    }
  }

  omradeChange(e) {
    let id = e.target.value;
    let navn = e.target.options[e.target.selectedIndex].text;
    this.setState({ omradeId: id });
    this.setState({ omradeNavn: navn });
  }

  navnChange(event) {
    this.setState({ navn: event.target.value });
  }

  colorChange(event) {
    this.setState({ color: event.target.value });
  }

  keyDown = (event) => {
    const pressedChar = event.key;
     // Allow only numbers and letters (alphabets)
     if (!/[A-Za-z0-9\sÅÆØåæø]/.test(pressedChar)) {
     event.preventDefault();
   }
 }

  render() {
    if (!this.state.omrader) {
      return <div>Laster...</div>;
    }
    return (
      <div className="modal-content height440">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">Endre sone</h4>
        </div>
        <div className="modal-body">
          <label>Endre '{this.state.originalNavn}' </label>
          <br />
          <div className="form-group ">
            <label>Område</label>

            <select
              className="form-control"
              onChange={this.omradeChange}
              value={this.state.omradeId}>
              {_.map(this.state.omrader, function(o, i) {
                return (
                  <option key={i} value={o.id}>
                    {o.navn}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label>Sone</label>
            <br />
            <input
              type="text"
              value={this.state.navn}
              className="form-control"
              id="tittel"
              onChange={this.navnChange}
              onKeyDown={(evt) => {this.keyDown(evt);}}
            />
           
            <span style={{ color: 'blue', marginLeft: '8px',  marginTop: '4px', fontSize: '12px' }} className='pull-left'>Bruk kun bokstaver og nummer.</span>
            <br/>
          </div>

          <div className="form-group">
            <div className="redText">{this.state.varsel}</div>
          </div>

          <div className="form-group">
            <label>Farge</label>
            <br />
            <select
              value={this.state.color}
             /*  defaultValue={this.state.color} */
              onChange={this.colorChange}
              className="farger">
              <option value="cyan">Aqua</option>
              <option value="brown">Brun</option>
              <option value="transparent">Gjennomsiktig</option>
              <option value="grey">Grå</option>
              <option value="lime">Grønn</option>
              <option value="yellow">Gul</option>
              <option value="lawngreen">Lime</option>
              <option value="coral">Oransje</option>
              <option value="violet">Rosa</option>
              <option value="red">Rødt</option>
              <option value="blue">Violet</option>
            </select>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary height40" onClick={this.lagre}>
            OK Lagre
          </button>
          <button className="btn btn-primary height40" onClick={this.lagreKopi}>
            Lagre som kopi
          </button>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default LagreEndretRegion;
