import moment from 'moment';
import { PropTypes } from 'prop-types';
//import 'moment/locale/nb';
import DatePicker from 'react-datepicker';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
var React = require('react');
var enhetApi = require('api/enhetApi');
require('react-datepicker/dist/react-datepicker.css');

var nesteDato;
var adresse = null;
class NyOppdaterTiltaktype extends React.Component {
  static propTypes = {
    statusTiltaktype: PropTypes.string,
    onClose: PropTypes.func,
    onNyEndreTiltaktypeOK: PropTypes.func,
    lagretTiltak: PropTypes.object,
    tiltakType: PropTypes.object,
    type: PropTypes.string,
    tiltakNavn: PropTypes.string,
    hyppighetAntalldager: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      typeData: null,
      tiltakTypeId: null,
      dato: null,
      selectedRadio: 'valg1',
      hyppighet: '',
      utfortav: '',
      merknad: '',
      startDate: moment(),
      endDate: moment().add(2, 'month'),
      nesteDato: null,
      text1: null,
      text2: null
    };
    this.dialogs = new DialogStore({
      owner: this,
      //dialogs: modalDialogs
    });
  }

  componentDidMount() {
    console.log(this.props.statusTiltaktype);
    console.log(this.props.lagretTiltak);
    console.log(this.props.tiltakType);
    console.log(this.props.hyppighetAntalldager);
    
    nesteDato = moment().add(this.props.hyppighetAntalldager, 'days').format('YYYY-MM-DDTHH:00:00');
    console.log(nesteDato);
    this.setState({ nesteDato: nesteDato }, this.submit());
    if (this.props.adresse != null && this.props.adresse != undefined && this.props.adresse != "") {
      adresse = 'i ' + this.props.adresse;
    }
  }

  submit = data => {
    console.log(nesteDato);
    if (this.props.statusTiltaktype === 'ny') {
      // Opprett bare hvis hyppighet er satt
      if (this.props.lagretTiltak.hyppighetId != null) {
        if (this.props.type === 'bruksenhet') {
          var nyTiltaktype = {
            bruksenhetid: this.props.lagretTiltak.bruksenhetId,
            tiltaktypeid: this.props.lagretTiltak.tiltakTypeId,
            hyppighetid: this.props.lagretTiltak.hyppighetId,
            nestedato: nesteDato,
            sistedato: null,
            sisteStatus: false
          };
          enhetApi.saveBruksenhetTiltakType(
            nyTiltaktype,
            this.onTiltaktypeLagret
          );
        } else {
          nyTiltaktype = {
            royklopid: this.props.lagretTiltak.royklopId,
            bruksenhetid: this.props.lagretTiltak.bruksenhetId,
            tiltaktypeid: this.props.lagretTiltak.tiltakTypeId,
            hyppighetid: this.props.lagretTiltak.hyppighetId,
            nestedato: nesteDato,
            sistedato: null,
            sisteStatus: false
          };
          enhetApi.saveRoyklopTiltakType(nyTiltaktype, this.onTiltaktypeLagret);
        }
      }
      
    } else if (this.props.statusTiltaktype === 'endre') {
      // Endre bare hvis hyppighet er satt, ellers slett
      if (this.props.lagretTiltak.hyppighetId != null) {
        if (this.props.type === 'bruksenhet') {
          var tiltaktype = this.props.tiltakType;
          tiltaktype.nesteDato = nesteDato;
          
          tiltaktype.hyppighetid = this.props.lagretTiltak.hyppighetId;
          enhetApi.oppdaterBruksenhetTiltakType(
            tiltaktype.id,
            tiltaktype,
            this.onTiltaktypeLagret
          );
        } else {
          tiltaktype = this.props.tiltakType;
          tiltaktype.nesteDato = nesteDato;
          
          tiltaktype.hyppighetid = this.props.lagretTiltak.hyppighetId;
          console.log(tiltaktype);
          enhetApi.oppdaterRoyklopTiltakType(
            tiltaktype.id,
            tiltaktype,
            this.onTiltaktypeLagret
          );
        }
      } else {
        //Fjern tiltak
        if (this.props.type === 'bruksenhet') {
          enhetApi.slettBruksenhetTiltakType(
            this.props.tiltakType.id,
            this.onTiltaktypeSlettet
          );
        } else {
          enhetApi.slettRoyklopTiltakType(
            this.props.tiltakType.id,
            this.onTiltaktypeSlettet
          );
        }
      }
      
    } else {
      //Endre tiltak
      console.log(tiltaktype);
      if (this.props.type === 'bruksenhet') {
        tiltaktype = this.props.tiltakType;
        tiltaktype.nesteDato = nesteDato;
        
        enhetApi.oppdaterBruksenhetTiltakType(
          tiltaktype.id,
          tiltaktype,
          this.onTiltaktypeLagret
        );
      } else {
        tiltaktype = this.props.tiltakType;
        tiltaktype.nesteDato = nesteDato;
        
        enhetApi.oppdaterRoyklopTiltakType(
          tiltaktype.id,
          tiltaktype,
          this.onTiltaktypeLagret
        );
      }
    }
  };

  onTiltaktypeLagret = (err, tiltak) => {
    //this.props.onNyEndreTiltaktypeOK();
  };

  onTiltaktypeSlettet = () => {
    
  };

  ok = () => {
    this.props.onNyEndreTiltaktypeOK();
  }

  render() {
    if (
      this.state.nesteDato == null
    ) {
      return <div>Laster...</div>;
    }
    return (
      <div className="modal-content">
        <div className="modal-header">

          <h4 className="modal-title">Regelmessige tiltak</h4>
        </div>
        <div className="modal-body">
          <br />
          <div className="form">
            <div className="row">
              <div className="col-sm-12">
              {this.props.lagretTiltak.hyppighetId == null ? 
              <div>
              Det beregnes ingen ny dato for {this.props.tiltakNavn} {adresse}.
              <hr/>
            </div>
              :
              <div
                style={{ padding: '0 15px'}}>
              Antatt dato for neste {this.props.tiltakNavn} {adresse} er beregnet til {this.state.nesteDato.toString().substring(8, 10)}.{this.state.nesteDato.toString().substring(5, 7)}.{this.state.nesteDato.toString().substring(0, 4)}.
              <hr/>
            </div>
              }
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary ok-knapp"
                    value="OK"
                    onClick={this.ok}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NyOppdaterTiltaktype;
