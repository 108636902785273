import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import { AccessDenied } from 'components/exceptions/ErrorPages';
import withAccessLevel from 'modules/felles/withAccessLevel';
import Index from './index';
import Oppslagsdata from './Oppslagsdata';
import SakArkiv from './SakArkiv';
import Risikovurdering from './Risikovurdering';
import RisikoTiltakstyper from './Risikovurdering/Tiltakstyper';
import Innstillinger from './Risikovurdering/Innstillinger';
import Matrise from './Risikovurdering/Matrise';
import Sakstyper from './SakArkiv/Sakstyper';
import Arkiv from './SakArkiv/Arkiv';
import Bruker from './SakArkiv/Bruker';
import Test from './SakArkiv/Test';
import Titler from './SakArkiv/Titler';
import Tiltakstyper from './SakArkiv/Tiltakstyper';
import Oppsett from './SakArkiv/Oppsett';
import NySakstype from './SakArkiv/Sakstyper/NySakstype.jsx';
import EndreSakstype from './SakArkiv/Sakstyper/EndreSakstype.jsx';
import Maloppsett from './Maloppsett';
import Sms from './Maloppsett/Sms.jsx';
import Dokument from './Maloppsett/Dokument.jsx';
import Kart from './Kart';
import RedigerSoner from './Kart/SonerOmraader.jsx';
import AdministrerWms from './Kart/AdministrerWms.jsx';
import EndreBrukere from './brukere/EndreBrukere.jsx';
import EndreOrganisasjon from './organisasjoner/EndreOrganisasjon.jsx';
import { enhanceComponents } from 'utils';
import SvarUtLogg from './SvarUtLogg';


const enhanced = enhanceComponents(
  {
    Index,
    Oppslagsdata,
    SakArkiv,
    Sakstyper,
    NySakstype,
    EndreSakstype,
    Arkiv,
    Risikovurdering,
    RisikoTiltakstyper,
    Innstillinger,
    Matrise,
    Bruker,
    Test,
    Titler,
    Tiltakstyper,
    Oppsett,
    Maloppsett,
    Sms,
    Dokument,
    Kart,
    RedigerSoner,
    AdministrerWms,
    SvarUtLogg,
    EndreBrukere: withAccessLevel.admin(EndreBrukere, AccessDenied),
    EndreOrganisasjon: withAccessLevel.admin(EndreOrganisasjon, AccessDenied),
  },
  [asRouteComponent]
);

export default (
  <Route
    path='innstillinger'
    component={enhanced.Index}
    name='Innstillinger'
    icon='innstillinger'
    inMenu={true}
    login={true}
    roles={[]}>
    <Route
      path='Oppslagsdata'
      component={enhanced.Oppslagsdata}
      name='Oppslagsdata'
    />
    <Route path='SakArkiv' component={enhanced.SakArkiv} name='SakArkiv'>
      <Route path='Sakstyper' component={enhanced.Sakstyper} name='Sakstyper'>
        <Route
          path='Ny'
          component={enhanced.NySakstype}
          name='Ny sakskategori'
        />
        <Route
          path='Endre'
          component={enhanced.EndreSakstype}
          name='Endre sakstype'
        />
      </Route>
      <Route
        path='Arkiv'
        component={enhanced.Arkiv}
        name='Arkivinnstillinger'
      />
      <Route
        path='Bruker'
        component={enhanced.Bruker}
        name='Brukerinnstillinger'
      />
      <Route path='Titler' component={enhanced.Titler} name='Titler' />
      <Route path='Tiltakstyper' component={enhanced.Tiltakstyper} name='Tiltakstyper' />
      <Route path='Oppsett' component={enhanced.Oppsett} name='Oppsett' />
      <Route path='Test' component={enhanced.Test} name='Test' />
    </Route>

    <Route path='Risikovurdering' component={enhanced.Risikovurdering} name='Risikovurdering'>
       <Route path='Innstillinger' component={enhanced.Innstillinger} name='Innstillinger' />
       <Route path='Matrise' component={enhanced.Matrise} name='Matrise' />
       <Route path='Tiltakstyper' component={enhanced.RisikoTiltakstyper} name='Tiltakstyper' />
    </Route>

    <Route path='Maloppsett' component={enhanced.Maloppsett} name='Maloppsett'>
      <Route path='Sms' component={enhanced.Sms} name='SMS-maler' />
      <Route
        path='Dokument'
        component={enhanced.Dokument}
        name='Dokumentmaler'
      />
    </Route>
    <Route path='Kart' component={enhanced.Kart} name='Kart'>
      <Route
        path='RedigerSoner'
        component={enhanced.RedigerSoner}
        name='Rediger områder og soner'
      />
      <Route
        path='AdministrerWms'
        component={enhanced.AdministrerWms}
        name='Oversikt Wms'
      />
    </Route>
    <Route path='Brukere' component={enhanced.EndreBrukere} name='Brukere' />
    <Route
      path='Organisasjon'
      component={enhanced.EndreOrganisasjon}
      name='Organisasjon'
    />
    <Route
      path='SvarUtLogg'
      component={enhanced.SvarUtLogg}
      name='SvarUtLogg'
    />
  </Route>
);
