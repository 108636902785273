import L from 'leaflet';
import shadow from 'images/marker-shadow.png';
import blue from 'images/blue.png';
import red from 'images/red.png';
import green from 'images/green.png';
import violet from 'images/violet.png';
import grey from 'images/grey.png';
import forbud from 'images/forbud.png';
import orange from 'images/orange.png';
import sprett from 'images/sprett.png';
import risikoRed from 'images/risiko/markerred.png';
import risikoYellow from 'images/risiko/markeryellow.png';
import risikoBlue from 'images/risiko/markerblue.png';
import risikoGreen from 'images/risiko/markergreen.png';
import risikoRedOverstyrt from 'images/risiko/markerredOverstyrt.png';
import risikoYellowOverstyrt from 'images/risiko/markeryellowOverstyrt.png';
import risikoBlueOverstyrt from 'images/risiko/markerblueOverstyrt.png';
import risikoGreenOverstyrt from 'images/risiko/markergreenOverstyrt.png';
import risikoRedOverstyrtPlus from 'images/risiko/markerredOverstyrtPluss.png';
import risikoYellowOverstyrtPlus from 'images/risiko/markeryellowOverstyrtPluss.png';
import risikoBlueOverstyrtPlus from 'images/risiko/markerblueOverstyrtPluss.png';
import risikoGreenOverstyrtPlus from 'images/risiko/markergreenOverstyrtPluss.png';
import risikoRedPluss from 'images/risiko/markerredpluss.png';
import risikoYellowPluss from 'images/risiko/markeryellowpluss.png';
import risikoBluePluss from 'images/risiko/markerbluepluss.png';
import risikoGreenPluss from 'images/risiko/markergreenpluss.png';

export const sprettIcon = new L.icon({  
  iconUrl: sprett,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const greenIcon = new L.icon({
  iconUrl: green,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const greyIcon = new L.Icon({
  iconUrl: grey,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const forbudIcon = new L.Icon({
  iconUrl: forbud,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const redIcon = new L.Icon({
  iconUrl: red,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const blueIcon = new L.Icon({
  iconUrl: blue,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
export const orangeIcon = new L.Icon({
  iconUrl: orange,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
export const violetIcon = new L.Icon({
  iconUrl: violet,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoRedIcon = new L.Icon({
  iconUrl: risikoRed,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoRedOverstyrtIcon = new L.Icon({
  iconUrl: risikoRedOverstyrt,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoRedPlussIcon = new L.Icon({
  iconUrl: risikoRedPluss,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoRedOverstyrtPlussIcon = new L.Icon({
  iconUrl: risikoRedOverstyrtPlus,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoYellowIcon = new L.Icon({
  iconUrl: risikoYellow,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoYellowOverstyrtIcon = new L.Icon({  
  iconUrl: risikoYellowOverstyrt,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoYellowPlussIcon = new L.Icon({
  iconUrl: risikoYellowPluss,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoYellowOverstyrtPlussIcon = new L.Icon({  
  iconUrl: risikoYellowOverstyrtPlus,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoGreenIcon = new L.Icon({
  iconUrl: risikoGreen,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoGreenOverstyrtIcon = new L.Icon({
  iconUrl: risikoGreenOverstyrt,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoGreenOverstyrtPlussIcon = new L.Icon({
  iconUrl: risikoGreenOverstyrtPlus,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoGreenPlussIcon = new L.Icon({
  iconUrl: risikoGreenPluss,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoBlueIcon = new L.Icon({
  iconUrl: risikoBlue,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoBlueOverstyrtIcon = new L.Icon({
  iconUrl: risikoBlueOverstyrt,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoBlueOverstyrtPlussIcon = new L.Icon({
  iconUrl: risikoBlueOverstyrtPlus,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const risikoBluePlussIcon = new L.Icon({
  iconUrl: risikoBluePluss,
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]  
});

export const valgtIcon = new L.Icon({
  iconUrl:
    'https://upload.wikimedia.org/wikipedia/commons/4/47/Orange_circle_50%25.svg',
  iconSize: [50, 50]
});
