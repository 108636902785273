import React from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import { getMaler } from 'api/malerApi';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import './maleditor.css';

const debug = debugFactory('nk:DokEditor');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Mal til annen modul'
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class MalerMalsoek extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maler: [],
      malerFiltert: [],
      funksjoner: [],
      funksjon: 'Alle',
      modulvalgt: 'O',
      visFunksjon: false,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    let modul = this.props.modul;
    debug('MMALL x', this.props);
    this.setState({ modulvalgt: this.props.modul });
    getMaler(modul, this.gotMaler);
  };

  gotMaler = (err, data) => {
    data = this.stripListe(data);
    this.setState({ maler: data });
    this.setState({ malerFiltert: data });
    if (this.props.visFunksjon) {
      this.getFunksjoner(data);
    }
  };

  getFunksjoner = (data) => {
    var distinct = ['Alle'];
    for (var i = 0; i < data.length; i++) {
      if (!distinct.includes(data[i].funksjon)) {
        distinct.push(data[i].funksjon);
      }
    }
    this.setState({ funksjoner: distinct });
  };

  stripListe = (liste) => {
    for (let i = 0; i < liste.length; i++) {
      if (liste[0].tekst) {
        let tekst = liste[i].tekst;
        if (tekst !== '') {
          liste[i].striptekst = this.stripTekst(tekst);
        }
      }
    }
    return liste;
  };

  stripTekst = (tekst) => {
    tekst = this.goReplace(tekst, '<h2>', '');
    tekst = this.goReplace(tekst, '</h2>', '');
    tekst = this.goReplace(tekst, '<ul>', '');
    tekst = this.goReplace(tekst, '</ul>', '');
    tekst = this.goReplace(tekst, '<strong>', '');
    tekst = this.goReplace(tekst, '</strong>', '');
    tekst = this.goReplace(tekst, '<li>', ' -');
    tekst = this.goReplace(tekst, '</li>', '');
    tekst = this.goReplace(tekst, '<p>', '');
    tekst = this.goReplace(tekst, '</p>', '\n');
    tekst = this.goReplace(tekst, '<br>', '\n');
    tekst = this.goReplace(tekst, '\n\n', '\n');
    tekst = this.goReplace(tekst, '&nbsp;', ' ');
    tekst = tekst.trim();
    return tekst;
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replace(new RegExp(find, 'g'), repl);
    return str;
  };

  brukTekst = (txt) => {
    this.props.onInsertMal(txt);
  };

  funksjonChange = (e) => {
    let filtert = this.state.maler;
    let funksjon = e.target.value;
    if (funksjon === 'Alle') {
      this.setState({ malerFiltert: filtert });
    } else {
      this.setState({ funksjon: funksjon });
      filtert = _.filter(filtert, { funksjon: funksjon });
      this.setState({ malerFiltert: filtert });
    }
  };

  modulChange = (e) => {
    let org = this.state.modulvalgt;
    let mm = 'Planlegging';
    switch (org) {
      case 'P':
        mm = 'Planlegging';
        break;
      case 'G':
        mm = 'Gjennomføring';
        break;
      case 'O':
        mm = 'Oppfølging';
        break;
      default:
    }
    
    let modul = e.target.value;
    this.setState({ modulvalgt: modul });
    getMaler(modul, this.gotMaler);
    if (modul === this.props.modul) {
      return;
    }
    this.dialogs.setOpenDialog('OkDialog', {
      melding:
        'Obs! Maler fra en annen modul kan ha flettefelt som blir ignorert i ' +
        mm +
        '.',
    });
  };

  render() {
    let x = 0;
    return (
      <div style={{ height: '500px' }} className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Velg mal </h4>
        </div>
        <div className='modal-body'>
          <div className='form-group'>
            {this.props.visFunksjon && (
              <span>
                <span style={{ display: 'inline' }}>Funksjon</span>
                <select
                  className='form-control'
                  id='funksjonSelect'
                  style={{
                    marginLeft: '4px',
                    width: '80% !important',
                    maxWidth: '80%',
                    display: 'inline',
                  }}
                  value={this.state.funksjon}
                  onChange={this.funksjonChange}>
                  {_.map(this.state.funksjoner, function(o, i) {
                    return (
                      <option key={i} value={o}>
                        {o}
                      </option>
                    );
                  })}
                </select>
              </span>
            )}
            {this.props.visModulvalg && (
              <span>
                <span style={{ display: 'inline' }}>Modul</span>
                <select
                  className='form-control'
                  id='modulSelect'
                  style={{
                    marginLeft: '4px',
                    width: '80% !important',
                    maxWidth: '80%',
                    display: 'inline',
                  }}
                  value={this.state.modulvalgt}
                  onChange={this.modulChange}>
                  <option value='P'>Planlegging</option>
                  <option value='G'>Gjennomføring</option>
                  <option value='O'>Oppfølging</option>
                </select>
              </span>
            )}
          </div>

          <div
            className='list-group'
            style={{ maxHeight: '360px', overflow: 'auto' }}>
            {!this.state.malerFiltert
              ? ''
              : this.state.malerFiltert.map(function(mal) {
                  var tittel = mal.tittel;
                  var txt = mal.tekst;
                  x = x + 1;
                  if (txt) {
                    return (
                      <button
                        key={x}
                        type='button'
                        onClick={this.brukTekst.bind(this, txt)}
                        className='list-group-item list-group-item-action'>
                        {tittel}
                      </button>
                    );
                  } else { return null; }
                }, this)}
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default MalerMalsoek;
