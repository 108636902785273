
import callApi from './api';


var getEnheter = function getEnheter(callback) {
    callApi('GET', '/enhet/', null, callback);
};

var getEnheterByFilter = function getEnheterByFilter(filter, callback) {
    callApi('GET', '/bruksenhet/getbruksenheterbyfilter/' + filter, null, callback);
};

// var getEnheterById = function getEnheterById(arbeidslisteId, callback) {
//     callApi('GET', '/enhet/getEnhetListeById/' + arbeidslisteId, null, callback);
// };

var getEnheterMedVarslingByEnhetIds = function getEnheterMedVarslingByEnhetIds(enhetsIder, callback) {
    callApi('GET', '/enhet/getEnheterMedVarslingByEnhetIds/' + enhetsIder, enhetsIder, callback);
};

var getBruksenhetSituasjon = function(id, arbeidslisteId, callback) {
    if (arbeidslisteId){
        callApi('GET', '/Bruksenhet/situasjon/' + id +'/' + arbeidslisteId, null, callback);
    }
    else{
        callApi('GET', '/Bruksenhet/situasjon/' + id, null, callback);
    }
    
};

//TODO: fjernes
// var getArbeidslisteBrukenheter = function (arbeidslisteId, callback) {
//     callApi('GET', '/Arbeidslistebruksenhet/bruksenheter/' + arbeidslisteId, null, callback);
// };

var getArblisteBrukenheterGjennomforing = function(arbeidslisteId, callback) {
    callApi('GET', '/Arbeidslistebruksenhet/bruksenhetergjennomforing/' + arbeidslisteId, null, callback);
};

var getArblisteBrukenheterPlanlegging = function(arbeidslisteId, callback) {
    callApi('GET', '/Arbeidslistebruksenhet/bruksenheterplanlegging/' + arbeidslisteId, null, callback);
};

var getBruksenhet = function(id, callback) {
    callApi('GET', '/Bruksenhet/' + id, null, callback);
};

var getBruksenhetEiendom = function(id, callback) {
    callApi('GET', '/Bruksenhet/eiendom/' + id, null, callback);
};

var getByggLokasjon = function(id, callback) {
    callApi('GET', '/bruksenhet/bygglokasjon/' + id, null, callback);
};

var getByggTypeKode = function(id, callback) {
    callApi('GET', '/bruksenhet/byggtypekode/' + id, null, callback);
};

let createBruksenhet = function(bruksenhet, callback) {
    callApi('POST', '/Bruksenhet/', bruksenhet, callback);
};

let kobleTilMatrikkel = function(fagId, bruksenhetId, callback){
    callApi('POST', '/bruksenhet/kobletilmatrikkel/' + fagId + '/' + bruksenhetId, null, callback);
};

var updateBruksenhet = function(id, bruksenhet, callback) {
    callApi('PUT', '/Bruksenhet/' + id, bruksenhet, callback);
};

var deleteBruksenhet = function(id, callback) {
    callApi('DELETE', '/Bruksenhet/' + id, null, callback);
};

var risikooverstyrtBruksenhet = function(id, callback) {
    callApi('GET', '/Bruksenhet/risikooverstyr/' + id, null, callback);
};

var deleteBruksenhetFraListe = function(bruksEnhetId, arbeidslisteId, callback) {
    callApi('POST', '/arbeidslistebruksenhet/fjern/' + bruksEnhetId +'/' + arbeidslisteId, null, callback);
};
var getBruksenheterTilknyttetBygg = function(id, callback) {
    callApi('GET', '/bruksenhet/bygg/' + id, null, callback);
};

var getBruksenhetSlettetLogg = function(brid, callback) {
    callApi('GET', '/Bruksenhet/loggslettet/' + brid, null, callback);
};

// T I L T A K
var getBruksenhetTiltakHistorikk = function(id, callback) {
    callApi('GET', '/bruksenhettiltak/historikk/' + id, null, callback);
};

var getBruksenhetTiltakOppslagsdata = function(callback) {
    callApi('GET', '/bruksenhetoppslagdata/tiltak/', null, callback);
} ;
var getBruksenhetTiltakOppslagsdataForArbOgBr = function(arbeidslisteId, brId, callback) {
    callApi('GET', '/bruksenhetoppslagdata/tiltak/' + arbeidslisteId + '/' + brId, null, callback);
};

var saveTiltakBruksenhet = function(tiltak, callback) {
    callApi('POST', '/bruksenhettiltak/', tiltak, callback);
};

var getBruksenhetSituasjonTiltakById = function(brId, arbeidslisteId, callback) {
    callApi('GET', '/Bruksenhet/situasjontiltak/' + brId +'/' + arbeidslisteId, null, callback);
};

/* let flyttTiltakBruksenhet = function(data, callback) {    
    callApi('POST', '/ArbeidslisteBruksenhet/flytt/', data, callback);
}; */

let flyttTiltakBruksenhet2 = function(data, callback) {    
    callApi('POST', '/ArbeidslisteBruksenhet/flyttbruksenhet/', data, callback);
};

/* let fjernTiltakBruksenhet = function(data, callback) {    
    callApi('POST', '/ArbeidslisteBruksenhet/fjern/', data, callback);
}; */

let fjernTiltakBruksenhet2 = function(data, callback) {    
    callApi('POST', '/ArbeidslisteBruksenhet/fjernbruksenhet2/', data, callback);
};

var updateTiltakArbeidslisteBruksenhet = function(tiltakTypeBruksenhetListe, callback) {
    callApi('POST', '/arbeidslistebruksenhet/oppdatertiltak/', tiltakTypeBruksenhetListe, callback);
};

var validertiltakendret = function(bruksEnhetId, arbeidslisteId, callback) {
    callApi('GET', '/arbeidslistebruksenhet/validertiltakendret/' + bruksEnhetId +'/' + arbeidslisteId, null, callback);
};

var updateTiltakBruksenhet = function(id, tiltak, callback) {
    callApi('PUT', '/bruksenhettiltak/' + id, tiltak, callback);
};

var slettTiltakBruksenhet = function(id, callback) {
    callApi('DELETE', '/bruksenhettiltak/' + id, null, callback);
};

var getTiltakBruksenhet = function(id, callback) {
    callApi('GET', '/bruksenhettiltak/' + id, null, callback);
};
var getBruksenhetTiltakikkeutfort = function(bruksenhetId, arbeidslisteId, callback) {
    callApi('GET', '/arbeidslistebruksenhet/tiltakikkeutfort/' + bruksenhetId + '/' + arbeidslisteId, null, callback);
};

var GetBruksenhetRoyklopTiltakStatus = function(bruksenhetId, arbeidslisteId, callback) {
    callApi('GET', '/arbeidslistebruksenhet/bruksenhetRoyklopTiltakStatus/' + bruksenhetId + '/' + arbeidslisteId, null, callback);
};

var getTiltaksHistorikk = function(brid, callback) {
    callApi('POST', '/bruksenhettiltak/roykloptiltak', brid, callback);
};

// H I S T O R I K K
var getBruksenhetObjekthistorikk = function(bruksEnhetId, callback) {
    callApi('GET', '/objektendringer/bruksenhet/' + bruksEnhetId, null, callback);
};

// T I L T A K S T Y P E R  -  B R U K S E N H E T 
var getAlleTiltakstyper = function(callback) {
    callApi('GET', '/bruksenhetoppslagdata/tiltaktypealle', null, callback);
};

var getBruksenhetTiltakstyper = function(bruksenhetId, callback) {
    callApi('GET', '/BruksenhetTiltakType/' + bruksenhetId, null, callback);
};

var getBruksenhetTiltakTypeOppslagdata = function(callback) {
    callApi('GET', '/BruksenhetOppslagdata/tiltaktype/', null, callback);
};

var saveBruksenhetTiltakType = function(tiltaktype, callback) {
    callApi('POST', '/BruksenhetTiltakType/', tiltaktype, callback);
};

var slettBruksenhetTiltakType = function(id, callback) {
    callApi('DELETE', '/BruksenhetTiltakType/'+ id, null, callback);
};

var oppdaterBruksenhetTiltakType = function(id, tiltaktype, callback) {
    callApi('PUT', '/BruksenhetTiltakType/' + id, tiltaktype, callback);
};
var getTiltakTypeForBruksenhet = function(tiltaktypeId, bruksenhetId, callback) {
    callApi('GET', '/BruksenhetTiltakType/' + tiltaktypeId + '/' + bruksenhetId, null, callback);
};

var nyOppdaterBruksenhetTiltakType = function(tiltaktype, callback) {
    callApi('POST', '/BruksenhetTiltakType/createorupdate', tiltaktype, callback);
};

// T I L T A K S T Y P E R  -  R Ø Y K L Ø P

var getRoyklopTiltakstyper = function(royklopId, callback) {
    callApi('GET', '/RoyklopTiltakType/' + royklopId, null, callback);
};

var getRoyklopTiltakTypeOppslagdata = function(callback) {
    callApi('GET', '/RoyklopOppslagdata/tiltaktype/', null, callback);
};

var saveRoyklopTiltakType = function(tiltaktype, callback) {
    callApi('POST', '/RoyklopTiltakType/', tiltaktype, callback);
};

var slettRoyklopTiltakType = function(id, callback) {
    callApi('DELETE', '/RoyklopTiltakType/'+ id, null, callback);
};

var oppdaterRoyklopTiltakType = function(id, tiltaktype, callback) {
    callApi('PUT', '/RoyklopTiltakType/' + id, tiltaktype, callback);
};

var getBruksenhetNesteTiltakTypeById = function(id, callback) {
    callApi('GET', '/BruksenhetTiltakType/neste/' + id, null, callback);
};
var getTiltakTypeForRoyklop = function(tiltaktypeId, royklopId, callback) {
    callApi('GET', '/RoyklopTiltakType/' + tiltaktypeId + '/' + royklopId, null, callback);
};

// B E S Ø K  M E R K N A D

var getBesokMerknad = function(bruksenhetId, callback) {
    callApi('GET', '/Bruksenhet/besokmerknad/' + bruksenhetId, null, callback);
};

var saveBesokMerknad = function(bruksenhetId, merknad, callback) {
    callApi('PUT', '/Bruksenhet/besokmerknad/' + bruksenhetId, merknad, callback);
};

// V A R S E L

//Fra BruksenhetVarselController i SkyBrannApi
var postVarslingDataManuell = function(varselListe, callback) {
    callApi('POST', '/bruksenhetvarsel/manuell', varselListe, callback); 
};

//Henter blant annet adresser som skal vises i Delt-Pipe
var postBruksenhetAdresser2 = function(bruksenhetListe, callback) {
    callApi('POST', '/bruksenhet/adresser2/', bruksenhetListe, callback);
};

var postVarslingInnbyggerManuell = function(bruksenhetvarselListe, callback) {
    callApi('POST', '/bruksenhetvarsel/innbyggermanuell', bruksenhetvarselListe, callback); 
};

var postVarslingInnbyggerSvarUt = function(bruksenhetvarsel, callback) {
    callApi('POST', '/bruksenhetvarsel/innbyggersvarut', bruksenhetvarsel, callback); 
};

var getInfoForSvarut = function(dto, callback) {
    callApi('POST', '/bruksenhetvarsel/malfletting', dto, callback);
};

//Kanseller
var postVarslingKanseller = function(bruksenhetKansellerListe, callback) {
    callApi('POST', '/bruksenhetvarsel/kanseller', bruksenhetKansellerListe, callback); 
};

//Fjerne fra planlegging-arbeidsliste
var postVarslingFjern = function(bruksenhetFjernListe, callback) {
    callApi('POST', '/ArbeidslisteBruksenhet/fjernbruksenhet2', bruksenhetFjernListe, callback); 
};

//Flytte til annen planlegging-arbeidsliste
var postVarslingFlytt = function(bruksenhetFjernListe, callback) {
    callApi('POST', '/ArbeidslisteBruksenhet/flyttbruksenhet2', bruksenhetFjernListe, callback); 
};


// N O T A T

var getNotaterForBruksenhet = function(bruksenhetId, callback) {
    callApi('GET', '/Bruksenhet/notat/' + bruksenhetId, null, callback);
};

var postNotatForBruksenhet = function(notat, callback) {
    callApi('POST', '/Bruksenhet/notat/', notat, callback);
};

var slettNotatForBruksenhet = function(notatId, callback) {
    callApi('DELETE', '/Bruksenhet/notat/'+ notatId, null, callback);
};

// F Y R I N G S  F O R B U D
var getFyringsforbudForBruksenhet = function(bruksenhetId, callback) {
    callApi('GET', '/Bruksenhet/fyringsforbud/' + bruksenhetId, null, callback);
};

var saveFyringsforbudForBruksenhet = function(bruksenhetId, fyringsforbud, callback) {
    callApi('PUT', '/Bruksenhet/fyringsforbud/' + bruksenhetId, fyringsforbud, callback);
};


export  {
    getEnheter,
    getEnheterByFilter,
    getEnheterMedVarslingByEnhetIds,
    getBruksenhetSituasjon,
    getBruksenhet,
    updateBruksenhet,
    createBruksenhet,
    getBruksenhetTiltakHistorikk,
    getBruksenhetTiltakOppslagsdata,
    getBruksenhetTiltakOppslagsdataForArbOgBr,
    saveTiltakBruksenhet,
    getBruksenhetSituasjonTiltakById,
    updateTiltakArbeidslisteBruksenhet,
    validertiltakendret,
    deleteBruksenhet,
    getAlleTiltakstyper,
    getArblisteBrukenheterPlanlegging,
    getBruksenhetTiltakstyper,
    saveBruksenhetTiltakType,
    slettBruksenhetTiltakType,
    oppdaterBruksenhetTiltakType,
    getRoyklopTiltakstyper,
    getRoyklopTiltakTypeOppslagdata,
    saveRoyklopTiltakType,
    slettRoyklopTiltakType,
    oppdaterRoyklopTiltakType,
    getBruksenhetObjekthistorikk,
    getTiltakTypeForBruksenhet,
    getTiltakTypeForRoyklop,
    slettTiltakBruksenhet,
    updateTiltakBruksenhet,
    getTiltakBruksenhet,
    getBruksenhetTiltakTypeOppslagdata,
    getBruksenhetNesteTiltakTypeById,
    getArblisteBrukenheterGjennomforing,
    getBesokMerknad,
    saveBesokMerknad,
    flyttTiltakBruksenhet2,
    fjernTiltakBruksenhet2,    
    getByggLokasjon,
    postVarslingDataManuell,
    kobleTilMatrikkel,
    postBruksenhetAdresser2,
    getBruksenhetTiltakikkeutfort,
    postVarslingInnbyggerManuell,
    nyOppdaterBruksenhetTiltakType,
    risikooverstyrtBruksenhet,
    postVarslingKanseller,
    postVarslingFjern,
    postVarslingFlytt,
    getByggTypeKode,
    postVarslingInnbyggerSvarUt,
    getInfoForSvarut,
    GetBruksenhetRoyklopTiltakStatus,
    getBruksenhetEiendom,
    getTiltaksHistorikk,
    getBruksenheterTilknyttetBygg,
    getNotaterForBruksenhet,
    postNotatForBruksenhet,
    slettNotatForBruksenhet,
    getFyringsforbudForBruksenhet,
    saveFyringsforbudForBruksenhet,
    getBruksenhetSlettetLogg
};

export default {
    getEnheter,
    getEnheterByFilter,
    getEnheterMedVarslingByEnhetIds,
    getBruksenhetSituasjon,
    getBruksenhet,
    updateBruksenhet,
    createBruksenhet,
    getBruksenhetTiltakHistorikk,
    getBruksenhetTiltakOppslagsdata,
    getBruksenhetTiltakOppslagsdataForArbOgBr,
    saveTiltakBruksenhet,
    getBruksenhetSituasjonTiltakById,
    updateTiltakArbeidslisteBruksenhet,
    validertiltakendret,
    deleteBruksenhet,
    deleteBruksenhetFraListe,
    getAlleTiltakstyper,
    getArblisteBrukenheterPlanlegging,
    getBruksenhetTiltakstyper,
    saveBruksenhetTiltakType,
    slettBruksenhetTiltakType,
    oppdaterBruksenhetTiltakType,
    getRoyklopTiltakstyper,
    getRoyklopTiltakTypeOppslagdata,
    saveRoyklopTiltakType,
    slettRoyklopTiltakType,
    oppdaterRoyklopTiltakType,
    getBruksenhetObjekthistorikk,
    getTiltakTypeForBruksenhet,
    getTiltakTypeForRoyklop,
    slettTiltakBruksenhet,
    updateTiltakBruksenhet,
    getTiltakBruksenhet,
    getBruksenhetTiltakTypeOppslagdata,
    getBruksenhetNesteTiltakTypeById,
    getArblisteBrukenheterGjennomforing,
    getBesokMerknad,
    saveBesokMerknad,
    flyttTiltakBruksenhet2,  
    postVarslingDataManuell,
    fjernTiltakBruksenhet2,
    getByggLokasjon,
    kobleTilMatrikkel,
    postBruksenhetAdresser2,
    getBruksenhetTiltakikkeutfort,
    postVarslingInnbyggerManuell,
    nyOppdaterBruksenhetTiltakType,
    risikooverstyrtBruksenhet,
    postVarslingKanseller,
    postVarslingFjern,
    postVarslingFlytt,
    getByggTypeKode,
    postVarslingInnbyggerSvarUt,
    getInfoForSvarut,
    GetBruksenhetRoyklopTiltakStatus,
    getBruksenhetEiendom,
    getTiltaksHistorikk,
    getBruksenheterTilknyttetBygg,
    getNotaterForBruksenhet,
    postNotatForBruksenhet,
    slettNotatForBruksenhet,
    getFyringsforbudForBruksenhet,
    saveFyringsforbudForBruksenhet,
    getBruksenhetSlettetLogg
};