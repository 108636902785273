import React, { Component } from 'react';
import BasisKart from 'components/Kart/BasisKart.jsx';

 class sonerOmraader extends Component {  

  componentDidMount = () => {
    //
  }

  componentWillUnmount = () => {
    //
  }

  render() {
      return (
      <div>
        
        <BasisKart
           kartClassName= 'kartenheterFxModal'
            visning="soner" 
            minimap={false} 
          />
      </div>
      );
  }
} 


export default sonerOmraader;