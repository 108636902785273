import _ from 'lodash';

export function findProperty(obj, key) {    
  if (_.has(obj, key))
    {
    return [obj];
    }
  return _.flatten(
    _.map(obj, function(v) {
      return typeof v === 'object' ? findProperty(v, key) : [];
    }),
    true
  );

  var res = [];
  _.forEach(obj, function(v) {
    if (typeof v === 'object' && (v = findProperty(v, key)).length) {
      res.push.apply(res, v);
    }
  });
  return res;
}
