import React from 'react';
import norkartIdJs from 'auth/auth';
import { PropTypes } from 'prop-types';

const LoginButton = function ({ children }, { router }) {
    const login = () => {
        norkartIdJs.logIn();
        router.replace('/');
    };
    return (
        <button className="btn btn-success"  style={{ fontSize: '18px' }} onClick={login} type="button">{children}</button>
    );
};
LoginButton.contextTypes = {
    router: PropTypes.object.isRequired
};

export default LoginButton;