import React, { Component } from 'react';
import moment from 'moment';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import ReactTable from '@norkart/react-table';
import { getBruksenheterLoggAutomatisering } from 'api/loggApi';
import { debugFactory } from 'utils';
import { withRouter } from 'lib/enhancers';
const debug = debugFactory('nk:Automatisering:LoggBruksenheter');
//kopier: import LoggBruksenheter from './LoggBruksenheter.jsx';

moment.locale('nb', {
    week: {
      dow: 1,
      doy: 1
    }
  });

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  )
};


let columns = [
  {
    id: 'timestamp',
    name: 'Dato/Tid',
    sortParams: 'timestamp',
    isSorted: true,
    sortDirection: 'asc',
    formatter: function(item) {
      return moment(item.timeStamp).format('DD.MM.YYYY HH:mm');
    }
  },
  {
    id: 'typeAutomatisering',
    name: 'Type',
    sortParams: 'typeAutomatisering',
    isSorted: false,
    sortDirection: 'asc'
  },
  {
    id: 'bruksenhetAdresse',
    name: 'Adresse',
    sortParams: 'bruksenhetAdresse',
    isSorted: false,
    sortDirection: 'asc'
  },
  {
    id: 'informasjon',
    name: 'Informasjon',
    sortParams: 'informasjon',
    isSorted: false,
    sortDirection: 'asc',
    formatter: function(item) {
      return (
        <span style={{ color: 'blue', fontSize: '14px' }}>
          {item.informasjon}
        </span>
      );
    }
  }
];

class LoggBruksenheter extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 0, orgId: this.props.params.orgId, bruksenheter: [] };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    debug('data mount');
    this.hentData();
  }

  hentData = () => {
    let id = this.props.params.orgId;
    getBruksenheterLoggAutomatisering(id, this.onGotBruksenheter);
  };

  onGotBruksenheter = (err, data) => {
    this.setState({ bruksenheter: data });
    debug('HHH data', data);
    debug('HHH err', data);
  };

  render() {
    return (
      <div>
        <h3>{'Bruksenheter ' + this.props.params.orgNavn}</h3>
        <p></p>
        <ReactTable
          items={this.state.bruksenheter}
          showIndex={false}
          filterable={false}
          columns={columns}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(LoggBruksenheter);
