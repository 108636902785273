import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import flames from './flames.png';
import moment from 'moment';

let doc = null;

const makeTittel = (tittel) => {
	doc = new jsPDF('p', 'px', 'a4');
	doc.setFontSize(18);
	doc.text(60, 40, tittel);
	doc.setDrawColor('gray');
	doc.setLineWidth(0.4);
	doc.line(60, 44, 400, 44);
	doc.addImage(flames, 'PNG', 20, 18, 30, 30);
};

const makeFooter = () => {
	const pages = doc.internal.getNumberOfPages();
	const pageWidth = doc.internal.pageSize.width;
	const pageHeight = doc.internal.pageSize.height;
	doc.setFontSize(10);

	for (let j = 1; j < pages + 1; j++) {
		let horizontalPos = pageWidth / 2;
		let horizontalRight = pageWidth - 60;
		let verticalPos = pageHeight - 10;
		doc.setPage(j);
		doc.setDrawColor('gray');
		doc.setLineWidth(0.6);
		doc.line(20, verticalPos - 10, 600, verticalPos - 10);
		doc.text(`side ${j} av ${pages}`, horizontalPos, verticalPos, {
			align: 'center',
		});
		let dat = moment(new Date()).format('DD.MM.YYYY');
		doc.text('© Norkart AS - ' + dat, horizontalRight, verticalPos, { align: 'right' });
	}
};

const makeDataRows = (data) => {
	let rows = [];
	data.map((row) =>
		rows.push([
			row.label,
			row.tall
		])
	);
	return rows;
};

const makeTable = (data) => {
	autoTable(doc, {
		headStyles: { fillColor: [43, 167, 0] },
		startY: 60,
		tableWidth: 400,
		margin: {
			left: 20,
		},
		theme: 'grid',
		head: [
			[
				'Label',
				'Antall',
			],
		],
		body: makeDataRows(data),
	});
};

const makeFileName = (str) => {
    let res = 'brannforebygging';
    if (str) {
      let s = str.replace(/[^\w\s]/gi, '');
      if (s) {
        s = s.replace(/ /g, '_');
      }
      return s.toLowerCase();
    }
    return res + '.pdf';
  };

export const Nokkeltall = (props) => {
	makeTittel(props.tittel);
	makeTable(props.data);
	makeFooter();

	doc.save(makeFileName(props.tittel));
};
