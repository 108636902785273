import React from 'react';
import ContextMenu from 'components/ContextMenu';
import './mal.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ReactModal from 'react-modal';
import ReactTable from '@norkart/react-table';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import * as _ from 'lodash';
import MalDialog from 'modules/Innstillinger/Maloppsett/SmsMalDialog.jsx';
import { ToolbarButton } from 'components/generics/Button.jsx';
import AlleEksempelDialog from './AlleEksempelDialog.jsx';
import {
  getSmsMaler,
  getAlleSmsEksempelMal,
  getMalerOppslagsdata,
} from 'api/malerApi';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  AlleEksempelmalDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <AlleEksempelDialog
        onClose={onClose}
        etterLastingAvMaler={owner.etterLastingAvMaler}
        modus='SMS'
      />
    </NKModal>
  ),
  MalDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className='malModalMediumX'>
      <MalDialog
        onClose={onClose}
        onOppdatert={owner.oppdaterListe}
        mal={dialogData.mal}
        flettefelt={dialogData.flettefelt}
        funksjonListe={dialogData.funksjonListe}
        modus='Eksempel'
      />
    </ReactModal>
  ),
};

class Smsmaler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maler: null,
      oppslagP: null,
      oppslagG: null,
      oppslagO: null,
      oppslagF: null,
      swap: true,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    getMalerOppslagsdata('P', 'S', this.gotgOppslagDataP);
    getMalerOppslagsdata('G', 'S', this.gotgOppslagDataG);
    getMalerOppslagsdata('O', 'S', this.gotgOppslagDataO);
    getMalerOppslagsdata('F', 'S', this.gotgOppslagDataF);
  }

  oppdaterListe = () => {
    getAlleSmsEksempelMal(this.gotMaler);
  };

  getEksempelMalerx = () => {
    setTimeout(() => {
      getAlleSmsEksempelMal(this.gotMaler);
    }, 1000);
  };

  gotgOppslagDataP = (err, data) => {
    this.setState({ oppslagP: data });
  };

  gotgOppslagDataG = (err, data) => {
    this.setState({ oppslagG: data });
  };

  gotgOppslagDataO = (err, data) => {
    this.setState({ oppslagO: data }, this.getEksempelMalerx());
  };

  gotgOppslagDataF = (err, data) => {
    this.setState({ oppslagF: data }, this.getEksempelMalerx());
  };

  gotMaler = (err, maler) => {
    if (maler) {
      for (let i = 0; i < maler.length; i++) {
        let mal = maler[i];
        maler[i].funksjon = this.getFunksjon(mal.gjelderKode, mal.funksjonId);
      }
      this.setState({ maler });
    }
  };

  rowClick = (mal) => {
    let funksjonId = mal.funksjonId;
    let flettefelt = [];
    let funksjonListe = [];
    let funksjon;
    switch (mal.gjelderKode) {
      case 'P':
        funksjon = _.find(this.state.oppslagP.funksjonListe, {
          id: funksjonId,
        });
        if (funksjon) {
          flettefelt = funksjon.flettefelt;
          funksjonListe = this.state.oppslagP.funksjonListe;
        }
        break;
      case 'G':
        funksjon = _.find(this.state.oppslagG.funksjonListe, {
          id: funksjonId,
        });
        if (funksjon) {
          flettefelt = funksjon.flettefelt;
          funksjonListe = this.state.oppslagG.funksjonListe;
        }

        break;
      case 'O':
        funksjon = _.find(this.state.oppslagO.funksjonListe, {
          id: funksjonId,
        });
        if (funksjon) {
          flettefelt = funksjon.flettefelt;
          funksjonListe = this.state.oppslagO.funksjonListe;
        }

        break;
      case 'F':
        funksjon = _.find(this.state.oppslagF.funksjonListe, {
          id: funksjonId,
        });
        if (funksjon) {
          flettefelt = funksjon.flettefelt;
          funksjonListe = this.state.oppslagF.funksjonListe;
        }

        break;
      default:
        return;
    }
    this.dialogs.setOpenDialog('MalDialog', {
      mal: mal,
      flettefelt: flettefelt,
      funksjonListe: funksjonListe,
    });
  };

  slettMal = (mal) => {};

  nyMal = (gjelderKode, gjelder) => {};

  etterLastingAvMaler = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Eksempelmaler..',
      melding: 'Eksempelmalene for "SMS" har nå blitt lastet inn.',
    });
    getAlleSmsEksempelMal(this.gotMaler);
  };

  alleEksempelmal = () => {
    getSmsMaler(this.gotAntallMaler);
  };


  gotAntallMaler = (err, maler) => {
    if (maler.length > 0 && this.state.swap) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Du kan ikke bruke funksjonen..',
        melding:
          'Organisasjonen har allerede definert ' +
          maler.length +
          ' SMS-maler.',
      });
    } else {
      this.dialogs.setOpenDialog('AlleEksempelmalDialog');
    }
    this.setState({ swap: !this.state.swap });
  };

  slettMal = (mal) => {
    alert('Slett eksempelmal er ikke implementert enda..');
  };

  getFunksjon = (gjelder, funksjonId) => {
    var result = null;
    switch (gjelder) {
      case 'P':
        result = _.filter(this.state.oppslagP.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }

      case 'G':
        result = _.filter(this.state.oppslagG.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }

      case 'O':
        result = _.filter(this.state.oppslagO.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }

      case 'F':
        result = _.filter(this.state.oppslagF.funksjonListe, {
          id: funksjonId,
        });
        if (result[0]) {
          return result[0].tekst;
        } else {
          return '!! Ukjent funksjonId ' + funksjonId;
        }

      default:
        return '';
    }
  };

  getColumns() {
    let columns = [
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.standardMal) {
            let t = _.truncate(item.tittel, { length: 60 });
            return (
              <span>
                <span>{t}</span>
                <span style={{ marginLeft: '4px', color: 'blue' }}>
                  {'( Standardmal )'}
                </span>
              </span>
            );
          } else {
            return _.truncate(item.tittel, { length: 60 });
          }
        },
      },
      {
        id: 'beskrivelse',
        name: 'Beskrivelse',
        sortParams: 'beskrivelse',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return (
            <span title={item.beskrivelse}>
              {_.truncate(item.beskrivelse, { length: 35 })}
            </span>
          );
        },
      },
      {
        id: 'gjelderKode',
        name: 'Gjelder',
        sortParams: 'gjelderKode',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          switch (item.gjelderKode) {
            case 'P':
              return 'Planlegging';

            case 'G':
              return 'Gjennomføring';

            case 'O':
              return 'Oppfølging';

            case 'F':
              return 'Generelle funksjoner';

            default:
              return 'Planlegging';
          }
        },
      },
      {
        id: 'funksjon',
        name: 'Funksjon',
        sortParams: 'funksjon',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.funksjon.includes('!!')) {
            return <span style={{ color: 'red' }}>{item.funksjon}</span>;
          } else {
            return <span>{item.funksjon}</span>;
          }
        },
      },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.truncate(item.tekst, { length: 1400 });
          let z = { __html: s };
          let tooltip = <div dangerouslySetInnerHTML={z} />;
          /*   let klasse = 'malspan';
          if (s === '') {
            s = 'Tekst mangler..';
            klasse = 'malspanred';
          } */
          return (
            <span className='text'>
              {' '}
              <NKIcon icon='kommentar' size='0.8' color='nk-black' />
              <span className='text-tooltip'>{tooltip}</span>
            </span>
          );
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => this.rowClick(item)}>
                Rediger mal
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => this.slettMal(item)}>
                Slett mal
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
    return columns;
  }

  render() {
    if (!this.state.maler) {
      return <div style={{ margin: '30px' }}>Vent, laster data..</div>;
    }
    return (
      <div style={{ margin: '10px' }}>
        <h2>Eksempelmaler SMS</h2>
        <div style={{ margin: '0' }}>
          {/*   <React.Fragment>
            <ToolbarButtonDrop style={{ marginRight: 4 }} icon='pluss'>
              Ny mal
            </ToolbarButtonDrop>
            <div className='btn-group' style={{ marginBottom: '2px' }}>
              <button
                style={{
                  marginRight: '3px',
                  marginBottom: '2px',
                  marginTop: 0,
                  borderColor: 'darkGray',
                  height: '48px',
                }}
                type='button'
                aria-haspopup='true'
                className='btn btn-default dropdown-toggle'
                data-toggle='dropdown'>
                <span className='caret' />
              </button>
              <ul
                className='dropdown-menu'
                style={{ width: '90px' }}
                role='menu'>
                <li name='U'>
                  <a onClick={this.nyMal.bind(this, 'P', 'Planlegging')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Planlegging
                    </span>
                  </a>
                </li>
                <li name='U'>
                 
                  <a>
                    <span title='Ikke i bruk enda..' style={{ padding: '4px', cursor: 'not-allowed' }}>
                      Gjennomføring
                    </span>
                  </a>
                </li>
                <li name='U'>
                
                  <a>
                    <span title='Ikke i bruk enda..' style={{ padding: '4px', cursor: 'not-allowed' }}>
                      Oppfølging
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </React.Fragment> */}
          
   
          <ToolbarButton
            style={{ marginLeft: 4 }}
            className='pull-right'
            icon='kopier'
            onClick={this.alleEksempelmal}>
            &nbsp;&nbsp;Last inn ALLE eksempelmaler
          </ToolbarButton>
        </div>
        <br />
        <br />
        <br />
        <ReactTable
          toggleAllDefault={false}
          showIndex={false}
          columns={this.getColumns(this)}
          items={this.state.maler}
          selectableRows={false}
          onRowClick={(row) => this.rowClick(row)}
        />
        <br />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Smsmaler;
