import React from 'react';
import ReactTable from '@norkart/react-table';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import royk_ikon from 'images/48/nk_075-royklop-A-48px.png';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import DialogStore from 'lib/DialogStore';
import ObjektAdresse from '../components/ObjektAdresse';
// Utils
import {
    iconTypeReset,
    tiltakTypeBruksenhetListe2ArbeidslisteBruksenhetListe,
    tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe,
} from '../lib';
import {
    debugFactory,
    objGetDeep,
    parseMultiLineString,
} from 'utils';

const debug = debugFactory('nk:planlegging:arbeidsliste:leggtilobjekter:pagetiltak:index');

class PageTiltak extends React.Component {

    state = {
        valgteBruksenhetsIder: [],
        valgteRoyklopIder: [],
        valgteRoyklop: [],
        bruksenheterVarslet: [],
        arbeidslisteTiltakLister: {},
        selectedTiltaksTypeBruksenhet: 'Velg tiltak',
        selectedTiltaksTypeRoyklop: 'Velg tiltak'
    };

    constructor(props) {
        super(props);

        this.dialogs = new DialogStore({
            owner: this,
            dialogs: modalDialogs
        });
    }

    harAlleObjekterTiltak = () => {
        const objektliste = this.props.getTiltakListe();
        const alleHarTiltakSatt = _.every(objektliste, (objekt) => {
            const hasTiltak = (objekt.tiltakTypeId && objekt.tiltakTypeId >= 0) || objekt.slett === true;
            ; (!hasTiltak && debug('harAlleObjekterTiltak:royklop', { hasTiltak, objekt }));
            return hasTiltak;
        });


        return alleHarTiltakSatt;
    };

    isEnhetRowSelectable = (item) => {
        if (_.isEmpty(this.bruksenheterVarslet)) { return true; }
        return !this.bruksenheterVarslet.some(id => id == item.bruksenhetId);
    }

    isRoyklopRowSelectable = (item) => {
        if (_.isEmpty(this.royklopVarslet)) { return true; }
        return !this.royklopVarslet.some(id => id == item.royklopId);
    }

    // E V E N T  H A N D L E R S

    onClickOk = (e) => {
        if (this.harAlleObjekterTiltak()) {
            debug('onClickOk:save');
            e.target.disabled = true;
            this.props.onSave();
        } else {
            debug('onClickOk:OkTiltakMangler');
            this.dialogs.setOpenDialog('OkTiltakMangler');
        }
    };

    onClickForrige = () => {
        this.props.showPrevPage();
    }

    onChangeSelectTiltakBruksenheter = (e) => {
        const tiltakTypeId = e.target.value;
        this.setState({ selectedTiltaksTypeBruksenhet: tiltakTypeId }, () => {
            this.setTilttak(tiltakTypeId, true);
        });
    };

    onChangeSelectTiltakRoyklop = (e) => {
        const tiltakTypeId = e.target.value;
        this.setState({ selectedTiltaksTypeRoyklop: tiltakTypeId }, () => {
            this.setTilttak(tiltakTypeId, false);
        });
    };

    onClickResettTiltakBruksenheter = () => {
        this.props.applyPageTiltakSnapshot('tiltakTypeBruksenhetListe');
        this.setState({ selectedTiltaksTypeBruksenhet: null });
    };

    onClickResettTiltakRoyklop = () => {
        this.props.applyPageTiltakSnapshot('tiltakTypeRoyklopListe');
        this.setState({ selectedTiltaksTypeRoyklop: null });
    };

    onChangeSelectedTableBruksenheter = (rows) => {
        debug('xy rows', rows);
        var ids = (rows || [])
            .map(({ bruksenhetId }) => parseInt(bruksenhetId));
        this.setState({ valgteBruksenhetsIder: ids });
    };

    onChangeSelectedTableRoyklop = (rows) => {
        var ids = (rows || [])
            .map(({ royklopId }) => parseInt(royklopId));

        debug('onChangeSelectedTableRoyklop:selected', { rows, ids });
        this.setState({ valgteRoyklopIder: ids });
    };

    // P A R S I N G  ( C O M P L E X  S T U F F )

    getTiltakTypeTekst = (tiltakTypeId, erBruksenhet) => {
        tiltakTypeId = parseInt(tiltakTypeId);

        const tiltakTypeListe = (this.props.store.tiltakstyper[(erBruksenhet
            ? 'tiltakTypeBruksenhetListe'
            : 'tiltakTypeRoyklopListe')]);

        const tiltak = _.find(tiltakTypeListe, { id: tiltakTypeId })

        debug('getTiltakTypeTekst', { tiltakTypeId, erBruksenhet, tiltak, tiltakTypeListe });

        return objGetDeep(tiltak, 'tekst', '');
    };

    parseArbeidslisteTiltakLister = () => {

        [true, false].forEach(erBruksenhet => {

            const objektliste = (this.props.store.arbeidslisteTiltakLister[(erBruksenhet
                ? 'tiltakTypeBruksenhetListe'
                : 'tiltakTypeRoyklopListe')]);

            debug('parseArbeidslisteTiltakLister:objektliste', { erBruksenhet, objektliste })

            const groups = Object.values(_.groupBy(objektliste, (erBruksenhet ? 'bruksenhetId' : 'royklopId')));

            debug('parseArbeidslisteTiltakLister:groups', { groups })

            groups.forEach(group => {
                const tiltakstekstSet = new Set();
                group.forEach(objekt => {
                    // Prime for concatenating `tiltakstekst`; 
                    // Just share the set among all items in the group so adding to one will add to all
                    const tiltakstekst = this.getTiltakTypeTekst(objekt.tiltakTypeId, erBruksenhet);
                    if (tiltakstekst) {
                        tiltakstekstSet.add(tiltakstekst)
                    };
                    objekt.tiltakstekstSet = tiltakstekstSet;
                });
            });
        });

        debug('parseArbeidslisteTiltakLister:done', { arbeidslisteTiltakLister: this.props.store.arbeidslisteTiltakLister });
    };

    // U P D A T E R S ( C O M P L E X  S T U F F ) 

    setTilttak = (tiltakTypeId, erBruksenhet) => {
        // Prime for setting `tiltak` based on `erBruksenhet`;
        const params = {
            tiltakTypeId,
            tiltakTypetekst: this.getTiltakTypeTekst(tiltakTypeId, true),            
        };

        if (erBruksenhet) {
            params.arbeidsListeTiltakListe = this.props.getTiltakListe({ bruksenheter: true });
            
            params.valgteObjektTiltak = params.arbeidsListeTiltakListe
                // Only include those items are checked
                .filter(objekt => this.state.valgteBruksenhetsIder.includes(objekt.bruksenhetId));
            
            params.duplicateCheck = (objekt1, objekt2) => {
                return (
                    objekt2.bruksenhetId == objekt1.bruksenhetId 
                    && objekt2.tiltakTypeId == tiltakTypeId
                );
            }

            params.setArbeidslisteTiltakListe = (arbeidsListeTiltakListe) => this.props.setArbeidslisteTiltakLister({
                'tiltakTypeBruksenhetListe': arbeidsListeTiltakListe
            });        
        } 
        else {
            params.arbeidsListeTiltakListe = this.props.getTiltakListe({ royklop: true });

            params.valgteObjektTiltak = params.arbeidsListeTiltakListe
                // Only include those items are checked
                .filter(objekt => this.state.valgteRoyklopIder.includes(objekt.royklopId));
            
            params.duplicateCheck = (objekt1, objekt2) => {
                return (
                    objekt2.bruksenhetId == objekt1.bruksenhetId 
                        && objekt2.royklopId == objekt1.royklopId
                        && objekt2.tiltakTypeId == tiltakTypeId
                );
            }

            params.setArbeidslisteTiltakListe = (arbeidsListeTiltakListe) => this.props.setArbeidslisteTiltakLister({
                'tiltakTypeRoyklopListe': arbeidsListeTiltakListe
            });
        }

        // Do the actual work
        this._setTiltak(params);

    }

    _setTiltak = ({
        tiltakTypeId,
        tiltakTypetekst,
        arbeidsListeTiltakListe,
        valgteObjektTiltak,
        duplicateCheck,
        setArbeidslisteTiltakListe,
    }) => {
        const objekterToAdd = valgteObjektTiltak
            .map(objekt => {
                let objektToAdd; // Return value

                const duplikatFunnet = valgteObjektTiltak.find((objekt2) => duplicateCheck(objekt, objekt2));

                debug('_setTiltak:objekt', { objekt, duplikatFunnet })

                if (duplikatFunnet) {
                    // Nothing to do
                    return;
                }
    
                // Add `tekst` to the set, the set is shared among the tiltak in the group that makes out the `objekt`
                objekt.tiltakstekstSet.add(tiltakTypetekst);

                if (objekt.tiltakTypeId === undefined) {
                    // Brand new objekt; update it
                    Object.assign(objekt, {
                        tiltakTypeId: parseInt(tiltakTypeId),
                        ny: true,
                        slett: false
                    });

                    debug('_setTiltak:objekt:new', { objekt })
                }
                else {
                    // Make a new `objekt`
                    objektToAdd = {
                        ...(_.cloneDeep(objekt)),
                        tiltakTypeId: parseInt(tiltakTypeId),
                        ny: true,
                        slett: false
                    }

                    debug('_setTiltak:objekt:existing', { objekt, objektToAdd });
                }

                return objektToAdd
            })
            .filter(objekt => objekt);
            
        // Set updated values    
        setArbeidslisteTiltakListe(arbeidsListeTiltakListe.concat(objekterToAdd));      
    }

    // C O M P O N E N T S

    SelectTiltakForBruksenheter = () => {
        return (
            <select
                className="form-control"
                disabled={this.state.valgteBruksenhetsIder.length < 1}
                onChange={this.onChangeSelectTiltakBruksenheter}
                value={''}
            >
                <option key={''}>- Velg tiltak -</option>
                {
                    _.map(
                        this.props.store.tiltakstyper.tiltakTypeBruksenhetListe,
                        function (o, i) {
                            return (
                                <option key={i} value={o.id}>
                                    {o.tekst}
                                </option>
                            );
                        }
                    )
                }
            </select>
        );
    }

    SelectTiltakForRoyklop = () => {
        return (
            <select
                className="form-control"
                disabled={this.state.valgteRoyklopIder.length < 1}
                onChange={this.onChangeSelectTiltakRoyklop}
                value={''}>
                <option key={''}>- Velg tiltak -</option>
                {_.map(
                    this.props.store.tiltakstyper.tiltakTypeRoyklopListe,
                    function (o, i) {
                        return (
                            <option key={i} value={o.id}>
                                {o.tekst}
                            </option>
                        );
                    }
                )}
            </select>
        );
    }

    TableBruksenheter = () => {

        const objektlisteOrig = this.props.getTiltakListe({ bruksenheter: true });
        debug('xy 1', objektlisteOrig);
        // Here we are only interested in the "units", and the sum of their "tiltak"
        const objektliste = tiltakTypeBruksenhetListe2ArbeidslisteBruksenhetListe(objektlisteOrig, {
            uniqBy: 'bruksenhetId',
            bypass: false,
            debug
        });

        

        const toggleAllDefault = _.every(objektliste, ['ny', true]);
        debug('xy 2', toggleAllDefault); 
        debug('xy 3', {
            objektlisteOrig,
            objektliste  
        });

        return (
            <ReactTable 
                ref= 'enheterTable'
                className="panel-title"
                showIndex={false}
                toggleAllDefault={toggleAllDefault}
                columns={get_RT_columns({ erBruksenhet: true })}
                selectableRows={true}
                items={objektliste}
                isRowSelectable={this.isEnhetRowSelectable}
                onRowSelectionChange={this.onChangeSelectedTableBruksenheter}
                onRowClick={null}
                idProperty="bruksenhetId"
                itemsPrPage={0}
                selectedItems={objektliste}
            />
        );
    }

    TableRoyklop = () => {

        const objektlisteOrig = this.props.getTiltakListe({ royklop: true });
        // Here we are only interested in the "units", and the sum of their "tiltak"
        const objektliste = tiltakTypeRoyklopListe2ArbeidslisteRoyklopListe(objektlisteOrig, {
            uniqBy: 'royklopId',
            bypass: false,
            debug
        });

        const toggleAllDefault = _.every(objektliste, ['ny', true]);

        debug('TableRoyklop', {
            objektlisteOrig,
            objektliste,
        });

        return (
            <ReactTable
                className="panel-title"
                showIndex={false}
                toggleAllDefault={toggleAllDefault}
                columns={get_RT_columns({ erBruksenhet: false })}
                selectableRows={true}
                items={objektliste}
                onRowSelectionChange={this.onChangeSelectedTableRoyklop}
                isRowSelectable={this.isRoyklopRowSelectable}
                onRowClick={null}
                idProperty="royklopId"
                itemsPrPage={0}
            />
        );
    }

    render() {
        debug('render', { ...this });

        if (_.isEmpty(this.props.store.arbeidslisteTiltakLister)) {
            return null;
        }

        this.parseArbeidslisteTiltakLister();

        return (
            <React.Fragment>
                {/* BRUKSENHETER */}
                <div className="row rowSokrowSok fixedresults">
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4>
                                            <span className="pull-left">
                                                <NKIcon icon="hjem" size="0.7" color="nk-black" />
                                            </span>
                                            <span className="margin-left-10">Tiltak for bruksenheter</span>
                                        </h4>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="col-md-6">
                                            {this.SelectTiltakForBruksenheter()}
                                        </div>
                                        <div className="col-md-6">
                                            <button
                                                onClick={this.onClickResettTiltakBruksenheter}
                                                className="btn "
                                            >
                                                <NKIcon icon={iconTypeReset} size="0.7" color="nk-black" />
                                                Tilbakestill tiltak
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                {this.TableBruksenheter()}
                            </div>
                        </div>
                    </div>

                    {/* ROYKLOP */}
                    <div className="col-md-6">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h4>
                                            <img
                                                height="16"
                                                width="16"
                                                src={royk_ikon}
                                                className="app-preloader__img pull-left"
                                                alt=""
                                            />
                                            <span className="margin-left-10">Tiltak for røykløp</span>
                                        </h4>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="col-md-6">
                                            {this.SelectTiltakForRoyklop()}
                                        </div>
                                        <div className="col-md-6">
                                            <button
                                                onClick={this.onClickResettTiltakRoyklop}
                                                className="btn "
                                            >
                                                <NKIcon icon={iconTypeReset} size="0.7" color="nk-black" />
                                                Tilbakestill tiltak
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="panel-body">
                                {this.TableRoyklop()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        className="btn btn-lg"
                        onClick={this.onClickForrige}>Forrige</button>  
                    <button className="btn btn-primary btn-lg" onClick={(e) => this.onClickOk(e)}>OK</button>
                </div>

                {this.dialogs.renderOpenDialog()}

            </React.Fragment>
        );
    }
}

export default PageTiltak;

// Declarative parts

const modalDialogs = {
    'OkTiltakMangler': ({ isOpen, onClose, dialogData, owner }) => {
        return (
            <OkDialog
                melding={parseMultiLineString(`
                    Ett eller flere objekter mangler tiltak. 
                    For å lagre må objektene uten tiltak fjernes fra arbeidslisten, eller tiltak må settes på alle objekter.
                `)}
                isOpen={isOpen}
                tittel="Objekter uten tiltak"
                onClose={onClose}
                onOk={onClose}
            />
        )
    }
}

const get_RT_columns = ({ erBruksenhet }) => {

    const adresse = {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function (item) {
            return (<ObjektAdresse item={item} />)
        }
    };

    const columns = [adresse, {
        id: 'tiltakstekst',
        name: 'Tiltak',
        sortParams: 'tiltakstekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function (item) {
            return Array.from(item.tiltakstekstSet).join(', ');
        }
    }];

    return columns;
};