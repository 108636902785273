import React, { Component } from 'react';
import { getAlleArkivInnstillinger } from 'api/arkivApi';
import { getOrganisasjoner } from 'api/organisasjonApi';
import VisBrukere from './VisBrukere.jsx';
import VisSakskategori from './NyVisSakskategori.jsx';
import VisArkivinstillinger from './VisArkivinstillinger.jsx';
import ReactTable from '@norkart/react-table';
import ContextMenu from 'components/ContextMenu';
import DialogStore from 'lib/DialogStore';
import ModalContainer from 'components/Dialogs/ModalContainer';
var _ = require('underscore');

let style1 = {
  height: '1px'
};

const modalDialogs = {
    Sakskategorier: ({ isOpen, onClose, dialogData, owner }) => (
        <ModalContainer
          size="fullscreen"
          isOpen={isOpen}    
          onClose={onClose}
          
          tittel={'Sakskategorier for: ' + dialogData.kundeNavn}>
          <VisSakskategori kundeId = {dialogData.kundeId}/>
        </ModalContainer>
      ),
      BrukerInstillinger: ({ isOpen, onClose, dialogData, owner }) => (
        <ModalContainer
          size="large"
          isOpen={isOpen}    
          onClose={onClose}
          
          tittel={'Brukerinnstillinger for: ' + dialogData.kundeNavn}>
          <VisBrukere kundeId = {dialogData.kundeId}/>
        </ModalContainer>
      ),
      ArkivInstillinger: ({ isOpen, onClose, dialogData, owner }) => (
        <ModalContainer
          size="large"
          isOpen={isOpen}    
          onClose={onClose}
          
          tittel={'Arkivinnstillinger for: ' + dialogData.kundeNavn}>
          <VisArkivinstillinger instillinger = {dialogData.innstillinger}/>
        </ModalContainer>
      ),
};
class navn extends Component {
  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
        owner: this,
        dialogs: modalDialogs
      });
    this.state = { org: null, laster: true, orglaster: false, brukere: null };
  }

  componentDidMount() {
    this.setState({ laster: true });
    getOrganisasjoner(this.gotOrg);
    
  }

  gotOrg = (err, res) => {
    this.setState({ org: res });
    getAlleArkivInnstillinger('123', this.gotInst);
  }

  gotInst = (err, res) => {
    this.setState({ kunder: res, laster: false });
    //console.log(res);
    // let org = this.state.org;
    // let inst = res;
    // org.forEach(o => {
    //     inst.forEach(i => {
    //         if (o.kundeId == i.KundeId) {
    //             o.instillinger = i;
    //         }        
    //     });
    // });
    // console.log(org);
  }

   visKat = (item) => {
    this.dialogs.setOpenDialog('Sakskategorier', {
        kundeId: item.kundeId,
        kundeNavn: item.navn,
      });
   }

   visBruker = (item) => {
    this.dialogs.setOpenDialog('BrukerInstillinger', {
        kundeId: item.kundeId,
        kundeNavn: item.navn,
      });
   }

   visIns = (item) => {
       let inst = null;
       console.log(item);
    this.state.kunder.forEach(element => {
        if (item.kundeId == element.KundeId && element.Applikasjon == 'Brannforebygging') {
            console.log(element);
            inst = element;
        }
    });
    this.dialogs.setOpenDialog('ArkivInstillinger', {
        innstillinger: inst,
        kundeNavn: item.navn,
      });
   }
  
  render() {
    var columns = this.getColumns();
    if (this.state.laster) {
      return <div className="loader" />;
    }
    
    return (
      <div style={{ margin: '30px' }}>
        <h1>Arkiv innstillinger for alle kunder</h1>
        <hr/>
        <p/>
        <div className="row">
            <div className="col-sm-12">
              <ReactTable
                items={this.state.org}
                idProperty="id"
                showIndex={false}
                filterable={false}
                columns={columns}
                selectableRows={false}
              />
            </div>
          </div>
        
          {/* {_.map(
            this.state.kunder,
            function(kunde) {
              console.log(kunde);
              return (
                
                <div>
                  <h3>Arkivinnstillinger</h3>
                  <p>KundeId:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{kunde.KundeId}</p>
                  <p>Arkivsystem: {kunde.Arkivsystem}</p>
                  <p>Fagsystem: {kunde.Fagsystem}</p>
                  <p>Dokumenttype: {kunde.Dokumenttype}</p>
                  <nl/>
                  <p>KorrespondanseparttypeMapping</p>
                  <p>&nbsp;EksternMottakerType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.EksternMottakerType : null}</p>
                  <p>&nbsp;EksternAvsenderType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.EksternAvsenderType : null}</p>
                  <p>&nbsp;InternMottakerType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternMottakerType : null}</p>
                  <p>&nbsp;InternAvsenderType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternAvsenderType : null}</p>
                  <p>&nbsp;InternMottakerInngåendeJpType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternMottakerInngåendeJpType : null}</p>
                  <p>&nbsp;InternMottakerInterntNotatType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternMottakerInterntNotatType : null}</p>
                  <hr/>
                  
                  { kunde.KundeId != null && kunde.KundeId != undefined ? 
                  <div>
                    <VisSakskategori kundeId={kunde.KundeId}/>
                  <hr/>
                  <VisBrukere kundeId={kunde.KundeId}/>
                  <hr className='hr' />
                  </div> : null}
                  
                  
                </div>
              );
            },
            this
          )} */}
       
       {this.dialogs.renderOpenDialog()}  
      </div>
    );
  }

  getColumns() {
    return [
      {
        id: 'navn',
        name: 'Kunde',
        sortParams: 'navn',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
        id: 'kundeId',
        name: 'KundeId',
        sortParams: 'kundeId',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
        id: 'arkivIkkeAktiv',
        name: 'Arkivering',
        sortParams: 'arkivIkkeAktiv',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
            if(item.arkivIkkeAktiv === false) {
                return 'Ja';
            } else {
                return 'Nei';
            }
        },
    },
        {
            id: 'arkivSkjerming',
            name: 'Skjerming',
            sortParams: 'arkivSkjerming',
            filterable: false,
            isSorted: false,
            sortable: false,
            formatter: function(item) {
                if(item.arkivSkjerming === true) {
                    return 'Ja';
                } else {
                    return 'Nei';
                }
            }
      },
      // {
      //   id: 'Arkivdel',
      //   name: 'Arkivdel',
      //   sortParams: 'Arkivdel',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: false
      // },

      // {
      //   id: 'StatusNySaksmappe',
      //   name: 'Status ny',
      //   sortParams: 'StatusNySaksmappe',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: false
      // },
      // {
      //   id: 'StatusAvsluttSaksmappe',
      //   name: 'Status avslutt',
      //   sortParams: 'StatusAvsluttSaksmappe',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: false
      // },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
                  <ContextMenu.Item onClick={this.visIns.bind(this, item)}>
                Vis innstillinger...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.visKat.bind(this, item)}>
                Vis sakskategorier...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.visBruker.bind(this, item)}>
                Vis brukerinnstillinger...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      
    ];
  }
}

export default navn;
