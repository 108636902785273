import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import Nokkeltall from './Nokkeltall';
import Gebyr from './Gebyr';
import NokkeltallIntern from './NokkeltallIntern';
import { debugFactory, enhanceComponents } from 'utils';
import AI from './AI';
import React from 'react';

const debug = debugFactory('nk:rapporter:routes');

const enhanced = enhanceComponents(
  {
    Index,
    Nokkeltall,
    Gebyr,
    NokkeltallIntern,
    AI,
  },
  [asRouteComponent]
);

export default (
  <Route
    path='rapporter'
    component={enhanced.Index}
    name='Rapporter'
    icon='statistikk'
    inMenu={true}
    login={true}
    roles={[]}>
    <Route
      path='nokkeltall'
      component={enhanced.Nokkeltall}
      name='Nøkkeltall'
    />
    <Route
      path='gebyr'
      component={enhanced.Gebyr}
      name='Gebyr'
    />
       <Route
      path='nokkeltallintern'
      component={enhanced.NokkeltallIntern}
      name='NøkkeltallIntern'
    />
    <Route
      path='AI'
      component={enhanced.AI}
      name='AI'
    />
  </Route>
);
