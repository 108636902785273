import callApi from './api';

export function getPersoner(callback) {
  callApi('GET', '/person/', null, callback);
};

export function getPerson(id, callback) {
  callApi('GET', '/person/' + id, null, callback);
};

export function getPersonByAdOid(callback) {
  callApi('GET', '/person/adoid/', null, callback);
}

export function updatePerson(id, data, callback) {
  callApi('PUT', '/person/' + id, data, callback);
};

export function getPersonerAdmin(callback) {
  callApi('GET', '/person/administrasjon/', null, callback);
};

export function getNorkartBrukere(callback) {
  callApi('GET', '/person/administrasjon/norkartbrukere', null, callback);
};

export function deleteAdKobling(id, callback) {
  callApi('DELETE', '/person/administrasjon/deleteadkobling/' + id, null, callback);
};


export function updatePersonAdmin(id, data, callback) {
    callApi('PUT', '/person/administrasjon/' + id, data, callback);
};

export function updatePersonAdminNorkart(id, data, callback) {
  callApi('PUT', '/person/administrasjon/norkart/' + id, data, callback);
};

export function createPerson(person, callback) {
  callApi('POST', '/person/administrasjon/', person, callback);
};

export function getPersonNew(callback) {
  callApi('GET', '/person/norkart/adoid', null, callback);
};

export function getNorkartPersoner(callback) {
  callApi('GET', '/person/norkart/', null, callback);
};




