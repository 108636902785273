import React from 'react';

class className extends React.Component {
    state = { manuell: false };

    handleFraChange = (val) => {
        this.props.tilValue(val.target.value);
    }


    render() {
        return (
            <div>
                {this.state.manuell == false ? 
                <div>
                <select onChange={this.props.handleFraSelect} value={this.props.fraValue} className="width90 h30 samme-linje">
                    <option value=""></option>
                    <option value="06:00">06:00</option>
                    <option value="06:10">06:10</option>
                    <option value="06:20">06:20</option>
                    <option value="06:30">06:30</option>
                    <option value="06:40">06:40</option>
                    <option value="06:50">06:50</option>
                    <option value="07:00">07:00</option>
                    <option value="07:10">07:10</option>
                    <option value="07:20">07:20</option>
                    <option value="07:30">07:30</option>
                    <option value="07:40">07:40</option>
                    <option value="07:50">07:50</option>
                    <option value="08:00">08:00</option>
                    <option value="08:10">08:10</option>
                    <option value="08:20">08:20</option>
                    <option value="08:30">08:30</option>
                    <option value="08:40">08:40</option>
                    <option value="08:50">08:50</option>
                    <option value="09:00">09:00</option>
                    <option value="09:10">09:10</option>
                    <option value="09:20">09:20</option>
                    <option value="09:30">09:30</option>
                    <option value="09:40">09:40</option>
                    <option value="09:50">09:50</option>
                    <option value="10:00">10:00</option>
                    <option value="10:10">10:10</option>
                    <option value="10:20">10:20</option>
                    <option value="10:30">10:30</option>
                    <option value="10:40">10:40</option>
                    <option value="10:50">10:50</option>
                    <option value="11:00">11:00</option>
                    <option value="11:10">11:10</option>
                    <option value="11:20">11:20</option>
                    <option value="11:30">11:30</option>
                    <option value="11:40">11:40</option>
                    <option value="11:50">11:50</option>
                    <option value="12:00">12:00</option>
                    <option value="12:10">12:10</option>
                    <option value="12:20">12:20</option>
                    <option value="12:30">12:30</option>
                    <option value="12:40">12:40</option>
                    <option value="12:50">12:50</option>
                    <option value="13:00">13:00</option>
                    <option value="13:10">13:10</option>
                    <option value="13:20">13:20</option>
                    <option value="13:30">13:30</option>
                    <option value="13:40">13:40</option>
                    <option value="13:50">13:50</option>
                    <option value="14:00">14:00</option>
                    <option value="14:10">14:10</option>
                    <option value="14:20">14:20</option>
                    <option value="14:30">14:30</option>
                    <option value="14:40">14:40</option>
                    <option value="14:50">14:50</option>
                    <option value="15:00">15:00</option>
                    <option value="15:10">15:10</option>
                    <option value="15:20">15:20</option>
                    <option value="15:30">15:30</option>
                    <option value="15:40">15:40</option>
                    <option value="15:50">15:50</option>
                    <option value="16:00">16:00</option>
                    <option value="16:10">16:10</option>
                    <option value="16:20">16:20</option>
                    <option value="16:30">16:30</option>
                    <option value="16:40">16:40</option>
                    <option value="16:50">16:50</option>
                    <option value="17:00">17:00</option>
                    <option value="17:10">17:10</option>
                    <option value="17:20">17:20</option>
                    <option value="17:30">17:30</option>
                    <option value="17:40">17:40</option>
                    <option value="17:50">17:50</option>
                    <option value="18:00">18:00</option>
                    <option value="18:10">18:10</option>
                    <option value="18:20">18:20</option>
                    <option value="18:30">18:30</option>
                    <option value="18:40">18:40</option>
                    <option value="18:50">18:50</option>
                    <option value="19:00">19:00</option>
                    <option value="19:10">19:10</option>
                    <option value="19:20">19:20</option>
                    <option value="19:30">19:30</option>
                    <option value="19:40">19:40</option>
                    <option value="19:50">19:50</option>
                    <option value="20:00">20:00</option>
                    <option value="20:10">20:10</option>
                    <option value="20:20">20:20</option>
                    <option value="20:30">20:30</option>
                    <option value="20:40">20:40</option>
                    <option value="20:50">20:50</option>
                    <option value="21:00">21:00</option>
                    <option value="21:10">21:10</option>
                    <option value="21:20">21:20</option>
                    <option value="21:30">21:30</option>
                    <option value="21:40">21:40</option>
                    <option value="21:50">21:50</option>
                    <option value="22:00">22:00</option>
                    <option value="22:10">22:10</option>
                    <option value="22:20">22:20</option>
                    <option value="22:30">22:30</option>
                    <option value="22:40">22:40</option>
                    <option value="22:50">22:50</option>
                    <option value="23:00">23:00</option>
                    <option value="23:10">23:10</option>
                    <option value="23:20">23:20</option>
                    <option value="23:30">23:30</option>
                    <option value="23:40">23:40</option>
                    <option value="23:50">23:50</option>
                    <option value="24:00">24:00</option>
                </select>

                <label className="samme-linje">&nbsp;&nbsp;-&nbsp;&nbsp;</label>

                <select onChange={this.props.handleTilSelect} value={this.props.tilValue} className="width90 h30 samme-linje">
                    <option value=""></option>
                    <option value="06:00">06:00</option>
                    <option value="06:15">06:15</option>
                    <option value="06:30">06:30</option>
                    <option value="06:45">06:45</option>
                    <option value="07:00">07:00</option>
                    <option value="07:15">07:15</option>
                    <option value="07:30">07:30</option>
                    <option value="07:45">07:45</option>
                    <option value="08:00">08:00</option>
                    <option value="08:15">08:15</option>
                    <option value="08:30">08:30</option>
                    <option value="08:45">08:45</option>
                    <option value="09:00">09:00</option>
                    <option value="09:15">09:15</option>
                    <option value="09:30">09:30</option>
                    <option value="09:45">09:45</option>
                    <option value="10:00">10:00</option>
                    <option value="10:15">10:15</option>
                    <option value="10:30">10:30</option>
                    <option value="10:45">10:45</option>
                    <option value="11:00">11:00</option>
                    <option value="11:15">11:15</option>
                    <option value="11:30">11:30</option>
                    <option value="11:45">11:45</option>
                    <option value="12:00">12:00</option>
                    <option value="12:15">12:15</option>
                    <option value="12:30">12:30</option>
                    <option value="12:45">12:45</option>
                    <option value="13:00">13:00</option>
                    <option value="13:15">13:15</option>
                    <option value="13:30">13:30</option>
                    <option value="13:45">13:45</option>
                    <option value="14:00">14:00</option>
                    <option value="14:15">14:15</option>
                    <option value="14:30">14:30</option>
                    <option value="14:45">14:45</option>
                    <option value="15:00">15:00</option>
                    <option value="15:15">15:15</option>
                    <option value="15:30">15:30</option>
                    <option value="15:45">15:45</option>
                    <option value="16:00">16:00</option>
                    <option value="16:15">16:15</option>
                    <option value="16:30">16:30</option>
                    <option value="16:45">16:45</option>
                    <option value="17:00">17:00</option>
                    <option value="17:15">17:15</option>
                    <option value="17:30">17:30</option>
                    <option value="17:45">17:45</option>
                    <option value="18:00">18:00</option>
                    <option value="18:15">18:15</option>
                    <option value="18:30">18:30</option>
                    <option value="18:45">18:45</option>
                    <option value="19:00">19:00</option>
                    <option value="19:15">19:15</option>
                    <option value="19:30">19:30</option>
                    <option value="19:45">19:45</option>
                    <option value="20:00">20:00</option>
                    <option value="20:15">20:15</option>
                    <option value="20:30">20:30</option>
                    <option value="20:45">20:45</option>
                    <option value="21:00">21:00</option>
                    <option value="21:15">21:15</option>
                    <option value="21:30">21:30</option>
                    <option value="21:45">21:45</option>
                    <option value="22:00">22:00</option>
                    <option value="22:15">22:15</option>
                    <option value="22:30">22:30</option>
                    <option value="22:45">22:45</option>
                    <option value="23:00">23:00</option>
                    <option value="23:15">23:15</option>
                    <option value="23:30">23:30</option>
                    <option value="23:45">23:45</option>
                    <option value="24:00">24:00</option>
                </select>
            </div>
                : 
                <div>
                    <div className="width90 h30 samme-linje">
                   <input
                    type="text"
                    placeholder='TT:MM'
                    className="form-control"
                    onChange={this.props.handleFraSelect}
                    value={this.props.fraValue}
                  />
                  </div>
                  <label className="samme-linje">&nbsp;&nbsp;-&nbsp;&nbsp;</label>
                  <div className="width90 h30 samme-linje">
                   <input
                    type="text"
                    className="form-control"
                    placeholder='TT:MM'
                    onChange={this.props.handleTilSelect}
                    value={this.props.tilValue}
                  />
                  </div>
                </div>}
                
                <br/>
                <div className='row'>
                <div >
                <div className='col-sm-6'>
                  <label>Angi tid manuelt</label>
                </div>
                <div className='col-sm-6'>
                  <input
                  className='checkbox30'
                  type='checkbox'
                  checked={this.state.manuell}
                  onChange={e => {
                    this.setState({
                      manuell: !this.state.manuell
                      });
                    }}
                    />
                </div> 
                </div>
              </div>
            </div>
        );
    }
}

export default className;