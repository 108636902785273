import React from 'react';
import InfoOppdatering from './infoOppdatering.jsx';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:infosider');

class Infosider extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
       <br/><br/>
        <InfoOppdatering />
      </div>
    );
  }
}

export default Infosider;
