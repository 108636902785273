import React from 'react';
import Matrise from './Matrise.jsx';

export default class extends React.Component {
  state = { breadcrumbname: 'Laster....' };

  render() {
    return (
      <div>
        <Matrise />
      </div>
    );
  }
}
