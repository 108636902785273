import React from 'react';
import * as _ from 'lodash';
import NKModal from 'components/Dialogs/NKModal.jsx';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import NKReactSelect from 'components/Select/NKReactSelect.jsx';
import { getContextBrukere } from 'context/contextOppslag.js';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:lagredeFilters');

class LagreSokDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      navn: '',
      isCheckedAlle: true,
      brukere: [],
      selectedBrukere: [],
    };

    this.onChangeNavn = this.onChangeNavn.bind(this);
    this.onChangeTilgjengelig = this.onChangeTilgjengelig.bind(this);
    this.onSelectBrukereChange = this.onSelectBrukereChange.bind(this);
    this.onLagre = this.onLagre.bind(this);
  }

  componentDidMount() {
    getContextBrukere( brukere => {
      this.setState({ brukere: brukere.brukere });
    });  
  }

  onChangeNavn(e) {
    var value = e.target.value;
    this.setState({ navn: value });
  }

  onChangeTilgjengelig() {
    this.setState({ isCheckedAlle: !this.state.isCheckedAlle });
    this.setState({ selectedBrukere: [] });
  }

  onSelectBrukereChange(brukere) {
    this.setState({ selectedBrukere: brukere });
  }

  onLagre() {
    if (
      !this.state.navn ||
      (!this.state.isCheckedAlle && _.isEmpty(this.state.selectedBrukere))
    ) {
      return;
    }
    let SokLagretDto = {
      id: 0,
      navn: this.state.navn,
      forAlle: this.state.isCheckedAlle,
      brukerIds: _.map(this.state.selectedBrukere, (bruker) => bruker.id),
    };

    this.props.onLagreSok(SokLagretDto);
  }

  render() {
    let classNameButton =
      !this.state.navn ||
      (!this.state.isCheckedAlle && _.isEmpty(this.state.selectedBrukere))
        ? 'btn btn-primary pull-right disabled'
        : 'btn btn-primary pull-right';
    return (
      <NKModal size='medium' isOpen={this.props.isOpen}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              type='button'
              onClick={this.props.onClose}>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'> Lagre søk </h4>
          </div>
          <div>
            <div className='modal-body' style={{ margin: '20px' }}>
              <br />
              <div className='form-group '>
                <input
                  className='form-control'
                  type='text'
                  value={this.state.navn}
                  placeholder='Navn på søk'
                  onChange={this.onChangeNavn}
                />
              </div>
              <br />
              <div className='form-group '>
                <Switch
                  className='pull-left'
                  on={this.state.isCheckedAlle}
                  onClick={this.onChangeTilgjengelig}
                />
                <label className='switchLabel'>Tilgjengelig for alle</label>
              </div>
              {!this.state.isCheckedAlle && (
                <div className='form-group'>
                  <label className='switchLabel'>
                    Tilgjengelige for brukere
                  </label>
                  <NKReactSelect
                    disabled={this.state.isCheckedAlle}
                    name='selectBrukere'
                    options={this.state.brukere}
                    value={this.state.selectedBrukere}
                    onChange={this.onSelectBrukereChange}
                  />
                </div>
              )}
              <br />
            </div>
          </div>
          <div className='modal-footer'>
            <button
              type='button'
              className={classNameButton}
              onClick={this.onLagre}>
              Lagre
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default LagreSokDialog;
