import React from 'react';
import 'react-toggle-switch/dist/css/switch.min.css';
import '.././grunndata.css';
import { getAntallparagraftilknyttetapneavvik, fjernParagrafTilknyttetApneAvvik } from 'api/avvikApi.js';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
import { debugFactory } from 'utils';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';

const debug = debugFactory('nk:Paragrafer');
var _ = require('underscore');

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        tittel='Fjerne paragraf fra Åpne Avvik'
        isOpen={isOpen}
        onClose={onClose}
        melding={
          'Paragrafer som er deaktivert bør fjernes fra alle Åpne Avvik.'
        }
        melding2={'Ønsker du å fjerne denne paragrafen fra alle Åpne Avvik?'}
        onJa={owner.brukJa}
        onNei={onClose}
      />
    );
  },
};

var id = 0;
class NyEndreOppslagsdataAvvikParagraf extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleTekstChange = this.handleTekstChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.dataLagret = this.dataLagret.bind(this);
    this.objektChange = this.objektChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.state = {
      lukket: true,
      tekst: '',
      url: '',
      infoTekst: '',
      visAvkobling: false,
      btnClass: 'btn btn-primary height48'  //btn btn-primary height48 disabled
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }
  handleTekstChange(data) {
    this.setState({ tekst: data.target.value });
  }
  handleUrlChange(data) {
    this.setState({ url: data.target.value });
  }
  componentDidMount() {
    this.tekstInput.focus();
    if (this.props.nyEndre === 'Endre') {
      id = this.props.valgtItem.id;

      this.setState({
        infoTekst: 'Vent.. ',
        tekst: this.props.valgtItem.tekst,
        lukket: this.props.valgtItem.aktiv,
        lovdataId: this.props.valgtItem.lovdataId,
        url: this.props.valgtItem.url,
      });

      this.sjekkOmParagrafErIBruk(id);
    } else {
      this.setState({ lovdataId: this.props.lovdata[0].id });
    }
  }

  sjekkOmParagrafErIBruk = (id) => {
    getAntallparagraftilknyttetapneavvik(id, this.sjekkAntallDone);
  };

  sjekkAntallDone = (err, data) => {
    debug('PARA data ' + this.props.valgtItem.id, data);
    let info = '';
    if (data) {
      if (data > 0) {
        info = 'Paragrafen er i bruk i ' + data + ' Åpen Avvik.';
        this.setState({ visAvkobling: true });
      } else {
        info = '';
      }
    } 
    this.setState({ infoTekst: info });
  };

  objektChange(data) {
    this.setState({ lovdataId: data.target.value });
  }
  submit() {
    var paragraf = {
      id: id,
      tekst: this.state.tekst,
      aktiv: this.state.lukket,
      lovdataId: this.state.lovdataId,
      url: this.state.url,
    };
    if (this.props.nyEndre === 'Ny') {
      oppslagsdataApi.saveAvvikParagraf(paragraf, this.dataLagret);
    } else {
      oppslagsdataApi.updateAvvikParagraf(id, paragraf, this.dataLagret);
    }
  }
  dataLagret(err, nyEndreitem) {
    this.props.dataLagret(nyEndreitem);
  }

  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    this.setState({ utfort: lukket });
    if (this.state.lukket && this.state.visAvkobling){
       this.setState({ btnClass: 'btn btn-primary height48 disabled' });
    } 
    if (!this.state.lukket ){
      this.setState({ btnClass: 'btn btn-primary height48' });
   } 
  }

  fjernParagrafFraApneAvvik = () => {
    this.dialogs.setOpenDialog('JaNeiDialog');
  };

  brukJa = () => {
    this.dialogs.closeDialog();
    debug('PARA fjern', id);
    fjernParagrafTilknyttetApneAvvik(id, this.harBlittFjernet);
  };

  harBlittFjernet = (err, data) => {
    debug('PARA fjernet', data);
     this.setState({ infoTekst: 'Paragrafen er nå fjernet fra alle Åpne Avvik', 
     visAvkobling: false, 
     btnClass: 'btn btn-primary height48' });
  };

  render() {
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {this.props.nyEndre} oppslagsdata for: {this.props.type} -{' '}
            {this.props.tabell}
          </h4>
        </div>
        <div className='modal-body'>
          <div className='form margin-left-15 margin-right-15'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group'>
                  {!this.state.visAvkobling && (
                    <span style={{ color: 'blue' }}>
                      {this.state.infoTekst}
                    </span>
                  )}
                  {this.state.visAvkobling && (
                    <table>
                      <tbody>
                        <tr>
                        <td style={{ verticalAlign: 'bottom' }}>
                            <NKIcon
                              icon='advarsel_roed'
                              size='1'
                              color='nk-black'
                            />
                          </td>
                          <td style={{ verticalAlign: 'center' }}>
                            <span style={{ color: 'red', marginLeft: '8px', marginRight: '4px' }}>
                              {this.state.infoTekst}
                            </span>
                          </td>
                         <td style={{ verticalAlign: 'bottom' }}>
                            <button
                              type='button'
                              style={{ fontSize: '16px', paddingTop: '5px' }}
                              onClick={this.fjernParagrafFraApneAvvik}
                              className='btn btn-link'>
                              Les mer..
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  <br />
                  <input
                    className='checkbox30'
                    type='checkbox'
                    onClick={this.lukketChange}
                    checked={this.state.lukket}
                  />
                  <span className='checkboxtext18'>Aktiv</span>
                </div>
                <div className='form-group'>
                  <label>Lov</label>
                  <select
                    className='form-control'
                    id='type'
                    onChange={this.objektChange}
                    value={this.state.lovdataId}>
                    {_.map(this.props.lovdata, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='form-group'>
                  <label>Tekst</label>
                  <textarea
                    ref={(input) => {
                      this.tekstInput = input;
                    }}
                    className='form-control textareaNoresize'
                    type='textarea'
                    id='message'
                    placeholder='Tekst'
                    rows='2'
                    onChange={this.handleTekstChange}
                    value={this.state.tekst}
                    maxLength='2000'
                  />
                </div>
                <div className='form-group'>
                  <label>Url</label>
                  <input
                    className='form-control'
                    type='text'
                    id='url'
                    onChange={this.handleUrlChange}
                    value={this.state.url}
                  />
                </div>

                <div className='pull-right'>
                  <input
                    type='button'
                    className={this.state.btnClass}
                    value='OK'
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NyEndreOppslagsdataAvvikParagraf;
