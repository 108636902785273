import React from 'react';
import NKModal from './NKModal.jsx';
import { PropTypes } from 'prop-types';

//Sett melding for rein tekst, eller children for html

class OkDialog extends React.Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired
    };

    state = { isOpen: true };

    render() {

        return (
            <NKModal size='medium' isOpen={this.props.isOpen}   >
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" onClick={this.props.onClose} type="button">
                            <span>x</span>
                            <span className="sr-only">Lukk</span>
                        </button>
                        <h4 className="modal-title">{this.props.tittel}</h4>
                    </div>
                    <div className="modal-body"
                        style={{ marginLeft: '10px', marginRight: '10px'}}>
                        <div>
                            {this.props.children}
                            <p style={{ margin: '4px' }}>{this.props.melding}</p>
                            <p style={{ margin: '4px' }}>{this.props.melding2}</p>
                            <p style={{ margin: '4px' }}>{this.props.melding3}</p>
                        </div>
                    </div>
                    { this.props.onOk ?
                    <div className="modal-footer">
                        <button style={{ margin: '2px', height: '48px', paddingLeft: '30px', paddingRight: '30px' }} className="btn btn-primary pull-right" onClick={this.props.onOk}>OK</button>
                    </div> : ''}
                </div>
            </NKModal>
        );
    }
}

export default OkDialog;