/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import * as _ from 'lodash';
import SmsMalTable from './SmsMalTable.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import ReactModal from 'react-modal';
import DialogStore from 'lib/DialogStore';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import { getSmsMaler, getMalerOppslagsdata, slettSmsMal } from 'api/malerApi';
import './mal.css';
import SmsMalDialog from './SmsMalDialog.jsx';
import NySmsVarselDialog from './NySmsVarselDialog.jsx';
import { Button } from 'components/generics/Button';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  MalDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className='malModalMedium'>
      <SmsMalDialog
        onClose={onClose}
        onOppdatert={owner.oppdaterListe}
        mal={dialogData.mal}
        modus='Vanlig'
        gjelderKode={dialogData.gjelderKode}
        new={dialogData.new}
        checkTiltakMaler={dialogData.checkTiltakMaler}
        flettefelt={dialogData.flettefelt}></SmsMalDialog>
    </ReactModal>
  ),
  SletteDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Slette?'}
        melding={dialogData.melding}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
  NyVarselDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal contentLabel='' isOpen={isOpen} className='malModalLiten400'>
        <NySmsVarselDialog
          onGenererMalFraDialog={owner.onGenererMalFraDialog}
          onClose={onClose}
          liste={dialogData.liste}
        />
      </ReactModal>
    );
  },
};

class Sms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smsMaler: [],
      varselMaler: [],
      planleggingsMaler: [],
      gjennomMaler: [],
      oppfolgingMaler: [],
      fellesMaler: [],
      funksjonslisteP: [],
      tiltakTypeListeP: [],
      funksjonslisteG: [],
      tiltakTypeListeG: [],
      funksjonslisteO: [],
      tiltakTypeListeO: [],
      funksjonslisteF: [],
      tiltakTypeListeF: [],
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    this.hentDataSms(true);
  };

  stripTekst = (tekst) => {
    tekst = this.goReplace(tekst, '<h2>', '');
    tekst = this.goReplace(tekst, '</h2>', '');
    tekst = this.goReplace(tekst, '<ul>', '');
    tekst = this.goReplace(tekst, '</ul>', '');
    tekst = this.goReplace(tekst, '<strong>', '');
    tekst = this.goReplace(tekst, '</strong>', '');
    tekst = this.goReplace(tekst, '<li>', ' -');
    tekst = this.goReplace(tekst, '</li>', '');
    tekst = this.goReplace(tekst, '<p>', '');
    tekst = this.goReplace(tekst, '</p>', '\n');
    tekst = this.goReplace(tekst, '<br>', '\n');
    tekst = this.goReplace(tekst, '<br/>', '\n');
    tekst = this.goReplace(tekst, '<br />', '');
    tekst = this.goReplace(tekst, '\n\n', '\n');
    tekst = this.goReplace(tekst, '&nbsp;', ' ');
    tekst = tekst.trim();
    return tekst;
  };

  stripListe = (liste) => {
    for (let i = 0; i < liste.length; i++) {
      let tekst = liste[i].tekst;
      if (tekst !== '') {
        liste[i].striptekst = this.stripTekst(tekst);
      }
    }
    return liste;
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replace(new RegExp(find, 'g'), repl);
    return str;
  };

  hentDataSms = (pluss) => {
    getSmsMaler((err, data) => {
      // eslint-disable-next-line eqeqeq

      let liste = this.stripListe(data);
      this.setState({ smsMaler: liste });
      
      let planleggingsMaler = _.filter(liste, { gjelderKode: 'P' });
      this.setState({ planleggingsMaler });
      let gjennomMaler = _.filter(liste, { gjelderKode: 'G' });
      this.setState({ gjennomMaler });
      let oppfolgingMaler = _.filter(liste, { gjelderKode: 'O' });
      this.setState({ oppfolgingMaler });
      let fellesMaler = _.filter(liste, { gjelderKode: 'F' });
      this.setState({ fellesMaler });
    });
    if (pluss) {
      getMalerOppslagsdata('P', 'S', this.gotOppslagDataP);
      getMalerOppslagsdata('G', 'S', this.gotOppslagDataG);
      getMalerOppslagsdata('O', 'S', this.gotOppslagDataO);
      getMalerOppslagsdata('F', 'S', this.gotOppslagDataF);
    }
  };

  gotOppslagDataP = (err, data) => {
    this.setState({
      //flettefelt: data.funksjonListe[0].flettefelt,
      funksjonslisteP: data.funksjonListe,
      tiltakTypeListeP: data.tiltakTypeListe,
    });
  };

  gotOppslagDataG = (err, data) => {
    this.setState({
      //flettefelt: data.funksjonListe[0].flettefelt,
      funksjonslisteG: data.funksjonListe,
      tiltakTypeListeG: data.tiltakTypeListe,
    });
  };

  gotOppslagDataO = (err, data) => {
    this.setState({
      //flettefelt: data.funksjonListe[0].flettefelt,
      funksjonslisteO: data.funksjonListe,
      tiltakTypeListeO: data.tiltakTypeListe,
    });
  };

  gotOppslagDataF = (err, data) => {
    this.setState({
      //flettefelt: data.funksjonListe[0].flettefelt,
      funksjonslisteF: data.funksjonListe,
      tiltakTypeListeF: data.tiltakTypeListe,
    });
  };

  makeAlleVarselMaler = () => {
    let liste = _.filter(this.state.planleggingsMaler, {
      funksjon: 'Varsel',
      standardMal: false,
    });
    return liste;
  };

  onRowClickP = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteP, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'P',
      checkTiltakMaler: this.makeAlleVarselMaler(),
      mal: item,
      flettefelt: flettefelt,
      new: false,
    });
  };

  onRowClickG = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteG, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'G',
      checkTiltakMaler: this.state.gjennomMaler,
      mal: item,
      flettefelt: flettefelt,
      new: false,
    });
  };

  onRowClickO = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteO, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'O',
      checkTiltakMaler: [],
      mal: item,
      flettefelt: flettefelt,
      new: false,
    });
  };

  onRowClickF = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteF, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'F',
      checkTiltakMaler: [],
      mal: item,
      flettefelt: flettefelt,
      new: false,
    });
  };

  slettMal = (item) => {
    this.dialogs.setOpenDialog('SletteDialog', {
      melding: 'Ønsker du å slette malen "' + item.tittel + '"',
      onJa: () => {
        slettSmsMal(item.id, this.oppdaterListe);
      },
    });
  };

  nyMalDialogVarsel = () => {
    let liste = _.filter(this.state.smsMaler, { funksjon: 'Varsel' });
    //  let liste=  this.state.smsMaler;
    this.dialogs.setOpenDialog('NyVarselDialog', {
      liste: liste,
    });
  };

  nyMalDialogGjennomforing = () => {
    let liste = _.filter(this.state.gjennomMaler);
    this.dialogs.setOpenDialog('NyVarselDialog', {
      liste: liste,
    });
  };

  onGenererMalFraDialog = (mal) => {
    this.dialogs.closeDialog();
    if (mal.gjelderKode === 'P'){
      this.genererVarselMal(mal);
    };
    if (mal.gjelderKode === 'G'){
      this.genererGjennomforingMal(mal);
    };
    
  };

  genererNyMalFraMeny = (mal) => {
   switch(mal.gjelderKode) {
     case 'P':
      this.genererVarselMal(mal);
       break;
     case 'G':
      this.genererGjennomforingMal(mal);
       break;
       case 'O':
        this.genererOppfolgingMal(mal);
       break;
     default:
       
    }
  };


  genererVarselMal = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteP, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }
  let nyMal =  _.cloneDeep(item);
  nyMal.standardMal = false;
  delete nyMal['id'];
  nyMal.beskrivelse = nyMal.beskrivelse + '...';
  nyMal.tittel = nyMal.tittel + '...';
  nyMal.aktiv = true;  
  nyMal.gjelderKode = 'P';
  

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'P',
      checkTiltakMaler: this.makeAlleVarselMaler(),
      mal: nyMal,
      flettefelt: flettefelt,
      new: true,
    });
  };

  genererGjennomforingMal = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteG, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }
  let nyMal =  _.cloneDeep(item);
  nyMal.standardMal = false;
  delete nyMal['id'];
  nyMal.beskrivelse = nyMal.beskrivelse + '...';
  nyMal.tittel = nyMal.tittel + '...';
  nyMal.aktiv = true;  
  nyMal.gjelderKode = 'G';
  
  const checkTiltakMalerFiltered = this.state.gjennomMaler.filter(item => item.funksjonId.toString() === funksjonId.toString()); 

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'G',
      checkTiltakMaler: checkTiltakMalerFiltered,
      //this.state.gjennomMaler,
      mal: nyMal,
      flettefelt: flettefelt,
      new: true,
    });
  };

  genererOppfolgingMal = (item) => {
    let funksjonId = item.funksjonId;
    let funksjon = _.find(this.state.funksjonslisteO, { id: funksjonId });
    let flettefelt = [];
    if (funksjon) {
      flettefelt = funksjon.flettefelt;
    }
  let nyMal =  _.cloneDeep(item);
  nyMal.standardMal = false;
  delete nyMal['id'];
  nyMal.beskrivelse = nyMal.beskrivelse + '...';
  nyMal.tittel = nyMal.tittel + '...';
  nyMal.aktiv = true;  
  nyMal.gjelderKode = 'O';

    this.dialogs.setOpenDialog('MalDialog', {
      gjelderKode: 'O',
      checkTiltakMaler: [],
      mal: nyMal,
      flettefelt: flettefelt,
      new: true,
    });
  };

  oppdaterListe = () => {
    this.hentDataSms(false);
  };

  render() {
    return (
      <div>
        <h3>SMS-maler</h3>
        <div className='panel panel-default'>
          <ul className='nav nav-tabs'>
            <li className='active'>
              <a data-toggle='tab' href='#plantab'>
                Planlegging
              </a>
            </li>
           <li>
              <a data-toggle='tab' href='#gjentab'>
                Gjennomføring
              </a>
            </li>
            <li>
              <a data-toggle='tab' href='#oppftab'>
                Oppfølging
              </a>
            </li>
            <li>
              <a data-toggle='tab' href='#fellestab'>
                Generelle funksjoner
              </a>
            </li>
          </ul>
          <div className='panel-body'>
            <div className='tab-content'>
              <div id='plantab' className='tab-pane fade in active'>  
                <Button icon='pluss' onClick={() => this.nyMalDialogVarsel()}>
                  Ny mal for Varsel
                </Button>
            
                <br /> <br />
                <SmsMalTable
                  gjelderKode='P'
                  items={this.state.planleggingsMaler}
                  slettMal={this.slettMal}
                  genererNyMalFraMeny={this.genererNyMalFraMeny}
                  onRowClick={this.onRowClickP}></SmsMalTable>
              </div>
              <div id='gjentab' className='tab-pane fade'>  
              <Button icon='pluss' onClick={() => this.nyMalDialogGjennomforing()}>
                  Ny mal for Gjennomføring
                </Button>
            
                <br /> <br />
                <SmsMalTable
                  gjelderKode='G'
                  items={this.state.gjennomMaler}
                  slettMal={this.slettMal}
                  genererNyMalFraMeny={this.genererNyMalFraMeny}
                  onRowClick={this.onRowClickG}></SmsMalTable>
              </div>  
              <div id='oppftab' className='tab-pane fade'>  
                <SmsMalTable
                  gjelderKode='O'
                  items={this.state.oppfolgingMaler}
                  slettMal={this.slettMal}
                  genererNyMalFraMeny={this.genererNyMalFraMeny}
                  onRowClick={this.onRowClickO}></SmsMalTable>
              </div>  
              <div id='fellestab' className='tab-pane fade'>  
                <SmsMalTable
                  gjelderKode='F'
                  items={this.state.fellesMaler}
                  slettMal={this.slettMal}
                  genererNyMalFraMeny={this.genererNyMalFraMeny}
                  onRowClick={this.onRowClickF}></SmsMalTable>
              </div>  
            </div>
          </div>
        </div>
        {/*   </div> */}

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Sms;
