import React, { useState, useEffect, useImperativeHandle } from 'react';
import * as blobApi from 'api/storageblobApi';
import './Bilder.css';
import NKIcon from 'nka-icons';
import OKDialog from 'components/Dialogs/OkDialog.jsx';


const Bilder = React.forwardRef(({ ...props }, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [visThumpnails, setVisThumpnails] = useState(true);
  const [bilder, setBilder] = useState([]);
  const [sistVist, setSistVist] = useState(0);
  const [src, setSrc] = useState('');
  const [orientation, setOrientation] = useState('stor north');
  const [modalKnuppOpen, setModalKnuppOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    //
  }));

  useEffect(() => {
    hentThumbnails();
  }, [props.filer]);

  useEffect(() => {
    setOrientation('stor north');
  }, [sistVist]);

  const hentThumbnails = () => {
    setBilder([]);
    if (!props.filer) {
      setIsLoading(false);
      return;
    }
    let filer = [];
    for (let i = 0; i < props.filer.length; i++) {
      let filnavn = props.filer[i].filnavn;
      let fil = { filnavn: filnavn };
      if (!props.filer[i].slett) {
        filer.push(fil);
      }
    }
    if (filer.length > 0) {
      getBildeFraBlobStorage(filer);
    } else {
      setIsLoading(false);
    }
  };

  const getBildeFraBlobStorage = (filer) => {
    blobApi.hentthumbnails(filer, thumpnailsHentet);
  };

  const thumpnailsHentet = (err, thumbs) => {
    let templiste = [];

    if (thumbs !== undefined) {
      for (let i = 0; i < thumbs.length; i++) {
        let bilde = thumbs[i];
        let bildeObj = {
          id: bilde.filnavn,
          status: 'old',
          orientation: 0,
          base64: bilde.filData,
        };
        templiste.push(bildeObj);
      }
      setBilder(templiste);
    }
    setIsLoading(false);
  };

  const storfilHentet = (err, foto) => {
    let src = 'data:image/jpg;base64,' + foto.filData;
    setSrc(src);
    setVisThumpnails(false);
  };

  const visBilde = (nr) => {
    let bilde = bilder[nr];
    let fil = { filnavn: bilde.id };
    blobApi.hentfil(fil, storfilHentet);
    setSistVist(nr);
  };

  const forrigeBilde = () => {
    let nr = sistVist - 1;
    if (nr < 0) {
      nr = bilder.length - 1;
    }
    visBilde(nr);
  };

  const nesteBilde = () => {
    let nr = sistVist + 1;
    if (nr > bilder.length - 1) {
      nr = 0;
    }
    visBilde(nr);
  };

  const roterBilde = () => {
    let stor = orientation;
    switch (stor) {
      case 'stor north':
        stor = 'stor west';
        break;
      case 'stor west':
        stor = 'stor south';
        break;
      case 'stor south':
        stor = 'stor east';
        break;
      case 'stor east':
        stor = 'stor north';
        break;
      default:
        stor = 'stor north';
    }
    setOrientation(stor);
  };

  const skjulBilde = () => {
    setVisThumpnails(true);
  };

  const slettBilde = () => {
    let b = bilder[sistVist];

    setVisThumpnails(true);
    props.afterSlett(b);
    setVisThumpnails(true);
  };

  const onBildeChange = (e) => {
    var file = e.target.files[0];
    var fileSize = file.size;
    var extension = file.name
      .substr(file.name.lastIndexOf('.') + 1)
      .toLowerCase();

    if (fileSize > 1000000) {
      if (extension === 'jpg' || extension === 'jpeg') {
        komprimerBilde(e.target.files[0], 800, Infinity, 0.6);
      } else {
        alert('Feil filformat. Velg .jpg/.jpeg');
      }
    } else {
      if (extension === 'jpg' || extension === 'jpeg') {
        setIsLoading(true);
        getOrientation(file, function(orientation) {
          uploadFileToServer(file, orientation);
        });
      } else {
        alert('Feil filformat. Velg .jpg/.jpeg');
      }
    }
  };

  const bildeTilCanvas = (img, rawWidth, rawHeight, orientation) => {
    var canvas = document.createElement('canvas');
    if (orientation > 4) {
      canvas.width = rawHeight;
      canvas.height = rawWidth;
    } else {
      canvas.width = rawWidth;
      canvas.height = rawHeight;
    }

    var ctx = canvas.getContext('2d');
    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, rawWidth, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, rawHeight);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, rawHeight, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, rawHeight, rawWidth);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, rawWidth);
        break;
      default:
        ctx.transform(-1, 0, 0, 1, rawWidth, 0);
    }
    ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
    return canvas;
  };

  const komprimerBilde = (file, maxWidth, maxHeight, quality) => {
    var img = new Image();

    img.onload = function() {
      URL.revokeObjectURL(this.src);
      getOrientation(file, function(orientation) {
        var w = img.width,
          h = img.height;
        var skaler =
          orientation > 4
            ? Math.min(maxHeight / w, maxWidth / h, 1)
            : Math.min(maxWidth / w, maxHeight / h, 1);
        h = Math.round(h * skaler);
        w = Math.round(w * skaler);

        var canvas = bildeTilCanvas(img, w, h, orientation);
        var filename = file.name;
        canvas.toBlob(
          function(file) {
            blobToFile(file, filename);
            uploadFileToServer(file, orientation);
          },
          'image/jpeg',
          quality
        );
      });
    };

    img.src = URL.createObjectURL(file);
  };

  const blobToFile = (file, filename) => {
    file.lastModifiedDate = new Date();
    file.name = filename;
    return file;
  };

  const getOrientation = (file, callback) => {
    var reader = new FileReader();

    reader.onload = function(event) {
      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2);
      }

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  };

  const uploadFileToServer = (file, orientation) => {
    var reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function() {
      let b64 = btoa(reader.result);
      let b64Obj = {
        base64: b64,
        id: bilder.length + 1,
        status: 'new',
        orientation: orientation,
      };

      uploadFil(b64Obj, file.name);
    };
  };

  const uploadFil = (filObjekt, navn) => {
    let objekt = {
      filnavn: navn,
      filData: filObjekt.base64,
    };
    blobApi.lagrefilmedthumbnail(objekt, bildeUploaded);
  };

  const bildeUploaded = (err, bilde) => {
    props.afterUpload(bilde);
    /*     let tempListe = this.state.bildeliste;
    let obj = tempListe[tempListe.length - 1];
    obj.id = bilde.tekst;
    this.props.onlisteChange(bilde.tekst);
    this.setState({ bildeliste: tempListe });
    this.setState({ hideProsessing: ' hide' }); */
  };

  const getLabelShroud = {
    height: '40px',
    width: '180px',
    position: 'absolute',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
    marginTop: '10px',
    backgroundColor: 'transparent',
  };

  return (
    <div style={{ margin: '8px', paddingLeft: '10px', paddingTop: '10px' }}>
      {isLoading ? (
        <>
          <div style={{ padding: '20px' }}>Vent.. laster bilder..</div>
        </>
      ) : (
        <>
          <div
            style={{
              marginTop: '8px',
              width: '720px',
            }}>
            {visThumpnails && (
              <span className='pull-right'>
                <label
                  htmlFor='tabilde'
                  style={getLabelShroud}
                  className='bildeButton'></label>

                <button
                  className='bildeButton'
                  style={{
                    margin: '0px',
                    marginTop: '10px',
                    height: '40px',
                    width: '180px',
                  }}>
                  <NKIcon
                    style={{ marginLeft: '6px' }}
                    className='pull-left'
                    icon='kamera'
                    size='1'
                    color='nk-black'
                  />
                  Nytt bilde
                </button>

                <div className='hide'>
                  <input
                    hide
                    type='file'
                    accept='image/jpg, image/jpeg'
                    name='tabilde'
                    onChange={onBildeChange}
                    id='tabilde'
                  />
                </div>
              </span>
            )}
          </div>

          <br />

          {bilder.length === 0 ? (
            <div style={{ margin: '20px' }}>Ingen bilder</div>
          ) : (
            <div style={{ marginTop: '18px' }}>
              <label>{props.avvikType}</label>
            </div>
          )}
          <br />
          {visThumpnails &&
            bilder.map((foto, index) => {
              var spId = 'sp' + index;
              var iId = 'i' + index;

              return (
                <span key={spId} id={spId}>
                  <img
                    key={iId}
                    alt=''
                    className={'thumbbilde rotate' + foto.orientation}
                    src={'data:image/jpg;base64,' + foto.base64}
                    onClick={() => visBilde(index)}
                  />
                </span>
              );
            })}
          {!visThumpnails && (
            <>
              <div>
                <button onClick={forrigeBilde} className='btn btn-default w110'>
                  <NKIcon
                    className='pull-left'
                    icon='venstrepil'
                    color='nk-black'
                  />
                  Forrige
                </button>
                <button onClick={nesteBilde} className='btn btn-default w110 '>
                  <NKIcon
                    className='pull-left'
                    icon='hoyrepil'
                    color='nk-black'
                  />
                  Neste
                </button>
                <button
                  type='button'
                  className='btn btn-default w110'
                  onClick={roterBilde}>
                  <NKIcon
                    className='pull-left'
                    icon='rotasjon'
                    color='nk-black'
                  />
                  Roter
                </button>
                <button onClick={skjulBilde} className='btn btn-default w110'>
                  <NKIcon className='pull-left' icon='liste' color='nk-black' />
                  Vis liste
                </button>
                <button onClick={slettBilde} className='btn btn-default w110'>
                  <NKIcon
                    className='pull-left'
                    icon='soppelbotte'
                    color='nk-black'
                  />
                  Slett
                </button>
              </div>
              <br />
              <br />
              <div className='stordiv'>
                <img
                  className={orientation}
                  alt=''
                  style={{ maxWidth: '700px' }}
                  src={src}
                />
              </div>
            </>
          )}
        </>
      )}

      {/* *************************** Dialoger *********************** */}

      <OKDialog
        tittel={'Hei og ho'}
        melding={'Du er en knupp!'}
        isOpen={modalKnuppOpen}
        onClose={() => setModalKnuppOpen(false)}
        onOk={() => setModalKnuppOpen(false)}
      />
      {/* *************************** END Dialoger *********************** */}
    </div>
  );
});
export default Bilder;
