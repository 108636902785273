import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import * as avvikApi from 'api/avvikApi';
import NKIcon from 'nka-icons';
import './AvvikAnmerkninger.css';
import AvvikForm from 'modules/felles/avvikAnmerkninger/AvvikForm.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:Avvik');

const modalDialogs = {
  AvvikForm: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen}>
        <AvvikForm
          onClose={onClose}
          steng={dialogData.steng}
          tittel={dialogData.tittel}
          objektReferanseType={dialogData.objektReferanseType}
          adresse={dialogData.adresse}
          objektReferanseId={dialogData.objektReferanseId}
          bruksenhetid={dialogData.bruksenhetid}
          onLagred={owner.onLagred}
          id={dialogData.id}
          emne={dialogData.emne}
          avvikTekst={dialogData.avvikTekst}
          identi={dialogData.identi}
        />
      </NKModal>
    );
  },
};

class ListeMedAvvik extends React.Component {
  componentDidMount() {
  
  };

  formatDato(datostring) {
    let date = new Date(datostring);
    var dateString =
      ('0' + date.getDate()).slice(-2) +
      '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '.' +
      date.getFullYear();
    return dateString;
  }
  getLukket(lukket) {
    let res = 'Lukket';
    if (!lukket) {
      res = 'Åpen';
    }
    return res;
  }

  render() {
    if (!this.props.liste) {
      return <div>Ingen avvik registrert..</div>;
    }

    return (
      <div className='avv-scroller w500'>
        {this.props.liste.map(function(avvik) {
              debug('FREDAG', avvik);
          var dId = 'd' + avvik.id.toString();
          var bId = 'b' + avvik.id.toString();
          var beId = 'be' + avvik.id.toString();
          var lukketClass = 'malboksred ';
          var lukketIcon = 'ikke-valgt';
          var avvikTekst = avvik.avvikType;
          if (avvik.lukket) {
            lukketClass = 'malboksgreen';
            lukketIcon = 'valgt';
          }
          var buttonClass = 'btn btn-default ';
          if (avvik.sidemannsKontroll) {
            buttonClass = 'btn btn-info ';
          }

          return (
            <div key={dId} className='pad2'>
              <button
                key={bId}
                className={buttonClass + ' ' + lukketClass}
                onClick={this.props.onVelgRad.bind(null, avvik.id, avvikTekst)}>
                <table className='btntable'>
                  <tbody>
                    <tr>
                      <td className='btntd0'>
                        <NKIcon icon={lukketIcon} color='nk-black' />
                      </td>
                      <td className='btntd1'>{this.formatDato(avvik.dato)}</td>
                      {/* <td className="btntd2">{avvik.status}</td> */}
                    </tr>
                  </tbody>
                </table>
                <p key={beId} className='textleft'>
                  {avvik.avvikType}
                </p>
               
              </button>
            </div>
          );
        }, this)}
      </div>
    );
  }
}

export default class AvvikListe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      avviker: [],
      avvikerCount: 0,
      valgtId: '0',
      alle: 'active',
    };
    this.getAvvik();
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  getAvvik() {
    var ttype = this.props.objektReferanseType;

    if (ttype === 'B') {
      avvikApi.getAvvikByBruksenhetId(
        this.props.bruksenhetid,
        this.gotAvvik.bind(this)
      );
    }
    if (ttype === 'R') {
      avvikApi.getAvvikByRoyklopId(
        this.props.royklopid,
        this.gotAvvik.bind(this)
      );
    }
    if (ttype === 'I') {
      avvikApi.getAvvikByIldstedId(
        this.props.ildstedid,
        this.gotAvvik.bind(this)
      );
    }
  }

  gotAvvik(err, avviker) {
    this.setState({ avviker: avviker });
    if (avviker === null || avviker === undefined) {
      this.setState({ avvikerCount: 0 });
    } else {
      this.setState({ avvikerCount: avviker.length });
    }
  }

  onLagred = () => {
    this.setState({ alle: 'active' });
    this.getAvvik();
  };

  openAvvik() {
    this.dialogs.setOpenDialog('AvvikForm', {
      objektReferanseType: this.props.objektReferanseType,
      objektReferanseId: this.props.objektReferanseId,
      bruksenhetid: this.props.bruksenhetid,
      emne: this.props.emne,
      adresse: this.props.adresse,
      identi: this.props.identi,
      id: this.state.valgtId,
      avvikTekst: this.state.avvikTekst,
      steng: this.props.onClose,
    });
  }

  radValgt(id, avvikTekst) {
    this.setState(
      {
        valgtId: id.toString(),
        avvikTekst: avvikTekst,
      },
      this.openAvvik
    );
  }

  nyAvvik() {
    this.setState({ valgtId: '0' }, this.openAvvik);
  }

  render() {
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {' '}
            {this.state.avvikerCount + ' avvik ' + this.props.adresse}
          </h4>
        </div>
        <h4 className='m6'>{/* {this.props.info} */}</h4>
        <div className='modal-body'>
          <button
            id='btnNew'
            className='btn btn-default h48'
            style={{ marginLeft: '6px' }}
            onClick={this.nyAvvik.bind(this)}>
            <span className='pull-left'>
              <NKIcon icon='pluss' size='1' color='nk-black' />
            </span>
            <span className='font16' style={{ marginLeft: '5px' }}>
              Nytt avvik
            </span>
          </button>
          <br />
          <br />

          <ListeMedAvvik
            liste={this.state.avviker}
            onVelgRad={this.radValgt.bind(this)}
          />
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
