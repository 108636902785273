import callApi from './api';
import { debugFactory, callbackSpy } from 'utils';

const debug = debugFactory('nk:api:organisasjonsapi');

export function getOrganisasjoner(callback) {
  callApi('GET', '/organisasjonnk/administrasjon/norkart/', null, callback);
}

//oss
export function getOrganisasjonNK(id, callback) {
  callApi(
    'GET',
    '/organisasjonnk/administrasjon/norkart/' + id,
    null,
    callback
  );
}

//pålogget
export function getOrganisasjon(callback) {
  callApi('GET', '/organisasjon/administrasjon/', null, callback);
}

export function getSimpleOrganisasjon(callback) {
  callApi('GET', '/organisasjon/', null, callback);
}

export function getOrganisasjonSmsSkarp(callback) {
  callApi('GET', '/organisasjon/smsskarp/', null, callback);
}

export function getOrganisasjonSmsToveis(callback) {
  callApi('GET', '/organisasjon/smstoveis/', null, callback);
}

export function getOrganisasjonArkivSkjerming(callback) {
  callApi('GET', '/organisasjon/arkivskjerming/', null, callback);
}

export function getOrganisasjonLokasjon(callback) {
  callApi('GET', '/organisasjon/lokasjon/', null, callback);
}

export function getMatrikkelUrl(callback) {
  callback = callbackSpy(callback, debug, 'getMatrikkelUrl');
  callApi('GET', '/organisasjon/kategorimatrikkelurl', null, callback);
}

export function updateOrganisasjonNk(id, organisasjon, callback) {
  callApi(
    'PUT',
    '/organisasjonnk/administrasjon/norkart/' + id,
    organisasjon,
    callback
  );
}

export function updateOrganisasjon(organisasjon, callback) {
  callApi(
    'PUT',
    '/organisasjon/administrasjonoppdater/',
    organisasjon,
    callback
  );
}

export function createOrganisasjon(organisasjon, callback) {
  callApi(
    'POST',
    '/organisasjonnk/administrasjon/norkart/',
    organisasjon,
    callback
  );
}

export function getOrganisasjonKategorier(callback) {
  callApi('GET', '/organisasjonnk/administrasjon/kategori', null, callback);
}

export function getOrganisasjonRisikovurdering(callback) {
  callApi('GET', '/organisasjon/risikovurdering', null, callback);
}

export function getOrganisasjonSvarUt(callback) {
  callApi('GET', '/organisasjon/svarut', null, callback);
}

export function getOrganisasjonGebyrkobling(callback) {
  callApi('GET', '/organisasjon/gebyrkobling', null, callback);
}

export function getOrganisasjonArkivInfo(callback) {
  callApi('GET', '/organisasjon/arkivinfo', null, callback);
}

export function getOrganisasjonFont(callback) {
  callApi('GET', '/organisasjon/font', null, callback);
}

export function getOrganisasjonMalinfo(callback) {
  callApi('GET', '/organisasjon/malinfo', null, callback);
}

export function getOrganisasjonDokumentKontroll(callback) {
  callApi('GET', '/organisasjon/dokumentkontroll', null, callback);
}

export function getLoggprosessbyuser(callback) {
  callApi('GET', '/organisasjon/loggprosess/byuser', null, callback);
}

export function getLoggprosessAntallByUser(callback) {
  callApi('GET', '/organisasjon/loggprosess/antallbyuser', null, callback);
}

export function getLoggprosessSiste200ForAlle(callback) {
  callApi('GET', '/organisasjon/loggprosess/last200', null, callback);
}

export function getLoggprosessForBruksenhet(brId, callback) {
  callApi(
    'GET',
    '/organisasjon/loggprosess/bybruksenhet/' + brId,
    null,
    callback
  );
}

export function putLoggprosessOppdaterNotification(id, callback) {
  callApi(
    'PUT',
    '/organisasjon/loggprosess/notificationfalse/' + id,
    null,
    callback
  );
}

/* export function getOrganisasjonOppfolgingavvikbruksenhet(callback) {  
  callApi('GET', '/organisasjon/oppfolgingavvikbruksenhet', null, callback);
};  */
