import React from 'react';
import _ from 'underscore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { LinkLabel } from 'components/LinkList';
import ReactTable from '@norkart/react-table';
import { getArbeidslisteBruksenhet } from 'api/arbeidslisteApi.js';

class ArbeidslisteInfoModal extends React.Component {
  constructor() {
    super();
    this.state = {
      arbeidslister: []
    };
    
  }

  componentDidMount() {
    this.hentData(this.props.id);
  }

  hentData(id) {
    getArbeidslisteBruksenhet( id, this.gotArbeidslister.bind(this));
  }

  gotArbeidslister(err, arbeidslister) {
    this.setState({ arbeidslister: arbeidslister });
  }

  getColumns() {
    return  [
      {
        id: 'navn',
        name: 'Navn',
        sortParams: 'navn',
        isSorted: true,
        formatter: function(item) {
          return (
            <LinkLabel
            //  onClick={this.props.onClose}
              title={item.navn}
              path={'/planlegging/' + item.id}
            />
          );
        }
      },
      {
        id: 'ansvarlig',
        name: 'Ansvarlig',
        sortParams: 'ansvarlig',
        filterable: false,
        isSorted: false
      },
      {
        id: 'datofra',
        name: 'Plan.fra',
        sortParams: 'planlagtFraDato',
        filterable: false,
        sortable: true,
        formatter: function(item) {
          if (item.planlagtFraDato === null) {
            return '';
          } else {
            var dato = new Date(item.planlagtFraDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        }
      },
      {
        id: 'datotil',
        name: 'Plan.til',
        sortParams: 'planlagtTilDato',
        filterable: false,
        formatter: function(item) {
          if (item.planlagtTilDato === null) {
            return '';
          } else {
            var dato = new Date(item.planlagtTilDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        }
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: false,
        isSorted: false,
        sortable: false,
        hideInVertical: false,
        formatter: function(item) {
          if (item.tiltakBruksenhet === null && item.tiltakRoyklop === null) {
            return '';
          }
          if (item.tiltakRoyklop === null) {
            return item.tiltakBruksenhet;
          }
          if (item.tiltakBruksenhet === null) {
            return item.tiltakRoyklop;
          } else {
            return item.tiltakBruksenhet + ', ' + item.tiltakRoyklop;
          }
        }
      }
    ];
  }

  
  render() {
    if (!this.props.isOpen) {
      return <div />;
    }

    if (this.state.arbeidslister === []) {
      return <div>Lader..</div>;
    }

    return (
      <NKModal size="large-wide" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header modal-header-info">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Vis arbeidsliste for bruksenhet</h4>
          </div>
          <div className="modal-body" style={{ maxHeight: '500px' }}>
            <h3>{this.props.adresse}</h3>
            <div>Klikk på listenavn for å gå direkte til listen</div>
            <div style={{ height: '400px', overflow: 'auto', overflowX: 'hidden' }}>
              <ReactTable
                items={this.state.arbeidslister}
                showIndex={false}
                filterable={false}
                columns={this.getColumns()}
                selectableRows={false}
                responsive={true}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              style={{ margin: '2px', width: '80px', height: '40px' }}
              className="btn btn-primary pull-right"
              onClick={this.props.onClose}>
              Lukk
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}
export default ArbeidslisteInfoModal;
