import React, { Component } from 'react';
import L from 'leaflet';
import { Map } from 'react-leaflet';
import 'components/Dialogs/NKFullModal.css';
import './KartEnheter.css';
import 'leaflet.fullscreen';
import $ from 'jquery';
import 'leaflet-minimap';
import './spretter2.js';
import * as getIcon from './geticon.js';
import 'leaflet-minimap/src/Control.MiniMap.css';
import ZoomControl from 'components/Kart/Controls/ZoomControl.jsx';
import FullscreenControl from 'components/Kart/Controls/FullscreenControl.jsx';
import LocateControl from 'components/Kart/Controls/LocateControl.jsx';
/* import HitsControl from 'components/Kart/Controls/HitsControl.jsx'; */
import LayerControl from 'components/Kart/Controls/LayerControl.jsx';
import StartpunktControl from 'components/Kart/Controls/StartpunktControl.jsx';
import MarkerClusterLayerControl from 'components/Kart/Controls/MarkerClusterLayerControl.jsx';
import GjMarkerClusterLayerControl from 'components/Kart/Controls/GjMarkerClusterLayerControl.jsx';
import RegionerControl from 'components/Kart/Controls/RegionerControl.jsx';
import OppmaalingControl from 'components/Kart/Controls/OppmaalingControl.jsx';
import { debugFactory } from 'utils';
import {  getContextInnstillingerKartNew } from 'context/contextInnstillinger.js';
delete L.Icon.Default.prototype._getIconUrl;

const debug = debugFactory('nk:basiskart');
let spretter; //Ikke fjern!
let spretterLayer;
//let visWmsLayer;
var basisKart = null;


class BasisKart extends Component {
  state = {
    isMounted: false,
    zoomLevel: 13,
    lat: 63.9905722,
    lng: 12.3077975,
    points: [],
    
  };

  componentDidMount = () => {
    getContextInnstillingerKartNew(this.initier);
  };

  componentDidUpdate = () => {
    //  this.setState({ rekkeBound: this.props.rekkeBound });
  }

  initier = (data) => {
    this.getSenterPunkt(data);
    this.initierKart();
    this.initierKartEvents();
    this.setState({ isMounted: true });
    setTimeout(() => {
      this.doGoBound();
    }, 1000);
  };

  componentWillUnmount = () => {
    this.setState({ isMounted: false });
    basisKart = null;
  };

  getSenterPunkt = (data) => {
    if (this.props.visning === 'startpunkt') {
      return;
    }
    this.setState({
      lat: data.latitude,
      lng: data.longitude,
      zoomLevel: data.zoomLevel,
    });
  };

  initierKart = () => {
    basisKart = this.refs.basiskart.leafletElement;

    $('.leaflet-control-zoom-fullscreen').hide();

    //Scale
    L.control
      .scale({ position: 'bottomleft', metric: true, imperial: false })
      .addTo(basisKart);
    //Attribution
    basisKart.attributionControl.setPrefix(false);
    basisKart.attributionControl.addAttribution('Norkart Brannforebygging');

    if (this.props.minimap !== false) {
      this.setMiniMap();
    }

    spretterLayer = new L.FeatureGroup();
    basisKart.addLayer(spretterLayer);
  };

  initierKartEvents = () => {
    basisKart.on('zoomend', () => {
      if (this.state.isMounted) {
        this.setState({ zoomLevel: basisKart.getZoom() });
      }
    });
    basisKart.on('click', (e) => {
      if (this.layerControl) {
        this.layerControl.gotClick(e);
      }
    });
  };

  setMiniMap = () => {
    let url =
      '//www.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png';
    let options = {
      width: '300',
      height: '200',
      collapsedWidth: '40',
      collapsedHeight: '40',
      toggleDisplay: true,
      minimized: true,
      strings: { hideText: 'Skjul minikart', showText: 'Vis minikart' },
    };
    var layer = new L.TileLayer(url, { minZoom: 0, maxZoom: 13 });
    new L.Control.MiniMap(layer, options).addTo(basisKart);
  };

  doGoBound = () => {
   if (this.markerlayerControl){
    this.markerlayerControl.setClusterBounds();
   }
  };

  sprett = (lok) => {
    spretterLayer.clearLayers();
    var sprettIcon = new L.divIcon({
      iconSize: new L.Point(20, 20),
      className: 'spretter',
      html: '',
    });
    if (lok.lokasjon === undefined || lok.lokasjon.lat === undefined) {
      return;
    }
    debug('sprett');
    let zIcon = getIcon.sprettIcon;
    basisKart.panTo(new L.LatLng(lok.lokasjon.lat, lok.lokasjon.lon));
    //Vis spretter
    spretter = L.marker([lok.lokasjon.lat, lok.lokasjon.lon], {
      icon: zIcon,
      bounceOnAdd: true,
      bounceOnAddOptions: { duration: 1000, height: 200 },
    })
      .on('click', function() {
        spretterLayer.clearLayers();
      })
      .bindTooltip('Klikk for å fjerne')
      .addTo(spretterLayer);

    setTimeout(() => {
      spretterLayer.clearLayers();
    }, 3000);
  };

  onClickedGj = (s, e) => {
    //ifm wms
    if (this.layerControl) {
      this.layerControl.gotClick(e);
    }
  };

  render() {
    const position = [this.state.lat, this.state.lng];

    let map = (
      <Map
        /* className='kartenheterFxModal'    */
        className={this.props.kartClassName}
        center={position}
        zoom={this.state.zoomLevel}
        zoomControl={false}
        fullscreenControl={true}
        ref='basiskart'>
        {this.props.visning === 'startpunkt' && (
          <StartpunktControl
            kart={basisKart}
            objektIn={this.props.objektIn}
            objektUt={this.props.objektUt}
          />
        )}
        <ZoomControl
          zoom={this.state.zoomLevel}
          showZoom={true}
          kart={basisKart}
        />

        <LayerControl
          ref={(ref) => (this.layerControl = ref)}
          kart={basisKart}
          modus={this.state.visning}
          visning={this.props.visning}
        />
        <FullscreenControl />
        <LocateControl kart={basisKart} />
        {/* <HitsControl kart={basisKart} hits={this.state.controlHits}></HitsControl> */}

        {this.props.visning !== 'wms' && <OppmaalingControl />}

        {this.props.visning !== 'startpunkt' &&
          this.props.visning !== 'soner' &&
          this.props.visning !== 'gjennomføring-kart' && (
            <MarkerClusterLayerControl
              ref={(ref) => (this.markerlayerControl = ref)}
              data={this.props.data}
              itemid={this.props.itemid}
              arbeidsliste={this.props.arbeidsliste}
              visning={this.props.visning}
              kart={basisKart}
              adresse={this.props.adresse}
              eiendom={this.props.eiendom}
              onClickedGj={this.onClickedGj}
              onClickedVarsel={this.props.onClickedVarsel} //R
              sorterModus={this.props.sorterModus} //R
              rekkeBound={this.props.rekkeBound}
            />
          )}
        {this.props.visning === 'gjennomføring-kart' && (
          <GjMarkerClusterLayerControl
            data={this.props.data}
            visning={this.props.visning}
            kart={basisKart}
            adresse={this.props.adresse}
            eiendom={this.props.eiendom}
            onClickedGj={this.onClickedGj}
          />
        )}
        {this.props.visning === 'soner' && <RegionerControl kart={basisKart} />}
      </Map>
    );

    return <div>{map}</div>;
  }
}

export default BasisKart;
