import React from 'react';
import { deleteBruksenhet, kobleTilMatrikkel } from 'api/enhetApi';
import ReactTable from '@norkart/react-table';
import ContextMenu from 'components/ContextMenu';
import ModalContainer from 'components/Dialogs/ModalContainer';
import Enhetssok from '../Enhetssok';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import DialogStore from 'lib/DialogStore';
import Debug from 'debug';

const debug = Debug('nk:Grunndata');

function getEtasjeplan(item) {
  let enhet = item._source.skybrannData.bruksenhet;
  let etnr = '..';
  if (enhet.fEtasjeNr) {
    etnr = ('00' + enhet.fEtasjeNr).substr(-2, 2);
  }
  let etlop = '..';
  if (enhet.fEtasjeLopeNr) {
    etlop = ('00' + enhet.fEtasjeLopeNr).substr(-2, 2);
  }
  return enhet.fEtasjeType ? enhet.fEtasjeType + etnr + etlop : '';
}

function getAdresse(item) {
  if (!item._source.skybrannData) {
    return null;
  }
  let enhet = item._source.skybrannData.bruksenhet;

  let gatenavn =
    (enhet.fGatenavn ? enhet.fGatenavn : 'Mangler gatenavn') +
    ' ' +
    (enhet.fHusNr ? enhet.fHusNr : '') +
    ' ' +
    (enhet.fBokstav ? enhet.fBokstav : '') +
    ' ' +
    getEtasjeplan(item);
  if (gatenavn.includes('ed')) {
    debug('AAA', getEtasjeplan(item));
  }
  return gatenavn;
}

function getMatrikkelnummer(item) {
  if (item._source.skybrannData) {
    let enhet = item._source.skybrannData.bruksenhet;
    let eiendom =
      enhet.fGnr + '/' + enhet.fBnr + '/' + enhet.fFnr + '/' + enhet.fSnr;

    return eiendom;
  }
  return null;
}

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.meldingTittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <JaNeiDialog
      tittel='Slette?'
      melding='Bruksenheten med alle tilhørende fagdata slettes. Vil du fortsette?'
      isOpen={isOpen}
      onClose={onClose}
      onJa={owner.slettJa.bind(this, dialogData.item)}
      onNei={onClose}
    />
  ),
  KobleTilEnhetDialog: (
    { isOpen, onClose, dialogData, owner } //visKobleTilEnhet
  ) => (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      size='fullscreen'
      tittel={dialogData.tittel}>
      <Enhetssok
        kobleTilReelEnhet={owner.onKobleTilenhet}
        isMapCollapsed={true}
        fraFiktiv={true}
      />
    </ModalContainer>
  ),
  EnhetDialog: (
    { isOpen, onClose, dialogData, owner } //visSituasjonstre
  ) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose}
      itemId={dialogData.itemId}
      bruksenhetIdMa={dialogData.bruksenhetIdMa}
      itemAdresse={dialogData.itemAdresse}
      fraFiktive= {true}
    />
  ),
};
class FiktivEnhetTabell extends React.Component {
  constructor() {
    super();
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.state = { deleteItems: [] };
    this.onVisKobleTilenhet = this.onVisKobleTilenhet.bind(this);
    this.onKobleTilenhet = this.onKobleTilenhet.bind(this);
    this.slettJa = this.slettJa.bind(this);
    this.afterDelete = this.afterDelete.bind(this);
    this.rowFormat = this.rowFormat.bind(this);
  }
  componentDidMount= () => {
    
  }

  getColumns() {
    return [
      {
        id: 'kommune',
        name: 'Kommune',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item._source.skybrannData) {
            return item._source.skybrannData.bruksenhet.fKommuneNr;
          }
          return null;
        },
      },
      {
        id: 'adresse',
        name: 'Adresse',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getAdresse(item);
        },
      },
      {
        id: 'adressetype',
        name: 'Adressetype',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (!item._source.skybrannData) {
            return null;
          }
          return item._source.skybrannData.bruksenhet.fGateType === 'M'
            ? 'Matrikkeladresse'
            : 'Vegadresse';
        },
      },
      {
        id: 'eiendom',
        name: 'Eiendom',
        sortParams: 'eiendom',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getMatrikkelnummer(item);
        },
      },
      {
        id: 'byggtype',
        name: 'Bygningstype',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (!item._source.skybrannData) {
            return null;
          }
          return item._source.skybrannData.bruksenhet.fByggType;
        },
      },
      {
        id: 'byggnr',
        name: 'Bygningsnr',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (!item._source.skybrannData) {
            return null;
          }
          return item._source.skybrannData.bruksenhet.fByggNr;
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (!item._source.skybrannData) {
            return null;
          }
          let id = item._source.skybrannData.bruksenhet.fagId;
          if (!this.state.deleteItems.includes(id)) {
            return (
              <ContextMenu.Button
                title='Meny'
                icon='meny'
                iconColor='nk-black'
                className='inlinebtn'>
                <ContextMenu.Item
                  onClick={this.onVisKobleTilenhet.bind(this, item)}>
                  Koble til bruksenhet
                </ContextMenu.Item>
                <ContextMenu.Item
                  onClick={this.onClickSituasjon.bind(this, item)}>
                  Vis situasjon
                </ContextMenu.Item>
                <ContextMenu.Item onClick={this.onSlettEnhet.bind(this, item)}>
                  Slett fiktiv enhet
                </ContextMenu.Item>
              </ContextMenu.Button>
            );
          }
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  }

  onSlettEnhet(item) {
    if (!item._source.skybrannData) {
      this.dialogs.setOpenDialog('OkDialog', {
        melding:
          'Denne enheten er ikke registrert i brannforebygging og kan ikke slettes.',
        meldingTittel: 'Kan ikke slettes',
      });
      return;
    }
    this.dialogs.setOpenDialog('JaNeiDialog', { item: item });
  }

  slettJa(item) {
    this.dialogs.closeDialog();
    debug('Delete bruksenhet', item);
    deleteBruksenhet(
      item._source.skybrannData.bruksenhet.fagId,
      this.afterDelete.bind(this, item)
    );
  }

  afterDelete(item, err, result) {
    debug('Item brukt i delete fra fikt.enheter', item);
    debug('Result after delete fra fikt.enheter', result);
    let deleteItems = this.state.deleteItems;
    deleteItems.push(item._source.skybrannData.bruksenhet.fagId);
    // deleteItems.push(item._id);
    this.setState({ deleteItems: deleteItems });
    this.props.refreshHits();
  }

  onClickSituasjon(item) {
    let enhetid = item._source.skybrannData.bruksenhet.fagId;
    let adresse = item._source ? getAdresse(item) : 'null';
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemId: enhetid,
      bruksenhetIdMa: item._source.id,
      itemAdresse: adresse,
    });
  }

  onKobleTilenhet(item) {
    this.dialogs.closeDialog();
    if (item.source.skybrannData) {
      this.dialogs.setOpenDialog('OkDialog', {
        melding:
          'Det er allerede koblet fagdata til denne enheten, vennligst velg en uten fagdata.',
        meldingTittel: 'Kan ikke kobles',
      });
    } else {
      let fagId = this.state.selectedRow._source.skybrannData.bruksenhet.fagId;
      //Todo: Delete old
      kobleTilMatrikkel(
        fagId,
        item.id,
        this.afterKobleTilMatrikkel.bind(this, fagId)
      );
    }
  }

  afterKobleTilMatrikkel(fagId, err, result) {
    this.dialogs.closeDialog();
    debug('Item brukt i koble til matrikkel', fagId);
    debug('Result after koble til matrikkel', result);
    let deleteItems = this.state.deleteItems;
    //deleteItems.push(item._source.skybrannData.bruksenhet.fagId);
    deleteItems.push(fagId);
    this.setState({ deleteItems: deleteItems });
    this.props.refreshHits();
  }

  onVisKobleTilenhet(item) {
    let tittel = this.getTittel(item);
    debug('KobleTilEnhetDialog', item);
    this.dialogs.setOpenDialog('KobleTilEnhetDialog', { tittel: tittel });
    this.setState({ selectedRow: item });
  }
  getTittel(item) {
    let adresse = item ? getAdresse(item) : '';
    let bygningsNr = item._source.skybrannData.bruksenhet.fByggNr;
    let bygningstekst = bygningsNr ? ' - Bygningsnummer ' + bygningsNr : '';
    return adresse + ' - ' + getMatrikkelnummer(item) + bygningstekst;
  }

  rowFormat(item) {
    if (item._source.skybrannData) {
      let id = item._source.skybrannData.bruksenhet.fagId;
      if (this.state.deleteItems.includes(id)) {
        //  return { className: 'redBackground' };
        return { className: 'hide' };
      }
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className='scrollerMedium'>
          <ReactTable
            onRowClick={null}
            showIndex={false}
            items={this.props.hits}
            columns={this.getColumns()}
            selectableRows={false}
            idProperty='_id'
            itemsPrPage={0}
            rowFormat={this.rowFormat}
          />
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
export default FiktivEnhetTabell;
