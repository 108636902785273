import React, { Component } from 'react';

class SoneInfo extends Component {
   

render() {

  return (
   <div className="modal-content">   
       <div className="modal-header">
           <button className="close" type="button" onClick={this.props.onClose}>
               <span>x</span>
               <span className="sr-only">Lukk</span>
           </button>
           <h4 className="modal-title"> Rediger sone </h4>
       </div>
       <div className="modal-body ">
            <div className="padding10">
                <p>Hvordan kan en sone endres?</p>  
                <br/>
                <p>
                    <div>For å endre en sone må den først vises i kartet.</div>
                    <div>Klikk innenfor sonen for å endre til redigeringsmodus.</div>
                    <div>Nå kan du endre fasongen og forskyve polygonet.</div>
                    <div>Klikk igjen innenfor sonen for å komme tilbake til vanlig modus.</div>
                </p>
                <p>
                    <div>For å ta vare på endringene kan du åpne et vindu ved å..</div>
                    <div>- klikke med høyre musknapp innenfor polygonet (pc og laptop)</div>
                    <div>- bruke "touch and hold" (nettbrett og mobil)</div>
                </p>
                <p>I vinduet kan du også endre sonenavn, område og farge.</p>
            </div>
       </div>
       <div className="modal-footer">
            
            <button className='btn btn-primary bedit' onClick={this.props.onClose}>OK</button>
        </div>
   </div>
   );
 }
}

export default SoneInfo;