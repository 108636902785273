import React from 'react';
import * as _ from 'lodash';
import { withRouter } from 'lib/enhancers';
import ContextMenu from 'components/ContextMenu';
import ReactModal from 'react-modal';
import KartDialog from 'components/Kart/KartDialog.jsx';
import ReactTable from '@norkart/react-table';
import '@norkart/react-table/dist/react-table.css';
import PropTypes from 'prop-types';
import { huskBreadcrumb } from 'components/Breadcrumbs.jsx';
import { getArbeidslisteGjennomforingByUserId, deleteArbeidsliste } from 'api/arbeidslisteApi';
import { getContextBrukere } from 'context/contextOppslag.js';
import { getOrgSvarUt } from 'context/contextInnstillinger.js';
import { setSisteFilterBruker, getSisteFilterBruker } from 'context/contextGjennomforing.js';
import green from 'images/Annet/legendaBlokGreen.png';
import orange from 'images/Annet/legendaBlokOrange.png';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { promisifyApiCall } from 'utils';


const modalDialogs = {
  OkSlettArbeisliste: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={`Slett arbeidsliste "${dialogData.item.navn}"`}
        melding='Du sletter nå valgt arbeidsliste. Vil du fortsette?'
        onOk={onClose(() => owner.onSlettArbeidsliste(dialogData.item))}
      />
    );
  },
  IkkeOkSlettArbeisliste: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={`Slett arbeidsliste "${dialogData.item.navn}"`}
        melding='Arbeidslisten kan ikke slettes da den ikke er fullført.'
        onOk={onClose}
      />
    );
  }
};

const getReactTableColumns = (instance) => [
  {
    id: 'navn',
    name: 'Arbeidsliste',
    sortParams: 'navn',
    isSorted: true,
    sortDirection: 'asc',
  },
  {
    id: 'antallBruksenheter',
    name: 'Bruksenheter',
    sortParams: 'antallBruksenheter',
    filterable: false,
    sortable: false,
  },
  {
    id: 'ansvarlig',
    name: 'Ansvarlig',
    sortParams: 'ansvarlig',
    filterable: false,
    isSorted: false,
  },
  {
    id: 'avtaltFraDato',
    name: 'Første besøk',
    sortParams: 'planlagtFraDato',
    filterable: false,
    isSorted: false,
    sortable: true,
    formatter: function(item) {
      if (item.planlagtFraDato === null) {
        return '';
      }
      var dato = new Date(item.planlagtFraDato);
      var dag = dato.getDate().toString();
      var mnd = (dato.getMonth() + 1).toString();
      var aar = dato.getFullYear().toString();
      if (dag.length === 1) {
        dag = '0' + dag;
      }
      if (mnd.length === 1) {
        mnd = '0' + mnd;
      }
      return dag + '.' + mnd + '.' + aar;
    },
  },
  {
    id: 'avtaltTilDato',
    name: 'Siste besøk',
    sortParams: 'planlagtTilDato',
    filterable: false,
    isSorted: false,
    sortable: true,
    formatter: function(item) {
      if (item.planlagtTilDato === null) {
        return '';
      }
      var dato = new Date(item.planlagtTilDato);
      var dag = dato.getDate().toString();
      var mnd = (dato.getMonth() + 1).toString();
      var aar = dato.getFullYear().toString();
      if (dag.length === 1) {
        dag = '0' + dag;
      }
      if (mnd.length === 1) {
        mnd = '0' + mnd;
      }
      return dag + '.' + mnd + '.' + aar;
    },
  },
  {
    id: 'tiltak',
    name: 'Tiltak',
    sortParams: 'tiltak',
    filterable: false,
    isSorted: false,
    sortable: false,
    hideInVertical: true,
    formatter: function(item) {
      if (item.tiltakBruksenhet === null && item.tiltakRoyklop === null) {
        return '';
      }
      if (item.tiltakRoyklop === null) {
        return item.tiltakBruksenhet;
      }
      if (item.tiltakBruksenhet === null) {
        return item.tiltakRoyklop;
      } else {
        return item.tiltakBruksenhet + ',' + item.tiltakRoyklop;
      }
    },
  },
  {
    id: 'status',
    name: 'Reg. tiltak',
    filterable: false,
    isSorted: false,
    sortable: false,
    hideInVertical: true,
    formatter: function(item) {
     
      var index1 = item.tiltakBrukenhetStatus.indexOf('/');
      var index2 = item.tiltakRoyklopStatus.indexOf('/');
      var utf =
        parseInt(item.tiltakBrukenhetStatus.substring(0, index1)) +
        parseInt(item.tiltakRoyklopStatus.substring(0, index2));
      var til =
        parseInt(
          item.tiltakBrukenhetStatus.substring(
            index1 + 1,
            item.tiltakBrukenhetStatus.length
          )
        ) +
        parseInt(
          item.tiltakRoyklopStatus.substring(
            index2 + 1,
            item.tiltakRoyklopStatus.length
          )
        );
      return utf + '/' + til;
    },
  },
  {
    id: 'meny',
    filterable: false,
    isSorted: false,
    sortable: false,
    formatter: function(item) {
      return (
        <ContextMenu.Button>
          <ContextMenu.Item onClick={instance.onSlett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
          <ContextMenu.Item onClick={instance.openKart.bind(this, item)}>
            Vis i kart..
          </ContextMenu.Item>
        </ContextMenu.Button>
      );
    }.bind(this),
  },
  {
    //Ekstra space ifm meny-button i mobil-grid
    formatter: function() {
      return <div>&nbsp;</div>;
    },
  },
];




class Table extends React.Component {

  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  static propTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
  };
  state = {
    filtrerteLister: null,
    personer: [],
    valgtPersonId: 0,
    kartTittel: '',
  };

  componentDidMount() {
    getContextBrukere(
      function(brukere) {
        
        let p = getSisteFilterBruker();
        if (p === 0){
          p= brukere.paalogget;
        }
        this.setState({ valgtPersonId: p });
        this.setState({ personer: brukere.brukere });
        this.filterArbeidslister(p);
      }.bind(this)
    );
    getOrgSvarUt(this.gotSvarUt);
  }

  gotSvarUt = (res) => {

  };

  filterArbeidslister = (p) => {
    this.setState({ filtrerteLister: null });
    promisifyApiCall(getArbeidslisteGjennomforingByUserId)(p).then((lister) => {
      this.setState({ filtrerteLister: _.sortBy(lister, 'navn') });
    });
    setSisteFilterBruker(p);
  };

  handleAnsvarligChange = (p) => {
    this.filterArbeidslister(p.target.value);
    this.setState({ valgtPersonId: p.target.value });
  };

  onSlett = (item) => {
    if (this.checkSlett(item)) {
      this.dialogs.setOpenDialog('OkSlettArbeisliste', { item });
    } else {
      this.dialogs.setOpenDialog('IkkeOkSlettArbeisliste', { item });
    }
  }

  checkSlett = (item) => {
    var index1 = item.tiltakBrukenhetStatus.indexOf('/');
    var index2 = item.tiltakRoyklopStatus.indexOf('/');
    var utf =
      parseInt(item.tiltakBrukenhetStatus.substring(0, index1)) +
      parseInt(item.tiltakRoyklopStatus.substring(0, index2));
    var til =
      parseInt(
        item.tiltakBrukenhetStatus.substring(
          index1 + 1,
          item.tiltakBrukenhetStatus.length
        )
      ) +
      parseInt(
        item.tiltakRoyklopStatus.substring(
          index2 + 1,
          item.tiltakRoyklopStatus.length
        )
      );
    if (utf === til && item.antallBruksenheterSendtEier >= item.antallBruksenheter) {
      return true;
    } else {
      return false;
    }
  }

  onSlettArbeidsliste = ({ id }) => {
    deleteArbeidsliste(id, this.afterUpdate);
    this.dialogs.closeDialog();
  };

  afterUpdate = (err, data) => {
    this.filterArbeidslister(this.state.valgtPersonId);
  };

  openKart = (item) => {
    this.setState({ kartTittel: item.navn });
    this.setState({ arbeidsliste: item }, this.openKart2());
  };

  openKart2 = () => {
    ReactModal.setAppElement('body');
    this.setState({ modalKartIsOpen: true });
  };

  closeKartModal = () => {
    this.setState({ modalKartIsOpen: false });
  };

  onRowClick = (item) => {
    this.props.router.push(
      'gjennomforing/' + item.id + '/' + encodeURIComponent(item.navn)
    );

    //============= REACT CONTEXT BREADCRUMBS ====================
    huskBreadcrumb('gjennomforing_arbeidsliste_navn', item.navn);
  };

  rowFormat = (item) => {
    var index1 = item.tiltakBrukenhetStatus.indexOf('/');
    var index2 = item.tiltakRoyklopStatus.indexOf('/');
    var utf =
      parseInt(item.tiltakBrukenhetStatus.substring(0, index1)) +
      parseInt(item.tiltakRoyklopStatus.substring(0, index2));
    var til =
      parseInt(
        item.tiltakBrukenhetStatus.substring(
          index1 + 1,
          item.tiltakBrukenhetStatus.length
        )
      ) +
      parseInt(
        item.tiltakRoyklopStatus.substring(
          index2 + 1,
          item.tiltakRoyklopStatus.length
        )
      );
    if (utf === til) {
      if  (item.antallBruksenheterSendtEier >= item.antallBruksenheter) {
        return { style: { backgroundColor: '#dcedc0' } };
      } else {
        return { style: { backgroundColor: '#f5d9a6' } };
      }
      
    } 
  };

  render() {

    if (!this.state.filtrerteLister) {
      return <div className='loader' />;
    }
    
    return (
      <React.Fragment>
        <h2>Gjennomføring</h2>
        <hr />
        <div className='form'>
          <div className='row back-norkart-grey'>
            <p />
            <div className='col-lg-4 col-sm-12 col-md-6'>
              <div className='form-group'>
                <label className='top-margin-4'>Ansvarlig&nbsp;</label>

                <select
                  className='h30 width220'
                  id='type'
                  onChange={this.handleAnsvarligChange}
                  value={
                    this.state.valgtPersonId !== 0
                      ? this.state.valgtPersonId
                      : 0
                  }>
                  <option key={'key'} value={0}>
                    Alle
                  </option>
                  {_.map(this.state.personer, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <p />
        <p />
        <ReactTable
          items={this.state.filtrerteLister}
          idProperty='id'
          showIndex={false}
          filterable={false}
          columns={getReactTableColumns(this)}
          selectableRows={false}
          onRowSelectionChange={this.noeErValgt}
          selectAllLabel='Alle'
          onRowClick={this.onRowClick}
          rowFormat={this.rowFormat}
        />

        <div><span><img height= '30px' src={orange} alt="" /></span><span style={{ marginLeft: '8px' }}>Oppgavene på arbeidslisten er gjennomført</span></div>
         <div style={{ marginTop: '4px' }}><span><img height= '30px' src={green} alt="" /></span><span style={{ marginLeft: '8px' }}>Arbeidslisten er fullført og kan slettes</span></div>
        
        <ReactModal
          isOpen={this.state.modalKartIsOpen}
          contentLabel='Modal'
          className='FModal'>
          <KartDialog
            data={this.state.arbeidsliste}
            onClose={this.closeKartModal}
            tittel={this.state.kartTittel}
            visning='gjennomføring-arbeidsliste'
          />
        </ReactModal>
        {this.dialogs.renderOpenDialog()}
      </React.Fragment>
    );
  }
}

export default withRouter(Table);

