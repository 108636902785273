import { makeGetTypeKey } from 'redux/utils';
const getTypeKey = makeGetTypeKey('oppfolging');


//INGEN I BRUK LENGER
export const AAPNEPUBLISERTEAVVIK_FETCHED = getTypeKey('AAPNEPUBLISERTEAVVIK_FETCHED');
export const IKKEPUBLISERTEAVVIK_FETCHED = getTypeKey('IKKEPUBLISERTEAVVIK_FETCHED');
export const SET_LASTBRUKER_IKKEPUBLISERTEAVVIK = getTypeKey('SET_LASTBRUKER_IKKEPUBLISERTEAVVIK');
export const SET_LASTBRUKER_AAPNEAVVIK = getTypeKey('SET_LASTBRUKER_AAPNEAVVIK');
export const BRUKERE_FETCHED = getTypeKey('BRUKERE_FETCHED');  
export const SET_EDITORTEKST_AAPNEAVVIK = getTypeKey('SET_EDITORTEKST_AAPNEAVVIK'); 