import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
import ReactTable from '@norkart/react-table';
import NKIcon from 'nka-icons';
import _ from 'underscore';
import arkivApi from 'api/arkivApi';
//import { getOrganisasjonArkivInfo } from 'api/organisasjonApi';
import { getOrgArkivInfo } from 'context/contextInnstillinger.js';
import { getSakskategori, sjekkSakArkivStatus } from 'api/arkivApi';
import { getBruksenheterTilknyttetBygg } from 'api/enhetApi';
import { getRoyklopBruksenhetTilknyttet } from 'api/royklopApi';

import { uuid } from 'utils';
//Sett melding for rein tekst, eller children for html

class LukkAvvikDialog2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      merknad: '',
      step1Hide: '',
      step15Hide: 'hide',
      step2Hide: 'hide',
      tittel: 'Lukk avvik',
      eierkontakter: [],
      eierInfoMangler: true,
      laster: false,
      unntatt: false,
      visSkjerming: true,
      skjermingSatt: false,
      sakArkivStatus: false,
    };
  }

  componentDidMount() {
    //getOrganisasjonArkivInfo(this.gotArkivInfo);
    sjekkSakArkivStatus(this.props.bruksenhetid, this.sakArkivStatushentet);
    // getOrganisasjonArkivSkjerming(this.gotOrg);
    // getSakskategori('BF', null, this.gotKategori);
    this.setState({ laster: true });
  }

  sakArkivStatushentet = (err, res) => {
    this.setState({ sakArkivStatus: res });
    getOrgArkivInfo(this.gotArkivInfo);
  };

  gotArkivInfo = (res) => {
    this.setState({ visSkjerming: res.data.arkivSkjerming });
    if (!res.data.arkivIkkeAktiv) {
      getSakskategori('BF', null, this.gotKategori);
    } else {
      this.setState({ laster: false, sakArkivStatus: true });
    }
  };

  gotKategori = (err, kat) => {
    if (kat != null) {
      if (
        kat.Tilgangsrestriksjon != null &&
        kat.Tilgangsrestriksjon != '' &&
        kat.Skjermingshjemmel != null &&
        kat.Skjermingshjemmel != ''
      ) {
        this.setState({
          skjermingSatt: true,
          tilgang: kat.Tilgangsrestriksjon,
          hjemmel: kat.Skjermingshjemmel,
        });
      }
    }
    this.setState({ laster: false });
  };

  gotOrg = (err, arkivSkjerming) => {
    this.setState({ visSkjerming: arkivSkjerming, laster: false });
  };

  merknadChange = (val) => {
    this.setState({ merknad: val.target.value });
  };

  neste1 = () => {
    if (this.state.unntatt) {
      this.setState({
        step1Hide: 'hide',
        step2Hide: 'hide',
        step15Hide: '',
        tittel: 'Lukk avvik - unnta innbygger dialog fra offentlig innsyn',
      });
      if (!this.state.skjermingSatt) {
        this.setState({ laster: true });
        arkivApi.getKodeliste(
          'Tilgangsrestriksjon',
          uuid(),
          this.gotTilgangsrestriksjon
        );
      }
    } else {
      let array = this.props.bruksenheter.split(',');
      console.log(this.props.avvik);
      if (this.props.avvik.gjelderBygg) {
        if (this.props.avvik.objektReferanseType == 'B') {
          getBruksenheterTilknyttetBygg(
            this.props.bruksenhetid,
            this.gotBrIdsTilnyttet
          );
        } else {
          getRoyklopBruksenhetTilknyttet(
            this.props.bruksenhetid,
            this.gotBrIdsTilnyttet
          );
        }
      } else {
        getBruksenhetKontakter2(array, this.gotEierkontakter);
        this.setState({
          step1Hide: 'hide',
          step2Hide: 'hide',
          step15Hide: 'hide',
          tittel: 'Lukk avvik - verifiser kontaktinformasjon',
          laster: true,
        });
      }
    }
  };

  neste2 = () => {
    let array = this.props.bruksenheter.split(',');
    if (this.props.avvik.gjelderBygg) {
      if (this.props.avvik.objektReferanseType == 'B') {
        getBruksenheterTilknyttetBygg(
          this.props.bruksenhetid,
          this.gotBrIdsTilnyttet
        );
      } else {
        getRoyklopBruksenhetTilknyttet(
          this.props.bruksenhetid,
          this.gotBrIdsTilnyttet
        );
      }
    } else {
      getBruksenhetKontakter2(array, this.gotEierkontakter);
      this.setState({
        step1Hide: 'hide',
        step2Hide: 'hide',
        step15Hide: 'hide',
        tittel: 'Lukk avvik - verifiser kontaktinformasjon',
        laster: true,
      });
    }
  };

  forrige1 = () => {
    this.setState({
      step1Hide: '',
      step2Hide: 'hide',
      step15Hide: 'hide',
      tittel: 'Lukk avvik',
    });
  };

  forrige2 = () => {
    this.setState({
      step1Hide: '',
      step2Hide: 'hide',
      step15Hide: 'hide',
      tittel: 'Lukk avvik',
    });
  };

  gotBrIdsTilnyttet = (err, res) => {
    let array = this.props.bruksenheter.split(',');
    if (res !== undefined && res !== null) {
      res.forEach((element) => {
        var el = element.toString();
        if (array.indexOf(el) === -1) {
          array.push(el);
        }
      });
    }
    getBruksenhetKontakter2(array, this.gotEierkontakter);
    this.setState({
      step1Hide: 'hide',
      step2Hide: 'hide',
      step15Hide: 'hide',
      tittel: 'Lukk avvik - verifiser kontaktinformasjon',
      laster: true,
    });
  };

  gotTilgangsrestriksjon = (err, data) => {
    this.setState({ Tilgangsrestriksjoner: data, tilgang: data[0].kodeverdi });
    arkivApi.getKodeliste(
      'SkjermingsHjemmel',
      uuid(),
      this.gotSkjermingsHjemmel
    );
  };

  gotSkjermingsHjemmel = (err, data) => {
    this.setState({
      SkjermingsHjemmeler: data,
      laster: false,
      hjemmel: data[0].kodeverdi,
    });
  };

  gotEierkontakter = (err, data) => {
    let d = data;
    this.checkEierkontakter(d);
    this.setState({ eierkontakter: d });
  };

  checkEierkontakter = (eierkontakter) => {
    console.log(eierkontakter);
    var result = true;
    if (eierkontakter !== null && eierkontakter.length > 0) {
      eierkontakter.forEach((ek) => {
        ek.kontakt.forEach((ko) => {
          if (ko.telefon != null || ko.telefon != '' || ko.ugyldigTelefonNr) {
            result = false;
          }
        });
      });
    }
    this.setState({ eierInfoMangler: result, laster: false, step2Hide: '' });
  };

  onOk = () => {
    this.props.onOk(
      this.state.merknad,
      this.state.eierkontakter,
      this.state.unntatt,
      this.state.tilgang,
      this.state.hjemmel
    );
  };

  onOk2 = () => {
    this.props.onClose();
  };

  onOk3 = () => {
    this.props.opprettSak();
  }

  unntattChange = () => {
    let unntatt = !this.state.unntatt;
    this.setState({ unntatt: unntatt });
  };

  hjemmelChange = (val) => {
    this.setState({ hjemmel: val.target.value });
  };

  tilgangChange = (val) => {
    this.setState({ tilgang: val.target.value });
  };

  render() {
    let loaderStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
    };

    if (this.state.laster) {
      return <div style={loaderStyle} className='loader' />;
    } else {
      var columns = this.getColumns();
      return (
        <NKModal size='medium' isOpen={this.props.isOpen}>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                className='close'
                onClick={this.props.onClose}
                type='button'>
                <span>x</span>
                <span className='sr-only'>Lukk</span>
              </button>
              <h4 className='modal-title'>{this.state.tittel}</h4>
            </div>
            <div className='modal-body'>
              {/* STEP1 */}
              <div className={this.state.step1Hide}>
                {!this.state.sakArkivStatus ? (
                  <div>
                    <br />
                    <p>Du har valgt å lukke avviket.</p>
                    <p>
                      Avviket kan ikke lukkes da saken som er knyttet til
                      bruksenheten har feil status i arkivet eller så eksisterer
                      den ikke i arkivet. Lukking av avvik krever at saken som
                      er knyttet til bruksenheten, eksisterer i arkivet og er
                      åpen.
                    </p>
                    <u>Vil du opprette ny sak og knytte denne til bruksenheten?</u>
                    <p>Avviket kan da lukkes og dokumentasjon arkiveres på denne sak.</p>
                    <p />
                  </div>
                ) : (
                  <div>
                    <div>
                      <br />
                      { this.props.info ? 
                      <div>
                        <p>Ny sak er opprettet og koblet til bruksenheten. Aviket kan nå lukkes.</p>
                      </div> :
                      <div>
                        <p>Du har valgt å lukke avviket.</p>
                      </div>  }
                      
                      <p>
                        Innbyggeren blir informert om at tilbakemeldingen var
                        tilfredsstillende og dialogen avsluttes.
                      </p>

                      {this.state.visSkjerming ? (
                        <div>
                          <p>
                            Innbygger dialogen arkiveres når avviket lukkes.
                            Hvis det er ønskelig å unnta denne journalposten fra
                            offentligheten, kryss av under.
                          </p>
                          <div
                            className='row idiv margintop8'
                            style={{
                              display: 'inline-block',
                              marginRight: '30px',
                            }}>
                            <div
                              className='form-group'
                              style={{ marginBottom: '4px' }}>
                              <label>
                                <input
                                  className='checkbox30'
                                  type='checkbox'
                                  onChange={this.unntattChange}
                                  onClick={this.unntattChange}
                                  checked={this.state.unntatt}
                                />
                                <span className='checkboxtext18'>
                                  Unntatt offentlighet
                                </span>
                              </label>
                            </div>
                          </div>
                          <p />
                        </div>
                      ) : null}

                      <p>Bekreft at du ønsker å lukke avviket.</p>
                    </div>
                  </div>
                )}
              </div>
              {/* STEP15 */}
              <div className={this.state.step15Hide}>
                {this.state.skjermingSatt ? (
                  <div>
                    <p>
                      Du har valgt å unnta journalposten fra offentlighet når
                      den arkiveres.
                    </p>
                    Skjermingshjemmel: {this.state.hjemmel}
                    <br />
                    Tilgangsrestriksjon: {this.state.tilgang}
                  </div>
                ) : (
                  <div>
                    <p>
                      Du har valgt å unnta journalposten fra offentlighet når
                      den arkiveres. For å kunne gjøre det, må det velges
                      Skjermingshjemmel og Tilgangsrestriksjon.
                    </p>
                    <p />
                    <div className='row idiv'>
                      <div className='form-group '>
                        <label>Skjermingshjemmel</label>
                        <select
                          className='form-control'
                          id='hjemmel'
                          onChange={this.hjemmelChange}>
                          {_.map(this.state.SkjermingsHjemmeler, function(
                            o,
                            i
                          ) {
                            return (
                              <option key={i} value={o.kodeverdi}>
                                {o.kodeverdi} - {o.kodebeskrivelse}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className='row idiv'>
                      <div className='form-group '>
                        <label>Tilgangsrestriksjon</label>
                        <select
                          className='form-control'
                          id='tilgang'
                          onChange={this.tilgangChange}>
                          {_.map(this.state.Tilgangsrestriksjoner, function(
                            o,
                            i
                          ) {
                            return (
                              <option key={i} value={o.kodeverdi}>
                                {o.kodeverdi} - {o.kodebeskrivelse}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* STEP2 */}
              <div className={this.state.step2Hide}>
                <h3>{this.props.adresse}</h3>
                {this.state.eierInfoMangler ? (
                  <div>
                    <NKIcon
                      icon='advarsel_roed'
                      className='pull-left'
                      size='2'
                      color='nk-black'
                    />
                    Pga manglende kontaktinformasjon kan ikke eier kontaktes for
                    å informere om lukking av avvik. Avviket lukkes likevel og
                    eierkontakten må kontaktes manuelt for å informere om dette.
                    <p />
                    <p />
                  </div>
                ) : (
                  <div />
                )}
                <ReactTable
                  showIndex={false}
                  items={this.state.eierkontakter}
                  columns={columns}
                  selectableRows={false}
                  onRowClick={null}
                  toggleAll={false}
                />
                <p>
                  Hvis SMS er aktivert for lukking av avvik, vil eiere med
                  telefon motta SMS.
                </p>
              </div>
            </div>

            <div className='modal-footer'>
              {!this.state.step1Hide && this.state.sakArkivStatus && (
                <button
                  onClick={this.neste1}
                  className='btn btn-primary pull-right btnBottom'>
                  <div>Neste</div>
                </button>
              )}
              {!this.state.sakArkivStatus && (
                <div>
                  <button
                    onClick={this.onOk3}
                    className='btn btn-success pull-right btnBottom'>
                    OK
                  </button>

                  <button
                    onClick={this.onOk2}
                    className='btn btn-default pull-right btnBottom'>
                    Avbryt
                  </button>
                </div>
                
              )}
              {!this.state.step2Hide && (
                <div>
                  <div>
                    <button
                      onClick={this.onOk}
                      className='btn btn-success pull-right btnBottom'>
                      OK
                    </button>
                  </div>

                  <button
                    onClick={this.forrige1}
                    className='btn btn-default pull-right btnBottom'>
                    Forrige
                  </button>
                </div>
              )}
              {!this.state.step15Hide && (
                <div>
                  <div>
                    <button
                      onClick={this.neste2}
                      className='btn btn-success pull-right btnBottom'>
                      Neste
                    </button>
                  </div>

                  <button
                    onClick={this.forrige2}
                    className='btn btn-default pull-right btnBottom'>
                    Forrige
                  </button>
                </div>
              )}
            </div>
          </div>
        </NKModal>
      );
    }
  }

  getColumns = () => {
    return [
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
      },
      {
        id: 'navn',
        name: 'Eierkontakt',
        formatter: function(item) {
          let teller = 1;
          let t = 1;
          return (
            <table>
              <tbody>
                {item.kontakt.map(function(ko) {
                  teller = teller + 1;
                  return (
                    <tr key={'tr' + teller}>
                      <td key={'td' + teller}>
                        {ko.navn !== null ? (
                          ko.navn
                        ) : (
                          <div className='red'>Mangler eierkontakt</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        },
      },
      {
        id: 'telefon',
        name: 'Telefon',
        formatter: function(item) {
          let teller = 1;
          let t = 1;
          return (
            <table>
              <tbody>
                {item.kontakt.map(function(ko) {
                  teller = teller + 1;
                  return (
                    <tr key={'tr' + teller}>
                      <td key={'td' + teller}>
                        {ko.telefon !== null ? (
                          ko.ugyldigTelefonNr ? (
                            <div className='red'>Ugyldig nummer</div>
                          ) : (
                            ko.telefon
                          )
                        ) : (
                          <div className='red'>Mangler telefon</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        },
      },
    ];
  };
}

export default LukkAvvikDialog2;
