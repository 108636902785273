import React from 'react';

import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import moment from 'moment';
import { debugFactory } from 'utils';
import PdfDialog from 'components/Pdf/PdfDialog.jsx';
import PdfIcon from 'images/Annet/pdf.png';
import { getDashboardIntern } from 'api/dashboardApi.js';
import NKModal from 'components/Dialogs/NKModal.jsx';
const debug = debugFactory('nk:NokkeltallIntern');
//kopier: import NokkeltallIntern from './NokkeltallIntern.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  PdfDialogen: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <PdfDialog
          onClose={onClose}
          data={dialogData.data}
          rapportType={dialogData.rapportType}
          tittel={dialogData.tittel}
        />
      </NKModal>
    );
  },
};

class NokkeltallIntern extends React.Component {
  constructor(props) {
    super(props);
    this.theTable = null;
    this.state = { id: 0, tall: null };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    getDashboardIntern(this.gotData);
  }

  gotData = (err, tall) => {
    debug('HOLO', tall);
    this.setState({ tall });
  };

  getTableData = () => {
    debug('HOLO', this.theTable);
    let data = [];

    let table = this.theTable;
    for (let i in table.rows) {
      let row = table.rows[i];
      let obj = { label: '', tall: '' };
      if (row.cells) {
        if (row.cells[0]) {
          let a = row.cells[0].innerHTML;
          obj.label = a;
        }
        if (row.cells[2]) {
          let b = row.cells[2].innerHTML;
          obj.tall = b;
        }

        data.push(obj);
      }
    }
    return data;
  };

  eksporterTilPdf = () => {
    let data = this.getTableData();
    this.dialogs.setOpenDialog('PdfDialogen', {
      data: data,
      rapportType: 'nokkeltallIntern',
      tittel:
        ' Brannforebygging: Interne nøkkeltall - ' +
        moment().format('DD.MM.YYYY'),
    });
  };

  render() {
    let rad = {
      borderBottom: '1px solid lightGray',
      borderCollapse: 'collapse',
    };
    let right = { textAlign: 'right' };
    if (!this.state.tall) {
      return <div style={{ padding: '40px' }}>Vent..</div>;
    }
    return (
      <div>
        <h2>Nøkkeltall intern</h2>
        <br />
        <div style={{ marginLeft: '20px' }}>
          <label>
            Brannforebygging: Interne nøkkeltall -{' '}
            {moment().format('DD.MM.YYYY')}
          </label>
          <br />
          <br />
          <table
            ref={(el) => {
              this.theTable = el;
            }}>
            <tbody>
              <tr style={rad}>
                <td>Antall kunder i produksjon</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.produksjon} </td>
              </tr>
              <tr style={rad}>
                <td>Antall kommuner i produksjon</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.kommuner} </td>
              </tr>
              <tr style={rad}>
                <td>Antall brukere i produksjon</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.brukere} </td>
              </tr>
              <tr style={rad}>
                <td>Antall kunder til verifisering</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.verifisering} </td>
              </tr>

              <tr style={{ height: '20px' }} />

              <tr style={rad}>
                <td>Antall organisasjoner med SvarUt</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.svarut} </td>
              </tr>
              <tr style={rad}>
                <td>Antall organisasjoner med Risikovurdering</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.risikovurdering} </td>
              </tr>
              <tr style={rad}>
                <td>Antall organisasjoner med Arkiv</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.arkiv} </td>
              </tr>

              <tr style={{ height: '20px' }} />

              <tr style={rad}>
                <td>Antall organisasjoner som bruker SMS</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.sms} </td>
              </tr>
              <tr style={rad}>
                <td>Antall organisasjoner som bruker SMS påminnelse</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.smsPaminnelse} </td>
              </tr>
              <tr style={rad}>
                <td>Antall organisasjoner som bruker SMS purring</td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.smsPurring} </td>
              </tr>
              <tr style={rad}>
                <td>
                  Antall organisasjoner som bruker 'Varsel utsatt sending'
                </td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.varselUtsattsending} </td>
              </tr>
              <tr style={rad}>
                <td>
                  Antall organisasjoner som bruker 'Dokumentkontroll'
                </td>
                <td style={{ width: '10px' }} />
                <td style={right}>{this.state.tall.dokumentKontroll} </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <button
            className='btn btn-success '
            title='Eksporter til Pdf'
            onClick={this.eksporterTilPdf}>
            <img src={PdfIcon} alt='pdf' height='24' width='24' />
            <span style={{ marginLeft: '20px' }}>Eksporter til PDF</span>
          </button>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NokkeltallIntern;
