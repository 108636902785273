import callApi from './api';
import { debugFactory, callbackSpy } from 'utils';

const debug = debugFactory('nk:api:arbeidslisteapi');

// G E T
export function getArbeidslistePlanlegging(callback) {
    callApi('GET', '/arbeidsliste/planlegging', null, callback);
};
export function getArbeidslistePlanleggingByUserId(brukerId, callback) {
    callApi('GET', '/arbeidsliste/planlegging/'+ brukerId, null, callback);
};
export function getArbeidslisteByAktiv(aktiv, callback) {
    callApi('GET', '/arbeidsliste/aktiverte/'+ aktiv, null, callback);
};
export function getArbeidslisteBruksenhet(id, callback) {
    callApi('GET', '/ArbeidslisteBruksenhet/'+ id, null, callback);
};
export function getArbeidslisteGjennomforing(callback) {
    callApi('GET', '/arbeidsliste/gjennomforing', null, callback);
};
export function getArbeidslisteGjennomforingByUserId(brukerId, callback) {
    callApi('GET', '/arbeidsliste/gjennomforing/' + brukerId, null, callback);
};

//Henter bruksenheter og røykløp flatet ut på tiltak
export function getArbeidslisteBrukseneheterOgRoklop(id, callback) {
  callApi('GET', '/ArbeidslisteBruksenhet/bruksenheterroyklop/' + id, null, callback);
};

export function getArbeidslisteById(id, callback) {
    callApi('GET', '/arbeidsliste/' + id, null, callback);
};

export function getArbeidslisteObjekt(id, callback) {
    callApi('GET', '/arbeidsliste/listeobjekt/' + id, null, callback);
};

export function getObjekterArbeidsliste(id, callback) {
    callApi('GET', '/arbeidsliste/getObjekterArbeidsliste/' + id, null, callback);
};

// UPDATE

export function saveArbeidsliste(liste, callback) {
    callApi('POST', '/arbeidsliste/', liste, callback);
};

export function saveTiltak(liste, callback) {
    callApi('POST', '/arbeidslisteBruksenhet/oppdatertiltak/', liste, callback);
};

export function deleteArbeidsliste(id, callback) {
    callApi('DELETE', '/arbeidsliste/' + id, null, callback);
};

export function updateArbeidsliste(id, liste, callback) {
    callApi('PUT', '/arbeidsliste/' + id, liste, callback);
};

export function updateArbeidslisteStatus(id, status, callback) {
    callApi('POST', '/arbeidsliste/status/'+ id +'/' + status, null, callback);
};

export function validerSlettArbeidsliste(id, callback) {
    callApi('GET', '/arbeidsliste/validerslett/'+id, null, callback);
};

export function getBruksenhetStatus(status, orgId, callback) {
    callApi('GET', '/arbeidsliste/bruksenhetstatus/'+ status +'/' + orgId, null, callback);
};

export function setRekkefolgenummer(liste, callback) {
    callApi('POST', '/bruksenhetvarsel/rekkefolgenummer/', liste, callback); 
}

export default  {
    getArbeidslistePlanlegging,
    getArbeidslistePlanleggingByUserId,
    getArbeidslisteGjennomforing,
    getArbeidslisteGjennomforingByUserId,
    getArbeidslisteById,
    getArbeidslisteObjekt,
    getObjekterArbeidsliste,
    saveArbeidsliste,
    saveTiltak,
    deleteArbeidsliste,
    updateArbeidsliste,
    updateArbeidslisteStatus,
    validerSlettArbeidsliste,
    getArbeidslisteBrukseneheterOgRoklop,
    getArbeidslisteByAktiv,
    getArbeidslisteBruksenhet,
    getBruksenhetStatus
};