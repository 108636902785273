import React from 'react';
import '@norkart/react-table/dist/react-table.css';
import ModalContainer from 'components/Dialogs/ModalContainer';
import NySak from './NySak.jsx';
import HentSak from './HentSak.jsx';
import NyUtJp from './NyUtJp.jsx';
import VisInnstillinger from './VisInnstillinger.jsx';
import NyVisInnstillinger from './NyVisInnstillinger.jsx';
import PdfTest from './PdfTest.jsx';
import SvarUtTest from './SvarUtTest.jsx';
import DialogStore from 'lib/DialogStore';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

const modalDialogs = {
  NySak: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      isOpen={isOpen}
      onClose={onClose}
      buttonText='Opprett'
      onSubmit={() => owner.refs.nysak.submit()}
      tittel={'Opprett ny sak'}>
      <NySak ref='nysak' />
    </ModalContainer>
  ),
  HentSak: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      isOpen={isOpen}
      onClose={onClose}
      buttonText='Hent sak'
      onSubmit={() => owner.refs.hentsak.submit()}
      tittel={'Hent'}>
      <HentSak ref='hentsak' />
    </ModalContainer>
  ),
  NyJP: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      isOpen={isOpen}
      onClose={onClose}
      buttonText='Lagre'
      onSubmit={() => owner.refs.nyjp.submit()}
      tittel={'Ny utgående journalpost'}>
      <NyUtJp ref='nyjp' />
    </ModalContainer>
  ),
  Innstillinger: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='fullscreen'
      isOpen={isOpen}
      onClose={onClose}
      tittel={'Kunde arkiv innstillinger'}>
      <VisInnstillinger ref='Innstillinger' />
    </ModalContainer>
  ),
  NyInnstillinger: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='fullscreen'
      isOpen={isOpen}
      onClose={onClose}
      tittel={'Kunde arkiv innstillinger'}>
      <NyVisInnstillinger ref='Innstillinger' />
    </ModalContainer>
  ),
  Pdftest: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='large'
      isOpen={isOpen}
      onClose={onClose}
      buttonText='Send'
      onSubmit={() => owner.refs.pdf.submit()}
      tittel={'Pdf test'}>
      <PdfTest ref='pdf' />
    </ModalContainer>
  ),
  Svarut: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='large'
      isOpen={isOpen}
      onClose={onClose}
      buttonText='Send'
      onSubmit={() => owner.refs.svarut.submit()}
      tittel={'SvarUt test'}>
      <SvarUtTest ref='svarut' />
    </ModalContainer>
  ),
};
class Test extends React.Component {
  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {};

  render() {
    return (
      <div style={{ margin: '30px' }}>
        <h2>Test integrasjon mot arkivApi</h2>
        <p>Funksjoner for testing av integrasjon mot arkiv.</p>
        <p>
          Vær oppmerksom på at Ny Sak og Ny Journalpost oppretter nye saker og
          journalposter i arkivet.{' '}
        </p>
        <p>Dette må være avklart med arkiv ansvarlig.</p>
        <p>
          Saker opprettes basert på meta data som er definert i
          arkivinnstillinger, brukerinstillinger og sakskategori BF.
          <br />
          Det er en forutsetning at det er definert en sakskategori med navn BF.
        </p>
        <br />
        <div className='btn-toolbar'>
          <button
            className='btn btn-default width300'
            onClick={() => this.dialogs.setOpenDialog('NySak')}>
            <span className='pull-left margin-left-10 '>
              Test opprett ny sak
            </span>
          </button>
          <br /><br />
          <button
            className='btn btn-default width300'
            onClick={() => this.dialogs.setOpenDialog('HentSak')}>
            <span className='pull-left margin-left-10 '>Test hent sak</span>
          </button>
          <br /><br />
          <button
            className='btn btn-default width300'
            onClick={() => this.dialogs.setOpenDialog('NyJP')}>
            <span className='pull-left margin-left-10 '>
              Test opprett ny journalpost
            </span>
          </button>
          <br /><br />
          {contextInnstillingerPerson._currentValue.norkartAdmin && (
            <fragment>
              <button
                className='btn btn-default width300'
                onClick={() => this.dialogs.setOpenDialog('Innstillinger')}>
                <span className='pull-left margin-left-10 '>
                  Vis arkiv innstillinger for kunder
                </span>
              </button>
              <br /><br />
            </fragment>
          )}
          {contextInnstillingerPerson._currentValue.norkartAdmin && (
            <fragment>
              <button
                className='btn btn-default width300'
                onClick={() => this.dialogs.setOpenDialog('NyInnstillinger')}>
                <span className='pull-left margin-left-10 '>
                  (Ny) Vis arkiv innstillinger for kunder
                </span>
              </button>
              <br /><br />
            </fragment>
          )}
          {contextInnstillingerPerson._currentValue.norkartAdmin && (
            <fragment>
              <button
                className='btn btn-default width300'
                onClick={() => this.dialogs.setOpenDialog('Pdftest')}>
                <span className='pull-left margin-left-10 '>
                  Test konverter html til pdf
                </span>
              </button>
              <br /><br />
            </fragment>
          )}
          {contextInnstillingerPerson._currentValue.norkartAdmin && (
            <fragment>
              <button
                className='btn btn-default width300'
                onClick={() => this.dialogs.setOpenDialog('Svarut')}>
                <span className='pull-left margin-left-10 '>SvarUt test</span>
              </button>
              <br /><br />
            </fragment>
          )}
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Test;
