import callApi from './api';
       
export function hentfil(fil, callback) {
    callApi('POST', '/storageblob/hentfil/', fil, callback);
};

export function hentthumbnails(fil, callback) {
    callApi('POST', '/storageblob/hentthumbnails/', fil, callback);
};

export function slettfilmedthumbnail(fil, callback) {
    callApi('POST', '/storageblob/slettfilmedthumbnail/', fil, callback);
};

export function lagrefilmedthumbnail(fil, callback) {
    callApi('POST', '/storageblob/lagrefilmedthumbnail/', fil, callback);
};

export function lagreLogo(fil, callback) {
    callApi('POST', '/storageblob/logo/', fil, callback);
};

export function hentLogo(callback) {
    callApi('GET', '/storageblob/logourl/', null, callback);
};




