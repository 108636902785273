import callApi from './api';


export function getSvarUtOppgaverForBruksenhet(brId, callback) {
    callApi('GET', '/svarut/organisasjon/' + brId, null, callback); 
};

export function getSvarUtOppgaver(callback) {
    callApi('GET', '/svarut/organisasjon/', null, callback); 
};

export function getSvarUtOppgaverFeilet(callback) {
    callApi('GET', '/svarut/organisasjon/feil/', null, callback); 
};

export function getSvarUtDokument(oppgaveid, callback) {
    callApi('GET', '/svarut/dokument/' + oppgaveid, null, callback); 
};

export function sendPaNytt(id, callback) {
    callApi('PUT', '/svarut/sendpanytt/' + id, null, callback);
}

export function getSvarutStatus(shippingIds, callback) {
    callApi('POST', '/svarut/status', shippingIds, callback);
}

export function updateSvarutTilManueltBehandlet(id, callback) {
    callApi('PUT', '/svarut/manueltbehandlet/' + id, null, callback);
}

export function getSvarutLoggsiste30dager(callback) {
    callApi('GET', '/svarut/organisasjoner/', null, callback);
}

export function getSvarutFeilLoggsiste30dager(callback) {
    callApi('GET', '/svarut/organisasjoner/feil/', null, callback);
}

export function getKundekonfigurasjon(kundeId, callback) {
    callApi('GET', '/svarut/' + kundeId + '/kundekonfigurasjon', null, callback);
}

