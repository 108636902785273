import React from 'react';
import _ from 'lodash';
import arbeidslisteApi from 'api/arbeidslisteApi';
import * as enhetApi from 'api/enhetApi.js';
import * as personApi from 'api/personApi';
import * as ildstedApi from 'api/ildstedApi';
import * as royklopApi from 'api/royklopApi';
import { debugFactory, promisifyApiCall, objGetDeep } from 'utils';

const debug = debugFactory('nk:contextGodkjenning');

let selectedArbeidsliste = { id: null, navn: '' };
let arbeidslisteBrukenheter = [];
let situasjon = null;
let selectedArbeidslisteEnhet = null;
let sisteFilterBruker = 0;


export const setContextSelectedArbeidsliste = (id, navn) => {
  selectedArbeidsliste.id = id;
  selectedArbeidsliste.navn = navn;
};

export const setSisteFilterBruker = (brukerId) => {
  sisteFilterBruker = brukerId;
};

export const getSisteFilterBruker = () => {
  return sisteFilterBruker;
};

export const getArbeidslisteEnheter = (id, _callback) => {
  //Alltid fresh data, men setter den i minne. For fra denne listen velges senere situasjon
  promisifyApiCall(enhetApi.getArblisteBrukenheterGjennomforing)(id).then(
    (data) => {
      arbeidslisteBrukenheter = data;
      _callback(data);
    }
  );
};

export const getSituasjon = (enhetId, arbeidslisteId, _callback) =>
  //Alltid fresh data, men setter den i minne.
  promisifyApiCall(enhetApi.getBruksenhetSituasjon)(
    enhetId,
    arbeidslisteId
  ).then((data) => {
    situasjon = data;
    situasjon.arbeidsListeId = arbeidslisteId;

    getMoreProperties(enhetId, situasjon, function(s) {
      _callback(s);
    });
  });

export const getMoreProperties = (enhetId, situasjon, _callback) => {
  if (
    selectedArbeidslisteEnhet &&
    selectedArbeidslisteEnhet.id.toString() === enhetId.toString()
  ) {
    let s = { ...situasjon, ...selectedArbeidslisteEnhet };
    _callback(s);
  } else {
    if (arbeidslisteBrukenheter && arbeidslisteBrukenheter.length > 0) {
      let enhet = arbeidslisteBrukenheter.find(
        (item) => item.id.toString() === enhetId.toString()
      );
      selectedArbeidslisteEnhet = enhet;
      let s =  { ...situasjon,  ...enhet };
      _callback(s);
    } else {
      _callback(situasjon);
    }
  }
};

export const contextGjennomforingSelectedArbeidsliste = React.createContext(
  selectedArbeidsliste
);



