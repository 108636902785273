import * as actionTypes from './actionTypes';

const initialState = {
    organisasjon: {},
    organisasjoner: [],
    orgKommuner: [],
    orgKategorier: [],
    orgKartsenterLokasjon: {
        // Geo Center Norway [lat: 63.990556, lng: 12.307778] @ zoomLevel 5
        latitude: 63.990556,
        longitude: 12.307778,
        zoomLevel: 5
    },
    userPermissions: null,
    userPersonalia: null,
    saksbehandlere: [],
    paalogget: {},
    arkivinnstillinger: null
};

export default (state = initialState, action) => {
    switch (action.type) {
       /*  case actionTypes.UPDATE_USER_PERSONALIA:
            return {
                ...state,
                userPersonalia: (action.payload),
            }; */
      /*   case actionTypes.UPDATE_ORGANISASJON:
            return {
                ...state,
                organisasjon: (action.payload),
                organisasjoner: state.organisasjoner.map(org => org.id !== action.payload.id
                    ? org
                    : action.payload)
            }; */
       /*  case actionTypes.UPDATE_ORG_KARTSENTER_LOKASJON:
            return {
                ...state,
                orgKartsenterLokasjon: (action.payload),
            }; */
         case actionTypes.USER_PERSONALIA_FETCHED:
            return {
                ...state,
                userPersonalia: (action.payload),
            };
        case actionTypes.USER_PERMISSIONS_FETCHED:
            return {
                ...state,
                userPermissions: (action.payload),
            };
        case actionTypes.ORGANISASJON_FETCHED:
            return {
                ...state,
                organisasjon: (action.payload),
            };
        case actionTypes.ORGANISASJONER_FETCHED:
            return {
                ...state,
                organisasjoner: (action.payload),
            };
        case actionTypes.ORG_KARTSENTER_LOKASJON_FETCHED:
            return {
                ...state,
                orgKartsenterLokasjon: (action.payload),
            };
        case actionTypes.ORG_KOMMUNER_FETCHED:
            return {
                ...state,
                orgKommuner: (action.payload),
            };
        case actionTypes.ORG_KATEGORIER_FETCHED:
            return {
                ...state,
                orgKategorier: (action.payload),
            };
        /* case actionTypes.SAKSBEHANDLERE_FETCHED: 
            return {
                ...state,
                saksbehandlere: (action.payload),
                paalogget: (action.payload.find(p => p.paaLogget === true))
            }; */
       /*  case actionTypes.ARKIVINNSTILLINGER_FETCHED:
            return {
                ...state,
                arkivinnstillinger: (action.payload)
            }; */
        default:
            return state;  
    }
};

