import React from 'react';
import Sakstyper from './Sakstyper.jsx';

var type = null;
var id = null;

export default class extends React.Component {
    state = { breadcrumbname: 'Laster....' };

    render() {
        return <div>
            <Sakstyper />
        </div>;
    }
}