import callArkivApi from './apiArkiv';
import callApi from './api';
// var handleError = require('./errorHandler');


// VIA SKYBRANN

function getSakForSaksnummer(saksaar, saksekvensnr, callback) {
    callApi('GET', '/arkiv/sak/' + saksaar + '/' + saksekvensnr, null, callback);
}

function getSakerForMatrikkelsnummer(knr, gnr, bnr, fnr, snr, callback) {
    callApi('GET', '/arkiv/sak/hentSakerForMatrikkelnr/' + knr + '/' + gnr + '/' + bnr + '/' + fnr + '/' + snr, null, callback);
}

function getSakerForKlassering(klasse, callback) {
    callApi('POST', '/arkiv/sak/hentSakerForKlassering/', klasse, callback);
}

function getJournalposterForSak(saksaar, saksekvensnr, callback) {
    callApi('GET', '/arkiv/journalpost/hentJpForSak/' + saksaar + '/' + saksekvensnr, null, callback);
}

function nyArkivSak(sak, callback) {
    callApi('POST', '/arkiv/sak/', sak, callback);
}

function oppdaterSakstatus(saksaar, saksekvensnr, nyStatus, callback) {
    callApi('GET', '/arkiv/sak/oppdaterSakStatus/'+saksaar+'/'+saksekvensnr+'/'+nyStatus, null, callback);
}

function nyUtgJournalpost(jp, callback) {
    callApi('POST', '/arkiv/utgaaendeJournalpost/', jp, callback);
}

function nyManuellUtgJournalpost(jp, callback) {
    callApi('POST', '/arkiv/manuellUtgaaendeJournalpost/', jp, callback);
}

export function pdfGenTest(html, callback) {
    callApi('POST', '/arkiv/pdfgen/', html, callback);
}

export function svarutTest(fil, callback) {
    callApi('POST', '/arkiv/svarut/', fil, callback);
}

export function sjekkSakArkivStatus(brId, callback) {
    callApi('GET', '/arkiv/sjekkSakArkivStatus/'+ brId, null, callback);
}

export function opprettSakOgKobleTilBruksenhet(brId, adr, matrikkelId, callback) {
    callApi('POST', '/arkiv/opprettSakogKoble/' + brId + '/' + adr + '/' + matrikkelId, null, callback);
};


// *** innstillinegr via skybrann ***

// function getAlleSakskategorier(callback) {
//     callApi('GET', '/arkiv/sakskategori/hentAlleSaksKategorier/', null, callback);
// };


// FRA ARKIV API DIREKTE

// ADMIN
function getAlleArkivInnstillinger(requestId, callback) {
    callArkivApi('GET', '/admin/hentAlleArkivInnstillinger/' + requestId, null, callback);
};

function getAlleBrukerInnstillingerForKunde(kundeId, requestId, callback) {
    callArkivApi('GET', '/admin/hentAlleBrukerInnstillingerForKunde/'+ kundeId + '/' + requestId, null, callback);
};

function getSakskategorierForKunde(kundeId, requestId, callback) {
    callArkivApi('GET', '/admin/hentAlleSakskategorierForKunde/'+ kundeId + '/' + requestId, null, callback);
};

// A R K I V  I N N S T I L L I N G E R

function getArkivInnstillinger(requestId, callback) {
    callArkivApi('GET', '/innstillinger/hentArkivInnstilling/' + requestId, null, callback);
};

function nyArkivInnstilling(requestId, innstilling, callback) {
    callArkivApi('POST', '/innstillinger/nyArkivInnstilling/' + requestId, innstilling, callback);
};

function endreArkivInnstilling(requestId, innstilling, callback) {
    callArkivApi('PUT', '/innstillinger/endreArkivInnstilling/' + requestId, innstilling, callback);
};

function slettArkivInnstilling(id, requestId, callback) {
    callArkivApi('DELETE', '/innstillinger/slettArkivInnstilling/'+ id + '/' + requestId, null, callback);
};

// B R U K E R   I N N S T I L L IN G E R

function getAlleBrukerInnstillinger(requestId, callback) {
    callArkivApi('GET', '/brukerinnstillinger/hentAlleBrukerInnstillinger/' + requestId, null, callback);
};

function hentBrukerInnstilling(requestId, callback) {
    callArkivApi('GET', '/brukerinnstillinger/hentBrukerInnstilling/' + requestId, null, callback);
};

function nyBrukerInnstilling(requestId, innstilling, callback) {
    callArkivApi('POST', '/brukerinnstillinger/nyBrukerInnstilling/' + requestId, innstilling, callback);
};

function endreBrukerInnstilling(requestId, innstilling, callback) {
    callArkivApi('PUT', '/brukerinnstillinger/endreBrukerInnstilling/' + requestId, innstilling, callback);
};

function slettBrukerInnstilling(requestId, bruker, callback) {
    callArkivApi('DELETE', '/brukerinnstillinger/slettBrukerInnstilling/'+ bruker + '/' + requestId, null, callback);
};

// K O D E L I S T E 

function getKodeliste(kodelisteNavn, requestId, callback) {
    callArkivApi('GET', '/innstillinger/hentKodeliste/' + kodelisteNavn + '/' + requestId, null, callback);
};

// S A K S T Y P E R

function getAlleSakskategorier(requestId, callback) {
    callArkivApi('GET', '/sakskategori/hentAlleSaksKategorier/' + requestId, null, callback);
};

function getSakskategori(navn, requestId, callback) {
    callArkivApi('GET', '/sakskategori/hentSaksKategori/' +navn + '/' + requestId, null, callback);
};

function saveSakskategori(requestId, sakstype, callback) {
    callArkivApi('POST', '/sakskategori/nySaksKategori/' + requestId, sakstype, callback);
};

function endreSakskategori(requestId, sakstype, callback) {
    callArkivApi('PUT', '/sakskategori/endreSaksKategori/' + requestId, sakstype, callback);
};

function slettSakskategori(requestId, navn, callback) {
    callArkivApi('DELETE', '/sakskategori/slettSaksKategori/'+ navn + '/' + requestId, null, callback);
};

// S A K

function getSakBySaksnummer(saksaar, saksekvensnr, requestId, callback) {
    callArkivApi('GET', '/sak/finnSakGittSaksnr/' + saksaar +'/' + saksekvensnr + '/' + requestId, null, callback);
};

function getSakByMatrikkelnummer(knr, gnr, bnr, fnr, snr, requestId, callback) {
    callArkivApi('GET', '/sak/finnSakerGittMatrikkelnr/'+ knr + '/' + gnr +'/' + bnr + '/' + fnr + '/' + snr + '/' + requestId, null, callback);
};

function getSakByEksternReferanseNoekkel(fagsystem, nokkel, requestId, callback) {
    callArkivApi('GET', '/sak/finnSakGittEksternReferanseNoekkel/' + fagsystem + '/' + nokkel + '/' + requestId, null, callback);
};

function nySak(requestId, sak, callback) {
    callArkivApi('POST', '/sak/nySak/' + requestId, sak, callback);
};

// J O U R N A L P O S T

function nyUtgaaendeJournalpost(requestId, utgaaendeJournalpost, ekspederFoerEndeligStatus, callback) {
    callArkivApi('POST', '/journalpost/nyJournalpostUtgaaende/' + requestId + '?ekspederFoerEndeligStatus=' + ekspederFoerEndeligStatus, utgaaendeJournalpost, callback);
};

function nyInngaaendeJournalpost(requestId, inngaaendeJournalpost, callback) {
    callArkivApi('POST', '/journalpost/nyJournalpostInngaaende/' + requestId, inngaaendeJournalpost, callback);
};

function nyJournalpostInterntNotatUtenOppfoelging(requestId, nyJournalpost, ekspederFoerEndeligStatus, callback) {
    callArkivApi('POST', '/journalpost/nyJournalpostInterntNotatUtenOppfoelging/' + requestId + '/' + ekspederFoerEndeligStatus, nyJournalpost, callback);
};

function nyJournalpostInterntNotatMedOppfoelging(requestId, nyJournalpost, ekspederFoerEndeligStatus, callback) {
    callArkivApi('POST', '/journalpost/nyJournalpostInterntNotatMedOppfoelging/' + requestId + '/' + ekspederFoerEndeligStatus, nyJournalpost, callback);
};

function getJournalposterBySaksnummer(saksaar, saksekvensnr, requestId, callback) {
    callArkivApi('GET', '/journalpost/FinnJournalposterGittSaksnummer/' + saksaar +'/' + saksekvensnr + '/' + requestId, null, callback);
};

function getJournalposterByJournalnummer(journalaar, journalsekvensnr, requestId, callback) {
    callArkivApi('GET', '/journalpost/FinnJournalposterGittJournalnummer/' + journalaar +'/' + journalsekvensnr + '/' + requestId, null, callback);
};

// D O K U M E N T E R

function getDokumenterForJournalpost(journalaar, journalsekvensnr, requestId, callback) {
    callArkivApi('GET', '/dokument/finnDokumenterForJournalpost/' + journalaar +'/' + journalsekvensnr + '/' + requestId, null, callback);
}


export {
    getArkivInnstillinger,
    getAlleSakskategorier,
    getSakskategori,
    getKodeliste,
    saveSakskategori,
    getSakBySaksnummer,
    nyArkivInnstilling,
    nySak,
    endreArkivInnstilling,
    slettArkivInnstilling,
    endreSakskategori,
    slettSakskategori,
    nyUtgaaendeJournalpost,
    nyInngaaendeJournalpost,
    nyJournalpostInterntNotatUtenOppfoelging,
    nyJournalpostInterntNotatMedOppfoelging,
    getAlleBrukerInnstillinger,
    hentBrukerInnstilling,
    nyBrukerInnstilling,
    endreBrukerInnstilling,
    slettBrukerInnstilling,
    getJournalposterBySaksnummer,
    getJournalposterByJournalnummer,
    getDokumenterForJournalpost,
    getSakByMatrikkelnummer,
    getSakByEksternReferanseNoekkel,
    getSakForSaksnummer,
    nyArkivSak,
    nyUtgJournalpost,
    getAlleArkivInnstillinger,
    getAlleBrukerInnstillingerForKunde,
    getSakskategorierForKunde,
    getJournalposterForSak,
    getSakerForKlassering,
    oppdaterSakstatus,
    getSakerForMatrikkelsnummer,
    nyManuellUtgJournalpost
};

export default {
    getArkivInnstillinger,
    getAlleSakskategorier,
    getKodeliste,
    saveSakskategori,
    getSakskategori,
    getSakBySaksnummer,
    nyArkivInnstilling,
    nySak,
    endreArkivInnstilling,
    slettArkivInnstilling,
    endreSakskategori,
    slettSakskategori,
    nyUtgaaendeJournalpost,
    nyInngaaendeJournalpost,
    nyJournalpostInterntNotatUtenOppfoelging,
    nyJournalpostInterntNotatMedOppfoelging,
    getAlleBrukerInnstillinger,
    hentBrukerInnstilling,
    nyBrukerInnstilling,
    endreBrukerInnstilling,
    slettBrukerInnstilling,
    getJournalposterBySaksnummer,
    getJournalposterByJournalnummer,
    getDokumenterForJournalpost,
    getSakByMatrikkelnummer,
    getSakByEksternReferanseNoekkel,
    getSakForSaksnummer,
    nyArkivSak,
    nyUtgJournalpost,
    getAlleArkivInnstillinger,
    getAlleBrukerInnstillingerForKunde,
    getSakskategorierForKunde,
    getJournalposterForSak,
    getSakerForKlassering,
    oppdaterSakstatus,
    getSakerForMatrikkelsnummer,
    nyManuellUtgJournalpost
};


