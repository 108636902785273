import moment from 'moment';
import 'moment/locale/nb';
import DatePicker from 'react-datepicker';
import { getIldstedById } from 'api/ildstedApi';
import * as _ from 'lodash';
import React from 'react';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { isPersonNorkartAdmin } from 'context/contextInnstillinger.js';
import { getContextIldstedOppslag } from 'context/contextOppslag.js';
import 'react-datepicker/dist/react-datepicker.css';
import PrikProdusentModell from 'components/Dialogs/PrikProdusentModell.js';
import { debugFactory } from 'utils';
import royklopApi from 'api/royklopApi';
import OkDialog from 'components/Dialogs/OkDialog.jsx';

const debug = debugFactory('nk:gjennomforing:ildsted');

var endretIldsted = null;
var oppIBruk = false;
var valgtmodelnavn = '';
var tiltaktyper = [];

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        melding={dialogData.melding}
        onJa={owner.lagreJa}
        onNei={onClose}
      />
    );
  },
  DialogRegelmessigeTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Regelmessige tiltak'
        melding='Tilknyttet røykløp har regelmessige tiltak som vil bli slettet hvis du fullfører endringen. Ønsker du å fortsette?'
        onOk={owner.tiltakSjekketLagre}
      />
    );
  },
};

class EndreIldsted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ildsted: null,
      ildstedTypeData: null,
      modeller: null,
      selectedDate: null,
      startDate: null,
      varsel: 'Du har ikke valgt en modell.  Ønsker du å lagre likevel?',
      selectedModell: '',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    if (this.props.nyendre === 'endre') {
      getIldstedById(this.props.ildstedId, this.gotIldsted);
    } else {
      this.createIldSted();
    }
  }

  gotIldsted = (err, ildsted) => {
      this.setState({ ildsted: ildsted });
      endretIldsted = ildsted;
      oppIBruk = ildsted.ibruk;
      this.setState({ selectedModell: ildsted.modellId });
      if (ildsted.installert !== null) {
        this.setState({ selectedDate: moment(ildsted.installert) });
      }
      getContextIldstedOppslag(this.gotIldstedTypeData);
  };

  createIldSted = () => {
    endretIldsted = {
      ibruk: true,
      merknad: '',
      modellId: null,
      plasseringId: null,
      produsentId: null,
      rentbrennende: true,
      royklopId: null,
      typeId: null,
      installert: null,
    };
    this.setState({ ildsted: endretIldsted });
    getContextIldstedOppslag(this.gotIldstedTypeData);
/*     this.props.fetchIldstedOppslagsData().then((ildstedOppslagsData) => {
      this.gotIldstedTypeData(ildstedOppslagsData);
    }); */
  };

  gotIldstedTypeData = (data) => {
    this.setState({ ildstedTypeData: data }, this.nextStep(data));
  };

  gotIldstedTypeDataOld = (data) => {
  };

  nextStep = (ildstedTypeData) => {
    if (this.props.nyendre === 'ny') {
      this.setInitielModel(ildstedTypeData, null);
    } else {
      this.setInitielModel(ildstedTypeData, endretIldsted.produsentId);
    }
  };

  setInitielModel = (ildstedTypeData, produsentId) => {
    var modeller = [];
    if (produsentId !== null) {
      for (var i = 0; i < ildstedTypeData.modellListe.length; i++) {
        //var feil her
        if (
          ildstedTypeData.modellListe[i].fremmedNokkel.toString() ===
          produsentId.toString()
        ) {
          modeller.push(ildstedTypeData.modellListe[i]);
        }
      }
      modeller.unshift({ id: 0, tekst: '', tekst2: '' });
    }
    var d = '';

    if (this.props.nyendre === 'ny') {
      endretIldsted.modellId = null;
      endretIldsted.typeId = null;
      endretIldsted.produsentId = null;
      endretIldsted.plasseringId = null;
      if (modeller.length > 0) {
        d = modeller[0].tekst2;
      }
    } else {
      var modvalgt = _.find(modeller, function(modvalgt) {
        return modvalgt.id === endretIldsted.modellId;
      });
      if (modvalgt) {
        d = modvalgt.tekst2;
      }
    }
    this.setState({
      modeller: modeller,
      doc: d,
    });
  };

  produsentChange = (data) => {
    endretIldsted.produsentId = data.target.value;
    var modeller = [];
    if (data.target.value !== 0) {
      for (var i = 0; i < this.state.ildstedTypeData.modellListe.length; i++) {
        if (
          this.state.ildstedTypeData.modellListe[i].fremmedNokkel.toString() ===
          data.target.value
        ) {
          modeller.push(this.state.ildstedTypeData.modellListe[i]);
        }
      }
      modeller.unshift({ id: 0, tekst: '', tekst2: '' });
    }

    this.setState({ modeller: modeller });

    //selectedModell start med null
    endretIldsted.modellId = null;
    endretIldsted.dokumentasjon = '';
    valgtmodelnavn = '';
    let ildsted = this.state.ildsted;
    ildsted.modellId = 0;
    this.setState({ ildsted: ildsted });
    this.setState({ setInitielModel: '' });
    this.setState({ doc: '' });
    this.setState({ selectedModell: '' });
  };

  modellChangeOld = (data) => {
    console.log('her');
    endretIldsted.modellId = data.target.value;
    if (data.target.value !== 0) {
      var modvalgt = _.find(this.state.modeller, function(modvalgt) {
        return modvalgt.id === data.target.value;
      });
      if (modvalgt) {
        this.setState({ doc: modvalgt.tekst2 });
        valgtmodelnavn = modvalgt.tekst;
        endretIldsted.dokumentasjon = modvalgt.tekst2;
      }
    }
  };

  modellChange = (data) => {
    endretIldsted.modellId = data.target.value;
    this.setState({ selectedModell: data.target.value });
    let ildsted = this.state.ildsted;
    ildsted.modellId = data.target.value;
    this.setState({ ildsted: ildsted });
    valgtmodelnavn = '';
    for (let i = 0; i < this.state.modeller.length; i++) {
      let element = this.state.modeller[i];
      if (element.id == endretIldsted.modellId) {
        valgtmodelnavn = element.tekst;
        this.setState({ doc: element.tekst2 });
        console.log(element.tekst2);
        endretIldsted.dokumentasjon = element.tekst2;
      }
    }
  };
  typeChange = (data) => {
    endretIldsted.typeId = data.target.value;
  };

  plasseringChange = (data) => {
    endretIldsted.plasseringId = data.target.value;
  };

  merknadChange = (data) => {
    endretIldsted.merknad = data.target.value;
  };

  rentbrennendeChange = (data) => {
    endretIldsted.rentbrennende =
      data.currentTarget.value === 'ja' ? true : false;
  };

  ibrukChange = (data) => {
    endretIldsted.ibruk = data.currentTarget.value === 'ja' ? true : false;
  };

  handleDatePickerChange = (date) => {
    console.log(date);
    this.setState({
      selectedDate: date,
    });
    endretIldsted.installert =
      date !== null ? moment(date).format('YYYY-MM-DDTHH:00:00') : null;
  };

  getModell = (modellId) => {
    let modeller = this.state.ildstedTypeData.modellListe;
    for (let i = 0; i < modeller.length; i++) {
      let row = modeller[i];
      if (row.id.toString() === modellId.toString()) {
        return row.tekst;
      }
    }
    return '';
  };

  submit = () => {
    if (
      this.state.selectedModell === '' ||
      this.state.selectedModell === '0' ||
      this.state.selectedModell === null ||
      this.state.selectedModell === 0
    ) {
      valgtmodelnavn = '';
      let varsel =
        'Du har ikke valgt en modell. Ønsker du å lagre likevel? (Produsent blir heller ikke lagret)';
      this.dialogs.setOpenDialog('JaNeiDialog', { melding: varsel });
    } else {
      valgtmodelnavn = this.getModell(this.state.selectedModell);
      this.lagre();
    }
  };

  lagre = () => {
    if (endretIldsted.typeId == 0) {
      endretIldsted.typeId = null;
    }
    if (endretIldsted.modellId == 0) {
      endretIldsted.modellId = null;
      endretIldsted.produsentId = null;
    }
    if (endretIldsted.plasseringId == 0) {
      endretIldsted.plasseringId = null;
    }
    if (endretIldsted.produsentId == 0) {
      endretIldsted.produsentId = null;
    }
    if (this.props.nyendre === 'endre') {
      var ibrukEndret = false;
      if (endretIldsted.ibruk == false && oppIBruk == true) {
        ibrukEndret = true;
      }
      console.log(this.props.royklop);
      if (this.sjekkIldsteder() == 1 && ibrukEndret) {
        royklopApi.getRoyklopTiltakType(
          this.props.royklop.id,
          this.tiltakSjekket
        );
      } else {
        this.props.onEndreIldsted(endretIldsted, valgtmodelnavn);
        this.dialogs.closeDialog();
      }
    } else {
      this.props.onLagreNyttIldsted(endretIldsted, valgtmodelnavn);
      this.dialogs.closeDialog();
    }
    //this.dialogs.closeDialog();
  };

  tiltakSjekket = (err, res) => {
    if (res.length > 0) {
      tiltaktyper = res;
      this.dialogs.setOpenDialog('DialogRegelmessigeTiltak');
    } else {
      this.props.onEndreIldsted(endretIldsted, valgtmodelnavn);
      this.dialogs.closeDialog();
    }
  };

  sjekkIldsteder = () => {
    let result = 0;
    let r = this.props.royklop;
    r.ildstedListe.forEach((i) => {
      if (i.ibruk) {
        result = result + 1;
      }
    });
    return result;
  };

  lagreJa = () => {
    this.lagre();
    this.dialogs.closeDialog();
  };

  tiltakSjekketLagre = () => {
    tiltaktyper.forEach((element) => {
      royklopApi.slettRoyklopTiltakType(element.id, this.slettet);
    });

    this.props.onEndreIldsted(endretIldsted, valgtmodelnavn);
    this.dialogs.closeDialog();
  };

  slettet = (err, res) => {};

  onValgtModell = (item) => {
    debug('FISH', item);
    this.setState({ selectedModell: item.modellId, doc: item.dokumentasjon });
    endretIldsted.produsentId = item.produsentId;
    endretIldsted.modellId = item.modellId;
  };

  render() {
    if (
      !this.state.ildsted ||
      !this.state.ildstedTypeData ||
      !this.state.modeller
    ) {
      return <div className='loader'>Laster...</div>;
    }

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {this.props.nyendre === 'ny' ? 'Nytt ildsted' : 'Endre ildsted'}
          </h4>
        </div>
        <div className='modal-body'>
          <form>
            <div className='panel panel-default'>
              <div className='panel-heading'>Produktinfo</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Type</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.typeChange}
                        defaultValue={
                          this.props.nyendre === 'endre'
                            ? this.state.ildsted.typeId
                            : null
                        }>
                        {_.map(this.state.ildstedTypeData.typeListe, function(
                          o,
                          i
                        ) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Produsent</label>
                      <select
                        className='form-control'
                        id='produsent'
                        onChange={this.produsentChange}
                        defaultValue={
                          this.props.nyendre === 'endre'
                            ? this.state.ildsted.produsentId
                            : null
                        }>
                        {_.map(
                          this.state.ildstedTypeData.produsentListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Modell</label>
                      <select
                        className='form-control'
                        id='modell'
                        onChange={this.modellChange}
                        value={this.state.selectedModell}
                        /*  defaultValue={
                          this.props.nyendre === 'endre'
                            ? this.state.ildsted.modellId
                            : null
                        } */
                      >
                        {_.map(this.state.modeller, function(o, i) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <p />

           {/*      {isPersonNorkartAdmin && (
                  <>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className='form-group'>
                          <label>Type</label>
                          <select
                            className='form-control'
                            id='type'
                            onChange={this.typeChange}
                            defaultValue={
                              this.props.nyendre === 'endre'
                                ? this.state.ildsted.typeId
                                : null
                            }>
                            {_.map(
                              this.state.ildstedTypeData.typeListe,
                              function(o, i) {
                                return (
                                  <option key={i} value={o.id}>
                                    {o.tekst}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                      <div className='col-sm-8'>
                        <div className='form-group '>
                          <label>Produsent / Modell</label>
                          <PrikProdusentModell
                            type='Ildsted'
                            width='330px'
                            value={this.state.ildsted.modellId}
                            onValgtModell={this.onValgtModell}
                          />
                        </div>
                      </div>
                    </div>
                    <p />
                  </>
                )} */}

                <div className='row'>
                  <div className='col-sm-12'>
                    <label>Dokumentasjon</label>
                    <br />
                    {this.state.doc != '' && this.state.doc != null ? (
                      <a href={this.state.doc} target='blank'>
                        {this.state.doc.length > 60
                          ? this.state.doc.substring(0, 60) + '...'
                          : this.state.doc}
                      </a>
                    ) : (
                      <span>Ikke tilgjengelig</span>
                    )}
                    {/* {<validUrl className="isUrl"></validUrl>(this.state.doc) ? (
                      <a href={this.state.doc} target="_blank">
                        {this.state.doc.substring(0, 60) + '...'}
                      </a>
                    ) : (
                      <span>Ikke tilgjengelig</span>
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div className='panel panel-default'>
              <div className='panel-heading'>Annet</div>
              <div className='panel-body'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Plassering</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={this.plasseringChange}
                        defaultValue={this.state.ildsted.plasseringId}>
                        {_.map(
                          this.state.ildstedTypeData.plasseringListe,
                          function(o, i) {
                            return (
                              <option key={i} value={o.id}>
                                {o.tekst}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Rentbrennende</label>
                      <br />
                      <div className='radio inline marginleft10'>
                        <label>
                          <input
                            style={{ left: '16px' }}
                            type='radio'
                            name='rentRadio'
                            value='ja'
                            defaultChecked={
                              this.state.ildsted.rentbrennende === true
                            }
                            onChange={this.rentbrennendeChange}
                          />
                          Ja
                        </label>
                      </div>
                      <div className='radio inline marginleft30'>
                        <label>
                          <input
                            style={{ left: '16px' }}
                            type='radio'
                            name='rentRadio'
                            value='nei'
                            defaultChecked={
                              this.state.ildsted.rentbrennende === false
                            }
                            onChange={this.rentbrennendeChange}
                          />
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>I bruk</label>
                      <br />
                      <div className='radio inline marginleft10'>
                        <label>
                          <input
                            style={{ left: '16px' }}
                            type='radio'
                            name='ibrukRadio'
                            value='ja'
                            defaultChecked={this.state.ildsted.ibruk === true}
                            onChange={this.ibrukChange}
                          />
                          Ja
                        </label>
                      </div>
                      <div className='radio inline marginleft30'>
                        <label>
                          <input
                            style={{ left: '16px' }}
                            type='radio'
                            name='ibrukRadio'
                            value='nei'
                            defaultChecked={this.state.ildsted.ibruk === false}
                            onChange={this.ibrukChange}
                          />
                          Nei
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <label>Installert dato</label>
                      <br />
                      <DatePicker
                        className='hoyde34'
                        selected={this.state.selectedDate}
                        onChange={this.handleDatePickerChange}
                        isClearable={true}
                        style={{ border: '1px solid #ccc' }}
                        showYearDropdown
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <label>Merknad</label>
                    <textarea
                      className='form-control textareaNoresize'
                      type='textarea'
                      id='message'
                      placeholder='Merknad'
                      maxLength='140'
                      rows='4'
                      onChange={this.merknadChange}
                      defaultValue={this.state.ildsted.merknad}
                    />
                  </div>
                </div>
                <br />
                <div className='row'>
                  <div className='col-sm-12 col-lg-12 col-md-12'>
                    <div className='pull-right'>
                      <input
                        type='button'
                        className='btn btn-primary height48'
                        value='OK'
                        onClick={this.submit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}


export default EndreIldsted;
