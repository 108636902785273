import React from 'react';
import EndreBrukere from './EndreBrukere.jsx';

class index extends React.Component {
  constructor() {
    super();
  }  
  render() {
    return <EndreBrukere norkartBrukere={true}></EndreBrukere>;
  }
}
export default index;
