import React from 'react';
import TiltakstyperTable from './TiltakstyperTable';

export default class extends React.Component {
  state = {
    init: false,
    breadcrumbname: 'Laster....',
    type: this.props.location ? this.props.location.state.type : this.props.type,
    id: this.props.location ? this.props.location.state.id : this.props.id,
    royklop: this.props.location ? this.props.location.state.royklop : this.props.royklop,
    brId: this.props.location ? this.props.location.state.brId : this.props.brId,
    orgRisiko: this.props.location ? this.props.location.state.orgRisiko : this.props.orgRisiko
  };

  
  componentDidMount() {
    //SteinType: her har jeg litt ekstra tid for å være sikkert at type har kommet på plass.
    setTimeout(() => {
      this.setState({ init: true });
    }, 1000); 
  };

  render() {
    if (!this.state.init){
      //SteinType
      return <div className="loader" />;
    }
    return (
      <div>
        <TiltakstyperTable
          type={this.state.type}
          id={this.state.id ? this.state.id : this.props.id}
          royklop={this.state.royklop ? this.state.royklop : this.props.royklop}
          brId={this.state.brId}
          orgRisiko={this.state.orgRisiko}
          base_path={
            this.props.params
              ? '/gjennomforing/' +
                this.props.params.arbeidslisteId +
                '/' +
                this.props.params.arbeidslisteNavn
              : null
          }
        />
      </div>
    );
  }
}
