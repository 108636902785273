import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { 
    debugFactory, 
    objExtractProps,
    validatableMoment
} from 'utils';

const debug = debugFactory('nk:components:restricteddatepicker');

const RestrictedDatePicker = (origProps) => {
    /*
    FIXME! React 16 + react-datepicker v > 1.8.0 ?
    Workaround for limitation in `react-datepicker` that makes it impossible to 
    programatically clear the input-element value, which should be done onBlur when
    the value not is a valid date-string...
    */
    const props = { ...origProps };

    const { selected, onBlur } = objExtractProps(props, [
        'selected',
        'value',
        'onBlur'
    ]);

    const isValid = validatableMoment(selected).isValid();

    if (isValid) {
        props.selected = selected;
    }
    else {
        props.value = '';
    }

    if (onBlur) {
        props.onBlur = ({ target }) => onBlur(target.value);
    }

    debug('render', { isValid, props })

    return (<DatePicker {...props} />);
}

export default RestrictedDatePicker;