import React from 'react';
import { debugFactory } from 'utils';
import './maleditor.css';
import grid from 'images/Annet/gridB.png';
import rektangel from 'images/Annet/rektangelB.png';

const debug = debugFactory('nk:Borders');

class TabellBorderDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rek: ' active',
      grid: '',
      gridModus: false,
      borderWidth: '1px',
      borderStyle: 'dotted',
      borderColor: 'Gray',
      border: '1px dotted LightGray',
      hasBorder: false
    };
  }

  componentDidMount = () => {
    debug('TTABLE PROPS ELEMENT', this.props.element);
    let style = this.props.element.style;
    let border = style.border;

    if (border) {
      this.setState({ hasBorder: true });
      this.setState({ border });
      debug('TTABLE HAS BORDER', border);
      this.setState({ border });
      let styleListe = style.border.split(' ');
      if (styleListe.length == 3) {
        if (styleListe[0].includes('px')) {
          this.setState({ borderWidth: styleListe[0] });
          this.setState({ borderStyle: styleListe[1] });
          this.setState({ borderColor: styleListe[2] });
        }
      }
    } else {
      debug('TTABLE HAS NO BORDER', this.state.border);
    }
  };

  settBorderOgClose = () => {
    let resultBorder = this.state.border;
    if (this.state.border === '1px dotted LightGray') {
      resultBorder = null;
      debug('TTABLE NO BORDER');
    } 
    
    if (this.state.gridModus) {
      debug('TTABLE TABLE', this.props.element);
      let table = this.props.element;
      table.style.border = resultBorder;

      let rows = table.rows;
      debug('TTABLE TABLE', rows);
      for (let i = 0; i < rows.length; i++) {
        let celler = rows[i].cells;
        for (let j = 0; j < celler.length; j++) {
          let celle = celler[j];
          celle.style.border = resultBorder;
        }
      }
    } else {
      debug('TTABLE ELEMENT', this.props.element);
      this.props.element.style.border = resultBorder;
    }
    this.props.onClose();
  };

  settBorderNixVedSkrivUt = () => {
    this.setState({ hasBorder: false });
    this.setState({ borderWidth: '1px' });
    this.setState({ borderStyle: 'dotted' });
    this.setState({ borderColor: 'Gray' });
    this.setState({ border: '1px dotted LightGray' });
  };

  gridModus = (modus) => {
    this.setState({ rek: '', grid: '' });
    this.setState({ gridModus: modus });
    if (modus) {
      this.setState({ grid: ' aktive' });
    } else {
      this.setState({ rek: ' aktive' });
    }
  };

  checkWidth = () => {
    let x = this.state.borderWidth;
    if (x === '1px') {
      this.setState({ borderWidth: '2px' });
    }
  };

  sammenSettBorder = () => {
    let border =
      this.state.borderWidth +
      ' ' +
      this.state.borderStyle +
      ' ' +
      this.state.borderColor;
    this.setState({ border });
  };

  setFarge = (f) => {
    this.setState({ hasBorder: true });
    let border =
      this.state.borderWidth + ' ' + this.state.borderStyle + ' ' + f;
    debug('TTABLE F', border);
    this.setState({ border });
    this.setState({ borderColor: f });
  };

  setBredde = (b) => {
    this.setState({ hasBorder: true });
    let border =
      b + ' ' + this.state.borderStyle + ' ' + this.state.borderColor;
    debug('TTABLE B', border);
    this.setState({ border });
    this.setState({ borderWidth: b });
  };

  setStil = (s) => {
    this.setState({ hasBorder: true });
    let border =
      this.state.borderWidth + ' ' + s + ' ' + this.state.borderColor;
    debug('TTABLE S', border);
    this.setState({ border });
    this.setState({ borderStyle: s });
  };

  render() {
    return (
      <div
        style={{ height: '570px', width: '552px' }}
        className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {' '}
            Velg kantlinje for {this.props.elementNavn}{' '}
          </h4>
        </div>
        <div
          className='modal-body'
          style={{ height: '450px', padding: '20px' }}>
          <div className='btn-group'>
            <button
              style={{ width: '240px', height: '40px' }}
              className='btn btn-default pull-left'
              onClick={() => this.settBorderNixVedSkrivUt()}>
              Gjør usynlig ved utskrift
            </button>

            {this.props.elementNavn === ' tabell' && (
              <React.Fragment>
                <button
                  style={{
                    width: '90px',
                    height: '40px',
                    marginLeft: '72px',
                  }}
                  title='ytterste kantlinje (boks)'
                  className={'btn btn-default' + this.state.rek}
                  onClick={() => this.gridModus(false)}>
                  <div>
                    <img height='26px' src={rektangel} />
                  </div>
                </button>
                <button
                  style={{ width: '90px', height: '40px', marginLeft: '4px' }}
                  title='Alle celler (rutenett)'
                  className={'btn btn-default' + this.state.grid}
                  onClick={() => this.gridModus(true)}>
                  <div>
                    <img height='26px' src={grid} />
                  </div>
                </button>
              </React.Fragment>
            )}
          </div>
          <br />
          <br />
          <p>Kantlinje farge</p>

          <div className='btn-group'>
            <button
              type='button'
              className='btn '
              style={{
                backgroundColor: 'black',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('black')}
            />
            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'orange',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('orange')}
            />

            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'gray',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('gray')}
            />
            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'blue',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('blue')}
            />
            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'red',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('red')}
            />
            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'green',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('green')}
            />
            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'DeepPink',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('deepPink')}
            />
            <button
              type='button'
              className='btn'
              style={{
                backgroundColor: 'brown',
                width: '60px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setFarge('brown')}
            />
          </div>
          <br />
          <br />
          <p>Kantlinje stil</p>

          <div className='btn-group'>
            <button
              type='button'
              className='btn btn-default'
              style={{
                backgroundColor: 'hvite',
                border: '4px solid black',
                width: '123px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setStil('solid')}
            />
            <button
              type='button'
              className='btn btn-default'
              style={{
                backgroundColor: 'hvite',
                border: '4px dashed black',
                width: '123px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setStil('dashed')}
            />

            <button
              type='button'
              className='btn btn-default'
              style={{
                backgroundColor: 'hvite',
                border: '4px double black',
                width: '123px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setStil('double')}
            />
            <button
              type='button'
              className='btn btn-default'
              style={{
                backgroundColor: 'hvite',
                border: '4px dotted black',
                width: '123px',
                height: '30px',
                marginRight: '4px',
              }}
              onClick={() => this.setStil('dotted')}
            />
          </div>
          <br />
          <br />
          <p>Kantlinje bredde</p>

          <div className='btn-group'>
        {/*   <button
              type='button'
              className='btn btn-default'
              style={{
                border: '1px solid black',
                width: '123px',
                height: '42px',
                marginRight: '4px',
                paddingTop: '2px',
              }}
              onClick={() => this.setBredde('1px')}>
              Tynn ( 1px )
            </button> */}
            <button
              type='button'
              className='btn btn-default'
              style={{
                border: '2px solid black',
                width: '165px',
                height: '42px',
                marginRight: '4px',
                paddingTop: '2px',
              }}
              onClick={() => this.setBredde('2px')}>
              Normal ( 2px )
            </button>
            <button
              type='button'
              className='btn btn-default'
              style={{
                border: '3px solid black',
                width: '165px',
                height: '42px',
                marginRight: '4px',
                paddingTop: '2px',
                paddingLeft: '3px',
              }}
              onClick={() => this.setBredde('3px')}>
              Medium ( 3px )
            </button>

            <button
              type='button'
              className='btn btn-default'
              style={{
                border: '4px solid black',
                width: '165px',
                height: '42px',
                marginRight: '4px',
                paddingTop: '2px',
              }}
              onClick={() => this.setBredde('4px')}>
              Tykk ( 4px )
            </button>
          </div>
          <br />
         
          <br />
          <p>
          {!this.state.hasBorder && (
            <span>{'Eksempel ' + this.props.elementNavn +'  ( ikke synlig ved utskrift )'}</span>
          )}
          {this.state.hasBorder && (
            <span>{'Eksempel ' + this.props.elementNavn}</span>
          )}
          </p>
          {!this.state.gridModus && (
            <table
              style={{
                height: '60px',
                width: '98%',
                border: this.state.border,
              }}>
              <tbody>
                <tr>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                </tr>
              </tbody>
            </table>
          )}
          {this.state.gridModus && (
            <table
              style={{
                width: '98%',
                height: '60px',
                border: this.state.border,
              }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: this.state.border,
                    }}
                  />
                  <td
                    style={{
                      border: this.state.border,
                    }}
                  />
                </tr>
                <tr>
                  <td
                    style={{
                      border: this.state.border,
                    }}
                  />
                  <td
                    style={{
                      border: this.state.border,
                    }}
                  />
                </tr>
              </tbody>
            </table>
          )}
        </div>
        <div className='modal-footer' style={{ height: '50px' }}>
          <span style={{ fontSize: '10px', color: 'LightGray'}} className='pull-left'>{this.state.border}</span>
          <button
            style={{ width: '60px', height: '40px' }}
            className='btn btn-success pull-right'
            onClick={this.settBorderOgClose}>
            OK
          </button>
        </div>
      </div>
    );
  }
}

export default TabellBorderDialog;
