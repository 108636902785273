import React from 'react';
import './AvvikAnmerkninger.css';
import { nyHurtigdel } from 'api/malerApi';

class LagreTekstbit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Lagre tekst for senere bruk?',
      tiitel: '',
      sporsmal1: 'Ønsker du å ta vare på teksten som du har merket ut?',
      sporsmal2: '',
      tekst: '',
    };
  }

  componentDidMount() {
    if (this.props.merkettekst === '') {
      this.setState({
        sporsmal1: 'Du har IKKE merket ut noe.',
        sporsmal2: 'Ønsker du å ta vare på HELE teksten?',
        tekst: this.props.tekst,
      });
    } else {
      this.setState({ tekst: this.props.merkettekst });
    }
  }

  lagre() {
    var tekst = this.state.tekst;
    var nyTekst = { id: 0, tekst: tekst, aktiv: true, tittel: this.state.tittel };

    nyHurtigdel(nyTekst, this.lagretTekst.bind(this));
  }

  onClose() {
    console.log('************ close 2 ************************');
    this.props.onClose();
  }

  lagretTekst() {
    this.props.onLagret();
  }

  render() {
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button
            className='close'
            type='button'
            onClick={this.onClose.bind(this)}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {this.state.title} </h4>
        </div>
        <div className='modal-body divTekstbiter'>
          <label>{this.state.sporsmal1}</label>
          <br />
          <label>{this.state.sporsmal2}</label>
          <br />
          <br />
          <div className='tekstbit'>{this.state.tekst}</div>
          <br />
          <br />
          <label>Tittel</label>
          <br />
          <input
            autoFocus
            placeholder='Tittel'
            maxLength='500'
            value={this.state.tittel}
            className='form-control'
            type='text'
            style={{ fontSize: '16px' }}
            onChange={(e) => {
              this.setState({ tittel: e.target.value });
            }}
          />
          <br />
          <br />
          <div className='btn-toolbar pull-right' role='toolbar'>
            {/*                             <button type="button" onClick={this.props.onClose} className="btn btn-default h48">
                            &nbsp;&nbsp;&nbsp;Nei&nbsp;&nbsp;&nbsp;</button>  */}
            <button
              type='button'
              onClick={this.lagre.bind(this)}
              className='btn btn-primary h48'>
              &nbsp;&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default LagreTekstbit;
