import React from 'react';
import SmsLogg from 'modules/felles/Sms/SmsLogg.jsx';


class index extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }
    
  render() {
    return <SmsLogg alle = {true} org = {false}></SmsLogg>;
  }
}
export default index;