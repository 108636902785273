import React, { Component } from 'react';
import omradeSoneApi from 'api/omradeSonerApi';
import _ from 'underscore';
import NKIcon from 'nka-icons';

class RedigerOmrader extends Component {
  constructor(props) {
    super(props);
    this.state = { omrader: [], soner: [], soneriBruk: [], slettListe: [] };
    this.lagre = this.lagre.bind(this);
    this.edit = this.edit.bind(this);
    this.angre = this.angre.bind(this);
    this.slett = this.slett.bind(this);
    this.findSonerIBruk = this.findSonerIBruk.bind(this);
  }

  componentDidMount() {
    console.log('Mount');
    omradeSoneApi.getAlleOmrader(this.onGotAlleOmrader.bind(this));
    omradeSoneApi.getAlleSoner(this.onGotAlleSoner.bind(this));
  }

  onGotAlleOmrader(err, data) {
    let omrader = data;
    omrader = _.sortBy(omrader, 'navn');
    this.setState({ omrader: omrader });
  }

  onGotAlleSoner(err, data) {
    this.setState({ soner: data });
    this.findSonerIBruk();
  }

  findSonerIBruk() {
    let alleSoner = _.groupBy(this.state.soner, 'omradeId');
    let soner = Object.keys(alleSoner);
    this.setState({ soneriBruk: soner });
  }

  edit(omrade) {
    let id = omrade.id;
    let ref = 'lab' + id;
    let el = this.refs[ref];
    el.setAttribute('class', 'divLabel hide');
    let ref2 = 'edit' + id;
    let el2 = this.refs[ref2];
    el2.setAttribute('class', 'iedit');
    let ref3 = 'btn' + id;
    let el3 = this.refs[ref3];
    el3.setAttribute('class', 'btn btn-default bedit hide');

    if (omrade.ny === true) {
      let ref5 = 'slett' + id;
      let el5 = this.refs[ref5];
      el5.setAttribute('class', 'btn btn-default bedit');
    } else {
      let ref4 = 'angre' + id;
      let el4 = this.refs[ref4];
      el4.setAttribute('class', 'btn btn-default bedit');
    }
  }

  angre(id, navn) {
    let ref = 'lab' + id;
    let el = this.refs[ref];
    el.setAttribute('class', 'divLabel ');
    let ref2 = 'edit' + id;
    let el2 = this.refs[ref2];
    el2.setAttribute('class', 'iedit hide');
    el2.defaultValue = navn;
    el2.value = navn;
    let ref3 = 'btn' + id;
    let el3 = this.refs[ref3];
    el3.setAttribute('class', 'btn btn-default bedit');
    let ref4 = 'angre' + id;
    let el4 = this.refs[ref4];
    el4.setAttribute('class', 'btn btn-default bedit hide');
  }

  lagre() {
    let endreListe = [];
    for (var ref in this.refs) {
      let klasse = this.refs[ref].getAttribute('class');
      if (klasse === 'iedit') {
        let endret = { id: ref.substring(4), navn: this.refs[ref].value };
        endreListe.push(endret);
      }
    }
    this.props.omraderChanged(endreListe, this.state.slettListe);
  }

  slett(omrade) {
    let omrader = this.state.omrader;
    let slettListe = this.state.slettListe;
    for (var i = 0; i < omrader.length; i++) {
      if (omrader[i].id === omrade.id) {
        omrader[i].slett = true;
        slettListe.push(omrader[i]);
      }
    }
    this.setState({ slettListe: slettListe });
    this.setState({ omrader: omrader });
  }

  keyDown = (event) => {
     const pressedChar = event.key;
      // Allow only numbers and letters (alphabets)
      if (!/[A-Za-z0-9\sÅÆØåæø]/.test(pressedChar)) {
      event.preventDefault();
    }
  }


  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title"> Rediger områder </h4>
        </div>
        <div className="modal-body">
          <div className="form-group regionerScroll2">
            <span>
              {this.state.omrader.map( function(omrade) {
                if (omrade.slett !== true) {
                  var bb = 'key' + omrade.id;
                  var d1 = 'd1' + omrade.id;
                  var ref = 'ref' + omrade.id;
                  var tt = omrade.navn;
                  var hide = 'btn btn-default bsletto';
                  if (
                    this.state.soneriBruk.indexOf(omrade.id.toString()) > -1
                  ) {
                    hide = 'btn btn-default bsletto hide';
                    console.log('I bruk');
                  }

                  var html;
                  html = (
                    <div key={bb} ref={ref}>
                      <table key={d1} className="table100">
                        <tbody>
                          <tr>
                            <td className="td80">
                              <label
                                ref={'lab' + omrade.id}
                                className="divLabel">
                                {omrade.navn}
                              </label>
                              <input
                                ref={'edit' + omrade.id}
                                key={omrade.id}
                                type="text"
                                defaultValue={tt}
                                className="iedit hide"
                                onKeyDown={(evt) => {this.keyDown(evt);}}
                              />
                            </td>
                            <td className="tdRight">
                              <button
                                ref={'btn' + omrade.id}
                                className="btn btn-default bedit"
                                onClick={this.edit.bind(this, omrade)}>
                                <NKIcon icon="rediger" color="nk-black" />
                              </button>
                              <button
                                ref={'angre' + omrade.id}
                                className="btn btn-default bedit hide"
                                onClick={this.angre.bind(
                                  this,
                                  omrade.id,
                                  omrade.navn
                                )}>
                                <NKIcon icon="venstrepil" color="nk-black" />
                              </button>
                              <button
                                className={hide}
                                onClick={this.slett.bind(this, omrade)}>
                                X
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                  return html;
                }
              }, this)}
            </span>
          </div>
        </div>
        <div className="modal-footer">
          <span style={{ color: 'blue' }} className='pull-left'>Bruk kun bokstaver og nummer.</span>
          <button className="btn btn-primary" onClick={this.lagre}>
            OK
          </button>
        </div>
      </div>
    );
  }
}

export default RedigerOmrader;
