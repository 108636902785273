import React from 'react';
import LoggAutomatisering from './LoggAutomatisering.jsx';

class index extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }  
  render() {
    return <LoggAutomatisering></LoggAutomatisering>;
  }
}
export default index;