import React, { Component } from 'react';
import ContextMenu from 'components/ContextMenu';
import ReactModal from 'react-modal';
import ReactTable from '@norkart/react-table';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import './svarutlogg.css';
import {
  getSvarUtOppgaver,
  getSvarUtOppgaverForBruksenhet,
  getSvarUtDokument,
  sendPaNytt,
  getSvarutStatus,
  updateSvarutTilManueltBehandlet,
  getSvarutLoggsiste30dager,
  getSvarutFeilLoggsiste30dager,
} from 'api/svarUtApi';
import VisDokumentRO from 'components/Editor/VisDokumentRO.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';


const modalDialogs = {
  VisDokumentDialog: ({ isOpen, onClose, dialogData }) => (
    <ReactModal isOpen={isOpen} className='malModalSvarut'>
      <VisDokumentRO
        onClose={onClose}
        id={dialogData.id}
        model={dialogData.model}
      />
    </ReactModal>
  ),
  SendPaNyttDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Send til SvarUt på nytt?'
      melding={dialogData.melding}
      isOpen={isOpen}
      onOk={owner.sendOk.bind(this, dialogData.item)}
      onClose={onClose}
    />
  ),
  GammelDialog: ({ isOpen, onClose }) => (
    <OKDialog
      tittel='Sjekk status'
      melding='Status kan ikke sjekkes på "gamle" SvarUt-hendelser.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  IngenRadDialog: ({ isOpen, onClose }) => (
    <OKDialog
      tittel='Sjekk status'
      melding='Ikke nødvendig å sjekke.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
};

//knapp på toppen sjekk status new Rieni

class SvarUtLogg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataHentet: false,
      data: null,
      item: null,
      laster: false,
      tittel: '',
      columns: [],
      visInfo: false,
      infoTekst: 'Vis info om "Status KS"',
    };

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    this.hentData();
  }

  hentData = () => {
    this.setState({ laster: true });
    switch (this.props.fra) {
      case 'fraBruksenhetTabell':
        this.setState({
          tittel: 'Oversikt SvarUt oppgaver ' + this.props.adresse,
        });
        this.setState({ columns: this.getColumnsInnstillinger() });
        getSvarUtOppgaverForBruksenhet(this.props.id, this.dataHentet);
        break;
      case 'fraSituasjon':
        this.setState({
          tittel: 'Oversikt SvarUt oppgaver ' + this.props.adresse,
        });
        this.setState({ columns: this.getColumnsInnstillinger() });
        getSvarUtOppgaverForBruksenhet(this.props.id, this.dataHentet);
        break;
      case 'fraNkAdmin':
        this.setState({ tittel: 'Oversikt SvarUt oppgaver siste 30 dager' });
        this.setState({ columns: this.getColumnsNkAdmin() });
        getSvarutLoggsiste30dager(this.dataHentet);
        break;
      case 'fraNkAdminFeil':
        this.setState({ columns: this.getColumnsNkAdminFeil() });
        this.setState({
          tittel: 'Oversikt SvarUt oppgaver som feilet siste 30 dager',
        });
        getSvarutFeilLoggsiste30dager(this.dataHentet);
        break;
      case 'fraInnstillinger':
        this.setState({ columns: this.getColumnsInnstillinger() });
        this.setState({ tittel: 'Oversikt SvarUt oppgaver' });
        getSvarUtOppgaver(this.dataHentet);
        break;
      default:
        this.setState({ columns: this.getColumnsInnstillinger() });
        this.setState({ tittel: 'Oversikt SvarUt oppgaver' });
        getSvarUtOppgaver(this.dataHentet);
        break;
    }
  };

  dataHentet = (err, data) => {
    this.setState({ laster: false });

    //Test
/*     if (data) {
      if (data[2]) {
        data[2].feil =
          'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.'  +
          'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. ';
      }
    }
    debug('mandag1', data); */
    this.setState({ data: data, dataHentet: true });
  };

  onClickVis = (item) => {
    this.setState({ testId: item.id });
    getSvarUtDokument(item.id, this.dokumentHentet);
  };

  dokumentHentet = (err, data) => {
    let model = data.tekst;
    if (model) {
      this.dialogs.setOpenDialog('VisDokumentDialog', {
        model: model,
        id: this.state.testId,
      });
    } else {
      alert('Ingen dokument..');
    }
  };

  onClickSend = (item) => {
    this.setState({ item: item });
    let m =
      'Du er i ferd med å sende dokumentet til mottaker via SvarUt på nytt. Vil du fortsette?';
    this.dialogs.setOpenDialog('SendPaNyttDialog', { melding: m, item: item });
  };

  sendOk = (item) => {
    sendPaNytt(item.id, this.sendtPaNytt);
  };

  sendtPaNytt = () => {
    this.dialogs.closeDialog();
    this.hentData();
  };

  onClickManuell = (item) => {
    updateSvarutTilManueltBehandlet(item.id, this.hentData);
  };

  visSendPaNytt = (item) => {
    if (item.status === 'Feilet') {
      return (
        <>
          <ContextMenu.Item onClick={this.onClickSend.bind(this, item)}>
            Send på nytt
          </ContextMenu.Item>
        </>
      );
    } else {
      return '';
    }
  };

  visStatus = (item) => {
    if (item.status === 'Sendt') {
      if (this.props.fra.includes('Admin')) {
        return (
          <>
            {/*  <ContextMenu.Item onClick={this.onClickStatus.bind(this, item)}>
              Sjekk status Old
            </ContextMenu.Item> */}
            <ContextMenu.Item onClick={this.onClickStatusNew.bind(this, item)}>
              Sjekk status
            </ContextMenu.Item>
          </>
        );
      } else {
        return (
          <>
            <ContextMenu.Item onClick={this.onClickStatusNew.bind(this, item)}>
              Sjekk status
            </ContextMenu.Item>
          </>
        );
      }
    } else {
      return '';
    }
  };

  visManuell = (item) => {
    if (item.status === 'Feilet') {
      return (
        <ContextMenu.Item onClick={this.onClickManuell.bind(this, item)}>
          Avslutt (manuelt håndtert)
        </ContextMenu.Item>
      );
    } else {
      return '';
    }
  };

  onClickStatus = (item) => {
    this.setState({ laster: true });
    let idList = [item.svarUtGuid];
    getSvarutStatus(idList, this.gotStatusRadOld);
  };

  gotStatusRadOld = (err, res) => {
    this.setState({ laster: false });
    if (res != null && res !== undefined) {
      let tmp = this.state.data;
      let dato = res.svarUtGuidStatusDato;
      res.svarUtStatus.forEach((s) => {
        tmp.forEach((e) => {
          if (e.svarUtGuid === s.shippingId) {
            e.svarUtGuidStatus = s.status;
            e.svarUtGuidStatusDato = dato;
          }
        });
      });
      this.setState({ data: tmp });
    }
  };

  onClickStatusNew = (item) => {
    if (!item.nkShippingGuid) {
      this.dialogs.setOpenDialog('GammelDialog');
    } else {
      this.setState({ laster: true });
      let idList = [item.nkShippingGuid];
      getSvarutStatus(idList, this.gotStatusNewRad);
    }
  };

  gotStatusNewRad = (err, res) => {
    this.setState({ laster: false });
    if (res != null && res !== undefined) {
      let data = this.state.data;
      let nyDato = res.svarUtGuidStatusDato;
      let nyStatus = res.svarUtStatusv2[0].status;
      let nyNKStatus = res.svarUtStatusv2[0].nkStatus;
      let id = res.svarUtStatusv2[0].nkShippingId;

      for (let i = 0; i < data.length; i++) {
        if (data[i].nkShippingGuid) {
          if (data[i].nkShippingGuid === id) {
            data[i].svarUtGuidStatus = nyStatus;
            data[i].svarUtGuidStatusDato = nyDato;
            data[i].nkStatus = nyNKStatus;
          }
        }
      }

      this.setState({ data });
    }
  };

  rowFormat = (item) => {
    if (item.status === 'Sendt') {
      return { style: { backgroundColor: '#dcedc0' } };
    } else if (item.status === 'Feilet') {
      return { style: { backgroundColor: '#FDD9D6' } };
    } else if (item.status === 'Manuelt behandlet') {
      return { style: { backgroundColor: '#bad0f5' } };
    } else {
      return { style: { backgroundColor: '#f5d9a6' } };
    }
  };

  ksStatusInPrintetRead = (svarUtGuidStatus) => {
    let results = ['PRINTED', 'PRINTET', 'READ', 'LEST'];
    let res = results.includes(svarUtGuidStatus.toUpperCase());
    return res;
  };

  sjekkStatusTilAlle = () => {
    let idList = [];
    let rader = this.state.data;
    for (let i = 0; i < rader.length; i++) {
      if (rader[i].nkShippingGuid) {
        if (!this.ksStatusInPrintetRead(rader[i].svarUtGuidStatus)) {
          idList.push(rader[i].nkShippingGuid);
        }
      }
    }
    if (idList.length > 0) {
      this.setState({ laster: true });
      getSvarutStatus(idList, this.hentData);
    } else {
      this.setState({ laster: false });
      this.dialogs.setOpenDialog('IngenRadDialog');
    }
  };

  toggleInfo = () => {
    this.setState({ visInfo: !this.state.visInfo });
    let txt = this.state.infoTekst;
    if (txt === 'Vis info om "Status KS"') {
      this.setState({ infoTekst: 'Skjul info om "Status KS"' });
    } else {
      this.setState({ infoTekst: 'Vis info om "Status KS"' });
    }
  };

  render() {
    if (!this.state.data) {
      return <div>Laster...</div>;
    }
    let loaderStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
    };
    if (this.state.laster) {
      return <div style={loaderStyle} className='loader' />;
    }

    return (
      <div>
        <h4>{this.state.tittel}</h4>
        <hr />

        <button
          style={{ marginLeft: '4px' }}
          className='btn btn-default'
          onClick={this.sjekkStatusTilAlle}>
          Sjekk status til alle
        </button>
        <button
          style={{ marginLeft: '4px' }}
          className='btn btn-link'
          onClick={this.toggleInfo}>
          {this.state.infoTekst}
        </button>

        <br />
        {this.state.visInfo && (
          <div style={{ width: '92%', padding: '20px' }} className='pull-left'>
            <div
              className='container pull-left '
              style={{ width: '100%', fontSize: '12px' }}>
              <div className='row '>
                <div className='col-lg-6 col-md-12 '>
                  <label style={{ width: '150px' }}>ACCEPTED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Meldingen er validert og forsendelsesfil dannet.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>REJECTED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Forsendelsen er ikke validert pga. manglende/korrupt
                    metadata, eller fordi forsendelsesfil ikke kunne dannes.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>NOT_DELIVERED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Kun digital leveranse hvor vi ikke har klart å levere
                    forsendelsen.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>READY_FOR_RECIPIENT</label>
                  <span style={{ marginLeft: '10px' }}>
                    Venter på at forsendelse skal bli lastet ned av mottaker.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>READ</label>
                  <span style={{ marginLeft: '10px' }}>
                    En forsendelse er lest når hele forsendelsesfilen er lastet
                    ned av mottaker.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>DELIVERED_SDP</label>
                  <span style={{ marginLeft: '10px' }}>
                    Forsendelsen er mottatt og sendt til Sikker digital
                    postkasse. Vi har mottatt Leveringskvittering fra SDP.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>MANUAL_HANDLED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Forsendelsen er manuelt avsluttet, f.eks. pga en
                    feilsituasjon.
                  </span>
                  <br />
                </div>

                <div className='col-lg-6 col-md-12 '>
                  <label style={{ width: '150px' }}>RECEIVED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Et kall mottatt på forsendelses-service. En id blir tildelt
                    forsendelsen.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>PRINTED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Printkvittering mottatt fra printleverandør eller manuell
                    print bekreftet(via webgrensesnitt).
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>SENT_DIGITAL</label>
                  <span style={{ marginLeft: '10px' }}>
                    Forsendelsen er mottatt og sendt slik den skal. Ikke blitt
                    lest enda. Forsendelser med denne status vil kun leveres
                    digitalt, og vil aldri gå til print
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>SENT_PRINT</label>
                  <span style={{ marginLeft: '10px' }}>
                    Forsendelsen er blitt overført til printleverandør.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>SENT_SDP</label>
                  <span style={{ marginLeft: '10px' }}>
                    Forsendelsen er mottatt og sendt til Sikker digital
                    postkasse.
                  </span>
                  <br />
                  <label style={{ width: '150px' }}>NOTIFIED</label>
                  <span style={{ marginLeft: '10px' }}>
                    Et varsel om forsendelsen er sendt til varslingstjenesten.
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}
        <br />

        <div style={{ fontSize: '12px' }}>
          <ReactTable
            items={this.state.data}
            idProperty='id'
            showIndex={false}
            filterable={false}
            columns={this.state.columns}
            selectableRows={false}
            toggleAll={false}
            onRowClick={null}
            rowFormat={this.rowFormat}
          />
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  getColumnsNkAdminFeil = () => {
    return [
      {
        id: 'organsiasjon',
        name: 'Organisasjon',
        sortParams: 'organsiasjon',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'info',
        name: 'Gjelder',
        sortParams: 'info',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'feil',
        name: 'Feil',
        sortParams: 'feil',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return (
            <>
              <span
                style={{
                  display: 'block',
                  maxWidth: '350px',
                  width: '350px',
                  wordWrap: 'break-word',
                }}>
                {item.feil}
              </span>
            </>
          );
        },
      },
      {
        id: 'registrertDato',
        name: 'Dato',
        sortParams: 'registrertDato',
        filterable: true,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.registrertDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Bruksenhet adresse',
        sortParams: 'BruksenhetAdresse ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerNavn',
        name: 'Navn',
        sortParams: 'mottakerNavn ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerAdresse',
        name: 'Adresse',
        sortParams: 'mottakerAdresse ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerPostnr',
        name: 'Postnr',
        sortParams: 'mottakerPostnr ',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return (
            <>
              <span>{item.mottakerPostnr}</span>
              <br />
              <span>{item.mottakerPoststed}</span>
            </>
          );
        },
      },
      {
        id: 'nkStatus',
        name: 'Status NK',
        sortParams: 'nkStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'svarUtGuidStatus',
        name: 'Status KS ',
        sortParams: 'svarUtGuidStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      /*       {
        id: 'svarUtGuidStatus',
        name: 'Status KS',
        sortParams: 'svarUtGuidStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
      }, */
      {
        id: 'svarUtGuidStatusDato',
        name: 'Sist sjekket',
        sortParams: 'svarUtGuidStatusDato ',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.svarUtGuidStatusDato === null) {
            return '-';
          } else {
            var dato = new Date(item.svarUtGuidStatusDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button title='Meny' icon='meny' iconColor='nk-black'>
              {this.visStatus(item)}
              <ContextMenu.Item onClick={this.onClickVis.bind(this, item)}>
                Vis dokument...
              </ContextMenu.Item>
              {this.visSendPaNytt(item)}
              {this.visManuell(item)}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  getColumnsNkAdmin = () => {
    return [
      {
        id: 'organsiasjon',
        name: 'Organisasjon',
        sortParams: 'organsiasjon',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'info',
        name: 'Gjelder',
        sortParams: 'info',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'registrertDato',
        name: 'Dato',
        sortParams: 'registrertDato',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.registrertDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Bruksenhet adresse',
        sortParams: 'BruksenhetAdresse ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerNavn',
        name: 'Navn',
        sortParams: 'mottakerNavn ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerAdresse',
        name: 'Adresse',
        sortParams: 'mottakerAdresse ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerPostnr',
        name: 'Postnr',
        sortParams: 'mottakerPostnr ',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return (
            <>
              <span>{item.mottakerPostnr}</span>
              <br />
              <span>{item.mottakerPoststed}</span>
            </>
          );
        },
      },
      /*       {
        id: 'mottakerPoststed',
        name: 'Poststed',
        sortParams: 'mottakerPoststed ',
        filterable: false,
        isSorted: false,
        sortable: true,
      }, */
      {
        id: 'status',
        name: 'Status',
        sortParams: 'status ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'nkStatus',
        name: 'Status NK',
        sortParams: 'nkStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'svarUtGuidStatus',
        name: 'Status KS',
        sortParams: 'svarUtGuidStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      /*      {
        id: 'svarUtGuidStatus',
        name: 'Status',
        sortParams: 'svarUtGuidStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let dd = '-';
          let ds = '-';
          if (item.svarUtGuidStatusDato === null) {
            dd = '-';
          } else {
            var dato = new Date(item.svarUtGuidStatusDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            dd = 'Sist: ' + dag + '.' + mnd + '.' + aar;
          }
          if (item.svarUtGuidStatus === null) {
            ds = '-';
          } else {
            ds = 'St: ' + item.svarUtGuidStatus;
          }
          return (
            <>
              <span>{ds}</span>
              <br />
              <span>{dd}</span>
            </>
          );
        },
      }, */
      {
        id: 'svarUtGuidStatusDato',
        name: 'Sist sjekket',
        sortParams: 'svarUtGuidStatusDato ',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.svarUtGuidStatusDato === null) {
            return '-';
          } else {
            var dato = new Date(item.svarUtGuidStatusDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button title='Meny' icon='meny' iconColor='nk-black'>
              {this.visStatus(item)}
              <ContextMenu.Item onClick={this.onClickVis.bind(this, item)}>
                Vis dokument...
              </ContextMenu.Item>
              {this.visSendPaNytt(item)}
              {this.visManuell(item)}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  getColumnsInnstillinger = () => {
    return [
      {
        id: 'info',
        name: 'Gjelder',
        sortParams: 'info',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'registrertDato',
        name: 'Dato',
        sortParams: 'registrertDato',
        filterable: true,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.registrertDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Bruksenhet adresse',
        sortParams: 'BruksenhetAdresse ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerNavn',
        name: 'Navn',
        sortParams: 'mottakerNavn ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerAdresse',
        name: 'Adresse',
        sortParams: 'mottakerAdresse ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerPostnr',
        name: 'Postnr',
        sortParams: 'mottakerPostnr ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'mottakerPoststed',
        name: 'Poststed',
        sortParams: 'mottakerPoststed ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'status',
        name: 'Status',
        sortParams: 'status ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'svarUtGuidStatus',
        name: 'SvarUt status',
        sortParams: 'svarUtGuidStatus ',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'svarUtGuidStatusDato',
        name: 'Sist sjekket',
        sortParams: 'svarUtGuidStatusDato ',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.svarUtGuidStatusDato === null) {
            return '-';
          } else {
            var dato = new Date(item.svarUtGuidStatusDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'feil',
        name: 'Feil',
        sortParams: 'feil ',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.feil && item.feil !== ' ' ? (
            <span className='text'>
              {' '}
              <NKIcon icon='advarsel_roed' size='0.8' color='nk-black' />
              <span className='text-tooltip-SU'>{item.feil}</span>
            </span>
          ) : (
            <div style={{ maxWidth: '6px' }} />
          );
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button title='Meny' icon='meny' iconColor='nk-black'>
              {this.visStatus(item)}
              <ContextMenu.Item onClick={this.onClickVis.bind(this, item)}>
                Vis dokument...
              </ContextMenu.Item>
              {this.visSendPaNytt(item)}
              {this.visManuell(item)}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };
}

export default SvarUtLogg;
