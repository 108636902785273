import callApi from './api';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:api:loggapi');

export function getOrganisasjonerLoggAutomatisering(callback) {
    callApi('GET', '/organisasjon/loggautomatisering', null, callback);
};

export function getBruksenheterLoggAutomatisering(id, callback) {
    callApi('GET', '/organisasjon/loggautomatiseringbruksenhetbyorgid/' + id, null, callback);
};   


export function getBrukereInnleggetNow(callback) {
    callApi('GET', '/felles/cachegetusers', null, callback);
};

