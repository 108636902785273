import callApi from './api';


var getAnmerkningById = function(id, callback) {
    callApi('GET', '/anmerkning/' + id, null, callback);
};

var getAnmerkningByBruksenhetId = function(bruksenhetid, callback) {
    callApi('GET', '/anmerkning/bruksenhet/' + bruksenhetid, null, callback);
};

var getAnmerkningByRoyklopId = function(royklopid, callback) {
    callApi('GET', '/anmerkning/royklop/' + royklopid, null, callback);
};

var getAnmerkningByIldstedId = function(ildstedid, callback) {
    callApi('GET', '/anmerkning/ildsted/' + ildstedid, null, callback);
};

var updateAnmerkning = function(id, anmerkning, callback) {
    callApi('PUT', '/anmerkning/' + id, anmerkning, callback);
    
};

var deleteAnmerkning = function(id, callback) {
    callApi('DELETE', '/anmerkning/' + id, null, callback);
    
};

var createAnmerkning = function(anmerkning, callback) {
    callApi('POST', '/anmerkning/', anmerkning, callback);
};

//Beskrivelser, tekster som man ønsker å ta vare på

//Hent alle
var getAnmerkningAdmBeskrivelse = function(callback) {   
    callApi('GET', '/AnmerkningAdmBeskrivelse/aktive', null, callback);
};
//Create ny
var createAnmerkningAdmBeskrivelse = function(beskrivelse, callback) {
    callApi('POST', '/AnmerkningAdmBeskrivelse/', beskrivelse, callback);
}; 

var deleteAdmBeskrivelse = function(id, callback) {
    callApi('DELETE', '/AnmerkningAdmBeskrivelse/' + id, null, callback);
};

var updateAdmBeskrivelse = function(id, dto, callback) {
    callApi('PUT', '/AnmerkningAdmBeskrivelse/' + id, dto, callback);
};


var getAnmerkningOppslagdataKategori = function(callback) {
    callApi('GET', '/avvikanmerkningadmkategori/', null, callback);
};

var getAnmerkiningOppslagdata = function(objektType, objektId, callback) {
    callApi('GET', '/anmerkningoppslagdata/' + objektType +'/' + objektId, null, callback);
};

var updateAnmerkningTilAndreForhold = function(kategoriId, callback) {
    callApi('PUT', '/anmerkning/andreforholdkategori/' + kategoriId, null, callback);
};

var updateAndreForholdTilAnmerkning = function(kategoriId, callback) {
    callApi('PUT', '/anmerkning/anmerkningkategori/' + kategoriId, null, callback);
};


export default { 
    getAnmerkningById,
    getAnmerkningByBruksenhetId,
    getAnmerkningByRoyklopId,
    getAnmerkningByIldstedId,
    updateAnmerkning,
    deleteAnmerkning,
    createAnmerkning,
    createAnmerkningAdmBeskrivelse,
    getAnmerkningAdmBeskrivelse,
    getAnmerkningOppslagdataKategori,
    deleteAdmBeskrivelse,
    updateAdmBeskrivelse,
    getAnmerkiningOppslagdata,
    updateAnmerkningTilAndreForhold,
    updateAndreForholdTilAnmerkning
    };

export {
    getAnmerkningById,
    getAnmerkningByBruksenhetId,
    getAnmerkningByRoyklopId,
    getAnmerkningByIldstedId,
    updateAnmerkning,
    deleteAnmerkning,
    createAnmerkning,
    createAnmerkningAdmBeskrivelse,
    getAnmerkningAdmBeskrivelse,
    getAnmerkningOppslagdataKategori,
    deleteAdmBeskrivelse,
    updateAdmBeskrivelse,
    getAnmerkiningOppslagdata,
    updateAnmerkningTilAndreForhold,
    updateAndreForholdTilAnmerkning
};