import React, { Component } from 'react';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import * as getIcon from '../geticon.js';
import '../KartEnheter.css';
import { debugFactory } from 'utils';

var parentKart = null;
var StartMarker = null;
var slat = 0;
var slong = 0;

const debug = debugFactory('nk:basiskart');

class StartpunktControl extends Component {
  state = {
    initiert: false,
    Startpunktlat: '',
    Startpunktlong: '',
    slat: '0',
    slong: '0',
    isSet: false
  };

  componentDidMount= () => {
    if (!this.state.isSet) {  
      this.setState({ isSet: true });
      parentKart = this.props.kart;
      if (parentKart != null) {
        if (!this.state.initiert) {
          if (StartMarker == null) {
            this.setState({ initiert: true }, this.updateKart());  
          };
        }
      }
    }
  };

  componentWillUnmount() {
    StartMarker = null;
  }

  updateKart= () => {
    this.setStartpunkt();
    this.setEvents();
  };

  setStartpunkt = () => {
    //Default: Norges arealtyngdepunkt:
    let lat = 63.9905722;
    let long = 12.3077975;
    let zoomStart = 5;
    let org = this.props.objektIn; //organisasjon
    
    if (org.ny) {
      //hopp over
      debug('startpunkt org props', org);
    } else {
      if (
        org.longitude !== undefined &&
        org.longitude !== 0 &&
        org.longitude !== ''
      ) {
        long = org.longitude;
        lat = org.latitude;
        zoomStart = org.zoomLevel;
      }
    }
    
    this.setState({ slat: lat });
    this.setState({ slong: long });
  
    let viewPosition = [lat, long];
    parentKart.setView(viewPosition, zoomStart);
      StartMarker = L.marker([lat, long], {
        draggable: true,
        autoPan: true,
        icon: getIcon.redIcon
      }).addTo(parentKart);
  };

  setEvents = () => {
    parentKart.doubleClickZoom.disable();
    parentKart.on('dblclick', e => {
      e.Handled = true;
      var lat = e.latlng.lat.toPrecision(8);
      var lon = e.latlng.lng.toPrecision(8);
      StartMarker.setLatLng([lat, lon]).update();
      this.setMarker(lat, lon);
    });

    StartMarker.on('dragend', () => {
      if (!StartMarker){
        return;
      }
      var location = StartMarker.getLatLng();
      var lat = location.lat.toPrecision(8);
      var long = location.lng.toPrecision(8);
      this.setMarker(lat, long);
      let viewPosition = [lat, long];
      parentKart.setView(viewPosition);
    });
  };

  setMarker = (lat, lon) => {
    this.setState({ slat: lat });
    this.setState({ slong: lon });
    slong = lon;
    slat = lat;

    StartMarker.bindPopup(
      '<font size="4">Startpunktet flyttet til<br/><font size="3">' +
      lat + ' - ' + lon + '<br/>Klikk på <b>bruk-knappen</b> for å lagre koordinatene.',
      { offset: L.point({ x: 0, y: -20 }) }
    ).openPopup(); 
  };

  lagreStartPunkt = () => {
    debug('startpunkt pp', slat + '  ' + slong);
     this.props.objektUt(
      slat, slong,
      parentKart.getZoom()
    ); 
  };

  render() {
    return (
      <div>
        {/*Startpunkt control  */}
        <Control position="topleft">
          <table>
            <tbody>
            {/*   <tr>
                <td className="tdStartpunkt">
                  <div className="tddiv">Lat.</div>{' '}
                </td>
                <td>
                  <input
                    className="m4"
                    type="text"
                    maxLength="10"
                    value={this.state.slat}
                   
                  />
                </td>
              </tr>
              <tr>
                <td className="tdStartpunkt">
                  {' '}
                  <div className="tddiv">Long.</div>
                </td>
                <td>
                  <input
                    className="m4"
                    type="text"
                    maxLength="10"
                    value={this.state.slong}
                  
                  />
                </td>
              </tr> */}
              <tr>
                <td colSpan="2">
                  <p />
                  <button
                    className="startButton btn btn-primary"
                    onClick={this.lagreStartPunkt}>
                    Bruk
                  </button>
                
                  <p />
                </td>
              </tr>
            </tbody>
          </table>
        </Control>
      </div>
    );
  }
}

export default StartpunktControl;
