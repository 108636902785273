import React, { Component } from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import 'moment/locale/nb';
import EnheterTable from './EnheterTable';
import EnheterKartView from './EnheterKartView.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import '.././gjennomforing.css';
import PdfIcon from 'images/Annet/pdf.png';
import PdfDialog from 'components/Pdf/PdfDialog.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { brukBreadcrumb } from 'components/Breadcrumbs';
import { getInnloggetPerson } from 'context/contextInnstillinger.js';
import {
  getArbeidslisteEnheter,
  setContextSelectedArbeidsliste,
  contextGjennomforingSelectedArbeidsliste,
} from 'context/contextGjennomforing.js';


const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  PdfDialogen: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <PdfDialog
          onClose={onClose}
          data={dialogData.data}
          rapportType={dialogData.rapportType}
          tittel={dialogData.tittel}
        />
      </NKModal>
    );
  },
};

class Enheter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arbeidslisteId: 0,
      arbeidslisteNavn: '',
      visningKartStandard: false,
      enheterPlus: [],
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    //Rieni eksempel bind this
    getInnloggetPerson(
      function(person) {
        if (person) {
          this.setState({ visningKartStandard: person.visningKartStandard });
        }
      }.bind(this)
    );

    let arbeidslisteId = this.props.params.arbeidslisteId;
    let arbeidslisteNavn = this.props.params.arbeidslisteNavn;

    this.setState({ arbeidslisteId, arbeidslisteNavn });
    setContextSelectedArbeidsliste(arbeidslisteId, arbeidslisteNavn);
    this.hentEnheter(arbeidslisteId);

    this.ammendBreadCrumbs();
  };

  componentDidUpdate(prevProps) {
    this.ammendBreadCrumbs();
  }

  ammendBreadCrumbs = () => {
    //============= REACT CONTEXT BREADCRUMBS  ====================
    brukBreadcrumb('Arbeidsliste', 'gjennomforing_arbeidsliste_navn');
  };

  hentEnheter = (id) => {
    getArbeidslisteEnheter(id, this.plussEnheterMedDatoTid);
  };

  plussEnheterMedDatoTid = (enheter) => {  
    if (enheter) {
      for (let i = 0; i < enheter.length; i++) {
        let enhet = enheter[i];
        //datotid
        let d = moment(enhet.planlagtDato).format('YYYYMMDD');

        let bf = '';
        if (enhet.besokFra !== '' && enhet.besokFra !== null) {
          bf = enhet.besokFra.replace('.', '');
          bf = bf.replace(':', '');
        }

        let bt = '';
        if (enhet.besokTil !== '' && enhet.besokTil !== null) {
          let bt = enhet.besokTil.replace('.', '');
          bt = bt.replace(':', '');
        }

        d = d + bf + bt;
        enheter[i].datotid = d;

        //datotid-vennlig
        let dd = moment(enhet.planlagtDato).format('DD.MM.YYYY');

        if (enhet.besokFra !== '' && enhet.besokFra !== null) {
          dd = dd + ' ' + enhet.besokFra;
        }

        if (enhet.besokTil !== '' && enhet.besokTil !== null) {
          dd = dd + '-' + enhet.besokTil;
        }
        enheter[i].datotidvennlig = dd;

        //datotid kartmodus: vises på kart
        let dk = moment(enhet.planlagtDato).format('D/M');

        if (enhet.besokFra !== '' && enhet.besokFra !== null) {
          dk = dk + ' ' + enhet.besokFra;
        }

        enheter[i].datotidkart = dk;
      }
      //Sorter etter dato/tid
      enheter = this.sorterEtterTid(enheter);
      this.setState({ enheterPlus: enheter });
    }
  };

  sorterEtterTid = (enheter) => {
    const iterater = (obj) => obj.datotid;
    enheter = _.sortBy(enheter, iterater);
    let teller = 0;
    for (let y = 0; y < enheter.length; y++) {
      teller = teller + 1;
      enheter[y].datotidnummer = teller; //Ikke i bruk lenger..
    }
    return enheter;
  };

  toggleView = () => {
    let gjennomKart = !this.state.visningKartStandard;
    this.setState({ visningKartStandard: gjennomKart });
  };

  eksporterTilPdf = () => {
    this.dialogs.setOpenDialog('PdfDialogen', {
      data: this.state.enheterPlus,
      rapportType: 'gjennomforingArbeidsliste',
      tittel: contextGjennomforingSelectedArbeidsliste._currentValue.navn,
    });
  };

  visKart = () => {
    this.setState({ timeTikker: Date.now() });
  };

  refresh = () => {
    let arbeidslisteId = this.props.params.arbeidslisteId;
    this.hentEnheter(arbeidslisteId);
  };

  render() {
    if (!this.state.enheterPlus.length) {
      return <div className='loader' />;
    }

    return (
      <div>
        {!this.state.visningKartStandard && (
          <div className='row'>
            <div className='col-sm-8'>
              <h2> {this.state.arbeidslisteNavn}</h2>
            </div>
            <div className='col-sm-4'>
              {this.state.userPermissions &&
              !this.state.userPermissions.norkartAdmin ? null : (
                <span>
                  <button
                    className='btn btn-default pull-right'
                    title='Eksporter til Pdf'
                    onClick={this.eksporterTilPdf}>
                    <img src={PdfIcon} alt='pdf' height='24' width='24' />
                  </button>
                </span>
              )}

              <span>
                <button
                  className='btn btn-default pull-right'
                  title='Til kart modus'
                  onClick={this.toggleView}>
                  <NKIcon icon='punktmarkoer' size='1' color='nk-black' />
                </button>
              </span>
            </div>
          </div>
        )}

        {!this.state.visningKartStandard && (
          <div>
            <EnheterTable
              enheter={this.state.enheterPlus}
              base_path={'/gjennomforing/' + this.props.params.arbeidslisteId}
              arbeidslistenavn={
                contextGjennomforingSelectedArbeidsliste._currentValue.navn
              }
              arbeidslisteId={this.props.params.arbeidslisteId}
              refresh={this.refresh}
              setSelectedArbeidslisteEnhet={
                this.props.setSelectedArbeidslisteEnhet
              }
            />
          </div>
        )}
        {this.state.visningKartStandard && (
          <div style={{ padding: '4px' }}>
            <EnheterKartView
              enheter={this.state.enheterPlus}
              base_path={'/gjennomforing/' + this.props.params.arbeidslisteId}
              arbeidslistenavn={
                contextGjennomforingSelectedArbeidsliste._currentValue.navn
              }
              arbeidslisteId={this.props.params.arbeidslisteId}
              tilbake={this.toggleView}
              visKart={this.visKart}
              refresh={this.refresh}
              timeTikker={this.state.timeTikker}
            />
          </div>
        )}
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Enheter;
