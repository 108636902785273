import React, { Component } from 'react';
import CONFIG from 'lib/CONFIG';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import excel from 'images/Annet/excel.png';
import * as _ from 'lodash';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:Gebyr');
//kopier: import Gebyr from './Gebyr.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class Gebyr extends Component {
  constructor(props) {
    super(props);
    this.state = { kommune: null, kommuner: null, kommuneId: '', rapport: '', downLoad: 'rapport', visGebyr: false };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
  
  };

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
    let kommuner = innst.kommuner;
    this.setState({ kommuner });
    if (kommuner) {
      if (kommuner.length > 0) {
        this.setState({ kommuneId: kommuner[0].kommuneId });
        this.setState({ kommune: kommuner[0] }, this.makeRapport(kommuner[0].kommuneId, kommuner[0].kommuneNavn, kommuner[0].replikKundeId));
      }
    }
  };

/*   checkUrl(url) {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.send();
    request.onload = function() {
      debug('RAPPORT XY3', request);
      debug('RAPPORT XY3', request.status);
      if (request.status.toString() === '200') 
      {
        debug('RAPPORT XY3 exists');
      } else {
        debug('RAPPORT XY3 NOT exists');
      }
    };
  } */

  kommuneChanged = (val) => {
  //  debug('XYX', val.target.value);
    this.setState({ kommuneId: val.target.value });
    let kommune = this.state.kommuner.find(
      (o) => o.kommuneId.toString() === val.target.value.toString()
    );
  //  debug('XYX', kommune);
    this.setState({ kommune });
    this.makeRapport(val.target.value, kommune.kommuneNavn, kommune.replikKundeId);
  };

  makeRapport = (kommuneId, navn, replikk) => {
  /*   let ex = 'https://bfbapistoragev3aa2.blob.core.windows.net/brannforebyggingpublic/GebyrRapporter/7159e585-3194-4f8d-bc64-c49444e0a2b9/4647_GebyrRapport.xlsx';
    let aa = 'https://i.picsum.photos/id/204/200/300.jpg?hmac=XxKpmfmEwzuLIP4_ji37Ql6leTx-j6LTtl8wNK3JTYY'; */
    let fraConfig = CONFIG.REACT_APP_BFBAPISTORAGE;
    let rapport = fraConfig + 'brannforebyggingpublic/GebyrRapporter/';
    rapport = rapport + replikk+ '/' + kommuneId + '_GebyrRapport.xlsx';
    debug('RAPPORTSTRING', rapport);
    // i PROD: https://bfbapistoragexpiof.blob.core.windows.net/brannforebyggingpublic/GebyrRapporter/ec6e8929-e9d3-4f2a-9cd7-ca938d2040aa/3436_GebyrRapport.xlsx
    this.setState({ rapport });
    let downLoad = 'Excel_Gebyr_' + navn;
    this.setState({ downLoad });
  };



  render() {
    return (
      <div style={{ margin: '4px' }}>
        <h2>Gebyr</h2>

        <div className='form'>
          <div className='row'>
            <p />
            <div className='col-lg-4 col-sm-12 col-md-6'>
              <div className='form-group'>
                <label className='top-margin-4'>Kommune&nbsp;&nbsp;</label>
                <select className='h30 width220' onChange={this.kommuneChanged}>
                  {_.map(this.state.kommuner, function(o, i) {
                    return (
                      <option key={i} value={o.kommuneId}>
                        {o.kommuneNavn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <p />
        
        <br />
        

        {this.state.kommune && (
          <div style={{ marginLeft: '10px' }}>
            {this.state.kommune.replikKundeId && (
              <div style={{ marginLeft: '8px' }}>
                Klikk på linken under for å laste ned gebyrrapport for den valgte kommunen.<br/>
                Nye rapporter blir generert ukentlig og vil være klare for nedlastning fra og med tirsdager.
             {/*    NB! Vi har problemer med å oppdatere datagrunnlaget til rapportene. <br/>
                De endres derfor ikke nattlig som vanlig. Sjekk dato for siste oppdatering inne på rapporten. <br/>
                Vi jobber med å fikse feilen, og beklager de ulemper dette måtte medføre. */}
                {/* NB! Datagrunnlaget i rapporten endres nattlig, se eksakt tidspunkt i rapporten. */}
                <br/>
                <br/>
                <a
                  href={this.state.rapport}
                  target='_blank' rel='noreferrer noopener'
                  download={this.state.downLoad}>
                    <span style={{ fontSize: '18px', fontWeight: 'bold', marginRight: '8px' }}>Last ned Excel-rapport for kommune {this.state.kommune.kommuneNavn}</span>
                    <img src={excel} alt='ExcelRapport' width='24' height='24'/>
                </a>
              </div>
            )}
             {!this.state.kommune.replikKundeId && (
                <label style={{ color: 'blue', marginTop: '2px', marginLeft: '10px' }}> {this.state.kommune.kommuneNavn} har ikke en gebyrkobling mot Komtek Forvaltning.</label>
             )}
            <br/>
            <br/>
          {/*   <div style={{ fontSize: '10px' }}>
              Test info: <br/>
              {this.state.rapport}<br/>
              {this.state.kommuneId}<br/>
              {this.state.kommune.kommuneNavn}
            </div> */}
          </div>
        )}

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Gebyr;

