import React, { Component } from 'react';
import NKIcon from 'nka-icons';
import { debugFactory } from 'utils';
import DialogStore from 'lib/DialogStore';
import { gebyravvikAvtaleMelding } from 'api/gebyrInnsynApi.js';
import { gebyravvikEiendomMelding } from 'api/gebyrInnsynApi.js';

const debug = debugFactory('nk:Gebyr:GebyrAvvikDialog');



class GebyrMeldingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 0, tekst: null };
  }

  componentDidMount() {}

  notatChange = (val) => {
    this.setState({ tekst: val.target.value });
  };

  sendMelding = () => {
    if (this.props.meldingstype == 'avtale') {
      let dto = {
        komtekReplikKundeid: this.props.fakturaavtalekobling
          .komtekReplikKundeid,
        kommunenummer: this.props.valgtavtale.kommune,
        bruksenhetId: this.props.brId,
        eiendom: this.props.fakturaavtalekobling.eiendom,
        matrikkelenhetId: this.props.fakturaavtalekobling.matrikkelenhetId,
        bruksenhetIdMatrikkel: this.props.fakturaavtalekobling
          .bruksenhetIdMatrikkel,
        bruksenhetadresse: this.props.fakturaavtalekobling.bruksenhetadresse,
        bygningsNummer: this.props.fakturaavtalekobling.bygningsNr,
        antallBruksenheheterEiendom: this.props.fakturaavtalekobling
          .antallBruksenheheterEiendom,
        antallRoyklopIbrukEiendom: this.props.fakturaavtalekobling
          .antallRoyklopIbrukEiendom,
        fritekst: this.state.tekst,
        avtaleNr: this.props.valgtavtale.avtaleNr,
        gateNavn: this.props.valgtavtale.gatenavn,
        husnr: this.props.valgtavtale.husnummer,
        bokstav: this.props.valgtavtale.bokstav,
      };
      console.log(dto);
      gebyravvikAvtaleMelding(dto, this.meldingSent);
      this.props.avtalemelding();
    } else {
      let avtaler = [];
      this.props.fakturaavtalekobling.fakturaAvtaleMedGebyr.forEach(
        (element) => {
          avtaler.push(element.avtaleNr);
        }
      );
      let dto = {
        komtekReplikKundeid: this.props.fakturaavtalekobling
          .komtekReplikKundeid,
        kommunenummer: this.props.fakturaavtalekobling.kommunenr,
        bruksenhetId: this.props.brId,
        eiendom: this.props.fakturaavtalekobling.eiendom,
        matrikkelenhetId: this.props.fakturaavtalekobling.matrikkelenhetId,
        bruksenhetIdMatrikkel: this.props.fakturaavtalekobling
          .bruksenhetIdMatrikkel,
        bruksenhetadresse: this.props.fakturaavtalekobling.bruksenhetadresse,
        bygningsNummer: this.props.fakturaavtalekobling.bygningsNr,
        antallBruksenheheterEiendom: this.props.fakturaavtalekobling
          .antallBruksenheheterEiendom,
        antallRoyklopIbrukEiendom: this.props.fakturaavtalekobling
          .antallRoyklopIbrukEiendom,
        fritekst: this.state.tekst,
        avtaleNr: 0,
        avtaler: avtaler,
      };
      console.log(dto);
      gebyravvikEiendomMelding(dto, this.meldingSent);
      
    }
  };

  meldingSent = (err, res) => {
    if (!err){
      this.props.avtalemelding();
    } else {
      alert(err);
    }
    
  };

  render() {
    return (
      <div className='modal-content'>
        <div className='modal-header modal-header-info'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {this.props.meldingstype == 'avtale'
              ? 'Avtalemelding'
              : 'Eiendomsmelding'}
          </h4>
        </div>
        <div
          className='modal-body'
          style={{ minHeight: '500px', maxHeight: '500px' }}>
          <h4>&nbsp;&nbsp;Meldingen gjelder:</h4>

          <div className='row idiv'>
            <div className='col-sm-12'>
              {this.props.meldingstype === 'avtale' ? (
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Avtale:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.valgtavtale.avtaleNr}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Adresse:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.fakturaavtalekobling.bruksenhetadresse}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Eiendom:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.fakturaavtalekobling.eiendom}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Bygningsnummer:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.fakturaavtalekobling.bygningsNr}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Eiendom:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.fakturaavtalekobling.eiendom}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Adresse:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.fakturaavtalekobling.bruksenhetadresse}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px', paddingBottom: '3px' }}>
                          Bygningsnummer:
                        </td>
                        <td style={{ paddingBottom: '3px' }}>
                          {this.props.fakturaavtalekobling.bygningsNr}
                        </td>
                      </tr>
                      <tr />
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
          <br />
          <h4>&nbsp;&nbsp;Legg ved notat:</h4>
          <div className='row idiv'>
            <div className='col-sm-12'>
              <div className='form-group '>
                <textarea
                  className='form-control textareaNoresize'
                  type='textarea'
                  id='message'
                  placeholder='Notat til gebyransvarlig'
                  rows='12'
                  onChange={this.notatChange}
                />
              </div>
            </div>
          </div>
          {this.state.isSendt && (
            <div className='pull-Left'>
            <label style={{ color: 'blue', marginLeft: '20px' }}>
              Meldingen har blitt sendt.
            </label>
          </div>
          )}
          
        </div>
        <div className='modal-footer'>
          <button
            style={{ margin: '2px', width: '80px', height: '40px' }}
            className='btn btn-primary pull-right'
            onClick={this.sendMelding.bind(this)}>
            Send
          </button>
          <br />
        </div>
      </div>
    );
  }
}

export default GebyrMeldingDialog;
