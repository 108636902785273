import React from 'react';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import '.././grunndata.css';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
var _ = require('underscore');

var id = 0;
class NyEndreOppslagsdataAvvikLovdata extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleTekstChange = this.handleTekstChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.lokalChange = this.lokalChange.bind(this);
    this.dataLagret = this.dataLagret.bind(this);
    this.state = {
      lukket: true,
      tekst: '',
      lokal: false
    };
  }

  handleTekstChange(data) {
    this.setState({ tekst: data.target.value });
  }
  componentDidMount() {
    this.tekstInput.focus();
    if (this.props.nyEndre === 'Endre') {
      id = this.props.valgtItem.id;
      this.setState({
        tekst: this.props.valgtItem.tekst,
        lukket: this.props.valgtItem.aktiv,
        lokal: this.props.valgtItem.lokal
      });
    } else {
    }
  }

  submit() {
    var lovdata = {
      id: id,
      tekst: this.state.tekst,
      aktiv: this.state.lukket,
      lokal: this.state.lokal
    };
    if (this.props.nyEndre === 'Ny') {
      oppslagsdataApi.saveAvvikLovdata(lovdata, this.dataLagret);
    } else {
      oppslagsdataApi.updateAvvikLovdata(id, lovdata, this.dataLagret);
    }
  }

  dataLagret(err, nyEndreitem) {
    this.props.dataLagret(nyEndreitem);
  }
  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
  }
  lokalChange() {
    let lokal = !this.state.lokal;
    this.setState({ lokal: lokal });
  }

  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">
            {this.props.nyEndre} oppslagsdata for: {this.props.type} -{' '}
            {this.props.tabell}
          </h4>
        </div>
        <div className="modal-body">
          <div className="form margin-left-15 margin-right-15">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group ">
                  <br />
                  <input className="checkbox30" type="checkbox" onClick={this.lukketChange} checked={this.state.lukket} />
                  <span className="checkboxtext18">
                    Aktiv
                  </span>
                </div>
                <div className="form-group">
                  <input className="checkbox30" type="checkbox" onClick={this.lokalChange} checked={this.state.lokal} />
                  <span className="checkboxtext">
                  &nbsp;&nbsp;Lokal
                  </span>
                </div>
                <div className="form-group">
                  <label>Tekst</label>
                  <textarea
                    ref={(input) => { this.tekstInput = input; }}
                    className="form-control textareaNoresize"
                    type="textarea"
                    id="message"
                    placeholder="Tekst"
                    rows="2"
                    onChange={this.handleTekstChange}
                    value={this.state.tekst}
                    maxLength = '2000'
                  />
                </div>

                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NyEndreOppslagsdataAvvikLovdata;
