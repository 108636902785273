


export const testKommentar = [
  {
    id: '789-abc',
    innlegg: [
      {
        bruker: 'Jonas Ødegaard',
        key: '123-567-888',
        innleggTekst: 'Er -åpenbart- ikke litt utydelig?',
        endret: '03.05.2022 09:45',
      },
      {
        bruker: 'Arve Holm',
        key: '123-567-8gf',
        innleggTekst: 'Jeg skjønner hvorfor du spør. Men som det er, er helt OK..',
        endret: '04.05.2022 12:45',
      },
    ],
  },

  {
    id: '789-def',
    innlegg: [
      {
        bruker: 'Katja Solbakken',
        key: '123-567-pok',
        innleggTekst: 'Sjekk om dette står virkelig slik i loven. Litt rart?.',
        endret: '07.03.2022 12:05',
      },
    ],
  },
];
