import React, { Component } from 'react';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';

var marker = null;
var noIcon = new L.Icon({
  iconUrl: 'mypicture',
  iconSize: [0.00, 0.00],
  shadowSize: [0, 0]
});

class LocateControl extends Component {
  hvorErJeg = () => {
    let kart = this.props.kart;

    kart
      .locate({ setView: true, maxZoom: 20 })
      .on('locationfound', e => {
        marker = L.marker(e.latlng, { icon: noIcon })
          .addTo(kart)
          .bindPopup(
            'Du befinner deg innen ' + e.accuracy / 2 + ' meter fra her.'
          )
          .openPopup();
      })
      .on('locationerror', function(e) {
        console.log(e);
        alert('Lokasjon tilgang nektet.');
      });

    setTimeout(() => { marker.remove();}, 4000);
  };

  render() {
    let position = 'topright';
    if (this.props.left === true) {
      position = 'topleft';
    }
    return (
      <div>
        {/* Locate: hvor er jeg */}
        <Control position={position}>
          <div>
            <button
              type="button"
              className={'btnLag'}
              title="Hvor er jeg?"
              onClick={this.hvorErJeg}>
              <NKIcon icon="lokasjon" color="nk-black" />
            </button>
          </div>
        </Control>
      </div>
    );
  }
}

export default LocateControl;
