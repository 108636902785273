/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import CONFIG from 'lib/CONFIG';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/plugins/image.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { checkEditorNormal, skjulTabellKantlinjer, visTabellKantlinjerIgjen } from './EditorNormal.js';

const debug = debugFactory('nk:VisDokument');
const FROALA_KEY = CONFIG.REACT_APP_FROALA;

const modalDialogs = {
  ReadOnlyDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={'Kan ikke redigere'}
      melding={'Teksten kan ikke redigeres her.'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class VisDokumentRO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tittel: 'Vis dokument',
      model: this.props.model,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    this.sjekkSideShift();
  };

  sjekkSideShift = () => {
    let model = this.state.model;
    model = checkEditorNormal(model);
    model = model.replace(/-sideshift-/gi, '');
    this.setState({ model });
  };

  handleModelChange = (model) => {
    // this.setState({ model});
    this.dialogs.setOpenDialog('ReadOnlyDialog');
    let s = this.state.model;
    this.setState({ model: s });
  };

  unsynligTable = () => {
    let bStyle = '1px dotted white';
    let editor = this.froalaRef.editor;
    let el = editor.el.children;
    for (let i = 0; i < el.length; i++) {
      let element = el[i];
      if (element.localName === 'table') {
        //table
        debug('KILL', element.style);
        if (element.style.border === '') {
          element.style.border = bStyle;
        }
        //celler
        for (let y = 0; y < element.rows.length; y++) {
          let row = element.rows[y];
          for (let z = 0; z < row.children.length; z++) {
            let celle = row.children[z];
            if (celle.style.border === '') {
              celle.style.border = bStyle;
            }
          }
        }
      }
    }
  };

  synligTable = () => {
    let bStyle = '1px dotted white';
    let editor = this.froalaRef.editor;
    let el = editor.el.children;
    for (let i = 0; i < el.length; i++) {
      let element = el[i];
      if (element.localName === 'table') {
        //table
        if (element.style.border === bStyle) {
          element.style.border = '';
        }
        //celler
        for (let y = 0; y < element.rows.length; y++) {
          let row = element.rows[y];
          for (let z = 0; z < row.children.length; z++) {
            let celle = row.children[z];
            if (celle.style.border === bStyle) {
              celle.style.border = '';
            }
          }
        }
      }
    }
  };

  render() {
    
    if (this.state.model === '') {
      return <div>Vent..</div>;
    }

   /*  let visHTML = '';
    if (this.props.norkartbruker) {
      visHTML = 'html';
    } */

    const config = {
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'SvarUt tekst..',
      autoFocus: true,
      language: 'nb',
      heightMin: 200,
      height: 'calc(100vh - 290px)',
      lineheight: '1',
      charCounterCount: true,
      toolbarButtons: {
        moreParagraph: {
          buttons: [
            'print',
          ],
          align: 'left',
          buttonsVisible: 3,
        },
      },

      events: {
        'commands.before': function(e) {
          if (e === 'print') {
            return skjulTabellKantlinjer(this);
          }
        },
        'commands.after': function(e) {
          if (e === 'print') {
            return visTabellKantlinjerIgjen(this);
          }
        },
      },
    };
    return (
      <div style={{ width: '800px' }}>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{this.state.tittel}</h4>
        </div>

        <div
          style={{ marginTop: '2px', height: 'calc(100vh - 160px)' }}
          className='panel panel-default'>
          <div className='panel-body'>
            <FroalaEditorComponent
              ref={(el) => {
                this.froalaRef = el;
              }}
              documentReady={true}
              useClasses= {false}
              id='editor'
              config={config}
              onModelChange={this.handleModelChange}
              model={this.state.model}
            />
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default VisDokumentRO;
