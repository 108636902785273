
function handleErrors(err, url='') {
    if (err.status === 404){//Not found, ignorer
        return;
    }
    else if (err.status === 409) {
        var x = document.getElementsByClassName('container main body-content')[0];
        var newNode = document.createElement('DIV');
        newNode.className = 'alert alert-danger';
        newNode.id = 'exception';
        newNode.innerHTML = '<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> ' +
           'Bruker/navn/kategori/id eksisterer fra før';

    } 
    else {
        let message = err.response ? err.response : ''; // :-0
        let statusText = err.statusText ? err.statusText : '';    
        let stackTrace = err.responseJSON ? err.responseJSON.stackTrace.match('[a-zA-Z .()]+') : '';    
        let responseURL = err.responseURL ? err.responseURL : url;
        let status = err.status || !isNaN(err.status) ? err.status : '';
        var element = document.getElementById('exception');

        if (status === 0) {
            statusText = `
                The browser refused to honor the request / A network error occurred.
                This could be a CORS issue (make sure API endpoind responds!) or a dropped internet connection. It is not possible for us to know.
            `.trim();
        }

        if (element) {
            element.parentNode.removeChild(element);
        }
         x = document.getElementsByClassName('container main body-content')[0];
        newNode = document.createElement('DIV');
        newNode.className = 'alert alert-danger';
        newNode.id = 'exception';
        newNode.innerHTML =
            '<a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> ' +
            '<strong> En feil har oppstått</strong>' +
            '<p>' + message + '</p>' +
            '<p>' + statusText + '</p>' +
            '<br /><button class="btn btn-default btn-sm" data-toggle="collapse" data-target="#detaljer">Detaljer</button>' +
            '<form id="detaljer" class="toggle collapse">' +
            '</br>' +
            '<p><strong>Responsetext:</strong> ' + message + '</p>' +
            '<p><strong>Responseurl:</strong> ' + responseURL + '</p>' +
            '<p><strong>statusText:</strong> ' + statusText + '</p>' +
            '<p><strong>Statuscode:</strong> ' + status + '</p>' +
            '<p><strong>stackTrace:</strong> ' + stackTrace + '</p>' +
            
    
            '</form>';
    }
        

    x.insertBefore(newNode, x.firstChild);
};
module.exports = handleErrors;