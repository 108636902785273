import React, { Component } from 'react';
import * as _ from 'lodash';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import SmsEditor from 'components/Editor/SmsEditor.jsx';
import DialogStore from 'lib/DialogStore';
import { oppdaterSmsMal, nySmsMal, oppdaterSmsEksempelMal } from 'api/malerApi';
import { getContextTiltakstyper } from 'context/contextPlanlegging.js';
import { objGetDeep } from 'utils';

class TiltakCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valgte: [],
      valgListe: [],
      mal: null,
      init: false
    };
  }

  componentDidMount() {
    
    this.setState({ mal: this.props.mal });  
   
    this.mergeData();
  }

  mergeData() {
    let valgte = this.props.mal.tiltak;
    let valgListe = this.props.tiltakListe;
    for (let y = 0; y < valgListe.length; y++) {
      let rad = valgListe[y];
      let elId = rad.id;
      let found = _.find(valgte, { tiltakTypeId: elId });
      if (found) {
        rad.valgt = true;
      } else {
        rad.valgt = false;
      }
    }
    this.setState({ valgListe });
    this.setState({ valgte });
  }

  handleCheckbox = (e, box) => {
    let valgListe = this.state.valgListe;
    for (let i = 0; i < valgListe.length; i++) {
      let rad = valgListe[i];
      if (rad.id === box.id) {
        rad.valgt = !rad.valgt;
      }
    }
    this.setState({ valgListe }, this.oppdaterMalTiltak(valgListe));
  };

  oppdaterMalTiltak = (valgListe) => {
    let valgte = [];
    for (let i = 0; i < valgListe.length; i++) {
      let rad = valgListe[i];
      if (rad.valgt === true) {
        valgte.push(rad);
      }
    }
    let valgteNy = [];
    for (let y = 0; y < valgte.length; y++) {
      let element = {
        //************************************************************************* mangler id og malId
        tiltakTypeId: valgte[y].id,
        tiltak: valgte[y].tekst,
        aktiv: valgte[y].aktiv,
        malId: this.props.mal.id,
      };
      if (this.state.mal.id === 0) {
        element.id = 0;
      }
      valgteNy.push(element);
    }
    this.setState({ valgte: valgteNy });
    this.props.oppdaterTiltak(valgteNy);
  };

  render() {
    if (this.state.valgListe && this.state.valgListe.length === 0) {
      return 'Laster liste..';
    }
    return (
      <div>
        <table>
          <tbody>
            <React.Fragment>
              {this.state.valgListe.map(function(tiltak) {
                let key = 'row_' + tiltak.id;
                let keyCh = 'check_' + tiltak.id;
                let td1 = 'td1_' + tiltak.id;
                let td2 = 'td2_' + tiltak.id;
                let td3 = 'td3_' + tiltak.id;
                let td4 = 'td4_' + tiltak.id;
                return (
                  <tr key={key}>
                    <td key={td1}>
                      <input
                        alt={tiltak.paragrafId}
                        className='checkbox20'
                        ref={tiltak.id}
                        id={keyCh}
                        key={keyCh}
                        onChange={(e) => this.handleCheckbox(e, tiltak)}
                        checked={tiltak.valgt}
                        type='checkbox'
                      />
                    </td>
                    <td key={td2}>&nbsp;&nbsp;&nbsp;</td>
                    <td key={td3}>{tiltak.tekst}</td>
                    <td key={td4}>{tiltak.valgt}</td>
                  </tr>
                );
              }, this)}
            </React.Fragment>
          </tbody>
        </table>
      </div>
    );
  }
}

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class SmsMalDialog extends Component {
  constructor(props) {
    super(props);
    this.smseditorRef = null;
    this.state = {
      mal: null,
      maltext: '',
      funksjonListe: null,
      fletters: [],
      tiltaktypeListe: null,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    
    this.utvidFunksjonListe();
    this.setState({ mal: this.props.mal, fletters: this.props.flettefelt });
    getContextTiltakstyper(this.gotTiltaksTyper);
  }

  synkroniserTiltakIdenter = (nyTiltakListe) => {
    let originalListe = _.cloneDeep(this.props.mal.tiltak);
    for (let i = 0; i < nyTiltakListe.length; i++) {
      let tiltakRad = nyTiltakListe[i];
      let found = _.find(originalListe, {
        tiltakTypeId: nyTiltakListe[i].tiltakTypeId,
      });
      if (found) {
        tiltakRad.id = found.id;
      }
    }
    return nyTiltakListe;
  };

  gotTiltaksTyper = (data) => {
    let tiltaktypeListe = [];
    if (data) {
      if (objGetDeep(data, 'typer.tiltakTypeBruksenhetListe')) {
        for (let i = 0; i < data.typer.tiltakTypeBruksenhetListe.length; i++) {
          tiltaktypeListe.push(data.typer.tiltakTypeBruksenhetListe[i]);
        }
      }
      if (objGetDeep(data, 'typer.tiltakTypeRoyklopListe')) {
        for (let i = 0; i < data.typer.tiltakTypeRoyklopListe.length; i++) {
          tiltaktypeListe.push(data.typer.tiltakTypeRoyklopListe[i]);
        }
      }
      this.setState({ tiltaktypeListe });
    }
  };

  utvidFunksjonListe = () => {
    if (this.props.funksjonListe) {
      let obj = {
        id: 0,
        tekst: 'Ingenting valgt..',
        aktiv: null,
        flettefelt: null,
      };
      let fliste = this.props.funksjonListe;
      if (
        fliste.filter(function(e) {
          return e.tekst === 'Ingenting valgt..';
        }).length === 0
      ) {
        fliste.unshift(obj);
      }
      this.setState({ funksjonListe: fliste });
    }
  };

  tabClick = (tab) => {};

  sjekkTiltak = (tiltak, gjelderKode) => { 
    let funksjon= '';
    console.log('%c RIENI sjekkTiltak ', 'font-size: 14px; background: firebrick; color: white',  this.props.checkTiltakMaler );  
    if (this.props.checkTiltakMaler.length){
      funksjon= this.props.checkTiltakMaler[0].funksjon;
    } else {
      if (gjelderKode === 'P'){
        funksjon = 'Varsel';
      }
    }
  
    
    if (tiltak.length === 0) {
      
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Kan ikke lagre: Tiltak mangler',
        melding: gjelderKode === 'G' ? 'En SMS mal med funksjon "' + funksjon + '" og som ikke er standardmal skal ha "Tiltak"' : 'En SMS mal med funksjon "Varsel" og som ikke er standardmal skal ha "Tiltak"'
      });
      return false;
    }
    //make string list to check
    let checkListe = [];

   
    for (let i = 0; i < this.props.checkTiltakMaler.length; i++) {
      let mal = this.props.checkTiltakMaler[i];
        
      if (mal.id !== this.state.mal.id || this.state.mal.id === undefined) {
        let list = [];
        for (let i = 0; i < mal.tiltak.length; i++) {
          list.push(mal.tiltak[i].tiltak + '*');
        }
        list.sort();
        let s = '';
        for (let i = 0; i < list.length; i++) {
          s = s + list[i];
        }
        checkListe.push(s);
      }
    }

    //make string to check
    let liste = [];
    for (let i = 0; i < tiltak.length; i++) {
      liste.push(tiltak[i].tiltak + '*');
    }

    liste.sort();
    let s = '';
    for (let i = 0; i < liste.length; i++) {
      s = s + liste[i];
    }

    //do the check;
    let inList = false;
    for (let y = 0; y < checkListe.length; y++) {
      if (s === checkListe[y]) {
        inList = true;
        break;
      }
    }
    if (inList) {
      let z = s.replaceAll('*', ', ');
      z = z.slice(0, -2);
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Kan ikke lagre',
        melding: gjelderKode === 'G' ? 'Det finnes allerede en mal for funksjon "' + funksjon + '" med valgte tiltak (' + z + ')': 'Det finnes allerede en varselmal med valgte tiltak (' + z + ')'
      });
      return false;
    } else {
      return true;
    }
  };

  lagre = () => {
    let mal = this.state.mal;

    let editor = this.smseditorRef;
    let txt = '';
    if (editor) {
      txt = editor.getMaltekst();
    }
    if (txt === 'ERROR') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'For mange tegn i sms mal',
        melding:
          'For å unngå feil ved sending av sms og for å unngå ekstra kostnader håndterer vi en begrensing av 500 tegn.',
      });
      return;
    }
    if (txt === undefined) {
      this.dialogs.setOpenDialog('OkDialog', {
        melding: 'Malteksten kan ikke være tom!',
        tittel: 'Malen kan ikke lagres..',
      });
      return;
    } else {
      mal.tekst = txt;
      mal.gjelderKode = this.props.gjelderKode;
      if (this.props.new) {
        //Kun planlegging så lenge
       /*  mal.funksjon = 'Varsel';
        mal.funksjonId = 1;
        mal.standardMal = false; */
        mal.funksjon = this.state.mal.funksjon;
        mal.funksjonId = this.state.mal.funksjonId;
        mal.standardMal = false;
      }
      mal.tiltak = this.synkroniserTiltakIdenter(mal.tiltak);

      let tiltakOk = true;
      if ((mal.funksjon === 'Varsel' || mal.gjelderKode === 'G' ) && !mal.standardMal) {
        tiltakOk = this.sjekkTiltak(mal.tiltak, mal.gjelderKode);
      }
      
      if (!tiltakOk) {
        return;
      } 
     
      if (this.props.modus === 'Vanlig') {
        if (this.props.new) {
          nySmsMal(mal, this.malOppdatert);
        } else {
          oppdaterSmsMal(mal.id, mal, this.malOppdatert);
        }
      } else {
        oppdaterSmsEksempelMal(mal.id, mal, this.malOppdatert);
      }
    }
  };

  malOppdatert = () => {
    this.props.onOppdatert();
    this.props.onClose();
  };

  getValueFromList = (id, liste) => {
    let value = '';
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].id === id) {
        value = liste[i].tekst;
        break;
      }
    }
    return value;
  };

  getFlettersFromList = (id, liste) => {
    let value = '';
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].id === id) {
        value = liste[i].flettefelt;
        break;
      }
    }
    return value;
  };

  getAktuelleFlettefelt = (funksjonId) => {
    let fletters = this.getFlettersFromList(
      funksjonId,
      this.state.funksjonListe
    );

    if (fletters === null) {
      fletters = [];
    }
    this.setState({ fletters: fletters });
  };

  tittelChange = (e) => {
    let mal = this.state.mal;
    mal.tittel = e.target.value;
    this.setState(mal);
  };

  funksjonChange = (e) => {
    let mal = this.state.mal;
    mal.funksjonId = e.target.value;
    mal.funksjon = this.getValueFromList(
      e.target.value,
      this.state.funksjonListe
    );
    this.setState(mal);
    this.getAktuelleFlettefelt(e.target.value);
  };

  aktivChange = (e) => {
    let mal = this.state.mal;
    mal.aktiv = !mal.aktiv;
    this.setState(mal);
  };

  oppdaterTiltak = (tiltakListe) => {
    let mal = this.state.mal;
    mal.tiltak = tiltakListe;
    this.setState({ mal });
  };

  makeGjelder = (kode) => {
    switch(kode) {
      case 'P': return ' (Planlegging)';
      case 'G': return ' (Gjennomføring)';
      case 'O': return ' (Oppfølging)';
      case 'F': return ' (Generelle funksjoner)';
      default: return '';
        
     }
  }

  render() {
    if (!this.state.mal) {
      return 'Vent..';
    }
    if (this.state.tiltaktypeListe && this.state.tiltaktypeListe.length === 0) {
      return 'Vent litt..';
    }
    return (
      <div style={{ background: 'white' }} className='malModalHeight'>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{this.state.mal.tittel + this.makeGjelder(this.state.mal.gjelderKode)}</h4>
        </div>

        <div
          style={{ marginTop: '2px', maxHeight: '90%' }}
          className='panel panel-default'>
          <ul className='nav nav-tabs'>
            <li className='active' onClick={() => this.tabClick('egenskaper')}>
              <a data-toggle='tab' href='#1tab'>
                Mal egenskaper
              </a>
            </li>
            <li onClick={() => this.tabClick('tekst')}>
              <a data-toggle='tab' href='#3tab'>
                Mal tekst
              </a>
            </li>
          </ul>
          <div className='panel-body'>
            <div className='tab-content'>
              {/* ******** TAB ************************************** */}
              <div id='1tab' className='tab-pane fade in active'>
                <div className='row malrow'>
                  <table>
                    <tbody>
                      {(this.props.modus === 'Eksempel' || (this.state.mal.funksjon !== 'Varsel' && this.state.mal.gjelderKode !== 'G' ) ||
                        this.state.mal.standardMal) && (
                        <tr>
                          <td>
                            <label>Tittel</label>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ verticalAlign: 'top' }}>
                            <span>{this.state.mal.tittel}</span>
                          </td>
                        </tr>
                      )}
                      {(this.state.mal.funksjon === 'Varsel' || this.state.mal.gjelderKode === 'G') &&
                        !this.state.mal.standardMal && (
                          <tr>
                            <td>
                              <label>Tittel</label>
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <input
                                style={{
                                  width: '500px',
                                  marginBottom: '6px',
                                }}
                                autoFocus
                                maxLength='250'
                                className='form-control'
                                placeholder='Tittel..'
                                type='text'
                                value={this.state.mal.tittel}
                                onChange={this.tittelChange}
                              />
                            </td>
                          </tr>
                        )}
                      {(this.state.mal.funksjon === 'Varsel' || this.state.mal.gjelderKode === 'G') &&
                        !this.state.mal.standardMal && (
                          <tr>
                            <td>
                              <label>I bruk</label>
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <input
                                className='checkbox20'
                                checked={this.state.mal.aktiv}
                                type='checkbox'
                                onChange={this.aktivChange}
                              />
                            </td>
                          </tr>
                        )}

                      <tr>
                        <td style={{ verticalAlign: 'top' }}>
                          <label style={{ marginTop: '12px' }}>
                            Beskrivelse
                          </label>
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>
                          <textarea
                            style={{
                              width: '500px',
                              marginTop: '6px',
                              resize: 'none',
                            }}
                            cols='40'
                            rows='5'
                            className='form-control'
                            value={
                              this.state.mal.beskrivelse
                                ? this.state.mal.beskrivelse
                                : ''
                            }
                            onChange={(e) => {
                              this.setState({
                                mal: {
                                  ...this.state.mal,
                                  beskrivelse: e.target.value,
                                },
                              });
                            }}
                          />
                        </td>
                      </tr>
                      <tr style={{ height: '20px' }} />

                      <tr>
                        <td>
                          <label style={{ marginTop: '4px' }}>Funksjon</label>
                        </td>
                        <td>&nbsp;&nbsp;&nbsp;</td>
                        <td>{this.state.mal.funksjon}</td>
                      </tr>
                    </tbody>
                  </table>
                  {this.state.tiltaktypeListe &&
                    (this.state.mal.funksjon === 'Varsel' || this.state.mal.gjelderKode === 'G') &&
                    !this.state.mal.standardMal && (
                      <React.Fragment>
                        <tr style={{ height: '20px', width: '700px' }} />
                        <tr>
                          <td style={{ verticalAlign: 'top' }}>
                            <label style={{ marginTop: '2px' }}>Tiltak</label>
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td style={{ paddingLeft: '50px' }}>
                            <TiltakCheckList
                              mal={this.state.mal}
                              oppdaterTiltak={this.oppdaterTiltak}
                              tiltakListe={this.state.tiltaktypeListe}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    )}

                  {this.state.mal && this.state.mal.tekst.length > 499 && (
                    <React.Fragment>
                      <br />
                      <br />
                      <span style={{ color: '#E25653' }}>
                        Sjekk antall tegn på malteksten..
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
              {/* ******** TAB ************************************** */}
              <div id='3tab' className='tab-pane fade '>
                <div style={{ paddingLeft: '10px' }}>
                  <SmsEditor
                    ref={(el) => {
                      this.smseditorRef = el;
                    }}
                    cols='70'
                    rows='12'
                    visTekstbiter={false}
                    mal={this.state.mal}
                    maltext={this.state.mal.tekst}
                    flettefelt={this.state.fletters}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='malModalFooter'>
          <button onClick={this.lagre} className='btn btn-primary pull-right'>
            Lagre
          </button>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SmsMalDialog;
