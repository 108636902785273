import React from 'react';
import * as blobApi from 'api/storageblobApi';
import _ from 'underscore';
import NKIcon from 'nka-icons';
import NkaButton from 'nka-button';
import 'components/Bilder/Bilder.css';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:components:bilderpanel');

class BilderListe extends React.Component {
  state = {
    src: '',
    bildeNr: -1,
    //boxheight: 'boxheight400',
    hidestor: 'hide',
    hideliste: '',
    stor: 'stor north',
    modalBildeIsOpen: false
  };

  visBilde = (filnavn, teller) => {
    let fil = { filnavn: filnavn };
    this.setState({ bildeNr: teller });
    blobApi.hentfil(fil, this.storfilHentet);
  };

  storfilHentet = (err, foto) => {
    let src = 'data:image/jpg;base64,' + foto.filData;
    this.setState({ src: src });
    this.setState({ bildeId: foto.id });
    this.setState({ hidestor: '' });
    this.setState({ hideliste: 'hide' });
    this.props.toggleKnapp('hide');
  };

  skjulBilde = () => {
    this.setState({ hidestor: 'hide' });
    this.setState({ hideliste: '' });
    this.props.toggleKnapp('');
  };

  slettBilde = () => {
    let liste = this.props.liste;
    let nr = this.state.bildeNr;
    let fil = liste[nr].id;
    this.props.slett(fil);
    this.setState({ hidestor: 'hide' });
    this.setState({ hideliste: '' });
    this.props.toggleKnapp('');
  };

  roterBilde = () => {
    let stor = this.state.stor;
    switch (stor) {
      case 'stor north':
        stor = 'stor west';
        break;
      case 'stor west':
        stor = 'stor south';
        break;
      case 'stor south':
        stor = 'stor east';
        break;
      case 'stor east':
        stor = 'stor north';
        break;
      default:
        stor = 'stor north';
    }
    this.setState({ stor: stor });
  };

  closeStorBilde = () => {
    this.setState({ modalBildeIsOpen: false });
  };

  nesteBilde = () => {
    let liste = this.props.liste;
    if (liste.length < 1){
      this.skjulBilde();
      return;
    }
    let nr = this.state.bildeNr;
    nr++;
    if (nr > liste.length - 1) {
      nr = 0;
    }
    let fil = liste[nr].id;
    this.visBilde(fil, nr);
  };

  forrigeBilde = () => {
    let liste = this.props.liste;
    if (liste.length < 1){
      this.skjulBilde();
      return;
    }
    let nr = this.state.bildeNr;
    nr--;
    if (nr < 0) {
      nr = liste.length - 1;
    }
    let fil = liste[nr].id;
    this.visBilde(fil, nr);
  };

  render() {
    var teller = 0;
    var bliste = this.props.liste;
    return (
      <div className="max444">
        <div className={this.state.hidestor}>
          <div className="move2">
            <button
              onClick={this.forrigeBilde}
              className="btn btn-default height38">
              <NKIcon icon="venstrepil" color="nk-black" />
            </button>
            <button
              onClick={this.nesteBilde}
              className="btn btn-default height38">
              <NKIcon icon="hoyrepil" color="nk-black" />
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={this.roterBilde}>
              <NKIcon icon="rotasjon" color="nk-black" />
            </button>
            <button
              onClick={this.skjulBilde}
              className="btn btn-default height38">
              <NKIcon icon="liste" color="nk-black" />
            </button>
            <button
              onClick={this.slettBilde}
              className="btn btn-default height38">
              <NKIcon icon="soppelbotte" color="nk-black" />
            </button>
          </div>
          <br />
          <div className="stordiv">
            <img
              ref="bilde"
              alt=""
              className={this.state.stor}
              src={this.state.src}
            />
          </div>
        </div>
        <div className={'row ' + this.state.hideliste}>
          <br />
          {bliste.map(function(foto) {
            teller++;
            var spId = 'sp' + teller;
            var iId = 'i' + teller;
            return (
              <span key={spId} id={spId}>
                <img
                  key={iId}
                  alt=""
                  className={'thumbbilde rotate' + foto.orientation}
                  src={'data:image/jpg;base64,' + foto.base64}
                  onClick={this.visBilde.bind(null, foto.id, teller - 1)}
                />
              </span>
            );
          }, this)}
        </div>
      </div>
    );
  }
}

class BilderPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bildeliste: [],
      teller: 1,
      src: '',
      startet: false,
      fagDataId: '',
      hideKnapp: '',
      venter: 'Ingen bilder.',
      hideVenter: '',
      hideProsessing: ' hide'
    };

    this.bildeUploaded = this.bildeUploaded.bind(this);
    this.bildeHentet = this.bildeHentet.bind(this);
    this.thumpnailsHentet = this.thumpnailsHentet.bind(this);
    this.toggleKnapp = this.toggleKnapp.bind(this);
  }

  //#region  HEMT DATA
  componentDidMount() {
    if (this.props.onMounted) {
      this.props.onMounted({
        start: text => this.start(text)
      });
    }
  }

  start(text) {
    this.setState({ bildeliste: [] });
    this.hentThumbnails();
  }

  hentThumbnails() {
    let filer = [];
    for (let i = 0; i < this.props.filListe.length; i++) {
      let filnavn = this.props.filListe[i].filnavn;
      let fil = { filnavn: filnavn };
      filer.push(fil);
    }
    debug('TARZAN hent filer', filer);
    if (filer.length > 0) {
      this.getBildeFraBlobStorage(filer);
    }
  }

  getBildeFraBlobStorage(filer) {
    this.setState({ venter: 'Bildene hentes..' });
    blobApi.hentthumbnails(filer, this.thumpnailsHentet);
  }

  thumpnailsHentet(err, thumbs) {
    let templiste = this.state.bildeliste;
  
    if (thumbs !== undefined) {
      for (let i = 0; i < thumbs.length; i++) {
        let bilde = thumbs[i];
        let bildeObj = {
          id: bilde.filnavn,
          status: 'old',
          orientation: 0,
          base64: bilde.filData
        };
        templiste.push(bildeObj);
      }
      this.setState({ bildeliste: templiste });
      
    }
    this.setState({ venter: '', hideVenter: ' hide' });
  }

  //#endregion

  bildeHentet(err, bilde) {
    let templiste = this.state.bildeliste;
    if (bilde) {
      let bildeObj = {
        id: bilde.filnavn,
        status: 'old',
        orientation: 0,
        base64: bilde.filData
      };
      templiste.push(bildeObj);
    } else {
      //Rieni
    }

    this.setState({ bildeliste: templiste });
  }

  getOrientation(file, callback) {
    var reader = new FileReader();

    reader.onload = function(event) {
      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2);
      }

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) == 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }

  komprimerBilde(file, maxWidth, maxHeight, quality) {
      var img = new Image();
      var getOrientation = this.getOrientation;
      var bildeTilCanvas = this.bildeTilCanvas;
      var blobToFile = this.blobToFile;
      var uploadFileToServer = this.uploadFileToServer.bind(this);
        
      img.onload = function() {
          URL.revokeObjectURL(this.src);
          getOrientation(file, function(orientation) {
              var w = img.width, h = img.height;
              var skaler = (orientation > 4 ?
                  Math.min(maxHeight / w, maxWidth / h, 1) :
                  Math.min(maxWidth / w, maxHeight / h, 1));
              h = Math.round(h * skaler);
              w = Math.round(w * skaler);

              var canvas = bildeTilCanvas(img, w, h, orientation);
              var filename = file.name
              canvas.toBlob(function(file) {
                blobToFile (file, filename)
                uploadFileToServer(file, orientation)
              }, 'image/jpeg', quality)
          });
      };
    img.src = URL.createObjectURL(file);
  }

  bildeTilCanvas(img, rawWidth, rawHeight, orientation) {
    var canvas = document.createElement('canvas');
    if (orientation > 4) {
        canvas.width = rawHeight;
        canvas.height = rawWidth;
    } else {
        canvas.width = rawWidth;
        canvas.height = rawHeight;
    }

    var ctx = canvas.getContext('2d');
    switch (orientation) {
        case 2: ctx.transform(-1, 0, 0, 1, rawWidth, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, rawHeight); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, rawHeight, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, rawHeight, rawWidth); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, rawWidth); break;
    }
    ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
    return canvas;
}

  blobToFile(file, filename) {
    file.lastModifiedDate = new Date();
    file.name = filename;
    return file;
  }

  onBildeChange(e) {
    var file = e.target.files[0];
    var fileSize = file.size;
    var extension = file.name
        .substr(file.name.lastIndexOf('.') + 1)
        .toLowerCase();

    if (fileSize > 1000000) {
      if (extension === 'jpg' || extension === 'jpeg') {
      this.komprimerBilde(e.target.files[0], 800, Infinity, 0.6); 
      } else {
        alert('Feil filformat. Velg .jpg/.jpeg');
      }
    } else {
      if (extension === 'jpg' || extension === 'jpeg') {
        this.getOrientation(
          file,
          function(orientation) {
            this.uploadFileToServer(file, orientation);
          }.bind(this)
        );
      } else {
        alert('Feil filformat. Velg .jpg/.jpeg');
      }
    }
  }

  uploadFileToServer(file, orientation) {
    this.setState({ venter: '', hideVenter: ' hide' });
    this.setState({ hideProsessing: '' });
    var reader = new FileReader();
    reader.readAsBinaryString(file);
    let id = this.state.teller;
    this.setState({ teller: id + 1 });
    reader.onload = function() {
      let b64 = btoa(reader.result);
      let b64Obj = {
        base64: b64,
        id: this.state.teller,
        status: 'new',
        orientation: orientation
      };
      let tempListe = this.state.bildeliste;
      tempListe.push(b64Obj);
      this.setState({ bildeliste: tempListe });
      this.uploadFil(b64Obj, file.name);
    }.bind(this);
  }


  uploadFil(filObjekt, navn) {
    let objekt = {
      filnavn: navn,
      filData: filObjekt.base64
    };
    blobApi.lagrefilmedthumbnail(objekt, this.bildeUploaded);
  }

  bildeUploaded(err, bilde) {
    let tempListe = this.state.bildeliste;
    let obj = tempListe[tempListe.length - 1];
    obj.id = bilde.tekst;
    this.props.onlisteChange(bilde.tekst);
    this.setState({ bildeliste: tempListe });
    this.setState({ hideProsessing: ' hide' });
  }

  slett(id) {
    //slett fra BlobStorage
    if (id !== 'error') {
      let obj = { filnavn: id };
      blobApi.slettfilmedthumbnail(obj, this.bildeSlettet.bind(this, id));
    } else {
      alert('Bildet blir ikke slettet fra databasen ifm. testing.');
    }
  }

  bildeSlettet(id, succes) {
    let templiste = this.state.bildeliste;
    templiste = this.state.bildeliste.filter(item => item.id !== id);
    this.setState({ bildeliste: templiste });
    this.props.onBildeSlettet(id);
  }

  toggleKnapp(hide) {
    this.setState({ hideKnapp: hide });
  }

  render() {

    const flexContainerStyle = {
      height: '500px',
      flexDirection: 'column',
      display: 'flex',
      padding: '5px 0'
    };

    const labelShroud = {
      height: '47px',
      width: '117px',
      position: 'absolute',
      cursor: 'pointer',
      padding: '0',
      margin: '0'
    };

    let buttonLabel = 'Ta bilde';
    let buttonWidth= '120px';
    if (this.props.hent) {
      buttonLabel = 'Ta / hent bilde';
      buttonWidth = '200px';
    }

    return (
      <div className="bilderheight">   
        {!this.props.readOnly ? (
          <div>
            <div className={this.state.hideKnapp}>
              <label htmlFor="tabilde" style={labelShroud} />
              <NkaButton
                style={{ margin: '0px', marginTop: '10px', width: { buttonWidth }, height: '40px' }}
                icon="kamera">
                {buttonLabel}
              </NkaButton>
            </div>
            <div className="hide">
              <input
                type="file"
                name="tabilde"
                onChange={this.onBildeChange.bind(this)}
                id="tabilde"
                className="hide"
              />
            </div>
          </div>
        ) : (
          ''
        )}
        <div>
          <div className={this.state.hideVenter}>
            {this.state.venter}
          </div>
          <div className={'rodt ' + this.state.hideProsessing}>
            Vent! Bildet sendes til serveren..
          </div>

          <BilderListe
            readOnly={this.props.readOnly}
            slett={this.slett.bind(this)}
            liste={this.state.bildeliste}
            toggleKnapp={this.toggleKnapp}
          />
        </div>
        <div />
      </div>
    );
  }
}

BilderPanel.defaultProps = { bildeposisjon: '' };
export default BilderPanel;
