import React from 'react';


function TestComponent2({ tekst }) {
  //annerledes enn this.props
  return (
    <div>
      <label>Her er TestComponent2</label>
      <br/>
     <span>Valgt sted:</span>
     <br/>
     <label>{tekst}</label>
     
    </div>
  );
}

export default TestComponent2;
