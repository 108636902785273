/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react';
import { withRouter } from 'lib/enhancers';
import NKModal from 'components/Dialogs/NKModal.jsx';
import moment from 'moment';
import NKIcon from 'nka-icons';
import Spinner from 'components/generics/Spinner';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import { promisifyApiCall } from 'utils';
import AvvikForm from 'modules/felles/avvikAnmerkninger/AvvikForm.jsx';
import AnmerkningForm from 'modules/felles/avvikAnmerkninger/AnmerkningForm.jsx';
import senddialogwhite from 'images/Annet/senddialogwhite.png';
import senddialogblack from 'images/Annet/senddialogblack.png';
import bruker from 'images/Annet/bruker_standardWit.png';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import BFButtonGroup from 'components/BFButtonGroup/BFButtonGroup.js';
import BFButton from 'components/BFButtonGroup/BFButton.js';
import BFDropDownButton from 'components/BFButtonGroup/BFDropDownButton';
import BFButtonLinkGroup from 'components/BFButtonGroup/BFButtonLinkGroup.js';
import NkaFormElementDropdown from 'nka-form-element-dropdown';
import 'nka-form-element-dropdown/dist/style.css';
import './sidemann.css';
import ByttSidemann from './ByttSidemann.js';
import Editor from 'components/Editor/SidemannEditor';
import Kommentar from './Kommentar.js';
import { getApneAvvikAnmIkkePublisert } from 'api/avvikApi';
import {
  endreOppgaveDokument,
  getOppgave,
  getPreviewDokumentSidemann,
  getPreviewDokumentSaksbehandler,
  sendTilEierFraKlargjorRapportSaksbehandler,
  sendTilEierFraKlargjorRapportSidemann,
  godkjennOppgave,
  setOppgaveTilSidemann,
} from 'api/smkApi.js';
import { getEksempeltekst } from 'components/Editor/testtekst.js';
import { getBruksenhet, getFyringsforbudForBruksenhet } from 'api/enhetApi.js';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import forhand from 'images/Annet/forhandsvisning.png';
import JaNeiDialog from 'components/Dialogs/JaNeiDialogFC.js';
import { setSisteFaneBesoekt } from 'context/contextOppfolging';
import VelgKontakter from './VelgKontakter.js';
import DeltPipe from 'images/deltpipe.png';

function MineOppgaverKlargjorRapport(props) {
  const froalaRef = useRef(null);
  const komRef = useRef();
  const buttonGroupA = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [fane, setFane] = useState('Dok');
  const [forhandsModus, setForhansModus] = useState(true);
  const [forhandsview, setForhandsview] = useState(
    '<p/><p><b>Vent, forhåndsvisning flettes..</b></p>'
  );
  const [linkTekst, setLinkTekst] = useState('Vis kommentar');
  const [minOppgave, setMinOppgave] = useState({
    arbeidslisteBruksenhetOppgave: { id: -1 },
  });
  const [fyringsForbud, setFyringsForbud] = useState(false);
  const [screenModus, setScreenModus] = useState('stor');
  const [buttonAvvikValgt, setButtonAvvikValgt] = useState(0);
  const [modalMatrikkelOpen, setModalMatrikkelOpen] = useState(false);
  const [modalSituasjonOpen, setModalSituasjonOpen] = useState(false);
  const [avvikListe, setAvvikListe] = useState([]);
  const [avvikCount, setAvvikCount] = useState(0);
  const [anmerkningListe, setAnmerkningListe] = useState([]);
  const [anmerkningCount, setAnmerkningCount] = useState(0);
  const [andreListe, setAndreListe] = useState([]);
  const [andreCount, setAndreCount] = useState(0);
  const [anmOgAndreListe, setAnmOgAndreListe] = useState([]);
  const [valgtAvvik, setValgtAvvik] = useState(null);
  const [modalAvvikOpen, setModalAvvikOpen] = useState(false);
  const [valgtAnmerkning, setValgtAnmerkning] = useState(null);
  const [modalAnmerkningOpen, setModalAnmerkningOpen] = useState(false);
  const [kommentarer, setKommentarer] = useState([]);
  const [brukerNavn, setBrukerNavn] = useState('');
  const [model, setModel] = useState('...');
  const [modelInitiert, setModelInitiert] = useState(false);
  const [dokumentHarBlittEndret, setDokumentHarBlittEndret] = useState(false);
  const [modalLagreDokumentOpen, setModalLagreDokumentOpen] = useState(false);
  const [modalLagreKommentarOpen, setModalLagreKommentarOpen] = useState(false);
  const [jaNeiGodkjenningOpen, setJaNeiGodkjenningOpen] = useState(false);
  const [jaNeiStatusOpen, setJaNeiStatusOpen] = useState(false);
  const [matrikkelId, setMatrikkelId] = useState(0);
  const [bygningsNr, setBygningsNr] = useState(0);
  const [modalVelgSidemannOpen, setModalVelgSidemannOpen] = useState(false);
  const [modalVelgKontakterOpen, setModalVelgKontakterOpen] = useState(false);
  const [
    jaNeiSendTilEierLikevelOpen,
    setJaNeiSendTilEierLikevelOpen,
  ] = useState(false);
  const [previewHentet, setPreviewHentet] = useState(false);
  const [kontakterForPreview, setKontakterForPreview] = useState(null);
  const [kontakterForSend, setKontakterForSend] = useState(null);
  const [avvikTilknyttetDone, setAvvikTilknyttetDone] = useState(false);
  const [anmTilknyttetDone, setAnmTilknyttetDone] = useState(false);
  const [editorFletteOptions, setEditorFletteOptions] = useState([]);
  const [modalVarselDeltAvvikOpen, setModalVarselDeltAvvikOpen] = useState(
    false
  );
  const [modalVarselDeltAnmOpen, setModalVarselDeltAnmOpen] = useState(false);

  /*   useEffect(() => {
    if (props.location.state.ikkeAnsvarlig) {
      console.log(
        '%c PRX ikkeAnsvarlig',
        'font-size: 20px; background: orange; color: white'
      );
    }
  }, []); */

  useEffect(() => {
    if (isLoading) {
      setSisteFaneBesoekt('KlargjørRapport');
      setIsLoading(false);
      updateWidthAndHeight();
      window.addEventListener('resize', updateWidthAndHeight);
      if (isLoading) {
        // ellers blir det en infin. loop
        hentData();
      }
    }
  });

  //Åpne forhåndsvisning ved åpning av side
  useEffect(() => {
    if (
      !previewHentet &&
      avvikTilknyttetDone &&
      anmTilknyttetDone &&
      minOppgave.arbeidslisteBruksenhetOppgave.id > -1 &&
      model !== '...'
    ) {
      setPreviewHentet(true);
      setForhandsview('<p/><p><b>Vent, forhåndsvisning flettes..</b></p>');
      setTimeout(() => {
        visPreview();
      }, 2000);
    }
  });

  useEffect(() => {
    let feilGrunnlagAvvik = false;
    if (minOppgave.arbeidslisteBruksenhetOppgave.id > -1 && avvikListe.length) {
      let oppgaveAvvik = minOppgave.arbeidslisteBruksenhetOppgave.avvikListe;
      if (oppgaveAvvik) {
        for (let i = 0; i < avvikListe.length; i++) {
          const a = avvikListe[i];
          a.tilknyttet = oppgaveAvvik.includes(a.id);
        }

        //Check feil grunnlag
        let kommaListe = oppgaveAvvik.split(',');
        for (let x = 0; x < kommaListe.length; x++) {
          let id = kommaListe[x];
          let items = avvikListe.find((obj) => obj.id.toString() === id);

          if (items === undefined || items === null) {
            feilGrunnlagAvvik = true;
            break;
          }
        }
      }
      setAvvikTilknyttetDone(true);

      if (feilGrunnlagAvvik) {
        setModalVarselDeltAvvikOpen(true);
      }
    }
  }, [avvikListe]);

  useEffect(() => {
    let feilGrunnlagAnm = false;
    if (
      minOppgave.arbeidslisteBruksenhetOppgave.id > -1 &&
      anmOgAndreListe.length
    ) {
      let oppgaveAnmerkning =
        minOppgave.arbeidslisteBruksenhetOppgave.anmerkningListe;
      if (oppgaveAnmerkning) {
        for (let i = 0; i < anmOgAndreListe.length; i++) {
          const a = anmOgAndreListe[i];
          a.tilknyttet = oppgaveAnmerkning.includes(a.id);
        }

        //Check feil grunnlag
        let kommaListe = oppgaveAnmerkning.split(',');
        for (let x = 0; x < kommaListe.length; x++) {
          let id = kommaListe[x];
          let items = anmOgAndreListe.find((obj) => obj.id.toString() === id);

          if (items === undefined || items === null) {
            feilGrunnlagAnm = true;
            break;
          }
        }
      }
      // setAnmTilknyttetDone(true);

      if (feilGrunnlagAnm) {
        setModalVarselDeltAnmOpen(true);
      }
    }
    setAnmTilknyttetDone(true);
  }, [anmOgAndreListe]);

  useEffect(() => {
    if (brukerNavn === '') {
      let bruker = contextInnstillingerPerson._currentValue.navn;
      setBrukerNavn(bruker);
    }
  }, [brukerNavn]);

  useEffect(() => {
    if (valgtAvvik) {
      setModalAvvikOpen(true);
    }
  }, [valgtAvvik]);

  useEffect(() => {
    if (valgtAnmerkning) {
      setModalAnmerkningOpen(true);
    }
  }, [valgtAnmerkning]);

  useEffect(() => {
    if (minOppgave.arbeidslisteBruksenhetOppgave.id < 0) {
      return;
    }
    console.log('FEX', minOppgave);
    let e = getEksempeltekst();
    let liste = [
      {
        text: 'Dagens dato',
        result: moment().format('DD.MM.YYYY'),
      },
      {
        text: 'Dato registrert',
        result: moment(
          minOppgave.arbeidslisteBruksenhetOppgave.registrertDato
        ).format('DD.MM.YYYY'),
      },
      {
        text: 'Besøk dato',
        result: moment(
          minOppgave.arbeidslisteBruksenhetOppgave.besokdato
        ).format('DD.MM.YYYY'),
      },
      {
        text: 'Saksbehandler',
        result: minOppgave.arbeidslisteBruksenhetOppgave.saksbehandler,
      },
      {
        text: 'Adresse',
        result: minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetAdresse,
      },
      {
        text: contextInnstillingerPerson._currentValue.navn,
        result: contextInnstillingerPerson._currentValue.navn,
      },
      {
        text: 'Mobil: ' + contextInnstillingerPerson._currentValue.mobil,
        result: contextInnstillingerPerson._currentValue.mobil,
      },
      {
        text: 'Telefon: ' + contextInnstillingerPerson._currentValue.telefon,
        result: contextInnstillingerPerson._currentValue.telefon,
      },
      {
        text: contextInnstillingerPerson._currentValue.orgNavn,
        result: contextInnstillingerPerson._currentValue.orgNavn,
      },
    ];
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      liste.unshift({
        text: 'Eksempeltekst',
        result: e,
      });
    }

    setEditorFletteOptions(liste);
  }, [minOppgave]);

  const updateWidthAndHeight = () => {
    let w = window.innerWidth;
    let modus = '';
    if (w > 1400) {
      modus = 'stor';
    } else if (w > 1100) {
      modus = 'middels';
    } else {
      modus = 'small';
    }
    setFane('Dok');
    setScreenModus(modus);
  };

  const buttonFaneValgt = (id, name) => {
    let tag = 'Dok';
    switch (id) {
      case 0:
        tag = 'Dok';
        break;
      case 1:
        if (name){
          if (name.toString().includes('.Av')){
            tag = '.Av';
          } else {
            tag = 'Avv';
          }
        } else {
          tag = 'Avv';
        }
        break;
      case 2:
        tag = 'Anm';
        break;
      case 3:
        tag = 'And';
        break;
      default:
        tag = 'Dok';
        break;
    }
    setFane(tag);
    /*  let tag = name.substring(0, 3);  .Av
    setFane(tag); */
  };

  //************* Hent data ***************************************** */

  const hentData = () => {
    if (!isLoading) {
      return;
    }
    if (setMinOppgave.arbeidslisteBruksenhetOppgave) {
      if (minOppgave.arbeidslisteBruksenhetOppgave.id > -1) {
        setIsLoading(false);
      }
    }

    //1. hent data for oppgave med dokument
    promisifyApiCall(getOppgave)(props.params.Id).then(
      //props.location.state.oppgave.id
      (data) => {
        console.log('XXX hentData', data);
        setMinOppgave(data);
        /*  setAnsvarlig(data.arbeidslisteBruksenhetOppgave.ansvarlig); */
        setModel(data.arbeidslisteBruksenhetOppgave.dokument);
        setKommentarer(
          rensKommentarer(data.arbeidslisteBruksenhetOppgaveKommentar)
        );

        setModelInitiert(true);
        //2. hent data for avvikMM
        getDataForAvvikMm(data.arbeidslisteBruksenhetOppgave.bruksenhetId);
        //editToggleReadOnly(data.arbeidslisteBruksenhetOppgave.sidemannGodkjent);
      }
    );
  };

  const rensKommentarer = (kom) => {
    for (let i = 0; i < kom.length; i++) {
      const k = kom[i];
      if (k.kommentar.endsWith('<p><br></p>')) {
        k.kommentar = k.kommentar.replace('<p><br></p>', '');
      }
    }
    return kom;
  };

  const getDataForAvvikMm = (id) => {
    //Hent data og opprett 3 arrays

    /*   promisifyApiCall(getAvvikAnmerkningerForBruksenhet)(id, true).then(
      (data) => {
        console.log('AVVIKX avvik/anmerkning/', data);
        avvikDataTil3Lister(data);

        getFyringsforbudForBruksenhet(id, gotInfoOmFyringsforbud);
      }
    );   */

    promisifyApiCall(getApneAvvikAnmIkkePublisert)(id).then((data) => {
      console.log('AVVIKX avvik/anmerkning/ikkepublisert', data);
      avvikDataTil3Lister(data);
      getFyringsforbudForBruksenhet(id, gotInfoOmFyringsforbud);
    });
  };

  const gotInfoOmFyringsforbud = (err, forbud) => {
    setFyringsForbud(forbud);
  };

  const avvikDataTil3Lister = (data) => {
    //Avvik
    setAvvikListe(data.avvikListe);
    setAnmOgAndreListe(data.anmerkningListe);
    if (!data.avvikListe.length) {
      setAvvikTilknyttetDone(true);
    }
    if (!data.anmerkningListe.length) {
      setAnmTilknyttetDone(true);
    }
    setAvvikCount(data.avvikListe.length);
    //Anmerkninger og andre forhold
    let anm = [];
    let andre = [];
    data.anmerkningListe.forEach((element) => {
      if (element.andreForhold) {
        andre.push(element);
      } else {
        anm.push(element);
      }
    });
    
    setAnmerkningListe(anm);
    setAnmerkningCount(anm.length);
    setAndreListe(andre);
    setAndreCount(andre.length);
    console.log(anm, anm.length, andre, andre.length);
  };

  //************* Hent data End ***************************************** */

  const buttonAValgt = (id, name) => {
    setButtonAvvikValgt(id);
  };

  //************* Dialogs ***************************************** */
  const buttonValgt = (id, name) => {
    if (id.label) {
      name = id.label;
    }
    switch (name) {
      case 'Matrikkelinfo':
        //DErsom info ikke finnes enda, må det hentes først
        if (matrikkelId === 0) {
          getBruksenhet(
            minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetId,
            hentetBruksenhetInfo
          );
        } else {
          setModalMatrikkelOpen(true);
        }
        break;
      case 'Situasjon':
        setModalSituasjonOpen(true);
        break;
      default:
        alert('nix');
    }
  };

  const hentetBruksenhetInfo = (err, data) => {
    setMatrikkelId(data.bruksenhetIdMa);
    setBygningsNr(data.bygning);
    setTimeout(() => {
      setModalMatrikkelOpen(true);
    }, 2000);
  };

  const knapper = [
    {
      knapp: 0,
      label: 'Matrikkelinfo',
    },
    {
      knapp: 1,
      label: 'Situasjon',
    },
  ];

  const skjulMatrikkel = (svar) => {
    setModalMatrikkelOpen(false);
  };

  const skjulSituasjon = (svar) => {
    setModalSituasjonOpen(false);
  };

  //************* Dialogs End ***************************************** */

  const avvikClick = (avvik) => {
    setValgtAvvik(avvik);
  };

  const skjulAvvik = (svar) => {
    setModalAvvikOpen(false);
    setValgtAvvik(null);
  };

  const onAvvikLagred = () => {
    getDataForAvvikMm(minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetId);
  };

  const anmerkningClick = (anm) => {
    setValgtAnmerkning(anm);
  };

  const skjulAnmerkning = (svar) => {
    setModalAnmerkningOpen(false);
    setValgtAnmerkning(null);
  };

  const oppdaterKommentar = () => {
    promisifyApiCall(getOppgave)(props.params.Id).then(
      //props.location.state.oppgave.id
      (data) => {
        setKommentarer(
          rensKommentarer(data.arbeidslisteBruksenhetOppgaveKommentar)
        );
      }
    );
  };

  const onAnmerkningLagred = () => {
    getDataForAvvikMm(minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetId);
  };

  const getIkon = (item) => {
    let ic = '';
    switch (item.objektType) {
      case 'Bruksenhet':
        ic = 'hjem';
        break;
      case 'Ildsted':
        ic = 'ildsted';
        break;
      case 'Røykløp':
        ic = 'royklop';
        break;
      default:
        ic = 'minus';
    }
    return ic;
  };

  //***************************************************************** */
  const tilbakeTilMineOppgaver = () => {
    setIsLoading(false);
    setIsSending(false);
    props.router.push({
      pathname: 'oppfolging/',
      state: {
        fra: 'KlargjørRapport',
      },
    });
  };
  const sendTilSaksbehandler = (godkjent, ansvarTilSaksbeh) => {
    if (dokumentHarBlittEndret) {
      setModalLagreDokumentOpen(true);
      return;
    } else {
      if (komRef.current.kommentarSkalLagreFirst()) {
        setModalLagreKommentarOpen(true);
        return;
      } else {
        let id = minOppgave.arbeidslisteBruksenhetOppgave.id;
        godkjennOppgave(
          id,
          godkjent,
          ansvarTilSaksbeh,
          godkjennOppgaveDoneTilbake
        );
      }
    }
  };

  const sendTilSidemann = () => {
    if (dokumentHarBlittEndret) {
      setModalLagreDokumentOpen(true);
      return;
    } else {
      if (komRef.current.kommentarSkalLagreFirst()) {
        setModalLagreKommentarOpen(true);
        return;
      } else {
        let id = minOppgave.arbeidslisteBruksenhetOppgave.id;
        let data = {
          id: id,
          avvikListe: minOppgave.arbeidslisteBruksenhetOppgave.avvikListe,
          anmerkningListe:
            minOppgave.arbeidslisteBruksenhetOppgave.anmerkningListe,
        };
        setOppgaveTilSidemann(id, data, hasSendTilSidemann);
      }
    }
  };

  const hasSendTilSidemann = () => {
    tilbakeTilMineOppgaver();
  };

  const sendTilEier = () => {
    if (dokumentHarBlittEndret) {
      setModalLagreDokumentOpen(true);
      return;
    } else {
      if (komRef.current.kommentarSkalLagreFirst()) {
        setModalLagreKommentarOpen(true);
        return;
      } else {
        if ( minOppgave.arbeidslisteBruksenhetOppgave.sidemannId !== null 
          && minOppgave.arbeidslisteBruksenhetOppgave.sidemannId !== 0 
          &&  minOppgave.arbeidslisteBruksenhetOppgave.ansvarlig === 'Saksbehandler'  
          && !minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent 
        ) {
          setJaNeiSendTilEierLikevelOpen(true);
        } else {
          forberedParamsOgFortsett(false);
        }
      }
    }
  };

  const resultatJaNeiSendLikevel = (svar) => {
    if (svar === 'ja') {
      setJaNeiSendTilEierLikevelOpen(false);
      forberedParamsOgFortsett(false);
    } else {
      setJaNeiSendTilEierLikevelOpen(false);
    }
  };

  const resultatVelgSidemann = () => {
    setModalVelgSidemannOpen(false);

    props.router.push({
      pathname: 'oppfolging/',
      state: {
        fra: 'KlargjørRapport',
      },
    });
  };

  const godkjennOppgaveDoneTilbake = () => {
    tilbakeTilMineOppgaver();
  };

  const getAvvikBox = (avvik) => {
    return (
      <div
        onClick={() => avvikClick(avvik)}
        style={{ verticalAlign: 'textTop' }}
        className='bfAvvikAapen'
        key={avvik.id}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '20px' }}>
                {' '}
                <NKIcon
                  className='pull-left'
                  icon={getIkon(avvik)}
                  color='nk-black'
                  size='0.8'
                />
              </td>

              <td>
                {avvik.bruksenhetIdsTilknyttet !== null && (
                  <img
                    height='24'
                    width='24'
                    src={DeltPipe}
                    className='app-preloader__img pull-left mr8'
                    alt=''
                    title='Delt'
                  />
                )}
              </td>

              <td>
                <span style={{ marginTop: '4px', float: 'right' }}>
                  {' '}
                  {moment(avvik.dato).format('DD.MM.YYYY')}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>

              <td
                colSpan={3}
                style={{ height: '45px', verticalAlign: 'textTop' }}>
                <span
                  style={{
                    float: 'left',
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: 'black',
                  }}>
                  {avvik.avvikType}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getAnmerkningBox = (anm) => {
    return (
      <div
        onClick={() => anmerkningClick(anm)}
        style={{ verticalAlign: 'textTop' }}
        className='bfAvvikAapen'
        key={anm.id}>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '20px' }}>
                {' '}
                <NKIcon
                  className='pull-left'
                  icon={getIkon(anm)}
                  color='nk-black'
                  size='0.8'
                />
              </td>
              <td>
                {' '}
                {anm.bruksenhetIdsTilknyttet !== null && (
                  <img
                    height='24'
                    width='24'
                    src={DeltPipe}
                    className='app-preloader__img pull-left mr8'
                    alt=''
                    title='Delt'
                  />
                )}
              </td>

              <td>
                <span style={{ marginTop: '4px', float: 'right' }}>
                  {' '}
                  {moment(anm.dato).format('DD.MM.YYYY')}
                </span>
              </td>
            </tr>
            <tr>
              <td></td>

              <td
                colSpan={3}
                style={{ height: '45px', verticalAlign: 'textTop' }}>
                <span
                  style={{
                    float: 'left',
                    textAlign: 'left',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    color: 'black',
                  }}>
                  {anm.beskrivelse}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  //************************************* Dokument *******************************************
  const getForhandsvisningPanel = () => {
    return (
      <div
        style={{
          borderRadius: '10px',
          minWidth: '776px',
          maxWidth: '776px',
          minHeight: 'calc(100vh - 146px)',
          maxHeight: 'calc(100vh - 146px)',
          paddingLeft: '29px',
          paddingRight: '44px',
          paddingTop: '26px',
          /* borderBottom: '1px solid lightGray', */
          overflowY: 'auto',
          backgroundColor: 'white',
          border: '1px solid lightgray',
        }}>
        <span
          style={{ background: 'url(' + forhand + ')' }}
          className='fr-view'
          dangerouslySetInnerHTML={{
            __html: forhandsview,
          }}
        />
      </div>
    );
  };

  const previewDokument = () => {
    visPreview();
  };

  const redigerDokument = () => {
    if (
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent &&
      minOppgave.arbeidslisteBruksenhetOppgave.ansvarlig === 'Saksbehandler' &&
      modelInitiert
    ) {
      setJaNeiGodkjenningOpen(true);
    } else {
      setForhansModus(false);
    }
  };

  const visPreview = () => {
    setPreviewHentet(true);
    setForhandsview('<p/><p><b>Vent, forhåndsvisning flettes...</b></p>');
    setForhansModus(true);

    forberedParamsOgFortsett(true);
  };

  const resultatVelgKontakter = (valgteKontakter) => {
    setModalVelgKontakterOpen(false);
    if (valgteKontakter != null) {
      forberedParamsOgFortsett3(
        minOppgave.arbeidslisteBruksenhetOppgave,
        false,
        valgteKontakter
      );
    }
  };

  const forberedParamsOgFortsett = (preview) => {
    let o = minOppgave.arbeidslisteBruksenhetOppgave;

    let bruksenhetids = [];
    //preview? da kun kontakter til valgt enhet, ellers også evt. kontakter ifm. delt avvik
    if (preview) {
      bruksenhetids.push(o.bruksenhetId.toString());
      if (kontakterForPreview) {
        forberedParamsOgFortsett2(o, preview, kontakterForPreview);
      } else {
        promisifyApiCall(getBruksenhetKontakter2)(bruksenhetids).then(
          (kontakter) => {
            setKontakterForPreview(kontakter);
            forberedParamsOgFortsett2(o, preview, kontakter);
          }
        );
      }
    } else {
      bruksenhetids.push(o.bruksenhetId.toString());
      for (let i = 0; i < avvikListe.length; i++) {
        if (avvikListe[i].bruksenhetIdsTilknyttet) {
          bruksenhetids.push(avvikListe[i].bruksenhetIdsTilknyttet);
        }
      }
      for (let i = 0; i < anmerkningListe.length; i++) {
        if (anmerkningListe[i].bruksenhetIdsTilknyttet) {
          bruksenhetids.push(anmerkningListe[i].bruksenhetIdsTilknyttet);
        }
      }

      if (kontakterForSend) {
        forberedParamsOgFortsett2(o, preview, kontakterForSend);
      } else {
        promisifyApiCall(getBruksenhetKontakter2)(bruksenhetids).then(
          (kontakter) => {
            setKontakterForSend(kontakter);
            forberedParamsOgFortsett2(o, preview, kontakter);
          }
        );
      }
    }
  };

  const forberedParamsOgFortsett2 = (o, preview, kontakter) => {
    //Velg kontakter
     
    if (!preview) {
      setModalVelgKontakterOpen(true);
    } else {
      forberedParamsOgFortsett3(o, preview, kontakter);
    }
  };

  const forberedParamsOgFortsett3 = (o, preview, kontakter) => {
    let avvikListeMed = [];
    for (let i = 0; i < avvikListe.length; i++) {
      const a = avvikListe[i];
      if (a.tilknyttet) {
        avvikListeMed.push(a);
      }
    }

    let anmListeMed = [];
    for (let i = 0; i < anmOgAndreListe.length; i++) {
      const an = anmOgAndreListe[i];
      if (an.tilknyttet) {
        anmListeMed.push(an);
      }
    }

    let params = {
      bruksenhetId: o.bruksenhetId,
      arbeidslisteId: o.arbeidslisteId,
      besokdato: o.besokdato,
      previewDoc: preview,
      previewDocTekst: model,
      oppgaveUnderbehandlingId: o.id,
      sidemannId: o.sidemannId,
      sidemann: o.sidemann,
      sidemannTittel: o.sidemannTittel,
      sidemannGodkjent: o.sidemannGodkjent,
      avvikListe: avvikListeMed,
      anmerkningListe: anmListeMed,
      bruksenhetKontakt: kontakter,
    };

    //Forhåndsvisning
    if (preview) {
      if (o.sidemannId === null || o.sidemannId === 0) {
        getPreviewDokumentSaksbehandler(params, gotPreview); //  /innbyggerdialog/saksbehandler/sendtileier/ikkepublisert
      } else {
        getPreviewDokumentSidemann(params, gotPreview); //  /innbyggerdialog/sidemannskontroll/sendtileier/ikkepublisert
      }
    } else {
      //Send til eier
      setIsLoading(true);
      setIsSending(true);
      if (o.sidemannId === null || o.sidemannId === 0) {
        sendTilEierFraKlargjorRapportSaksbehandler(
          params,
          tilbakeTilMineOppgaver
        ); //  /innbyggerdialog/saksbehandler/sendtileier/ikkepublisert
      } else {
        sendTilEierFraKlargjorRapportSidemann(params, tilbakeTilMineOppgaver); //  /innbyggerdialog/sidemannskontroll/sendtileier/ikkepublisert
      }
    }
  };

  const gotPreview = (err, html) => {
    setForhandsview(html.tekst);
  };

  const LagreDokument = () => {
    let id = minOppgave.arbeidslisteBruksenhetOppgave.id;
    let tekst = { tekst: model };
    endreOppgaveDokument(id, tekst, dokumentEndret);

    //Alternativ for å likevel kunne redigere
    /* if (minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent) {
      setJaNeiGodkjenningOpen(true);
    } else {
      let id = minOppgave.arbeidslisteBruksenhetOppgave.id;
      let tekst = { tekst: model };
      endreOppgaveDokument(id, tekst, dokumentEndret);
    } */
  };

  const resultatJaNei = (svar) => {
    //lukk dialogen om ja/nei heve godkjenningen
    setJaNeiGodkjenningOpen(false);

    // ja: ophev godkjenningen, saksbeh kan nå redigerer
    // nei: dokument settes tilbake til original. Ved å redigere får saksbeh. samme ja-nei dialog igjen.
    if (svar === 'ja') {
      let id = minOppgave.arbeidslisteBruksenhetOppgave.id;
      godkjennOppgave(id, false, true, godkjennOppgaveDoneOgRefresh);
      setForhansModus(false);
      buttonGroupA.current.setSelectBtnIndex(1);
    } else {
      setForhansModus(true);
      buttonGroupA.current.setSelectBtnIndex(0);
      /*  setModel(minOppgave.arbeidslisteBruksenhetOppgave.dokument);
      setTimeout(() => {
        setDokumentHarBlittEndret(false);
      }, 2000); */
    }
  };

  const resultatStatusJaNei = (svar) => {
    //lukk dialogen om ja/nei status
    setJaNeiStatusOpen(false);
    // ja: endre godkjenningen, ikke endre ansvar

    if (svar === 'ja') {
      let id = minOppgave.arbeidslisteBruksenhetOppgave.id;
      let godkj = !minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent;
      let saksbeh =
        minOppgave.arbeidslisteBruksenhetOppgave.ansvarlig === 'Saksbehandler';
      godkjennOppgave(id, godkj, saksbeh, godkjennOppgaveDoneOgRefresh);
    }
  };

  const startVelgSidemann = (svar) => {
    if (dokumentHarBlittEndret) {
      setModalLagreDokumentOpen(true);
      return;
    } else {
      if (komRef.current.kommentarSkalLagreFirst()) {
        setModalLagreKommentarOpen(true);
        return;
      } else {
        setModalVelgSidemannOpen(true);
      }
    }
  };

  const godkjennOppgaveDoneOgRefresh = () => {
    setIsLoading(true);
    hentData();
  };

  const resetEditorUndoRedo = () => {
    //undostack
    froalaRef.current.editor.editor.undo_stack = [];
    froalaRef.current.editor.editor.undo_index = [];
    froalaRef.current.editor.editor.undo.reset();
    //  froalaRef.current.editor.editor.undo.saveStep();
    froalaRef.current.editor.editor.events.focus();
  };

  const dokumentEndret = () => {
    setDokumentHarBlittEndret(false);

    resetEditorUndoRedo();

    /*     let endret = '<p><i>Dokumenttekst har blitt endret.</i></p>...';
    komRef.current.setNyKommentar(endret);
 */
    /*     promisifyApiCall(getOppgave)(props.params.Id).then(
      (data) => {
       setModel(data.arbeidslisteBruksenhetOppgave.dokument);
       let endret = '<p><i>Dokumenttekst har blitt endret.</i></p>...';
       komRef.current.setNyKommentar(endret);
      }
    ); */
  };

  const setModelTekst = (tekst) => {
    setModel(tekst);

    //Ikke vis lagring ved første endring av model/text
    if (modelInitiert) {
      setDokumentHarBlittEndret(true);
    }

    /*  if (
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent &&
      minOppgave.arbeidslisteBruksenhetOppgave.ansvarlig === 'Saksbehandler' &&
      modelInitiert
    ) {
      setJaNeiGodkjenningOpen(true);
    } else {
      setModel(tekst);

      //Ikke vis lagring ved første endring av model/text
      if (modelInitiert) {
        setDokumentHarBlittEndret(true);
      }
    } */
  };

  const afterUndoRedo = (ed) => {
    /*  console.log('99x', model);
    console.log('99x', minOppgave.arbeidslisteBruksenhetOppgave.dokument); */
    if (model === minOppgave.arbeidslisteBruksenhetOppgave.dokument) {
      //  console.log('99X', 'same');
      setDokumentHarBlittEndret(false);
      resetEditorUndoRedo();
    } else {
      //  console.log('99X', 'ulik');
    }
  };

  //*********************************** Grønn knapp **************************************
  const getGreenKnapp = () => {
    if (props.location.state.ikkeAnsvarlig) {
      return getGreenKnappIkkeAnsvarlig();
    }
    if (
      minOppgave.arbeidslisteBruksenhetOppgave.ansvarlig === 'Saksbehandler'
    ) {
      return getGreenKnappSaksbehandler();
    } else {
      return getGreenKnappSidemann();
    }
  };

  const getGreenKnappIkkeAnsvarlig = () => {
    return (
      <div
        style={{
          height: '40px',
          width: '250px',
          position: 'relative',
        }}>
        {' '}
        <BFButton
          disabled={true}
          buttonWidth={250}
          green={false}
          buttonLabel='Send til..'
          image={senddialogwhite}
          imageHover={senddialogblack}></BFButton>
      </div>
    );
  };

  const getGreenKnappSaksbehandler = () => {
    //    1 ikke sidemann innvolvert         send til eier   +  (if etaten har sidemann:)  send til sidemann
    if (
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannId === null ||
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannId === 0
    ) {
      return (
        <div
          style={{
            height: '40px',
            width: '250px',
            position: 'relative',
          }}>
          {' '}
          <BFDropDownButton
            disabled={fane !== 'Dok'}
            buttonWidth={250}
            green={true}
            buttonLabel='Send til..'
            image={senddialogwhite}
            imageHover={senddialogblack}></BFDropDownButton>
          <ul className='dropdown-menu' style={{ width: '320px', left: '0px' }}>
            <li>
              <a onClick={() => sendTilEier()}>
                <span
                  className='bfspan'
                  style={{ padding: '4px', cursor: 'pointer' }}>
                  <span style={{ fontSize: '16px' }}>
                    {/*  */}
                    Send til eier/publiser
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a onClick={() => startVelgSidemann(true)}>
                <span
                  className='bfspan'
                  style={{ padding: '4px', cursor: 'pointer' }}>
                  <span style={{ fontSize: '16px' }}>Send til en sideperson</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      );
    }

    //    2 sidemann, men ikke godkjent    send til sidemann
    /*     if (
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannId > 0 &&
      !minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent
    ) {
      console.log('XAX 2', minOppgave.arbeidslisteBruksenhetOppgave);
      return (
        <>
          <button
            style={{ height: '40px', width: '250px' }}
            className='btn btn-success dropdown-toggle'
            type='button'
            onClick={() => sendTilSidemann()}
            data-toggle='dropdown'>
            <span style={{ float: 'left', marginLeft: '10px' }}>
              <img height='24' width='24' src={bruker} alt='' />
            </span>
            <span style={{ fontSize: '16px' }}>Send til sidemann</span>
          </button>
        </>
      );
    } */

    //    3 sidemann og godkjent   send til sidemann + send til eier/publiser
    if (
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannId > 0
      /*  minOppgave.arbeidslisteBruksenhetOppgave.sidemannId > 0 &&
      minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent */
    ) {
      return (
        <div
          style={{
            height: '40px',
            width: '250px',
            position: 'relative',
          }}>
          {' '}
          <BFDropDownButton
            disabled={fane !== 'Dok'}
            buttonWidth={250}
            green={true}
            buttonLabel='Send til..'
            image={senddialogwhite}
            imageHover={senddialogblack}></BFDropDownButton>
          <ul className='dropdown-menu' style={{ width: '320px', left: '0px' }}>
            <li>
              <a onClick={() => sendTilEier()}>
                <span
                  className='bfspan'
                  style={{ padding: '4px', cursor: 'pointer' }}>
                  <span style={{ fontSize: '16px' }}>
                    Send til eier/publiser
                  </span>
                </span>
              </a>
            </li>
            <li>
              <a onClick={() => sendTilSidemann()}>
                <span
                  className='bfspan'
                  style={{ padding: '4px', cursor: 'pointer' }}>
                  <span style={{ fontSize: '16px' }}>Send til sideperson</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      );
    }
  };

  const getGreenKnappSidemann = () => {
    return (
      <div
        style={{
          height: '40px',
          width: '250px',
          position: 'relative',
        }}>
        {' '}
        <BFDropDownButton
          disabled={fane !== 'Dok'}
          buttonWidth={250}
          green={true}
          buttonLabel='Send til saksbehandler'
          image={bruker}
          imageHover={bruker}></BFDropDownButton>
        <ul className='dropdown-menu' style={{ width: '350px', left: '0px' }}>
          {/*     {!minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent && ( */}
          <li>
            <a onClick={() => sendTilSaksbehandler(true, true)}>
              <span
                className='bfspan'
                style={{ padding: '4px', cursor: 'pointer' }}>
                <span style={{ fontSize: '16px' }}>
                  Send til saksbehandler som{' '}
                  <span style={{ fontWeight: 'bold' }}>godkjent</span>
                </span>
              </span>
            </a>
          </li>
          {/*   )} */}

          <li>
            <a onClick={() => sendTilSaksbehandler(false, true)}>
              <span
                className='bfspan'
                style={{ padding: '4px', cursor: 'pointer' }}>
                <span style={{ fontSize: '16px' }}>
                  Send til saksbehandler som{' '}
                  <span style={{ fontWeight: 'bold' }}>ikke godkjent</span>
                </span>
              </span>
            </a>
          </li>

          {minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent && (
            <li>
              <span style={{ padding: '4px', cursor: 'arrow' }}>
                <span
                  style={{
                    marginLeft: '20px',
                    fontSize: '16px',
                    color: 'gray',
                  }}>
                  Send til saksbehandler som godkjent
                </span>
              </span>
            </li>
          )}
        </ul>
      </div>
    );
  };

  return (
    <div
      style={{
        paddingRight: '10px',
        paddingLeft: '10px',
      }}>
      {model === '...' || isSending ? (
        <>
          <Spinner className='h4' />
        </>
      ) : (
        <>
          <h3>
            <span style={{ marginRight: '4px' }}>
              {minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent && (
                <span
                  style={{ fontSize: '16px', marginRight: '8px' }}
                  className='badge badge-inverse'>
                  Godkjent
                </span>
              )}
              {!minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent &&
                minOppgave.arbeidslisteBruksenhetOppgave.sidemannId !== null &&
                minOppgave.arbeidslisteBruksenhetOppgave.sidemannId !== 0 && (
                  <span
                    style={{ fontSize: '16px', marginRight: '8px' }}
                    className='badge badge-inverse'>
                    Ikke godkjent
                  </span>
                )}
            </span>
            Klargjør rapport for
            <span style={{ marginLeft: '4px' }}>
              {minOppgave.arbeidslisteBruksenhetOppgave
                ? minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetAdresse
                : ''}
            </span>
            {fyringsForbud && (
              <span
                style={{ fontSize: '16px', marginLeft: '4px' }}
                className='badge badge-error'>
                Fyringsforbud !
              </span>
            )}
          </h3>
          {/* Header med knapp, saksbeh, adresse og antall avvik */}
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td rowSpan='2' style={{ width: '210px' }}>
                  {/*  Hvilken knapp skal brukes? */}
                  {getGreenKnapp()}
                </td>
                <td
                  style={{
                    paddingLeft: '20px',
                  }}>
                  <span>
                    {minOppgave.arbeidslisteBruksenhetOppgave.saksbehandler ? (
                      <span>
                        <span>{'Saksbeh:'}</span>
                        <span style={{ paddingLeft: '14px' }}>
                          {
                            minOppgave.arbeidslisteBruksenhetOppgave
                              .saksbehandler
                          }
                          {minOppgave.arbeidslisteBruksenhetOppgave
                            .ansvarlig === 'Saksbehandler' && (
                            <span style={{ paddingLeft: '4px' }}>
                              {'( Ansvarlig )'}
                            </span>
                          )}
                        </span>
                      </span>
                    ) : (
                      <span>{'Saksbeh.'}</span>
                    )}
                  </span>
                </td>

                <td rowSpan='2' style={{ flex: '1', display: 'inlineblock' }}>
                  <span style={{ float: 'right' }}>
                    {screenModus !== 'small' && (
                      <BFButtonGroup
                        buttons={['Matrikkelinfo', 'Situasjon']}
                        afterClick={buttonValgt}
                        vertikal={false}></BFButtonGroup>
                    )}
                    {screenModus === 'small' && (
                      <div style={{ width: '130px' }}>
                        <NkaFormElementDropdown
                          handleValueChange={buttonValgt}
                          value={0}
                          values={knapper}
                          other={{ placeholder: 'Vis..   ' }}
                        />
                      </div>
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: '20px',
                  }}>
                  <span>
                    {minOppgave.arbeidslisteBruksenhetOppgave.sidemann
                      ? 'Sideperson: ' +
                        minOppgave.arbeidslisteBruksenhetOppgave.sidemann
                      : ''}
                    {minOppgave.arbeidslisteBruksenhetOppgave.ansvarlig ===
                      'Sideperson' && (
                      <span style={{ paddingLeft: '4px' }}>
                        {'( Ansvarlig )'}
                      </span>
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          {/* ############## STOR ##################   Faner med Dokument og Avvik/Anmerkninger */}
          {screenModus !== 'small' && (
            <>
              <br />
              <BFButtonGroup
                buttons={[
                  'Dokument',
                  <span>
                    Avvik <span className='badge'>{avvikCount}</span>
                  </span>,
                  <span>
                    Anmerkning <span className='badge'>{anmerkningCount}</span>
                  </span>,
                  <span>
                    Andre forh. <span className='badge'>{andreCount}</span>
                  </span>,
                ]}
                afterClick={buttonFaneValgt}
                select={true}
                buttonWidth={'180px'}
                vertikal={false}></BFButtonGroup>
              <br />
              <br />
              {fane === 'Dok' && (
                <>
                  <div id='parentX'>
                    <div id='dokX'>
                      {' '}
                      <span>
                        {}
                        {!props.location.state.ikkeAnsvarlig && (
                          <span>
                            <BFButton
                              name={'Forhandsvisning'}
                              disabled={dokumentHarBlittEndret}
                              buttonLabel={['Forhåndsvisning']}
                              farget={false}
                              buttonWidth={'180px'}
                              afterClick={previewDokument}></BFButton>

                            <BFButton
                              name={'Rediger'}
                              buttonLabel={['Rediger']}
                              farget={false}
                              buttonWidth={'180px'}
                              afterClick={redigerDokument}></BFButton>

                            <BFButton
                              name={'LagreDokument'}
                              disabled={!dokumentHarBlittEndret}
                              buttonLabel={['Lagre dokument']}
                              farget={true}
                              buttonWidth={'180px'}
                              afterClick={LagreDokument}></BFButton>
                          </span>
                        )}
                      </span>
                      <br />
                      {!forhandsModus && (
                        <>
                          <Editor
                            minHeight={'540px'}
                            ref={froalaRef}
                            model={model}
                            handleModelChange={(val) => setModelTekst(val)}
                            fletteOptions={editorFletteOptions}
                            ikkeFlettModus={true}
                            bruksenhetId={''}
                            afterUndoRedo={(ed) => afterUndoRedo(ed)}
                            parent={'A'}
                          />
                        </>
                      )}
                      {forhandsModus && (
                        <div style={{ marginLeft: '0px' }}>
                          {getForhandsvisningPanel()}
                        </div>
                      )}
                    </div>
                    <div id='komX'>
                      {' '}
                      <Kommentar
                        ref={komRef}
                        avvikId={0}
                        className='text-top-extra'
                        minOppgave={minOppgave}
                        kommentarer={kommentarer}
                        bruker={brukerNavn}
                        screenModus={screenModus}
                        parent='A'
                        oppdaterParent={oppdaterKommentar}></Kommentar>
                    </div>
                  </div>
                </>
              )}
              {fane === 'Avv' && avvikCount > 0 && (
                <div>
                  <label>Åpne Avvik knyttet til oppgave:</label>
                  <br />
                  {avvikListe.map((avvik) => {
                    if (avvik.tilknyttet) {
                      return getAvvikBox(avvik);
                    }
                  })}
                  <br />
                  <br />
                  <label>Andre upubliserte åpne Avvik:</label>
                  <br />
                  {avvikListe.map((avvik) => {
                    if (!avvik.tilknyttet) {
                      return getAvvikBox(avvik);
                    }
                  })}
                </div>
              )}
              {fane === 'Avv' && avvikCount === 0 && (
                <div>
                  <label style={{ color: 'GrayText' }}>
                    Ingen åpne Avvik.{' '}
                  </label>
                </div>
              )}

              {fane === 'Anm' && anmerkningCount > 0 && (
                <div>
                  <label>Åpne Anmerkninger knyttet til oppgave:</label>
                  <br />
                  {anmerkningListe.map((anm) => {
                    if (anm.tilknyttet) {
                      return getAnmerkningBox(anm);
                    }
                  })}
                  <br />
                  <br />
                  <label>Andre åpne Anmerkninger:</label>
                  <br />
                  {anmerkningListe.map((anm) => {
                    if (!anm.tilknyttet) {
                      return getAnmerkningBox(anm);
                    }
                  })}
                </div>
              )}
              {fane === 'Anm' && anmerkningCount === 0 && (
                <div>
                  <label style={{ color: 'GrayText' }}>
                    Ingen åpne Anmerkninger.{' '}
                  </label>
                </div>
              )}
              {fane === 'And' && andreCount > 0 && (
                <div>
                  <label>Åpne Andre forhold knyttet til oppgave:</label>
                  <br />
                  {andreListe.map((anm) => {
                    if (anm.tilknyttet) {
                      return getAnmerkningBox(anm);
                    }
                  })}
                  <br />
                  <br />
                  <label>Andre åpne Andre forhold:</label>
                  <br />
                  {andreListe.map((anm) => {
                    if (!anm.tilknyttet) {
                      return getAnmerkningBox(anm);
                    }
                  })}
                </div>
              )}
              {fane === 'And' && andreCount === 0 && (
                <div>
                  <label style={{ color: 'GrayText' }}>
                    Ingen åpne Andre forhold.{' '}
                  </label>
                </div>
              )}
            </>
          )}
          {/* ############## SMALL ##################   Faner med Dokument og Avvik mm. */}
          {screenModus === 'small' && (
            <>
              <br />
              <span>
                <BFButtonGroup
                  buttons={[
                    'Dokument',
                    '.Avvik mm. (' +
                      (avvikCount + anmerkningCount + andreCount) +
                      ')',
                  ]}
                  select={true}
                  buttonWidth={'180px'}
                  afterClick={buttonFaneValgt}
                  vertikal={false}></BFButtonGroup>
                {fane === 'Dok' && (
                  <button
                    onClick={() =>
                      linkTekst === 'Vis kommentar'
                        ? setLinkTekst('Skul kommentar')
                        : setLinkTekst('Vis kommentar')
                    }
                    type='button'
                    data-toggle='collapse'
                    data-target='#kommentar'
                    className='btn btn-link'>
                    {linkTekst}
                  </button>
                )}
              </span>
              <br />
              {fane === 'Dok' && (
                <div
                  className='container'
                  style={{ padding: 0, float: 'left' }}>
                  <div id='kommentar' className='collapse'>
                    <Kommentar
                      ref={komRef}
                      minOppgave={minOppgave}
                      kommentarer={kommentarer}
                      bruker={brukerNavn}
                      screenModus={screenModus}
                      oppdaterParent={oppdaterKommentar}></Kommentar>
                  </div>
                  <br />
                  <span>
                    {/*  <BFButtonGroup
                      buttons={['Forhåndsvisning', 'Rediger']}
                      select={true}
                      buttonWidth={'180px'}
                      afterClick={buttonForhandsValgt}
                      vertikal={false}></BFButtonGroup> */}
                    {!props.location.state.ikkeAnsvarlig && (
                      <span>
                        <BFButton
                          name={'Forhandsvisning'}
                          disabled={dokumentHarBlittEndret}
                          buttonLabel={['Forhåndsvisning']}
                          farget={false}
                          buttonWidth={'180px'}
                          afterClick={previewDokument}></BFButton>

                        <BFButton
                          name={'Rediger'}
                          buttonLabel={['Rediger']}
                          farget={false}
                          buttonWidth={'180px'}
                          afterClick={redigerDokument}></BFButton>

                        <BFButton
                          name={'LagreDokument'}
                          disabled={!dokumentHarBlittEndret}
                          buttonLabel={['Lagre dok.']}
                          farget={true}
                          buttonWidth={'180px'}
                          afterClick={LagreDokument}></BFButton>
                      </span>
                    )}
                  </span>
                  {!forhandsModus && (
                    <>
                      <div style={{ marginLeft: '0px' }}>
                        <Editor
                          minHeight={'540px'}
                          ref={froalaRef}
                          model={model}
                          handleModelChange={(val) => setModel(val)}
                          fletteOptions={editorFletteOptions}
                          ikkeFlettModus={true}
                          bruksenhetId={''}
                          afterUndoRedo={(ed) => afterUndoRedo(ed)}
                        />
                      </div>
                    </>
                  )}
                  {forhandsModus && (
                    <div style={{ marginLeft: '0px' }}>
                      {getForhandsvisningPanel()}
                    </div>
                  )}
                </div>
              )}
              {fane === '.Av' && (
                <div>
                  <BFButtonLinkGroup
                    buttons={[
                      'Avvik (' + avvikCount + ')',
                      'Anmerkninger (' + anmerkningCount + ')',
                      'Andre forhold ' + andreCount + ')',
                    ]}
                    afterClick={buttonAValgt}
                    select={true}
                    vertikal={false}></BFButtonLinkGroup>
                  <br />

                  {buttonAvvikValgt === 0 && avvikCount > 0 && (
                    <div>
                      <label>Åpne Avvik knyttet til oppgave:</label>
                      <br />
                      {avvikListe.map((avvik) => {
                        if (avvik.tilknyttet) {
                          return getAvvikBox(avvik);
                        }
                      })}
                      <br />
                      <br />
                      <label>Andre upubliserte åpne Avvik:</label>
                      <br />
                      {avvikListe.map((avvik) => {
                        if (!avvik.tilknyttet) {
                          return getAvvikBox(avvik);
                        }
                      })}
                    </div>
                  )}
                  {buttonAvvikValgt === 0 && avvikCount === 0 && (
                    <div>
                      <label style={{ color: 'GrayText' }}>
                        Ingen åpne Avvik.{' '}
                      </label>
                    </div>
                  )}

                  {buttonAvvikValgt === 1 && anmerkningCount > 0 && (
                    <div>
                      <label>Åpne Anmerkninger knyttet til oppgave:</label>
                      <br />
                      {anmerkningListe.map((anm) => {
                        if (anm.tilknyttet) {
                          return getAnmerkningBox(anm);
                        }
                      })}
                      <br />
                      <br />
                      <label>Andre åpne Anmerkninger:</label>
                      <br />
                      {anmerkningListe.map((anm) => {
                        if (!anm.tilknyttet) {
                          return getAnmerkningBox(anm);
                        }
                      })}
                    </div>
                  )}
                  {buttonAvvikValgt === 1 && anmerkningCount === 0 && (
                    <div>
                      <label style={{ color: 'GrayText' }}>
                        Ingen åpne Anmerkninger.{' '}
                      </label>
                    </div>
                  )}

                  {buttonAvvikValgt === 2 && andreCount > 0 && (
                    <div>
                      <label>Åpne Andre forhold knyttet til oppgave:</label>
                      <br />
                      {andreListe.map((anm) => {
                        if (anm.tilknyttet) {
                          return getAnmerkningBox(anm);
                        }
                      })}
                      <br />
                      <br />
                      <label>Andre åpne Andre forhold:</label>
                      <br />
                      {andreListe.map((anm) => {
                        if (!anm.tilknyttet) {
                          return getAnmerkningBox(anm);
                        }
                      })}
                    </div>
                  )}
                  {buttonAvvikValgt === 2 && andreCount === 0 && (
                    <div>
                      <label style={{ color: 'GrayText' }}>
                        Ingen åpne Andre forhold.{' '}
                      </label>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {/******************************** Dialoger **************************************************************/}

          <EnhetModal
            isOpen={modalSituasjonOpen}
            onClose={skjulSituasjon}
            itemId={minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetId}
            itemAdresse={
              minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetAdresse
            }
            /*     matrikkelenhetId={dialogData.matrikkelenhetId}
              bruksenhetIdMa={dialogData.matrikkelenhetId} 
               eiendom={dialogData.eiendom}  //Burde kanskje ikke være med? Ifm. feil Bronnøy..*/
            fraFiktive={false}
          />

          {minOppgave.arbeidslisteBruksenhetOppgave && (
            <NKModal size='medium' isOpen={modalMatrikkelOpen}>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button
                    className='close'
                    type='button'
                    onClick={skjulMatrikkel}>
                    <span>x</span>
                    <span className='sr-only'>Lukk</span>
                  </button>
                  <h4 className='modal-title'>
                    {' '}
                    {'Matrikkelinfo - ' +
                      minOppgave.arbeidslisteBruksenhetOppgave
                        .bruksenhetAdresse}{' '}
                  </h4>
                </div>
                <div className='modal-body'>
                  <Matrikkelinfo
                    onClose={skjulMatrikkel}
                    id={matrikkelId}
                    bygningsNr={bygningsNr}
                  />
                </div>
              </div>

              {/* Vi trenger ikke en footer */}
            </NKModal>
          )}

          {valgtAvvik && (
            <NKModal size='medlargeium' isOpen={modalAvvikOpen}>
              <AvvikForm
                onClose={skjulAvvik}
                steng={null}
                tittel={'Avvik '}
                objektReferanseType={valgtAvvik.objektType.charAt(0)}
                adresse={
                  minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetAdresse
                }
                objektReferanseId={valgtAvvik.objektReferanseId}
                bruksenhetid={
                  minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetId
                }
                onLagred={onAvvikLagred}
                id={valgtAvvik.id}
              />
            </NKModal>
          )}

          <OKDialog
            tittel={'Endringer dokument'}
            melding={'Du må lagre eller angre endringene i dokumentet først.'}
            isOpen={modalLagreDokumentOpen}
            onClose={() => setModalLagreDokumentOpen(false)}
            onOk={() => setModalLagreDokumentOpen(false)}
          />

          <OKDialog
            tittel={'Delte avvik! '}
            melding={'Delte avvik har blitt fjernet fra oppgaven.'}
            melding2={
              'Sjekk at gjeldende rapport samsvarer med registrerte avvik.'
            }
            isOpen={modalVarselDeltAvvikOpen}
            onClose={() => setModalVarselDeltAvvikOpen(false)}
            onOk={() => setModalVarselDeltAvvikOpen(false)}
          />

          <OKDialog
            tittel={'Delte anmerkninger! '}
            melding={'Delte anmerkninger har blitt fjernet fra oppgaven.'}
            melding2={
              'Sjekk at gjeldende rapport samsvarer med registrerte anmerkninger.'
            }
            isOpen={modalVarselDeltAnmOpen}
            onClose={() => setModalVarselDeltAnmOpen(false)}
            onOk={() => setModalVarselDeltAnmOpen(false)}
          />

          <OKDialog
            tittel={'Endringer kommentar'}
            melding={'Du må lagre eller angre endringene i kommentar først.'}
            isOpen={modalLagreKommentarOpen}
            onClose={() => setModalLagreKommentarOpen(false)}
            onOk={() => setModalLagreKommentarOpen(false)}
          />

          <JaNeiDialog
            tittel='Endre status'
            tekst={
              minOppgave.arbeidslisteBruksenhetOppgave.sidemannGodkjent
                ? 'Status er nå "Godkjent"'
                : 'Status er nå "Under beh."'
            }
            tekst2='Ønsker du å endre det nå?'
            isOpen={jaNeiStatusOpen}
            handleClose={resultatStatusJaNei}
            visClose={false}></JaNeiDialog>

          <JaNeiDialog
            tittel='Oppgaven er godkjent'
            tekst='Når du redigerer dokumentet, '
            tekst2='oppheves godkjenningen. Ok?'
            isOpen={jaNeiGodkjenningOpen}
            handleClose={resultatJaNei}
            visClose={false}
          />

          <JaNeiDialog
            tittel='Oppgaven er under kontroll,'
            tekst='men IKKE GODKJENT. '
            tekst2='Ønsker du å publisere likevel?'
            isOpen={jaNeiSendTilEierLikevelOpen}
            handleClose={resultatJaNeiSendLikevel}
            visClose={false}
          />

          <NKModal size='medium' isOpen={modalVelgSidemannOpen}>
            <ByttSidemann
              onClose={resultatVelgSidemann}
              tittel={'Velg sideperson'}
              valgtItem={minOppgave.arbeidslisteBruksenhetOppgave}
              type={'oppgave'}
            />
          </NKModal>

          <NKModal size='large' isOpen={modalVelgKontakterOpen}>
            <VelgKontakter
              kontakter={kontakterForSend}
              onClose={resultatVelgKontakter}></VelgKontakter>
          </NKModal>

          {valgtAnmerkning && (
            <NKModal size='large' isOpen={modalAnmerkningOpen}>
              <AnmerkningForm
                onClose={skjulAnmerkning}
                steng={null}
                tittel={'Anmerkning '}
                objektReferanseType={valgtAnmerkning.objektType.charAt(0)}
                adresse={
                  minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetAdresse
                }
                objektReferanseId={valgtAnmerkning.objektReferanseId}
                bruksenhetid={
                  minOppgave.arbeidslisteBruksenhetOppgave.bruksenhetId
                }
                onLagred={onAnmerkningLagred}
                id={valgtAnmerkning.id}
                type={valgtAnmerkning.andreForhold ? 'Andre' : 'Anmerkning'}
              />
            </NKModal>
          )}
        </>
      )}
    </div>
  );
}

export default withRouter(MineOppgaverKlargjorRapport);
