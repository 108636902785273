import callApi from './api';

export function getGrunndataAnalyseTiltak(filter, callback) {
    callApi('POST', '/grunndataanalyse/tiltak/', filter, callback);
};

export function getGrunndataAnalyseRoyklop(filter, callback) {
    callApi('POST', '/grunndataanalyse/royklop/', filter, callback);
};

export function getGrunndataAnalyseIldsted(filter, callback) {
    callApi('POST', '/grunndataanalyse/ildsted/', filter, callback);
};

export function getGrunndataAnalyseTiltakHyppighet(filter, callback) {
    callApi('POST', '/grunndataanalyse/tiltakhyppighet/', filter, callback);
};

export function getRisikoGrunndataAnalyse(filter, callback) {
    callApi('POST', '/grunndataanalyse/risikotall/', filter, callback); 
};