
import {
    compose,
    withState,
    withHandlers,
} from 'recompose';


const withToggle = compose(
    // https://github.com/acdlite/recompose/wiki/Recipes#reusable-withtoggle-hoc
    withState('toggledOn', 'toggle', false),
    withHandlers({
        show: ({ toggle }) => (e) => toggle(true),
        hide: ({ toggle }) => (e) => toggle(false),
        toggle: ({ toggle }) => (e) => toggle((current) => !current)
    })
);

export default withToggle;