import React, { Component, Fragment } from 'react';
import enhetApi from 'api/enhetApi';
//import { getSvarUtOppgaverForBruksenhet } from 'api/svarUtApi';
//import { contextInnstillingerTest, toggleTestSituasjonHoover } from 'context/contextInnstillinger.js';
import { PulseLoader } from 'halogenium';
import Tooltip from 'react-tooltip-lite';
import Tree from 'react-animated-tree';
import './tooltip.css';
import { debugFactory, promisifyApiCall } from 'utils';
import _ from 'lodash';
import situasjon from 'images/situasjon.png';
import { isMobile } from 'react-device-detect';
import hjem from 'images/hjem.png';
import royklop from 'images/royklop.png';
import ildsted from 'images/ildsted.png';
import ikkeRoyklop from 'images/IkkeiBrukRoyk.png';
import ikkeIldsted from 'images/IkkeiBrukIld.png';
import loggIm from 'images/logg.png';

const debug = debugFactory('nk:situasjonbutton');

let adresse = '';
let objektReferanseId;
let objektReferanseType;

const treeStyles = {
	color: 'black',
	fill: 'black',
	fontSize: '12px',
	/*  fontWeight: 'bold', */
	verticalAlign: 'middle',
	/*  border: '1px solid gray', */
	width: '300px',
	padding: '4px',
};

class SituasjonButton extends Component {
	constructor(props) {
		super(props);
		this.situasjonTree = React.createRef();
		this.state = {
			init: false,
			initLogg: false,
			origId: 0,
			visLogg: false,
			tooltip: (
				<div style={{ textAlign: 'center', width: '300px', fontSize: '12px' }}>
					<PulseLoader color='#26A65B' size='12px' margin='4px' />
				</div>
			),
			situasjontip: (
				<div style={{ textAlign: 'center', width: '300px', fontSize: '12px' }}>
					<PulseLoader color='#26A65B' size='12px' margin='4px' />
				</div>
			),
			loggtip: (
				<div style={{ textAlign: 'center', width: '300px', fontSize: '12px' }}>
					<PulseLoader color='#26A65B' size='12px' margin='4px' />
				</div>
			),
		};
	}

	makeSituasjontip = () => {
		//debug('Smurf item ***', this.props);

		if (!this.state.init) {
			let tempId;
			let temps;
			switch (this.props.fra) {
				case 'avvikaapne':
					adresse = this.props.item.bruksenhetAdresse;
					if (_.get(this.props.item, 'objektReferanseId')) {
						objektReferanseId = this.props.item.objektReferanseId;
					}
					if (_.get(this.props.item, 'objektReferanseType')) {
						objektReferanseType = this.props.item.objektReferanseType;
					}
					tempId = _.get(this.props.item, 'bruksenhetIds');
					if (tempId) {
						temps = tempId.split(',');
						this.setState({ origId: temps[0] });
						if (tempId.includes(',')) {
							this.makeFlereSituasjontip(temps);
							return;
						}

						enhetApi.getBruksenhetSituasjon(
							temps[0],
							null,
							this.onSituasjonFunnet
						);
					}
					break;
				case 'avvikIkkePub':
					adresse = this.props.item.bruksenhetAdresse;
					tempId = _.get(this.props.item, 'bruksenhetId');
					this.setState({ origId: tempId });
					enhetApi.getBruksenhetSituasjon(tempId, null, this.onSituasjonFunnet);
					break;
				case 'deltavvik':
					adresse = this.props.item.adresse;
					tempId = _.get(this.props.item, 'bruksenhetIdFag');
					this.setState({ origId: tempId });
					enhetApi.getBruksenhetSituasjon(tempId, null, this.onSituasjonFunnet);
					break;
				case 'avvikoppfbruk':
					adresse = this.props.tempAdresse;
					tempId = _.get(this.props, 'item');
					this.setState({ origId: tempId });
					enhetApi.getBruksenhetSituasjon(tempId, null, this.onSituasjonFunnet);
					break;
				default:
					break;
			}
		}
	};

	makeFlereSituasjontip = (temps) => {
		enhetApi.postBruksenhetAdresser2(temps, this.onAdresserHentet);
	};

	onAdresserHentet = (err, adresserFunnet) => {
		// adresse, bruksenhetIdFag, bruksenhetIdMa
		for (let i = 0; i < adresserFunnet.length; i++) {
			const adr = adresserFunnet[i];
			promisifyApiCall(enhetApi.getBruksenhetSituasjon)(
				adr.bruksenhetIdFag,
				null
			).then((sit) => {
				adr.situasjon = sit;
				adr.test = 'Test Abc';
			});
		}
		let enheter = [...adresserFunnet];

		setTimeout(() => {
			this.makeHtmlForFlere(enheter);
		}, 2000);
	};

	makeHtmlForFlere = (enheter) => {
		let situasjontip = (
			<div>
				<Fragment>
					{this.makeHeaderSimpel('Situasjon')}
					{/* adresser ************************************/}
					{enheter.map((enhet, i) => (
						<Fragment>
							<Tree
								content={enhet.adresse}
								style={treeStyles}
								open={true}
								type={
									<span>
										<img src={hjem} width='16px' alt='R' />
									</span>
								}>
								{/* røykløp *****************************************/}
								{enhet.situasjon.royklopListe &&
									enhet.situasjon.royklopListe.map((roykl, i) => (
										<Tree
											content={this.makeRoyklop(roykl)}
											open={objektReferanseType === 'I'}
											style={this.checkIsThisRoyk(roykl)}
											type={
												<span>
													{roykl.ibruk && (
														<img src={royklop} width='16px' alt='R' />
													)}
													{!roykl.ibruk && (
														<img src={ikkeRoyklop} width='16px' alt='R' />
													)}
												</span>
											}>
											{/* ildsted *****************************************/}
											{roykl.ildstedListe &&
												roykl.ildstedListe.map((ildst, ix) => (
													<Tree
														content={this.makeIldsted(ildst)}
														style={this.checkIsThisIld(ildst)}
														type={
															<span>
																{ildst.ibruk && (
																	<img src={ildsted} width='16px' alt='I' />
																)}
																{!ildst.ibruk && (
																	<img src={ikkeIldsted} width='16px' alt='I' />
																)}
															</span>
														}
													/>
												))}
											{/* ildsted end *****************************************/}
										</Tree>
									))}
								{/* røykløp end *****************************************/}
							</Tree>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
								}}>
								<label style={{ fontSize: '12px' }} />

								{/*   <button
                  onClick={this.toggleSpes.bind(this, enhet)}
                  style={{ height: '20px', fontSize: '12px' }}>
                  → SvarUt logg
                </button> */}
							</div>
						</Fragment>
					))}
				</Fragment>
			</div>
		);

		let init = true;
		this.setState({ situasjontip, init });
		this.setState({ tooltip: situasjontip });
	};

	onSituasjonFunnet = (err, sit) => {
		if (_.get(sit, 'royklopListe')) {
			let royklopListe = sit.royklopListe;

			let situasjontip = (
				<div>
					<Fragment>
						{/*   {this.makeHeader('Situasjon', '→ SvarUt-logg')} */}
						<Tree
							id='situasjonTree'
							ref={this.situasjonTree}
							content={adresse}
							style={treeStyles}
							type={
								<span>
									<img src={hjem} width='16px' alt='R' />
								</span>
							}
							open={true}>
							{royklopListe.map((roykl, i) => (
								<Tree
									key={'rl_' + roykl.id}
									open={objektReferanseType === 'I'}
									content={this.makeRoyklop(roykl)}
									style={this.checkIsThisRoyk(roykl)}
									type={
										<span>
											{roykl.ibruk && (
												<img src={royklop} width='16px' alt='R' />
											)}
											{!roykl.ibruk && (
												<img src={ikkeRoyklop} width='16px' alt='R' />
											)}
										</span>
									}>
									{roykl.ildstedListe.map((ildst, ix) => (
										<Tree
											content={this.makeIldsted(ildst)}
											style={this.checkIsThisIld(ildst)}
											open
											type={
												<span>
													{ildst.ibruk && (
														<img src={ildsted} width='16px' alt='I' />
													)}
													{!ildst.ibruk && (
														<img src={ikkeIldsted} width='16px' alt='I' />
													)}
												</span>
											}
										/>
									))}
								</Tree>
							))}
						</Tree>
					</Fragment>
				</div>
			);

			let init = true;
			this.setState({ situasjontip, init });
			this.setState({ tooltip: situasjontip });
		} else {
			let init = true;
			let situasjontip = <div>Ingen røykløp funnet..</div>;
			this.setState({ situasjontip, init });
			this.setState({ tooltip: situasjontip });
		}
	};

	formatDato(datostring) {
		let date = new Date(datostring);
		var dateString =
			('0' + date.getDate()).slice(-2) +
			'.' +
			('0' + (date.getMonth() + 1)).slice(-2) +
			'.' +
			date.getFullYear();
		return dateString;
	}

	makeRoyklop = (royklop) => {
		let plass = 'Ukj. plass.';
		let dim = '( dim. ukj. )';
		if (royklop.plassering) {
			plass = royklop.plassering;
		}
		if (royklop.dimensjon) {
			dim = '( dim. ' + royklop.dimensjon + ' )';
		}
		return plass + '  ' + dim;
	};

	checkIsThisRoyk = (royklop) => {
		let st = {
			fontSize: '12px',
			color: '#000000',
			fontWeight: 'normal',
			textDecoration: 'none',
		};
		if (objektReferanseId) {
			if (royklop.id.toString() === objektReferanseId.toString()) {
				st = {
					fontSize: '14px',
					color: '#000000',
					fontWeight: 'bold',
					textDecoration: 'underline',
				}; //#FF00FF
			}
		}

		return st;
	};

	checkIsThisIld = (ildsted) => {
		let st = {
			fontSize: '12px',
			color: '#000000',
			fontWeight: 'normal',
			textDecoration: 'none',
		};
		if (objektReferanseId) {
			if (ildsted.id === objektReferanseId) {
				st = {
					fontSize: '14px',
					color: '#000000',
					fontWeight: 'bold',
					textDecoration: 'underline',
				}; //#FF4500
			}
		}

		return st;
	};

	makeIldsted = (ildsted) => {
		let plass = 'Ukj. plass.';
		let modell = '( Ukj. modell )';
		if (ildsted.plassering) {
			plass = ildsted.plassering;
		}

		if (ildsted.modell) {
			modell = '( ' + ildsted.modell + ' )';
		}
		return plass + '  ' + modell;
	};

	onMouseOver = () => {
		this.makeSituasjontip();
	};

	onClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	makeHeader = (header, btn) => {
		return (
			<Fragment>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<label style={{ fontSize: '12px' }}>{header}</label>
					<span>
						<button
							onClick={this.toggle}
							style={{ height: '20px', fontSize: '12px' }}>
							{btn}
						</button>
						{/*  <button
              onClick={toggleTestSituasjonHoover()}
              title='hoover eller klikk'
              style={{ marginLeft: '2px', height: '20px', fontSize: '12px' }}>
              <img src={innst} width='16px' alt='R' />
            </button> */}
					</span>
				</div>
			</Fragment>
		);
	};

	makeHeaderSimpel = (header) => {
		return (
			<Fragment>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<label style={{ fontSize: '12px' }}>{header}</label>

					{/*  <button
            onClick={toggleTestSituasjonHoover()}
            title='hoover eller klikk'
            style={{ marginLeft: '2px', height: '20px', fontSize: '12px' }}>
            <img src={innst} width='16px' alt='R' />
          </button> */}
				</div>
			</Fragment>
		);
	};

	loggHented = (err, loggArray) => {
		let html;
		if (loggArray.length > 0) {
			html = (
				<div style={{ textAlign: 'left', width: '300px', fontSize: '12px' }}>
					{this.makeHeader('SvarUt logg', '→ Situasjon')}

					<Tree
						content={loggArray[0].bruksenhetAdresse}
						style={treeStyles}
						type={
							<span>
								<img src={hjem} width='16px' alt='R' />
							</span>
						}
						open>
						{loggArray.map((logg, i) => (
							<Tree
								content={
									this.formatDato(logg.registrertDato) + ' - ' + logg.status
								}
								style={{ fontSize: '12px' }}
								type={
									<span>
										<img src={loggIm} width='16px' alt='R' />
									</span>
								}>
								<Tree
									style={{ padding: 0, fontSize: '12px' }}
									content={logg.info}
								/>
								<Tree
									style={{ padding: 0, fontSize: '12px' }}
									content={logg.mottakerNavn}
								/>
								<Tree
									style={{ padding: 0, fontSize: '12px' }}
									content={logg.mottakerAdresse}
								/>
							</Tree>
						))}
					</Tree>
				</div>
			);
		} else {
			html = (
				<div style={{ textAlign: 'center', width: '300px', fontSize: '12px' }}>
					{this.makeHeader('SvarUt logg', '→ Situasjon')}
					<br />
					<span style={{ fontSize: '12px', color: 'blue' }}>
						Finner ingen poster i SvarUt-Logg
					</span>
				</div>
			);
		}
		this.setState({ loggtip: html }, this.setState({ tooltip: html }));
		this.setState({ initLogg: true });
	}; /* 

/*   toggle = () => {
    if (!this.state.initLogg) {
      getSvarUtOppgaverForBruksenhet(this.state.origId, this.loggHented);
    }

    let vis = this.state.visLogg;
    if (vis) {
      this.setState({ tooltip: this.state.situasjontip });
    } else {
      this.setState({ tooltip: this.state.loggtip });
    }
    this.setState({ visLogg: !vis });
  }; */

	/*   toggleSpes = (enhet) => {
   // debug('Fox Id', enhet);
    if (!this.state.initLogg) {
      getSvarUtOppgaverForBruksenhet(enhet.bruksenhetIdFag, this.loggHented);
    }

    let vis = this.state.visLogg;
    if (vis) {
      this.setState({ tooltip: this.state.situasjontip });
    } else {
      this.setState({ tooltip: this.state.loggtip });
    }
    this.setState({ visLogg: !vis });
  }; */

	render() {
		/*   
    let hoover = contextInnstillingerTest._currentValue.situasjonHoover; */
		/*     let t = null;
    if (!this.props.hoover) {
      t = 'onClick';
    } */

		let toggle = null;
		if (isMobile) {
			toggle = 'onClick';
		}

		let element = <img src={situasjon} width='24px' alt='R' />;
		if (this.props.link) {
			element = (
				<span style={{ color: 'SlateBlue', cursor: 'pointer' }}>
					<u>Situasjon</u>
				</span>
			);
		}
		return (
			<div onMouseOver={this.onMouseOver} onClick={this.onClick}>
				<Tooltip
					onClick={this.onClick}
					zIndex={99000}
					eventToggle={toggle}
					content={this.state.tooltip}
					distance={0}
					direction='down-start'
					className='target'
					tipContentClassName=''
					arrow={false}>
					{element}
				</Tooltip>
			</div>
		);
	}
}

export default SituasjonButton;
