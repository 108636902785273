import callApi from './api';

var getAvvikById = function(id, callback) {
  callApi('GET', '/avvik/' + id, null, callback);
};

var createAvvik = function(avvik, callback) {
  callApi('POST', '/avvik/', avvik, callback);
};

var updateAvvik = function(id, avvik, callback) {
  callApi('PUT', '/avvik/' + id, avvik, callback);
};

function saveAvvik(id, avvik, callback) {
  callback(null, avvik);
}

var getAvvikByBruksenhetId = function(bruksenhetid, callback) {
  callApi('GET', '/avvik/bruksenhet/' + bruksenhetid, null, callback);
};

var getAvvikByRoyklopId = function(royklopid, callback) {
  callApi('GET', '/avvik/royklop/' + royklopid, null, callback);
};

var getAvvikByIldstedId = function(bruksenhetid, callback) {
  callApi('GET', '/avvik/ildsted/' + bruksenhetid, null, callback);
};

var getAvvikOgAnmerkningByBruksenhetId = function(bruksenhetid, callback) {
  callApi('GET', '/avvik/anmerkning/' + bruksenhetid, null, callback);
};

var getAvvikOppslagdata = function(objektType, objektId, callback) {
  callApi(
    'GET',
    '/avvikoppslagdata/objekt/' + objektType + '/' + objektId,
    null,
    callback
  );
};

var getAvvikOppslagdataKategori = function(callback) {
  callApi('GET', '/avvikoppslagdata/kategori/', null, callback);
};

var deleteAvvik = function(id, callback) {
  callApi('DELETE', '/avvik/' + id, null, callback);
};

//Hent alle
var getAvvikAdmBeskrivelse = function(callback) {
  callApi('GET', '/AvvikAdmBeskrivelse/aktive', null, callback);
};
//Create ny
var createAvvikAdmBeskrivelse = function(beskrivelse, callback) {
  callApi('POST', '/AvvikAdmBeskrivelse/', beskrivelse, callback);
};

var deleteAdmBeskrivelse = function(id, callback) {
  callApi('DELETE', '/AvvikAdmBeskrivelse/' + id, null, callback);
};

var updateAdmBeskrivelse = function(id, dto, callback) {
  callApi('PUT', '/AvvikAdmBeskrivelse/' + id, dto, callback);
};

// Publiserter avvik
var getApnePubliserteAvvik = function(param, callback) {
  callApi('POST', '/avvik/apnePublisert/', param, callback);
};

// Ikke Publiserte avvik og anmerkninger per bruksenhet
var getApneIkkePublisert = function(bruksenhetid, callback) {
  callApi(
    'GET',
    '/avvik/anmerkning/apneIkkePublisert/' + bruksenhetid,
    null,
    callback
  );
};

// Alle ikke Publiserter avvik og anmerkninger
var getAlleApneIkkePublisert = function(param, callback) {
  callApi('POST', '/avvik/anmerkning/apneikkepublisert', param, callback);
};

// Alle åpne ikke publiserte avvik og anm for bruksenhet (delte avvik??)
var getIkkePubliserte = function(bruksenhetid, callback) {
  callApi(
    'GET',
    '/avvik/anmerkning/ikkePublisert/' + bruksenhetid,
    null,
    callback
  );
};
var getApneForSaksbehandler = function(saksbehandlerId, callback) {
  callApi(
    'GET',
    '/avvik/anmerkning/antallapne/' + saksbehandlerId,
    null,
    callback
  );
};
var updateSaksbehandlerForApne = function(sIdFra, sIdTil, callback) {
  callApi(
    'POST',
    '/avvik/anmerkning/endresaksbehandlerapne/' + sIdFra + '/' + sIdTil,
    null,
    callback
  );
};
var getAvvikTilOppfolgingForSaksbehandler = function(callback) {
  callApi('GET', '/avvik/tiloppfolgingsaksbehandler/', null, callback);
};

// Åpne per bruksenhet
var getApneAvvikForBruksenhet = function(bruksenhetid, callback) {
  callApi(
    'GET',
    '/avvik/apnepublisert/bruksenhet/' + bruksenhetid,
    null,
    callback
  );
};

// Åpne per bruksenhet
var getApnePubliserteAvvikForBruksenhet = function(bruksenhetid, callback) {
  callApi(
    'GET',
    '/avvik/oppfolging/bruksenhet/' + bruksenhetid,
    null,
    callback
  );
};

//  Lagrer om avvik innbyggerdialog er klar til publisering med tilhørende dialog tekst
var updateInnbyggerDialogPubliseringKlarv2 = function(
  avvikId,
  stringDto,
  callback
) {
  callApi(
    'PUT',
    '/avvik/innbyggerdialogpubliseringklarv2/' + avvikId,
    stringDto,
    callback
  );
};

//  Lagrer om avvik innbyggerdialog er under arbeid
var updateInnbyggerDialogUnderArbeid = function(avvikId, callback) {
  callApi(
    'PUT',
    '/avvik/innbyggerdialogunderarbeid/' + avvikId,
    null,
    callback
  );
};

//  Lagrer om avvik innbyggerdialog er klar til lukking
var updateInnbyggerdialogpubliseringklarlukk = function(
  avvikId,
  arsakId,
  callback
) {
  callApi(
    'PUT',
    '/avvik/innbyggerdialogpubliseringklarlukk/' + avvikId,
    arsakId,
    callback
  );
};

/* var updateInnbyggerdialogpubliseringklarlukk = function(avvikId, arsakId, callback) {
    let obj = { avvikId: avvikId, arsakId: arsakId };
    callApi('PUT', '/avvik/innbyggerdialogpubliseringklarlukk/'+ avvikId, obj, callback);
}; */

//  Lagrer om avvik innbyggerdialog Fjern klar til lukking
var updateInnbyggerdialogpubliseringFjernklarlukk = function(
  avvikId,
  callback
) {
  callApi(
    'PUT',
    '/avvik/innbyggerdialogpubliseringfjernklarlukk/' + avvikId,
    null,
    callback
  );
};

// Sjekk om en paragraf er knyttet til en åpen avvik
var getAntallparagraftilknyttetapneavvik = function(paragrafId, callback) {
  callApi(
    'GET',
    '/avvik/antallparagraftilknyttetapneavvik/' + paragrafId,
    null,
    callback
  );
};

var fjernParagrafTilknyttetApneAvvik = function(paragrafId, callback) {
  callApi(
    'DELETE',
    '/avvik/deleteparagraftilknyttetapneavvik/' + paragrafId,
    null,
    callback
  );
};

var getAvvikAnmerkningerForBruksenhet = function(bruksenhetid, alle, callback) {
  callApi(
    'GET',
    '/avvik/anmerkning/' + bruksenhetid + '/ ' + alle,
    null,
    callback
  );
};

var getApneAvvikAnmIkkePublisert = function(bruksenhetid, callback) {
  callApi(
    'GET',
    '/avvik/anmerkning/IkkePublisert/' + bruksenhetid,
    null,
    callback
  );
};

var createAvvikKommentar = function(param, callback) {
  callApi('POST', '/avvik/kommentar/', param, callback);
};

var oppfolgingSendSidemannskontroll = function(id, sidemannId, callback) {
    if (sidemannId){
        callApi(
            'PUT',
            '/avvik/oppfolgingsidemannskontroll/' + id + '/' + sidemannId,
            null,
            callback
          );
    } else {
        callApi(
            'PUT',
            '/avvik/oppfolgingsidemannskontroll/' + id,
            null,
            callback
          );
    }
 
};

var oppfolgingSendSaksbehandler = function(id, callback) {
  callApi('PUT', '/avvik/oppfolgingsaksbehandler/' + id, null, callback);
};

var oppfolgingAvbrytSidemann = function(id, callback) {
  callApi('PUT', '/avvik/sidemannkontrollavbryt/' + id, null, callback);
};

var avvikGodkjent = function(id, godkjent, callback) {
  callApi('PUT', '/avvik/godkjent/' + id + '/' + godkjent +'/' + true,  null, callback);
};

var avvikGodkjentTilSaksbehandler = function(id, godkjent, tilSaksbeh, callback) {
  callApi('PUT', '/avvik/godkjent/' + id + '/' + godkjent +'/' + tilSaksbeh, null, callback);
};



export default {
  getAvvikById,
  createAvvik,
  updateAvvik,
  deleteAvvik,
  saveAvvik,
  getAvvikByBruksenhetId,
  getAvvikByRoyklopId,
  getAvvikByIldstedId,
  getAvvikOppslagdata,
  getAvvikOppslagdataKategori,
  getAvvikAdmBeskrivelse,
  createAvvikAdmBeskrivelse,
  deleteAdmBeskrivelse,
  updateAdmBeskrivelse,
  getAvvikOgAnmerkningByBruksenhetId,
  getApnePubliserteAvvik,
  getApneIkkePublisert,
  getAlleApneIkkePublisert,
  getIkkePubliserte,
  getApneForSaksbehandler,
  updateSaksbehandlerForApne,
  getAvvikTilOppfolgingForSaksbehandler,
  getApnePubliserteAvvikForBruksenhet,
  updateInnbyggerDialogPubliseringKlarv2,
  updateInnbyggerDialogUnderArbeid,
  getApneAvvikForBruksenhet,
  updateInnbyggerdialogpubliseringklarlukk,
  updateInnbyggerdialogpubliseringFjernklarlukk,
  getAntallparagraftilknyttetapneavvik,
  fjernParagrafTilknyttetApneAvvik,
  getAvvikAnmerkningerForBruksenhet,
  getApneAvvikAnmIkkePublisert,
  createAvvikKommentar,
  oppfolgingSendSidemannskontroll,
  oppfolgingSendSaksbehandler,
  oppfolgingAvbrytSidemann,
  avvikGodkjent,
  avvikGodkjentTilSaksbehandler
};

export {
  getAvvikById,
  createAvvik,
  updateAvvik,
  deleteAvvik,
  saveAvvik,
  getAvvikByBruksenhetId,
  getAvvikByRoyklopId,
  getAvvikByIldstedId,
  getAvvikOppslagdata,
  getAvvikOppslagdataKategori,
  getAvvikAdmBeskrivelse,
  createAvvikAdmBeskrivelse,
  deleteAdmBeskrivelse,
  updateAdmBeskrivelse,
  getAvvikOgAnmerkningByBruksenhetId,
  getApnePubliserteAvvik,
  getApneIkkePublisert,
  getAlleApneIkkePublisert,
  getIkkePubliserte,
  getApneForSaksbehandler,
  updateSaksbehandlerForApne,
  getAvvikTilOppfolgingForSaksbehandler,
  getApnePubliserteAvvikForBruksenhet,
  updateInnbyggerDialogPubliseringKlarv2,
  updateInnbyggerDialogUnderArbeid,
  getApneAvvikForBruksenhet,
  updateInnbyggerdialogpubliseringklarlukk,
  updateInnbyggerdialogpubliseringFjernklarlukk,
  getAntallparagraftilknyttetapneavvik,
  fjernParagrafTilknyttetApneAvvik,
  getAvvikAnmerkningerForBruksenhet,
  getApneAvvikAnmIkkePublisert,
  createAvvikKommentar,
  oppfolgingSendSidemannskontroll,
  oppfolgingSendSaksbehandler,
  oppfolgingAvbrytSidemann,
  avvikGodkjent,
  avvikGodkjentTilSaksbehandler
};
