import React from 'react';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import {
  PanelGroup,
  Panel  
} from 'react-bootstrap';

let Bygningsdata = ({ bygning }) => {
  return (
    <Panel.Body collapsible>
      <p>
        <strong>Bygningsnr: </strong>
        {bygning.bygningsnummer}
      </p>
      <p>
        <strong>Løpenummer: </strong>
        {bygning.lopenummer}
      </p>
      <p>
        <strong>Type: </strong>
        {bygning.type}
      </p>
      <p>
        <strong>Status: </strong>
        {bygning.bygningsstatus}
      </p>
      <p>
        <strong>Statusdato: </strong>
        {bygning.dato}
      </p>
      <p>
        <strong>SEFRAK-minne: </strong>
        {bygning.sefrakminne}
      </p>
      <p>
        <strong>Kulturminne: </strong>
        {bygning.kulturminne}
      </p>
      <PanelGroup accordion id="bruksenheter" >  {/* defaultActiveKey={1}    */}
        {getBruksenheter(bygning)}
      </PanelGroup>
    </Panel.Body>
  );
};

let getBruksenheter = bygning => {
  let index = 0;
  let bruksenhetsPanels = _.map(bygning.bruksenheter, function(enhet) {
    index++;
    return (
      <Panel key={index} eventKey={index}>
        <Panel.Heading>
          <Panel.Title toggle>
            Bruksenhet ({index + '/' + bygning.bruksenheter.length})
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          <p>{enhet.adresse}</p>
          <p>
            <strong>Type: </strong>
            {enhet.type}
          </p>
          <p>
            <strong>Bruksenhetsnr: </strong>
            {enhet.bruksenhetNr}
          </p>
        </Panel.Body>
      </Panel>
    );
  });
  return bruksenhetsPanels;
};

class Bygningsinfo extends React.Component {

  render() {
    let {
      bygninger,
      eventKeyIndex,      
      valgtBygningsnummer      
    } = this.props;

    let index = 0;
    let bygningPanels = _.map(bygninger, function(bygning) {
      eventKeyIndex++;
      index++;
      let expanded = index === 1;
      if (valgtBygningsnummer) {
        expanded = parseInt(valgtBygningsnummer, 10) === bygning.bygningsnummer;
      }

      return (
        <div key={eventKeyIndex}>
          <Panel eventKey={'b' + eventKeyIndex} defaultExpanded={expanded}>
            <Panel.Heading>
              <Panel.Title toggle>
                <NKIcon
                  style={{ paddingTop: '1px', marginRight: '3px' }}
                  icon="hjem"
                  size="0.8"
                  color="nk-black"
                />
                Bygning ({index + '/' + bygninger.length})
              </Panel.Title>
            </Panel.Heading>
            <Bygningsdata bygning={bygning} />
          </Panel>
        </div>
      );
    });
    return <div>{bygningPanels}</div>;
  }
}
export default Bygningsinfo;
