import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';
import * as getIcon from '../geticon.js';
import MarkerClusterLayer from './MarkerClusterLayer';
import EnhetPopupKjor from './EnhetPopupKjor';
import '../EnhetPopup.css';
import {
  getByggLokasjon,
  getArblisteBrukenheterGjennomforing,
} from 'api/enhetApi';


var parentKart = null;

class MarkerClusterLayerControl extends Component {
  state = {
    initiert: false,
    arbeidsliste: [],
    gjennomfortIcon: null,
    valgtIcon: null,
    markerIcon: getIcon.redIcon,
    harAlleredeBound: false,
    doBound: true,
  };

  componentDidUpdate = () => {
    parentKart = this.props.kart;
    //Ikke start før parentKart har blitt satt
    if (parentKart != null) {
      if (
        this.props.visning === 'planlegging-varsel' ||
        this.props.visning === 'planlegging-rekke'
      ) {
        if (this.state.arbeidsliste !== this.props.data) {
          this.setState({ initiert: false });
        }
        let bound = !this.props.sorterModus;
        if (this.state.doBound !== bound) {
          this.setState({ doBound: bound });
        }
        //Overrule
        /*   if (this.props.visning === 'planlegging-rekke'){
          this.setState({ doBound: this.props.rekkeBound });
        } */
      }
      if (!this.state.initiert) {
        this.setState({ initiert: true });
        this.VelgRiktigVisning();
      }
    }
  };

  VelgRiktigVisning = () => {
    switch (this.props.visning) {
      case 'planlegging-arbeidsliste':
      case 'gjennomføring-arbeidsliste':
        this.hentArbeidsliste(this.props.data.id);
        break;
      case 'gjennomføring-kart':
        this.hentArbeidsliste(this.props.data);
        break;
      case 'planlegging-enhet':
        this.hentArbeidslisteOgEnhet(this.props);
        break;
      case 'gjennomføring-enhet':
        this.hentEnhet(this.props.data);
        break;
      case 'objekter-fagdata':
        this.hentFagdataEnhet(this.props.data);
        break;
      case 'planlegging-varsel':
        this.brukVarselListe(this.props.data);
        break;
      case 'planlegging-rekke':
        this.brukVarselListe(this.props.data);
        break;
      default:
    }
  };

  fjernUtenLokasjon = (enheter) => {
    let ryddetListe = [];
    for (let index = 0; index < enheter.length; index++) {
      let element = enheter[index];
      if (
        element.lokasjonLatitude.toString() !== '0.0' &&
        element.lokasjonLatitude.toString() !== '0'
      ) {
        ryddetListe.push(element);
      }
    }

    return ryddetListe;
  };

  hentArbeidslisteOgEnhet = (pro) => {
    let arbeidslisteId = pro.arbeidsliste.id;
    let enhetId = pro.itemid;
    getArblisteBrukenheterGjennomforing(
      arbeidslisteId,
      function(err, enheter) {
        if (enheter) {
          //Fjern enheter uten lokasjon
          enheter = this.fjernUtenLokasjon(enheter);
          let x = 0;
          for (let i = 0; i < enheter.length; i++) {
            let e = enheter[i];

            if (e.id === enhetId) {
              e.valgt = true;
              x = i;
              break;
            } else {
              e.valgt = false;
            }
          }

          this.setState({
            arbeidsliste: enheter,
            valgtIcon: getIcon.redIcon,
            markerIcon: getIcon.blueIcon,
          });
          setTimeout(() => this.iconSpiderfy(enheter[x]), 10);
        }
      }.bind(this)
    );
  };

  hentArbeidsliste = (arbeidslisteId) => {
    getArblisteBrukenheterGjennomforing(
      arbeidslisteId,
      function(err, enheter) {
        if (enheter) {
          //Fjern enheter uten lokasjon
          enheter = this.fjernUtenLokasjon(enheter);
          this.setState({
            arbeidsliste: enheter,
            gjennomfortIcon: getIcon.greenIcon,
          });
          setTimeout(() => this.setClusterBounds(), 400);
        }
      }.bind(this)
    );
  };

  hentEnhet = (enhet) => {
    this.setState({
      arbeidsliste: enhet,
      gjennomfortIcon: getIcon.greenIcon,
    });
    //Bruker timeOut, ellers for fort..
    setTimeout(() => this.setClusterBounds(), 400);
  };

  brukVarselListe = (liste) => {
    this.setState({ arbeidsliste: liste }, this.goKanskjeBounds());
  };

  goKanskjeBounds = () => {

    if (this.props.visning === 'planlegging-rekke') {
      if (this.props.rekkeBound) {
        setTimeout(() => this.setClusterBounds(), 400);
      }
    } else {
      if (this.state.doBound) {
        setTimeout(() => this.setClusterBounds(), 400);
      }
    }
  };

  hentFagdataEnhet = (enhet) => {
    let id = enhet[0].id;
    getByggLokasjon(
      id,
      function(err, lok) {
        if (lok) {
          this.makeSimpelArbeidsliste(lok);
        }
      }.bind(this)
    );
    //Bruker timeOut, ellers for fort..
    setTimeout(() => this.setClusterBounds(), 600);
  };

  makeSimpelArbeidsliste = (lok) => {
    let arbeidsliste = [];
    let enhet = this.props.data[0];
    enhet.lokasjonLatitude = lok.latitude;
    enhet.lokasjonLongitude = lok.longitude;
    arbeidsliste.push(enhet);
    this.setState({ arbeidsliste: arbeidsliste });
  };

  setClusterBounds = () => {
    let layer = this.refs.clusterLayer;
    if (parentKart !== undefined && parentKart !== null) {
      if (layer !== undefined && layer !== null) {
        if (layer.getTheBounds() !== null) {
          parentKart.fitBounds(layer.getTheBounds(), { padding: [10, 10] });
          if (this.props.visning === 'objekter-fagdata') {
            parentKart.setZoom(15);
          }
        }
      }
    }
  };

  iconSpiderfy = (enhet) => {
    if (enhet !== undefined) {
      let viewPosition = [enhet.lokasjonLatitude, enhet.lokasjonLongitude];
      parentKart.setView(viewPosition, parentKart.getZoom(), {
        animate: true,
        pan: {
          duration: 1,
        },
      });
      setTimeout(() => this.iconSpiderfy2(enhet), 300);
    }
  };

  iconSpiderfy2 = (enhet) => {
    parentKart.eachLayer(function(layer) {
      if (layer.getChildCount) {
        if (
          layer._cLatLng.lat === enhet.lokasjonLatitude &&
          layer._cLatLng.lng === enhet.lokasjonLongitude
        ) {
          layer.spiderfy();
        }
      }
    });
  };

  onKjor = (object) => {
    var lon = object.lokasjonLongitude;
    var lat = object.lokasjonLatitude;
    var url =
      'https://www.google.com/maps/dir/Current+Location/' + lat + ',' + lon;
    window.open(url);
  };

  onStreetview = (object) => {
    var lon = object.lokasjonLongitude;
    var lat = object.lokasjonLatitude;
    var url =
      'https://www.google.com/maps/place/' +
      object.adresse.substring(0, object.adresse.length - 6) +
      ',Norway/@' +
      lat +
      ',' +
      lon +
      '17z';
    window.open(url);
  };

  getTooltip = (data) => {
    if (this.props.visning === 'planlegging-varsel') {
      return this.getEnhetTooltipVarsel(data);
    }
    if (this.props.visning === 'planlegging-rekke') {
      return this.getEnhetTooltipVarsel(data);
    }
    if (this.props.visning === 'objekter-fagdata') {
      return this.getFagdataTooltip(data);
    }
    return this.getEnhetTooltip(data);
  };

  getFagdataTooltip = (data) => {
    const { adresse, bygningsnummer, bruksenhetstype, eiendom } = data;
    var ts =
      '-' +
      adresse +
      '\n ' +
      eiendom +
      '\n Bygningsnr: ' +
      bygningsnummer +
      '\n Bygningstype:' +
      bruksenhetstype;
    return ts;
  };

  getEnhetTooltipVarsel = (data) => {
    let dt = '';
    if (data.datoTid) {
      dt = data.datoTid.dd;
      if (data.datoTid.tf) {
        dt = dt + '   ' + data.datoTid.tf + ' - ' + data.datoTid.tt;
      }
    }

    let ts = data.bruksenhetAdresse + '\n ' + data.tiltak + '\n ' + dt;
    return ts;
  };

  getEnhetTooltip = (data) => {
    const { adresse, tiltakBruksenhet, tiltakRoyklop, eiendom } = data;
    let tiltak = '';
    if (tiltakBruksenhet) {
      tiltak = data.tiltakBruksenhet;
    }
    if (tiltakRoyklop) {
      if (tiltak === '') {
        tiltak = data.tiltakRoyklop;
      } else {
        tiltak = tiltak + ',' + data.tiltakRoyklop;
      }
    }
    tiltak = '\n ' + tiltak;

    let status = '';
    var index1 = '';
    var utf = '';
    var til = '';
    if (data.tiltakBrukenhetStatus) {
      index1 = data.tiltakBrukenhetStatus.indexOf('/');
      utf =
        parseInt(data.tiltakBrukenhetStatus.substring(0, index1)) +
        data.antallLopPaaAlisteBesokt;
      til =
        parseInt(
          data.tiltakBrukenhetStatus.substring(
            index1 + 1,
            data.tiltakBrukenhetStatus.length
          )
        ) + data.antallLopPaaAliste;
      status = utf + '/' + til;
    }
    status = '\n  Gjennomført::: ' + status;
    var ts =
      adresse +
      '\n ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ͞ ' +
      '\n ' +
      eiendom +
      tiltak +
      status;
    return ts;
  };

  getEnhetTooltipGj = (data) => {
    const { adresse, tiltakBruksenhet, tiltakRoyklop, eiendom } = data;
    let tiltak = '';
    if (tiltakBruksenhet) {
      tiltak = data.tiltakBruksenhet;
    }
    if (tiltakRoyklop) {
      if (tiltak === '') {
        tiltak = data.tiltakRoyklop;
      } else {
        tiltak = tiltak + ',' + data.tiltakRoyklop;
      }
    }

    let status = '';
    var index1 = '';
    var utf = '';
    var til = '';
    if (data.tiltakBrukenhetStatus) {
      index1 = data.tiltakBrukenhetStatus.indexOf('/');
      utf =
        parseInt(data.tiltakBrukenhetStatus.substring(0, index1)) +
        data.antallLopPaaAlisteBesokt;
      til =
        parseInt(
          data.tiltakBrukenhetStatus.substring(
            index1 + 1,
            data.tiltakBrukenhetStatus.length
          )) + data.antallLopPaaAliste;
      status = utf + '/' + til;
    }
    status = 'Gjennomført: ' + status;
    var ts = adresse + '\n' + eiendom + tiltak + '\n' + status;
    return ts;
  };

  goVelg = () => {
    this.props.tilSituasjon();
  };

  getMarkerWithCallback = ({ data }) => {
    if (this.props.visning === 'planlegging-varsel') {
      return;
    }
    if (this.props.visning === 'planlegging-rekke') {
      return;
    }
    if (this.props.visning === 'gjennomføring-kart') {
      return (
        <div className='opp50'>
          <label> {data.adresse} </label>
          <br />
          <button
            onClick={() => this.goVelg()}
            className='btn btn-success w100'>
            Velg
          </button>
        </div>
      );
    } else {
      return (
        <EnhetPopupKjor
          calldirection={this.onKjor}
          callstreetview={this.onStreetview}
          data={data}
        />
      );
    }
  };

  onClickedGj = (e) => {
    let s = this.getEnhetTooltipGj(e);
    this.props.onClickedGj(s, e);
  };

  render() {
    let latLngAccessor = (enhet) => [
      enhet.lokasjonLatitude,
      enhet.lokasjonLongitude,
      enhet.adresse,
    ];

    /* let MarkerWithCallback = ({ data }) => (
      <EnhetPopupKjor
        calldirection={this.onKjor}
        callstreetview={this.onStreetview}
        data={data}
      />
    ); */
    let position = 'topright';
    if (this.props.left === true) {
      position = 'topleft';
    }

    return (
      <div>
        <MarkerClusterLayer
          ref='clusterLayer'
          data={this.state.arbeidsliste}
          latLngAccessor={latLngAccessor}
          markerIcon={this.state.markerIcon}
          valgtIcon={this.state.valgtIcon}
          gjennomfortIcon={this.state.gjennomfortIcon}
          markerPopup={this.getMarkerWithCallback}
          /*   getMarkerTooltip={this.getTooltip} */
          visning={this.props.visning}
          onClickedGj={this.onClickedGj}
          onClickedVarsel={this.props.onClickedVarsel}
          sorterModus={this.props.sorterModus}
        />

        <Control position={position}>
          <div>
            <button
              title='Sentrer alle enheter'
              type='button'
              className={'btnLag'}
              onClick={this.setClusterBounds}>
              <NKIcon icon='avslutt-fullskjerm' color='nk-black' />
            </button>
          </div>
        </Control>
      </div>
    );
  }
}

export default MarkerClusterLayerControl;
