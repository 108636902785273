import callApi from './api';

var getIldstedById = function(id, callback) {
    callApi('GET', '/ildsted/' + id, null, callback);
};

var updateIldsted = function(id, ildsted, callback) {
    callApi('PUT', '/Ildsted/' + id, ildsted, callback);
};

function saveIldsted(ildsted, callback) {
   callApi('POST', '/ildsted/', ildsted, callback);
};

function deleteIldsted(id, callback) {
  callApi('DELETE', '/ildsted/' + id, null, callback);
};

function flyttIldsted(objektId, callback) {
    callApi('POST', '/ildsted/flytt', objektId, callback);
  };

var getIldstedOppslagdataNyEndre = function(callback) {
    callApi('GET', '/ildstedoppslagdata/nyendre/', null, callback);
};

export default { 
    getIldstedById,
    updateIldsted,
    saveIldsted,
    getIldstedOppslagdataNyEndre,
    deleteIldsted,
    flyttIldsted
    };

export {
    getIldstedById,
    updateIldsted,
    saveIldsted,
    getIldstedOppslagdataNyEndre,
    deleteIldsted,
    flyttIldsted
};