import React from 'react';
import EndreOrganisasjoner from './EndreOrganisasjoner.jsx';

class index extends React.Component {
  constructor() {
    super();
  }  
  render() {
    return <EndreOrganisasjoner ></EndreOrganisasjoner>;
  }
}
export default index;