import { AppInsights } from 'applicationinsights-js';     
import { isFunction } from 'lodash';                   
import CONFIG from 'lib/CONFIG';
import {    
    proxyFactory,
    debugFactory,
} from 'utils';

const debug = debugFactory('nk:utils:appinsights');

const NoopProxy = proxyFactory({}, {
    get: (_target, prop) => {
        debug(`Noop for appInsights.${prop}`);
        return () => {};
    }
});

const AppInsightsProxy = proxyFactory(AppInsights, {
    get: (target, prop) => {
        if (isFunction(target[prop]) && prop === 'trackException') {            
            return (...args) => {
                const [customDimensions] = args;                
                debug(prop, { customDimensions });
                return target[prop](...args);
            };
        }
        return target[prop];
        
    }
});

const isDevEnv = CONFIG.NODE_ENV.toLowerCase().startsWith('dev');
const localStorageKey = CONFIG.appInsights_instrumentationKey;

const instrumentationKey = localStorageKey || CONFIG.REACT_APP_APPLICATION_INSIGHTS_KEY;

// Noop if in dev env and no `instrumentationKey`
const isNoop = isDevEnv && !instrumentationKey;
const instance = (isNoop ? NoopProxy :  AppInsightsProxy);

export default instance;

let isSetUp = false;
export const setUp = () => {
    if (!isSetUp) {
        
        debug('AppInsights', {
            mode: isNoop ? 'Noop(off)' : `On(key from ${localStorageKey ? 'localStorage' : '.env'})`, 
            instrumentationKey,
            methodNames: Object.keys(AppInsights),
        });

        instance.downloadAndSetup({ instrumentationKey: instrumentationKey });
        isSetUp = true;
    }
    else {
        console.warn('AppInsights is already set up!');
    }
    return true;
};