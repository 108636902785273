import NKIcon from 'nka-icons';
import ReactTable from '@norkart/react-table';
import React, { Component } from 'react';

class MangleKontakDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    var colums = this.getKolloner();
    var eierkontakter = [];
    eierkontakter.push(this.props.eierkontakt);
    console.log(this.props.eierkontakt);
    return (
      <div>
        <h3>{this.props.adresse}</h3>
        <table>
          <tbody>
            <tr>
              <td valign="top">
                <span>
                  <NKIcon
                    icon="advarsel_roed"
                    className="pull-left"
                    size="1"
                    color="nk-black"
                  />
                </span>
              </td>
              <td style={{ paddingLeft: '10px' }}>
                <span >
                  Vi fant ikke telefonnummeret til eierkontakten. Melding om
                  kansellering vil likevel bli publisert til Min eiendom. Ta
                  kontakt med eieren manuelt for å informere om kansellering.
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <p />

        <ReactTable
          showIndex={false}
          items={eierkontakter}
          columns={colums}
          selectableRows={false}
          onRowClick={null}
          toggleAll={false}
        />
      </div>
    );
  }
  getKolloner = () => {
    return [
      {
        id: 'bruksenhetAdresse',
        name: 'Gjelder adresse',
        isSorted: false,
        sortable: false
      },
      {
        id: 'navn',
        name: 'Eierkontakt',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let teller = 1;
          return (
            <table>
              <tbody>
                {item.kontakt.map(function(ko) {
                  teller = teller + 1;
                  let key = 'k' + teller.toString();
                  return (
                    <tr key={key}>
                      <td key={'td' + key}>{ko.navn}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        }
      },
      {
        id: 'telefon',
        name: 'Telefon',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let teller = 1;
          return (
            <table>
              <tbody>
                {item.kontakt.map(function(ko) {
                  teller = teller + 1;
                  let key = 'k' + teller.toString();
                  return (
                    <tr key={key}>
                      <td key={'td' + key}><div className="red">Mangler telefon</div></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
          //return <div className="red">Mangler telefon</div>;
        }
      }
    ];
  };
}

export default MangleKontakDialog;
