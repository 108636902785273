import * as actionTypes from './actionTypes';

import _ from 'lodash';
import {
    debugFactory
} from 'utils';

const debug = debugFactory('nk:redux:app:actions');

export const incrementRequestCount = ({
    type: actionTypes.INCREMENT_REQUEST_COUNT,
});

export const decrementRequestCount = ({
    type: actionTypes.DECREMENT_REQUEST_COUNT,
});

export const exceptionOccured = ({
    type: actionTypes.EXCEPTION_OCCURED,
});


export const setStateSnapshot = (snapshotKey, snapshot) => (dispatch) => {
    debug('setStateSnapshot', { snapshotKey, snapshot });
    return Promise.resolve(dispatch({
        type: actionTypes.SET_STATE_SNAPSHOT,
        payload: {
            snapshotKey,
            snapshot: _.cloneDeep(snapshot),
        }
    }));
};
