import React from 'react';
import 'react-toggle-switch/dist/css/switch.min.css';
import '.././grunndata.css';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { updateAnmerkningTilAndreForhold, updateAndreForholdTilAnmerkning } from 'api/anmerkningApi';

var opprinneligAnm = false;
var id = 0;
var data = null;
class NyEndreOppslagsdata extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleTekstChange = this.handleTekstChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.dataLagret = this.dataLagret.bind(this);
    this.state = {
      lukket: true,
      tekst: '',
      visAndreForhold: null,
      anmerkningKategori: true
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  handleTekstChange(data) {
    this.setState({ tekst: data.target.value });
  }
  componentDidMount() {
    this.tekstInput.focus();
    console.log(this.props);
    if (this.props.nyEndre === 'Endre') {
      id = this.props.valgtItem.id;
      this.setState({
        tekst: this.props.valgtItem.tekst,
        lukket: this.props.valgtItem.aktiv,
        anmerkningKategori: this.props.valgtItem.anmerkningKategori
      });
      // Tar vare på om det er anm opprinnelig hvis den endres till andre forhold, for å gi melding
      if (this.props.type === 'Anmerkning / Andre forhold' && this.props.tabell === 'Kategori') {
          opprinneligAnm = this.props.valgtItem.anmerkningKategori;
      }
    } else {
    }
    this.sjekkAnm();
  }

  sjekkAnm = () => {
    if (
      this.props.type === 'Anmerkning / Andre forhold' &&
      this.props.tabell === 'Kategori'
    ) {
      let vis = (
        <div className="row">
          <div className="radio inline" style={{ paddingLeft: '15px' }}>
            <label>
              <input
                type="radio"
                name="sprakRadio"
                value="Anmerkning"
                defaultChecked={id === 0 ? true : this.props.valgtItem.anmerkningKategori === true}
                onChange={(e) => {
                  this.setState({ anmerkningKategori: true });
                }}
              />
              <span>Anmerkning</span>
            </label>
          </div>
          <div className="radio inline margin-left-15">
            <label>
              <input
                type="radio"
                name="sprakRadio"
                value="Andre forhold"
                defaultChecked={id === 0 ? false : this.props.valgtItem.anmerkningKategori === false}
                onChange={(e) => {
                  this.setState({ anmerkningKategori: false });
                }}
              />
              <span style={{ marginLeft: '5px' }}>Andre forhold</span>
            </label>
          </div>
        </div>
      );
      this.setState({ visAndreForhold: vis });
    }
  };

  submit() {
    if (
      this.props.type === 'Anmerkning / Andre forhold' &&
      this.props.tabell === 'Kategori') {
        data = {
          id: id,
          tekst: this.state.tekst,
          aktiv: this.state.lukket,
          anmerkningKategori: this.state.anmerkningKategori
        };
      } else {
        data = {
          id: id,
          tekst: this.state.tekst,
          aktiv: this.state.lukket
      };
    };
    // Ildsted
    if (this.props.type === 'Ildsted') {
      if (this.props.tabell === 'Plassering') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveIldstedPlassering(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateIldstedPlassering(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Produsent') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveIldstedProdusent(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateIldstedProdusent(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Type') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveIldstedType(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateIldstedType(id, data, this.dataLagret);
        }
      }
    }
    //Avvik
    if (this.props.type === 'Avvik') {
      if (this.props.tabell === 'Beskrivelse') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveAvvikBeskrivelse(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateAvvikBeskrivelse(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Status') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveAvvikStatus(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateAvvikStatus(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Årsak lukket') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveAvvikAdmLukkArsak(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateAvvikAdmLukkArsak(id, data, this.dataLagret);
        }
      }
    }
    // Anmerkning
    if (this.props.type === 'Anmerkning / Andre forhold') {
      if (this.props.tabell === 'Beskrivelse') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveAnmerkningBeskrivelse(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateAnmerkningBeskrivelse(
            id,
            data,
            this.dataLagret
          );
        }
      }
      if (this.props.tabell === 'Kategori') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveAvvikAnmerkningKategori(data, this.dataLagret);
        } else {
          console.log('her');
          console.log(opprinneligAnm);
          console.log(data.anmerkningKategori);

          if (opprinneligAnm && !data.anmerkningKategori) {
            this.dialogs.setOpenDialog('OkDialog');
          } else {
            oppslagsdataApi.updateAvvikAnmerkningKategori(
              id,
              data,
              this.dataLagret
            );
            if (opprinneligAnm && !data.anmerkningKategori) {
              updateAnmerkningTilAndreForhold(data.id, this.anmOppdatert);
            }
            if (!opprinneligAnm && data.anmerkningKategori) {
              updateAndreForholdTilAnmerkning(data.id, this.anmOppdatert);
            }
          }
          
        }
      }
    }
    //Røykløp
    if (this.props.type === 'Røykløp') {
      if (this.props.tabell === 'Adkomst') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopAdkomst(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopAdkomst(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Dimensjon') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopDimensjon(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopDimensjon(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Plassering') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopPlassering(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopPlassering(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Produsent') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopProdusent(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopProdusent(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Sotmengde') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopSotmengde(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopSotmengde(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Sotuttak') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopSotuttak(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopSotuttak(id, data, this.dataLagret);
        }
      }
      if (this.props.tabell === 'Type') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveRoyklopType(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateRoyklopType(id, data, this.dataLagret);
        }
      }
    }

    // Tiltak
    if (this.props.type === 'Tiltak') {
      if (this.props.tabell === 'Årsak ikke utført') {
        if (this.props.nyEndre === 'Ny') {
          oppslagsdataApi.saveTiltakAarsakIkkeUtfort(data, this.dataLagret);
        } else {
          oppslagsdataApi.updateTiltakAarsakIkkeUtfort(
            id,
            data,
            this.dataLagret
          );
        }
      }
    }
  }

  fortsettOppdaterAnmerkning = () => {
    oppslagsdataApi.updateAvvikAnmerkningKategori(
      id,
      data,
      this.dataLagret
    );
    if (opprinneligAnm && !data.anmerkningKategori) {
      updateAnmerkningTilAndreForhold(data.id, this.anmOppdatert);
    }
    if (!opprinneligAnm && data.anmerkningKategori) {
      updateAndreForholdTilAnmerkning(data.id, this.anmOppdatert);
    }
  }

  anmOppdatert = (err, res) => {

  }

  dataLagret(err, nyEndreitem) {
    this.props.dataLagret(nyEndreitem);
  }
  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    this.setState({ utfort: lukket });
  }

  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">
            {this.props.nyEndre} oppslagsdata for: {this.props.type} -{' '}
            {this.props.tabell}
          </h4>
        </div>
        <div className="modal-body">
          <div className="form margin-left-15 margin-right-15">
            <div className="row">
              <div className="col-sm-3">
                <div className="form-group">
                  <br />
                  <input
                    className="checkbox30"
                    type="checkbox"
                    onChange={this.lukketChange}
                    onClick={this.lukketChange}
                    checked={this.state.lukket}
                  />
                  <span className="checkboxtext18">Aktiv</span>
                </div>
              </div>
              <div className="col-sm-9">
                <div style={{ marginTop: '23px' }}>
                {this.state.visAndreForhold}
                </div>
              </div>
            </div> 
            <div className="row">
              <div className="col-sm-12">  
                <div className="form-group">
                  <label>Tekst</label>
                  <textarea
                    ref={(input) => {
                      this.tekstInput = input;
                    }}
                    className="form-control textareaNoresize"
                    type="textarea"
                    id="message"
                    placeholder="Tekst"
                    rows="2"
                    onChange={this.handleTekstChange}
                    value={this.state.tekst}
                    maxLength={this.props.textMaxLength}
                  />
                </div>
              </div>
              <div className="row">
              <div className="col-sm-12">  
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          {this.dialogs.renderOpenDialog()}
        </div>
      
    );
  }
}

export default NyEndreOppslagsdata;

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel="Endre anmerkning kategori"
        melding="OBS! Du endrer nå en egenskap som tidligere kan ha vært publisert i dokumenter som en annen type. Vil du fortsatt lagre endringen?'"
        onOk={owner.fortsettOppdaterAnmerkning}
      />
    );
  }
};
