import React, { Component } from 'react';
import * as _ from 'lodash';
import { withRouter } from 'lib/enhancers';
import EnhetTabell from './Fagdata/EnhetTabell.jsx';
import { CreateDefaultQueryMedUnummererte } from 'components/Search/DefaultQuery';
import CONFIG from 'lib/CONFIG';
import { getTranslations } from 'components/Search/translations';
import { CustomQueryBuilderSearch } from 'components/Search/QueryBuilder.js';
import FagKronRed from 'images/Annet/FagKronRed.png';
import FagKronYellow from 'images/Annet/FagKronYellow.png';
import FagKronGreen from 'images/Annet/FagKronGreen.png';
import {
  CustomQueryBuilder,
  CustomQueryBuilderBygg,
  CustomQueryBuilderEnhet,
} from 'components/Search/QueryBuilder.js';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
import {
  utelatBruksenhet,
  angreUtelatBruksenhet,
  getVurderte,
} from 'api/grunndataApi';
import {
  DynamicRangeFilter,
  RangeInput,
  ActionBar,
  ActionBarRow,
  SortingSelector,
  PageSizeSelector,
  Select,
  HitsStats,
  GroupedSelectedFilters,
  TermQuery,
  BoolShould,
  BoolMust,
  CheckboxFilter,
  SearchkitManager,
  SearchkitProvider,
  RefinementListFilter,
  Hits,
  Panel,
  SearchBox,
  NestedQuery,
  BoolMustNot,
  InputFilter,
  ResetFilters,
  Pagination,
} from 'searchkit';
import { getKommunerForOrganisasjon } from 'api/kommuneApi';
import { gebyrEiendom } from 'api/gebyrInnsynApi';
import EtablerFagdataModal from './Fagdata/EtablerFagdataModal.jsx';
import 'css/generated/themingSearchkit/theme.css';
import DialogStore from 'lib/DialogStore';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import { getAccessToken } from 'auth/auth';
import { reloadSearch } from 'utils/searchKitHelper';
import { debugFactory } from 'utils';
import norkartIdJs from 'auth/auth';
import Spinner from 'components/generics/Spinner';

const getCurrentCustomerId = () => {
  let cust = norkartIdJs.getCurrentCustomerContext();
  return cust !== undefined ? cust.CustomerId : '';
};

const debug = debugFactory('nk:searchFagdata');

const ES_URL = CONFIG.REACT_APP_API_ENDPOINT;
const ES_INDEX = CONFIG.REACT_APP_ES_INDEX;
const WAAPI_PROFILE = CONFIG.REACT_APP_PROFILE;

if (!ES_URL || !ES_INDEX) {
  throw new Error('Missing search index settings.');
}

const host = ES_URL + 'api/search/' + ES_INDEX;

let searchkit = null;
let previousSelected = [];

const modalDialogs = {
  EtablerDialog: (
    { isOpen, onClose, dialogData, owner } //was visEtablerfagdata
  ) => (
    <EtablerFagdataModal
      valgteEnheter={dialogData.valgteEnheter}
      valgteEnhetsIder={dialogData.valgteEnhetsIder}
      isOpen={isOpen}
      onClose={owner.closeEtablerData.bind(this)}
      onCloseAvbryt={owner.closeAvbrytEtablerData.bind(this)}
    />
  ),

  IngenValgtDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Ingen enhet valgt'
      melding={dialogData.melding}
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),

  timeOutDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Pålogging har utløpt'
      melding='Du må logge inn på nytt.'
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
};

class SearchkitFagdata extends Component {
  constructor() {
    super();

    this.filterKommune = React.createRef();
    this.filterEiendom = React.createRef();
    this.filterBruksenhet = React.createRef();
    this.filterStatus = React.createRef();

    this.state = {
      init: false,
      selectedRows: [],
      hits: [],
      hitsGebyr: [],
      valgtListe: '1',
      visningWidth: '300px',
      altVisning: 'Enkelt',
      visFilter: true,
      visGebyr: false,
      bType: 'btn btn-default sbpilpanel',
      bStatus: 'btn btn-default sbpilpanel',
      bKommune: 'btn btn-default sbpilpanel',
      bEiendom: 'btn btn-default sbpilpanel',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });

    this.searchkit = new SearchkitManager(host, {
     
      httpHeaders: {
        Authorization: getAccessToken(),
        'X-WAAPI-Profile': WAAPI_PROFILE,
        'X-WAAPI-CONTEXT': getCurrentCustomerId(),
      },
      useHistory: false,
      vurderte: [],
      searchOnLoad: true,
    });
    
    this.searchkit.translateFunction = getTranslations;

    this.searchkit.addResultsListener((results) => {
      this.formatResult(results);
    });
    // this.visAntall = this.visAntall.bind(this);
  }

  componentDidMount() {
    getKommunerForOrganisasjon(this.onGotKommuner);
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
    setTimeout(() => {
      this.setNummererteFilter();
    }, 1000);

    // this.setState({ init: true });

    getVurderte((err, result) => this.setState({ vurderte: result }));
  }

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  setNummererteFilter = () => {
    /*  let filter = {
      brukerIds: null,
      forAlle: false,
      id: 39,
      navn: 'numFilterDefault',
      polygon: null,
      state: '{"numm": true}',
    };
    this.searchkit.accessors.setState(JSON.parse(filter.state)); */
      reloadSearch(this.searchkit); 
    if (!this.state.init) {
      this.setState({ init: true });
    }
  };

  formatResult = (result) => {
//    console.log('XTOKEN results', this.searchkit);
    this.setState({ hits: result.hits.hits });
    /// this.markerFilterButtons();
    debug('HITSX', result.hits.hits);
    if (this.state.visGebyr) {
      if (result.hits.hits) {
        if (result.hits.hits.length > 0) {
          this.getMatrikkelIds(result);
        }
      }
    }
  };

  getMatrikkelIds = (hits) => {
    let liste = [];

    for (let i = 0; i < hits.hits.hits.length; i++) {
      const element = hits.hits.hits[i]._source;
      if (element.matrikkelenhet) {
        if (element.matrikkelenhet.id) {
          liste.push(element.matrikkelenhet.id);
        }
      }
    }
    gebyrEiendom(liste, this.gotAvtaler);
  };

  gotAvtaler = (err, data) => {
    this.setState({ hitsGebyr: data });
  };

  closeEtablerData = () => {
    this.dialogs.closeDialog();
    this.updateResult();
  };

  closeAvbrytEtablerData = () => {
    this.dialogs.closeDialog();
  };

  onGotKommuner = (err, kommuner) => {
    this.searchkit.addDefaultQuery((query) => {
      query = CreateDefaultQueryMedUnummererte(query, kommuner);
      query = query.addQuery(
        BoolMustNot(
          NestedQuery('skybrannData.bruksenhet', {
            exists: {
              field: 'skybrannData.bruksenhet.fagId',
            },
          })
        )
      );
      return query;
    });

    reloadSearch(this.searchkit);
  };

  onEtablerFagdata = () => {
    if (!_.isEmpty(this.state.selectedRows)) {
      this.dialogs.setOpenDialog('EtablerDialog', {
        valgteEnhetsIder: _.map(this.state.selectedRows, (item) => item._id),
        valgteEnheter: _.map(this.state.selectedRows, (item) => item),
      });
    } else {
      this.dialogs.setOpenDialog('IngenValgtDialog', {
        melding: 'Velg hvilke enheter du ønsker å etablere fagdata på.',
      });
    }
  };

  updateResult = () => {
    this.setState({ init: false });
    let hits = _.filter(this.state.hits, (hit) => {
      return !_.some(this.state.selectedRows, { _id: hit._id });
    });
    this.setState({ hits: hits });

    previousSelected = previousSelected.concat(this.state.selectedRows);

    this.setState({ selectedRows: null });
    setTimeout(() => {
      this.setState({ init: true });
    }, 1000);
  };

  onVurdert = (item) => {
    utelatBruksenhet(item._id, (res) =>
      getVurderte((err, result) => {
        this.setState({ vurderte: result });
        reloadSearch(this.searchkit);
      })
    );
  };

  onFjernVurdert = (item) => {
    angreUtelatBruksenhet(item._id, (res) =>
      getVurderte((err, result) => {
        this.setState({ vurderte: result });
        reloadSearch(this.searchkit);
      })
    );
  };

  getBruksenhetstabell = () => {
    return (
      <div>
        <EnhetTabell
          selectedRows={(rows) => {
            let nyvalgte = _.filter(rows, (row) => {
              return !_.some(previousSelected, { _id: row._id });
            });
            this.setState({ selectedRows: nyvalgte });
          }}
          vurdert={this.onVurdert}
          vurderte={this.state.vurderte}
          fjernVurdert={this.onFjernVurdert}
          hits={this.state.hits}
          hitsGebyr={this.state.hitsGebyr}
          valgtListe={this.state.valgtListe}
        />
      </div>
    );
  };

  /*   visAntall(minus) {
    let element = document.getElementsByClassName('sk-hits-stats');
    let antall = this.state.xxxx.total - minus;
    element[0].innerText = antall + ' bruksenheter' ;
  } */

  onReset = () => {
    debug('RESET X');
  };

  SwapVisning = () => {
    let visFilter = !this.state.visFilter;
    let visningWidth = this.state.visningWidth;
    let altVisning = this.state.altVisning;
    if (altVisning === 'Enkelt') {
      altVisning = 'Avansert';
      visningWidth = '2px';
    } else {
      altVisning = 'Enkelt';
      visningWidth = '300px';
    }

    this.setState({ visFilter, visningWidth, altVisning });
  };

  checkSearchKitfor401= () => {
    if (this.searchkit){
      if (this.searchkit.error){
        let s = this.searchkit.error;
        if (s){
         if (s.toString().includes('401')){
          alert('Pålogging har utløpt. Du må logge inn på nytt.');
          this.props.router.push({ pathname: '/' });
         }
        }
      }
    }
  }


  render() {
    if (!this.state.init) {
      return (
        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
          <Spinner className='h4' />
        </div>
      );
    }

    this.checkSearchKitfor401();
    
    return (
      <div>
        <SearchkitProvider searchkit={this.searchkit}>
          <table>
            <tbody>
              <tr>
                <td>
                  <h3>Etabler fagdata på valgte enheter</h3>
                </td>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                <td>
                  <div style={{ marginTop: '6px' }}>
                    <HitsStats />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className='flex-container'>
            <div className='inner'>
              <button className='btn btn-default' onClick={this.SwapVisning}>
                {this.state.altVisning}
              </button>
            </div>

            <div className='inner'>
              <ResetFilters
                style={{ paddingTop: '8px' }}
                translations={{
                  'reset.clear_all': 'Nullstill filtre',
                }}
                onClick={this.onReset}
              />
            </div>

            <div className='inner'>
              <SearchBox
                searchThrottleTime={500}
                searchOnChange={true}
                queryBuilder={CustomQueryBuilderSearch}
                translations={{
                  'searchbox.placeholder': 'Adressesøk: Storgata 2',
                }}
              />
            </div>
            <div className='inner'>
              <SortingSelector
                options={[
                  {
                    label: 'Sorter på dato opprettet',
                    key: 'opprettetDato',
                    field: 'opprettetDato',
                    order: 'desc',
                  },
                  {
                    label: 'Sorter på bruksenhetstype (a-å)',
                    key: 'bruksenhetstypea',
                    field: 'bruksenhetstype.keyword',
                    order: 'asc',
                  },
                  {
                    label: 'Sorter på bruksenhetstype (å-a)',
                    key: 'bruksenhetstypeb',
                    field: 'bruksenhetstype.keyword',
                    order: 'desc',
                  },
                  {
                    label: 'Sorter på adresse',
                    key: 'adresse',

                    fields: [
                      {
                        field: 'kommune.kommuneId',
                        options: {
                          order: 'asc',
                          nested_path: 'kommune',
                        },
                      },
                      {
                        field: 'adresse.gatenavn.keyword',
                        options: {
                          order: 'asc',
                          nested_path: 'adresse',
                        },
                      },
                      {
                        field: 'adresse.husnummer',
                        options: {
                          order: 'asc',
                          nested_path: 'adresse',
                        },
                      },
                      {
                        field: 'adresse.bokstav.keyword',
                        options: {
                          order: 'asc',
                          nested_path: 'adresse',
                        },
                      },
                      {
                        field: 'etasjenummer',
                      },
                      {
                        field: 'lopenummer',
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div className='inner'>
              <PageSizeSelector
                className='pull-left'
                options={[10, 50, 100, 200]} //, 250, 500, 1000
                listComponent={Select}
              />
              <span
                style={{
                  fontSize: '12px',
                  marginTop: '12px',
                  marginLeft: '4px',
                }}>
                pr.side
              </span>
            </div>
            <div className='inner'>
              <Pagination showNumbers={true} />
            </div>
          </div>

          <div style={{ marginLeft: '2px' }}>
            <ActionBar>
              <ActionBarRow>
                <GroupedSelectedFilters
                  translations={{
                    true: 'Ja',
                    false: 'Nei',
                  }}
                />
              </ActionBarRow>
            </ActionBar>
          </div>

          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td
                  style={{
                    verticalAlign: 'top',
                    width: this.state.visningWidth,
                  }}>
                  <div
                    style={{
                      display: this.state.visFilter ? 'block' : 'none',
                      marginTop: '4px',
                      marginRight: '6px',
                      backgroundColor: 'white',
                      paddingLeft: '8px',
                      paddingRight: '12px',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      verticalAlign: 'top',
                    }}>
                    <label style={{ fontSize: '22px' }}>Søkefilter</label>
                    <Panel
                      title='Adresse'
                      collapsable={true}
                      defaultCollapsed={true}>
                      <RefinementListFilter
                        field='kommune.kommuneNavn.keyword'
                        title='Kommune'
                        fieldOptions={{
                          type: 'nested',
                          options: {
                            path: 'kommune',
                          },
                        }}
                        size={3}
                        operator='OR'
                        id='kommune'
                      />
                      <br />
                      <InputFilter
                        id='gatenavn'
                        title='Gatenavn'
                        queryOptions={{ minimum_should_match: '100%' }}
                        searchOnChange={true}
                        searchThrottleTime={500}
                        translations={{
                          'searchbox.placeholder': 'Søk',
                        }}
                        queryFields={['gatenavn']}
                        queryBuilder={CustomQueryBuilder}
                      />
                      <br />
                      <DynamicRangeFilter
                        field='adresse.husnummer'
                        rangeComponent={RangeInput}
                        translations={{
                          'range.submit': 'OK',
                        }}
                        fieldOptions={{
                          type: 'nested',
                          options: {
                            path: 'adresse',
                          },
                        }}
                        title='Husnummer'
                        id='husnummer'
                      />
                      <br />
                      <InputFilter
                        id='bokstav'
                        title='Bokstav'
                        searchOnChange={true}
                        queryBuilder={CustomQueryBuilder}
                        queryFields={['bokstav']}
                        translations={{
                          'searchbox.placeholder': 'Søk',
                        }}
                      />
                      <br />
                      <DynamicRangeFilter
                        field='etasjenummer'
                        id='etasje'
                        title='Etasje'
                      />
                      <br />
                      <DynamicRangeFilter
                        field='lopenummer'
                        id='etasjenummer'
                        title='Etasjenummer'
                      />
                      <br />
                      <RefinementListFilter
                        field='etasjeplan.keyword'
                        title='Etasjetype'
                        size={5}
                        operator='OR'
                        id='Etasjetype'
                      />
                    </Panel>
                    <br />
                    <Panel
                      title='Eiendom'
                      collapsable={true}
                      defaultCollapsed={true}>
                      <InputFilter
                        style={{ display: 'block' }}
                        id='gnr'
                        title='Gnr'
                        searchOnChange={false}
                        queryFields={['gardsnummer']}
                        queryBuilder={CustomQueryBuilderEnhet}
                        translations={{
                          'searchbox.placeholder': 'Gardsnummer',
                        }}
                      />
                      <InputFilter
                        id='bnr'
                        title='Bnr'
                        searchOnChange={false}
                        queryFields={['bruksnummer']}
                        queryBuilder={CustomQueryBuilderEnhet}
                        translations={{
                          'searchbox.placeholder': 'Bruksnummer',
                        }}
                      />
                      <InputFilter
                        id='fnr'
                        title='Fnr'
                        searchOnChange={false}
                        queryFields={['festenummer']}
                        queryBuilder={CustomQueryBuilderEnhet}
                        translations={{
                          'searchbox.placeholder': 'Festenummer',
                        }}
                      />
                      <InputFilter
                        id='snr'
                        title='Snr'
                        searchOnChange={false}
                        queryFields={['seksjonsnummer']}
                        queryBuilder={CustomQueryBuilderEnhet}
                        translations={{
                          'searchbox.placeholder': 'Seksjonsnummer',
                        }}
                      />
                    </Panel>
                    <br />
                    <Panel
                      title='Bruksenhet'
                      collapsable={true}
                      defaultCollapsed={true}>
                      <CheckboxFilter
                        title=''
                        id='-'
                        label='Skjul unummererte bruksenheter'
                        showCount={false}
                        filter={BoolMustNot([
                          TermQuery('bruksenhetstypekode', 'U'),
                        ])}
                      />
                    </Panel>
                    <br />
                    <Panel
                      title='Bygning'
                      collapsable={true}
                      defaultCollapsed={true}>
                      <InputFilter
                        id='bygningsnummer'
                        title='Bygningsnummer'
                        searchOnChange={false}
                        queryFields={['bygningsnummer']}
                        queryBuilder={CustomQueryBuilderBygg}
                        translations={{
                          'searchbox.placeholder': 'Søk',
                        }}
                      />
                      <br />
                      <RefinementListFilter
                        fieldOptions={{
                          type: 'nested',
                          options: {
                            path: 'bygg',
                          },
                        }}
                        field='bygg.bygningsType'
                        title='Bygningstype'
                        size={6}
                        operator='OR'
                        id='type'
                      />
                      <br />
                      <RefinementListFilter
                        fieldOptions={{
                          type: 'nested',
                          options: {
                            path: 'bygg',
                          },
                        }}
                        field='bygg.bygningstatus'
                        title='Bygningsstatus'
                        size={6}
                        operator='OR'
                        id='bygningstatuskode'
                      />
                      <br />
                      <RefinementListFilter
                        fieldOptions={{
                          type: 'nested',
                          options: {
                            path: 'bygg',
                          },
                        }}
                        field='bygg.naringsgruppe'
                        title='Næringsgruppe'
                        size={6}
                        operator='OR'
                        id='naringsgruppe'
                      />
                      <br />
                      <CheckboxFilter
                        id='sefrak'
                        title=''
                        label='Har SEFRAK-minne'
                        filter={BoolMust(
                          NestedQuery(
                            'bygg',
                            BoolShould([
                              TermQuery('bygg.harSefrakminne', 'true'),
                            ])
                          )
                        )}
                      />
                    </Panel>

                    <br />
                  </div>
                </td>

                <td style={{ verticalAlign: 'top' }}>
                  <div
                    style={{
                      paddingTop: '4px',
                      paddingRight: '4px',
                      paddingBottom: '4px',
                    }}>
                    <Hits
                      hitsPerPage={100}
                      listComponent={this.getBruksenhetstabell}
                      sourceFilter={[
                        'id',
                        'adresse.id',
                        'adresse.gatenavn', //Todo: kan også være fiktiv
                        'adresse.type',
                        'adresse.husnummer',
                        'adresse.bokstav',
                        'matrikkelenhet.id',
                        'matrikkelenhet.gardsnummer',
                        'matrikkelenhet.bruksnummer',
                        'matrikkelenhet.festenummer',
                        'matrikkelenhet.seksjonsnummer',
                        'matrikkelenhet.bruksnavn',
                        'etasjeplankode',
                        'lopenummer',
                        'etasjenummer',
                        'kommune.kommuneNavn',
                        'kommune.kommuneId',
                        'bruksenhetstype',
                        'opprettetDato',
                        'bygg.bygningstatus',
                        'bygg.bygningstatuskode',
                      ]}
                    />
                  </div>
                  {this.state.visGebyr && (
                    <div
                      className='container-fluid'
                      style={{ marginTop: '6px' }}>
                      <div className='row'>
                        <div
                          className='col-md-4 col col-sm-12 col-xs-12'
                          style={{ paddingLeft: 0 }}>
                          <span>
                            <img
                              height='20'
                              width='20'
                              src={FagKronGreen}
                              alt=''
                              title=''
                            />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '12px' }}>
                            Alle aktive avtaler tilknyttet samme eiendom i KTF
                            har minst en varelinje til forfall for feie- eller
                            tilsynsgebyr.
                          </span>
                        </div>
                        <div className='col-md-4 col col-sm-12 col-xs-12'>
                          <span>
                            <img
                              height='20'
                              width='20'
                              src={FagKronYellow}
                              alt=''
                              title=''
                            />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '12px' }}>
                            Det er minst en aktiv avtale tilknyttet samme
                            eiendom i KTF med minst en varelinje til forfall for
                            feie- eller tilsynsgebyr.
                          </span>
                        </div>
                        <div className='col-md-4 col col-sm-12 col-xs-12'>
                          <span>
                            <img
                              height='20'
                              width='20'
                              src={FagKronRed}
                              alt=''
                              title=''
                            />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '12px' }}>
                            Ingen av de aktive avtalene på eiendommen har minst
                            en varelinje til forfall for feie- eller
                            tilsynsgebyr.
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <div className='modal-footer'>
            <button
              className='btn btn-primary btn-lg pull-right'
              onClick={this.onEtablerFagdata}>
              Etabler fagdata
            </button>
          </div>
        </SearchkitProvider>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(SearchkitFagdata);
