import React from 'react';
import NumericInput from 'react-numeric-input';
import 'react-toggle-switch/dist/css/switch.min.css';
import 'modules/Gjennomforing/gjennomforing.css';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:gjennomforing:situasjon EnhetForm');
var _ = require('underscore');
var api = require('api/enhetApi');

var tmpBruksenhet = null;

class ElementForm extends React.Component {
  state = { bruksenhet: null, endreRisiko: false, endreRisikoText: 'Nei' };

  componentDidMount() {
    api.getBruksenhet(this.props.id, this.gotBruksenhet);
  }

  gotBruksenhet = (err, bruksenhet) => {
    this.setState({
      bruksenhet: bruksenhet,
      endreRisiko: bruksenhet.risikoverdiOverstyr,
      opprinneligOverstyr: bruksenhet.risikoverdiOverstyr,
      opprinneligRisikoVerdi: bruksenhet.risikoverdi
    });
    bruksenhet.risikoverdiOverstyr
      ? this.setState({ endreRisikoText: 'Ja' })
      : this.setState({ endreRisikoText: 'Nei' });

    tmpBruksenhet = bruksenhet;
  };

  enkleRoykChange = (val) => {
    val == null
      ? (tmpBruksenhet.antallRoykvarslerEnkel = 0)
      : (tmpBruksenhet.antallRoykvarslerEnkel = val);
  };

  sammenRoykChange = (val) => {
    val == null
      ? (tmpBruksenhet.antallRoykvarslerSammenkobl = 0)
      : (tmpBruksenhet.antallRoykvarslerSammenkobl = val);
  };

  pulverChange = (val) => {
    val == null
      ? (tmpBruksenhet.antallPulver = 0)
      : (tmpBruksenhet.antallPulver = val);
  };

  skumChange = (val) => {
    val == null
      ? (tmpBruksenhet.antallSkum = 0)
      : (tmpBruksenhet.antallSkum = val);
  };

  slangeChange = (val) => {
    val == null
      ? (tmpBruksenhet.antallSlange = 0)
      : (tmpBruksenhet.antallSlange = val);
  };

  annetChange = (val) => {
    val == null
      ? (tmpBruksenhet.antallAnnet = 0)
      : (tmpBruksenhet.antallAnnet = val);
  };

  romningChange = (val) => {
    tmpBruksenhet.romningsveger =
      val.currentTarget.value === 'ja' ? true : false;
  };

  alarmChange = (val) => {
    tmpBruksenhet.alarm = val.currentTarget.value === 'ja' ? true : false;
  };

  sentralChange = (val) => {
    tmpBruksenhet.alarmsentral =
      val.currentTarget.value === 'ja' ? true : false;
  };

  risikoChange = (val) => {
    val > 100
      ? (tmpBruksenhet.risikoverdi = 100)
      : (tmpBruksenhet.risikoverdi = val);
  };

  merknadChange = (val) => {
    tmpBruksenhet.merknad = val.target.value;
  };

  endreRisikoChange = () => {
    let endreRisiko = !this.state.endreRisiko;
    this.setendreRisiko(endreRisiko);
  };

  setendreRisiko = (endreRisiko) => {
    if (endreRisiko) {
      this.setState({
        endreRisiko: true,
        endreRisikoText: 'Ja'
      });
      tmpBruksenhet.risikoverdiOverstyr = true;
    } else {
      this.setState({
        endreRisiko: false,
        endreRisikoText: 'Nei'
      });
      tmpBruksenhet.risikoverdiOverstyr = false;
    }
    //this.setState({ utfort: endreRisiko });
  };

  risikoverdiOverstyrAarsakChange = (val) => {
    tmpBruksenhet.risikoverdiOverstyrAarsak = val.currentTarget.value;
  };

  submit = (data) => {
    if (tmpBruksenhet.risikoverdi != this.state.opprinneligRisikoVerdi) {
      if (!tmpBruksenhet.risikoverdiOverstyr) {
        tmpBruksenhet.risikoverdi = this.state.opprinneligRisikoVerdi;
      };
    };
    console.log(tmpBruksenhet);
    this.props.endreEnhet(tmpBruksenhet);
  };

  render() {
    if (!this.state.bruksenhet) {
      return <div>Laster...</div>;
    }

    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">
            Endre bruksenhet - {this.state.bruksenhet.adresse}
          </h4>
        </div>
        <div className="modal-body">
          <form>
            <div className="panel panel-default"
              style={{ marginBottom: '0' }} >
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Adresse</label>
                      <br />
                      {this.state.bruksenhet.adresse}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group"
                      style={{wordBreak: 'break-all'}}>
                      <label>Bygningstype</label>
                      <br />
                      {this.state.bruksenhet.bygningType}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Bygningsnr.</label>
                      <br />
                      {this.state.bruksenhet.bygning}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default"
              style={{ marginBottom: '0' }} >
              <div className="panel-heading">Antall røykvarslere</div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Enkle</label>
                      <br />
                      <NumericInput
                        mobile={true}
                        className="width100 height38"
                        min={0}
                        max={100}
                        value={this.state.bruksenhet.antallRoykvarslerEnkel}
                        onChange={this.enkleRoykChange}
                      />
                      {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallRoykvarslerEnkel} onChange={this.enkleRoykChange} />*/}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Sammenkoblede</label>
                      <br />
                      <NumericInput
                        mobile={true}
                        className="width100 height38"
                        min={0}
                        max={100}
                        value={
                          this.state.bruksenhet.antallRoykvarslerSammenkobl
                        }
                        onChange={this.sammenRoykChange}
                      />
                      {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallRoykvarslerSammenkobl} onChange={this.sammenRoykChange} />*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default"
              style={{ marginBottom: '0' }} >
              <div className="panel-heading">Antall slukkeutstyr</div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Slanger</label>
                      <br />
                      <NumericInput
                        mobile={true}
                        className="width100 height38"
                        min={0}
                        max={100}
                        value={this.state.bruksenhet.antallSlange}
                        onChange={this.slangeChange}
                      />
                      {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallSlange} onChange={this.slangeChange} />*/}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Pulver</label>
                      <br />
                      <NumericInput
                        mobile={true}
                        className="width100 height38"
                        min={0}
                        max={100}
                        value={this.state.bruksenhet.antallPulver}
                        onChange={this.pulverChange}
                      />
                      {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallPulver} onChange={this.pulverChange} />*/}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Skum</label>
                      <br />
                      <NumericInput
                        mobile={true}
                        className="width100 height38"
                        min={0}
                        max={100}
                        value={this.state.bruksenhet.antallSkum}
                        onChange={this.skumChange}
                      />
                      {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallSkum} onChange={this.skumChange} />*/}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Annet</label>
                      <br />
                      <NumericInput
                        mobile={true}
                        className="width100 height38"
                        min={0}
                        max={100}
                        value={this.state.bruksenhet.antallAnnet}
                        onChange={this.annetChange}
                      />
                      {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallAnnet} onChange={this.annetChange} />*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default"
              style={{ marginBottom: '0' }} >
              <div className="panel-heading">Annet</div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Rømningsveier</label>
                      <br />
                      <div className="radio inline"
                        style={{ paddingLeft: '5px' }} >
                        <label>
                          <input
                            type="radio"
                            name="romningRadio"
                            value="ja"
                            defaultChecked={
                              this.state.bruksenhet.romningsveger === true
                            }
                            onChange={this.romningChange}
                          />
                          <span
                            style={{ margin: '0 5px' }}> 
                            Ja
                          </span>
                        </label>
                      </div>
                      <div className="radio inline margin-left-10">
                        <label>
                          <input
                             type="radio"
                            name="romningRadio"
                            value="nei"
                            defaultChecked={
                              this.state.bruksenhet.romningsveger === false
                            }
                            onChange={this.romningChange}
                          />
                          <span
                            style={{ marginLeft: '5px' }}> 
                            Nei
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Alarm</label>
                      <br />
                      <div className="radio inline"
                        style={{ paddingLeft: '5px' }} >
                        <label>
                          <input
                            type="radio"
                            name="alarmRadio"
                            value="ja"
                            defaultChecked={
                              this.state.bruksenhet.alarm === true
                            }
                            onChange={this.alarmChange}
                          />
                          <span
                            style={{ margin: '0 5px' }}> 
                            Ja
                          </span>
                        </label>
                      </div>
                      <div className="radio inline margin-left-10">
                        <label>
                          <input
                            type="radio"
                            name="alarmRadio"
                            value="nei"
                            defaultChecked={
                              this.state.bruksenhet.alarm === false
                            }
                            onChange={this.alarmChange}
                          />
                          <span
                            style={{ marginLeft: '5px' }}> 
                            Nei
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Alarmsentral</label>
                      <br />
                      <div className="radio inline"
                       style={{ paddingLeft: '5px' }} >
                        <label>
                          <input
                            type="radio"
                            name="sentralRadio"
                            value="ja"
                            defaultChecked={
                              this.state.bruksenhet.alarmsentral === true
                            }
                            onChange={this.sentralChange}
                          />
                          <span
                            style={{ margin: '0 5px' }}> 
                            Ja
                          </span>
                        </label>
                      </div>
                      <div className="radio inline margin-left-10">
                        <label>
                          <input
                            type="radio"
                            name="sentralRadio"
                            value="nei"
                            defaultChecked={
                              this.state.bruksenhet.alarmsentral === false
                            }
                            onChange={this.sentralChange}
                          />
                          <span
                            style={{ marginLeft: '5px' }}> 
                            Nei
                          </span>
                        </label>
                      </div>
                      {/*<select className="form-control" id='type' onChange={this.sentralChange} defaultValue={this.state.bruksenhet.alarmsentral ? 'Ja' : 'Nei'}>
                                                <option>Nei</option>
                                                <option>Ja</option>
                                            </select>*/}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Risikoverdi</label>
                      <br />
                      <div>
                        <NumericInput
                          mobile={true}
                          className="width100 height38"
                          min={0}
                          max={100}
                          value={this.state.bruksenhet.risikoverdi}
                          onChange={this.risikoChange}
                          disabled={!this.state.endreRisiko}
                        />
                        {/*<input type="number" name="quantity" min="0" max="100" defaultValue={this.state.bruksenhet.antallAnnet} onChange={this.annetChange} />*/}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    {/*<div className="form-group">
                      <label>Overstyr risiko</label>
                      <br />
                       <Switch
                        className="pull-left"
                        on={this.state.endreRisiko}
                        onClick={this.endreRisikoChange}
                      />
                      <label className="endreRisikoText">
                        {this.state.endreRisikoText}
                      </label> 
                    </div>*/}

                    <div className="form-group">
                      <label>Overstyr risiko</label>
                      <br />
                      <div className="radio inline"
                        style={{ paddingLeft: '5px' }} >
                        <label>
                          <input
                            type="radio"
                            name="overstyrRadio"
                            value="ja"
                            defaultChecked={
                              this.state.endreRisiko === true
                            }
                            onChange={this.endreRisikoChange}
                          />
                          <span
                            style={{ margin: '0 5px' }}> 
                            Ja
                          </span>
                        </label>
                      </div>
                      <div className="radio inline margin-left-10">
                        <label>
                          <input
                            type="radio"
                            name="overstyrRadio"
                            value="nei"
                            defaultChecked={
                              this.state.endreRisiko === false
                            }
                            onChange={this.endreRisikoChange}
                          />
                          <span
                            style={{ marginLeft: '5px' }}> 
                            Nei
                          </span>
                        </label>
                      </div>
                    </div>

                  </div>
                  {this.state.endreRisiko ? (
                    <div className="col-sm-6">
                      <label>Årsak til overstyring</label>
                      <textarea
                        className="form-control"
                        type="textarea"
                        id="message"
                        placeholder="Årsak ..."
                        maxLength="140"
                        rows="1"
                        defaultValue={
                          this.state.bruksenhet.risikoverdiOverstyrAarsak
                        }
                        onChange={this.risikoverdiOverstyrAarsakChange}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label>Merknad</label>
                    <textarea
                      className="form-control textareaNoresize"
                      type="textarea"
                      id="message"
                      placeholder="Merknad"
                      maxLength="2000"
                      rows="3"
                      defaultValue={this.state.bruksenhet.merknad}
                      onChange={this.merknadChange}
                    />
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                    <div className="pull-right">
                      <input
                        type="button"
                        className="btn btn-primary height48"
                        value="OK"
                        onClick={this.submit}
                      /> 
                    </div>
                
              </div>
            </div>
              </div>
            </div>
            
          </form>
        </div>
      </div>
    );
  }
}

export default ElementForm;
