import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import { v4 as uuidv4 } from 'uuid';
import KommentarEdit from 'images/Annet/kommentarEdit.png';
import Kommentar from 'images/Annet/kommentar.png';
import KommentarPlus from 'images/Annet/kommentarPlus.png';
import KommentarDelete from 'images/Annet/kommentarDelete.png';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import NKIcon from 'nka-icons';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import { debugFactory } from 'utils';
import { testKommentar } from './testData';

const debug = debugFactory('nk:Kommentar');

export const PanelKommentar = forwardRef((props, ref) => {
  const [kommentarer, setKommentarer] = useState([]);
  const [tempTekst, setTempTekst] = useState('');
  const [tempKey, setTempKey] = useState('');
  const [modus, setModus] = useState('blank');
  const [currentKommentar, setCurrentKommentar] = useState(null);
  const [editInnleggKey, setEditInnleggKey] = useState('');

  const [visJaNei, setVisJaNei] = useState(false);
  const [jaNeiTittel, setJaNeiTittel] = useState('Slette innlegg?');
  const [jaNeiMelding, setJaNeiMelding] = useState(
    'Ønsker du å slette valgt innlegg?'
  );

  const [editor, setEditor] = useState(null);
  const [panel, setPanel] = useState(null);

  useEffect(() => {
    setKommentarer(testKommentar);
  }, []);

  useImperativeHandle(ref, () => ({
    lesKommentar(id) {
      findKommentarById(id);
      setTempKey('');
      setEditInnleggKey('');
      setModus('les');
    },
    nyKommentar(data, panel, editor) {
      setCurrentKommentar(data);
      setEditor(editor);
      setPanel(panel);
      setModus('edit');
    },
    kommentarEksisterer(id) {
      for (let i = 0; i < kommentarer.length; i++) {
        const k = kommentarer[i];
        if ('#BF&' + k.id === id) {
          return true;
        }
      }
      return false;
    },
  }));

  const findKommentarById = (id) => {
    let kommentar = null;

    for (let i = 0; i < kommentarer.length; i++) {
      const k = kommentarer[i];
      if ('#BF&' + k.id === id) {
        kommentar = k;
        break;
      }
    }
    setCurrentKommentar(kommentar);
  };

  const setRedigerTekst = (val) => {
    setTempTekst(val.target.value);
  };

  const lagreEditedTekst = (key) => {
    for (let i = 0; i < currentKommentar.innlegg.length; i++) {
      const element = currentKommentar.innlegg[i];
      if (element.key === key) {
        element.innleggTekst = tempTekst;
      }
    }
    setEditInnleggKey('');
    setTempTekst('');
  };

  const lagreForsteInnlegg = () => {
    currentKommentar.innlegg[0].innleggTekst = tempTekst;
    let k = kommentarer;
    k.push(currentKommentar);
    setKommentarer(k);
    setModus('les');
  };

  const angreForsteInnlegg = () => {
    setCurrentKommentar(null);
    visPinkKommentar(editor, panel, true);
    setModus('les');
  };

  const editInnlegg = (key, tekst) => {
    setEditInnleggKey(key);
    setTempTekst(tekst);
  };

  const slettInnleggEllerKOmmentar = () => {
    if (jaNeiTittel === 'Slette innlegg?') {
      slettInnlegg2();
    } else {
      slettCurrentKommentarDirekt(currentKommentar);
    }
  };

  const slettInnlegg = (key) => {
    setJaNeiTittel('Slette innlegg?');
    setJaNeiMelding('Ønsker du å slette innlegg fra kommentaren?');
    setVisJaNei(true);
    setTempKey(key);
  };

  const slettInnlegg2 = () => {
    let index = -1;
    for (let i = 0; i < currentKommentar.innlegg.length; i++) {
      const element = currentKommentar.innlegg[i];
      if (element.key === tempKey.toString()) {
        index = i;
      }
    }
    if (index > -1) {
      currentKommentar.innlegg.splice(index, 1);
    }
    setTempKey('');
    setVisJaNei(false);
    if (currentKommentar.innlegg.length < 1) {
      slettCurrentKommentarDirekt(currentKommentar);
    }
  };

  const slettCurrentKommentarDirekt = (kommentar) => {
    let index = -1;
    for (let i = 0; i < kommentarer.length; i++) {
      const element = kommentarer[i];
      if (element.id === kommentar.id) {
        index = i;
      }
    }
    if (index > -1) {
      kommentarer.splice(index, 1);
      setKommentarer(kommentarer);
      visPinkKommentar(props.editor, props.panel, true);
      setCurrentKommentar(null);
      setVisJaNei(false);
    }
  };

  const slettCurrentKommentar = (kommentar) => {
    setCurrentKommentar(kommentar);
    setJaNeiTittel('Slette kommentar?');
    setJaNeiMelding('Ønsker du å slette kommentaren?');
    setVisJaNei(true);
  };

  const svarCurrentKommentar = (kommentar) => {
    let k = uuidv4();
    let nyInnlegg = {
      key: k,
      bruker: contextInnstillingerPerson._currentValue.navn,
      innleggTekst: '',
      endret: moment().format('DD.MM.YYYY: HH:mm'),
    };
    kommentar.innlegg.push(nyInnlegg);
    setEditInnleggKey(k);
    setTempTekst('');
  };

  return (
    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
      <div>
        <span>
          <label>Kommentar</label>
        </span>
        <span style={{ marginLeft: '4px' }} className='pull-right'>
          <button className='btn btn-default'
            onClick={props.visKommentarPanel}
            title='Lukk kommentar panel'>
            <img
              style={{ marginRight: '6px' }}
              height='24px'
              src={Kommentar}
              alt=''
            />
            Lukk
          </button>
          <button className='btn btn-default'
            onClick={props.setNyKommentar}
            title='Opprett ny kommentar'>
            <img
              style={{ marginRight: '6px' }}
              height='24px'
              src={KommentarPlus}
              alt=''
            />
            Ny
          </button>
        </span>
      </div>
      <br />

      {modus === 'edit' && currentKommentar && (
        <div>
          <div>
            <span>
              <NKIcon icon='bruker' size='0.8' color='nk-black' />
            </span>
            <span
              style={{
                fontWeight: 'bold',
                marginLeft: '10px',
                fontSize: '14px',
              }}>
              {currentKommentar.innlegg[0].bruker}
            </span>
          </div>
          <textarea
            rows='7'
            cols='31'
            placeholder='Skriv kommentar..'
            style={{ resize: 'none' }}
            onChange={setRedigerTekst}
          />
          <br />
          <div>
            <span className='pull-right'>
              <button onClick={angreForsteInnlegg} className='btn btn-default'>
                Avbryt
              </button>
              <button
                style={{ marginLeft: '4px' }}
                onClick={lagreForsteInnlegg}
                className='btn btn-success'>
                Lagre
              </button>
            </span>
          </div>
        </div>
      )}
      {modus === 'les' && currentKommentar && (
        <div>
          {currentKommentar.innlegg.map((innlegg) => {
            return (
              <>
                <div style={{ marginBottom: '0px' }}>
                  <span>
                    <NKIcon icon='bruker' size='0.8' color='nk-black' />
                  </span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      marginLeft: '10px',
                      fontSize: '14px',
                    }}>
                    {innlegg.bruker}
                  </span>

                  {editInnleggKey !== innlegg.key && (
                    <span className='dropdown pull-right'>
                      <button
                        className='btn btn-default dropdown-toggle'
                        type='button'
                        style={{ height: '28px', fontWeight: 'bolder' }}
                        data-toggle='dropdown'>
                        {/*  <span style={{ fontSize: '18px',   }}>...</span> */}
                        ...
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <button
                            onClick={() => slettInnlegg(innlegg.key)}
                            className='btn btn-default'
                            style={{ margin: '2px', width: '180px' }}>
                            Slett innlegg
                          </button>
                        </li>
                        <li>
                          <button
                            onClick={() =>
                              editInnlegg(innlegg.key, innlegg.innleggTekst)
                            }
                            className='btn btn-default'
                            style={{ margin: '2px', width: '180px' }}>
                            Endre innlegg
                          </button>
                        </li>
                      </ul>
                    </span>
                  )}
                </div>
                {editInnleggKey === innlegg.key && (
                  <div>
                    <textarea
                      rows='7'
                      cols='31'
                      placeholder='Skriv kommentar..'
                      value={tempTekst}
                      style={{ resize: 'none', border: '1px green solid' }}
                      onChange={setRedigerTekst}
                    />
                    <div className='pull-right'>
                      <span>
                        <button onClick={() => setEditInnleggKey('')}>
                          Avbryt
                        </button>
                      </span>
                      <span>
                        <button
                          onClick={() => lagreEditedTekst(editInnleggKey)}>
                          Lagre
                        </button>
                      </span>
                    </div>
                  </div>
                )}

                {editInnleggKey !== innlegg.key && (
                  <div>
                    <div
                      style={{
                        fontSize: '12px',
                        maxHeight: '250px',
                        overflowY: 'auto',
                        width: '100%',
                      }}>
                      {innlegg.innleggTekst}
                    </div>

                    <span
                      className='pull-right'
                      style={{ fontSize: '12px', fontStyle: 'italic', color: 'red' }}>
                      {innlegg.endret}
                    </span>
                  </div>
                )}

                <div>
                  <br />
                  <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                </div>
              </>
            );
          })}

          <span className='pull-right'>
            <button
              className='btn btn-default'
              onClick={() => slettCurrentKommentar(currentKommentar)}>
              <img
                style={{ marginRight: '6px' }}
                height='24px'
                src={KommentarDelete}
                alt=''
              />
              Slett
            </button>
          </span>
          <span style={{ marginRight: '4px' }} className='pull-right'>
            <button
              className='btn btn-default'
              onClick={() => svarCurrentKommentar(currentKommentar)}>
              <img
                style={{ marginRight: '6px' }}
                height='24px'
                src={KommentarEdit}
                alt=''
              />
              Svar
            </button>
          </span>
        </div>
      )}
      <div />
      <JaNeiDialog
        isOpen={visJaNei}
        tittel={jaNeiTittel}
        melding={jaNeiMelding}
        onJa={() => {
          slettInnleggEllerKOmmentar();
        }}
        onNei={() => {
          setTempKey('');
          setVisJaNei(false);
        }}
      />
    </div>
  );
});

//KUN FOR TESTING  **************************************************************************************
export const lastTestData = (model) => {
  debug('AAA1 in');
  let t1 = '<span id= "#BF&789-abc">aktsomhet ved brannfarlig aktivitet</span>';
  let t2 = '<span id= "#BF&789-def">det er åpenbart at brann ikke kan oppstå</span>';
  model = model.replace('aktsomhet ved brannfarlig aktivitet', t1);
  model = model.replace('det er åpenbart at brann ikke kan oppstå', t2);
  debug('AAA1 uit', model);
  return model;
};

export const visPinkKommentar = (editor, panel, checkEksist) => {
  Array.from(editor.el.querySelectorAll('span')).forEach(function(span) {
    let id = _.get(span, 'id');
    if (id.toString().startsWith('#BF&')) {
      if (checkEksist) {
        //Fjern de som ikke ble noe av
        if (panel.kommentarEksisterer(id.toString())) {
          span.style.backgroundColor = 'pink';
        } else {
          span.style = '';
          span.id = '';
        }
      } else {
        span.style.backgroundColor = 'pink';
      }
    }
  });
};

export const visIkkeKommentar = (editor) => {
  Array.from(editor.el.querySelectorAll('span')).forEach(function(span) {
    let id = _.get(span, 'id');
    if (id.toString().startsWith('#BF&')) {
      span.style = '';
    }
  });
};

export const onKommentarClick = (e, panel, editor) => {
  visPinkKommentar(editor, panel, true);

  let styleX = _.get(e, 'currentTarget.attributes.style.value');
  let idX = _.get(e, 'currentTarget.attributes.id.value');

  if (styleX && idX) {
    let pink = 'background-color: pink;'; //background-color: pink;
    if (styleX.includes(pink)) {
      //let text = _.get(e, 'currentTarget.firstChild.nodeValue');

      panel.lesKommentar(idX);
    }

    Array.from(editor.el.querySelectorAll('span')).forEach(function(span) {
      let id = _.get(span, 'id');
      if (id.toString() === idX) {
        span.style.backgroundColor = 'yellowgreen';
        // span.select();
      }
    });
  }
};

export const onNyKommentar = (tekst, panel, editor) => {
  visPinkKommentar(editor, panel, true);
  let id = uuidv4();
  let pink =
    '<span id=#BF&' +
    id +
    ' style="background-color: greenyellow;">' +
    tekst +
    '</span>';
  editor.html.insert(pink);
  let kommentar = { id: id, innlegg: [] };
  let innlegg = {
    key: uuidv4(),
    bruker: contextInnstillingerPerson._currentValue.navn,
    innleggTekst: '',
    endret: moment().format('DD.MM.YYYY: HH:mm'),
  };
  kommentar.innlegg.push(innlegg);

  panel.nyKommentar(kommentar, panel, editor);
};
