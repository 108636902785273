import React, { useImperativeHandle } from 'react';

const Child3 = React.forwardRef(({ ...props }, ref) => {  //(I steden for verdi kan man også bruke ...props)   const Child3 = React.forwardRef(({ verdi, navn, bil }, ref) => {

  useImperativeHandle(ref, () => ({
    childFunction1, 
    childFunction2
  }));
  
  const childFunction1 = () => {
    alert('Hei 1');
  };

  const childFunction2 = () => {
    alert('Hei 2');
  };

  return ( 

    <div style={{ margin: '4px', padding: '10px', border: '2px solid violet', width: '250px' }}>
        <h3>Child3</h3>
        <br/>
       <label> {props.verdi}</label>
       {props.verdi === 'Fra Parent DEF' && (
          <div style= {{ height: '80px', backgroundColor: 'lime', padding: '8px' }}>
            <br/>
              Vises dersom DEF
          </div>
       )}
       
      </div>
   );
});
export default Child3;