import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ReactTable from '@norkart/react-table';


class VisKontaktinfoForSms extends React.Component {
  state = { isOpen: true };

  render() {
    var kollonner = this.getKolloner();
    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Mottakere</h4>
          </div>
          <div className="modal-body scroller"
          style={{
            height: '90%',
            paddingTop: '4px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '4px'
          }}>
          <ReactTable
                  items={this.props.kontaktListe}
                  columns={kollonner}
                  showIndex={false}
                  onRowClick={null}
                  
                />
          </div>
         
            <div className="modal-footer">
              <button
                style={{ margin: '2px', width: '50px', height: '40px' }}
                className="btn btn-primary pull-right"
                onClick={this.props.onOk}>
                OK
              </button>
            </div>
        </div>
      </NKModal>
    );
  }

  getKolloner = () => {
    return [   
      {
        id: 'navn',
        name: 'Navn',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'gjelderAdresse',
        name: 'Bruksenhet',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'telefon',
        name: 'Telefon',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.ugyldigTelefonNr) {
            return <div className="red">Ugyldig nummer</div>;
          } else {
            return item.telefon;
          }
        }
      }
    ];
  };
}

export default VisKontaktinfoForSms;
