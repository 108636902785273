import React, { useState, useEffect } from "react";
import { promisifyApiCall } from 'utils';
import { getOrganisasjonData, updateOrganisasjonData } from 'context/contextInnstillinger.js';


const Oppsett = () => {

  const [org, setOrg] = useState(null);

  useEffect(() => {
    promisifyApiCall(getOrganisasjonData)().then((res) => { 
      setOrg(res);
    });
  }, []);
  return <h2>Test</h2>;
};

export default Oppsett;