import { Component } from 'react';
import CONFIG from 'lib/CONFIG';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import './dokeditor.css';
import Tt from 'images/24/topptekst24.png';
import Komment from 'images/Annet/kommentar.png';
import ReactFileReader from 'react-file-reader';
import { debugFactory } from 'utils';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/plugins/image.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import LagreTekstbit from 'modules/felles/avvikAnmerkninger/LagreTekstbit.jsx';
import SoekTekstbit from 'modules/felles/avvikAnmerkninger/SoekTekstbit.jsx';
import MalsoekDialog from './MalerMalsoekDialog.jsx';
import { flettMal } from 'api/innbyggerDialogApi';
/* import Forhandsvisning from './Forhandsvisning.jsx'; */
import TabellBorderDialog from './TabellBorderDialog.jsx';
import {
  PanelKommentar,
  visPinkKommentar,
  visIkkeKommentar,
  lastTestData,
  onKommentarClick,
  onNyKommentar,
} from './Kommentar.js';
import {
  checkEditorNormal,
  skjulTabellKantlinjer,
  visTabellKantlinjerIgjen,
  getMalDokumentPrMottaker,
} from './EditorNormal.js';
const debug = debugFactory('nk:SvarUtEditor');
const FROALA_KEY = CONFIG.REACT_APP_FROALA;

const kontaktString =
  '<!-- mott1 --><span contenteditable="false" style="background-color: yellow; color: blue;">Ved utskrift<br>vises mottakerne her..<br>Vennligst ikke fjern.</span><!-- mott2 -->';
const bStyle = '1px dotted white';
const cStyle = '1px dotted lightgray';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  TekstbiterDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <LagreTekstbit
        modul='avvik'
        merkettekst={dialogData.merkettekst}
        tekst={dialogData.tekst}
        onClose={onClose}
        onLagret={onClose}
      />
    </NKModal>
  ),
  TekstbiterSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <SoekTekstbit
        modul='avvik'
        onClose={onClose}
        onVelgTekst={onClose(owner.onInsertTekstbit)}
      />
    </NKModal>
  ),
  MalSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <MalsoekDialog
        onClose={onClose}
        onInsertMal={onClose(owner.onInsertMal)}
        modul={'P'}
        visFunksjon={false}
        visModulvalg={false}
      />
    </NKModal>
  ),
  /*   ForhandsvisningDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <Forhandsvisning
        onClose={onClose}
        model={owner.state.model}
        visToppTekstButton={false}
        logoUrl={owner.state.logoUrl}
        visValgMottaker={true}
        kontakter={owner.props.kontakt}
      />
    </NKModal>
  ), */
  BorderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <TabellBorderDialog
        onClose={onClose}
        element={dialogData.element}
        elementNavn={dialogData.elementNavn}
      />
    </NKModal>
  ),
};

class SvarUtEditor extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.froalaRef = null;
    this.kommentarRef = null;
    this.fileReader = null;
    this.state = {
      model: '',
      logoUrl: '',
      originalMode: '',
      kontakter: [],
      visKommentarPanel: false,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    this.editor = this.froalaRef;
    this.setState({ kontakter: this.props.kontakt });
    let m = this.props.model;
    m = checkEditorNormal(m);
    m = lastTestData(m);
    this.makeFroalaToolButtons(this.props.fletteOptions);
    this.setState({ model: m });

    setTimeout(() => {
      this.setNotEdibleText();
      let ed = this.unsynligTableVedStart();
      let x = ed.html.get();
      this.setState({ model: x });
    }, 1000);

    // hentLogo(this.gotLogo);
  };

  testFlereKontakter = () => {
    let k = this.props.kontakt;
    if (k.length > 1) {
      let array1 = k;
      let array2 = array1.concat(array1);
      let array3 = array2.concat(array2);
      let array4 = array3.concat(array3);
      debug('CONCON', array3);
      this.setState({ kontakter: array4 });
    }
  };

  gotLogo = (err, data) => {
    let currentTimeInMilliseconds = Date.now();
    let logo = data + '?' + currentTimeInMilliseconds;
    if (data) {
      this.setState({ logoUrl: logo });
    }
    this.setLogoFraStart();
  };

  notEditable = () => {
    alert('Flettefeltet skal ikke endres!');
  };

  setLogoFraStart = () => {
    let model = this.state.model;
    let html =
      '<p><img src ="' +
      this.state.logoUrl +
      '" style="display:block; height: 100px;" ></p>';
    model = model.replaceAll('#logo#', html);
    this.setState({ model });
  };

  setNotEdibleText = () => {
    let model = this.state.model;
    model = model.replaceAll('#mottaker#', kontaktString);
    this.setState({ model });
  };

  settMottakerTilbake = (txt) => {
    let ff = kontaktString;
    let gg = '#mottaker# ';
    txt = txt.replaceAll(ff, gg);
    return txt;
  };

  //*********************KOMMENTAR*************************************************************************************** */

  visKommentarPanel = () => {
    if (!this.state.visKommentarPanel) {
      this.props.toggleDialogWidth();
      this.setState({ visKommentarPanel: true });
      visPinkKommentar(this.froalaRef.editor, this.KommentarRef, false);
/*       setTimeout(() => {
       
      }, 3000); */
     
    } else {
      this.props.toggleDialogWidth();
      this.setState({ visKommentarPanel: false });
      visIkkeKommentar(this.froalaRef.editor);
    }
  };

  setNyKommentar = () => {
    if (!this.state.visKommentarPanel) {
      this.visKommentarPanel();
    }
    let editor = this.froalaRef.editor;

    editor.selection.restore();
    let tekst = editor.selection.text();
    if (!tekst) {
      alert('Ingen tekst merket ut..!');
      return;
    }
    onNyKommentar(tekst, this.KommentarRef, editor);
  };

  //************************************************************************************************************ */

  makeFroalaToolButtons = (fletteListe) => {
    // Froalaeditor.DefineIcon('tags', { NAME: 'tags', SVG_KEY: 'tags' });
    Froalaeditor.DefineIcon('tekstbiter', {
      NAME: 'Tekstbiter',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('tekstbiter', {
      title: 'Tekstbiter',
      type: 'dropdown',
      focus: true,
      undo: true,
      options: {
        SettInn: 'Sett inn tekstbit',
        TaVaere: 'Lagre tekstbit',
      },
      refreshAfterCallback: true,
      callback: (cmd, val) => {
        this.handleTekstbiter(cmd, val);
      },
    });
    Froalaeditor.DefineIcon('logo', {
      NAME: 'imageManager',
      SVG_KEY: 'imageManager',
    });
    Froalaeditor.RegisterCommand('logo', {
      title: 'Velg bilde',
      icon: 'imageManager',
      callback: () => {
        this.openFileReader();
      },
    });
    Froalaeditor.DefineIcon('maltekst', {
      NAME: 'Velg mal',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('maltekst', {
      title: 'Velg mal',
      icon: 'maltekst',
      callback: () => {
        this.velgMal();
      },
    });

    Froalaeditor.DefineIcon('kommentar', {
      SRC: Komment,
      ALT: 'Kommentar',
      template: 'image',
    });
    Froalaeditor.RegisterCommand('kommentar', {
      title: 'Sideperson kommentar',
      icon: 'kommentar',
      callback: () => {
        this.visKommentarPanel();
      },
    });

    Froalaeditor.DefineIcon('star', {
      NAME: 'star',
      SVG_KEY: 'star',
    });
    Froalaeditor.RegisterCommand('star', {
      title: 'Testknapp Sideperson inn',
      icon: 'star',
      callback: () => {
        this.setKommentar();
      },
    });

    Froalaeditor.DefineIcon('star2', {
      NAME: 'star2',
      SVG_KEY: 'star2',
    });
    Froalaeditor.RegisterCommand('star2', {
      title: 'Testknapp Sideperson ut',
      icon: 'star',
      callback: () => {
        this.fjernKommentar();
      },
    });

    Froalaeditor.DefineIcon('cellbordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'cellStyle',
    });
    Froalaeditor.RegisterCommand('cellbordersDialog', {
      title: 'Kantlinjer celle',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var celle = celleArray[0];
        this.dialogs.setOpenDialog('BorderDialog', {
          element: celle,
          elementNavn: ' celle',
        });
      },
    });

    Froalaeditor.DefineIcon('tablebordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'tableStyle',
    });
    Froalaeditor.RegisterCommand('tablebordersDialog', {
      title: 'Kantlinjer tabell',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var parentTable = celleArray[0].offsetParent;
        this.dialogs.setOpenDialog('BorderDialog', {
          element: parentTable,
          elementNavn: ' tabell',
        });
      },
    });

    Froalaeditor.DefineIcon('pageBreaker', {
      NAME: 'Sideskift',
      SVG_KEY: 'pageBreaker',
    });
    Froalaeditor.RegisterCommand('pageBreaker', {
      title: 'Sideskift',
      icon: 'pageBreaker',
      callback: () => {
        this.sideskift();
      },
    });

    Froalaeditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
    Froalaeditor.RegisterCommand('clear', {
      title: 'Fjern alt',
      focus: false,
      undo: true,
      refreshAfterCallback: true,
      callback: function() {
        this.html.set('');
        this.events.focus();
      },
    });
    let liste = fletteListe;
    let fletteOptions = {};
    for (let i = 0; i < liste.length; i++) {
      const el = liste[i];
      if (el.result) {
        fletteOptions[el.result + ' '] = el.text;
      }
    }

    Froalaeditor.DefineIcon('topptekst', {
      SRC: Tt,
      ALT: 'Topptekst',
      template: 'image',
    });
    Froalaeditor.RegisterCommand('topptekst', {
      title: 'Vis/skjul topptekst',
      icon: 'topptekst',
      callback: () => {
        this.visTopptekst();
      },
    });

    Froalaeditor.DefineIcon('flettefelt', {
      NAME: 'Sett inn',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('flettefelt', {
      title: 'Sett inn flettefelt',
      type: 'dropdown',
      focus: false,
      undo: false,
      options: fletteOptions,
      refreshAfterCallback: true,
      callback: (cmd, val) => {
        this.settInnFlettefelt(cmd, val);
      },
    });
  };

  openFileReader = () => {
    let fileReader = this.fileReaderRef;
    fileReader.click();
  };

  handleTekstbiter = (cmd, val) => {
    if (val === 'SettInn') {
      this.settInnTekstbit();
    } else {
      this.lagreTekstbit();
    }
  };

  settInnTekstbit = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('TekstbiterSoekDialog');
  };

  onInsertMal = (mal) => {
    let editor = this.froalaRef.editor;
    editor.selection.restore();
    editor.html.insert(mal);
  };

  onInsertMalOld = (mal) => {
    let MalId = mal.id;
    let FunksjonId = mal.funksjonId;
    let AvvikId = this.props.avvikId;
    let BruksenhetId = this.props.bruksenhetId;
    //let ToppTekstFlettes = false;

    let dto = {
      MalId: MalId,
      FunksjonId: FunksjonId,
      AvvikId: AvvikId,
      BruksenhetId: BruksenhetId,
      //ToppTekstFlettes: ToppTekstFlettes,
    };
    flettMal(dto, this.gotFlettetTekst);
  };

  gotFlettetTekst = (err, val) => {
    if (val) {
      if (val.tekst) {
        let editor = this.froalaRef.editor;
        editor.selection.restore();
        editor.html.insert(val.tekst);
      }
    }
  };

  onInsertTekstbit = (val) => {
    let editor = this.froalaRef.editor;
    editor.selection.restore();
    /* editor.html.insert(val); */
    editor.html.insert('<p>' + val + '</p>');
    editor.selection.save();
    this.handleModelChange(editor.html.get());
  };

  removeHTML = (str) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  };

  lagreTekstbitOld = () => {
    let editor = this.froalaRef.editor;
    let s = editor.selection.text();
    s = this.removeHTML(s);
    this.dialogs.setOpenDialog('TekstbiterDialog', {
      tekst: s,
      merkettekst: s,
    });
  };

  lagreTekstbit = () => {
    let editor = this.froalaRef.editor;
    let selection = window.getSelection();
    if (selection.rangeCount > 0) {
      let range = selection.getRangeAt(0); // Get the Range object
      let container = document.createElement('div');
      container.appendChild(range.cloneContents()); // Clone the contents of the range
    
      this.dialogs.setOpenDialog('TekstbiterDialog', {
      tekst: editor.html.get(),
      merkettekst: container.innerHTML, // Get the HTML of the selected content
      });
    }
    };

  velgMal = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('MalSoekDialog');
  };

  settInnFlettefelt = (cmd, val) => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    editor.selection.restore();
    editor.html.insert(val);
  };

  getDokumentTekst = () => {
    return this.state.model;
  };

  handleModelChange = (model) => {
    this.setState({
      model: model,
    });
    this.props.onChange(model);
  };

  handleFiles = (files) => {
    let imstring =
      '<img src="' +
      files.base64 +
      '" alt="Bilde x" class="fr-fic fr-dii"></img>';
    let editor = this.froalaRef.editor;
    editor.selection.save();
    editor.selection.restore();
    editor.html.insert(imstring);
    editor.helpers.scrollTop();
  };

  sideskift = () => {
    let bre =
      '<p style="border-top: 1px solid WhiteSmoke; page-break-before: always;"/>';
    let editor = this.froalaRef.editor;
    editor.selection.save();
    editor.selection.restore();
    editor.html.insert(bre);
  };

  visTopptekst = () => {
    this.setLogo(this.state.logoUrl);
  };

  setLogo = (src) => {
    let html =
      '<img src ="' +
      src +
      '" style="display:block; height: 100px;" ><div style="display:none;">**====**</div>';
    let model = this.state.model;
    if (model.includes('**====**')) {
      let pos = model.indexOf('**====**') + 14;
      model = model.slice(pos);
    } else {
      model = html + model;
    }
    this.setState({ model });
  };

  unsynligTableVedStart = () => {
    let editor = this.froalaRef.editor;
    let el = editor.el.children;
    for (let i = 0; i < el.length; i++) {
      let element = el[i];
      if (element.localName === 'table') {
        //table
        if (element.style.border === '') {
          element.style.border = cStyle;
        }
        //celler
        for (let y = 0; y < element.rows.length; y++) {
          let row = element.rows[y];
          for (let z = 0; z < row.children.length; z++) {
            let celle = row.children[z];
            if (celle.style.border === '') {
              celle.style.border = cStyle;
            }
          }
        }
      }
    }
    return editor;
  };

  unsynligTable = () => {
    let editor = this.froalaRef.editor;
    let el = editor.el.children;
    for (let i = 0; i < el.length; i++) {
      let element = el[i];
      if (element.localName === 'table') {
        //table
        if (element.style.border === cStyle) {
          element.style.border = bStyle;
        }
        //celler
        for (let y = 0; y < element.rows.length; y++) {
          let row = element.rows[y];
          for (let z = 0; z < row.children.length; z++) {
            let celle = row.children[z];
            if (celle.style.border === cStyle) {
              celle.style.border = bStyle;
            }
          }
        }
      }
    }
    return editor;
  };

  synligTable = () => {
    let editor = this.froalaRef.editor;
    let el = editor.el.children;
    for (let i = 0; i < el.length; i++) {
      let element = el[i];
      if (element.localName === 'table') {
        //table
        if (element.style.border === bStyle) {
          element.style.border = cStyle;
        }
        //celler
        for (let y = 0; y < element.rows.length; y++) {
          let row = element.rows[y];
          for (let z = 0; z < row.children.length; z++) {
            let celle = row.children[z];
            if (celle.style.border === bStyle) {
              celle.style.border = cStyle;
            }
          }
        }
      }
    }
    if (this.state.kontakter.length > 1) {
      this.setState({ model: this.state.originalModel });
    }
  };

  skrivutFlereMottakere = () => {
    let modelFlere = '';
    let model = this.state.model;
    model = this.settMottakerTilbake(model);

    //Skriv ut til ett dokuemnt eller til flere dokumenter

    if (getMalDokumentPrMottaker()) {
      for (let i = 0; i < this.state.kontakter.length; i++) {
        let shift = '';
        if (i > 0) {
          shift = '<p style="page-break-before: always;"></p><p/>';
        }
        const kontakt = this.state.kontakter[i];
        let flettetTekst = this.goFlettMottaker(model, kontakt);
        modelFlere = modelFlere + shift + flettetTekst;
      }
      this.setState({ model: modelFlere });
    } else {
      //Make big blokk med alle mottakere
      let blokk = this.makeBigBlokk();
      if (model.includes('#mottaker#')) {
        model = this.goReplace(model, '#mottaker#', blokk);
      }
      if (model.includes(cStyle)) {
        model = this.goReplace(model, cStyle, bStyle);
      }
      this.setState({ model });
    }
  };

  makeBigBlokk = () => {
    let s = '<p>';
    for (let i = 0; i < this.state.kontakter.length; i++) {
      const kontakt = this.state.kontakter[i];
      s =
        s +
        kontakt.navn +
        '<br/>&nbsp;&nbsp;' +
        kontakt.adresse +
        '<br/>&nbsp;&nbsp;' +
        kontakt.poststed +
        '<br/>';
    }
    s = s + '</p>';
    return s;
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replaceAll(new RegExp(find, 'g'), repl);
    return str;
  };

  goFlettMottaker = (m, kontakt) => {
    let flettefelt = '#mottaker#';
    let kontaktBlokk =
      '<p>' +
      kontakt.navn +
      '<br/>' +
      kontakt.adresse +
      '<br/>' +
      kontakt.poststed +
      '</p>';
    if (m.includes(flettefelt)) {
      m = this.goReplace(m, flettefelt, kontaktBlokk);
    }
    if (m.includes(cStyle)) {
      m = this.goReplace(m, cStyle, bStyle);
    }
    return m;
  };

  test = () => {
    alert('TEST');
  };

  render() {
    if (this.state.model === '') {
      return <div>Vent..</div>;
    }

    let visHTML = '';
    let visStar = '';
    let visStar2 = '';
    let visKommentar = '';
   /*  let visKommentarPlus = ''; */
    if (this.props.norkartbruker) {
      visHTML = 'html';
      visKommentar = 'kommentar';
     /*  visKommentarPlus = 'kommentarPlus'; */
      visStar = 'star';
      visStar2 = 'star2';
    }

    const config = {
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'SvarUt tekst..',
      autoFocus: true,
      listAdvancedTypes: false,
      fontFamilySelection: true,
      fontSizeSelection: true,
      /*       paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
      }, */
      paragraphFormatSelection: false,
      quickInsertEnabled: false,
      tableEditButtons: [
        'tableHeader',
        'tableRemove',
        '|',
        'tableRows',
        'tableColumns',
        'tablebordersDialog',
        '-',
        'tableCells',
        'tableCellBackground',
        '|',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign',
        'cellbordersDialog',
      ],

      tableColors: [
        '#61BD6D',
        '#1ABC9C',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#CCCCCC',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#000000',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#FFFFFF',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
        'REMOVE',
      ],
      tabSpaces: 4,
      language: 'nb',
      imagePaste: false,
      heightMin: 200,
      heightMax: 'calc(100vh - 360px)',
      height: 'calc(100vh - 360px)',
      lineheight: '1',
      charCounterCount: true,
      lineHeights: {
        Standard: '1.5',
        '1.15': '1.15',
        '1.5': '1.5',
        Dobbel: '2',
      },
      events: {
        /*  'commands.before': function(e) {
          if (e === 'print') {
            return skjulTabellKantlinjer(this);
          }
        },
        'commands.after': function(e) {
          if (e === 'print') {
            return visTabellKantlinjerIgjen(this);
          }
        }, */
        'commands.before': (e, x) => {
          if (e === 'print') {
            let editor = this.froalaRef.editor;
            this.setState({ originalModel: this.state.model });
            if (this.state.kontakter.length > 1) {
              this.skrivutFlereMottakere();
              return skjulTabellKantlinjer(editor);
            } else {
              return skjulTabellKantlinjer(editor);
            }
          }
        },
        'commands.after': (f) => {
          if (f === 'print') {
            this.setState({ model: this.state.originalModel });
            let editor = this.froalaRef.editor;
            return visTabellKantlinjerIgjen(editor);
          }
        },
        mousedown: (e) => {
          if (e.currentTarget.nodeName === 'SPAN') {
            onKommentarClick(e, this.KommentarRef, this.froalaRef.editor);
          }
        },
      },
      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Verdana,Geneva,sans-serif': 'Verdana',
        'Open Sans': 'Open Sans',
        'Calibri': 'Calibri',
        'Times New Roman,Times,serif': 'Times New Roman',
        'Roboto,sans-serif': 'Roboto',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        'Oswald,sans-serif': 'Oswald',
        'Montserrat,sans-serif': 'Montserrat',
        Handlee: 'Handlee',
      },
      fontSize: [
        '8',
        '10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '20',
        '24',
        '30',
        '60',
      ],
      toolbarButtons: {
        moreText: {
          buttons: [
            'bold',
            'underline',
            'italic',
            'strikeThrough',
            'subscript',
            'superscript',
            'fontFamily',
            '|',
            'fontSize',
            '|',
            'paragraphFormat',
            '|',
            'textColor',
            'backgroundColor',
          ],
          align: 'left',
          buttonsVisible: 3,
        },
        moreMisc: {
          buttons: [
            'undo',
            'redo',
            'tekstbiter',
            'specialCharacters',
            'pageBreaker',
            visStar,
            visStar2,
            visKommentar,
            visHTML,
          ],
          align: 'right',
          buttonsVisible: 2,
        },
        moreParagraph: {
          buttons: [
            'formatOL',
            'formatUL',
            'print',
            'flettefelt',
            'alignLeft',
            'alignCenter',
            'alignRight',
            'alignJustify',
            'lineHeight',
            'outdent',
            'indent',
          ],
          align: 'left',
          buttonsVisible: 4,
        },
      },
    };
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td style={{ maxWidth: '776px', minWidth: '776px' }}>
                <FroalaEditorComponent
                  ref={(el) => {
                    this.froalaRef = el;
                  }}
                  id='editor'
                  useClasses={false}
                  config={config}
                  documentReady={true}
                  model={this.state.model}
                  onModelChange={this.handleModelChange}
                />
              </td>
              {this.state.visKommentarPanel && (
                <td
                  style={{
                    padding: '4px',
                    verticalAlign: 'top',
                    width: '300px',
                  }}>
                  <PanelKommentar
                    ref={(el) => {
                      this.KommentarRef = el;
                    }}
                    editor={this.froalaRef}
                    panel={ this.KommentarRef}
                    visKommentarPanel={this.visKommentarPanel}
                    setNyKommentar={this.setNyKommentar}
                  />
                </td>

              )}

              <tr />
            </tr>
          </tbody>
        </table>

        <ReactFileReader
          base64={true}
          multipleFiles={false}
          fileTypes={['.gif, .jpg, .png, .jpeg']}
          handleFiles={this.handleFiles}>
          <button
            ref={(fl) => {
              this.fileReaderRef = fl;
            }}
            className='btn'
            style={{ display: 'none' }}>
            Last opp image
          </button>
        </ReactFileReader>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SvarUtEditor;
