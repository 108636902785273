/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import Spinner from 'components/generics/Spinner';
import moment from 'moment';
import NKIcon from 'nka-icons';
import NKModal from 'components/Dialogs/NKModal.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import VelgArsakDialog from './VelgArsakDialog.jsx';
import DeltAvvikValgModal from './DeltAvvikValgModal.jsx';
import senddialogwhite from 'images/Annet/senddialogwhite.png';
import senddialogblack from 'images/Annet/senddialogblack.png';
import { getEksempeltekst } from 'components/Editor/testtekst.js';
import { getContextAvvikLukkArsaker } from 'context/contextOppslag.js';
import './sidemann.css';
import DialogRader from './DialogRader.js';
import Bilder from './Bilder.js';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import NkaFormElementDropdown from 'nka-form-element-dropdown';
import BFButtonGroup from 'components/BFButtonGroup/BFButtonGroup.js';
import BFButton from 'components/BFButtonGroup/BFButton.js';
import Editor from 'components/Editor/SidemannEditor';
import { getMottakerVarselEierDialog } from 'components/Editor/EditorNormal.js';
import JaNeiDialog from 'components/Dialogs/JaNeiDialogFC.js';
import Kommentar from './Kommentar.js';
import {
  updateAvvik,
  getApnePubliserteAvvikForBruksenhet,
  updateInnbyggerdialogpubliseringklarlukk,
  updateInnbyggerDialogUnderArbeid,
  updateInnbyggerdialogpubliseringFjernklarlukk,
  updateInnbyggerDialogPubliseringKlarv2,
} from 'api/avvikApi.js';
import {
  nyInnbyggerDialogAvvik,
  endreInnbyggerdialogAvvik,
  slettInnbyggerdialogAvvikDialog,
  getPublisertAvvikDialogOppfolging,
} from 'api/innbyggerDialogApi';
import SendAvvikTilEier from './SendAvvikTilEier.jsx';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
import { promisifyApiCall, stringBetweenPluss } from 'utils';
import { getBruksenhet, getFyringsforbudForBruksenhet } from 'api/enhetApi.js';
import { setSisteFaneBesoekt } from 'context/contextOppfolging';
import UnderBehandlingAvvik from './UnderBehandlingAvvik.js';
import { getContextInnstillingerMalinfoNew } from 'context/contextInnstillinger.js';
import {
  contextInnstillingerPerson,
  getInnloggetPerson,
} from 'context/contextInnstillinger.js';

function MineOppgaverUnderBehandling(props) {
  const froalaRef = useRef(null);
  const komRef = useRef();
  const bilderRef = useRef();
  const [propsAvvik, setPropsAvvik] = useState(null);
  const [valgtAvvik, setValgtAvvik] = useState(null);
  const [fane, setFane] = useState('Dok');
  const [sendTilEierGreen, setSendTilEierGreen] = useState(false);
  const [avvikListe, setAvvikListe] = useState([]);
  const [adresse, setAdresse] = useState('');
  const [bruksenhetId, setBruksenhetId] = useState(-1);
  const [bruksenhetIds, setBruksenhetIds] = useState([]);
  const [screenModus, setScreenModus] = useState('stor');
  const [malInfo, setMalInfo] = useState(null);
  const [avvikScrollHeight, setAvvikScrollHeight] = useState('1000px');
  const [modalMatrikkelOpen, setModalMatrikkelOpen] = useState(false);
  const [modalSituasjonOpen, setModalSituasjonOpen] = useState(false);
  const [matrikkelId, setMatrikkelId] = useState(0);
  const [bygningsNr, setBygningsNr] = useState(0);
  const [velgBruksenhetOpen, setVelgBruksenhetOpen] = useState(false);
  const [dokumentHarBlittEndret, setDokumentHarBlittEndret] = useState(false);
  const [kommentarHarBlittEndret, setKommentarHarBlittEndret] = useState(false);
  const [linkTekst, setLinkTekst] = useState('Vis kommentar');
  const [model, setModel] = useState('');
  const [editorSynlig, setEditorSynlig] = useState(false);
  const [modelInitiert, setModelInitiert] = useState(false);
  const [kommentarer, setKommentarer] = useState([]);
  const [komAvvikId, setKomAvvikId] = useState(0);
  const [brukerNavn, setBrukerNavn] = useState('');
  const [sletteOpen, setSletteOpen] = useState(false);
  const [currentInnlegg, setCurrentInnlegg] = useState(null);
  const [kanIkkeVelgeOpen, setKanIkkeVelgeOpen] = useState(false);
  const [dialogRader, setDialogRader] = useState([]);
  const [arsakDialogOpen, setArsakDialogOpen] = useState(false);
  const [arsaker, setArsaker] = useState([]);
  const [jaNeiFjernPublOpen, setJaNeiFjernPublOpen] = useState(false);
  const [jaNeiSkalLukkesOpen, setJaNeiSkalLukkesOpen] = useState(false);
  const [oppdaterer, setOppdaterer] = useState(false);
  const [eierkontaktTekst, setEierkontaktTekst] = useState(
    'Vis eierkontakt(er)'
  );
  const [eierKontakter, setEierKontakter] = useState([]);
  const [hideKontakter, setHideKontakter] = useState(true);
  const [editorFletteOptions, setEditorFletteOptions] = useState([]);
  const [fyringsForbud, setFyringsForbud] = useState(false);
  const [sendAvvikTilEierOpen, setSendAvvikTilEierOpen] = useState(false);
  const [
    jaNeiSendTilEierLikevelOpen,
    setJaNeiSendTilEierLikevelOpen,
  ] = useState(false);
  const [sendAvvikListe, setSendAvvikListe] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [skrivebeskyttetOpen, setSkrivebeskyttetOpen] = useState(false);
  const [innloggetPersonNavn, setInnloggetPersonNavn] = useState('');
  const [ansvarFeil, setAnsvarFeil] = useState(false);
  const [VarselNewOpen, setVarselNewOpen] = useState(false);

  useEffect(() => {
    setSisteFaneBesoekt('UnderBehandling');
    //setValgtAvvik(props.location.state.avvik);
    setPropsAvvik(props.location.state.avvik);
    let brListe = props.location.state.avvik.bruksenhetIds.split(',');
    setBruksenhetIds(brListe);

    //Delt avvik?
    if (props.location.state.avvik.bruksenhetIds.indexOf(',') > -1) {
      //Velg bruksenhet fra dialog
      setVelgBruksenhetOpen(true);
    } else {
      //Kun 1 bruksenhet
      let bid = parseInt(brListe[0]);
      setBruksenhetId(bid);
      getFyringsforbudForBruksenhet(bid, gotInfoOmFyringsforbud);
      setAdresse(props.location.state.avvik.bruksenhetAdresse);
    }
  }, []);

  useEffect(() => {
    if (bruksenhetIds && bruksenhetIds.includes(',')) {
      setEierkontaktTekst('Delt med flere, vis alle eierkontakter');
    } else {
      setEierkontaktTekst('Vis eierkontakt(er)');
    }
  }, [bruksenhetIds]);


  useEffect(() => {
    if (bruksenhetId > -1 && avvikListe.length === 0) {
      hentData();
    }
  }, [bruksenhetId]);

  useEffect(() => {
    getContextInnstillingerMalinfoNew(gotMalInfo);
    getInnloggetPerson((person) => {
      setInnloggetPersonNavn(person.navn);
    });
    updateWidthAndHeight();
    window.addEventListener('resize', updateWidthAndHeight);
  }, []);

  useEffect(() => {
    if (!valgtAvvik) {
      return;
    }
    if (!valgtAvvik.avvik) {
      return;
    }
    let feil = ansvarMangler();
    setAnsvarFeil(feil);

  }, [valgtAvvik]);

  useEffect(() => {
    if (!valgtAvvik) {
      return;
    }
    if (!valgtAvvik.avvik) {
      return;
    }
    let e = getEksempeltekst();
    let liste = [
      {
        text: 'Dagens dato',
        result: moment().format('DD.MM.YYYY'),
      },
      {
        text: 'Dato registrert',
        result: moment(valgtAvvik.avvik.dato).format('DD.MM.YYYY'),
      },
      {
        text: 'Frist',
        result: moment(valgtAvvik.avvik.frist).format('DD.MM.YYYY'),
      },
      {
        text: 'Avvikstype',
        result: getAvvikType(),
      },
      {
        text: 'Adresse',
        result: adresse,
      },
      {
        text: 'Paragraf(er)',
        result: getParagraferRegistrert(),
      },
      {
        text: contextInnstillingerPerson._currentValue.navn,
        result: contextInnstillingerPerson._currentValue.navn,
      },
      {
        text: 'Mobil: ' + contextInnstillingerPerson._currentValue.mobil,
        result: contextInnstillingerPerson._currentValue.mobil,
      },
      {
        text: 'Telefon: ' + contextInnstillingerPerson._currentValue.telefon,
        result: contextInnstillingerPerson._currentValue.telefon,
      },
      {
        text: contextInnstillingerPerson._currentValue.orgNavn,
        result: contextInnstillingerPerson._currentValue.orgNavn,
      },
    ];
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      liste.unshift({
        text: 'Eksempeltekst',
        result: e,
      });
    }

    setEditorFletteOptions(liste);
  }, [valgtAvvik]);

  const ansvarMangler = () => {
    //Hvem har ansvar?
    if (valgtAvvik.avvik.ansvarlig === 'Saksbehandler') {
      if (innloggetPersonNavn !== valgtAvvik.avvik.saksbehandler) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };
  //info om fontsize fonttype osv
  const gotMalInfo = (info) => {
    setMalInfo(info);
  };

  const getParagraferRegistrert = () => {
    let s = '';
    let p = valgtAvvik.avvik.paragrafRegistrert;
    for (let i = 0; i < p.length; i++) {
      s = s + '<li>' + p[i].paragraf + '</li>';
    }
    if (s !== '') {
      s = '<ul>' + s + '</ul>';
    }
    return s;
  };

  //Vis grønn knapp dersom en av avvikene er klar til publ.
  useEffect(() => {
    if (avvikListe.length) {
      let a = avvikListe.find(
        (obj) => obj.publiserInnbyggerDialogKlar === true
      );
      if (a) {
        setSendTilEierGreen(true);
      } else {
        setSendTilEierGreen(false);
      }
    } else {
      setSendTilEierGreen(false);
    }
  }, [avvikListe]);

  //Dersom delt
  const bruksenhetValgt = (bruksenhetId, adresse) => {
    setVelgBruksenhetOpen(false);
    setBruksenhetId(bruksenhetId);
    setAdresse(adresse);
    getFyringsforbudForBruksenhet(bruksenhetId, gotInfoOmFyringsforbud);
  };

  const gotInfoOmFyringsforbud = (err, forbud) => {
    setFyringsForbud(forbud);
  };

  const hentData = () => {
    //Hent  åpne avvik  for bruksenheten.
    promisifyApiCall(getApnePubliserteAvvikForBruksenhet)(bruksenhetId).then(
      (liste) => {
        setAvvikListe(liste);

        scrollTilAvvik(false);
        let a = liste.find((obj) => obj.id === props.location.state.avvik.id);

        hentBigAvvik(a);
      }
    );
  };

  const hentBigAvvik = (a) => {
    //Hent Big Avvik med avvik, opplagslister, paragrafer, dokument, kommentarer mm

    promisifyApiCall(getPublisertAvvikDialogOppfolging)(
      a.id,
      a.objektType.charAt(0)
    ).then((data) => {
      setValgtAvvik(data);
      setKomAvvikId(data.avvik.id);
      rensKommentarer(data.avvik.kommentarer);
      splitDialogRader(data.avvikDialog);
    });
  };

  const splitDialogRader = (rader) => {
    setModelInitiert(false);
    setModel('');
    setDokumentHarBlittEndret(false);

    //Rader som skal vises under Innbyggerdialog
    let dialogRader = rader.filter((f) => f.publisert === true);
    setDialogRader(dialogRader);

    //Rad som skal vises i Dokument
    let current = rader.filter((f) => f.publisert === false);
    if (current.length) {
      setCurrentInnlegg(current[0]);
      let txt = current[0].tekst;

      //Ikke vis editor dersom ingen text. Da kan man velge Nytt svar eller Lukk Avvik
      if (txt.length) {
        setModelTekst(txt, true);
        setEditorSynlig(true);
      } else {
        setModelTekst('', false);
        setEditorSynlig(false);
      }

      setDokumentHarBlittEndret(false);
    } else {
      setModelTekst('', false);
      setEditorSynlig(false);
    }
    setTimeout(() => {
      setModelInitiert(true);
      setDokumentHarBlittEndret(false);
      setKommentarHarBlittEndret(false);
    }, 1000);
  };

  const rensKommentarer = (kom) => {
    for (let i = 0; i < kom.length; i++) {
      const k = kom[i];
      if (k.kommentar.endsWith('<p><br></p>')) {
        k.kommentar = k.kommentar.replace('<p><br></p>', '');
      }
    }
    setKommentarer(kom);
  };

  const getAvvikType = () => {
    let t = valgtAvvik.avvik.avvikTypeId;
    let liste = valgtAvvik.avvikOppslagdata.avvikTypeListe;
    let a = liste.find((obj) => obj.id === t);
    if (a) {
      return a.tekst;
    } else {
      return '';
    }
  };

  const scrollTilAvvik = (brukValgtAvvik) => {
    let id = propsAvvik.id;

    if (brukValgtAvvik) {
      if (valgtAvvik) {
        id = valgtAvvik.avvik.id;
      }
    }

    setTimeout(() => {
      let key = 'key' + id;
      let el = document.getElementById(key);
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }
    }, 1000);
  };

  const updateWidthAndHeight = () => {
    let w = window.innerWidth;
    let modus = '';
    if (w > 1400) {
      modus = 'stor';
    } else if (w > 1100) {
      modus = 'small'; //'middels'
    } else {
      modus = 'small';
    }
    // setFane('Dok');
    setScreenModus(modus);

    let h = window.innerHeight - 200;

    setAvvikScrollHeight(h + 'px');
  };

  const buttonValgt = (id, name) => {
    if (id.label) {
      name = id.label;
    }
    switch (name) {
      case 'Matrikkelinfo':
        //DErsom info ikke finnes enda, må det hentes først
        if (matrikkelId === 0) {
          getBruksenhet(
            bruksenhetId, //Pass på, kan være flere
            hentetBruksenhetInfo
          );
        } else {
          setModalMatrikkelOpen(true);
        }
        break;
      case 'Situasjon':
        setModalSituasjonOpen(true);
        break;
      default:
        alert('nix');
    }
  };

  const hentetBruksenhetInfo = (err, data) => {
    setMatrikkelId(data.bruksenhetIdMa);
    setBygningsNr(data.bygning);
    setTimeout(() => {
      setModalMatrikkelOpen(true);
    }, 2000);
  };

  const knapper = [
    {
      knapp: 0,
      label: 'Matrikkelinfo',
    },
    {
      knapp: 1,
      label: 'Situasjon',
    },
  ];

  const skjulMatrikkel = (svar) => {
    setModalMatrikkelOpen(false);
  };

  const skjulSituasjon = (svar) => {
    setModalSituasjonOpen(false);
  };

  const visKontakt = () => {
    //Ikke hent dersom allerede har blitt hentet
    if (!eierKontakter.length) {
      if (bruksenhetIds.includes(',')) {
        let liste = bruksenhetIds.split(',');
        getBruksenhetKontakter2(liste, gotEierkontakter);
      } else {
        let liste = [];
        liste.push(bruksenhetId);
        getBruksenhetKontakter2(liste, gotEierkontakter);
      }
    }
    setHideKontakter(false);
  };

  const gotEierkontakter = (err, data) => {
    setEierKontakter(data);
  };

  const getEierKontakter = () => {
    if (!eierKontakter.length) {
      return;
    }
    return (
      <div style={{ padding: '6px', border: '1px solid green' }}>
        {eierKontakter.map((enhet) => {
          return (
            <div key={'dd' + enhet.id}>
              <span style={{ fontSize: '14px' }}>
                {enhet.bruksenhetAdresse}
              </span>
              <br />
              <ul>
                {enhet.kontakt.map((k, index) => {
                  return (
                    <li style={{ fontSize: '12px' }} key={'li' + index}>
                      <span>
                        <span>
                          {k.navn} {k.telefon}
                        </span>
                        <br />
                        <span>{k.adresse}</span>
                        <br />
                        <span>{k.poststed}</span>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}

        <button
          style={{ marginLeft: '4px', marginTop: '0px', paddingTop: '0px' }}
          type='button'
          onClick={() => setHideKontakter(true)}
          className='btn btn-link'>
          Skjul kontakt(er)
        </button>
      </div>
    );
  };

  const test = () => {
    setModel('Hopp');
  };

  const sendTilEierPubliser = () => {
    let liste = [];

    //Filtrer kun de som er klar til publisering
    for (let i = 0; i < avvikListe.length; i++) {
      const av = avvikListe[i];
      if (av.publiserInnbyggerDialogKlar) {
        liste.push(av);
      }
    }

    setSendAvvikListe(liste);

  };

  useEffect(() => {
    if (sendAvvikListe.length && !jaNeiSendTilEierLikevelOpen) {
      sendTilEierPubliserFortsett();
    }
  }, [sendAvvikListe]);

  const resultatJaNeiSendLikevel = (svar) => {
    if (svar === 'ja') {
      sendTilEierPubliserFortsett();
    }
    setJaNeiSendTilEierLikevelOpen(false);
    setSendAvvikListe([]);
  };

  const sendTilEierPubliserFortsett = () => {
    setSendAvvikTilEierOpen(true);
  };

  const avvikHarBlittSendtTilEier = () => {
    setSendAvvikTilEierOpen(false);
    hentData();
  };

  const getGreenKnapp = () => {
    return (
      <div
        style={{
          height: '40px',
          width: '250px',
          position: 'relative',
        }}>
        <BFButton
          disabled={fane !== 'Dok' || !sendTilEierGreen}
          buttonWidth={250}
          title='Kan brukes dersom minst ett avvik er "klar til publisering"'
          green={true}
          buttonLabel='Send til eier/publiser'
          image={senddialogwhite}
          afterClick={sendTilEierPubliser}
          imageHover={senddialogblack}></BFButton>
      </div>
    );
  };

  //////////////////////////////VELG FANE
  const buttonFaneValgt = (id, name) => {
    let tag = 'Dok';
    switch (id) {
      case 0:
        tag = 'Dok';
        break;
      case 1:
        tag = 'Inn';
        break;
      case 2:
        tag = 'Avv';
        break;
      case 3:
        tag = 'Bil';
        break;
      default:
        tag = 'Dok';
        break;
    }
    setFane(tag);
  };

  ////////////////////////////// VELG AVVIK
  const endreAvvik = (frist) => {
    let av = { ...valgtAvvik };
    av.avvik.frist = frist;
    setValgtAvvik(av);
    updateAvvik(av.avvik.id, av.avvik, avvikLagred);

    //Sync avvikListe uten å måtte hente på nytt
    let ax = avvikListe.find((obj) => obj.id === av.avvik.id);
    ax.frist = frist;
  };

  const avvikLagred = (err, data) => {};

  const velgAvvik = (avvik) => {
    if (komRef.current) {
      if (komRef.current.kommentarSkalLagreFirst() || dokumentHarBlittEndret) {
        setKanIkkeVelgeOpen(true);
      } else {
        hentBigAvvik(avvik);
      }
    } else {
      hentBigAvvik(avvik);
    }
  };

  const ikkeVelgAvvik = () => {
    setKanIkkeVelgeOpen(false);
    scrollTilAvvik(true);
  };

  const getIkon = (item) => {
    let ic = '';
    switch (item.objektType) {
      case 'Bruksenhet':
        ic = 'hjem';
        break;
      case 'Ildsted':
        ic = 'ildsted';
        break;
      case 'Røykløp':
        ic = 'royklop';
        break;
      default:
        ic = 'minus';
    }
    return ic;
  };

  const getAvvikBox = (avvik) => {
    let cn = 'bfAvvikAapen2';
    /*   if (avvik.id === rememberMe ){
      cn = 'bfAvvikAapen2Valgt';
    } else */
    if (avvik.id === valgtAvvik.avvik.id) {
      cn = 'bfAvvikAapen2Valgt';
    }
    return (
      <div
        onClick={() => velgAvvik(avvik)}
        style={{ verticalAlign: 'textTop' }}
        className={cn}
        id={'key' + avvik.id}
        key={'key' + avvik.id}>
        <table
          style={{ width: '100%', marginLeft: '4px', marginRight: '2px' }}
          key={'keyTab' + avvik.id}>
          <tbody>
            <tr key='tr1'>
              <td style={{ width: '22px', verticalAlign: 'textTop' }}>
                {' '}
                <NKIcon
                  style={{ marginLeft: '2px', verticalAlign: 'textTop' }}
                  className='pull-left'
                  icon={getIkon(avvik)}
                  color='nk-black'
                  size='0.8'
                />
              </td>
              <td>
              </td>
              <td style={{ width: '6px' }}></td>
              <td>
                <span
                  style={{
                    marginTop: '4px',
                    float: 'right',
                    marginRight: '6px',
                    fontSize: '14px',
                  }}>
                  {' '}
                  Dato: {moment(avvik.dato).format('DD.MM.YYYY')}
                </span>
                <br />
                <span
                  style={
                    moment() > moment(avvik.frist)
                      ? {
                          float: 'right',
                          marginRight: '6px',
                          fontSize: '14px',
                          color: 'red',
                        }
                      : {
                          float: 'right',
                          marginRight: '6px',
                          fontSize: '14px',
                        }
                  }>
                  {' '}
                  Frist: {moment(avvik.frist).format('DD.MM.YYYY')}
                </span>
              </td>
            </tr>

            <tr key='tr2'>
              <td
                colSpan={4}
                style={{ height: '60px', verticalAlign: 'textTop' }}>
                <span
                  className='spanWrap'
                  title={avvik.avvikType}
                  style={{
                    float: 'left',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    height: '60px',
                  }}>
                  {avvik.avvikType}
                </span>
              </td>
            </tr>
            <tr key='tr3'>
              <td colSpan={4}>
                <span
                  style={{
                    float: 'left',
                    fontSize: '14px',
                  }}>
                  Ansvar nå: {avvik.ansvarlig}
                </span>
              </td>
            </tr>
            <tr key='tr'>
              <td colSpan={2}>
                {' '}
                {avvik.publiserInnbyggerAvvikLukket && (
                  <span
                   /*  title='Klikk for å fjerne'
                    onClick={() => setJaNeiSkalLukkesOpen(true)} */
                    className='badge badge-warning'>
                    Skal lukkes
                  </span>
                )}
              </td>
              <td></td>
              <td colSpan={2}>
                {avvik.publiserInnbyggerDialogKlar && (
                  <span
                    style={{ marginRight: '10px' }}
                    className='badge badge-success pull-right'>
                    Klar til publ.
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  ////////////////// EDITOR /////////////////////////////////////////
  const settMottakerTilbake = (txt) => {
    let ff = stringBetweenPluss('<!-- mott1 -->', '<!-- mott2 -->', txt);
    let gg = '#mottaker# ';
    txt = txt.replaceAll(ff, gg);
    return txt;
  };

  const settGulForMottaker = (txt) => {  //WOX
    let ff = getMottakerVarselEierDialog();
    let gg = '#mottaker# ';
    txt = txt.replaceAll(gg, ff);
    return txt;
  };

  const LagreDokument = () => {
       //Erstatt gull tekst #mottaker
       
    currentInnlegg.tekst = settMottakerTilbake(model);

 
    if (currentInnlegg.dialogId === 'new') {
      delete currentInnlegg.dialogId;
    }

    if (currentInnlegg.dialogId) {
      //endre
      endreInnbyggerdialogAvvik(currentInnlegg, dokumentEndret);
    } else {
      //ny
      if (currentInnlegg) {
        nyInnbyggerDialogAvvik(currentInnlegg, syncAvvikListeGuid);
      } else {
        alert('Feil ved lagre innlegg.. Innlegg undefined.');
      }
    }
    dialogTekstLagret();
  };

  const dialogTekstLagret = (oppdater) => {};

  const dokumentEndret = (err, data) => {
    setDokumentHarBlittEndret(false);
    if (oppdaterer) {
      oppdaterAvvikListe(data);
    } else {
      resetEditorUndoRedo();
    }
    setOppdaterer(false);
    // velgAvvik(komAvvikId);
  };

  const slettDokument = () => {
    setSletteOpen(true);
  };

  const klarTilPublisering = () => {
    //Kun mulig å publisere uten tekst dersom 'Skal lukkes'
    if (currentInnlegg.dialogId === 'new' && !valgtAvvik.avvik.publiserInnbyggerAvvikLukket) {
      setVarselNewOpen(true);
    } else {
      if (currentInnlegg.dialogId) {
        let stringDto = { Tekst1: settMottakerTilbake(model), Tekst2: currentInnlegg.dialogId };
        updateInnbyggerDialogPubliseringKlarv2(
          komAvvikId,
          stringDto,
          oppdaterAvvikListe
        );
      }
    }
  };

  const setModelTekst = (tekst, mottaker) => {
    if (mottaker){
      let nyTekst = settGulForMottaker(tekst);  //WOX
      setModel(nyTekst);
    } else {
      setModel(tekst);
    }
    //Ikke vis lagring ved første endring av model/text
    if (modelInitiert) {
      setDokumentHarBlittEndret(true);
    }
  };

  const resetEditorUndoRedo = () => {
    if (!froalaRef.current) {
      return;
    }
    //undostack
    froalaRef.current.editor.editor.undo_stack = [];
    froalaRef.current.editor.editor.undo_index = [];
    froalaRef.current.editor.editor.undo.reset();
    //  froalaRef.current.editor.editor.undo.saveStep();
    froalaRef.current.editor.editor.events.focus();
  };


  const afterUndoRedo = () => {
    if (model === currentInnlegg.tekst) {
      setDokumentHarBlittEndret(false);
      resetEditorUndoRedo();
    }
  };

  const oppdaterKommentar = () => {
    promisifyApiCall(getPublisertAvvikDialogOppfolging)(
      valgtAvvik.avvik.id,
      valgtAvvik.avvik.objektReferanseType
    ).then((data) => {
      setKommentarer(data.avvik.kommentarer);
    });
    setKommentarHarBlittEndret(false);
  };

  //******************' 2 knapper

  const nyttSvar = () => {
    let model = malInfo.modelStyle;
    setModel(model);

    let currentInnlegg = {
      publisert: false,
      avvikId: komAvvikId,
      avsender: 'BF',
      adresse: adresse,
      eierkontakt: null,
      datoPublisert: moment(),
      tekst: model,
      dialogId: 'new',
    };
    setCurrentInnlegg(currentInnlegg);
    setEditorSynlig(true);
  };

  const nyttSvarOgLukk = () => {

    let model = malInfo.modelStyle;
    setModel(model);
    getContextAvvikLukkArsaker(nyttSvarOgLukk2);
   
  };

  const nyttSvarOgLukk2 = (arsaker) => {
    
    setArsaker(arsaker.arsak);
    setTimeout(() => {
      if (arsaker) {
        if (arsaker.arsak.length) {
          setArsakDialogOpen(true);
        } else {
          onVelgIngenArsak();
        }
      } else {
        onVelgIngenArsak();
      }
    }, 1000);
  };

  const onVelgIngenArsak = () => {
    setArsakDialogOpen(false);
    endreArsak(null);
  };

  const onVelgArsak = (arsak) => {
    setArsakDialogOpen(false);
    endreArsak(arsak);
  };

  const endreArsak = (arsak) => {
    let ar = null;
    valgtAvvik.avvik.publiserInnbyggerAvvikLukket = true;
    if (arsak) {
      valgtAvvik.avvik.publiserInnbyggerAvvikLukketArsakId = arsak.id;
      ar = arsak.id;
    } else {
      valgtAvvik.avvik.publiserInnbyggerAvvikLukketArsakId = null;
      ar = null;
    }

    updateInnbyggerdialogpubliseringklarlukk(komAvvikId, ar, etterKlarLukk);
  };

  const etterKlarLukk = (err, data) => {
    if (data) {
      let a = avvikListe.find((obj) => obj.id === komAvvikId);
      a.publiserInnbyggerAvvikLukket = true;
      let currentInnlegg = {
        publisert: false,
        avvikId: komAvvikId,
        avsender: 'BF',
        adresse: adresse,
        eierkontakt: null,
        datoPublisert: moment(),
        tekst: model,
        dialogId: 'new',
      };
      setCurrentInnlegg(currentInnlegg);
      setEditorSynlig(true);
      setDokumentHarBlittEndret(false);
    }
  };

  const getKommentarComponent = () => {
    return (
      <Kommentar
        ref={komRef}
        avvikId={komAvvikId}
        minOppgave={null}
        kommentarer={kommentarer}
        bruker={brukerNavn}
        screenModus={screenModus}
        parent='C'
        oppdaterParent={oppdaterKommentar}></Kommentar>
    );
  };

  //************************************* Dokument *******************************************
  const malHentet = () => {
    setDokumentHarBlittEndret(true);
  };

  const getEditor = () => {
    return (
      <Editor
        minHeight={'540px'}
        ref={froalaRef}
        model={model}
        handleModelChange={(val) => setModelTekst(val, false)}
        ikkeFlettModus={false}
        bruksenhetId={bruksenhetId}
        avvikId={komAvvikId}
        bruksenhetIdsTilknyttet={bruksenhetIds}
        afterUndoRedo={(ed) => afterUndoRedo(ed)}
        tiltakTypeId={valgtAvvik.avvik.tiltakTypeId}
        skalLukkes={valgtAvvik.avvik.publiserInnbyggerAvvikLukket}
        parent={'UB'}
        malHentet={malHentet}
        fletteOptions={editorFletteOptions}
      />
    );
  };

  const resultatFjernPublJaNei = (svar) => {
    setJaNeiFjernPublOpen(false);
    if (svar === 'ja') {
      updateInnbyggerDialogUnderArbeid(komAvvikId, oppdaterAvvikListe);
    }
  };

  const resultatFjernSkalLukkes = (svar) => {
    setJaNeiSkalLukkesOpen(false);
    if (svar === 'ja') {
      updateInnbyggerdialogpubliseringFjernklarlukk(
        komAvvikId,
        syncAvvikListeFjernSkalLukkes
      );
     
    }
  };

  const syncAvvikListeFjernSkalLukkes = (err, data) => {
    if (data) {
      let a = avvikListe.find((obj) => obj.id === komAvvikId);
      a.publiserInnbyggerAvvikLukket = false;
      currentInnlegg.publiserInnbyggerAvvikLukket = false;
      velgAvvik(a);
    }
  };

  const syncAvvikListeGuid = (err, avvik) => {
    let a = avvikListe.find((obj) => obj.id === avvik.avvikId);
    a.dialogId = avvik.dialogId;
    currentInnlegg.dialogId = avvik.dialogId;
    setDokumentHarBlittEndret(false);
  };

  const oppdaterAvvikListe = (err, data) => {
    let r = komAvvikId;
    setOppdaterer(true);
    hentData();

    setTimeout(() => {
      if (avvikListe.length) {
        let a = avvikListe.find((obj) => obj.id === r);
        if (a) {
          velgAvvik(a);
          //scrollTilAvvik(true);
        }
      }
      setOppdaterer(false);
    }, 2000);
  };

  const resultatSletteJaNei = (svar) => {
    setSletteOpen(false);
    if (svar === 'ja') {
      setOppdaterer(true);
      setModel('');
      if (currentInnlegg) {
        let obj = { dialogId: currentInnlegg.dialogId, avvikId: komAvvikId };
        slettInnbyggerdialogAvvikDialog(obj, innleggSlettet);
      } else {
        setEditorSynlig(false);
      }
      setTimeout(() => {
        if (avvikListe.length) {
          let a = avvikListe.find((obj) => obj.id === komAvvikId);
          if (a) {
            a.publiserInnbyggerAvvikLukket = false;
            a.PubliserInnbyggerDialogKlar  = false;
            a.PubliserInnbyggerAvvikLukketArsakId  = null;
            a.sidemannGodkjent = false;
            velgAvvik(a);
          }
        }
        setOppdaterer(false);
      }, 2000);
    }
  };

  const innleggSlettet = (err, data) => {
    setEditorSynlig(false);
  };

  const afterSlettBilde = (bilde) => {
    let filer = valgtAvvik.avvik.filer;
    for (var i = 0; i < filer.length; i++) {
      if (filer[i].filnavn === bilde.id) {
        filer[i].slett = true;
        break;
      }
    }
    let av = { ...valgtAvvik };
    av.avvik.filer = filer;
    setValgtAvvik(av);
    setTimeout(() => {
      updateAvvik(av.avvik.id, av.avvik, bilderLagred);
    }, 500);
  };

  const afterUploadBilde = (bilde) => {
    let nyttBilde = {
      bruksenhetId: bruksenhetId,
      id: '0',
      filnavn: bilde.tekst,
      filtittel: 'Avvik',
      ny: true,
      objektReferanseId: valgtAvvik.avvik.objektReferanseId,
      objektReferanseType: valgtAvvik.avvik.objektReferanseType,
      slett: false,
    };

    let filer = valgtAvvik.avvik.filer;
    filer.push(nyttBilde);
    let av = { ...valgtAvvik };
    av.avvik.filer = filer;
    setTimeout(() => {
      updateAvvik(av.avvik.id, av.avvik, bilderLagred);
    }, 500);
  };

  const bilderLagred = (err, data) => {
    let av = { ...valgtAvvik };
    av.avvik = data;
    setValgtAvvik(av);
  };



  return (
    <div
      style={{
        margin: '4px',
        padding: '10px',
      }}>
      {!valgtAvvik || !valgtAvvik.avvik ? (
        <>
          <Spinner className='h4' />
        </>
      ) : (
        <>
          <table>
            <tbody>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ verticalAlign: 'top' }}>
                  <h3>
                    {' '}
                    Under behandling: {adresse}
                    {fyringsForbud && (
                      <span
                        style={{ fontSize: '16px', marginLeft: '4px' }}
                        className='badge badge-error'>
                        Fyringsforbud !
                      </span>
                    )}
                  </h3>
                </td>
                <td style={{ width: '14px' }}></td>
                <td style={{ verticalAlign: 'top' }}>
                  <span style={{ marginLeft: '20px' }}>
                    {hideKontakter && (
                      <span>
                        <button
                          style={{ marginTop: '16px' }}
                          type='button'
                          onClick={visKontakt}
                          className='btn btn-link'>
                          {eierkontaktTekst}
                        </button>
                      </span>
                    )}
                    {!hideKontakter && <span>{getEierKontakter()}</span>}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          {/* Horizontal scroll med avvik-blokk */}
          {screenModus === 'small' && (
            <div className='scrollmenu'>
              {avvikListe.map((avvik, index) => {
                return getAvvikBox(avvik);
              })}
              <br />

              <br />
            </div>
          )}

          {/* Header med knapp, saksbeh, adresse og antall avvik */}
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td rowSpan='2' style={{ width: '210px' }}>
                  {/*  Hvilken knapp skal brukes? */}
                  {getGreenKnapp()}
                </td>
                <td
                  style={{
                    paddingLeft: '30px',
                  }}>
                  <>
                    <span>
                      {valgtAvvik.avvik.saksbehandler ? (
                        <span>
                          <span>{'Saksbeh:'}</span>

                          <span style={{ paddingLeft: '14px' }}>
                            {valgtAvvik.avvik.saksbehandler}
                            {valgtAvvik.avvik.ansvarlig === 'Saksbehandler' && (
                              <span
                                style={
                                  ansvarFeil
                                    ? { color: '#C43835', paddingLeft: '4px' }
                                    : { color: 'black', paddingLeft: '4px' }
                                }>
                                {'( Ansvarlig )'}
                              </span>
                            )}
                          </span>
                        </span>
                      ) : (
                        <span>'Saksbeh.'</span>
                      )}
                    </span>
                  </>
                </td>

                <td rowSpan='2' style={{ flex: '1', display: 'inlineblock' }}>
                  <span style={{ float: 'right' }}>
                    {screenModus !== 'small' && (
                      <BFButtonGroup
                        buttons={['Matrikkelinfo', 'Situasjon']}
                        afterClick={buttonValgt}
                        vertikal={false}></BFButtonGroup>
                    )}
                    {screenModus === 'small' && (
                      <div style={{ width: '130px' }}>
                        <NkaFormElementDropdown
                          handleValueChange={buttonValgt}
                          value={0}
                          values={knapper}
                          other={{ placeholder: 'Vis..   ' }}
                        />
                      </div>
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    paddingLeft: '30px',
                  }}>
                  <>
                    {valgtAvvik.avvik.ansvarlig === 'Eier' && (
                      <>
                        <br />
                        <span style={{ fontStyle: 'italic' }}>
                          Ansvarlig nå: Eier
                        </span>
                      </>
                    )}
                  </>
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{ display: 'flex' }}>
            {screenModus !== 'small' && (
              <div style={{ marginTop: '11px' }}>
                {/* Vertikal scroll med avvik-blokk */}
                <br />
                <label style={{ marginLeft: '4px' }}>Avviksliste:</label>
                <div
                  className='scrollmenu'
                  style={{ height: avvikScrollHeight }}>
                  {avvikListe.map((avvik, index) => {
                    return (
                      <React.Fragment key={'RF' + index}>
                        {getAvvikBox(avvik)} <br />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
            <div
              style={{
                marginLeft: '4px',
                paddingTop: '6px',
                width: 'auto',
              }}>
              <BFButtonGroup
                buttons={[
                  'Dokument',
                  <div>
                    Avviksdialog{' '}
                    <span className='badge'>
                      {dialogRader ? dialogRader.length : 0}
                    </span>
                  </div>,
                  'Avviksdetaljer',
                  <div>
                    Bilder{' '}
                    <span className='badge'>
                      {valgtAvvik.avvik.filer
                        ? valgtAvvik.avvik.filer.length
                        : 0}
                    </span>
                  </div>,

                  /*   'Bilder/Vedlegg', */
                ]}
                afterClick={buttonFaneValgt}
                select={true}
                buttonWidth={'180px'}
                vertikal={false}></BFButtonGroup>

              <br />
              {/* ************* DOK SMALL******************* */}
              {fane === 'Dok' && screenModus === 'small' && (
                <>
                  <button
                    onClick={() =>
                      linkTekst === 'Vis kommentar'
                        ? setLinkTekst('Skul kommentar')
                        : setLinkTekst('Vis kommentar')
                    }
                    type='button'
                    data-toggle='collapse'
                    data-target='#kommentar'
                    className='btn btn-link'>
                    {linkTekst}
                  </button>
                  <br />
                  <div id='kommentar' className='collapse'>
                    {getKommentarComponent()}
                    <br />
                    <br />
                  </div>
                  {!editorSynlig && (
                    <span>
                      {!ansvarFeil && (
                        <>
                          <BFButton
                            name={'NyttSvar'}
                            buttonLabel={['Nytt svar']}
                            farget={true}
                            buttonWidth={'180px'}
                            afterClick={nyttSvar}></BFButton>
                          <BFButton
                            disabled={
                              valgtAvvik.avvik.publiserInnbyggerAvvikLukket ===
                              true
                            }
                            name={'LukkAvvik'}
                            buttonLabel={['Lukk avvik']}
                            farget={false}
                            buttonWidth={'180px'}
                            afterClick={nyttSvarOgLukk}></BFButton>
                        </>
                      )}
                      {ansvarFeil && (
                        <span style={{ marginLeft: '40px', color: '#C43835' }}>
                          Du står ikke som "Ansvarlig", derfor har du begrenset
                          adgang.
                        </span>
                      )}
                    </span>
                  )}
                  {editorSynlig && (
                    <span>

                      {!ansvarFeil && (
                        <>
                          <span>
                            <BFButton
                              name={'LagreDokument'}
                              disabled={!dokumentHarBlittEndret}
                              buttonLabel={['Lagre']}
                              farget={true}
                              buttonWidth={'88px'}
                              afterClick={LagreDokument}></BFButton>
                            <BFButton
                              name={'SlettDokument'}
                              disabled={false}
                              buttonLabel={['Slett']}
                              farget={false}
                              buttonWidth={'88px'}
                              afterClick={slettDokument}></BFButton>
                          </span>
                         
                          <span>
                            {valgtAvvik.avvik.publiserInnbyggerDialogKlar && (
                              <BFButton
                                name={'FjernKlarTilPubl'}
                                disabled={dokumentHarBlittEndret}
                                buttonLabel={['Fjern Klar til publ.']}
                                farget={false}
                                buttonWidth={'180px'}
                                afterClick={() =>
                                  setJaNeiFjernPublOpen(true)
                                }></BFButton>
                            )}
                            {!valgtAvvik.avvik.publiserInnbyggerDialogKlar && (
                              <BFButton
                                name={'KlarTilPubl'}
                                disabled={dokumentHarBlittEndret}
                                buttonLabel={['Klar til publisering']}
                                farget={false}
                                buttonWidth={'180px'}
                                afterClick={klarTilPublisering}></BFButton>
                            )}
                          </span>
                        </>
                      )}
                      {ansvarFeil && (
                        <span style={{ marginLeft: '40px', color: '#C43835' }}>
                          Du står ikke som "Ansvarlig", derfor har du begrenset
                          adgang.
                        </span>
                      )}
                      ;{/* Send til saksbeh. button */}
                    
                    </span>
                  )}

                  <br />

                  {editorSynlig && (
                    <div>
                      {oppdaterer && (
                        <>
                          <Spinner className='h4' />
                        </>
                      )}
                      {!oppdaterer && <>{getEditor()}</>}
                    </div>
                  )}
                </>
              )}

              {/* ************* DOK STOR ******************* */}
              {fane === 'Dok' && screenModus !== 'small' && (
                <>
                  <div id='parentX' style={{ marginTop: '20px' }}>
                    <div id='dokX'>
                      {' '}
                      {!editorSynlig && (
                        <span>
                          {!ansvarFeil && (
                            <>
                              <BFButton
                                name={'NyttSvar'}
                                buttonLabel={['Nytt svar']}
                                farget={true}
                                buttonWidth={'180px'}
                                afterClick={nyttSvar}></BFButton>
                              <BFButton
                                disabled={
                                  valgtAvvik.avvik
                                    .publiserInnbyggerAvvikLukket === true
                                }
                                name={'LukkAvvik'}
                                buttonLabel={['Lukk avvik']}
                                farget={false}
                                buttonWidth={'180px'}
                                afterClick={nyttSvarOgLukk}></BFButton>
                            </>
                          )}
                          {ansvarFeil && (
                            <span
                              style={{ marginLeft: '40px', color: '#C43835' }}>
                              Du står ikke som "Ansvarlig", derfor har du
                              begrenset adgang.
                            </span>
                          )}
                        </span>
                      )}
                      {editorSynlig && (
                        <span>
                          {!ansvarFeil && (
                            <>
                              <span>
                                <BFButton
                                  name={'LagreDokument'}
                                  disabled={!dokumentHarBlittEndret}
                                  buttonLabel={['Lagre']}
                                  farget={true}
                                  buttonWidth={'88px'}
                                  afterClick={LagreDokument}></BFButton>
                                <BFButton
                                  name={'SlettDokument'}
                                  buttonLabel={['Slett']}
                                  farget={false}
                                  buttonWidth={'88px'}
                                  afterClick={slettDokument}></BFButton>
                                <span>
                                  {valgtAvvik.avvik
                                    .publiserInnbyggerDialogKlar && (
                                    <BFButton
                                      name={'FjernKlarTilPubl'}
                                      disabled={dokumentHarBlittEndret}
                                      buttonLabel={['Fjern Klar til publ.']}
                                      farget={false}
                                      buttonWidth={'180px'}
                                      afterClick={() =>
                                        setJaNeiFjernPublOpen(true)
                                      }></BFButton>
                                  )}
                                  {!valgtAvvik.avvik
                                    .publiserInnbyggerDialogKlar && (
                                    <BFButton
                                      name={'KlarTilPubl'}
                                      disabled={dokumentHarBlittEndret}
                                      buttonLabel={['Klar til publisering']}
                                      farget={false}
                                      buttonWidth={'180px'}
                                      afterClick={
                                        klarTilPublisering
                                      }></BFButton>
                                  )}
                                </span>
                              </span>
                              {/* Send til saksbeh. button */}
                              {isReadOnly && (
                                <BFButton
                                  name={'OpenRedigering'}
                                  buttonLabel={'Rediger'}
                                  farget={false}
                                  buttonWidth={'180px'}
                                  afterClick={() => {
                                    setSkrivebeskyttetOpen(true);
                                  }}></BFButton>
                              )}
                            </>
                          )}
                          {ansvarFeil && (
                            <span
                              style={{ marginLeft: '40px', color: '#C43835' }}>
                              Du står ikke som "Ansvarlig", derfor har du
                              begrenset adgang.
                            </span>
                          )}
                        </span>
                      )}
                      <br />
                      {editorSynlig && (
                        <div>
                          {oppdaterer && (
                            <>
                              <Spinner className='h4' />
                            </>
                          )}
                          {!oppdaterer && <>{getEditor()}</>}
                        </div>
                      )}
                    </div>
                    <div id='komX'> {getKommentarComponent()}</div>
                 {/*    {editorSynlig && (
                      <div id='komX'> {getKommentarComponent()}</div>
                    )} */}
                  </div>
                </>
              )}

              {fane === 'Inn' && (
                <div style={{ marginTop: '8px' }}>
                  <DialogRader
                    dialogRader={dialogRader}
                    avvikType={getAvvikType()}></DialogRader>
                </div>
              )}

              {fane === 'Avv' && (
                <div>
                  <UnderBehandlingAvvik
                    endreAvvik={endreAvvik}
                    avvik={valgtAvvik}></UnderBehandlingAvvik>
                </div>
              )}

              {fane === 'Bil' && (
                <Bilder
                  ref={bilderRef}
                  avvikType={getAvvikType()}
                  afterSlett={afterSlettBilde}
                  afterUpload={afterUploadBilde}
                  filer={valgtAvvik.avvik.filer}></Bilder>
              )}
            </div>
          </div>
          {/*  */}

          <br />
        </>
      )}

      {/* *************************** Dialoger *********************** */}

      {propsAvvik && (
        <EnhetModal
          isOpen={modalSituasjonOpen}
          onClose={skjulSituasjon}
          itemId={bruksenhetId}
          itemAdresse={propsAvvik.bruksenhetAdresse}
          /*     matrikkelenhetId={dialogData.matrikkelenhetId}
              bruksenhetIdMa={dialogData.matrikkelenhetId} 
               eiendom={dialogData.eiendom}  //Burde kanskje ikke være med? Ifm. feil Bronnøy..*/
          fraFiktive={false}
        />
      )}

      {propsAvvik && (
        <NKModal size='medium' isOpen={modalMatrikkelOpen}>
          <div className='modal-content'>
            <div className='modal-header'>
              <button className='close' type='button' onClick={skjulMatrikkel}>
                <span>x</span>
                <span className='sr-only'>Lukk</span>
              </button>
              <h4 className='modal-title'>
                {' '}
                {'Matrikkelinfo - ' + propsAvvik.bruksenhetAdresse}{' '}
              </h4>
            </div>
            <div className='modal-body'>
              <Matrikkelinfo
                onClose={skjulMatrikkel}
                id={matrikkelId}
                bygningsNr={bygningsNr}
              />
            </div>
          </div>

          {/* Vi trenger ikke en footer */}
        </NKModal>
      )}

      {valgtAvvik && valgtAvvik.avvik && (
        <JaNeiDialog
          tittel='Slette?'
          tekst='Dokumentet skal slettes.. '
          tekst2={
            valgtAvvik.avvik.publiserInnbyggerAvvikLukket
              ? '..og "Skal lukkes" fjernes. Ok?'
              : 'OK?'
          }
          isOpen={sletteOpen}
          handleClose={resultatSletteJaNei}
          visClose={false}
        />
      )}

      <JaNeiDialog
        tittel='Fjern?'
        tekst='"Skal lukkes" skal fjernes '
        tekst2='Ok?'
        isOpen={jaNeiSkalLukkesOpen}
        handleClose={resultatFjernSkalLukkes}
        visClose={false}
      />


      <JaNeiDialog
        tittel='Fjern?'
        tekst='"Klar publisering" skal fjernes '
        tekst2='Ok?'
        isOpen={jaNeiFjernPublOpen}
        handleClose={resultatFjernPublJaNei}
        visClose={false}
      />

      {propsAvvik && (
        <NKModal size='medium' isOpen={velgBruksenhetOpen}>
          <DeltAvvikValgModal
            onClose={() => setVelgBruksenhetOpen(false)}
            bruksenheter={bruksenhetIds}
            onOk={bruksenhetValgt}
          />
        </NKModal>
      )}

      {propsAvvik && (
        <NKModal size='medium' isOpen={arsakDialogOpen}>
          <VelgArsakDialog
            onClose={onVelgIngenArsak}
            arsaker={arsaker}
            onVelgArsak={onVelgArsak}
            onIkkeVelgArsak={onVelgIngenArsak}
          />
        </NKModal>
      )}

      {propsAvvik && (
        <NKModal size='large' isOpen={sendAvvikTilEierOpen}>
          <SendAvvikTilEier
            onClose={() => setSendAvvikTilEierOpen(false)}
            avvikListe={avvikListe}
            bruksenhetId={bruksenhetId}
            onOk={avvikHarBlittSendtTilEier}
          />
        </NKModal>
      )}

      <OKDialog
        tittel={'Du kan ikke velge et annet avvik'}
        melding={
          'Du må lagre eller angre endringene i dokumentet eller kommentar først.'
        }
        isOpen={kanIkkeVelgeOpen}
        onClose={() => ikkeVelgAvvik()}
        onOk={() => ikkeVelgAvvik()}
      />

      <OKDialog
        tittel={'Ingenting å publisere'}
        melding={'Du må skrive og lagre en tekst først.'}
        isOpen={VarselNewOpen}
        onClose={() => setVarselNewOpen(false)}
        onOk={() => setVarselNewOpen(false)}
      />


    </div>
  );
}

export default MineOppgaverUnderBehandling;
