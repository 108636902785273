import React from "react";
import NKModal from "components/Dialogs/NKModal.jsx";
import NKIcon from "nka-icons";
import _ from "underscore";
import { updateSaksbehandlerForApne } from 'api/avvikApi';

class EndreSaksbehandlerAvvik extends React.Component {
  state = { isOpen: true, personer: [] };

  componentDidMount() {
      let oppdatertliste = this.props.personer.filter(x => x.aktiv === true && x.id !== this.props.person.id);
      if (oppdatertliste.length > 0) {
        this.setState({ nyId: oppdatertliste[0].id });
      }
      this.setState({ personer: oppdatertliste });
  } 

  saksbehandlerChange = (val) => {
    this.setState({ nyId: val.target.value });
  };

  oppdaterSaksbehandler = () => {
    updateSaksbehandlerForApne(this.props.person.id, this.state.nyId, this.saksbehandlerUpdated );
  }

  saksbehandlerUpdated = (err, res) => {
      this.props.oppdater();
  }

  render() {
    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button"
            >
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Endre saksbehandler</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-sm-12">
                  <div style={{ marginLeft: '10px', marginRight: '10px'}}>
                Merk: Brukeren du vil sette historisk/innaktiv, er tilknyttet
                som saksbehandler for åpne avvik.
               <br/>
                Hvis du vil følge opp disse avvikene først, avslutter du denne
                dialogen og går til oppfølgingsbildet.
                <br/>
                Hvis du ønsker å overføre alle åpne avvik til en annen aktiv
                bruker, velger du ny saksbehandler fra nedtrekksmenyen og lagrer. Alle
                åpne avvik blir da flyttet til denne brukeren for videre
                oppfølging.
                </div>
                <br />
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-sm-4">
                <label>Ny saksbehandler</label>
              </div>
              <div className="col-sm-8 form-group">
                <select
                  className="form-control"
                  id="type"
                  onChange={this.saksbehandlerChange}
                >
                  {_.map(this.state.personer, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              style={{ margin: "2px", width: "50px", height: "40px" }}
              className="btn btn-primary pull-right"
              onClick={this.oppdaterSaksbehandler}
            >
              OK
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default EndreSaksbehandlerAvvik;
