/* eslint-disable radix */
import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';
import * as getIcon from '../geticon.js';
import GjMarkerClusterLayer from './GjMarkerClusterLayer';
import '../EnhetPopup.css';
import { debugFactory } from 'utils';
import _ from 'lodash';
import { setKartContext, getKartContext } from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:CCC');

var parentKart = null;

class GjMarkerClusterLayerControl extends Component {
  state = {
    initiert: false,
    gjennomfortIcon: null,
    tidspunkt: false,
    markermodus: 'modus_icon'
  };

  componentDidMount = () => {
    this.getContext();
  };

  componentDidUpdate = () => {
    parentKart = this.props.kart;
    //Ikke start før parentKart har blitt satt
    if (parentKart != null) {
      if (!this.state.initiert) {
        this.setState({ initiert: true }, this.start());
      }
    }
  };

  start = () => {
    setTimeout(() => this.setClusterBounds(), 200);
  };

  componentWillUnmount = () => {
    setKartContext('gjPluss', null, null, null, null, this.state.markermodus, this.state.tidspunkt );
  };

  getContext = () => {
    if (this.props.contextOn) {
      let kartContext = getKartContext('gjPlussj');
      debug('ZOPP', kartContext);
      if (kartContext && kartContext.markerModus ) {
        if (kartContext.markerModus !== 'modus_icon'){
          this.setState({ tidspunkt: !this.state.tidspunkt });
          this.setState({ markermodus: kartContext.markerModus });
        }
      }
    }
  };

 setClusterBounds = () => {
    let layer = this.refs.clusterLayer;
    if (parentKart !== undefined && parentKart !== null) {
      if (layer !== undefined && layer !== null) {
        if (layer.getTheBounds() !== null) {
          parentKart.fitBounds(layer.getTheBounds(), { padding: [10, 10] });
        }
      }
    }
  };

  getEnhetTooltipGj = data => {
    const {
      adresse,
      tiltakBruksenhet,
      tiltakRoyklop,
      eiendom,
      datotidvennlig
    } = data;
    let tiltak = '';
    if (tiltakBruksenhet) {
      tiltak = data.tiltakBruksenhet;
    }
    if (tiltakRoyklop) {
      if (tiltak === '') {
        tiltak = data.tiltakRoyklop;
      } else {
        tiltak = tiltak + ',' + data.tiltakRoyklop;
      }
    }

    let status = '';
    var index1 = '';
    var utf = '';
    var til = '';
    var tiddato = datotidvennlig;
    if (data.tiltakBrukenhetStatus) {
      index1 = data.tiltakBrukenhetStatus.indexOf('/');
      utf =
        parseInt(data.tiltakBrukenhetStatus.substring(0, index1), 10) +
        data.antallLopPaaAlisteBesokt;
      til =
        parseInt(
          data.tiltakBrukenhetStatus.substring(
            index1 + 1,
            data.tiltakBrukenhetStatus.length
          ),
          10
        ) + data.antallLopPaaAliste;
      status = utf + '/' + til;
    }
    status = 'Gjennomført: ' + status;

    var ts = adresse + '\n' + eiendom + tiltak + '\n' + tiddato + '\n' + status;
    return ts;
  };

  goVelg = () => {
    this.props.tilSituasjon();
  };

  getMarkerWithCallback = ({ data }) => {
    return (
      <div className='opp50'>
        <label> {data.adresse} </label>
        <br />
        <button onClick={() => this.goVelg()} className='btn btn-success w100'>
          Velg
        </button>
      </div>
    );
  };

  onClickedGj = e => {
    let s = this.getEnhetTooltipGj(e);
    this.props.onClickedGj(s, e);
  };

  toggleBesoekInfo = e => {
    this.setState({ tidspunkt: !this.state.tidspunkt });
    let modus = this.state.markermodus;
    switch (modus) {
      case 'modus_icon':
        this.setState({ markermodus: 'modus_nummer' });
        break;
      case 'modus_nummer':
        this.setState({ markermodus: 'modus_tid' });
        break;
      case 'modus_tid':
        this.setState({ markermodus: 'modus_icon' });
        break;
      default:
        this.setState({ markermodus: 'modus_icon' });
    }
  };

  render() {
    let latLngAccessor = enhet => [
      enhet.lokasjonLatitude,
      enhet.lokasjonLongitude,
      enhet.adresse
    ];
    let position = 'topright';

    if (this.props.left === true) {
      position = 'topleft';
    }

    return (
      <div>
        <GjMarkerClusterLayer
          ref='clusterLayer'
          enheter={this.props.enheter}
          data={[]} 
          latLngAccessor={latLngAccessor}
          markerIcon={getIcon.redIcon}
          markerPopup={this.getMarkerWithCallback}
          getMarkerTooltip={this.getEnhetTooltipGj}
          onClickedGj={this.onClickedGj}
          tidspunkt={this.state.tidspunkt}
          markermodus={this.state.markermodus}
        />

        <Control position={position}>
          <div>
            <button
              title='Sentrer alle enheter'
              type='button'
              className={'btnLag'}
              onClick={this.setClusterBounds}>
              <NKIcon icon='avslutt-fullskjerm' color='nk-black' />
            </button>
            <br />
            <button
              title='Vis tidspunkt'
              type='button'
              className={'btnLag'}
              onClick={this.toggleBesoekInfo}>
              <NKIcon icon='info-transparent' color='nk-black' />
            </button>
          </div>
        </Control>
      </div>
    );
  }
}

export default GjMarkerClusterLayerControl;
