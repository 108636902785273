import React, { Component } from 'react';
import { getContextcontextRoyklopKombi, getContextcontextIldstedKombi } from 'context/contextOppslag.js';
import { debugFactory } from 'utils';
import _ from 'lodash';
import DialogStore from 'lib/DialogStore';
import SoekDialog2Col from 'components/Dialogs/SoekDialog2Col.jsx';
const debug = debugFactory('nk:prikProdusentModell');

const modalDialogs = {
  SoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <SoekDialog2Col
      tittel={'Velg produsent og modell'}
      liste={dialogData.liste}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose} 
      onValgtModell={owner.onValgtModell}
    />
  )
};

class PrikProdusentModell extends Component {
  constructor(props) {
    super(props);
    this.state = { tekst: '',  liste: [] } ;
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    if (!this.state.liste.length) {
      if (this.props.type === 'Royklop'){
       
      /*  Fungerer!!  
             getContextcontextRoyklopKombi(function(liste) {
             debug('XXX', liste);
        }); */

        
        getContextcontextRoyklopKombi(this.gotKombi);
      } else {
        getContextcontextIldstedKombi(this.gotKombi);
      }
    }
  }


  gotKombi = (liste) => {
    this.setState({ liste });
    if (this.props.value) {
      this.getTekst(liste, this.props.value);
    }
  };

getTekst = (list, id) => {
    let item = _.find(list, {
      modellId: id,
    });
    if (item) {
      this.setState({ tekst: item.produsent + ' / ' + item.modell });
    } 
  };

onValgtModell= (item) => {
    this.dialogs.closeDialog();
    this.setState({ tekst: item.produsent + ' / ' + item.modell, dokumentasjon: item.dokumentasjon });
    this.props.onValgtModell(item);
  };

  openSoekDialog = () => {
    this.dialogs.setOpenDialog('SoekDialog',  {
      liste: this.state.liste,
    });
  };

  render() {
  return (
    <>
      <div
        onClick={this.openSoekDialog}
        style={{
          paddingLeft: '6px',
          paddingTop: '2px',
          borderColor: 'lightgray',
          borderWidth: '1px',
          borderStyle: 'solid',
          verticalAlign: 'middle',
          minHeight: '34px',
          lineHeight: '24px',
          float: 'left',
          backgroundColor: 'white',
          width: this.props.width,
          fontSize: '14px',
          cursor: 'pointer'
        }}>
        {this.state.tekst}
      </div>
      <div style={{ marginLeft: '4px;', float: 'left' }}>
        <button
          onClick={this.openSoekDialog}
          style={{ fontWeight: 'bold', fontSize: '16px', height: '34px' }}>
          ...
        </button>
        {this.dialogs.renderOpenDialog()}
      </div>
    </>
  );
};
};

export default PrikProdusentModell;
