import * as React from 'react';
import * as moment from 'moment';
import { PropTypes } from 'prop-types';

import {
  SearchkitComponent,
  SearchkitComponentProps,
  RenderComponentType,
  RenderComponentPropType,
  renderComponent,
  FieldOptions,
  Panel
} from 'searchkit';

import {
  DateRangeAccessor,
} from './DateRangeAccessor';


import {
  defaults,
  map,
  get
} from 'lodash';

import { debugFactory } from 'utils';

const debug = debugFactory('nk:components:search:daterangefilter');

// For testing without a calendar component. Accepts date math.
export class DateRangeFilterInput extends SearchkitComponent<any, any> {
  refs: {
    [key: string]: any;
    dateFromInput: any;
    dateToInput: any;
  }

  handleDateFinished = (event) => {
    const { onFinished } = this.props;
    const newState = {
      fromDate: this.refs.dateFromInput.value,
      toDate: this.refs.dateToInput.value
    };
    onFinished(newState);
  }

  render() {
    const { fromDate, toDate } = this.props;

    return (
      <div>
        <input id="date-from" ref="dateFromInput" defaultValue={fromDate} />
        <input id="date-to" ref="dateToInput" defaultValue={toDate} />
        <button id="date-submit" onClick={this.handleDateFinished}>OK</button>
      </div>
    );
  }
}


export interface DateRangeFilterProps extends SearchkitComponentProps {  
  field:string,
  fromDate?:moment.Moment,
  toDate?:moment.Moment,
  id:string,
  title:string,
  interval?:number,
  containerComponent?: RenderComponentType<any>,
  calendarComponent?: RenderComponentType<any>,
  rangeFormatter?:(count:number)=> number | string,
  fieldOptions?:FieldOptions
}


export class DateRangeFilter extends SearchkitComponent<DateRangeFilterProps, any> {
  accessor:DateRangeAccessor

  static propTypes = defaults({
    fromDate:PropTypes.object,
    toDate:PropTypes.object,
    field:PropTypes.string.isRequired,    
    title:PropTypes.string.isRequired,
    id:PropTypes.string.isRequired,
    containerComponent:RenderComponentPropType,
    calendarComponent:RenderComponentPropType,
    rangeFormatter:PropTypes.func,
    fieldOptions:PropTypes.shape({
      type:PropTypes.oneOf(['embedded', 'nested', 'children']).isRequired,
      options:PropTypes.object
    }),
  }, SearchkitComponent.propTypes)

  static defaultProps = {
    containerComponent: Panel,
    rangeFormatter: (v) => moment(parseInt(''+v)).format('D.M.YYYY')
  }

  constructor(props){
    super(props);     
  }

  defineAccessor() {
    const {
      id,
      title,
      fromDate,
      toDate,
      field,      
      fieldOptions,
      rangeFormatter,
    } = this.props;

    return new DateRangeAccessor(id, {
      id,
      fromDate,
      toDate,
      field,      
      title,
      fieldOptions,
      rangeFormatter,      
      onClearState: this.handleClearState
    });
  }

  handleClearState = () => {    
    
/*     let d = document.getElementsByClassName('sk-input-filter');
    for (let i = 0; i < d.length; i++) {  
      if ((d[i].value !== undefined) && (d[i].value !== '')) {
        console.log();debug('clearfilter 1: ', d[i].value);
        console.log();debug('clearfilter 1: ', d[i].innerHTML);

        d[i].value = '';
        d[i].innerHTML = '';
        d[i].innerText = '';
      }
      
    } */
   
      let obj = { fromDate: undefined, toDate: undefined };
      this.setCalendarState(obj);
      this.fromDateValue = '';
      this.toDateValue = '';
      this.fromDate = '';
      this.toDate = '';
      
      this.accessor && this.accessor.resetState(); 

/*       for (let i = 0; i < d.length; i++) {  
          console.log();debug('clearfilter 2: ', d[i].value);
          console.log();debug('clearfilter 2: ', d[i].innerHTML);
          d[i].value = '';
          d[i].innerHTML = '';

          d[i].innerText = '';
      } */
     
  }

  defineBEMBlocks() {
    let block = this.props.mod || 'sk-date-range-filter';  
    return {
      container: block,
      labels: block + '-value-labels'
    };
  }

  setCalendarState = (newValues) => {
    debug('setCalendarState', {...newValues});
    if (!newValues.fromDate) {    
      this.accessor && this.accessor.resetState();
      debug('setCalendarState:resetState', {...this});
    }
    else {
      debug('setCalendarState:setValue', {...this});
      this.accessor.state = this.accessor && this.accessor.state.setValue(newValues);
    }

  }

  calendarUpdate = (newValues) => {
    this.setCalendarState(newValues);
  }

  calendarUpdateAndSearch = (newValues) => {
    this.calendarUpdate(newValues);
    this.searchkit.performSearch();
  }

  getCalendarComponent() {
    const { calendarComponent } = this.props;
    return (calendarComponent || DateRangeFilterInput);
  }

  render() {
    const { id, title, containerComponent } = this.props;

    return renderComponent(containerComponent, {
      title,
      className: id ? `filter--${id}` : undefined      
    }, this.renderCalendarComponent(this.getCalendarComponent()));
  }

  renderCalendarComponent(component: RenderComponentType<any>) {
    const { fromDate, toDate, rangeFormatter } = this.props;
    const state:{ fromDate?:string, toDate?:string } = this.accessor ? this.accessor.state.getValue() : '';

    return renderComponent(component, {
      fromDate: state.fromDate || fromDate,
      toDate: state.toDate || toDate,
      fromDateValue: get(state, 'fromDate', fromDate),
      toDateValue: get(state, 'toDate', toDate),
      items: this.accessor && this.accessor.getBuckets(),
      onChange: this.calendarUpdate,
      onFinished: this.calendarUpdateAndSearch,
      rangeFormatter
    });
  }

}