import { combineReducers } from 'redux';

import app from './app/reducers';
import planlegging from './planlegging/reducers';
import oppfolging from './oppfolging/reducers';
import gjennomforing from './gjennomforing/reducers';
import innstillinger from './innstillinger/reducers';
import crossSliceReducer from './crossSliceReducer';

const rootReducer = combineReducers({
    app,
    planlegging,
    gjennomforing,
    oppfolging,
    innstillinger,
});

const wrapper = (prevState, action) => {
    let nextState = rootReducer(prevState, action);
    nextState = crossSliceReducer(nextState, action);
  //  debug('wrapper', { prevState, action, nextState });
    return nextState;
};

export default wrapper;