import React from 'react';
import enhetApi from 'api/enhetApi';
import royklopApi from 'api/royklopApi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/nb';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { sjekkBruksenhetRisiko, sjekkRoyklopRisiko } from 'api/risikoApi.js';

var hyppighetListe = null;

const modalDialogs = {
  DatoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Dato"
      melding="Datofeltet må fylles ut."
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  HyppighetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Hyppighet"
      melding="Hyppighet må fylles ut."
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  ),
  AvsluttDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Ny tiltakstype"
      melding="Ingen flere definerte tiltakstyper."
      isOpen={isOpen}
      onOk={owner.avslutt}
      onClose={owner.avslutt}
    />
  )
};

class NyTiltakstype extends React.Component {
  state = {
    breadcrumbname: 'Laster....',
    selectedDate: null,
    id: this.props.location ? this.props.location.state.id : this.props.id,
    historikk: [],
    startDate: moment(Date.now()),
    lagrer: false,
    risikoSjekk: false
  };

  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  };

  componentDidMount() {
    if (this.getType() === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakTypeOppslagdata(this.gotOppslagsdata);
      sjekkBruksenhetRisiko(this.props.id, this.gotRisikoEnhetRoyk);
    } else {
      enhetApi.getRoyklopTiltakTypeOppslagdata(this.gotOppslagsdata);
      sjekkRoyklopRisiko(this.props.id, this.props.brId, this.gotRisikoEnhetRoyk);
    }
  }

  gotOppslagsdata = (err, data) => {
    this.setState({ oppslagsdata: data });
    if (this.getType() === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakHistorikk(this.state.id, this.gotHistorikk);
    } else {
      royklopApi.getRoyklopTiltakHistorikk(this.props.id, this.gotHistorikk);
    }
    
  };

  gotRisikoEnhetRoyk = (err, res) => {
    this.setState( { risikoSjekk: res });
  }

  gotHistorikk = (err, res) => {
    this.setState({ historikk: res });
    this.setDefaultData();
    this.setTiltakTypeListe();
  }

  // gotFritidsdata = (err, res) => {
  //   this.setState({ fritidsbygg: res });
  //   enhetApi.getByggTypeKode(this.props.brId, this.gotByggTypeKode);
  // }

  // gotByggTypeKode = (err, data) => {
  //   this.setState({ byggkode: data }); 
  //   this.sjekkFritid(data);
  // }

  // sjekkFritid = (data) => {
  //   let fritid = _.find(this.state.fritidsbygg, function(x) {
  //     return x === data;
  //   });
    
  //   if (fritid !== undefined) {
  //     this.setState({ erFritidsbygg: true }); 
  //   } else {
  //     this.setState({ erFritidsbygg: false });
  //   }
  //   console.log(this.state.erFritidsbygg);
  // }

  setDefaultData = () => {
      var typeListe = this.state.oppslagsdata.tiltakTypeListe;
      console.log(this.state.oppslagsdata);
      var items = this.props.items;
      var tmp = [];

      typeListe.forEach((item) => {
        var tt = _.find(items, function(valgt) {
          return valgt.tiltakTypeId === item.id;
        });
        if (tt === undefined) {
          tmp.push(item);
        }
      });

      if (tmp.length == 0) {
        
        // melding = 'Ingen flere definerte tiltakstyper';
        // meldingHeader = 'Ny tiltakstype';
        // this.dialogs.setOpenDialog('MeldingDialog', {
        //   melding: melding,
        //   meldingHeader: meldingHeader,
        // });
        this.dialogs.setOpenDialog('AvsluttDialog', {
          melding: 'test',
          meldingHeader: 'test',
        });
      } 
    let blank = {
      id: null,
      tekst: null,
      aktiv: false
    };
    hyppighetListe = this.state.oppslagsdata.tiltakHyppighetListe;
    hyppighetListe.unshift(blank);
    this.setState({
      hyppighetId: hyppighetListe[0].id
    });
  };

  setTiltakTypeListe = () => {
    var typeListe = this.state.oppslagsdata.tiltakTypeListe;

    var items = this.getItems();
    var tmp = [];

    typeListe.forEach(item => {
      let tt = _.find(items, valgt => {
        return valgt.tiltakTypeId === item.id;
      });
      if (!tt) {
        tmp.push(item);
      }
    });

    this.setState({ tiltakTypeListe: tmp });
    if (tmp.length > 0) {
      this.setState({ tiltakId: tmp[0].id });
      let hist = this.state.historikk.filter(x => x.tiltakTypeId === tmp[0].id);
      console.log(hist);
      if (hist !== undefined && hist.length > 0) {
        let d = new Date(hist[0].dato);
        this.setState({ startDate: d });
      }
    }
  };

  avslutt = () => {
    this.props.onCloseModal();
  }

  handleTypeChange = data => {
    let hist = this.state.historikk.filter(x => x.tiltakTypeId === data.target.value);
    
    if (hist !== undefined && hist.length > 0) {
      let d = new Date(hist[0].dato);
      this.setState({ startDate: d });
    }
    
    this.setState({
      tiltakId: data.target.value
    });
  };

  handleHyppighetChange = data => {
    this.setState({
      hyppighetId: data.target.value
    });
  };

  handleDatePickerChange = date => {
    this.setState({
      selectedDate: date
    });
  };

  onLagreClick = () => {
    this.setState({ lagrer: true });
    if (this.state.hyppighetId == null) {
      this.dialogs.setOpenDialog('HyppighetDialog');
      this.setState({ lagrer: false });
      return;
    };

    if (this.state.selectedDate == null) {
      this.dialogs.setOpenDialog('DatoDialog');
      this.setState({ lagrer: false });
      return;
    };

    if (this.getType() === 'bruksenhet') {
      var nyTiltaktype = {
        bruksenhetid: this.getId(),
        tiltaktypeid: this.state.tiltakId,
        hyppighetid: this.state.hyppighetId,
        nestedato:
          this.state.selectedDate != null
            ? moment(this.state.selectedDate).format('YYYY-MM-DDTHH:00:00')
            : null,
        sistedato: null,
        sisteStatus: false,
        risikovurdering: this.state.risikoSjekk
      };

      enhetApi.saveBruksenhetTiltakType(nyTiltaktype, this.onTiltaktypeLagret);
    } else {
      var nyTiltaktypeRoyk = {
        royklopid: this.getId(),
        bruksenhetid: this.props.brId,
        tiltaktypeid: this.state.tiltakId,
        hyppighetid: this.state.hyppighetId,
        nestedato: moment(this.state.selectedDate).format(
          'YYYY-MM-DDTHH:00:00'
        ),
        sistedato: null,
        sisteStatus: false,
        risikovurdering: this.state.risikoSjekk
      };
      enhetApi.saveRoyklopTiltakType(nyTiltaktypeRoyk, this.onTiltaktypeLagret);
    }
  };

  onTiltaktypeLagret = (err, nyEnhet) => {
    this.setState({ lagrer: false });

    if (this.props.location) {
      this.props.router.push({
        pathname:
          '/gjennomforing/' +
          this.props.params.arbeidslisteId +
          '/' +
          this.props.params.arbeidslisteNavn +
          '/' +
          this.props.location.state.bruksEnhetId +
          '/tiltakstyper',
        state: {
          type: this.props.location.state.type,
          id: this.props.location ? this.props.location.state.id : this.props.id,
          adresse: this.props.adresse,
          eiendom: this.props.eiendom
        }
      });
    } else {
      this.props.onCloseModal();
    }
  };

  getType = () => {
    return this.props.location
      ? this.props.location.state.type
      : this.props.type;
  };

  getItems = () => {
    return this.props.location
      ? this.props.location.state.items
      : this.props.items;
  };

  getId = () => {
    return this.props.location ? this.props.location.state.id : this.props.id;
  };

  render() {
    if (!this.state.oppslagsdata) {
      return <div>Laster...</div>;
    }
    if (this.state.lagrer) {
      return <div>Lagrer...</div>;
    }
    return (
      <div className="margin-top-15">
        <div>
          <div className="row">
            <div className="col-sm-5">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Type tiltak</label>
                <select
                  className="form-control"
                  id="type"
                  onChange={this.handleTypeChange}>
                  {_.map(this.state.tiltakTypeListe, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.tekst}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Hyppighet</label>
                <select
                  className="form-control"
                  id="type"
                  onChange={this.handleHyppighetChange}>
                  {_.map(hyppighetListe, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.tekst}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Dato</label>
                <br />
                <DatePicker
                  className="hoyde34 width120"
                  selected={this.state.selectedDate}
                  /* startDate={moment(Date.now())} */
                  minDate={moment(Date.now())}
                  onChange={this.handleDatePickerChange}
                  todayButton={'I dag'}
                  filterDate={this.isWeekday}
                  isClearable={false}
                  showYearDropdown
                  
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="pull-right margin-right-55">
              <input
                type="button"
                className="btn btn-primary"
                value="OK"
                onClick={this.onLagreClick}
              />
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NyTiltakstype;
