import React from 'react';
import ContextMenu from 'components/ContextMenu';

import oppslagsdataApi from 'api/oppslagsdataApi.js';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ReactTable from '@norkart/react-table';
import NKIcon from 'nka-icons';
import NyEndreOppslagsdata from './NyEndreOppslagsdata.jsx';
import NyEndreOppslagsdataAvvikBeskrivelse from './NyEndreOppslagsdataAvvikBeskrivelse.jsx';
import NyEndreOppslagsdataAvvikType from './NyEndreOppslagsdataAvvikType.jsx';
import NyEndreOppslagsdataAvvikLovdata from './NyEndreOppslagsdataAvvikLovdata.jsx';
import NyEndreOppslagsdataAvvikParagraf from './NyEndreOppslagsdataAvvikParagraf.jsx';
import NyEndreOppslagsdataTiltakType from './NyEndreOppslagsdataTiltakType.jsx';
import NyEndreOppslagsdataTiltakHyppighet from './NyEndreOppslagsdataTiltakHyppighet.jsx';
import NyEndreOppslagsdataModel from './NyEndreOppslagsdataModel.jsx';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import {
  getAnmerkningTabellOversikt,
  getIldstedTabellOversikt,
  getRoyklopTabellOversikt,
  getTiltakTabellOversikt,
  getAvvikTabellOversikt
} from './OppslagsDataTabellValg.jsx';
import { debugFactory } from 'utils';

var _ = require('underscore');
var nyEndre = 'Ny';
var avvikTypeObjektTyper = [];
var avvikTypeParagrafer = [];
var avvikLovdataer = [];
var ildstedprodusenter = [];

const debug = debugFactory('nk:innstillinger:oppslagsdata');  

class Oppslagsdata extends React.Component {
  constructor(props) {
    super(props);
    this.endre = this.endre.bind(this);
    this.slett = this.slett.bind(this);
    this.ny = this.ny.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.tabellChange = this.tabellChange.bind(this);
    this.lastTabellData = this.lastTabellData.bind(this);
    this.getColumnsStd = this.getColumnsStd.bind(this);
    this.getColumnsIldstedModell = this.getColumnsIldstedModell.bind(this);
    this.getColumnsAvvikType = this.getColumnsAvvikType.bind(this);
    this.gotData = this.gotData.bind(this);
    this.closeNyEndre = this.closeNyEndre.bind(this);
    this.nyEndreLagret = this.nyEndreLagret.bind(this);
    this.closeSlettDialogOk = this.closeSlettDialogOk.bind(this);
    this.slettetResultat = this.slettetResultat.bind(this);
    this.getColumnsAvvikLovdata = this.getColumnsAvvikLovdata.bind(this);
    this.closeFeilDialogOk = this.closeFeilDialogOk.bind(this);
    this.dataLagret = this.dataLagret.bind(this);

    this.state = {
      tabell: [],
      tabellNavn: undefined,
      type: undefined,
      data: [],
      kollonner: this.getColumnsStd(),
      dataLastet: false,
      default: null,
      modalNyIsOpen: false,
      modalNyAvvikBeskrivelseIsOpen: false,
      modalNyAvvikTypeIsOpen: false,
      modalNyAvvikLovdataIsOpen: false,
      modalNyAvvikParagrafIsOpen: false,
      modalNyModellIsOpen: false,
      modalNyTiltakTypeIsOpen: false,
      modalNyTiltakHyppighetIsOpen: false,
      isLoading: false,
      SlettMeldingIsOpen: false,
      valgtItem: null,
      melding: '',
      FeilMeldingIsOpen: false,
      textMaxLength: '100'
    };
  }

   componentDidMount() {
   }

  ny() {
    nyEndre = 'Ny';
    if (this.state.type === 'Avvik') {
      if (this.state.tabellNavn === 'Type') {
        this.setState({ modalNyAvvikTypeIsOpen: true });
      } else if (this.state.tabellNavn === 'Lovdata') {
        this.setState({ modalNyAvvikLovdataIsOpen: true });
      } else if (this.state.tabellNavn === 'Paragraf') {
        this.setState({ modalNyAvvikParagrafIsOpen: true });
      } else if (this.state.tabellNavn === 'Status') {
        this.setState({ modalNyIsOpen: true, textMaxLength: '2000' });
      } else if (this.state.tabellNavn === 'Beskrivelse') {
        this.setState({ modalNyAvvikBeskrivelseIsOpen: true, textMaxLength: '2000' });
      } else {
        this.setState({ modalNyIsOpen: true });
      }
    } else if (this.state.type === 'Ildsted') {
      if (this.state.tabellNavn === 'Modell') {
        this.setState({ modalNyModellIsOpen: true });
      } else {
        this.setState({ modalNyIsOpen: true });
      }
    } else if (this.state.type === 'Røykløp') {
      if (this.state.tabellNavn === 'Modell') {
        this.setState({ modalNyModellIsOpen: true });
      } else if (this.state.tabellNavn === 'Dimensjon') {
        this.setState({ modalNyIsOpen: true, textMaxLength: '50' });
      } else {
        this.setState({ modalNyIsOpen: true });
      }
    } else if (this.state.type === 'Tiltak') {
      if (this.state.tabellNavn === 'Type') {
        this.setState({ modalNyTiltakTypeIsOpen: true });
      } else if (this.state.tabellNavn === 'Hyppighet') {
        this.setState({ modalNyTiltakHyppighetIsOpen: true });
      } else {
        this.setState({ modalNyIsOpen: true });
      }
    } else if (this.state.type === 'Anmerkning / Andre forhold') {
      if (this.state.tabellNavn === 'Beskrivelse') {
        this.setState({ modalNyAvvikBeskrivelseIsOpen: true });
      } else {
        this.setState({ modalNyIsOpen: true });
      }
      

    } else {
      this.setState({ modalNyIsOpen: true });
    }
  }
  nyEndreLagret(item) {
    if (this.state.type === 'Tiltak' && this.state.tabellNavn === 'Type') {
      if (item.standard) {
        for (var i = 0; i < this.state.data.length; i++) {
          if (
            this.state.data[i].tiltakObjektId === item.tiltakObjektId &&
            this.state.data[i].standard &&
            this.state.data[i].id !== item.id
          ) {
            var endre = this.state.data[i];
            endre.standard = false;
            oppslagsdataApi.updateTiltakType(
              endre.id,
              endre,
              this.dataLagret
            );
          }
        }
      }
    }

    this.lastTabellData(this.state.tabellNavn);

    this.setState({
      modalNyIsOpen: false,
      modalNyAvvikBeskrivelseIsOpen: false,
      modalNyAvvikTypeIsOpen: false,
      modalNyAvvikLovdataIsOpen: false,
      modalNyAvvikParagrafIsOpen: false,
      modalNyModellIsOpen: false,
      modalNyTiltakTypeIsOpen: false,
      modalNyTiltakHyppighetIsOpen: false,
      textMaxLength: '100'
    });
  }
  dataLagret(err, nyEndreitem) {
    this.lastTabellData(this.state.tabellNavn);
  }
  endre(item) {
    nyEndre = 'Endre';
    if (this.state.type === 'Avvik') {
      if (this.state.tabellNavn === 'Type') {
        this.setState({ modalNyAvvikTypeIsOpen: true, valgtItem: item });
      } else if (this.state.tabellNavn === 'Lovdata') {
        this.setState({ modalNyAvvikLovdataIsOpen: true, valgtItem: item });
      } else if (this.state.tabellNavn === 'Paragraf') {
        this.setState({ modalNyAvvikParagrafIsOpen: true, valgtItem: item });
      } else if (this.state.tabellNavn === 'Status') {
        this.setState({
          modalNyIsOpen: true,
          textMaxLength: '2000',
          valgtItem: item
        });
      } else if (this.state.tabellNavn === 'Beskrivelse') {
        this.setState({
          modalNyAvvikBeskrivelseIsOpen: true,
          textMaxLength: '2000',
          valgtItem: item
        });
      } else {
        this.setState({ modalNyIsOpen: true, valgtItem: item });
      }
    } else if (this.state.type === 'Ildsted') {
      if (this.state.tabellNavn === 'Modell') {
        this.setState({ modalNyModellIsOpen: true, valgtItem: item });
      } else {
        this.setState({ modalNyIsOpen: true, valgtItem: item });
      }
    } else if (this.state.type === 'Røykløp') {
      if (this.state.tabellNavn === 'Modell') {
        this.setState({ modalNyModellIsOpen: true, valgtItem: item });
      } else if (this.state.tabellNavn === 'Dimensjon') {
        this.setState({
          modalNyIsOpen: true,
          textMaxLength: '50',
          valgtItem: item
        });
      } else {
        this.setState({ modalNyIsOpen: true, valgtItem: item });  
      }
    } else if (this.state.type === 'Tiltak') {
      if (this.state.tabellNavn === 'Type') {
        this.setState({ modalNyTiltakTypeIsOpen: true, valgtItem: item });
      } else if (this.state.tabellNavn === 'Hyppighet') {
        this.setState({ modalNyTiltakHyppighetIsOpen: true, valgtItem: item });
      } else {
        this.setState({ modalNyIsOpen: true, valgtItem: item });
      }
    } else if (this.state.type === 'Anmerkning / Andre forhold') {

      if (this.state.tabellNavn === 'Beskrivelse') {
        this.setState({ modalNyAvvikBeskrivelseIsOpen: true, valgtItem: item });
      } else {
        this.setState({ modalNyIsOpen: true, valgtItem: item });
      }
    } else {
      this.setState({ modalNyIsOpen: true, valgtItem: item });
    }
  }
  closeNyEndre() {
    this.setState({
      modalNyIsOpen: false,
      modalNyAvvikBeskrivelseIsOpen: false,
      modalNyAvvikTypeIsOpen: false,
      modalNyAvvikLovdataIsOpen: false,
      modalNyAvvikParagrafIsOpen: false,
      modalNyModellIsOpen: false,
      modalNyTiltakTypeIsOpen: false,
      modalNyTiltakHyppighetIsOpen: false,
      textMaxLength: '100'
    });
  }

  slett(item) {
    this.setState({ SlettMeldingIsOpen: true, valgtItem: item });
  }
  closeFeilDialogOk() {
    this.setState({ FeilMeldingIsOpen: false });
  }
  closeSlettDialogOk() {
    this.setState({ SlettMeldingIsOpen: false });
    if (this.state.type === 'Ildsted') {
      if (this.state.tabellNavn === 'Plassering') {
        oppslagsdataApi.slettIldstedPlassering(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Produsent') {
        oppslagsdataApi.slettIldstedProdusent(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Modell') {
        oppslagsdataApi.slettIldstedModell(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Type') {
        oppslagsdataApi.slettIldstedType(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
    }
    if (this.state.type === 'Avvik') {
      if (this.state.tabellNavn === 'Beskrivelse') {
        oppslagsdataApi.slettAvvikBeskrivelse(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Status') {
        oppslagsdataApi.slettAvvikStatus(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Type') {
        oppslagsdataApi.slettAvvikType(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Årsak lukket') {
        oppslagsdataApi.slettAvvikAdmLukkArsak(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Lovdata') {
        oppslagsdataApi.slettAvvikLovdata(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Paragraf') {
        oppslagsdataApi.slettAvvikParagraf(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
    }
    if (this.state.type === 'Anmerkning / Andre forhold') {
      if (this.state.tabellNavn === 'Beskrivelse') {
        oppslagsdataApi.slettAnmerkningBeskrivelse(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Kategori') {
        oppslagsdataApi.slettAvvikAnmerkningKategori(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
    }
    if (this.state.type === 'Røykløp') {
      if (this.state.tabellNavn === 'Adkomst') {
        oppslagsdataApi.slettRoyklopAdkomst(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Dimensjon') {
        oppslagsdataApi.slettRoyklopDimensjon(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Modell') {
        oppslagsdataApi.slettRoyklopModell(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Plassering') {
        oppslagsdataApi.slettRoyklopPlassering(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Produsent') {
        oppslagsdataApi.slettRoyklopProdusent(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Sotmengde') {
        oppslagsdataApi.slettRoyklopSotmengde(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Sotuttak') {
        oppslagsdataApi.slettRoyklopSotuttak(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Type') {
        oppslagsdataApi.slettRoyklopType(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
    }
    if (this.state.type === 'Tiltak') {
      if (this.state.tabellNavn === 'Årsak ikke utført') {
        oppslagsdataApi.slettTiltakAarsakIkkeUtfort(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Type') {
        oppslagsdataApi.slettTiltakType(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
      if (this.state.tabellNavn === 'Hyppighet') {
        oppslagsdataApi.slettTiltakHyppighet(
          this.state.valgtItem.id,
          this.slettetResultat
        );
      }
    }
  }
  slettetResultat(err, melding) {
    if (
      this.state.type === 'Avvik' &&
      this.state.tabellNavn === 'Beskrivelse'
    ) {
      this.lastTabellData(this.state.tabellNavn);
    } else if (
      this.state.type === 'Anmerkning / Andre forhold' &&
      this.state.tabellNavn === 'Beskrivelse'
    ) {
      this.lastTabellData(this.state.tabellNavn);
    } else {
      if (melding.length === 0) {
        this.lastTabellData(this.state.tabellNavn);
      } else {
        var retur = (
          <div>
            {_.map(
              melding,
              function(m) {
                return (
                  <div>
                    {m} <br />
                  </div>
                );
              },
              this
            )}
          </div>
        );
        this.setState({ melding: retur, FeilMeldingIsOpen: true });
      }
    }
    this.setState({ textMaxLength: '100' });
  }

  // Valg av type
  typeChange(data) {
    this.setState({
      type: data.target.value,
      tabellNavn: undefined,
      tabell: null,
      dataLastet: false,
      data: []
    });
    if (data.target.value === 'Velg tabell...') {
      this.setState({ tabell: [] });
    }
    if (data.target.value === 'Ildsted') {
      this.setState({
        tabell: getIldstedTabellOversikt(),
        tabellNavn: 'Velg tabell...'
      });
    }
    if (data.target.value === 'Røykløp') {
      this.setState({
        tabell: getRoyklopTabellOversikt(),
        tabellNavn: 'Velg tabell...'
      });
    }
    if (data.target.value === 'Tiltak') {
      this.setState({
        tabell: getTiltakTabellOversikt(),
        tabellNavn: 'Velg tabell...'
      });
    }
    if (data.target.value === 'Avvik') {
      this.setState({
        tabell: getAvvikTabellOversikt(),
        tabellNavn: 'Velg tabell...'
      });
    }
    if (data.target.value === 'Anmerkning / Andre forhold') {
      this.setState({
        tabell: getAnmerkningTabellOversikt(),
        tabellNavn: 'Velg tabell...'
      });
    }
  }
  // Valg i tabellen
  tabellChange(data) {
    this.setState({ tabellNavn: data.target.value });
    this.lastTabellData(data.target.value);
  }

  lastTabellData(data) {
    //Anmerkning
    if (this.state.type === 'Anmerkning / Andre forhold') {
      if (data === 'Velg tabell...') {
        this.setState({ data: [], tabellNavn: '', dataLastet: false });
      }
      if (data === 'Beskrivelse') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getAnmerkningBeskrivelse(this.gotData);
      }
      if (data === 'Kategori') {
        this.setState({ kollonner: this.getColumnsAnmKategori(), isLoading: true });
        oppslagsdataApi.getAvvikAnmerkningKategori(this.gotData);
      }
    }
    // Avvik
    if (this.state.type === 'Avvik') {
      if (data === 'Velg tabell...') {
        this.setState({ data: [], tabellNavn: '', dataLastet: false });
      }
      if (data === 'Beskrivelse') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getAvvikBeskrivelse(this.gotData);
      }
      if (data === 'Lovdata') {
        this.setState({
          kollonner: this.getColumnsAvvikLovdata(),
          isLoading: true
        });
        oppslagsdataApi.getAvvikLovdata(this.gotData);
      }
      if (data === 'Paragraf') {
        this.setState({
          kollonner: this.getColumnsAvvikParagraf(),
          isLoading: true
        });
        oppslagsdataApi.getAvvikParagraf(this.gotData);
      }
      if (data === 'Status') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getAvvikStatus(this.gotData);
      }
      if (data === 'Type') {
        this.setState({
          kollonner: this.getColumnsAvvikType(),
          isLoading: true
        });
        oppslagsdataApi.getAvvikType(this.gotData);
      }
      if (data === 'Årsak lukket') {
        this.setState({
          kollonner: this.getColumnsStd(),
          isLoading: true
        });
        oppslagsdataApi.getAvvikAdmLukkArsak(this.gotData);
      }
    }
    // Ildsted
    if (this.state.type === 'Ildsted') {
      if (data === 'Velg tabell...') {
        this.setState({ data: [], tabellNavn: '', dataLastet: false });
      }
      if (data === 'Modell') {
        this.setState({
          kollonner: this.getColumnsIldstedModell(),
          isLoading: true
        });
        oppslagsdataApi.getIldstedModell(this.gotData);
      }
      if (data === 'Plassering') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getIldstedPlassering(this.gotData);
      }
      if (data === 'Produsent') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getIldstedProdusent(this.gotData);
      }
      if (data === 'Type') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getIldstedType(this.gotData);
      }
    }
    // Røykløp
    if (this.state.type === 'Røykløp') {
      if (data === 'Velg tabell...') {
        this.setState({ data: [], tabellNavn: '', dataLastet: false });
      }
      if (data === 'Adkomst') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopAdkomst(this.gotData);
      }
      if (data === 'Dimensjon') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopDimensjon(this.gotData);
      }
      if (data === 'Modell') {
        this.setState({
          kollonner: this.getColumnsIldstedModell(),
          isLoading: true
        });
        oppslagsdataApi.getRoyklopModell(this.gotData);
      }
      if (data === 'Plassering') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopPlassering(this.gotData);
      }
      if (data === 'Produsent') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopProdusent(this.gotData);
      }
      if (data === 'Sotmengde') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopSotmengde(this.gotData);
      }
      if (data === 'Sotuttak') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopSotuttak(this.gotData);
      }
      if (data === 'Type') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getRoyklopType(this.gotData);
      }
    }
    // Tiltak
    if (this.state.type === 'Tiltak') {
      if (data === 'Velg tabell...') {
        this.setState({ data: [], tabellNavn: '', dataLastet: false });
      }
      if (data === 'Årsak ikke utført') {
        this.setState({ kollonner: this.getColumnsStd(), isLoading: true });
        oppslagsdataApi.getTiltakAarsakIkkeUtfort(this.gotData);
      }
      if (data === 'Hyppighet') {
        this.setState({
          kollonner: this.getColumnsTiltakHyppighet(),
          isLoading: true
        });
        oppslagsdataApi.getTiltakHyppighet(this.gotData);
      }
      if (data === 'Type') {
        this.setState({
          kollonner: this.getColumnsTiltakType(),
          isLoading: true
        });
        oppslagsdataApi.getTiltakType(this.gotData);
      }
    }
  }
  gotData(err, data) {
    var liste = data;
    if (this.state.tabellNavn === 'Modell') {
      if (this.state.type === 'Ildsted' || this.state.type === 'Røykløp') {
        liste = data.modell;
        ildstedprodusenter = data.produsent;
        // var ld = { id: null, tekst: '' };
        // ildstedprodusenter.unshift(ld);
      }
    }
    if (this.state.type === 'Avvik') {
      if (this.state.tabellNavn === 'Type') {
        liste = data.avvikType;
        avvikTypeObjektTyper = data.objektTyper;
        avvikTypeParagrafer = data.alleParagrafer;
      }
      if (this.state.tabellNavn === 'Paragraf') {
        liste = data.paragraf;
        avvikLovdataer = data.lovdata;
        // Add tomt elemt i starten av listen slik at lovdata ikke trenger å velges
        var ld = { id: null, tekst: '' };
        avvikLovdataer.unshift(ld);
      }
    }
    this.setState({ data: liste, dataLastet: true, isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return <div className="loader" />;
    }
    return (
      <div>
        <div className="form">
          <h2>Oppslagsdata</h2>
          <div className="row">
            <div className="col-sm-4">
              <label>Type</label>
              <select
                className="form-control"
                id="sel1"
                onChange={this.typeChange}
                value={this.state.type}>
                <option>Velg type...</option>
                <option>Tiltak</option>
                <option>Røykløp</option>
                <option>Ildsted</option>
                <option>Avvik</option>
                <option>Anmerkning / Andre forhold</option>
              </select>
            </div>

            <div className="col-sm-4">
              <label>Tabell</label>
              <select
                className="form-control"
                id="type"
                onChange={this.tabellChange}
                value={this.state.tabellNavn}>
                {_.map(this.state.tabell, function(o, i) {
                  return (
                    <option key={i} value={o.navn}>
                      {o.navn}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12">
              <h4>{/* {this.state.type} {this.state.tabellNavn} */}</h4>
            </div>
          </div>
          {this.state.dataLastet ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="btn-toolbar">
                  <button
                    className="btn btn-default button-arbliste-enhet"
                    onClick={this.ny}>
                    <span className="pull-left">
                      <NKIcon
                        className="pull-left"
                        icon="pluss"
                        color="nk-black"
                      />
                    </span>
                    <span className="pull-left margin-left-10 ">Ny</span>
                  </button>
                </div>
                <br />
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col-sm-12">
              <ReactTable
                items={this.state.data}
                idProperty="id"
                showIndex={false}
                filterable={false}
                columns={this.state.kollonner}
                onRowClick={null}
              />
            </div>
          </div>
        </div>
        <NKModal size="medium" isOpen={this.state.modalNyIsOpen}>
          <NyEndreOppslagsdata
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
            textMaxLength={this.state.textMaxLength}
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyAvvikBeskrivelseIsOpen}>
          <NyEndreOppslagsdataAvvikBeskrivelse
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
            textMaxLength={this.state.textMaxLength}
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyAvvikTypeIsOpen}>
          <NyEndreOppslagsdataAvvikType
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
            objektTyper={avvikTypeObjektTyper}
            paragrafer={avvikTypeParagrafer}
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyAvvikLovdataIsOpen}>
          <NyEndreOppslagsdataAvvikLovdata
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyAvvikParagrafIsOpen}>
          <NyEndreOppslagsdataAvvikParagraf
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
            lovdata={avvikLovdataer}
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyModellIsOpen}>
          <NyEndreOppslagsdataModel
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
            produsenter={ildstedprodusenter}
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyTiltakTypeIsOpen}>
          <NyEndreOppslagsdataTiltakType
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
            data={this.state.data}
           /*  organisasjon={this.props.store.organisasjon} */
          />
        </NKModal>
        <NKModal size="medium" isOpen={this.state.modalNyTiltakHyppighetIsOpen}>
          <NyEndreOppslagsdataTiltakHyppighet
            type={this.state.type}
            tabell={this.state.tabellNavn}
            onClose={this.closeNyEndre}
            dataLagret={this.nyEndreLagret}
            nyEndre={nyEndre}
            valgtItem={this.state.valgtItem}
          />
        </NKModal>
        <OkDialog
          isOpen={this.state.SlettMeldingIsOpen}
          onClose={() => this.setState({ SlettMeldingIsOpen: false })}
          tittel="Slett oppslagsdata"
          melding="Du sletter nå valgt oppslagsdata. Vil du fortsette?"
          onOk={this.closeSlettDialogOk}
        />
        <OkDialog
          isOpen={this.state.FeilMeldingIsOpen}
          onClose={() => this.setState({ FeilMeldingIsOpen: false })}
          tittel="Slett oppslagsdata"
          melding={this.state.melding}
          onOk={this.closeFeilDialogOk}
        />
      </div>
    );
  }

  // Standard kollone oppsett
  getColumnsStd() {
    return [
      // {
      //   id: 'id',
      //   name: 'Id',
      //   sortParams: 'id',
      //   filterable: false,
      //   isSorted: true,
      //   sortable: false
      // },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: true,
        sortable: true
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  getColumnsAnmKategori() {
    return [
      // {
      //   id: 'id',
      //   name: 'Id',
      //   sortParams: 'id',
      //   filterable: false,
      //   isSorted: true,
      //   sortable: false
      // },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: true,
        sortable: true
      },
      {
        id: 'anmerkningKategori',
        name: 'Type',
        sortParams: 'anmerkningKategori',
        filterable: false,
        isSorted: true,
        sortable: true,
        formatter: function(item) {
          if (item.anmerkningKategori) {
            return 'Anmerkning';
          } else {
            return 'Andre forhold';
          }
        }
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  getColumnsAvvikLovdata() {
    return [
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: true,
        sortable: true
      },
      {
        id: 'lokal',
        name: 'Lokal',
        sortParams: 'lokal',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.lokal) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  getColumnsAvvikParagraf() {
    return [
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: true,
        sortable: true
      },
      {
        id: 'url',
        name: 'Url',
        sortParams: 'url',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.url}
            </a>
          );
        }
      },
      {
        id: 'lovdataId',
        name: 'Lovdata',
        sortParams: 'lovdata',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (
            item.lovdataId !== null &&
            item.lovdataId !== '' &&
            avvikLovdataer.length > 0
          ) {
            var lov = _.findWhere(avvikLovdataer, { id: item.lovdataId });
            if (lov !== undefined) {
              return lov.tekst;
            }
          }
        }
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  // Tabell kolonner for Avvik Type
  getColumnsAvvikType() {
    return [
      // {
      //   id: 'id',
      //   name: 'Id',
      //   sortParams: 'id',
      //   filterable: false,
      //   isSorted: true,
      //   sortable: true
      // },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: true,
        sortable: true
      },
      {
        id: 'objektReferanseType',
        name: 'Objekt',
        sortParams: 'objektReferanseType',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.objektReferanseType === 'R') {
            return 'Røykløp';
          }
          if (item.objektReferanseType === 'B') {
            return 'Bruksenhet';
          }
          if (item.objektReferanseType === 'I') {
            return 'Ildsted';
          }
        }
      },
      {
        id: 'paragrafer',
        name: 'Paragrafer',
        sortParams: 'paragrafer',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.paragrafer !== null && item.paragrafer !== undefined) {
            return item.paragrafer.length;
          }
          return 0;
        }
      },
      {
        id: 'fristDefault',
        name: 'Frist antall dager',
        sortParams: 'fristDefault',
        filterable: false,
        isSorted: false,
        sortable: true
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  // Tabell kolonner for ildsted modell
  getColumnsIldstedModell() {
    return [
      // {
      //   id: 'id',
      //   name: 'Id',
      //   sortParams: 'id',
      //   filterable: false,
      //   isSorted: true,
      //   sortable: false
      // },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: true
      },
      {
        id: 'produsentId',
        name: 'Produsent',
        sortParams: 'produsentId',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (
            item.produsentId !== null &&
            item.produsentId !== '' &&
            ildstedprodusenter.length > 0
          ) {
            var prod = _.findWhere(ildstedprodusenter, {
              id: item.produsentId
            });
            if (prod !== undefined) {
              return prod.tekst;
            }
          }
        }
      },
      {
        id: 'dokumentasjon',
        name: 'Dokumentasjon',
        sortParams: 'dokumentasjon',
        filterable: false,
        isSorted: false,
        sortable: false
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  getColumnsTiltakHyppighet() {
    return [
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: true
      },
      {
        id: 'dager',
        name: 'Antall dager',
        sortParams: 'dager',
        filterable: false,
        isSorted: false,
        sortable: true
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }

  getColumnsTiltakType() {
    return [
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: true
      },
      {
        id: 'tiltakObjekt',
        name: 'Objekt',
        sortParams: 'tiltakObjekt',
        filterable: false,
        isSorted: false,
        sortable: true
      },
      {
        id: 'aktiv',
        name: 'Aktiv',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'standard',
        name: 'Standard',
        sortParams: 'standard',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.standard) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      {
        id: 'risikovurdering',
        name: 'Risikovurdering',
        sortParams: 'risikovurdering',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.risikovurdering) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        }
      },
      // {
      //   id: 'arkiv',
      //   name: 'Arkiv',
      //   sortParams: 'arkiv',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: true,
      //   formatter: function(item) {
      //     if (item.arkiv) {
      //       return 'Ja';
      //     } else {
      //       return 'Nei';
      //     }
      //   }
      // },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  }
}

export default Oppslagsdata;
