import React from 'react';
import { Panel } from 'react-bootstrap';

const Matrikkelenhet = ({ eiendomsdata }) => {

  return (
    <Panel eventKey="1">
      <Panel.Heading>
        <Panel.Title toggle>
          Matrikkelenhet - {eiendomsdata.matrikkelnummerFormatert}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <p>
          <strong>Bruksnavn: </strong> {eiendomsdata.bruksnavn}
        </p>
        <p>
          <strong>Kommune: </strong>
          {eiendomsdata.kommune}
        </p>
        <p>
          <strong>Eiendomstype: </strong>
          {eiendomsdata.eiendomstype}
        </p>
        <p>
          <strong>Areal: </strong>
          {eiendomsdata.beregnetAreal} m<sup>2</sup>
        </p>
        <p>
          <strong>Arealmerknader: </strong>
          {eiendomsdata.arealmerknader}
        </p>
        <p>
          <strong>Tinglyst: </strong>
          {eiendomsdata.tinglyst === 'true' ? 'Ja' : 'Nei'}
        </p>
        <p>
          <strong>Seksjonert: </strong>
          {eiendomsdata.seksjonert === 'true' ? 'Ja' : 'Nei'}
        </p>
        <p>
          <strong>Har fester: </strong>
          {eiendomsdata.harFester === 'true' ? 'Ja' : 'Nei'}
        </p>
        <p>
          <strong>Grunnforurensning:</strong>{' '}
          {eiendomsdata.grunnforurensning === 'true' ? 'Ja' : 'Nei'}
        </p>
        <p>
          <strong>Kulturminne: </strong>
          {eiendomsdata.harFester === 'true' ? 'Ja' : 'Nei'}
        </p>
      </Panel.Body>
    </Panel>
  );
};

export default Matrikkelenhet;
