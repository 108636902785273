import {
  BoolShould,
  BoolMust,
  MultiMatchQuery,
  NestedQuery,
  TermsQuery
} from 'searchkit';
import * as _ from 'lodash';

export function CustomQueryBuilder(query, options) {
  if (options.fields[0] === 'bokstav') {
    query = query.toUpperCase();
  }
  return {
    nested: {
      path: 'adresse',
      query: {
        bool: {
          should: [
            {
              multi_match: {
                query: query,
                type: 'phrase_prefix',
                fields: ['adresse.' + options.fields[0]]
              }
            }
          ]
        }
      }
    }
  };
}

export function CustomQueryBuilderEnhet(query, options) {
  return {
    nested: {
      path: 'matrikkelenhet',
      query: {
        bool: {
          should: [
            {
              multi_match: {
                query: query,
                type: 'phrase',
                fields: ['matrikkelenhet.' + options.fields[0]]
              }
            }
          ]
        }
      }
    }
  };
}

export function CustomQueryBuilderBygg(query, options) {
  return {
    nested: {
      path: 'bygg',
      query: {
        bool: {
          should: [
            {
              multi_match: {
                query: query,
                type: 'phrase',
                fields: ['bygg.' + options.fields[0]]
              }
            }
          ]
        }
      }
    }
  };
}

export function CustomQueryBuilderSearch(query) {
  let numbers = query.match(/\d+/);
  if (numbers == null) {
    return NestedQuery(
      'adresse',
      MultiMatchQuery(query, {
        type: 'phrase_prefix',
        fields: ['adresse.gatenavn']
      })
    );
  } else {
    return BoolShould([
      NestedQuery(
        'adresse',
        BoolMust([
          { match: { 'adresse.gatenavn': query } },

          TermsQuery('adresse.husnummer', numbers)
        ])
      )
    ]);
  }
}

/* export function CustomQueryBuilderSearch_Org(query) {
  let numbers = query.match(/\d+/);

  if (!numbers || numbers.length === 0) {
    return NestedQuery(
      'adresse',
      MultiMatchQuery(query, {
        type: 'phrase_prefix',
        fields: ['adresse.gatenavn']
      })
    );
  } else {
    let queryString = query.match(/[^\\u0000-\\u007F]+/g);
    return BoolShould([
      NestedQuery(
        'adresse',
        BoolMust([
          { match: { 'adresse.gatenavn': queryString ? queryString[0] : '' } },

          TermsQuery('adresse.husnummer', numbers)
        ])
      )
    ]);
  }
} */
