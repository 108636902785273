/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import * as _ from 'lodash';
import DatePicker from 'react-datepicker';
import TimeInput5min from 'components/TimeInput/TimeInput5min.jsx';
import moment from 'moment';
import { debugFactory } from 'utils';
import { getInfoForSvarut } from 'api/enhetApi';
import './dialog.css';
import { getMalinfo } from 'components/Editor/EditorNormal.js';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:SkrivSvarutDialog');

class SkrivSvarutDialogStep1 extends Component {
  constructor(props) {
    super(props);
    this.editorRef = null;
    this.min1Ref = React.createRef();
    this.tim1Ref = React.createRef();
    this.state = {
      tab: '',
      maltext: '',
      startDato: moment(),
      datoFeil: '',
      enhet: null,
      tim1: '08',
      min1: '00',
      fra: '',
      til: '',
      datepickerClassname: 'datepickerRed',
      datepickerWarning: 'sjekk dato',
      tittel: 'SvarUt'
    };
  }

  componentDidMount = () => {
  
    if (this.props.modus === 'PDF'){
      this.setState({ tittel: 'Skriv ut' });
    }

    let enhet = this.props.enhet;
    this.setState({ enhet });

    if (enhet.planlagtDato) {
      this.setState({ startDato: moment(enhet.planlagtDato) });
    }
    this.setState({ fra: enhet.planlagtBesokFra });
    this.setState({ til: enhet.planlagtBesokTil });
    getMalinfo();
  };

  DatoChange = (date) => {
    let valgtDag = date.format('DD.MM.YYYY');
    let now = moment().subtract(1, 'd');
    this.setState({ datoFeil: '' });
    if (date < now) {
      this.setState({
        datoFeil: 'Obs! Datoen (' + valgtDag + ') er tilbake i tid. ',
      });
    } else {
      this.setState({ valgtDag: valgtDag });
      this.setState({ startDato: date });
    }
    let same = date.isSame(moment(), 'day');
    if (same) {
      this.setState({ datepickerClassname: 'datepickerRed' });
      this.setState({ datepickerWarning: 'sjekk dato' });
    } else {
      this.setState({ datepickerClassname: 'datepicker' });
      this.setState({ datepickerWarning: '' });
    }
  };

  neste = () => {
    let pld = moment(this.state.startDato)   
      .add(3, 'h')
      .toDate();
    let dto = {
      BruksenhetId: this.state.enhet.id,
      BruksenhetAdresse: this.state.enhet.adresse,
      ArbeidslisteId: this.props.arbeidslisteId,
      PlanlagtDato: pld,
      BesokFra: this.state.fra,  
      BesokTil: this.state.til, 
      MatrikkelenhetId: this.state.enhet.matrikkelenhetId //this.state.enhet.eiendom
    };
    getInfoForSvarut(dto, this.onGotInfoForSvarut);
  };

  onGotInfoForSvarut = (err, data) => {
    if (err) {
      this.dialogs.closeDialog();
      alert('Malen kan ikke brukes. Sjekk malen.');
    } else {
      let enhet = this.state.enhet;
      let options = this.getFletteOptions();
      enhet.planlagtDato = this.state.startDato;
      enhet.planlagtBesokFra = this.state.fra;
      enhet.planlagtBesokTil = this.state.til;
      this.props.onNextSvarUtStep(enhet, options, data);
    }
  };

  getFletteOptions = () => {
    return [
      {
        text: 'Dagens dato',
        result: moment().format('DD.MM.YYYY'),
      },
      {
        text: 'Adresse',
        result: this.props.enhet.adresse,
      },
      {
        text: 'Eiendom',
        result: this.props.enhet.eiendom,
      },
      {
        text: 'Besøk dato',
        result: moment(this.state.startDato).format('DD.MM.YYYY'),
      },
      {
        text: 'Besøk fra',
        result: this.state.fra,
      },
      {
        text: 'Besøk til',
        result: this.state.til,
      },
      {
        text: contextInnstillingerPerson._currentValue.navn,
        result: contextInnstillingerPerson._currentValue.navn,
      },
      {
        text: 'Mobil: ' + contextInnstillingerPerson._currentValue.mobil,
        result: contextInnstillingerPerson._currentValue.mobil,
      },
      {
        text: 'Telefon: ' + contextInnstillingerPerson._currentValue.telefon,
        result: contextInnstillingerPerson._currentValue.telefon,
      },
    ];
  };

  onTidChange = (fra, til) => {
    this.setState({ fra });
    this.setState({ til });
  };

  render() {
    return (
      <div style={{ width: '790px' }}>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
    <h4 className='modal-title'>{this.state.tittel}</h4>
        </div>

        <div
          style={{ marginTop: '2px', height: 'calc(100vh - 160px)' }}
          className='panel panel-default'>
          <div className='panel-body'>
            <div className='row malrowX'>
              <table style={{ width: '700px', marginLeft: '20px' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '140px' }}>Adresse</td>
                    <td style={{ width: '20px' }} />
                    <td>{this.props.enhet.adresse}</td>
                  </tr>
                  <tr>
                    <td>Eiendom</td>
                    <td style={{ width: '20px' }} />
                    <td>{this.props.enhet.eiendom}</td>
                  </tr>

                  {this.props.enhet.tiltakBruksenhet && (
                    <tr>
                      <td>Tiltak</td>
                      <td style={{ width: '20px' }} />
                      <td>{this.props.enhet.tiltakBruksenhet}</td>
                    </tr>
                  )}

                  {this.props.enhet.tiltakRoyklop && (
                    <tr>
                      <td>Tiltak røykløp</td>
                      <td style={{ width: '20px' }} />
                      <td>{this.props.enhet.tiltakRoyklop}</td>
                    </tr>
                  )}

                  <tr style={{ height: '20px' }} />

                  <tr>
                    <td>Planlagt dato</td>
                    <td style={{ width: '20px' }} />
                    <td style={{ width: '300px'}}>
                    
                        <DatePicker
                          //className='width90 h30 text-center '
                          className={this.state.datepickerClassname}
                          readOnly={true}
                          selected={this.state.startDato}
                          onChange={this.DatoChange.bind(this)}
                          showYearDropdown
                          setMinDate={moment().toDate()}
                          title= {this.state.datepickerWarning}
                        />
                      
                       
                      
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td style={{ width: '20px' }} />
                    <td style={{ color: 'darkRed' }}>{this.state.datoFeil}</td>
                  </tr>

                  <tr style={{ height: '20px' }} />

                  <tr>
                    <td>Tidspunkt fra-til</td>
                    <td style={{ width: '20px' }} />
                    <td>
                      <TimeInput5min
                        onTidChange={this.onTidChange}
                        fra={this.state.fra}
                        til={this.state.til}
                        vertical={false}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td />
                    <td style={{ width: '20px' }} />
                    <td style={{ color: 'darkRed' }}>{this.state.tidFeil}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='malModalFooter'>
          <button onClick={this.neste} className='btn btn-primary pull-right'>
            Neste
          </button>
        </div>
      </div>
    );
  }
}

export default SkrivSvarutDialogStep1;
