var React = require('react');

class merInfoDialog extends React.Component {
    onClickOk = () => {
        this.props.onOk();


    };

    render() {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close" onClick={this.props.onAvbryt} type="button">
                        <span>x</span>
                        <span className="sr-only">Lukk</span>
                    </button>
                    <h4 className="modal-title">{this.props.enhet.adresse}</h4>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group ">
                                <label>Varselstatus</label>
                                <br/>
                                <text>Bruker varslet</text>
                            </div>
                            <div className="form-group ">
                                <label>Mobilnummer</label>
                                <br/>
                                <text>43231544</text>
                            </div>
                            <div className="form-group ">
                                <label>Svar</label>
                                <br/>
                                <text>Passer ikke</text>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary" onClick={this.onClickOk}>OK</button>
                </div>
            </div >
        );
    }
}

export default merInfoDialog;