import React, { Component } from 'react';
import enhetApi from 'api/enhetApi';
import DeltPipe from 'images/deltpipe.png';
import Debug from 'debug';
import _ from 'lodash';

const debug = Debug('nk:Delte piper');

class DeltePiperButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideMe: 'hide',
      liste: []
    };
  }

  onAdresserHentet = (err, result) => {  
    if (err) {
      debug('error', err);
      return;
    }
    if (result && this.props.enheter) {
      for (let i = 0; i < result.length; i++) {
        let rad = result[i];
        let id = rad.bruksenhetIdMa;
        for (let y = 0; y < this.props.enheter.length; y++) {
          let enhet = this.props.enheter[y];
          if (enhet.bruksenhetIdMa === id) {
            enhet.varselstatus === null
              ? (rad.varselstatus = ' - Ikke varslet')
              : (rad.varselstatus = ' - ' + enhet.varselstatus);
            break;
          }
        }
      }
    }
    //sorter egen først
    if (this.props.item.bruksenhetIdMa) {
      for (let x = 0; x < result.length; x++) {
        if (result[x].bruksenhetIdMa === this.props.item.bruksenhetIdMa) {
          result[x].bruksenhetIdMa = 0;
        }
      }
    } else {
      //Litt annerledes i Oppfølging - Åpne Avvik
      for (let x = 0; x < result.length; x++) {
        if (result[x].adresse === this.props.item.bruksenhetAdresse) {
          result[x].bruksenhetIdMa = 0;
        }
      }
    }

    let liste = _.sortBy(result, 'bruksenhetIdMa');

    if (this.props.fraSendTilEier === true) {
      if (this.props.egenAdresse) {
        let obj = {
          bruksenhetIdFag: 9999,
          bruksenhetIdMa: 999999,
          adresse: this.props.egenAdresse
        };
        liste.unshift(obj);
      }
    }
    this.setState({ liste: liste });
  };

  handleClick = e => {
    e.stopPropagation();
    if (this.props.fraSendTilEier === true) {
      this.visDeltFraSendTilEier(this.props.item);
    } else {
      if (this.props.fraOppfolging === true) {
        this.visDeltMedFlere(this.props.item);
      } else {
        this.visDeltePiper(this.props.item);
      }
    }
  };

  hideOrNot = () => {
    if (this.state.hideMe === 'hide') {
      this.setState({ hideMe: '' });
    } else {
      this.setState({ hideMe: 'hide' });
    }
  };

  visDeltFraSendTilEier = item => {
    if (this.state.liste.length === 0) {
      let str = item.bruksenhetIdsTilknyttet;
      let array = str.split(',');
      enhetApi.postBruksenhetAdresser2(array, this.onAdresserHentet);
    }
    this.hideOrNot();
  };

  visDeltMedFlere = item => {
    if (this.state.liste.length === 0) {
      let str = item.bruksenhetIds;
      let array = str.split(',');
      enhetApi.postBruksenhetAdresser2(array, this.onAdresserHentet);
    }
    this.hideOrNot();
  };

  visDeltePiper = item => {
    if (this.state.liste.length === 0) {
      let str = item.bruksenhetIdDelerLop;
      let array = str.split(',');
      if (this.props.fraLeggTilObjekter === true) {
        array.push(item.bruksenhetId);
      }
      enhetApi.postBruksenhetAdresser2(array, this.onAdresserHentet);
    }
    this.hideOrNot();
  };

  render() {
    let teller = 0;
    let egenAdresse = '';
    if (this.props.fraOppfolging === true) {
      egenAdresse = this.props.item.bruksenhetAdresse;
    } else {
      egenAdresse = this.props.item.adresse;
    }

    return (
      <div>
        {egenAdresse}
        &nbsp;
        <img
          height="22"
          width="22"
          src={DeltPipe}
          alt="Delt"
          onClick={this.handleClick}
        />
        <div className={this.state.hideMe}>
          <ul style={{ paddingLeft: '10px' }}>
            {this.state.liste.map(function(adr) {
              teller = teller + 1;
              let style = { textDecoration: 'underline' };
              if (teller > 1) {
                style = {};
              }
              let key = 'k' + teller.toString();
              if (adr.varselstatus !== undefined) {
                return (
                  <li style={style} key={key}>
                    {adr.adresse + adr.varselstatus}
                  </li>
                );
              } else {
                return (
                  <li style={style} key={key}>
                    {adr.adresse}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default DeltePiperButton;
