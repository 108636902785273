import React, { Component } from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';

// Utils
import {
  debugFactory,
  objGetDeep
} from 'utils';
import { getMalinfo } from 'components/Editor/EditorNormal.js';

const debug = debugFactory('nk:eksempelmaler:index');


  let pathname = null;

class index extends Component {
  //export default (props) => {

  constructor(props) {
    super(props);
    debug('props person', { props });
    pathname = objGetDeep(props, 'location.pathname');
  }

  componentDidMount() {
    getMalinfo();
  }
  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-md-8 col-lg-6'>
            <LinkList title='Eksempelmaler' columns={1}>
              <LinkListItem title='Eksempelmaler SMS' path={`${pathname}/Smsmaler`} />
              <LinkListItem
                title='Eksempelmaler dokument'
                path={`${pathname}/Dokumentmaler`}
              />
            </LinkList>
          </div>
        </div>
      </div>
    );
  }
}

export default index;