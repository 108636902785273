/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import './dialog.css';
import NKIcon from 'nka-icons';
import Editor from 'components/Editor/SvarUtEditor.jsx';
const debug = debugFactory('nk:SkrivSvarutDialogStep2');

class SkrivSvarutDialogStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tekst: this.props.data.malTekst,
      tittel: 'SvarUt',
    };
  }

  componentDidMount = () => {
    if (this.props.modus === 'PDF') {
      this.setState({ tittel: 'Skriv ut varsel' });
    }
  };

  editorOnChange = (model) => {
    this.setState({ tekst: model });
  };

  sendSvarUt = () => {
    let tekst = this.state.tekst;
    debug('MMM 2', tekst);
    //Replace contenteditable.. med #mottaker# igjen.
    tekst = this.settMottakerTilbake(tekst);
    //debug('Ajax 1', tekst);
    this.props.onSendSvarUt(this.props.enhet, tekst, this.props.data.kontakt);
  };

  settMottakerTilbake = (txt) => {
    let ff =
      '<!-- mott1 --><span contenteditable="false" style="background-color: yellow; color: blue;">Med utskrift<br/>viser mottakere her..<br/>Vennligst ikke fjern.</span><!-- mott2 -->';
    let gg = '#mottaker# ';
    txt = txt.replaceAll(ff, gg);
    return txt;
  };

  makeToolTipKontakt = () => {
    if (!this.props.data.kontakt) {
      return null;
    }
    if (this.props.data.kontakt.length > 1) {
      let s = '<b>Ved utskrift tas disse mottakere med:</b><br/>';
      for (let i = 0; i < this.props.data.kontakt.length; i++) {
        let kontakt = this.props.data.kontakt[i];
        debug('MODUS', kontakt);
        s = s + kontakt.navn + ' ( ' + kontakt.adresse + ' ' + kontakt.poststed+' )<br/>'
      }
      return s;
    }
  };

  render() {
    let s = this.makeToolTipKontakt();
    let z = { __html: s };
    let tooltip = <div dangerouslySetInnerHTML={z} />;
    return (
      <div style={{ width: '790px' }}>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{this.state.tittel}</h4>
        </div>

        <div
          style={{ marginTop: '2px', height: 'calc(100vh - 160px)' }}
          className='panel panel-default'>
          <div className='panel-body'>
            {this.props.data.kontakt.length > 1 && (
              <div
                style={{
                  height: '34px',
                  paddingLeft: '20px',
                }}>
                <span style={{ color: 'blue' }}>
                  Brevet har flere mottakere. Alle kan bli med ved utskrift.
                </span>
                <span
                  className='textA'
                  style={{
                    marginLeft: '30px',
                    color: 'blue',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}>
                  {' '}
                  {/*   <NKIcon icon='bruker' size='1' color='nk-black' /> */}
                  Les mer..
                  <span style={{ zIndex: '9999' }} className='text-tooltipA'>
                    {tooltip}
                  </span>{' '}
                  {/* class="fa fa-users" */}
                </span>
              </div>
            )}
            <Editor
              ref={(el) => {
                this.editorRef = el;
              }}
              onChange={this.editorOnChange}
              fletteOptions={this.props.fletteOptions}
              model={this.props.data.malTekst}
              kontakt={this.props.data.kontakt}
              norkartbruker={this.props.norkartbruker}
            />
          </div>
        </div>
        {this.props.modus !== 'PDF' && (
          <div className='malModalFooter'>
            <button
              onClick={this.sendSvarUt}
              className='btn btn-primary pull-right'>
              Send til SvarUt
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default SkrivSvarutDialogStep2;
