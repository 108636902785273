import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import IconButton from '@mui/material/IconButton';
import NKIcon from 'nka-icons';
import './dialogs.css';

const ModalDialog = ({
  tittel,
  Content,
  isOpen,
  handleClose,
  visClose,
}) => {




  return (
    <Dialog
      open={isOpen}

      onClose={handleClose}>
      <DialogTitle>
        <span style={{ fontSize: '16px', paddingTop: '24px' }}>{tittel}</span>

           {/* Desrsom visClose, da viser vi en close-button X */}
           {visClose && (
          <span className='closeButton'>
            <IconButton
              aria-label='close'
              onClick={() => handleClose(null)}>
              <NKIcon icon='lukk' size='1' color='nk-black' />
            </IconButton>
          </span>
        )}

      </DialogTitle>
      <DialogContent style={{ width: '400px' }}>
          
        
      <Content/>

      </DialogContent>
      
      
    
      <DialogActions style={{ padding: '0px', borderTop: '1px solid #51a026' }}>
        <NkaButton
         /*  style={{ height: '38px' }} */
          onClick={() => handleClose('ok')}
          buttontype='primary'
          autoFocus>
          <span>OK</span>
        </NkaButton>
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose(null)}
          buttontype='default'>
          Avbryt
        </NkaButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;

