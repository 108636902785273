import { REQUEST_STATES } from 'redux/constants';

import * as appActionTypes from 'redux/app/actionTypes';
import * as planleggingActionTypes from 'redux/planlegging/actionTypes';
import * as gjennomforingActionTypes from 'redux/gjennomforing/actionTypes';
import {
    debugFactory
} from 'utils';

const debug = debugFactory('nk:redux:crossslicereducer');

const getStateWithResetFetchStates = (state, fetchKeysToReset) => {

    debug('getStateWithResetFetchStates', { fetchKeysToReset });

    const newState = {
        ...state
    };
    const fetchStates = newState.app.fetchStates;

    Object.assign(fetchStates, fetchKeysToReset.reduce((acc, fetchKey) => ({
        ...acc,
        [fetchKey]: REQUEST_STATES.NOT_STARTED,
    }), {}));

    debug('getStateWithResetFetchStates', { newState });

    return newState;
};

export default (state, action) => {
    switch (action.type) {
        case planleggingActionTypes.ARBEIDSLISTE_UPDATED:
            return getStateWithResetFetchStates(state, [
                planleggingActionTypes.ARBEIDSLISTE_ENHETER_FETCHED,
                gjennomforingActionTypes.ARBEIDSLISTER_FETCHED,
                gjennomforingActionTypes.ARBEIDSLISTE_ENHETER_FETCHED,
                gjennomforingActionTypes.SITUASJON_FETCHED,
                gjennomforingActionTypes.ARBEIDSLISTE_SELECTED,
                gjennomforingActionTypes.ENHET_SELECTED,
            ]);
        case gjennomforingActionTypes.ARBEIDSLISTE_UPDATED:
            return getStateWithResetFetchStates(state, [
                planleggingActionTypes.ARBEIDSLISTER_FETCHED,
                planleggingActionTypes.ARBEIDSLISTE_ENHETER_FETCHED,
                planleggingActionTypes.ARBEIDSLISTE_TILTAKSLISTER_FETCHED,
                planleggingActionTypes.ARBEIDSLISTE_SELECTED,
            ]);
        case appActionTypes.RESET_FETCH_STATES:
            return getStateWithResetFetchStates(state, action.payload);
        default: {
            return state;
        }
    }
}