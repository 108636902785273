import React from 'react';
import Prosesslogg from './Prosesslogg';

export default class extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
      super(props);
    }

    render() {
        return ( 
            <Prosesslogg type = 'bruker'/>
        );
    }
}


