import * as actionTypes from './actionTypes';

const setIsCached = (state, key, boolIsCached = true) => ({
    ...state.isCached,
    [key]: boolIsCached,
});

const initialState = {
    isCached: {},
    aapnepubliserteavvik: [],
    ikkepubliserteavvik: [],
    lastbruker_ikkepubliserteavvik: null,
    lastbruker_aapnepubliserteavvik: null,
    brukere: {},
    editortekst_aapneavvik: { tekst: '', id: 0 }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AAPNEPUBLISERTEAVVIK_FETCHED:
            return {
                ...state,
                aapnepubliserteavvik: (action.payload),
                isCached: setIsCached(state, action.type),
            };
        case actionTypes.IKKEPUBLISERTEAVVIK_FETCHED:
            return {
                ...state,
                ikkepubliserteavvik: (action.payload),
                isCached: setIsCached(state, action.type),
            };
        case actionTypes.SET_LASTBRUKER_IKKEPUBLISERTEAVVIK:
            return {
                ...state,
                lastbruker_ikkepubliserteavvik: (action.payload)
            };
        case actionTypes.SET_LASTBRUKER_AAPNEAVVIK:
            return {
                ...state,
                lastbruker_aapnepubliserteavvik: (action.payload)
            };
        case actionTypes.BRUKERE_FETCHED:
                return {
                ...state,
                brukere: action.payload,
                };
        case actionTypes.SET_EDITORTEKST_AAPNEAVVIK:
                    return {
                        ...state,
                        editortekst_aapneavvik: (action.payload)
                    };
        default: {
            return state;
        }
    }
};