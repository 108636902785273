import React from 'react';
import './AvvikAnmerkninger.css';
import ReactTable from '@norkart/react-table';
import {
  getHurtigdeler,
  nyHurtigdel,
  oppdaterHurtigdel,
  slettHurtigdel,
} from 'api/malerApi';
import NKIcon from 'nka-icons';
import NKModal from 'components/Dialogs/NKModal.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
import RedigerTekstbit from './RedigerTekstbit.jsx';
import ContextMenu from 'components/ContextMenu';

const debug = debugFactory('nk:Editor');

const modalDialogs = {
  SletteDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Slette'
      melding='Ønsker du å slette teksten?'
      isOpen={isOpen}
      onClose={onClose}
      onOk={owner.slettOk.bind(this)}
    />
  ),
  RedigerDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <RedigerTekstbit
          onClose={onClose}
          lagreTekstbit={owner.lagreTekstbit}
          tittel='Rediger tekstbit'
          bit={dialogData.bit}
        />
      </NKModal>
    );
  },
};

class SoekTekstbit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tittel: 'Søk i tekstbiter for sms',
      tekstData: [],
      tekstFilterData: [],
      soktekst: '',
      slettId: null,
      hideNull: 'hide',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    this.hentData();
  }

  hentData= () => {
    getHurtigdeler(this.gotTekstbiter);
  }

  gotTekstbiter= (err, txtData) => {
    debug('HURTIGX', txtData);
    this.setState({ tekstData: txtData });
    this.setState({ tekstFilterData: txtData });
  }

  sok(event) {
    var soktekst = event.target.value;
    this.setState({ soktekst: soktekst });
    var matches = [];
    if (soktekst.length > 0) {
      this.state.tekstData.forEach(function(a) {
        if (a.tekst) {
          if (
            a.tekst.toLowerCase().indexOf(soktekst.toLowerCase()) > -1 ||
            a.id.toString() === soktekst
          ) {
            matches.push(a);
          }
        }
      });
      this.setState({ tekstFilterData: matches });
    } else {
      this.setState({ tekstFilterData: this.state.tekstData });
    }
   
  }

  green(txt) {
    let s = this.state.soktekst;
    let x = '<mark>' + s + '</mark>';
    let nyText = txt.toLowerCase().replace(new RegExp(s, 'g'), x);
    return { __html: nyText };
  }

  slettSoekTekst() {
    this.refs.sokboks.value = '';
    this.setState({ tekstFilterData: this.state.tekstData });
    this.refs.sokboks.focus();
  }

  lagreTekstbit = (bit) => {
   debug('PLOKK lagre', bit);
   let obj = {
     id: bit.id,
     tittel: bit.tittel,
     tekst: bit.tekst
   };
    oppdaterHurtigdel(bit.id, obj, this.oppdaterEtterRediger);
    this.dialogs.closeDialog();
  };

  oppdaterEtterRediger = (err, data) => {
    debug('HHH', data);
    this.hentData();
  };

  rediger= (tekst) => {
    debug('PLOKK rediger', tekst);
    this.dialogs.setOpenDialog('RedigerDialog', { bit: tekst });
  }

  slett= (tekst) => {
    debug('PLOKK slett', tekst);
    this.setState({ slettId: tekst.id });
    this.dialogs.setOpenDialog('SletteDialog');
  }

  brukTekst= (tekst) => {
    debug('PLOKK 3', tekst.tekst);
    this.props.onVelgTekst(tekst.tekst);
  }

  slettOk= () => {
    this.dialogs.closeDialog();
    this.setState({ soktekst: '' });
    slettHurtigdel(this.state.slettId, this.hentData);
    this.setState({ slettId: null });
  }




  getColumns = (that) => {
    let columns = [
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        isSorted: true,
        sortDirection: 'asc',
      },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        isSorted: false,
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => this.rediger(item)}>
                Rediger 
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => this.slett(item)}>
                Slett
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
    ];
    return columns;
  };

  render() {
    return (
      <div className='modal-content height700'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {this.state.tittel} </h4>
        </div>
        <div className='modal-body'>
          <div>
            <input
              autoFocus
              type='text'
              style={{ fontSize: '16px' }}
              ref='sokboks'
              className='form-control w95c hh36 samme'
              placeholder='Søk...'
              onChange={this.sok.bind(this)}
            />
            <button
              style={{ height: '40px' }}
              className='btn btn-default samme marginBottom2'
              onClick={this.slettSoekTekst.bind(this)}>
              <span className='glyphicon glyphicon-erase' />
            </button>
          </div>
          <br />
          <label className={this.state.hideNull}>
            Ingen tekstbiter definert enda..{' '}
          </label>
          <ReactTable
            items={this.state.tekstFilterData}
            idProperty='id'
            showIndex={false}
            filterable={true}
            columns={this.getColumns(this)}             
             onRowClick={row => this.brukTekst( row )} 
          />
          {/*  <div className='scrollerTekster'>
            <table style={{ width: '100%' }}>
              <tbody>
                <>
                  {this.state.tekstFilterData.map((text) => (
                    <tr>
                      <td
                        style={{
                          borderBottom: 'thin solid gray',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                        }}>
                        {text.tittel}
                      </td>
                      <td
                        style={{
                          borderBottom: 'thin solid gray',
                          width: '4px',
                          whiteSpace: 'nowrap',
                        }}
                      />
                      <td
                        style={{
                          borderBottom: 'thin solid gray',
                          width: '99%',
                        }}>
                        {text.tekst}
                      </td>

                      <td
                        className='pull-right'
                        style={{ width: '40px', whiteSpace: 'nowrap' }}>
                        <button
                          className='btn btn-default h48'
                          onClick={this.slettTekst.bind(this, text.id)}>
                          <NKIcon
                            icon='soppelbotte'
                            size='0.8'
                            color='nk-black'
                          />
                        </button>
                      </td>

                      <td
                        className='pull-right'
                        style={{ width: '40px', whiteSpace: 'nowrap' }}>
                        <button
                          className='btn btn-default h48'
                          onClick={this.redigerTekst.bind(this, text.tekst)}>
                          <NKIcon icon='rediger' size='0.8' color='nk-black' />
                        </button>
                      </td>

                      <td
                        className='pull-right'
                        style={{ width: '40px', whiteSpace: 'nowrap' }}>
                        <button
                          className='btn btn-default h48'
                          onClick={this.slettTekst.bind(this, text.id)}>
                          <NKIcon icon='velg' size='0.8' color='nk-black' />
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
          </div> */}
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SoekTekstbit;
