import React from 'react';
import arkivApi from 'api/arkivApi';
import NyArkivInnstilling from './NyArkivInnstilling.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import DialogStore from 'lib/DialogStore';
import { uuid } from 'utils';
import { withRouter } from 'lib/enhancers';

const modalDialogs = {
  NyArkivInnstilling: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" isOpen={isOpen} onClose={onClose}>
      <NyArkivInnstilling
        onClose={onClose}
        nyLagret={owner.nyLagret}
        type={dialogData.type}
        valgt={dialogData.valgt}
      />
    </NKModal>
  )
};

class ArkivInnstillinger extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {
      innstillinger: null,
      valgt: null,
      isLoading: true
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  componentDidMount() {
    this.hentData();
  }

  hentData = () => {
    arkivApi.getArkivInnstillinger(uuid(), this.gotInnstillinger);
  }

  gotInnstillinger= (err, innstillinger) => {
    console.log(err);
    console.log(innstillinger.statusText);
    if (
      innstillinger === null ||
      innstillinger === undefined ||  
      innstillinger.statusText === 'No Content'
    ) {
      this.setState({ isLoading: false, innstillinger: null });
    } else {
      this.setState({ isLoading: false });
      this.setState({ innstillinger: innstillinger });
      this.setState({ KundeId: innstillinger.KundeId });
      this.setState({ Arkivsystem: innstillinger.Arkivsystem });
      this.setState({ Fagsystem: innstillinger.Fagsystem });
      //this.setState({ Dokumenttype: innstillinger.Dokumenttype });
      //this.setState({ Landkode: innstillinger.Landkode });
      this.setState({ Klientnavn: innstillinger.Klientnavn });
      this.setState({ Klientversjon: innstillinger.Klientversjon });

      if (
        innstillinger.KorrespondanseparttypeMapping !== null &&
        innstillinger.KorrespondanseparttypeMapping !== undefined
      ) {
        this.setState({
          EksternAvsenderType:
            innstillinger.KorrespondanseparttypeMapping.EksternAvsenderType
        });
        this.setState({
          EksternMottakerType:
            innstillinger.KorrespondanseparttypeMapping.EksternMottakerType
        });
        this.setState({
          InternAvsenderType:
            innstillinger.KorrespondanseparttypeMapping.InternAvsenderType
        });
        this.setState({
          InternMottakerInngåendeJpType:
            innstillinger.KorrespondanseparttypeMapping
              .InternMottakerInngåendeJpType
        });
        this.setState({
          InternMottakerInterntNotatType:
            innstillinger.KorrespondanseparttypeMapping
              .InternMottakerInterntNotatType
        });
        this.setState({
          InternMottakerType:
            innstillinger.KorrespondanseparttypeMapping.InternMottakerType
        });
      }
    }
  }

  submit= () => {
    arkivApi.getArkivInnstillinger(uuid(), this.gotInnstillinger);
  }
  nyLagret= () => {
    arkivApi.getArkivInnstillinger(uuid(), this.gotInnstillinger);
    this.dialogs.closeDialog();
  }
  
  render() {
    if (this.state.isLoading) {
      return <div className="loader" />;
    }
    return (
      <div>
        <div className="form">
          <h2>Arkivinnstillinger</h2>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Arkivsystem</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Arkivsystem}
                  readOnly
                />
              </div> 
              
              <div className="form-group">
                <label>Fagsystem</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Fagsystem}
                  readOnly
                />
              </div>
              {/* <div className="form-group">
                <label>Dokumenttype</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Dokumenttype}
                  readOnly
                />
              </div> */}
              {/* <div className="form-group">
                <label>Landkode</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Landkode}
                  readOnly
                />
              </div> */}

              <h3>Korrespondansepart mapping</h3>
              <div className="form-group">
                <label>EksternAvsenderType</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.EksternAvsenderType}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>EksternMottakerType</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.EksternMottakerType}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>InternAvsenderType</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.InternAvsenderType}
                  readOnly
                />
              </div>
              {/* <div className="form-group">
                <label>InternMottakerInngåendeJpType</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.InternMottakerInngåendeJpType}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>InternMottakerInterntNotatType</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.InternMottakerInterntNotatType}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>InternMottakerType</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.InternMottakerType}
                  readOnly
                />
              </div> */}

              <hr />
              <div className="form-group">
                <label>Klientnavn</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Klientnavn}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Klientversjon</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Klientversjon}
                  readOnly
                />
              </div>
              <hr />
              <div className="pull-right">
                <input
                  type="button"
                  className="btn btn-primary"
                  value="Endre innstillinger"
                  onClick={() =>
                    this.dialogs.setOpenDialog('NyArkivInnstilling', {
                      type: 'endre',
                      valgt: this.state.innstillinger
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(ArkivInnstillinger);


