import React from 'react';

export const RefinementOption = function(props) {  
  let label = '';    
  switch (props.label) {    
    case 'false':    
      label = 'Nei';
      break;
    case 'true':
      label = 'Ja';
      break;
    case 'False':    
      label = 'Nei';
      break;
    case 'True':
      label = 'Ja';
      break;
    default:
      label = 'Alle';
  }

  return (
    <div
      className={props.bemBlocks
        .option()
        .state({ selected: props.selected })
        .mix(props.bemBlocks.container('item'))}
      onClick={props.onClick}>
      <div className={props.bemBlocks.option('text')}>{label}</div>
      <div className={props.bemBlocks.option('count')}>{props.count}</div>
    </div>
  );
};
