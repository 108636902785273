import React from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import {
  LastnedalleEksempelmaler,
  LastnedalleEksempelmalerSms,
} from 'api/malerApi';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import Spinner from 'components/generics/Spinner';

const debug = debugFactory('nk:Alleeksempelmaler');

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        tittel='Laste inn?'
        isOpen={isOpen}
        onClose={onClose}
        melding={'Er du sikker at du ønsker å laste ned alle eksempelmaler?'}
        onJa={owner.brukJa}
        onNei={onClose}
      />
    );
  },
};

class AlleEksempelDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {};

  brukJa = () => {
    this.dialogs.closeDialog();
    this.setState({ isLoading: true });
    if (this.props.modus === 'SMS') {
      LastnedalleEksempelmalerSms(this.ferdigLastet);
    } else {
      LastnedalleEksempelmaler(this.ferdigLastet);
    }

    setTimeout(() => {
      this.ferdigLastet();
    }, 6000);
  };

  ferdigLastet = (err, data) => {
    this.setState({ isLoading: false });
    this.props.etterLastingAvMaler();
  };

  lastNed = () => {
    this.dialogs.setOpenDialog('JaNeiDialog');
  };

  render() {
    return (
      <div style={{ height: '600px' }} className='modal-content'>
        {this.state.isLoading && (
          <React.Fragment>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <span
              style={{
                marginLeft: '160px',
                color: 'blue',
                fontStyle: 'Italic',
              }}>
              Vent, eksempelmalene blir lastet inn..
            </span>
            <Spinner className='h4' />
          </React.Fragment>
        )}
        {!this.state.isLoading && (
          <React.Fragment>
            <div className='modal-header'>
              <button
                className='close'
                type='button'
                onClick={this.props.onClose}>
                <span>x</span>
                <span className='sr-only'>Lukk</span>
              </button>
              <h4 className='modal-title'>
                {' '}
                Last inn alle eksempelmaler for {this.props.modus}
              </h4>
            </div>
            <div
              className='modal-body'
              style={{ height: '470px', padding: '20px' }}>
              <span style={{ color: 'blue' }}>
                Det er mulig å laste inn alle eksempelmaler for "
                {this.props.modus}" på en gang.
              </span>
              <br />
              <br />
              <br />
              <span style={{}}>Etter gjennomføring av prosedyren..</span>
              <br />
              <br />
              <ul>
                {this.props.modus === 'Dokument' && (
                  <li>
                    må du spesifisere <b>tiltakstype(r)</b> selv (per mal). *)
                  </li>
                )}

                <li>
                  kan du redigere malene etter behov
                  <br />
                  (i innstillinger &#8594; maloppsett).
                </li>
                <li>
                  kan du slette maler som ikke trenges
                  <br />
                  (i innstillinger &#8594; maloppsett).
                </li>
              </ul>
              <br />
              <br />
              {this.props.modus === 'Dokument' && (
                <span style={{ fontSize: '12px' }}>
                  *) Brannforebygging vet ikke hvilke tiltakstyper
                  organisasjonen har definert.
                </span>
              )}
            </div>
            <div className='modal-footer'>
              <button
                onClick={this.lastNed}
                className='btn btn-primary pull-right'>
                OK, last inn
              </button>
            </div>
          </React.Fragment>
        )}
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default AlleEksempelDialog;
