import React, { useState, useEffect } from 'react';
import { getNorkartBrukere, updatePersonAdminNorkart } from 'api/personApi';
import { promisifyApiCall } from 'utils';
import NkaFormElementDropdown from 'nka-form-element-dropdown';
import 'nka-form-element-dropdown/dist/style.css';
import NKAInput from 'nka-input';
//import NKARadioButtons from 'nka-radio-buttons';
import 'nka-input/dist/style.css';
import NKACheckbox from 'nka-checkbox';
import 'nka-checkbox/dist/style.css';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import './test.css';

function TestFunc2() {
  const [isLoading, setIsLoading] = useState(true);
  const [personer, setPersoner] = useState([]); //personer i rullegardin
  const [valgtPerson, setValgtPerson] = useState(); //valgt person i rullegardin

  useEffect(() => {
    //litt som componentDidMount
    console.log('PPPP useEffect slår til, slik som render');
    if (isLoading) {
      // ellers blir det en infin. loop
      hentData();
    }
  });

  const hentData = () => {
    setIsLoading(false);

    promisifyApiCall(getNorkartBrukere)().then((personer) => {
      //Utvider tabellen med 2 properties ifm. rullegaqrdin
      for (let i = 0; i < personer.length; i++) {
        const rad = personer[i];
        rad.value= rad.id;
        rad.label=rad.navn;
      }
      setPersoner(personer);
    });
  };

  const handlePersonerChange = (e) => {
    setValgtPerson(e);
  };

  const handleInputChange = (key, evt) => {  //Til alle text-input
    setValgtPerson({ ...valgtPerson, [key]: evt.target.value });
};

const handleCheckChange = (key, evt) => {  //Til alle checkbox-input
  setValgtPerson({ ...valgtPerson, [key]: evt.target.checked });
};

const onLagreClick = () => {
   promisifyApiCall(updatePersonAdminNorkart)(valgtPerson.id, valgtPerson).then((p) => {
    //Evt:
    hentData();
    setValgtPerson(null);
  }); 
};

  return (
    <div style={{ marginLeft: '24px' }}>
      <h3>Test funct.comp. Form / Objekt</h3>
      <br />
      <div style={{ width: 400 }}>
        <NkaFormElementDropdown
          handleValueChange={handlePersonerChange}
          value={valgtPerson}
          values={personer}
          other={{ placeholder: 'Velg person' }}
        />
      </div>

      <p />
      {(valgtPerson)  && (
        <div>
          <h3>Rediger</h3>
        <p>
     
          <span>Navn</span>
          <br />
          <NKAInput
            style={{ width: '400px' }}
            color='nk-white'
            placeholder='Navn'
            value={valgtPerson.navn}
            onChange={(evt) => handleInputChange('navn', evt)}
          />
          <br/>
         <span>Mobil</span>
          <br />
          <NKAInput
            style={{ width: '200px' }}
            color='nk-white'
            placeholder='Mobil'
            value={valgtPerson.mobil}
            onChange={(evt) => handleInputChange('mobil', evt)}
          />
          <br/>
          <span>Epost</span>
          <br />
          <NKAInput
            style={{ width: '400px' }}
            color='nk-white'
            placeholder='Epost'
            value={valgtPerson.epost}
            onChange={(evt) => handleInputChange('epost', evt)}
          /> 
          <br/><br/>
          <NKACheckbox id="pilotCheck"  checked={valgtPerson.pilotBruker} 
                  onChange={(evt) => handleCheckChange('pilotBruker', evt)}>
                Pilotbruker
            </NKACheckbox>  
            <br/>
          <NKACheckbox id="pilotCheck"  checked={valgtPerson.visningKartStandard} 
                  onChange={(evt) => handleCheckChange('visningKartStandard', evt)}>
                Gjennomføring: Vis kart som standard 
            </NKACheckbox>
        </p>
        <br/>
        <NkaButton onClick={onLagreClick} buttontype="primary" >Lagre</NkaButton>
      </div>
      )}
      
    </div>
  );
}

export default TestFunc2;
