import reqwest from 'reqwest';
import CONFIG from 'lib/CONFIG';
import { exceptionOccured } from 'redux/boundActions';
import HandleErrors from './errorHandler.js';
import norkartIdJs from 'auth/auth';

var BASE_URL = CONFIG.REACT_APP_ARKIVAPI_ENDPOINT;
let profile = CONFIG.REACT_APP_WAAPI_ARKIVPROFILE;

if (!BASE_URL) {
    throw new Error('Missing required ARKIV_APP_API_URL config');
}

const getCurrentCustomerId = () => {
    let cust = norkartIdJs.getCurrentCustomerContext();
    return cust !== undefined ? cust.CustomerId : '';
};

const makeCall = function(method, path, data, callback, token) {
    var headers = {
        'Accept': 'application/json; charset=utf-8'
    };

    if (token) {
        headers['Authorization'] = 'Bearer ' + token;
        headers['X-WAAPI-Profile'] = profile;
        headers['X-WAAPI-CONTEXT'] = getCurrentCustomerId();
    }

    if (data) {
        data = JSON.stringify(data);
    }
    reqwest({
        url: BASE_URL + path,
        crossOrigin: true,
        type: 'json',
        method: method,
        contentType: 'application/json; charset=utf-8',
        headers: headers,
        data: data,
        error: function (err) {
            exceptionOccured({ err, method, path, data, callback });
            try {
                HandleErrors(err); 
                callback(JSON.parse(err.response));
            } catch (e) {                                
                HandleErrors(err);     
                callback(err);
                       
            }
        },
        success: function (resp) {
            callback(null, resp);            
        }
    });
};

export default function callArkivApi(method, path, data, callback) {
    // auth.getToken(function (err, token) {
    //     if (err) {            
    //         callback(err);
    //         return;
    //     }
    //     makeCall(method, path, data, callback, token);

    // }, BASE_URL + path);
    let burl = BASE_URL;
    if (path === '/status/system'){
        var to = burl.lastIndexOf('/');
        to = to == -1 ? burl.length : to + 1;
        burl = burl.substring(0, to - 1);
        //debug('/status/system basisurl 1', burl);
    }
    norkartIdJs.getToken().then(token => {
        makeCall(method, path, data, callback, token);
    }).catch(err => callback(err));
};