import { NorkartIdJsMsal } from 'norkartidjs2';
import CONFIG from 'lib/CONFIG';
let profile = CONFIG.REACT_APP_PROFILE;

const norkartIdJs = new NorkartIdJsMsal({
    clientId: 'a51bdb9b-ec96-4fce-b1e9-c6c3240792a8',
    profile: profile,
    useApplicationBackend: true,
    postLogoutUrl: window.location.origin,
    cacheLocation: 'localStorage',
    useRedirectFlow: true,
    useMultiCustomerContext: true
});
export default norkartIdJs;

export const getAccessToken = () => {
return localStorage.getItem("msal.a51bdb9b-ec96-4fce-b1e9-c6c3240792a8.idtoken");
};