

export const harGebyrOgReplikk = (gebyrInnstillinger,  matrikkelId) => {
  let res = false;
  if (matrikkelId && gebyrInnstillinger.gebyrkobling) {
    let replikk = '';
  //  let kommuneNr = '';
    let komNr = matrikkelId.substring(0, 4);
    let kommuneListe = gebyrInnstillinger.kommuner;
    if (kommuneListe){
      for (let i = 0; i < kommuneListe.length; i++) {
        if (kommuneListe[i].kommuneId.toString() === komNr){
          replikk = kommuneListe[i].replikKundeId;
         // kommuneNr = kommuneListe[i].kommuneId.toString();
        }
      }
    }

    if (!replikk){
      replikk = '';
    }
    res = replikk !== '';
  }
  return res;
}; 


