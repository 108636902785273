import React, { Component } from 'react';
import BasisKart from 'components/Kart/BasisKart.jsx';

class KartDialog extends Component {

  render() {
    return (
      <div>
        <div className="modal-header">
          <button className="close" onClick={this.props.onClose} type="button">
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">{this.props.tittel}</h4>
        </div>
        <div>
          <BasisKart
            kartClassName= 'kartenheterFxModal'
            data={this.props.data}
            arbeidsliste={this.props.arbeidsliste}
            itemid={this.props.itemid}
            visning={this.props.visning}  
            adresse={this.props.adresse}
            eiendom={this.props.eiendom}
            objektIn={this.props.objektIn}
            objektUt={this.props.objektUt}
            minimap={this.props.minimap}
          />
        </div>
      </div>
    );
  }
}

export default KartDialog;
