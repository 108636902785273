import React from 'react';
import Oppslagsdata from './Oppslagsdata.jsx';

export default function(props) {

    var path = props.route.path;
    return (
        <div>
            <Oppslagsdata/>
        </div>
    );
}