import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import $ from 'jquery';

class FullscreenControl extends Component {
 
  fullskjerm = () => {
    $('.leaflet-control-zoom-fullscreen')[0].click();
  };

  render() {
    return (
      <div>
        {/* Fullscreen*/}
        <Control position="topright">
          <div>
             <p />
            <button
              type="button"
              className={'btnLag'}
              onClick={this.fullskjerm}
              title="Full skjerm">
              <NKIcon icon="fullskjerm" color="nk-black" />
            </button>
          </div>
        </Control>
      </div>
    );
  }
}

export default FullscreenControl;
