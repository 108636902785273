import React from 'react';
import SvarUtLogg from 'modules/felles/svarut/SvarUtLogg.jsx';


class index extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }  
  render() {
    return <SvarUtLogg fra= {'fraInnstillinger'} alle = {true} org = {true}></SvarUtLogg>;
  }
}
export default index;