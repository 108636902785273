import {
  Accessor,
  TermsQuery,
  NestedQuery,
  BoolShould
} from 'searchkit';
import * as _ from 'lodash';

export default class PersonAccessor extends Accessor {
  setMatrikkelnumre(value) {
    this.matrikkelnumre = value;
  }

  setBruksenhetsIdenter(values) {
    this.bruksenhetsIdenter = values;
  }

  setPersonNavn(navn) {
    this.navn = navn;
  }

  buildSharedQuery(query) {
    let selectedFilters = [];
    selectedFilters.push({
      name: 'Eier',
      value: this.navn,
      id: 2,
      remove: () => {
        this.matrikkelnumre = null;
        this.navn = null;
        this.bruksenhetsIdenter = null;
      }
    });


    if (
      !_.isEmpty(this.bruksenhetsIdenter) &&
      !_.isEmpty(this.matrikkelnumre)
    ) {
      query = query
        .addFilter(
          'personId',
          BoolShould([
            TermsQuery('id', this.bruksenhetsIdenter),
            NestedQuery(
              'matrikkelenhet',
              BoolShould(TermsQuery('matrikkelenhet.id', this.matrikkelnumre))
            )
          ])

          )        
        .addSelectedFilters(selectedFilters);        
    }
    else if (!_.isEmpty(this.bruksenhetsIdenter)) {      
      query = query
        .addFilter(
          'bIdent',
          BoolShould(TermsQuery('id', this.bruksenhetsIdenter))
        )
        .addSelectedFilters(selectedFilters);        
    }
    else if (!_.isEmpty(this.matrikkelnumre)) {      
      query = query
        .addFilter(
          'personId',
          NestedQuery(
            'matrikkelenhet',
            BoolShould(TermsQuery('matrikkelenhet.id', this.matrikkelnumre))
          )
        )
        .addSelectedFilters(selectedFilters);
    }
    return query;
  }
}
