import React from 'react';
import {
  uuid
} from 'utils' ;
var arkivApi = require('api/arkivApi');

let korrespondanseparttypeMapping = {
  EksternMottakerType: null,
  EksternAvsenderType: null,
  //InternMottakerType: null,
  InternAvsenderType: null,
  // InternMottakerInngåendeJpType: null,
  // InternMottakerInterntNotatType: null
};

class NyArkivInnstilling extends React.Component {
  constructor(props) {
    super(props);
    this.handleKundeIdChange = this.handleKundeIdChange.bind(this);
    this.handleArkivChange = this.handleArkivChange.bind(this);
    this.handleFagsystemChange = this.handleFagsystemChange.bind(this);

    //this.handleDokumenttypeChange = this.handleDokumenttypeChange.bind(this);
    this.handleKlientnavnChange = this.handleKlientnavnChange.bind(this);
    this.handleKlientversjonChange = this.handleKlientversjonChange.bind(this);
    this.handleEksternAvsenderTypeChange = this.handleEksternAvsenderTypeChange.bind(
      this
    );
    this.handleEksternMottakerTypeChange = this.handleEksternMottakerTypeChange.bind(
      this
    );
    this.handleInternAvsenderTypeChange = this.handleInternAvsenderTypeChange.bind(
      this
    );
    // this.handleInternMottakerTypeChange = this.handleInternMottakerTypeChange.bind(
    //   this
    // );
    // this.handleInternMottakerInngaendeJpTypeChange = this.handleInternMottakerInngaendeJpTypeChange.bind(
    //   this
    // );
    // this.handleInternMottakerInterntNotatTypeChange = this.handleInternMottakerInterntNotatTypeChange.bind(
    //   this
    // );
    this.nyinnstillingLagret = this.nyinnstillingLagret.bind(this);
    this.onLagre = this.onLagre.bind(this);
    this.innstillingLagret = this.innstillingLagret.bind(this);
    this.state = {
      innstillinger: this.props.valgt,
      // kundeId: this.props.valgt != null ? this.props.valgt.KundeId : null,
      arkiv: this.props.valgt != null ? this.props.valgt.Arkivsystem : null,
      AddAuthorizationHeader: this.props.valgt != null ? this.props.valgt.AddAuthorizationHeader : null,
      
      fagsystem: this.props.valgt != null ? this.props.valgt.Fagsystem : null,
      domain:
        this.props.valgt != null ? this.props.valgt.WebserviceDomene : null,
      
      // dokumenttype:
      //   this.props.valgt != null ? this.props.valgt.Dokumenttype : null,
      // landkode:
      //   this.props.valgt != null ? this.props.valgt.Landkode : null,
      klientNavn: this.props.valgt != null ? this.props.valgt.Klientnavn : null,
      klientVersjon:
        this.props.valgt != null ? this.props.valgt.Klientversjon : null,

      eksternMottakerType:
        this.props.valgt === null ||
        this.props.valgt.KorrespondanseparttypeMapping === null ||
        this.props.valgt.KorrespondanseparttypeMapping === undefined
          ? null
          : this.props.valgt.KorrespondanseparttypeMapping.EksternMottakerType,
      eksternAvsenderType:
        this.props.valgt === null ||
        this.props.valgt.KorrespondanseparttypeMapping === null ||
        this.props.valgt.KorrespondanseparttypeMapping === undefined
          ? null
          : this.props.valgt.KorrespondanseparttypeMapping.EksternAvsenderType,
      // internMottakerType:
      //   this.props.valgt === null ||
      //   this.props.valgt.KorrespondanseparttypeMapping === null ||
      //   this.props.valgt.KorrespondanseparttypeMapping === undefined
      //     ? null
      //     : this.props.valgt.KorrespondanseparttypeMapping.InternMottakerType,
      internAvsenderType:
        this.props.valgt === null ||
        this.props.valgt.KorrespondanseparttypeMapping === null ||
        this.props.valgt.KorrespondanseparttypeMapping === undefined
          ? null
          : this.props.valgt.KorrespondanseparttypeMapping.InternAvsenderType,
      // internMottakerInngåendeJpType:
      //   this.props.valgt === null ||
      //   this.props.valgt.KorrespondanseparttypeMapping === null ||
      //   this.props.valgt.KorrespondanseparttypeMapping === undefined
      //     ? null
      //     : this.props.valgt.KorrespondanseparttypeMapping
      //         .InternMottakerInngåendeJpType,
      // internMottakerInterntNotatType:
      //   this.props.valgt === null ||
      //   this.props.valgt.KorrespondanseparttypeMapping === null ||
      //   this.props.valgt.KorrespondanseparttypeMapping === undefined
      //     ? null
      //     : this.props.valgt.KorrespondanseparttypeMapping
      //         .InternMottakerInterntNotatType
    };
    console.log(this.props.valgt);
  }

  handleKundeIdChange(val) {
    this.setState({ kundeId: val.target.value });
  }
  handleArkivChange(val) {
    this.setState({ arkiv: val.target.value });
  }

  handleFagsystemChange(val) {
    this.setState({ fagsystem: val.target.value });
  }
  
  // handleDokumenttypeChange(val) {
  //   this.setState({ dokumenttype: val.target.value });
  // }
  handleKlientnavnChange(val) {
    this.setState({ klientNavn: val.target.value });
  }
  handleKlientversjonChange(val) {
    this.setState({ klientVersjon: val.target.value });
  }
  handleEksternAvsenderTypeChange(val) {
    this.setState({ eksternAvsenderType: val.target.value });
  }
  handleEksternMottakerTypeChange(val) {
    this.setState({ eksternMottakerType: val.target.value });
  }
  handleInternAvsenderTypeChange(val) {
    this.setState({ internAvsenderType: val.target.value });
  }
  // handleInternMottakerTypeChange(val) {
  //   this.setState({ internMottakerType: val.target.value });
  // }
  // handleInternMottakerInngaendeJpTypeChange(val) {
  //   this.setState({ internMottakerInngåendeJpType: val.target.value });
  // }
  // handleInternMottakerInterntNotatTypeChange(val) {
  //   this.setState({ internMottakerInterntNotatType: val.target.value });
  // }

  // handleLandkodeChange = (val) => {
  //   this.setState({ landkode: val.target.value });
  // }

  onLagre() {
    console.log(this.props.valgt);
    if (this.props.valgt === null || this.props.valgt === undefined) {
      var nyInnstilling = {
        Arkivsystem: this.state.arkiv
      };
      arkivApi.nyArkivInnstilling(
        uuid(),
        nyInnstilling,
        this.nyinnstillingLagret
      );
    } else {
      var oppdatert = this.props.valgt;
      oppdatert.Arkivsystem = this.state.arkiv;
      oppdatert.Fagsystem = this.state.fagsystem;
      //oppdatert.Dokumenttype = this.state.dokumenttype;
      //oppdatert.Landkode = this.state.landkode;
      oppdatert.Klientnavn = this.state.klientNavn;
      oppdatert.Klientversjon = this.state.klientVersjon;
      let korrparter = korrespondanseparttypeMapping;
      korrparter.EksternMottakerType = this.state.eksternMottakerType;
      korrparter.EksternAvsenderType = this.state.eksternAvsenderType;
      korrparter.InternAvsenderType = this.state.internAvsenderType;
      // korrparter.InternMottakerType = this.state.internMottakerType;
      // korrparter.InternMottakerInngåendeJpType = this.state.internMottakerInngåendeJpType;
      // korrparter.InternMottakerInterntNotatType = this.state.internMottakerInterntNotatType;
      oppdatert.KorrespondanseparttypeMapping = korrparter;
      arkivApi.endreArkivInnstilling(uuid(), oppdatert, this.innstillingLagret);
    }

    // }
  }
  nyinnstillingLagret(err, nyInnstilling) {
    var oppdatert = nyInnstilling;
    oppdatert.Arkivsystem = this.state.arkiv;
    oppdatert.Fagsystem = this.state.fagsystem;
    //oppdatert.Dokumenttype = this.state.dokumenttype;
    oppdatert.Klientnavn = this.state.klientNavn;
    oppdatert.Klientversjon = this.state.klientVersjon;
    let korrparter = korrespondanseparttypeMapping;
    korrparter.EksternMottakerType = this.state.eksternMottakerType;
    korrparter.EksternAvsenderType = this.state.eksternAvsenderType;
    korrparter.InternAvsenderType = this.state.internAvsenderType;
    // korrparter.InternMottakerType = this.state.internMottakerType;
    // korrparter.InternMottakerInngåendeJpType = this.state.internMottakerInngåendeJpType;
    // korrparter.InternMottakerInterntNotatType = this.state.internMottakerInterntNotatType;
    oppdatert.KorrespondanseparttypeMapping = korrparter;
    arkivApi.endreArkivInnstilling(uuid(), oppdatert, this.innstillingLagret);
  }

  innstillingLagret() {
    this.props.nyLagret(this.state.kundeId);
  }

  render() {
    return (
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">
              {this.props.type === 'ny'
                ? 'Ny Arkivinnstilling'
                : 'Endre Arkivinnstilling'}
            </h4>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="row">
                <div className="col-sm-12">
                  {/* <div className="form-group">
                                        <label>KundeId</label>
                                        <input type="text" className="form-control" value={this.state.kundeId}
                                            onChange={this.handleKundeIdChange}/>
                                    </div> */}
                  <div className="form-group">
                    <label>Arkivsystem</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.arkiv}
                      onChange={this.handleArkivChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Fagsystem</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.fagsystem}
                      onChange={this.handleFagsystemChange}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>Dokumenttype</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.dokumenttype}
                      onChange={this.handleDokumenttypeChange}
                    />
                  </div> */}
                  {/* <div className="form-group">
                    <label>Landkode</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.landkode}
                      onChange={this.handleLandkodeChange}
                    />
                  </div> */}
                  <div className="form-group">
                    <label>Klientnavn</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.klientNavn}
                      onChange={this.handleKlientnavnChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Klientversjon</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.klientVersjon}
                      onChange={this.handleKlientversjonChange}
                    />
                  </div>
                  <p>Korrespondansepart mapping</p>
                  <div className="form-group">
                    <label>EksternAvsenderType</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.eksternAvsenderType}
                      onChange={this.handleEksternAvsenderTypeChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>EksternMottakerType</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.eksternMottakerType}
                      onChange={this.handleEksternMottakerTypeChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>InternAvsenderType</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.internAvsenderType}
                      onChange={this.handleInternAvsenderTypeChange}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>InternMottakerType</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.internMottakerType}
                      onChange={this.handleInternMottakerTypeChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>InternMottakerInngaendeJpType</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.internMottakerInngåendeJpType}
                      onChange={this.handleInternMottakerInngaendeJpTypeChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>InternMottakerInterntNotatType</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.internMottakerInterntNotatType}
                      onChange={this.handleInternMottakerInterntNotatTypeChange}
                    />
                  </div> */}
                  <hr />
                  <div className="pull-right">
                    <input
                      type="button"
                      className="btn btn-primary"
                      value="OK"
                      onClick={this.onLagre}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NyArkivInnstilling;
