import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';

import { debugFactory, objGetDeep } from 'utils';

export default function (props) {
    const pathname = objGetDeep(props, 'location.pathname');
    return (
        <div>
            <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-6">
                    <LinkList title="Risikovurdering" columns={1} >
                        <LinkListItem title="Tiltakstyper" path={`${pathname}/Tiltakstyper`} />
                        <LinkListItem title="Innstillinger" path={`${pathname}/Innstillinger`} />
                        {/* <LinkListItem title="Risiko matrise" path={`${pathname}/Matrise`} /> */}
                        
                    </LinkList>
                </div>
            </div>
        </div>
    );
}