import React, { useState, useImperativeHandle } from 'react';
import './BFButtonGroup.css';

/* Dersom vertikal, da vertikal= {true}
Dersom holde selected button, da select= {true}
Dersom bestemt width, da buttonWidth={'200px'} */
const BFButtonGroup = React.forwardRef(({ buttons, afterClick, vertikal, buttonWidth, select }, ref) => {

  useImperativeHandle(ref, () => ({
    setSelectBtnIndex,
  }));

  const [clickedId, setClickedId] = useState( select? 0 : -1);

  const setSelectBtnIndex = (btnIndex) => {
    setClickedId(btnIndex);
  };

  const handleClick = (id, name) => {
    if (select){
      setClickedId(id);
    }
    
    afterClick(id, name);
  };

  return (
    <>
      {!vertikal &&
        buttons.map((buttonLabel, i) => (
          <button
            
            style={{ marginRight: '4px', width: buttonWidth  }}
            key={i}
            name={buttonLabel}
            onClick={() => handleClick(i, buttonLabel)}
            className={i === clickedId ? 'bfButton bfValgt' : 'bfButton'}>
            {buttonLabel}
          </button>
        ))}

{vertikal &&
        buttons.map((buttonLabel, i) => (
          <>
          <button
            style={{ marginBottom: '4px',  width: buttonWidth  }}
            key={i}
            name={buttonLabel}
            onClick={() => handleClick(i, buttonLabel)}
            className={i === clickedId ? 'bfButton bfValgt' : 'bfButton'}>
            {buttonLabel}
          </button>
          <br/>
          </>
        ))}

    </>
  );
});

export default BFButtonGroup;
