import React from 'react';
import { isFetched } from 'redux/boundActions';
import { asRouteComponent } from 'components/Router';

import PageObjekter from './PageObjekter';
import PageTiltak from './PageTiltak';

// Searchkit
import searchkitManagerFactory from 'lib/searchkitManagerFactory';
import { reloadSearch } from 'utils/searchKitHelper';
import { getTranslations } from 'components/Search/translations';
import { CreateDefaultQueryMedUnummererte } from 'components/Search/DefaultQuery';
import { GeoAccessor } from 'components/Search/Map/GeoAccessor.jsx';
import { getGeoObjekt } from 'components/Search/GeoAccessorHelper';
import { getContextFilters } from 'context/contextFilters.js';
// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as innstillingerActions from 'redux/innstillinger/actions';
import * as innstillingerActionTypes from 'redux/innstillinger/actionTypes';
import * as planleggingActions from 'redux/planlegging/actions';
import * as planleggingActionTypes from 'redux/planlegging/actionTypes';
import * as planleggingSelectors from 'redux/planlegging/selectors.js';
// Utils
import _ from 'lodash';
import { brukBreadcrumb } from 'components/Breadcrumbs';
import { makeLockReasonsKey } from './lib';
import { getSelectedArbeidslistePlanlegging } from  'context/contextPlanlegging.js';
import {
  debugFactory,
  objGetDeep,
  objPickProps,
  objPickPropsDeep,
  getDistinctsByKeys,
  ifReloadHome,
} from 'utils';

const debug = debugFactory('nk:planlegging:arbeidsliste:leggtilobjekter:index');

const mapStateToProps = (state, ownProps) => {
  let newState = objPickPropsDeep(
    state,
    [
      'innstillinger.orgKommuner',
      'planlegging.tiltakstyper',
      'planlegging.selectedArbeidsliste',
      'planlegging.arbeidslisteEnheter',
      'planlegging.arbeidslisteTiltakLister',
      'planlegging.objektLockReasons',
    ],
    { targetKeysPrefix: 'store.' }
  );

  Object.assign(newState, {
    getTiltakListe: planleggingSelectors.makeGetTiltakListe(newState),
    getObjektLockReasons: planleggingSelectors.makeGetObjektLockReasons(
      newState
    ),
  });
  return newState;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...objPickProps(innstillingerActions, ['fetchOrgKommuner']),
      ...planleggingActions,
    },
    dispatch
  );

class LeggTilObjekter extends React.Component {
  state = {
    visLeggTilTiltak: false,
    filters: [],
    standardFilter: null,
    standardFilterId: 0,
    filtersInit: false,
    selectedArbeidsliste: null
  };

  constructor(props) {
    super(props);
    // Searchkit setup
    // var getPostFilter = this.setupPostFilter();
    ifReloadHome(props, 'planlegging');
    this.initierSearchKit();
  }

  componentDidMount() {
    getSelectedArbeidslistePlanlegging(this.gotSelectedArbeidsliste);
    getContextFilters(this.onGotFilters);
    this.props.initializeLeggTilObjekter();

    this.props.fetchOrgKommuner();
    this.props.fetchTiltakstyper();
    this.props
      .fetchArbeidslisteTiltakLister(this.props.params.arbeidslisteId)
      .then(this.setObjektLockReasonsFromTiltakLister)
      .then(this.props.takePageObjekterSnapshot);

    this.props
      .setSelectedArbeidsliste(this.props.params.arbeidslisteId)
      .then(this.ammendBreadCrumbs);
  }

  gotSelectedArbeidsliste = (selectedArbeidsliste) => {
      this.setState({ selectedArbeidsliste });
  };

  onGotFilters = (contextFilters) => {
    this.setState(
      {
        filtersInit: true,
        filters: contextFilters.filters,
        standardFilterId: contextFilters.standardFilterId,
        standardFilter: contextFilters.standardFilter,
      },
    );
  };

  componentWillUnmount() {
    // Constructor is run twice on first display after refresh (bad coding or stupid React), so
    // need to clear first readycheck interval
    this.searchkit.readyCheckClearInterval('componentWillUnmount');
  }

  initierSearchKit = () => {
    this.searchkit = searchkitManagerFactory({
      readyCheck: (searchkit) => {
        if (searchkit.accessors.accessors.length < 50) {
          /*
            HACK! - "Accessors" are all filters of any kind, so just wait for a threshold of them to 
            determine when to "be ready".
            Problem: Filter-components must be rendered for searchkit to post the right query, 
            but this takes time and looses the race when API data is cached. The effect is that 
            incomplete queries are requested.
            */
          return false;
        }

        return isFetched([
          innstillingerActionTypes.ORG_KOMMUNER_FETCHED,
          planleggingActionTypes.TILTAKSTYPER_FETCHED,
          planleggingActionTypes.ARBEIDSLISTE_TILTAKSLISTER_FETCHED,
        ]);
      },
      onceWhenReady: (searchkit) => {
        searchkit.translateFunction = getTranslations;
        searchkit.addDefaultQuery((query) => {
          return CreateDefaultQueryMedUnummererte(
            query,
            this.props.store.orgKommuner
          );
        });

        searchkit.addResultsListener(this.setObjektLockReasonsFromSearchResult);
        // Add geoAccessor
        const geoAccessor = new GeoAccessor();
        searchkit.accessors.add(geoAccessor);
        searchkit.geoAccessor = geoAccessor;
        // Her kommer personlig-standardFilter
      
        setTimeout(() => {
          this.brukLagretSok( searchkit, this.state.standardFilter );
        }, 1000);
      },
      debug,
    });
  };

  brukLagretSok = (searchkit, item) => {
    if (!item){
      return;
    }
    searchkit.getQueryAccessor().keepOnlyQueryState();
    searchkit.accessors.setState(JSON.parse(item.state));
    let geoAccessor = searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });

    if (item.polygon && geoAccessor) {
      let parsedPolygon = JSON.parse(item.polygon);
      let poly = getGeoObjekt(parsedPolygon.points);
      geoAccessor.setPolygon(null);
      geoAccessor.setPolygon(poly);
    } else if (!item.polygon && geoAccessor) {
      geoAccessor.setPolygon(null);
    }
    reloadSearch(searchkit);
  }

  ammendBreadCrumbs = () => {
    /*  brukBreadcrumbNameHelper(
      'Arbeidsliste',
      objGetDeep(this.props.store, 'selectedArbeidsliste.navn')
    ); */

    //============= REACT CONTEXT BREADCRUMBS  ====================
    brukBreadcrumb('Arbeidsliste', 'planlegging_arbeidsliste_navn');
  };

  onClose = () => {
    // Go back
/*     const newPathname = this.props.router.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');
    debug('close', {
      pathname: this.props.router.location.pathname,
      newPathname,
    });
    this.props.router.push(newPathname); */
    this.props.router.push('/planlegging/' +  this.state.selectedArbeidsliste.id);
  };

  onSave = () => {
    // Save and go back
    const {
      tiltakTypeBruksenhetListe = [],
      tiltakTypeRoyklopListe = [],
    } = objGetDeep(this, 'props.store.arbeidslisteTiltakLister', {});

    const payload = {
      tiltakTypeBruksenhetListe: tiltakTypeBruksenhetListe.map((objekt) => ({
        arbeidslisteId: this.state.selectedArbeidsliste.id,
        ...objPickProps(objekt, [
          'adresse',
          'lokasjonLatitude',
          'lokasjonLongitude',
          'bruksenhetId',
          'bruksenhetIdMa',
          'arbeidslisteId',
          'tiltakTypeId',
          'tiltakTypetekst',
          'rekkefolge',
          'ny',
          'slett',
          'endre',
          'varselSendt',
        ]),
      })),

      tiltakTypeRoyklopListe: tiltakTypeRoyklopListe.map((objekt) => ({
        arbeidslisteId: this.state.selectedArbeidsliste.id,
        ...objPickProps(objekt, [
          'adresse',
          'lokasjonLatitude',
          'lokasjonLongitude',
          'bruksenhetId',
          'bruksenhetIdMa',
          'royklopId',
          'arbeidslisteId',
          'tiltakTypeId',
          'tiltakTypetekst',
          'rekkefolge',
          'ny',
          'slett',
          'endre',
          'varselSendt',
          'bruksenhetIdDelerLop',
        ]),
      })),
    };

    // Remove duplicates
    payload.tiltakTypeBruksenhetListe = getDistinctsByKeys(
      payload.tiltakTypeBruksenhetListe,
      ['bruksenhetId', 'tiltakTypeId']
    );
    payload.tiltakTypeRoyklopListe = getDistinctsByKeys(
      payload.tiltakTypeRoyklopListe,
      ['royklopId', 'tiltakTypeId']
    );

    this.props.saveArbeidslisteTiltakLister(payload).then(this.onClose);
  };

  setObjektLockReasonsFromSearchResult = (result) => {
    if (!objGetDeep(result, 'hits.hasChanged')) {
      // Nothing to do
      return;
    }

    const objektLockReasons = result.hits.hits.reduce((acc, hit) => {
      /*
                AFAIK, the only `lockReason` settable by a `searchHit` is `manglerFagData`, which
                let's us assume that `royklopId` is negligible because `manglerFagData` == no `royklop`
                */

      const bruksenhetId = objGetDeep(
        hit,
        '_source.skybrannData.bruksenhet.fagId'
      );

      const lockReasons = {
        manglerFagData: !bruksenhetId,
      };

      if (_.some(lockReasons, (val) => val)) {
        // NOTE! See "getObjektLockReasons" above, for `key`-usage
        const key = makeLockReasonsKey({ bruksenhetId });
        acc[key] = Object.assign(acc[key] || {}, lockReasons);
      }
      return acc;
    }, this.props.store.objektLockReasons);
    this.props.updateObjektLockReasons(objektLockReasons);
  };

  setObjektLockReasonsFromTiltakLister = () => {
    const objektLockReasons = this.props
      .getTiltakListe()
      .reduce((acc, objekt) => {

        const lockReasons = {
          fraApi: true,
          varslet: !!objekt.varselSendt,
        };

        if (_.some(lockReasons, (val) => val)) {
          // NOTE! See "getObjektLockReasons" above, for `key`-usage
          const key = makeLockReasonsKey(objekt);
          acc[key] = Object.assign(acc[key] || {}, lockReasons);
        }
        return acc;
      }, {});

    this.props.updateObjektLockReasons(objektLockReasons);
  };

  render() {
    if (!this.state.filtersInit) {
      return <div>Lader filter...</div>;
    }
    return (
      <div>
        {!this.state.visLeggTilTiltak ? (
          <PageObjekter
            {
              ...this.props /* Silver bullet =P */
            }
            visLeggTilTiltak={(skalVises) =>
              this.setState({ visLeggTilTiltak: skalVises })
            }
            showNextPage={() => {
              this.props.takePageTiltakSnapshot();
              this.setState({ visLeggTilTiltak: true });
            }}
            searchkit={this.searchkit}
          />
        ) : (
          <PageTiltak
            {
              ...this.props /* Silver bullet =P */
            }
            showPrevPage={() => {
              this.setState({ visLeggTilTiltak: false });
            }}
            onClose={this.close}
            onSave={this.onSave}
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(asRouteComponent(LeggTilObjekter));
