/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../gjennomforing.css';
import NKIcon from 'nka-icons';
import ReactModal from 'react-modal';
import { withRouter } from 'lib/enhancers';
import 'components/Dialogs/NKFullModal.css';
import AnmerkningListe from '../avvikAnmerkning/AnmerkningListe.jsx';
import AvvikListe from '../avvikAnmerkning/AvvikListe.jsx';
import AlleAvvikAnmerkninger from '../avvikAnmerkning/AlleAvvikAnmerkninger.jsx';
import VisAvikAnmerkninger from '../avvikAnmerkning/VisAvikAnmerkninger.jsx';
import RoyklopSituasjon from '../Royklop/RoyklopSituasjon.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NyttTiltak from '../Tiltak/NyttTiltak.jsx';
import EnhetForm from './EnhetForm.jsx';
import NyttRoyklop from '../Royklop/NyttRoyklop.jsx';
import enhetApi from 'api/enhetApi';
import * as kontaktApi from 'api/kontaktApi';
import moment from 'moment';
import royklopApi from 'api/royklopApi';
import Objekthistorikk from 'modules/Objekter/Objekthistorikk.jsx';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import SendTilEierMedRisiko from 'modules/felles/avvikAnmerkninger/SendTilEierMedRisiko.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import TilknyttRoyklopDialog from '../Royklop/TilknyttRoyklopDialog.jsx';
import ModalDialogTable from 'modules/felles/avvikAnmerkninger/ModalDialogTable.jsx';
import NyOppdaterTiltaktype from '../Tiltak/NyOppdaterTiltaktype.jsx';
import TiltakstyperModal from '../Tiltakstyper/TiltakstyperModal.jsx';
import TiltakshistorikkModal from '../Tiltakshistorikk/TiltakshistorikkModal.jsx';
import ArkivModal from '../Arkiv/ArkivModal.jsx';
import DialogStore from 'lib/DialogStore';
import '../gjennomforing.css';
import EndreIldsted from '../Ildsted/EndreIldsted.jsx';
import { saveIldsted } from 'api/ildstedApi';
import { getInfoOmSendtTilInnbygger } from 'api/innbyggerDialogApi';
import { deleteInnbyggerDialog } from 'api/innbyggerDialogApi';
// Utils
import { visEiendomKommunekart } from 'utils/kommunekart.js';
import InfoOmSendtTilInnbyggerDialog from 'modules/felles/InfoOmSendtTilInnbyggerDialog.jsx';
import PdfDialog from 'components/Pdf/PdfDialog.jsx';
import { startRisikoanalyseBruksenhet } from 'api/risikoApi';
import RisikovurderingLogg from '../RisikovurderingLogg/RisikovurderingLogg.js';
import RisikovurderingLoggOld from '../RisikovurderingLogg/RisikovurderingLoggOld.jsx';
import { sjekkBruksenhetRisiko } from 'api/risikoApi.js';
//import { getOrganisasjonArkivInfo } from 'api/organisasjonApi';
import { getOrgArkivInfo } from 'context/contextInnstillinger.js';

import {
  contextInnstillingerPerson,
  getContextGebyrInnstillinger,
  contextInnstillingerSvarut
} from 'context/contextInnstillinger.js';
import { sjekkSistSendt } from 'api/smkApi.js';
import { harSMK } from 'context/contextOppslag.js';
import Notater from './Notater.jsx';
import kron from 'images/Annet/kron.png';
import risikologg from 'images/Annet/risikologg.png';
import senddialogblack from 'images/Annet/senddialogblack.png';
import GebyrDialog from 'modules/BraGe/GebyrDialog.jsx';
import PreviewDialog from 'components/Dialogs/PreviewDialog.jsx';
import SvarUtLogg from 'modules/felles/svarut/SvarUtLogg.jsx';
import SmsGenerellDialog from 'modules/felles/SmsGenerellDialog.jsx';
import SlettedeElementerLogg from './SlettedeElementerLogg.jsx';
import Prosesslogg from 'modules/Prosesslogg/Prosesslogg.jsx';

var _ = require('underscore'); 
var farge = 'black';

var base_path;
var royklopIdForNyttIldsted;

class EnhetSituasjon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      royklop: null,
      nyttTiltak: null,
      tiltakType: null,
      situasjon: this.props.situasjon,
      royklopListe: this.props.situasjon.royklopListe,
      matrikkelinfoId: null,
      farge: 'black',
      adresse: this.props.adresse,
      visGebyr: false,
      sistSendt: '',
      hideEierListe: 'hide',
      kontakter: [],
      BruksenhetIdMaFinnes: true,
      visEiereText: 'Vis eiere',
      ventText: '',
      tiltakRisiko: false,
      fritidseiendom: false,
      arkivFunksjonalitetArkivert: false,
      antallNotat: 0,
      visSidemannsKnapp: false,
      oppdaterer: false,
      alleredeSendt: false,
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  /*   static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedChanges(
      {
        situasjon: 'situasjon',
        'situasjon.royklopListe': 'royklopListe',
        adresse: 'adresse',
      },
      nextProps,
      prevState,
      debug
    );
  } */

  componentDidMount = () => {
    console.log('SXXX', contextInnstillingerSvarut._currentValue);
    //For å unngå en warning:
    //Warning: react-modal: App element is not defined.
    ReactModal.setAppElement('body');
    window.onpopstate = this.onBackButtonEvent;
    this.setState({ antallNotat: this.props.situasjon.antallNotat });
    base_path = this.props.base_path;
    if (!this.props.modal) {
      this.labelInfoOmSendtTilInnbygger();
    }
    this.skalSidemannsVises();
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
    getOrgArkivInfo(this.gotOrgArkivInfo);
  };


  skalSidemannsVises = () => {
    harSMK(this.skalSidemannsVises2);
  };

  skalSidemannsVises2 = (data) => {
    if (!this.props.modal && data) {
      this.setState({ visSidemannsKnapp: true });

      //Check om noe ligger Under Behandling som SMK-oppgave
      let b = this.props.fagbruksenhetId.toString();
      let a = this.props.arbListeId.toString();

      //checkSidemannskontrollStatus(b, a, this.checkedSidemannskontrollStatus);
      sjekkSistSendt(b, a, this.checkedSidemannskontrollStatus);
    }
  };

  checkedSidemannskontrollStatus = (err, liste) => {
    console.log(liste);
    if (liste) {
      if (liste.length) {
        let i = liste.length - 1;

        let d = moment(liste[i]).format('dddd DD.MM.YYYY');
        d = d.charAt(0).toUpperCase() + d.slice(1);
        let label = 'Sendt til Under behandling: ' + d;
        this.setState({ sistSendt: label, alleredeSendt: true });
      }
    }
  };

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  //#region Henting av data ****************************************

  hentSituasjonPaaNytt = () => {
    if (!this.props.modal) {
      this.props.fetchSituasjonPaaNy();
      this.props.refreshCache();
    } else {
      // modalt
      this.props.refresh();
    }
  };

  gotOrgArkivInfo = (res) => {
    this.setState({
      arkivFunksjonalitetArkivert: res.data.arkivFunksjonalitetArkivert,
    });
  };

  //#endregion

  //#region Fra Menyen *********************************************

  openDialoghistorikk = () => {
    if (this.props.base_path !== undefined) {
      this.props.router.push({
        pathname:
          this.props.base_path + '/' + this.props.enhetId + '/dialoghistorikk',
        state: {
          adresse: this.props.adresse,
          eiendom: this.props.eiendom,
          id: this.props.enhetId,
          type: 'bruksenhet',
        },
      });
    } else {
      this.dialogs.setOpenDialog('ModalDialogTable', {
        id: this.props.enhetId,
        adresse: this.props.adresse,
      });
    }
  };

  openTiltakstyper = () => {
    console.log(this.props.risiko);
    if (this.props.base_path !== undefined) {
      this.props.router.push({
        pathname:
          this.props.base_path + '/' + this.props.enhetId + '/tiltakstyper',
        state: {
          type: 'bruksenhet',
          id: this.props.enhetId,
          situasjon: this.props.situasjon,
          adresse: this.props.adresse,
          eiendom: this.props.eiendom,
          brId: this.props.enhetId,
          orgRisiko: this.props.risiko,
        },
      });
    } else {
      this.dialogs.setOpenDialog('TiltakstyperModal', {
        id: this.props.enhetId,
        brId: this.props.enhetId,
        orgRisiko: this.props.risiko,
      });
    }
  };

  openTiltakHistorikk = () => {
    if (this.props.base_path !== undefined) {
      this.props.router.push({
        pathname:
          this.props.base_path + '/' + this.props.enhetId + '/tiltakshistorikk',
        state: {
          type: 'bruksenhet',
          id: this.props.enhetId,
          adresse: this.props.adresse,
          eiendom: this.props.eiendom,
        },
      });
    } else {
      this.dialogs.setOpenDialog('TiltakshistorikkModal', {
        id: this.props.enhetId,
      });
    }
  };

  visMatrikkelinfo = (item) => {
    this.setState({ matrikkelinfoId: item.id });
    this.dialogs.setOpenDialog('Matrikkelinfo', {
      id: this.props.bruksenhetIdMa,
    });
  };

  visGebyr = () => {
    this.dialogs.setOpenDialog('GebyrDialog', {
      adresse: this.props.adresse,
      matrikkelId: this.props.matrikkelenhetId,
      fagbruksenhetId: this.props.fagbruksenhetId,
      maid: null,
    });
  };

  visProsessLogg = (item) => {
    this.dialogs.setOpenDialog('ProsessDialog', {
      brId: this.props.enhetId
    });
  }

  //#endregion

  //#region Close modaler ******************************************

  closeNyttRoykModal = () => {
    this.setState({ modalNyttRoykIsOpen: false });
  };

  //#endregion

  lagreNyttRoyklop = (royklop) => {
    royklopApi.saveRoyklop(royklop, this.royklopLagret);
  };

  royklopLagret = (err, royklop) => {
    this.hentSituasjonPaaNytt();
    this.dialogs.closeDialog();
  };

  ildstedLagret = () => {
    this.hentSituasjonPaaNytt();
  };

  leggTilTiltak = (
    nyttTiltak,
    tiltakNavn,
    hyppighetAntalldager,
    risikovurdering,
    tiltakRisiko,
    erFritidsbygg
  ) => {
    this.setState({
      tiltakNavn: tiltakNavn,
      hyppighetAntalldager: hyppighetAntalldager,
      risikovurdering: risikovurdering,
      // tiltakRisiko: tiltakRisiko,
      // fritidseiendom: erFritidsbygg,
      dato: nyttTiltak.dato,
    });

    nyttTiltak.arbeidslisteId = this.props.arbListeId;
    enhetApi.saveTiltakBruksenhet(nyttTiltak, this.tiltakLagret);
    this.dialogs.closeDialog();
    if (risikovurdering) {
      this.dialogs.setOpenDialog('RisikoDialog');
    }
  };

  onCloseRisiko = () => {
    this.dialogs.closeDialog();
    this.hentSituasjonPaaNytt();
  };

  tiltakLagret = (err, nyttTiltak) => {
    this.setState({ nyttTiltak: nyttTiltak });
    if (!this.state.risikovurdering) {
      this.setState({ oppdater: true });
    }
    console.log(nyttTiltak);
    //this.hentSituasjonPaaNytt();

    console.log(this.state.dato);
    let nesteDato = moment(this.state.dato)
      .add(this.state.hyppighetAntalldager, 'days')
      .format('YYYY-MM-DDTHH:00:00');
    if (nyttTiltak.hyppighetId == null || this.state.risikovurdering) {
      nesteDato = null;
    }

    var nyTiltaktype = {
      bruksenhetid: nyttTiltak.bruksenhetId,
      tiltaktypeid: nyttTiltak.tiltakTypeId,
      hyppighetid: !this.state.risikovurdering ? nyttTiltak.hyppighetId : null,
      nestedato: nesteDato,
      sistedato: null,
      sisteStatus: false,
      risikovurdering: this.state.risikovurdering,
    };

    enhetApi.nyOppdaterBruksenhetTiltakType(
      nyTiltaktype,
      this.tiltakTypeSjekket
    );
  };

  tiltakTypeSjekket = (err, tiltaktype) => {
    console.log(tiltaktype);
    this.setState({ oppdaterer: false });

    if (!this.state.risikovurdering) {
      if (tiltaktype != null) {
        if (tiltaktype.hyppighetId != null) {
          this.dialogs.setOpenDialog('NyDatoOkDialog', {
            tittel: 'Tiltak',
            melding:
              'Antatt dato for neste ' +
              this.state.tiltakNavn +
              ' er beregnet til ' +
              tiltaktype.nesteDato.substring(8, 10) +
              '.' +
              tiltaktype.nesteDato.substring(5, 7) +
              '.' +
              tiltaktype.nesteDato.substring(0, 4),
          });
        }
      }
    }
  };

  onCloseNyDato = () => {
    this.dialogs.closeDialog();
    this.hentSituasjonPaaNytt();
  };

  onNyEndreTiltaktypeOK = () => {
    this.dialogs.closeDialog();
  };

  sendTilEierOk = () => {
    this.dialogs.closeDialog();
    this.props.router.push({
      pathname: this.props.base_path,
    });
  };

  sendTilSmkOk = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('SmkDialog');
  };

  sendTilSaksbehandlerOk = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('SaksbehandlerDialog');
  };

  onCloseSmk = () => {
    this.props.router.push({
      pathname: this.props.base_path,
    });
  };

  endreEnhet = (endretBruksenhet) => {
    enhetApi.updateBruksenhet(
      endretBruksenhet.id,
      endretBruksenhet,
      this.enhetOppdatert
    );
    this.dialogs.closeDialog();
  };

  enhetOppdatert = (err, oppdatertBruksenhet) => {
    console.log(err);
  };

  slettEnhet = () => {
    this.dialogs.setOpenDialog('OkDialog', {
      okKnapp: 'owner.closeSlettDialogOk',
      tittel: 'Slett enhet',
      melding:
        'Du sletter nå valgt enhet. Dette innebaærer at avik, anmerkininger, ildsted, røykløp (som ikke er nknyttet til andre enheter) ..bla bla. Vil du fortsette?',
    });
  };

  closeSlettDialogOk = () => {
    enhetApi.deleteBruksenhet(this.props.enhetId, this.enhetSlettet);
    this.dialogs.closeDialog();
  };

  openVisAvikAnmModal = () => {
    this.dialogs.setOpenDialog('VisAvikAnmerkninger');
  };

  enhetSlettet = () => {
    this.props.router.push({
      pathname: this.props.base_path,
      state: {
        refresh: 'fraEnhetSlettet',
      },
    });
  };

  checkTltakIkkeUtfort = () => {
    let sit = this.props.situasjon;
    let rl = this.props.situasjon.royklopListe;
    if (sit.antallBruksenhetPaaAliste > sit.antallBruksenhetPaaAlisteBesokt) {
      return true;
    }
    if (rl === null || rl.length === 0) {
      return false;
    }
    for (var i = 0; i < rl.length; i++) {
      if (rl[i].antallLopPaaAliste > rl[i].antallLopPaaAlisteBesokt) {
        return true;
      }
    }
    return false;
  };

  escapeFraSend = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('OkDialog', {
      okKnapp: 'onClose',
      tittel: 'Forhåndsvisning ikke mulig',
      melding: 'Det er ingen nye avvik og anmerkninger å sende til eier.',
    });
  };

  sendTilEier2 = (preview, sidemann, saksbehandler, e) => {
    if (this.checkTltakIkkeUtfort()) {
      if (preview) {
        this.dialogs.setOpenDialog('OkDialog', {
          okKnapp: 'onClose',
          tittel: 'Forhåndsvisning ikke mulig',
          melding:
            'Planlagte tiltak på enheten er ikke utført. Fullfør tiltakene først.',
        });
      } else {
        this.dialogs.setOpenDialog('OkDialog', {
          okKnapp: 'onClose',
          tittel: 'Publiser avvik',
          melding:
            'Planlagte tiltak på enheten er ikke utført. Fullfør tiltakene eller flytt enheten til ny arbeidsliste',
        });
      }
    } else {
      if (this.state.alleredeSendt) {
        this.dialogs.setOpenDialog('OkDialog', {
          tittel: 'Send til ...',
          melding:
            'Det ligger allerede en rapport til klargjøring på denne bruksenheten. Du må fjerne eller sende denne for å kunne lage en ny rapport',
        });
      } else {
        // Kaller risiko
        startRisikoanalyseBruksenhet(this.props.enhetId, () => {
          console.log('Risikoanalyse kjørt');
        });

        this.dialogs.setOpenDialog('SendTilEier', {
          brId: this.props.enhetId,
          adresse: this.props.adresse,
          arbeidslisteId: this.props.arbListeId,
          planlagtDato: this.props.planlagtDato,
          sidemann: sidemann,
          previewDoc: preview,
          saksbehandler: saksbehandler,
          orgRisiko: this.props.risiko
        });
      }
    }
  };

  opprettIldstedFraNyttRoyklop = (id) => {
    royklopIdForNyttIldsted = id;
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('EndreIldsted');
  };

  addNyttIldsted = (nyttIldsted, valgttype) => {
    nyttIldsted.bruksenhetId = this.props.enhetId;
    nyttIldsted.royklopId = royklopIdForNyttIldsted;
    saveIldsted(nyttIldsted, () => {
      this.dialogs.closeDialog();
      this.hentSituasjonPaaNytt();
    });
  };

  ingenRoykTilknytt = () => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('OkDialog', {
      okKnapp: 'onClose',
      tittel: 'Tilknytt røykløp',
      melding:
        'Kan ikke tilknytte røykløp. Det er ingen andre registrerte røykløp på dette bygget.',
    });
  };
  roykTilknyttetUtenIldsted = () => {
    this.dialogs.closeDialog();
    this.hentSituasjonPaaNytt();
  };

  toTitleCase(str) {
    return str.replace(
      /\S+/g,
      (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
    );
  }

  labelInfoOmSendtTilInnbygger = () => {
    let enhetId = this.props.enhetId;
    let listeId = this.props.arbListeId;
    getInfoOmSendtTilInnbygger(
      enhetId,
      listeId,
      this.gotLabelInfoOmSendtTilInnbygger
    );
  };

  gotLabelInfoOmSendtTilInnbygger = (err, result) => {
    if (result) {
      if (result.length > 0) {
        result = result.reverse();
        //let d = moment(result[0]).format('dddd DD.MM.YYYY   H:mm');
        let d = moment(result[0]).format('dddd DD.MM.YYYY');
        d = d.charAt(0).toUpperCase() + d.slice(1);
        let label = 'Sist sendt: ' + d;

        this.setState({ sistSendt: label });
      }
    }
  };

  visInfoOmSendtTilInnbygger = () => {
    let enhetId = this.props.enhetId;
    let listeId = this.props.arbListeId;
    getInfoOmSendtTilInnbygger(
      enhetId,
      listeId,
      this.gotInfoOmSendtTilInnbygger
    );
  };

  gotInfoOmSendtTilInnbygger = (err, result) => {
    let datoer = [];

    let tittel = 'Du(?) har sendt noe til eieren før..';
    if (result) {
      result = result.reverse();
      if (result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          let dato = moment(result[i]).format('dddd DD.MM.YYYY  H:mm');
          let idag = moment(result[i]).isSame(moment(), 'day');
          datoer.push({ dato: dato, idag: idag });
        }
      } else {
        tittel = '';
        datoer.push({ dato: 'Ingenting sendt', idag: false });
      }
    }
    this.dialogs.setOpenDialog('SendtTilInnbyggerDialog', {
      datoer: datoer,
      tittel: tittel,
    });
  };

  slettDialogBruksenhet = () => {
    deleteInnbyggerDialog(this.props.enhetId, (err, res) => {});
  };

  sletteLoggElementerBruksenhet = () => {
    this.dialogs.setOpenDialog('SlettedeElementerLogg', {
      brid: this.props.enhetId,
    });
  };

  slettDialogAvvik = () => {};

  visHideEiere = (e) => {
    if (this.state.kontakter.length === 0) {
      this.hentEierkontakter();
    }
  };

  hentEierkontakter = () => {
    this.setState({ ventText: 'Vent...' });
    if (this.props.situasjon.bruksenhetIdMa) {
      let bruksenhetDto = {};
      if (!this.props.modal) {
        bruksenhetDto = {
          bruksenhetIdFag: this.props.enhetId,
          bruksenhetIdMa: this.props.situasjon.bruksenhetIdMa,
          arbeidslisteId: this.props.arbListeId,
        };
      } else {
        bruksenhetDto = {
          bruksenhetIdFag: this.props.enhetId,
          bruksenhetIdMa: this.props.situasjon.bruksenhetIdMa,
        };
        if (this.props.arbListeId) {
          bruksenhetDto.arbeidslisteId = this.props.arbListeId;
        }
      }
      kontaktApi.getBruksenhetKontakter3(
        bruksenhetDto,
        this.onGotEierKontakter
      );
    } else {
      this.setState({ ventText: 'Finner ingen eier' });
    }
  };

  onGotEierKontakter = (err, data) => {
    if (!data) {
      this.setState({ ventText: 'Finner ingen eier' });
      return;
    }
    let kontakter = data;
    // Test kontakter[0].ugyldigTelefonNr = true;
    //  kontakter = _.sortBy(kontakter, 'varslet').reverse();
    this.setState({ kontakter: kontakter });
    if (kontakter.length === 0) {
      this.setState({ ventText: 'Finner ingen eier' });
    } else {
      //this.setState({ ventText: kontakter.length });
      this.setState({ ventText: '' });
    }
  };

  hentEierkontakterForPdf = () => {
    if (this.props.situasjon.bruksenhetIdMa) {
      let bruksenhetDto = {};
      if (!this.props.modal) {
        bruksenhetDto = {
          bruksenhetIdFag: this.props.enhetId,
          bruksenhetIdMa: this.props.situasjon.bruksenhetIdMa,
          arbeidslisteId: this.props.arbListeId,
        };
      } else {
        bruksenhetDto = {
          bruksenhetIdFag: this.props.enhetId,
          bruksenhetIdMa: this.props.situasjon.bruksenhetIdMa,
        };
      }
      kontaktApi.getBruksenhetKontakter3(
        bruksenhetDto,
        this.onGotEierKontakterForPdf
      );
    }
  };

  onGotEierKontakterForPdf = (err, data) => {
    let kontakter = data;
    this.setState({ kontakter: kontakter }, this.eksporterTilPdf());
  };

  eksporterTilPdf = () => {
    let eiere = this.state.kontakter;
    if (eiere.length < 1) {
      this.hentEierkontakterForPdf();
    } else {
      this.dialogs.setOpenDialog('PdfDialogen', {
        data: this.state.situasjon,
        adresse: this.props.adresse,
        eiere: this.state.kontakter,
        rapportType: 'gjennomforingSituasjon',
        tittel: this.props.adresse,
      });
    }
  };

  startRisiko = () => {
    sjekkBruksenhetRisiko(this.props.enhetId, this.gotRisikoSjekk);
  };

  gotRisikoSjekk = (err, res) => {
    if (res) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Risikoanalyse',
        melding: 'Risikoanalyse på objektet er nå startet.',
      });
      startRisikoanalyseBruksenhet(this.props.enhetId, () => {
        console.log('Risikoanalyse kjørt');
      });
    } else {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Risikoanalyse',
        melding:
          'Systemet er ikke satt opp til å kjøre risikoanalyse på denne enheten.',
      });
    }
  };

/*   visRisikoLogg = () => {
    if (isThisProd()){
      this.dialogs.setOpenDialog('RisikologgOld', { id: this.props.enhetId, adresse: this.props.adresse });
    } else {
      this.dialogs.setOpenDialog('Risikologg', { id: this.props.enhetId, adresse: this.props.adresse });
    }
  }; */

  visRisikoLogg = () => {
    this.dialogs.setOpenDialog('Risikologg', { id: this.props.enhetId, adresse: this.props.adresse });
  };

  arkiv = () => {
    if (this.props.base_path !== undefined) {
      this.props.router.push({
        pathname: this.props.base_path + '/' + this.props.enhetId + '/arkiv',
        state: {
          type: 'bruksenhet',
          id: this.props.enhetId,
          adresse: this.props.adresse,
          eiendom: this.props.eiendom,
        },
      });
    } else {
      this.dialogs.setOpenDialog('ArkivModal', {
        id: this.props.enhetId,
        eiendom: this.props.eiendom,
        adrese: this.props.adresse,
      });
    }
  };

  sms = () => {
    this.dialogs.setOpenDialog('SmsGenerellDialog', {
      modus: 'single',
      valgteEnheter: this.props.enhetId,
    });
  };

  smsSent = () => {
    this.dialogs.closeDialog();
  };

  onClickSvarUt = () => {
    this.dialogs.setOpenDialog('SvarUtOppgaver', {
      id: this.props.enhetId,
      adresse: this.props.adresse,
    });
  };

  oppdaterAntall = (antallNotat) => {
    this.setState({ antallNotat });
  };

  forhandsVisningFerdigForVisning = (data) => {
    this.dialogs.closeDialog();
    //Vis dialog med forhåndsvisning
    this.dialogs.setOpenDialog('PreviewDialog', {
      tekst: data,
      tittel: 'Forhåndsvisning ' + this.props.adresse,
    });
  };

  render() {
    if (this.state.oppdaterer) {
      return <div className="loader" />;
    }

    if (this.props.situasjon.antallBruksenhetPaaAliste > 0) {
      if (
        this.props.situasjon.antallBruksenhetPaaAliste >
        this.props.situasjon.antallBruksenhetPaaAlisteBesokt
      ) {
        farge = 'red';
      } else {
        farge = 'green';
      }
    }

    let ico = 'hjem';
    if (!this.props.reellBruksenhet == null) {
      ico = 'advarsel_roed';
    }
    let btnStyle = {
      width: 'calc(100% - 54px)',
      background: '#d9d9d9',
      borderColor: '#999999',
      paddingRight: '0px',
      paddingLeft: '4px',
      maxHeight: '42px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    };
    let btnStyleEier = {
      width: 'calc(100% - 54px)',
      background: '#d9d9d9',
      borderColor: '#999999',
      paddingRight: '0px',
      paddingLeft: '4px',
      maxHeight: '42px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      marginBottom: '10px',
    };
    let ellipsedStyle = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 100px)',
      textAlign: 'left',
      display: 'inline-block',
    };
    let propilStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      width: '60px',
      height: '40px',
      lineHeight: '40px',
      float: 'right',
    };
    let propilStyleAfter = {
      float: 'right',
      marginTop: '10px',
      marginRight: '4px',
    };
    let menuDivStyle = {
      textAlign: 'center',
      verticalAlign: 'bottom',
      width: '40px',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      position: 'relative',
    };
    let menuButtonStyle = {
      border: 'none',
      background: 'transparent',
    };
    let sitKnappStyle = {
      float: 'left',
      marginLeft: '10px',
      marginTop: '4px',
    };

    return (
      <div>
        <br />

        <div style={{ width: '100%' }}>
          <div
            style={btnStyleEier}
            className="container-fluid btn btn-default sbpilpanel collapsed"
            href="#eiere"
            onClick={this.visHideEiere}
            data-toggle="collapse"
          >
            <span>
              <NKIcon
                icon={'bruker'}
                style={{
                  marginRight: '10px',
                  marginTop: '10px',
                  float: 'left',
                  display: 'inline-block',
                }}
                size="0.8"
                color="nk-black"
              />
            </span>
            <h4 style={ellipsedStyle}>{'Eier  ' + this.state.ventText}</h4>
            <div style={propilStyle}>
              <span
                style={propilStyleAfter}
                className="chevron fa fa-fw fa-lg"
              />
            </div>
          </div>

          {/* panel */}
          <div
            id="eiere"
            style={{ width: 'calc(100% - 54px)', paddingLeft: '30px' }}
            className="collapse "
          >
            <div className="row">
              {this.state.kontakter.length > 0 &&
                this.state.kontakter.map((e, index) => {
                  let tlf = e.telefon;
                  let ugyldig = e.ugyldigTelefonNr;
                  let td = (
                    <td style={{ verticalAlign: 'top' }}>
                      <a href={'Telefon: ' + tlf}> {tlf} </a>{' '}
                    </td>
                  );
                  if (tlf === '' || tlf === null || tlf === undefined) {
                    td = (
                      <td style={{ verticalAlign: 'top', color: 'red' }}>
                        Mangler telefonnummer
                      </td>
                    );
                  }
                  if (
                    tlf !== '' &&
                    tlf !== null &&
                    tlf !== undefined &&
                    ugyldig
                  ) {
                    td = (
                      <td style={{ verticalAlign: 'top', color: 'red' }}>
                        Ugyldig nummer {tlf}
                      </td>
                    );
                  }
                  return (
                    <div className="col-sm-6">
                      <table key={'table' + index.toString()}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                verticalAlign: 'top',
                                marginRight: '15px',
                                paddingRight: '4px',
                              }}
                            >
                              {'Telefon: '}
                            </td>
                            {td}
                            <td
                              style={{
                                verticalAlign: 'top',
                                float: 'left',
                                marginLeft: '15px',
                              }}
                            >
                              {e.navn}
                            </td>
                            <td>
                              {e.varslet ? (
                                <span>
                                  <NKIcon
                                    style={{
                                      marginLeft: '6px',
                                      marginTop: '4px',
                                    }}
                                    icon="varsel"
                                    color="nk-black"
                                    size="0.6"
                                  />
                                </span>
                              ) : (
                                <span />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
          </div>

          {/*Button*/}
          <div
            style={btnStyle}
            className="container-fluid btn btn-default sbpilpanel collapsed"
            href="#enhet"
            data-toggle="collapse"
          >
            <span>
              <NKIcon
                icon={ico}
                style={{
                  marginRight: '10px',
                  marginTop: '10px',
                  float: 'left',
                  display: 'inline-block',
                }}
                size="0.8"
                color="nk-black"
              />
            </span>
            <h4 style={ellipsedStyle}>{this.state.adresse}</h4>
            <div style={propilStyle}>
              <span
                style={propilStyleAfter}
                className="chevron fa fa-fw fa-lg"
              />
              {this.props.situasjon.antallBruksenhetPaaAliste === 0 ||
              !this.props.base_path ? null : (
                <span
                  className={farge}
                  style={{
                    float: 'right',
                    marginRight: '5px',
                    fontSize: '16px',
                  }}
                >
                  {this.props.situasjon.antallBruksenhetPaaAlisteBesokt}/
                  {this.props.situasjon.antallBruksenhetPaaAliste}
                </span>
              )}
            </div>
          </div>
          {/* menu */}
          <div style={menuDivStyle}>
            {' '}
            {/* className='dropdown' */}
            <button
              style={menuButtonStyle}
              className="dropdown-toggle"
              data-toggle="dropdown"
            >
              <span>
                <NKIcon icon="meny" size="1.5" color="nk-black" />
              </span>
            </button>
            <ul style={{ float: 'right' }} className="dropdown-menu">
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('NyttRoyklop', {
                      enhetId: this.props.enhetId,
                    })
                  }
                >
                  Nytt røykløp
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('TilknyttRoyklopDialog', {
                      bruksenhetId: this.props.situasjon.id,
                    })
                  }
                >
                  Tilknytt røykløp
                </a>
              </li>
              <li className="divider" />
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.openDialoghistorikk}
                >
                  Innbyggerdialog
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.openTiltakstyper}
                >
                  Regelmessige tiltak
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.openTiltakHistorikk}
                >
                  Tiltakshistorikk
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('Objekthistorikk', {
                      id: this.props.enhetId,
                    })
                  }
                >
                  Objektendringer
                </a>
              </li>

              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.visMatrikkelinfo}
                >
                  Vis matrikkelinfo
                </a>
              </li>

              {this.state.visGebyr && !this.props.fraFiktive && (
                <li>
                  <a style={{ cursor: 'pointer' }} onClick={this.visGebyr}>
                    Vis gebyr
                  </a>
                </li>
              )}

              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.visProsessLogg}
                >
                  Systemmeldinger
                </a>
              </li>

              {this.props.risiko ? (
                <>
                  <li className="divider" />
                  <li>
                    <a style={{ cursor: 'pointer' }} onClick={this.startRisiko}>
                      Start risikoanalyse
                    </a>
                  </li>
                </>
              ) : null}
             {/*  {this.props.risiko ? (
                <li>
                  <a style={{ cursor: 'pointer' }} onClick={this.visRisikoLogg}>
                    Vis risikoanalyse logg
                  </a>
                </li>
              ) : null} */}

              {!this.state.arkivFunksjonalitetArkivert ||
                ((contextInnstillingerPerson._currentValue.organisasjonAdmin ||
                  contextInnstillingerPerson._currentValue.arkivAdmin) && (
                  <li>
                    <a style={{ cursor: 'pointer' }} onClick={this.arkiv}>
                      Arkiv
                    </a>
                  </li>
                ))}
              <li>
                <a style={{ cursor: 'pointer' }} onClick={this.sms}>
                  Send SMS
                </a>
              </li>
              {contextInnstillingerSvarut._currentValue.data &&
              (contextInnstillingerPerson._currentValue.organisasjonAdmin ||
                contextInnstillingerPerson._currentValue.norkartAdmin) ? (
                <li>
                  <a style={{ cursor: 'pointer' }} onClick={this.onClickSvarUt}>
                    SvarUt logg
                  </a>
                </li>
              ) : null}

              {contextInnstillingerPerson._currentValue.norkartAdmin && (
                <React.Fragment>
                  <li className="divider" />
                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={this.eksporterTilPdf}
                    >
                      Eksporter til pdf
                    </a>
                  </li>

                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={this.slettDialogBruksenhet}
                    >
                      Slett dialog bruksenhet
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() =>
                        visEiendomKommunekart(this.props.eiendom, 'situasjon')
                      }
                    >
                      Vis i kommunekart
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={this.sletteLoggElementerBruksenhet}
                    >
                      Vis logg over slettede elementer
                    </a>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>

          {/* panel */}
          <div
            id="enhet"
            style={{ width: 'calc(100% - 54px)', paddingLeft: '30px' }}
            className="collapse "
          >
            <br />
            <div className="row">
              <div className="col-sm-6 col-xs-12 col-lg-3">
                <button
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('EnhetForm', {
                      id: this.props.situasjon.id,
                    })
                  }
                >
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="rediger"
                      color="nk-black"
                    />
                  </span>
                  <span style={sitKnappStyle}>Endre</span>
                </button>
              </div>
              <div className="col-sm-6 col-xs-12 col-lg-3">
                <button
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('NyttTiltak', {
                      id: this.props.situasjon.id,
                      arbListeId: this.props.arbListeId,
                    })
                  }
                >
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="tilsyn"
                      color="nk-black"
                    />
                  </span>
                  <span style={sitKnappStyle}>Utfør tiltak</span>
                </button>
              </div>
              <div className="col-sm-6 col-xs-12 col-lg-4">
                <button
                  className="btn btn-default"
                  title="Anmerkninger & andre forhold"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('AnmerkningListe', {
                      tittel: this.props.adresse,
                      adresse: this.props.adresse,
                      bruksenhetid: this.props.situasjon.id,
                      objektReferanseId: this.props.situasjon.id,
                    })
                  }
                >
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="anmerkninger"
                      color="nk-black"
                    />
                  </span>
                  <span className="knappEllipsis">
                    &nbsp;&nbsp;Anmerkninger & andre forhold
                  </span>
                </button>
              </div>
              <div className="col-sm-6 col-xs-12 col-lg-2">
                <button
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={() =>
                    this.dialogs.setOpenDialog('AvvikListe', {
                      tittel: this.props.adresse,
                      adresse: this.props.adresse,
                      bruksenhetid: this.props.situasjon.id,
                      objektReferanseId: this.props.situasjon.id,
                    })
                  }
                >
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="risiko"
                      color="nk-black"
                    />
                  </span>
                  <span style={sitKnappStyle}>Avvik</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <p />

        <div>
          {_.map(
            this.state.royklopListe,
            function(royklop) {
              let adresse = this.props.adresse;
              return (
                <RoyklopSituasjon
                  adresse={adresse}
                  emne="røykløp"
                  royklop={royklop}
                  key={royklop.id}
                  base_path={base_path}
                  oppdater={this.hentSituasjonPaaNytt}
                  arbListeId={this.props.arbListeId}
                  risiko={this.props.risiko}
                  situasjon={this.state.situasjon}
                />
              );
            },
            this
          )}
        </div>

        <p />

        {/* Buttons nede */}
        <div
          style={{
            width: 'calc(100% - 54px)',
            paddingLeft: '30px',
            borderTopColor: 'gray',
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
          }}
        >
          <br />
          <div className="row">
            <div className="col-sm-4 col-xs-12 col-lg-4" />
            <div className="col-sm-4 col-xs-12 col-lg-4">
              <div
                style={{
                  marginLeft: '4px',
                  marginBottom: '6px',
                  fontSize: '14px',
                  color: 'blue',
                }}
              >
                {' '}
                {this.state.sistSendt}{' '}
              </div>
            </div>
            <div className="col-sm-4 col-xs-12 col-lg-4" />
          </div>

          <div className="row">
            <div className="col-sm-6 col-xs-12 col-lg-4">
              <button
                style={{
                  height: '40px',
                  width: '100%',

                  marginBottom: '4px',
                }}
                className="btn btn-default"
                onClick={() =>
                  this.dialogs.setOpenDialog('AlleAvvikAnmerkninger', {
                    tittel: this.props.adresse,
                    adresse: this.props.adresse,
                    bruksenhetid: this.props.situasjon.id,
                    situasjon: this.props.situasjon,
                  })
                }
              >
                <span style={{ float: 'left', marginLeft: '10px' }}>
                  <NKIcon icon="risiko" size="1" color="nk-black" />
                </span>
                <span className="knappEllipsis">
                  &nbsp;&nbsp;Avvik/Anmerkninger/Andre forhold
                </span>
              </button>
            </div>

          

            <div className="col-sm-6 col-xs-12 col-lg-3">
              <button
                style={{
                  height: '40px',
                  width: '100%',
                  minWidth: '216px',
                  marginBottom: '4px',
                }}
                className="btn btn-default"
                onClick={() =>
                  this.dialogs.setOpenDialog('Notater', {
                    tittel: this.props.adresse,
                    adresse: this.props.adresse,
                    bruksenhetid: this.props.situasjon.id,
                    situasjon: this.props.situasjon,
                  })
                }
              >
                <span style={{ float: 'left', marginLeft: '10px' }}>
                  <NKIcon icon="rapport" size="1" color="nk-black" />
                </span>
                <span style={sitKnappStyle}>
                  Notater ({this.state.antallNotat})
                </span>
                {/* <span>({this.props.situasjon.antallNotat})</span> */}
              </button>
            </div>

            {this.state.visGebyr && !this.props.fraFiktive && (
              <div className="col-sm-6 col-xs-12 col-lg-2">
                <button
                  style={{
                    height: '40px',
                    width: '100%',
                    minWidth: '216px',
                    marginBottom: '4px',
                  }}
                  className="btn btn-default"
                  onClick={this.visGebyr}
                >
                  <span style={{ float: 'left', marginLeft: '10px' }}>
                    <img height="24" width="24" src={kron} alt="" />
                  </span>
                  <span style={sitKnappStyle}>Vis gebyr</span>
                </button>
              </div>
            )}

           {this.props.risiko && (
              <div className="col-sm-6 col-xs-12 col-lg-2">
                <button
                  style={{
                    height: '40px',
                    width: '100%',
                    minWidth: '216px',
                    marginBottom: '4px',
                  }}
                  className="btn btn-default"
                  onClick={this.visRisikoLogg}
                >
                  <span style={{ float: 'left', marginLeft: '10px' }}>
                    <img height="24" width="24" src={risikologg} alt="" />
                  </span>
                  <span style={sitKnappStyle}>Vis risikoanalyse logg</span>
                </button>
              </div>
            )}

{!this.state.visSidemannsKnapp && !this.props.modal && (
              <div
                style={{ marginBottom: '4px' }}
                className='col-sm-6 col-xs-12 col-lg-3'>
                <button
                  style={{ height: '40px', width: '100%' }}
                  className='btn btn-default'
                  onClick={this.sendTilEier2.bind(false, false, false, false)}>
                  <span style={{ float: 'left', marginLeft: '10px' }}>
                    <img height='24' width='24' src={senddialogblack} alt='' />
                  </span>
                  <span style={sitKnappStyle}>Send til eier</span>
                </button>
              </div>
            )}

            {!this.props.modal && this.state.visSidemannsKnapp &&(
              <div
                style={{ marginBottom: '4px' }}
                className="col-sm-6 col-xs-12 col-lg-3"
              >
                <div
                  /*   className='dropdown' */
                  style={{
                    height: '40px',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <button
                    style={{ marginTop: '12px', height: '40px', width: '100%' }}
                    className="btn btn-default dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                  >
                    <span style={{ float: 'left', marginLeft: '10px' }}>
                      <img
                        height="24"
                        width="24"
                        src={senddialogblack}
                        alt=""
                      />
                    </span>
                    <span style={sitKnappStyle}>
                      Avslutt tiltak og send til ...
                    </span>
                    <span
                      className="caret pull-right"
                      style={{ marginTop: '10px', borderWidth: '8px' }}
                    />
                  </button>

                  <ul className="dropdown-menu" style={{ width: '100%' }}>
                    {/*   <li>
                      <a onClick={this.sendTilEier2.bind(this, true, false)}>
                        <span style={{ padding: '4px', cursor: 'pointer' }}>
                          Forhåndsvisning
                        </span>
                      </a>
                    </li> */}
                    {!this.state.sistSendt.includes('sidemann') && (
                      <>
                        <li>
                          <a
                            onClick={this.sendTilEier2.bind(
                              this,
                              false,
                              false,
                              false
                            )}
                          >
                            <span style={{ padding: '4px', cursor: 'pointer' }}>
                              Eier (og arkiv)
                            </span>
                          </a>
                        </li>
                        {this.state.visSidemannsKnapp && (
                          <li>
                            <a
                              onClick={this.sendTilEier2.bind(
                                this,
                                false,
                                true,
                                false
                              )}
                            >
                              <span
                                style={{ padding: '4px', cursor: 'pointer' }}
                              >
                                Kontroll
                              </span>
                            </a>
                          </li>
                        )}
                        {this.state.visSidemannsKnapp && (
                          <li>
                            <a
                              onClick={this.sendTilEier2.bind(
                                this,
                                false,
                                false,
                                true
                              )}
                            >
                              <span
                                style={{ padding: '4px', cursor: 'pointer' }}
                              >
                                Saksbehandler
                              </span>
                            </a>
                          </li>
                        )}
                      </>
                    )}
                    {this.state.sistSendt.includes('sidemann') && (
                      <>
                        <li>
                          <a>
                            <span
                              style={{
                                color: 'lightGray',
                                padding: '4px',
                                cursor: 'pointer',
                              }}
                            >
                              Eier og arkiv
                            </span>
                          </a>
                        </li>
                        {this.state.visSidemannsKnapp && (
                          <li>
                            <a>
                              <span
                                style={{
                                  color: 'lightGray',
                                  padding: '4px',
                                  cursor: 'pointer',
                                }}
                              >
                                Kontroll
                              </span>
                            </a>
                          </li>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            )}

          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(EnhetSituasjon);

// Declaratice stuff

const modalDialogs = {
  NyttRoyklop: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <NyttRoyklop
          onClose={onClose}
          onLagreNyttRoyklop={owner.lagreNyttRoyklop}
          enhetId={dialogData.enhetId}
        />
      </NKModal>
    );
  },
  VisAvikAnmerkninger: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <VisAvikAnmerkninger onClose={onClose} />
      </NKModal>
    );
  },
  NyttTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <NyttTiltak
          onClose={onClose}
          onNyttTiltak={owner.leggTilTiltak}
          id={dialogData.id}
          type="bruksenhet"
          arbeidslisteId={dialogData.arbListeId}
        />
      </NKModal>
    );
  },
  EnhetForm: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <EnhetForm
          onClose={onClose}
          endreEnhet={owner.endreEnhet}
          id={dialogData.id}
          eiendom={dialogData.eiendom}
        />
      </NKModal>
    );
  },
  ModalDialogTable: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <ModalDialogTable
          onClose={onClose}
          adresse={dialogData.adresse}
          id={dialogData.id}
        />
      </NKModal>
    );
  },
  Matrikkelinfo: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="medium"
        buttonText={'Lukk'}
        tittel="Matrikkelinfo"
        onClose={onClose}
      >
        <Matrikkelinfo id={dialogData.id} />
      </ModalContainer>
    );
  },
  SmkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={owner.onCloseSmk}
        tittel={'Til kontroll'}
        melding={'Opplysningene har nå blitt sendt til dokumentkontroll'}
        onOk={owner.onCloseSmk}
      />
    );
  },
  SaksbehandlerDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={owner.onCloseSmk}
        tittel={'Til saksbehandler'}
        melding={'Opplysningene har nå blitt sendt til saksbehandler'}
        onOk={owner.onCloseSmk}
      />
    );
  },
  RisikoDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={owner.onCloseRisiko}
        tittel={'Tiltak'}
        melding={
          'Objektet er underlagt risikovurdering. Hyppighet og neste dato vil bli oppdatert på bakgrunn av dette.'
        }
        onOk={owner.onCloseRisiko}
      />
    );
  },
  NyDatoOkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={owner.onCloseNyDato}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={owner.onCloseNyDato}
      />
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={onClose}
      />
    );
  },
  AnmerkningListe: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <AnmerkningListe
          onClose={onClose}
          tittel={dialogData.tittel}
          emne=""
          adresse={dialogData.adresse}
          identi=""
          bruksenhetid={dialogData.bruksenhetid}
          objektReferanseType="B"
          objektReferanseId={dialogData.objektReferanseId}
        />
      </NKModal>
    );
  },
  AvvikListe: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <AvvikListe
          onClose={onClose}
          tittel={dialogData.tittel}
          emne=""
          adresse={dialogData.adresse}
          identi=""
          bruksenhetid={dialogData.bruksenhetid}
          objektReferanseType="B"
          objektReferanseId={dialogData.objektReferanseId}
        />
      </NKModal>
    );
  },
  AlleAvvikAnmerkninger: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <AlleAvvikAnmerkninger
          onClose={onClose}
          tittel={dialogData.tittel}
          emne=""
          adresse={dialogData.adresse}
          identi=""
          bruksenhetid={dialogData.bruksenhetid}
          situasjon={dialogData.situasjon}
        />
      </NKModal>
    );
  },
  Objekthistorikk: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <Objekthistorikk onClose={onClose} id={dialogData.id} type="enhet" />
      </NKModal>
    );
  },
  Notater: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <Notater
          onClose={onClose}
          oppdaterAntall={owner.oppdaterAntall}
          brid={dialogData.bruksenhetid}
        />
      </NKModal>
    );
  },
  SendtTilInnbyggerDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="small" isOpen={isOpen}>
        <InfoOmSendtTilInnbyggerDialog
          onClose={onClose}
          datoer={dialogData.datoer}
          tittel={dialogData.tittel}
        />
      </NKModal>
    );
  },

  SendTilEier: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <SendTilEierMedRisiko
          onClose={onClose}
          brId={dialogData.brId}
          adresse={dialogData.adresse}
          arbeidslisteId={dialogData.arbeidslisteId}
          fraModul="Gjennomforing"
          onFerdig={owner.sendTilEierOk}
          onFerdigSmk={owner.sendTilSmkOk}
          onFerdigSaksbehandler={owner.sendTilSaksbehandlerOk}
          planlagtDato={dialogData.planlagtDato}
          sidemann={dialogData.sidemann}
          saksbehandler={dialogData.saksbehandler}
          previewDocFraSituasjon={dialogData.previewDoc}
          forhandsVisningFerdigForVisning={
            owner.forhandsVisningFerdigForVisning
          }
          escapeFraSend={owner.escapeFraSend}
          orgRisiko={dialogData.orgRisiko}
        />
      </NKModal>
    );
  },
  TilknyttRoyklopDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <TilknyttRoyklopDialog
          onClose={onClose}
          onIldsted={(id) => owner.opprettIldstedFraNyttRoyklop(id)}
          ingen={() => owner.ingenRoykTilknytt()}
          bruksenhetId={dialogData.bruksenhetId}
          tilkyttetUtenRoyk={() => owner.roykTilknyttetUtenIldsted()}
        />
      </NKModal>
    );
  },
  NyOppdaterTiltaktype: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <NyOppdaterTiltaktype
          onClose={onClose}
          statusTiltaktype={dialogData.statusTiltaktype}
          onNyEndreTiltaktypeOK={owner.onNyEndreTiltaktypeOK}
          lagretTiltak={dialogData.lagretTiltak}
          tiltakType={dialogData.tiltakType}
          type={'bruksenhet'}
          tiltakNavn={dialogData.tiltakNavn}
          hyppighetAntalldager={dialogData.hyppighetAntalldager}
          adresse={dialogData.adresse}
        />
      </NKModal>
    );
  },
  TiltakstyperModal: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <TiltakstyperModal
        isOpen={isOpen}
        onClose={onClose}
        type="bruksenhet"
        id={dialogData.id}
        brId={dialogData.brId}
        orgRisiko={dialogData.orgRisiko}
      />
    );
  },
  TiltakshistorikkModal: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <TiltakshistorikkModal
        isOpen={isOpen}
        onClose={onClose}
        type={'bruksenhet'}
        id={dialogData.id}
      />
    );
  },
  ArkivModal: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ArkivModal
        isOpen={isOpen}
        onClose={onClose}
        type="bruksenhet"
        id={dialogData.id}
        eiendom={dialogData.eiendom}
      />
    );
  },
  EndreIldsted: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <EndreIldsted
          onClose={onClose}
          ildstedId={null}
          nyendre="ny"
          onLagreNyttIldsted={owner.addNyttIldsted}
        />
      </NKModal>
    );
  },
  PdfDialogen: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <PdfDialog
          onClose={onClose}
          data={dialogData.data}
          adresse={dialogData.adresse}
          eiere={dialogData.eiere}
          rapportType={dialogData.rapportType}
          tittel={dialogData.tittel}
        />
      </NKModal>
    );
  },
  Risikologg: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <RisikovurderingLogg onClose={onClose} id={dialogData.id} adresse={dialogData.adresse}/>
      </NKModal>
    );
  },
  RisikologgOld: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <RisikovurderingLoggOld onClose={onClose} id={dialogData.id} adresse={dialogData.adresse}/>
      </NKModal>
    );
  },
  SmsGenerellDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="large" isOpen={isOpen}>
        <SmsGenerellDialog
          onClose={onClose}
          modus={dialogData.modus}
          valgteEnheter={dialogData.valgteEnheter}
          smsSent={owner.smsSent}
        />
      </NKModal>
    );
  },
  GebyrDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="large" isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrDialog
        isOpen={isOpen}
        onClose={onClose}
        adresse={dialogData.adresse}
        matrikkelId={dialogData.matrikkelId}
        fagbruksenhetId={dialogData.fagbruksenhetId}
        fraEtablerFag={false}
        maid={dialogData.maid}
      />
    </NKModal>
  ),
  PreviewDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="large" isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <PreviewDialog
        isOpen={isOpen}
        onClose={onClose}
        tekst={dialogData.tekst}
        tittel={dialogData.tittel}
      />
    </NKModal>
  ),
  SvarUtOppgaver: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="xl"
        buttonText={'Lukk'}
        tittel="SvarUt logg"
        onClose={onClose}
      >
        <SvarUtLogg
          id={dialogData.id}
          alle={false}
          feil={false}
          fra={'fraSituasjon'}
          adresse={dialogData.adresse}
        />
      </ModalContainer>
    );
  },
  SlettedeElementerLogg: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="large-wide"
        tittel="Slettede elementer på bruksenheten"
        onClose={onClose}
      >
        <SlettedeElementerLogg
          brid={dialogData.brid}
          onCloseModal={owner.closeNyModal}
        />
      </ModalContainer>
    );
  },
  ProsessDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size="large-wide"
        tittel="Systemmelding"
        onClose={onClose}
      >
        <Prosesslogg
          brId={dialogData.brId}
          onCloseModal={owner.closeNyModal}
        />
      </ModalContainer>
    );
  },
};

//  <TiltakshistorikkModal
//     isOpen={this.state.modalTiltakHistorikkIsOpen}
//     id={this.props.enhetId}
//     onClose={() => this.setState({ modalTiltakHistorikkIsOpen: false })}
//     type="bruksenhet"
//   />
