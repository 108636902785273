
export const getHtml_snippets = [
  {
    id: 12,
    sms: true,
    tittel: 'Feier syk',
    tekst: 'Vi er nødt til å avlyse for at feieren er syk.',
  },
  {
    id: 13,
    sms: true,
    tittel: 'Prioritering',
    tekst:
      'Vi er nødt til å avlyse besøket ifm. prioritering av en akutt situasjon.',
  },
  {
    id: 15,
    sms: true,
    tittel: 'Fest',
    tekst: 'Vi kansellerer for at vi helst skal på fest! Hikk! :-)',
  },
  {
    id: 22,
    sms: false,
    tittel: 'Kontroll av',
    tekst:
      '<p><strong>Tilsynet vil omfatte kontroll av at</strong></p> <ul>' +
      ' <li>eventuelle tidligere avvik eller anmerkninger ved fyringsanlegget er fulgt' +
      ' opp og utbedret</li> <li>nytt ildsted eller vesentlige endringer ved fyringsanlegget er ' +
      'meldt til kommunen</li> <li>ildsted, røykkanal, skorstein og eventuelt matesystem' +
      ' for brensel er tilfredsstillende bygget og montert (herunder uklarheter omkring ' +
      'lovverkets krav til fyringsanlegget og konsekvenser av manglende oppfyllelse og hvorledes' +
      ' utbedring kan foretas)</li> <li>anlegget er tilfredsstillende kontrollert, ettersett og vedlikeholdt</li> </ul> <p>',
  },
];

export const getEksempeltekst = () => {
  let r = Math.floor(Math.random() * 7) + 1;
  let t = 'abc';
  switch (r) {
    case 1:
      t =
      'Han flyktet til Norge sammen med Miller i 1920, og livnærte seg som bryggeriarbeider og drosjesjåfør på Lillehammer frem til sin død tre år senere. ';
      break;
    case 2:
      t =
        'Det store innrykket av OL-arbeidere, deltagere og ledere representerte en fordobling av folketallet i Lillehammer, som vanligvis er på drøyt 20 000.';
      break;
    case 3:
      t =
        'Fra 1849 til 1854 var han overlærer og bestyrer ved Lillehammer skole, og videre med tittel som rektor ved samme skole til sin død i 1878.';
      break;
    case 4:
      t =
        'Næringslivsrepresentanter fra hele verden var til stede under Lillehammer-lekene, og mange av dem så denne internasjonale massemønstringen som en anledning til å pleie sine forretningsforbindelser og holde seminarer og konferanser.';
      break;
    case 5:
      t =
        'Den 23. juni 1945 kom Holst med bil fra Stockholm og var tidlig om morgenen den 26. juni med som kjentmann ved razzia og arrestasjoner som britiske og norske styrker foretok i tyske militærleire ved Wehrmachts hovedkvarter på Lillehammer.';
      break;
      case 6:
      t =
        'I samarbeid med den amerikanske etterretningsorganisasjonen Office of Strategic Services (OSS) og med godkjenning og støtte fra den norske legasjonen i Stockholm dro representanter for den svenske etterretningsorganisasjonen C-byrån sammen med to norske etterretningsspesialister til Lillehammer den 9. og 10. mai 1945.';
      break;
      case 7:
      t =
        'Andreassen har ved siden av en rekke TV-programmet også bidratt til åpningen av Lillehammer-OL, Ski-VM 1997 i Trondheim, åpningsshowet på Gardermoen i 1998, Eurovision Song Contest i 1986 og millenniumshowet på Rådhusplassen i Oslo.';
      break;
    default:
      t =
        'Etter å ha undersøkt hele innsjøen la de frem en rapport som besto av elleve forskjellige steder, deriblant områder i nærheten av dagens Eidsvoll, Minnesund, Tangen, Åker, Storhamar, Brumunddal, Nes, Moelven, Lillehammer, Gjøvik og Toten.';
  }

  return t;
 /*  return '<p>' +  t + '&nbsp;</p> '; */
};
