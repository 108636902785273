import React from 'react';
import arkivApi from 'api/arkivApi';
var kundeId = null;
var _ = require('underscore');

class HentSak extends React.Component {
  constructor(props) {
    super(props);
    this.handleAarChange = this.handleAarChange.bind(this);
    this.handleSekvensChange = this.handleSekvensChange.bind(this);
    this.submit = this.submit.bind(this);
    this.sakHentet = this.sakHentet.bind(this);
    this.state = {
      kundeId: null,
      aar: null,
      sekvensnr: null,
      visning: <div>Ingen sak å vise</div>
    };
  }

  componentDidMount() {
    //arkivApi.getArkivInnstillinger('94eb4680-6dcd-4a26-8ecc-fb877f1df6e7', this.gotInnstillinger);
  }
  handleAarChange(val) {
    this.setState({ aar: val.target.value });
  }
  handleSekvensChange(val) {
    this.setState({ sekvensnr: val.target.value });
  }

  submit() {
    arkivApi.getSakForSaksnummer(
      this.state.aar,
      this.state.sekvensnr,
      this.sakHentet
    );
  }
  
  sakHentet(err, sak) {
      console.log(sak);
      console.log(err);
    if (sak === null || sak === undefined || sak.statusText === 'No Content') {
        this.setState({
            visning: 'Fant ingen sak'
        }
        );
    } else 
    {
      this.setState({
        visning: (
          <div>
            <h4>
              Sak: {sak.saksnummer.saksaar}/{sak.saksnummer.sakssekvensnummer}
            </h4>
            <b>Tittel:&nbsp;</b>
            {sak.tittel}
            <br />
            <b>AdministrativEnhetInit:&nbsp;</b>
            {sak.administrativEnhetInit}
            <br />
            <b>SaksansvarligInit:&nbsp;</b>
            {sak.saksansvarligInit}
            <br />
            <b>Journalenhet:&nbsp;</b>
            {sak.journalenhet != null ? sak.journalenhet.kodeverdi : null}
            <br />
            <b>Mappetype:&nbsp;</b>
            {sak.mappetype == null ? 'Ikke satt' : sak.mappetype.kodeverdi}
            <br />
            <b>ReferanseArkivdel:&nbsp;</b>
            {sak.referanseArkivdel.kodeverdi}
            <br />
            <b>SystemId:&nbsp;</b>
            {sak.systemID}
            <br />
            <b>Dokumentmedium:&nbsp;</b>
            {sak.dokumentmedium.kodeverdi}
            <br />
            <b>Klasseliste:&nbsp;</b>
            {sak.klasseListe != null && sak.klasseListe.length > 0 ? <div>
              sak.klasseListe[0].Klassifikasjonssystem sak.klasseListe[0].klasseID
            </div> : 'Ingen klasser'}
            <br />
          </div>
        )
      });
    }
  }

  render() {
    return (
      <div style={{ margin: '30px' }}>
        <h2>Hent Sak</h2>
        <br />
        <div className="form">
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Sak år</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Saksår"
                  onChange={this.handleAarChange}
                  ref="aarInput"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Sak sekvensnummer</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Saksekvensnummer"
                  onChange={this.handleSekvensChange}
                  ref="sekvensInput"
                />
              </div>
            </div>
          </div>
          <div>
          {this.state.visning}
          </div>
        </div>
      </div>
    );
  }
}

export default HentSak;
