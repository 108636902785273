import React from 'react';

export const dummyFactory = (title, children = '(from `utils/components/dummyFactory`)') => {
    return class Dummy extends React.Component {
        render() {
            return (
                <div>
                    {title && <h1>{title}</h1>}
                    {children && <p>{children}</p>}
                </div>
            )
        }
    };
};

export const isClassComponent = (component) => {
    return (
        typeof component === 'function' &&
        !!component.prototype.isReactComponent
    ) ? true : false
};

export const isElement = (element) => {
    return React.isValidElement(element);
};
