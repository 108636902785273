import React, { Component } from 'react';
import { getKommunerForOrganisasjon } from 'api/kommuneApi';
import {
  getGrunndataAnalyseTiltak,
  getGrunndataAnalyseRoyklop,
  getGrunndataAnalyseTiltakHyppighet,
  getGrunndataAnalyseIldsted,
  getRisikoGrunndataAnalyse, 
} from 'api/grunndataAnalyseApi';
import * as _ from 'lodash';
//import PdfIcon from 'images/Annet/pdf.png';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import DonutRoyklop from './DonutRoyklop.jsx';
import DonutIldsted from './DonutIldsted.jsx';
import BarRoyklop5 from './BarRoyklop5.jsx';
import BarIldsted5 from './BarIldsted5.jsx';
import HorBarTiltak from './HorBarTiltak.jsx';
import DonutsHypp from './DonutsHypp.jsx';
import DonutsRisikoAnalyse from './DonutsRisikoAnalyse.jsx';
import { getOrgRisiko } from 'context/contextInnstillinger.js';

class Nokkeltall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kommuner: null,
      kommune: null,
      kommuneId: null,
      //startdato: moment('01.01.2020'),
      enddato: moment(),
      //startdatoRoyk: moment(),
      enddatoRoyk: moment(),
      tiltakdata: <div>Tiltak data ikke hentet</div>,
      royklopdata: <div>Røykløp data ikke hentet</div>,
      ildsteddata: <div>Ildsted data ikke hentet</div>,
      hyppdata: <div>Hyppighet data ikke hentet</div>,
      risikoAnalyseData: <div>Risiko analyse data ikke hentet</div>,
      tiltakDatoString: '',
      royklopDatoString: '',
      ildstedDatoString: '',
      visroyk5: false,
      visild5: false,
      harRisiko: false,
    };
  }

  componentDidMount() {
    let end = new Date();
    let year = end.getFullYear();
    let start = new Date(year, 0, 1);

    this.setState({
      startdato: start,
      startdatoRoyk: start,
      startdatoIld: start,
    });
    this.setState({ enddato: end, enddatoRoyk: end, enddatoIld: end });
    getOrgRisiko(this.gotRisiko);
    getKommunerForOrganisasjon(this.gotKommuner);
  }

  gotRisiko = (res) => {
    if (res) {
      this.setState({ harRisiko: res.data });
    }
  };

  kommuneChanged = (val) => {
    console.log(val.target.value);
    this.setState({
      kommuneId: val.target.value,
      tiltakdata: <div>Tiltak data ikke hentet</div>,
      royklopdata: <div>Røykløp data ikke hentet</div>,
      ildsteddata: <div>Ildsted data ikke hentet</div>,
      hyppdata: <div>Hyppighet data ikke hentet</div>,
    });
  };

  eksporterTilPdf = () => {
    // this.dialogs.setOpenDialog('PdfDialogen', {
    //   data: [] ,
    //   rapportType: 'gjennomforingArbeidsliste',
    //   tittel: 'test')
    // });
  };

  gotKommuner = (err, res) => {
    var result = res;
    if (result.length > 1) {
      var add = {
        id: 0,
        kommuneNavn: 'Alle',
        kommuneId: 0,
      };
      result.unshift(add);
    }
    this.setState({ kommuner: result, kommune: result[0] });
    console.log(res);
  };

  onChangeStartdato = (val) => {
    this.setState({ startdato: val ? val : moment('01.01.1900') });
    // this.setState({ startdato: val });
  };

  onChangeEnddato = (val) => {
    this.setState({ enddato: val ? val : moment('01.01.2100') });
    //this.setState({ enddato: val });
  };

  onChangeStartdatoRoyk = (val) => {
    this.setState({ startdatoRoyk: val ? val : moment('01.01.1900') });
    //this.setState({ startdatoRoyk: val });
  };

  onChangeEnddatoRoyk = (val) => {
    this.setState({ enddatoRoyk: val ? val : moment('01.01.2100') });
    //this.setState({ enddatoRoyk: val });
  };

  onChangeStartdatoIld = (val) => {
    this.setState({ startdatoIld: val ? val : moment('01.01.1900') });
    //this.setState({ startdatoIld: val });
  };

  onChangeEnddatoIld = (val) => {
    this.setState({ enddatoIld: val ? val : moment('01.01.2100') });
    //this.setState({ enddatoIld: val });
  };

  visTiltakforPeriode = () => {
    let sd = moment(this.state.startdato)
      .add(1, 'days')
      .toDate();

    let ed = moment(this.state.enddato)
      //.add(1, 'days')
      .toDate();
    let param = {
      kommunenr: this.state.kommuneId === '0' ? null : this.state.kommuneId,
      datoFra: sd,
      datoTil: ed,
    };

    let sdOrg = moment(this.state.startdato).toDate();
    let edOrg = moment(this.state.enddato).toDate();
    this.setState({
      tiltakDatoString:
        moment(sdOrg).format('DD.MM.YYYY') +
        ' - ' +
        moment(edOrg).format('DD.MM.YYYY'),
    });
    getGrunndataAnalyseTiltak(param, this.gotTiltakInfo);
    this.setState({ tiltakdata: <div>Henter data ...</div> });
  };

  visRoyklopforPeriode = () => {
    let sd = moment(this.state.startdatoRoyk)
      .add(1, 'days')
      .toDate();
    let ed = moment(this.state.enddatoRoyk)
      .add(1, 'days')
      .toDate();
    let param = {
      kommunenr: this.state.kommuneId === '0' ? null : this.state.kommuneId,
      datoFra: sd,
      datoTil: ed,
    };

    let sdOrg = moment(this.state.startdatoRoyk).toDate();
    let edOrg = moment(this.state.enddatoRoyk).toDate();
    this.setState({
      royklopDatoString:
        moment(sdOrg).format('DD.MM.YYYY') +
        ' - ' +
        moment(edOrg).format('DD.MM.YYYY'),
    });
    getGrunndataAnalyseRoyklop(param, this.gotRoyklopInfo);
    this.setState({ royklopdata: <div>Henter data ...</div> });
  };

  visIldstedforPeriode = () => {
    let sd = moment(this.state.startdatoIld)
      .add(1, 'days')
      .toDate();
    let ed = moment(this.state.enddatoIld)
      .add(1, 'days')
      .toDate();
    let param = {
      kommunenr: this.state.kommuneId === '0' ? null : this.state.kommuneId,
      datoFra: sd,
      datoTil: ed,
    };

    let sdOrg = moment(this.state.startdatoIld).toDate();
    let edOrg = moment(this.state.enddatoIld).toDate();
    this.setState({
      ildstedDatoString:
        moment(sdOrg).format('DD.MM.YYYY') +
        ' - ' +
        moment(edOrg).format('DD.MM.YYYY'),
    });
    getGrunndataAnalyseIldsted(param, this.gotIldstedInfo);
    this.setState({ ildsteddata: <div>Henter data ...</div> });
  };

  gotIldstedInfo = (err, res) => {
    this.setState({ visild5: true });
    let data = res;
    let result = (
      <div>
        <table style={{ fontSize: '15px' }}>
          <tbody>
            <tr
              style={{
                border: '1px solid',
                borderColor: 'gray',
                backgroundColor: '#F8F8F8',
              }}>
              <th style={{ width: '150px', textAlign: 'center' }}>
                Antall ildsted
              </th>
              <th style={{ width: '150px', textAlign: 'center' }}>
                Antall i bruk
              </th>
              <th style={{ width: '150px', textAlign: 'center' }}>
                Antall ikke i bruk
              </th>
            </tr>
            <tr
              style={{
                border: '1px solid',
                borderColor: 'gray',
                backgroundColor: '#F8F8F8',
              }}>
              <td style={{ width: '150px', textAlign: 'center' }}>
                {data.antallIldsted}
              </td>
              <td style={{ width: '150px', textAlign: 'center' }}>
                {data.antallIbruk}
              </td>
              <td style={{ width: '150px', textAlign: 'center' }}>
                {data.antallIkkeIbruk}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <DonutIldsted periode={this.state.ildstedDatoString} data={data} />
        <br />
        <br />
      </div>
    );
    this.setState({ ildsteddata: result });
  };

  visHyppforPeriode = () => {
    let param = {
      kommunenr: this.state.kommuneId === '0' ? null : this.state.kommuneId,
      datoFra: null,
      datoTil: null,
    };
    getGrunndataAnalyseTiltakHyppighet(param, this.gotHyppInfo);
    this.setState({ hyppdata: <div>Henter data ...</div> });
  };

  gotTiltakInfo = (err, res) => {
    let bt = res.bruksenhetTiltak;
    let rt = res.royklpoTiltak;

    this.formaterTiltakInfo(rt, bt);
  };

   visRisikoAnalyse = () => {
    let param = {
      kommunenr: this.state.kommuneId === '0' ? null : this.state.kommuneId,
      datoFra: null,
      datoTil: null,
    };

    getRisikoGrunndataAnalyse(param, this.gotRisikoGrunndataAnalyse);
    this.setState({ risikoAnalyseData: <div>Henter data ...</div> });
  }; 

  inRange = (x, min, max) => {
    return min <= x && x <= max;
  };

  getRisikoColor = (tall) => {
    let col = '#abcdef';
    if (tall === 0) {
      col = '#abcdef';
    } else if (this.inRange(tall, 1, 4)) {
      col = 'green';
    } else if (this.inRange(tall, 5, 14)) {
      col = '#FFAE42';
    } else if (this.inRange(tall, 15, 25)) {
      col = 'red';
    } else {
      col = '#abcdef';
    }
    return col;
  };

  checkRisikoDataForFlereNull = (data) => {
      let mergedArray = [];
      data.forEach((item) => {
        const existingItem = mergedArray.find((el) => el.risikoverdi === item.risikoverdi);
  
        if (existingItem) {
          // Hvis det finnes et objekt med samme risikoverdi, slår du sammen risikoverdiAntall
          existingItem.risikoverdiAntall +=  item.risikoverdiAntall;
        } else {
          // Else, push til mergedArray
          item.color = this.getRisikoColor(item.risikoverdi);
          mergedArray.push(item);
        }
      });

      return mergedArray;
    };
  

  gotRisikoGrunndataAnalyse = (err, res) => {
    //TEST
    /* res.bruksenhetRisikotall.push({ risikoverdi: 0, risikoverdiAntall: 1000, color: '' });
    res.bruksenhetRisikotall.push({ risikoverdi: 3, risikoverdiAntall: 1000, color: '' }); */
    let brR = this.checkRisikoDataForFlereNull(res.bruksenhetRisikotall);
    let roR = this.checkRisikoDataForFlereNull(res.royklopRisikotall);

    let result = (
      <div>
        <b>Risikotall for bruksenhet</b>
        <div>
          <tabel>
            <tbody>
              <tr
                style={{
                  border: '1px solid',
                  borderColor: 'gray',
                  backgroundColor: '#F8F8F8',
                }}>
                <td
                  style={{
                    fontWeight: 'bold',
                    marginRight: '4px',
                    border: '1px solid',
                    borderColor: 'gray',
                    width: '50px',
                  }}>
                  Verdi:
                </td>
                {brR.map((br, i) => (
                  <>
                    <td
                      style={{
                        backgroundColor: br.color,
                        marginRight: '4px',
                        border: '1px solid',
                        borderColor: 'gray',
                        width: '50px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                      {br.risikoverdi}
                    </td>
                  </>
                ))}
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: 'bold',
                    marginRight: '4px',
                    border: '1px solid',
                    borderColor: 'gray',
                    width: '50px',
                  }}>
                  Antall:
                </td>
                {brR.map((br, i) => (
                  <>
                    <td
                      style={{
                        marginRight: '4px',
                        border: '1px solid',
                        borderColor: 'gray',
                        width: '50px',
                        textAlign: 'center',
                      }}>
                      {br.risikoverdiAntall}
                    </td>
                  </>
                ))}
              </tr>
            </tbody>
          </tabel>
        </div>
        <br />
        <br />
        <b>Risikotall for røykløp</b>
        <div>
          <tabel>
            <tbody>
              <tr
                style={{
                  border: '1px solid',
                  borderColor: 'gray',
                  backgroundColor: '#F8F8F8',
                }}>
                <td
                  style={{
                    fontWeight: 'bold',
                    marginRight: '4px',
                    border: '1px solid',
                    borderColor: 'gray',
                    width: '50px',
                  }}>
                  Verdi:
                </td>
                {roR.map((ro, i) => (
                  <>
                    <td
                      style={{
                        backgroundColor: ro.color,
                        marginRight: '4px',
                        border: '1px solid',
                        borderColor: 'gray',
                        width: '50px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                      {ro.risikoverdi}
                    </td>
                  </>
                ))}
              </tr>

              <tr>
                <td
                  style={{
                    fontWeight: 'bold',
                    marginRight: '4px',
                    border: '1px solid',
                    borderColor: 'gray',
                    width: '50px',
                  }}>
                  Antall:
                </td>
                {roR.map((ro, i) => (
                  <>
                    <td
                      style={{
                        marginRight: '4px',
                        border: '1px solid',
                        borderColor: 'gray',
                        width: '50px',
                        textAlign: 'center',
                      }}>
                      {ro.risikoverdiAntall}
                    </td>
                  </>
                ))}
              </tr>
            </tbody>
          </tabel>
        </div>
        <br />
        <br />
        <DonutsRisikoAnalyse
          feiingdata={roR}
          tilsyndata={brR}
          filnavn='RisikoAnalyse'></DonutsRisikoAnalyse>
      </div>
    );
    this.setState({ risikoAnalyseData: result });
  };

  gotRoyklopInfo = (err, res) => {
    this.setState({ visroyk5: true });
    let data = res;
    let result = (
      <div>
        <table style={{ fontSize: '15px' }}>
          <tbody>
            <tr
              style={{
                border: '1px solid',
                borderColor: 'gray',
                backgroundColor: '#F8F8F8',
              }}>
              <th style={{ width: '150px', textAlign: 'center' }}>
                Antall røykløp
              </th>
              <th style={{ width: '150px', textAlign: 'center' }}>
                Antall i bruk
              </th>
              <th style={{ width: '150px', textAlign: 'center' }}>
                Antall ikke i bruk
              </th>
            </tr>
            <tr
              style={{
                border: '1px solid',
                borderColor: 'gray',
                backgroundColor: '#F8F8F8',
              }}>
              <td style={{ width: '150px', textAlign: 'center' }}>
                {data.antallRoyklop}
              </td>
              <td style={{ width: '150px', textAlign: 'center' }}>
                {data.antallIbruk}
              </td>
              <td style={{ width: '150px', textAlign: 'center' }}>
                {data.antallIkkeIbruk}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <DonutRoyklop periode={this.state.royklopDatoString} data={data} />
        <br />
        <br />
      </div>
    );
    this.setState({ royklopdata: result });
  };

  gotHyppInfo = (err, res) => {
    let brh = res.bruksenhetTiltakHyppighet;
    let roh = res.royklopTiltakHyppighet;

    let tdStyle = {
      textAlign: 'left',
      width: '210px',
    };

    let result = (
      <div>
        <b>Regelmessige tiltakstyper bruksenhet</b>
        {_.map(
          brh,
          function(brtiltak, inx) {
            return (
              <div key={'div' + inx}>
                <table style={{ fontSize: '14px' }}>
                  <tbody>
                    <tr
                      key={inx}
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}>
                      <th style={tdStyle}>{brtiltak.tiltak}</th>
                      {_.map(
                        brtiltak.hyppighetAntall,
                        function(hypp, inx) {
                          return (
                            <th
                              key={'pp' + inx}
                              style={{ width: '150px', textAlign: 'center' }}>
                              {hypp.tekst1 ? hypp.tekst1 : 'Ingen hypp.'}
                            </th>
                          );
                        },
                        this
                      )}
                    </tr>

                    <tr
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}>
                      <td style={tdStyle} />
                      {_.map(
                        brtiltak.hyppighetAntall,
                        function(hypp, inx) {
                          return (
                            <td
                              key={'ss' + inx}
                              style={{ width: '150px', textAlign: 'center' }}>
                              {hypp.tekst2}
                            </td>
                          );
                        },
                        this
                      )}
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            );
          },
          this
        )}
        <DonutsHypp data={brh} filnavn='HyppBruksenheter' />
        <hr />
        <b>Regelmessige tiltakstyper røykløp</b>
        {_.map(
          roh,
          function(brtiltak) {
            return (
              <div>
                <table style={{ fontSize: '14px' }}>
                  <tbody>
                    <tr
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}>
                      <th style={tdStyle}>{brtiltak.tiltak}</th>
                      {_.map(
                        brtiltak.hyppighetAntall,
                        function(hypp, inx) {
                          return (
                            <th
                              key={'ff' + inx}
                              style={{ width: '150px', textAlign: 'center' }}>
                              {hypp.tekst1 ? hypp.tekst1 : 'Ingen hypp.'}
                            </th>
                          );
                        },
                        this
                      )}
                    </tr>

                    <tr
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}>
                      <td style={tdStyle} />
                      {_.map(
                        brtiltak.hyppighetAntall,
                        function(hypp) {
                          return (
                            <td style={{ width: '150px', textAlign: 'center' }}>
                              {hypp.tekst2}
                            </td>
                          );
                        },
                        this
                      )}
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            );
          },
          this
        )}
        <DonutsHypp data={roh} filnavn='HyppRoyklop' />
      </div>
    );

    this.setState({ hyppdata: result });
  };

  formaterTiltakInfo = (rt, bt) => {
    let result = (
      <div>
        {bt.length === 0 ? (
          <div>Ingen bruksenhet tiltak funnet</div>
        ) : (
          <div>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <tr
                  style={{
                    border: '1px solid',
                    borderColor: 'gray',
                    backgroundColor: '#F8F8F8',
                  }}>
                  <th style={{ width: '200px', textAlign: 'rigth' }}>
                    Tiltaktype bruksenhet
                  </th>
                  <th style={{ width: '150px', textAlign: 'center' }}>
                    Antall utført
                  </th>
                  <th style={{ width: '150px', textAlign: 'center' }}>
                    Antall ikke utført
                  </th>
                </tr>
                {_.map(
                  bt,
                  function(tiltak, inx) {
                    return (
                      <tr
                        key={inx}
                        style={{
                          border: '1px solid',
                          borderColor: 'gray',
                          backgroundColor: '#F8F8F8',
                        }}>
                        <td style={{ width: '200px', textAlign: 'rigth' }}>
                          {tiltak.tiltakType}
                        </td>
                        <td style={{ width: '150px', textAlign: 'center' }}>
                          {tiltak.antallUtfort}
                        </td>
                        <td style={{ width: '150px', textAlign: 'center' }}>
                          {tiltak.antallIkkeUtfort}
                        </td>
                      </tr>
                    );
                  },
                  this
                )}
              </tbody>
            </table>
          </div>
        )}
        <hr />
        {rt.length === 0 ? (
          <div>Ingen røykløp tiltak funnet</div>
        ) : (
          <div>
            <table style={{ fontSize: '15px' }}>
              <tbody>
                <tr
                  style={{
                    border: '1px solid',
                    borderColor: 'gray',
                    backgroundColor: '#F8F8F8',
                  }}>
                  <th style={{ width: '200px', textAlign: 'rigth' }}>
                    Tiltaktype røykløp
                  </th>
                  <th style={{ width: '150px', textAlign: 'center' }}>
                    Antall utført
                  </th>
                  <th style={{ width: '150px', textAlign: 'center' }}>
                    Antall ikke utført
                  </th>
                </tr>
                {_.map(
                  rt,
                  function(tiltak, inx) {
                    return (
                      <tr
                        key={inx}
                        style={{
                          border: '1px solid',
                          borderColor: 'gray',
                          backgroundColor: '#F8F8F8',
                        }}>
                        <td style={{ width: '200px', textAlign: 'rigth' }}>
                          {tiltak.tiltakType}
                        </td>
                        <td style={{ width: '150px', textAlign: 'center' }}>
                          {tiltak.antallUtfort}
                        </td>
                        <td style={{ width: '150px', textAlign: 'center' }}>
                          {tiltak.antallIkkeUtfort}
                        </td>
                      </tr>
                    );
                  },
                  this
                )}
              </tbody>
            </table>
          </div>
        )}
        <br />
        <br />
        <HorBarTiltak
          periode={this.state.tiltakDatoString}
          dataEnhet={bt}
          dataRoyklop={rt}
        />
      </div>
    );
    this.setState({ tiltakdata: result });
  };

  render() {
    let accStyle = {
      backgroundColor: '#ffffff',
      border: '1px solid rgb(153, 153, 153)',
      borderRadius: '0',
      marginBottom: '15px',
    };
    let chevronStyle = {
      float: 'right',
      margin: '4px 4px 0 0',
    };
    let panelStyle = {
      marginLeft: '20px',
      marginRight: '20px',
      height: 'auto',
    };
    let resultStyle = {
      marginLeft: '20px',
      marginBottom: '20px',
    };

    if (this.state.kommuner == null) {
      return <div className='loader' />;
    }
    return (
      <div>
        <h2>Nøkkeltall</h2>
        <div className='form'>
          <div className='row'>
            <p />
            <div className='col-lg-4 col-sm-12 col-md-6'>
              <div className='form-group'>
                <label className='top-margin-4'>Kommune&nbsp;&nbsp;</label>
                <select className='h30 width220' onChange={this.kommuneChanged}>
                  {_.map(this.state.kommuner, function(o, i) {
                    return (
                      <option key={i} value={o.kommuneId}>
                        {o.kommuneNavn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <p />
        <div className='row'>
          <div className='panel-group' id='accordion1' style={{ margin: '0' }}>
            <div className='panel panel-default'>
              <div
                className='panel-heading collapsed sbpilpanel'
                data-toggle='collapse'
                data-parent='#accordion'
                href='#1'
                style={accStyle}>
                <h4
                  className='panel-title'
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}>
                  <span style={{ marginRight: '10px' }}>
                    Summering av tiltak
                  </span>
                </h4>
                <span style={chevronStyle} className='chevron fa fa-fw' />
              </div>
              <div id='1' className='panel-collapse collapse'>
                <div className='row'>
                  {/* <span>
                    <button
                      className="btn btn-default pull-right"
                      title="Eksporter til Pdf"
                      onClick={this.eksporterTilPdf}
                      style={{ marginRight: '10px' }}
                    >
                      <img src={PdfIcon} alt="pdf" height="24" width="24" />
                    </button>
                  </span> */}
                  <div style={panelStyle}>
                    <h4>
                      Oversikt over registrerte tiltak for gitt periode.
                      {/* Angi Fra-dato og Til-dato og trykk Vis */}
                    </h4>
                    {/* <hr />
                    <h5>Valgt kommune: {this.state.kommuneNavn}</h5> */}
                    <hr />
                    <table>
                      <tbody>
                        <tr>
                          <td>Dato fra</td>
                          <td style={{ width: '130px' }}>
                            <DatePicker
                              className='datepickerStor'
                              id='dato'
                              selected={moment(this.state.startdato)}
                              // startDate={moment()}
                              placeholderText='Velg dato..'
                              todayButton={'I dag'}
                              onChange={this.onChangeStartdato.bind(this)}
                              isClearable={true}
                              showYearDropdown
                            />
                          </td>
                          <td>Dato til</td>
                          <td style={{ width: '130px' }}>
                            <DatePicker
                              className='datepickerStor'
                              id='dato'
                              selected={moment(this.state.enddato)}
                              // startDate={moment()}
                              placeholderText='Velg dato..'
                              todayButton={'I dag'}
                              onChange={this.onChangeEnddato.bind(this)}
                              isClearable={true}
                              showYearDropdown
                            />
                          </td>
                          <td>
                            <button
                              className='btn btn-default'
                              style={{
                                background: '#51A026',
                                color: 'white',
                                height: '32px',
                              }}
                              onClick={this.visTiltakforPeriode}
                              type='button'>
                              Vis
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <hr />
                    <div style={resultStyle}>
                      {this.state.tiltakdata}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />

          <div className='panel-group' id='accordion2' style={{ margin: '0' }}>
            <div className='panel panel-default'>
              <div
                className='panel-heading collapsed sbpilpanel gebyr'
                data-toggle='collapse'
                data-parent='#accordion'
                href='#2'
                style={accStyle}>
                <h4
                  className='panel-title'
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}>
                  <span style={{ marginRight: '10px' }}>
                    Summering av røykløp
                  </span>
                </h4>
                <span style={chevronStyle} className='chevron fa fa-fw' />
              </div>

              <div id='2' className='panel-collapse collapse react-table'>
                <div className='row'>
                  {/* <span>
                    <button
                      className="btn btn-default pull-right"
                      title="Eksporter til Pdf"
                      onClick={this.eksporterTilPdf}
                      style={{ marginRight: '10px' }}
                    >
                      <img src={PdfIcon} alt="pdf" height="24" width="24" />
                    </button>
                  </span> */}
                  <div key='panelX' style={panelStyle}>
                    <h4>Oversikt over registrerte røykløp for gitt periode.</h4>

                    <table>
                      <tbody>
                        <tr>
                          <td>Dato fra</td>
                          <td style={{ width: '130px' }}>
                            <DatePicker
                              className='datepickerStor'
                              id='dato'
                              selected={moment(this.state.startdatoRoyk)}
                              // startDate={moment()}
                              placeholderText='Velg dato..'
                              todayButton={'I dag'}
                              onChange={this.onChangeStartdatoRoyk.bind(this)}
                              isClearable={true}
                              setMaxDate={moment().toDate()}
                              showYearDropdown
                            />
                          </td>
                          <td>Dato til</td>
                          <td style={{ width: '130px' }}>
                            <DatePicker
                              className='datepickerStor'
                              id='dato'
                              selected={moment(this.state.enddatoRoyk)}
                              // startDate={moment()}
                              placeholderText='Velg dato..'
                              todayButton={'I dag'}
                              onChange={this.onChangeEnddatoRoyk.bind(this)}
                              isClearable={true}
                              setMaxDate={moment().toDate()}
                              showYearDropdown
                            />
                          </td>
                          <td>
                            <button
                              className='btn btn-default'
                              style={{
                                background: '#51A026',
                                color: 'white',
                                height: '32px',
                              }}
                              onClick={this.visRoyklopforPeriode}
                              type='button'>
                              Vis
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <div style={resultStyle}>
                      {this.state.royklopdata}
                      {this.state.visroyk5 && (
                        <BarRoyklop5 kommuneId={this.state.kommuneId} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />
          <div className='panel-group' id='accordion4' style={{ margin: '0' }}>
            <div className='panel panel-default'>
              <div
                className='panel-heading collapsed sbpilpanel gebyr'
                data-toggle='collapse'
                data-parent='#accordion'
                href='#4'
                style={accStyle}>
                <h4
                  className='panel-title'
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}>
                  <span style={{ marginRight: '10px' }}>
                    Summering av ildsted
                  </span>
                </h4>
                <span style={chevronStyle} className='chevron fa fa-fw' />
              </div>

              <div id='4' className='panel-collapse collapse react-table'>
                <div className='row'>
                  {/* <span>
                    <button
                      className="btn btn-default pull-right"
                      title="Eksporter til Pdf"
                      onClick={this.eksporterTilPdf}
                      style={{ marginRight: '10px' }}
                    >
                      <img src={PdfIcon} alt="pdf" height="24" width="24" />
                    </button>
                  </span> */}
                  <div style={panelStyle}>
                    <h4>Oversikt over registrerte ildsted for gitt periode.</h4>

                    <table>
                      <tbody>
                        <tr>
                          <td>Dato fra</td>
                          <td style={{ width: '130px' }}>
                            <DatePicker
                              className='datepickerStor'
                              id='dato'
                              selected={moment(this.state.startdatoIld)}
                              // startDate={moment()}
                              placeholderText='Velg dato..'
                              todayButton={'I dag'}
                              onChange={this.onChangeStartdatoIld.bind(this)}
                              isClearable={true}
                              setMaxDate={moment().toDate()}
                              showYearDropdown
                            />
                          </td>
                          <td>Dato til</td>
                          <td style={{ width: '130px' }}>
                            <DatePicker
                              className='datepickerStor'
                              id='dato'
                              selected={moment(this.state.enddatoIld)}
                              // startDate={moment()}
                              placeholderText='Velg dato..'
                              todayButton={'I dag'}
                              onChange={this.onChangeEnddatoIld.bind(this)}
                              isClearable={true}
                              setMaxDate={moment().toDate()}
                              showYearDropdown
                            />
                          </td>
                          <td>
                            <button
                              className='btn btn-default'
                              style={{
                                background: '#51A026',
                                color: 'white',
                                height: '32px',
                              }}
                              onClick={this.visIldstedforPeriode}
                              type='button'>
                              Vis
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr />
                    <div style={resultStyle}>
                      {this.state.ildsteddata}
                      {this.state.visild5 && (
                        <BarIldsted5 kommuneId={this.state.kommuneId} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />
          <div className='panel-group' id='accordion3' style={{ margin: '0' }}>
            <div className='panel panel-default'>
              <div
                className='panel-heading collapsed sbpilpanel gebyr'
                data-toggle='collapse'
                data-parent='#accordion'
                href='#3'
                style={accStyle}>
                <h4
                  className='panel-title'
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}>
                  <span style={{ marginRight: '10px' }}>
                    Summering av hyppighet
                  </span>
                </h4>
                <span style={chevronStyle} className='chevron fa fa-fw' />
              </div>
              <div id='3' className='panel-collapse collapse react-table'>
                <div style={panelStyle}>
                  <h4>
                    Hyppighet for tiltakstyper fordelt på røykløp og
                    bruksenheter.
                  </h4>
                  <button
                    className='btn btn-default'
                    style={{
                      background: '#51A026',
                      color: 'white',
                      height: '32px',
                    }}
                    onClick={this.visHyppforPeriode}
                    type='button'>
                    Vis
                  </button>
                  <hr />

                  <div style={resultStyle}>
                    {this.state.hyppdata}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />
       {/*    {this.state.harRisiko &&  !isThisProd() && (  */}
          {this.state.harRisiko && ( 
            <div
              className='panel-group'
              id='accordion5'
              style={{ margin: '0' }}>
              <div className='panel panel-default'>
                <div
                  className='panel-heading collapsed sbpilpanel gebyr'
                  data-toggle='collapse'
                  data-parent='#accordion'
                  href='#5'
                  style={accStyle}>
                  <h4
                    className='panel-title'
                    style={{
                      display: 'inline-block',
                      width: 'calc(100% - 40px)',
                    }}>
                    <span style={{ marginRight: '10px' }}>
                      Summering risikotall
                    </span>
                  </h4>
                  <span style={chevronStyle} className='chevron fa fa-fw' />
                </div>
                <div id='5' className='panel-collapse collapse react-table'>
                  <div style={panelStyle}>
                    <h4>Risikotall fordelt på røykløp og bruksenheter.</h4>
                    <button
                      className='btn btn-default'
                      style={{
                        background: '#51A026',
                        color: 'white',
                        height: '32px',
                      }}
                      onClick={this.visRisikoAnalyse} 
                      type='button'>
                      Vis
                    </button>
                    <hr />

                    <div style={resultStyle}>
                      {this.state.risikoAnalyseData}
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Nokkeltall;
