import React, { useState, useEffect, useImperativeHandle } from 'react';
import Spinner from 'components/generics/Spinner';
import moment from 'moment';
import NKIcon from 'nka-icons';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import Collapsible from 'react-collapsible';
import 'components/collapsibleBF.css';

const DialogRader = React.forwardRef(({ ...props }, ref) => {
  const [dialogRader, setDialogRader] = useState([]);
  const [modalKnuppOpen, setModalKnuppOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    childFunction1,
  }));

  useEffect(() => {
    setDialogRader(props.dialogRader);
  }, [props.dialogRader]);


  const childFunction1 = () => {
    alert('Hei 1');
  };

  const getIcon = (innlegg) => {
    let ik =
      innlegg.avsender === 'BF' || innlegg.avsender === undefined
        ? 'kommune'
        : 'hjem';

    return <NKIcon size='1' color='nk-black' icon={ik} />;
  };

  const getHtml = (innlegg) => {
    let txt = innlegg.tekst ? innlegg.tekst : '';
    if (txt !== '') {
      return <div dangerouslySetInnerHTML={{ __html: txt }}></div>;
    } else {
      return (
        <div>
          <span style={{ margin: '10px', color: 'gray' }}>Ingen text</span>
        </div>
      );
    }
  };

  return (
    <div style={{ margin: '4px', padding: '10px' }}>
      {dialogRader.length === 0 ? (
        <>
          <Spinner className='h4' />
        </>
      ) : (
        <>

        <label>{props.avvikType}</label>
        
        {dialogRader.map((innlegg, index) => 
           <Collapsible key={'cc' + index}  trigger={
            <table style={{ width: '100%' }} key={'cct' + index}>
               <tbody>
                  <tr>
                    <td style={{ width: '26px' }}>{getIcon(innlegg)}</td>
                    <td><><span style={{ fontSize: '14px'  }}>Reg.</span><span> {moment(innlegg.registrertDato).format('DD.MM.YYYY') + ' - ' + innlegg.registrertAv}</span></></td>
                    <td style={{ width: 'auto' }}></td>
                    <td style={{ width: '200px', fontSize: '14px'  }}>{innlegg.saksnummer ?  'Saksnr: ' + innlegg.saksnummer: ''   }</td>
                  </tr>
                </tbody>
            </table>
        }>
            <div style={{ minHeight: '50px', backgroundColor: 'white' }}>
              <span style={{ fontSize: '14px', color: 'blue' }}>{'Kontakt: ' + innlegg.eierkontakt} </span>
              <br/><br/>
              {getHtml(innlegg)}</div>
        </Collapsible>
        
        )}
               
        </>
      )}

      {/* *************************** Dialoger *********************** */}

      <OKDialog
        tittel={'Hei og ho'}
        melding={'Du er en knupp!'}
        isOpen={modalKnuppOpen}
        onClose={() => setModalKnuppOpen(false)}
        onOk={() => setModalKnuppOpen(false)}
      />
      {/* *************************** END Dialoger *********************** */}
    </div>
  );
});
export default DialogRader;
