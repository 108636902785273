import React from 'react';

const PrikDialog = (props) => {
  return (
    <>
      <div
        onClick={props.openSoekDialog}
        style={{
          paddingLeft: '6px',
          paddingTop: '2px',
          borderColor: 'lightgray',
          borderWidth: '1px',
          borderStyle: 'solid',
          verticalAlign: 'middle',
          minHeight: '29px',
          lineHeight: '24px',
          float: 'left',
          backgroundColor: 'white',
          width: props.width,
          fontSize: '14px',
          cursor: 'pointer'
        }}>
        {props.tekst}
      </div>
      <div style={{ marginLeft: '4px', float: 'left' }}>
        <button
          onClick={props.openSoekDialog}
          style={{ fontWeight: 'bold', fontSize: '16px' }}>
          ...
        </button>
      </div>
    </>
  );
};

export default PrikDialog;
