import 'moment/locale/nb';  
import moment from 'moment';
import 'moment/locale/nb';


export const sjekkOmRoedDag = date => {
    let d = moment(date).format('DD.MM.YYYY');
    let d1 = d.substring(0, 5);

    let isRoed = '';
    for (let i = 0; i < rodeDager.length; i++) {
      if (rodeDager[i].dato === d1) {
        isRoed = rodeDager[i].tit;
        break;  
      }
    }
    if (isRoed === '') {
      if (date.getMonth() > 2 && date.getMonth() < 6) {
        isRoed = getEaster(date);
      }
    }
    return isRoed;
  };

const rodeDager = [
    { dato: '01.01', tit: 'ny' },
    { dato: '01.05', tit: 'ar' },
    { dato: '17.05', tit: 'gr' },
    { dato: '24.12', tit: 'ju' },
    { dato: '25.12', tit: 'ju' },
    { dato: '31.12', tit: 'na' }
  ];

const getEaster = date => {
    let year = date.getFullYear();
    var f = Math.floor,
      // Golden Number - 1
      G = year % 19,
      C = f(year / 100),
      // related to Epact
      H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
      // number of days from 21 March to the Paschal full moon
      I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
      // weekday for the Paschal full moon
      J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
      // number of days from 21 March to the Sunday on or before the Paschal full moon
      L = I - J,
      month = 3 + f((L + 40) / 44),
      day = L + 28 - 31 * f(month / 4);
    let easterListe = [];
    easterListe.push({
      dato: new Date(year, month - 1, day).valueOf(),
      tit: 'på'
    });
    easterListe.push({
      dato: new Date(year, month - 1, day - 2).valueOf(),
      tit: 'la'
    });
    easterListe.push({
      dato: new Date(year, month - 1, day - 3).valueOf(),
      tit: 'sk'
    });
    easterListe.push({
      dato: new Date(year, month - 1, day + 1).valueOf(),
      tit: 'på'
    });
    easterListe.push({
      dato: new Date(year, month - 1, day + 39).valueOf(),
      tit: 'hi'
    });
    easterListe.push({
      dato: new Date(year, month - 1, day + 49).valueOf(),
      tit: 'pi'
    });
    easterListe.push({
      dato: new Date(year, month - 1, day + 50).valueOf(),
      tit: 'pi'
    });

    let finnes = '';
    for (let i = 0; i < easterListe.length; i++) {
      if (easterListe[i].dato.valueOf() === date.valueOf()) {
        finnes = easterListe[i].tit;
        break;
      }
    }
    return finnes;
  };

export const getTextColor = hexcolor => {
    hexcolor = hexcolor.replace('#', '');
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 128) ? 'black' : 'white';
};

export const weekNummer = date => {
  let weeknum = moment(date, 'MMDDYYYY').isoWeek();
      return weeknum;
  };

export const eventDateTimePluss = (date, d, h) => {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  return new Date(year, month, day + d, hour + h, minutes);
};