import React, { Component } from 'react';
import { getAlleBrukerInnstillingerForKunde } from 'api/arkivApi';
import ReactTable from '@norkart/react-table';
var _ = require('underscore');
 
class VisBrukere extends Component {
  constructor(props) {
  super(props);
  this.state = { brukere: null };
  
  }

componentDidMount() {
    getAlleBrukerInnstillingerForKunde(
        this.props.kundeId,
        '123',
        (err, res) => {
          this.setState({ brukere: res });
        }
      );
}

getColumns() {
  return [
    {
      id: 'Bruker',
      name: 'Bruker',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'AdministrativEnhetInit',
      name: 'Adm.Enhet',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'Journalenhet',
      name: 'Journalenhet',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'SaksbehandlerInit',
      name: 'SaksbehandlerInit',
      filterable: false,
      isSorted: false,
      sortable: false
    }
  ];
}

render() {
    console.log(this.state.brukere);
    if (!this.state.brukere) {return null;}
   return (
    <div style={{ margin: '30px' }}>
        <h4>Brukere</h4>
        <ReactTable
            items={this.state.brukere}
            columns={this.getColumns()}
            showIndex={false}
          />
          <p/>
        {/* <table border='1'>
        {_.map(
            this.state.brukere,
            function(bruker) {
              console.log(bruker);
              return (
                
                <div>
                  
                  <tr>
                    <td>Bruker</td>
                    <td>{bruker.Bruker}</td>
                  </tr>
                  <tr>
                    <td>Adm.Enhet</td>
                    <td>{bruker.AdministrativEnhetInit}</td>
                  </tr>
                  <tr>
                    <td>Journalenhet</td>
                    <td>{bruker.Journalenhet}</td>
                  </tr>
                  <tr>
                    <td>SaksbehandlerInit</td>
                    <td>{bruker.SaksbehandlerInit}</td>
                  </tr>
                  
                </div>
              );
            },
            this
          )}
        </table> */}
    </div>
    );
  }
}

export default VisBrukere;