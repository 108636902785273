import React from 'react';
import Titler from './Titler.jsx';

export default class extends React.Component {
    state = { breadcrumbname: 'Laster....' };

    render() {
        return <div>
            <Titler />
        </div>;
    }
}