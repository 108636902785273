import React, { Component } from 'react';
import { debugFactory } from 'utils';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import moment from 'moment';
import L from 'leaflet';
import NKModal from 'components/Dialogs/NKModal.jsx';
import DatePicker from 'react-datepicker';
import ModalDialogTable from 'modules/felles/avvikAnmerkninger/ModalDialogTable.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import SmsSkarpAdvarselDialog from 'components/Dialogs/SmsSkarpAdvarselDialog.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import DialogStore from 'lib/DialogStore';
import ClusterListe from './clusterListe.jsx';
import { sendEierevarsel2 } from 'api/smsApi.js';
import BasisKart from 'components/Kart/BasisKart.jsx';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import './sortable.css';
import { isUndefined } from 'lodash';

const debug = debugFactory('nk:planlegging:VarsleSmsMedKart');


const modalDialogs = {
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose}
      itemId={dialogData.itemId}
      bruksenhetIdMa={dialogData.bruksenhetIdMa}
      itemAdresse={dialogData.itemAdresse}
    />
  ),
  ModalDialogTable: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen}>
      <ModalDialogTable
        onClose={onClose}
        adresse={dialogData.adresse}
        id={dialogData.id}
      />
    </NKModal>
  ),
  Matrikkelinfo: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size='medium'
        buttonText={'Lukk'}
        tittel='Matrikkelinfo'
        onClose={onClose}>
        <Matrikkelinfo id={dialogData.id} />
      </ModalContainer>
    );
  },
  TilpassTidDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Tilpasse varsel liste?'}
        melding={'Ønsker du å tilpasse fra/til-tid i resten av listen?'}
        onJa={onClose(owner.onJa.bind(this, dialogData))}
        onNei={onClose}
      />
    );
  },
  LockDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Låse enheter med dato/tid?'}
        melding={dialogData.melding}
        onJa={onClose(owner.onJaLock.bind(this, dialogData))}
        onNei={onClose(owner.onNeiLock.bind(this, dialogData))}
      />
    );
  },
  ClusterIndexDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal style={{ width: '400px' }} isOpen={isOpen}>
        <ClusterListe
          onClose={onClose}
          ref='clusterliste'
          items={dialogData.items}
          bruk={owner.bruk}
          sorterNummer={dialogData.sorterNummer}
        />
      </NKModal>
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        isOpen={isOpen}
        onClose={onClose}
        onOk={onClose}
      />
    );
  },
};

moment.locale('nb', {
  week: {
    dow: 1,
    doy: 1,
  },
});

class VarsleSmsMedKart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enheterMedEiere: [],
      startdato: moment(),
      datepickerClassname: 'datepickerRed',
      datepickerWarning: 'sjekk dato',
      starttimer: 8,
      startminuter: 0,
      starttimerFast: 8,
      startminuterFast: 0,
      slutttimerFast: 11,
      sluttminuterFast: 0,
      startnummer: 1,
      interval: 30,
      brukKart: false,
      brukKartTekst: 'Start rekkefølge ved klikk på kart',
      brukColor: '#2e2d30',
      iconColor: 'nk-white',
      sorterNummer: 1,
      visStore: false,
      alternativ: 'ned',
      varselItem: null,
      kartcol: 'col-sm-9',
      visExtra: false,
      pauseTid: 30,
      basiskart: null,
      modalOkSendIsOpen: false,
      sendInProgress: false,
      lockTekst: 'Lås alle',
      modus: 'intervall', //'dato'  'fasttid'
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }
  

  bruk = (blokk, index) => {
    let enheterMedEiere = this.state.enheterMedEiere;
    //startliste
    let startListe = [];
    for (let y = 0; y < index - 1; y++) {
      startListe.push(enheterMedEiere[y]);
    }
    //blokk
    let teller = 0;
    for (let i = 0; i < blokk.length; i++) {
      blokk[i].index = index + teller;
      teller = teller + 1;
      startListe.push(blokk[i]);
    }
    //sluttliste
    let sluttListe = [];
    let t = startListe.length;
    for (let z = 0; z < enheterMedEiere.length; z++) {
      let f = _.find(startListe, {
        bruksenhetId: enheterMedEiere[z].bruksenhetId,
      });
      if (!f) {
        let rad = enheterMedEiere[z];
        t = t + 1;
        rad.index = t;
        sluttListe.push(rad);
      }
    }
    for (let r = 0; r < sluttListe.length; r++) {
      startListe.push(sluttListe[r]);
    }
    this.setState({ enheterMedEiere: startListe });
    this.setState({ sorterNummer: this.state.sorterNummer + blokk.length });
    this.dialogs.closeDialog();
  };

  componentDidMount = () => {
    debug('houdmoed props', this.props);
    let k = this.kart;
    this.setState({ basiskart: k });
    this.setState({ modus: this.props.modus });
    let teller = this.sjekkOmLock();
    if (teller > 0) {
      this.dialogs.setOpenDialog('LockDialog', {
        melding:
          'Du har merket ' +
          teller +
          ' enheter som allerede har en dato/tid. Ønsker du å låse dem?',
      });
    } else {
      this.getEierData(false);
    }
  };

  onJaLock = (x) => {
    this.getEierData(true);
  };

  onNeiLock = (x) => {
    this.getEierData(false);
  };

  //******************* Make Lister med  enheterMedEiere/ enheterMedEiereMedTelefon / enheterMedEiereUtenTelefon
  sortByIndex = (liste, rev) => {
    const iteratees = (obj) => obj.index;
    if (rev) {
      liste = _.sortBy(liste, iteratees).reverse();
    } else {
      liste = _.sortBy(liste, iteratees);
    }

    return liste;
  };

  giNyIndex = (liste) => {
    let teller = 1;
    for (let i = 0; i < liste.length; i++) {
      let a = liste[i];
      a.index = teller;
      teller = teller + 1;
    }
    return liste;
  };

  sjekkOmLock = () => {
    let enheter = this.props.smsEnheter;
    let teller = 0;
    for (let i = 0; i < enheter.length; i++) {
      let enhet = enheter[i];
      if (enhet.datoTid !== '') {
        teller = teller + 1;
      }
    }
    return teller;
  };

  getEierData = (lock) => {
    let enheterMedEiere = [];
    let enheter = this.props.smsEnheter;
    debug('1234', enheter);
    let enheterMedKontakter = this.props.enheterMedKontakter;
    //Merge de to
    for (let i = 0; i < enheter.length; i++) {
      let enhet = enheter[i];
      let enhetMedKontakt = enheterMedKontakter.find(
        (item) => item.bruksenhetFagId === enhet.id
      );
      if (enhetMedKontakt) {
        let dt = undefined;
        let laas = false;
        if (enhet.datoTid !== '' && lock) {
          laas = true;
        }

        if (enhet.datoTid !== '') {
          let tf = '';
          let tt = '';
          let dd = moment(enhet.planlagtDato).format('DD.MM.YYYY');
          let d = moment(enhet.planlagtDato).format('DD.MMM');
          if (enhet.planlagtBesokFra) {
            tf = enhet.planlagtBesokFra;
          }
          if (enhet.planlagtBesokTil) {
            tt = enhet.planlagtBesokTil;
          }
          if (enhet.planlagtBesokTil) {
            dt = { dd, d, tf, tt };
          }
        }
       
        let smsBesok = {
          arbeidslisteId: this.props.arbeidslisteId,
          bruksenhetId: enhet.id,
          bruksenhetAdresse: enhet.adresse,
          bruksenhetIdMa: enhet.bruksenhetIdMa,
          smsBesokDato: '',
          smsBesokFra: '',
          smsBesokTil: '',
          lokasjonLatitude: enhet.lokasjonLatitude,
          lokasjonLongitude: enhet.lokasjonLongitude,
          hartelefon: this.kontaktHarTelefon(enhetMedKontakt.kontakt),
          tekst: '',
          matrikkelenhetId: enhet.matrikkelenhetId,
          eiendom: enhet.eiendom,
          varselstatusId: 2,  //Burde det gamle status være med her?
          kontakt: enhetMedKontakt.kontakt,
          tiltak: this.makeTiltak(enhet),
          lock: laas,
          datoTid: dt,
        };
        if (enhet.planlagtBesokTil && enhet.planlagtBesokTil) {
          smsBesok.datoTid = this.checkSs(smsBesok);
        }
        enheterMedEiere.push(smsBesok);
      }
    }

    // this.splitEnheter(enheterMedEiere);
    enheterMedEiere = this.giNyIndex(enheterMedEiere);
    enheterMedEiere = this.sortByIndex(enheterMedEiere, false);
    debug('houdmoed enheterMedEiere', enheterMedEiere);
    this.setState({ enheterMedEiere });
  };

  makeTiltak = (enhet) => {
    let s = '';
    if (enhet.tiltakBruksenhet) {
      s = enhet.tiltakBruksenhet.trim() + ',';
    }
    if (enhet.tiltakRoyklop) {
      s = s + enhet.tiltakRoyklop.trim();
    }
    if (s.charAt(s.length - 1) === ',') {
      s = s.substr(0, s.length - 1);
    }
    s = s.replace(' ', '');
    s = s.replace(',', ', ');
    return s;
  };

  kontaktHarTelefon = (kontakter) => {
    let hartelefon = false;
    //sjekk om i hvertfall 1 telefon er gyldig
    for (let i = 0; i < kontakter.length; i++) {
      const k = kontakter[i];
      // eslint-disable-next-line eqeqeq
      if (k.ugyldigTelefonNr == false) {
        hartelefon = true;
        break;
      }
    }
    return hartelefon;
  };
  //*******************

  //******************* Alt hva som er i top panel

  onChangeStartdato = (val) => {
    let same = val.isSame(moment(), 'day');
    if (same) {
      this.setState({ datepickerClassname: 'datepickerRed'})
      this.setState({ datepickerWarning: 'sjekk dato'})
    } else {
      this.setState({ datepickerClassname: 'datepicker'})
      this.setState({ datepickerWarning: ''})
    }
    this.setState({ startdato: val });
  };

  onChangeInterval = (val) => {
    this.setState({ interval: val.target.value });
  };

  onChangeStarttimer = (val) => {
    this.setState({ starttimer: val.target.value });
  };

  onChangeStartminuter = (val) => {
    this.setState({ startminuter: val.target.value });
  };

  onChangeStarttimerFast = (val) => {
    this.setState({ starttimerFast: val.target.value });
  };

  onChangeStartminuterFast = (val) => {
    this.setState({ startminuterFast: val.target.value });
  };

  onChangeSlutttimerFast = (val) => {
    this.setState({ slutttimerFast: val.target.value });
  };

  onChangeSluttminuterFast = (val) => {
    this.setState({ sluttminuterFast: val.target.value });
  };

  onStartnummerChange = (val) => {
    this.setState({ startnummer: val.target.value });
  };

  //**********************************

  //******************* Make interval

  getDateTimeFromItem = (item) => {
    let dato = null;
    let datoTid = null;
    if (item.datoTid) {
      datoTid = item.datoTid;
    } else {
      return null;
    }
    let h = parseInt(datoTid.tf.substr(0, 2));
    let m = parseInt(datoTid.tf.substr(3, 2));

    let dd = datoTid.dd.substr(0, 2);
    let mm = datoTid.dd.substr(3, 2);
    let yy = datoTid.dd.substr(6, 4);
    let dz = yy + '-' + mm + '-' + dd;
    dato = new Date(dz);
    dato = moment(dato);
    dato.set({ h: h, m: m });
    return dato;
  };

  getDateTimeTilFromItem = (item) => {
    let dato = null;
    let datoTid = null;
    if (item.datoTid) {
      datoTid = item.datoTid;
    } else {
      return null;
    }
    let h = parseInt(datoTid.tt.substr(0, 2));
    let m = parseInt(datoTid.tt.substr(3, 2));

    let dd = datoTid.dd.substr(0, 2);
    let mm = datoTid.dd.substr(3, 2);
    let yy = datoTid.dd.substr(6, 4);
    let dz = yy + '-' + mm + '-' + dd;
    dato = new Date(dz);
    dato = moment(dato);
    dato.set({ h: h, m: m });
    return dato;
  };

  getDateFromItem = (item) => {
    let d = null;
    if (item.datoTid) {
      d = item.datoTid.dd;
    }
    return d;
  };

  makeDateObjekt = (date, interval) => {
    let dd = date.format('DD.MM.YYYY');
    let d = date.format('DD MMM');

    let tf = '';
    let date2 = '';
    let tt = '';

    tf = date.format('HH:mm');
    date2 = moment(date).add(interval, 'minutes');
    tt = date2.format('HH:mm');

    let obj = { dd, d, tf, tt };
    return obj;
  };

  makeDate = (dato) => {
    let d = dato.substr(0, 2);
    let m = dato.substr(3, 2);
    let y = dato.substr(6, 4);
    var newdate = new Date(y, m - 1, d, 11, 0, 0);
    return newdate;
  };

  setPause = () => {
    let enheter = this.state.enheterMedEiere;
    let interval = this.state.interval;
    let pauseTid = this.state.pauseTid;
    let item = this.state.varselItem;
    let mulig = true;
    debug('houdmoed', item);
    if (!item.datoTid) {
      mulig = false;
    } else {
      if (item.datoTid.tf === '') {
        mulig = false;
      }
    }

    if (!mulig) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Pause kan ikke settes inn..',
        melding: 'Velg  "Sett inn dato/tid"  først.',
      });
      return;
    }

    let x = item.index;
    let ix = item.index + 1;
    let itemP = enheter.find((enhet) => enhet.index == ix);
    if (!itemP) {
      return;
    }
    let startdato = this.getDateTimeFromItem(itemP);
    let dag = this.getDateFromItem(itemP);
    startdato = moment(startdato).add(pauseTid, 'minutes');

    for (let y = 0; y < enheter.length; y++) {
      let element = enheter[y];
      if (element.index > x && !element.lock) {
        if(element.datoTid === undefined){
          this.dialogs.setOpenDialog('OkDialog', {
            tittel: 'Tiden kan ikke endres..',
            melding: 'Velg  "Sett inn dato/tid"  først.',
          });
          return;
        }
        if (element.datoTid.dd === dag) {
          //Kun pause på samme dag, ikke skyv opp neste dag
          element.datoTid = this.makeDateObjekt(startdato, interval);
          startdato = moment(startdato).add(interval, 'minutes');
        }
      } else {
        debug('Lock', element);
        debug('Lock modus', this.state.modus);
      }
    }

    this.setState({ enheterMedEiere: enheter });
  };

  brukInterval = () => {
    this.addDatoTid(this.state.startnummer);
  };

  makeNesteDatoTidMedIntervall = (lastTid1) => {
    let starttimer = this.state.starttimer;
    let startminuter = this.state.startminuter;
    let interval = this.state.interval;
    let resDatoFra = this.state.startdato;
    resDatoFra.set({ h: starttimer, m: startminuter, s: 0 });

    if (lastTid1) {
      debug('lock LastT', lastTid1);
      resDatoFra = lastTid1;
      resDatoFra = moment(resDatoFra).add(interval, 'minutes');
    }

    /*  if (lockTid) {
      debug('lock LockT', lockTid.til);
      resDatoFra = lockTid.til;
    } */

    let resDatoTil = moment(resDatoFra).add(interval, 'minutes');
    let dd = resDatoFra.format('DD.MM.YYYY');
    let d = resDatoFra.format('DD MMM');
    let tf = resDatoFra.format('HH:mm');
    let tt = resDatoTil.format('HH:mm');
    let ss = resDatoFra.format('YYYYMMDDHHmm');
    ss = ss + resDatoTil.format('HHmm');
    return {
      fra: resDatoFra,
      til: resDatoTil,
      dd: dd,
      d: d,
      tf: tf,
      tt: tt,
      ss: ss,
    };
  };

  makeNesteDatoUtenIntervall = () => {
    let resDatoFra = this.state.startdato;
    resDatoFra.set({ h: 0, m: 0, s: 0 });
    let dd = resDatoFra.format('DD.MM.YYYY');
    let d = resDatoFra.format('DD MMM');
    let ss = resDatoFra.format('YYYYMMDDHHmm');
    return {
      fra: resDatoFra,
      dd: dd,
      d: d,
      tf: '',
      tt: '',
      ss: ss,
    };
  };

  makeFastTid = () => {
    let resDatoFra = this.state.startdato;
    let starttimer = this.state.starttimerFast;
    let startminuter = this.state.startminuterFast;
    resDatoFra.set({ h: starttimer, m: startminuter, s: 0 });
    let dd = resDatoFra.format('DD.MM.YYYY');
    let d = resDatoFra.format('DD MMM');
    let tf = resDatoFra.format('HH:mm');
    let ss = resDatoFra.format('YYYYMMDDHHmm');

    let resDatoTil = resDatoFra;
    let slutttimer = this.state.slutttimerFast;
    let sluttminuter = this.state.sluttminuterFast;
    resDatoTil.set({ h: slutttimer, m: sluttminuter, s: 0 });

    let tt = resDatoTil.format('HH:mm');
    ss = ss + resDatoTil.format('HHmm');
    return {
      fra: resDatoFra,
      til: resDatoTil,
      dd: dd,
      d: d,
      tf: tf,
      tt: tt,
      ss: ss,
    };
  };

  addDatoTid = (start) => {
    let enheterLocked = [];
    let lastDate = null;
    let obj = null;
    let enheter = this.sortByIndex(this.state.enheterMedEiere, false);
    for (let i = 0; i < enheter.length; i++) {
      let enhet = enheter[i];
      if (!enhet.lock) {
        if (enhet.index >= start) {
          if (obj) {
            if (obj.fra) {
              lastDate = obj.fra;
            }
          }
          switch (this.state.modus) {
            case 'intervall':
              obj = this.makeNesteDatoTidMedIntervall(lastDate);
              break;
            case 'dato':
              obj = this.makeNesteDatoUtenIntervall();
              break;
            case 'fasttid':
              obj = this.makeFastTid();
              break;
            default:
          }
          debug('VVV 3', obj);
          if (obj) {
            enhet.datoTid = obj;
          }
        }
      } else {
        debug('lock a', enhet);
        //har de en ss?
        enhet.datoTid = this.checkSs(enhet);
        if (this.state.modus === 'intervall') {
          enheterLocked.push(enhet);
        }
      }
    }

    let attrPath = 'datoTid.ss';
    enheter = _.orderBy(enheter, [(item) => _.get(item, attrPath)]);
    enheter = this.giNyIndex(enheter);
    this.setState({ enheterMedEiere: enheter });

    /*  if (this.state.modus === 'intervall') {
      this.intervallSorterMedLock(enheterLocked, enheter);
    } */
  };

  intervallSorterMedLock = (enheterLocked, enheter) => {
    debug('999 start', enheter);
    // enheter = _.filter(enheter, { lock: false });
    debug('999 unlocked', enheter);
    debug('999 locked', enheterLocked);

    let forrigeTil = null;
    for (let i = 0; i < enheter.length; i++) {
      if (i > 0) {
        forrigeTil = enheter[i - 1].datoTid.til;
      }
      let fra = enheter[i].datoTid.fra;
      debug('999 enhet', enheter[i].bruksenhetAdresse);
      debug('999 forrige', forrigeTil);
      debug('999 fra', fra);
      debug('999 ');
      debug('999 ');
      debug('999 ');
    }
  };

  checkSs = (item) => {
    if (item.datoTid) {
      if (item.datoTid.ss) {
        return item.datoTid;
      } else {
        let s = '';
        let dd = item.datoTid.dd.substr(0, 2);
        let mm = item.datoTid.dd.substr(3, 2);
        let yy = item.datoTid.dd.substr(6, 4);
        let h = item.datoTid.tf.substr(0, 2);
        let m = item.datoTid.tf.substr(3, 2);
        let h2 = item.datoTid.tt.substr(0, 2);
        let m2 = item.datoTid.tt.substr(3, 2);
        s = yy + mm + dd + h + m + h2 + m2;
        item.datoTid.ss = s;
        return item.datoTid;
      }
    }
  };

  //**********************************

  //*********************** Alternativer
  alternativer = () => {
    let pil = this.state.alternativ;
    if (pil === 'ned') {
      pil = 'opp';
    } else {
      pil = 'ned';
    }
    this.setState({ alternativ: pil });
  };

  snuRekkefolge = () => {
    let enheterMedEiere = this.state.enheterMedEiere;
    enheterMedEiere = this.sortByIndex(enheterMedEiere, true);
    enheterMedEiere = this.giNyIndex(enheterMedEiere);
    enheterMedEiere = this.sortByIndex(enheterMedEiere, false);
    this.setState({ enheterMedEiere });
  };

  //**********************************

  onSortEnd = ({ oldIndex, newIndex }) => {
    let enheterMedEiere = this.state.enheterMedEiere;
    let enhet = enheterMedEiere[oldIndex];
    if (!enhet) {
      return;
    }

    enheterMedEiere = arrayMove(enheterMedEiere, oldIndex, newIndex);
    enheterMedEiere = this.giNyIndex(enheterMedEiere);
    this.setState({ enheterMedEiere }, this.brukInterval());
  };

  onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) {
      event.preventDefault();
    }
  };

  togglekartSortering = () => {
    let bruk = !this.state.brukKart;
    this.setState({ brukKart: bruk });
    let tekst = 'Start rekkefølge ved klikk på kart ';
    let color = '#2e2d30';
    let iColor = 'nk-white';
    if (bruk) {
      tekst = 'Avslutt rekkefølge på kart';
      color = '#A1C438';
      iColor = 'nk-black';
    }
    this.setState({
      brukKartTekst: tekst,
      brukColor: color,
      iconColor: iColor,
    });
    this.setState({ sorterNummer: 1 });
  };

  closeExtra = () => {
    this.setState({ varselItem: null, visExtra: false, kartcol: 'col-sm-9' });
    //  this.state.basiskart.doGoBound();
  };

  onItemClick = (item, panorer) => {
    if (this.state.varselItem && item.index === this.state.varselItem.index) {
      this.setState({ varselItem: null, visExtra: false, kartcol: 'col-sm-9' });
    } else {
      this.setState({ varselItem: item, visExtra: true, kartcol: 'col-sm-7' });
    }
    // this.state.basiskart.doGoBound();
    if (panorer) {
      let lok = {};
      lok.lokasjon = {};
      lok.lokasjon.lat = item.lokasjonLatitude;
      lok.lokasjon.lon = item.lokasjonLongitude;
      this.state.basiskart.sprett(lok);
    }
  };

  checkForCluster = (item) => {
    let dobbele = [item];
    let lat = item.lokasjonLatitude;
    let lng = item.lokasjonLongitude;
    for (let i = 0; i < this.state.enheterMedEiere.length; i++) {
      let enhet = this.state.enheterMedEiere[i];
      if (enhet.bruksenhetId !== item.bruksenhetId) {
        if (enhet.lokasjonLatitude === lat && enhet.lokasjonLongitude === lng) {
          dobbele.push(enhet);
        }
      }
    }
    if (dobbele.length > 1) {
      const iteratees = (obj) => obj.bruksenhetAdresse;
      dobbele = _.sortBy(dobbele, iteratees).reverse();
      this.dialogs.setOpenDialog('ClusterIndexDialog', {
        items: dobbele,
        sorterNummer: this.state.sorterNummer,
      });
      return false;
    } else {
      return true;
    }
  };

  clusterIndexResult = (e) => {
    debug('cluster result', e);
  };

  onClickedVarsel = (item) => {
    debug(item);
    if (item) {
      if (this.checkForCluster(item)) {
        // let max = this.state.enheterMedEiereMedTelefon.length;
        let max = this.state.enheterMedEiere.length;
        let oldIndex = item.index - 1;
        let newIndex = this.state.sorterNummer - 1;

        if (this.state.sorterNummer >= max) {
          this.togglekartSortering();
          return;
        }

        this.onSortEnd({ oldIndex, newIndex });
        this.setState({ sorterNummer: this.state.sorterNummer + 1 });
      }
    }
  };

  lukkVarselControl = () => {
    this.setState({ varselItem: null });
  };

  pauseChange = (e) => {
    this.setState({ pauseTid: e.currentTarget.value });
  };

  fjern = () => {
    let nr = this.state.varselItem.index;
    let liste = this.state.enheterMedEiere;
    for (var i = liste.length - 1; i >= 0; i--) {
      if (liste[i].index === nr) {
        liste.splice(i, 1);
      }
    }
    liste = this.giNyIndex(liste);
    this.setState({ enheterMedEiere: [] });
    setTimeout(this.oppdaterListe(liste, this.state.varselItem), 10000);
  };

  oppdaterListe = (enheterMedEiere, item) => {
    enheterMedEiere = this.sortByIndex(enheterMedEiere);
    enheterMedEiere = this.sortByIndex(enheterMedEiere);
    this.setState({ enheterMedEiere });
    this.setState({ varselItem: null, visExtra: false, kartcol: 'col-sm-9' });
    // this.state.basiskart.doGoBound();
    this.skalTidenTilpasses(enheterMedEiere, item);
  };

  skalTidenTilpasses = (liste, item) => {
    debug('tilp C');
    if (item.datoTid) {
      this.dialogs.setOpenDialog('TilpassTidDialog', {
        liste: liste,
        item: item,
      });
    }
  };

  onJa = (data, t) => {
    let liste = data.liste;
    let item = data.item;
    let minusTid = this.getMinusTid(item);
    let startnr = item.index - 1;
    let dag = this.getDateFromItem(item);

    //Alle tider, samme dag, både fra og til etter valgtItem skal nå få 15 pluss eller minus
    for (let y = 0; y < liste.length; y++) {
      let element = liste[y];
      if (element.index > startnr) {
        if (element.datoTid.dd === dag) {
          //Kun endring på samme dag, ikke skiv opp neste dag
          let dd1 = this.getDateTimeFromItem(element);
          let dd2 = this.getDateTimeTilFromItem(element);
          dd1 = moment(dd1).subtract(minusTid.minutes, 'minutes');
          dd2 = moment(dd2).subtract(minusTid.minutes, 'minutes');

          element.datoTid.tf = dd1.format('HH:mm');
          element.datoTid.tt = dd2.format('HH:mm');
        }
      }
    }
    this.setState({ enheterMedEiere: liste });
  };

  timeDiff = (date1, date2) => {
    var a = new Date(date1).getTime(),
      b = new Date(date2).getTime(),
      diff = {};

    diff.milliseconds = a > b ? a % b : b % a;
    diff.seconds = diff.milliseconds / 1000;
    diff.minutes = diff.seconds / 60;
    diff.hours = diff.minutes / 60;
    diff.days = diff.hours / 24;
    diff.weeks = diff.days / 7;

    return diff;
  };

  getMinusTid = (item) => {
    debug('tilp', item);
    let dd1 = this.getDateTimeTilFromItem(item);
    let dd2 = this.getDateTimeFromItem(item);
    let diff = this.timeDiff(dd1, dd2);
    return diff;
  };

  onClickMatrikkel = () => {
    let item = this.state.varselItem;
    this.dialogs.setOpenDialog('Matrikkelinfo', {
      id: item.bruksenhetIdMa,
    });
  };

  onClickSituasjon = () => {
    let item = this.state.varselItem;
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemId: item.bruksenhetId,
      bruksenhetIdMa: item.bruksenhetIdMa,
      itemAdresse: item.bruksenhetAdresse,
    });
  };

  onClickInnbyggerDialog = () => {
    let item = this.state.varselItem;
    this.dialogs.setOpenDialog('ModalDialogTable', {
      id: item.bruksenhetId,
      adresse: item.adresse,
    });
  };

  endreTidPluss = () => {
    debug('endretid+');
    this.endreTid(true);
  };

  endreTidMinus = () => {
    this.endreTid(false);
  };

  endreTid = (pluss) => {
    let enheter = this.state.enheterMedEiere;
    let item = this.state.varselItem;
    let mulig = true;
    if (!item.datoTid) {
      mulig = false;
    } else {
      if (item.datoTid.tf === '') {
        mulig = false;
      }
    }

    if (!mulig) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Tiden kan ikke endres..',
        melding: 'Velg  "Sett inn dato/tid"  først.',
      });
      return;
    }

    let ix = item.index;
    let itemP = enheter.find((enhet) => enhet.index == ix);
    if (!itemP) {
      return;
    }
    let sluttdato = this.getDateTimeTilFromItem(itemP);
    let dag = this.getDateFromItem(itemP);
    if (pluss) {
      sluttdato = moment(sluttdato).add(15, 'minutes');
    } else {
      sluttdato = moment(sluttdato).subtract(15, 'minutes');
    }
    let newTil = sluttdato.format('HH:mm');
    if (newTil === item.datoTid.tf) {
      return;
    }
    item.datoTid.tt = newTil;

    //Alle tider, samme dag, både fra og til etter valgtItem skal nå få 15 pluss eller minus
    for (let y = 0; y < enheter.length; y++) {
      let element = enheter[y];
      if (element.index > ix && !element.lock) {
        if (element.datoTid.dd === dag) {
          //Kun endring på samme dag, ikke skiv opp neste dag
          let dd1 = this.getDateTimeFromItem(element);
          let dd2 = this.getDateTimeTilFromItem(element);
          if (pluss) {
            dd1 = moment(dd1).add(15, 'minutes');
            dd2 = moment(dd2).add(15, 'minutes');
          } else {
            dd1 = moment(dd1).subtract(15, 'minutes');
            dd2 = moment(dd2).subtract(15, 'minutes');
          }
          element.datoTid.tf = dd1.format('HH:mm');
          element.datoTid.tt = dd2.format('HH:mm');
        }
      }
    }

    this.setState({ enheterMedEiere: enheter });
  };

  closeSendIsOpen = () => {
    this.setState({ modalOkSendIsOpen: false });
  };

  sendSms = () => {
    let liste = this.state.enheterMedEiere;
    if (liste[0].datoTid === undefined) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Varsel kan ikke sendes..',
        melding: 'Planlagt dato mangler!.',
      });
      return;
    }
    this.setState({ modalOkSendIsOpen: true });
  };

  stripKontakter = (kontakter) => {
    let k = [];
    for (let i = 0; i < kontakter.length; i++) {
      const kontakt = kontakter[i];
      if (!kontakt.ugyldigTelefonNr && !kontakt.smsUtelat) {
        k.push(kontakt);
      }
    }
    return k;
  };

  okSend = () => {
    this.setState({ modalOkSendIsOpen: false });
    this.setState({ sendInProgress: true });
    let liste = [];
    for (let i = 0; i < this.state.enheterMedEiere.length; i++) {
      let eh = this.state.enheterMedEiere[i];
      //   if (eh.hartelefon){
      let obj = {
        bruksenhetId: eh.bruksenhetId,
        bruksenhetAdresse: eh.bruksenhetAdresse,
        arbeidslisteId: eh.arbeidslisteId,
        planlagtDato: eh.datoTid ? this.makeDate(eh.datoTid.dd) : null,
        besokFra: eh.datoTid ? eh.datoTid.tf : '',
        besokTil: eh.datoTid ? eh.datoTid.tt : '',
        tekst: eh.tekst,
        matrikkelenhetId: eh.matrikkelenhetId,
        varselstatusId: eh.varselstatusId,
        rekkefolgeNummer: eh.index,
        // kontakt: eh.kontakt,
        kontakt: this.sjekkKontakter(eh.kontakt),
        eierkontakt: '',
        eierkontaktTlf: '',
      };
      liste.push(obj);
      //   }
    }
    if (liste) {
      if (liste.length > 0) {
        this.sendSmsUt(liste);
      }
    }
  };

  sjekkKontakter = (kontakter) => {
    for (let i = 0; i < kontakter.length; i++) {
      if (kontakter[i].ugyldigTelefonNr) {
        kontakter[i].smsUtelat = true;
      }
    }

    return kontakter;
  };

  sendSmsUt = (liste) => {
    sendEierevarsel2(liste, this.sendSmsFraSmsKartHarBlittSend);
    // test this.sendSmsFraSmsKartHarBlittSend('nix');
  };

  sendSmsFraSmsKartHarBlittSend = (err) => {
    this.setState({ sendInProgress: false }, this.props.refreshArbeidsliste());
  };

  lockAlle = () => {
    let lock = false;
    if (this.state.lockTekst === 'Lås alle') {
      lock = true;
      this.setState({ lockTekst: 'Låse opp alle' });
    } else {
      lock = false;
      this.setState({ lockTekst: 'Lås alle' });
    }
    let enheterMedEiere = this.state.enheterMedEiere;
    for (let i = 0; i < enheterMedEiere.length; i++) {
      enheterMedEiere[i].lock = lock;
    }
    this.setState({ enheterMedEiere });
  };

  lockClick = (e) => {
    let varselItem = this.state.varselItem;
    varselItem.lock = !varselItem.lock;
    this.setState({ varselItem });
  };

  setModus = (modus) => {
    debug('VVV', modus);
    this.setState({ modus: modus });
  };

  render() {
    if (this.state.sendInProgress) {
      return <div className='loader' />;
    }
    const topLabel = {
      marginLeft: '10px',
      marginTop: '5px',
      fontWeight: 'normal',
    };
    const SortableItem = SortableElement(({ item }) => (
      <li className='SortableItem'>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td align='left' rowSpan='2' style={{ width: '34px' }}>
                {item.hartelefon === true && (
                  <button
                    title='Vis mer informasjon'
                    onClick={this.onItemClick.bind(this, item, true)}
                    className='numberCircle'>
                    {item.index}
                  </button>
                )}
                {item.hartelefon === false && (
                  <button
                    title='Vis mer informasjon'
                    onClick={this.onItemClick.bind(this, item, true)}
                    className='numberCircleRed'>
                    {item.index}
                  </button>
                )}
              </td>
              <td align='left'>
                <button
                  onClick={this.onItemClick.bind(this, item, false)}
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    color: 'blue',
                    height: '30px',
                    fontSize: '14px',
                    paddingLeft: '0px',
                    float: 'left',
                    whiteSpace: 'nowrap',
                    /* borderLeftWidth: '0px' */
                  }}
                  className='pull-left'>
                  {item.bruksenhetAdresse}
                </button>
              </td>
            </tr>
            <tr>
              {/*  <td style={{ width: '32px' }}>
                
              </td> */}
              <td align='left'>
                <span
                  style={{
                    float: 'left',
                    marginRight: '4px',
                    fontSize: '12px',
                  }}>
                  {item.datoTid === undefined ? '' : item.datoTid.d}
                </span>
                <span style={{ float: 'left', fontSize: '12px' }}>
                  {item.datoTid === undefined
                    ? ''
                    : item.datoTid.tf !== ''
                    ? item.datoTid.tf + ' - ' + item.datoTid.tt
                    : ''}
                </span>
                <span style={{ float: 'left', marginLeft: '4px' }}>
                  {item.lock && (
                    <NKIcon
                      className='pull-left'
                      icon='lukket'
                      size='0.6'
                      color='nk-black'
                    />
                  )}
                </span>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul className='SortableList'>
          {items.map((item, index) => (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          ))}
        </ul>
      );
    });

    const makeEierliste = (kontakt) => {
      return (
        <React.Fragment>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              {_.map(kontakt, function(k) {
                let telefonnummer = k.telefon;
                if (k.ugyldigTelefonNr) {
                  if (!k.telefon) {
                    telefonnummer = 'Mobiltlf. ikke funnet';
                  } else {
                    telefonnummer = 'Ugyldig telefonnr.';
                  }
                }
                return (
                  <tr key={k.id}>
                    <td>
                      {k.ugyldigTelefonNr ? (
                        <span style={{ color: '#CB4335' }}>
                          {k.navn + ' - ' + telefonnummer}
                        </span>
                      ) : (
                        k.navn + ' - ' + telefonnummer
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </React.Fragment>
      );
    };

    return (
      <div className='modal-content modalheight'>
        {/* HEADER */}
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <button
            className='btn btn-success pull-right'
            style={{ marginRight: '14px' }}
            onClick={this.sendSms}
            type='button'>
            <span>Send varsel</span>
          </button>

          <span>
            <h4
              className='modal-title blokk'
              style={{ marginBottom: '2px', fontWeight: 'bold' }}>
              {' '}
              Varsling
            </h4>
            <label className='blokk' style={topLabel}>
              Dato
            </label>{' '}
            <span className='blokk'>
              <DatePicker
                className= {this.state.datepickerClassname}
                id='dato'
                selected={this.state.startdato}              
                startDate={moment()}
                placeholderText='Velg dato..'
                todayButton={''}
                onChange={this.onChangeStartdato.bind(this)}
                isClearable={false}
                showYearDropdown
                setMinDate={moment().toDate()}
                title= {this.state.datepickerWarning}
              />
            </span>
          </span>

          <span>
            {this.state.modus === 'fasttid' && (
              <React.Fragment>
                <label style={topLabel}>Fra</label>

                <select
                  style={{ marginLeft: '4px' }}
                  value={this.state.starttimerFast}
                  onChange={this.onChangeStarttimerFast.bind(this)}>
                  <option value='5'>05</option>
                  <option value='6'>06</option>
                  <option value='7'>07</option>
                  <option value='8'>08</option>
                  <option value='9'>09</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                </select>
                <select
                  style={{ marginLeft: '2px' }}
                  value={this.state.startminuterFast}
                  onChange={this.onChangeStartminuterFast.bind(this)}>
                  <option value='0'>00</option>
                  <option value='15'>15</option>
                  <option value='30'>30</option>
                  <option value='45'>45</option>
                </select>

                <label style={topLabel}>Til</label>

                <select
                  style={{ marginLeft: '4px' }}
                  value={this.state.slutttimerFast}
                  onChange={this.onChangeSlutttimerFast.bind(this)}>
                  <option value='5'>05</option>
                  <option value='6'>06</option>
                  <option value='7'>07</option>
                  <option value='8'>08</option>
                  <option value='9'>09</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                </select>
                <select
                  style={{ marginLeft: '2px' }}
                  value={this.state.sluttminuterFast}
                  onChange={this.onChangeSluttminuterFast.bind(this)}>
                  <option value='0'>00</option>
                  <option value='15'>15</option>
                  <option value='30'>30</option>
                  <option value='45'>45</option>
                </select>
              </React.Fragment>
            )}
          </span>
          <span>
            {this.state.modus === 'intervall' && (
              <React.Fragment>
                <label style={topLabel}>Start tid</label>

                <select
                  style={{ marginLeft: '4px' }}
                  value={this.state.starttimer}
                  onChange={this.onChangeStarttimer.bind(this)}>
                  <option value='5'>05</option>
                  <option value='6'>06</option>
                  <option value='7'>07</option>
                  <option value='8'>08</option>
                  <option value='9'>09</option>
                  <option value='10'>10</option>
                  <option value='11'>11</option>
                  <option value='12'>12</option>
                  <option value='13'>13</option>
                  <option value='14'>14</option>
                  <option value='15'>15</option>
                  <option value='16'>16</option>
                  <option value='17'>17</option>
                  <option value='18'>18</option>
                  <option value='19'>19</option>
                  <option value='20'>20</option>
                  <option value='21'>21</option>
                  <option value='22'>22</option>
                </select>
                <select
                  style={{ marginLeft: '2px' }}
                  value={this.state.startminuter}
                  onChange={this.onChangeStartminuter.bind(this)}>
                  <option value='0'>00</option>
                  <option value='15'>15</option>
                  <option value='30'>30</option>
                  <option value='45'>45</option>
                </select>

                <label style={topLabel}>Intervall</label>

                <select
                  style={{ marginLeft: '4px' }}
                  value={this.state.interval}
                  onChange={this.onChangeInterval.bind(this)}>
                  <option value='15'> 15 min.</option>
                  <option value='20'> 20 min.</option>
                  <option value='30'> 30 min.</option>
                  <option value='40'> 40 min.</option>
                  <option value='45'> 45 min.</option>
                  <option value='60'> 1 time.</option>
                  <option value='90'>1.5 time.</option>
                  <option value='120'> 2 timer</option>
                </select>
              </React.Fragment>
            )}
          </span>
          <span>
            <label style={topLabel}>Start ved nr.</label>

            <input
              value={this.state.startnummer}
              style={{
                paddingLeft: '4px',
                marginLeft: '4px',
                width: '50px',
                marginRight: '10px',
              }}
              onChange={this.onStartnummerChange}
              min='1'
              max={this.state.enheterMedEiere.length}
              type='number'
              onKeyPress={this.onKeyPress.bind(this)}
            />

            <button
              className='btn btn-default'
              style={{
                background: '#2e2d30',
                color: 'white',
                height: '38px',
              }}
              onClick={this.brukInterval}
              type='button'>
              {this.state.modus === 'intervall' && (
                <span>Sett dato og intervall-tid</span>
              )}
              {this.state.modus === 'dato' && <span>Sett dato</span>}
              {this.state.modus === 'fasttid' && (
                <span>Sett dato og fast tid</span>
              )}
            </button>
          </span>
        </div>

        {/* BODY */}
        <div className='modal-body' style={{ padding: '4px' }}>
          <div className='row'>
            {/* Sortable liste */}
            <div className='col-sm-3' style={{ height: '400px' }}>
              <div>
                {/*  <button
                  onClick={this.alternativer}
                  className='btn btn-default'
                  style={{
                    width: '100%',
                    marginTop: '4px',
                    marginBottom: '0px',
                    marginLeft: '4px',
                    marginRight: '4px',
                    backgroundColor: '#2e2d30',
                    color: 'white',
                  }}
                  data-toggle='collapse'
                  data-target='#options'>
                  Alternativer
                  <NKIcon
                    className='pull-right'
                    icon={this.state.alternativ}
                    size='0.8'
                    style={{ marginRight: '6px' }}
                    color='nk-white'
                  />
                </button>

                <div id='options' className='collapse'>
                  <button
                    onClick={this.lockAlle}
                    className='btn btn-default'
                    style={{
                      width: '90%',
                      marginTop: '4px',
                      marginBottom: '0px',
                      marginLeft: '12px',
                      marginRight: '4px',
                    }}>
                    {this.state.lockTekst}
                  </button>
                   <br />
                  <button
                    onClick={this.snuRekkefolge}
                    className='btn btn-default'
                    style={{
                      width: '90%',
                      marginTop: '4px',
                      marginBottom: '0px',
                      marginLeft: '12px',
                      marginRight: '4px',
                    }}>
                    Snu rekkefølge
                  </button>
                  <br />

                  <span
                    style={{ marginLeft: '16px', marginTop: '6px' }}
                    className='btn-group'
                    data-toggle='buttons'>
                    <button
                      onClick={() => this.setModus('intervall')}
                      className='btn btn-default active'
                      style={{ height: '32px' }}>
                      <input type='radio' defaultChecked='true' />
                      Dato og intervall
                    </button>
                    <button
                      onClick={() => this.setModus('dato')}
                      className='btn btn-default'
                      style={{ height: '32px' }}>
                      <input type='radio' />
                      Kun dato
                    </button>
                    <button
                      onClick={() => this.setModus('fasttid')}
                      className='btn btn-default'
                      style={{ height: '32px' }}>
                      <input type='radio' />
                      Dato og fast tid
                    </button>
                  </span> 
                  <br />
                </div>*/}
              </div>

              <button
                onClick={this.togglekartSortering}
                className='btn btn-default'
                style={{
                  height: '36px',
                  width: '100%',
                  margin: '4px',
                  background: this.state.brukColor,
                  color: 'white',
                }}>
                <NKIcon
                  icon='kart'
                  size='1'
                  style={{ marginRight: '12px' }}
                  color={this.state.iconColor}
                />
                {this.state.brukKartTekst}
              </button>
              {this.state.brukKart && (
                <label className='pull-right' style={{ marginLeft: '10px' }}>
                  {'Settes nå: ' + this.state.sorterNummer.toString() + '  →'}
                </label>
              )}

              <div
                style={{
                  width: '100%',
                  marginLeft: '4px',
                  marginBottom: '4px',
                }}>
                <SortableList
                  style={{ height: '100%' }}
                  items={this.state.enheterMedEiere}
                  onSortEnd={this.onSortEnd}
                  helperClass='SortableHelper'
                  distance={50}
                />
              </div>
            </div>{' '}
            {/* Kart */}
            <div className={this.state.kartcol}>
              <BasisKart  //Fix
                kartClassName= 'kartenheterFxModal'
                id='kart'
                ref={(ref) => (this.kart = ref)}
                data={this.state.enheterMedEiere}
                visning={'planlegging-varsel'}
                sorterModus={this.state.brukKart}
                onClickedVarsel={this.onClickedVarsel}
                lukkVarselControl={this.lukkVarselControl}

                /*  varselItem={this.state.varselItem}
                pause={this.pause} */
              />
            </div>
            {/* Ekstra */}
            {this.state.visExtra && (
              <div className='col-sms-2 ' style={{ padding: '4px' }}>
                {this.state.varselItem.index < 90000 && (
                  <label style={{ marginTop: '10px' }}>
                    {this.state.varselItem.index + '.'}
                  </label>
                )}
                {this.state.varselItem.index > 90000 && (
                  <label style={{ marginTop: '10px', color: 'red' }}>0.</label>
                )}
                <span style={{ marginLeft: '10px', marginRight: '8px' }}>
                  Lås
                </span>
                <span style={{ verticalAlign: 'bottom' }}>
                  <input
                    style={{ marginTop: '4px' }}
                    alt=''
                    className='checkbox20'
                    checked={this.state.varselItem.lock}
                    onClick={this.lockClick}
                    type='checkbox'
                  />
                </span>
                <button
                  className='pull-right'
                  style={{
                    marginTop: '4px',
                    marginRight: '10px',
                    fontSize: '14px',
                  }}
                  title='Skjul panel'
                  type='button'
                  onClick={this.closeExtra}>
                  <span>x</span>
                </button>
                <br />
                {!this.state.varselItem.hartelefon && (
                  <span style={{ color: '#CB4335', fontWeight: 'bold' }}>
                    {this.state.varselItem.bruksenhetAdresse}
                  </span>
                )}
                {this.state.varselItem.hartelefon && (
                  <span style={{fontWeight: 'bold' }}>{this.state.varselItem.bruksenhetAdresse}</span>
                )}
                {/*   <br />
                <span style={{ fontSize: '12px' }}>
                  {' '}
                  {this.state.varselItem.eiendom}{' '}
                </span> */}
                <br />
                Tiltak:
                <span style={{ marginLeft: '2px', fontSize: '12px' }}>
                  {this.state.varselItem.tiltak}{' '}
                </span>
                <br />
                <span>Eier(e):</span>
                <br />
                {this.state.varselItem.kontakt &&
                  makeEierliste(this.state.varselItem.kontakt)}
                <br />
                {this.state.modus === 'intervall' && (
                  <React.Fragment>
                    <span>Pause etter nr. {this.state.varselItem.index}</span>
                    )
                    <br />
                    <span>
                      <input
                        style={{ left: '16px', marginRight: '4px' }}
                        type='radio'
                        name='tidRadio'
                        value='15'
                        defaultChecked={false}
                        onChange={this.pauseChange}
                      />
                      15 min.
                    </span>
                    <span style={{ marginLeft: '18px' }}>
                      <input
                        style={{ left: '16px', marginRight: '4px' }}
                        type='radio'
                        name='tidRadio'
                        value='30'
                        defaultChecked={true}
                        onChange={this.pauseChange}
                      />
                      30 min.
                    </span>
                    <button
                      onClick={this.setPause}
                      style={{ width: '190px', marginBottom: '4px' }}
                      className='btn btn-default'>
                      Sett inn pause
                    </button>
                    <br />
                    <br />
                    Endre besøk nr. {this.state.varselItem.index} med 15 min.
                    <br />
                    <table style={{ width: '220px' }}>
                      <tbody>
                        <tr>
                          <td>
                            <button
                              className='btn btn-default'
                              onClick={this.endreTidPluss}>
                              <NKIcon
                                className='pull-left'
                                icon='pluss'
                                size='0.8'
                                color='nk-black'
                              />
                              Pluss
                            </button>
                          </td>
                          <td>
                            <button
                              className='btn btn-default'
                              onClick={this.endreTidMinus}>
                              <NKIcon
                                className='pull-left'
                                icon='minus'
                                size='0.8'
                                color='nk-black'
                              />
                              Minus
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                  </React.Fragment>
                )}
                <button
                  onClick={this.fjern}
                  style={{ width: '190px', marginBottom: '4px' }}
                  className='btn btn-default'>
                  Fjern fra varsel liste
                </button>
                <br />
                <button
                  onClick={this.onClickMatrikkel}
                  style={{ width: '190px', marginBottom: '4px' }}
                  className='btn btn-default'>
                  Vis matrikkel
                </button>
                <br />
                <button
                  onClick={this.onClickSituasjon}
                  style={{ width: '190px', marginBottom: '4px' }}
                  className='btn btn-default'>
                  Vis situasjon
                </button>
                <br />
                <button
                  onClick={this.onClickInnbyggerDialog}
                  style={{ width: '190px', marginBottom: '4px' }}
                  className='btn btn-default'>
                  Vis innbyggerdialog
                </button>
              </div>
            )}
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
        <SmsSkarpAdvarselDialog
          isOpen={this.state.modalOkSendIsOpen}
          onClose={this.closeSendIsOpen}
          onOk={this.okSend}
        />
      </div>
    );
  }
}

export default VarsleSmsMedKart;
