import React from 'react';
import SearchkitFagdata from './SearchkitFagdata.jsx';

class Matrikkelenheter extends React.Component {
componentDidMount(){
  
}
  render() {
    return <SearchkitFagdata/>;
  }
}
export default Matrikkelenheter;
