import * as _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import {
  getEierSuggestion,
  getEier,
  getJuridiskPerson,
  //  getBorett
} from 'api/waapi/eierApi';
import { grunnbokGetBruksenhetids } from 'api/grunnbokApi';
import './Personsok.css';
import PersonAccessor from './PersonAccessor.jsx';
import { SearchkitComponent } from 'searchkit';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';

const getSuggestionValue = (suggestion) => suggestion.Text;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => <div>{suggestion.Text}</div>;

const renderInputComponent = (inputProps) => (
	<div className='inputContainer'>
		<img
			className='autoIcon'
			src='https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-128.png'
			alt=''
		/>
		<input {...inputProps} />
	</div>
);

//Ifm. feil dersom Juridisk person har veldig mange matrikkelId's
let MAXMatrikkelIds = 800;

class PersonSok extends SearchkitComponent<any, any> {
	accessor: PersonAccessor;
	constructor() {
		super();
		this.state = {
			value: '',
			eiere: [],
			suggestions: [],
			tittel: '',
			visJaNeiDialog: false,
      matrikkelListe: [],
      melding1: '',
      melding2: '',
      melding3: ''
		};
		this.onGotEierSuggestion = this.onGotEierSuggestion.bind(this);
		this.onGotEier = this.onGotEier.bind(this);
		this.onGotJuridiskPerson = this.onGotJuridiskPerson.bind(this);
		this.getEiereSuggestion = this.getEiereSuggestion.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSuggestionsFetchRequested =
			this.onSuggestionsFetchRequested.bind(this);
		this.onSuggestionsClearRequested =
			this.onSuggestionsClearRequested.bind(this);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
	}

	defineAccessor() {
		return new PersonAccessor();
	}

	getEiereSuggestion(value) {
		getEierSuggestion('Query=' + value, this.onGotEierSuggestion);
	}

	onGotEierSuggestion(err, eiere) {
		if (!_.isEmpty(eiere)) {
			this.setState({ suggestions: eiere.SearchResults });
		}
	}

	onGotEier(err, eier) {
		if (_.isEmpty(eier)) {
			this.accessor.setMatrikkelnumre(null);
		} else {
			let matrikkelNumre = eier.FysiskPerson.TinglysteEierforhold;
			matrikkelNumre.concat(eier.FysiskPerson.IkkeTinglysteEierforhold);
			this.accessor.setMatrikkelnumre(
				_.map(matrikkelNumre, (matrikkelenhet) => {
					return matrikkelenhet.MatrikkelenhetId;
				})
			);
			this.accessor.setPersonNavn(
				eier.FysiskPerson.ForNavn + ' ' + eier.FysiskPerson.EtterNavn
			);
			this.setState({
				value: eier.FysiskPerson.ForNavn + ' ' + eier.FysiskPerson.EtterNavn,
			});
			if (eier.FysiskPerson.Boretter.length > 0) {
				let boretter = [];
				_.forEach(eier.FysiskPerson.Boretter, (borett) => {
					grunnbokGetBruksenhetids(
						borett.OrganisasjonsNummer,
						borett.Andelsnummer,
						(err, data) => {
							if (err) {
								return;
							}
							this.accessor.setBruksenhetsIdenter(data);
							this.props.onSelected(false);
						}
					);
					/*           getBorett(   ********* gammel
            borett.OrganisasjonsNummer,
            borett.Andelsnummer,
            (err, data) => {         
              if (err) return;
              console.log('MANX 0', data);
              boretter = boretter.concat(this.getBruksenhetsIder(data));
              //Todo: trenger synkroniseringspunkt.
              this.accessor.setBruksenhetsIdenter(boretter);
              this.props.onSelected(false);
            }
          ); */
				});
			} else {
				this.accessor.setBruksenhetsIdenter(null);
			}
		}

		this.props.onSelected(false);
	}

	getBruksenhetsIder(data) {
		let ider = _.map(data.Kandidater, (kandidat) => {
			return kandidat.BruksenhetsId;
		});

		return _.uniq(ider);
	}

	juridiskPerson1 = () => {
		this.setState({ visJaNeiDialog: false });
   // alert('Vent..'); 
    if (this.state.matrikkelListe.length){
      this.accessor.setMatrikkelnumre(
      _.map(this.state.matrikkelListe, (matrikkelenhet) => {
        return matrikkelenhet.MatrikkelenhetId;
      })
    );
    } else {
      console.log('%c JURIDISK PERSON ERROR ', 'font-size: 14px; background: red; color: white',  'ERROR' );  
    }
    
    this.props.onSelected(false);
	};

	juridiskPerson2 = () => {
    this.setState({ visJaNeiDialog: false });
    this.props.onSelected(false);
	};

	onGotJuridiskPerson(err, person) { 
		if (_.isEmpty(person)) {
			this.accessor.setMatrikkelnumre(null);
			this.props.onSelected(false);

			return;
		}

		let matrikkelNumre = person.JuridiskPerson.TinglysteEierforhold;
		matrikkelNumre.concat(person.JuridiskPerson.IkkeTinglysteEierforhold);
    

	 	if (matrikkelNumre.length > MAXMatrikkelIds) {
			let melding1 =
				person.JuridiskPerson.Navn +
				' har ' +
				matrikkelNumre.length +
				' matrikkelIdenter knyttet til seg. ';
        let melding2 =
				'Akkurat nå blir det for mye å håndtere som filter. Vi jobber med saken.';
			let melding3 =
				'Vi kan prøve å hente data for de første ' +
				MAXMatrikkelIds +
				' matrikkelIdenter. Ok?';
        this.setState({melding1});
        this.setState({melding2});
        this.setState({melding3});
        this.setState({ matrikkelListe: matrikkelNumre.slice(0, MAXMatrikkelIds)})
			  this.accessor.setPersonNavn(person.JuridiskPerson.Navn);
        this.setState({ value: person.JuridiskPerson.Navn });
		  	this.setState({ visJaNeiDialog: true });
		
		} else {
      this.accessor.setMatrikkelnumre(
        _.map(matrikkelNumre, (matrikkelenhet) => {
          return matrikkelenhet.MatrikkelenhetId;
        })
      );
      this.accessor.setPersonNavn(person.JuridiskPerson.Navn);
      this.setState({ value: person.JuridiskPerson.Navn });
      this.props.onSelected(false);
    }


	}


	onChange = (event, { newValue }) => {
		this.setState({
			value: newValue,
		});
		// this.getEiere(newValue);
	};

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	onSuggestionsFetchRequested = ({ value }) => {
		suggestions: this.getEiereSuggestion(value);
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
			value: '',
		});
	};

	onSuggestionSelected(
		event,
		{ suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
	) {
		if (suggestion.Type === 'organisasjon') {
			getJuridiskPerson(suggestion.Id, this.onGotJuridiskPerson);
		} else {
			getEier(suggestion.Id, this.onGotEier);
		}
	}

	render() {
		const { value, suggestions } = this.state;
		// Autosuggest will pass through all these props to the input.
		const inputProps = {
			placeholder: this.props.placeholder || 'Eiersøk: Ola Hansen, Oslo',
			value: value,
			onChange: this.onChange,
		};
		return (
			<>
				<Autosuggest
					suggestions={suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					onSuggestionSelected={this.onSuggestionSelected}
					getSuggestionValue={getSuggestionValue}
					renderSuggestion={renderSuggestion}
					inputProps={inputProps}
					renderInputComponent={renderInputComponent}
				/>
				<JaNeiDialog
					isOpen={this.state.visJaNeiDialog}
					tittel={'For mange matrikkelidenter for filter.'}
					melding={this.state.melding1}
          melding2={this.state.melding2}
          melding3={this.state.melding3}
					onJa={() => {
						this.juridiskPerson1();
					}}
					onNei={() => {
						this.juridiskPerson2();
					}}
				/>
			</>
		);
	}
}
export default PersonSok;
