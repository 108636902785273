import React from 'react';
import { getOrganisasjonData } from 'context/contextInnstillinger.js';
import {
  getBruksenhetMatrise,
  getBruksenhetMatriseFritid,
  getRoyklopmatrise,
  getRoyklopmatriseFritid,
} from 'api/risikoApi.js';
import * as _ from 'lodash';

export default class extends React.Component {
  state = {
    org: null,
    matriseBruksenhet: null,
    matriseBruksenhetFritid: null,
    matriseRoyk: null,
    matriseRoykFritid: null,
    matriseBruksenhetHentet: false,
    matriseBruksenhetFritidHentet: false,
    matriseRoykHentet: false,
    matriseRoykFritidHentet: false,
  };

  componentDidMount() {
    getOrganisasjonData(this.gotOrg);
  }

  gotOrg = (org) => {
    this.setState({ org: org.data });
    this.setState({ bruksenhetFritid: org.risikovurderingBruksenhetFritid });
    //console.log(org.data);
  };

  hentMatriseBruksenhet = () => {
    getBruksenhetMatrise(this.gotBruksenhetMatrise);
  };

  hentMatriseBruksenhetFritid = () => {
    getBruksenhetMatriseFritid(this.gotBruksenhetFritidMatrise);
  };

  hentMatriseRoyk = () => {
    getRoyklopmatrise(this.gotRoykMatrise);
  };

  hentMatriseRoykFritid = () => {
    getRoyklopmatriseFritid(this.gotRoykMatriseFritid);
  };

  gotRoykMatriseFritid = (err, res) => {
    console.log(res);
    this.setState({
      matriseRoykFritid: this.formaterMatrise(res),
      matriseRoykFritidHentet: true,
    });
  };

  gotRoykMatrise = (err, res) => {
    console.log(res);
    this.setState({
      matriseRoyk: this.formaterMatrise(res),
      matriseRoykHentet: true,
    });
  };
  gotBruksenhetMatrise = (err, res) => {
    console.log(res);
    this.setState({
      matriseBruksenhet: this.formaterMatrise(res),
      matriseBruksenhetHentet: true,
    });
  };

  gotBruksenhetFritidMatrise = (err, res) => {
    console.log(res);
    console.log(err);
    this.setState({
      matriseBruksenhetFritid: this.formaterMatrise(res),
      matriseBruksenhetFritidHentet: true,
    });
  };

  formaterMatrise = (res) => {
    console.log(res.data);
    let result = null;
    if (res.data == undefined || res.data == null) {
      result = <div> Ingen data å vise</div>;
    } else {
      result = (
        <div>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <tr
                style={{
                  border: '1px solid',
                  borderColor: 'gray',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <th style={{ width: '150px', textAlign: 'rigth' }}>Kommune</th>
                <th style={{ width: '150px', textAlign: 'rigth' }}>
                  ROS Objekt
                </th>
                <th style={{ width: '150px', textAlign: 'rigth' }}>
                  Antall faktorer i bruk
                </th>
                <th style={{ width: '170px', textAlign: 'rigth' }}>
                  Totalt antall faktrorer i fil
                </th>
              </tr>
              <tr
                style={{
                  border: '1px solid',
                  borderColor: 'gray',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <td style={{ width: '150px', textAlign: 'rigth' }}>
                  {res.data.Kommune}
                </td>
                <td style={{ width: '150px', textAlign: 'rigth' }}>
                  {res.data.ROSObjekt}
                </td>
                <td style={{ width: '150px', textAlign: 'rigth' }}>
                  {res.data.AntallFaktorerIBruk}
                </td>
                <td style={{ width: '170px', textAlign: 'rigth' }}>
                  {res.data.TotaltAntallFaktorerIFil}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Hyppighet</div>
          <br />
          <table style={{ fontSize: '12px' }}>
            <tbody>
              <tr
                style={{
                  border: '1px solid',
                  borderColor: 'gray',
                  backgroundColor: '#F8F8F8',
                }}
              >
                <th style={{ width: '100px', textAlign: 'rigth' }}>
                  Hyppighet
                </th>
                <th style={{ width: '100px', textAlign: 'rigth' }}>
                  Risikotall
                </th>
                <th style={{ width: '100px', textAlign: 'rigth' }}>
                  HyppId oppslagg
                </th>
              </tr>
              {_.map(res.data.Hyppighet, function(hyp, hx) {
                return (
                  <tr
                    key={'tr' + hx}
                    style={{
                      border: '1px solid',
                      borderColor: 'gray',
                      backgroundColor: '#F8F8F8',
                    }}
                  >
                    <td style={{ width: '200px', textAlign: 'rigth' }}>
                      {hyp.Hyppighet}
                    </td>
                    <td style={{ width: '100px', textAlign: 'rigth' }}>
                      {hyp.Risikotall}
                    </td>
                    <td style={{ width: '100px', textAlign: 'rigth' }}>
                      {hyp.HyppId_oppslag}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <br />
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Parametere</div>
          <br />
          {_.map(res.data.Parametere, function(param, ix) {
            return (
              <div key={'div' + ix}>
                <table style={{ fontSize: '12px' }}>
                  <tbody>
                    <tr
                      key={'tr' + ix}
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}
                    >
                      <th style={{ width: '200px', textAlign: 'rigth' }}>
                        Faktor navn
                      </th>
                      <th style={{ width: '100px', textAlign: 'rigth' }}>
                        Obligatorisk
                      </th>
                      <th style={{ width: '100px', textAlign: 'rigth' }}>
                        Vekt
                      </th>
                      <th style={{ width: '150px', textAlign: 'rigth' }}>
                        Bruk konsekvens
                      </th>
                      <th style={{ width: '150px', textAlign: 'rigth' }}>
                        Bruk sannsynlighet
                      </th>
                    </tr>
                    <tr
                      key={'tr1' + ix}
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}
                    >
                      <td style={{ width: '200px', textAlign: 'rigth' }}>
                        {param.FaktorNavn}
                      </td>
                      <td style={{ width: '100px', textAlign: 'rigth' }}>
                        {param.Obligatorisk}
                      </td>
                      <td style={{ width: '100px', textAlign: 'rigth' }}>
                        {param.Vekt}
                      </td>
                      <td style={{ width: '100px', textAlign: 'rigth' }}>
                        {param.BrukKonsekvens}
                      </td>
                      <td style={{ width: '150px', textAlign: 'rigth' }}>
                        {param.BrukSannsynlighet}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <div>
                  Faktor navn: {param.FaktorNavn},
                  <br/>
                  Obligatorisk: {param.Obligatorisk}
                  <br/>
                  Vekt: {param.Vekt}
                </div> */}
                <br />
                <table style={{ fontSize: '12px' }}>
                  <tbody>
                    <tr
                      key={ix}
                      style={{
                        border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8',
                      }}
                    >
                      <th style={{ width: '100px', textAlign: 'rigth' }}>
                        Kode
                      </th>
                      <th style={{ width: '300px', textAlign: 'rigth' }}>
                        Navn
                      </th>
                      <th style={{ width: '150px', textAlign: 'rigth' }}>
                        Sannsynlighets verdi
                      </th>
                      <th style={{ width: '150px', textAlign: 'rigth' }}>
                        Konsekvens verdi
                      </th>
                      <th style={{ width: '150px', textAlign: 'rigth' }}>
                        Minste risiko
                      </th>
                    </tr>
                    {_.map(param.Data, function(item, inx) {
                      return (
                        <tr
                          key={'tr' + inx}
                          style={{
                            border: '1px solid',
                            borderColor: 'gray',
                            backgroundColor: '#F8F8F8',
                          }}
                        >
                          <td style={{ width: '100px', textAlign: 'rigth' }}>
                            {item.Kode}
                          </td>
                          <td style={{ width: '300px', textAlign: 'rigth' }}>
                            {item.Navn}
                          </td>
                          <td style={{ width: '150px', textAlign: 'rigth' }}>
                            {item.SannsynlighetsVerdi}
                          </td>
                          <td style={{ width: '150px', textAlign: 'rigth' }}>
                            {item.KonsekvensVerdi}
                          </td>
                          <td style={{ width: '150px', textAlign: 'rigth' }}>
                            {item.MinsteRisiko}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <hr />
              </div>
            );
          })}
        </div>
      );
    }

    return result;
  };

  render() {
    let accStyle = {
      backgroundColor: '#ffffff',
      border: '1px solid rgb(153, 153, 153)',
      borderRadius: '0',
      marginBottom: '15px',
    };
    let chevronStyle = {
      float: 'right',
      margin: '4px 4px 0 0',
    };
    let panelStyle = {
      marginLeft: '20px',
      marginRight: '20px',
      height: 'auto',
    };
    let resultStyle = {
      marginLeft: '20px',
      marginBottom: '20px',
    };
    if (this.state.org == null) {
      return <div className="loader" />;
    }

    return (
      <div>
        <h2>Innstillinger/oppsett risikovurdering</h2>
        <br />
        <div className="row">
          <div className="col-sm-6">
            <div className="row">
              <div className="col-sm-8" style={{ paddingTop: '8px' }}>
                <label>Risikovurdering på bruksenhet fritidseiendom:</label>
              </div>
              <div
                className="col-sm-4 form-check "
                style={{ paddingTop: '8px' }}
              >
                {this.state.org.risikovurderingBruksenhetFritid ? (
                  <div>
                    <label>Ja</label>
                  </div>
                ) : (
                  <div>
                    <label>Nei</label>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8" style={{ paddingTop: '8px' }}>
                <label>Risikovurdering på røykløp fritidseiendom:</label>
              </div>
              <div
                className="col-sm-4 form-check "
                style={{ paddingTop: '8px' }}
              >
                {this.state.org.risikovurderingRoyklopFritid ? (
                  <div>
                    <label>Ja</label>
                  </div>
                ) : (
                  <div>
                    <label>Nei</label>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8" style={{ paddingTop: '8px' }}>
                <label>
                  Risikovurdering på bruksenheter uten fyringsanlegg:
                </label>
              </div>
              <div
                className="col-sm-4 form-check "
                style={{ paddingTop: '8px' }}
              >
                {this.state.org.risikovurderingUtenFyringsanlegg ? (
                  <div>
                    <label>Ja</label>
                  </div>
                ) : (
                  <div>
                    <label>Nei</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            {!this.state.org.risikovurderingBruksenhetFritid && !this.state.org.risikovurderingRoyklopFritid ? 
            <div>
              <label>Fritidseiendommer (boligtyper) som utelates:</label>
              <br/>
              
              <table>
                <body>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <th style={{ width: '300px', textAlign: 'center' }}>Bygning kode</th>
                    <th style={{ width: '150px', textAlign: 'center' }}>Kode verdi</th>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Fritidsbygg(hyttersommerh. ol)</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>161</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Helårsb.benyttes som fritidsbolig</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>162</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Våningh. benyttes som fritidsbolig</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>163</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Seterhus sel rorbu o.l.</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>171</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Skogs- og utmarkskoie gamme</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>172</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Garasjeuth.anneks til fritidbolig</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>182</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Naust båthus sjøbu</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>183</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Appartement</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>523</td>
                  </tr>
                  <tr style={{ border: '1px solid',
                        borderColor: 'gray',
                        backgroundColor: '#F8F8F8', fontSize: '14px' }}>
                    <td style={{ width: '300px', textAlign: 'center' }}>Camping/utleiehytte</td>
                    <td style={{ width: '150px', textAlign: 'center' }}>524</td>
                  </tr>
                </body>
              </table>
            </div>
            : null}
          </div>
        </div>

        <hr />
        <h4>Risiko faktorer</h4>
        <div className="row">
          <div className="panel-group" id="accordion1" style={{ margin: '0' }}>
            <div className="panel panel-default">
              <div
                className="panel-heading collapsed sbpilpanel"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#1"
                style={accStyle}
              >
                <h4
                  className="panel-title"
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}
                >
                  <span style={{ marginRight: '10px' }}>
                    Risikofaktorer Bruksenhet
                  </span>
                </h4>
                <span style={chevronStyle} className="chevron fa fa-fw" />
              </div>
              <div id="1" className="panel-collapse collapse">
                <div className="row">
                  <div style={panelStyle}>
                    {!this.state.matriseBruksenhetHentet ? (
                      <div>
                        <button
                          className="btn btn-default"
                          style={{
                            background: '#51A026',
                            color: 'white',
                            height: '32px',
                          }}
                          onClick={this.hentMatriseBruksenhet}
                          type="button"
                        >
                          Hent data
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}

                    <br />
                    {this.state.matriseBruksenhet}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />

          <div className="panel-group" id="accordion2" style={{ margin: '0' }}>
            <div className="panel panel-default">
              <div
                className="panel-heading collapsed sbpilpanel gebyr"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#2"
                style={accStyle}
              >
                <h4
                  className="panel-title"
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}
                >
                  <span style={{ marginRight: '10px' }}>
                    Risikofaktorer Bruksenhet fritidseiendom
                  </span>
                </h4>
                <span style={chevronStyle} className="chevron fa fa-fw" />
              </div>

              <div id="2" className="panel-collapse collapse react-table">
                <div className="row">
                  <div style={panelStyle}>
                    {!this.state.matriseBruksenhetFritidHentet ? (
                      <div>
                        <button
                          className="btn btn-default"
                          style={{
                            background: '#51A026',
                            color: 'white',
                            height: '32px',
                          }}
                          onClick={this.hentMatriseBruksenhetFritid}
                          type="button"
                        >
                          Hent data
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}

                    <br />
                    {this.state.matriseBruksenhetFritid}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />
          <div className="panel-group" id="accordion3" style={{ margin: '0' }}>
            <div className="panel panel-default">
              <div
                className="panel-heading collapsed sbpilpanel gebyr"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#3"
                style={accStyle}
              >
                <h4
                  className="panel-title"
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}
                >
                  <span style={{ marginRight: '10px' }}>
                    Risikofaktorer Røykløp
                  </span>
                </h4>
                <span style={chevronStyle} className="chevron fa fa-fw" />
              </div>

              <div id="3" className="panel-collapse collapse react-table">
                <div className="row">
                  <div style={panelStyle}>
                    {!this.state.matriseRoykHentet ? (
                      <div>
                        <button
                          className="btn btn-default"
                          style={{
                            background: '#51A026',
                            color: 'white',
                            height: '32px',
                          }}
                          onClick={this.hentMatriseRoyk}
                          type="button"
                        >
                          Hent data
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}

                    <br />
                    {this.state.matriseRoyk}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p />
          <p />
          <div className="panel-group" id="accordion4" style={{ margin: '0' }}>
            <div className="panel panel-default">
              <div
                className="panel-heading collapsed sbpilpanel gebyr"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#4"
                style={accStyle}
              >
                <h4
                  className="panel-title"
                  style={{
                    display: 'inline-block',
                    width: 'calc(100% - 40px)',
                  }}
                >
                  <span style={{ marginRight: '10px' }}>
                    Risikofaktorer Røykløp fritidseiendom
                  </span>
                </h4>
                <span style={chevronStyle} className="chevron fa fa-fw" />
              </div>

              <div id="4" className="panel-collapse collapse react-table">
                <div className="row">
                  <div style={panelStyle}>
                    {!this.state.matriseRoykFritidHentet ? (
                      <div>
                        <button
                          className="btn btn-default"
                          style={{
                            background: '#51A026',
                            color: 'white',
                            height: '32px',
                          }}
                          onClick={this.hentMatriseRoykFritid}
                          type="button"
                        >
                          Hent data
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}

                    <br />
                    {this.state.matriseRoykFritid}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
