import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Header, Section } from '../../components/generics';
import ObjektAdresse from '../../components/ObjektAdresse';
// API
import { postBruksenhetAdresser2 } from 'api/enhetApi';
import { getBruksenheteIderForDeltRoyklop } from 'api/royklopApi';
// Utils
import { makeLockReasonsTooltip } from '../../lib';
import { debugFactory, getDerivedChanges, promisifyApiCall } from 'utils';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:components:itemroyklop'
);



class ItemRoyklop extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
  };

  state = {
    isFetching: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedChanges(['item'], nextProps, prevState, debug);
  }

  ensureBruksenhetIdDelerLop = () => {
    const item = this.state.item;
    if (this.state.isFetching || item.bruksenheterSomDelerRoyklop) {
      return;
    }

    if (_.isUndefined(item.bruksenhetIdDelerLop)) {
      this.setState({ isFetching: true });
      
      // Item is from ES index, use `royklopId` to fetch `bruksenhetIdDelerLop`
      promisifyApiCall(getBruksenheteIderForDeltRoyklop)(
        item.royklopId,
        item.bruksenhetId
      ).then((idsString) => {
        item.bruksenhetIdDelerLop = idsString.tekst;
        this.setState({ item, isFetching: false });
      });
    }
    if (
      item.bruksenhetIdDelerLop &&
      _.isUndefined(item.bruksenheterSomDelerRoyklop)
      
    ) {

      let idListe= [];
   
      if(_.isString(item.bruksenhetIdDelerLop)){
        idListe = item.bruksenhetIdDelerLop
        .split(',')
        .map((id) => parseInt(id))
        .filter((id) => id);
      };

      if (!_.isEmpty(idListe)) {
        
        this.setState({ isFetching: true });

        promisifyApiCall(postBruksenhetAdresser2)(
          [item.bruksenhetId].concat(idListe)
        ).then((bruksenhetListe) => {
          // TODO! Stack it on all items wich are included in `bruksenhetListe`
          item.bruksenheterSomDelerRoyklop = bruksenhetListe;
          this.setState({ item, isFetching: false });
        });
      }
    }
  };

  render = () => {
    debug('render', { ...this });

    this.ensureBruksenhetIdDelerLop();
   
    const item = this.state.item;
    let r = _.get(item, 'royklopId');
    if (!r){
      r = '';
    }
    let p = _.get(item, 'plassering');
    if (!p){
      p = '';
    }
    let t = _.get(item, 'type');
    if (!t){
      t = '';
    }
   let  extraInfo =  r + ' ' +  p + ' '  + t;
    const lockReasons = this.props.getObjektLockReasons(item);
    const tooltip = lockReasons ? makeLockReasonsTooltip(lockReasons) : 'Fjern';

    const Title = this.state.isFetching ? (
      <span>Laster...</span>
    ) : (
      <ObjektAdresse className='cf pl2' item={item} />
    );

    return (
      <Section>
        <Header
          title={Title}
          extraInfo={extraInfo}
          onIconClick={lockReasons ? null : () => this.props.onRemoveItem(item)}
          iconTooltip={tooltip}
          onClick={() => this.props.onClick(item)}
        />
      </Section>
    );
  };
}

export default ItemRoyklop;

 
 

 

