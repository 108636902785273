import callApi from './api';

export function etablerfagdata(fagdata, callback) {
    callApi('POST', '/grunndata/etablerfagdata', fagdata, callback);
};

export function utelatBruksenhet(id, callback) {
    callApi('POST', '/grunndata/bruksenhetutelat/' + id, null, callback);
};

export function angreUtelatBruksenhet(id, callback) {
    callApi('DELETE', '/grunndata/bruksenhetutelat/' + id, null, callback);
};

export function getVurderte(callback){
    callApi('GET', '/grunndata/bruksenhetutelat', null, callback);
  }