import callApi from './api';

// A N M E R K N I N G 

var getAnmerkningBeskrivelse = function(callback) {
    callApi('GET', '/AnmerkningAdmBeskrivelse/', null, callback);
};
var saveAnmerkningBeskrivelse = function(beskrivelse, callback) {
    callApi('POST', '/AnmerkningAdmBeskrivelse/', beskrivelse, callback);
};
var updateAnmerkningBeskrivelse = function(id, beskrivelse, callback) {
    callApi('PUT', '/AnmerkningAdmBeskrivelse/' + id, beskrivelse, callback);
};
var slettAnmerkningBeskrivelse = function(id, callback) {
    callApi('DELETE', '/AnmerkningAdmBeskrivelse/' + id, null, callback);
};

// A V V I K 
var getAvvikAnmerkningKategori = function(callback) {
    callApi('GET', '/AvvikAnmerkningAdmKategori/', null, callback);
};
var saveAvvikAnmerkningKategori = function(kategori, callback) {
    callApi('POST', '/AvvikAnmerkningAdmKategori/', kategori, callback);
};
var updateAvvikAnmerkningKategori = function(id, kategori, callback) {
    callApi('PUT', '/AvvikAnmerkningAdmKategori/' + id, kategori, callback);
};
var slettAvvikAnmerkningKategori = function(id, callback) {
    callApi('DELETE', '/AvvikAnmerkningAdmKategori/' + id, null, callback);
};
var getAvvikBeskrivelse = function(callback) {
    callApi('GET', '/AvvikAdmBeskrivelse/', null, callback);
};
var saveAvvikBeskrivelse = function(beskrivelse, callback) {
    callApi('POST', '/AvvikAdmBeskrivelse/', beskrivelse, callback);
};
var updateAvvikBeskrivelse = function(id, beskrivelse, callback) {
    callApi('PUT', '/AvvikAdmBeskrivelse/' + id, beskrivelse, callback);
};
var slettAvvikBeskrivelse = function(id, callback) {
    callApi('DELETE', '/AvvikAdmBeskrivelse/' + id, null, callback);
};
var getAvvikStatus = function(callback) {
    callApi('GET', '/AvvikAdmStatus/', null, callback);
};
var saveAvvikStatus = function(status, callback) {
    callApi('POST', '/AvvikAdmStatus/', status, callback);
};
var updateAvvikStatus = function(id, status, callback) {
    callApi('PUT', '/AvvikAdmStatus/' + id, status, callback);
};
var slettAvvikStatus = function(id, callback) {
    callApi('DELETE', '/AvvikAdmStatus/' + id, null, callback);
};
var getAvvikLovdata = function(callback) {
    callApi('GET', '/AvvikAdmLovdata/', null, callback);
};
var saveAvvikLovdata = function(lovdata, callback) {
    callApi('POST', '/AvvikAdmLovdata/', lovdata, callback);
};
var updateAvvikLovdata = function(id, lovdata, callback) {
    callApi('PUT', '/AvvikAdmLovdata/' + id, lovdata, callback);
};
var slettAvvikLovdata = function(id, callback) {
    callApi('DELETE', '/AvvikAdmLovdata/' + id, null, callback);
};
var getAvvikParagraf = function(callback) {
    callApi('GET', '/AvvikAdmParagraf/', null, callback);
};
var saveAvvikParagraf = function(paragraf, callback) {
    callApi('POST', '/AvvikAdmParagraf/', paragraf, callback);
};
var updateAvvikParagraf = function(id, paragraf, callback) {
    callApi('PUT', '/AvvikAdmParagraf/' + id, paragraf, callback);
};
var slettAvvikParagraf = function(id, callback) {
    callApi('DELETE', '/AvvikAdmParagraf/' + id, null, callback);
};
var getAvvikType = function(callback) {
    callApi('GET', '/AvvikAdmType/', null, callback);
};
var saveAvvikType = function(type, callback) {
    callApi('POST', '/AvvikAdmType/', type, callback);
};
var updateAvvikType = function(id, type, callback) {
    callApi('PUT', '/AvvikAdmType/' + id, type, callback);
};
var slettAvvikType = function(id, callback) {
    callApi('DELETE', '/AvvikAdmType/' + id, null, callback);
};
var getAvvikKategori= function(callback) {
    callApi('GET', '/AvvikAdmKategori/', null, callback);
};
var getAvvikAdmLukkArsak = function(callback) {
    callApi('GET', '/avvikadmlukkarsak/', null, callback);
};
var saveAvvikAdmLukkArsak = function(arsak, callback) {
    callApi('POST', '/avvikadmlukkarsak/', arsak, callback);
};
var updateAvvikAdmLukkArsak = function(id, arsak, callback) {
    callApi('PUT', '/avvikadmlukkarsak/' + id, arsak, callback);
};
var slettAvvikAdmLukkArsak = function(id, callback) {
    callApi('DELETE', '/avvikadmlukkarsak/' + id, null, callback);
};


// I L D S T E D
var getIldstedModell = function(callback) {
    callApi('GET', '/IldstedAdmModell/', null, callback);
};
var saveIldstedModell = function(modell, callback) {
    callApi('POST', '/IldstedAdmModell/', modell, callback);
};
var updateIldstedModell = function(id, modell, callback) {
    callApi('PUT', '/IldstedAdmModell/' + id, modell, callback);
};
var slettIldstedModell = function(id, callback) {
    callApi('DELETE', '/IldstedAdmModell/' + id, null, callback);
};
//--
var getIldstedPlassering = function(callback) {
    callApi('GET', '/IldstedAdmPlassering/', null, callback);
};
var saveIldstedPlassering = function(ildstedPlassering, callback) {
    callApi('POST', '/IldstedAdmPlassering/', ildstedPlassering, callback);
};
var updateIldstedPlassering = function(id, ildstedPlassering, callback) {
    callApi('PUT', '/IldstedAdmPlassering/' + id, ildstedPlassering, callback);
};
var slettIldstedPlassering = function(id, callback) {
    callApi('DELETE', '/IldstedAdmPlassering/' + id, null, callback);
};
//--
var getIldstedType = function(callback) {
    callApi('GET', '/IldstedAdmType/', null, callback);
};
var saveIldstedType = function(type, callback) {
    callApi('POST', '/IldstedAdmType/', type, callback);
};
var updateIldstedType = function(id, type, callback) {
    callApi('PUT', '/IldstedAdmType/' + id, type, callback);
};
var slettIldstedType = function(id, callback) {
    callApi('DELETE', '/IldstedAdmType/' + id, null, callback);
};
//--
var getIldstedProdusent = function(callback) {
    callApi('GET', '/IldstedAdmProdusent/', null, callback);
};
var saveIldstedProdusent = function(produsent, callback) {
    callApi('POST', '/IldstedAdmProdusent/', produsent, callback);
};
var updateIldstedProdusent = function(id, produsent, callback) {
    callApi('PUT', '/IldstedAdmProdusent/' + id, produsent, callback);
};
var slettIldstedProdusent = function(id, callback) {
    callApi('DELETE', '/IldstedAdmProdusent/' + id, null, callback);
};


// R Ø Y K L Ø P
var getRoyklopAdkomst = function(callback) {
    callApi('GET', '/RoyklopAdmAdkomst/', null, callback);
};
var saveRoyklopAdkomst = function(adkomst, callback) {
    callApi('POST', '/RoyklopAdmAdkomst/', adkomst, callback);
};
var updateRoyklopAdkomst = function(id, adkomst, callback) {
    callApi('PUT', '/RoyklopAdmAdkomst/' + id, adkomst, callback);
};
var slettRoyklopAdkomst = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmAdkomst/' + id, null, callback);
};
//--
var getRoyklopDimensjon = function(callback) {
    callApi('GET', '/RoyklopAdmDimensjon/', null, callback);
};
var saveRoyklopDimensjon = function(dimensjon, callback) {
    callApi('POST', '/RoyklopAdmDimensjon/', dimensjon, callback);
};
var updateRoyklopDimensjon = function(id, dimensjon, callback) {
    callApi('PUT', '/RoyklopAdmDimensjon/' + id, dimensjon, callback);
};
var slettRoyklopDimensjon = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmDimensjon/' + id, null, callback);
};
//--
var getRoyklopModell = function(callback) {
    callApi('GET', '/RoyklopAdmModell/', null, callback);
};
var saveRoyklopModell = function(modell, callback) {
    callApi('POST', '/RoyklopAdmModell/', modell, callback);
};
var updateRoyklopModell = function(id, modell, callback) {
    callApi('PUT', '/RoyklopAdmModell/' + id, modell, callback);
};
var slettRoyklopModell = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmModell/' + id, null, callback);
};
//--
var getRoyklopPlassering = function(callback) {
    callApi('GET', '/RoyklopAdmPlassering/', null, callback);
};
var saveRoyklopPlassering = function(plassering, callback) {
    callApi('POST', '/RoyklopAdmPlassering/', plassering, callback);
};
var updateRoyklopPlassering = function(id, plassering, callback) {
    callApi('PUT', '/RoyklopAdmPlassering/' + id, plassering, callback);
};
var slettRoyklopPlassering = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmPlassering/' + id, null, callback);
};
//--
var getRoyklopProdusent = function(callback) {
    callApi('GET', '/RoyklopAdmProdusent/', null, callback);
};
var saveRoyklopProdusent = function(produsent, callback) {
    callApi('POST', '/RoyklopAdmProdusent/', produsent, callback);
};
var updateRoyklopProdusent = function(id, produsent, callback) {
    callApi('PUT', '/RoyklopAdmProdusent/' + id, produsent, callback);
};
var slettRoyklopProdusent = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmProdusent/' + id, null, callback);
};
//--
var getRoyklopSotmengde = function(callback) {
    callApi('GET', '/RoyklopAdmSotmengde/', null, callback);
};
var saveRoyklopSotmengde = function(sotmengde, callback) {
    callApi('POST', '/RoyklopAdmSotmengde/', sotmengde, callback);
};
var updateRoyklopSotmengde = function(id, sotmengde, callback) {
    callApi('PUT', '/RoyklopAdmSotmengde/' + id, sotmengde, callback);
};
var slettRoyklopSotmengde = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmSotmengde/' + id, null, callback);
};
//--
var getRoyklopSotuttak = function(callback) {
    callApi('GET', '/RoyklopAdmSotuttak/', null, callback);
};
var saveRoyklopSotuttak = function(sotuttak, callback) {
    callApi('POST', '/RoyklopAdmSotuttak/', sotuttak, callback);
};
var updateRoyklopSotuttak = function(id, sotuttak, callback) {
    callApi('PUT', '/RoyklopAdmSotuttak/' + id, sotuttak, callback);
};
var slettRoyklopSotuttak = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmSotuttak/' + id, null, callback);
};
//--
var getRoyklopType = function(callback) {
    callApi('GET', '/RoyklopAdmType/', null, callback);
};
var saveRoyklopType = function(type, callback) {
    callApi('POST', '/RoyklopAdmType/', type, callback);
};
var updateRoyklopType = function(id, type, callback) {
    callApi('PUT', '/RoyklopAdmType/' + id, type, callback);
};
var slettRoyklopType = function(id, callback) {
    callApi('DELETE', '/RoyklopAdmType/' + id, null, callback);
};


//  T I L T A K

var getTiltakAarsakIkkeUtfort = function(callback) {
    callApi('GET', '/TiltakAdmArsakIkkeUtfort/', null, callback);
};
var saveTiltakAarsakIkkeUtfort = function(aarsak, callback) {
    callApi('POST', '/TiltakAdmArsakIkkeUtfort/', aarsak, callback);
};
var updateTiltakAarsakIkkeUtfort = function(id, aarsak, callback) {
    callApi('PUT', '/TiltakAdmArsakIkkeUtfort/' + id, aarsak, callback);
};
var slettTiltakAarsakIkkeUtfort = function(id, callback) {
    callApi('DELETE', '/TiltakAdmArsakIkkeUtfort/' + id, null, callback);
};
//--
var getTiltakHyppighet = function(callback) {
    callApi('GET', '/TiltakAdmHyppighet/', null, callback);
};
var saveTiltakHyppighet = function(hyppighet, callback) {
    callApi('POST', '/TiltakAdmHyppighet/', hyppighet, callback);
};
var updateTiltakHyppighet = function(id, hyppighet, callback) {
    callApi('PUT', '/TiltakAdmHyppighet/' + id, hyppighet, callback);
};
var slettTiltakHyppighet = function(id, callback) {
    callApi('DELETE', '/TiltakAdmHyppighet/' + id, null, callback);
};
//--
var getTiltakType = function(callback) {
    callApi('GET', '/TiltakAdmType/', null, callback);
};
var saveTiltakType = function(type, callback) {
    callApi('POST', '/TiltakAdmType/', type, callback);
};
var updateTiltakType = function(id, type, callback) {
    callApi('PUT', '/TiltakAdmType/' + id, type, callback);
};
var slettTiltakType = function(id, callback) {
    callApi('DELETE', '/TiltakAdmType/' + id, null, callback);
};
var getOrganisasjonTiltakRisikovurdering = function(orgId, callback) {
    callApi('GET', '/tiltakadmtype/organisasjon/' + orgId, null, callback);  
};


export {
    getAnmerkningBeskrivelse,
    saveAnmerkningBeskrivelse,
    updateAnmerkningBeskrivelse,
    slettAnmerkningBeskrivelse,

    getAvvikAnmerkningKategori,
    saveAvvikAnmerkningKategori,
    updateAvvikAnmerkningKategori,
    slettAvvikAnmerkningKategori,

    getAvvikLovdata,
    saveAvvikLovdata,
    updateAvvikLovdata,
    slettAvvikLovdata,

    getAvvikParagraf,
    saveAvvikParagraf,
    updateAvvikParagraf,
    slettAvvikParagraf,

    getAvvikStatus,
    saveAvvikStatus,
    updateAvvikStatus,
    slettAvvikStatus,

    getAvvikType,
    saveAvvikType,
    updateAvvikType,
    slettAvvikType,

    getAvvikKategori,

    getAvvikBeskrivelse,
    saveAvvikBeskrivelse,
    updateAvvikBeskrivelse,
    slettAvvikBeskrivelse,
    
    getIldstedModell,
    saveIldstedModell,
    updateIldstedModell,
    slettIldstedModell,

    getIldstedPlassering,
    saveIldstedPlassering,
    updateIldstedPlassering,
    slettIldstedPlassering,

    getIldstedType,
    saveIldstedType,
    updateIldstedType,
    slettIldstedType,

    getIldstedProdusent,
    saveIldstedProdusent,
    updateIldstedProdusent,
    slettIldstedProdusent,

    getRoyklopAdkomst,
    saveRoyklopAdkomst,
    updateRoyklopAdkomst,
    slettRoyklopAdkomst,

    getRoyklopDimensjon,
    saveRoyklopDimensjon,
    updateRoyklopDimensjon,
    slettRoyklopDimensjon,

    getRoyklopModell,
    saveRoyklopModell,
    updateRoyklopModell,
    slettRoyklopModell,

    getRoyklopPlassering,
    saveRoyklopPlassering,
    updateRoyklopPlassering,
    slettRoyklopPlassering,

    getRoyklopProdusent,
    saveRoyklopProdusent,
    updateRoyklopProdusent,
    slettRoyklopProdusent,

    getRoyklopSotmengde,
    saveRoyklopSotmengde,
    updateRoyklopSotmengde,
    slettRoyklopSotmengde,

    getRoyklopSotuttak,
    saveRoyklopSotuttak,
    updateRoyklopSotuttak,
    slettRoyklopSotuttak,

    getRoyklopType,
    saveRoyklopType,
    updateRoyklopType,
    slettRoyklopType,

    getTiltakAarsakIkkeUtfort,
    saveTiltakAarsakIkkeUtfort,
    updateTiltakAarsakIkkeUtfort,
    slettTiltakAarsakIkkeUtfort,

    getTiltakHyppighet,
    saveTiltakHyppighet,
    updateTiltakHyppighet,
    slettTiltakHyppighet,

    getTiltakType,
    saveTiltakType,
    updateTiltakType,
    slettTiltakType,
    getOrganisasjonTiltakRisikovurdering,

    getAvvikAdmLukkArsak,
    saveAvvikAdmLukkArsak,
    updateAvvikAdmLukkArsak,
    slettAvvikAdmLukkArsak
  };
  
  export default {
    getAnmerkningBeskrivelse,
    saveAnmerkningBeskrivelse,
    updateAnmerkningBeskrivelse,
    slettAnmerkningBeskrivelse,

    getAvvikAnmerkningKategori,
    saveAvvikAnmerkningKategori,
    updateAvvikAnmerkningKategori,
    slettAvvikAnmerkningKategori,

    getAvvikBeskrivelse,
    saveAvvikBeskrivelse,
    updateAvvikBeskrivelse,
    slettAvvikBeskrivelse,

    getAvvikLovdata,
    saveAvvikLovdata,
    updateAvvikLovdata,
    slettAvvikLovdata,

    getAvvikParagraf,
    saveAvvikParagraf,
    updateAvvikParagraf,
    slettAvvikParagraf,

    getAvvikStatus,
    saveAvvikStatus,
    updateAvvikStatus,
    slettAvvikStatus,

    getAvvikType,
    saveAvvikType,
    updateAvvikType,
    slettAvvikType,

    getAvvikKategori,

    getIldstedModell,
    saveIldstedModell,
    updateIldstedModell,
    slettIldstedModell,

    getIldstedPlassering,
    saveIldstedPlassering,
    updateIldstedPlassering,
    slettIldstedPlassering,

    getIldstedType,
    saveIldstedType,
    updateIldstedType,
    slettIldstedType,

    getIldstedProdusent,
    saveIldstedProdusent,
    updateIldstedProdusent,
    slettIldstedProdusent,

    getRoyklopAdkomst,
    saveRoyklopAdkomst,
    updateRoyklopAdkomst,
    slettRoyklopAdkomst,

    getRoyklopDimensjon,
    saveRoyklopDimensjon,
    updateRoyklopDimensjon,
    slettRoyklopDimensjon,

    getRoyklopModell,
    saveRoyklopModell,
    updateRoyklopModell,
    slettRoyklopModell,

    getRoyklopPlassering,
    saveRoyklopPlassering,
    updateRoyklopPlassering,
    slettRoyklopPlassering,

    getRoyklopProdusent,
    saveRoyklopProdusent,
    updateRoyklopProdusent,
    slettRoyklopProdusent,

    getRoyklopSotmengde,
    saveRoyklopSotmengde,
    updateRoyklopSotmengde,
    slettRoyklopSotmengde,

    getRoyklopSotuttak,
    saveRoyklopSotuttak,
    updateRoyklopSotuttak,
    slettRoyklopSotuttak,

    getRoyklopType,
    saveRoyklopType,
    updateRoyklopType,
    slettRoyklopType,

    getTiltakAarsakIkkeUtfort,
    saveTiltakAarsakIkkeUtfort,
    updateTiltakAarsakIkkeUtfort,
    slettTiltakAarsakIkkeUtfort,

    getTiltakHyppighet,
    saveTiltakHyppighet,
    updateTiltakHyppighet,
    slettTiltakHyppighet,

    getTiltakType,
    saveTiltakType,
    updateTiltakType,
    slettTiltakType,
    getOrganisasjonTiltakRisikovurdering,

    getAvvikAdmLukkArsak,
    saveAvvikAdmLukkArsak,
    updateAvvikAdmLukkArsak,
    slettAvvikAdmLukkArsak
  };