import React from 'react';
import { AuthContext } from 'norkartidjs2';
import { useContext, useState, useEffect } from 'react';
import ReactTable from '@norkart/react-table';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import { updatePersonId } from 'api/innloggingApi';
import NyBruker from './NyBruker.jsx';
import brann from 'images/Brannforebygging.png';
import { debugFactory  } from 'utils';
const debug = debugFactory('nk:auth.brukernavnkobler2');

let columns = [
  {
    id: 'brukernavn',
    name: 'Brukernavn',
    sortParams: 'brukernavn',
    isSorted: false,
    sortDirection: 'asc',
  },
  {
    id: 'navn',
    name: 'Navn',
    sortParams: 'navn',
    isSorted: false,
    sortDirection: 'asc',
  },
];

const BrukernavnKobler2 = (props) => {
  const authMsal = useContext(AuthContext);
  const [visJaNeiDialog, setVisJaNeiDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [visNyBruker, setVisNyBruker] = useState(false);
  const [brukereIkkeAktivert, setBrukereIkkeAktivert] = useState([]);

  useEffect(() => {
     if (props.brukere){
      setBrukereIkkeAktivert(props.brukere);
     }
  }, []);
  
  const logout = (e) => {
    e.preventDefault();
    authMsal.logOut();
  };

  const onPersonIdUpdated = () => {
    //this.setState({ visJaNeiDialog: false });
    setVisJaNeiDialog(  false );
    alert(
      'Din bruker er nå registrert i brannforebygging.\nDu må kontakte administrator for aktivering av brukerkontoen.\nDu får denne meldingen fordi det er første gang du logger inn.'
    );
    document.location.href = '/';
  };

  const selectRow = (row) => {
    setVisJaNeiDialog( true );
    setSelectedRow( row );
  };

  const closeNyBruker = () => {
    setVisNyBruker( !visNyBruker );
  };

  return (
    <div className='panel panel-default'>
      <div className='panel-body'>
        <div className='form'>
          <img src={brann} style={{ width: '40px', height: '40px' }} alt='' />
          <h3>
            {brukereIkkeAktivert.length > 0
              ? 'Velg navnet ditt fra listen'
              : 'For å kunne logge inn må du først registrere en ny bruker'}
          </h3>
          <JaNeiDialog
            isOpen={visJaNeiDialog}
            tittel={'Valgt bruker'}
            melding={
              'Du har valgt ' +
              (selectedRow !== null ? selectedRow.navn : '') +
              ' Stemmer dette?'
            }
            onJa={() => {
              updatePersonId(selectedRow.id, onPersonIdUpdated);
            }}
            onNei={() => {
              setVisJaNeiDialog( false );
             // this.setState({ visJaNeiDialog: false });
            }}
          />

          <NyBruker
            isOpen={visNyBruker}
            closeModal={() => {
              setVisNyBruker( false );
              document.location.href = '/';
            }}
            onClose={() => closeNyBruker()}
          />

              <ReactTable
                items={brukereIkkeAktivert}
                idProperty='id'
                showIndex={false}
                filterable={false}
                columns={columns}
                selectableRows={false}
                selectAllLabel='Alle'
                noDataText='Finner ingen bruker som ikke er aktivert'
                onRowClick={(row) => selectRow(row)}
              />
            

          <div className='btn-toolbar' role='toolbar'>
            <div className='btn-group' role='group'>
              <button
                className='btn btn-default'
                onClick={() => setVisNyBruker( true )}>
                Jeg finner ikke navnet mitt
              </button>
            </div>
            <div className='btn-group' role='group'>
              <button className='btn btn-default' onClick={logout}>
                Logg ut
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrukernavnKobler2;
