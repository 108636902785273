import React from 'react';
import KalenderBasis from 'components/Kalender/KalenderBasis.jsx';
import {  getArbeidslisteByAktiv } from 'api/arbeidslisteApi';
import {
  getArblisteBrukenheterPlanlegging,
  getArblisteBrukenheterGjennomforing
} from 'api/enhetApi.js';
import DialogStore from 'lib/DialogStore';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import { debugFactory } from 'utils';


const debug = debugFactory('nk:kalendar');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
      tittel="Filter"
      melding="Filter ett eller annet. F.eks: ansvarlig / arbeidsliste / sted / tiltak.. "
    />
  )
};

class KalenderMain extends React.Component {
  constructor() {
    super();
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });  
    this.state = {
      arbeidslistermain: [],
      arbeidslister: [],
      antallArbeidslister: 0,
      teller: 0,
      events: [],
      eventsUten: [],
      arbeidslisteColors: [],
      hideLaster: ''
    };
  }



  componentDidMount() {
    getArbeidslisteByAktiv(false, this.gotListerP);
  }

  gotListerP = (err, liste) => {
    for (let i = 0; i < liste.length; i++) {
      liste[i].aktiv = false;
    }
    this.setState({ arbeidslistermain: liste });
    getArbeidslisteByAktiv(true, this.gotListerG);
  };

  gotListerG = (err, liste) => {
    for (let i = 0; i < liste.length; i++) {
      liste[i].aktiv = true;
    }
    let orgliste = this.state.arbeidslistermain;
    let nyliste = orgliste.concat(liste);

    this.setState({ arbeidslistermain: nyliste });

    this.setState({ antallArbeidslister: nyliste.length });
    this.makeColors(nyliste.length);
    this.hentMerData();
  };

  makeColors = x => {
    let arbeidslisteColors = [];
    for (let i = 0; i < x + 1; i++) {
      let s = '#' + Math.floor(Math.random() * 16777215).toString(16);
      arbeidslisteColors.push(s);
    }
    this.setState({ arbeidslisteColors: arbeidslisteColors });
  };

  hentMerData = async () => {
    let sluttListe = [];
    let liste = this.state.arbeidslistermain;
    let total = liste.length;

    for (let i = 0; i < total; i++) {
      const arbeidsliste = liste[i];

      if (arbeidsliste.aktiv) {
        await getArblisteBrukenheterGjennomforing(
          arbeidsliste.id,
          // eslint-disable-next-line no-loop-func
          (err, liste) => {
            //Oppdater liste med arbeidsliste-navn og -id
            for (let y = 0; y < liste.length; y++) {
              liste[y].arbeidslisteid = arbeidsliste.id;
              liste[y].arbeidslistenavn = arbeidsliste.navn;
              liste[y].arbeidslisteaktiv = true;
              liste[y].teller = i;
            }
            sluttListe = sluttListe.concat(liste);
            if (i + 1 === total) {
              this.setState(
                { arbeidslister: sluttListe },
                this.makeTimeObjekts(sluttListe)
              );
            }
          }
        );
      } else {
        await getArblisteBrukenheterPlanlegging(
          arbeidsliste.id,
          // eslint-disable-next-line no-loop-func
          (err, liste) => {
            //Oppdater liste med arbeidsliste-navn og -id
            for (let y = 0; y < liste.length; y++) {
              liste[y].arbeidslisteid = arbeidsliste.id;
              liste[y].arbeidslistenavn = arbeidsliste.navn;
              liste[y].arbeidslisteaktiv = false;
              liste[y].teller = i;
            }
            //
            sluttListe = sluttListe.concat(liste);
            if (i + 1 === total) {
              this.setState(
                { arbeidslister: sluttListe },
                this.makeTimeObjekts(sluttListe)
              );
            }
          }
        );
      }
    }

    this.setState({ arbeidslister: sluttListe });
  };

  makeTimeObjekts = liste => {
    let timeListe = [];
    let timeListeUtenDato = [];
    for (let i = 0; i < liste.length; i++) {
      const element = liste[i];
      if (element.planlagtDato !== null) {
        let obj = {
          id: i,
          title: element.adresse + ' (' + element.arbeidslistenavn + ') ' + element.varselstatus,
          farge: this.state.arbeidslisteColors[element.teller],
          start: this.makeStartEnd(true, element),
          end: this.makeStartEnd(false, element),
          border: this.makeBorder(element),
          tiltakBruksenhet: element.tiltakBruksenhet,
          tiltakRoyklop: element.tiltakRoyklop,
          varselStatus: element.varselStatus,
          arbeidslistenavn: element.arbeidslistenavn,
          arbeidslisteid: element.arbeidslisteid
        };
        timeListe.push(obj);
      } else {
        let obj = {
          id: i,
          title: element.adresse,
          farge: this.state.arbeidslisteColors[element.teller],
          start: null,
          end: null,
          border: this.makeBorder(element),
          tiltakBruksenhet: element.tiltakBruksenhet,
          tiltakRoyklop: element.tiltakRoyklop,
          varselStatus: element.varselStatus,
          arbeidslistenavn: element.arbeidslistenavn,
          arbeidslisteid: element.arbeidslisteid
        };
        timeListeUtenDato.push(obj);
      }
    }
    this.setState({ events: timeListe });
    this.setState({ eventsUten: timeListeUtenDato });
    this.setState({ hideLaster: 'hide' });
  };

  makeBorder = el => {
    let res = 'green';
    if (el.varselstatus) {
      if (el.varselstatus.toLowerCase().includes('ok') === false) {
        res = '#FF0000';
      }
    }
    return res;
  };

  makeStartEnd = (start, el) => {
    let date = new Date(el.planlagtDato);
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let fra1 = '07';
    let fra2 = '00';
    let til1 = '08';
    let til2 = '00';
    if (el.besokFra) {
      if (el.besokFra !== null) {
        fra1 = el.besokFra.substring(0, 2);
        fra2 = el.besokFra.substring(3, 5);
      }
    }
    if (el.besokTil) {
      if (el.besokTil !== null) {
        til1 = el.besokTil.substring(0, 2);
        til2 = el.besokTil.substring(3, 5);
      }
    }
    if (start) {
      return new Date(year, month, day, fra1, fra2);
    } else {
      return new Date(year, month, day, til1, til2);
    }
  };

  onFilter = () => {
    this.dialogs.setOpenDialog('OkDialog');
  };

  render() {
    return (
      <div>
        
          <div>
          
            <label className={this.state.hideLaster}>Laster data..</label>
          
              <KalenderBasis
                events={this.state.events}
                eventsUten={this.state.eventsUten}
                onFilter={this.onFilter}
              />
           
            {this.dialogs.renderOpenDialog()}
          </div>
      </div>
    );
  }
}
export default KalenderMain;
