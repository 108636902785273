import React, { Component } from 'react';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import { TileLayer } from 'react-leaflet';
import NKIcon from 'nka-icons';
import $ from 'jquery';
import * as _ from 'lodash';
import { Tab, Tabs } from 'react-bootstrap';
import { debugFactory, objPickPropsDeep } from 'utils';
import { setWmsLayer } from './WmsUtils';
import { getSimpleOrganisasjon } from 'api/organisasjonApi';
import CONFIG from 'lib/CONFIG';
import { contextOppslagKommuner } from 'context/contextOppslag.js';
import { contextInnstillingerPerson, setKartContext, getKartContext, getContextInnstillingerKartNew, getOrgRisiko } from 'context/contextInnstillinger.js';
import RisikoControl from 'components/Kart/Controls/RisikoControl.jsx';
import risikoRed from 'images/risiko/markerred.png';
import risikoYellow from 'images/risiko/markeryellow.png';
import risikoBlue from 'images/risiko/markerblue.png';
import risikoGreen from 'images/risiko/markergreen.png';
import risikoRedOverstyrt from 'images/risiko/markerredOverstyrt.png';
import risikoYellowOverstyrt from 'images/risiko/markeryellowOverstyrt.png';
import risikoBlueOverstyrt from 'images/risiko/markerblueOverstyrt.png';
import risikoGreenOverstyrt from 'images/risiko/markergreenOverstyrt.png';
import risikoRedOverstyrtPlus from 'images/risiko/markerredOverstyrtPluss.png';
import risikoYellowOverstyrtPlus from 'images/risiko/markeryellowOverstyrtPluss.png';
import risikoBlueOverstyrtPlus from 'images/risiko/markerblueOverstyrtPluss.png';
import risikoGreenOverstyrtPlus from 'images/risiko/markergreenOverstyrtPluss.png';
import risikoRedPluss from 'images/risiko/markerredpluss.png';
import risikoYellowPluss from 'images/risiko/markeryellowpluss.png';
import risikoBluePluss from 'images/risiko/markerbluepluss.png';
import risikoGreenPluss from 'images/risiko/markergreenpluss.png';

const debug = debugFactory('nk:kart:layercontrol');
const tdStyle = { width: '40px', textAlign: 'center' };


//let visWmsLayer;
let kart = null;
let wmsGroup = L.featureGroup();
let activeLatling = {};

class GjLayerControl extends Component {
  state = {
    vektorClass: 'btn btn-primary w80',
    hybridClass: 'btn btn-default w80',
    fotoClass: 'btn btn-default w80',
    hideLayerComponent: 'hide',
    vektor: true,
    foto: false,
    hybrid: false,
    hideWmsPanel: 'hide',
    wmsListe: [],
    visWmsLegend: false,
    visWmsFInfo: false,
    wmsFInfoObjectListe: [],
    wmsFInfoObject: [],
    valgtObjectSide: 0,
    showBlaButtons: '',
    wmsListeCount: 0,
    wmsGroups: [],
    teller: 0,
    kommuner: [],
    groupAdded: false,
    valgtWmsListe: [],
    valgtLayer: null,
    activeTab: 1,
    tikker: -1,
    wmsTeller: 1,
    bbox: '',
    hideRisikoPanel: 'hide',
    harRisiko: false,
    visRisikoLegend: false
  };

  componentDidMount = () => {
    kart = this.props.kart;
    wmsGroup.eachLayer(function(layer) {
      wmsGroup.removeLayer(layer);
    });
    getSimpleOrganisasjon(this.onGotSimpleOrganisasjon);
    getContextInnstillingerKartNew(this.gotKart);
    //Så lenge kun til oss
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      this.getWmsForKommuner();
    }

    let kartContext = getKartContext('gj');
    let valgtWmsListe = kartContext.wmsListe;
   
   if (kartContext.layer){
    this.setBaseLayer(kartContext.layer);
   }

    if (valgtWmsListe) {
      this.setState({ valgtWmsListe });
      valgtWmsListe = this.rensClassNames(valgtWmsListe);
      setTimeout(() => {
        this.fortsettWmsOgView(valgtWmsListe, kartContext.zoom, kartContext.lastCenter );
      }, 3000);
    }
    this.setState({ mounted: true });
    getOrgRisiko(this.gotRisiko);
  };

  gotRisiko = (res) => {
    if (res) {
      this.setState({ harRisiko: res.data });
    }
  };


  gotKart = (data) => { 
    this.setState({ bbox: data.bbox });
};

  componentWillReceiveProps = (nextProps) => {
    if (kart == null) {
      kart = nextProps.kart;
    }
    let visible = nextProps.isvisible;
    if (visible === '') {
      if (!this.state.isMounted) {
        setTimeout(() => {
          this.start();
        }, 200);
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.tikker !== prevProps.tikker) {
      if (this.props.gotClickE !== null) {
        let latling = this.props.gotClickE.latlng;
        activeLatling = latling;
        this.gotClick(this.props.gotClickE);
      }
    }
  }

  componentWillUnmount = () => {
    let z = null;
    let c = null;
    if (kart){
      z = kart.getZoom();
      c = kart._lastCenter;
    }
    setKartContext('gj', this.state.valgtWmsListe, this.state.valgtLayer, z, c );
 };

  onGotSimpleOrganisasjon = (err, organisasjon) => {
    let urlConfig = CONFIG.REACT_APP_WMS_URL;
    let appName = '=cpc-Brannforebygging-';
  /*   if (urlConfig.includes('wacloudtest')) {
      appName = '=cpc-BrannforebyggingTest-';
    } */
    let urlx = urlConfig + appName + organisasjon.kortnavn;
    $.getJSON(urlx, this.gotWmsListeFraTjenestePanel); //Eiendomskart
  };

  getWmsForKommuner = () => {  // urlx = 'https://tjenestekatalogen.api.norkart.no/CatalogueService.svc/json/GetCapabilities?applicationID=cpc-Kommunekart-5037';  //Rieni  5037  4612
    let url = CONFIG.REACT_APP_WMS_URL;
    let appName = '=cpc-kommunekart-';

    let kommuner= contextOppslagKommuner._currentValue.kommuner;
      for (let i = 0; i < kommuner.length; i++) {
        let kom = kommuner[i];
        let urlx = url + appName + kom.kommuneId;  
        this.setState({ komkom: kom.kommuneNavn });
        $.getJSON(urlx, this.gotWmsListeFraTjenestePanel); 
      }
  }

  gotWmsListeFraTjenestePanel = (data) => {
    let teller = this.state.teller;
    let wmsListe = this.state.wmsListe;
    for (let i = 0; i < data.length; i++) {
      const group = data[i];
      let groupname = group.Name;
      let groupurl = group.URL;
      let grouptitle = group.Title;
      let imageurl = this.makeImageUrl(groupurl);
      for (let y = 0; y < group.Layers.length; y++) {
        let layer = group.Layers[y];
        teller = teller + 1;
        let rad = {
          alphablend: layer.AlphaBlend,
          layerdescription: layer.Description,
          layername: layer.Name,
          id: teller,
          zoomMax: 20,
          zoomMin: 14,
          groupurl: groupurl,
          grouptitle: grouptitle,
          groupname: groupname,
          style: '',
          srs: layer.SRS,
          className: 'btn btn-default',
          imageurl: imageurl,
        };
        wmsListe.push(rad);
      }
    }
    wmsListe = _.sortBy(wmsListe, 'grouptitle');
    this.setState({ wmsListe: wmsListe });
    this.setState({ teller: teller });
    let wmsGroupsRaw = _.uniqBy(wmsListe, 'grouptitle');
    let wmsGroups = [];
    for (let x = 0; x < wmsGroupsRaw.length; x++) {
      wmsGroups.push(wmsGroupsRaw[x].grouptitle);
    }

    this.setState({ wmsGroups: wmsGroups });
  };

  makeImageUrl = (groupurl) => {
    let urlstring = decodeURI(groupurl);
    var deler = urlstring.split('?');
    urlstring = deler[0];
    urlstring = urlstring.toLowerCase();
    urlstring = urlstring.replace('waapi.', 'www.');
    return urlstring;
  };

  rensClassNames = (liste) => {
    for (let i = 0; i < liste.length; i++) {
      const rad = liste[i];
      rad.className = 'btn btn-default';
    }
    return liste;
  };

  fortsettWmsOgView = (valgtWmsListe, zoom, center ) => {
    this.setVisWmsFraContext(valgtWmsListe);
    if (kart){
      // Dette kommer kanskje senere
/*       let viewPosition = [
        center.lat,
        center.lng
      ]; */
    //  kart.setView(viewPosition, zoom);
      
    }
  };

  setVisWmsFraContext = (valgtWmsListe) => {
    wmsGroup.clearLayers();
    this.setState({ visWmsLegend: valgtWmsListe.length > 0 });
    //Features
    this.setState({ wmsFInfoObject: [] });
    this.setState({ wmsFInfoObjectListe: [] });
    this.setState({ visWmsFInfo: false });
    this.addGroupToKart();

    for (let i = 0; i < valgtWmsListe.length; i++) {
      let rad = valgtWmsListe[i];
      wmsGroup = setWmsLayer(wmsGroup, rad, false);

      this.huskValgtWms(rad, true);
      this.setFargePaaListe(true, rad);
    }
  };

  handleLayerComponentHide = () => {
    this.setState({ hideWmsPanel: 'hide' });
    var hide = this.state.hideLayerComponent;
    if (hide === 'hide') {
      this.setState({ hideLayerComponent: ' ' });
    } else {
      this.setState({ hideLayerComponent: 'hide' });
    }
  };

  handleWmsPanelHide = () => {
    var hide = this.state.hideWmsPanel;
    if (hide === 'hide') {
      this.setState({ hideWmsPanel: ' ' });
    } else {
      this.setState({ hideWmsPanel: 'hide' });
    }
    this.setState({ hideLayerComponent: 'hide' });
  };

  handleBaseLayer = (e) => {
    this.setBaseLayer(e.target.value);
  };

  handleRisikoPanelHide = () => {
    var hide = this.state.hideRisikoPanel;
    if (hide === 'hide') {
      this.setState({ hideRisikoPanel: ' ' });
    } else {
      this.setState({ hideRisikoPanel: 'hide' });
    }
    this.setState({ hideLayerComponent: 'hide' });
  };

  skjulLegendRisiko = () => {
    this.setState({ visRisikoLegend: false });
  };

  visRisiko = (mode) => {
    this.props.visRisikoFraControl(mode);
    this.setState({ visRisikoLegend: mode === 'Feiing' || mode === 'Tilsyn' });
  };

  setBaseLayer = (lay) => {
    this.setState({ vektorClass: 'btn btn-default w80' });
    this.setState({ hybridClass: 'btn btn-default w80' });
    this.setState({ fotoClass: 'btn btn-default w80' });

    switch (lay) {
      case 'vektor':
        this.setState({ vektorClass: 'btn btn-primary w80' });
        break;
      case 'hybrid':
        this.setState({ hybridClass: 'btn btn-primary w80' });
        break;
      case 'foto':
        this.setState({ fotoClass: 'btn btn-primary w80' });
        break;
      default:
        this.setState({ vektorClass: 'btn btn-primary w80' });
    }

    this.setState({ vektor: lay === 'vektor' });
    this.setState({ foto: lay === 'foto' });
    this.setState({ hybrid: lay === 'hybrid' });

    this.setState({ valgtLayer: lay });

  };

  gotClick = (e) => {
    if (activeLatling === undefined) {
      activeLatling = { lat: e.lokasjonLatitude, lng: e.lokasjonLongitude };
    }

    this.setState({ wmsFInfoObjectListe: [] });
    this.setState({ wmsFInfoObject: [] });
    this.setState({ valgtObjectSide: 0 });
    this.setState({ visWmsFInfo: false });

    let wmsParamsListe = [];

    if (kart) {
      kart.eachLayer(function(layer) {
        if (layer.wmsParams) {
          let wmsParams = layer.wmsParams;
          if (wmsParams.service === 'WMS') {
            wmsParamsListe.push(wmsParams);
          }
        }
      });
    }
    for (let i = 0; i < wmsParamsListe.length; i++) {
      let params = wmsParamsListe[i];
      //this.getWmsInfo(e, params);
      this.getWmsInfo(activeLatling, params);
    }
  };

  wmsVisesAllerede = (wmsRad) => {
    let layerFjernes = null;
    wmsGroup.eachLayer(function(layer) {
      if (layer.options.layers === wmsRad.layername) {
        layerFjernes = layer;
      }
    });
    if (layerFjernes === null) {
      return false;
    } else {
      wmsGroup.removeLayer(layerFjernes);
      this.setFargePaaListe(false, wmsRad);
      this.huskValgtWms(wmsRad, false);
      return true;
    }
  };

  setFargePaaListe = (farge, wmsRad) => {
    let klasse = 'btn btn-default';
    if (farge) {
      klasse = 'btn btn-primary';
    }
    let liste = this.state.wmsListe;
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].id === wmsRad.id) {
        liste[i].className = klasse;
        break;
      }
    }
    //  this.setState({ wmsListe: liste });
    // liste = this.filterPrimary(liste);
    this.filterPrimary(liste);
    this.setWmsListe(liste);
  };

  filterPrimary = (liste) => {
    let nyListe = [];
    for (let i = 0; i < liste.length; i++) {
      let element = liste[i];
      if (element.className.includes('primary')) {
        nyListe.push(element);
      }
    }
    this.setState({ wmsListeCount: nyListe.length });
    // return nyListe;
  };

  finnesIkkeFraFor = (valgtWmsListe, wmsRad) => {
    let res = true;
    for (let i = 0; i < valgtWmsListe.length; i++) {
      const rad = valgtWmsListe[i];
      if (rad.id.toString() === wmsRad.id.toString()) {
        res = false;
        break;
      }
    }
    return res;
  };

  huskValgtWms = (wmsRad, isNew) => {
    let valgtWmsListe = this.state.valgtWmsListe;
    if (isNew) {
      if (this.finnesIkkeFraFor(valgtWmsListe, wmsRad)) {
        valgtWmsListe.push(wmsRad);
      }
    } else {
      if (!this.finnesIkkeFraFor(valgtWmsListe, wmsRad)) {
        _.remove(valgtWmsListe, function(rad) {
          return rad.layername === wmsRad.layername;
        });
      }
    }
    this.setState({ valgtWmsListe });
  };

  addGroupToKart = () => {
    if (this.state.groupAdded === false) {
      if (kart) {
        wmsGroup.addTo(kart);
        this.setState({ groupAdded: true });
      } else {
        debug('wms addGroupToKart kart = null!', this.state.wmsListe);
      }
    }
  };

  velgWms = (wmsRad) => {
    this.addGroupToKart();
    if (this.wmsVisesAllerede(wmsRad)) {
      return;
    }

    wmsGroup.on('click', (e) => {});
    wmsGroup = setWmsLayer(wmsGroup, wmsRad, false);

    this.huskValgtWms(wmsRad, true);
    this.setFargePaaListe(true, wmsRad);
  };

  getBasisUrl = (layername) => {
    for (let i = 0; i < this.state.wmsListe.length; i++) {
      let wms = this.state.wmsListe[i];
      if (wms.layername === layername) {
        return wms.groupurl;
      }
    }
    return null;
  };

  getWmsTittel = (layername) => {
    for (let i = 0; i < this.state.wmsListe.length; i++) {
      let wms = this.state.wmsListe[i];
      if (wms.layername === layername) {
        return wms.layerdescription;
      }
    }
    return null;
  };

  getWmsInfo = (e, wmsParams) => {
    let basisUrl = this.getBasisUrl(wmsParams.layers);
    let lay = wmsParams.layers;
    let version = wmsParams.version;

    // let latlng = e.latlng;
    let latlng = e;
    let lat1 = latlng.lng - 0.0004;
    let lat2 = latlng.lng + 0.0004;
    let lng1 = latlng.lat - 0.0002;
    let lng2 = latlng.lat + 0.0002;
    /* let lat1 = latlng.lng - 0.0002;
    let lat2 = latlng.lng + 0.0002;
    let lng1 = latlng.lat - 0.0001;
    let lng2 = latlng.lat + 0.0001; */
    let bbox =
      lat1.toString() +
      ',' +
      lng1.toString() +
      ',' +
      lat2.toString() +
      ',' +
      lng2.toString();
    let s =
      basisUrl +
      '&service=WMS&version=' +
      version +
      '&request=GetFeatureInfo&layers=' +
      lay +
      '&query_layers=' +
      lay +
      '&feature_count=10&info_format=application/json' +
      '&srs=EPSG%3A4326&width=101&height=101&x=50&y=50' +
      '&bbox=' +
      bbox;

    s = s.replace('http://', 'https://');
    $.getJSON(s, this.wmsResult);

    // kart.panTo(new L.LatLng(lng1, lat1));
  };

  wmsResult = (info) => {
    let features = info.features;
    if (features.length > 0) {
      for (let i = 0; i < features.length; i++) {
        let prop = features[i].properties;
        var id = features[i].id.split('.')[0];
        let tittel = this.getWmsTittel(id);

        let liste = [];
        for (var key in prop) {
          var value = prop[key];
          liste.push({ key: key, value: value });
        }
        liste = _.sortBy(liste, 'key');
        liste.unshift({ key: 'xx_tittel', value: tittel });

        let nyObjectListe = this.state.wmsFInfoObjectListe;
        nyObjectListe.push(liste);
        this.setState({ wmsFInfoObjectListe: nyObjectListe });

        if (nyObjectListe.length > 0) {
          this.setState({ wmsFInfoObject: nyObjectListe[0] });
          this.setState({ visWmsFInfo: true });
        } else {
          this.setState({ visWmsFInfo: false });
        }
        if (nyObjectListe.length > 1) {
          this.setState({ showBlaButtons: '' });
        } else {
          this.setState({ showBlaButtons: 'hide' });
        }
      }
    } else {
      debug('wms wmsControl INGEN RESULT', info);
    }
  };

  visNesteInfo = () => {
    let nr = this.state.valgtObjectSide + 1;
    if (this.state.wmsFInfoObjectListe.length > nr) {
      this.setState({ valgtObjectSide: nr });
      this.setState({ wmsFInfoObject: this.state.wmsFInfoObjectListe[nr] });
    }
  };

  visForrigeInfo = () => {
    let nr = this.state.valgtObjectSide;
    if (nr > 0) {
      this.setState({ valgtObjectSide: nr - 1 });
      this.setState({ wmsFInfoObject: this.state.wmsFInfoObjectListe[nr - 1] });
    }
  };

  wmsLegendKanVises = (liste) => {
    for (let i = 0; i < liste.length; i++) {
      let wms = liste[i];
      if (wms.imageurl.includes('gislinexxx.no')) {
        return true;
      }
    }
    return false;
  };

  setWmsListe = (wmsListe) => {
    this.setState({ wmsListe: wmsListe });

    let hasImages= false;
    for (let i = 0; i < wmsListe.length; i++) {
      const wms = wmsListe[i];
      if (wms.grouptitle === 'Risikoanalyse'){
        hasImages = true;
      }
    }
    this.setState({ visWmsLegend: (wmsListe.length > 0 && hasImages) });

    //Features
    this.setState({ wmsFInfoObject: [] });
    this.setState({ wmsFInfoObjectListe: [] });
    this.setState({ visWmsFInfo: false });
  };

  setVisSkjulLegend = () => {
    //Ikke vis i det hele tatt dersom ingen av wms'ene kan vise legend
    // if (this.wmsLegendKanVises()) {
    this.setState({ visWmsLegend: !this.state.visWmsLegend });
    // }
  };

  skjulFInfo = () => {
    this.setState({ visWmsFInfo: false });
  };

  skjulAlle = () => {
    wmsGroup.clearLayers();
    let liste = this.state.wmsListe;
    let klasse = 'btn btn-default';
    for (let i = 0; i < liste.length; i++) {
      liste[i].className = klasse;
    }
    this.setState({ wmsListe: liste });
    this.setState({ wmsListeCount: 0 });
    this.setWmsListe([]);
  };

  //////////////////////////////////////////////////////////
  setVisWms = () => {
    if (this.props.visning === 'wms') {
      let wms = this.props.wms;
      this.addGroupToKart();
      wmsGroup = setWmsLayer(wmsGroup, wms, false);
    }

    this.setState({ hideWmsPanel: '' });
  };


  getImageSource = wms => {
    let groupUrl = wms.groupurl;
    var n = groupUrl.lastIndexOf('?');
    let apitoken = groupUrl.substr(n);
    if (wms.imageurl.includes('gisline.no')) {
      let sk = this.makeImageSourceKommuner(wms);
      return sk;
    } else {
      let legendString =
        '&service=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=';
      let s = wms.imageurl +  apitoken + legendString + wms.layername;
      return s;
    }
  };

 makeImageSourceKommuner=(wms) => {
    let s = wms.imageurl + '?REQUEST=GetLegend&SERVICE=WMS&VERSION=1.0.0&LegendColor=0xFFFFFF&SRS=' ;
    let srs = wms.srs;
     srs = 'EPSG:4326';
     s = s + srs;
     let lay = '&LAYERS=' + wms.layername;
     s = s + lay;
     let rest = '&FORMAT=image/png&HEIGHT=1866&WIDTH=3259&LEGENDWIDTH=200&LEGENDHEIGHT=4000&LEGENDFONT=Verdana&LEGENDFONTSIZE=10&LEGENDELEMENTHEIGHT=15';
     s = s + rest;
    // let box = '&BBOX=5.184935709635413836,59.79329305013018825,5.191857096354165257,59.79753133138018484';
     //5.304444588572654,59.79367969702515,5.304844588572655,59.793879697025154
  //   let box2 = '&BBOX=345.541992187499998,39.06738281250001,51.781435604431195,72.50172235139388';
     s = s + this.state.bbox;
    return s;
  };



  makeGroupkey = (group) => {
    let s = group.replace(/[\])}[{(]/g, '');
    let z = s.replace(/\s+/g, '');
    return z;
  };

  handleSelectTab = (selectedTab) => {
    this.setState({ activeTab: selectedTab });
  };

  

  render() {
    let wmsPanel = (
      <div
        style={{
          backgroundColor: 'white',
          marginLeft: '2px',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
        className={this.state.hideWmsPanel}>
        <button
          title='Lukk'
          style={{ height: '33px', width: '33px', float: 'right' }}
          onClick={this.handleWmsPanelHide}>
          X
        </button>
        {this.state.wmsListe.length > 0 ? (
          <Tabs
            id='wmstabs'
            activeKey={this.state.activeTab}
            onSelect={this.handleSelectTab}>
            <Tab eventKey={1} title='Velg Wms'>
              <React.Fragment>
                <div>
                  <div
                    style={{
                      maxHeight: '360px',
                      height: 'auto',
                      backgroundColor: 'white',
                      overflowY: 'scroll',
                      background: 'white',
                      padding: '6px',
                    }}>
                    <div
                      className='panel-group'
                      style={{ width: '360px' }}
                      id='accordion'>
                      <span>
                        {this.state.wmsGroups.map(function(group) {
                          var g = this.makeGroupkey(group);
                          var bb = 'key' + g;
                          var html;
                          html = (
                            <div key={bb} style={{ width: '360px' }}>
                              <button
                                className='btn btn-basic arrow-toggle collapsed'
                                style={{
                                  marginBottom: '4px',
                                  borderColor: 'darkgreen',
                                  borderStyle: 'solid',
                                  borderWidth: '1px',
                                  width: '360px',
                                }}
                                data-toggle='collapse'
                                data-target={'#' + g}>
                                <label className='pull-left'>{group}</label>
                                <NKIcon
                                  className='pull-right icon-arrow-up'
                                  icon='ned'
                                  size='1'
                                  color='nk-black'
                                />
                                <NKIcon
                                  className='pull-right icon-arrow-down'
                                  icon='opp'
                                  size='1'
                                  color='nk-black'
                                />
                              </button>

                              <div id={g} className='collapse w200'>
                                {this.state.wmsListe.map(function(wms) {
                                  if (wms.grouptitle === group) {
                                    var bId = 'btn_' + wms.id;
                                    var dId = 'div_' + wms.id;
                                    var html;
                                    html = (
                                      <div key={dId}>
                                        <button
                                          style={{
                                            width: '340px',
                                            marginLeft: '10px',
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                          }}
                                          key={bId}
                                          type='button'
                                          onClick={this.velgWms.bind(this, wms)}
                                          className={wms.className}>
                                          {wms.layerdescription}
                                        </button>
                                        <br />
                                      </div>
                                    );
                                    return html;
                                  }
                                }, this)}
                              </div>
                            </div>
                          );

                          return html;
                        }, this)}
                      </span>
                    </div>
                  </div>

                  {this.state.wmsListeCount < 1 && (
                    <div style={{ height: '8px', backgroundColor: 'white' }} />
                  )}
                </div>
              </React.Fragment>
            </Tab>
            <Tab eventKey={2} title='Tegnforklaring'>
              <div
                style={{
                  maxHeight: '400px',
                  width: '340px',
                  backgroundColor: 'white',
                  overflowY: 'scroll',
                  background: 'white',
                  padding: '6px',
                }}>
                <React.Fragment>
                  {this.state.valgtWmsListe.length > 0 &&
                    this.state.visWmsLegend && (
                      <div
                        style={{
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: 'darkgray',
                          backgroundColor: 'white',
                        }}>
                        <div>
                          {this.state.valgtWmsListe.map(function(wms) {
                            let source = this.getImageSource(wms);
                            if (source === 'ingen') {
                              return '';
                            }
                            var imgId = 'img' + wms.id;
                            var dId = 'div' + wms.id;
                            var html;
                            html = (
                              <div key={dId}>
                                <div
                                  style={{
                                    paddingLeft: '5px',
                                    paddingRight: '5px',
                                    paddingBottom: '5px',
                                    borderBottom: 'thin solid darkgray',
                                  }}>
                                  <label>{wms.layerdescription}</label>
                                  <br />
                                  <img
                                    key={imgId}
                                    src={source}
                                    alt={' Bilde mangler'}
                                  />
                                </div>
                              </div>
                            );
                            return html;
                          }, this)}
                        </div>
                      </div>
                    )}
                </React.Fragment>
              </div>
            </Tab>
          </Tabs>
        ) : (
          <div
            style={{
              padding: '14px',
              backgroundColor: 'white',
              borderStyle: 'solid',
              borderWidth: '1px',
            }}>
            <button
              title='Lukk'
              style={{ height: '33px', width: '33px', float: 'right' }}
              onClick={this.handleWmsPanelHide}>
              X
            </button>
            <label style={{ marginTop: '8px', marginRight: '8px' }}>
              Ingen Wms definert.
            </label>
          </div>
        )}
      </div>
    );

    let risikoPanel = (
      <div className={'subPanel ' + this.state.hideRisikoPanel}>
        <RisikoControl
          ref={(ref) => (this.risikoControl = ref)}
          kart={this.props.kart}
          kartfra={this.props.kartfra}
          hidePanel={this.handleRisikoPanelHide}
          visRisiko={this.visRisiko}
          modus={this.state.modus}
        />
      </div>
    );

    let tellerA = 0;

    //let wmsListe = this.filterPrimary(this.state.wmsListe);

    return (
      <div>
        {this.state.vektor && (
          <TileLayer
            id='z1'
            key='z1'
            name='z1'
            url='//www.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png'
            maxZoom={20}
          />
        )}

        {this.state.foto && (
          <TileLayer
            id='z2'
            key='z2'
            name='z2'
            url='//www.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg'
            maxZoom={20}
          />
        )}

        {this.state.hybrid && (
          <TileLayer
            id='z3'
            key='z3'
            name='z3'
            url='//www.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg'
            maxZoom={20}
          />
        )}

        {/* Velg layer */}
        <Control position='topleft'>
          <div>
            <button
              type='button'
              className='btnLag'
              onClick={this.handleLayerComponentHide}>
              <NKIcon icon='lag' color='nk-black' />
            </button>
            {/*KART fOTO hYBRID*/}
            <div
              className={
                'layerComponent samme ' + this.state.hideLayerComponent
              }>
              <button
                type='button'
                value='vektor'
                onClick={this.handleBaseLayer}
                className={this.state.vektorClass}>
                Kart
              </button>{' '}
              <button
                type='button'
                value='hybrid'
                onClick={this.handleBaseLayer}
                className={this.state.hybridClass}>
                Hybrid
              </button>{' '}
              <button
                type='button'
                value='foto'
                onClick={this.handleBaseLayer}
                className={this.state.fotoClass}>
                Foto
              </button>{' '}
           {/*    {this.state.harRisiko && (
                <>
                  <button
                    style={{ backgroundColor: 'black', color: 'white' }}
                    type='button'
                    value='Risiko'
                    onClick={this.handleRisikoPanelHide}
                    className='btn btn-default w80'>
                    Risiko
                  </button>
                </>
              )} */}
              <button
                style={{ backgroundColor: '#388a8d', color: 'white' }}
                type='button'
                value='Wms'
                onClick={this.handleWmsPanelHide}
                className='btn btn-default w80'>
                Wms
              </button>
            </div>

            {<React.Fragment>{wmsPanel}</React.Fragment>}

          {/*   Så lenge ut-commentert
            {this.state.harRisiko && risikoPanel} */}
          </div>
        </Control>

        <Control position='bottomright'>
          <React.Fragment>
            {this.state.visWmsFInfo && (
              <div
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'darkgray',
                  backgroundColor: 'white',
                }}>
                <label
                  style={{
                    marginTop: '10px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize: '14px',
                  }}>
                  {'Wms Info: ' +
                    (this.state.valgtObjectSide + 1) +
                    ' av ' +
                    this.state.wmsFInfoObjectListe.length}
                </label>

                <button
                  className={'btn btn-default ' + this.state.showBlaButtons}
                  onClick={this.visForrigeInfo.bind(this)}
                  title='Forrige'
                  style={{
                    backgroundColor: 'white',
                    height: '33px',
                    width: '40px',
                    float: 'left',
                  }}>
                  <NKIcon icon='venstrepil' size='0.7' color='nk-black' />
                </button>
                <button
                  className='btn btn-default'
                  title='Lukk'
                  style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={this.skjulFInfo.bind(this)}>
                  X
                </button>

                <button
                  className={'btn btn-default ' + this.state.showBlaButtons}
                  onClick={this.visNesteInfo.bind(this)}
                  title='Neste'
                  style={{
                    backgroundColor: 'white',
                    height: '33px',
                    width: '40px',
                    float: 'right',
                  }}>
                  <NKIcon icon='hoyrepil' size='0.7' color='nk-black' />
                </button>

                <div
                  style={{
                    maxWidth: '300px',
                    maxHeight: '400px',
                    overflowY: 'scroll',
                    padding: '8px',
                  }}>
                  {this.state.wmsFInfoObject.map(function(rad) {
                    tellerA = tellerA + 1;
                    let key = 'kk' + tellerA;
                    let linje = (
                      <div style={{ display: 'inline' }} key={key}>
                        <p style={{ color: 'black', display: 'inline' }}>
                          {rad.key + ':  '}
                        </p>
                        <p style={{ color: 'darkgreen', display: 'inline' }}>
                          {rad.value}
                        </p>
                        <br />
                      </div>
                    );
                    if (rad.key === 'xx_tittel') {
                      linje = (
                        <div key={key}>
                          <label
                            style={{ paddingLeft: '8px', paddingRight: '5px' }}>
                            {rad.value}
                          </label>
                          <br />
                        </div>
                      );
                    }
                    return linje;
                  }, this)}
                </div>
              </div>
            )}
          </React.Fragment>
        </Control>

        <Control position='bottomright'>
          <React.Fragment>
            {this.state.visRisikoLegend && (
              <div
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'darkgray',
                  backgroundColor: 'white',
                }}>
                <label
                  style={{
                    marginTop: '10px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize: '14px',
                  }}>
                  Risiko markører
                </label>
                <button
                  className='btn btn-default pull-right'
                  title='Lukk'
                  style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={this.skjulLegendRisiko.bind(this)}>
                  X
                </button>
                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>Stor &nbsp;&nbsp;</td>
                      <td style={tdStyle}>Midd.</td>
                      <td style={tdStyle}>Lav&nbsp;</td>
                      <td style={tdStyle}>0&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRed}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellow}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreen}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBlue}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span style={{ marginLeft: '8px' }}>Overstyrt</span>

                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRedOverstyrt}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellowOverstyrt}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreenOverstyrt}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBlueOverstyrt}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span style={{ marginLeft: '8px' }}>Flere røykløp</span>
                <br />
                <span style={{ marginLeft: '8px', fontSize: '10px' }}>
                  Høyeste risiko lengst fram
                </span>

                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRedPluss}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellowPluss}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreenPluss}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBluePluss}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span style={{ marginLeft: '8px', fontSize: '10px' }}>
                  Høyeste (overstyrt) risiko lengst fram
                </span>

                <span style={{ marginLeft: '8px', fontSize: '10px' }}> </span>

                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRedOverstyrtPlus}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellowOverstyrtPlus}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreenOverstyrtPlus}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBlueOverstyrtPlus}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div
                  style={{
                    maxHeight: '300px',
                    minWidth: '30px',
                    backgroundColor: 'white',
                    overflowY: 'scroll',
                  }}></div>
              </div>
            )}
          </React.Fragment>
        </Control>
      </div>
    );
  }
}

export default GjLayerControl;
