import React from 'react';
import '../EnhetPopup.css';
import { getTooltipForGjennomforing } from 'components/Kart/getTooltip.js';

const EnhetPopupKjor = ({ calldirection, data }) => {
  const {
    adresse,
    tiltakBruksenhet,
    tiltakRoyklop,
    eiendom,
    lokasjonLatitude,
    lokasjonLongitude
  } = data;  
  let tiltak = '';
  if (tiltakBruksenhet) {
    tiltak = data.tiltakBruksenhet;
  }
  if (tiltakRoyklop) {
    if (tiltak === '') {
      tiltak = data.tiltakRoyklop;
    } else {
      tiltak = tiltak + ',' + data.tiltakRoyklop;
    }
  }

  let status = '';
  var index1 = '';
  var utf = '';
  var til = '';
  if (data.tiltakBrukenhetStatus) {
    index1 = data.tiltakBrukenhetStatus.indexOf('/');
    utf =
      parseInt(data.tiltakBrukenhetStatus.substring(0, index1)) +
      data.antallLopPaaAlisteBesokt;
    til =
      parseInt(
        data.tiltakBrukenhetStatus.substring(
          index1 + 1,
          data.tiltakBrukenhetStatus.length
        )
      ) + data.antallLopPaaAliste;
    status = '  Gjennomført: ' + utf + '/' + til;
  }

  let lat = lokasjonLatitude.toString();
  let long = lokasjonLongitude.toString();
  let koor = 'Lat:' + lat.substring(0, 7) + ' Long:' + long.substring(0, 7);

  let s = getTooltipForGjennomforing(data, '');
  let divX = <div dangerouslySetInnerHTML={{ __html: s }} />;

  if (data.bygningsnummer) {
    return (
      <div className="enhetPopup" style={{ textAlign: 'left', fontSize: '14px' }}>
      {/*   <label className="header"> {adresse} </label>
        <br />
        {eiendom}
        <br />Bygningsnr:  {data.bygningsnummer}
        <br />Bygningstype: {data.bruksenhetstype}
        <br />
        <br /> */}
        {divX}
        <button style={{ width: '180px', marginTop: '4px' }} onClick={() => calldirection(data)} className="btn btn-success">
          Veibeskrivelse
        </button>
      </div>
    );
  } else {
    return (
      <div className="enhetPopup" style={{ textAlign: 'left', fontSize: '14px' }}>
        {/* <label className="header"> {adresse} </label>
        <br />
        {eiendom}
        <br />
        Planlagte tiltak:
        <br /> {tiltak}
        <br /> {status}
        <br /> {koor}
        <br />
        <br /> */}
        {divX}
        <button style={{ width: '180px', marginTop: '4px' }} onClick={() => calldirection(data)} className="btn btn-success">
          Veibeskrivelse
        </button>
      </div>
    );
  }
};

export default EnhetPopupKjor;
