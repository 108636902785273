/* eslint-disable eqeqeq */
import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import { ToolbarButton } from 'components/generics/Button.jsx';
import { brukBreadcrumb } from 'components/Breadcrumbs.jsx';
import ArbeidslistePlanlegger from './ArbeidslistePlanlegger.jsx';
import VarsleSmsKartModus from './VarsleSmsKartModus';
import DialogStore from 'lib/DialogStore';
// Utils
import { debugFactory, promisifyApiCall } from 'utils';
import KansellerDialog from './KansellerDialog.jsx';
import SmsGenerellDialog from 'modules/felles/SmsGenerellDialog.jsx';
import FlyttDialog from './FlyttDialog.jsx';
import FlyttDialogOppgaverFerdig from './FlyttDialogOppgaverFerdig.jsx';
import VelgModusDialog from './VelgModusDialog.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import {
  getArblisteBrukenheterPlanlegging,
  postVarslingFjern,
} from 'api/enhetApi.js';
import {
  getArbeidslisteObjekt,
  updateArbeidslisteStatus,
} from 'api/arbeidslisteApi.js';
import {
  getBruksenhetStatus,
  getKontakterForEnhetInfo,
} from 'api/kontaktApi.js';
import {
  contextOppslagKommuner,
  contextOppslagBrukere,
} from 'context/contextOppslag.js';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import {
  setSelectedArbeidslistePlanlegging,
  setArbeidslisteTiltakListe,
} from 'context/contextPlanlegging.js';
import EndreRekkefolgeDialog from './EndreRekkefolgeDialog.jsx';
import { setRekkefolgenummer } from '../../../api/arbeidslisteApi.js';
const debug = debugFactory('nk:planlegging:arbeidsliste');

let kanselListe = null;

class Arbeidsliste extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    store: PropTypes.object,
  };

  state = {
    vent: '',
    valgteEnheter: [],
    sendInProgress: false,
    flyttDialogClass: 'malModalMediumStor',
    loaded: false,
    hasExtra: false,
    laderStatus: false,

    arbeidslisteId: null,
    selectedArbeidsliste: null,
    arbeidslisteEnheter: [],
    kommuner: [],
    personer: [],
    norkartAdminBruker: false,
    organisasjonAdminBruker: false,
    pilotBruker: false,
  };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    this.hentStartData();
    this.ammendBreadCrumbs();
  }

  hentStartData = () => {
    //Kommuner
    this.setState({
      kommuner: contextOppslagKommuner._currentValue.kommuner,
    });
    //Brukere
    this.setState({ personer: contextOppslagBrukere._currentValue.brukere });
    //Bruker
    this.setState({
      norkartAdminBruker: contextInnstillingerPerson._currentValue.norkartAdmin,
      organisasjonAdminBruker:
        contextInnstillingerPerson._currentValue.organisasjonAdmin,
      pilotBruker: contextInnstillingerPerson._currentValue.pilotBruker,
      orgNavn: contextInnstillingerPerson._currentValue.orgNavn,
    });
    this.hentArbeidsliste();
  };

  hentArbeidsliste = () => {
    let arbeidslisteId = this.props.params.arbeidslisteId;
    //Hent Arbeidsliste og Enheter
    return promisifyApiCall(getArbeidslisteObjekt)(arbeidslisteId).then(
      (selectedArbeidsliste) => {
        this.setState({ arbeidslisteId });
        this.setState({ selectedArbeidsliste });
        return promisifyApiCall(getArblisteBrukenheterPlanlegging)(
          arbeidslisteId
        ).then((arbeidslisteEnheter) => {
          this.setState({ arbeidslisteEnheter });
          this.setState({ loaded: true });
        });
      }
    );

    /////////////////////7 render
  };

  refreshArbeidsliste = () => {
    promisifyApiCall(getArblisteBrukenheterPlanlegging)(
      this.state.arbeidslisteId
    ).then((arbeidslisteEnheter) => {
      this.setState({ arbeidslisteEnheter });
    });
  };

  componentDidUpdate(prevProps) {
    this.ammendBreadCrumbs();
  }

  ammendBreadCrumbs = () => {
    //============= REACT CONTEXT BREADCRUMBS  ====================
    brukBreadcrumb('Arbeidsliste', 'planlegging_arbeidsliste_navn');
  };

  onLeggTilObjekterMed = () => {
    if (this.state.selectedArbeidsliste.aktiv) {
      this.dialogs.setOpenDialog('OkIkkeLeggTilPaAktiv');
    } else {
       setSelectedArbeidslistePlanlegging(this.state.selectedArbeidsliste);
      this.props.router.push({
        pathname: this.props.router.location.pathname + '/leggtilobjektermed',
      });
    }
  };

  getEnheterMedEiereKart = () => {
    this.setState({ vent: 'Vent...' });
    this.setState({ sendInProgress: true });
    let bruksenhetIdListe = [];
    let dtoListe = [];
    let bruksenhetDto = null;
    let liste = this.getValgte();

    for (let i = 0; i < liste.length; i++) {
      let enhet = liste[i];
      bruksenhetIdListe.push(enhet.id);
    }
    for (let i = 0; i < liste.length; i++) {
      let enhet = liste[i];
      bruksenhetIdListe.push(enhet.id);
      bruksenhetDto = {
        BruksenhetFagId: enhet.id,
        BruksenhetMaId: enhet.bruksenhetIdMa,
        BruksenhetAdresse: enhet.adresse,
      };
      dtoListe.push(bruksenhetDto);
    }

    promisifyApiCall(getKontakterForEnhetInfo)(dtoListe).then((kontakter) => {
      this.setState({ vent: '' });
      this.setState({ sendInProgress: false });
      this.dialogs.setOpenDialog('VelgDatoModusDialog', {
        kontakter: kontakter,
      });
    });
  };

  onModus = (modus, data) => {
    this.dialogs.closeDialog();
    this.dialogs.setOpenDialog('ModalVarsleSmsWizardKartModus', {
      enheterMedKontakter: data,
      personer: this.state.personer,
      orgNavn: this.state.orgNavn,
      modus: modus,
    });
  };

  varselStatusNull = (liste) => {
    let godkjente = [
      'OK',
      'Varsel sendt',
      'OK - manuell',
      'Ugyldig svar',
      'SvarUt sender',
      'SvarUt sendt',
    ];
    let res = false;
    for (let i = 0; i < liste.length; i++) {
      if (godkjente.indexOf(liste[i].varselstatus) === -1) {
        res = true;
        break;
      }
    }
    return res;
  };

  varselSvarut = (liste) => {
    //let su = ['SvarUt sender', 'SvarUt sendt'];
    let res = false;
    for (let i = 0; i < liste.length; i++) {
      if (
        liste[i].varselstatus == 'SvarUt sender' ||
        liste[i].varselstatus == 'SvarUt sendt'
      ) {
        res = true;
        break;
      }
    }
    return res;
  };

  varselGjennomfort = (liste) => {
    let res = false;
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].oppgaver === 'Ferdig') {
        res = true;
        break;
      }
    }
    return res;
  };

  sjekkOmAlleErSvarut = (liste) => {
    let res = true;
    for (let i = 0; i < liste.length; i++) {
      if (
        liste[i].varselstatus != 'SvarUt sender' &&
        liste[i].varselstatus != 'SvarUt sendt'
      ) {
        res = false;
        break;
      }
    }
    return res;
  };

  onVarlseSmsKart = () => {
    if (this.state.valgteEnheter.length > 0) {
      this.getEnheterMedEiereKart();
    } else {
      this.dialogs.setOpenDialog('OkVarsleEnheterIngenValgt');
    }
  };

  onKanseller = () => {
    let liste = this.state.valgteEnheter;
    if (liste.length == 0) {
      this.dialogs.setOpenDialog('OkKansellerAvbrytt', {
        melding: 'Du må først velge en eller flere enheter.',
      });
      return;
    }
    if (this.varselStatusNull(liste)) {
      this.dialogs.setOpenDialog('OkKansellerAvbrytt', {
        melding:
          'Du kan kun velge enheter med varselstatus "Varsel sendt", "OK", "OK-manuell" eller "Ugyldig svar".',
      });
      return;
    }

    if (this.varselGjennomfort(liste)) {
      this.dialogs.setOpenDialog('OkKansellerAvbrytt', {
        melding:
          'Sjekk valgte enheter: Det er ikke nødvendig å kansellere dersom oppgavene allerede har blitt gjennomført.',
      });
      return;
    }
    if (this.varselSvarut(liste)) {
      kanselListe = liste;
      this.dialogs.setOpenDialog('OkKansellerSvarut', {
        melding:
          'Du har valgt å kansellere tiltak som er varslet via Svar Ut. Disse vil ikke få systemgenerert beskjed om kanselleringen, og må håndteres manuelt. Ønsker du å fortsette?',
      });
      return;
    } else {
      this.dialogs.setOpenDialog('KansellerDialog', {
        modus: 'kanseller',
        valgteEnheter: liste,
      });
    }
  };

  kansellerSvarUt = () => {
    this.dialogs.closeDialog();
    let mod = 'kanseller';
    if (this.sjekkOmAlleErSvarut(kanselListe)) {
      mod = 'kansellerBareSvarut';
    }
    this.dialogs.setOpenDialog('KansellerDialog', {
      modus: mod,
      valgteEnheter: kanselListe,
    });
  };

  onSMS = () => {
    let liste = this.state.valgteEnheter;
    if (liste.length == 0) {
      this.dialogs.setOpenDialog('OkSMSAvbryt', {
        melding: 'Du må først velge en eller flere enheter.',
      });
      return;
    }
    this.dialogs.setOpenDialog('SmsGenerellDialog', {
      modus: 'multi',
      valgteEnheter: liste,
    });
  };

  getEnheterMedEiereNy = () => {
    this.setState({ vent: 'Vent...' });
    let bruksenhetIdListe = [];
    let dtoListe = [];
    let bruksenhetDto = null;
    let liste = this.getValgte();
    for (let i = 0; i < liste.length; i++) {
      let enhet = liste[i];
      bruksenhetIdListe.push(enhet.id);
    }
    for (let i = 0; i < liste.length; i++) {
      let enhet = liste[i];
      bruksenhetIdListe.push(enhet.id);
      bruksenhetDto = {
        BruksenhetFagId: enhet.id,
        BruksenhetMaId: enhet.bruksenhetIdMa,
        BruksenhetAdresse: enhet.adresse,
      };
      dtoListe.push(bruksenhetDto);
    }

    promisifyApiCall(getKontakterForEnhetInfo)(dtoListe).then((data) => {
      this.setState({ vent: '' });
      this.dialogs.setOpenDialog('ModalVarsleSmsWizardNy', {
        enheterMedKontakter: data,
        personer: this.state.personer,
        orgNavn: this.state.orgNavn,
      });
    });
  };

  //Ny varsle-modal
  onVarlseSmsNy = () => {
    if (this.state.valgteEnheter.length > 0) {
      this.getEnheterMedEiereNy();
    } else {
      this.dialogs.setOpenDialog('OkVarsleEnheterIngenValgt');
    }
  };

  onToggleActiveStatus = () => {
    if (this.state.selectedArbeidsliste.aktiv) {
      this.toggleArbeidslisteAktiv();
    } else {
      let liste = this.state.arbeidslisteEnheter;
      if (liste.length === 0) {
        this.dialogs.setOpenDialog('AktivereIkkeAlleVarslet');
        return;
      }
      let test = liste.find(
        (x) =>
          x.varselstatusId < 2 ||
          (x.varselstatusId > 7 && x.varselstatusId < 12) ||
          x.varselstatusId == 13 ||
          x.varselstatusId == 14 ||
          x.varselstatusId == 16
      );
      if (test !== undefined) {
        this.dialogs.setOpenDialog('AktivereIkkeAlleVarslet');
      } else {
        this.toggleArbeidslisteAktiv();
      }
    }
  };

  /*   "id": 0,
  "navn": "string",
  "aktiv": true,
  "ansvarlige": [
    {
      "id": 0,
      "personId": 0,
      "arbeidslisteId": 0,
      "navn": "string",
      "mobil": "string",
      "ny": true,
      "slett": true
    }
  ]

          const payload = {
            id: this.state.id,
            navn: this.state.navn,
            aktiv: this.state.aktiv,
            ansvarlige: Object.values(ansvarligePersoner)
        };
} */

  toggleArbeidslisteAktiv = () => {
    let selectedArbeidsliste = this.state.selectedArbeidsliste;
    debug('AJAX 1', selectedArbeidsliste);
    let id = selectedArbeidsliste.id;
    let isAktiv = !selectedArbeidsliste.aktiv;
    promisifyApiCall(updateArbeidslisteStatus)(id, isAktiv).then(() => {
      selectedArbeidsliste.aktiv = isAktiv;
      this.setState({ selectedArbeidsliste });
    });
  };

  onValgteEnheter = (valgteEnheter) => {
    let liste = [];
    for (let i = 0; i < valgteEnheter.length; i++) {
      const element = valgteEnheter[i];
      liste.push(element);
    }
    this.setState({ valgteEnheter: liste });
  };

  onRefreshArbeidsliste = () => {
    this.dialogs.closeDialog();
    this.setState({ valgteEnheter: [] });
    this.refreshArbeidsliste();
  };

  onRefreshArbeidslisteSmsSent = () => {
    this.dialogs.closeDialog();
    this.setState({ valgteEnheter: [] });
    this.refreshArbeidsliste();
  };

  onRefreshArbeidslisteFlytt = (melding) => {
    this.dialogs.closeDialog();
    if (melding) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Kan ikke flyttes..',
        melding: melding,
      });
    }
    this.refreshArbeidsliste();
  };

  onRefreshArbeidslisteFjern = (melding) => {
    let s = '';
    if (melding){
      if (melding.tekst){
        s= melding.tekst;
      }
    }
    this.dialogs.closeDialog();
    if (melding) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Kan ikke fjernes..',
        melding: s,
      });
    }
    this.refreshArbeidsliste();
  };

  getValgte = () => {
    let v = this.state.valgteEnheter;
    let liste = [];
    for (let i = 0; i < v.length; i++) {
      const row = v[i];
      row.dato = v[i].planlagtDato;
      liste.push(row);
    }
    return liste;
  };

  flyttEnhet = (enhet) => {
    let status = enhet.varselstatus;

    let godkjente = ['OK', 'Varsel sendt', 'OK - manuell'];
    if (enhet.oppgaver == 'Ferdig') {
      this.setState({ flyttDialogClass: 'malModalMediumLiten' });
      this.flyttEnhetOppgaverFerdigDialog(enhet);
    } else {
      if (godkjente.indexOf(status) > -1) {
        this.setState({ flyttDialogClass: 'malModalMediumStor' });
        this.flyttEnhetMedVarselDialog(enhet);
      } else {
        this.setState({ flyttDialogClass: 'malModalMediumLiten' });
        this.flyttEnhetUtenVarselDialog(enhet);
      }
    }
  };

  flyttEnhetOppgaverFerdigDialog = (enhet) => {
    this.dialogs.setOpenDialog('FlyttDialogOppgaverFerdig', {
      valgteEnheter: [enhet],
    });
  };

  flyttEnhetMedVarselDialog = (enhet) => {
    this.dialogs.setOpenDialog('FlyttDialog', {
      modus: 'flytt',
      skalVarsles: true,
      valgteEnheter: [enhet],
      melding1: '',
      melding2: '',
    });
  };

  flyttEnhetUtenVarselDialog = (enhet) => {
    let s2 = '.';
    if (enhet.varselstatus === null) {
      s2 = ' for de(n) har ikke fått et varsel om besøket fra før.';
    }
    if (enhet.varselstatus === 'Varsel kansellert') {
      s2 = ' for besøket var allerede kansellert.';
    }
    let melding1 = 'Du er i ferd med å flytte en enhet fra arbeidslisten. ';
    let melding2 = 'Det er ikke nødvendig å varsle eier(e)' + s2;
    this.dialogs.setOpenDialog('FlyttDialog', {
      modus: 'flytt',
      skalVarsles: false,
      valgteEnheter: [enhet],
      melding1: melding1,
      melding2: melding2,
    });
  };

  closeWizzard = () => {
    this.setState({ valgteEnheter: [] });
    this.dialogs.closeDialog();
  };

  fjernEnhet = (enhet) => {
    //sjekk om det skal åpnes en dialog først
    let status = enhet.varselstatus;
    let godkjente = ['OK', 'Varsel sendt', 'OK - manuell'];
    if (godkjente.indexOf(status) > -1) {
      //fjern etter dialog
      this.dialogs.setOpenDialog('KansellerDialog', {
        modus: 'fjern',
        valgteEnheter: [enhet],
      });
    } else {
      this.fjernEnhetUtenDialog(enhet);
    }
  };

  fjernEnhetUtenDialog = (enhet) => {
    let s2 = '.';
    if (enhet.varselstatus === null) {
      s2 = ' for de(n) har ikke fått et varsel om besøket fra før.';
    }
    if (enhet.varselstatus === 'Varsel kansellert') {
      s2 = ' for besøket var allerede kansellert.';
    }
    this.dialogs.setOpenDialog('FjerneDialog', {
      melding:
        'Du er i ferd med å fjerne en enhet fra arbeidslisten. ' +
        'Det er ikke nødvendig å varsle eier(e)' +
        s2 +
        ' Fortsette?',
      onJa: () => {
        this.jaFjernEnhetFraArbeidsliste(enhet);
      },
    });
  };

  jaFjernEnhetFraArbeidsliste = (e) => {
    let obj = {
      bruksenhetId: e.id,
      bruksenhetIdMa: e.bruksenhetIdMa,
      arbeidslisteId: this.state.arbeidslisteId,
      arbeidslisteIdFlytt: 0,
      matrikkelEnhetId: e.matrikkelenhetId,
      tiltaktyper: this.makeTiltak(e),
      varselDato: e.planlagtDato,
      varselstatusId: e.varselstatusId,
      besokFra: e.planlagtBesokFra ? e.planlagtBesokFra : '',
      besokTil: e.planlagtBesokTil ? e.planlagtBesokTil : '',
      adresse: e.adresse,
      ingenValidering: true,
      smsTekst: '',
      smsUtelat: false,
      varselKanseller: false,
      kontakt: [],
    };
    let s = '';
    if (!obj.adresse) {
      s = 'Adresse er ikke definert. ';
    } else {
      if (obj.adresse === '') {
        s = 'Adresse er tomt. ';
      }
    }
    if (s !== '') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Enheten kan ikke fjernes..',
        melding: 'Merkelig feil: 2B' + s + ' Ta kontakt med brukerstøtte.',
      });
      return;
    } else {
      postVarslingFjern(obj, this.enhetharBlittFjernet);
    }
  };

  enhetharBlittFjernet = (err) => {
    this.onRefreshArbeidsliste();
  };

  makeTiltak = (enhet) => {
    let s = '';
    if (enhet.tiltakBruksenhet) {
      s = enhet.tiltakBruksenhet.trim() + ',';
    }
    if (enhet.tiltakRoyklop) {
      s = s + enhet.tiltakRoyklop.trim();
    }
    if (s.charAt(s.length - 1) === ',') {
      s = s.substr(0, s.length - 1);
    }
    s = s.replace(' ', '');
    s = s.replace(',', ', ');
    return s;
  };

  svarUt = (item) => {
    alert('Ikke implementert enda!');
  };

  onSjekkDigitalStatus = () => {
    this.setState({ laderStatus: true });
    let enheter = this.state.arbeidslisteEnheter;
    let liste = [];
    for (let i = 0; i < enheter.length; i++) {
      let obj = {
        BruksenhetFagId: enheter[i].id,
        BruksenhetMaId: enheter[i].bruksenhetIdMa,
      };
      liste.push(obj);
    }
    getBruksenhetStatus(liste, this.gotDigStatus);
  };

  gotDigStatus = (err, data) => {
    let enheter = this.state.arbeidslisteEnheter;
    for (let i = 0; i < enheter.length; i++) {
      const enhet = enheter[i];
      let match = _.find(data, { bruksenhetFagId: enhet.id });
      if (match) {
        enheter[i].extra = match;
        enheter[i].digstatus = match.status;
      }
    }
    this.setState({
      arbeidslisteEnheter: enheter,
      hasExtra: true,
      laderStatus: false,
    });
  };

  checkForRekkefolge = () => {
    //Check 1: antall rader
    if (this.state.arbeidslisteEnheter.length < 1) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Rekkefølge',
        melding: 'Rekkefølgen kan ikke endres for en tom arbeidsliste.',
      });
      return false;
    }
    //Check 2: varslet
    let check = this.state.arbeidslisteEnheter.find(
      (x) =>
        x.varselstatusId < 2 ||
        (x.varselstatusId > 7 && x.varselstatusId < 12) ||
        x.varselstatusId == 13 ||
        x.varselstatusId == 14 ||
        x.varselstatusId == 16
    );
    if (check !== undefined) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Rekkefølge',
        melding:
          'Rekkefølgen kan ikke endres før alle enhetene har blitt varslet.',
      });
      return false;
    }
    //No problems:
    return true;
  };

  onRekkefolge = () => {
    if (this.checkForRekkefolge()) {
      this.dialogs.setOpenDialog('RekkefolgeDialog', {
        arbeidslisteNavn: this.state.selectedArbeidsliste.navn,
        arbeidslisteId: this.state.arbeidslisteId,
        enheter: this.state.arbeidslisteEnheter,
      });
    }
  };

  onEndreRekkefolge = (liste) => {
    this.dialogs.closeDialog();
    setRekkefolgenummer(liste, this.onRekkefolgeEndret2);
  };

  onRekkefolgeEndret2 = (err, data) => {
    this.refreshArbeidsliste();
  };

  render() {
    if (!this.state.selectedArbeidsliste) {
      return <div className='loader' />;
    }
    if (this.state.sendInProgress) {
      return <div className='loader' />;
    }
    if (this.state.loaded == false) {
      return <div>Vent...</div>;
    }

    const toggleAktivStatusText = this.state.selectedArbeidsliste.aktiv
      ? 'Deaktiver arbeidsliste'
      : 'Aktiver arbeidsliste';

    return (
      <React.Fragment>
        <h2>{this.state.selectedArbeidsliste.navn}</h2>
        <hr />
        <div>
          <div>
            <div>
              <div className='row'>
                <div className='col-md-7'>
                  <ToolbarButton
                    style={{ marginRight: 0 }}
                    icon='pluss'
                    onClick={this.onLeggTilObjekterMed}>
                    {' '}
                    Legg til objekter
                  </ToolbarButton>

                  <ToolbarButton
                    icon='lenke'
                    onClick={this.onSjekkDigitalStatus}>
                    Sjekk status for utsendelse
                  </ToolbarButton>

                  <ToolbarButton icon='varsel' onClick={this.onVarlseSmsKart}>
                    Varsle valgte enheter
                  </ToolbarButton>

                  <ToolbarButton
                    icon='lasting'
                    onClick={this.onToggleActiveStatus}>
                    {toggleAktivStatusText}
                  </ToolbarButton>
                </div>
                <div className='col-md-5'>
                  <ToolbarButton
                    className='pull-right'
                    icon='varsel'
                    onClick={this.onKanseller}>
                    Kanseller
                  </ToolbarButton>

                  <ToolbarButton
                    className='pull-right'
                    icon='kommentar'
                    onClick={this.onSMS}>
                    SMS
                  </ToolbarButton>

                  <ToolbarButton
                    className='pull-right'
                    icon='liste'
                    onClick={this.onRekkefolge}>
                    Rekkefølge
                  </ToolbarButton>

                  {/*   {this.state.norkartAdminBruker && (
                    <ToolbarButton
                      className='pull-right'
                      icon='liste'
                      onClick={this.onRekkefolge}>
                      Rekkefølge
                    </ToolbarButton>
                  )} */}
                </div>
              </div>
            </div>
          </div>

          {this.state.vent !== '' && (
            <label style={{ marginLeft: '20px', marginTop: '10px' }}>
              {this.state.vent}
            </label>
          )}

          <hr />

          {/* TODO! Refactor all of `ArbeidslistePlanlegger` in here! */}
          <ArbeidslistePlanlegger
            hasExtra={this.state.hasExtra}
            laderStatus={this.state.laderStatus}
            arbeidsliste={this.state.selectedArbeidsliste}
            enheter={this.state.arbeidslisteEnheter}
            orgKommuner={this.state.kommuner}
            onValgteEnheter={this.onValgteEnheter}
            refreshArbeidsliste={this.onRefreshArbeidsliste}
            slettEnhet={this.slettEnhet}
            fjernEnhet={this.fjernEnhet}
            flyttEnhet={this.flyttEnhet}
            svarUt={this.svarUt}
          />
        </div>

        {this.dialogs.renderOpenDialog()}
      </React.Fragment>
    );
  }
}

export default Arbeidsliste;

// Declarative parts

const modalDialogs = {
  OkIkkeLeggTilPaAktiv: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Arbeidsliste er aktiv'
        melding='Det er ikke mulig å legge til flere objekter i en aktiv liste. Sett listen til inaktiv først om du ønsker å legge til flere objekter på listen'
        onOk={onClose}
      />
    );
  },
  FjerneDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={'Fjerne fra arbeidsliste'}
        melding={dialogData.melding}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
  KansellerDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal isOpen={isOpen} className='malModalMedium'>
        <KansellerDialog
          onClose={onClose}
          modus={dialogData.modus}
          valgteEnheter={dialogData.valgteEnheter}
          arbeidsliste={owner.state.selectedArbeidsliste}
          refreshArbeidslisteFjern={owner.onRefreshArbeidslisteFjern}
          refreshArbeidsliste={owner.onRefreshArbeidsliste}
        />
      </ReactModal>
    );
  },
  SmsGenerellDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen}>
        <SmsGenerellDialog
          onClose={onClose}
          modus={dialogData.modus}
          valgteEnheter={dialogData.valgteEnheter}
          refreshArbeidsliste={owner.onRefreshArbeidsliste}
        />
      </NKModal>
    );
  },
  FlyttDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal isOpen={isOpen} className={owner.state.flyttDialogClass}>
        <FlyttDialog
          onClose={onClose}
          skalVarsles={dialogData.skalVarsles}
          melding1={dialogData.melding1}
          melding2={dialogData.melding2}
          modus='flytt'
          valgteEnheter={dialogData.valgteEnheter}
          arbeidsliste={owner.state.selectedArbeidsliste}
          refreshArbeidslisteFlytt={owner.onRefreshArbeidslisteFlytt}
        />
      </ReactModal>
    );
  },
  FlyttDialogOppgaverFerdig: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal isOpen={isOpen} className={owner.state.flyttDialogClass}>
        <FlyttDialogOppgaverFerdig
          onClose={onClose}
          valgteEnheter={dialogData.valgteEnheter}
          arbeidsliste={owner.state.selectedArbeidsliste}
          refreshArbeidslisteFlytt={owner.onRefreshArbeidslisteFlytt}
        />
      </ReactModal>
    );
  },
  VelgDatoModusDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal contentLabel='' isOpen={isOpen} className='malModalLiten300'>
        <VelgModusDialog
          data={dialogData.kontakter}
          onModus={owner.onModus}
          onClose={onClose}
        />
      </ReactModal>
    );
  },

  RekkefolgeDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal contentLabel='' isOpen={isOpen} className='modalRekkefolge'>
        <EndreRekkefolgeDialog
          arbeidslisteNavn={dialogData.arbeidslisteNavn}
          arbeidslisteId={dialogData.arbeidslisteId}
          enheter={dialogData.enheter}
          onClose={onClose}
          onOk={owner.onEndreRekkefolge}
        />
      </ReactModal>
    );
  },

  ModalVarsleSmsWizardKartModus: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal contentLabel='' isOpen={isOpen} className='FModal'>
        <VarsleSmsKartModus
          enheterMedKontakter={dialogData.enheterMedKontakter}
          arbeidslisteId={owner.state.selectedArbeidsliste.id}
          smsEnheter={owner.getValgte()}
          onClose={owner.closeWizzard}
          arbeidsliste={owner.state.selectedArbeidsliste}
          refreshArbeidsliste={owner.onRefreshArbeidsliste}
          personer={dialogData.personer}
          orgNavn={dialogData.orgNavn}
          modus={dialogData.modus}
        />
      </ReactModal>
    );
  },
  OkVarsleEnheterIngenValgt: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Varsle enheter'
        melding='Du må først velge en eller flere enheter som skal få varsel'
        onOk={onClose}
      />
    );
  },
  OkKansellerAvbrytt: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Kan ikke kansellere..'
        melding={dialogData.melding}
        onOk={onClose}
      />
    );
  },
  OkSMSAvbryt: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Send SMS'
        melding={dialogData.melding}
        onOk={onClose}
      />
    );
  },
  OkKansellerSvarut: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Kansellere..'
        melding={dialogData.melding}
        onOk={owner.kansellerSvarUt}
      />
    );
  },
  AktivereIkkeAlleVarslet: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Aktiver arbeidsliste'
        melding='Alle enheter på arbeidslisten må varsles før listen kan aktiveres.'
        onOk={onClose}
      />
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};
