import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import * as _ from 'lodash';
import ReactModal from 'react-modal';
import ReactTable from '@norkart/react-table';
import { withRouter } from 'components/Router';
import ContextMenu from 'components/ContextMenu';
import KartDialog from 'components/Kart/KartDialog.jsx';
import EndreArbeidsliste from './EndreArbeidsliste.jsx';
import NyArbeidsliste from './NyArbeidsliste.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { ToolbarButton } from 'components/generics/Button.jsx';
import { getArbeidslistePlanlegging, getArbeidslistePlanleggingByUserId, validerSlettArbeidsliste, updateArbeidslisteStatus, deleteArbeidsliste, saveArbeidsliste } from 'api/arbeidslisteApi';
import { getArblisteBrukenheterPlanlegging } from 'api/enhetApi';
import { huskBreadcrumb } from 'components/Breadcrumbs.jsx';
import {
  setValgtPerson,
  contextPlanlegging,
} from 'context/contextPlanlegging.js';
import {  getContextBrukere } from 'context/contextOppslag.js';
import { debugFactory, promisifyApiCall } from 'utils';
import green from 'images/Annet/legendaBlokGreen.png';
import orange from 'images/Annet/legendaBlokOrange.png';
const debug = debugFactory('nk:Planlegging');

let antall = 0;

const modalDialogs = {
  NyArbeidsliste: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='small' isOpen={isOpen}>
        <NyArbeidsliste
          onClose={onClose}
          onSettInnNyArbeidsliste={onClose(owner.onNyArbeidsliste)}
        />
      </NKModal>
    );
  },
  EndreArbeidsliste: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='small' isOpen={isOpen}>
        <EndreArbeidsliste
          onClose={onClose(owner.onEndretArbeidsliste)}
          arbeidsliste={dialogData.item}
        />
      </NKModal>
    );
  },
  VisKart: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ReactModal contentLabel='' isOpen={isOpen} className='FModal'>
        <KartDialog
          data={dialogData.item}
          visning='planlegging-arbeidsliste'
          onClose={onClose}
          tittel={dialogData.item.navn}
        />
      </ReactModal>
    );
  },
  OkSlettArbeisliste: ({ isOpen, onClose, dialogData, owner }) => {
    console.log(dialogData.item);
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={`Slett arbeidsliste "${dialogData.item.navn}"`}
        melding='Du sletter nå valgt arbeidsliste. Vil du fortsette?'
        onOk={onClose(() => owner.onSlettArbeidsliste(dialogData.item))}
      />
    );
  },
  IkkeOkSlettArbeisliste: ({ isOpen, onClose, dialogData, owner }) => {
    console.log(dialogData.item);
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={`Slett arbeidsliste "${dialogData.item.navn}"`}
        melding='Arbeidslisten kan ikke slettes da den ikke er fullført.'
        onOk={onClose}
      />
    );
  },
  OkValiderSlettArbeidsliste: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Validering'
        melding={dialogData.message + ' Vil du fortsette?'}
        onOk={() => owner.onSlettArbeidslisteValidert({ id: dialogData.id })}
      />
    );
  },
  AktivereIkkeAlleVarslet: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel='Aktiver arbeidsliste'
        melding='Alle enheter på arbeidslisten må varsles før listen kan aktiveres.'
        onOk={onClose}
      />
    );
  },
};

/* const mapStateToProps = (state, ownProps) => {
  const newState = objPickProps(
    state,
    [
      'planlegging.brukere',
      'planlegging.arbeidslister',
      'planlegging.arbeidslisteEnheter',
    ],
    {
      targetKeysPrefix: 'store.',
    }
  );
  return newState;
}; */

/* const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...objPickProps(planleggingActions, [
       // 'fetchBrukere',
        //'fetchArbeidslister',
       // 'FetchArbeidslister',
      // 'fetchArbeidslisteEnheter',
     //   'removeArbeidsliste',
        //'toggleArbeidslisteAktiv',
       // 'addNewArbeidsliste',
      ]),
    },
    dispatch
  ); */

class Arbeidslister extends Component {
/*   static propTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
  }; */
  state = {
    filtrerteLister: null,
    personer: [],
    valgtPersonId: 0,
    antallFerdig: 0,
    ferdigCheckSlett: false
  };

  constructor(props) {
    super(props);
    antall = 0;
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    antall = 0;
    //Velg person i rullegardin
    let ansvarligFraContext = contextPlanlegging._currentValue.personId;

    getContextBrukere( brukere => {
      if (ansvarligFraContext === -1){
      this.setState({ valgtPersonId: brukere.paalogget });
      this.filterArbeidslister(brukere.paalogget);
      } else {
        this.setState({ valgtPersonId: ansvarligFraContext });
        this.filterArbeidslister(ansvarligFraContext);
      }
      this.setState({ personer: brukere.brukere });
    });    
  };



  getReactTableColumns = () => [
    {
      id: 'navn',
      name: 'Arbeidsliste',
      sortParams: 'navn',
      isSorted: false,
      formatter: function(item) {
        return <div style={{ maxWidth: '300px' }}>{item.navn}</div>;
      },
    },
    {
      id: 'antallBruksenheter',
      name: 'Bruksenheter',
      sortParams: 'antallBruksenheter',
      filterable: false,
      isSorted: false,
      sortable: false,
    },
    {
      id: 'ansvarlig',
      name: 'Ansvarlig',
      sortParams: 'ansvarlig',
      filterable: false,
      isSorted: false,
    },
    {
      id: 'datofra',
      name: 'Første besøk',
      sortParams: 'planlagtFraDato',
      filterable: false,
      sortable: true,
      formatter: function(item) {
        if (item.planlagtFraDato === null) {
          return '';
        } else {
          var dato = new Date(item.planlagtFraDato);
          var dag = dato.getDate().toString();
          var mnd = (dato.getMonth() + 1).toString();
          var aar = dato.getFullYear().toString();
          if (dag.length === 1) {
            dag = '0' + dag;
          }
          if (mnd.length === 1) {
            mnd = '0' + mnd;
          }
          return dag + '.' + mnd + '.' + aar;
        }
      },
    },
    {
      id: 'datotil',
      name: 'Siste besøk',
      sortParams: 'planlagtTilDato',
      filterable: false,
      formatter: function(item) {
        if (item.planlagtTilDato === null) {
          return '';
        } else {
          var dato = new Date(item.planlagtTilDato);
          var dag = dato.getDate().toString();
          var mnd = (dato.getMonth() + 1).toString();
          var aar = dato.getFullYear().toString();
          if (dag.length === 1) {
            dag = '0' + dag;
          }
          if (mnd.length === 1) {
            mnd = '0' + mnd;
          }
          return dag + '.' + mnd + '.' + aar;
        }
      },
    },
    {
      id: 'aktiv',
      name: 'Aktiv',
      sortParams: 'aktiv',
      filterable: false,
      isSorted: false,
      sortable: true,
      hideInVertical: true,
      formatter: function(item) {
        if (item.aktiv === false) {
          return 'Nei';
        } else {
          return 'Ja';
        }
        // if (item.aktiv === false) {
        //     return React.createElement('input', { type: 'checkbox', defaultChecked: false });
        // } else {
        //     return React.createElement('input', { type: 'checkbox', defaultChecked: true });
        // }
      },
    },
    {
      id: 'tiltak',
      name: 'Tiltak',
      sortParams: 'tiltak',
      filterable: false,
      isSorted: false,
      sortable: false,
      hideInVertical: false,
      formatter: function(item) {
        if (item.tiltakBruksenhet === null && item.tiltakRoyklop === null) {
          return '';
        }
        if (item.tiltakRoyklop === null) {
          return item.tiltakBruksenhet;
        }
        if (item.tiltakBruksenhet === null) {
          return item.tiltakRoyklop;
        } else {
          return item.tiltakBruksenhet + ', ' + item.tiltakRoyklop;
        }
      },
    },
    {
      id: 'status',
      name: 'Reg. tiltak',
      filterable: false,
      isSorted: false,
      sortable: false,
      hideInVertical: false,
      formatter: function(item) {
        var index1 = item.tiltakBrukenhetStatus.indexOf('/');
        var index2 = item.tiltakRoyklopStatus.indexOf('/');
        var utf =
          parseInt(item.tiltakBrukenhetStatus.substring(0, index1)) +
          parseInt(item.tiltakRoyklopStatus.substring(0, index2));
        var til =
          parseInt(
            item.tiltakBrukenhetStatus.substring(
              index1 + 1,
              item.tiltakBrukenhetStatus.length
            )
          ) +
          parseInt(
            item.tiltakRoyklopStatus.substring(
              index2 + 1,
              item.tiltakRoyklopStatus.length
            )
          );
        let s = utf + '/' + til;
        item.ferdig = false;
        item.antallUtfort = utf;
        if (s !== '0/0' && utf === til) {
          item.ferdig = true;
          //}
          //  return s;
          antall = antall + 1;
          return (
            <span
              style={{
                fontStyle: 'Bold',
                background: '#a1c438',
                color: 'White',
              }}>
              {s}
            </span>
          );
        } else {
          return s;
        }
      }.bind(this),
    },
    {
      id: 'meny',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        return (
          <ContextMenu.Button
            id='menu1'
            title='Meny'
            icon='meny'
            iconColor='nk-black'
            className='inlinebtn'>
            {!item.aktiv && (
              <ContextMenu.Item
                onClick={this.onToggleActiveStatus.bind(this, item)}>
                Aktiver...
              </ContextMenu.Item>
            )}

            {item.aktiv && (
              <ContextMenu.Item
                onClick={this.onToggleActiveStatus.bind(this, item)}>
                Deaktiver...
              </ContextMenu.Item>
            )}

            <ContextMenu.Item
              onClick={() =>
                this.dialogs.setOpenDialog('EndreArbeidsliste', { item })
              }>
              Endre...
            </ContextMenu.Item>

            <ContextMenu.Item
              onClick={() => {
                this.setState({ ferdigCheckSlett: item.ferdig });
                this.slett(item);
                //this.dialogs.setOpenDialog('OkSlettArbeisliste', { item });
              }}>
              Slett...
            </ContextMenu.Item>

            <ContextMenu.Divider />

            <ContextMenu.Item
              onClick={() => this.dialogs.setOpenDialog('VisKart', { item })}>
              Vis i kart..
            </ContextMenu.Item>
          </ContextMenu.Button>
        );
      }.bind(this),
    },
    {
      //Ekstra space ifm meny-button i mobil-grid
      formatter: function() {
        return <div>&nbsp;</div>;
      },
    },
  ];

  filterArbeidslister = (p) => {
    promisifyApiCall(getArbeidslistePlanleggingByUserId)(p).then((lister) => {
        
  
        //this.setState({ filtrerteLister: _.sortBy(lister, 'navn') });
        this.setState({ filtrerteLister: lister });
        this.setState({ filtrerteLister: lister });
      }
    );

    // promisifyApiCall(getArbeidslistePlanlegging)().then((lister) => {
    //     if (p && p > 0) {
    //       lister = _.filter(lister, (item) =>
    //         (item.ansvarligIds + ',').includes(p + ',')
    //       );
    //     }
  
    //     //this.setState({ filtrerteLister: _.sortBy(lister, 'navn') });
    //     this.setState({ filtrerteLister: lister });
    //     this.setState({ filtrerteLister: lister });
    //   }
    // );
  };

  filterArbeidslisterNewItem = (p) => {
    promisifyApiCall(getArbeidslistePlanlegging)().then(
      (lister) => {
        if (p && p > 0) {
          lister = _.filter(lister, (item) =>
            (item.ansvarligIds + ',').includes(p + ',')
          );
        }
        this.setState({ filtrerteLister: _.sortBy(lister, 'id').reverse() });
      }
    );
  };

  handleAnsvarligChange = (p) => {
    this.filterArbeidslister(p.target.value);
    this.setState({ valgtPersonId: p.target.value });
    setValgtPerson(p.target.value);
  };

  onNyArbeidsliste = (data) => {
    const item = {
      Navn: data.navn,
      aktiv: false,
      ansvarlige: data.ansvarlige.map((ansvarlig) => ({
        personId: ansvarlig.id,
        navn: ansvarlig.navn,
        ny: true,
      })),
    };
    promisifyApiCall(saveArbeidsliste)(item).then(() => {
      this.filterArbeidslisterNewItem(this.state.valgtPersonId);
    });
  };

  onEndretArbeidsliste = () => {
    this.filterArbeidslister(this.state.valgtPersonId);
  };

  slett = (item) => {
    console.log(item);
    if (item.antallUtfort == 0) {
      this.dialogs.setOpenDialog('OkSlettArbeisliste', { item });
    } else {
      if (item.antallBruksenheterSendtEier >= item.antallBruksenheter) {
        this.dialogs.setOpenDialog('OkSlettArbeisliste', { item });
      } else {
        this.dialogs.setOpenDialog('IkkeOkSlettArbeisliste', { item });
      }
    }
  }

  onSlettArbeidsliste = ({ id }) => {
    console.log(id);
    validerSlettArbeidsliste(id, (err, res) => {
      if (res === null || res.length === 0) {
        // No warnings, just delete it
        this.onSlettArbeidslisteValidert({ id });
      } else {
        // Warnings, confirm deletetion

        // Listen er ferdig, delte uansett Warning
        if (this.state.ferdigCheckSlett) {
          this.onSlettArbeidslisteValidert({ id });
        } else {
          this.dialogs.setOpenDialog('OkValiderSlettArbeidsliste', {
            message: res,
            id: id,
          });
        }
      }
    });
  };

  onSlettArbeidslisteValidert = ({ id }) => {
    deleteArbeidsliste(id, this.afterUpdate);
    this.dialogs.closeDialog();
  };

  onToggleActiveStatus = ( arbeidsliste ) => {  
     promisifyApiCall(getArblisteBrukenheterPlanlegging)(arbeidsliste.id).then((liste) => {
      if (liste.length === 0) {
        this.dialogs.setOpenDialog('AktivereIkkeAlleVarslet');
        return;
      };

      let test = liste.find( (x) =>
          x.varselstatusId < 2 ||
          (x.varselstatusId > 7 && x.varselstatusId < 12) ||
          x.varselstatusId === 13 ||
          x.varselstatusId === 14 ||
          x.varselstatusId === 16
      );

      if (test !== undefined) {
        this.dialogs.setOpenDialog('AktivereIkkeAlleVarslet');
      } else {
       
        console.group('TOGGLEX');
        console.log( liste  );
        console.log( arbeidsliste  );
        console.groupEnd();
        let aktiv = !arbeidsliste.aktiv;
        this.goToggleArbeidslisteAktiv(arbeidsliste.id, aktiv);
      };
    }); 
  };

  goToggleArbeidslisteAktiv = (id, aktiv) => {
    updateArbeidslisteStatus(id, aktiv, this.afterUpdate); 
  };

  afterUpdate = (err, data) => {
    this.filterArbeidslister(this.state.valgtPersonId);
  };


  onRowClick = (item) => {
    //============= REACT CONTEXT BREADCRUMBS ====================
    huskBreadcrumb('planlegging_arbeidsliste_navn', item.navn);

    this.props.router.push('planlegging/' + item.id);
  };

  visKalender = () => {
    console.log(this.props.router);
    this.props.router.push('planlegging/kalender');
  };

  rowFormat = (item) => {
    if (item.ferdig === true) {
      if (item.antallBruksenheterSendtEier >= item.antallBruksenheter) {
        return { style: { backgroundColor: '#dcedc0' } };
      } else {
        return { style: { backgroundColor: '#f5d9a6' } };
      }
    }
  };

  render() {
    let z = {
      __html:
        'Det er ikke nødvendig å ta vare på arbeidslister som er ferdig gjennomført. Det tar bare unødvendig plass.',
    };
    let tooltip = <div dangerouslySetInnerHTML={z} />;

    return (
      <React.Fragment>
        <h2>Planlegging</h2>
        <hr />
        <div className='btn-toolbar'>
          <ToolbarButton
            icon='pluss'
            onClick={() => this.dialogs.setOpenDialog('NyArbeidsliste')}>
            Ny arbeidsliste
          </ToolbarButton>
          {/*         <ToolbarButton icon="kalender" onClick={ this.visKalender}>
                Kalender (test)
          </ToolbarButton>  */}
          {/*           {this.state.antallFerdig > 1 && (
            <Fragment>
            <span style={{ marginLeft: '20px', color: 'blue'}}>
              Det finnes {this.state.antallFerdig} arbeidslister som er ferdig
              gjennomført. De burde slettes.
            </span>
             <span
             className='textA'
             style={{
               marginLeft: '4px',
               color: 'blue',
               cursor: 'pointer',
               textDecoration: 'underline',
             }}>
             Les mer..
             <span style={{ zIndex: '9999' }} className='text-tooltipA'>
               {tooltip}
             </span>{' '}
           </span>
           </Fragment>
          )}
          {this.state.antallFerdig === 1 && (
            <span style={{ marginLeft: '20px', color: 'blue' }}>
              Det finnes en arbeidsliste som er ferdig
              gjennomført. Den burde slettes. Les mer..
            </span>
          )} */}
        </div>

        <p />
        <div className='form'>
          <div className='row back-norkart-grey'>
            <p />
            <div className='col-lg-4 col-sm-12 col-md-6'>
              <div className='form-group'>
                <label className='top-margin-4'>Ansvarlig&nbsp;</label>

                <select
                  className='h30 width220'
                  id='type'
                  onChange={this.handleAnsvarligChange}
                  value={
                    this.state.valgtPersonId !== 0
                      ? this.state.valgtPersonId
                      : 0
                  }>
                  <option key={'key'} value={0}>
                    Alle
                  </option>
                  {_.map(this.state.personer, function(o, i) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <p />
        <p />
        {this.state.filtrerteLister && (
          <React.Fragment>
          <ReactTable
            items={this.state.filtrerteLister}
            idProperty='id'
            showIndex={false}
            filterable={false}
            columns={this.getReactTableColumns()}
            selectableRows={false}
            onRowClick={this.onRowClick}
            responsive={true}
            rowFormat={this.rowFormat}
          />
           <div><span><img height= '30px' src={orange} alt="" /></span><span style={{ marginLeft: '8px' }}>Oppgavene på arbeidslisten er gjennomført</span></div>
           <div style={{ marginTop: '4px' }}><span><img height= '30px' src={green} alt="" /></span><span style={{ marginLeft: '8px' }}>Arbeidslisten er fullført og kan slettes</span></div>
           </React.Fragment>
        )}

        {this.dialogs.renderOpenDialog()}
       
      </React.Fragment>
    );
  }
}

export default withRouter(Arbeidslister);