import React from 'react';
import { GjSituasjon2 } from './GjSituasjon2.js';
import { GjArbeidsliste2 } from './GjArbeidsliste2.js';
import { Nokkeltall } from './Nokkeltall2.js';


// eslint-disable-next-line no-unused-vars
let doc = null;

class PdfDialog extends React.Component {
	state = {
		show: false,
		tittel: '',
		filnavn: '',
		adresse: '',
	};

	componentDidMount = () => {

		// eslint-disable-next-line default-case
		switch (this.props.rapportType) {
			case 'gjennomforingArbeidsliste':
				this.gjennomforingArbeidslisteStart();
				break;
			case 'gjennomforingSituasjon':
				this.gjennomforingSituasjonStart();
				break;
			case 'nokkeltallIntern':
				this.nokkeltallStart();
				break;
		}
	};

	//#region gjennomføring
	gjennomforingArbeidslisteStart = () => {
		this.setState({
			tittel: this.props.tittel,
			filnavn: this.rensTittel(this.props.tittel),
		});
	};

	gjennomforingArbeidslisteEksporter = () => {
		let props = {
			tittel: this.state.tittel,
			data: this.props.data,
		};
		GjArbeidsliste2(props);
	};

	gjennomforingSituasjonStart = () => {
		this.setState({
			tittel: this.props.tittel,
			adresse: this.props.adresse,
			filnavn: this.rensTittel(this.props.tittel),
		});
	};

	gjennomforingSituasjonEksporter = () => {
		let props = {
			tittel: this.state.tittel,
			adresse: this.props.adresse,
			data: this.props.data,
		};
		GjSituasjon2(props);
	};

	nokkeltallStart = () => {
		this.setState({
			tittel: this.props.tittel,
			filnavn: this.rensTittel(this.props.tittel),
		});
	};

	nokkeltallEksporter = () => {
		let props = {
			tittel: this.state.tittel,
			data: this.props.data,
		};
		Nokkeltall(props);
	};

	//#endregion

	eksporter = () => {
		// eslint-disable-next-line default-case
		switch (this.props.rapportType) {
			case 'gjennomforingArbeidsliste':
				this.gjennomforingArbeidslisteEksporter();
				break;
			case 'gjennomforingSituasjon':
				this.gjennomforingSituasjonEksporter();
				break;
			case 'nokkeltallIntern':
				this.nokkeltallEksporter();
				break;
		}
	};

	rensTittel = (str) => {
		let res = 'situasjon';
		if (str) {
			let s = str.replace(/[^\w\s]/gi, '');
			if (s) {
				s = s.replace(/ /g, '_');
			}
			return s.toLowerCase();
		}
		return res;
	};

	testNy = () => {
		GjArbeidsliste2(this.props);
	};

	onChange = (e) => {
		let s = e.target.value;
		this.setState({ tittel: s, filnavn: s });
	};

	render() {
		return (
			<div className='modal-content'>
				<div className='modal-header'>
					<button className='close' onClick={this.props.onClose} type='button'>
						<span>x</span>
						<span className='sr-only'>Lukk</span>
					</button>
					<h4 className='modal-title'>Eksporter til Pdf</h4>
				</div>
				<div
					className='modal-body'
					style={{ padding: '10px', minHeight: '150px' }}>
					<div className='form'>
						<div className='row'>
							<div className='col-sm-1'>
								<label style={{ marginTop: '6px' }}>Tittel</label>
							</div>
							<div className='col-sm-11 form-group'>
								<input
									autoFocus
									placeholder='Dokument tittel'
									maxLength='250'
									className='form-control'
									type='text'
									value={this.state.tittel}
									onChange={this.onChange}
								/>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-sm-1' />
						<div className='col-sm-2'>
							<button style={{ width: '200px'}} onClick={this.eksporter} className='btn btn-success'>
								Genererer pdf dokument
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PdfDialog;
