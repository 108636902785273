import {
    objGetDeep,
} from 'utils';


export const makeGetTiltakListe = (state) => {

    const getTiltakListe = ({ bruksenheter = false, royklop = false } = { bruksenheter: true, royklop: true }) => {
        /*
        Return *one* list of `tiltak`; from either or both tiltakLister
        - Use to get an array of items to loop or filter
        */
        const tiltakLister = objGetDeep(state, 'store.arbeidslisteTiltakLister', {});
        const tiltakListe = [].concat(
            bruksenheter ? tiltakLister.tiltakTypeBruksenhetListe : [],
            royklop ? tiltakLister.tiltakTypeRoyklopListe : [],
        ).filter(item => item);

       // debug('getTiltakListe', { tiltakListe, bruksenheter, royklop, tiltakLister });

        return tiltakListe;
    };

    return getTiltakListe;
};

export const makeGetObjektLockReasons = (state) => {

    const getObjektLockReasons = ({ bruksenhetId, royklopId }) => {
        /* 
        Helper to check if an `objekt` is locked and why.
        NOTE! `roklopId` is omittable, which is the case on `getTiltakListe({bruksenheter: true})
        - However, to make things easier, we just use one format for the `key`.
        */
        const key = `${parseInt(bruksenhetId)}-${parseInt(royklopId)}`;
        // debug('getObjektLockReasons', stateProps.store.objektLockReasons, { bruksenhetId, royklopId, key });
        return state.store.objektLockReasons[key] || false;
    };

    return getObjektLockReasons;
};