import React, { Component } from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';
// Utils
import { debugFactory } from 'utils';
const debug = debugFactory('nk:objekter:index');


let pathname = '/objekter';

class Index extends Component {

  
  
  render() {
    return (
      <div>
        <div className='row'>
          <div>
            <LinkList title='Objekter' columns={2}>
              <LinkListItem
                title='Søk på enheter'
                path={`${pathname}/EnhetssokMed`}  //path={`${pathname}/Enhetssok`}
                icon='hjem'
              />
             {/*  {norkartAdminBruker && (
                <LinkListItem
                  red={true}
                  title='Søk på enheter'
                  path={`${pathname}/EnhetssokMed`}
                  icon='hjem'
                />
              )} */}
              <LinkListItem
                title='Etabler fagdata'
                path={`${pathname}/Matrikkelenheter`}
                icon='feiing'
              />
              <LinkListItem
                title='Fiktive enheter'
                path={`${pathname}/FiktiveEnheter`}
                icon='gjennomsiktig-kartlag'
              />
{/*               {norkartAdminBruker && (
                <LinkListItem
                  red={true}
                  title='Pivot'
                  path={`${pathname}/Pivot`}
                  icon='analyse'
                />
              )} */}
            </LinkList>
          </div>
        </div>
      </div>
    );
  }
}

export default Index ;

