import React from 'react';
import { getInnbyggerdialogAvvikByAvvikId, getInnbyggerdialogByBruksenhetId } from 'api/innbyggerDialogApi';
import moment from 'moment';
import NKIcon from 'nka-icons';
 
class DialogHistorikkAvvik extends React.Component {
    constructor(props) {
    super(props);
    this.state = { id: 0, liste: [] };
    this.onGotDialogData = this.onGotDialogData.bind(this);
    this.formatAvsender= this.formatAvsender.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatEier = this.formatEier.bind(this);
    this.formatHtml = this.formatHtml.bind(this);
  }

componentDidMount() {
   if (this.props.avvikId > 0) {
    getInnbyggerdialogAvvikByAvvikId(this.props.avvikId, this.onGotDialogData);
   }
   if (this.props.enhetId > 0) {
    getInnbyggerdialogByBruksenhetId(this.props.avvikId, this.onGotDialogData);
   }
}

onGotDialogData(err, liste) {
  console.log('Liste');
  console.log(liste);
  liste = liste.sort(function(a, b){return new Date(b.datoPublisert) - new Date(a.datoPublisert);});
  this.setState({ liste: liste });
}

formatDate(date) {
  return moment(date).format('DD.MM.YYYY') ;
}

formatAvsender(avsender) {
   let result = 'Fra innbygger';
   if (avsender.toLowerCase() === 'bf') {
       result = 'Fra brannvesenet';
   }
   return result ;
}

formatEier(eier) {
   let result = 'ukjent';
   if (eier !== null) {
       result = eier;
   }
   return result ;
}

formatHtml(tekst) {
    return { __html: tekst  };
}

render() {
   return (
    <div>
        <br/>
        <div className="maxHeight600Scroll">
                    
            {this.state.liste.map(function(dialog) { 
                 
                var dId = 'div' + dialog.dialogId;
                var bId = 'b' + dialog.dialogId;
                var panelId = 'pan' + dialog.dialogId;
                var colId = 'collapse' + dialog.dialogId;
                var colIdx = '#collapse' + dialog.dialogId;
                let ikon = 'hjem';
                if (dialog.avsender === 'BF') { ikon = 'kommune'; } 
                return (
                    
                    <div  key={dId}>

                        <button key={bId} className="btn btn-default arrow-toggle panel-enhet-dialog collapsed"
                                data-toggle="collapse"
                                data-target={colIdx}>
                            <table className="table100">  
                            <tbody>
                                <tr>
                                    <td className="tdSpace20">  
                                        <NKIcon
                                            className="pull-left"
                                            icon={ ikon }
                                            size="1"
                                            color="nk-black"
                                        />
                                    </td>
                                    <td className="tdSpace0 pull-left">{this.formatAvsender( dialog.avsender )}</td>

                                    <td className="tdSpace pull-left">{this.formatDate( dialog.datoPublisert )}</td>
                                    
                                    {/* <td className="tdSpace pull-left">{dialog.beskrivelse}</td>  */}

                                    <td className="tdwidth30">
                                        <NKIcon
                                            className="pull-right icon-arrow-up"
                                            icon="ned"
                                            size="1"
                                            color="nk-black"
                                        />
                                        <NKIcon
                                            className="pull-right icon-arrow-down"
                                            icon="opp"
                                            size="1"
                                            color="nk-black"  
                                        />
                                    </td>   
                                </tr>
                                </tbody>
                            </table>
                        </button>   
                        <br/>
                        <div key={panelId} id= {colId} className="collapsPanel collapse margin-left-10 margin-right-10">
                            {/* <label className="margin-left-5">Tekst</label>    */}
                            <div className="margin-left-5">
                                <div  >
                                    <p dangerouslySetInnerHTML= {this.formatHtml(dialog.tekst)}></p>  
                                    <label>Kontakt</label><br/>
                                    {this.formatEier( dialog.korrespondanseNavn )}
                                    <br/><br/>
                                    <label>Saksnummer</label><br/>
                                    {dialog.saksnummer }
                                </div>                                    
                            </div> 
                            <br/>
                        </div>
                    </div>
                );
                }, this)}
        </div>
    </div>
    );
  }
}

export default DialogHistorikkAvvik;