import React from 'react';
import Debug from 'debug';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import {
    assert,
    objEnsureDeep,
} from 'utils';

const debug = Debug('nk:lib:enhancers');

const withModalContainer = (Component) => ({ modalProps = {}, ...props }) => {
    objEnsureDeep(modalProps, 'isOpen', false);
    assert(modalProps, '\'withModalContainer\'-enhancer needs `props.modalProps`');
    debug('withModalContainer:render', { modalProps, ...props });
    return (
        <ModalContainer {...modalProps}>
            <Component {...props} />
        </ModalContainer>
    );
};

export default withModalContainer;