import React from 'react';
import styled from 'styled-components';

export const Spinner = () => (
    <div data-label="spinner">
        <div className="loader">Laster...</div>
    </div>
)

const Styled = styled(Spinner).attrs({
    className: 'spinner h-100'
})``;


export default Styled;