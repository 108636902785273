import callApi from './api';


//Områder
var getAlleOmrader = function(callback) {
    callApi('GET', '/omrade/', null, callback);
};

var updateOmrade = function(id, omrade, callback) {
    callApi('PUT', '/omrade/'+ id, omrade, callback);
};

var createOmrade = function(omrade, callback) {
    callApi('POST', '/omrade/', omrade, callback);
};

var deleteOmrade = function(id, callback) {
    callApi('DELETE', '/omrade/'+ id, null, callback);
};

//Soner
var createSone = function(sone, callback) {
    callApi('POST', '/omradesone/', sone, callback);
};

var updateSone = function(id, sone, callback) {
    callApi('PUT', '/omradesone/'+ id, sone, callback);
};

var deleteSone = function(id, callback) {
    callApi('DELETE', '/omradesone/'+ id, null, callback);
};

var getAlleSoner = function(callback) {
    callApi('GET', '/omradesone/', null, callback);
};

export default { 
    getAlleOmrader,
    createOmrade,
    updateOmrade,
    deleteOmrade,
    getAlleSoner,
    createSone,
    updateSone,
    deleteSone
};

export {
    getAlleOmrader,
    createOmrade,
    updateOmrade,
    deleteOmrade,
    getAlleSoner,
    createSone,
    updateSone,
    deleteSone
};