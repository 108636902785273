import React, { useState, useEffect, createContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import './dialogs.css';

const OkDialog = ({
  tittel,
  tekst,
  tekst2,
  isOpen,
  handleClose
}) => {

/* const Test = () => {
    return <label>Test</label>;
}; */

  return (
    <Dialog
      open={isOpen}
/*       sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          m: 10,
          top: 100,
          left: 200,
        },
      }} */
      onClose={handleClose}>
      <DialogTitle>
        <span style={{ fontSize: '16px', paddingTop: '24px' }}>{tittel}</span>

      </DialogTitle>
      <DialogContent style={{ width: '400px' }}>
        <DialogContentText style={{ fontSize: '16px' }}>
          {tekst}
          <br /> 
          {tekst2}
        </DialogContentText>
      </DialogContent>
      
      <DialogActions style={{ padding: '0px', borderTop: '1px solid #51a026' }}>
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose('ok')}
          buttontype='primary'
          autoFocus>
          <span>OK</span>
        </NkaButton>
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose(null)}
          buttontype='default'>
          Avbryt
        </NkaButton>
      </DialogActions>
    </Dialog>
  );
};

export default OkDialog;

