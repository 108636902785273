import React, { useState, useEffect, createContext } from 'react';
import TestComponent1 from './TestComponent1.js';
import TestComponent2 from './TestComponent2.js';

function TestFunc3() {
  const UserContext = createContext();
  const [sted, setSted] = useState('Oslo');
  const [user, setUser] = useState('Olav');

  useEffect(() => {
    console.log('XXXX ______________useEffect_____________________');
  });

  const onStedChange = (extra, evt) => {
    console.log('XXXX extra', extra);
    console.log('XXXX', evt.target.value);
    setSted(evt.target.value);
  };

  return (
    <UserContext.Provider value={user}>
      <div style={{ marginLeft: '24px' }}>
        <h3>Test funct.comp. Sammenstille flere componenter</h3>
        <br />
        <br />
        <TestComponent1
          user={user}
          sted={sted}
          tittel='Ferieplaner?'
          handleStedChange={onStedChange}></TestComponent1>
        <br />
        <br />
        <TestComponent2 tekst={sted}></TestComponent2>
      </div>
    </UserContext.Provider>
  );
}

export default TestFunc3;
