import React, { Component } from 'react';
import ReactTable from '@norkart/react-table';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import { isPersonNorkartAdmin } from 'context/contextInnstillinger.js';
import NKIcon from 'nka-icons';
import { oppdaterMal, nyMal } from 'api/malerApi';
import { promisifyApiCall } from 'utils';
import ContextMenu from 'components/ContextMenu';
import './mal.css';
//import sidemannskontroll from 'images/Annet/SMK45.png';
import sidemannskontroll from 'images/Annet/bruker_sidemann.png';
import NKModal from 'components/Dialogs/NKModal.jsx';
import Forhandsvisning from 'components/Editor/Forhandsvisning.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Trenges ikke å endre'
      melding='Malen inneholder ikke Calibri-skrift'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  OldDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Tittelen slutter på "_old"'
      melding='Du må endre tittelen før malen evt. kan sjekkes for Calibri.'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  ForhandsvisningDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <Forhandsvisning
        onClose={onClose}
        model={dialogData.model}
        //visToppTekstButton= {false}
      />
    </NKModal>
  ),
};

class MalTable extends Component {
  constructor(props) {
    super(props);
    this.state = { id: 0 };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {}

  makeToolTip = (s) => {
    return { __html: s };
  };

  /*   forhandsvisning = (mal) => {
    this.dialogs.setOpenDialog('ForhandsvisningDialog', {
      model: mal.tekst,
    });
  }; */

  EndsWith = (str, suffix) => {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  };

  TilOpenSans = (orgMal, font) => {
    if (
      orgMal.tekst.includes('Calibri') ||
      orgMal.tekst.includes('font-family: undefined')
    ) {
      if (this.EndsWith(orgMal.tittel, '_old')) {
        this.dialogs.setOpenDialog('OldDialog');
        return;
      }
      var nyGMal = _.cloneDeep(orgMal);
      nyGMal.id = 0;
      nyGMal.tittel = orgMal.tittel + '_old';
      delete nyGMal.striptekst;
      promisifyApiCall(nyMal)(nyGMal).then(() => {
        this.ErstattCalibriOrgMal(orgMal, font);
      });
    } else {
      this.dialogs.setOpenDialog('OkDialog');
    }
  };

  ErstattCalibriOrgMal = (orgMal, font) => {
    orgMal.tekst = orgMal.tekst.replaceAll(
      'font-family: undefined;',
      'font-family: ' + font + ';'
    );
    orgMal.tekst = orgMal.tekst.replaceAll(
      'font-family: Calibri;',
      'font-family: ' + font + ';'
    );
    orgMal.tekst = orgMal.tekst.replaceAll(
      'font-size: undefined;',
      'font-size: 16px;'
    );
    promisifyApiCall(oppdaterMal)(orgMal.id, orgMal).then(() => {
      this.props.oppdaterListe();
    });
  };

  getColumns = () => {
    let col;
    let m = this.props.modul;
    switch (m) {
      case 'P':
      case 'G':
        col = this.getColumnsPG();
        break;
      case 'O':
      case 'F':
        col = this.getColumnsOF();
        break;
      default:
    }
  // add SidemannsKontroll dersom ikke Planlegging og norkartAdmin
/*   if (( m !== 'P') && (isPersonNorkartAdmin)) {
    let ix = col.length - 3;
    col.splice(ix, 0, this.getColomnSmk());
  } */

    return col;
  };

  getColomnSmk = () => {
    let col = 
    {
       id: 'sidemannskontroll',
       name: <img src={sidemannskontroll} title='På denne malen skal dokumentkontroll gjennomføres' width='20px' alt='SmK' />,
       sortParams: 'sidemannskontroll',
       filterable: false,
       isSorted: false,
       sortable: true,
       formatter: function(item) {
         if (item.sidemannskontroll) {  // Må endres selvfølgelig
           return 'ja';
         } else {
           return 'nei';
         }
       }, 
   };
   return col;
  }

  getColumnsPG = () => {
    let columns = [
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let str = _.truncate(item.tittel, { length: 60 });
          return <div style={{ maxWidth: '300px' }}>{str}</div>;
        },
      },
      {
        id: 'side',
        name: '1. side',
        sortParams: 'side',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.hoveddokument) {
            return 'ja';
          } else {
            return 'nei';
          }
        },
      },

      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.truncate(item.tekst, { length: 1400 });
          let z = { __html: s };
          let tooltip = <div dangerouslySetInnerHTML={z} />;

          return (
            <span className='text'>
              {' '}
              <NKIcon icon='kommentar' size='0.8' color='nk-black' />
              <span className='text-tooltip'>{tooltip}</span>
            </span>
          );
        },
      },
      {
        id: 'funksjon',
        name: 'Funksjon',
        sortParams: 'funksjon',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let str = '';
          let first = true;
          if (item.tiltak) {
            for (let y = 0; y < item.tiltak.length; y++) {
              let til = item.tiltak[y].tiltak;
              if (!first) {
                if (til) {
                  til = til.toLowerCase();
                }
              }
              first = false;
              str = str + til + ', ';
            }
          }
          if (str.length > 2) {
            str = str.substring(0, str.length - 2);
          }
          //return str;
          return <div style={{ maxWidth: '200px' }}>{str}</div>;
        },
      },
      {
        id: 'beskrivelse',
        name: 'Beskrivelse',
        sortParams: 'beskrivelse',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let str = _.truncate(item.beskrivelse, { length: 45 });
          return <div style={{ maxWidth: '200px' }}>{str}</div>;
        },
      },
      {
        id: 'aktiv',
        name: 'I bruk',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'ja';
          } else {
            return 'nei';
          }
        },
      },
   

      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              {/*  <ContextMenu.Item onClick={() => this.forhandsvisning(item)}>
                Vis forhåndsvisning
              </ContextMenu.Item> */}
              <ContextMenu.Item onClick={() => this.props.slettMal(item)}>
                Slett mal
              </ContextMenu.Item>
              <ContextMenu.Divider />
              <ContextMenu.Item onClick={() => this.TilOpenSans(item, 'Arial')}>
                Fra Calibri til Arial
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={() => this.TilOpenSans(item, 'Verdana')}>
                Fra Calibri til Verdana
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
    return columns;
  };

  getColumnsOF = () => {
    let columns = [
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let str = _.truncate(item.tittel, { length: 60 });
          return <div style={{ maxWidth: '300px' }}>{str}</div>;
        },
      },

      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.truncate(item.tekst, { length: 1400 });
          let z = { __html: s };
          let tooltip = <div dangerouslySetInnerHTML={z} />;

          return (
            <span className='text'>
              {' '}
              <NKIcon icon='kommentar' size='0.8' color='nk-black' />
              <span className='text-tooltip'>{tooltip}</span>
            </span>
          );
        },
      },
      {
        id: 'funksjon',
        name: 'Funksjon',
        sortParams: 'funksjon',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let str = '';
          let first = true;
          if (item.tiltak) {
            for (let y = 0; y < item.tiltak.length; y++) {
              let til = item.tiltak[y].tiltak;
              if (!first) {
                if (til) {
                  til = til.toLowerCase();
                }
              }
              first = false;
              str = str + til + ', ';
            }
          }
          if (str.length > 2) {
            str = str.substring(0, str.length - 2);
          }
          //return str;
          return <div style={{ maxWidth: '200px' }}>{str}</div>;
        },
      },
      {
        id: 'beskrivelse',
        name: 'Beskrivelse',
        sortParams: 'beskrivelse',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let str = _.truncate(item.beskrivelse, { length: 45 });
          return <div style={{ maxWidth: '200px' }}>{str}</div>;
        },
      },
      {
        id: 'aktiv',
        name: 'I bruk',
        sortParams: 'aktiv',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.aktiv) {
            return 'ja';
          } else {
            return 'nei';
          }
        },
      },

      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              {/*  <ContextMenu.Item onClick={() => this.forhandsvisning(item)}>
                Vis forhåndsvisning
              </ContextMenu.Item> */}
              <ContextMenu.Item onClick={() => this.props.slettMal(item)}>
                Slett mal
              </ContextMenu.Item>
              <ContextMenu.Divider />
              <ContextMenu.Item onClick={() => this.TilOpenSans(item, 'Arial')}>
                Fra Calibri til Arial
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={() => this.TilOpenSans(item, 'Verdana')}>
                Fra Calibri til Verdana
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
    return columns;
  };

  rowClick = (row) => {
    this.props.onRowClick(row);
  };

  render() {
    return (
      <div>
        <ReactTable
          toggleAllDefault={false}
          showIndex={false}
          columns={this.getColumns()}
          items={this.props.items}
          selectableRows={false}
          onRowClick={(row) => this.rowClick(row)}
        />

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default MalTable;
