import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index.jsx';
import MineOppgaverKlargjorRapport from './MineOppgaverKlargjorRapport';
import MineOppgaverUnderBehandling from './MineOppgaverUnderBehandling';
import MineOppgaverUnderBehandlingSmk from './MineOppgaverUnderBehandlingSmk';
import MineOppgaverIkkePublisert from './MineOppgaverIkkePublisert';
import { enhanceComponents } from 'utils';


const enhanced = enhanceComponents({
    Index,
    MineOppgaverKlargjorRapport,
    MineOppgaverIkkePublisert,
    MineOppgaverUnderBehandling,
    MineOppgaverUnderBehandlingSmk
}, [
        asRouteComponent,
    ]);

export default (
 
    <Route path="oppfolging" component={enhanced.Index} name="Oppfølging" icon="kopier" inMenu={true} login={true} roles={[]}>
        <Route path="ub/:AvvikId" component={enhanced.MineOppgaverUnderBehandling} name="Under behandling" />
        <Route path="ubsmk/:AvvikId" component={enhanced.MineOppgaverUnderBehandlingSmk} name="Under behandling" />
        <Route path="ib/:BrId" component={enhanced.MineOppgaverIkkePublisert} name="Ikke behandlet" />
        <Route path="kr/:Id" component={enhanced.MineOppgaverKlargjorRapport} name="Klargjør rapport" />
    </Route >
);
