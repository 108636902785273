import * as actionTypes from './actionTypes';
import { merge } from 'lodash';
import { constructorFactory } from 'utils';

const initialState = {
  personer: [],
  brukere: {},
  tiltakstyper: [],
  arbeidslister: [],
  arbeidslisteEnheter: [],
  arbeidslisteTiltakLister: {},
  arbeidslisteTiltakLister_storage: {},
  objektLockReasons: {},
  selectedArbeidsliste: null,
  enheterMedEiere: [],
  enheterMedEiere2: []
};

// Since multiple reducers can hit in one go, declare all variables here by `let`
let id, props, item;

export default (state = initialState, action) => {
  switch (action.type) {
    // M I S C

    case actionTypes.ARBEIDSLISTER_FETCHED:
      return {
        ...state,
        arbeidslister: action.payload
      };
    case actionTypes.PERSONER_FETCHED:
      return {
        ...state,
        personer: action.payload
      };
    case actionTypes.BRUKERE_FETCHED:
      return {
        ...state,
        brukere: action.payload
      };
    case actionTypes.TILTAKSTYPER_FETCHED:
      return {
        ...state,
        tiltakstyper: action.payload
      };
    case actionTypes.ARBEIDSLISTE_ENHETER_FETCHED:
      return {
        ...state,
        arbeidslisteEnheter: action.payload
      };
    case actionTypes.ENHETER_MED_EIERE_FETCHED:
      return {
        ...state,
        enheterMedEiere: action.payload
      };
    case actionTypes.ENHETER_MED_EIERE2_FETCHED:
      return {
        ...state,
        enheterMedEiere2: action.payload
      };

    // A R B E I D S L I S T E

    case actionTypes.ARBEIDSLISTE_SELECTED:
      return {
        ...state,
        selectedArbeidsliste: action.payload
      };
    case actionTypes.UPDATE_ARBEIDSLISTE:
      ({ id, props } = action.payload);
      return {
        ...state,
        arbeidslister: state.arbeidslister.map(item =>
          item.id === parseInt(id) ? Object.assign(item, props) : item
        )
      };
    case actionTypes.REMOVE_ARBEIDSLISTE:
      ({ id } = action.payload);
      return {
        ...state,
        arbeidslister: state.arbeidslister.filter(item => item.id !== id)
      };
    case actionTypes.ADD_NEW_ARBEIDSLISTE:
      ({ item } = action.payload);
      return {
        ...state,
        arbeidslister: [item].concat(state.arbeidslister)
      };
    case actionTypes.INITIALIZE_LEGG_TIL_OBJEKTER:
      return {
        ...state,
        arbeidslisteTiltakLister: constructorFactory(
          initialState.arbeidslisteTiltakLister
        ),
        arbeidslisteTiltakLister_storage: constructorFactory(
          initialState.arbeidslisteTiltakLister
        ),
        objektLockReasons: constructorFactory(initialState.objektLockReasons)
      };

    // A R B E I D S L I S T E _ T I L T A K _ L I S T E R

    case actionTypes.ARBEIDSLISTE_TILTAKSLISTER_FETCHED:
      return {
        ...state,
        arbeidslisteTiltakLister: action.payload
      };
    case actionTypes.UPDATE_OBJEKT_LOCK_REASONS:
      return {
        ...state,
        objektLockReasons: merge(state.objektLockReasons, action.payload)
      };
    case actionTypes.SET_OBJEKT_LOCK_REASONS:
      return {
        ...state,
        objektLockReasons: action.payload
      };

    case actionTypes.SET_ARBEIDSLISTE_TILTAKSLISTER:
      return {
        ...state,
        arbeidslisteTiltakLister: {
          ...state.arbeidslisteTiltakLister,
          ...action.payload
        }
      };

    // Default: entire state-tree
    default:
      return state;
  }
};
