import callApi from './api';

//Hent kontaktopplysninger fra arbeidsliste
var getKontaktInfo = (BruksenhetIdDto, callback) => {
    callApi('POST', '/kontakt/eierkontakt', BruksenhetIdDto, callback); 
};

//Hent kontaktopplysninger fra arbeidsliste
var getKontakterForEnhetInfo = (BruksenhetIdDto,  callback) => {
    callApi('POST', '/kontakt/bruksenhet', BruksenhetIdDto, callback); 
};


var getBruksenhetKontakter = (BruksenhetIdDto, callback) => {    //kun i bruk på 1 plass
    callApi('POST', '/kontakt/bruksenhet', BruksenhetIdDto, callback);
};

var getBruksenhetKontakter2 = (BruksenhetIdDto, callback) => {   //i bruk
    callApi('POST', '/kontakt/bruksenhet2', BruksenhetIdDto, callback);
};

var getBruksenhetStatus = (BruksenhetIdDto, callback) => {   //i bruk
    callApi('POST', '/kontakt/bruksenhetstatus', BruksenhetIdDto, callback);
};


/* Sendes med: objekt med id fag og matrikkel bruksenhet id. arbeidslisteid sendes med om skal ha sjekk om kontakt er varslet
 long BruksenhetIdFag 
 long BruksenhetIdMa 
 long ArbeidslisteId  
 returnerer også  public bool Varslet { get; set; }*/
var getBruksenhetKontakter3 = (BruksenhetIdDto, callback) => {
    callApi('POST', '/kontakt/bruksenhet3', BruksenhetIdDto, callback);
};

export default {
    getKontaktInfo,
    getKontakterForEnhetInfo,
    getBruksenhetKontakter,
    getBruksenhetKontakter2,
    getBruksenhetKontakter3,
    getBruksenhetStatus
};

export {
    getKontaktInfo,
    getKontakterForEnhetInfo,
    getBruksenhetKontakter,
    getBruksenhetKontakter2,
    getBruksenhetKontakter3,
    getBruksenhetStatus
};

