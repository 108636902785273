import React from 'react';
import NKModal from './NKModal.jsx';
import NKIcon from 'nka-icons';

class SmsSkarpAdvarselDialog extends React.Component {
  state = { isOpen: true };

  render() {
    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              onClick={this.props.onClose}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Send SMS</h4>
          </div>
          <div className="modal-body">
            <p>
              <NKIcon
                icon="advarsel"
                className="pull-left"
                size="2"
                color="nk-black"
              />

              <label>
                &nbsp;&nbsp;&nbsp;&nbsp;Ved å trykke OK sender du SMS til reelle
                personer.
              </label>
              <br />
              <label>
                &nbsp;&nbsp;&nbsp;&nbsp;Lukk vinduet uten å trykke OK hvis dette
                ikke var hensikten.
              </label>
            </p>
          </div>
         
            <div className="modal-footer">
              <button
                style={{ margin: '2px', width: '50px', height: '40px' }}
                className="btn btn-primary pull-right"
                onClick={this.props.onOk}>
                OK
              </button>
            </div>
        </div>
      </NKModal>
    );
  }
}

export default SmsSkarpAdvarselDialog;
