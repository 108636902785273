/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SmsEditor from 'components/Editor/SmsEditor.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import { getSmsKanselleringMal } from 'api/malerApi';
import SmsSkarpAdvarselDialog from 'components/Dialogs/SmsSkarpAdvarselDialog.jsx';
import { postVarslingKanseller, postVarslingFjern } from 'api/enhetApi';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:KansellerDialog');
//kopier: import HurtigdelDialog from './KansellerDialog.jsx';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  SkarpDialogKanseller: ({ isOpen, onClose, dialogData, owner }) => (
    <SmsSkarpAdvarselDialog
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose(() => owner.okSendKanseller(dialogData.liste))}
    />
  ),
  SkarpDialogFjern: ({ isOpen, onClose, dialogData, owner }) => (
    <SmsSkarpAdvarselDialog
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose(() => owner.okSendFjern(dialogData.obj))}
    />
  )
};

class KansellerDialog extends Component {
  constructor(props) {
    super(props);
    this.smseditorRef = null;
    this.state = {
      valgteEnheter: [],
      manuellKansellering: false,
      enheterMedEiere: [],
      mal: null,
      flettefelt: [],
      editortext: '',
      tittel: '..',
      ekstraTittel: '',
      knapp: 'Kanseller',
      infoTekst1: '..',
      infoTekst2: '..',
      infoTekst3: '',
      checkTekst: '..',
      bareSvarUt: false
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    switch (this.props.modus) {
      case 'kanseller':
        this.setState({ tittel: 'Kanseller' });
        this.setState({
          infoTekst1:
            'Du har valgt å kansellere besøk i en eller flere enheter.'
        });
        this.setState({
          infoTekst2:
            'Bruk/endre teksten nedenfor dersom du ønsker å sende en SMS om kansellering av besøket.'
        });
        this.setState({ knapp: 'Kanseller' });
        this.setState({ checkTekst: 'Jeg ønsker IKKE å sende ut en SMS.' });
        break;
      case 'kansellerBareSvarut':
        this.setState({ tittel: 'Kanseller' });
        this.setState({
          infoTekst1:
            'Du har valgt å kansellere besøk i en eller flere enheter. Alle er varslet via Svar ut.'
        });
        this.setState({
          infoTekst2:
            'Bruk/endre teksten nedenfor dersom du ønsker å sende en SMS om kansellering av besøket.'
        });
        this.setState({ bareSvarUt: true });
        this.setState({ knapp: 'Kanseller' });
        this.setState({ checkTekst: 'Jeg ønsker IKKE å sende ut en SMS.' });
        break;
      case 'fjern':
        this.setState({ tittel: 'Fjern' });
        this.setState({
          infoTekst1:
            'Du har valgt å fjerne en enhet fra arbeidslisten. Klikk på "Fjern" for å fjerne.'
        });
        this.setState({
          infoTekst2:
            'Bruk/endre teksten nedenfor dersom du ønsker å sende en SMS om kansellering av besøket.'
        });
        this.setState({ knapp: 'Fjern' });
        this.setState({ checkTekst: 'Jeg ønsker IKKE å sende ut en SMS.' });
        if (this.props.valgteEnheter[0].oppgaver === 'UnderArbeid') {
          this.setState({ infoTekst3: 'Arbeid er påbegynt.' });
        }
        break;
      default:
        this.setState({ tittel: 'Kanseller' });
    }

    getSmsKanselleringMal(this.gotMal);
    this.makeDtoListe();
  }

  makeDtoListe = () => {
    let liste = this.props.valgteEnheter;
    let valgteEnheter = [];   
    for (let i = 0; i < liste.length; i++) {
      let e = liste[i];
      let utelat = false;
      console.log(e.varselstatus);
      if (e.varselstatus == 'SvarUt sender' || e.varselstatus == 'SvarUt sendt') { utelat = true;}
      let obj = {
        bruksenhetId: e.id,
        bruksenhetIdMa: e.bruksenhetIdMa,
        arbeidslisteId: this.props.arbeidsliste.id,
        arbeidslisteIdFlytt: 0,
        matrikkelEnhetId: e.matrikkelenhetId,
        tiltaktyper: this.makeTiltak(e),
        varselDato: e.planlagtDato,
        varselstatusId: e.varselstatusId,
        besokFra: e.planlagtBesokFra ? e.planlagtBesokFra : '',
        besokTil: e.planlagtBesokTil ? e.planlagtBesokTil : '',
        adresse: e.adresse,
        ingenValidering: true,
        smsTekst: '',
        smsUtelat: utelat
      };
      valgteEnheter.push(obj);
    }
    this.setState({ valgteEnheter });

    if (this.props.modus === 'kanseller') {
      let antall = valgteEnheter.length;
      let ekstraTittel = '( ' + antall + ' enhet )';
      if (antall > 1) {
        ekstraTittel = '( ' + antall + ' enheter )';
      }
      this.setState({ ekstraTittel });
    }
    if (this.props.modus === 'fjern') {
      let adresse = valgteEnheter[0].adresse;
      let tittel = 'Fjern "' + adresse + '" fra arbeidslisten.';
      this.setState({ tittel });
    }
  };

  gotMal = (err, data) => {
    this.setState({ flettefelt: data.flettefelt, editortext: data.mal.tekst });
  };

  makeTiltak = enhet => {
    let s = '';
    if (enhet.tiltakBruksenhet) {
      s = enhet.tiltakBruksenhet.trim() + ',';
    }
    if (enhet.tiltakRoyklop) {
      s = s + enhet.tiltakRoyklop.trim();
    }
    if (s.charAt(s.length - 1) === ',') {
      s = s.substr(0, s.length - 1);
    }
    s = s.replace(' ', '');
    s = s.replace(',', ', ');
    return s;
  };

  toggleManuell = () => {
    this.setState({ manuellKansellering: !this.state.manuellKansellering });
  };

  getEditorTekst = () => {
    let s = '';
    let editor = this.smseditorRef;
    if (editor) {
      s = editor.getMaltekst();
    }
    return s;
  };

  submit = () => {
    switch (this.props.modus) {
      case 'kanseller':
        this.kanseller();
        break;
        case 'kansellerBareSvarut':
          this.kanseller();
          break;
      case 'fjern':
        this.fjern();
        break;
      default:
    }
  };

  fjern = () => {
    console.log(this.state.manuellKansellering);
    if (this.state.manuellKansellering) {
      let obj = this.state.valgteEnheter[0];
      obj.varselKanseller = !this.state.manuellKansellering;
      obj.ingenValidering = false;
      obj.smsUtelat = true;
      postVarslingFjern(obj, this.fjernIngenSms);
    } else {
      this.setState({ sendInProgress: true });
      let editorText = this.getEditorTekst();
      if (editorText === 'ERROR') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'For mange tegn i sms',
        melding:
          'For å unngå feil ved sending av sms og for å unngå ekstra kostnader håndterer vi en begrensing av 500 tegn.'
      });
        return;
      }
      let obj = this.state.valgteEnheter[0];
      obj.smsTekst = editorText;
      obj.varselKanseller = !this.state.manuellKansellering;
      obj.ingenValidering = false;
      if (obj) {
        this.fortsettFjern(obj);
      }
    }
  };

  fjernIngenSms = (err, res) => {
    this.props.refreshArbeidsliste();
  }

  fortsettFjern = obj => {
    let s = '';
    if (!obj.adresse){
      s= 'Adresse er ikke definert. ';
    } else {
      if (obj.adresse === ''){
        s='Adresse er tomt. ';
      }
    };
    if (s !== ''){
      this.setState({ sendInProgress: false });
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Enheten kan ikke fjernes..',
        melding: 'Merkelig feil 1A: ' + s + ' Ta kontakt med brukerstøtte.' 
      });
      return;
    } else {
     
      if (this.state.manuellKansellering) {
        this.okSendFjern(obj);
      } else {
        this.dialogs.setOpenDialog('SkarpDialogFjern', {
          obj: obj
        });
      }
      
    }
    
  };

  okSendFjern = (obj) => {
    this.setState({ sendInProgress: true });
    postVarslingFjern(obj, this.sendSmsFjernHarBlittSend); 
  };

  sendSmsFjernHarBlittSend = (err, melding) => {
    this.setState({ sendInProgress: false }, this.props.refreshArbeidslisteFjern(melding));
  };

  kanseller = () => {
    let liste = [];
    if (this.state.manuellKansellering || this.state.bareSvarUt){
      for (let i = 0; i < this.state.valgteEnheter.length; i++) {
        let obj = this.state.valgteEnheter[i];
        obj.smsUtelat = true;
        obj.smsTekst = '';
        liste.push(obj);
      }
    } else {
      let editorText = this.getEditorTekst();
      if (editorText === 'ERROR') {
        this.dialogs.setOpenDialog('OkDialog', {
          tittel: 'For mange tegn i sms',
          melding:
            'For å unngå feil ved sending av sms og for å unngå ekstra kostnader håndterer vi en begrensing av 500 tegn.'
        });
        return;
      }
      for (let i = 0; i < this.state.valgteEnheter.length; i++) {
        let obj = this.state.valgteEnheter[i];
        obj.smsTekst = editorText;
        obj.smsUtelat = obj.varselstatusId == 10 || obj.varselstatusId == 15 ? true : false;
        liste.push(obj);
      }
    }
   
    if (liste) {
      if (liste.length > 0) {
        if (this.state.manuellKansellering || this.state.bareSvarUt) {
          this.okSendKanseller(liste);
        } else {
          this.dialogs.setOpenDialog('SkarpDialogKanseller', {
            liste: liste
          });
        } 
        
      }
    }
  };

  okSendKanseller = (liste) => {
    this.setState({ sendInProgress: true });
    postVarslingKanseller(liste, this.sendSmsKansellerHarBlittSend);
  };

  sendSmsKansellerHarBlittSend = err => {
    this.setState({ sendInProgress: false }, this.props.refreshArbeidsliste());
  };

  render() {
    if (this.state.editortext === '') {
      return <div className='loader' />;
    }
    if (this.state.flettefelt === []) {
      return <div className='loader' />;
    }

    return (
      <div>
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <table>
            <tbody>
              <tr>
                <td>
                  <h4 className='modal-title'>{this.state.tittel}</h4>
                </td>
                <td>&nbsp;&nbsp;</td>
                <td>{this.state.ekstraTittel}</td>
                <td>&nbsp;&nbsp;</td>
                <td style={{ color: 'blue', fontSize: '12px' }}>
                  {this.state.infoTekst3}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* BODY */}
        <div
          className='modal-body'
          style={{
            height: '90%',
            paddingTop: '4px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '4px'
          }}>
          {this.state.bareSvarUt ? 
            <div>
              <React.Fragment>
                <p/>
              <p>{this.state.infoTekst1}</p>
              </React.Fragment>
            </div>
            :
            <div>
              <React.Fragment>
            <p>{this.state.infoTekst1}</p>
            <p>{this.state.infoTekst2}</p>
            <span style={{ marginRight: '2px' }}>
              <input
                className='checkbox30'
                type='checkbox'
                checked={this.state.manuellKansellering}
                onChange={this.toggleManuell}
              />
            </span>
            <span style={{ verticalAlign: 'top' }}>
              <label style={{ marginTop: '8px' }}>
                  {this.state.checkTekst}
              </label>
            </span>
          </React.Fragment>
          <br />
            </div>}
          
        
          {this.state.manuellKansellering || this.state.bareSvarUt ? 
            <div></div>
          : 
          <div>
            <React.Fragment>
              <p> </p>
                <SmsEditor
                  ref={el => {
                    this.smseditorRef = el;
                  }}
                  cols='70'
                  rows='6'
                  visTekstbiter= {true}
                  maltext={this.state.editortext}
                  flettefelt={this.state.flettefelt}></SmsEditor>   
            </React.Fragment>
            </div>
          }
        </div>

        <div className='malModalFooter'>
          <button onClick={this.submit} className='btn btn-primary pull-right'>
            {this.state.knapp}
          </button>
        </div>

        {this.dialogs.renderOpenDialog()}

      </div>
    );
  }
}

export default KansellerDialog;
