import React from 'react';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import enhetApi from 'api/enhetApi';
import royklopApi from 'api/royklopApi.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NyOppdaterTiltaktype from '../Tiltak/NyOppdaterTiltaktype.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';

var _ = require('underscore');
var risikoOverstyr = false;
var htekst = '';
var hyppighetListe = null;

const modalDialogs = {
  NyOppdaterTiltaktype: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" isOpen={isOpen}>
      <NyOppdaterTiltaktype
        onClose={onClose}
        statusTiltaktype={dialogData.statusTiltaktype}
        onNyEndreTiltaktypeOK={owner.onNyEndreTiltaktypeOK}
        tiltakType={dialogData.tiltakType}
        lagretTiltak={dialogData.lagretTiltak}
        type={dialogData.type}
        tiltakNavn={dialogData.tiltakNavn}
        hyppighetAntalldager={dialogData.hyppighetAntalldager}
      />
    </NKModal>
  ),
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OKDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={onClose}
      />
    );
  },
};

class NyTiltakshistorikk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      lukket: true,
      id: null,
      oppslagsdata: null,
      lagretTiltak: null,
      tiltakType: null,
      tiltakNavn: null,
      risiko: false, 
      laster: true,
      merknad: ''
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  componentDidMount() {

    this.setState({ id: this.props.id });
    if (this.props.type === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakOppslagsdataForArbOgBr(0, this.props.id, this.gotOppslagsdata);
    } else {
      royklopApi.getRoyklopTiltakOppslagsdataForArbOgBr(0, this.props.id, this.gotOppslagsdata);
    }
  }

  gotOppslagsdata = (err, data) => {
    this.setState({ oppslagsdata: data });
    risikoOverstyr = this.props.type === 'bruksenhet' ? data.bruksenhetRisikoOverstyr : data.royklopRisikoOverstyr;
    hyppighetListe = data.tiltakHyppighetListe;
    let blank = {
      fremmedNokkel: null,
      id: 0,
      tekst: '',
      tekst2: ''
    };
    hyppighetListe.unshift(blank);
    this.setDefaultData();
  };

  setDefaultData = () => {
    this.setState({
      hyppighetId: this.state.oppslagsdata.tiltakHyppighetListe[0].id
    });
    this.setState({ tiltakId: this.state.oppslagsdata.tiltakTypeListe[0].id });
    this.setState({ utfortav: this.state.oppslagsdata.tiltakPersoner[0].id });
    this.setState({ risiko: this.state.oppslagsdata.tiltakTypeListe[0].risikovurdering && !risikoOverstyr });
    // if (this.state.oppslagsdata.tiltakTypeListe[0].risikovurdering) {
    //   htekst = _.find(hyppighetListe, function(x) {
    //     return x.id == this.state.oppslagsdata.tiltakTypeListe[0].hyppighetId;
    //   }).tekst;
    // }
    if (this.props.type !== 'bruksenhet') {
      this.setState({
        sotmengde: this.state.oppslagsdata.tiltakSotmengde[0].id
      });
    }
    this.setState({ laster: false });
    this.setLukket(true);
  };

  handleTypeChange = data => {
    this.setState({
      tiltakId: data.target.value
    });
    var tiltak = _.find(this.state.oppslagsdata.tiltakTypeListe, function(x) {
      return x.id === data.target.value;
    });
    this.setState({ risiko: tiltak.risikovurdering && !risikoOverstyr });
    // if (tiltak.risikovurdering) {
    //   htekst = _.find(hyppighetListe, function(x) {
    //     return x.id == tiltak.hyppighetId;
    //   }).tekst;
    // }
  };

  handleHyppighetChange = data => {
    this.setState({
      hyppighetId: data.target.value
    });
  };

  handleUtfortavChange = data => {
    this.setState({
      utfortav: data.target.value
    });
  };

  handleDatePickerChange = date => {
    if (date == null) {
      this.dialogs.setOpenDialog('OkDialog', { tittel: 'Dato påkreves', melding: 'Dato kan ikke være blank.' });
    }
    this.setState({ selectedDate: date });
  };

  handleMerknadChange = data => {
    this.setState({
      merknad: data.target.value
    });
  };

  handleSotmengdeChange = data => {
    this.setState({ sotmengde: data.target.value });
  };

  lukketChange = () => {
    let lukket = !this.state.lukket;
    this.setLukket(lukket);
  };

  setLukket = lukket => {
    if (lukket) {
      this.setState({
        lukket: true,
        lukketText: 'Utført'
      });
    } else {
      this.setState({
        lukket: false,
        lukketText: 'Ikke utført'
      });
    }
    this.setState({ utfort: lukket });
  };

  onLagreClick = () => {
    if (this.state.selectedDate == null) {
      this.dialogs.setOpenDialog('OkDialog', { tittel: 'Dato påkreves', melding: 'Dato kan ikke være blank.' });
      return;
    }
    
    var hid = this.state.hyppighetId;
    var hyppighet = _.find(
      this.state.oppslagsdata.tiltakHyppighetListe,
      function(tiltak) {
        return tiltak.id === hid;
      }
    );
    this.setState({
      hyppighetAntalldager: hyppighet !== undefined ? hyppighet.tekst2 : null
    });
    var tid = this.state.tiltakId;
    var tiltak = _.find(this.state.oppslagsdata.tiltakTypeListe, function(
      tiltak
    ) {
      return tiltak.id === tid;
    });
    this.setState({ tiltakNavn: tiltak.tekst, risikovurdering: tiltak.risikovurdering });

    if (this.props.type === 'bruksenhet') {
      var nyttTiltak = {
        bruksenhetId: this.state.id,
        dato: new Date(this.state.selectedDate),
        tiltakTypeId: this.state.tiltakId,
        utfort: this.state.utfort,
        arsakIkkeUtfortId: '',
        hyppighetId: this.state.hyppighetId === 0 || this.state.hyppighetId === undefined || this.state.hyppighetId === '0' ? null : this.state.hyppighetId, // Constraint feil hvis 0
        utfortAvId: this.state.utfortav,
        merknad: this.state.merknad,
        arbeidslisteId: '',
        arbeidslisteInfo: ''
      };
      enhetApi.saveTiltakBruksenhet(
        nyttTiltak,
        this.tiltakLagret
      );
    } else {
      nyttTiltak = {
        royklopId: this.state.id,
        dato: new Date(this.state.selectedDate),
        tiltakTypeId: this.state.tiltakId,
        utfort: this.state.utfort,
        arsakIkkeUtfortId: '',
        hyppighetId: this.state.hyppighetId === 0 || this.state.hyppighetId === undefined || this.state.hyppighetId === '0' ? null : this.state.hyppighetId,
        utfortAvId: this.state.utfortav,
        merknad: this.state.merknad,
        arbeidslisteId: '',
        arbeidslisteInfo: '',
        sotmengdeId: this.state.sotmengde
      };
      royklopApi.saveTiltakRoyklop(
        nyttTiltak,
        this.tiltakLagret
      );
    }
  };

  tiltakLagret = (err, tiltak) => {
    //var tiltakliste = this.state.oppslagsdata.tiltakTypeListe;
    
    console.log(tiltak);
    console.log(this.state.oppslagsdata.tiltakTypeListe);
    this.setState({ lagretTiltak: tiltak });
    if (this.props.type === 'bruksenhet') {
      var nyTiltaktype = {
        bruksenhetid: tiltak.bruksenhetId,
        tiltaktypeid: tiltak.tiltakTypeId,
        hyppighetid: tiltak.hyppighetId,
        nestedato: this.state.risiko ? null : moment().add(this.state.hyppighetAntalldager, 'days').format('YYYY-MM-DDTHH:00:00'),
        sistedato: null,
        sisteStatus: false,
        risikovurdering: this.state.risiko
      };
      enhetApi.nyOppdaterBruksenhetTiltakType(nyTiltaktype, this.tiltakTypeSjekket);
    } else {
        nyTiltaktype = {
        royklopId: tiltak.royklopId,
        tiltaktypeid: tiltak.tiltakTypeId,
        hyppighetid: tiltak.hyppighetId,
        nestedato: this.state.risiko ? null : moment().add(this.state.hyppighetAntalldager, 'days').format('YYYY-MM-DDTHH:00:00'),
        sistedato: null,
        sisteStatus: false,
        risikovurdering: this.state.risiko
      };
      royklopApi.nyOppdaterRoyklopTiltakType(nyTiltaktype, this.tiltakTypeSjekket);
    }
  }

  tiltakTypeSjekket = (err, enhettiltaktype) => {
    console.log(enhettiltaktype);
   
    this.props.onCloseModal(this.state.tiltakNavn, enhettiltaktype, this.state.risiko);

  };


  onNyEndreTiltaktypeOK = () => {
    this.dialogs.closeDialog();
    this.props.onCloseModal();
  };

  closeNyEndreTiltaktype = () => {
    this.dialogs.closeDialog();
    this.props.onCloseModal();
  };

  render() {
    if (this.state.laster) {
      return <div className="loader" />;
    }
    return (
      <div className="margin-top-15">
        <div>
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Type tiltak</label>
                <select
                  className="form-control"
                  id="type"
                  onChange={this.handleTypeChange}>
                  {_.map(this.state.oppslagsdata.tiltakTypeListe, function(
                    o,
                    i
                  ) {
                    return (
                      <option key={i} value={o.id}>
                        {o.tekst}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Hyppighet</label>
                {this.state.risiko ? 
                <div>
                  <input
                          className="form-control"
                          type="text"
                          id="message"
                          readOnly
                          value={htekst}
                        />
                </div> : 
                <div><select
                className="form-control"
                id="type"
                onChange={this.handleHyppighetChange}>
                {_.map(this.state.oppslagsdata.tiltakHyppighetListe, function(
                  o,
                  i
                ) {
                  return (
                    <option key={i} value={o.id}>
                      {o.tekst}
                    </option>
                  );
                })}
              </select>
              </div>}
                
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Dato</label>
                <br />
                <DatePicker
                  className="hoyde34 width120"
                  placeholderText="Dato"
                  selected={this.state.selectedDate}
                  startDate={moment(Date.now())}
                  onChange={this.handleDatePickerChange}
                  todayButton={'I dag'}
                  filterDate={this.isWeekday}
                  isClearable={false}
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          {this.state.risiko ? 
            <div className="row">
              <div className="col-sm-12">
              <div className="form-group margin-left-10">
              Valgt tiltakstype er tilknyttet risikovurdering. Hvis det er ønskelig å overstyre risikovurderingen kan dette gjøres på objektet.
                    </div>
              </div>
            </div> : 
            <div></div>
          }
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Utført av</label>
                <select
                  className="form-control"
                  id="type"
                  onChange={this.handleUtfortavChange}
                  defaultValue={this.state.oppslagsdata.tiltakPersoner[0].id}>
                  {_.map(this.state.oppslagsdata.tiltakPersoner, function(
                    o,
                    i
                  ) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group margin-left-10 margin-right-15">
                <br />
                <Switch
                  className="pull-left"
                  on={this.state.lukket}
                  onClick={this.lukketChange}
                />
                <label className="lukketText">{this.state.lukketText}</label>
              </div>
            </div>
            {this.props.type !== 'bruksenhet' ? (
              <div className="col-sm-4">
                <div className="form-group margin-left-10 margin-right-15">
                  <label>Sotmengde</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.handleSotmengdeChange}
                    defaultValue={
                      this.state.oppslagsdata.tiltakSotmengde[0].id
                    }>
                    {_.map(this.state.oppslagsdata.tiltakSotmengde, function(
                      o,
                      i
                    ) {
                      return (
                        <option key={i} value={o.id}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group margin-left-10 margin-right-15">
                <label>Merknad</label>
                <textarea
                  className="form-control"
                  type="textarea"
                  id="message"
                  placeholder="Merknad"
                  maxLength="2000"
                  rows="3"
                  onChange={this.handleMerknadChange}
                  value={this.state.merknad}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12">
            <div className="pull-right margin-right-15">
              <input
                type="button"
                className="btn btn-primary"
                value="OK"
                onClick={this.onLagreClick}
              />
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NyTiltakshistorikk;
