import React from 'react';
import SearchkitEnhetssok from './SearchkitEnhetssok.jsx';
import EnhetSokMobile from './EnhetSokMobile.jsx';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import { getKommunerForOrganisasjon } from 'api/kommuneApi';
import { isMobile, isDesktop } from 'react-device-detect';

const debug = debugFactory('nk:objekter:enhetssok');

class Enhetssok extends React.Component {
  constructor() {
    super();
    this.state = {
      kommuner: null,
    };

    this.onGotKommuner = this.onGotKommuner.bind(this);
  }

  componentDidMount() {
    getKommunerForOrganisasjon(this.onGotKommuner);
  }

  onGotKommuner(err, kommuner) {
    this.setState({ kommuner: kommuner });
  }

  render() {
    if (!this.state.kommuner) {
      return <div>Vent, laster data..</div>;
    }
    return (
      <div>
       {/*  <SearchkitEnhetssok
            kobleTilReelEnhet={this.props.kobleTilReelEnhet}
            isMapCollapsed={this.props.isMapCollapsed}
            kommuner={this.state.kommuner}
            fraFiktiv={this.props.fraFiktiv}
          /> */}
     {isMobile && (
        <EnhetSokMobile kommuner={this.state.kommuner}/>
        )}

        {isDesktop && (
          <SearchkitEnhetssok
            kobleTilReelEnhet={this.props.kobleTilReelEnhet}
            isMapCollapsed={this.props.isMapCollapsed}
            kommuner={this.state.kommuner}
            fraFiktiv={this.props.fraFiktiv}
          />
        )} 
      </div>
    );
  }
}
export default Enhetssok;
