import React from 'react';
import NKModal from './NKModal.jsx';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:SoekDialog');

class soekDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tekstData: null,
      tekstFilterData: [],
      soktekst: '',
      slettId: null,
      hideNull: 'hide',
      isOpen: true,
    };
    this.sokBoks = React.createRef();
    this.buttons = React.createRef();
  }

  componentDidMount() {
    debug('BRAM 0', this.props);
    this.klarGjorData();
  }

  klarGjorData = () => {
    let liste = this.props.liste;
    let tekstData = [];
    for (let i = 0; i < liste.length; i++) {
      const row = liste[i];
      let obj = {};
      obj.id = row[this.props.ident];
      obj.tekst = row[this.props.tekst];
      //aktiv?
      if (this.props.checkAktiv !== '') {
        if (row[this.props.checkAktiv]) {
          tekstData.push(obj);
        }
      } else {
        tekstData.push(obj);
      }
    }
    this.setState({ tekstData });
    this.setState({ tekstFilterData: tekstData });

    debug('BRAM Data', tekstData);
  };

  closeModal = () => {
    this.props.isOpen = false;
  };

  sok(event) {
    var soktekst = event.target.value;
    this.setState({ soktekst: soktekst });
    if (soktekst.length < 1) {
      this.setState({ tekstFilterData: this.state.tekstData });
      return;
    }

    var matches = [];
    if (soktekst.length > 0) {
      this.state.tekstData.forEach(function(a) {
        if (a.tekst) {
          if (
            a.tekst.toLowerCase().indexOf(soktekst.toLowerCase()) > -1 ||
            a.id.toString() === soktekst
          ) {
            matches.push(a);
          }
        }
      });
    } else {
      this.setState({ tekstFilterData: this.state.tekstData });
    }
    debug('BRAM event', matches);
    this.setState({ tekstFilterData: matches });
  }

  slettSoekTekst = (o) => {
    let boks = this.sokBoks;
    boks.current.value = '';
    this.setState({ tekstFilterData: this.state.tekstData });
    boks.current.focus();
  };

  green(txt) {
    /*  let s = this.state.soktekst;
    if (s.length < 2){
      return { __html: txt };
    }
    let x = '<mark>' + s + '</mark>';
    debug('BRAM mark', x);
    let nyText = txt.toLowerCase().replace(new RegExp(s, 'g'), x);
    return { __html: nyText }; */
    return { __html: txt };
  }

  brukTekst = (row, x) => {
    this.props.onValgt(row);
  };

/*   keyPress = (e) => {
    if (e.code === 'ArrowDown') {
      debug('BBBB', this.buttons);
      let but = document.getElementById('bid0');
      if (but){
        but.focus();
      }
    }
  }; */

  render() {
    if (!this.state.tekstFilterData) {
      return (
        <div style={{ padding: '20px' }}>'Vent, lader oppslagsdata..'</div>
      );
    }
    return (
      <NKModal size='medium' isOpen={this.props.isOpen}>
        <div className='modal-content'>
          <div className='modal-header modal-header'>
            <button
              className='close'
              onClick={this.props.onClose}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'>{this.props.tittel}</h4>
          </div>
          <div className='modal-body'>
            <div style={{ marginRight: '8px', marginLeft: '8px' }}>
              <input
               /*  onKeyDown={this.keyPress} */
                autoFocus
                type='text'
                ref={this.sokBoks}
                placeholder='Søk...'
                onChange={this.sok.bind(this)}
                className='prikprikprik1'
                style={{ width: '530px', fontSize: '14px' }}
              />
              <div className='prikprikprik2'>
                <button
                  title='Fjern søktekst'
                  onClick={this.slettSoekTekst}
                  style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  <span className='glyphicon glyphicon-erase' />
                </button>
              </div>
            </div>
            <br />
            <div>
              <div
                style={{
                  margin: '12px',
                  overflow: 'auto',
                  height: '500px',
                  overflowX: 'hidden',
                }}>
                <div  ref={this.buttons}>
                  {this.state.tekstFilterData.map(
                    (row, idx) => (
                      <div key={'dv' + idx}>
                        <button
                          onClick={this.brukTekst.bind(this, row)}
                          key={'bxx' + idx}
                          id={'bid' +idx}
                          style={{
                            textalign: 'left',
                            marginBottom: '2px',
                            width: '524px',
                            padding: '0',
                            minHeight: '30px',
                          }}
                          className='btn btn-default'>
                          <span
                            key={'bsp' + idx}
                            style={{
                              marginLeft: '4px',
                              marginRight: '4px',
                              wordBreak: 'break-word',
                              textAlign: 'justify',
                              padding: '0',
                            }}
                            className='pull-left'
                            dangerouslySetInnerHTML={this.green(row.tekst)}
                          />
                        </button>
                        <br />
                      </div>
                    ),
                    this
                  )}
                  {/*             {this.state.tekstFilterData.map(function(row) {
                  var t = this.green(row.tekst);
                  var txt = row.tekst;
                  var tid = row.id;

                  return (
                      <div>{txt}</div>
                  );
                }, this)} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default soekDialog;
