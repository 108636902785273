import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import { debugFactory, enhanceComponents } from 'utils';

const debug = debugFactory('nk:planlegging:routes');

const enhanced = enhanceComponents({
    Index
}, [
        asRouteComponent
    ]);

export default (
    <Route path="infosider" component={enhanced.Index} name="Infosider" icon="info-transparent" inMenu={true} login={true} roles={[]}>
   
       
    </Route>
);