import * as boundActions from 'redux/boundActions';
import * as actionTypes from './actionTypes';
import * as personApi from 'api/personApi';
import * as kommuneApi from 'api/kommuneApi';
import * as organisasjonApi from 'api/organisasjonApi';
import * as innloggingApi from 'api/innloggingApi';
import {
    debugFactory,
    promisifyApiCall
} from 'utils';

const debug = debugFactory('nk:redux:innstilinger:actions');

export const fetchUserPersonalia = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.USER_PERSONALIA_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, personApi.getPersonByAdOid, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.userPersonalia);
    }
};

export const fetchUserPermissions = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.USER_PERMISSIONS_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, innloggingApi.innlogging, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.userPermissions);
    }
};

export const fetchOrganisasjon = (id = undefined, { forceFetch = false } = {}) => (dispatch, getState) => {
    /* TODO! FIXME!
    This call is used for multiple sources, so it is not adequate to just cache automatically though `fetch/shoudFetch`!
    - 1st source  - The logged in organization
    - 2nd source - Editing organizations as a Norkart Admin

    Best case is to have caching include the `id`, which is `undefined` for the logged in org (1st source above).
    
    Rieni: i prinsipp brukes dette kun fra en ikke-norkart-admin
    */
   debug('orgx actions 1');
    const actionType = actionTypes.ORGANISASJON_FETCHED;    
    const state = getState();
    const userPermissions = state.innstillinger.userPermissions;   
    const apiCall = userPermissions.norkartAdmin 
        ? organisasjonApi.getOrganisasjonNK
        : organisasjonApi.getOrganisasjon;
    if (userPermissions.norkartAdmin) {
        if (boundActions.shouldFetch(actionType, forceFetch)) {
            debug('orgx actions 2a', apiCall);
            return dispatch(boundActions.fetch(actionType, apiCall, {
                apiArgs: [id || userPermissions.orgId],  
                actionType,  
                debugInstance: debug,
            }))
                .then(data => {
                    dispatch({
                        type: actionType,
                        payload: data
                    });
                    return data;
                });
        }
        else {
            debug('orgx actions 3a');
            return Promise.resolve(state.innstillinger.organisasjon);
        }
    } else {
        if (boundActions.shouldFetch(actionType, forceFetch)) {
            debug('orgx actions 2b', apiCall);
            return dispatch(boundActions.fetch(actionType, apiCall, {
                actionType,  
                debugInstance: debug,
            }))
                .then(data => {
                    dispatch({
                        type: actionType,
                        payload: data
                    });
                    return data;
                });
        }
        else {
            debug('orgx actions 3b');
            return Promise.resolve(state.innstillinger.organisasjon);
        }
    }
  
};

export const fetchOrganisasjoner = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.ORGANISASJONER_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, organisasjonApi.getOrganisasjoner, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.organisasjoner);
    }
};

export const fetchOrgKartSenterLokasjon = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.ORG_KARTSENTER_LOKASJON_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, organisasjonApi.getOrganisasjonLokasjon, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.orgKartsenterLokasjon);
    }
};

export const fetchOrgKommuner = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.ORG_KOMMUNER_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, kommuneApi.getKommunerForOrganisasjon, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.kommuner);
    }
};

export const fetchOrgKategorier = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.ORG_KATEGORIER_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, organisasjonApi.getOrganisasjonKategorier, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.orgKategorier);
    }
};

/* export const updateUserPersonalia = (id, props) => (dispatch, getState) => {
    debug('updateUserPeronalia:api-props', { id, props });

    return promisifyApiCall(personApi.updatePerson)(id, props)
        .then(() => {
            dispatch({
                type: actionTypes.UPDATE_USER_PERSONALIA,
                payload: { id, props },
            });
            return { id, props };
        });
}; */

 export const updateOrganisasjon  = (props) => (dispatch, getState) => {    
    const state = getState();
    const userPermissions = state.innstillinger.userPermissions;
  //  userPermissions.norkartAdmin = false;  //Rieni
    debug('orgx0', userPermissions);
    debug('orgx0Props', props);
    if (!props.fontSize){
        props.fontSize='16px';
    }
    if (!props.fontType){
        props.fontType='Arial';
    }
    debug('orgx0Props-2', props);
    const { id } = props;
    let apiArgs = null;
    const apiCall = userPermissions.norkartAdmin
        ? (isNaN(id) 
            ? organisasjonApi.createOrganisasjon 
            : organisasjonApi.updateOrganisasjonNk)
        : organisasjonApi.updateOrganisasjon;  
    debug('orgx1', apiCall);
    if (userPermissions.norkartAdmin) {
        apiArgs = [props];
        if (!isNaN(id)) { apiArgs.unshift(id); }
    } else {
        apiArgs = [props];
    }
    debug('orgx2', apiArgs);
    boundActions.incrementRequestCount();
    return promisifyApiCall(apiCall)(...apiArgs)
        .then((data) => {

            dispatch({
                type: actionTypes.UPDATE_ORGANISASJON,  
                payload: data,
            });
           /*  dispatch({
                type: actionTypes.UPDATE_ORG_KARTSENTER_LOKASJON,
                payload: objPickProps(data, [
                    'latitude',
                    'longitude',
                    'zoomLevel',
                ])
            }); */
            boundActions.decrementRequestCount();
            return { id, props };
        });
}; 

/* export const fetchSaksbehandlere = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.SAKSBEHANDLERE_FETCHED;
    const state = getState();
    if (boundActions.shouldFetch(actionType, forceFetch)) {
        return dispatch(boundActions.fetch(actionType, personApi.getPersoner, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    }
    else {
        return Promise.resolve(state.innstillinger.saksbehandlere);
    }
}; */

/* export const fetchArkivinnstillinger = ({ forceFetch = false } = {}) => (dispatch, getState) => {
    const actionType = actionTypes.ARKIVINNSTILLINGER_FETCHED;
    const state = getState();

    if (boundActions.shouldFetch(actionType, forceFetch)) { 
        return dispatch(boundActions.fetch(actionType, arkivApi.getArkivInnstillinger, {
            actionType,
            debugInstance: debug,
        }))
            .then(data => {
                dispatch({
                    type: actionType,
                    payload: data
                });
                return data;
            });
    } else {
        return Promise.resolve(state.innstillinger.arkivinnstillinger);
    } 

};*/