import callApi from './api';

export function getKommunerForOrganisasjon(callback) {
    callApi('GET', '/kommune/organisasjon/', null, callback);
}

export function getAlleKommunerForOrg(orgId, callback) {
    callApi('GET', '/kommune/organisasjonalle/' + orgId, null, callback);   
}

export function updateKommune(id, data, callback) {
    callApi('PUT', '/kommune/' + id, data, callback);   
}

export function createKommune(kommune, callback) {
  callApi('POST', '/kommune/', kommune, callback);
};