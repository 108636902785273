import React from 'react';
import _ from 'underscore';
import ReactSelect from 'react-select';
import { PropTypes } from 'prop-types';

export default class extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired   
    };

    state = {};

    render() {
        return (
            <ReactSelect multi
                id={this.props.name}
                valueKey="id" labelKey="navn"
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChange}
                options={this.props.options}
                searchable={true}
                placeholder="Velg..."
                noResultsText="Ingen resultat.."
                searchPromptText="Søk.."
                openOnFocus={true}
                isOpen={false}
                disabled={this.props.disabled}
                />
        );
    }
}
