import React from 'react';
import ReactTable from '@norkart/react-table';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
import moment from 'moment';
import ContextMenu from 'components/ContextMenu';
import NKIcon from 'nka-icons';
import NKModal from 'components/Dialogs/NKModal.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import DriftDialog from './DriftDialog.jsx';
import './driftsmeldinger.css';
import {
  nyDriftsmelding,
  oppdaterDriftsmelding,
  deleteDriftsmelding,
  getDriftsmeldinger,
} from 'api/innloggingApi.js';

const debug = debugFactory('nk:driftsmeldinger');

class DriftsmeldingerHome extends React.Component {
  state = { meldinger: null };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    this.getData();
    //getAktivDriftsmelding(this.gotMeldinger);
  };

  getData = () => {
    getDriftsmeldinger(this.gotMeldinger);
  };

  gotMeldinger = (err, meldinger) => {
    let liste = meldinger.sort((a, b) => b.id - a.id);
    console.log('%c RIENI meldinger ', 'font-size: 14px; background: black; color: white',  meldinger );  
    this.setState({ meldinger: liste });
  };

  rowFormat = (item) => {
    if (!item.historisk) {
      return { style: { backgroundColor: '#FDD9D6' } };
    }
  };

  getColumns = () => {
    let columns = [
      {
        id: 'publisertDato',
        name: 'Pub.dato',
        sortParams: 'publisertDato',
        isSorted: true,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.publisertDato === null) {
            return '';
          } else {
            return moment(item.publisertDato).format('DD.MM.YYYY HH:mm');
          }
        },
      },
      {
        id: 'publisertAv',
        name: 'Av',
        sortParams: 'publisertAv',
        isSorted: false,
      },
      {
        id: 'tittel',
        name: 'Tittel',
        sortParams: 'tittel',
        isSorted: false,
      },
      {
        id: 'tekst',
        name: 'Tekst',
        sortParams: 'tekst',
        isSorted: false,
        formatter: function(item) {
          return <div style={{ maxWidth: '500px' }}>{item.tekst}</div>;
        },
      },
      {
        id: 'merknad',
        name: 'Merk',
        sortParams: 'merknad',
        isSorted: false,
        formatter: function(item) {
          return item.url && item.url !== ' ' ? (
            <span className='text'>
              {' '}
              <NKIcon icon='kommentar' size='0.8' color='nk-black' />
              <span className='text-tooltipDM2'>{item.merknad}</span>
            </span>
          ) : (
            <div style={{ maxWidth: '6px' }} />
          );
        },
      },
      {
        id: 'url',
        name: 'Url',
        sortParams: 'url',
        isSorted: false,
        formatter: function(item) {
          return item.url && item.url !== ' ' ? (
            <span className='text'>
              {' '}
              <NKIcon icon='lenke' size='0.8' color='nk-black' />
              <span className='text-tooltipDM'>{item.url}</span>
            </span>
          ) : (
            <div style={{ maxWidth: '6px' }} />
          );
        },
      },
      {
        id: 'historisk ',
        name: 'Utgått',
        sortParams: 'historisk ',
        isSorted: false,
        formatter: function(item) {
          if (item.historisk) {
            return 'Ja';
          } else {
            return 'Nei';
          }
        },
      },
      {
        id: 'historiskDato ',
        name: 'Ut.dato',
        sortParams: 'historiskDato ',
        isSorted: false,
        formatter: function(item) {
          if (item.historiskDato === null) {
            return '';
          } else {
            return moment(item.historiskDato).format('DD.MM.YYYY HH:mm');
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button title='Meny' icon='meny' iconColor='nk-black'>
              <ContextMenu.Item onClick={this.openModal.bind(this, item)}>
                Endre driftsmelding...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.kopierMelding.bind(this, item)}>
                Kopier driftsmelding...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slettMelding.bind(this, item)}>
                Slett driftsmelding...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this),
      },
    ];
    return columns;
  };

  slettMelding = (item) => {
    this.dialogs.setOpenDialog('JaNeiDialog', {
      onJa: () => {
        deleteDriftsmelding(item.id, this.getData);
      },
    });
  };

  nyMelding = () => {
    this.dialogs.setOpenDialog('DriftDialog', {
      tittel: 'Ny driftsmelding',
      modus: 'ny',
      item: null,
    });
  };

  kopierMelding = (item) => {
    let kopie = {
      historiskDato: null,
      historisk: false,
      publisertAv: '',
      publisertDato: moment(),
      tittel: item.tittel,
      tekst: item.tekst,
    };
    this.dialogs.setOpenDialog('DriftDialog', {
      tittel: 'Ny driftsmelding',
      modus: 'kopier',
      item: kopie,
    });
  };

  openModal = (item) => {
    this.dialogs.setOpenDialog('DriftDialog', {
      tittel: 'Endre driftsmelding',
      modus: 'endre',
      item: item,
    });
  };

  onRowClick = (row) => {
    this.openModal(row);
  };

  onOk = (item, ny) => {
    debug('ZZZa', item);
    this.dialogs.closeDialog();
    if (ny) {
      nyDriftsmelding(item, this.getData);
    } else {
      oppdaterDriftsmelding(item.id, item, this.getData);
    }
  };

  render() {
    if (!this.state.meldinger) {
      return <div>Vent..</div>;
    }
    return (
      <div>
        <h2>
          <span>Driftsmeldinger</span>
          <span style={{ fontStyle: 'Italic', marginLeft: '4px' }}>
            'startside'
          </span>{' '}
        </h2>
        <button className='btn btn-default' onClick={this.nyMelding}>
          {' '}
          <NKIcon icon='advarsel_roed' style={{ marginRight: '6px' }} />
          <span>Ny driftsmelding</span>
        </button>
        <br /> <br />
        <ReactTable
          items={this.state.meldinger}
          idProperty='id'
          showIndex={false}
          filterable={true}
          columns={this.getColumns()}
          selectableRows={false}
          selectAllLabel='Alle'
          onRowClick={(row) => this.onRowClick(row)}
          rowFormat={this.rowFormat}
        />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default DriftsmeldingerHome;

const modalDialogs = {
  DriftDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen}>
        <DriftDialog
          onClose={onClose}
          onOK={owner.onOk}
          tittel={dialogData.tittel}
          item={dialogData.item}
          modus={dialogData.modus}
        />
      </NKModal>
    );
  },
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Slett melding'
        isOpen={isOpen}
        onClose={onClose}
        melding={'Ønsker du å slette valgt driftsmelding?'}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};
