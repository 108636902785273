import Debug from 'debug';
import {
    branch,
    renderComponent,
} from 'recompose';

const debug = Debug('nk:lib:enhancers:withspinnerwhileisloading');

const withSpinnerWhileIsLoading = (Spinner) => branch(
    ({ isLoading }) => {
        debug({ isLoading });
        return isLoading;
    },
    renderComponent(Spinner)
);

export default withSpinnerWhileIsLoading;