import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import L from 'leaflet';
import NKIcon from 'nka-icons';
import './regioner.css';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import Lagreregion from './LagreRegion.jsx';
import LagreEndretRegion from './LagreEndretRegion.jsx';
import omradeSoneApi from 'api/omradeSonerApi';
import RegionerPanel from './RegionerPanel.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:kartsoner');

var parentKart = null;

const modalDialogs = {
  LagreRegionDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal
      size='small'
      isOpen={isOpen}
      onClose={owner.closeLagreRegion.bind(this)}>
      <Lagreregion
        onLagreRegionOk={owner.onPolygonLagret}
        onClose={owner.closeLagreRegion.bind(this)}
      />
    </NKModal>
  ),
  LagreEndretRegionDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='small' isOpen={isOpen} onClose={onClose}>
      <LagreEndretRegion
        currentSone={dialogData.currentSone}
        onLagreEndretSoneOk={owner.onLagreEndretSoneOk}
        onLagreKopi={owner.onLagreKopi}
        onClose={onClose}
      />
    </NKModal>
  ),
};

class RegionerControl extends Component {
  state = {
    tegner: false,
    laast: false,
    initiert: false,
    workingLayer: [],
    lastVertex: [],
    snapDistance: '40',
    currentLayer: [],
    currentSone: [],
    soneCollection: [],
    fillOpacity: 0.2,
    hideRegionsComponent: 'hide',
    refreshPanel: 0,
  };

  constructor(props) {
    super(props);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    omradeSoneApi.getAlleSoner(this.gotAlleSoner.bind(this));
  }

  componentDidUpdate = () => {
    parentKart = this.props.kart;
    //Ikke start før parentKart har blitt satt
    if (parentKart != null) {
      if (!this.state.initiert) {
        this.setState({ initiert: true });
        this.initierRegioner();
      }
    }
  };

  gotAlleSoner(err, data) {
    this.setState({ soneCollection: data });
  }

  initierRegioner = () => {
    parentKart.on('keypress', (e) => {
      this.handleKeypress(e);
    });
    // regionMap.pm.addControls(options);
    parentKart.on('pm:create', (e) => {
      this.onPolygonTegned(e);
    });
    parentKart.on('pm:drawstart', (e) => {
      var layer = e.workingLayer;
      this.setState({ workingLayer: layer });
      layer.pm.enable(this.getPolyOptions());
      layer.pm.enable({ draggable: false });
      layer.on('pm:vertexadded', (v) => {
        this.setState({ lastVertex: v });
      });
    });
    parentKart.on('pm:drawend', (e) => {
      this.setState({ tegner: false }); //Rieni
    });
  };

  //LAYERFUNCTIONS
  settLayerFunctions = (son, layer) => {
    let polyStyle = {
      fillColor: son.color,
      color: 'black',
      weight: 2,
      fillOpacity: this.state.fillOpacity,
      draggable: false,
      editMode: false,
    };
    layer.setStyle(polyStyle);
    layer.bindTooltip(son.navn, {
      interactive: false,
      permanent: false,
      className: 'polyTooltip',
    });
    layer.on('click', (e) => {
      if (this.state.laast === false) {
        parentKart.pm.disableDraw('Poly');
        if (this.state.tegner === false) {
          layer.pm.toggleEdit(this.getPolyOptions);
          layer.pm.draggable = false;
          // layer.pm.enable({ draggable: false });
        } else {
          layer.pm.draggable = false;
          layer.pm.enable({ draggable: false });
        }
      }
    });
    layer.on('contextmenu', (e) => {
      if (this.state.tegner === false) {
        this.setState({ currentLayer: layer });
        this.setState({ currentSone: son });
        this.lagreEndringerOpen();
      }
    });
  };

  lagreEndringerOpen = () => {
    this.dialogs.setOpenDialog('LagreEndretRegionDialog', {
      currentSone: this.state.currentSone,
    });
  };

  skjulPolygoner = () => {
    parentKart.pm.disableDraw('Poly');
    parentKart.eachLayer(function(layer) {
      if (layer instanceof L.Polygon) {
        parentKart.removeLayer(layer);
      }
    });
    //Fjern alle grønne classes i områdene
    let panel = this.refs.RegionPanel;
    this.setState({ hideRegionsComponent: 'hide' });
    panel.nullstillAlle();
  };

  visPolygon = (son) => {
    let poly = JSON.parse(son.polygon);
    var layer = L.polygon([poly]).addTo(parentKart);
    this.settLayerFunctions(son, layer);
    layer.draggable = false;
    layer.pm.draggable = false;
    layer.pm.enable({ draggable: false });
    layer.pm.disable();
    let center = layer.getBounds().getCenter();
    parentKart.setView(center, 10);
  };

  onPolygonTegned = (e) => {
    this.setState({ currentLayer: e.layer });
    e.layer.pm.enable({ draggable: false });
    e.layer.pm.disable();
    this.setState({ modalLagreIsOpen: true });
    this.dialogs.setOpenDialog('LagreRegionDialog');
  };

  closeLagreRegion() {
    this.dialogs.closeDialog();
    this.state.currentLayer.remove();
  }

  onPolygonLagret = (omradeId, omradeNavn, navn, color) => {
    this.createNySone(omradeId, omradeNavn, navn, color, false);
    this.dialogs.closeDialog();
  };

  startPolygon = () => {
    this.disablePolygons();
    this.setState({ tegner: true });
    parentKart.pm.enableDraw('Poly', this.getPolyOptions());
  };

  getPolyOptions = () => {
    let options = {
      draggable: false,
      snappable: true,
      snapDistance: this.state.snapDistance,
      allowSelfIntersection: false,
      finishOnDoubleClick: true,
      cursorMarker: false,
    };
    return options;
  };

  disablePolygons = () => {
    parentKart.eachLayer(function(layer) {
      if (layer instanceof L.Polygon) {
        layer.pm.disable();
      }
    });
  };

  skjulPolygon = (son) => {
    parentKart.eachLayer(function(layer) {
      if (layer instanceof L.Polygon) {
        if (layer._tooltip !== undefined) {
          if (layer._tooltip._content === son.navn) {
            layer.remove();
          }
        }
      }
    });
  };

  handleKeypress = (e) => {
    if (this.state.tegner) {
      var x = e.originalEvent.key;
      if (x === '<') {
        this.fjernVertex();
      }
    }
  };

  fjernVertex = () => {
    if (this.state.tegner) {
      if (this.state.workingLayer !== null) {
        this.state.workingLayer.pm.enable();
        let markers = this.state.workingLayer.pm._markers;
        let m = markers[markers.length - 1];
        let e = { target: m };
        this.state.workingLayer.pm._removeMarker(e);
        //Vi har ikke en gruppe, så derfor ikke den:
        //let ls = this.state.workingLayer.pm._map.pm.Draw.Line._layerGroup._layers;
        if (
          this.state.workingLayer !== undefined &&
          this.state.workingLayer !== null
        ) {
          if (
            this.state.workingLayer.pm._map !== undefined &&
            this.state.workingLayer.pm._map !== null
          ) {
            let ls = this.state.workingLayer.pm._map.pm.Draw.Line._map._layers;
            let d = Object.keys(ls).pop();
            ls[d].remove();
            delete ls[d];
          }
        }
      }
    }
    //Får det ikke til  this.state.workingLayer.pm._map.pm.Draw.Line._syncHintLine();
  };

  closeLagreRegion = () => {
    this.dialogs.closeDialog();
    this.state.currentLayer.remove();
  };

  createNySone = (omradeId, omradeNavn, soneNavn, color, visExtra) => {
    let sone = {
      id: 0,
      color: color,
      omradeId: omradeId,
      omradeNavn: omradeNavn,
      navn: soneNavn,
      polygon: this.konverterTilLatLngs(this.state.currentLayer),
    };
    if (visExtra) {
       omradeSoneApi.createSone(sone, this.nySoneCreatedPluss.bind(this));
    } else {
       omradeSoneApi.createSone(sone, this.nySoneCreated.bind(this));
    }
  };

  nySoneCreated = (err, sone) => {
    let collection = this.state.soneCollection;
    sone.vis = true;
    collection.push(sone);
    this.setState({ soneCollection: collection });
    this.settLayerFunctions(sone, this.state.currentLayer);
    this.setState({ refreshPanel: this.state.refreshPanel + 1 });
    return sone;
  };

  nySoneCreatedPluss = (err, sone) => {
    let collection = this.state.soneCollection;
    sone.vis = true;
    collection.push(sone);
    this.setState({ soneCollection: collection });
    this.visPolygon(sone);
    this.settLayerFunctions(sone, this.state.currentLayer);
    this.setState({ refreshPanel: this.state.refreshPanel + 1 });
    return sone;
  };

  onLagreEndretSoneOk = (endretSone) => {
    this.dialogs.closeDialog();
    this.updateSone(endretSone);
    this.kunEndreFarge(endretSone.color);
  };

  onLagreKopi = (kopi) => {
    this.dialogs.closeDialog();
    let sone = {
      color: kopi.color,
      omradeId: kopi.omradeId,
      omradeNavn: kopi.omradeNavn,
      navn: kopi.navn,
      originalNavn: kopi.navn,
      vis: false,
      polygon: this.konverterTilLatLngs(this.state.currentLayer),
    };
    omradeSoneApi.createSone(sone, this.nyKopiCreated.bind(this));
  };

  nyKopiCreated = (err, sone) => {
    let collection = this.state.soneCollection;
    sone.vis = false;
    collection.push(sone);
    this.setState({ soneCollection: collection });
    this.setState({ refreshPanel: this.state.refreshPanel + 1 });
  };

  updateSone = (endretSone) => {
    let sone = {
      id: endretSone.id,
      color: endretSone.color,
      omradeId: endretSone.omradeId,
      omradeNavn: endretSone.omradeNavn,
      navn: endretSone.navn,
      vis: true,
      polygon: this.konverterTilLatLngs(this.state.currentLayer),
    };

    omradeSoneApi.updateSone(endretSone.id, sone, this.soneUpdated.bind(this));
  };

  kunEndreFarge = (color) => {
    let polyStyle = {
      fillColor: color,
      color: 'black',
      weight: 2,
      fillOpacity: this.state.fillOpacity,
    };
    this.state.currentLayer.setStyle(polyStyle);
  };

  soneUpdated = (err, sone) => {
    //Fjern originalen
    let panel = this.refs.RegionPanel;
    panel.nullstillSone(sone.originalNavn);
    this.state.currentLayer.remove();
    this.visPolygon(sone);

    let collection = this.state.soneCollection;

    for (var i = 0; i < collection.length; i++) {
      var element = collection[i];

      if (element.id === sone.id) {
        collection[i].color = sone.color;
        collection[i].omradeId = sone.omradeId;
        collection[i].omradeNavn = sone.omradeNavn;
        collection[i].polygon = sone.polygon;
        collection[i].navn = sone.navn;
      }
    }
    this.setState({ soneCollection: collection });
    this.setState({ refreshPanel: this.state.refreshPanel + 1 });
  };

  konverterTilLatLngs = (layer) => {
    let latlngsListe = this.getLatLngs(layer._latlngs);
    return JSON.stringify(latlngsListe);
  };

  getLatLngs = (latlngs) => {
    let liste = [];
    if (latlngs) {
      let array = latlngs[0];
      array.forEach(function(par) {
        //Check for negative ifm. Elastic Search: Sør Odal-problem
        let lat = par.lat;
        if (lat < 0){
          lat = lat + 360;
        }
        let lng = par.lng;
        if (lng < 0){
          lng = lng + 360;
        }
        liste.push({ lat, lng });
      });
      return liste;
    }
  };

  handleSnapChange = (event) => {
    this.setState({ snapDistance: event.target.value });
  };

  //SonerPanel
  onValgtSone = (son, vises) => {
    if (vises) {
      this.skjulPolygon(son);
    } else {
      this.visPolygon(son);
    }
  };

  omraderOppdatert = () => {
    //
  };

  onSlettSone = (sone) => {
    let id = -1;
    let collection = this.state.soneCollection;
    for (let i = 0; i < collection.length; i++) {
      if (collection[i].navn === sone.navn) {
        id = i;
      }
    }
    if (id > -1) {
      collection.splice(id, 1);
    }
    this.setState({ soneCollection: collection });
    this.skjulPolygon(sone);
    omradeSoneApi.deleteSone(sone.id, this.soneSlettet);
  };

  closeMeny = () => {
    this.setState({ hideRegionsComponent: 'hide' });
  };

  soneSlettet = (err, data) => {
    this.setState({ refreshPanel: this.state.refreshPanel + 1 });
  };

  handlepanelComponentHide = () => {
    var hide = this.state.hideRegionsComponent;
    if (hide === 'hide') {
      this.setState({ hideRegionsComponent: ' ' });
    } else {
      this.setState({ hideRegionsComponent: 'hide' });
    }
  };

  laasPolygoner = () => {
    this.disablePolygons();
    this.setState({ laast: !this.state.laast });
  };

  render() {
    let laastIcon = 'lukket';
    if (this.state.laast) {
      laastIcon = 'aapen';
    }
    return (
      <div>
        <Control position='topleft'>
          <button
            type='button'
            className='btnLag'
            onClick={this.handlepanelComponentHide}>
            <NKIcon icon='gjennomsiktig-kartlag' color='nk-black' />
          </button>
        </Control>

        <Control position='topright'>
          <div>
            <br />

            <button
              title='Tegn polygon'
              type='button'
              className={'btnLag pull-right'}
              onClick={this.startPolygon}>
              <NKIcon icon='polygon' color='nk-black' />
            </button>
            <br />
            <button
              title='Skjul polygoner'
              type='button'
              className={'btnLag pull-right'}
              onClick={this.skjulPolygoner}>
              <NKIcon icon='enkelt-kartlag' color='nk-black' />
            </button>
            <br />
            <button
              title='Lås polygoner'
              type='button'
              className={'btnLag pull-right'}
              onClick={this.laasPolygoner}>
              <NKIcon icon={laastIcon} color='nk-black' />
            </button>
            <br />
            <button
              title='Angre siste punkt ( < )'
              type='button'
              className={'btnLag pull-right'}
              onClick={this.fjernVertex}>
              <NKIcon icon='polylinje' color='nk-black' />
            </button>
          </div>
        </Control>

        {/*Snap */}
        <Control position='bottomright'>
          <div className='SnapDiv'>
            <label>Snapavstand</label>
            <br />
            <label className='radio-inline'>
              <input
                type='radio'
                value='30'
                name='optradio'
                onChange={this.handleSnapChange}
                checked={this.state.snapDistance === '30'}
              />
              30
            </label>
            <label className='radio-inline'>
              <input
                type='radio'
                value='40'
                name='optradio'
                onChange={this.handleSnapChange}
                checked={this.state.snapDistance === '40'}
              />
              40
            </label>
            <label className='radio-inline'>
              <input
                type='radio'
                value='50'
                name='optradio'
                onChange={this.handleSnapChange}
                checked={this.state.snapDistance === '50'}
              />
              50
            </label>
            <br />
            <br />
            <label>Hold ALT = ikke snap</label>
          </div>
        </Control>

        {/*Panel */}
        <Control position='topleft'>
          <div className={this.state.hideRegionsComponent}>
            <RegionerPanel
              ref='RegionPanel'
              onValgtSone={this.onValgtSone}
              // soneCollection={this.state.soneCollection}
              refresh={this.state.refreshPanel}
              omraderOppdatert={this.omraderOppdatert}
              onSlettSone={this.onSlettSone}
              closeMeny={this.closeMeny}
            />
          </div>
        </Control>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default RegionerControl;
