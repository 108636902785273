import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';
import { objGetDeep } from 'utils';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';

let pathname = null;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visGebyr: false, isNorkartBruker: false };
    pathname = objGetDeep(props, 'location.pathname');
  }

  componentDidMount() {
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
    let isNorkartBruker=contextInnstillingerPerson._currentValue.norkartBruker;
    this.setState({ isNorkartBruker });
  }

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  render() {
    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-md-8 col-lg-6'>
            <LinkList title='Rapporter' columns={2} />

            <LinkListItem
              title='Nøkkeltall'
              path={`${pathname}/Nokkeltall`}
              icon='rapporter'
            />
            {this.state.visGebyr && ( 
              <LinkListItem
                title='Gebyr'
                path={`${pathname}/Gebyr`}
                icon='krone'
              />
            )}
             {this.state.isNorkartBruker && ( 
              <LinkListItem
                title='Nøkkeltall Intern'
                path={`${pathname}/NokkeltallIntern`}
                icon='graf'
              />
            )}
            {/* {this.state.isNorkartBruker && ( 
              <LinkListItem
                title='Børste-Børge svarer..' //hepp
                path={`${pathname}/AI`}
                icon='kommentar'
              />
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
