import React, { Component } from 'react';
import { getAlleArkivInnstillinger } from 'api/arkivApi';
import ReactTable from '@norkart/react-table';
var _ = require('underscore');
 
class VisArkivinstillinger extends Component {
  constructor(props) {
  super(props);
  this.state = { inst: null };
  
  }

componentDidMount() {
  
}

getColumns() {
  return [
    {
      id: 'Bruker',
      name: 'Bruker',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'AdministrativEnhetInit',
      name: 'Adm.Enhet',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'Journalenhet',
      name: 'Journalenhet',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'SaksbehandlerInit',
      name: 'SaksbehandlerInit',
      filterable: false,
      isSorted: false,
      sortable: false
    }
  ];
}

render() {
    console.log(this.props.instillinger);
    if (!this.props.instillinger) {return null;}
   return (
    <div style={{ margin: '30px' }}>
        
                  <h3>Arkivinnstillinger</h3>
                  <p>KundeId:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.instillinger.KundeId}</p>
                  <p>Arkivsystem: {this.props.instillinger.Arkivsystem}</p>
                  <p>Fagsystem: {this.props.instillinger.Fagsystem}</p>
                  <p>Dokumenttype: {this.props.instillinger.Dokumenttype}</p>
                  <nl/>
                  <p>KorrespondanseparttypeMapping</p>
                  <p>&nbsp;EksternMottakerType: {this.props.instillinger != null && this.props.instillinger.KorrespondanseparttypeMapping != null ? this.props.instillinger.KorrespondanseparttypeMapping.EksternMottakerType : null}</p>
                  <p>&nbsp;EksternAvsenderType: {this.props.instillinger != null && this.props.instillinger.KorrespondanseparttypeMapping != null ? this.props.instillinger.KorrespondanseparttypeMapping.EksternAvsenderType : null}</p>
                  <p>&nbsp;InternMottakerType: {this.props.instillinger != null && this.props.instillinger.KorrespondanseparttypeMapping != null ? this.props.instillinger.KorrespondanseparttypeMapping.InternMottakerType : null}</p>
                  <p>&nbsp;InternAvsenderType: {this.props.instillinger != null && this.props.instillinger.KorrespondanseparttypeMapping != null ? this.props.instillinger.KorrespondanseparttypeMapping.InternAvsenderType : null}</p>
                  <p>&nbsp;InternMottakerInngåendeJpType: {this.props.instillinger != null && this.props.instillinger.KorrespondanseparttypeMapping != null ? this.props.instillinger.KorrespondanseparttypeMapping.InternMottakerInngåendeJpType : null}</p>
                  <p>&nbsp;InternMottakerInterntNotatType: {this.props.instillinger != null && this.props.instillinger.KorrespondanseparttypeMapping != null ? this.props.instillinger.KorrespondanseparttypeMapping.InternMottakerInterntNotatType : null}</p>
                  <hr/>
    </div>
    );
  }
}

export default VisArkivinstillinger;