/* eslint-disable no-extra-semi */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'underscore';
import NKIcon from 'nka-icons';
import './AvvikAnmerkninger.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import * as anmerkningApi from 'api/anmerkningApi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import LagreTekstbit from './LagreTekstbit.jsx';
import BilderPanel from 'components/Bilder/BilderPanel.jsx';
import SoekTekstbit from './SoekTekstbit.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { getPerson } from 'api/personApi';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/plugins/image.min.js';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { EditorConfig } from './editorConfig.js';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:AnmerkningerForm');

var endretAnmerkning = null;
let gjelderTekst = '';
let opprinneligLukket = false;

const modalDialogs = {
	SletteDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel='Slette'
			melding='Du er i ferd med å slette en anmerkning. Fortsette?'
			isOpen={isOpen}
			onOk={owner.slettOk.bind(this)}
			onClose={onClose}
		/>
	),
	TekstbiterDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<NKModal size='medium' isOpen={isOpen}>
			<LagreTekstbit
				modul='anmerkninger'
				merkettekst={dialogData.merkettekst}
				tekst={dialogData.tekst}
				onClose={owner.closeTekstbit.bind(this)}
				onLagret={owner.lagretTekstbit.bind(this)}
			/>
		</NKModal>
	),
	TiltakManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel='Angi tiltaktype'
			melding='Du må angi tiltakstype på anmerkning.'
			isOpen={isOpen}
			onOk={onClose}
			onClose={onClose}
		/>
	),
	SaksbehandlerManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel='Angi saksbehandler'
			melding='Du må angi saksbehandler på anmerkning.'
			isOpen={isOpen}
			onOk={onClose}
			onClose={onClose}
		/>
	),
	TekstbiterSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<NKModal size='medium' isOpen={isOpen} onClose={onClose}>
			<SoekTekstbit
				modul='anmerkninger'
				onClose={onClose}
				onVelgTekst={onClose(owner.onInsertTekstbit)}
				/* onVelgTekst={owner.onValgtTekst.bind(this)} */
			/>
		</NKModal>
	),
	IkkeLukkSlettAnmerkningDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel='Lukk/slett anmerkning'
			melding='Anmerkning kan ikke slettes/lukkes fordi det er tilknyttet en rapport som ligger til klargjøring etter tiltak. Du må fjerne oppgaven for denne rapporten, før du kan slette/lukke anmerkningen.'
			isOpen={isOpen}
			onOk={onClose}
			onClose={onClose}
		/>
	),
	IkkeLukkSlettAndreForholdDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel='Lukk/slett andre forhold'
			melding='Andre forhold kan ikke slettes/lukkes fordi det er tilknyttet en rapport som ligger til klargjøring etter tiltak. Du må fjerne oppgaven for denne rapporten, før du kan slette/lukke andre forhold.'
			isOpen={isOpen}
			onOk={onClose}
			onClose={onClose}
		/>
	),
	FeiltegnDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel='Tegn ikke tilatt'
			melding='Beskrivelsen kan ikke inneholde spesialtegn som \.'
			isOpen={isOpen}
			onOk={onClose}
			onClose={onClose}
		/>
	),
};

class AnmerkningForm extends React.Component {
	constructor(props) {
		super(props);
		this.editor = null;
		this.froalaRef = null;
		this.state = {
			tittel: '',
			anmerkning: null,
			gjelderBygg: false,
			visgjelderbygning: '',
			dato: moment(),
			lukket: false,
			kategorier: [],
			tiltakstyper: [],
			filer: [],
			tekst: '',
			merkettekst: '',
			bilderCallbacks: null,
			saksbehandlere: [],
			saksbehandlereAktiv: [],
			feilSaksbehandler: '',
			antallBilder: 0,
			anmerkningActive: true,
		};
		this.dialogs = new DialogStore({
			owner: this,
			dialogs: modalDialogs,
		});
		this.getNewAnmerkning = this.getNewAnmerkning.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.hentData = this.hentData.bind(this);
		this.bilderMounted = this.bilderMounted.bind(this);
		this.slett = this.slett.bind(this);
		this.slettOk = this.slettOk.bind(this);
		this.gjelderByggChange = this.gjelderByggChange.bind(this);
		this.lukketChange = this.lukketChange.bind(this);
		this.gotOppslagsdata = this.gotOppslagsdata.bind(this);
		this.beregnAntallBilder = this.beregnAntallBilder.bind(this);
		this.getFirstTiltak = this.getFirstTiltak.bind(this);
		this.closeTekstbit = this.closeTekstbit.bind(this);
		this.lagretTekstbit = this.lagretTekstbit.bind(this);
		this.getPaaloggetBruker = this.getPaaloggetBruker.bind(this);
	}

	componentDidMount() {
		this.setState({ tittel: this.props.tittel + ' - ' + this.props.adresse });
		this.editor = this.froalaRef;
		this.hentData();
		if (this.props.objektReferanseType !== 'B') {
			this.setState({ visgjelderbygning: 'hide' });
		}
	}

	//  **** GET DATA **********************************************************************
	hentData() {
		anmerkningApi.getAnmerkiningOppslagdata(
			this.props.objektReferanseType,
			this.props.objektReferanseId,
			this.gotOppslagsdata.bind(this)
		);
	}

	getAktiveSaksbehandlere(saksbehandlereA){
		let s = [
			{ id: null, navn: '', brukernavn: null, aktiv: false, paaLogget: false },
		];

		for (let i = 0; i < saksbehandlereA.length; i++) {
			if (saksbehandlereA[i].aktiv) {
				s.push(saksbehandlereA[i]);
			}
		}
		return s;
	};

	visState(){
		console.group('Oppslag state');
		console.log(this.state);
		console.groupEnd();
	};

	gotOppslagsdata(err, data) {
		gjelderTekst = data.objektPlassering;
		console.group('Oppslag gotoppslag');
		console.log(data);
		console.groupEnd();
		this.setState({
			saksbehandlere: data.saksbehandler,
			saksbehandlereAktiv: this.getAktiveSaksbehandlere(data.saksbehandler),
			kategorier: this.utvidkategori(data.kategori),
			tiltakstyper: this.utvidTiltak(data.tiltakTypeListe),
		});

		setTimeout(() => {
			this.visState();
		}, 6000);

		this.getAnmerkning();
	}

	utvidkategori(liste) {
		console.log(liste);
		console.log(this.props.type);
		var anm = [];
		var and = [];
		liste.forEach((element) => {
			if (element.anmerkningKategori) {
				anm.push(element);
			} else {
				and.push(element);
			}
		});
		let kat = { id: null, tekst: null, tekst2: null, anmerkningKategori: null };
		if (this.props.type === 'Anmerkning') {
			anm.unshift(kat);
			return anm;
		} else {
			and.unshift(kat);
			return and;
		}
	}

	utvidTiltak(liste) {
		let til = { id: 0, tekst: null, tekst2: null, fremmedNokkel: null };
		liste.unshift(til);
		return liste;
	}

	getAnmerkning() {
		if (this.props.id === '0') {
			this.getNewAnmerkning();
		} else {
			anmerkningApi.getAnmerkningById(
				this.props.id,
				this.gotAnmerkning.bind(this)
			);
		}
	}

	gotAnmerkning(err, data) {
		debug('zerox data', data);
		let a = _.find(this.state.saksbehandlere, {
			id: data.saksbehandlerId,
		});
		if (a === undefined) {
			getPerson(data.saksbehandlerId, this.gotPersoner);
		}

		//Ifm exception
		if (!data.hasOwnProperty('reApnet')) {
			data.reApnet = false;
		}

		endretAnmerkning = data;
		opprinneligLukket = data.lukket;
		//RIENI TEST TEST TEST
		//endretAnmerkning.saksbehandlerId = null;

		//skal gjelderBygg vises?
		if (data.objektReferanseType === 'B') {
			this.setState({ visgjelderbygning: '' });
		} else {
			this.setState({ visgjelderbygning: 'hide' });
		}
		this.setState({
			anmerkning: data,
			tittel:
				this.props.type === 'Anmerkning'
					? 'Endre anmerkning - ' + this.props.adresse
					: 'Endre andre forhold - ' + this.props.adresse,
			filer: data.filer,
			lukket: data.lukket,
			gjelderBygg: data.gjelderBygg,
			dato: data.dato !== null ? moment(data.dato) : null,
		});
		this.beregnAntallBilder(data.filer);
		this.sjekkSaksbehandler(endretAnmerkning.saksbehandlerId);
	}

	sjekkSaksbehandler(id){
		let liste = this.state.saksbehandlere;
		let sb = null;
		let feilSaksbehandler = '';
		for (let i = 0; i < liste.length; i++) {
			let s = liste[i];
			if (s.id === id) {
				sb = liste[i];
			}
		}
		if (sb) {
			if (!sb.aktiv) {
				feilSaksbehandler =
					'Opprinnelig saksbehandler ' +
					sb.navn +
					' er non-aktiv, velg en ny saksbehandler.';
				endretAnmerkning.saksbehandlerId = null;
			}
		} else {
			feilSaksbehandler =
				'Opprinnelig saksbehandler er ukjent, velg en ny saksbehandler.';
			endretAnmerkning.saksbehandlerId = null;
		}

		this.setState({ feilSaksbehandler });
	};

	gotPersoner(res, data){
		console.log(data);
		if (data !== null && data !== undefined) {
			var tmp = this.state.saksbehandlere;
			tmp.unshift(data);
			console.group('Oppslag z');
			console.log(this.state.saksbehandlere);
			console.log(tmp);
			console.groupEnd();
			this.setState({ saksbehandlere: tmp });
		}
	};

	getPaaloggetBruker() {
		let saksbehandlere = this.state.saksbehandlere;
		console.group('Oppslag y');
		console.log(this.state.saksbehandlere);
		console.log(saksbehandlere);
		console.table(saksbehandlere);
		console.groupEnd();
		let result = '';
		for (let i = 0; i < saksbehandlere.length; i++) {
			if (saksbehandlere[i].paaLogget) {
				result = saksbehandlere[i].id;
				break;
			}
		}
		if (!result) {
			result = 0;
			// result = _.first(this.state.saksbehandlere).id;
		}
		return result;
	}

	getNewAnmerkning() {
		console.group('Oppslag new Anm');
		console.log(this.state.saksbehandlere);
		console.table(this.state.saksbehandlere);
		console.groupEnd();
		let nyAnmerkning = {
			id: '0',
			dato: moment().format('YYYY-MM-DDTHH:00:00'),
			beskrivelse: '',
			gjelderBygg: false,
			bruksenhetId: this.props.bruksenhetid,
			lukket: false,
			objektReferanseId: this.props.objektReferanseId,
			objektReferanseType: this.props.objektReferanseType,
			filer: [],
			saksbehandlerId: '', //this.state.saksbehandlere[0].id,
			kategoriId: null,
			tiltakTypeId: this.getFirstTiltak(),
			reApnet: false,
			andreForhold: this.props.type === 'Anmerkning' ? false : true,
		};
		nyAnmerkning.saksbehandlerId = this.getPaaloggetBruker();
		endretAnmerkning = nyAnmerkning;
		this.setState({ anmerkning: nyAnmerkning });
		this.setState({ antallBilder: 0 });
	}

	getFirstTiltak() {
		let first = _.first(this.state.tiltakstyper);
		return first ? first.id : null;
	}

	// **** Rediger ***********************************************************************
	lukketChange() {
		let lukket = !this.state.lukket;
		this.setState({ lukket: lukket });
		endretAnmerkning.lukket = lukket;
	}

	gjelderByggChange() {
		let bygg = !this.state.gjelderBygg;
		this.setState({ gjelderBygg: bygg });
		endretAnmerkning.gjelderBygg = bygg;
	}

	datoChange(val) {
		this.setState({ dato: val });
		endretAnmerkning.dato = moment(val).format('YYYY-MM-DDTHH:00:00');
	}

	kategoriChange(val) {
		let x = parseInt(val.target.value);
		endretAnmerkning.kategoriId = x;
	}

	handleBeskrivelseChange(model){
		endretAnmerkning.beskrivelse = model;
		console.log(
			'%c RIENI MODEL ',
			'font-size: 14px; background: firebrick; color: white',
			endretAnmerkning.beskrivelse
		);
	};

	saksbehandlerChange(val) {
		endretAnmerkning.saksbehandlerId = val.target.value;
	}

	//  **** Bilder **********************************************************************
	bilderMounted(callbacks) {
		this.setState({ bilderCallbacks: callbacks });
	}

	startHentBilder() {
		if (!this.state.anmerkningActive) {
			return;
		}
		this.setState({ anmerkningActive: false });
		//Bildene kan kun knyttes til en eksisterende anmerkning..
		if (endretAnmerkning.id === '0') {
			anmerkningApi.createAnmerkning(
				endretAnmerkning,
				this.anmerkningForBilderLagred.bind(this)
			);
		} else {
			this.state.bilderCallbacks.start('hentbilder ' + endretAnmerkning.id);
		}
	}

	anmerkningForBilderLagred(err, obj) {
		endretAnmerkning = obj;
		this.state.bilderCallbacks.start('hentbilder for anmerkning ' + obj.id);
	}

	onfillListeChange(data) {
		let bilde = {
			bruksenhetId: this.props.bruksenhetid,
			id: '0',
			filnavn: data,
			filtittel: 'Anmerkning',
			ny: true,
			objektReferanseId: this.props.objektReferanseId,
			objektReferanseType: this.props.objektReferanseType,
			slett: false,
		};

		let filliste = this.state.filer;

		filliste.push(bilde);
		this.setState({ filer: filliste });
		this.beregnAntallBilder(filliste);
	}

	beregnAntallBilder(filer) {
		let antall = 0;
		for (let index = 0; index < filer.length; index++) {
			let bilde = filer[index];
			if (!bilde.slett) {
				antall++;
			}
		}
		this.setState({ antallBilder: antall });
	}

	onBildeSlettet(data) {
		let filer = this.state.filer;
		for (let i = 0; i < filer.length; i++) {
			let bilde = filer[i];
			if (bilde.filnavn === data) {
				bilde.slett = true;
			}
		}
		this.setState({ filer: filer });
		this.beregnAntallBilder(filer);
	}

	//  ****  Tekster ***********************************************************************
	areaSelected() {
		var ta = this.areaRef.current;
		var ss = ta.selectionStart;
		var se = ta.selectionEnd;
		var s = ta.value.substring(ss, se);
		this.setState({ merkettekst: s });
	}

	closeTekstbit() {
		this.setState({ merkettekst: '' });
		this.dialogs.closeDialog();
	}

	removeHTML(str){
		var tmp = document.createElement('DIV');
		tmp.innerHTML = str;
		return tmp.textContent || tmp.innerText || '';
	};

	lagreTekstbit(){
		let editor = this.froalaRef.editor;
		let selection = window.getSelection();
		if (selection.rangeCount > 0) {
			let range = selection.getRangeAt(0); // Get the Range object
			let container = document.createElement('div');
			container.appendChild(range.cloneContents()); // Clone the contents of the range

			this.dialogs.setOpenDialog('TekstbiterDialog', {
				tekst: editor.html.get(),
				merkettekst: container.innerHTML, // Get the HTML of the selected content
			});
		}
	};

	lagretTekstbit() {
		this.dialogs.closeDialog();
		//Update tekster
		this.setState({ merkettekst: '' });
	}

	onInsertTekstbit(val){
		let editor = this.froalaRef.editor;
		editor.selection.restore();
		editor.html.insert('<p>' + val + '</p>');
		editor.selection.save();
		this.handleBeskrivelseChange(editor.html.get());
	};

	openTekstbitSoek() {
		let editor = this.froalaRef.editor;
		editor.selection.save();
		this.dialogs.setOpenDialog('TekstbiterSoekDialog');
	}

	//  ************************************************************************

	lagre() {
		if (
			endretAnmerkning.lukket &&
			!opprinneligLukket &&
			endretAnmerkning.oppfolgingType != null
		) {
			if (endretAnmerkning.andreForhold) {
				this.dialogs.setOpenDialog('IkkeLukkSlettAndreForholdDialog');
			} else {
				this.dialogs.setOpenDialog('IkkeLukkSlettAnmerkningDialog');
			}
		} else {
			if (!endretAnmerkning.tiltakTypeId) {
				this.dialogs.setOpenDialog('TiltakManglerDialog');
				return;
			}
			if (!endretAnmerkning.saksbehandlerId) {
				this.dialogs.setOpenDialog('SaksbehandlerManglerDialog');
				return;
			}
			let bilder = [];

			for (let i = 0; i < this.state.filer.length; i++) {
				let b = this.state.filer[i];
				if (b.ny === true && b.slett === true) {
					//Ingenting
				} else {
					bilder.push(b);
				}
			}

			endretAnmerkning.filer = bilder;
			if (endretAnmerkning.id === '0') {
				anmerkningApi.createAnmerkning(
					endretAnmerkning,
					this.anmerkningLagred.bind(this)
				);
			} else {
				if (!endretAnmerkning.lukket && opprinneligLukket) {
					endretAnmerkning.reApnet = true;
				}
				anmerkningApi.updateAnmerkning(
					endretAnmerkning.id,
					endretAnmerkning,
					this.anmerkningLagred.bind(this)
				);
			}
		}
	}

	slett() {
		if (endretAnmerkning.oppfolgingType != null) {
			if (endretAnmerkning.andreForhold) {
				this.dialogs.setOpenDialog('IkkeLukkSlettAndreForholdDialog');
			} else {
				this.dialogs.setOpenDialog('IkkeLukkSlettAnmerkningDialog');
			}
		} else {
			this.dialogs.setOpenDialog('SletteDialog');
		}
	}

	slettOk() {
		this.dialogs.closeDialog();

		if (endretAnmerkning.id === '0') {
			this.props.onClose();
		} else {
			anmerkningApi.deleteAnmerkning(
				endretAnmerkning.id,
				this.anmerkningLagred.bind(this)
			);
		}
	}

	anmerkningLagred(err, obj) {
		this.props.onLagred();
		this.props.onClose();
	}

	render() {
		let accStyle = {
			width: '100%',
			background: '#f6f6f6',
			borderColor: '#ccc',
			maxHeight: '42px',
			textAlign: 'left',
			padding: '0 10px',
			borderRight: '0',
			borderLeft: '0',
		};

		let chevronStyle = {
			float: 'right',
			marginTop: '12px',
			marginRight: '4px',
		};

		let noZoom = {
			fontSize: '16px',
			transform: 'scale(0.95)',
			transformOrigin: 'top left',
		};

		if (
			!this.state.anmerkning ||
			!this.state.kategorier ||
			!this.state.saksbehandlere ||
			!this.state.saksbehandlereAktiv
		) {
			return <div>Laster...</div>;
		}
		return (
			<div className='modal-content anmerkningheight'>
				<div className='modal-header'>
					<button className='close' type='button' onClick={this.props.onClose}>
						<span>x</span>
						<span className='sr-only'>Lukk</span>
					</button>
					<h4 className='modal-title'> {this.state.tittel} </h4>
				</div>
				<div className='modal-body'
				style={{	
					height: '600px)',
				}}>
					<div className='panel panel-default'>
						<ul className='nav nav-tabs' width={'100%'}>
							<li className='active'>
								<a data-toggle='tab' href='#home'>
									{this.props.type === 'Anmerkning'
										? 'Anmerkning'
										: 'Andre forhold'}
								</a>
							</li>
							<li onClick={this.startHentBilder.bind(this)}>
								<a data-toggle='tab' href='#bilder'>
									Bilder ({this.state.antallBilder})
								</a>
							</li>
						</ul>

						<div className='tab-content'>
							<div
								id='home'
								className='tab-pane fade in active'
								style={{ padding: '16px' }}>
								<div
									className='row idiv margintop8'
									style={{ display: 'inline-block', marginRight: '30px' }}>
									<div className='form-group' style={{ marginBottom: '4px' }}>
										<label>
											<input
												className='checkbox30'
												type='checkbox'
												onClick={this.lukketChange}
												onChange={this.lukketChange}
												checked={this.state.lukket}
											/>
											<span className='checkboxtext18'>Lukket</span>
										</label>
									</div>
								</div>

								<div className='row idiv' style={{ display: 'inline-block' }}>
									<div className='form-group'>
										<label>
											{endretAnmerkning && endretAnmerkning.reApnet ? (
												<input
													className={
														'checkbox30 ' + this.state.visgjelderbygning
													}
													type='checkbox'
													onChange={this.gjelderByggChange}
													onClick={this.gjelderByggChange}
													checked={this.state.gjelderBygg}
													disabled
												/>
											) : (
												<input
													className={
														'checkbox30 ' + this.state.visgjelderbygning
													}
													type='checkbox'
													onChange={this.gjelderByggChange}
													onClick={this.gjelderByggChange}
													checked={this.state.gjelderBygg}
												/>
											)}

											<span
												className={
													'checkboxtext18 ' + this.state.visgjelderbygning
												}>
												Gjelder bygning
											</span>
										</label>
									</div>
								</div>

								<div className='row'>
									{/* Dato */}
									<div className='col-xs-12 col-sm-6 idiv'>
										<div className='form-group '>
											<label>Dato funnet</label>
											<br />
											<DatePicker
												className='width120Heigth32'
												id='anmdato'
												selected={this.state.dato}
												startDate={moment()}
												placeholderText='Velg dato..'
												todayButton={'I dag'}
												onChange={this.datoChange.bind(this)}
												isClearable={false}
												showYearDropdown
											/>
										</div>
									</div>

									{/* Saksbehandler */}
									<div className='col-xs-12 col-sm-6 idiv'>
										<div className='form-group '>
											{this.state.feilSaksbehandler === '' && (
												<label>Saksbehandler</label>
											)}
											{this.state.feilSaksbehandler !== '' && (
												<label style={{ color: 'red' }}>
													{this.state.feilSaksbehandler}
												</label>
											)}

											<select
												style={noZoom}
												className='form-control'
												id='type'
												onChange={this.saksbehandlerChange}
												defaultValue={this.state.anmerkning.saksbehandlerId}>
												{_.map(this.state.saksbehandlereAktiv, function (o, i) {
													return (
														<option key={i} value={o.id}>
															{o.navn}
														</option>
													);
												})}
											</select>
										</div>
									</div>
								</div>
								<div className='row'>
									{/* Tiltakstype */}
									<div className='col-xs-12 col-sm-6 idiv'>
										<div className='form-group '>
											<label>Tiltakstype</label>
											<select
												style={noZoom}
												className='form-control'
												id='type'
												onChange={(e) =>
													(endretAnmerkning.tiltakTypeId = parseInt(
														e.target.value
													))
												}
												defaultValue={this.state.anmerkning.tiltakTypeId}>
												{_.map(this.state.tiltakstyper, function (o, i) {
													return (
														<option key={i} value={o.id}>
															{o.tekst}
														</option>
													);
												})}
											</select>
										</div>
									</div>

									{/* Kategori */}
									<div className='col-xs-12 col-sm-6 idiv'>
										<div className='form-group '>
											<label>Kategori</label>
											<select
												style={noZoom}
												className='form-control'
												id='type'
												onChange={this.kategoriChange}
												defaultValue={this.state.anmerkning.kategoriId}>
												{_.map(this.state.kategorier, function (o, i) {
													return (
														<option key={i} value={o.id}>
															{o.tekst}
														</option>
													);
												})}
											</select>
										</div>
									</div>
								</div>

								{/* Bemerkning */}
								<div className='row idiv'>
									<div className='form-group'>
										<label className='margtop20'>Beskrivelse</label>
										<div
											className='col-xs-6 col-sm-6 col-md-6 col-lg-6 btn-group pull-right nopadding-right'
											role='group'>
											<button
												type='button'
												className='btn btn-default h48 pull-right'
												onClick={this.lagreTekstbit}>
												<NKIcon icon='kopier' color='nk-black' />
											</button>
											<button
												type='button'
												className='btn btn-default h48 pull-right'
												onClick={this.openTekstbitSoek.bind(this)}>
												<NKIcon icon='tekst' color='nk-black' />
											</button>
										</div>
										<br />
										<FroalaEditorComponent
											ref={(el) => {
												this.froalaRef = el;
											}}
											id='editor'
											useClasses={false}
											config={EditorConfig}
											documentReady={true}
											model={this.state.anmerkning.beskrivelse}
											onModelChange={this.handleBeskrivelseChange}
										/>
									</div>
								</div>



							</div>
							<div
								id='bilder'
								className='tab-pane fade'
								style={{ padding: '16px' }}>
								{/* tab Bilder */}
								<div id='2anmerkning'>
									<div>
										<BilderPanel
											onMounted={this.bilderMounted}
											onlisteChange={this.onfillListeChange.bind(this)}
											filListe={this.state.filer}
											onBildeSlettet={this.onBildeSlettet.bind(this)}
											modul='Anmerkninger'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*  Lagre og Slett   */}
				<div
					className='nk-modal-footer'
					style={{
						height: '80px',
						backgroundColor: 'white',
					}}>
					<div style={{marginTop: '12px'}} className='btn-toolbar pull-right' role='toolbar'>
						<button
							type='button'
							onClick={this.slett.bind(this)}
							className='btn btn-default h48'>
							<NKIcon
								icon='soppelbotte'
								size='0.8'
								color='nk-black'
								style={{ marginRight: '5px' }}
							/>
							Slett
						</button>
						<button
							type='button'
							onClick={this.lagre.bind(this)}
							className='btn btn-primary h48'
							style={{ padding: '0 28px' }}>
							OK
						</button>
					</div>
				</div>
				{this.dialogs.renderOpenDialog()}
			</div>
		);
	}
}

export default AnmerkningForm;
