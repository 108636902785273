import React, { Component, Fragment } from 'react';
import * as _ from 'lodash';
import SoekEnheterKart from 'components/Kart/SoekEnheterKart.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import { GeoAccessor } from 'components/Search/Map/GeoAccessor.jsx';
import { CustomQueryBuilderSearch } from 'components/Search/QueryBuilder.js';
import { CreateDefaultQueryMedUnummererte } from 'components/Search/DefaultQuery';
import { getMatrikkelnummer, getAdresse } from 'modules/felles/AdresseHelper';
import { createSokLagret } from 'api/sokLagretApi';
import { getContextFilters, hentFiltersIgjen } from 'context/contextFilters.js';
import './mobile.css';
import { search } from 'utils/searchKitHelper';
import LagreSokDialog from 'components/Search/LagreSok.jsx';
import MobileDetails from './MobileDetails.jsx';
import { getTranslations } from './translationsMobile.js';
import { getSortOptions } from 'components/Search/searchkitOptions';
import { debugFactory } from 'utils';
import { getGeoObjekt } from 'components/Search/GeoAccessorHelper';
import { findProperty } from 'components/Search/FindInArray';
import { timeout } from 'components/Utils/CancellationTimer';
import Filters from './FiltersMobile.jsx';
import CONFIG from 'lib/CONFIG';
import { getAccessToken } from 'auth/auth';
import { reloadSearch } from 'utils/searchKitHelper';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import { getVurderte } from 'api/grunndataApi';

import {
  SearchkitManager,
  SearchkitProvider,
  SortingSelector,
  HitsStats,
  NoHits,
  ResetFilters,
  Hits,
  SearchBox,
  Pagination,
} from 'searchkit';
import 'react-select/dist/react-select.css';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import 'css/generated/themingSearchkit/theme.css'; // Se https://github.com/searchkit/searchkit/blob/develop/theming/vars.scss
import 'css/generated/sokModal.css';
import DialogStore from 'lib/DialogStore';
import norkartIdJs from 'auth/auth';

const getCurrentCustomerId = () => {
  let cust = norkartIdJs.getCurrentCustomerContext();
  return cust !== undefined ? cust.CustomerId : '';
};
const debug = debugFactory('nk:objekter:searchkitenhetssok');

const ES_URL = CONFIG.REACT_APP_API_ENDPOINT;
const ES_INDEX = CONFIG.REACT_APP_ES_INDEX;
const WAAPI_PROFILE = CONFIG.REACT_APP_PROFILE;
var myTimer;

if (!ES_URL || !ES_INDEX) {
  throw new Error('Missing search index settings.');
}

const host = ES_URL + 'api/search/' + ES_INDEX;

let timeOutObj = null;

const modalDialogs = {
  LagreSokDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <LagreSokDialog
      isOpen={isOpen}
      onClose={onClose}
      onLagreSok={owner.onLagreSok}
    />
  ),
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}
      itemId={dialogData.itemSituasjonId}
      bruksenhetIdMa={dialogData.matrikkelenhetId}
      itemAdresse={dialogData.itemsSituasjonAdresse}
      eiendom={dialogData.eiendom}
      matrikkelenhetId={dialogData.matrikkelenhetId}
      fraFiktive={false}
    />
  ),
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}
      onOk={onClose}
    />
  ),
  MatrikkelDialog: (
    { isOpen, onClose, dialogData, owner } //visMatrikkelinfo
  ) => (
    <ModalContainer
      size='medium'
      buttonText={'Lukk'}
      tittel='Matrikkelinfo'
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}>
      <Matrikkelinfo
        id={dialogData.matrikkelinfoId}
        bygningsNr={dialogData.bygningsNr}
      />
    </ModalContainer>
  ),
};
class enhetsSokMobile extends Component {
  accessor = new GeoAccessor();
  constructor() {
    super();
    this.state = {
      itemHeight: '92px',
      test: false,
      geoObjekt: [],
      organisasjonLokasjon: {},
      showSidebar: true,
      accessorState: [],
      sokLagrede: [],
      items: [],
      visDetails: false,
      visFilters: true,
      visKart: false,
      showSidebarArrow: true,
      selectedRowId: null,
      vurderte: [],
      antallroyklopfunnet: '',
      visFilterTekst: 'Vis filter',
      visKartTekst: 'Vis kart',
      currentEnhet: null,
      fraHvor: 'Tilbake til enheter',
      filters: [],
      standardFilter: null,
      standardFilterId: 0,
      filtersInit: false,
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.userShowSidebar = false;
  }

  componentDidMount = () => {
    this.ulListe = React.createRef();
    getContextFilters(this.onGotFilters);
  }

  setGeoAccessor = () => {
    let geoAccessor = this.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    if (geoAccessor === undefined) {
      this.searchkit.accessors.add(this.accessor);
    }
  };

  onGotFilters = (contextFilters) => {
    this.setState(
      {
        filtersInit: true,
        filters: contextFilters.filters,
        standardFilterId: contextFilters.standardFilterId,
        standardFilter: contextFilters.standardFilter,
      },
      this.startSearchKit(contextFilters.standardFilter)
    );
  };

  startSearchKit = (standardFilter) => {
    this.searchkit = new SearchkitManager(host, {
      httpHeaders: {
        Authorization: getAccessToken(),
        'X-WAAPI-Profile': WAAPI_PROFILE,
        'X-WAAPI-CONTEXT' : getCurrentCustomerId()
      },
      useHistory: false,
      searchOnLoad: true,
    });
    this.searchkit.translateFunction = getTranslations;

    this.searchkit.addDefaultQuery((query) => {
      return CreateDefaultQueryMedUnummererte(query, this.props.kommuner);
    });

    this.searchkit.addResultsListener((results) => {
      // this.formatResultTilGeo(results);
      this.formatResult(results);
      // this.getAntallRoyklopFraResults1(results);
    });
    getVurderte((err, result) => {
      this.setState({ vurderte: result });
    });

    this.setGeoAccessor();

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

     //Til slutt ønsket vi ikke en standard søk her...
     reloadSearch(this.searchkit);
/*     setTimeout(() => {
      this.brukLagretSok(standardFilter, true);
    }, 1000);  */

  };

  brukLagretSok = (item, lukke) => {
    if (!item){
      return;
    }
    this.searchkit.getQueryAccessor().keepOnlyQueryState();
    this.searchkit.accessors.setState(JSON.parse(item.state));
    let geoAccessor = this.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    if (item.polygon && geoAccessor) {
      let parsedPolygon = JSON.parse(item.polygon);
      let poly = getGeoObjekt(parsedPolygon.points);
      geoAccessor.setPolygon(null);
      geoAccessor.setPolygon(poly);
    } else if (!item.polygon && geoAccessor) {
      geoAccessor.setPolygon(null);
    }

    reloadSearch(this.searchkit);
    if (lukke){
      this.setState({ visFilters: false });
    } 
  }

  getAntallRoyklopFraResults1 = (results) => {
    clearInterval(myTimer);
    if (this.state.antallroyklopfunnet !== '') {
      this.setState({ antallroyklopfunnet: '' });
    }
    myTimer = setInterval(
      this.getAntallRoyklopFraResults2.bind(null, results),
      2000
    );
  };

  getAntallRoyklopFraResults2 = (results) => {
    clearInterval(myTimer);

    if (results.aggregations) {
      if (results.aggregations.rehabilitert33) {
        if (results.aggregations.rehabilitert33.inner) {
          if (results.aggregations.rehabilitert33.inner.doc_count) {
            this.setState({
              antallroyklopfunnet:
                'Røykløp: ' +
                results.aggregations.rehabilitert33.inner.doc_count,
            });
          }
        }
      }
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    /*  if (prevState.visFilters && !this.state.visFilters) {
      reloadSearch(this.searchkit);
    } */
  }

  componentWillUnmount= () => {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions= () => {
    if (this.userShowSidebar) {
      return;
    } //Bruker har overstyrt synlighet av
    this.setState({
      showSidebar: window.matchMedia('(min-width: 1560px)').matches,
    });
    let min = window.matchMedia('(min-width: 1024px)').matches;

    this.setState({
      showSidebarArrow: min,
      isMapCollapsed: !min,
    });
  }

  formatResult = (results) => {
    var items = _.map(results.hits.hits, function(item) {
      return {
        id: item._source.id,
        source: item._source,
        innerHits: item.inner_hits,
      };
    });

    this.setState({ items: items });
    if (timeOutObj) {
      timeOutObj.cancel();
    }

    timeOutObj = timeout(1500);

    timeOutObj.promise.then(
      function(result) {
        let firstItem = null;
        if (items.length > 0) {
          firstItem = _.first(items);
        }
        this.setState({
          selectedRowId: firstItem ? firstItem.id : null,
          bygningsnummer: firstItem
            ? firstItem.source.bygg.bygningsnummer
            : null,
        });
      }.bind(this)
    );
  };

  onGotOrganisasjonsLokasjon = (err, data) => {
    this.organisasjonLokasjon = data;
  }

  visLagreSokDialog = () => {
    this.dialogs.setOpenDialog('LagreSokDialog');
  };

  onLagreSok = (soklagre) => {
    soklagre.state = JSON.stringify(this.searchkit.accessors.getState());
    let polygon = findProperty(this.searchkit.query.query.query, 'points');
    polygon = _.last(polygon);
    soklagre.polygon = JSON.stringify(polygon);

    //Call API
    this.dialogs.closeDialog();
    debug('LSLS skal lagres', soklagre);
    createSokLagret(soklagre, this.onSokLagret);
  }

  onSokLagret = (err, item) => {
   hentFiltersIgjen();
  }

  onSelectedPerson = () => {
    reloadSearch(this.searchkit);
  };

  itemClick = (e) => {
    for (let i = 0; i < this.ulListe.current.children.length; i++) {
      const element = this.ulListe.current.children[i];
      if (element.id === e.currentTarget.id) {
        element.attributes.style.value = 'height: 92px; backGround: #D0E19B';
        this.setState({ currentBox: element.id });
      } else {
        element.attributes.style.value = 'height: 92px; backGround: White';
      }
    }
  };

  itemButtonClick = (enhet) => {
    this.setState(
      { currentEnhet: enhet, fraHvor: 'Tilbake til enheter' },
      this.visDetails()
    );
  };

  visDetails = () => {
    this.setState({ visDetails: true });
  };

  /////////// KART //////////////////////
  //BOUNDSFILTER *********
  onBoundsFilter = (boundsfilter) => {
    //this.accessor.setArea(boundsfilter);
    search(this.searchkit);
  }
  onBoundsFilterBruk = (bruk) => {
    if (!bruk) {
      //this.accessor.setArea(null);
      search(this.searchkit);
    }
  }
  //POLYGON **************
  //POLYGON **************
  onBrukPolygon = (poly) => {
    let geoAccessor = this.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });

    if (!geoAccessor) {
      return;
    }
    geoAccessor.setPolygon(poly, this.onPolygonFilterRemoved);
    search(this.searchkit);
  };
  onPolygonFjernet = () => {
    let geoAccessor = this.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    if (!geoAccessor) {
      return;
    }
    geoAccessor.setPolygon(null);
    search(this.searchkit);
  };

  onPolygonFilterRemoved = () => {
    let k = this.kart;
    if (k !== null) {
      k.fjernAllePolygonLayers();
    } else {
      console.log('Feil Bruksenhetstabell onPolygonFilterRemoved');
    }
  };

  visDetaljerMobil = (enhet) => {
    this.setState(
      { fraHvor: 'Tilbake til kart', currentEnhet: enhet },
      this.visDetails()
    );
  };

  visMatrikkelInfo = (item) => {
    let bygg = item.bygg ? item.bygg : item.source.bygg;
    this.setState({
      bygningsNr: bygg.bygningsnummer,
    });
    this.dialogs.setOpenDialog('MatrikkelDialog', {
      matrikkelinfoId: item.id,
      bygningsNr: bygg.bygningsnummer,
    });
  }

  visSituasjonInfoMobil = (item) => {
    let enhetid = null;
    let adresse = null;
    let eiendom = getMatrikkelnummer(item.source);

    enhetid = _.get(item, 'source.skybrannData.bruksenhet.fagId');
    if (!enhetid) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Kan ikke åpne situasjon',
        melding: 'Det mangler opplysninger for å åpne Situasjon.',
      });

      return;
    }
    adresse = item.source ? getAdresse(item.source) : 'null';

    this.dialogs.setOpenDialog('EnhetDialog', {
      itemSituasjonId: enhetid,
      matrikkelenhetId:
        item.source.adresse !== undefined
          ? item.source.adresse.id
          : item.source.matrikkelenhet.id,
      itemsSituasjonAdresse: adresse,
      eiendom: eiendom,
    });
  };

  onCloseDialog = () => {
    //
  }

  kartListe = () => {
    return (
      <div>
        <SoekEnheterKart
          id='kart'
          ref={(ref) => (this.kart = ref)}
          enheter={this.state.items}
          kartfra='SoekenheterMobil'
          onBrukPolygon={this.onBrukPolygon}
          onPolygonFjernet={this.onPolygonFjernet}
          onBoundsFilterBruk={this.onBoundsFilterBruk}
          visDetaljerMobil={this.visDetaljerMobil}
          visSituasjonInfo={(item) => this.visSituasjonInfoMobil(item)}
          visMatrikkelInfo={(item) => this.visMatrikkelInfo(item)}
          /*
          
          visArbeidslisteInfo={(item) => this.visArbeidslisteInfo(item)}
         
          onVisGebyr={this.onVisGebyrFraKart} 
          onMarkerClick={(status, id) => this.markerClick(status, id)}*/
        />
      </div>
    );
  };

  boxListe = () => {
    return (
      <ul className='containerM floatM' ref={this.ulListe} id='uListe'>
        {this.state.items.map((item, i) => {
          let klasse = 'itemM floatM-item';
          let extraText = '';
          let col = 'blue';
          if (!_.has(item, 'source.skybrannData.bruksenhet')) {
            extraText = '- mangler fagdata';
            klasse = 'itemMUten floatM-item';
            if (this.isVurdert(item)) {
              extraText = '- vurdert';
              klasse = 'itemMVurdert floatM-item';
            }
          }
          let ff = _.get(item, 'source.skybrannData.bruksenhet.fyringsforbud');
          if (ff) {
            klasse = 'itemMForbud floatM-item';
            extraText = ' - Fyringsforbud';
            col = 'yellow';
          }

          return (
            <li
              onClick={this.itemClick}
              style={{ height: this.state.itemHeight }}
              className={klasse}
              id={'li' + i}
              key={'li' + i}>
              <div>
                <label>{getAdresse(item.source)}</label>
                <br />
                {item.source.kommune.kommuneNavn}-
                {getMatrikkelnummer(item.source)}
                <br />
                {item.source.bruksenhetstype}
                <br />
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'bottom' }}>
                        <span style={{ fontSize: '10px', color: col }}>
                          {extraText}
                        </span>
                      </td>
                      <td>
                        <button
                          id={'li' + i}
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            color: 'black',
                          }}
                          onClick={this.itemButtonClick.bind(this, item)}
                          className='pull-right'>
                          ...
                        </button>
                        &nbsp;&nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  isVurdert = (item) => {
    if (!this.state.vurderte) {
      return false;
    }
    let i = parseInt(item.id);
    var inVurdert = this.state.vurderte.includes(i);
    return inVurdert;
  };

  getSourceFilter = () => {
    return [
      'id',
      'fromMatrikkel',
      'adresse.id',
      'adresse.gatenavn',
      'adresse.husnummer',
      'adresse.bokstav',
      'adresse.type',
      'bygg.lokasjon',
      'bygg.bygningsnummer',
      'matrikkelenhet.id',
      'matrikkelenhet.gardsnummer',
      'matrikkelenhet.bruksnummer',
      'matrikkelenhet.festenummer',
      'matrikkelenhet.seksjonsnummer',
      'matrikkelenhet.bruksnavn',
      'skybrannData.bruksenhet.risikovurdering',
      'skybrannData.bruksenhet.fagId',
      'skybrannData.bruksenhet.fyringsforbud',
      'lokasjon',
      'etasjeplankode',
      'lopenummer',
      'etasjenummer',
      'bruksenhetstype',
      'kommune.kommuneNavn',
      'skybrannData.royklop.plassering',
      'skybrannData.royklop.feieadkomst',
      'skybrannData.royklop.id',
      'skybrannData.royklop.type',
      'skybrannData.royklop.risikoverdi',
      'skybrannData.royklop.risikoverdiOverstyr',
      'skybrannData.royklopTiltakType.nesteDato',
      'skybrannData.royklopTiltakType.tiltakType',
      'skybrannData.bruksenhetTiltakType.nesteDato',
      'skybrannData.bruksenhetTiltakType.tiltakType',
    ];
  };

  toggleFilter = () => {
    let vis = this.state.visFilters;
    vis = !vis;
    let tekst = 'Vis filter';
    if (vis) {
      tekst = 'Skjul filter';
    }
    this.setState({ visFilters: vis, visFilterTekst: tekst });
  };

  toggleKart = () => {
    let vis = this.state.visKart;
    vis = !vis;
    let tekst = 'Vis kart';
    if (vis) {
      tekst = 'Vis enheter';
    }
    this.setState({ visKart: vis, visKartTekst: tekst });
  };


  render() {
    if (!this.searchkit) {
      return <div>Lader søk...</div>;
    }
    if (!this.state.filtersInit) {
      return <div>Lader filter...</div>;
    }

    return (
      <div style={{ marginTop: '6px' }}>
        <SearchkitProvider searchkit={this.searchkit}>
          <div>
            {this.state.visDetails && (
              <div
                style={{
                  paddingTop: '3px',
                  paddingLeft: '1px',
                  paddingRight: '10px',
                }}>
                <button
                  className='btn'
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    marginLeft: '6px',
                  }}
                  onClick={() => this.setState({ visDetails: false })}>
                  {this.state.fraHvor}
                  {/* Tilbake til enheter Tilbake til kart*/}
                </button>
                <br />
                <br />
                <MobileDetails enhet={this.state.currentEnhet} />
              </div>
            )}
          </div>
          {!this.state.visDetails && (
            <div>
              <div id='outer'>
                <div className='inner'>
                  <button
                    className='btn'
                    style={{
                      backgroundColor: 'black',
                      color: 'white',
                      marginLeft: '6px',
                    }}
                    onClick={this.toggleFilter}>
                    {this.state.visFilterTekst}
                  </button>
                </div>
                <div className='inner'>
                  <ResetFilters
                    translations={{
                      'reset.clear_all': 'Nullstill filtre',
                    }}
                  />
                </div>
                <div className='inner'>
                  <button className='btn btn-default' onClick={this.toggleKart}>
                    {this.state.visKartTekst}
                  </button>
                </div>
                <div className='inner'>
                  <HitsStats />
                </div>

                <div className='inner'>
                  <Pagination
                    style={{ height: '34px' }}
                    pageScope={2}
                    showNumbers={false}
                    showText={true}
                    showLast={true}
                  />
                </div>
              </div>

              <div>
                {this.state.visFilters && (
                  <Fragment >
                    <Filters
                      sokLagrede={this.state.sokLagrede}
                      brukLagretSok={this.brukLagretSok}
                      visLagreSokDialog={this.visLagreSokDialog}
                      onSelectedPerson={this.onSelectedPerson}
                    />
                  </Fragment>
                )}
              </div>

              <div className='row'>
                <div style={{ paddingLeft: '4px' }} className='col-sm-6'>
                  <SearchBox
                    queryBuilder={CustomQueryBuilderSearch}
                    searchThrottleTime={400}
                    searchOnChange={true}
                    translations={{
                      'searchbox.placeholder': 'Adressesøk: Storgata 1',
                    }}
                  />
                </div>
                <div style={{ paddingLeft: '4px' }} className='col-sm-6'>
                  <SortingSelector options={getSortOptions()} />
                </div>
              </div>
              <div />

              {this.state.visKart && (
                <Hits
                  hitsPerPage={250}
                  sourceFilter={this.getSourceFilter()}
                  listComponent={this.kartListe}
                  scrollTo='body'
                />
              )}
              {!this.state.visKart && (
                <Hits
                  hitsPerPage={250}
                  sourceFilter={this.getSourceFilter()}
                  listComponent={this.boxListe}
                  scrollTo='body'
                />
              )}
              <NoHits />
            </div>
          )}
        </SearchkitProvider>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default enhetsSokMobile;
