import React, { Component } from 'react';
import { debugFactory, promisifyApiCall } from 'utils';
import './diagram.css';
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from 'react-component-export-image';
import {
  BarChart,
  Bar,
  Tooltip,
  Label,
  Legend,
  LabelList,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

const debug = debugFactory('nk:Diagram');
const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;
  debug(props);
  if (props.value === 0) {
    return null;
  } else {
    return <Label {...rest} fontSize='12' fill='black' fontWeight='bold' />;
  }
};

class HorBarTiltak extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = { expand: true, antallRaderEnhet: 61, antallRaderRoyklop: 61 };
  }

  componentDidMount() {
    let xE = this.props.dataEnhet.length * 61;
    let xR = this.props.dataRoyklop.length * 61;
    this.setState({ antallRaderEnhet: xE });
    this.setState({ antallRaderRoyklop: xR});
  }

  makeWrap = (data) => {
    if (!data) {
      return null;
    }
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      debug('FFFF', element);
      if ( element.tiltakType.length > 21) {
        element.tiltakType =  element.tiltakType.substring(0, 20) + "..";
      }
      element.tiltakType = element.tiltakType.replace(/\s/g, '_');
     /*  if (element.tiltakType === 'Informasjonskampanje') {
        element.tiltakType = 'Informasjons kampanje';
      }  */
      element.utført = element.antallUtfort;
      let key = 'ikke utført';
      element[key] = element.antallIkkeUtfort;
    }
    return data;
  };

  getExpand = () => {
    if (this.state.expand) {
      return 'expand';
    } else {
      return 'none';
    }
  };

  render() {
    const data = this.makeWrap(this.props.dataEnhet);
    const dataR = this.makeWrap(this.props.dataRoyklop);
    return (
      <div style={{ maxWidth: '600px', width: '90%', height: '600px' }}>
        <div ref={this.componentRef}>
          <span style={{ fontSize: '12px' }}>{this.props.periode}</span>
          <ResponsiveContainer height={ this.state.antallRaderEnhet } width={'100%'}>
            <BarChart
              layout='vertical'
              data={data}
              margin={{ left: 90, right: 50 }}
              stackOffset={this.getExpand()}>
              <XAxis hide type='number' />
              <YAxis type='category' dataKey='tiltakType' fontSize='12' /> {/* //domain={[0, 'dataMax + 600']} */}
              <Tooltip />
              <Bar dataKey='utført' fill='#51a026' stackId='a' >
                <LabelList
                  dataKey='utført'
                  position='center'
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey='ikke utført' fill='#e96767' stackId='a' >
                <LabelList
                  dataKey='ikke utført'
                  position='center'
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Legend layout='horizontal' verticalAlign='top' align='center' />
            </BarChart>
          </ResponsiveContainer>
          <br />
          <ResponsiveContainer height={this.state.antallRaderRoyklop} width={'100%'}>
            <BarChart
              layout='vertical'
              data={dataR}
              margin={{ left: 90, right: 50 }}
              stackOffset={this.getExpand()}>
              <XAxis hide type='number' />
              <YAxis type='category' dataKey='tiltakType' fontSize='12' />
              <Tooltip />
              <Bar dataKey='utført' fill='#51a026' stackId='a'>
                <LabelList
                  dataKey='utført'
                  position='center'
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Bar dataKey='ikke utført' fill='#e96767' stackId='a'>
                <LabelList
                  dataKey='ikke utført'
                  position='center'
                  content={renderCustomizedLabel}
                />
              </Bar>
              <Legend layout='horizontal' verticalAlign='top' align='center' />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <button
          className='btn btn-link'
          onClick={() => {
            this.setState({ expand: !this.state.expand });
          }}>
          Utvidet/Enkelt
        </button>
        <button
          className='btn btn-link'
          onClick={() =>
            exportComponentAsPNG(this.componentRef, {
              fileName: 'RegistrerteTiltak',
            })
          }>
          Last ned som bilde
        </button>
      </div>
    );
  }
}

export default HorBarTiltak;
