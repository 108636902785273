import React from 'react';
import Test from './Test.jsx';

export default class extends React.Component {
  state = { breadcrumbname: 'Laster....' };

  render() {
    return (
      <div>
        <Test />
      </div>
    );
  }
}
