import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Debug from 'debug';
import {
    compose,
    getContext
} from 'recompose';
import ErrorBoundary from 'components/exceptions/ErrorBoundary';
import RouteFallbackUi from 'components/exceptions/RouteFallbackUi';
import withChildren from './withChildren';

const debug = Debug('nk:lib:enhancers:asroutecomponent');

const withRouterContext = compose(
    // `withRouter` is a mess, polluting enhanced component's `props` with its own props, all of which are accesible through `router` anyways...
    // - So extract just `router` from `context`, not `location`, `params` etc.
    getContext({
        router: PropTypes.object,
    })
);

const ErrorBoudaryWithRouter = withRouterContext(ErrorBoundary);

const withErrorBoundary = ({
    handledAt,
    FallbackUi
}) => (Component) => {

    const withErrorBoundary = function(props) {
      //  debug('withErrorBoundary', { ...props });
        return (
            <ErrorBoudaryWithRouter fallbackUi={FallbackUi} handledAt={handledAt}>
                <Component {...props} />
            </ErrorBoudaryWithRouter>
        );
    };

    return withErrorBoundary;
};

const asRouteComponent = compose(
    withChildren,
    withRouterContext,
    withErrorBoundary({
        handledAt: ({ router }) => `asRouteComponent:withErrorBoundary:${_.last(router.routes).name}`,
        FallbackUi: RouteFallbackUi,
    }),
);

export default asRouteComponent;