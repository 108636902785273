import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/store.js';
import { createErrorBoundary } from 'components/exceptions/ErrorBoundary.jsx';
import RouteFallbackUi from 'components/exceptions/RouteFallbackUi';
import Routes from 'routes';
import { AuthProvider } from 'norkartidjs2';

import norkartIdJs from 'auth/auth';

// TODO! FIXME! Is this working?
const ErrorBoundary = createErrorBoundary({
    handledAt: () => 'app-root',
    FallbackUi: RouteFallbackUi,
});

export default (
    <Provider store={store}>
        <ErrorBoundary>
            <AuthProvider auth={norkartIdJs}>
                {Routes}
            </AuthProvider>
        </ErrorBoundary>
    </Provider>
);