import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react';
import moment from 'moment';
import NKIcon from 'nka-icons';
import { v4 as uuidv4 } from 'uuid';
import BFButton from 'components/BFButtonGroup/BFButton.js';
import { createAvvikKommentar } from 'api/avvikApi.js';
import { nyOppgaveKommentar } from 'api/smkApi.js';
//Froala ===========================================
import 'froala-editor/css/froala_style.min.css';
/* import 'froala-editor/css/froala_editor.pkgd.min.css'; */
import 'froala-editor/js/languages/nb.js';
/* import 'froala-editor/js/plugins.pkgd.min.js';   */
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
/* import 'froala-editor/js/plugins/image.min.js'; */
import FroalaEditorComponent from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import { configX } from './FroalaConfigKommentar.js';
import { getEksempeltekst } from 'components/Editor/testtekst.js';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
//=============================================


const Kommentar = React.forwardRef(({ ...props }, ref) => {

    /* function Kommentar({
  minOppgave,
  kommentarer,
  bruker,
  screenModus,
  hentMainDataPaaNytt,
}) { */
    const froalaRefNy = useRef(null);

    const [endreKommentarId, setEndretKommentarId] = useState(0);
    const [nyTekst, setNyTekst] = useState('');
    const [buttons, setButtons] = useState(false);

    const setNyKommentar = (text) => {
      if (nyTekst.includes('Dokumenttekst har blitt endret.')) {
        return;
      }
      let t = text + nyTekst;
      setNyTekst(t);
    };

    const kommentarSkalLagreFirst = () => {
      return nyTekst.length > 0;
    };

    useImperativeHandle(ref, () => ({
      setNyKommentar,
      kommentarSkalLagreFirst,
    }));

    useEffect(() => {
      if (!buttons) {
        setButtons(true);
        makeFroalaToolButtons();
      }
    }, [buttons]);

    const makeFroalaToolButtons = () => {
      if (
        contextInnstillingerPerson._currentValue.norkartAdmin ||
        contextInnstillingerPerson._currentValue.norkartBruker
      ) {
        Froalaeditor.DefineIcon('starX', {
          NAME: 'starX',
          SVG_KEY: 'star',
        });
        Froalaeditor.RegisterCommand('starX', {
          title: 'Test-tekst',
          icon: 'starX',
          callback: () => {
            let s = getEksempeltekst();

            froalaRefNy.current.editor.selection.save();
            froalaRefNy.current.editor.selection.restore();
            froalaRefNy.current.editor.html.insert(s);

            //Jeg ønsker å hide extra toolbar'n
            let toolbarExtra = document.getElementsByClassName(
              'fr-more-toolbar position-relative fr-expanded'
            );
            let toolbarBasic = document.getElementsByClassName(
              'fr-toolbar fr-ltr fr-desktop fr-top fr-basic fr-sticky-off fr-toolbar-open'
            );
            let toolbarButton = document.getElementsByClassName(
              'fr-command fr-btn fr-open'
            );
            toolbarExtra[0].classList.remove('fr-expanded');
            toolbarBasic[0].classList.remove('fr-toolbar-open');
            toolbarButton[0].classList.remove('fr-open');
          },
        });
      }
    };


    const afterNySlettOgEndre = () => {
      //nullstill
      setEndretKommentarId(0);
      setNyTekst('');
      //hent på nytt
      // hentMainDataPaaNytt();
      props.oppdaterParent();
    };

    const doNyOppgaveKommentar = () => {
      let d = moment().format();
      d = d.replace('+01:00', '+00:00');
      d = d.replace('+02:00', '+00:00');
      let tekst = nyTekst.trim();
      if (tekst === '') {
        alert('Skriv noe i kommentarfelt');
        return;
      }

      if (props.parent === 'A'){
        nyKommentarKlargjorRapport(d, tekst);
      } else {
        nyKommentarUnderBehandling(d, tekst);
      }
    
    };

    const nyKommentarKlargjorRapport = (d, tekst) => {
      let k = uuidv4();
      let idX = props.minOppgave.arbeidslisteBruksenhetOppgave.id;
      let param = {
        id: 0,
        bruksenhetOppgaveId: idX,
        kommentar: tekst,
        kommentarGuid: k,
        registrertDato: d,
      };
      nyOppgaveKommentar(param, afterNySlettOgEndre);
    };

    const nyKommentarUnderBehandling = (d, tekst) => {
      let k = uuidv4();
      let param = {
        id: 0,
        avvikId: props.avvikId,
        kommentar: tekst,
        kommentarGuid: k,
        registrertDato: d,
      };
      createAvvikKommentar(param, afterNySlettOgEndre);
    };

    const getKommentarBox = () => {
      let divStyle = {
        /*    overflowY: 'auto', */
        maxHeight: 'calc(100vh - 330px',
        marginBottom: '8px',
      };
      if (!props.kommentarer) {
        divStyle = {
          /* overflowY: 'auto', */
          maxHeight: 'calc(100vh - 330px',
          marginBottom: '0px',
        };
      }
      let kommentarPanel = (
        <div
          style={{
            minHeight: props.screenModus === 'stor' ? 'calc(100vh - 160px)' : 'auto',
            /*  minWidth: screenModus === 'stor' ? '200px' : '400px', */
            //  maxWidth: screenModus === 'stor' ? '100%' : '600px',
          }}>
          <div className='text-top-extra' id='kommentarerDiv' style={divStyle}>
            {/*  <span>{screenModus === 'stor' && <label>Kommentar</label>}</span>
          <br /> */}

            <label
              style={{ marginTop: props.screenModus === 'stor' ? '6px' : '14px' }}>
              Kommentar
            </label>

            {props.kommentarer && (
              <>
                <div className='kontaktScroll'>
                  {props.kommentarer.map((kommentar, index) => {
                    return (
                      <Fragment key={'fr' + index}>
                        <div style={{ width: '98%', overflowX: 'hidden' }} key={'sx'+ index}>
                          <span
                            style={{
                              marginLeft: '30px',
                              fontSize: '12px',
                            }}>
                            {moment(kommentar.registrertDato).format(
                              'DD.MM.YYYY - HH:mm'
                            )}
                          </span>
                          <br />
                          <span>
                            <NKIcon icon='bruker' size='0.8' color='nk-black' />
                          </span>
                          <span
                            style={{
                              fontWeight: 'bold',
                              marginLeft: '10px',
                              fontSize: '14px',
                            }}>
                            {kommentar.registrertAv}
                          </span>

                          {/*                       {bruker === kommentar.registrertAv &&
                        endreKommentarId !== kommentar.id && (
                          <span className='pull-right'>
                            <button
                              title='Endre'
                              onClick={() => endreKommentar(kommentar)}
                              className='btn btn-default'>
                              <NKIcon
                                icon='rediger'
                                size='0.8'
                                color='nk-black'
                              />
                            </button>
                            <button
                              title='Slett'
                              onClick={() => slettKommentar(kommentar)}
                              className='btn btn-default'>
                              <NKIcon
                                icon='soppelbotte'
                                size='0.8'
                                color='nk-black'
                              />
                            </button>
                          </span>
                        )} */}
                          <br />
                       {/*    {endreKommentarId === kommentar.id && (
                            <div style={{ width: '100%' }}>
                              <br />
                              <FroalaEditorComponent
                                ref={froalaRefNy}
                                id='editorRediger'
                                config={configX()}
                                useClasses={false}
                                documentReady={true}
                                model={redigerTekst}
                                onModelChange={doSetRedigerTekst}
                              />

                              <br />
                              <button
                                style={{ marginLeft: '4px' }}
                                onClick={endreSidemannsKommentar}
                                className='pull-right btn btn-success'>
                                Lagre kommentar
                              </button>
                              <button
                                onClick={endreAvbrytSidemannsKommentar}
                                className='pull-right btn btn-default'>
                                Avbryt
                              </button>

                              <br />
                            </div>
                          )} */}

                          {endreKommentarId !== kommentar.id && (
                            <div
                              style={{
                                left: '0px',
                                padding: '0px',
                                transformOrigin: 'top left',
                                transform: 'scale(0.8)',
                                width: '120%',
                                /*  width: '100%', */
                                /*        minWidth:
                              screenModus === 'stor' ? '200px' : '400px',
                            maxWidth:
                              screenModus === 'stor' ? '600px' : '400px', */
                                /*  overflowY: 'auto', */
                                maxHeight: '200px',
                              }}>
                              <span
                                style={{ maxHeight: '200px' }}
                                dangerouslySetInnerHTML={{
                                  __html: kommentar.kommentar,
                                }}
                              />
                            </div>
                          )}
                        </div>

                        <hr
                          style={{
                            marginLeft: '0px',
                            marginTop: '4px',
                            marginBottom: '10px',
                            borderTop: '1px solid green',
                            width: '98%',
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {endreKommentarId < 1 && (
            <div style={{ width: '98%' }}>
              <FroalaEditorComponent
                id='editorNy'
                ref={froalaRefNy}
                config={configX()}
                useClasses={false}
                documentReady={true}
                model={nyTekst}
                onModelChange={(val) => setNyTekst(val)}
              />

              <span className='pull-right' style={{ marginTop: '2px' }}>
                <BFButton
                  disabled={nyTekst.length === 0}
                  buttonLabel={['Slett']}
                  farget={false}
                  buttonWidth={'100px'}
                  afterClick={() => setNyTekst('')}></BFButton>
                <BFButton
                  disabled={nyTekst.length === 0}
                  buttonLabel={['Lagre kommentar']}
                  farget={true}
                  buttonWidth={'180px'}
                  afterClick={doNyOppgaveKommentar}></BFButton>
              </span>
            </div>
          )}
          <br />
        </div>
      );
      return kommentarPanel;
    };
    return getKommentarBox();
  }
);

export default Kommentar;
