import { debugFactory, proxyFactory, objGetDeep } from 'utils';

const debug = debugFactory('nk:lib:config');

const devConfigOverrrides = {
    debugMode: objGetDeep(window, 'localStorage.debug'),
    requiresAuth_usePermissionsOrg: objGetDeep(window, 'localStorage.usePermissionsOrg'),  
    appInsights_instrumentationKey: objGetDeep(window, 'localStorage.instrumentationKey'),
    throwCaughtExceptions: objGetDeep(window, 'localStorage.throwCaughtExceptions'),
};

debug('devConfigOverrrides', { ...devConfigOverrrides });

export default proxyFactory({
    ...devConfigOverrrides,
    ...process.env,
    ...global.__CONFIG
}, {
    get: (target, prop) => {
        const value = (target[prop] === false 
            ? target[prop] // If expicitly `false` return just that
            : target[prop] || '' // Else return empty string for `undefined`/non-truthy
            );
            // debug(prop, value)
        return value;
    }
});