import React from 'react';
import 'react-toggle-switch/dist/css/switch.min.css';
import '.././grunndata.css';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
var _ = require('underscore');

var id = 0;
class NyEndreOppslagsdataModel extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleTekstChange = this.handleTekstChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.dataLagret = this.dataLagret.bind(this);
    this.objektChange = this.objektChange.bind(this);
    this.handleUrlChange = this.handleUrlChange.bind(this);
    this.state = {
      lukket: true,
      tekst: '',
      dokumentasjon: ''
    };
  }
  handleTekstChange(data) {
    this.setState({ tekst: data.target.value });
  }
  handleUrlChange(data) {
    this.setState({ dokumentasjon: data.target.value });
  }
  componentDidMount() {
    this.tekstInput.focus();
    if (this.props.nyEndre === 'Endre') {
      id = this.props.valgtItem.id;
      this.setState({
        tekst: this.props.valgtItem.tekst,
        lukket: this.props.valgtItem.aktiv,
        produsentId: this.props.valgtItem.produsentId,
        dokumentasjon: this.props.valgtItem.dokumentasjon
      });
    } else {
      this.setState({ produsentId: this.props.produsenter[0].id });
    }
  }
  objektChange(data) {
    this.setState({ produsentId: data.target.value });
  }
  submit() {
    var model = {
      id: id,
      tekst: this.state.tekst,
      aktiv: this.state.lukket,
      produsentId: this.state.produsentId,
      dokumentasjon: this.state.dokumentasjon
    };
    if (this.props.nyEndre === 'Ny') {
      if (this.props.type === 'Ildsted') {
        oppslagsdataApi.saveIldstedModell(model, this.dataLagret);
      } else {
        oppslagsdataApi.saveRoyklopModell(model, this.dataLagret);
      }     
    } else {
      if (this.props.type === 'Ildsted') {
        oppslagsdataApi.updateIldstedModell(id, model, this.dataLagret);
      } else {
        oppslagsdataApi.updateRoyklopModell(id, model, this.dataLagret);
      }
    }
  }
  dataLagret(err, nyEndreitem) {
    this.props.dataLagret(nyEndreitem);
  }
  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    this.setState({ utfort: lukket });
  }
  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">
            {this.props.nyEndre} oppslagsdata for: {this.props.type} -{' '}
            {this.props.tabell}
          </h4>
        </div>
        <div className="modal-body">
          <div className="form margin-left-15 margin-right-15">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group ">
                  <br />
                  <input className="checkbox30" type="checkbox" onClick={this.lukketChange} checked={this.state.lukket} />
                  <span className="checkboxtext18">
                    Aktiv
                  </span>
                </div>
                <div className="form-group">
                  <label>Produsent</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.objektChange}
                    value={this.state.produsentId}>
                    {_.map(this.props.produsenter, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Tekst</label>
                  <textarea
                    ref={(input) => { this.tekstInput = input; }}
                    className="form-control textareaNoresize"
                    type="textarea"
                    id="message"
                    placeholder="Tekst"
                    rows="2"
                    onChange={this.handleTekstChange}
                    value={this.state.tekst}
                    maxLength = '100'
                  />
                </div>
                <div className="form-group">
                  <label>Dokumentasjon url</label>
                  <input
                    className="form-control"
                    type="text"
                    id="url"
                    onChange={this.handleUrlChange}
                    value={this.state.dokumentasjon}
                    maxLength = '250'
                  />
                </div>

                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NyEndreOppslagsdataModel;
