import React from 'react';
import Debug from 'debug';
import _ from 'lodash';
import {
    objGetDeep
} from 'utils';
import {
    isClassComponent,
    isElement,
    getHocDisplayName
} from 'utils/components';

const debug = Debug('nk:lib:enhancers:withchildren');


const withChildren = (Component) => {
    // Renders any `children` on the `Component` instead of the component
    // https://medium.com/@franleplant/react-higher-order-components-in-depth-cf9032ee6c3e

    if (isClassComponent(Component)) {
        const type = 'isClassComponent';

        class WithChildren extends Component {
            render() {
                const hasChildren = !!(objGetDeep(this, 'props.children'));
                debug('withChildren', { type, hasChildren, Component });
                return hasChildren
                    ? (this.props.children)
                    : (<Component {...this.props} />);
            }
        }

        return withChildren;
    }

    else if (isElement(Component)) {
        const type = 'isElement';
        const hasChildren = !!(objGetDeep(Component, 'props.children'));
      //  debug('withChildren', { type, hasChildren, Component });
        return (hasChildren
            ? Component.props.children
            : Component);
    }

    else if (_.isFunction(Component)) {
        const type = '_.isFunction';

        const withChildren = function(props) {
            const hasChildren = !!props.children;
          //  debug('withChildren', { type, hasChildren, Component, props });
            return hasChildren
                ? (props.children)
                : (<Component {...props} />);
        };

        return withChildren;
    }
    else {
        const errorMsg = 'Could not determine `Component`-type!';
       // debug('withChildren', errorMsg, { Component });
        throw new Error('withChildren: ' + errorMsg);
    }
};

export default withChildren;