/* eslint-disable eqeqeq */
import React from 'react';
import { withRouter } from 'lib/enhancers';
import ReactTable from '@norkart/react-table';
import 'moment/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import enhetApi from 'api/enhetApi';
import arkivApi from 'api/arkivApi';
import MeldingDialog from 'modules/Gjennomforing/Tiltakstyper/melding.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ReactFileReader from 'react-file-reader';
import DialogStore from 'lib/DialogStore';
import { getOrganisasjon } from 'api/organisasjonApi';
import { getOrgArkivInfo } from 'context/contextInnstillinger.js';
import {
  uuid
} from 'utils' ;
require('@norkart/react-table/dist/react-table.css');
var _ = require('underscore');

var nysak = false;
let kliste = [];

const modalDialogs = {
  MeldingDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="small" isOpen={isOpen}>
        <MeldingDialog
          onClose={onClose}
          onOK={owner.closeMelding}
          meldingHeader={dialogData.meldingHeader}
          melding={dialogData.melding}
        />
      </NKModal>
    );
  },
};

class Arkiv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bruksenhet: null,
      apenSak: false,
      arkivApenSak: null,
      jps: null,
      hentsakfeilet: false,
      kategorier: null,
      valgtKategori: null,
      eiendom: null,
      kobleCheck: false,
      isLoading: true,
      arkivSakResult: <div>Sak ikke hentet</div>,
      jpsResult: <div>Journalposter ikke hentet</div>,
      oppdatertSakResult: <div>Ingen sak oppdatert</div>,
      historieSakResult: <div>Ingen saker hentet</div>,
      sakLagretResultat: <div>Ingen sak lagret</div>,
      oppdaterBruksenhetSakStatus: null,
      fil: null,
      innstillinger: null,
      lagretJp: null,
      jpLagret: false,
      lagrer: false,
      egenTittel: false,
      org: null,
      arkivInfo: null,
      saksarStatus: null,
      saksarBruksenhet: null,
      sekvensStatus: null
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }
  componentDidMount = () => {
    enhetApi.getBruksenhet(this.props.id, this.gotBruksenhet);
    enhetApi.getBruksenhetEiendom(this.props.id, this.gotBruksenhetEiendom);
    arkivApi.getAlleSakskategorier(null, this.gotKategorier);
    arkivApi.getArkivInnstillinger(uuid(), this.gotInnstillinger);
    getOrganisasjon(this.gotOrganisasjon);
    //getOrganisasjonArkivInfo(this.gotArkivInfo);
    getOrgArkivInfo(this.gotArkivInfo);
    
  };

  getColumns = () => {
    return [
      {
        id: 'tittel',
        name: 'Tittel',
        filterable: false,
        isSorted: false,
        sortable: false,
      },
      {
        id: 'journalstatus',
        name: 'Status',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.journalstatus.kodeverdi;
        },
      },
      {
        id: 'journalposttype',
        name: 'Type',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.journalposttype.kodeverdi;
        },
      },
      {
        id: 'journaldato',
        name: 'Journal dato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.journaldato === null) {
            return '-';
          } else {
            var dato = new Date(item.journaldato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      // {
      //   id: 'Arkivdel',
      //   name: 'Arkivdel',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: false
      // },
      // {
      //   id: 'KlasseListe',
      //   name: 'KlasseListe',
      //   filterable: false,
      //   isSorted: false,
      //   sortable: false,
      //   formatter: function(item) {
      //     console.log(item);
      //     return  item.KlasseListe != null && item.KlasseListe.length > 0 ? item.KlasseListe[0].Klassifikasjonssystem : null;
      //   }
      // }
    ];
  };

  getColumnsSaker = () => {
    return [
      {
        id: 'tittel',
        name: 'Tittel',
        filterable: false,
        isSorted: false,
        sortable: false,
      },
      {
        id: 'saksnummer',
        name: 'Saksnummer',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            item.saksnummer.saksaar + '/' + item.saksnummer.sakssekvensnummer
          );
        },
      },
      {
        id: 'saksstatus',
        name: 'Status',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.saksstatus.kodeverdi;
        },
      },
      {
        id: 'saksdato',
        name: 'Sak dato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.saksdato === null) {
            return '-';
          } else {
            var dato = new Date(item.saksdato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
    ];
  };

  gotKategorier = (err, res) => {
    this.setState({ kategorier: res, isLoading: false });
    if (res != null && res.length>0) {
      this.setState({ valgtKategori: res[0] });
    }
  }

  gotInnstillinger = (err, ins) => {
    this.setState({ innstillinger: ins });
    console.log('CONTEXT_X', ins);
  }

  gotBruksenhet = (err, data) => {
    this.setState({ bruksenhet: data });
    if (
      data.saksAr != null &&
      data.saksAr != 0 &&
      data.saksAr != undefined &&
      data.saksSekvensnr != null &&
      data.saksSekvensnr != 0 &&
      data.saksSekvensnr != undefined
    ) {
      this.setState({ apenSak: true, nyJpAar: data.saksAr, nyJpSekvens: data.saksSekvensnr });

    }
  };

  gotBruksenhetEiendom = (err, data) => {
    this.setState({ eiendom: data });
  };

  gotOrganisasjon = (err, res) => {
    this.setState({ org: res });
  }

  gotArkivInfo = (res) => {
    this.setState({ arkivInfo: res.data });
  }

  HentSak = () => {
    if (this.state.apenSak) {
      arkivApi.getSakForSaksnummer(
        this.state.bruksenhet.saksAr,
        this.state.bruksenhet.saksSekvensnr,
        this.SakHentet
      );
      this.setState({ arkivSakResult: <div>Henter sak ...</div> });
    }
  };

  SakHentet = (err, sak) => {
    if (err != undefined) {
      this.setState({ arkivSakResult: <div>Hent sak feilet</div> });
    } else {
      this.setState({ arkivApenSak: sak });
      this.visHentetSak(sak);
    }
  };

  visHentetSak = (sak) => {
    var result = null;
    var datoRes;
    if (sak == null || sak == undefined) {
      result = <div>Sak ikke funnet</div>;
    } else {
      if (sak.saksdato === null) {
        datoRes = '-';
      } else {
        var dato = new Date(sak.saksdato);
        var dag = dato.getDate().toString();
        var mnd = (dato.getMonth() + 1).toString();
        var aar = dato.getFullYear().toString();
        if (dag.length === 1) {
          dag = '0' + dag;
        }
        if (mnd.length === 1) {
          mnd = '0' + mnd;
        }
        datoRes = dag + '.' + mnd + '.' + aar;
      }
      result = (
        <div>
          <table>
            <tr>
              <td>Sakstittel</td>
              <td> </td>
              <td>{this.state.arkivApenSak.tittel}</td>
            </tr>
            <tr>
              <td>Saksstatus</td>
              <td> </td>
              <td>{this.state.arkivApenSak.saksstatus.kodeverdi}</td>
            </tr>
            <tr>
              <td>Mappetype</td>
              <td> </td>
              <td>{this.state.arkivApenSak.mappetype.kodeverdi}</td>
            </tr>
            <tr>
              <td>Journalenhet</td>
              <td> </td>
              <td>{this.state.arkivApenSak.journalenhet.kodeverdi}</td>
            </tr>
            <tr>
              <td>Arkivdel</td>
              <td> </td>
              <td>{this.state.arkivApenSak.referanseArkivdel.kodeverdi}</td>
            </tr>
            <tr>
              <td>Adm.Enhet</td>
              <td> </td>
              <td>{this.state.arkivApenSak.administrativEnhetInit}</td>
            </tr>
            <tr>
              <td>SaksansvarligInit</td>
              <td>&nbsp;&nbsp;</td>
              <td>{this.state.arkivApenSak.saksansvarligInit}</td>
            </tr>
            <tr>
              <td>SystemId</td>
              <td> </td>
              <td>{this.state.arkivApenSak.systemID}</td>
            </tr>
            <tr>
              <td>Saksdato</td>
              <td> </td>
              <td>{datoRes}</td>
            </tr>
          </table>
          <br />
        </div>
      );
    }
    this.setState({ arkivSakResult: result });
  };

  HentJps = () => {
    if (this.state.apenSak != null) {
      arkivApi.getJournalposterForSak(
        this.state.bruksenhet.saksAr,
        this.state.bruksenhet.saksSekvensnr,
        this.JpsHentet
      );
      this.setState({ jpsResult: <div>Henter journalposter ...</div> });
    }
  };

  JpsHentet = (err, jps) => {
    this.setState({ jps: jps });
    let result = <div>Ingen journalposter funnet</div>;
    if (jps != null && jps != undefined) {
      if (jps.length > 0) {
        result = (
          <div>
            <ReactTable
              items={this.state.jps}
              columns={this.getColumns()}
              showIndex={false}
            />
          </div>
        );
      }
      this.setState({ jpsResult: result });
    }
    // arkivApi.getAlleSakskategorier(null, (err, res) => {
    //   this.setState({ kategorier: res, isLoading: false });
    // });
    // arkivApi.getSakerForKlassering('GID', '43/263/0/0', (err, res) => {
    //   console.log(res);
    // });
  };

  kategoriValgt = (val) => {
    var kategori = _.find(this.state.kategorier, function(kat) {
      return kat.Navn === val.target.value;
    });
    console.log(kategori);
    this.setState({ valgtKategori: kategori });
  };

  handleTittelChange = (val) => {
    this.setState({ sakTittel: val.target.value });
  };

  submitNySak = () => {
    let brukTittel = this.state.sakTittel;
    //let klasseTittel = null;

    if (!this.state.egenTittel) {
      brukTittel = this.formaterSakTittel();
    }
    kliste = [];
    var kategori = this.state.valgtKategori;
    //var klasseliste = null;
    var klId='';
    var klId2='';
    var tittel1 = null;
    var tittel2 = null;
    var knreiendomstring = this.state.eiendom.kommunenummer + '/' + this.state.eiendom.gardsnummer + '/' + this.state.eiendom.bruksnummer + '/' + this.state.eiendom.festenummer + '/' + this.state.eiendom.seksjonsnummer;
    var eiendomstring = this.state.eiendom.gardsnummer + '/' + this.state.eiendom.bruksnummer + '/' + this.state.eiendom.festenummer + '/' + this.state.eiendom.seksjonsnummer;
    console.log(kategori);
    console.log(kategori.KlasseListe.length);
    if (kategori.KlasseListe.length == 1) {

      // Verdi
      if (kategori.KlasseListe[0].KlasseID === '[ eiendom ]') {
        klId = eiendomstring;
      
      } else if (kategori.KlasseListe[0].KlasseID === '[ knr/eiendom ]') {
        klId = knreiendomstring;
        
      } else if (kategori.KlasseListe[0].KlasseID === '[ adresse ]') {
        klId = this.state.bruksenhet.adresse;
        
      } else if (kategori.KlasseListe[0].KlasseID == '[ knr ]') {
        klId = this.state.eiendom.kommunenummer;

      } else if (kategori.KlasseListe[0].KlasseID == '' || kategori.KlasseListe[0].KlasseID == null ) {
        klId = eiendomstring;
        
      } else {
        klId = kategori.KlasseListe[0].KlasseID;
      }

      // Beskrivelse/Tittel
      if (kategori.KlasseListe[0].Tittel === '[ eiendom ]'){
        tittel1 = eiendomstring;
      }
      else if (kategori.KlasseListe[0].Tittel === '[ knr/eiendom ]') {
        tittel1 = knreiendomstring;
      }
      else if (kategori.KlasseListe[0].Tittel === '[ knr ]') {
        tittel1 = this.state.eiendom.kommunenummer;
      }
      else if (kategori.KlasseListe[0].Tittel === '[ adresse ]') {
        tittel1 = this.state.bruksenhet.adresse;
      } else {
        tittel1 = kategori.KlasseListe[0].Tittel;
      }
      
      let kl1 = {
        Klassifikasjonssystem: kategori.KlasseListe[0].Klassifikasjonssystem,
        Rekkefolge: kategori.KlasseListe[0].Rekkefolge,
        KlasseID: klId,
        Tittel: tittel1,
      };
      
      kliste.push(kl1);
    }

    if (kategori.KlasseListe.length >= 2) {
      let klasseliste = [
        {
          Klassifikasjonssystem:
            kategori.KlasseListe[0].Klassifikasjonssystem,
          //KlasseID: '43/263/0/0',
          Rekkefolge: kategori.KlasseListe[0].Rekkefolge
        },
        {
          Klassifikasjonssystem:
            kategori.KlasseListe[1].Klassifikasjonssystem,
          //KlasseID: kategori.KlasseListe[1].KlasseID,
          Rekkefolge: kategori.KlasseListe[1].Rekkefolge
        }
      ];
      // verdi 1
      if (kategori.KlasseListe[0].KlasseID === '[ eiendom ]') {
        klId = eiendomstring;
        
      } else if (kategori.KlasseListe[0].KlasseID === '[ knr/eiendom ]') {
        klId = knreiendomstring;
      } else if (kategori.KlasseListe[0].KlasseID === '[ adresse ]') {
        klId = this.state.bruksenhet.adresse;
        
      } else if (kategori.KlasseListe[0].KlasseID == '[ knr ]') {
        klId = this.state.eiendom.kommunenummer;
      } else if (kategori.KlasseListe[0].KlasseID == '' || kategori.KlasseListe[0].KlasseID == null ) {
        klId = eiendomstring;
        
      } else {
        klId = kategori.KlasseListe[0].KlasseID;
      }
      // Beskrivelse/Tittel 1
      if (kategori.KlasseListe[0].Tittel === '[ eiendom ]'){
        tittel1 = eiendomstring;
      }
      else if (kategori.KlasseListe[0].Tittel === '[ knr/eiendom ]') {
        tittel1 = knreiendomstring;
      }
      
      else if (kategori.KlasseListe[0].Tittel === '[ knr ]') {
        tittel1 = this.state.eiendom.kommunenummer;
      }
      else if (kategori.KlasseListe[0].Tittel === '[ adresse ]') {
        tittel1 = this.state.bruksenhet.adresse;
      } else {
        tittel1 = kategori.KlasseListe[0].Tittel;
      }

      let kl1 = {
        Klassifikasjonssystem: kategori.KlasseListe[0].Klassifikasjonssystem,
        Rekkefolge: kategori.KlasseListe[0].Rekkefolge,
        KlasseID: klId,
        Tittel: tittel1,
      };
      kliste.push(kl1);
      
      // Verdi 2
      if (kategori.KlasseListe[1].KlasseID === '[ eiendom ]') {
        klId2 = eiendomstring;
        
      } else if (kategori.KlasseListe[1].KlasseID === '[ knr/eiendom ]') {
        klId2 = knreiendomstring;

      } else if (kategori.KlasseListe[1].KlasseID === '[ adresse ]') {
        klId2 = this.state.bruksenhet.adresse;
        
      } else if (kategori.KlasseListe[1].KlasseID == '[ knr ]') {
        klId2 = this.state.eiendom.kommunenummer;
      } else {
        klId2 = kategori.KlasseListe[1].KlasseID;
      }
      // Beskrivelse/Tittel 2
      if (kategori.KlasseListe[1].Tittel === '[ eiendom ]'){
        tittel2 = eiendomstring;
      }
      else if (kategori.KlasseListe[1].Tittel === '[ knr/eiendom ]') {
        tittel2 = knreiendomstring;
      }
      else if (kategori.KlasseListe[1].Tittel === '[ knr ]') {
        tittel2 = this.state.eiendom.kommunenummer;
      }
      else if (kategori.KlasseListe[1].Tittel === '[ adresse ]') {
        tittel2 = this.state.bruksenhet.adresse;
      } else {
        tittel2 = kategori.KlasseListe[1].Tittel;
      }
      let kl2 = {
        Klassifikasjonssystem: kategori.KlasseListe[1].Klassifikasjonssystem,
        Rekkefolge: kategori.KlasseListe[1].Rekkefolge,
        KlasseID: klId2,
        Tittel: tittel2,
      };
      kliste.push(kl2);
      console.log(kliste);
    }
    console.log(kliste);
    var nySak = {
      tittel: brukTittel,
      saksstatus: kategori.StatusNySaksmappe,
      referanseArkivdel: kategori.Arkivdel,
      sakMappetype: kategori.Mappetype,
      matrikkelnummerListe: [
        {
          kommunenr: this.state.eiendom.kommunenummer,
          gnr: this.state.eiendom.gardsnummer,
          bnr: this.state.eiendom.bruksnummer,
          fnr: this.state.eiendom.festenummer,
          snr: this.state.eiendom.seksjonsnummer,
        },
      ],
      klasseListe: kliste,
    };
    console.log(nySak);
    this.setState({ sakLagretResultat: 'Oppretter sak ...' });
    arkivApi.nyArkivSak(nySak, this.sakLagret);
    this.setState({ sakLagretResultat: <div>Lagrer sak ...</div> });
  };

  sakLagret = (err, res) => {
    if (this.state.kobleCheck) {
      nysak = true;
      var b = this.state.bruksenhet;
      b.saksAr = res.saksaar;
      b.saksSekvensnr = res.sakssekvensnummer;
      // Oppdater
      enhetApi.updateBruksenhet(this.props.id, b, this.bruksenhetOppdatert);
    } else {
      var resultat = (
        <div>
          Sak lagret med saksnummer: {res.saksaar}/{res.sakssekvensnummer}
        </div>
      );
      this.setState({ sakLagretResultat: resultat, sakTittel: null });
    }
    
  };

  handleAarChange = (val) => {
    this.setState({ saksarStatus: val.target.value });
  };

  handleAarBruksenhetChange = (val) => {
    this.setState({ saksarBruksenhet: val.target.value });
  };

  handleSekvensChange = (val) => {
    this.setState({ sekvensStatus: val.target.value });
  };

  handleSekvensBruksenhetChange = (val) => {
    this.setState({ sekvensBruksenhet: val.target.value });
  };

  handleStatusChange = (val) => {
    this.setState({ nyStatus: val.target.value });
  };

  OppdaterBruksenhetSak = () => {
    var b = this.state.bruksenhet;
    b.saksAr = this.state.saksarBruksenhet == '0' ? null : this.state.saksarBruksenhet;
    b.saksSekvensnr = this.state.sekvensBruksenhet == '0' ? null : this.state.sekvensBruksenhet;
    // Oppdater
    enhetApi.updateBruksenhet(this.props.id, b, this.bruksenhetOppdatert);
  };

  bruksenhetOppdatert = (err, res) => {
    if (nysak) {
      var resultat = (
        <div>
          Sak lagret med saksnummer: {res.saksAr}/{res.saksSekvensnr}. Aktiv sak oppdatert.
        </div>
      );
      this.setState({ sakLagretResultat: resultat, sakTittel: null });
      nysak = false;
    }
    this.setState({
      bruksenhet: res,
      arkivSakResult: <div>Sak ikke hentet</div>,
      jpsResult: <div>Journalposter ikke hentet</div>,
      oppdatertSakResult: <div>Ingen sak oppdatert</div>,
      historieSakResult: <div>Ingen saker hentet</div>,
      oppdaterBruksenhetSakStatus: <div>Aktiv sak oppdatert</div>,
      
    });
  };

  OppdaterStatus = () => {
    // kall api
    arkivApi.oppdaterSakstatus(
      this.state.saksarStatus,
      this.state.sekvensStatus,
      this.state.nyStatus,
      this.statusOppdatert
    );
    this.setState({ oppdatertSakResult: 'Oppdaterer sak ...' });
  };

  statusOppdatert = (err, res) => {
    if (err == null || err == undefined) {
      arkivApi.getSakForSaksnummer(
        this.state.saksarStatus,
        this.state.sekvensStatus,
        this.OppdatertSakHentet
      );
    }
  };

  OppdatertSakHentet = (err, res) => {
    console.log(res);
    let result = (
      <div>
        Sak oppdatert
        <br />
        <table>
          <tr>
            <td>Saksnummer</td>
            <td> </td>
            <td>
              {res.saksnummer.saksaar} / {res.saksnummer.sakssekvensnummer}
            </td>
          </tr>
          <tr>
            <td>Sakstittel</td>
            <td> </td>
            <td>{res.tittel}</td>
          </tr>
          <tr>
            <td>Saksstatus</td>
            <td> </td>
            <td>{res.saksstatus.kodeverdi}</td>
          </tr>
        </table>
      </div>
    );
    this.setState({ oppdatertSakResult: result });
  };

  HentSakshistorie = () => {
    arkivApi.getSakerForMatrikkelsnummer(
      this.state.eiendom.kommunenummer,
      this.state.eiendom.gardsnummer,
      this.state.eiendom.bruksnummer,
      this.state.eiendom.festenummer,
      this.state.eiendom.seksjonsnummer,
      this.gotSakHistorie
    );
    this.setState({ historieSakResult: <div>Henter saker ...</div> });
  };

  gotSakHistorie = (err, res) => {
    this.setState({ saker: res });
    let result = <div>Ingen saker funnet</div>;
    if (res != null && res != undefined) {
      if (res.length > 0) {
        result = (
          <div>
            <ReactTable
              items={this.state.saker}
              columns={this.getColumnsSaker()}
              showIndex={false}
            />
          </div>
        );
      }
      this.setState({ historieSakResult: result });
    }
  };

  // NyJournalpost

  handleJpAarChange = (val) => {
    this.setState({ nyJpAar: val.target.value });
  }

  handleJpSekvensChange = (val) => {
    this.setState({ nyJpSekvens: val.target.value });
  }

  handleJpNameChange = (val) => {
    this.setState({ jpTittel: val.target.value });
  }

  handleJpStatusChange = (val) => {
    this.setState({ jpStatus: val.target.value });
  }

  handleJpFornavnChange = (val) => {
    this.setState( { jpFornavn: val.target.value });
  }

  handleJpEtternavnChange = (val) => {
    this.setState({ jpEtternavn: val.target.value });
  }

  handleJpAdresseChange = (val) => {
    this.setState({ jpAdresse: val.target.value });
  }

  handleJpPoststedChange = (val) => {
    this.setState({ jpPoststed: val.target.value });
  }

  handleJpPostnrChange = (val) => {
    this.setState({ jpPostnr: val.target.value });
  }

  handleJpEpostChange = (val) => {
    this.setState({ jpEpost: val.target.value });
  }

  handleJpFødselsnrChange = (val) => {
    this.setState({ jpFodselnr: val.target.value });
  }

  handleFiles = files => {
    this.setState({ fil: files, base64: files.base64 });
  };

  submitNyJp = () => {
    
    console.log('Lagre utgaende jp');
    var a = this.state.base64;
    var b = a.indexOf(',');
    var base64string = a.substr(b + 1, a.length - 1);
    var lk = this.state.innstillinger.Landkode;
    
    var utgJp = {
      saksnummerAar: this.state.nyJpAar,
      saksnummerSekvensnr: this.state.nyJpSekvens,
      tittel: this.state.jpTittel,
      journalstatus: this.state.jpStatus,
      eksterneMottakere: {
        // personer: [
        //   {
        //     fornavn: this.state.jpFornavn,
        //     etternavn: this.state.jpEtternavn,
        //     postadresse: this.state.jpAdresse,
        //     postadresse2: '',
        //     postnr: this.state.jpPostnr,
        //     poststed: this.state.jpPoststed,
        //     landKode: lk,
        //     epost: this.state.jpEpost,
        //     personnr: this.state.jpFodselnr
        //   }
        // ]
      },
      hoveddokument: {
        tittel: 'Hoveddokument',
        filinnhold: {
          filnavn: this.state.fil.fileList[0].name,
          mimetype: this.state.fil.fileList[0].type,
          base64: base64string
        },
        dokumenttypeKodeverdi: this.state.innstillinger.Dokumenttype
        //filformatKodeverdi: 'UKJENT'
      },
      brId: this.props.id
    };

    console.log(utgJp);

    arkivApi.nyManuellUtgJournalpost(utgJp, this.jpLagret);
    this.setState({ lagrer: true });
  }
  jpLagret = (err, lagretjp) => {

    this.setState({ lagretJp: lagretjp, jpLagret: true, lagrer: false });
  }

  render() {
    let btnStyle = {
      width: 'calc(100% - 54px)',
      
      borderColor: '#999999',
      paddingRight: '0px',
      paddingLeft: '4px',
      maxHeight: '42px',
      display: 'inline-block',
      whiteSpace: 'nowrap',
    };
    let propilStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      width: '60px',
      height: '40px',
      lineHeight: '40px',
      float: 'right',
    };
    let propilStyleAfter = {
      float: 'right',
      marginTop: '10px',
      marginRight: '4px',
    };
    let ellipsedStyle = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 100px)',
      textAlign: 'left',
      display: 'inline-block',
    };

    if (this.state.lagrer) {
      return <div className="loader" />;
    }
    if (!this.state.jpLagret) {
      var result = (
        <div>
          <b>Journalpost ikke arkivert</b>
          <p />
          En utgående journalpost med angitt tittel vil bli opprettet.
        </div>
      );
    } else {
      result = (
        <div>
          <b>Journalpost arkivert</b>
          <p>
            Journalnummer: {this.state.lagretJp.journalaar}/
            {this.state.lagretJp.journalsekvensnummer}
          </p>
          <p />
        </div>
      );
    }

    if (this.state.isLoading == true) {
      return <div className="loader" />;
    }

    if (!this.state.fil) {
      var filInfo = <div>Fil ikke opplastet</div>;
    } else {
      filInfo = <div>{this.state.fil.fileList[0].name}</div>;
    }

    return (
      <div style={{ width: '100%' }}>
        <h2>Arkiv</h2>
        <hr />
        {this.state.apenSak ? (
          <div>
            <h4>
              Åpen sak: {this.state.bruksenhet.saksAr}/
              {this.state.bruksenhet.saksSekvensnr}
            </h4>
          </div>
        ) : (
          <div>
            <h4>Bruksenheten har ikke åpen sak i arkivet</h4>
          </div>
        )}
        <br />

        {/* ***************************** */}
        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#hentArkivsak"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Vis arkiv sak</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="hentArkivsak"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <table>
            <tbody>
              <tr>
                <td>
                  {this.state.apenSak ? (
                    <div>
                      <button
                        className="btn btn-success"
                        style={{
                          height: '32px',
                        }}
                        onClick={this.HentSak}
                        type="button"
                      >
                        Hent sak i arkivet
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn btn-success"
                        style={{
                          height: '32px',
                        }}
                        onClick={this.HentSak}
                        type="button"
                        disabled
                      >
                        Hent sak i arkivet
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {this.state.arkivSakResult}
        </div>
        <br />
        <br />
        {/* ***************************** */}
                        
        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#hentArkivJps"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Vis journalposter på åpen arkiv sak</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="hentArkivJps"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <table>
            <tbody>
              <tr>
                <td>
                  {this.state.apenSak ? (
                    <div>
                      <button
                        className="btn btn-success"
                        style={{
                          height: '32px',
                        }}
                        onClick={this.HentJps}
                        type="button"
                      >
                        Hent journalposter
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className="btn btn-success"
                        style={{
                          height: '32px',
                        }}
                        onClick={this.HentJps}
                        type="button"
                        disabled
                      >
                        Hent journalposter
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          {this.state.jpsResult}
        </div>
        <br />
        <br />

        {/* ***************************** */}
        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#endresak"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Endre aktiv sak på bruksenhet</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="endresak"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <table>
            <tbody>
              <tr>
                <td>Saksår</td>
                <td style={{ width: '5px' }} />
                <td style={{ width: '110px' }}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleAarBruksenhetChange}
                  />
                </td>
                <td style={{ width: '10px' }} />
                <td>Saksekvensnr</td>
                <td style={{ width: '5px' }} />
                <td style={{ width: '110px' }}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleSekvensBruksenhetChange}
                  />
                </td>

                <td style={{ width: '10px' }} />
                <td>
                  <button
                    className="btn btn-success"
                    style={{
                      height: '32px',
                    }}
                    onClick={this.OppdaterBruksenhetSak}
                    type="button"
                  >
                    Oppdater bruksenhet
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          {this.state.oppdaterBruksenhetSakStatus}
        </div>
        <br />
        <br />

        {/* ***************************** */}

        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#status"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Oppdater status på sak</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="status"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <table>
            <tbody>
              <tr>
                <td>Saksår</td>
                <td style={{ width: '5px' }} />
                <td style={{ width: '110px' }}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleAarChange}
                  />
                </td>
                <td style={{ width: '10px' }} />
                <td>Saksekvensnr</td>
                <td style={{ width: '5px' }} />
                <td style={{ width: '110px' }}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleSekvensChange}
                  />
                </td>
                <td style={{ width: '10px' }} />
                <td>Ny status</td>
                <td style={{ width: '5px' }} />
                <td style={{ width: '110px' }}>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleStatusChange}
                  />
                </td>
                <td style={{ width: '10px' }} />
                <td>
                  {(this.state.saksarStatus == null || this.state.sekvensStatus == null || this.state.nyStatus == null) 
                  ? 
                  <div>
                    <button
                    className="btn btn-success"
                    style={{
                      height: '32px',
                    }}
                    onClick={this.OppdaterStatus}
                    type="button"
                    disabled
                  >
                    Oppdater status
                  </button>
                  </div> 
                  : 
                  <div>
                    <button
                    className="btn btn-success"
                    style={{
                      height: '32px',
                    }}
                    onClick={this.OppdaterStatus}
                    type="button"
                  >
                    Oppdater status
                  </button>
                  </div>}
                  
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          {this.state.oppdatertSakResult}
        </div>
        <br />
        <br />
        {/* ***************************** */}
        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#nysak"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Arkiver ny sak</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="nysak"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <div className="form">
            <div className="row">
            <div className="col-sm-2">
              <label>Egen sakstittel</label>
            </div>
            <div className="col-sm-6">
              <input
                  className="checkbox30"
                  type="checkbox"
                  checked={this.state.egenTittel}
                  onChange={(e) => {
                    this.setState({
                        egenTittel: !this.state.egenTittel }
                      );
                  }}
                />
            </div>
            </div>
            { this.state.egenTittel ? 
            <div>
              <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Tittel</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.handleTittelChange}
                  />
                </div>
              </div>
            </div>
            </div> 
            : null }
            
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Sakskategori</label>
                  <select
                    className="form-control"
                    id="type"
                    onChange={this.kategoriValgt}
                  >
                    {_.map(this.state.kategorier, function(o, i) {
                      return (
                        <option key={i} value={o.Navn}>
                          {o.Navn}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-2">
                <label>Koble saken til bruksenhet</label>
              </div>
              <div className="col-sm-2 form-check ">
                <input
                  className="checkbox30"
                  type="checkbox"
                  checked={this.state.kobleCheck}
                  onChange={(e) => {
                    this.setState({
                      kobleCheck: !this.state.kobleCheck,
                    });
                  }}
                />
              </div>
            </div>
            <br/>
            {/* <div className="row">
              <div className="col-sm-4">{this.state.sakLagretResultat}</div>
            </div> */}

            <div className="row">
              <div className="col-sm-6">
                <div className="btn-toolbar pull-left">
                  <button
                    type="button"
                    onClick={this.submitNySak}
                    className="btn btn-success"
                  >
                    Arkiver sak
                  </button>
                </div>
              </div>
            </div>
            <hr />
            {this.state.sakLagretResultat}
          </div>
        </div>
        <br />
        <br />
        {/* ***************************** */}
        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#nyjp"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Arkiver ny journalpost</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="nyjp"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <form>
            <div className="row">
              <div className="col-sm-2">
                <div className="form-group">
                  <label>Sak år</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Saksår"
                    onChange={this.handleJpAarChange}
                    value = {this.state.nyJpAar}
                    ref="aarInput"
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>Sak sekvensnummer</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Saksekvensnummer"
                    onChange={this.handleJpSekvensChange}
                    value = {this.state.nyJpSekvens}
                    ref="sekvensInput"
                  />
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group">
                  <label>Status</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-ekspedert hvis blank"
                    onChange={this.handleJpStatusChange}
                    ref="statusInput"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Journalpost tittel</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Journalpost tittel"
                    onChange={this.handleJpNameChange}
                    ref="nameInput"
                  />
                </div>
              </div>
              
            </div>
            {/* <p/>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Dokumenttype</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Feks Brev, prøv med blank først"
                  onChange={this.handleTypeChange}
                  ref="typeInput"
                />
              </div>
            </div>
          </div> */}
           
            
            
            <div className="row">
              <div className="col-sm-4">
              <div className="form-group">
              <label>Fil</label>
                
                  <ReactFileReader
                    base64={true}
                    multipleFiles={false}
                    fileTypes="/*"
                    handleFiles={this.handleFiles}
                  >
                    <button className="btn">Last opp fil</button>
                  </ReactFileReader>
                
                </div>
              </div>
              {/* <div className="col-sm-6">
              
                <div className="pull-left">
                  <div>{filInfo}</div>
                  <br />
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div>{filInfo}</div>
              </div>
            </div>
            <br/>
            <div className="row">
              <div className="col-sm-6">
                <div className="btn-toolbar pull-left">
                  <button
                    type="button"
                    onClick={this.submitNyJp}
                    className="btn btn-success"
                  >
                    Arkiver ny journalpost
                  </button>

                </div>
                
              </div>
            </div>
            
            <p />
            <hr />
            {result}
          </form>
        </div>
        <br />
        <br />
        {/* ***************************** */}
        <div
          style={btnStyle}
          className="container-fluid btn btn-default sbpilpanel collapsed"
          href="#henthistorie"
          data-toggle="collapse"
        >
          <span />
          <h4 style={ellipsedStyle}>Vis tidligere arkiv saker</h4>
          <div style={propilStyle}>
            <span style={propilStyleAfter} className="chevron fa fa-fw fa-lg" />
          </div>
        </div>
        <div
          id="henthistorie"
          style={{ width: 'calc(100% - 54px)', paddingLeft: '30px', background: '#FFFFFF', border: '1px solid #999999' }}
          className="collapse "
        >
          <br />
          <table>
            <tbody>
              <tr>
                <td>
                  <button
                    className="btn btn-success"
                    
                    onClick={this.HentSakshistorie}
                    type="button"
                  >
                    Hent saker for eiendom i arkivet
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {this.state.historieSakResult}
          <br />
        </div>
      </div>
    );
  }

  formaterSakTittel = () => {
    // målform
    let tittel = this.state.org.malFormBokmal ? 'Brannforebygging' : 'Brannførebyggjing';
    let gnr = this.state.eiendom.gardsnummer;
    let bnr = this.state.eiendom.bruksnummer;
    let fnr = this.state.eiendom.festenummer;
    let snr = this.state.eiendom.seksjonsnummer;
    let knr = this.state.eiendom.kommunenummer;
    let adresse = this.state.bruksenhet.adresse;

    // Tekst - Gnr/Bnr/Fnr/Snr
    if (this.state.arkivInfo.arkivTittelFormatSak == 0)
    {
        tittel = tittel + ' - ' + gnr + '/' + bnr + '/' + fnr + '/' + snr;
    }
    // Tekst - Knr / Gnr / Bnr / Fnr / Snr
    if (this.state.arkivInfo.arkivTittelFormatSak == 1)
    {
      tittel = tittel + ' - ' + knr + '/' + gnr + '/' + bnr + '/' + fnr + '/' + snr;
    }
    // Gnr/Bnr/Fnr/Snr - Tekst
    if (this.state.arkivInfo.arkivTittelFormatSak == 2)
    {
      tittel = gnr + '/' + bnr + '/' + fnr + '/' + snr + '- ' + tittel;
    }
    // Knr/Gnr/Bnr/Fnr/Snr - Tekst
    if (this.state.arkivInfo.arkivTittelFormatSak == 3)
    {
      tittel = knr + '/' + gnr + '/' + bnr + '/' + fnr + '/' + snr + '- ' + tittel;
    }
    if (this.state.arkivInfo.arkivTittelFormatSak == 4)
    {
      tittel = tittel + ' - ' + gnr + '/' + bnr + '/' + fnr + '/' + snr + ' - ' + adresse;
    }
    if (this.state.arkivInfo.arkivTittelFormatSak == 5)
    {
      tittel = tittel + ' - ' + knr + '/' + gnr + '/' + bnr + '/' + fnr + '/' + snr + ' - ' + adresse;
    }
    if (this.state.arkivInfo.arkivTittelFormatSak == 6)
    {
      tittel = tittel + ' - ' + adresse;
    }
    return tittel;
  }
}

export default withRouter(Arkiv);

