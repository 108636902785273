import React, { Component } from 'react';

import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import ReactTable from '@norkart/react-table';
import { debugFactory, getKronerDivRight } from 'utils';
const debug = debugFactory('nk:Gebyr:GjeldendeGebyrTable');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class FakturertHittilTable extends Component {
  constructor(props) {
    super(props);
    this.state = { gebyrer: [] };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {
    // if (this.props.gebyrer.length == 0) {
    //   this.setState({ gebyrinfo: null });
    // } else {
    //   this.setState({ gebyrinfo: this.props.gebyrer });
    // }
    // console.log(this.props.gebyrer);
  };

  componentDidUpdate = (prevProps, prevState) => {

  };

  render() {
    let columns = this.getFakturertColumns();

    return (
      <div>
        <ReactTable
          items={this.props.sistFakturert}
          columns={columns}
          // rowFormat={this.rowFormat}
          showIndex={false}
          filterable={false}
        />

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  
  getFakturertColumns = () => {
    return [
      {
        id: 'varenavn',
        name: 'Varenavn',
        sortParams: 'varenavn',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'belop',
        name: 'Terminbeløp',
        sortParams: 'belop',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            getKronerDivRight(item.belop, true)
          );
        },
      },
      {
        id: 'gebyrtype',
        name: 'Gebyrtype',
        sortParams: 'gebyrtype',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.gebyrtype === 'Lopende') {
            return 'Løpende';
          }
        }
      },
      {
        id: 'avgiftstypeTekst',
        name: 'Avgiftstype',
        sortParams: 'avgiftstypeTekst',
        isSorted: true,
        sortable: true,
        
      }
    ];
  };
}

export default FakturertHittilTable;
