import callApi from './waapi';
import CONFIG from 'lib/CONFIG';

var BASE_URL = CONFIG.REACT_APP_WAAPIFRI_URL;
if (!BASE_URL) {
  throw new Error('Missing required REACT_APP_WAAPIFRI_URL config');
}

export function callApiFritekst(method, path, data, callback){
    callApi(method, path, data, callback, BASE_URL, true);
}