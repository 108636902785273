import React, { Component } from 'react';
import * as _ from 'lodash';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import './rfSortable.css';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:RekkefolgeCluster');

class RekkefolgeClusterListe extends Component {
  constructor(props) {
    super(props);
    this.state = { startIndex: 1, items: [], index: 0 };
  }

  componentDidMount() {
    this.setState({ items: this.giNyIndex(this.props.items) });
  }

  giNyIndex = liste => {
    let teller = this.props.sorterNummer;
    for (let i = 0; i < liste.length; i++) {
      let a = liste[i];
      a.index = teller;
      teller = teller + 1;
    }
    return liste;
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    let items = this.state.items;
    let enhet = items[oldIndex];
    if (!enhet) {
      return;
    }
   /*  if (!enhet.hartelefon) {
      return;
    } */
   // let enhetU = items[newIndex];
   /*  if (!enhetU.hartelefon) {
      return;
    } */
    items = arrayMove(items, oldIndex, newIndex);

    items = this.giNyIndex(items);  

    this.setState({ items });
  };

  sortByIndex = (liste, rev) => {
    const iteratees = obj => obj.index;
    if (rev) {
      liste = _.sortBy(liste, iteratees).reverse();
    } else {
      liste = _.sortBy(liste, iteratees);
    }

    return liste;
  };

  snu= () => {
    let items = this.state.items;
    items = this.sortByIndex(items, true);
    items = this.giNyIndex(items);
    items = this.sortByIndex(items, false);
    this.setState({ items });
  };

  bruk = () => {
    this.props.bruk(this.state.items, this.props.sorterNummer);
  };

  render() {
    const SortableItem = SortableElement(({ item }) => (
      <li className='SortableItemCluster'>
        <table>
          <tbody>
            <tr>
              <td style={{ width: '50px' }}>
                  <button className='numberCircle'>{item.index}</button>       
              </td>
              <td>
                <span>{item.adresse}</span>
              </td>
            </tr>
          
          </tbody>
        </table>
      </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul className='SortableListCluster'>
          {items.map((item, index) => (
            <SortableItem key={`item-${index}`} index={index} item={item} />
          ))}
        </ul>
      );
    });
    return (
      <div className='modal-content clusterModal'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
             Flere enheter på samme lokasjon
          </h4>
        </div>
        <div className='modal-body'>
          <div
            style={{
              width: '100%',
              marginLeft: '4px',
              marginBottom: '4px'
            }}>
            <SortableList
              style={{ height: '100%' }}
              items={this.state.items}
              onSortEnd={this.onSortEnd}
              helperClass='SortableHelper'
            />
          </div>
        </div>
        <div className='modal-footer'>
          <button onClick={this.snu} className='btn btn-default pull-left'>Snu rekkefølge</button>
          <button onClick={this.bruk} className='btn btn-success pull-right'>Bruk</button>
        </div>
      </div>
    );
  }
}

export default RekkefolgeClusterListe;
