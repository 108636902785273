import React from 'react';

var merknad = null;

class ArbeidslisteFritekst extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleMerknadChange = this.handleMerknadChange.bind(this);
    merknad = this.props.merknad === 'null' ? '' : this.props.merknad;
  }
  
  handleMerknadChange(data) {
    merknad = data.target.value;
  }

  submit() {    
    if (merknad === '' || merknad === undefined) {      
      merknad = null;      
    } 
    this.props.lagreBesokMerknad(merknad);        
  }
  
  render() {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" onClick={this.props.onClose} type="button">
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">Melding til feier</h4>
        </div>
        <div className="modal-body">
          <div className="form">
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group"
                  style={{ margin: '15px 10px' }}>
                  <label>Informasjon</label>
                  <textarea
                    type="textarea"
                    rows="2"
                    className="form-control textareaNoresize"
                    placeholder="Informasjon"
                    onChange={this.handleMerknadChange}
                    defaultValue= {merknad}
                    ref="input"
                    maxlength="1000"
                  />
                </div>
                <div className="pull-right">
                  <input
                    type="button"
                    className="btn btn-primary height48"
                    value="OK"
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArbeidslisteFritekst;
