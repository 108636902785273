
export function getAnmerkningTabellOversikt() {
    return [
      {
        navn: 'Velg tabell...'
      },
      {
        navn: 'Beskrivelse'
      },
      {
        navn: 'Kategori'
      }
    ];
  }

  export function getAvvikTabellOversikt() {
    return [
        {
        navn: 'Velg tabell...'
      },
      {
        navn: 'Beskrivelse'
      },
      {
        navn: 'Lovdata'
      },
      {
        navn: 'Paragraf'
      },
      // {
      //   navn: 'Status'
      // },
      {
        navn: 'Type'
      },
      {
        navn: 'Årsak lukket'
      }
    ];
  }

  export function getIldstedTabellOversikt() {
    return [
        {
        navn: 'Velg tabell...'
      },
      {
        navn: 'Modell'
      },
      {
        navn: 'Type'
      },
      {
        navn: 'Produsent'
      },
      {
        navn: 'Plassering'
      }
    ];
  }

  export function getRoyklopTabellOversikt() {
    return [
        {
        navn: 'Velg tabell...'
      },
      {
        navn: 'Adkomst'
      },
      {
        navn: 'Dimensjon'
      },
      {
        navn: 'Modell'
      },
      {
        navn: 'Plassering'
      },
      {
        navn: 'Produsent'
      },
      {
        navn: 'Sotmengde'
      },
      {
        navn: 'Sotuttak'
      },
      {
        navn: 'Type'
      }
    ];
  }

  export function getTiltakTabellOversikt() {
    return [
        {
        navn: 'Velg tabell...'
      },
      {
        navn: 'Årsak ikke utført'
      },
      {
        navn: 'Hyppighet'
      },
      {
        navn: 'Type'
      }
    ];
  }

  
