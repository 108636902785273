import React from 'react';
import _ from 'underscore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import moment from 'moment';
import NumericInput from 'react-numeric-input';
import DatePicker from 'react-datepicker';
import { getTiltakType } from 'api/oppslagsdataApi';
import 'react-datepicker/dist/react-datepicker.css';
import Debug from 'debug';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { etablerfagdata } from 'api/grunndataApi';

const modalDialogs = {
  FeilDatoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Ugyldig dato"
      melding={dialogData.melding}
      isOpen={isOpen}
      onOk={onClose}
      onClose={onClose}
    />
  )
};

const debug = Debug('nk:Grunndata');
class EtablerFagdataModal extends React.Component {
  constructor() {
    super();
    this.state = {
      enheter: [],
      tiltakstyperEnhet: [],
      tiltakstyperRoyklop: [],
      visLeggTilTiltak: false,
      opprettRoyklop: true,
      opprettIldsted: true,
      antallRoyklop: 1,
      /* datoEnhet: moment(new Date()),
      datoRoyklop: moment(new Date()), */
      datoEnhet: null,
      datoRoyklop: null,
      saveFinished: true,
      selectedTiltakRoyklop: 0,
      selectedTiltakEnhet: 0
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.opprettRoyklopChange = this.opprettRoyklopChange.bind(this);
    this.opprettIldstedChange = this.opprettIldstedChange.bind(this);
    this.antallRoyklopChange = this.antallRoyklopChange.bind(this);
    this.onOpprettFagdata = this.onOpprettFagdata.bind(this);
    this.onChangeEnhetDate = this.onChangeEnhetDate.bind(this);
    this.onChangeRoyklopDate = this.onChangeRoyklopDate.bind(this);
    this.afterEtablert = this.afterEtablert.bind(this);
    this.tiltakEnhetOnChange = this.tiltakEnhetOnChange.bind(this);
    this.tiltakRoyklopOnChange = this.tiltakRoyklopOnChange.bind(this);
    this.waitAndFinish = this.waitAndFinish.bind(this);
  }
  componentDidMount() {
    this.makeEnheter(); 
    getTiltakType((err, result) => {
      let blankLinje1 = {
        aktiv: true,
        id: null,
        standard: false,
        tekst: '',
        tiltakObjekt: 'Bruksenhet',
        tiltakObjektId: 1
      };
      let blankLinje2 = {
        aktiv: true,
        id: null,
        standard: false,
        tekst: '',
        tiltakObjekt: '"Røykløp"',
        tiltakObjektId: 2
      };
      result.unshift(blankLinje2);
      result.unshift(blankLinje1);

      this.setState({
        tiltakstyperEnhet: _.filter(result, item => item.tiltakObjektId === 1)
      });
      this.setState({
        tiltakstyperRoyklop: _.filter(result, item => item.tiltakObjektId === 2)
      });
    });
  }

   makeEnheter= () => {
    let enheter = [];
    for (let i = 0; i < this.props.valgteEnheter.length; i++) {
      const element = this.props.valgteEnheter[i];
     /*  let adresse= '';
      if (element.formatAdresse){
        adresse = element.formatAdresse;
      }; */
      let kommuneId= '';
      if (element._source.kommune){
        kommuneId = element._source.kommune.kommuneId;
      };
      let matrikkelenhetId= '';
      if (element._source.matrikkelenhet){
        matrikkelenhetId = element._source.matrikkelenhet.id;
      };
      let enhetId = element._id;
      let harGebyrKobling= ( element.gebyrTypeKode !== '');
      
      let enhet = {
       // adresse: adresse,
        KtfAvtaleKobling: harGebyrKobling,
        MatrikkelenhetId: matrikkelenhetId,
        KommuneNr: kommuneId,
        BruksenhetIdMa: enhetId
      };

      enheter.push(enhet);
    }
    this.setState({ enheter });
  };

  tiltakEnhetOnChange(e) {
    this.setState({ selectedTiltakEnhet: e.target.value });
    if (e.target.value === null || e.target.value === '') {
      this.setState({ datoEnhet: null });
    }
  }

  tiltakRoyklopOnChange(e) {
    this.setState({ selectedTiltakRoyklop: e.target.value });
    if (e.target.value === null || e.target.value === '') {
      this.setState({ datoRoyklop: null });
    }
  }

  opprettRoyklopChange(val) {
    let verdi = val.currentTarget.value;
    this.setState({
      opprettRoyklop: verdi === 'ja' ? true : false
    });
    if (verdi === 'nei') {
      this.setState({
        opprettIldsted: false,
        datoRoyklop: null,
        selectedTiltakRoyklop: null
      });
    } else {
      this.setState({ datoRoyklop: null });
      this.setState({ selectedTiltakRoyklop: 0 });
    }
  }

  opprettIldstedChange(val) {
    let verdi = val.currentTarget.value;
    if (this.state.opprettRoyklop === false) {
      this.setState({ opprettIldsted: false });
    } else {
      this.setState({
        opprettIldsted: verdi === 'ja' ? true : false
      });
    }
  }
  antallRoyklopChange(val) {
    let antall = val ? val : 0;
    this.setState({ antallRoyklop: antall });
  }

  onOpprettFagdata() {
    this.props.onClose();
    let TilttakstypeIdEnhet = 0;
    let TiltaksdatoEnhet = this.state.datoEnhet;

    if (!this.state.selectedTiltakEnhet) {
      TilttakstypeIdEnhet = 0;
      TiltaksdatoEnhet = null;
    } else {
      TilttakstypeIdEnhet = this.state.selectedTiltakEnhet;
    }

    let TiltakstypeIdRoyklop = 0;
    let TiltaksdatoRoyklop = this.state.datoRoyklop;
    if (!this.state.selectedTiltakRoyklop) {
      TiltakstypeIdRoyklop = 0;
      TiltaksdatoRoyklop = null;
    } else {
      TiltakstypeIdRoyklop = this.state.selectedTiltakRoyklop;
    }

    this.setState({ saveFinished: false });
    let dto = {
      BruksenhetIdMaListe: this.props.valgteEnhetsIder,
      BruksenhetListe: this.state.enheter,
      EtablerRoyklop: this.state.opprettRoyklop,
      EtablerIldsted: this.state.opprettIldsted,
      AntallRoyklop: this.state.antallRoyklop,
      TilttakstypeIdEnhet: TilttakstypeIdEnhet,
      TiltakstypeIdRoyklop: TiltakstypeIdRoyklop,
      TiltaksdatoEnhet: TiltaksdatoEnhet,
      TiltaksdatoRoyklop: TiltaksdatoRoyklop
    };

    debug('DTO', dto);
    etablerfagdata(dto, this.afterEtablert);
  }

  afterEtablert(err, finished) {
    debug('afterEtablert error', err);
    debug('afterEtablert finished', finished);
    this.waitAndFinish();
  }

  waitAndFinish() {
    debug('Venter..', 'Finish');
    this.setState({ saveFinished: true });
  }

  onChangeEnhetDate(val) {
    if (
      this.state.selectedTiltakEnhet === 0 ||
      this.state.selectedTiltakEnhet === null
    ) {
      this.setState({ datoEnhet: null });
      this.dialogs.setOpenDialog('FeilDatoDialog', {
        melding: 'Velg et tiltak (bruksenhet) først.'
      });
      return;
    }
    if (val == null) {
      this.setState({ datoEnhet: null });
      return;
    }
    let today = moment(new Date());
    if (moment(val).isSameOrAfter(today, 'day')) {
      this.setState({ datoEnhet: moment(val).add(4, 'hours') });
    } else {
      this.dialogs.setOpenDialog('FeilDatoDialog', {
        melding: 'Dato skal være i dag eller senere.'
      });
    }
  }

  onChangeRoyklopDate(val) {
    if (
      this.state.selectedTiltakRoyklop === 0 ||
      this.state.selectedTiltakRoyklop === null
    ) {
      this.setState({ datoRoyklop: null });
      this.dialogs.setOpenDialog('FeilDatoDialog', {
        melding: 'Velg et tiltak (røykløp) først.'
      });
      return;
    }
    if (val == null) {
      this.setState({ datoRoyklop: null });
      return;
    }
    let today = moment(new Date());
    if (moment(val).isSameOrAfter(today, 'day')) {
      this.setState({ datoRoyklop: moment(val).add(4, 'hours') });
    } else {
      this.dialogs.setOpenDialog('FeilDatoDialog', {
        melding: 'Dato skal være i dag eller senere.'
      });
    }
  }

  render() {

    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header modal-header-info">
            <button
              className="close"
              onClick={this.props.onCloseAvbryt}
              type="button">
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Etabler fagdata</h4>
          </div>
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-heading">
                Opprett objekter for valgte enheter
              </div>
              <div className="panel-body"
                style={{ padding: '15px 0' }}>
                <div className="container"
                  style={{ width: '100%' }}>
                  <div className="row">

                    <div className="col-sm-6">
                      <label>Opprett røykløp</label>
                      <div className="form-group">
                        <div
                          className="radio-inline"
                          style={{ paddingLeft: '30px' }}>
                          <label>
                            <input
                              type="radio"
                              name="sentralRadio"
                              value="ja"
                              checked={this.state.opprettRoyklop === true}
                              onChange={this.opprettRoyklopChange}
                            />
                            <span
                              style={{
                                marginLeft: '6px',
                                marginRight: '10px'
                              }}>
                              Ja
                            </span>
                          </label>
                        </div>
                        <div className="radio-inline margin-left-10">
                          <label>
                            <input
                              type="radio"
                              name="sentralRadio"
                              value="nei"
                              checked={this.state.opprettRoyklop === false}
                              onChange={this.opprettRoyklopChange}
                            />
                            <span style={{ marginLeft: '6px' }}>Nei</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      {this.state.opprettRoyklop ? (
                        <div>
                          <label>Antall røykløp</label>
                          <div className="form-group">
                            <NumericInput
                              className="width100px height38"
                              mobile={true}
                              min={1}
                              max={10}
                              value={this.state.antallRoyklop}
                              onChange={this.antallRoyklopChange}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label>Opprett ildsted</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div
                          className="radio-inline"
                          style={{ paddingLeft: '30px' }}>
                          <label>
                            <input
                              className={
                                this.state.opprettRoyklop ? '' : 'disabled'
                              }
                              type="radio"
                              name="ildstedRadio"
                              value="ja"
                              checked={this.state.opprettIldsted === true}
                              onChange={this.opprettIldstedChange}
                            />
                            <span
                              style={{
                                marginLeft: '6px',
                                marginRight: '10px'
                              }}>
                              Ja
                            </span>
                          </label>
                        </div>
                        <div className="radio-inline margin-left-10">
                          <label>
                            <input
                              className={
                                this.state.opprettRoyklop ? '' : 'disabled'
                              }
                              type="radio"
                              name="ildstedRadio"
                              value="nei"
                              checked={this.state.opprettIldsted === false}
                              onChange={this.opprettIldstedChange}
                            />
                            <span style={{ marginLeft: '6px' }}>Nei</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div className="panel-heading">
                  {this.state.opprettRoyklop
                    ? 'Velg tiltak for bruksenhet og røykløp'
                    : 'Velg tiltak for bruksenhet'}
                </div>
                <div className="panel-body"
                  style={{ padding: '15px 0' }}>
                  <div className="form">
                    <div className="container"
                      style={{ width: '100%' }}>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Neste tiltak bruksenhet</label>
                            <br />
                            <select
                              className="form-control"
                              id="type"
                              onChange={this.tiltakEnhetOnChange}
                              defaultValue={this.state.selectedTiltakEnhet}>
                              {_.map(this.state.tiltakstyperEnhet, function(
                                o,
                                i
                              ) {
                                return (
                                  <option key={i} value={o.id}>
                                    {o.tekst}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          {this.state.opprettRoyklop ? (
                            <div className="form-group">
                              <label>Neste tiltak røykløp</label>
                              <br />
                              <select
                                /*  className={
                                  this.state.opprettRoyklop ? '' : 'disabled'
                                } */
                                className="form-control"
                                id="type"
                                onChange={this.tiltakRoyklopOnChange}
                                defaultValue={this.state.selectedTiltakRoyklop}>
                                {_.map(this.state.tiltakstyperRoyklop, function(
                                  o,
                                  i
                                ) {
                                  return (
                                    <option key={i} value={o.id}>
                                      {o.tekst}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          ) : (
                            <label>Røykløp vil ikke bli opprettet</label>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Neste tiltaksdato bruksenhet</label>

                            <DatePicker
                              className="form-control"
                              dateFormat="DD.MM.YYYY"
                              locale="nb_NO"
                              selected={this.state.datoEnhet}
                              filterDate={this.isAfterEndDate}
                              onChange={this.onChangeEnhetDate}
                              todayButton={'I dag'}
                              showYearDropdown
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          {this.state.opprettRoyklop ? (
                            <div className="form-group">
                              <label>Neste tiltaksdato røykløp</label>

                              <DatePicker
                                className="form-control"
                                readonly={
                                  this.state.opprettRoyklop ? false : true
                                }
                                dateFormat="DD.MM.YYYY"
                                locale="nb_NO"
                                selected={this.state.datoRoyklop}
                                filterDate={this.isAfterEndDate}
                                onChange={this.onChangeRoyklopDate}
                                todayButton={'I dag'}
                                showYearDropdown
                              />
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                style={{ margin: '2px', width: '60px', height: '48px' }}
                disabled={!this.state.saveFinished}
                className="btn btn-primary pull-right"
                onClick={this.onOpprettFagdata}>
                OK
              </button>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </NKModal>
    );
  }
}
export default EtablerFagdataModal;
