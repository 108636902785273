import React, { Component } from 'react';
import { sendteSms } from 'api/smsApi.js';
import ReactTable from '@norkart/react-table';
import { getOrganisasjoner } from 'api/organisasjonApi';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import './sms.css';
var _ = require('underscore');

export default class SmsLogg extends Component {
  constructor(props) {
    super(props);
    this.state = { laster: false, data: [], antall: null };
  }

  componentDidMount() {
    //sendteSms(object, this.smsResult);
    getOrganisasjoner(this.gotOrg);
    this.setState({ laster: false });
  }

  gotOrg = (err, res) => {

    this.setState({ orgListe: res, valgt: res[0].smsKundeId });

    //   let orgListe = [];
    //   let e = null;
    //   console.log(org);
    //     org.forEach(element => {
    //       e = {
    //           navn: element.navn,
    //           smsKundeId: element.smsKundeId
    //       };
    //       orgListe.shift(e);
    //     });

    // console.log(orgListe);
  };

  kundeValgt = (val) => {
    this.setState({ valgt: val.target.value });
  };

  visSms = () => {
    // const object = {
    //     Applikasjon: "Brannforebygging",
    //     KundeId: "49DE6B5D-3A19-4C6B-BF35-8683CB64AC86",
    //     SendFoer: "2021-12-31T00:01:37.280Z",
    //     SendtEtter: "2021-01-01T00:01:37.280Z",
    //   };
    if (this.state.valgt === null) {
    } else {
      const object = {
        Applikasjon: 'Brannforebygging',
        KundeId: this.state.valgt,
        SendFoer: this.state.enddato,
        SendtEtter: this.state.startdato,
      };
      this.setState({ laster: true });
      sendteSms(object, this.smsResult);
    }
  };

  smsResult = (err, res) => {
    this.setState({ laster: false, data: res, antall: res.length });
  };

  onChangeStartdato = (val) => {
    this.setState({ startdato: val ? val : moment('01.01.1900') });
    // this.setState({ startdato: val });
  };

  onChangeEnddato = (val) => {
    this.setState({ enddato: val ? val : moment('01.01.2100') });
    //this.setState({ enddato: val });
  };

  render() {
    let loaderStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
    };

    // if (!this.state.data) {
    //   return <div>Laster...</div>;
    // }

    if (this.state.laster) {
      return <div style={loaderStyle} className="loader" />;
    }
    var columns = this.getColums();

    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h4>Oversikt SMS for kunde {this.state.adresse}</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <table>
              <tbody>
                <tr>
                  <td>Kunde</td>
                  <td style={{ width: '400px' }}>
                    <div>
                      <select
                        className="form-control"
                        id="type"
                        onChange={this.kundeValgt}
                        value={this.state.valgt}
                      >
                        {_.map(this.state.orgListe, function(o, i) {
                          return (
                            <option key={i} value={o.smsKundeId}>
                              {o.navn}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td style={{ width: '20px' }} />
                  <td>Dato fra</td>
                  <td style={{ width: '130px' }}>
                    <DatePicker
                      className="datepickerStor"
                      id="dato"
                      selected={moment(this.state.startdato)}
                      // startDate={moment()}
                      placeholderText="Velg dato.."
                      todayButton={'I dag'}
                      onChange={this.onChangeStartdato.bind(this)}
                      isClearable={true}
                      showYearDropdown
                    />
                  </td>
                  <td>Dato til</td>
                  <td style={{ width: '130px' }}>
                    <DatePicker
                      className="datepickerStor"
                      id="dato"
                      selected={moment(this.state.enddato)}
                      // startDate={moment()}
                      placeholderText="Velg dato.."
                      todayButton={'I dag'}
                      onChange={this.onChangeEnddato.bind(this)}
                      isClearable={true}
                      showYearDropdown
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-default"
                      style={{
                        background: '#51A026',
                        color: 'white',
                        height: '34px',
                      }}
                      onClick={this.visSms}
                      type="button"
                    >
                      Vis
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-sm-6">
            <div>
              <select
                className="form-control"
                id="type"
                onChange={this.kundeValgt}
                value={this.state.valgt}
              >
                {_.map(this.state.orgListe, function(o, i) {
                  return (
                    <option key={i} value={o.smsKundeId}>
                      {o.navn}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-6" />
        </div> */}
        <br />
        <div className="row">
          <div className="col-sm-12">
            <h4>Antall sendte sms: {this.state.antall}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <hr />
            <ReactTable
              items={this.state.data}
              idProperty="id"
              showIndex={false}
              filterable={false}
              columns={columns}
              selectableRows={false}
              toggleAll={false}
              onRowClick={null}
            />
          </div>
        </div>
      </div>
    );
  }
  getColums = () => {
    return [
      {
        id: 'mobilnummer',
        name: 'Mobilnummer',
        sortParams: 'mobilnummer',
        filterable: true,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'levertTidspunkt',
        name: 'Levert tid',
        sortParams: 'levertTidspunkt',
        filterable: false,
        formatter: function(item) {
          if (item.levertTidspunkt === null) {
            return '-';
          } else {
            var dato = new Date(item.levertTidspunkt);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      
      {
        id: 'tekst',
        name: 'Melding',
        sortParams: 'tekst',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
          <div className='tooltipx'>
            <span>{item.tekst.substring(0, 15)}...</span>
            <span className='tooltiptextx'>{item.tekst}</span>
          </div>
          );
        }
      },
      {
        id: 'statusinfo',
        name: 'Status',
        sortParams: 'statusinfo',
        filterable: false,
        isSorted: false,
        sortable: true,
        
      },
      //   {
      //     id: 'atributter',
      //     name: 'Bruksenhet id',
      //     sortParams: 'bruksenhet',
      //     filterable: false,
      //     formatter: function(item) {
      //       item.attributter.forEach(element => {
      //           if (element.navn = 'Bruksenhet') {
      //               return element.verdi;
      //           }
      //       });
      //   },
      //     }
    ];
  };
}
