import React, { Component } from 'react';
import _ from 'lodash';
import L from 'leaflet';
import { Map } from 'react-leaflet';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import 'components/Dialogs/NKFullModal.css';
import './KartEnheter.css';
import 'leaflet.fullscreen';
import $ from 'jquery';
import 'leaflet-minimap';
import './spretter2.js';
import 'leaflet-minimap/src/Control.MiniMap.css';
import ZoomControl from 'components/Kart/Controls/ZoomControl.jsx';
import FullscreenControl from 'components/Kart/Controls/FullscreenControl.jsx';
import LocateControl from 'components/Kart/Controls/LocateControl.jsx';
import PolygonControl from 'components/Kart/Controls/PolygonControl.jsx';
import * as getIcon from 'components/Kart/geticon.js';
/* import HitsControl from 'components/Kart/Controls/HitsControl.jsx'; */
import LayerControl from 'components/Kart/Controls/LayerControl.jsx';
import PlanClusterLayerControl from 'components/Kart/Controls/PlanClusterLayerControl.jsx';
import VelgKnappControl from 'components/Kart/Controls/VelgKnappControl.jsx';
import OppmaalingControl from 'components/Kart/Controls/OppmaalingControl.jsx';
import { contextInnstillingerKart } from 'context/contextInnstillinger.js';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
delete L.Icon.Default.prototype._getIconUrl;



var basisKart = null;
let spretter; //Ikke fjern!
let spretterLayer;

class PlanleggingsKart extends Component {
  state = {
    isMounted: false,
    zoomLevel: 13,
    lat: 63.9905722,
    lng: 12.3077975,
    points: [],
    enheter: this.props.enheter,
    royklop: this.props.royklop,
    objekter: this.props.enheter,
    valgteEnheter: this.props.valgteEnheter,
    valgteRoyklop: this.props.valgteRoyklop,
    valgte: this.props.valgteEnheter,
    visning: 'Enheter',
    polygonLayerInUse: null,
    brukPanorer: false,
    valgtFraKart: false,
    sprettEnhet: null,
    visGebyr: false,
    visRisiko: 'Ingen'
  };

  componentDidMount = () => {
    // this.getSenterPunkt();
    //window.L_DISABLE_3D = true;
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
    
    this.initierKart();
    this.initierKartEvents();

    this.setState({ isMounted: true });
  };

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  componentWillReceiveProps = nextProps => {
    let oppdater = false;
    if (this.state.isMounted) {
      if (
        !_.isEqual(_.sortBy(nextProps.enheter), _.sortBy(this.state.enheter))
      ) {
        this.setState({ enheter: nextProps.enheter });
        oppdater = true;
      }
      if (
        !_.isEqual(_.sortBy(nextProps.royklop), _.sortBy(this.state.royklop))
      ) {
        this.setState({ royklop: nextProps.royklop });
        oppdater = true;
      }
      if (
        !_.isEqual(
          _.sortBy(nextProps.valgteRoyklop),
          _.sortBy(this.state.valgteRoyklop)
        )
      ) {
        this.setState({ valgteRoyklop: nextProps.valgteRoyklop });
        oppdater = true;
      }
      if (
        !_.isEqual(
          _.sortBy(nextProps.valgteEnheter),
          _.sortBy(this.state.valgteEnheter)
        )
      ) {
        this.setState({ valgteEnheter: nextProps.valgteEnheter });
        oppdater = true;
      }
    }
    if (oppdater) {
      this.swapVisning(this.state.visning);
    }
  };

  swapVisning = visning => {
    setTimeout(() => this.swapVisning2(visning), 200);
  };

  swapVisning2 = visning => {
    this.setState({ visning: visning });
 //   debug('kart swap', visning);
    switch (visning) {
      case 'Enheter':
        this.setState({
          objekter: this.state.enheter,
          valgte: this.state.valgteEnheter
        });
        break;
      case 'Røykløp':
        this.setState({
          objekter: this.state.royklop,
          valgte: this.state.valgteRoyklop
        });
        break;
      default:
        this.setState({
          objekter: this.state.enheter,
          valgte: this.state.valgteEnheter
        });
    }
  //  debug('kart swap 2', this.state.enheter);
  };

  componentWillUnmount = () => {
    this.setState({ isMounted: false });
    basisKart = null;
  };

  getSenterPunkt = () => {
    if (this.state.isMounted) {
      this.setState({
        lat: contextInnstillingerKart._currentValue.latitude,
        lng: contextInnstillingerKart._currentValue.longitude,
        zoomLevel: contextInnstillingerKart._currentValue.zoomLevel,
      });
    }
  };

  initierKart = () => {
    basisKart = this.refs.basiskart.leafletElement;
    $('.leaflet-control-zoom-fullscreen').hide();

    //Scale
    L.control
      .scale({ position: 'bottomleft', metric: true, imperial: false })
      .addTo(basisKart);
    //Attribution
    basisKart.attributionControl.setPrefix(false);
    basisKart.attributionControl.addAttribution('Norkart Brannforebygging');
    //Layer for spretter
    spretterLayer = new L.FeatureGroup();
    basisKart.addLayer(spretterLayer);
  };

  initierKartEvents = () => {
    basisKart.on('zoomend', () => {
      if (this.state.isMounted) {
        this.setState({ zoomLevel: basisKart.getZoom() });
      }
    });
    basisKart.on('click', e => {
      if (this.layerControl) {
        this.layerControl.gotClick(e);
      }
    });
    /* basisKart.on('pointermove', (e) => {
      if (e.dragging){
        return;
      }
      var pixel = this.props.kart.getEventPixel(e.originalEvent);
      var hit = this.props.kart.forEachLayerAtPixel(pixel, function() {
        return true;
      });
      this.props.kart.getTargetElement().style.cursor = hit ? 'pointer' : '';
    }); */
  };

  visKnappControl = row => {
    this.setState({ sprettEnhet: row });
  };

  sprett = lok => {
    spretterLayer.clearLayers();
    if (lok.lokasjon === undefined || lok.lokasjon.lat === undefined) {
      return;
    }

    basisKart.panTo(new L.LatLng(lok.lokasjon.lat, lok.lokasjon.lon));
    //Vis spretter
    let zIcon = getIcon.sprettIcon;
    spretter = L.marker([lok.lokasjon.lat, lok.lokasjon.lon], {
      icon: zIcon,
      bounceOnAdd: true,
      bounceOnAddOptions: { duration: 1000, height: 200 }
    })
      .on('click', function() {
        spretterLayer.clearLayers();
      })
      .bindTooltip('Klikk for å fjerne..')
      .addTo(spretterLayer);

    setTimeout(() => {
      spretterLayer.clearLayers();
    }, 3000);
  };

  setPolygonInUse = layer => {
    let poly = null;
    if (layer != null) {
      poly = layer;
    }
    this.setState({ polygonLayerInUse: poly });
  };

  goFit = bounds => {
    if (this.state.valgtFraKart) {
      this.setState({ valgtFraKart: false });
      return;
    }
    this.setState({ valgtFraKart: false });
    if (this.state.brukPanorer) {
      return;
    }
    if (this.state.polygonLayerInUse != null) {
      basisKart.fitBounds(this.state.polygonLayerInUse.getBounds(), {
        padding: [10, 10]
      });
    } else {
      basisKart.fitBounds(bounds, { padding: [10, 10] });
    }
  };

  fjernAllePolygonLayers = () => {
    this.setState({ polygonLayerInUse: null });
    this.refPolygonControl.fjernAllePolygonLayers();
  };

  setBrukPanorer = bruk => {
    this.setState({ brukPanorer: bruk });
  };

  onResize = () => {
    setTimeout(() => basisKart.invalidateSize(), 200);
  };

  valgtFraKart = data => {
    this.setState({ valgtFraKart: true });
    this.props.valgtFraKart(data, this.state.visning);
  };

  visMatrikkelInfo = data => {
   // debug('soek matrikkel', data);
  };

  visSituasjonInfo = data => {
   // debug('soek situasjon', data);
  };

  visArbeidslisteInfo = data => {
  //  debug('soek arbeidsliste', data);
  };

  lukkVelgKnappControl = () => {
    this.setState({ sprettEnhet: null });
  };

  onClickedGj = (s, e) => {
    //ifm wms
    if (this.layerControl) {
      this.layerControl.gotClick(e);   
    }
  };


 onVisRisikoInfo = (item) => {

 };


 //Hva med røykløp
 visRisikoFraControl = (mode) => {
       /*  let enheter = this.state.enheter;
    this.setState({ enheter: [] }); */
    let objekter = this.state.objekter;
    this.setState({ objekter: [] });
    this.setState({ visRisiko: mode });
    setTimeout(() => {
     this.setState({ objekter });
    }, 1000); 
    
 };

  render() {
    const position = [this.state.lat, this.state.lng];

    let map = (
      <Map
        style={{ height: 'calc(100vh - 270px)', width: '100%' }}
        center={position}
        maxZoom={20}
        zoom={this.state.zoomLevel}
        zoomControl={false}
        fullscreenControl={true}
        ref='basiskart'>
        {/* <LayerControl ref={ref => (this.layerControl = ref)} kart={basisKart} modus={this.state.visning}  visWms={true} /> */}
        <LayerControl
          ref={ref => (this.layerControl = ref)}
          kart={basisKart}
          kartfra={this.props.kartfra}
          modus={this.state.visning}
          visning={this.props.visning}
          visRisikoFraControl={this.visRisikoFraControl}
          visRisiko={this.state.visRisiko}
        />
        <ZoomControl
          left={true}
          zoom={this.state.zoomLevel}
          showZoom={false}
          kart={basisKart}
        />{' '}
        
        <FullscreenControl />
        <LocateControl kart={basisKart} />
        <PlanClusterLayerControl
          objekter={this.state.objekter}
          valgte={this.state.valgte}
          kart={basisKart}
          kartfra={this.props.kartfra}
          visRisiko={this.state.visRisiko}
          goFit={this.goFit}
          valgtFraKart={this.valgtFraKart}
          modus={this.state.visning}
          visGebyr={this.state.visGebyr}
          parent='Planlegging'
          visMatrikkelInfo={this.visMatrikkelInfo}
          visSituasjonInfo={this.visSituasjonInfo}
          visArbeidslisteInfo={this.visArbeidslisteInfo}
          onClickedGj={this.onClickedGj}
          onVisRisikoInfo={this.onVisRisikoInfo}
        />
        <PolygonControl
          ref={ref => (this.refPolygonControl = ref)}
          zoomLevel={this.state.zoomLevel}
          onBrukPolygon={this.props.onBrukPolygon}
          onPolygonFjernet={this.props.onPolygonFjernet}
          setPolygonInUse={this.setPolygonInUse}
          setBrukPanorer={this.setBrukPanorer}
          kart={basisKart}
        />
        <OppmaalingControl />
        {this.state.sprettEnhet && (
          <VelgKnappControl
            enhet={this.state.sprettEnhet}
            lukk= {this.lukkVelgKnappControl}
            valgtFraKart={this.valgtFraKart}
            kartVisning={this.state.visning}
            visGebyr={this.state.visGebyr}
          />
        )}
      </Map>
    );

    return <div>{map}</div>;
  }
}

export default PlanleggingsKart;
