import React from 'react';
import { withRouter } from 'lib/enhancers';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';
import enhetApi from 'api/enhetApi';
import royklopApi from 'api/royklopApi.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NyOppdaterTiltaktype from '../Tiltak/NyOppdaterTiltaktype.jsx';
import DialogStore from 'lib/DialogStore';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
var _ = require('underscore');

var valgt;
let typeTest = '';
var aarsakListe = null;
var risikoOverstyr = false;
var sotmengdeListe = null;

const modalDialogs = {
  NyOppdaterTiltaktype: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen}>
      <NyOppdaterTiltaktype
        onClose={onClose}
        statusTiltaktype={dialogData.statusTiltaktype}
        onNyEndreTiltaktypeOK={owner.onNyEndreTiltaktypeOK}
        tiltakType={dialogData.tiltakType}
        lagretTiltak={dialogData.lagretTiltak}
        type={dialogData.type}
        tiltakNavn={dialogData.tiltakNavn}
        hyppighetAntalldager={dialogData.hyppighetAntalldager}
      />
    </NKModal>
  ),
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel='Dato påkreves'
      melding='Dato kan ikke være blank.'
      isOpen={isOpen}
      onClose={onClose}
    />
  ),
};

class EndreTiltakshistorikk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(this.props.valgt.dato),
      lukket: this.props.valgt.utfort,
      id: null,
      lagretTiltak: null,
      tiltakType: null,
      tiltakNavn: this.props.valgt.tiltakNavn,
      tiltak: null,
      sotmengde: null,
      startDate: moment(Date.now()),
      arsakId: null,
      hyppighetTekst: '',
      laster: true,
     merknad: ''
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    this.setState({ id: this.props.id });
    valgt = this.props.valgt;
    this.setState({ tiltakNavn: valgt.tiltak });
    typeTest = this.props.type;
    if (typeTest === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakOppslagsdataForArbOgBr(
        0,
        this.props.id,
        this.gotOppslagsdata
      );
      enhetApi.getTiltakBruksenhet(valgt.id, this.tiltakHentet);
    } else {
      royklopApi.getRoyklopTiltakOppslagsdataForArbOgBr(
        0,
        this.props.id,
        this.gotOppslagsdata
      );
      royklopApi.getTiltakRoyklop(valgt.id, this.tiltakHentet);
    }
  }

  gotOppslagsdata = (err, data) => {
    console.log(data);
    risikoOverstyr =
      this.props.type === 'bruksenhet'
        ? data.bruksenhetRisikoOverstyr
        : data.royklopRisikoOverstyr;
    this.setState({ oppslagsdata: data });
    this.setDefaultData();
    //this.setTiltakTypeListe();
  };

  tiltakHentet = (err, tiltak) => {
    this.setState({
      tiltak: tiltak,
      merknad: tiltak.merknad,
      arsakId: tiltak.arsakIkkeUtfortId,
    });
  };

  setDefaultData = () => {
    this.setLukket(this.props.valgt.utfort);
    let defaultTiltak = _.find(
      this.state.oppslagsdata.tiltakTypeListe,
      function(x) {
        return x.tekst === valgt.tiltak;
      }
    );
    this.setState({
      tiltakId: defaultTiltak.id,
    });
    this.setState({ risiko: defaultTiltak.risikovurdering && !risikoOverstyr });
    this.setState({
      hyppighetTekst:
        defaultTiltak.risikovurdering && !risikoOverstyr
          ? ''
          : this.props.valgt.hyppighet,
    });
    if (valgt.hyppighet !== 0 && valgt.hyppighet != null) {
      this.setState({
        hyppighetId: _.find(
          this.state.oppslagsdata.tiltakHyppighetListe,
          function(x) {
            return x.tekst === valgt.hyppighet;
          }
        ).id,
      });
    }

    var utfortAv = _.find(this.state.oppslagsdata.tiltakPersoner, function(x) {
      return x.navn === valgt.utfortAv;
    });

    if (utfortAv !== undefined) {
      this.setState({ utfortav: utfortAv.id });
    } else {
      this.setState({ utfortav: this.state.oppslagsdata.tiltakPersoner[0].id });
    }

    if (typeTest !== 'bruksenhet') {
      if (valgt.sotmengde) {
        this.setState({
          sotmengde: _.find(this.state.oppslagsdata.tiltakSotmengde, function(
            x
          ) {
            return x.tekst === valgt.sotmengde;
          }).id,
        });
      }
    }
    aarsakListe = this.state.oppslagsdata.tiltakArakIkkeUtfort;
    sotmengdeListe = this.state.oppslagsdata.tiltakSotmengde;
    let bl = {
      id: null,
      tekst: '',
      aktiv: false,
    };
    if (aarsakListe != null) {
      aarsakListe.unshift(bl);
    }
    if (sotmengdeListe != null) {
      sotmengdeListe.unshift(bl);
    }
    this.setState({ laster: false });
  };

  handleTypeChange = (data) => {
    this.setState({
      tiltakId: data.target.value,
    });
    var tiltak = _.find(this.state.oppslagsdata.tiltakTypeListe, function(x) {
      return x.id === data.target.value;
    });
    this.setState({ risiko: tiltak.risikovurdering && !risikoOverstyr });
    let tekst = '';
    if (tiltak.hyppighetId !== 0 && tiltak.hyppighetId != null) {
      tekst = _.find(this.state.oppslagsdata.tiltakHyppighetListe, function(x) {
        return x.id === tiltak.hyppighetId;
      }).tekst;
    }
    this.setState({
      hyppighetTekst: tiltak.risikovurdering && !risikoOverstyr ? '' : tekst,
    });
  };

  // handleHyppighetChange = data => {
  //   this.setState({
  //     hyppighetId: data.target.value
  //   });
  // };

  handleUtfortavChange = (data) => {
    this.setState({
      utfortav: data.target.value,
    });
  };

  handleDatePickerChange = (date) => {
    if (date == null) {
      this.dialogs.setOpenDialog('OkDialog');
    }
    this.setState({ selectedDate: date });
  };

  handleMerknadChange = (data) => {
    this.setState({
      merknad: data.target.value,
    });
  };

  handleSotmengdeChange = (data) => {
    this.setState({ sotmengde: data.target.value });
  };

  handleArsakChange = (data) => {
    this.setState({
      arsakId: data.target.value === 0 ? null : data.target.value,
    });
  };

  lukketChange = () => {
    let lukket = !this.state.lukket;
    this.setLukket(lukket);
  };

  setLukket = (lukket) => {
    if (lukket) {
      this.setState({
        lukket: true,
        lukketText: 'Utført',
      });
    } else {
      this.setState({
        lukket: false,
        lukketText: 'Ikke utført',
      });
    }
    this.setState({ utfort: lukket });
  };

  onLagreClick = () => {
    console.log(this.state.sotmengde);
    if (this.state.selectedDate == null) {
      this.dialogs.setOpenDialog('OkDialog');
      return;
    }
    var hid = this.state.hyppighetId;
    var hyppighet = _.find(
      this.state.oppslagsdata.tiltakHyppighetListe,
      function(tiltak) {
        return tiltak.id === hid;
      }
    );
    this.setState({
      hyppighetAntalldager: hyppighet !== undefined ? hyppighet.tekst2 : null,
    });

    var oppdatertTiltak = this.state.tiltak;
    oppdatertTiltak.dato = moment(this.state.selectedDate).format(
      'YYYY-MM-DDTHH:00:00'
    );
    oppdatertTiltak.tiltakTypeId = this.state.tiltakId;
    oppdatertTiltak.utfort = this.state.utfort;
    oppdatertTiltak.utfortAvId = this.state.utfortav;
    oppdatertTiltak.hyppighetId = this.state.hyppighetId;
    oppdatertTiltak.merknad = this.state.merknad;
    this.state.utfort
      ? (oppdatertTiltak.arsakIkkeUtfortId = null)
      : (oppdatertTiltak.arsakIkkeUtfortId = this.state.arsakId);

    if (typeTest === 'bruksenhet') {
      enhetApi.updateTiltakBruksenhet(
        oppdatertTiltak.id,
        oppdatertTiltak,
        this.onUpdateOK
      );
    } else {
      oppdatertTiltak.sotmengdeId = this.state.sotmengde;
      royklopApi.updateTiltakRoyklop(
        oppdatertTiltak.id,
        oppdatertTiltak,
        this.onUpdateOK
      );
    }
  };

  onUpdateOK = (err, tiltak) => {
    console.log(tiltak);
    this.dialogs.closeDialog();
    this.props.onCloseModal(tiltak, this.state.risiko);
  };

  render() {
    if (this.state.laster) {
      return <div className="loader" />;
    }

    return (
      <div>
        <h2>Endre historisk tiltak {this.props.type}</h2>
        <hr />
        <div>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='form-group margin-left-10 margin-right-15'>
                <label>Type tiltak</label>
                <select
                  className='form-control'
                  id='type'
                  onChange={this.handleTypeChange}
                  value={this.state.tiltakId}>
                  {_.map(this.state.oppslagsdata.tiltakTypeListe, function(
                    o,
                    i
                  ) {
                    return (
                      <option key={i} value={o.id}>
                        {o.tekst}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group margin-left-10 margin-right-15'>
                <label>Hyppighet</label>
                <input
                  type='text'
                  className='form-control'
                  defaultValue={this.state.hyppighetTekst}
                  readOnly
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='form-group margin-left-10 margin-right-15'>
                <label>Dato</label>
                <br />
                <DatePicker
                  className='hoyde34 width120'
                  placeholderText='Dato'
                  selected={this.state.selectedDate}
                  startDate={this.state.startDate}
                  onChange={this.handleDatePickerChange}
                  todayButton={'I dag'}
                  filterDate={this.isWeekday}
                  isClearable={false}
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          {this.state.risiko ? (
            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group margin-left-10'>
                  Valgt tiltakstype er tilknyttet risikovurdering. Hvis det er
                  ønskelig å overstyre risikovurderingen kan dette gjøres på
                  objektet.
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className='row'>
            <div className='col-sm-4'>
              <div className='form-group margin-left-10 margin-right-15'>
                <label>Utført av</label>
                <select
                  className='form-control'
                  id='type'
                  onChange={this.handleUtfortavChange}
                  value={this.state.utfortav}>
                  {_.map(this.state.oppslagsdata.tiltakPersoner, function(
                    o,
                    i
                  ) {
                    return (
                      <option key={i} value={o.id}>
                        {o.navn}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className='col-sm-4'>
              <div className='form-group margin-left-10 margin-right-15'>
                <br />
                <Switch
                  className='pull-left'
                  on={this.state.lukket}
                  onClick={this.lukketChange}
                />
                <label className='lukketText'>{this.state.lukketText}</label>
              </div>
            </div>
            {!this.state.lukket ? (
              <div className='col-sm-4'>
                <div className='form-group margin-left-10 margin-right-15'>
                  <label>Årsak ikke utført</label>
                  <select
                    className='form-control'
                    id='type'
                    onChange={this.handleArsakChange}
                    value={this.state.arsakId == null ? 0 : this.state.arsakId}>
                    {_.map(aarsakListe, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            ) : null}
          </div>
          {typeTest !== 'bruksenhet' ? (
            <div className='row'>
              <div className='col-sm-4'>
                <div className='form-group margin-left-10 margin-right-15'>
                  <label>Sotmengde</label>
                  <select
                    className='form-control'
                    id='type'
                    onChange={this.handleSotmengdeChange}
                    value={this.state.sotmengde}>
                    {_.map(sotmengdeListe, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          ) : null}
          <div className='row'>
            <div className='col-sm-12'>
              <div className='form-group margin-left-10 margin-right-15'>
                <label>Merknad</label>
                <textarea
                  className='form-control textareaNoresize'
                  type='textarea'
                  id='message'
                  placeholder='Merknad'
                  maxLength='2000'
                  rows='3'
                  onChange={this.handleMerknadChange}
                  value={this.state.merknad}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-lg-12 col-md-12'>
            <div className='pull-right margin-right-15'>
              <input
                type='button'
                className='btn btn-primary height48'
                value='OK'
                onClick={this.onLagreClick}
              />
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(EndreTiltakshistorikk);
