/* eslint-disable no-extra-semi */
import React from 'react';
import './AvvikAnmerkninger.css';
import anmerkningApi from 'api/anmerkningApi';
import avvikApi from 'api/avvikApi';

class LagreTekstbit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tittel: 'Lagre tekst for senere bruk',
			sporsmal1: 'Ønsker du å ta vare på teksten som du har merket ut?',
			sporsmal2: '',
			tekst: '',
			harTekst: false,
		};
	}

	componentDidMount() { 
		//Alt er '' - ingen tekst
		if (this.props.merkettekst === '' && this.props.tekst === '') {
			this.setState({
				sporsmal1: 'Du har IKKE merket ut noe.',
				sporsmal2: 'Det er heller ingen tekst i feltet.',
				harTekst: false,
			});
			//Kun merket ut
		} else if (this.props.merkettekst !== '') {
			this.setState({
				sporsmal1: 'Ønsker du å ta vare på det du har merket ut?',
				sporsmal2: '',
				tekst: this.props.merkettekst,
				harTekst: true,
			});
			//Kun tekst
		} else if (this.props.merkettekst === '' && this.props.tekst !== '') {
			this.setState({
				sporsmal1: 'Du har IKKE merket ut noe!',
				sporsmal2: 'Ønsker du å ta vare på HELE teksten?',
				tekst: this.props.tekst,
				harTekst: true,
			});
		}
	}

	lagre() {
		var tekst = this.state.tekst;
		var nyTekst = { id: 0, tekst: tekst, aktiv: true };

		if (this.props.modul === 'anmerkninger') {
			anmerkningApi.createAnmerkningAdmBeskrivelse(
				nyTekst,
				this.lagretTekst.bind(this)
			);
		} else {
			avvikApi.createAvvikAdmBeskrivelse(nyTekst, this.lagretTekst.bind(this));
		}
	}

	onClose() {
		this.props.onClose();
	}

	lagretTekst() {
		this.props.onLagret();
	}

	render() {
		return (
			<div className='modal-content'>
				<div className='modal-header'>
					<button
						className='close'
						type='button'
						onClick={this.onClose.bind(this)}>
						<span>x</span>
						<span className='sr-only'>Lukk</span>
					</button>
					<h4 className='modal-title'> {this.state.tittel} </h4>
				</div>
				<div className='modal-body divTekstbiter'>
					<label>{this.state.sporsmal1}</label>
					<br />
					<label>{this.state.sporsmal2}</label>
					<br />
					<label>{this.state.harTekst}</label>
					<br />

					<>{/* <div className='tekstbit'>{this.state.tekst}</div> */}
						{this.state.harTekst ? (
							<div className='tekstbit'>
								<div dangerouslySetInnerHTML={{ __html: this.state.tekst }}></div>
							</div>
						) : (
							<div>
								<span>Ingen tekst.., kan ikke lagre..</span>
							</div>
						)}

						<br />
						<br />
						<div className='btn-toolbar pull-right' role='toolbar'>
							{this.state.harTekst && (
								<button
									type='button'
									onClick={this.lagre.bind(this)}
									className='btn btn-primary h48'>
									&nbsp;&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;&nbsp;
								</button>
							)}
						</div>
					</>

					<br />
					<br />
				</div>
			</div>
		);
	}
}

export default LagreTekstbit;
