import React from 'react';
import ReactTable from '@norkart/react-table';
import _ from 'underscore';
import { getBrukereInnleggetNow } from 'api/loggApi';
import { debugFactory, promisifyApiCall } from 'utils';

const debug = debugFactory('nk:BrukereNow');

class index extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
    this.state = {
      brukereNow: [],
    };
  }

  componentDidMount() {
    this.hentData();
  }

  hentData = () => {
    promisifyApiCall(getBrukereInnleggetNow)().then((data) => {
      this.setState({ brukereNow: data });
      debug('0712 get Brukere now', data);
    });
  };

  getColumns() {
    return [
      {
        id: 'tekst1',
        name: 'Navn',
        sortParams: 'tekst1',
        isSorted: false,
        sortDirection: 'asc',
      },
      {
        id: 'tekst2',
        name: 'Organisasjon',
        sortParams: 'tekst2',
        isSorted: true,
        sortDirection: 'asc',
        filterable: true
      },
    ];
  }

  render() {
    if (this.state.brukereNow === [] ) {
      return <div>Henter data..</div>;
    }
    return (
      <div>
        <h2>Brukere innlogget nå:</h2>
        <h4>Antall: {this.state.brukereNow.length}, siste 10 min.</h4>
        
        <button onClick={this.hentData} className='btn btn-primary pull-right'>Oppdater</button>
        <br /><br />
        <ReactTable
          items={this.state.brukereNow}
          showIndex={false}
          filterable={true}
          columns={this.getColumns()}
          selectableRows={false}
        />
      </div>
    );
  }
}
export default index;
