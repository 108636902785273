/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter } from 'lib/enhancers';
import AnmerkningListe from '../avvikAnmerkning/AnmerkningListe.jsx';
import AvvikListe from '../avvikAnmerkning/AvvikListe.jsx';
import TiltakstyperModal from '../Tiltakstyper/TiltakstyperModal.jsx';
import TiltakshistorikkModal from '../Tiltakshistorikk/TiltakshistorikkModal.jsx';
import 'components/Dialogs/NKFullModal.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import NyttTiltak from '../Tiltak/NyttTiltak.jsx';
import EndreRoyklop from '../Royklop/EndreRoyklop.jsx';
import EndreIldsted from '../Ildsted/EndreIldsted.jsx';
import IldstedSituasjon from '../Ildsted/IldstedSituasjon.jsx';
import RoyklopSituasjonTre from '../Royklop/RoyklopSituasjonTre.jsx';
import royklopApi from 'api/royklopApi';
import ildstedApi from 'api/ildstedApi';
import enhetApi from 'api/enhetApi';
import NKIcon from 'nka-icons';
import Objekthistorikk from 'modules/Objekter/Objekthistorikk.jsx';
import NyOppdaterTiltaktype from '../Tiltak/NyOppdaterTiltaktype.jsx';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import RoykIkkeIBruk from 'images/IkkeiBrukRoyk.png';
import DeltPipe from 'images/deltpipe.png';
import moment from 'moment';
import { startRisikoanalyseRoyklop } from 'api/risikoApi';
// Utils
import { getDerivedChanges, debugFactory } from 'utils';
import { sjekkRoyklopRisiko } from '../../../api/risikoApi.js';
const debug = debugFactory('nk:gjennomforing:royklop:situasjon');

require('../gjennomforing.css');
require('components/Dialogs/NKFullModal.css');
var _ = require('underscore');
var info = '';
var identi = '';
var farge = 'black';
var fritidseiendom = [];
var byggkode = 0;

class RoyklopSituasjon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      royklop: this.props.royklop,
      ildstedListe: this.props.royklop.ildstedListe,
      nyttTiltak: null,
      tiltakType: null,
      slettType: '',
      tiltakRisiko: false,
      fritidseiendom: false
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedChanges(
      {
        royklop: 'royklop',
        'royklop.ildstedListe': 'ildstedListe'
      },
      nextProps,
      prevState,
      debug
    );
  }

  componentDidMount() {
    
  }

  openNyttTiltak = () => {
    this.dialogs.setOpenDialog('NyttTiltak', {
      id: this.state.royklop.id,
      arbeidslisteId: this.props.arbListeId,
      brId: this.state.royklop.bruksenhetId
    });
  };

  addNyttTiltak = (tiltak, tiltakNavn, hyppighetAntalldager, risikovurdering, tiltakRisiko, erFritidsbygg) => {
    this.setState({
      tiltakNavn: tiltakNavn,
      hyppighetAntalldager: hyppighetAntalldager,
      risikovurdering: risikovurdering,
      // tiltakRisiko: tiltakRisiko,
      // fritidseiendom: erFritidsbygg,
      dato: tiltak.dato
    });
    tiltak.arbeidslisteId = this.props.arbListeId;
    royklopApi.saveTiltakRoyklop(tiltak, this.tiltakLagret);
    this.dialogs.closeDialog();
  };

  tiltakLagret = (err, nyttTiltak) => {
    this.setState({ nyttTiltak: nyttTiltak });
    console.log(this.state.royklop.bruksenhetId);
    var nyTiltaktype = {
      royklopId: nyttTiltak.royklopId,
      bruksenhetId: this.state.royklop.bruksenhetId,
      tiltaktypeid: nyttTiltak.tiltakTypeId,
      hyppighetid: nyttTiltak.hyppighetId,
      nestedato: this.state.risikovurdering ? null : moment(this.state.dato).add(this.state.hyppighetAntalldager, 'days').format('YYYY-MM-DDTHH:00:00'),
      sistedato: null,
      sisteStatus: false,
      risikovurdering: this.state.risikovurdering //? false : true // sende true hvis ikke fritid, da false
      //risikovurdering: this.state.risikovurdering ? !this.state.fritidseiendom: false

    };
    royklopApi.nyOppdaterRoyklopTiltakType(nyTiltaktype, this.tiltakTypeSjekket);

  };

 
  tiltakTypeSjekket = (err, tiltaktype) => {
    console.log(tiltaktype);
      if (this.state.risikovurdering) {
        this.dialogs.setOpenDialog('OkDialogRisiko', { tittel: 'Tiltak', melding: 'Objektet er underlagt risikovurdering. Hyppighet og neste dato vil bli oppdatert på bakgrunn av dette.' });
      } else {
        if (tiltaktype != null) {
          console.log('her');
          this.dialogs.setOpenDialog('OkDialogRisiko', { tittel: 'Tiltak', melding: 'Antatt dato for neste ' + this.state.tiltakNavn + ' er beregnet til ' + tiltaktype.nesteDato.substring(8, 10) + '.' + tiltaktype.nesteDato.substring(5, 7) + '.' + tiltaktype.nesteDato.substring(0, 4) });
        } 
        //this.props.oppdater();
      }
  }

  nyttTiltakOk = () => {
    this.props.oppdater();
    this.dialogs.closeDialog();
  }

  onNyEndreTiltaktypeOK = () => {
    this.props.oppdater();
    this.dialogs.closeDialog();
  };

  openHistorikk = () => {
    if (this.props.base_path !== undefined) {
      this.props.router.push({
        pathname:
          this.props.base_path +
          '/' +
          this.state.royklop.bruksenhetId +
          '/tiltakshistorikk',
        state: {
          type: 'røykløp',
          id: this.state.royklop.id,
          adresse: this.props.adresse,
          eiendom: this.props.eiendom
        }
      });
    } else {
      this.dialogs.setOpenDialog('TiltakshistorikkModal', {
        id: this.state.royklop.id
      });
    }
  };

  // closeHistorikk: function() {
  //     this.setState({ modalHistorikkIsOpen: false });
  // },
  openEndre = () => {
    this.dialogs.setOpenDialog('EndreRoyklop', {
      royklopId: this.state.royklop.id
    });
  };

  endreRoyklop = endretRoyklop => {
    royklopApi.updateRoyklop(
      endretRoyklop.id,
      endretRoyklop,
      this.royklopOppdatert
    );
    this.dialogs.closeDialog();
  };

  royklopOppdatert = (err, royklop) => {
    this.props.oppdater();
  };

  openNyttIldsted = () => {
    this.dialogs.setOpenDialog('EndreIldsted');
  };

  addNyttIldsted = (nyttIldsted, valgttype) => {
    nyttIldsted.bruksenhetId = this.state.royklop.bruksenhetId;
    nyttIldsted.royklopId = this.state.royklop.id;
    ildstedApi.saveIldsted(nyttIldsted, this.props.oppdater);
    this.dialogs.closeDialog();
  };

  nyttIldstedLagret = (err, ildsted) => {
    this.props.oppdater();
  };

  oppdaterSituasjon = () => {
    this.props.oppdater();
  };

  openAnmerkninger = () => {
    this.dialogs.setOpenDialog('AnmerkningListe', {
      adresse: this.props.adresse,
      identi: identi,
      bruksenhetid: this.state.royklop.bruksenhetId,
      objektReferanseId: this.state.royklop.id,
      royklopid: this.state.royklop.id,
      info: info
    });
  };

  openAvvik = () => {
    this.dialogs.setOpenDialog('AvvikListe', {
      adresse: this.props.adresse,
      identi: identi,
      bruksenhetid: this.state.royklop.bruksenhetId,
      objektReferanseId: this.state.royklop.id,
      royklopid: this.state.royklop.id,
      info: info
    });
  };

  closeVisAvikAnmModal = () => {
    this.setState({ modalVisAvikAnmIsOpen: false });
  };

  openVisAvikAnmModal = () => {
    this.setState({ modalVisAvikAnmIsOpen: true });
  };

  closeIkkeImpl = () => {
    this.setState({ IkkeImplIsOpen: false });
  };

  openIkkeImpl = () => {
    this.setState({ IkkeImplIsOpen: true });
  };

  openNyttRoyklop = () => {
    this.setState({ modalNyttRoykIsOpen: true });
  };

  closeNyttRoykModal = () => {
    this.setState({ modalNyttRoykIsOpen: false });
  };

  lagreNyEndreAnmerkning = () => {
    this.setState({ modalNyEndreAnmerkningIsOpen: false });
  };

  hentRoyklop = () => {
    //console.log('hente røykløp');
  };

  openTiltakstyper = () => {
    if (this.props.base_path !== undefined) {
      this.props.router.push({
        pathname:
          this.props.base_path +
          '/' +
          this.state.royklop.bruksenhetId +
          '/tiltakstyper',
        state: {
          type: 'røykløp',
          id: this.state.royklop.id,
          adresse: this.props.adresse,
          eiendom: this.props.eiendom,
          royklop: this.props.royklop,
          brId: this.state.royklop.bruksenhetId,
          orgRisiko: this.props.risiko
        }
      });
    } else {
      this.dialogs.setOpenDialog('TiltakstyperModal', {
        id: this.state.royklop.id,
        royklop: this.props.royklop,
        brId: this.state.royklop.bruksenhetId,
        orgRisiko: this.props.risiko
      });
    }
  };

  openRoyklopSituasjonTre = () => {
    this.dialogs.setOpenDialog('RoyklopSituasjonTre', {
      royklopId: this.state.royklop.id
    });
  };

  openObjektendringer = () => {
    this.dialogs.setOpenDialog('Objekthistorikk', {
      id: this.state.royklop.id
    });
  };

  onSlettClick = () => {
    royklopApi.getAntallBruksenheterTilknyttetLop(
      this.props.royklop.id,
      this.gotAntallBruksenheter
    );
  };

  gotAntallBruksenheter = (err, antall) => {
    let a = 0;
    if (antall.tekst){
      a = parseInt(antall.tekst);
    };
    if (a > 1) {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Slett røykløp',
        melding:
          'Dette røykløpet er knyttet til flere bruksenheter! Du sletter nå valgt røykløp. Dette innebærer at tilknyttede avvik, anmerkninger, ildsteder og tiltakshistorikk vil bli slettet. Vil du fortsette?'
      });
      this.setState({ slettType: 'slett' });
    } else {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Slett røykløp',
        melding:
          'Du sletter nå valgt røykløp. Dette innebærer at tilknyttede avvik, anmerkninger, ildsteder og tiltakshistorikk vil bli slettet. Vil du fortsette?'
      });
      this.setState({ slettType: 'slett' });
    }
  };

  onFjernTilknytningClick = () => {
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Fjern tilknytning',
      melding:
        'Du fjerner nå røykløpets tilknytning til bruksenheten. Dette innebærer at avvik, anmerkninger, tiltakshistorikk og tilknyttede ildsteder også blir slettet. Hvis ingen andre enheter er tilknyttet røykløpet slettes det. Vil du fortsette?'
    });
    this.setState({ slettType: 'fjern' });
  };

  closeSlettDialogOk = () => {
    if (this.state.slettType === 'slett') {
      royklopApi.deleteRoyklop(this.state.royklop.id, this.royklopSlettet);
    } else if (this.state.slettType === 'fjern') {
      royklopApi.fjernTilknytningTilBruksenhet(
        this.state.royklop.id,
        this.state.royklop.bruksenhetId,
        this.royklopTilknytningFjernet
      );
    } else {
      this.dialogs.closeDialog();
    }
  };

  royklopSlettet = (err, royklop) => {
    this.props.oppdater();
    this.dialogs.closeDialog();
  };

  royklopTilknytningFjernet = (err, royklop) => {
    this.props.oppdater();
    this.dialogs.closeDialog();
  };

  risiko = () => {
    sjekkRoyklopRisiko(this.state.royklop.id, this.state.royklop.bruksenhetId, this.gotSjekkRisiko); 
  }

  gotSjekkRisiko = (err, res) => {
    if (res) {
      this.dialogs.setOpenDialog('OkDialogRisiko', {
        tittel: 'Risikoanalyse',
        melding:
          'Risikoanalyse på objektet er nå startet.'
      });
      startRisikoanalyseRoyklop(this.state.royklop.bruksenhetId, this.state.royklop.id,
        () => {
          console.log('Risikoanalyse kjørt');
        });
    } else {
      this.dialogs.setOpenDialog('OkDialogRisiko', {
        tittel: 'Risikoanalyse',
        melding:
          'Systemet er ikke satt opp til å kjøre risikoanalyse på denne enheten.'
      });
    }
  }
  
  render() {
    if (this.state.royklop.antallLopPaaAliste > 0) {
      farge =
        this.state.royklop.antallLopPaaAliste >
        this.state.royklop.antallLopPaaAlisteBesokt
          ? 'red'
          : 'green';
    }
    info =
      this.state.royklop.modell !== null
        ? this.state.royklop.modell
        : '' + ' ' + this.state.royklop.plassering !== null
        ? this.state.royklop.plassering
        : '' + ' ' + this.state.royklop.dimensjon !== null
        ? this.state.royklop.dimensjon
        : '';
    var index = '#' + this.state.royklop.id;
    let p = this.state.royklop.plassering;
    if (p === null) {
      p = '';
    }
    let d = this.state.royklop.dimensjon;
    if (d === null) {
      d = '';
    }
    identi = p + ' ' + d;
    let btnDivStyle = { width: '100%', paddingLeft: '30px' };
    let btnStyle = {
      width: 'calc(100% - 54px)',
      background: '#d9d9d9',
      borderColor: '#999999',
      paddingRight: '0px',
      paddingLeft: '4px',
      maxHeight: '42px',
      display: 'inline-block',
      whiteSpace: 'nowrap'
    };
    let ellipsedStyle1 = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 100px)',
      textAlign: 'left',
      display: 'inline-block'
    };
    let ellipsedStyle2 = {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 124px)',
      textAlign: 'left',
      display: 'inline-block'
    };
    let propilStyle = {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'inline-block',
      width: '60px',
      height: '40px',
      lineHeight: '40px',
      float: 'right'
    };
    let propilStyleAfter = {
      float: 'right',
      marginTop: '10px',
      marginRight: '4px'
    };
    let menuDivStyle = {
      textAlign: 'center',
      verticalAlign: 'bottom',
      width: '40px',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      position: 'relative'
    };
    let menuButtonStyle = {  
      border: 'none',
      background: 'transparent'
    };
    let sitKnappStyle = {
      float: 'left',
      marginLeft: '10px',
      marginTop: '4px'
    };
    return (
      <div>
        <div style={btnDivStyle}>
          {/*Button*/}
          <div
            style={btnStyle}
            className="container-fluid btn btn-default sbpilpanel collapsed"
            href={index}
            data-toggle="collapse">
            <span>
              {this.state.royklop.ibruk ? (
                <div>
                  <NKIcon
                    icon="royklop"
                    style={{
                      marginRight: '1px',
                      marginTop: '10px',
                      float: 'left',
                      display: 'inline-block'
                    }}
                    size="0.8"
                    color="nk-black"
                  />
                </div>
              ) : (
                <div>
                  <img
                    height="20"
                    width="20"
                    src={RoykIkkeIBruk}
                    alt="Ikke i bruk"
                    style={{
                      marginRight: '1px',
                      marginTop: '10px',
                      float: 'left',
                      display: 'inline-block'
                    }}
                  />
                </div>
              )}
              {/* Markere delt røykløp */}
            </span>
            <span>
              {this.state.royklop.antallBruksenheter > 1 ? (
                <img
                  height="22"
                  width="22"
                  src={DeltPipe}
                  alt="Delt"
                  style={{
                    marginRight: '1px',
                    marginTop: '8px',
                    float: 'left',
                    display: 'inline-block'
                  }}
                />
              ) : null}
            </span>

            {this.state.royklop.antallBruksenheter > 1 ? (
              <h4 style={ellipsedStyle2}>
                {this.state.royklop.modell} {this.state.royklop.plassering}{' '}
                {this.state.royklop.dimensjon}
              </h4>
            ) : (
              <h4 style={ellipsedStyle1}>
                {this.state.royklop.modell} {this.state.royklop.plassering}{' '}
                {this.state.royklop.dimensjon}
              </h4>
            )}

            <div style={propilStyle}>
              <span
                style={propilStyleAfter}
                className="chevron fa fa-fw fa-lg"
              />

              {this.state.royklop.antallLopPaaAliste === 0 ||
              !this.props.base_path ? null : (
                <span
                  className={farge}
                  style={{
                    float: 'right',
                    marginRight: '5px',
                    fontSize: '16px'
                  }}>
                  {this.state.royklop.antallLopPaaAlisteBesokt}/
                  {this.state.royklop.antallLopPaaAliste}
                </span>
              )}
            </div>
          </div>

          {/* menu */}
          <div style={menuDivStyle}>{/*  className="dropdown"> */}
            <button
              style={menuButtonStyle}
              className="dropdown-toggle"
              data-toggle="dropdown">
              <span>
                <NKIcon icon="meny" size="1.5" color="nk-black" />
              </span>
            </button>
            <ul style={{ float: 'right' }} className="dropdown-menu">
              <li>
                <a style={{ cursor: 'pointer' }} onClick={this.openNyttIldsted}>
                  Nytt ildsted
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.openRoyklopSituasjonTre}>
                  Vis situasjon
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.onFjernTilknytningClick}>
                  Fjern tilknytning...
                </a>
              </li>
              <li>
                <a style={{ cursor: 'pointer' }} onClick={this.onSlettClick}>
                  Slett røykløp
                </a>
              </li>
              <li className="divider" />
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.openTiltakstyper}>
                  Regelmessige tiltak
                </a>
              </li>
              <li>
                <a style={{ cursor: 'pointer' }} onClick={this.openHistorikk}>
                  Tiltakshistorikk
                </a>
              </li>
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.openObjektendringer}>
                  Objektendringer
                </a>
              </li>
              { this.props.risiko ? 
              <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.risiko}>
                  Start risikoanalyse
                </a>
              </li>
              : null }
            </ul>
          </div>

          {/* panel */}
          <div
            id={this.state.royklop.id}
            className="collapse"
            style={{ width: '84%', paddingLeft: '30px' }}>
            <br />
            <div className="row">
              <div className="col-sm-6 col-xs-12 col-lg-3">
                <button
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={this.openEndre}>
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="rediger"
                      color="nk-black"
                    />
                  </span>
                  <span style={sitKnappStyle}>Endre</span>
                </button>
              </div>
              <div className="col-sm-6 col-xs-12 col-lg-3">
                <button
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={this.openNyttTiltak}>
                  <span className="pull-left">
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="feiing"
                      color="nk-black"
                    />
                  </span>
                  <span style={sitKnappStyle}>Utfør tiltak</span>
                </button>
              </div>
              <div className="col-sm-6 col-xs-12 col-lg-3">
                <button
                  title='Anmerkninger & andre forhold'
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px', whiteSpace: 'nowrap' }}
                  onClick={this.openAnmerkninger}>
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="anmerkninger"
                      color="nk-black"
                    />
                  </span>
                  <span className='knappEllipsis'>&nbsp;&nbsp;Anmerkninger & andre forhold</span>
                </button>
              </div>
              <div className="col-sm-6 col-xs-12 col-lg-3">
                <button
                  className="btn btn-default"
                  style={{ height: '40px', width: '100%', marginBottom: '4px' }}
                  onClick={this.openAvvik}>
                  <span style={{ float: 'left' }}>
                    <NKIcon
                      style={{ float: 'left' }}
                      icon="risiko"
                      color="nk-black"
                    />
                  </span>
                  <span style={sitKnappStyle}>Avvik</span>
                </button>
              </div>
            </div>
          </div>

          <p />

          <div>
            {_.map(
              this.state.ildstedListe,
              function(ildsted) {
                return (
                  <IldstedSituasjon
                    ildsted={ildsted}
                    emne="Ildsted"
                    adresse={this.props.adresse}
                    key={ildsted.id}
                    identi={this.props.identi}
                    oppdater={this.oppdaterSituasjon}
                    royklop={this.props.royklop}
                  />
                );
              },
              this
            )}
          </div>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default withRouter(RoyklopSituasjon);

// Declarative stuff

const modalDialogs = {
  NyttTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <NyttTiltak
          onClose={onClose}
          onNyttTiltak={owner.addNyttTiltak}
          id={dialogData.id}
          brId={dialogData.brId}
          type="royklop"
          arbeidslisteId={dialogData.arbeidslisteId}
        />
      </NKModal>
    );
  },
  EndreRoyklop: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <EndreRoyklop
          onClose={onClose}
          onEndreRoyklop={owner.endreRoyklop}
          royklopId={dialogData.royklopId}
        />
      </NKModal>
    );
  },
  EndreIldsted: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <EndreIldsted
          onClose={onClose}
          ildstedId={null}
          nyendre="ny"
          onLagreNyttIldsted={owner.addNyttIldsted}
        />
      </NKModal>
    );
  },
  RoyklopSituasjonTre: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <RoyklopSituasjonTre
          onClose={onClose}
          royklopId={dialogData.royklopId}
        />
      </NKModal>
    );
  },
  AnmerkningListe: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <AnmerkningListe
          onClose={onClose}
          tittel="Røykløp "
          emne="Røykløp"
          adresse={dialogData.adresse}
          identi={dialogData.identi}
          bruksenhetid={dialogData.bruksenhetid}
          objektReferanseType="R"
          objektReferanseId={dialogData.objektReferanseId}
          royklopid={dialogData.royklopid}
          info={dialogData.info}
        />
      </NKModal>
    );
  },
  AvvikListe: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <AvvikListe
          onClose={onClose}
          tittel="Røykløp "
          emne="Røykløp"
          adresse={dialogData.adresse}
          identi={dialogData.identi}
          bruksenhetid={dialogData.bruksenhetid}
          objektReferanseType="R"
          objektReferanseId={dialogData.objektReferanseId}
          royklopid={dialogData.royklopid}
          info={dialogData.info}
        />
      </NKModal>
    );
  },
  Objekthistorikk: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <Objekthistorikk onClose={onClose} type="royklop" id={dialogData.id} />
      </NKModal>
    );
  },
  NyOppdaterTiltaktype: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size="medium" isOpen={isOpen}>
        <NyOppdaterTiltaktype
          onClose={onClose}
          statusTiltaktype={dialogData.statusTiltaktype}
          onNyEndreTiltaktypeOK={owner.onNyEndreTiltaktypeOK}
          lagretTiltak={dialogData.lagretTiltak}
          tiltakType={dialogData.tiltakType}
          type="royklop"
          tiltakNavn={dialogData.tiltakNavn}
          hyppighetAntalldager={dialogData.hyppighetAntalldager}
          adresse={dialogData.adresse}
        />
      </NKModal>
    );
  },
  TiltakshistorikkModal: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <TiltakshistorikkModal
        isOpen={isOpen}
        onClose={onClose}
        type={'røyklop'}
        id={dialogData.id}
      />
    );
  },
  TiltakstyperModal: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <TiltakstyperModal
        isOpen={isOpen}
        onClose={onClose}
        type="royklop"
        id={dialogData.id}
        royklop={dialogData.royklop}
        brId={dialogData.brId}
      />
    );
  },
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={owner.closeSlettDialogOk}
      />
    );
  },
  OkDialogRisiko: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={owner.nyttTiltakOk}
      />
    );
  }
};
