import {
  ArrayState,
  FilterBasedAccessor,
  TermQuery,
  TermsBucket,
  CardinalityMetric,
  BoolShould,
  BoolMust,
  SelectedFilter,
  FilterBucket,
  FieldContextFactory,
  FieldContext,
  FieldOptions
} from 'searchkit';
import {
  assign,
  map,
  pick,
  omitBy,
  isUndefined,
  keyBy,
  each,
  forEach,
  filter,
  isEmpty,
  first
} from 'lodash';

import { DateRangeQuery } from 'components/Search/Datefilter/DateRangeQuery.jsx';
import { DateRangeAccessor } from 'components/Search/Datefilter/DateRangeAccessor';

export interface FacetAccessorOptions {
  operator?: string;
  title?: string;
  id?: string;
  size: number;
  facetsPerPage?: number;
  translations?: Object;
  include?: Array<string> | string;
  exclude?: Array<string> | string;
  orderKey?: string;
  orderDirection?: string;
  min_doc_count?: number;
  loadAggregations?: boolean;
  fieldOptions?: FieldOptions;
}

export interface ISizeOption {
  label: string;
  size: number;
}

export class NestedAccessor extends FilterBasedAccessor<ArrayState> {
  state = new ArrayState();
  options: any;
  defaultSize: number;
  size: number;
  uuid: string;
  loadAggregations: boolean;
  fieldContext: FieldContext;

  static translations: any = {
    'facets.view_more': 'View more',
    'facets.view_less': 'View less',
    'facets.view_all': 'View all'
  };
  translations = NestedAccessor.translations;

  constructor(key, options: FacetAccessorOptions) {
    super(key, options.id);
    this.options = options;
    this.defaultSize = options.size;
    this.options.facetsPerPage = this.options.facetsPerPage || 50;
    this.size = this.defaultSize;
    this.loadAggregations = isUndefined(this.options.loadAggregations)
      ? true
      : this.options.loadAggregations;
    if (options.translations) {
      this.translations = assign({}, this.translations, options.translations);
    }
    this.options.fieldOptions = this.options.fieldOptions || {
      type: 'embedded'
    };
    this.options.fieldOptions.field = this.key;
    this.fieldContext = FieldContextFactory(this.options.fieldOptions);
  }

  getRawBuckets() {
    return this.getAggregations(
      [this.uuid, this.fieldContext.getAggregationPath(), this.key, 'buckets'],
      []
    );
  }

  getBuckets() {
    let rawBuckets = this.getRawBuckets();
    let keyIndex = keyBy(rawBuckets, 'key');
    let missingFilters = [];
    each(this.state.getValue(), filter => {
      if (keyIndex[filter]) {
        keyIndex[filter].selected = true;
      } else {
        missingFilters.push({
          key: filter,
          missing: true,
          selected: true
        });
      }
    });
    let buckets =
      missingFilters.length > 0
        ? missingFilters.concat(rawBuckets)
        : rawBuckets;

    return buckets;
  }

  getDocCount() {
    return this.getAggregations(
      [this.uuid, this.fieldContext.getAggregationPath(), 'doc_count'],
      0
    );
  }

  getCount(): number {
    return this.getAggregations(
      [
        this.uuid,
        this.fieldContext.getAggregationPath(),
        this.key + '_count',
        'value'
      ],
      0
    );
  }

  setViewMoreOption(option: ISizeOption) {
    this.size = option.size;
  }

  getMoreSizeOption(): ISizeOption {
    var option = { size: 0, label: '' };
    var total = this.getCount();
    var facetsPerPage = this.options.facetsPerPage;
    if (total <= this.defaultSize) {return null;}

    if (total <= this.size) {
      option = {
        size: this.defaultSize,
        label: this.translate('facets.view_less')
      };
    } else if (this.size + facetsPerPage >= total) {
      option = { size: total, label: this.translate('facets.view_all') };
    } else if (this.size + facetsPerPage < total) {
      option = {
        size: this.size + facetsPerPage,
        label: this.translate('facets.view_more')
      };
    } else if (total) {
      option = null;
    }

    return option;
  }

  isOrOperator() {
    return this.options.operator === 'OR';
  }

  getBoolBuilder() {
    return this.isOrOperator() ? BoolShould : BoolMust;
  }

  getOrder() {
    if (this.options.orderKey) {
      let orderDirection = this.options.orderDirection || 'asc';
      return { [this.options.orderKey]: orderDirection };
    }
  }

  buildSharedQuery(query) {
    // Denne accessoren bryter litt med de andre. Normalt bygger en accessor opp query
    // for en komponent. Men spørringen som blir generert for lister med tiltakstyper for bruksenheter
    // og røykløp blir feil. Denne accessoren setter derfor sammen spørring for alle komponentene som
    // har med tiltakstyper å gjøre. 
    // En utebedring som kan gjøres: Det blir nå satt sammen en bool must med alle kriteriene gjennom denne
    // accessoren, men i tillegg vil hver enkelt accessor også sette de samme kriteriene. Mulig det ikke har noe å bety
    // hverken for resultat eller ytelse.
    var filters = this.state.getValue();

    var filterTerms = map(filters, filter => {
      return TermQuery(this.key, filter);
    });

    // Må også finne terms fra andre accessors, this.searchkit.get
    // Kan vi finne accessors ut fra options.path?...
    let otherAccessors = filter(this.searchkit.accessors.accessors, item => {
      return item.options &&
        item.options.fieldOptions &&
        item.options.fieldOptions.options
        ? item.options.fieldOptions.options.path ===
            this.options.fieldOptions.options.path &&
            (item.urlKey !== 'btiltakstype' && item.urlKey !== 'rtiltakstype')
        : false;
    });
    if (otherAccessors) {
      //foreach
      forEach(otherAccessors, otherAccessor => {
        if (isEmpty(otherAccessor.state.getValue() )){ return; }

        if (otherAccessor instanceof DateRangeAccessor){
          console.log('DATE');
          console.log(JSON.stringify(otherAccessor.state.value.toDate));
        }

        let newQuery =
          otherAccessor instanceof DateRangeAccessor
            ? DateRangeQuery(otherAccessor.options.field, {
              
                lte: otherAccessor.state.value.toDate ? Date.parse(otherAccessor.state.value.toDate) : null,
                gte: otherAccessor.state.value.fromDate ? Date.parse(otherAccessor.state.value.fromDate) : null
              })

            : TermQuery(otherAccessor.options.field, first(otherAccessor.state.getValue()));

        filterTerms.push(newQuery);
      });
    }

    var selectedFilters: Array<SelectedFilter> = map(filters, filter => {
      return {
        name: this.options.title || this.translate(this.key),
        value: this.translate(filter),
        id: this.options.id,
        remove: () => (this.state = this.state.remove(filter))
      };
    });

    if (filterTerms.length > 0) {
      query = query
        .addFilter(
          this.key,
          BoolMust(
            this.NestedQuery(
              this.options.fieldOptions.options.path,
              BoolMust(filterTerms)
            )
          )
        )
        .addSelectedFilters(selectedFilters);
    }
    return query;
  }

  wrapFilter(filter) {
    return this.NestedQuery(
      this.fieldContext.fieldOptions.options.path,
      filter,
      this.options
    );
  }

  // Må kanskje endre denne til å ta inn en liste med terms?
  // Utfordring at det skal knyttes sammen terms fra forskjellige filtere
  NestedQuery(path, query, options = {}) {
    const allowedOptions = ['score_mode', 'inner_hits'];    
    let changedQuery = {
      nested: assign(
        {
          path,
          query
        },
        pick(options, allowedOptions)
      )
    };
    return changedQuery;
  }

  buildOwnQuery(query) {
    if (!this.loadAggregations) {
      return query;
    } else {
      let excludedKey = this.isOrOperator() ? this.uuid : undefined;
      return query.setAggs(
        FilterBucket(
          this.uuid,
          query.getFiltersWithoutKeys(excludedKey),
          ...this.fieldContext.wrapAggregations(
            TermsBucket(
              this.key,
              this.key,
              omitBy(
                {
                  size: this.size,
                  order: this.getOrder(),
                  include: this.options.include,
                  exclude: this.options.exclude,
                  min_doc_count: this.options.min_doc_count
                },
                isUndefined
              )
            ),
            CardinalityMetric(this.key + '_count', this.key)
          )
        )
      );
    }
  }
}
