import React from 'react';
import 'react-toggle-switch/dist/css/switch.min.css';
import '.././grunndata.css';
import oppslagsdataApi from 'api/oppslagsdataApi.js';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';

var _ = require('underscore');

var id = 0;
var objektTyper = [
  { id: 1, tekst: 'Bruksenhet' },
  { id: 2, tekst: 'Røykløp' }
];
let objekt = '';
const debug = debugFactory('nk:NyEndreOppslagsdataTiltakType');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel={dialogData.tittel}
        melding={dialogData.melding}
        onOk={owner.closeDialogOk}
      />
    );
  }
};

class NyEndreOppslagsdataTiltakType extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.handleTekstChange = this.handleTekstChange.bind(this);
    this.lukketChange = this.lukketChange.bind(this);
    this.standardChange = this.standardChange.bind(this);
    this.dataLagret = this.dataLagret.bind(this);
    this.objektChange = this.objektChange.bind(this);
    this.state = {
      lukket: true,
      standard: false,
      tekst: '',
      arkiv: true
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }

  handleTekstChange(data) {
    this.setState({ tekst: data.target.value });
  }
  componentDidMount() {
    this.tekstInput.focus();
    if (this.props.nyEndre === 'Endre') {
      objekt = this.props.valgtItem.tiltakObjekt;
      id = this.props.valgtItem.id;
      this.setState({
        tekst: this.props.valgtItem.tekst,
        lukket: this.props.valgtItem.aktiv,
        objektType: this.props.valgtItem.tiltakObjektId,
        standard: this.props.valgtItem.standard,
        arkiv: this.props.valgtItem.arkiv,
      });      
    } else {
      this.setState({ objektType: objektTyper[0].id });
    }
  }

  objektChange(data) {
    this.setState({ objektType: data.target.value });
    console.log(data.target.value);
  }
  submit() {    
    var type = {
      id: id,
      tekst: this.state.tekst,
      aktiv: this.state.lukket,
      tiltakObjektId: this.state.objektType,
      standard: this.state.standard,      
      arkiv: this.state.arkiv
    };
    if (this.props.nyEndre === 'Ny') {
      oppslagsdataApi.saveTiltakType(type, this.dataLagret);
    } else {
      oppslagsdataApi.updateTiltakType(id, type, this.dataLagret);
    }
  }

  dataLagret(err, nyEndreitem) {
    this.props.dataLagret(nyEndreitem);
  }
  lukketChange() {
    let lukket = !this.state.lukket;
    this.setState({ lukket: lukket });
    this.setState({ utfort: lukket });
  }
  standardChange() {
    let standard = !this.state.standard;
    this.setState({ standard: standard });
  }  
  arkivChange= () => {
    let arkiv = !this.state.arkiv;
    this.setState({ arkiv: arkiv });
  };
  closeDialogOk = () => {
    this.dialogs.closeDialog();
  };
  render() {
    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            {this.props.nyEndre} oppslagsdata for: {this.props.type} -{' '}
            {this.props.tabell}
          </h4>
        </div>
        <div className='modal-body'>
          <div className='form margin-left-15 margin-right-15'>
            <div className='row'>
              <p />
              <div className='col-sm-12'>
                <div className='col-sm-6'>
                  <div className='form-group '>
                    <input
                      className='checkbox30'
                      type='checkbox'
                      onClick={this.lukketChange}
                      checked={this.state.lukket}
                    />
                    <span className='checkboxtext18'>Aktiv</span>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <input
                      className='checkbox30'
                      type='checkbox'
                      onClick={this.standardChange}
                      checked={this.state.standard}
                    />
                    <span className='checkboxtext18'>Standard</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-12'>
                <div className='form-group'>
                  <label>Objekt</label>
                  <select
                    className='form-control'
                    id='type'
                    onChange={this.objektChange}
                    value={this.state.objektType}>
                    {_.map(objektTyper, function(o, i) {
                      return (
                        <option key={i} value={o.id}>
                          {o.tekst}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className='form-group'>
                  <label>Tekst</label>
                  <textarea
                    ref={input => {
                      this.tekstInput = input;
                    }}
                    className='form-control textareaNoresize'
                    type='textarea'
                    id='message'
                    placeholder='Tekst'
                    rows='2'
                    onChange={this.handleTekstChange}
                    value={this.state.tekst}
                    maxLength='100'
                  />
                </div>

                <div className='pull-right'>
                  <input
                    type='button'
                    className='btn btn-primary height48'
                    value='OK'
                    onClick={this.submit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default NyEndreOppslagsdataTiltakType;
