import React, { Component } from 'react';
import omradeSoneApi from 'api/omradeSonerApi';
import _ from 'underscore';
import './KartEnheter.css';

class VelgSone extends Component {
  constructor(props) {
    super(props);
    this.state = { soner: [], omrader: [] };
    this.velgSone = this.velgSone.bind(this);
  }

  componentDidMount() {
    omradeSoneApi.getAlleSoner(this.gotAlleSoner.bind(this));
  }

  gotAlleSoner(err, data) {
    this.setState({ soner: data });
    this.makeOmrader(data);
  }

  makeOmrader(soner) {
    let omradeList = _.groupBy(soner, 'omradeNavn');
    let omrader = Object.keys(omradeList);
    this.setState({ omrader: omrader.sort() });
  }

  stringOmrade(omrade) {
    let s = omrade.replace(/[\])}[{(]/g, '');
    let z = s.replace(/\s+/g, '');
    return z;
  }

  velgSone(sone) {
    this.props.soneValgt(sone);
  }

  render() {
    return (
      <div ref="SonePanel" style={{ backgroundColor: 'white' }}>
        <label
          style={{ marginTop: '10px', marginLeft: '14px', fontSize: '14px' }}>
          Velg Sone
        </label>
        <button
          title="Lukk"
          style={{ height: '33px', width: '33px', float: 'right' }}
          onClick={this.props.closeMeny}>
          X
        </button>
        <br />

        <div className="regionerScroll w290">
          <div className="panel-group w160" id="accordion">
            {this.state.omrader.map(function(omrade) {
              var g = this.stringOmrade(omrade);
              var bb = 'key' + g;
              var html;
              html = (
                <div key={bb} className="panel panel-default w260">
                  <div className="panel-heading">
                    <h4 className="panel-title">
                      <a
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href={'#' + g}>
                        {omrade}
                      </a>
                    </h4>
                  </div>

                  <div id={g} className="panel-collapse collapse w300">
                    {this.state.soner.map(function(sone) {
                      if (sone.omradeNavn === omrade) {
                        var bId = 'btn' + sone.id;
                        var dId = 'div' + sone.id;
                        var html;
                        html = (
                          <div key={dId}>
                            <button
                              key={bId}
                              type="button"
                              onClick={this.velgSone.bind(this, sone)}
                              className="btn btn-default w260">
                              {sone.navn}
                            </button>
                            <br />
                          </div>
                        );
                        return html;
                      }
                    }, this)}
                  </div>
                </div>
              );
              return html;
            }, this)}
          </div>
        </div>
      </div>
    );
  }
}

export default VelgSone;
