import React from 'react';
import { getRoyklopForFlyttRoyklop } from 'api/royklopApi';
import { saveIldsted } from 'api/ildstedApi';
import ReactTable from '@norkart/react-table';
import NKModal from 'components/Dialogs/NKModal.jsx';
import EndreIldsted from '../Ildsted/EndreIldsted.jsx';
import DialogStore from 'lib/DialogStore';

let columns = [
  {
    id: 'adresse',
    name: 'Adresse',
    sortParams: 'adresse',
    filterable: false,
    isSorted: false,
    sortable: true
  },
  {
    id: 'eiendom',
    name: 'Eiendom',
    sortParams: 'eiendom',
    isSorted: false
  },
  {
    id: 'plassering',
    name: 'Plassering',
    sortParams: 'plassering',
    isSorted: false
  },
  {
    id: 'type',
    name: 'Type',
    sortParams: 'type',
    isSorted: false
  }
];

const modalDialogs = {
  EndreIldsted: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <EndreIldsted
        isOpen={isOpen}
        onClose={onClose}
        ildstedId={null}
        nyendre="ny"
      />
    );
  }
};

class TilknyttRoyklopDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      royklop: null,
      opprettIldsted: false,
      royklopId: null,
      step1Hide: '',
      step2Hide: 'hide',
      tittel: 'Velg et røykløp du ønsker å tilknytte',
      selectedRadio: 'opprett'
    };
    this.tilknyttRoyklop = this.tilknyttRoyklop.bind(this);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }
  componentDidMount() {
    getRoyklopForFlyttRoyklop(this.props.bruksenhetId, (err, res) => {
      if (res.length === 0) {
        this.ingenRoyklop();
      } else {
        this.setState({ royklop: res });
      }
    });
  }

  ingenRoyklop = () => {
    this.props.ingen();
  };

  tilknyttRoyklop(row) {
    this.setState({
      step1Hide: 'hide',
      step2Hide: '',
      selectedRoyklopId: row.royklopId,
      tittel: 'Opprett ildsted?'
    });
  }

  handleRadioChange = event => {
    this.setState({ selectedRadio: event.currentTarget.value });
  };

  neste = () => {
    this.dialogs.closeDialog();
    this.props.onIldsted(this.state.selectedRoyklopId);
  };

  okUtenIldsted = () => {
    let nyttIldsted = {
      BruksenhetId: this.props.bruksenhetId,
      RoyklopId: this.state.selectedRoyklopId,
      IngenIldsted: true
    };
    saveIldsted(nyttIldsted, (err, res) => this.props.tilkyttetUtenRoyk());
  };

  forrige = () => {
    this.setState({
      step1Hide: '',
      step2Hide: 'hide',
      tittel: 'Velg et røykløp du ønsker å tilknytte'
    });
  };

  render() {
    if (!this.state.royklop) {
      return <p>Laster...</p>;
    } else {
      return (
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <button
                className="close"
                type="button"
                onClick={this.props.onClose}>
                <span>x</span>
                <span className="sr-only">Lukk</span>
              </button>
              <h4 className="modal-title">{this.state.tittel}</h4>
            </div>
            <div className="modal-body" style={{ padding: '0 20px' }}>
              {/* STEP 1 */}
              <div className={this.state.step1Hide}>
                <br />

                <ReactTable
                  items={this.state.royklop}
                  idProperty="royklopId"
                  showIndex={false}
                  filterable={false}
                  columns={columns}
                  onRowClick={this.tilknyttRoyklop}
                  toggleAll={false}
                />

                {this.dialogs.renderOpenDialog()}
              </div>
              {/* STEP 2 */}
              <div className={this.state.step2Hide}>
                <br />
                <div className="form-group">
                  <label>
                    <input
                      style={{ marginRight: '10px' }}
                      type="radio"
                      name="opprett"
                      value="opprett"
                      checked={this.state.selectedRadio === 'opprett'}
                      onChange={this.handleRadioChange}
                    />
                    Opprett ildsted
                  </label>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      style={{ marginRight: '10px' }}
                      type="radio"
                      name="ikkeopprett"
                      value="ikkeopprett"
                      checked={this.state.selectedRadio === 'ikkeopprett'}
                      onChange={this.handleRadioChange}
                    />
                    Ikke opprett ildsted
                  </label>
                </div>
              </div>
              
            </div>
            <div className="modal-footer">
              {!this.state.step2Hide && (
                <div>
                  {this.state.selectedRadio == 'opprett' ? (
                    <div>
                      <button
                        onClick={this.neste}
                        className="btn btn-primary pull-right btnBottomSend">
                        <div>Neste</div>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={this.okUtenIldsted}
                        className="btn btn-primary pull-right btnBottomSend">
                        <div>OK</div>
                      </button>
                    </div>
                  )}
                  <button
                    onClick={this.forrige}
                    className="btn btn-default pull-right btnBottom">
                    Forrige
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
export default TilknyttRoyklopDialog;
