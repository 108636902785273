import React from 'react';
import Innstillinger from './Innstillinger.jsx';

export default class extends React.Component {
  state = { breadcrumbname: 'Laster....' };

  render() {
    return (
      <div>
        <Innstillinger />
      </div>
    );
  }
}
