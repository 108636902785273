import React from 'react';
import SvarListe from './svarListe';
import { sendSporsmalTilAi } from 'api/aiApi';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import Test1 from 'modules/Admin/Test/Test1';

/* [12:17 PM] Knut Engemoen
    public class AiDto
    {
        //public DateTime Date { get; set; }
        [Required(ErrorMessage = "Spørsmål må ha en verdi")]
        public string Sporsmal { get; set; }
        public List<AiHistorikkDto> Historikk { get; set; }
    }
[12:17 PM] Knut Engemoen
    public class AiHistorikkDto
    {
        public string Sporsmal { get; set; }
        public string Svar { get; set; }
    } */

const modalDialogs = {
  IngenSporsmalDialog: ({ isOpen, onClose }) => (
    <OKDialog
      tittel={'Spørsmål mangler'}
      melding={'Du må definere et spørsmål først.'}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  )
};



const listStyle = {
 height: 'calc(100vh',
 width: '800px',
 padding: '10px',
 overflowY: 'auto',
 marginLeft: '14px'
};

class AiTest1 extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = { venter: false, sporsmal: '', historikk: [] };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  };

  promptChange = (val) => {
    this.setState({ sporsmal: val.target.value });
  };

  slettSporsmal = () => {
    let s = '';
    this.setState({ sporsmal: s });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.sendSporsmal();
    }
  };

  sendSporsmal = () => {
    let sp = this.state.sporsmal;
    if (sp === ''){
      this.dialogs.setOpenDialog('IngenSporsmalDialog');
      return;
    }
    let his = this.state.historikk;
    let dto = { Sporsmal: sp, Historikk: his };
    this.setState({ venter: true });
    sendSporsmalTilAi(dto, this.gotSvarFraAi);
  };

  avbrytSporsmal = () => {
    this.setState({ venter: false });
  };

  gotSvarFraAi = ( err, svarData ) => {
    this.setState({ venter: false });
    if (err){
      console.log('%c AI FF: ERROR ', 'font-size: 14px; background: red; color: white',  err );
    }
    console.log('%c AI BF SVARDATA ', 'font-size: 14px; background: green; color: white',  svarData );  


    let obj = {
      Sporsmal: this.state.sporsmal,
      Svar: svarData.tekst
    };

    let historikk = this.state.historikk;
    historikk.unshift(obj);
    this.setState(historikk);
  };



  render() {
    return (
      <div>
        <h2>Børste-Børge svarer..</h2>
        {/* Dette for å dytte user og norkart til bottom */}

        <br/>
          <div className="row">
          
            <div className="col-sm-12">
              <label>Nytt spørsmål</label>
              <textarea
                style={{ width: '800px', maxWidth: '1000px', fontSize: '16px' }}
                className="form-control"
                type="textarea"
                id="prompt"
                placeholder="Hva ønsker du å spørre om..?"
                maxLength="2000"
                rows="5"
                onChange={this.promptChange}
                onKeyDown={this.handleKeyDown}
              />
              <br/>
              {!this.state.venter ? (
                <>
              <button
                className='btn btn-default'
                style={{
                  background: '#51A026',
                  color: 'white',
                  height: '32px',
                }}
                onClick={this.sendSporsmal}
                type='button'>
                Send spørsmål
              </button>
             {/*  <button
                className='btn btn-default'
                style={{
                  background: 'Gray',
                  color: 'white',
                  height: '32px',
                  marginLeft: '8px'
                }}
                onClick={() => this.slettSporsmal}
                type='button'>
                Slett
              </button> */}
              <br/>
              </>
              ) : (
                <div style={{ width: '800px' }}>
                  <marquee scrollamount='20'><span style={{ color: 'green', fontSize: '20px' }}><b>Vent litt, Børste-Børge prøver å svare så fort han kan.. </b></span></marquee>
                  <br/>
                  <button
                className='btn btn-default'
                style={{
                  background: 'black',
                  color: 'white',
                  height: '32px',
                  marginLeft: '8px'
                }}
                
                onClick={() => this.avbrytSporsmal}
                type='button'>
                Avbryt
              </button>
                </div>
                
              )
              }
              <br/>
            </div>
          </div>
         
          <br/>
        
          <div style={listStyle}>
            <SvarListe data={this.state.historikk}></SvarListe>

          </div>
          <div>
            <Test1></Test1>
          </div>
        <br/>
          {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default AiTest1;
