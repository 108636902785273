/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter } from 'lib/enhancers';
import { getInnbyggerdialogByBruksenhetId } from 'api/innbyggerDialogApi';
import '.././gjennomforing.css';
import moment from 'moment';
import ReactModal from 'react-modal';
import NKIcon from 'nka-icons';
import VisDokumentRO from 'components/Editor/VisDokumentRO.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import './dialog.css';
import DialogStore from 'lib/DialogStore';
import DialogHistorikkAvvik from 'modules/felles/avvikAnmerkninger/DialogHistorikkAvvik.jsx';

const modalDialogs = {
	TekstDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<ReactModal isOpen={isOpen} className='malModalSvarut'>
			<VisDokumentRO onClose={onClose} model={dialogData.model} />
		</ReactModal>
	),
	AvvikDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<NKModal size='large' isOpen={isOpen} onClose={onClose}>
			<div className='modal-content'>
				<div className='modal-header modal-header-info'>
					<button className='close' onClick={onClose} type='button'>
						<span>x</span>
						<span className='sr-only'>Lukk</span>
					</button>
					<h4 className='modal-title'> Dialog om avvik </h4>
				</div>
				<DialogHistorikkAvvik avvikId={dialogData.avvikId} />
				<div style={{ height: '40px' }}></div>
			</div>
		</NKModal>
	),
};

class DialogListe extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hideTekst: 'hide',
			hideTable: '',
			liste: [],
			tekstIsOpen: false,
			currentTekst: '',
		};
		this.onGotDialogData = this.onGotDialogData.bind(this);
		this.formatAvsender = this.formatAvsender.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.formatEier = this.formatEier.bind(this);
		this.formatSak = this.formatSak.bind(this);
		this.formatHtml = this.formatHtml.bind(this);
		this.lesMerClick = this.lesMerClick.bind(this);
		this.lesMerClose = this.lesMerClose.bind(this);

		this.dialogs = new DialogStore({
			owner: this,
			dialogs: modalDialogs,
		});
	}

	componentDidMount() {
		getInnbyggerdialogByBruksenhetId(this.props.id, this.onGotDialogData);
	}

	onGotDialogData(err, liste) {
		liste = liste.sort(function(a, b) {
			return new Date(b.datoPublisert) - new Date(a.datoPublisert);
		});
		this.setState({ liste: liste });
		if (liste === undefined || liste.length === 0) {
			this.setState({ hideTekst: '', hideTable: 'hide' });
		}
	}

	formatDate(date) {
		return moment(date).format('DD.MM.YYYY');
	}

	formatAvsender(avsender) {
		let result = 'Fra innbygger';
		if (avsender.toLowerCase() === 'bf') {
			result = 'Fra brannvesenet';
		}
		return result;
	}

	formatEier(eier) {
		let result = 'ukjent';
		if (eier !== null) {
			result = eier;
		}
		return result;
	}

	formatSak(aar, nr) {
		let result = 'Ikke registrert';
		if (aar !== null) {
			result = aar;
		}
		if (nr !== null) {
			result = result + '/' + nr;
		}
		return result;
	}

	formatHtml(tekst) {
		return { __html: tekst };
	}

	lesMerClick(tekst, e) {
		this.setState({ currentTekst: tekst });
		this.dialogs.setOpenDialog('TekstDialog', { model: tekst });
		return false;
	}

	avvikDetaljerClick = (avvikId, e) => {
		this.dialogs.setOpenDialog('AvvikDialog', { avvikId: avvikId });
		return false;
	};

	lesMerClose() {
		this.setState({ tekstIsOpen: false });
	}

	render() {
		return (
			<div>
				<div className={this.state.hideTekst}>
					Det finnes ingen data for innbyggerdialog.
				</div>

				<div className={this.state.hideTable}>
					<div>
						{this.state.liste.map(function(dialog) {
							var dId = 'div' + dialog.dialogId;
							var bId = 'b' + dialog.dialogId;
							var panelId = 'pan' + dialog.dialogId;
							var colId = 'collapse' + dialog.dialogId;
							var colIdx = '#collapse' + dialog.dialogId;
							var dialogtekst = dialog.tekst2;
							var linktekst = dialog.tekst2LinkTekst;
							var hyperlinktekst = '';
							let ikon = 'hjem';

							if (dialog.avsender === 'BF') {
								ikon = 'kommune';
							}

							if (linktekst !== '') {
								hyperlinktekst = (
									// eslint-disable-next-line jsx-a11y/anchor-is-valid
									<a
										className='pointer'
										onClick={this.lesMerClick.bind(null, dialogtekst)}
									>
										{linktekst}{' '}
									</a>
								);
							}

							return (
								<div key={dId} style={{ backgroundColor: 'white' }}>
									<button
										key={bId}
										className='btn btn-default arrow-toggle panel-enhet-dialogx collapsed'
										data-toggle='collapse'
										data-target={colIdx}
									>
										{/* onClick={this.dialogBtnClick.bind(null, dialog.dialogId)} */}
										<table className='table100'>
											<tbody>
												<tr>
													<td className='tdSpace20'>
														<NKIcon
															className='pull-left'
															icon={ikon}
															size='1'
															color='nk-black'
														/>
													</td>

													<td className='tdSpace60 pull-left'>
														{this.formatDate(dialog.datoPublisert)}
													</td>

													<td className='tdSpace99 pull-left'>
														{dialog.beskrivelse}
													</td>

													<td>
														<NKIcon
															className='pull-right icon-arrow-up'
															icon='ned'
															size='1'
															color='nk-black'
														/>
														<NKIcon
															className='pull-right icon-arrow-down'
															icon='opp'
															size='1'
															color='nk-black'
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</button>
									<br />
									<div
										key={panelId}
										id={colId}
										className='collapse margin-left-10 margin-right-10'
									>
										{/* <label className="margin-left-5">Tekst</label> */}

										<label>{dialog.beskrivelse}</label>
										<br/>
										<span style={{ marginLeft: '4px' }} className='textIB'>
											<span style={{ color: '	#4169E1', fontWeight: 'bold' }}>
											  Melding som vises på MinEiendom
											</span>
											<span className='text-tooltipIB'>
												<p
													dangerouslySetInnerHTML={this.formatHtml(
														dialog.tekst
													)}
												/>
											</span>
											<br/><br/>
										</span>
										{dialog.avvikId && (
											<>
												<span style={{ marginLeft: '5px' }}>
													<a
														className='pointer'
														onClick={this.avvikDetaljerClick.bind(
															null,
															dialog.avvikId
														)}
													>
														Se dialogen om avvik.
													</a>
												</span>
												<br />
											</>
										)}

										<div className='margin-left-5'>{hyperlinktekst}</div>
										<div>
											{dialog.loggInfo != null &&
											dialog.loggInfo.includes('Innbygger har svart på sms') ? (
												<>
													<br />
													<div>{dialog.loggInfo}</div>
												</>
											) : null}
											{dialog.loggInfo != null &&
											dialog.loggInfo.includes('SMS utsending feilet') ? (
												<div>
													<span style={{ color: 'red' }}>
														{dialog.loggInfo}
													</span>
												</div>
											) : null}
											<br />
											
											<label>Eierkontakt</label>
											<br />
											{this.formatEier(
												dialog.smsTelefon != null
													? dialog.smsTelefon
													: dialog.eierkontakt
											)}
											
											{dialog.sms && (
												<>
													<br /><br/>
													<span>
														<span style={{ marginRight: '8px' }}>
															<label>SMS</label>
														</span>
														<span>Innhold i utsendt SMS:</span>
														{/* {dialog.smsTekst ? (
															<span>Innhold i utsendt SMS (egen mal):</span>
														) : (
															<span>Innhold i utsendt SMS (standard):</span>
														)} */}
													</span>
													<div
														className='speech-bubble'
														style={{ padding: '20px', fontSize: '12px' }}
													>
														<div className='triangle'></div>
														{dialog.smsTekst ? (
															<div style={{ whiteSpace: 'pre-line' }}>
																{dialog.smsTekst}
															</div>
														) : (
															<div style={{ whiteSpace: 'pre-line' }}>
																<p
																	dangerouslySetInnerHTML={this.formatHtml(
																		dialog.tekst
																	)}
																></p>
															</div>
														)}
													</div>
													<br />
												</>
											)}
											
											<br /> <br />
											<label>Saksnummer</label>
											<br />
											{this.formatSak(dialog.saksAr, dialog.saksSekvensnr)}
											<br />
											<br />
											<br />
										</div>
									</div>
								</div>
							);
						}, this)}
					</div>
				</div>
				{this.dialogs.renderOpenDialog()}
			</div>
		);
	}
}

class DialogTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = { id: 0 };
	}

	render() {
		let pType = this.props.type;
		if (pType === 'royklop') {
			pType = 'røykløp';
		}
		return (
			<div>
				<h2>
					Innbyggerdialog for {pType} {this.props.adresse}
				</h2>
				<hr />
				<DialogListe id={this.props.id} />
			</div>
		);
	}
}

export default withRouter(DialogTable);
