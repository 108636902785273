import {
    dummyFactory,
} from 'utils';

const Error = dummyFactory('Error', 'Noe gikk galt! - Vi aner ikke hva. Sorry'); // <= Det sier seg selv at denne ikke skal vises i prod!
const _404 = dummyFactory('404', 'Side ikke funnet!');;
const AccessDenied = dummyFactory('Access denied', 'Ingen tilgang!');

export default Error;
export {
    _404,
    Error,
    AccessDenied,
};