import React, { useState, useEffect, createContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import IconButton from '@mui/material/IconButton';
import NKIcon from 'nka-icons';
import './dialogs.css';

const ListeDialog = ({
  tittel,
  isOpen,
  handleClose,
  liste,
  felt,  //kolonne i lista som vises på button
  width,
}) => {
  return (
    <Dialog
      open={isOpen}
     /*  sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          m: 10,
          top: 100,
          left: 200,
        },
      }} */
      onClose={handleClose}>
      <DialogTitle style={{ paddingBottom: '4px'  }}>
        <span style={{ fontSize: '16px', paddingTop: '24px' }}>{tittel}</span>
        <span className='closeButton'>
          <IconButton aria-label='close' onClick={() => handleClose(null)}>
            <NKIcon icon='lukk' size='1' color='nk-black' />
          </IconButton>
        </span>
      </DialogTitle>
      <DialogContent >
        {liste.map((row, idx) => (
          <React.Fragment key= {'RF' + idx}>
            <span key={idx}>
              <button className='btn btn-default'
                onClick={() => handleClose(row)}
                style={{ marginBottom: '2px', width: width, textAlign: 'left' }}>
                <span>{row[felt]}</span>
              </button>
            </span>
            <br />
          </React.Fragment>
        ))}
      </DialogContent>

    </Dialog>
  );
};

export default ListeDialog;
