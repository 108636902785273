import React, { Component } from 'react';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import NKIcon from 'nka-icons';
/* import {
  getSoekEnheterVisRisiko,
  getPlanleggingVisRisiko,
  setSoekEnheterVisRisiko,
  setPlanleggingVisRisiko,
} from 'context/contextKart.js'; */

/* 1. Waar vandaan?  


*/

class RisikoControl extends Component {
  state = {
    hideRisikoPanel: 'hide',
    tilsynClassName: 'btn btn-default',
    feiingClassName: 'btn btn-default',
    tilsynIconFarge: 'nk-black',
    feiingIconFarge: 'nk-black',
    modus: '',
  };

  componentDidMount = () => {
  };

  tilpassKnappene = (er, pr) => {
    //Hvor åpnes kart fra
    //Soekenheter
    /*   if (this.props.kartfra === 'Soekenheter') {
      let eR = getSoekEnheterVisRisiko();
      if (eR) {
        if (eR._currentValue) {
          if (eR._currentValue.mode === 'Tilsyn') {
            this.setState({ tilsynClassName: 'btn btn-success' });
          } else {
            if (eR._currentValue.mode === 'Feiing') {
              this.setState({ feiingClassName: 'btn btn-success' });
            }
          }
        }
      }
    } else {
      //Planlegging
      let pR = getPlanleggingVisRisiko();
      if (pR) {
        if (pR._currentValue) {
          if (pR._currentValue.mode === 'Tilsyn') {
            this.setState({ tilsynClassName: 'btn btn-success' });
          } else {
            if (pR._currentValue.mode === 'Feiing') {
              this.setState({ feiingClassName: 'btn btn-success' });
            }
          }
        }
      }
    } */
  };

  handleRisikoPanelHide = () => {
    this.props.hidePanel();
  };

  skjulPanel = () => {
    this.props.hidePanel();
  };

  visRisikoTilsyn = () => {
    this.setState({ feiingClassName: 'btn btn-default' });
    if (this.state.tilsynClassName === 'btn btn-default') {
      this.setState({ tilsynClassName: 'btn btn-success' });
      this.setState({ tilsynIconFarge: 'nk-white' });
      this.props.visRisiko('Tilsyn');
      /*  if (this.props.kartfra === 'Soekenheter') {
        setSoekEnheterVisRisiko('Tilsyn');
      } else {
        setPlanleggingVisRisiko('Tilsyn');
      } */
    } else {
      this.setState({ tilsynClassName: 'btn btn-default' });
      this.setState({ tilsynIconFarge: 'nk-black' });
      this.props.visRisiko('Ingen');
      /*   if (this.props.kartfra === 'Soekenheter') {
        setSoekEnheterVisRisiko('Ingen');
      } else {
        setPlanleggingVisRisiko('Ingen');
      } */
    }
  };

  visRisikoFeiing = () => {
    this.setState({ tilsynClassName: 'btn btn-default' });
    if (this.state.feiingClassName === 'btn btn-default') {
      this.setState({ feiingClassName: 'btn btn-success' });
      this.setState({ feiingIconFarge: 'nk-white' });
      this.props.visRisiko('Feiing');
      /*  if (this.props.kartfra === 'Soekenheter') {
        setSoekEnheterVisRisiko('Feiing');
      } else {
        setPlanleggingVisRisiko('Feiing');
      } */
    } else {
      this.setState({ feiingClassName: 'btn btn-default' });
      this.setState({ feiingIconFarge: 'nk-black' });
      this.props.visRisiko('Ingen');
      /*   if (this.props.kartfra === 'Soekenheter') {
        setSoekEnheterVisRisiko('Ingen');
      } else {
        setPlanleggingVisRisiko('Ingen');
      } */
    }
  };

  render() {
    let risikoPanel = (
      <div
        style={{
          minWidth: '200px',
          height: '140px',
          backgroundColor: 'white',
          marginLeft: '2px',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}>
        <div>
          <span
            style={{
              display: 'inline-block',
              marginTop: '10px',
              marginLeft: '14px',
              height: '24px',
              fontSize: '16px',
              fontWeight: 'bold'
            }}>
            Velg..{' '}
          </span>

          <button
            title='Lukk'
            style={{
              display: 'inline-block',
              height: '33px',
              width: '33px',
              float: 'right',
            }}
            onClick={this.handleRisikoPanelHide}>
            X
          </button>
        </div>
        
        
        <button
          onClick={this.visRisikoTilsyn}
          style={{ paddingLeft: 0,  width: '180px', marginLeft: '12px', marginTop: '10px' }}
          className={this.state.tilsynClassName}>
          <NKIcon icon='hjem' size='0.8' color='nk-black' />
          <span style={{ marginLeft: '8px' }}>Risiko Tilsyn</span>
        </button>
        <br />
        <button
          onClick={this.visRisikoFeiing}
          style={{ paddingLeft: 0, width: '180px', marginLeft: '12px', marginTop: '6px' }}
          className={this.state.feiingClassName}>
          <NKIcon icon='royklop' size='0.8' color='nk-black' />
          <span style={{ marginLeft: '8px' }}>Risiko Feiing</span>
        </button>
        {/*    <br />
        <label>{this.props.modus}</label>
        <br />
        <label>{'VisE: ' + getEnheterVisRisiko()._currentValue.mode}</label><br/>
        <label>{'VisPl: ' + getPlanleggingVisRisiko()._currentValue.mode}</label> */}
      </div>
    );

    return (
      <React.Fragment>
        <div>{risikoPanel}</div>
      </React.Fragment>
    );
  }
}

export default RisikoControl;
