var React = require('react');

/* *****************************************************
Eksempel:

this.state = {
    fra: '09:25',
    til: '10:45',
  };

onTidChange = (fra, til) => {
    this.setState({ fra });
    this.setState({ til });
  };

<TimeInput5min
    onTidChange={this.onTidChange}
    fra={this.state.fra}
    til={this.state.til}
    vertical={false}
/>
*************************************************** */

class TimeInput5min extends React.Component {
  constructor(props) {
    super(props);
    this.min1Ref = React.createRef();
    this.tim1Ref = React.createRef();
    this.min2Ref = React.createRef();
    this.tim2Ref = React.createRef();
    this.state = {};
  }

  tidChange = () => {
    let tim1 = this.tim1Ref.current.value;
    let min1 = this.min1Ref.current.value;
    let tim2 = this.tim2Ref.current.value;
    let min2 = this.min2Ref.current.value;

    if ((tim1 !== 'x') && (min1 === 'x')) {
        min1 = '00';
    }

    if ((tim2 !== 'x') && (min2 === 'x')) {
        min2 = '00';
    }

    let fra = tim1 + ':' + min1;
    let til = tim2 + ':' + min2;

    let t1 = parseInt(tim1 + min1);
    let t2 = parseInt(tim2 + min2);


    if (t2 < t1) {
      til = tim1 + ':' + min1;
    }
    if (fra.includes('x')) {
        fra = '';
    }
    if (til.includes('x')) {
        til = '';
    }
    console.log('?????????  ' + fra);
    this.props.onTidChange(fra, til);
  };

  getFraTime= (tid) => {
    if (tid){
        return tid.substring(0,2);
    } else {
        return 'x';
    }
  };

  getFraMinut= (tid) => {
    if (tid){
        return tid.substring(3,5);
    } else {
        return 'x';
    }
  };

  render() {
    return (
      <div>
        <div>
          <span>
            <select
              style={{ height: '30px', width: '45px' }}
              ref={this.tim1Ref}
              //value={this.getFraTime(this.props.fra.substring(0, 2))}
              value={this.getFraTime(this.props.fra)}
              onChange={this.tidChange}
              name='time1'
              id='time1'>
              <option value='x' />
              <option value='05'>05</option>
              <option value='06'>06</option>
              <option value='07'>07</option>
              <option value='08'>08</option>
              <option value='09'>09</option>
              <option value='10'>10</option>
              <option value='11'>11</option>
              <option value='12'>12</option>
              <option value='13'>13</option>
              <option value='14'>14</option>
              <option value='15'>15</option>
              <option value='16'>16</option>
              <option value='17'>17</option>
              <option value='18'>18</option>
              <option value='19'>19</option>
              <option value='20'>20</option>
            </select>
          </span>
          <span>
            <select
              style={{ borderLeftWidth: '0px', height: '30px', width: '45px' }}
              ref={this.min1Ref}
              //value={this.props.fra.substring(3, 5)}
              value={this.getFraMinut(this.props.fra)}
              onChange={this.tidChange}
              name='minut1'
              id='minut1'>
              <option value='x' />
              <option value='00'>00</option>
              <option value='05'>05</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
              <option value='25'>25</option>
              <option value='30'>30</option>
              <option value='35'>35</option>
              <option value='40'>40</option>
              <option value='45'>45</option>
              <option value='50'>50</option>
              <option value='55'>55</option>
            </select>
          </span>
          {this.props.vertical && (
            <span>
              <p />
            </span>
          )}
          {!this.props.vertical && (
            <span style={{ marginLeft: '8px', marginRight: '8px' }}>-</span>
          )}
          <span>
            <select
              style={{ height: '30px', width: '45px' }}
              ref={this.tim2Ref}
              value={this.getFraTime(this.props.til)}
              onChange={this.tidChange}
              name='time2'
              id='time2'>
              <option value='x' />
              <option value='05'>05</option>
              <option value='06'>06</option>
              <option value='07'>07</option>
              <option value='08'>08</option>
              <option value='09'>09</option>
              <option value='10'>10</option>
              <option value='11'>11</option>
              <option value='12'>12</option>
              <option value='13'>13</option>
              <option value='14'>14</option>
              <option value='15'>15</option>
              <option value='16'>16</option>
              <option value='17'>17</option>
              <option value='18'>18</option>
              <option value='19'>19</option>
              <option value='20'>20</option>
            </select>
          </span>
          <span>
            <select
              style={{ borderLeftWidth: '0px', height: '30px', width: '45px' }}
              ref={this.min2Ref}
              value={this.getFraMinut(this.props.til)}
              onChange={this.tidChange}
              name='minut2'
              id='minut2'>
              <option value='x' />
              <option value='00'>00</option>
              <option value='05'>05</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
              <option value='25'>25</option>
              <option value='30'>30</option>
              <option value='35'>35</option>
              <option value='40'>40</option>
              <option value='45'>45</option>
              <option value='50'>50</option>
              <option value='55'>55</option>
            </select>
          </span>
        </div>
      </div>
    );
  }
}

export default TimeInput5min;
