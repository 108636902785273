
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import { getSokLagrede, setStandardSokFilter } from 'api/sokLagretApi';
import { promisifyApiCall } from 'utils';

/* const gamleFjernummererte =
'{"kommune":[],"husnummer":{},"etasje":{},"etasjenummer":{},"Etasjetype":[],"type":[],"bygningstatuskode":[],"naringsgruppe":[],"-":true,"bruksenhettype":[],' + 
'"risikovurdering":{},"brisikoover":[],"iBruk2":[],"feieadkomst":[],"rrisikovurdering":{},"rrisikoover":[],"produsent":[],"modell":[],"typeR":[],"plassering":[],' + 
'"rehabilitert":[],"produsentI":[],"modellI":[],"typeI":[],"rentbrennende":[],"btiltakstype":[],"hyppighettiltakstype":[],"bSistestatus":[],"rtiltakstype":[],' + 
'"rHyppighettiltakstype":[],"rSistestatus":[]}'; */

const ibrukja =
'{"kommune":[],"husnummer":{},"etasje":{},"etasjenummer":{},"Etasjetype":[],"type":[],"bygningstatuskode":[],"' + 
'naringsgruppe":[],"bruksenhettype":[],"risikovurdering":{},"brisikoover":[],"iBruk2":["true"],"feieadkomst":[],"rrisikovurdering":{},"rrisikoover":[],' + 
'"produsent":[],"modell":[],"typeR":[],"plassering":[],"rehabilitert":[],"produsentI":[],"modellI":[],"typeI":[],"rentbrennende":[],"btiltakstype":[],' + 
'"hyppighettiltakstype":[],"bSistestatus":[],"rtiltakstype":[],"rHyppighettiltakstype":[],"rSistestatus":[]}';

let contextFilter = {
  init: false,
  filters: [],
  standardFilter: null,
  standardFilterId: -1,
};

export const getContextFilters = (_callback) => {
  let pif = contextInnstillingerPerson._currentValue.sokFilterStandard;

  //Test: hva dersom pif = null eller 0
  //pif = -1;

  if (contextFilter.init) {
    return _callback(contextFilter);
  }
  promisifyApiCall(getSokLagrede)().then((filters) => {
    contextFilter.filters = filters.sort((a, b) =>
      a.navn.localeCompare(b.navn)
    );
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].id === pif) {
        contextFilter.standardFilterId = pif;
        contextFilter.standardFilter = filters[i];
        contextFilter.init = true;
        return _callback(contextFilter);
      }
    }
     contextFilter.standardFilter = {
       brukerIds: null,
       forAlle: true,
       id: 0,
       navn: 'standardStandard',
       polygon: null,
       state: ibrukja
    };
    contextFilter.standardFilterId = pif;
    contextFilter.init = true;
    return _callback(contextFilter);
  });
};

export const hentFiltersIgjen = () => {
  contextFilter.init = false;
  setTimeout(() => {
    getContextFilters(gotFilters);
  }, 400);
};

const gotFilters = (filters) => {
};

export const lagreValgtFilter = (filter) => {
  contextFilter.standardFilter = filter;
  contextFilter.standardFilterId = filter.id;
  //Api
  setStandardSokFilter(filter.id, onFerdigLagret);
};

const onFerdigLagret = (err, data) => {
  
};

export const fjernStandardFilter = () => {
  setStandardSokFilter(99999, onFerdigFjernet);
};

const onFerdigFjernet = (err, data) => {
  contextFilter.standardFilter = {
    brukerIds: null,
    forAlle: true,
    id: 0,
    navn: 'standardStandard',
    polygon: null,
    state: ibrukja
 };
 contextFilter.standardFilterId = null;
 contextFilter.init = true;
};
