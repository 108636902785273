import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { PropTypes } from 'prop-types';
import { createKommune } from 'api/kommuneApi';

class NyKommune extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: true,
      kommune: {
        id: '',
        kommuneId: '',
        kommuneNavn: 'string',
        organisasjonsId: 0,
        aktiv: false,
      },
    };
    this.onKommuneOpprettet = this.onKommuneOpprettet.bind(this);
  }

  onKommuneOpprettet(err, result) {
    if (!err) {
      this.props.onClose();
      this.props.oppdater(true);
    } else {
      alert(err.response);
    }
  }

  render() {
    let classNameButton =
      this.props.isEnabled === false
        ? 'btn btn-lg font16 btn-primary disabled'
        : 'btn btn-lg font16 btn-primary';

    return (
      <NKModal size='medium' isOpen={this.props.isOpen}>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              onClick={this.props.onClose}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'>{this.props.tittel}</h4>
          </div>
          <div className='modal-body'>
            <div className='panel panel-default'>
              <div className='panel-body'>
                <div className='form'>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <label>Aktiv</label>
                    </div>
                    <div className='col-sm-8 form-check '>
                      <input
                        className='form-check-input'
                        style={{ width: '20px', height: '20px' }}
                        type='checkbox'
                        onChange={(e) => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              aktiv: !this.state.kommune.aktiv,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <label>Kommunenummer</label>
                    </div>
                    <div className='col-sm-4 form-group'>
                      <input
                        autoFocus
                        maxLength='50'
                        className='form-control'
                        type='text'
                        onChange={(e) => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              kommuneId: e.target.value,
                              id: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4 form-group'>
                      <label>Kommunenavn</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='250'
                        className='form-control'
                        type='text'
                        onChange={(e) => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              kommuneNavn: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4 form-group'>
                      <label>ReplikKundeId</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='250'
                        className='form-control'
                        type='text'
                        onChange={(e) => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              replikKundeId: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <label>Ktf MeldingstypeId</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='250'
                        className='form-control'
                        type='text'
                        value={this.state.kommune.ktfMeldingstypeId}
                        onChange={(e) => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              ktfMeldingstypeId: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-sm-4'>
                      <label>Ktf Røykløopmelding</label>
                    </div>
                    <div className='col-sm-8 form-check'>
                      <input
                        className='form-check-input'
                        style={{ width: '20px', height: '20px' }}
                        type='checkbox'
                        checked={this.state.kommune.royklopMelding}
                        onChange={(e) => {
                          this.setState({
                            kommune: {
                              ...this.state.kommune,
                              royklopMelding: !this.state.kommune
                                .royklopMelding,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <button
              className={classNameButton}
              onClick={() =>
                createKommune(this.state.kommune, this.onKommuneOpprettet)
              }>
              OK
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}

export default NyKommune;
