import React from 'react';
import { Header, Section } from '../../components/generics';
// Utils
import { makeLockReasonsTooltip } from '../../lib';
import { debugFactory } from 'utils';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:components:itembruksenhet'
);

const ItemBruksenhet = ({ item, onRemoveItem, onClick, ...props }) => {
  /*    const headerTitle = (CONFIG.debugMode ? `${item.tableId}: ` : '')
        + (item.adresse); */

  const headerTitle = item.adresse;

  const lockReasons = props.getObjektLockReasons(item);
  const tooltip = lockReasons ? makeLockReasonsTooltip(lockReasons) : 'Fjern';

  debug('render', { lockReasons, tooltip, item });

  return (
    <Section>
      <Header
        title={headerTitle}
        onIconClick={lockReasons ? null : () => onRemoveItem(item)}
        iconTooltip={tooltip}
        onClick={() => onClick(item)}
      />
    </Section>
  );
};

export default ItemBruksenhet;
