import callApi from './api';
import { debugFactory, callbackSpy } from 'utils';

const debug = debugFactory('nk:api:innloggingsapi');

/*     public bool Ok { get; set; }                         // styrer om system er nede eller ikke
        public string Tekst { get; set; }                   // tekst som skal vises til bruker når system er nede.
        public DateTime EndretDato { get; set; }  // dette settes i api men kan vises i klient
        public string EndretAv { get; set; }            // dette settes i api men kan vises i klient */

export function systemcheck(callback) {
  callback = callbackSpy(callback, debug, 'systemcheck');
  callApi('GET', '/status/system', null, callback);
}

/* public bool Ok { get; set; }                         // styrer om system er nede eller ikke
public string Tekst { get; set; }   */
export function oppdatersystemstatus( status, callback) {
    callApi('PUT', '/status/system', status, callback);
  }

export function innlogging(callback) {
  callback = callbackSpy(callback, debug, 'innlogging');
  callApi('GET', '/innlogging/', null, callback);
}

export function updatePersonId(id, callback) {
  callback = callbackSpy(callback, debug, 'updatePersonId');
  callApi('PUT', '/innlogging/updateadoid/' + id, null, callback);
}

export function updateorganisasjon(id, callback) {
  callback = callbackSpy(callback, debug, 'updateorganisasjon');
  callApi('PUT', '/innlogging/updateorganisasjon/' + id, null, callback);
}

export function createPersonInnlogging(person, callback) {
  callback = callbackSpy(callback, debug, 'createPersonInnlogging');
  callApi('POST', '/innlogging/createpersoninnlogging/', person, callback);
}

//import { nyDriftsmelding, oppdaterDriftsmelding, deleteDriftsmelding, getAktivDriftsmelding, getDriftsmeldinger } from 'api/innloggingApi.js';

export function nyDriftsmelding(melding, callback) {
  callApi('POST', '/status/driftsmelding/', melding, callback); 
};


export function oppdaterDriftsmelding(id, melding, callback) {
  callApi('PUT', '/status/driftsmelding/' + id,  melding, callback); 
};

export function deleteDriftsmelding(id, callback) {
  callApi('DELETE', '/status/driftsmelding/' + id, null, callback);
};

/* "id": 0,
"tittel": "string",
"tekst": "string",
"publisertDato": "2022-02-18T11:20:24.261Z",
"publisertAv": "string",
"historisk": true,
"historiskDato": "2022-02-18T11:20:24.261Z" */
export function getAktivDriftsmelding(callback) {
  callApi('GET', '/status/driftsmeldingaktiv/', null, callback); 
};

/* "id": 0,
"tittel": "string",
"tekst": "string",
"publisertDato": "2022-02-18T11:20:24.262Z",
"publisertAv": "string",
"historisk": true,
"historiskDato": "2022-02-18T11:20:24.262Z" */
export function getDriftsmeldinger(callback) {
  callApi('GET', '/status/driftsmeldinger/', null, callback); 
};

/* 
Informasjonsmeldinger
Aktiv bit NOT NULL DEFAULT (0),
Tittel varchar(250) NOT NULL,
Tekst varchar(2000) NOT NULL,
Url varchar(500) NULL
EndretAv varchar(50) NULL,
EndretDato datetime2 NULL */
export function oppdaterKundeMelding( melding, callback) {
  callApi('PUT', '/status/systeminformasjon/', melding, callback); 
};

export function getKundeMelding( callback) {
  callApi('GET', '/status/systeminformasjon/', null, callback); 
};

//FELLES
export function getSystemMeldingFelles( callback) {
  callApi('GET', '/status/systemfelles/', null, callback); 
};