import React, { Component } from 'react';

class MarkdownInfo extends Component {
  render() {
    return (
      <div>
        <table style={{ marginLeft: '40px', fontSize: '16px' }}>
          <tbody>
            <tr style={{ color: 'gray' }}>
              <td colspan='3' style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}>
                Litt om markdown:
              </td>
            </tr>
            <tr style={{ height: '8px' }}></tr>
            <tr style={{ color: 'gray' }}>
              <td style={{ fontWeight: 'bold' }}>Fet</td>
              <td style={{ width: '16px' }} />
              <td>**Dette er fet.**</td>
              <td style={{ width: '80px' }} />
              <td style={{ fontWeight: 'bold' }}>Liste</td>
              <td style={{ width: '16px' }} />
              <td>* Aaa</td>
            </tr>
            <tr style={{ color: 'gray' }}>
              <td style={{ fontWeight: 'bold' }}>Kursiv</td>
              <td />
              <td>*Dette er kursiv.*</td>
              <td style={{ width: '80px' }} />
              <td />
              <td style={{ width: '16px' }} />
              <td>* Bbb</td>
            </tr>
            <tr style={{ color: 'gray' }}>
              <td style={{ fontWeight: 'bold' }}>Linjeskift</td>
              <td />
              <td>\</td>
              <td style={{ width: '80px' }} />
              <td style={{ fontWeight: 'bold' }}>Nr.Liste</td>
              <td style={{ width: '16px' }} />
              <td>1. Aaa</td>
            </tr>
            <tr style={{ color: 'gray' }}>
              <td style={{ fontWeight: 'bold' }}>Tom linje</td>
              <td />
              <td>Tom linje</td>
              <td style={{ width: '80px' }} />
              <td />
              <td style={{ width: '16px' }} />
              <td>2. Bbb</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default MarkdownInfo;
