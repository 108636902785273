import React, { useState, useEffect } from 'react';
import Soppelbotte from 'images/24/nk_036-soppelbotte-A.png';
import LasterIcon from 'images/24/nk_019-lasting-A.png';
import {
    loadGrunnlagsdataRisiko,
    getStatusLoadGrunnlagsdataRisiko,
    deleteGrunnlagsdataRisiko,
    getAnalyseDataStatus,
    startMassivAnalyse,
    importMassiv
  } from 'api/risikoApi.js';
  import Spinner from 'components/generics/Spinner';
  import JaNeiDialog from 'components/Dialogs/JaNeiDialogFC.js';

const Risikomassiv = (props) => {
    const [lastIkkeFritid, setLastIkkeFritid] = useState(true);
    const [lastFritid, setLastFritid] = useState(true);
    const [lastMedfyring, setLastMedfyring] = useState(true);
    const [selectedRadio, setSelectedRadio] = useState('enhet');
    const [fritidsanalyse, setFritidsanalyse] = useState(false);
    const [massivstatus, setMassivstatus] = useState('Ikke startet');
    const [grunnlagTotalt, setGrunnlagTotalt] = useState('-');
    const [antallLastet, setAntallLastet] = useState('-');
    const [antallLastetFritid, setAntallLastetFritid] = useState('-');
    const [lastingStart, setLastingStart] = useState('-');
    const [sistLastet, setSistLastet] = useState('-');
    const [importstatus, setImportstatus] = useState('Ikke startet');
    const [bFeilet, setBFeilet] = useState('-');
    const [bImportert, setBImportert] = useState('-');
    const [rFeilet, setRFeilet] = useState('-');
    const [rImportert, setRImportert] = useState('-');
    const [totaltBruksenheter, setTotaltBruksenheter] = useState('-');
    const [totaltRoyklop, setTotaltRoyklop] = useState('-');
    const [isLoading, setIsLoading] = useState(false);

    const [isLastOpen, setIsLastOpen] = useState(false);
    const [isSlettOpen, setIsSlettOpen] = useState(false);
    const [isStartOpen, setIsStartOpen] = useState(false);
    const [isImportOpen, setIsImportOpen] = useState(false);

    const slettGrunnlagsdataRisiko = () => {
        setIsSlettOpen(true);
    };

    const handleSlettGrunnlagClose = (svar) => {
        if (svar === 'ja') { 
            setIsLoading(true);
        deleteGrunnlagsdataRisiko(props.org.id, dataSlettet);
        }
        setIsSlettOpen(false);
    };

    const dataSlettet = () => {
        setIsLoading(false);
    };

    const lastGrunnlagsdataRisiko = () => {
        console.log('test');
        setIsLastOpen(true);
        // loadGrunnlagsdataRisiko(
        //     props.org.id,
        //     lastMedfyring,
        //     lastIkkeFritid,
        //     lastFritid,
        //     lastingStartet
        //   );
        //   setIsLoading(true);
    };

    const handleLastGrunnlagClose = (svar) => {
        setIsLastOpen(false);
        if (svar === 'ja') {
            loadGrunnlagsdataRisiko(
                props.org.id,
                lastMedfyring,
                lastIkkeFritid,
                lastFritid,
                lastingStartet
              );
              setIsLoading(true);
        }
      };

    const lastingStartet = (err, res) => {
        setIsLoading(false);
    };  

    const handleRadioChange = (val) => {
        setSelectedRadio(val.currentTarget.value);
    };

    const startMassiv = () => {
        setIsStartOpen(true);
    };

    const handleStartClose = (svar) => {
        setIsStartOpen(false);
        if (svar === 'ja') {
            setMassivstatus('Startet');
            setIsLoading(true);
            startMassivAnalyse(props.org.id, selectedRadio, fritidsanalyse, massivAnalyseStartet);
        }
    };

    const massivAnalyseStartet = (err, res) => {
        setIsLoading(false);
    };

    const sjekkLastingStatus = () => {
        setIsLoading(true);
        getStatusLoadGrunnlagsdataRisiko(props.org.id, gotGrunnlagsdataRisiko);
    };

    const gotGrunnlagsdataRisiko = (err, res) => {
        setIsLoading(false);
        setGrunnlagTotalt(res.antallTotalt);
        setAntallLastet(res.antallLastet);
        setAntallLastetFritid(res.antallLastetFritid);
        setLastingStart(formaterDato(res.startLastet));
        setSistLastet(formaterDato(res.sisteLastet));
    };

    const sjekkAnalyseStatus = () => {
        setIsLoading(true);
        getAnalyseDataStatus(props.org.id, gotAnalyseStatusData);
    };

    const gotAnalyseStatusData = (err, res) => {
        setIsLoading(false);
        setTotaltBruksenheter(res.antallBruksenhetAnalysertTotalt);
        setTotaltRoyklop(res.antallRoyklopAnalysertTotalt);
    };

    const startImport = () => {
        setIsImportOpen(true);
    };

    const handleLastImportClose = (svar) => {
        setIsImportOpen(false);
        if (svar === 'ja') {
            setImportstatus('Startet');
            setIsLoading(true);
            importMassiv(props.org.id, massivImportStartet);
        }
    };

    const massivImportStartet = (err, res) => {
        setIsLoading(false);
    };

    const sjekkImportStatus = () => {
        setIsLoading(true);
        getAnalyseDataStatus(props.org.id, gotImportStatusData);
    };

    const gotImportStatusData = (err, res) => {
        setIsLoading(false);
        setBFeilet(res.antallBruksenhetFeilet);
        setBImportert(res.antallBruksenhetImportert);
        setRFeilet(res.antallRoyklopFeilet);
        setRImportert(res.antallRoyklopImportert);
    };

    const formaterDato = (dato) => {
        if (dato == null) {
          return null;
        }
        var date = new Date(dato);
        if (date.getFullYear().toString() === '1') {
          return null;
        }
        var dag = date.getDate().toString();
        var mnd = (date.getMonth() + 1).toString();
        var aar = date.getFullYear().toString();
        var time = date.getHours().toString();
        var min = date.getMinutes().toString();
        if (dag.length === 1) {
          dag = '0' + dag;
        }
        if (mnd.length === 1) {
          mnd = '0' + mnd;
        }
        if (time.length === 1) {
          time = '0' + time;
        }
        if (min.length === 1) {
          min = '0' + min;
        }
        return dag + '.' + mnd + '.' + aar + ' ' + time + ':' + min;
      };

  return (
    <div>
      { isLoading ? (
      <React.Fragment>
        <Spinner className='h4' />
      </React.Fragment>
      ) :(
        
        <div>
        <h2>Risikovurdering massiv analyse - {props.org.navn}</h2>
        <br/>
          <div className="panel-group">
            <div className="panel panel-default">
              <div className="panel-heading">
                <b>Grunnlagsdata for massiv analyse</b>
              </div>
              <div className="panel-body">
                <div className="row">
                    <div className="col-sm-3" style={{ paddingTop: '8px' }}>
                        <div style={{ paddingLeft: '15px' }}>
                              Last enheter som ikke er tilknyttet friitidsbygg
                        </div>
                    </div>
                    <div className="col-sm-2 form-check ">
                        <input
                            className="checkbox30"
                            type="checkbox"
                            checked={lastIkkeFritid}
                            onChange={(e) => {
                                setLastIkkeFritid(!lastIkkeFritid);
                            }}
                            />
                    </div>
                    <div className="col-sm-3 pull-rigth">
                        <button
                            className="btn btn-default "
                            style={{ paddingLeft: '15px' }}
                            title="Slett grunnlagsdata for valgt kunde"
                            onClick={slettGrunnlagsdataRisiko}>
                            <img
                                src={Soppelbotte}
                                alt="laster"
                                height="24"
                                width="24"
                            />
                            <span style={{ marginLeft: '20px' }}>
                                <b>Slett grunnlagsdata</b>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3" style={{ paddingTop: '8px' }}>
                        <div style={{ paddingLeft: '15px' }}>
                        Last enheter tilkyttet fritidsbygg
                        </div>
                    </div>
                    <div className="col-sm-2 form-check ">
                        <input
                            className="checkbox30"
                            type="checkbox"
                            checked={lastFritid}
                            onChange={(e) => {
                                setLastFritid(!lastFritid);
                            }}
                            />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-3" style={{ paddingTop: '8px' }}>
                        <div style={{ paddingLeft: '15px' }}>
                        Last enheter uten fyringsanlegg
                        </div>
                    </div>
                    <div className="col-sm-2 form-check ">
                        <input
                            className="checkbox30"
                            type="checkbox"
                            checked={lastMedfyring}
                            onChange={(e) => {
                                setLastMedfyring(!lastMedfyring);
                            }}
                            />
                    </div>
                    <div className="col-sm-3 pull-rigth">
                    <button
                        className="btn btn-default "
                        style={{ paddingLeft: '15px' }}
                        title="Last grunnlagsdata for valgt kunde"
                        onClick={lastGrunnlagsdataRisiko}>
                        <img
                            src={LasterIcon}
                            alt="laster"
                            height="24"
                            width="24"/>
                        <span style={{ marginLeft: '20px' }}>
                            <b>Last grunnlagsdata</b>
                        </span>
                    </button>
                    </div>
                </div>
               
                <div className="row">
                  <div className="col-sm-6" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                        Status:
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                  <table style={{ fontSize: '15px' }}>
                    <tbody>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '150px', paddingLeft: '3px' }}>
                                Antall totalt:
                            </td>
                            <td style={{ width: '160px', textAlign: 'center' }}>
                                {grunnlagTotalt}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '150px', paddingLeft: '3px' }}>
                                Antall lastet:
                            </td>
                            <td style={{ width: '160px', textAlign: 'center' }}>
                                {antallLastet}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '150px', paddingLeft: '3px' }}>
                                Antall lastet fritid:
                            </td>
                            <td style={{ width: '160px', textAlign: 'center' }}>
                                {antallLastetFritid}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '150px', paddingLeft: '3px' }}>
                                Lasting startet:
                            </td>
                            <td style={{ width: '160px', textAlign: 'center' }}>
                                {lastingStart}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '150px', paddingLeft: '3px' }}>
                                Sist lastet:
                            </td>
                            <td style={{ width: '160px', textAlign: 'center' }}>
                                {sistLastet}
                            </td>
                        </tr>
                        </tbody>
                    </table>  
                  </div>
                  <div className="col-sm-2">
                  </div>
                  <div className="col-sm-5 pull-rigth" style={{ paddingTop: '8px' }}>
                    <button className="btn btn-default"
                        style={{ paddingLeft: '10px', width: '260px' }}
                        title="Sjekk analyse status"
                        onClick={sjekkLastingStatus}>
                        <span style={{ marginLeft: '5px' }}>
                                <b>Sjekk last grunnlagsdata status</b>
                        </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <b>Massiv analyse</b>
              </div>
              <div className="panel-body">
              <div className="row">
              <div className="col-sm-5">
              <div className='form-group'>
                    <div>
                      <input
                        style={{ marginRight: '10px' }}
                        type='radio'
                        name='bruksenhet'
                        value='enhet'
                        checked={selectedRadio === 'enhet'}
                        onChange={handleRadioChange}
                      />
                      Bruksenhet
                    </div>
                  </div>
                  <div className='form-group'>
                    <div>
                      <input
                        style={{ marginRight: '10px' }}
                        type='radio'
                        name='royklop'
                        value='royklop'
                        checked={selectedRadio === 'royklop'}
                        onChange={handleRadioChange}
                      />
                      Røykløp
                    </div>
                  </div>
              </div>
              </div>                 

              </div>
              <div className="row">
                <div className="col-sm-3" style={{ paddingTop: '8px' }}>
                        <div style={{ paddingLeft: '15px' }}>
                        Fritidsanalyse
                        </div>
                </div>
                <div className="col-sm-2 form-check ">
                        <input
                            className="checkbox30"
                            type="checkbox"
                            checked={fritidsanalyse}
                            onChange={(e) => {
                                setFritidsanalyse(!fritidsanalyse);
                            }}
                            />
                </div>
                <div className="col-sm-5" style={{ paddingTop: '8px', paddingLeft: '15px' }}>
                    <button className="btn btn-default"
                              style={{ paddingLeft: '10px', width: '180px' }}
                              title="Start massiv analyse"
                              onClick={startMassiv}
                            >
                              <span style={{ marginLeft: '5px' }}>
                                <b>Start massiv analyse</b>
                              </span>
                    </button>
                    <span style={{ marginLeft: '10px' }}>
                        {massivstatus}
                    </span>
                </div>
                
                </div>
                <div className="row">
                  <div className="col-sm-6" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                        Status:
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-3" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                  <table style={{ fontSize: '15px' }}>
                    <tbody>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '260px', paddingLeft: '3px' }}>
                                Antall bruksenheter analysert:
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                {totaltBruksenheter}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '260px', paddingLeft: '3px' }}>
                                Antall røykløp analysert:
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                {totaltRoyklop}
                            </td>
                        </tr>
                        </tbody>
                    </table>  
                  </div>
                  <div className="col-sm-2"></div>

                  <div className="col-sm-5 pull rigth" style={{ paddingTop: '8px' }}>
                    <button className="btn btn-default"
                        style={{ paddingLeft: '10px', width: '180px' }}
                        title="Sjekk analyse status"
                        onClick={sjekkAnalyseStatus}>
                        <span style={{ marginLeft: '5px' }}>
                                <b>Sjekk analyse status</b>
                        </span>
                    </button>
                  </div>
                </div>
                <br/>
                <br/>
            </div>
            
            <div className="panel panel-default">
              <div className="panel-heading">
                <b>Import av massiv analyse</b>
              </div>
              <div className="panel-body">
              <div className="row">
              <div className="col-sm-5"></div>
              <div className="col-sm-5" style={{ paddingTop: '8px', paddingLeft: '15px' }}>
                    <button className="btn btn-default"
                              style={{ paddingLeft: '5px', width: '200px' }}
                              title="Importer analyse resultat"
                              onClick={startImport}
                            >
                              <span style={{ marginLeft: '5px' }}>
                              <b>Importer analyse resultat</b>
                              </span>
                    </button>
                    <span style={{ marginLeft: '10px' }}>
                    {importstatus}
                    </span>
                </div>
                
              </div>
              <div className="row">
                  <div className="col-sm-6" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                        Status:
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3" style={{ paddingTop: '8px', paddingLeft: '30px' }}>
                  <table style={{ fontSize: '15px' }}>
                    <tbody>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '260px', paddingLeft: '3px' }}>
                                Antall bruksenheter feilet:
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                {bFeilet}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '260px', paddingLeft: '3px' }}>
                                Antall bruksenheter importert:
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                {bImportert}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '260px', paddingLeft: '3px' }}>
                                Antall røykløp feilet:
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                {rFeilet}
                            </td>
                        </tr>
                        <tr style={{ border: '1px solid', borderColor: 'gray', backgroundColor: '#F8F8F8' }}>
                            <td style={{ width: '260px', paddingLeft: '3px' }}>
                                Antall røykløp importert:
                            </td>
                            <td style={{ width: '50px', textAlign: 'center' }}>
                                {rImportert}
                            </td>
                        </tr>
                        </tbody>
                    </table>  
                  </div>
                  <div className="col-sm-2">
                  </div>
                  <div className="col-sm-3 pull-rigth" style={{ paddingTop: '8px' }}>
                    <button className="btn btn-default"
                        style={{ paddingLeft: '10px', width: '200px' }}
                        title="Sjekk import status"
                        onClick={sjekkImportStatus}>
                        <span style={{ marginLeft: '5px' }}>
                                <b>Sjekk import status</b>
                        </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          
      </div>

      <JaNeiDialog
        tittel='Last risiko grunnlagsdata'
        tekst='Bekreft lasting av data'
        
        isOpen={isLastOpen}
        handleClose={handleLastGrunnlagClose}
        visClose={true}
      />
      <JaNeiDialog
        tittel='Slett risiko grunnlagsdata'
        tekst='Bekreft sletting av data'
        
        isOpen={isSlettOpen}
        handleClose={handleSlettGrunnlagClose}
        visClose={true}
      />
      <JaNeiDialog
        tittel='Start massiv analyse'
        tekst='Bekreft start massiv analyse'
        
        isOpen={isStartOpen}
        handleClose={handleStartClose}
        visClose={true}
      />
      <JaNeiDialog
        tittel='Importer analyse resultat'
        tekst='Bekreft import av analyse resultat'
        
        isOpen={isImportOpen}
        handleClose={handleLastImportClose}
        visClose={true}
      />
      </div>
      ) }
    </div>
    
  );
};
  

  
  export default Risikomassiv;