let sortOptions = [
  {
    label: 'Sorter på adresse',
    key: 'adresse',

    fields: [
      {
        field: 'kommune.kommuneId',
        options: {
          order: 'asc',
          nested_path: 'kommune'
        }
      },
      {
        field: 'adresse.gatenavn.keyword',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      },
      {
        field: 'adresse.husnummer',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      },
      {
        field: 'adresse.bokstav.keyword',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      },
      {
        field: 'etasjenummer'
      },
      {
        field: 'lopenummer'
      }
    ]
  },
  {
    label: 'Neste dato - tiltak bruksenhet',
    key: 'nesteDatoB',
    fields: [
      {
        field: 'skybrannData.bruksenhetTiltakType.nesteDato',
        options: {
          order: 'asc',
          nested_path: 'skybrannData.bruksenhetTiltakType'
        }
      },
      {
        field: 'adresse.gatenavn.keyword',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      },
      {
        field: 'adresse.husnummer',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      }
    ]
  },
  {
    label: 'Neste dato - tiltak røykløp',
    key: 'nesteDatoR',
    fields: [
      {
        field: 'skybrannData.royklopTiltakType.nesteDato',
        options: {
          order: 'asc',
          nested_path: 'skybrannData.royklopTiltakType'
        }
      },
      {
        field: 'adresse.gatenavn.keyword',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      },
      {
        field: 'adresse.husnummer',
        options: {
          order: 'asc',
          nested_path: 'adresse'
        }
      }
    ]
  },
  {
    label: 'Sorter på gårds- og bruksnummer',
    key: 'gnr',
    fields: [
      {
        field: 'matrikkelenhet.gardsnummer',
        options: {
          order: 'asc',
          nested_path: 'matrikkelenhet'
        }
      },
      {
        field: 'matrikkelenhet.bruksnummer',
        options: {
          order: 'asc',
          nested_path: 'matrikkelenhet'
        }
      },
      {
        field: 'matrikkelenhet.seksjonsnummer',
        options: {
          order: 'asc',
          nested_path: 'matrikkelenhet'
        }
      },
      {
        field: 'matrikkelenhet.festenummer',
        options: {
          order: 'asc',
          nested_path: 'matrikkelenhet'
        }
      },
      {
        field: 'matrikkelenhet.festenummer',
        options: {
          order: 'asc',
          nested_path: 'matrikkelenhet'
        }
      }
    ]
  }
];

export function getSortOptionsWithScore(){
  sortOptions.splice(1, 0, {
    label: 'Sorter på best treff',
    key: '_score',
    field: '_score', 
    order: 'desc'
  });  
  return sortOptions;
}

export function getSortOptions() {
  return sortOptions;
}
