import React, { Component } from 'react';
import _ from 'lodash';
import L from 'leaflet';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import NKIcon from 'nka-icons';
import './regioner.css';
import NKModal from 'components/Dialogs/NKModal.jsx';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import omradeSoneApi from 'api/omradeSonerApi';
import RedigerOmrader from './RedigerOmrader.jsx';
import SoneInfo from './SoneInfo.jsx';
import InfoDialog from 'components/Dialogs/InformationDialog.jsx';
import PromptDialog from 'components/Dialogs/PromptDialog.jsx';
import DialogStore from 'lib/DialogStore';
delete L.Icon.Default.prototype._getIconUrl;

const modalDialogs = {
  SletteDialog: ({ isOpen, onClose, dialogData, owner }) => (  
    <JaNeiDialog
      tittel="Slette?"
      melding={'Ønsker du å slette ' + dialogData.slettSone.navn + '?'}
      isOpen={isOpen} 
      onClose={onClose}
      onJa={owner.slettJa.bind(this)}
      onNei={onClose}
    />
  ),
  RedigerOmraderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" 
      isOpen={isOpen} 
      onClose={onClose}>
      <RedigerOmrader
        omraderChanged={owner.omraderChanged.bind(this)}
        onClose={onClose}
      />
    </NKModal>
  ),
  PromptDialog: ({ isOpen, onClose, dialogData, owner }) => ( 
    <PromptDialog
      prompt="Områdenavn"
      tittel="Nytt område"
      isOpen={isOpen} 
      onOk={owner.nyOmradeOK}
      onClose={onClose}
      omraader={true}
    />
  ),
  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => ( 
    <InfoDialog
      melding={dialogData.infoMelding}
      tittel="Lagre"
      isOpen={isOpen} 
      onClose={onClose}
    />
  ),
  SoneInfoDialog: ({ isOpen, onClose, dialogData, owner }) => ( 
    <NKModal size="medium" 
      isOpen={isOpen} 
      onClose={onClose}>
      <SoneInfo onClose={onClose} />
    </NKModal>
  )
};

class RegionerPanel extends Component {
  constructor(props) {
    super(props);
    this.velgSone = this.velgSone.bind(this);
    this.setRegionButton = this.setRegionButton.bind(this);
    this.state = {
      soneCollection: [],
      omrader: [],
      grupper: [],
      slettSone: '',
      infoMelding: '',
      refresh: 0
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.makeOmraade = this.makeOmraade.bind(this);
    this.slett = this.slett.bind(this);
    this.slettJa = this.slettJa.bind(this);
    this.nullstillSone = this.nullstillSone.bind(this);
    this.openOmrader = this.openOmrader.bind(this);
    this.omraderChanged = this.omraderChanged.bind(this);
    this.omradeUpdated = this.omradeUpdated.bind(this);
    this.openNyOmrade = this.openNyOmrade.bind(this);
    this.omradeCreated = this.omradeCreated.bind(this);
    this.nyOmradeOK = this.nyOmradeOK.bind(this);
    this.openSoneInfo = this.openSoneInfo.bind(this);
    this.onGotAlleOmrader = this.onGotAlleOmrader.bind(this);
    this.onGotAlleSoner = this.onGotAlleSoner.bind(this);
    this.onGotOmrader = this.onGotOmrader.bind(this);
    this.hentData = this.hentData.bind(this);
  }

  componentDidMount() {
    this.hentData();
  }

  componentWillReceiveProps(props) {
    let refresh = this.state.refresh;
    if (refresh < props.refresh) {
      this.setState({ refresh: props.refresh });
      this.hentData();
    }
  }

  hentData() {
    omradeSoneApi.getAlleSoner(this.onGotAlleSoner.bind(this));
  }

  onGotAlleSoner(err, data) {
    let soner = _.sortBy(data, 'navn');
    this.setState({ soneCollection: soner });
    omradeSoneApi.getAlleOmrader(this.onGotOmrader.bind(this));
  }

  onGotOmrader(err, data) {
    let omrader = _.sortBy(data, 'navn'); 
    this.setState({ omrader: omrader });
    let grupper = [];
    for (let i = 0; i < omrader.length; i++) {
      let gr = omrader[i].navn;
      grupper.push(gr);
    }
    this.setState({ grupper: grupper });
/*     console.log('omrader');
    console.log(omrader);
    console.log('grupper');
    console.log(grupper);
    console.log('soner');
    console.log(this.state.soneCollection); */
  }

  velgSone(son) {
    let ref = 'btn' + son.id;
    let vises = this.setRegionButton(ref);
    this.props.onValgtSone(son, vises);
  }

  setRegionButton(ref) {
    let vises = false;
    let btn = this.refs[ref];
    let cc = btn.getAttribute('class');
    if (cc.includes('paa')) {
      vises = true;
      cc = 'bregion av';
    } else {
      cc = 'bregion paa';
    }
    btn.setAttribute('class', cc);
    return vises;
  }

  nullstillAlle() {
    let bs = document.getElementsByTagName('button');
    for (var i = 0; i < bs.length; i++) {
      if (bs[i].getAttribute('class') === 'bregion paa') {
        bs[i].setAttribute('class', 'bregion av');
      }
    }
  }

  nullstillSone(sone) {
    let bs = document.getElementsByTagName('button');
    for (var i = 0; i < bs.length; i++) {
      if (bs[i].value === sone) {
        bs[i].setAttribute('class', 'bregion av');
      }
    }
  }

  makeOmraade(omraade) {
    let s = omraade.replace(/[\])}[{(]/g, '');
    let z = s.replace(/\s+/g, '');
    return z;
  }

  slett(sone) {
    this.setState({ slettSone: sone });
    this.dialogs.setOpenDialog('SletteDialog', { slettSone: sone });
  }

  slettJa() {
    this.dialogs.closeDialog();
    this.props.onSlettSone(this.state.slettSone);
  }

  openOmrader() {
    this.props.closeMeny();
    this.dialogs.setOpenDialog('RedigerOmraderDialog');
  }

  omraderChanged(omrader, slettListe) {
    this.dialogs.closeDialog();
    for (var i = 0; i < omrader.length; i++) {
      omradeSoneApi.updateOmrade(
        omrader[i].id,
        omrader[i],
        this.omradeUpdated.bind(this)
      );
    }
    for (var y = 0; y < slettListe.length; y++) {
      omradeSoneApi.deleteOmrade(
        slettListe[y].id,
        this.omradeUpdated.bind(this)
      );
    }
  }

  omradeUpdated(err, data) {
    this.props.omraderOppdatert();
  }

  openNyOmrade() {
    this.props.closeMeny();
    omradeSoneApi.getAlleOmrader(this.onGotAlleOmrader.bind(this));
  }

  onGotAlleOmrader(err, data) {
    this.setState({ omrader: data });
    this.dialogs.setOpenDialog('PromptDialog');
  }

  omradeFinnesIkkeFraFor(omrader, omrade) {
    let finnesIkke = true;
    for (var i = 0; i < omrader.length; i++) {
      var om = omrader[i];
      if (om.navn.toLowerCase() === omrade.toLowerCase()) {
        finnesIkke = false;
      }
    }
    if (finnesIkke === false) {
      this.dialogs.setOpenDialog('InfoDialog', 
         { infoMelding: 'Områdenavn er benyttet fra før, endre det til et unikt navn. ' });
      this.dialogs.setOpenDialog('PromptDialog');
    }

    return finnesIkke;
  }

  omradeCreated(err, data) {
    let grupper = this.state.grupper;
    let gruppe = data.navn;
    grupper.push(gruppe);
    this.setState({ grupper: grupper.sort() });
  }

  nyOmradeOK(val) {
    if (val.trim() === '') {
      alert('Kan ikke lagre uten navn..');
      return;
    } else {
      this.dialogs.closeDialog();
      if (this.omradeFinnesIkkeFraFor(this.state.omrader, val) === true) {
        let nyOmrade = { id: '0', navn: val };
        omradeSoneApi.createOmrade(nyOmrade, this.omradeCreated.bind(this));
      }
    }
  }

  openSoneInfo() {
    this.props.closeMeny();
    this.dialogs.setOpenDialog('SoneInfoDialog');
  }

  render() {
    return (
      <div>
        
        <div ref="SonePanel" className="regionerScroll">
        <label style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>Velg Sone</label>
          <button title= 'Lukk' style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={ this.props.closeMeny }>X</button>  
          <br/>
          <div className="panel-group w200" id="accordion">
            <span>
              {this.state.grupper.map(function(omraade) {
                var g = this.makeOmraade(omraade); 
                var bb = 'key' + omraade;
                var html;
                html = (
                  <div key={bb} className="w200">
                    <button
                      className="btn btn-basic arrow-toggle w200 mb4 collapsed"
                      data-toggle="collapse"
                      data-target={'#' + g}>
                      <label className="pull-left">{omraade}</label>
                      <NKIcon
                        className="pull-right icon-arrow-up"
                        icon="ned"
                        size="1"
                        color="nk-black"
                      />
                      <NKIcon
                        className="pull-right icon-arrow-down"
                        icon="opp"
                        size="1"
                        color="nk-black"
                      />
                    </button>

                    <div id={g} className="collapse w200">
                      {this.state.soneCollection.map(function(reg) {
                        if (reg.omradeNavn === omraade) {
                          var bId = 'btn' + reg.id;
                          var dId = 'div' + reg.id;
                          var klasse = 'bregion av ';
                          if (reg.vis === true) {
                            klasse = 'bregion paa ';
                          } else {
                            klasse = 'bregion av ';
                          }
                          var html;
                          html = (
                            <div key={dId}>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <button
                                        key={bId}
                                        ref={bId}
                                        className={klasse}
                                        value={reg.navn}
                                        onClick={this.velgSone.bind(this, reg)}>
                                        <div className="pull-left">
                                          &nbsp;&nbsp;{reg.navn}
                                        </div>
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-default bslett"
                                        onClick={this.slett.bind(this, reg)}>
                                        <NKIcon
                                          icon="soppelbotte"
                                          size="0.8"
                                          color="nk-black"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          );
                          return html;
                        }
                      }, this)}
                    </div>
                  </div>
                );

                return html;
              }, this)}
            </span>
          </div>
        </div>
        <button onClick={this.openNyOmrade} className="btn btn-basic btnOmrade">
          Nytt område
        </button>
        <button onClick={this.openOmrader} className="btn btn-basic btnOmrade">
          Rediger områder
        </button>
        <button onClick={this.openSoneInfo} className="btn btn-basic btnOmrade">
          Rediger soner
        </button>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default RegionerPanel;
