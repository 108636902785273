import React from 'react';
import _ from 'lodash';
import { getArblisteBrukenheterPlanlegging } from 'api/enhetApi.js';
import { getArbeidslisteBrukseneheterOgRoklop } from 'api/arbeidslisteApi.js';
import { getAlleTiltakstyper } from 'api/enhetApi.js';
import { debugFactory, promisifyApiCall, objGetDeep } from 'utils';

const debug = debugFactory('nk:context Planlegging');

let valgtPerson = { personId: -1 };
let contextTiltakstyper = { init: false };
let selectedArbeidsliste = null;
let LeggTilObjekter = {};
let snapshotarbeidslisteTiltakLister = null;

export const setValgtPerson = (personId) => {
  valgtPerson.personId = personId;
};

export const getContextTiltakstyper = (_callback) => {
  if (!contextTiltakstyper.init) {
    promisifyApiCall(getAlleTiltakstyper)().then((data) => {
      contextTiltakstyper.typer = data;
      contextTiltakstyper.init = true;
      LeggTilObjekter.tiltakstyper = data;
      _callback(contextTiltakstyper);
    });
  } else {
    _callback(contextTiltakstyper);
  }
};

const addTableId = (liste) => {
  //  tableId = bruksenhetId eller bruksenhetId-royklopId  969294-1005121
  for (let i = 0; i < liste.length; i++) {
    const element = liste[i];
    let en = _.get(element, 'bruksenhetId');
    let ro = _.get(element, 'royklopId');
    if (ro) {
      element.tableId = en + '-' + ro;
    } else {
      if (en) {
        element.tableId = en;
      }
    }
  }
  return liste;
};

export const getBrukseneheterOgRoklopForArbeidsliste = (_callback) => {
  if (!selectedArbeidsliste) {
    return;
  }
  let id = selectedArbeidsliste.id;
  promisifyApiCall(getArbeidslisteBrukseneheterOgRoklop)(id).then((data) => {
    debug('onsdagtil context', data);
    LeggTilObjekter.arbeidslisteTiltakLister = data;
    let bruksenheter = [];
    let royklop = [];
    debug('onsdag context planlegging arbeidslisteTiltakLister ', data);
    if (data.tiltakTypeBruksenhetListe) {
      bruksenheter = addTableId(data.tiltakTypeBruksenhetListe);
    }
    if (data.tiltakTypeRoyklopListe) {
      royklop = addTableId(data.tiltakTypeRoyklopListe);
    }
    LeggTilObjekter.arbeidslisteEnkelTiltakListe = bruksenheter.concat(royklop);
    _callback(data);
  });
};

export const getTiltakListe = (
  { bruksenheter = false, royklop = false } = {
    bruksenheter: true,
    royklop: true,
  }
) => {
  if (!LeggTilObjekter.arbeidslisteTiltakLister) {
    return [];
  }

  let liste = LeggTilObjekter.arbeidslisteTiltakLister;

  if (bruksenheter) {
    if (liste.tiltakTypeBruksenhetListe) {
      return liste.tiltakTypeBruksenhetListe;
    } else {
      return [];
    }
  }
  if (royklop) {
    if (liste.tiltakTypeRoyklopListe) {
      return liste.tiltakTypeRoyklopListe;
    } else {
      return [];
    }
  }
};

export const getTiltakListeAlle = () => {
  if (!LeggTilObjekter.arbeidslisteTiltakLister) {
    return [];
  }

  let liste = LeggTilObjekter.arbeidslisteTiltakLister;

  let b = [];
  if (liste.tiltakTypeBruksenhetListe) {
    b = liste.tiltakTypeBruksenhetListe;
  }

  let r = [];
  if (liste.tiltakTypeRoyklopListe) {
    r = liste.tiltakTypeRoyklopListe;
  }
  return b.concat(r);
};

export const setSelectedArbeidslistePlanlegging = (arbeidsliste) => {
  selectedArbeidsliste = arbeidsliste;
  LeggTilObjekter.selectedArbeidsliste = arbeidsliste;
};

export const getSelectedArbeidslistePlanlegging = (_callback) => {
  _callback(selectedArbeidsliste);
};

export const getSelectedArbeidslistePlanleggingEnheter = (_callback) => {
  if (!selectedArbeidsliste) {
    return;
  }
  let id = selectedArbeidsliste.id;
  promisifyApiCall(getArblisteBrukenheterPlanlegging)(id).then(
    (arbeidslisteEnheter) => {
      LeggTilObjekter.arbeidslisteEnheter = arbeidslisteEnheter;
      _callback(arbeidslisteEnheter);
    }
  );
};

export const nullstillPlanleggingsContext = () => {
  LeggTilObjekter.arbeidslisteEnheter = null;
  LeggTilObjekter.arbeidslisteTiltakLister = null;
  LeggTilObjekter.arbeidslisteEnkelTiltakListe = null;
  /*    LeggTilObjekter.tiltakstyper = null;
    LeggTilObjekter.selectedArbeidsliste = selectedArbeidsliste; */
};

export const makeSnapshotarbeidslisteTiltakLister = () => {
  snapshotarbeidslisteTiltakLister = LeggTilObjekter.arbeidslisteTiltakLister;
};

export const updateLockReasons = (reasons) => {
  LeggTilObjekter.lockReasons = reasons;
};

export const setArbeidslisteTiltakListe = (type, liste) => {
 debug('TTL context', type);
 debug('TTL context', liste);
  let Tiltaklister = LeggTilObjekter.arbeidslisteTiltakLister;

  switch (type) {
    case 'tiltakTypeBruksenhetListe':
      Tiltaklister.tiltakTypeBruksenhetListe = liste;
      break;
    case 'tiltakTypeRoyklopListe':
      Tiltaklister.tiltakTypeRoyklopListe = liste;
      break;
    default:
      break;
  }
  LeggTilObjekter.arbeidslisteTiltakLister = Tiltaklister;
};

export const getObjektLockReasons = (item) => {
  const key = `${parseInt(item.bruksenhetId)}-${parseInt(item.royklopId)}`;
  let reason = LeggTilObjekter.lockReasons[key] || false;
  debug('fredagLR item', reason);
  return reason;
};

export const contextPlanleggingValgtPerson = React.createContext(valgtPerson);
export const contextPlanleggingLeggTilObjekter = React.createContext(
  LeggTilObjekter
);
export const contextOppslagTiltakstyper = React.createContext(
  contextTiltakstyper
);
export const contextPlanleggingSnapshot = React.createContext(
  snapshotarbeidslisteTiltakLister
);

export const contextPlanlegging = React.createContext(valgtPerson);
