import React, { useState, useEffect } from 'react';
import Spinner from 'components/generics/Spinner';
import NKIcon from 'nka-icons';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import BFButton from 'components/BFButtonGroup/BFButton.js';
import OKDialog from 'components/Dialogs/OkDialog.jsx';

const UnderBehandlingAvvik = React.forwardRef(({ ...props }, ref) => {
	const [xFrist, setXFrist] = useState(null);
	const [modalFristOpen, setModalFristOpen] = useState(false);
	const fristDager = 21;

	useEffect(() => {
		console.log('AXX 2', props);
		setXFrist(props.avvik.avvik.frist);
	}, [props.avvik]);

  const escapeHtml = (text) => {
    if (!text) {
      return '';
    }
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
    };
    return text.replace(/[&<>"']/g, (m) => map[m]);
  };

	const getIkon = () => {
		let ic = '';
		switch (props.avvik.avvik.objektReferanseType) {
			case 'B':
				ic = 'hjem';
				break;
			case 'I':
				ic = 'ildsted';
				break;
			case 'R':
				ic = 'royklop';
				break;
			default:
				ic = 'minus';
		}
		return ic;
	};

	const getAvvikType = () => {
		let t = props.avvik.avvik.avvikTypeId;
		let liste = props.avvik.avvikOppslagdata.avvikTypeListe;
		let a = liste.find((obj) => obj.id === t);
		if (a) {
			return a.tekst;
		} else {
			return '';
		}
	};

	const getTiltakType = () => {
		let t = props.avvik.avvik.tiltakTypeId;
		let liste = props.avvik.avvikOppslagdata.tiltakTypeListe;
		let a = liste.find((obj) => obj.id === t);
		if (a) {
			return a.tekst;
		} else {
			return '';
		}
	};

	const getParagrafer = () => {
		let par = props.avvik.avvik.paragrafRegistrert;
		if (par.length) {
			return (
				<ul className='liste'>
					{par.map((p) => (
						<li key={'li' + p.paragrafId}>{p.paragraf}</li>
					))}
				</ul>
			);
		} else {
			return <div style={{ color: 'gray' }}>ingen</div>;
		}
	};

	const getArsakLukket = () => {
		let ar = props.avvik.avvik.lukketArsakId;
		if (!ar) {
			return '';
		}
		let liste = props.avvik.avvikOppslagdata.lukketArsakListe;
		let a = liste.find((obj) => obj.id === ar);
		if (a) {
			return a.tekst;
		} else {
			return '';
		}
	};

	const fristChange = (val) => {
		if (val <= new Date() || val <= props.avvik.avvik.dato) {
			setModalFristOpen(true);
		} else {
			let frist = moment(val).format('YYYY-MM-DDTHH:00:00');
			setXFrist(frist);
		}
	};

	const arsakChange = (e) => {
		let arsakId = e.target.value;
		console.log('AVX arsakId', arsakId);
	};

	const lagreAvvik = () => {
		props.endreAvvik(xFrist);
	};

	return (
		<div style={{ margin: '4px', paddingLeft: '12px', paddingTop: '4px' }}>
			{!props.avvik ? (
				<>
					<Spinner className='h4' />
				</>
			) : (
				<>
					<table>
						<tbody>
							<tr style={{ height: '30px' }}>
								<td>
									{' '}
									<NKIcon
										marginLeft='2px'
										className='pull-left'
										icon={getIkon()}
										color='nk-black'
										size='1'
									/>
								</td>
								<td style={{ width: '10px' }}></td>
								<td colSpan={4}>
									<label style={{ marginTop: '15px' }}>{getAvvikType()}</label>
								</td>
							</tr>
							<tr style={{ height: '10px' }}></tr>
							<tr>
								<td></td>
								<td></td>
								<td style={{ width: '160px' }}>
									<label>Dato funnet</label>
								</td>
								<td style={{ width: '10px' }}></td>
								<td style={{ width: '160px' }}>
									<label
										style={
											moment(props.avvik.avvik.frist) < moment()
												? { color: 'red' }
												: { color: 'black' }
										}>
										Frist tilbakemelding
									</label>
								</td>
								<td></td>
							</tr>
							<tr>
								<td></td>
								<td></td>
								<td style={{ width: '160px' }}>
									{' '}
									{moment(props.avvik.avvik.dato).format('DD.MM.YYYY')}
								</td>
								<td style={{ width: '10px' }}></td>
								<td style={{ width: '160px' }}>
									<DatePicker
										className='width120Heigth32'
										id='avvfrist'
										selected={moment(xFrist)}
										startDate={moment()}
										placeholderText='Velg fristdato..'
										todayButton={'I dag'}
										onChange={fristChange}
										isClearable={false}
										showYearDropdown
										readOnly={true}
										setMinDate={moment()}
									/>
								</td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<br />

					{/* Saksbehandler */}
					<div className='row' style={{ marginLeft: '30px' }}>
						<div className='form-group '>
							<label>Saksbehandler</label>
							<div>{props.avvik.avvik.saksbehandler}</div>
						</div>
					</div>

					{/* TiltakstypeType */}
					<div className='row' style={{ marginLeft: '30px' }}>
						<div className='form-group '>
							<label>Tiltak</label>
							<div>{getTiltakType()}</div>
						</div>
					</div>

					{/* Paragrafer */}
					<div className='row' style={{ marginLeft: '30px' }}>
						<div className='form-group '>
							<label>Paragrafer</label>
							<div>{getParagrafer()}</div>
						</div>
					</div>

					{/* Årsak lukket -- vises kun dersom lukket */}
					{props.avvik.avvik.lukket ? (
						<div className='row' style={{ marginLeft: '30px' }}>
							<div className='form-group '>
								<label>Årsak lukket</label>
								<div>{getArsakLukket()}</div>
							</div>
						</div>
					) : null}

					{/* Årsak lukket */}
					{/*               {!props.avvik.avvik.lukket ? (
                <div>
                  <div className='row' style={{ marginLeft: '30px' }}>
                    <div className='form-group '>
                      <label>Årsak lukket</label>
                      <select
                        className='form-control'
                        id='type'
                        onChange={arsakChange}
                        defaultValue={props.avvik.avvikOppslagdata.lukketArsakListe[0]}>
                        {_.map(props.avvik.avvikOppslagdata.lukketArsakListe, function(o, i) {
                          return (
                            <option key={i} value={o.id}>
                              {o.tekst}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              ) : null} */}

					{/* Bemerkning */}
					<div className='row ' style={{ marginLeft: '30px' }}>
						<div className='form-group'>
							<label className='margtop20'>Beskrivelse</label>

							<br />
							<div
								style={{
									padding: '4px',
									border: '1px solid lightgray',
									wordWrap: 'normal',
									width: '500px',
									minHeight: '30px',
								}}
								dangerouslySetInnerHTML={{
									__html: props.avvik.avvik.beskrivelse,
								}}></div>
						</div>

						{/* Skal lukkes? */}
						{props.avvik.avvik.publiserInnbyggerAvvikLukket && (
							<div className='row' style={{ marginLeft: '10px' }}>
								<div className='form-group '>
									<span>→ Skal lukkes</span>
								</div>
							</div>
						)}

						{/* Publiseres? */}
						{props.avvik.avvik.publiserInnbyggerDialogKlar && (
							<div className='row' style={{ marginLeft: '10px' }}>
								<div className='form-group '>
									<span>→ Klar til publisering</span>
								</div>
							</div>
						)}

						{/*  Lagre og Slett   */}
						<BFButton
							disabled={props.avvik.avvik.frist === xFrist}
							buttonLabel={['Lagre avvik']}
							farget={true}
							buttonWidth={'180px'}
							afterClick={lagreAvvik}></BFButton>
					</div>
				</>
			)}

			{/*   ************************** Dialogs *************************** */}
			<OKDialog
				tittel={'Fristdato'}
				melding={'Fristdato må komme etter i dag og etter "Dato funnet". '}
				isOpen={modalFristOpen}
				onClose={() => setModalFristOpen(false)}
				onOk={() => setModalFristOpen(false)}
			/>
		</div>
	);
});

export default UnderBehandlingAvvik;
