import React, { useState, useEffect } from 'react';
import ReactTable from '@norkart/react-table';
//import { getOrganisasjonSvarUt } from 'api/organisasjonApi';
import { getOrgSvarUt } from '../../context/contextInnstillinger';
function VelgKontakter(props) {
  const [kontakter, setKontakter] = useState([]);
  const [valgteKontakter, setValgteKontakter] = useState([]);
  const [medTelefon, setMedTelefon] = useState([]);
  const [utenTelefon, setUtenTelefon] = useState([]);
  const [adresse, setAdresse] = useState('');
  const [svarUt, setSvarUt] = useState(false);
  const [valgteEiereMed, setValgteEiereMed] = useState([]);
  const [valgteEiereUten, setValgteEiereUten] = useState([]);

  useEffect(() => {
    console.log('XXXZ', props.kontakter);
    setKontakter(props.kontakter);
    setValgteKontakter(props.kontakter);
    checkKontakterForTelefon(props.kontakter);
    getOrgSvarUt(gotSvarUt);
  }, []);

  const checkKontakterForTelefon = (eierk) => {
    let kontakterUtenTlf = [];
    let kontakterMedTlf = [];
    if (eierk !== null && eierk.length > 0) { 
      setAdresse(eierk[0].bruksenhetAdresse);
      eierk.forEach((ek) => {
        let kontakter = ek.kontakt;
        kontakter.forEach((ko) => {
          if (ko.telefon == null || ko.ugyldigTelefonNr) {
            ko.smsUtelat = true;
          }
          if (ko.ugyldigTelefonNr) {
            console.log('1');
            kontakterUtenTlf.push(ko);
          } else {
            console.log('2');
            kontakterMedTlf.push(ko);
          }
        });
      });
    }
    setMedTelefon(kontakterMedTlf);
    setUtenTelefon(kontakterUtenTlf);
    setValgteEiereMed(kontakterMedTlf);
    setValgteEiereUten(kontakterUtenTlf);
    console.log(kontakterMedTlf, kontakterUtenTlf);
  };

  const gotSvarUt = (res) => {
    setSvarUt(res.data);
  };

  const onDoClose = () => {
    props.onClose(null);
  };

/*   const testGetKontakter = () => {
    return (
      <div style={{ margin: '20px' }}>
        {kontakter.map((k, index) => {
          return (
            <>
              <label>{k.bruksenhetAdresse}</label>
              <br />
              {k.kontakt.map((ko, ix) => {
                return (
                  <div>
                    <span>{ko.navn}</span>   
                    <br />
                  </div>
                );
              })}
              
            </>
          );
        })}
        <br />
      </div>
    );
  }; */

  const eiereMedRowSelected = (rows) => {
    console.log(rows);
    setValgteEiereMed(rows);
  };

  const eiereUtenRowSelected = (rows) => {
    console.log(rows);
    setValgteEiereUten(rows);
  };

  const onOk = () => {
    console.log(valgteEiereMed);
    console.log(valgteEiereUten);
    let med = valgteEiereMed;
    let uten = valgteEiereUten;
    let bk = kontakter;

    // Set alle sms til smsutelat
    bk.forEach((element) => {
      element.kontakt.forEach((e) => {
        if (!e.ugyldigTelefonNr) {
          e.smsUtelat = true;
          e.svarUtValgt = false;
        }
      });
    });

    // Finn de som er valg og sett at sms/svarut skal sendes
    med.forEach((element) => {
      bk.forEach((b) => {
        let kontakt = b.kontakt;
        kontakt.forEach((k) => {
          console.log(k.id);
          console.log(element.id);
          if (k.id === element.id) {
            k.smsUtelat = false;
          }
        });
      });
    });
    uten.forEach((element) => {
      bk.forEach((b) => {
        let kontakt = b.kontakt;
        kontakt.forEach((k) => {
          if (k.id === element.id) {
            if (svarUt) {
              k.svarUtValgt = true;
            } else {
              k.svarUtValgt = false;
            }
          }
        });
      });
    });
    console.log(bk);
    props.onClose(bk);
  };

  const getEiereMedColumns = () => {
    return [
      {
        id: 'adresse',
        name: 'Send til',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'navn',
        name: 'Eier',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'telefon',
        name: 'Telefonnummer',
        isSorted: false,
        sortable: false,
      },
    ];
  };

  const getEiereUtenColumns = () => {
    return [
      {
        id: 'adresse',
        name: 'Send til Svarut',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'navn',
        name: 'Eier',
        isSorted: false,
        sortable: false,
      },
    ];
  };
  const getEiereUtenColumnsOgSvarut = () => {
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'navn',
        name: 'Eier',
        isSorted: false,
        sortable: false,
      },
    ];
  };


  return (
    
    <div
      style={{
        margin: '4px',
        padding: '10px',
      }}>
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={onDoClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Velg kontakt </h4>
        </div>
        <div className='modal-body'>
          {/* {kontakter === [] ? (
            <span style={{ margin: '20px' }}>Vent...</span>
          ) : (
            testGetKontakter()
          )} */}
          <h3>{adresse}</h3>
              Tilbakemelding om tilsynet blir publisert på Min Eiendom.
              {medTelefon != null &&
              medTelefon.length > 0 ? (
                <div>
                  Informasjon om tilsyn sendes med SMS til følgende eiere:
                  <div
                    style={{
                      border: '1px solid black',
                      paddingRight: '10px',
                    }}
                  >
                    
                    <ReactTable
                      showIndex={false}
                      items={medTelefon}
                      columns={getEiereMedColumns()}
                      selectableRows={true}
                      onRowClick={null}
                      onRowSelectionChange={eiereMedRowSelected}
                      toggleAll={true}
                      toggleAllDefault={true}
                      sortable={false}
                      multiSort={false}
                      filterable={false}
                    />
                  </div>
                </div>
              ) : null}

              {utenTelefon != null &&
                    utenTelefon.length > 0 ? (
                <div>
                  {svarUt
                    ? 'Telefonnummer ble ikke funnet for følgende eiere. Velg om informasjon skal sendes til SvarUt'
                    : 'Telefonnummer ble ikke funnet for følgende eiere'}
                  {svarUt ? (
                    <div>
                      <div
                        style={{
                          border: '1px solid black',
                          paddingRight: '10px',
                        }}
                      >
                        
                        <ReactTable
                          showIndex={false}
                          items={utenTelefon}
                          columns={getEiereUtenColumns()}
                          selectableRows={true}
                          onRowClick={null}
                          onRowSelectionChange={eiereUtenRowSelected}
                          toggleAll={true}
                          toggleAllDefault={true}
                          sortable={false}
                          multiSort={false}
                          filterable={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          border: '1px solid black',
                          paddingRight: '10px',
                        }}
                      >
                        <ReactTable
                          showIndex={false}
                          items={utenTelefon}
                          columns={getEiereUtenColumnsOgSvarut()}
                          selectableRows={false}
                          onRowClick={null}
                          toggleAll={true}
                          toggleAllDefault={true}
                          sortable={false}
                          multiSort={false}
                          filterable={false}
                        />
                      </div>
                    </div>
                  )}
              
                </div>
              ) : null }
              
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={onOk}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
                      
}

export default VelgKontakter;
