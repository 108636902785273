import ReactSelect from 'react-select';
import React from 'react';
import NKIcon from 'nka-icons';
import 'react-select/dist/react-select.css';
import _ from 'underscore';
import enhetApi from 'api/enhetApi.js';
import { debugFactory } from 'utils';

var tiltakTypeRoyklopListe;
var royklopliste;
var nyTiltakEnhetListe = [];
var nyTiltakRoyklopListe = [];
var endredeRoyklopListe = [];

const debug = debugFactory('nk:EndreTiltak');

class endreTiltak extends React.Component {
  state = {
    selectTiltak: null,
    enhet: this.props.enhet,
    situasjonMedTiltak: this.props.situasjonMedTiltak,
    tiltakTypeBruksenhetListe: this.props.situasjonMedTiltak
      .tiltakTypeBruksenhetListe,
    tiltakTypeRoyklopListe: this.props.situasjonMedTiltak
      .tiltakTypeRoyklopListe,
  };

  componentDidMount() {
    nyTiltakRoyklopListe = [];
    var valgte = [];
    if (this.props.situasjonMedTiltak.tiltakListe !== null) {
      for (
        let i = 0;
        i < this.props.situasjonMedTiltak.tiltakListe.length;
        i++
      ) {
        const tiltak = this.props.situasjonMedTiltak.tiltakListe[i];
        if (tiltak) {
          let x = {
            id: tiltak.tiltakTypeId,
            tekst: tiltak.tiltakTypetekst,
          };
          valgte.push(x);
        }
      }
    }
    tiltakTypeRoyklopListe = this.props.situasjonMedTiltak
      .tiltakTypeRoyklopListe;
    this.setState({ selectTiltak: valgte });
  }

  /*   componentDidMountOld() {
    nyTiltakRoyklopListe = [];
    var valgte = [];
    if (this.props.situasjonMedTiltak.tiltakListe !== null) {
      this.props.situasjonMedTiltak.tiltakListe.forEach(function(tiltak) {
        var x = {
          id: tiltak.tiltakTypeId,
          tekst: tiltak.tiltakTypetekst
        };
        valgte.push(x);
      });
    }
  
    tiltakTypeRoyklopListe = this.props.situasjonMedTiltak
      .tiltakTypeRoyklopListe;
    this.setState({ selectTiltak: valgte });
  } */

  onClickOk = () => {
    this.checkTiltakEnhet();
    this.checkTiltakRoyklopOgLagre();
  };

  handleTiltak = (options) => {
    this.setState({
      selectTiltak: options,
    });
  };

  checkTiltakEnhet = () => {
    // sjekker tiltak på enhet, sette true false på slett/ny
    var opprtiltak = this.state.situasjonMedTiltak.tiltakListe;
    var valgteBhTiltak = this.state.selectTiltak;

    // Sjekk om noen er fjernet
    if (valgteBhTiltak === null || valgteBhTiltak.length === 0) {
      opprtiltak.forEach(function(oppr) {
        oppr.slett = true;
      });
    } else {
      for (var i = 0; i < opprtiltak.length; i++) {
        if (!this.sjekkInclude(valgteBhTiltak, opprtiltak[i])) {
          opprtiltak[i].slett = true;
        }
      }
    }
    nyTiltakEnhetListe = opprtiltak;
    // Sjekk nye
    if (valgteBhTiltak !== null && valgteBhTiltak.length !== 0) {
      for (var x = 0; x < valgteBhTiltak.length; x++) {
        if (!this.sjekkInclude2(opprtiltak, valgteBhTiltak[x])) {
          var tiltak = {
            arbeidslisteId: this.props.arbeidslisteId,
            bruksenhetId: this.props.enhet.id,
            id: 0,
            ny: true,
            slett: false,
            tiltakTypeId: valgteBhTiltak[x].id,
            tiltakTypetekst: valgteBhTiltak[x].tekst,
          };
          nyTiltakEnhetListe.push(tiltak);
        }
      }
    }
  };

  sjekkInclude = (arr, obj) => {
    if (arr !== null) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === obj.tiltakTypeId) {
          return true;
        }
      }
    }
    return false;
  };

  sjekkInclude2 = (arr, obj) => {
    if (arr !== null) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].tiltakTypeId === obj.id) {
          return true;
        }
      }
    }
    return false;
  };

  royklopEndret = (tiltakliste, royklop) => {
    // legger inn i liste hver gang et tiltak endres på et royklop
    var tmpRoyklop = royklop;
    tmpRoyklop.tiltakListe = tiltakliste;
    var index = -1;
    for (var i = 0, len = endredeRoyklopListe.length; i < len; i++) {
      if (endredeRoyklopListe[i].id === tmpRoyklop.id) {
        index = i;
        break;
      }
    }
    if (index === -1) {
      endredeRoyklopListe.push(tmpRoyklop);
    } else {
      endredeRoyklopListe[index] = tmpRoyklop;
    }
  };

  checkTiltakRoyklopOgLagre = () => {
    enhetApi.getBruksenhetSituasjonTiltakById(
      this.props.enhet.id,
      this.props.arbeidslisteId,
      this.checkTiltakRoyklopOrgListe
    );
  };

  checkTiltakRoyklopOrgListe = (err, situasjon) => {
    royklopliste = situasjon.royklopListe;
    //var royklopListe = this.state.situasjonMedTiltak.royklopListe;
    // Gå igjennom alle royklop
    for (var i = 0; i < royklopliste.length; i++) {
      var index = endredeRoyklopListe
        .map((o) => o.id)
        .indexOf(royklopliste[i].id);
      if (index !== -1) {
        // Røykløpet er endret
        this.handleRoyklopTiltak(
          royklopliste[i].tiltakListe,
          endredeRoyklopListe[index].tiltakListe,
          royklopliste[i].id
        );
      }
      //nyTiltakRoyklopListe = royklopliste[i].tiltakListe;
      var liste = royklopliste[i].tiltakListe;
      if (liste !== null && liste !== undefined) {
        for (var t = 0; t < liste.length; t++) {
          nyTiltakRoyklopListe.push(liste[t]);
        }
      }
    }

    var nyeLister = {
      tiltakTypeBruksenhetListe: nyTiltakEnhetListe,
      tiltakTypeRoyklopListe: nyTiltakRoyklopListe,
    };
    debug('Nye nyeLister', nyeLister);
    this.props.lagreTiltak(nyeLister);
  };

  handleRoyklopTiltak = (org, ny, royklopId) => {
    // Sjekk om noen er fjernet
    if (ny === null || ny.length === 0) {
      if (org !== null) {
        org.forEach(function(oppr) {
          oppr.slett = true;
          nyTiltakRoyklopListe.push(oppr);
        });
      }
    } else {
      if (org !== null) {
        for (var i = 0; i < org.length; i++) {
          if (!this.sjekkInclude(ny, org[i])) {
            org[i].slett = true;
            nyTiltakRoyklopListe.push(org[i]);
          }
        }
      }
    }
    //nyTiltakRoyklopListe = org;
    // Sjekk nye
    if (ny !== null && ny.length !== 0) {
      for (var k = 0; k < ny.length; k++) {
        if (!this.sjekkInclude2(org, ny[k])) {
          var tiltak = {
            arbeidslisteId: this.props.arbeidslisteId,
            royklopId: royklopId,
            id: 0,
            ny: true,
            slett: false,
            tiltakTypeId: ny[k].id,
            tiltakTypetekst: ny[k].tekst,
          };
          nyTiltakRoyklopListe.push(tiltak);
        }
      }
    }
  };

  render() {
    if (!this.state.situasjonMedTiltak) {
      return <div>Laster...</div>;
    }

    return (
      <div className='modal-content'>
        <div className='modal-header'>
          <button className='close' onClick={this.props.onClose} type='button'>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>
            Endre tiltak på objekter - {this.state.enhet.adresse}{' '}
          </h4>
        </div>

        <div className='modal-body'>
          <div
            className='row'
            style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className='form-group '>
              <label>Bruksenhet </label>
              <br />
              <ReactSelect
                style={{ borderRadius: '0' }}
                multi
                id='id'
                valueKey='id'
                labelKey='tekst'
                name='Bruksenhet'
                value={this.state.selectTiltak}
                onChange={this.handleTiltak}
                options={this.state.tiltakTypeBruksenhetListe}
                placeholder='Velg tiltak'
              />
            </div>
          </div>
          <div>
            {' '}
            {/* mapp inn */}
            {_.map(
              this.state.situasjonMedTiltak.royklopListe,
              function(royklop) {
                return (
                  <Royklop
                    royklop={royklop}
                    tiltakTypeListe={tiltakTypeRoyklopListe}
                    key={royklop.id}
                    OppdaterRoyklopTiltak={this.royklopEndret}
                  />
                );
              }.bind(this)
            )}
          </div>{' '}
          {/* mapp ut */}
          <br />
          <table>
            <tbody>
              <tr>
                <td style={{ width: '50px' }}>
                  <span>
                    <NKIcon icon='info' />
                  </span>
                </td>
                <td>
                  Hvis alle tiltak fjernes, så vil bruksenheten slettes fra
                  arbeidslisten.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='modal-footer'>
          <button
            className='btn btn-primary pull-right'
            onClick={this.onClickOk}>
            OK
          </button>
        </div>
      </div>
    );
  }
}

class Royklop extends React.Component {
  state = {
    selectTiltak: this.props.royklop.tiltakliste,
  };

  componentDidMount() {
    var valgte = [];
    if (this.props.royklop.tiltakListe !== null) {
      for (let i = 0; i < this.props.royklop.tiltakListe.length; i++) {
        const tiltak = this.props.royklop.tiltakListe[i];
        if (tiltak){
          var x = {
            id: tiltak.tiltakTypeId,
            tekst: tiltak.tiltakTypetekst,
          };
          valgte.push(x);
        }
      }
      this.setState({ selectTiltak: valgte });
    }
  }

/*   componentDidMountOld() {
    var valgte = [];
    if (this.props.royklop.tiltakListe !== null) {
      this.props.royklop.tiltakListe.forEach(function(tiltak) {
        var x = {
          id: tiltak.tiltakTypeId,
          tekst: tiltak.tiltakTypetekst,
        };
        valgte.push(x);
      });
    }
    this.setState({ selectTiltak: valgte });
  } */

  handleTiltak = (options) => {
    this.setState({
      selectTiltak: options,
    });
    this.props.OppdaterRoyklopTiltak(options, this.props.royklop);
  };

  makeIdent = () => {
    let modell = this.checkElement(this.props.royklop.modell);
    let plassering = this.checkElement(this.props.royklop.plassering);
    let dimensjon = this.checkElement(this.props.royklop.dimensjon);
    return 'Røykløp' + modell + plassering + dimensjon;
  };

  checkElement = (el) => {
    if (el === null || el === '' || el === undefined) {
      return '';
    } else {
      return ' - ' + el;
    }
  };

  render() {
    let ident = this.makeIdent();
    return (
      <div key={this.props.royklop.id}>
        <hr style={{ margin: '0px' }} />
        <div
          className='row'
          style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <div className='form-group '>
            <label>{ident}</label>
            <br />
            <ReactSelect
              style={{ borderRadius: '0' }}
              multi
              id='id'
              valueKey='id'
              labelKey='tekst'
              name='Bruksenhet'
              value={this.state.selectTiltak}
              onChange={this.handleTiltak}
              options={this.props.tiltakTypeListe}
              placeholder='Velg tiltak'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default endreTiltak;
