import React, { Component } from 'react';
import L from 'leaflet';
import Control from 'react-leaflet-control';
import { TileLayer } from 'react-leaflet';
import NKIcon from 'nka-icons';
import { debugFactory } from 'utils';
import { setWmsLayer } from './WmsUtils';
import $ from 'jquery';
import * as _ from 'lodash';
import WmsControl from 'components/Kart/Controls/WmsControl.jsx';
import RisikoControl from 'components/Kart/Controls/RisikoControl.jsx';
import risikoRed from 'images/risiko/markerred.png';
import risikoYellow from 'images/risiko/markeryellow.png';
import risikoBlue from 'images/risiko/markerblue.png';
import risikoGreen from 'images/risiko/markergreen.png';
import risikoRedOverstyrt from 'images/risiko/markerredOverstyrt.png';
import risikoYellowOverstyrt from 'images/risiko/markeryellowOverstyrt.png';
import risikoBlueOverstyrt from 'images/risiko/markerblueOverstyrt.png';
import risikoGreenOverstyrt from 'images/risiko/markergreenOverstyrt.png';
import risikoRedOverstyrtPlus from 'images/risiko/markerredOverstyrtPluss.png';
import risikoYellowOverstyrtPlus from 'images/risiko/markeryellowOverstyrtPluss.png';
import risikoBlueOverstyrtPlus from 'images/risiko/markerblueOverstyrtPluss.png';
import risikoGreenOverstyrtPlus from 'images/risiko/markergreenOverstyrtPluss.png';
import risikoRedPluss from 'images/risiko/markerredpluss.png';
import risikoYellowPluss from 'images/risiko/markeryellowpluss.png';
import risikoBluePluss from 'images/risiko/markerbluepluss.png';
import risikoGreenPluss from 'images/risiko/markergreenpluss.png';
import {
  getContextInnstillingerKartNew,
  getOrgRisiko,
} from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:kart:planleggingskart');
const tdStyle = { width: '40px', textAlign: 'center' };

let visWmsLayer;
class LayerControl extends Component {
  state = {
    vektorClass: 'btn btn-primary w80',
    hybridClass: 'btn btn-default w80',
    fotoClass: 'btn btn-default w80',
    hideLayerComponent: 'hide',
    vektor: true,
    foto: false,
    hybrid: false,
    modus: 'Enheter',
    hideWmsPanel: 'hide',
    hideRisikoPanel: 'hide',
    wmsListe: [],
    visWmsLegend: false,
    visRisikoLegend: false,
    visWmsFInfo: false,
    wmsFInfoObjectListe: [],
    wmsFInfoObject: [],
    valgtObjectSide: 0,
    showBlaButtons: '',
    wmsTeller: 11,
    legendHasImages: false,
    bbox: '',
    harRisiko: false,
    visRisikoInfoPanel: false
  };

  componentDidMount = () => {
    getContextInnstillingerKartNew(this.gotKart);
    this.setState({ modus: this.props.modus });
    if (this.props.visning === 'wmsoversikt') {
      this.setVisWms();
    }

    getOrgRisiko(this.gotRisiko);
  };

  gotRisiko = (res) => {
    if (res) {
      this.setState({ harRisiko: res.data });
    }
  };

  gotKart = (data) => {
    this.setState({ bbox: data.bbox });
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({ modus: nextProps.modus });
  };

  handleLayerComponentHide = () => {
    this.setState({ hideWmsPanel: 'hide' });
    this.setState({ hideRisikoPanel: 'hide' });
    var hide = this.state.hideLayerComponent;
    if (hide === 'hide') {
      this.setState({ hideLayerComponent: ' ' });
    } else {
      this.setState({ hideLayerComponent: 'hide' });
    }
  };

  handleWmsPanelHide = () => {
    var hide = this.state.hideWmsPanel;
    if (hide === 'hide') {
      this.setState({ hideWmsPanel: ' ' });
    } else {
      this.setState({ hideWmsPanel: 'hide' });
    }
    this.setState({ hideLayerComponent: 'hide' });
  };

  handleRisikoPanelHide = () => {
    var hide = this.state.hideRisikoPanel;
    if (hide === 'hide') {
      this.setState({ hideRisikoPanel: ' ' });
    } else {
      this.setState({ hideRisikoPanel: 'hide' });
    }
    this.setState({ hideLayerComponent: 'hide' });
  };

  handleBaseLayer = (e) => {
    var lay = e.target.value;
    this.setState({ vektorClass: 'btn btn-default w80' });
    this.setState({ hybridClass: 'btn btn-default w80' });
    this.setState({ fotoClass: 'btn btn-default w80' });

    switch (lay) {
      case 'vektor':
        this.setState({ vektorClass: 'btn btn-primary w80' });
        break;
      case 'hybrid':
        this.setState({ hybridClass: 'btn btn-primary w80' });
        break;
      case 'foto':
        this.setState({ fotoClass: 'btn btn-primary w80' });
        break;
      default:
        this.setState({ vektorClass: 'btn btn-primary w80' });
    }

    this.setState({ vektor: lay === 'vektor' });
    this.setState({ foto: lay === 'foto' });
    this.setState({ hybrid: lay === 'hybrid' });
  };

  gotClick = (e) => {
    this.setState({ wmsFInfoObjectListe: [] });
    this.setState({ wmsFInfoObject: [] });
    this.setState({ valgtObjectSide: 0 });
    this.setState({ visWmsFInfo: false });

    let wmsParamsListe = [];
    this.props.kart.eachLayer(function(layer) {
      if (layer.wmsParams) {
        let wmsParams = layer.wmsParams;
        if (wmsParams.service === 'WMS') {
          wmsParamsListe.push(wmsParams);
        }
      }
    });
    /*  e.target.eachLayer(function(layer) {
      if (layer.wmsParams) {
        let wmsParams = layer.wmsParams;
        if (wmsParams.service === 'WMS') {
          wmsParamsListe.push(wmsParams);
        }
      }
    }); */
    for (let i = 0; i < wmsParamsListe.length; i++) {
      let params = wmsParamsListe[i];
      this.getWmsInfo(e, params);
    }
  };

  getBasisUrl = (layername) => {
    for (let i = 0; i < this.state.wmsListe.length; i++) {
      let wms = this.state.wmsListe[i];
      if (wms.layername === layername) {
        return wms.groupurl;
      }
    }
    return null;
  };

  getWmsTittel = (layername) => {
    for (let i = 0; i < this.state.wmsListe.length; i++) {
      let wms = this.state.wmsListe[i];
      if (wms.layername === layername) {
        return wms.layerdescription;
      }
    }
    return null;
  };

  getWmsInfo = (e, wmsParams) => {
    let basisUrl = this.getBasisUrl(wmsParams.layers);
    let lay = wmsParams.layers;
    let version = wmsParams.version;
    let latlng = e.latlng;
    let lat1 = latlng.lng - 0.0002;
    let lat2 = latlng.lng + 0.0002;
    let lng1 = latlng.lat - 0.0001;
    let lng2 = latlng.lat + 0.0001;
    let bbox =
      lat1.toString() +
      ',' +
      lng1.toString() +
      ',' +
      lat2.toString() +
      ',' +
      lng2.toString();
    let s =
      basisUrl +
      '&service=WMS&version=' +
      version +
      '&request=GetFeatureInfo&layers=' +
      lay +
      '&query_layers=' +
      lay +
      '&feature_count=10&info_format=application/json' +
      '&srs=EPSG%3A4326&width=101&height=101&x=50&y=50' +
      '&bbox=' +
      bbox;

    s = s.replace('http://', 'https://');
    $.getJSON(s, this.wmsResult);
  };

  wmsResult = (info) => {
    let features = info.features;
    if (features.length > 0) {
      for (let i = 0; i < features.length; i++) {
        let prop = features[i].properties;
        var id = features[i].id.split('.')[0];
        let tittel = this.getWmsTittel(id);

        let liste = [];
        for (var key in prop) {
          var value = prop[key];
          liste.push({ key: key, value: value });
        }
        liste = _.sortBy(liste, 'key');
        liste.unshift({ key: 'xx_tittel', value: tittel });

        let nyObjectListe = this.state.wmsFInfoObjectListe;
        nyObjectListe.push(liste);
        this.setState({ wmsFInfoObjectListe: nyObjectListe });

        if (nyObjectListe.length > 0) {
          this.setState({ wmsFInfoObject: nyObjectListe[0] });
          this.setState({ visWmsFInfo: true });
        } else {
          this.setState({ visWmsFInfo: false });
        }
        if (nyObjectListe.length > 1) {
          this.setState({ showBlaButtons: '' });
        } else {
          this.setState({ showBlaButtons: 'hide' });
        }
      }
    } else {
      debug('wms wmsControl INGEN RESULT', info);
    }
  };

  visNesteInfo = () => {
    let nr = this.state.valgtObjectSide + 1;
    if (this.state.wmsFInfoObjectListe.length > nr) {
      this.setState({ valgtObjectSide: nr });
      this.setState({ wmsFInfoObject: this.state.wmsFInfoObjectListe[nr] });
    }
  };

  visForrigeInfo = () => {
    let nr = this.state.valgtObjectSide;
    if (nr > 0) {
      this.setState({ valgtObjectSide: nr - 1 });
      this.setState({ wmsFInfoObject: this.state.wmsFInfoObjectListe[nr - 1] });
    }
  };

  wmsLegendKanVises = (liste) => {
    for (let i = 0; i < liste.length; i++) {
      let wms = liste[i];
      if (wms.imageurl.includes('gislinexxx.no')) {
        return true;
      }
    }
    return false;
  };

  setWmsListe = (wmsListe) => {
    this.setState({ wmsListe: wmsListe });

    this.setState({ visWmsLegend: wmsListe.length > 0 });

    //Features
    this.setState({ wmsFInfoObject: [] });
    this.setState({ wmsFInfoObjectListe: [] });
    this.setState({ visWmsFInfo: false });
  };

  setVisSkjulLegend = () => {
    //Ikke vis i det hele tatt dersom ingen av wms'ene kan vise legend
    // if (this.wmsLegendKanVises()) {
    this.setState({ visWmsLegend: !this.state.visWmsLegend });
    // }
  };

  skjulLegendRisiko = () => {
    this.setState({ visRisikoLegend: false });
  };

  skjulFInfo = () => {
    this.setState({ visWmsFInfo: false });
  };

   skjulFInfoRisiko = () => {
    this.setState({ visRisikoInfoPanel: false });
  }; 

visRisikoInfoPanelLayer = (object) => {
    this.setState({ visRisikoInfoPanel: true });
    this.setState({ visRisikoLegend: false });
  }; 

  //////////////////////////////////////////////////////////
  setVisWms = () => {
    if (this.props.visning === 'wms') {
      let wms = this.props.wms;
      visWmsLayer = new L.FeatureGroup();
      this.props.kart.addLayer(visWmsLayer);
      visWmsLayer = setWmsLayer(visWmsLayer, wms, false);
    }
    this.setState({ hideWmsPanel: '' });
  };

  getImageSourceOld = (wms) => {
    if (wms.imageurl.includes('gislinexxx.no')) {
      return 'ingen';
    } else {
      let legendString =
        '/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=';
      let s = wms.imageurl + legendString + wms.layername;
      return s;
    }
  };

  //http://waapiold.webatlas.no/wms-komtek/?apitoken=8bd595eb-65d1-461a-b033-89495cd8dec5&service=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=DatakvalitetRisikoFeiing
  //https://postgis.verdal.kommune.no/geoserver/historie/wmss&service=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=Verdal-Miilekart1785-Skjækerfossen-Vera-Sandvika

  getImageSource = (wms) => {
    let groupUrl = wms.groupurl;
    var n = groupUrl.lastIndexOf('?');
    let apitoken = groupUrl.substr(n);
    if (wms.imageurl.includes('gisline.no')) {
      let sk = this.makeImageSourceKommuner(wms);
      return sk;
    } else {
      let legendString =
        '&service=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=';
      let s = wms.imageurl + apitoken + legendString + wms.layername;
      return s;
    }
  };

  makeImageSourceKommuner = (wms) => {
    let s =
      wms.imageurl +
      '?REQUEST=GetLegend&SERVICE=WMS&VERSION=1.0.0&LegendColor=0xFFFFFF&SRS=';
    let srs = wms.srs;
    srs = 'EPSG:4326';
    s = s + srs;
    let lay = '&LAYERS=' + wms.layername;
    s = s + lay;
    let rest =
      '&FORMAT=image/png&HEIGHT=1866&WIDTH=3259&LEGENDWIDTH=200&LEGENDHEIGHT=4000&LEGENDFONT=Verdana&LEGENDFONTSIZE=10&LEGENDELEMENTHEIGHT=15';
    s = s + rest;

    s = s + this.state.bbox;

    // s = s + this.makeBbox();

    debug('PLOX 311', s);
    return s;
  };

  makeBbox = () => {
    let kart = this.props.kart;
    let bound = kart.getBounds();

    let latlng = bound.getCenter;
    let lat1 = latlng.lng - 0.0002;
    let lat2 = latlng.lng + 0.0002;
    let lng1 = latlng.lat - 0.0001;
    let lng2 = latlng.lat + 0.0001;
    let bbox =
      lat1.toString() +
      ',' +
      lng1.toString() +
      ',' +
      lat2.toString() +
      ',' +
      lng2.toString();

    debug('PLOX 1', bbox);
    debug('PLOX 2', this.state.bbox);
    return bbox;
  };

  setWmsListefraControl = (liste) => {
    if (this.props.setWmsListefraControl) {
      this.props.setWmsListefraControl(liste);
    }
  };

  oppdaterLegenda = () => {
    let bbox = this.makeBbox();
    debug('bbox', bbox);
    this.setState({ bbox });
  };

  visRisiko = (mode) => {
    this.props.visRisikoFraControl(mode);
    this.setState({ visRisikoLegend: mode === 'Feiing' || mode === 'Tilsyn' });
  };

  render() {
    let wmsPanel = (
      <div className={'subPanel ' + this.state.hideWmsPanel}>
        <WmsControl
          ref={(ref) => (this.wmsControl = ref)}
          kart={this.props.kart}
          hidePanel={this.handleWmsPanelHide}
          setWmsListe={this.setWmsListe}
          setVisSkjulLegend={this.setVisSkjulLegend}
          setWmsListefraControl={this.setWmsListefraControl}
        />
      </div>
    );

    let risikoPanel = (
      <div className={'subPanel ' + this.state.hideRisikoPanel}>
      <RisikoControl
          ref={(ref) => (this.risikoControl = ref)}
          kart={this.props.kart}
          kartfra={this.props.kartfra}
          hidePanel={this.handleRisikoPanelHide}
          visRisiko={this.visRisiko}
          modus={this.state.modus}
        /> 
      </div>
    );

    let tellerA = 0;

    //let wmsListe = this.filterPrimary(this.state.wmsListe);

    return (
      <div>
        {this.state.vektor && (
          <TileLayer
            id='z1'
            key='z1'
            name='z1'
            url='//www.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png'
            maxZoom={20}
          />
        )}

        {this.state.foto && (
          <TileLayer
            id='z2'
            key='z2'
            name='z2'
            url='//www.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg'
            maxZoom={20}
          />
        )}

        {this.state.hybrid && (
          <TileLayer
            id='z3'
            key='z3'
            name='z3'
            url='//www.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg'
            maxZoom={20}
          />
        )}

        {/* Velg layer */}
        <Control position='topleft'>
          <div>
            <button
              type='button'
              className='btnLag'
              onClick={this.handleLayerComponentHide}>
              <NKIcon icon='lag' color='nk-black' />
            </button>
            {/*KART fOTO hYBRID*/}
            <div
              className={
                'layerComponent samme ' + this.state.hideLayerComponent
              }>
              <button
                type='button'
                value='vektor'
                onClick={this.handleBaseLayer}
                className={this.state.vektorClass}>
                Kart
              </button>{' '}
              <button
                type='button'
                value='hybrid'
                onClick={this.handleBaseLayer}
                className={this.state.hybridClass}>
                Hybrid
              </button>{' '}
              <button
                type='button'
                value='foto'
                onClick={this.handleBaseLayer}
                className={this.state.fotoClass}>
                Foto
              </button>{' '}
             {/*   {this.state.harRisiko && !isThisProd() && ( */}
               {this.state.harRisiko && (
                <>
                  <button
                    style={{ backgroundColor: 'black', color: 'white' }}
                    type='button'
                    value='Risiko'
                    onClick={this.handleRisikoPanelHide}
                    className='btn btn-default w80'>
                    Risiko
                  </button>
                </>
              )} 
              <button
                style={{ backgroundColor: '#388a8d', color: 'white' }}
                type='button'
                value='Wms'
                onClick={this.handleWmsPanelHide}
                className='btn btn-default w80'>
                Wms
              </button>
            </div>
            <label style={{ marginLeft: '10px', fontSize: '18px' }}>
              {this.state.modus}
            </label>
            {this.state.harRisiko && this.props.visRisiko !== 'Ingen' && (
              <label style={{ marginLeft: '10px', fontSize: '18px' }}>
                 Risiko:  {this.props.visRisiko}
              </label>
            )}

            {/*   {this.props.visWms && <React.Fragment>{wmsPanel}</React.Fragment>}  */}
            {wmsPanel}
           {this.state.harRisiko && risikoPanel} 
          </div>
        </Control>
        <Control position='bottomright'>
          <React.Fragment>
            {this.state.visWmsLegend && (
              <div
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'darkgray',
                  backgroundColor: 'white',
                }}>
                <label
                  style={{
                    marginTop: '10px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize: '14px',
                  }}>
                  Tegnforklaring
                </label>
                <button
                  className='btn btn-default pull-right'
                  title='Lukk'
                  style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={this.setVisSkjulLegend.bind(this)}>
                  X
                </button>
                {/*       <button
                  className='btn btn-link pull-right'
                  onClick={this.oppdaterLegenda}>
                  <img height='20px' width='20px' src={Refresh} alt='' />
                </button> */}

                <br />
                <div
                  style={{
                    maxHeight: '300px',
                    minWidth: '30px',
                    backgroundColor: 'white',
                    overflowY: 'scroll',
                  }}>
                  {this.state.wmsListe.map(function(wms) {
                    let source = this.getImageSource(wms);
                    if (source === 'ingen') {
                      return '';
                    }

                    /*  if (wms.grouptitle !== 'Risikoanalyse'){
                      return '';
                    } */

                    var imgId = 'img' + wms.id;
                    var dId = 'div' + wms.id;
                    var html;
                    html = (
                      <div key={dId}>
                        <div
                          style={{
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            paddingBottom: '5px',
                            borderBottom: 'thin solid darkgray',
                          }}>
                          <label>{wms.layerdescription}</label>
                          <br />
                          <img
                            key={imgId}
                            src={source}
                            alt={' Bilde mangler'}
                          />
                        </div>
                      </div>
                    );
                    return html;
                  }, this)}
                </div>
              </div>
            )}
          </React.Fragment>
        </Control>

        <Control position='bottomright'>
          <React.Fragment>
            {this.state.visRisikoLegend && (
              <div
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'darkgray',
                  backgroundColor: 'white',
                }}>
                <label
                  style={{
                    marginTop: '10px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize: '14px',
                  }}>
                  Risiko markører
                </label>
                <button
                  className='btn btn-default pull-right'
                  title='Lukk'
                  style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={this.skjulLegendRisiko.bind(this)}>
                  X
                </button>
                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>Stor &nbsp;&nbsp;</td>
                      <td style={tdStyle}>Midd.</td>
                      <td style={tdStyle}>Lav&nbsp;</td>
                      <td style={tdStyle}>0&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRed}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellow}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreen}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBlue}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span style={{ marginLeft: '8px' }}>Overstyrt</span>

                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRedOverstyrt}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellowOverstyrt}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreenOverstyrt}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBlueOverstyrt}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <span style={{ marginLeft: '8px' }}>Flere røykløp</span>
                <br />
                <span style={{ marginLeft: '8px', fontSize: '10px' }}>
                  Høyeste risiko lengst fram
                </span>

                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRedPluss}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellowPluss}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreenPluss}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBluePluss}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <span style={{ marginLeft: '8px', fontSize: '10px' }}>
                  Høyeste (overstyrt) risiko lengst fram
                </span>

                <span style={{ marginLeft: '8px', fontSize: '10px' }}> </span>

                <table style={{ marginLeft: '8px' }}>
                  <tbody>
                    <tr>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoRedOverstyrtPlus}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoYellowOverstyrtPlus}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoGreenOverstyrtPlus}
                          alt=''
                        />
                      </td>
                      <td style={tdStyle}>
                        <img
                          style={{ height: '30px', marginRight: '8px' }}
                          src={risikoBlueOverstyrtPlus}
                          alt=''
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div
                  style={{
                    maxHeight: '300px',
                    minWidth: '30px',
                    backgroundColor: 'white',
                    overflowY: 'scroll',
                  }}></div>
              </div>
            )}
          </React.Fragment>
        </Control>

        <Control position='bottomright'>
          <React.Fragment>
            {this.state.visWmsFInfo && (
              <div
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'darkgray',
                  backgroundColor: 'white',
                }}>
                <label
                  style={{
                    marginTop: '10px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize: '14px',
                  }}>
                  {'Wms Info: ' +
                    (this.state.valgtObjectSide + 1) +
                    ' av ' +
                    this.state.wmsFInfoObjectListe.length}
                </label>

                <button
                  className={'btn btn-default ' + this.state.showBlaButtons}
                  onClick={this.visForrigeInfo.bind(this)}
                  title='Forrige'
                  style={{
                    backgroundColor: 'white',
                    height: '33px',
                    width: '40px',
                    float: 'left',
                  }}>
                  <NKIcon icon='venstrepil' size='0.7' color='nk-black' />
                </button>
                <button
                  className='btn btn-default'
                  title='Lukk'
                  style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={this.skjulFInfo.bind(this)}>
                  X
                </button>

                <button
                  className={'btn btn-default ' + this.state.showBlaButtons}
                  onClick={this.visNesteInfo.bind(this)}
                  title='Neste'
                  style={{
                    backgroundColor: 'white',
                    height: '33px',
                    width: '40px',
                    float: 'right',
                  }}>
                  <NKIcon icon='hoyrepil' size='0.7' color='nk-black' />
                </button>

                <div
                  style={{
                    maxWidth: '300px',
                    maxHeight: '400px',
                    overflowY: 'scroll',
                    padding: '8px',
                  }}>
                  {this.state.wmsFInfoObject.map(function(rad) {
                    tellerA = tellerA + 1;
                    let key = 'kk' + tellerA;
                    let linje = (
                      <div style={{ display: 'inline' }} key={key}>
                        <p style={{ color: 'black', display: 'inline' }}>
                          {rad.key + ':  '}
                        </p>
                        <p style={{ color: 'darkgreen', display: 'inline' }}>
                          {rad.value}
                        </p>
                        <br />
                      </div>
                    );
                    if (rad.key === 'xx_tittel') {
                      linje = (
                        <div key={key}>
                          <label
                            style={{ paddingLeft: '8px', paddingRight: '5px' }}>
                            {rad.value}
                          </label>
                          <br />
                        </div>
                      );
                    }
                    return linje;
                  }, this)}
                </div>
              </div>
            )}
          </React.Fragment>
        </Control>

        <Control position='bottomright'>
          <React.Fragment>
            {this.state.visRisikoInfoPanel && (
              <div
                style={{
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'darkgray',
                  backgroundColor: 'white',
                }}>
                <label
                  style={{
                    marginTop: '10px',
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontSize: '14px',
                  }}>
                  {'Risiko Info: ' +
                    (this.state.valgtObjectSide + 1) +
                    ' av ' +
                    this.state.wmsFInfoObjectListe.length}
                </label>

                <button
                  className={'btn btn-default ' + this.state.showBlaButtons}
                  onClick={this.visForrigeInfo.bind(this)}
                  title='Forrige'
                  style={{
                    backgroundColor: 'white',
                    height: '33px',
                    width: '40px',
                    float: 'left',
                  }}>
                  <NKIcon icon='venstrepil' size='0.7' color='nk-black' />
                </button>
                <button
                  className='btn btn-default'
                  title='Lukk'
                  style={{ height: '33px', width: '33px', float: 'right' }}
                  onClick={this.skjulFInfoRisiko.bind(this)}>
                  X
                </button>

                <button
                  className={'btn btn-default ' + this.state.showBlaButtons}
                  onClick={this.visNesteInfo.bind(this)}
                  title='Neste'
                  style={{
                    backgroundColor: 'white',
                    height: '33px',
                    width: '40px',
                    float: 'right',
                  }}>
                  <NKIcon icon='hoyrepil' size='0.7' color='nk-black' />
                </button>

                <div
                  style={{
                    maxWidth: '300px',
                    maxHeight: '400px',
                    overflowY: 'scroll',
                    padding: '8px',
                  }}>
                    Hepp
                </div>
              </div>
            )}
          </React.Fragment>
        </Control> 
      </div>
    );
  }
}

export default LayerControl;
