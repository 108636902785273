import React, { Component } from 'react';
import * as _ from 'lodash';
import { getMatrikkelnummer, getAdresse } from 'modules/felles/AdresseHelper';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import moment from 'moment';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import ArbeidslisteInfoModal from 'modules/felles/ArbeidslisteInfoModal.jsx';
import NKIcon from 'nka-icons';
import { debugFactory } from 'utils';
import GebyrDialog from 'modules/BraGe/GebyrDialog.jsx';
import { visEiendomKommunekart } from 'utils/kommunekart.js';
const debug = debugFactory('nk:mobileDetails');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}
      itemId={dialogData.itemSituasjonId}
      bruksenhetIdMa={dialogData.matrikkelenhetId}
      itemAdresse={dialogData.itemsSituasjonAdresse}
      eiendom={dialogData.eiendom}
      matrikkelenhetId={dialogData.matrikkelenhetId}
      fraFiktive={false}
    />
  ),
  MatrikkelDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      buttonText={'Lukk'}
      tittel='Matrikkelinfo'
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}>situasjon
      <Matrikkelinfo
        id={dialogData.matrikkelinfoId}
        bygningsNr={dialogData.bygningsNr}
      />
    </ModalContainer>
  ),
  ArbeidslisteInfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ArbeidslisteInfoModal
      isOpen={isOpen}
      onClose={onClose}
      id={dialogData.id}
      adresse={dialogData.adresse}
    />
  ),
  GebyrDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrDialog
        isOpen={isOpen}
        onClose={onClose}
        adresse={dialogData.adresse}
        matrikkelId={dialogData.matrikkelId}
        fagbruksenhetId={dialogData.fagbruksenhetId}
        fraEtablerFag={false}
        maid={dialogData.maid}
      />
    </NKModal>
  ),
};

class mobileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { currentEnhet: null };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount = () => {

  };

  onCloseDialog() {
    //this.props.reloadSearch();
  };

  makeAntatt = () => {
    let item = this.props.enhet;

    let doBold = (i) => {
      if (i == 0) {
        return 'bold';
      } else {
        return 'normal';
      }
    };

    let doColor = (d) => {
      const now = new Date();
      d = new Date(d);
      if (d < now) {
        return 'orangered';
      } else {
        return 'black';
      }
    };

    if (_.isEmpty(item.source.skybrannData)) {
      return '';
    } else {
      let liste = [];
      if (item.source.skybrannData.bruksenhetTiltakType) {
        for (
          let i = 0;
          i < item.source.skybrannData.bruksenhetTiltakType.length;
          i++
        ) {
          liste.push(item.source.skybrannData.bruksenhetTiltakType[i]);
        }
      }
      if (item.source.skybrannData.royklopTiltakType) {
        for (
          let i = 0;
          i < item.source.skybrannData.royklopTiltakType.length;
          i++
        ) {
          liste.push(item.source.skybrannData.royklopTiltakType[i]);
        }
      }
      if (liste.length > 0) {
        liste = _.orderBy(liste, ['nesteDato'], ['asc']);

        var rader = liste.map((rad, i) => (
          <tr
            style={{ fontSize: '12px', fontWeight: doBold(i) }}
            key={'tr_' + i}>
            <td style={{ color: doColor(rad.nesteDato) }}>
              {rad.nesteDato ? moment(rad.nesteDato).format('DD.MM.YYYY') : ''}
            </td>
            <td style={{ paddingLeft: '4px' }}>{rad.tiltakType}</td>
          </tr>
        ));

        return (
          <div>
            <table>
              <tbody>{rader}</tbody>
            </table>
          </div>
        );
      }
    }
  };

  onVisSituasjon = () => {
    let item = this.props.enhet;
    let enhetid = null;
    let adresse = null;
    let eiendom = getMatrikkelnummer(item.source);
    adresse = item.source ? getAdresse(item.source) : 'null';

    enhetid = _.get(item, 'source.skybrannData.bruksenhet.fagId');
    if (!enhetid){
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Kan ikke åpne situasjon',
        melding: 'Det mangler opplysninger for å åpne Situasjon.',
      });

      return;
    };

    this.dialogs.setOpenDialog('EnhetDialog', {
      itemSituasjonId: enhetid,
      matrikkelenhetId:
        item.source.adresse !== undefined
          ? item.source.adresse.id
          : item.source.matrikkelenhet.id,
      itemsSituasjonAdresse: adresse,
      eiendom: eiendom,
    });
  };

  onVisGebyr = () => {
    let item = this.props.enhet;

    let enhetid = null;
    let adresse = null;
    let matrikkelenhet = null;
    let maid = null;

    enhetid =
      item.source.skybrannData != undefined
        ? item.source.skybrannData.bruksenhet.fagId
        : null;
    adresse = item.source ? getAdresse(item.source) : 'null';
    matrikkelenhet = item.source.matrikkelenhet.id;
    if (!enhetid) {
      maid = item.source.id;
    }

    this.dialogs.setOpenDialog('GebyrDialog', {
      adresse: adresse,
      matrikkelId: matrikkelenhet,
      fagbruksenhetId: enhetid,
      maid: maid,
    });
  };

  onVisArbeidslisteinfo = () => {
    let item = this.props.enhet;
    let adresse = null;
    let fagId = null;

    adresse = item.source ? getAdresse(item.source) : 'null';
    fagId = item.source.skybrannData.bruksenhet.fagId;

    this.dialogs.setOpenDialog('ArbeidslisteInfoDialog', {
      id: fagId,
      adresse: adresse,
    });
    // this.onVisArbeidslisteinfo(item, true);
  };

  visMatrikkelinfo = () => {
    let item = this.props.enhet;

    let bygg = item.bygg ? item.bygg : item.source.bygg;
    this.setState({
      bygningsNr: bygg.bygningsnummer,
    });

    this.dialogs.setOpenDialog('MatrikkelDialog', {
      matrikkelinfoId: item.id,
      bygningsNr: bygg.bygningsnummer,
    });
  };

  kommunekart = () => {
    let ei =
      this.props.enhet.source.kommune.kommuneNavn +
      '-' +
      getMatrikkelnummer(this.props.enhet.source);
    //  ei = ei.replace(' ', '-'); */
    visEiendomKommunekart(ei, 'situasjon');
  };

  render() {
    if (!this.props.enhet) {
      return <div>Vent..</div>;
    }
    return (
      <div>
        <h3>{getAdresse(this.props.enhet.source)} </h3>
        <label>
          {this.props.enhet.source.kommune.kommuneNavn}-
          {getMatrikkelnummer(this.props.enhet.source)}{' '}
        </label>

        <br />
        <label>{this.props.enhet.source.bruksenhetstype}</label>
        <br />
        <table>
          <tbody>
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <label>Antatt neste:&nbsp;&nbsp;</label>
              </td>
              <td style={{ paddingTop: '4px' }}>{this.makeAntatt()}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <div className='row'>
          <div className='col-sm-6' style={{ padding: '3px' }}>
            <button
              onClick={this.visMatrikkelinfo}
              className='btn btn-default'
              style={{ width: '100%' }}>
              Vis matrikkelinfo
            </button>
          </div>
          <div className='col-sm-6' style={{ padding: '3px' }}>
            <button
              onClick={this.onVisSituasjon}
              className='btn btn-default'
              style={{ width: '100%' }}>
              Vis situasjon
            </button>
          </div>
          <div className='col-sm-6' style={{ padding: '3px' }}>
            <button
              onClick={this.onVisArbeidslisteinfo}
              className='btn btn-default'
              style={{ width: '100%' }}>
              Vis arbeidslisteinfo
            </button>
          </div>
          <div className='col-sm-6' style={{ padding: '3px' }}>
            <button
              onClick={this.onVisGebyr}
              className='btn btn-default'
              style={{ width: '100%' }}>
              Vis gebyr
            </button>
          </div>
          <div className='col-sm-6' style={{ padding: '3px' }}>
            <button
              onClick={this.kommunekart}
              className='btn btn-default'
              style={{ width: '100%' }}>
              Vis i kommunekart
            </button>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default mobileDetails;
