

export function getGeoObjekt(polygon) {
    var coor = [];
    if (polygon === undefined) {
      return 'Feil: Polygon er ikke komplett!';
    } else {      
      for (let i = 0; i < polygon.length; i++) {
        var cc = [polygon[i][0], polygon[i][1]];
        coor.push(cc);
      }
      var geo = {
        name: 'Bruksenheter: GeoShape Polygon',
        location: {
          type: 'polygon',
          coordinates: coor
        }
      };
      return geo;
    }
  }