import React from 'react';
import ReactFileReader from 'react-file-reader';
import arkivApi from 'api/arkivApi';
import '@norkart/react-table/dist/react-table.css';
import {
  uuid
} from 'utils' ;
import moment from 'moment';

var Base64Binary = {
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  /* will return a  Uint8Array type */
  decodeArrayBuffer: function(input) {
    var bytes = (input.length / 4) * 3;
    var ab = new ArrayBuffer(bytes);
    this.decode(input, ab);

    return ab;
  },

  removePaddingChars: function(input) {
    var lkey = this._keyStr.indexOf(input.charAt(input.length - 1));
    if (lkey === 64) {
      return input.substring(0, input.length - 1);
    }
    return input;
  },

  decode: function(input, arrayBuffer) {
    //get last chars to see if are valid
    input = this.removePaddingChars(input);
    input = this.removePaddingChars(input);

    var bytes = parseInt((input.length / 4) * 3);

    var uarray;
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;
    var j = 0;

    if (arrayBuffer) {
      uarray = new Uint8Array(arrayBuffer);
    } else {
      uarray = new Array(bytes);
    }

    input = input.replace(/[^A-Za-z0-9=]/g, '');

    for (i = 0; i < bytes; i += 3) {
      //get the 3 octects in 4 ascii chars
      enc1 = this._keyStr.indexOf(input.charAt(j++));
      enc2 = this._keyStr.indexOf(input.charAt(j++));
      enc3 = this._keyStr.indexOf(input.charAt(j++));
      enc4 = this._keyStr.indexOf(input.charAt(j++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      uarray[i] = chr1;
      if (enc3 !== 64) {
        uarray[i + 1] = chr2;
      }
      if (enc4 !== 64) {
        uarray[i + 2] = chr3;
      }
    }

    return uarray;
  }
};

class NyUtJp extends React.Component {
  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.submit = this.submit.bind(this);
    this.jpLagret = this.jpLagret.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleAarChange = this.handleAarChange.bind(this);
    this.handleSekvensChange = this.handleSekvensChange.bind(this);
    this._base64ToArrayBuffer = this._base64ToArrayBuffer.bind(this);
    this.state = {
      sakstyper: [],
      defaultValue: null,
      lagretJp: null,
      jpLagret: false,
      fil: null,
      array: null,
      type: null,
      status: 'E',
      lagrer: false,
      innstillinger: null,
      fornavn: null,
      etternavn: null
    };

    arkivApi.getArkivInnstillinger(uuid(), this.gotInnstillinger);
  }

  gotInnstillinger = (err, ins) => {
    this.setState({ innstillinger: undefined });
  }


  handleAarChange(val) {
    this.setState({ aar: val.target.value });
  }

  handleSekvensChange(val) {
    this.setState({ sekvensnr: val.target.value });
  }

  handleStatusChange = (val) => {
    this.setState({ status: val.target.value });
  }

  handleTypeChange = (val) => {
    this.setState({ type: val.target.value });
  }

  handleFiles = files => {
    console.log(files);
    //var array = this._base64ToArrayBuffer(files.base64);
    //this.setState({ fil: files, array: array});

    this.setState({ fil: files, base64: files.base64 });
  };

  _base64ToArrayBuffer(base64) {
    return Base64Binary.decode(base64);
    // var binary_string =  window.atob(base64);
    // var len = binary_string.length;
    // var bytes = new Uint8Array( len );
    // for (var i = 0; i < len; i++)        {
    //     bytes[i] = binary_string.charCodeAt(i);
    // }
    // return bytes.buffer;
  }

  handleNameChange(data) {
    this.setState({ tittel: data.target.value });
  }
  
  handleFornavnChange = (val) => {
    this.setState({ fornavn: val.target.value });
  }

  handleEtternavnChange = (val) => {
    this.setState({ etternavn: val.target.value });
  }

  submit() {
    console.log('Lagre utgaende jp');
    var a = this.state.base64;
    var b = a.indexOf(',');
    var base64string = a.substr(b + 1, a.length - 1);
    var lk = '';
    if (this.state.innstillinger != null && this.state.innstillinger !== undefined) {
      if (this.state.innstillinger.Landkode != null) {
        lk = this.state.innstillinger.Landkode;
      }
    }
    var utgJp = {
      saksnummerAar: this.state.aar,
      saksnummerSekvensnr: this.state.sekvensnr,
      tittel: this.state.tittel,
      journalstatus: this.state.status,
      dokumentetsDato: moment(),
      eksterneMottakere: {
        personer: [
          {
            fornavn: this.state.fornavn == null || this.state.fornavn === '' ? 'Ola' : this.state.fornavn,
            etternavn: this.state.etternavn == null || this.state.etternavn === '' ? 'Nordmann' : this.state.etternavn,
            postadresse: 'Hjemmeveien',
            postadresse2: '',
            postnr: '3333',
            poststed: 'Hjemstedet',
            landKode: lk,
            epost: 'olanordmann@mail.no'
          }
        ]
      },
      hoveddokument: {
        tittel: 'Hoveddokument',
        filinnhold: {
          filnavn: this.state.fil.fileList[0].name,
          mimetype: this.state.fil.fileList[0].type,
          base64: base64string
        },
        dokumenttypeKodeverdi: this.state.type
        //filformatKodeverdi: 'UKJENT'
      }
    };

    console.log(utgJp);

    arkivApi.nyUtgJournalpost(utgJp, this.jpLagret);
    this.setState({ lagrer: true });
  }
  jpLagret(err, lagretjp) {

    this.setState({ lagretJp: lagretjp, jpLagret: true, lagrer: false });
    console.log(err);
    console.log(lagretjp);
  }

  render() {
    if (this.state.lagrer) {
      return <div className="loader" />;
    }
    if (!this.state.jpLagret) {
      var result = (
        <div>
          <b>Journalpost ikke arkivert</b>
          <p />
          En utgående journalpost med angitt tittel vil bli opprettet.
        </div>
      );
    } else {
      if (this.state.lagretJp === null || this.state.lagretJp === undefined) {
        result = (
          <div>
            <b> Journalpost ikke lagret</b>
          </div>
        );
      } else {
        result = (
          <div>
            <b>Journalpost arkivert</b>
            <p>
              Journalnummer: {this.state.lagretJp.journalaar}/
              {this.state.lagretJp.journalsekvensnummer}
            </p>
            <p />
          </div>
        );
      }
      
    }
    if (!this.state.fil) {
      var filInfo = <div>Fil ikke opplastet</div>;
    } else {
      filInfo = <div>{this.state.fil.fileList[0].name}</div>;
    }
    return (
      
      <div style={{ margin: '30px' }}>
        <h2>Test ny utgående journalpost</h2>
        <form>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Sak år</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Saksår"
                  onChange={this.handleAarChange}
                  ref="aarInput"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Sak sekvensnummer</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Saksekvensnummer"
                  onChange={this.handleSekvensChange}
                  ref="sekvensInput"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Tittel</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Journalpost tittel"
                  onChange={this.handleNameChange}
                  ref="nameInput"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Status</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Settes til E-ekspedert hvis blank"
                  onChange={this.handleStatusChange}
                  ref="statusInput"
                />
              </div>
            </div>
          </div>
          <p/>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Dokumenttype</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Feks Brev, prøv med blank først"
                  onChange={this.handleTypeChange}
                  ref="typeInput"
                />
              </div>
            </div>
          </div>
          <hr/>
          <p/>
          Mottaker, ekstern korrespondansepart. Valgfri, hvis ikke oppgitt, brukes 'Ola Nordmann'.
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label>Fornavn</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Fornavn"
                  onChange={this.handleFornavnChange}
                  ref="fornavnInput"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>Etternavn</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Etternavn"
                  onChange={this.handleEtternavnChange}
                  ref="etternavnInput"
                />
              </div>
            </div>
          </div>
          <hr/>
          <p/>
          <label>Fil</label>
          <div className="row">
            <div className="col-sm-4">
              <div className="pull-left">
                <ReactFileReader
                  base64={true}
                  multipleFiles={false}
                  fileTypes="/*"
                  handleFiles={this.handleFiles}>
                  <button className="btn">Last opp fil</button>
                </ReactFileReader>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="pull-left">
                <div>{filInfo}</div>
                <br />
              </div>
            </div>
          </div>
          <p/>
          <hr />
          {result}
        </form>
      </div>
    );
  }
}

export default NyUtJp;
