import React, { Component } from 'react';
import ReactTable from '@norkart/react-table';
import { debugFactory } from 'utils';
import SoekEnheterKart from 'components/Kart/SoekEnheterKart.jsx';
import * as _ from 'lodash';
import { contextOppslagKommuner } from 'context/contextOppslag.js';
import { getSimpleOrganisasjon } from 'api/organisasjonApi';
import CONFIG from 'lib/CONFIG';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import { getContextInnstillingerKartNew } from 'context/contextInnstillinger.js';
import $ from 'jquery';

const debug = debugFactory('nk:innstillinger:kart:wms');

class administrerWms extends Component {
  constructor() {
    super();
    this.state = {
      wmsListe: []
    };
  }

  componentDidMount = () => {
    getContextInnstillingerKartNew(this.gotKart);
    getSimpleOrganisasjon(this.onGotSimpleOrganisasjon);

        //Så lenge kun til oss
        if (contextInnstillingerPerson._currentValue.norkartAdmin) {
          this.getWmsForKommuner();
        }
  };

  gotKart = (data) => {
    debug('ZAX_Administrer', data);
  };

  getWmsForKommuner = () => {  // urlx = 'https://tjenestekatalogen.api.norkart.no/CatalogueService.svc/json/GetCapabilities?applicationID=cpc-Kommunekart-5037';  //Rieni  5037  4612
    let url = CONFIG.REACT_APP_WMS_URL;
    let appName = '=cpc-kommunekart-';

    let kommuner= contextOppslagKommuner._currentValue.kommuner;
      for (let i = 0; i < kommuner.length; i++) {
        let kom = kommuner[i];
        let urlx = url + appName + kom.kommuneId;  
        this.setState({ komkom: kom.kommuneNavn });
        $.getJSON(urlx, this.gotWmsListeFraTjenestePanel); 
      }
  }
 
  onGotSimpleOrganisasjon = (err, organisasjon) => {
    let urlConfig =  CONFIG.REACT_APP_WMS_URL;
    let appName = '=cpc-Brannforebygging-';
    /* if (urlConfig.includes('wacloudtest')) {
      appName = '=cpc-BrannforebyggingTest-';
    } */
 
    let urlx = urlConfig + appName + organisasjon.kortnavn;  
    $.getJSON(urlx, this.gotWmsListeFraTjenestePanel); //Eiendomskart

  };

  gotWmsListeFraTjenestePanel = data => {
    let teller = this.state.teller;
    let wmsListe = this.state.wmsListe;
    for (let i = 0; i < data.length; i++) {
      const group = data[i];
      let groupname = group.Name;
      let groupurl = group.URL;
      let grouptitle = group.Title;
      for (let y = 0; y < group.Layers.length; y++) {
        let layer = group.Layers[y];
        teller = teller + 1;
        let rad = {
          alphablend: layer.AlphaBlend,
          layerdescription: layer.Description,
          layername: layer.Name,
          id: teller,
          zoomMax: 20,
          zoomMin: 14,
          groupurl: groupurl,
          grouptitle: grouptitle,
          groupname: groupname
        };
        wmsListe.push(rad);
      }
    }
    wmsListe = _.sortBy(wmsListe, 'grouptitle');
    this.setState({ wmsListe: wmsListe });
    
    debug('WMSX', wmsListe);
  };
  

  getColumns = () => {
    return [
      {
        id: 'grouptitle',
        name: 'Gruppe tittel',
        sortParams: 'grouptitle',
        isSorted: false,
        sortDirection: 'asc'
      },
      {
        id: 'groupname',
        name: 'Gruppe navn',
        sortParams: 'groupname',
        isSorted: false,
        sortDirection: 'asc'
      },
      {
        id: 'layername',
        name: 'Layer',
        sortParams: 'layername',
        isSorted: false,
        sortDirection: 'asc'
      }
    ];
  };

  render() {
    return (
      <div>
        {/*  <h2>Oversikt Wms</h2> */}
        <p />
        <ul className="nav nav-tabs">
          <li className="active">
            <a data-toggle="tab" href="#kart">
              Oversikt Wms på kart
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#liste">
              Wms liste
            </a>
          </li>
          {/*  <li>
            <a data-toggle="tab" href="#rediger">
              Endre eller ny Wms
            </a>
          </li> */}
        </ul>

        <div className="tab-content">
          <div id="kart" className="tab-pane fade in active">
            <SoekEnheterKart
              ref={ref => (this.KartX = ref)}
              visning="wmsoversikt"
              setWmsListefraControl={this.setWmsListefraControl}
            />
          </div>
          <div id="liste" className="tab-pane fade ">
            <ReactTable
              items={this.state.wmsListe}
              idProperty="id"
              showIndex={false}
              filterable={true}
              columns={this.getColumns()}
              selectableRows={false}
              selectAllLabel="Alle"
              onRowClick={null}
            />
          </div>
          {/* <div id="rediger" className="tab-pane fade ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4">
                  <div style={{ padding: '20px' }}>
                    Du kan ikke endre eller opprette nye Wms eller "kartlag" i
                    Brannforebygging. <br />
                    Dette kan gjøres i "Norkart Tjenestepanel". <br />
                    <br />
                    Ta eventuell kontakt med han/hun som hos dere er ansvarlig
                    for Wms.
                  </div>
                </div>
                <div className="col-sm-4">
                  <img className="bilde" src={bilde} alt="" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default administrerWms;
