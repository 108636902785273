var React = require('react');
var _ = require('underscore');

class VisAvikAnmerkninger extends React.Component {
    render() {
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close" type="button" onClick={this.props.onClose}>
                        <span>x</span>
                        <span className="sr-only">Lukk</span>
                    </button>
                    <h4 className="modal-title">Avvik/Anmerkninger registrert på enheten</h4>
                </div>
                <div className="modal-body">
                    <div>
                        <h3>Åpne avvik</h3>
                        <AvvikTable />   
                        <p />
                        <h3>Åpne anmerkninger</h3>
                        <AnmerkningTable />
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary" onClick={this.props.onClose}>OK</button>

                </div>

            </div>

        );
    }
}

class ListeRowAvvik extends React.Component {
    onClick = () => {
        this.props.onListeClick(this.props.rad.id);
    };

    render() {

        return (
            <tr>
                <td>{this.props.rad.avvik}</td>
                <td>{this.props.rad.registrert}</td>
            </tr>
        );
    }
}

class ListeRowAnm extends React.Component {
    onClick = () => {
        this.props.onListeClick(this.props.rad.id);
    };

    render() {

        return (
            <tr>
                <td>{this.props.rad.kommentar}</td>
                <td>{this.props.rad.registrert}</td>
            </tr>
        );
    }
}

class AvvikTable extends React.Component {
    render() {
        var avvik = getAvvik();

        var rows = _.map(avvik, function (rad) {
            return (
                <ListeRowAvvik key={rad.id} rad={rad} />
            );
        }, this);
        return (
            <div>
                <div className="table table-bordered table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr className="dl-horizontal">

                                <th>Avvik</th>
                                <th>Registrert</th>
                            </tr>
                        </thead>
                        <tbody>{rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

class AnmerkningTable extends React.Component {
    render() {
        var anmerkninger = getAnmerkninger();

        var rows = _.map(anmerkninger, function (rad) {
            return (
                <ListeRowAnm key={rad.id} rad={rad} />
            );
        }, this);
        return (
            <div>
                <div className="table table-bordered table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr className="dl-horizontal">

                                <th>Kommentar</th>
                                <th>Registrert</th>
                            </tr>
                        </thead>
                        <tbody>{rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function getAnmerkninger() {
    return [{
        'id': '1',
        'status': 'Åpent',
        'kommentar': 'Masse rot i oppgang',
        'registrert': '23.05.2015'
    },
    {
        'id': '2',
        'status': 'Åpent',
        'kommentar': 'Plate foran ovn',
        'registrert': '29.10.2014'
    },
    {
        'id': '3',
        'status': 'Åpent',
        'kommentar': 'Plate foran ovn',
        'registrert': '27.05.2014'
    }
    ];
}

function getAvvik() {
    return [{
        'id': '1',
        'status': 'Åpent',
        'avvik': 'Mangler røykvarsler',
        'paragraf': '4',
        'registrert': '23.05.2015'
    }, {
        'id': '2',
        'status': 'Åpent',
        'avvik': 'Mangler slukkeutstyr',
        'paragraf': '4',
        'registrert': '29.10.2014'
    },
    {
        'id': '3',
        'status': 'Åpent',
        'avvik': 'Mangler slukkeutstyr',
        'paragraf': '4',
        'registrert': '27.05.2014'
    }
    ];
}

export default VisAvikAnmerkninger;