var React = require('react');
var enhetApi = require('api/enhetApi');
var royklopApi = require('api/royklopApi');
var ReactTable = require('@norkart/react-table');
require('@norkart/react-table/dist/react-table.css');

//require('./Reacttable.css');

class Objekthistorikk extends React.Component {
    state = { historikk: [], dataHentet: false };

    componentDidMount() {
        if (this.props.type === 'enhet') {
            enhetApi.getBruksenhetObjekthistorikk(this.props.id, this.gotHistorikk);
        } else {
            royklopApi.getRoyklopObjekthistorikk(this.props.id, this.gotHistorikk);
        }
    }

    gotHistorikk = (err, historikk) => {
        console.log(historikk);
        this.setState({ historikk: historikk, dataHentet: true });
    };

    render() {

        if (!this.state.dataHentet) {
            return (
                <div>Laster...</div>
            );
        }
        return (
            <div className="modal-content">
                <div className="modal-header">
                    <button className="close" type="button" onClick={this.props.onClose}>
                        <span>x</span>
                        <span className="sr-only">Lukk</span>
                    </button>
                    <h4 className="modal-title">Objektendringer</h4>
                </div>
                <div className="modal-body"
                    style={{ padding: '20px'}}>
                    <ReactTable
                        items={this.state.historikk}
                        idProperty='id'
                        showIndex={false}
                        filterable={false}
                        columns={columns}
                        selectableRows={false}
                        toggleAll={false}
                        onRowClick={null}/>
                </div>
            </div>


        );
    }
}

var columns = [
    {
        id: 'endringType',
        name: 'Endring',
        sortParams: 'endringType',
        filterable: true,
        isSorted: false,
        sortable: true
    },
    {
        id: 'nyVerdi',
        name: 'Endret til',
        sortParams: 'nyVerdi',
        filterable: false,
        isSorted: false,
        sortable: true
    },
    {
        id: 'gammelVerdi',
        name: 'Endret fra',
        sortParams: 'gammelVerdi',
        filterable: false,
        isSorted: false,
        sortable: true
    },
    {
        id: 'merknad',
        name: 'Merknad',
        sortParams: 'merknad',
        filterable: false,
        isSorted: false,
        sortable: true
    },
    {
        id: 'registrertDato',
        name: 'Registrert',
        sortParams: 'registrertDato',
        isSorted: false,
        //sortDirection: 'dsc',
        formatter: function(item) {
            if (item.dato === null)
            { return '-'; }
            else {
                var dato = new Date(item.registrertDato);
                var dag = dato.getDate().toString();
                var mnd = (dato.getMonth() + 1).toString();
                var aar = (dato.getFullYear().toString());
                if (dag.length === 1) { dag = '0' + dag; }
                if (mnd.length === 1) { mnd = '0' + mnd; }
                return dag + '.' + mnd + '.' + aar;
            }
        }

    }];

export default Objekthistorikk;