import React, { Component } from 'react';
import JaNeiDialog from 'components/Dialogs/JaNeiDialog.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
import moment from 'moment';
import './driftsmeldinger.css';
import { systemcheck, oppdatersystemstatus } from 'api/innloggingApi.js';
const debug = debugFactory('nk:DriftsmeldingSystem');
//kopier: import DriftsmeldingSystem from './DriftsmeldingSystem.jsx';

const modalDialogs = {
  JaNeiDialog: ({ isOpen, onClose, dialogData, owner }) => {
    const { onJa = () => {} } = dialogData;
    return (
      <JaNeiDialog
        tittel='Bekreft og lagre'
        isOpen={isOpen}
        onClose={onClose}
        melding={'Ønsker du å endre driftsmeldingen for system?'}
        onJa={onClose(onJa)}
        onNei={onClose}
      />
    );
  },
};

class DriftsmeldingSystem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startOK: true,
      startTekst: 'Brannforebygging er midlertidig ute av drift!',
      ok: true,
      tekst: 'Brannforebygging er midlertidig ute av drift!',
      endretAv: '',
      endretDato: '',
      init: false
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    this.hentData();
  }

  hentData = () => {
    systemcheck(this.gotSystemCheck);
  };

  gotSystemCheck = (err, data) => {
    this.setState({
      ok: data.ok,
      tekst: data.tekst,
      startOk: data.ok,
      startTekst: data.tekst,
      endretAv: data.endretAv,
      endretDato: moment(data.endretDato).format('DD.MM.YYYY: HH:mm'),
      init: true
    });
  };

  okChange = (e) => {
    let ok = !this.state.ok;
    this.setState({ ok });
  };

  tekstChange = (e) => {
    let tekst = e.target.value;
    this.setState({ tekst });
  };

  bekreftLagre = () => {
    this.dialogs.setOpenDialog('JaNeiDialog', {
      onJa: () => {
        this.lagre();
      },
    });
  };

  lagre = () => {
    let status = { ok: this.state.ok, tekst: this.state.tekst };
    oppdatersystemstatus(status, this.hentData);
  };

  render() {
    if (!this.state.init){
        return <div style={{ padding: '20px' }}>Vent</div>;
    }
    return (
      <div>
        <h2>
          <span>System stop ved innlogging</span>
        </h2>
        <br /> <br />
        <div style={{ marginLeft: '20px' }}>
          <h3>
            Obs! Har du snakket med andre om endring av "Driftsmelding  for System" ?
          </h3>
          <br />
          <div className='yradio-item'>
            <input type='radio' id='ritema' name='ritem' value='ropt1'  checked={this.state.ok} onChange={this.okChange}/>
            <label htmlFor='ritema'>OK: Systemet kjøres som normal. Teksten vises ikke.</label>
          </div>
          <br />
          <div className='xradio-item'>
            <input type='radio' id='ritemb' name='ritem' value='ropt2' checked={!this.state.ok} onChange={this.okChange}/>
            <label htmlFor='ritemb'>Ikke OK: Systemet er sperret. Teksten vises.</label>
          </div>
          <br />
          <br />

          <label style={{ marginTop: '4px', fontSize: '16px' }}>Tekst:</label>
          <br />
          <textarea
            style={{
              width: '500px',
              padding: '6px',
              marginTop: '6px',
              resize: 'none',
              fontSize: '18px',
              color: 'darkred',
            }}
            cols='80'
            rows='7'
            value={this.state.tekst}
            placeholder='Driftsmelding..'
            onChange={this.tekstChange}
          />
          <br />
          <span style={{ marginLeft: '4px', fontStyle: 'italic',  color: 'blue' }}>En link til Norkart Kundesenter er alltid med.</span>
          <br />
         <br/><br/>
          <span style={{ color: 'gray' }}>
            Sist endret: {this.state.endretDato} av {this.state.endretAv}. 
          </span>
          <br/><br/>
          {(this.state.startOk !== this.state.ok ||
            this.state.startTekst !== this.state.tekst) && (
            <button
              className='btn btn-success'
              style={{ width: '500px' }}
              onClick={this.bekreftLagre}>
              Bekreft endringene
            </button>
          )}

          <br />

          <br />
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default DriftsmeldingSystem;
