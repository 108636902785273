import React from 'react';
import NKIcon from 'nka-icons';
import { LinkList, LinkListItem } from 'components/LinkList';
import { isPersonNorkartAdmin } from 'context/contextInnstillinger.js';
import { getContextKategorier } from 'context/contextOppslag.js';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:nkadmin');
let pathname = '/admin';

class Index extends React.Component {
  state = { init: false };

  componentDidMount() {
    getContextKategorier();
    this.setState({ init: true });
  }

  render() {
    if (!this.state.init) {
      return <div style={{ padding: '20px' }}>Vent...</div>;
    }
    return (
      <div>
        <h2 style={{ marginLeft: '40px' }}>NK Admin</h2>
        {isPersonNorkartAdmin() && (
          <div>
            <div className='row'>
              <div className='col-sm-12 col-md-8 col-lg-6'>
                <LinkList columns={1}>
                  <LinkListItem
                    title='Organisasjoner'
                    path={`${pathname}/Organisasjoner`}
                  />
                  <LinkListItem
                    title='Norkart brukere'
                    path={`${pathname}/NorkartBrukere`}
                  />

                  <LinkListItem
                    title='Brukere innlogget siste 10 min.'
                    path={`${pathname}/BrukereNaa`}
                  />
                </LinkList>
              </div>

              <div className='col-sm-12 col-md-8 col-lg-6'>
                <LinkList columns={1}>
                  <LinkListItem
                    title='Logg Automatisering'
                    path={`${pathname}/Logg`}
                  />
                  <LinkListItem
                    title='Logg SvarUt siste 30 dager'
                    path={`${pathname}/SvarUtLogg`}
                  />
                  <LinkListItem
                    title='Logg SvarUt feilet siste 30 dager'
                    path={`${pathname}/SvarUtFeilLogg`}
                  />
                  <LinkListItem
                    title='Systemmeldinger siste 200 feilet'
                    path={`${pathname}/ProsessLogg`}
                  />
                </LinkList>
              </div>
            </div>
            <br />
            <br />
            <div className='row'>
              <div className='col-sm-12 col-md-8 col-lg-6'>
                <LinkList columns={1}>
                  {/* <LinkListItem
                    title='Risikoanalyse overvåkning'
                    path={`${pathname}/Risiko`}
                  /> */}

                  {/* <LinkListItem
                    title='Logg SMS'
                    path={`${pathname}/SmsLogg`}
                  /> */}
                  {/*   <div onClick={() => this.fjernCookie()}>
                    <LinkListItem
                      cookie
                      icon='cookie'
                      title='Reset Info-cookie '
                    />
                  </div> */}

                  <LinkListItem
                    title='Eksempelmaler'
                    path={`${pathname}/Eksempelmaler`}
                  />

                  <LinkListItem
                    title='Systeminformasjon'
                    path={`${pathname}/Driftsmeldinger`}
                  />
                  <LinkListItem title='Test' path={`${pathname}/Test`} />
                </LinkList>
              </div>
            </div>
          </div>
        )}
        {!isPersonNorkartAdmin() && (
          <div style={{ marginTop: '60px', marginLeft: '60px' }}>
            <span>
              <span>
                <NKIcon icon='hammer' size='1' color='nk-black' />
              </span>
              <span style={{ marginLeft: '10px' }}>
                <label>Denne siden brukes av Norkart.</label>
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }
}
export default Index;
