import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import * as avvikApi from 'api/avvikApi';
import NKIcon from 'nka-icons';
import './AvvikAnmerkninger.css';
import AvvikForm from 'modules/felles/avvikAnmerkninger/AvvikForm.jsx';
import AnmerkningForm from 'modules/felles/avvikAnmerkninger/AnmerkningForm.jsx';
import royk_ikon from 'images/48/nk_075-royklop-A-48px.png';
import ildsted_ikon from 'images/48/nk_076-ildsted-A-48px.png';
import bruksenhet_ikon from 'images/48/hjem.png';
import DialogStore from 'lib/DialogStore';
import Debug from 'debug';
import {
  getFyringsforbudForBruksenhet,
  saveFyringsforbudForBruksenhet,
} from 'api/enhetApi';
import OKDialog from 'components/Dialogs/OkDialog.jsx';

const debug = Debug('nk:AvvikAnmerkninger');

const modalDialogs = {
	OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
		<OKDialog
			tittel={dialogData.tittel}
			melding={dialogData.melding}
			isOpen={isOpen}
			onClose={onClose}
			onOk={onClose}
		/>
	),
	AvvikForm: ({ isOpen, onClose, dialogData, owner }) => {
		return (
			<NKModal size='large' isOpen={isOpen}>
				<AvvikForm
					onClose={onClose}
					steng={dialogData.steng}
					tittel={dialogData.tittel}
					objektReferanseType={dialogData.objektReferanseType}
					adresse={dialogData.adresse}
					objektReferanseId={dialogData.objektReferanseId}
					bruksenhetid={dialogData.bruksenhetid}
					onLagred={owner.onLagred}
					id={dialogData.id}
					avvikTekst={dialogData.avvikTekst}
				/>
			</NKModal>
		);
	},
	AnmerkningForm: ({ isOpen, onClose, dialogData, owner }) => {
		return (
			<NKModal size='large' isOpen={isOpen}>
				<AnmerkningForm
					onClose={onClose}
					objektReferanseType={dialogData.objektReferanseType}
					adresse={dialogData.adresse}
					objektReferanseId={dialogData.objektReferanseId}
					bruksenhetid={dialogData.bruksenhetid}
					onLagred={owner.onLagred}
					id={dialogData.id}
					type={dialogData.type}
				/>
			</NKModal>
		);
	},
};

class ListeMedAvvik extends React.Component {
	formatDato(datostring) {
		let date = new Date(datostring);
		var dateString =
			('0' + date.getDate()).slice(-2) +
			'.' +
			('0' + (date.getMonth() + 1)).slice(-2) +
			'.' +
			date.getFullYear();
		return dateString;
	}
	getLukket(lukket) {
		let res = 'Lukket';
		if (!lukket) {
			res = 'Åpen';
		}
		return res;
	}

	render() {
		if (!this.props.liste) {
			return <div className='loader' />;
		}

		return (
			<div className='scroller w500'>
				{this.props.liste.map(function (avvik) {
					let sidemannClass = ' btn btn-default ';
					if (avvik.sidemannsKontroll) {
						sidemannClass = ' btn btn-info ';
					}

					var dId = 'd' + avvik.id.toString();
					var infoId = 'info' + avvik.id.toString();
					var bId = 'b' + avvik.id.toString();
					var beId = 'be' + avvik.id.toString();
					var lukketClass = 'malboksred ';
					var lukketIcon = 'ikke-valgt';
					if (avvik.lukket) {
						lukketClass = 'malboksgreen';
						lukketIcon = 'valgt';
					}

					var gjelderBygg = '';
					if (avvik.identifikasjon === 'Bygning') {
						gjelderBygg = '(Gjelder bygning)';
					}
					var objectIcon = bruksenhet_ikon;
					var moreInfo = '';
					var emne = '';
					var identi = '';
					var avvikTekst = avvik.avvikType;

					switch (avvik.objektType) {
						case 'Røykløp':
							emne = 'R';
							objectIcon = royk_ikon;
							identi = avvik.identifikasjon;
							if (avvik.identifikasjon) {
								moreInfo = (
									<div key={infoId} className='textright'>
										{identi}
									</div>
								);
							}
							break;
						case 'Ildsted':
							emne = 'I';
							objectIcon = ildsted_ikon;
							identi = avvik.identifikasjon;
							if (avvik.identifikasjon) {
								moreInfo = (
									<div key={infoId} className='textright'>
										{identi}
									</div>
								);
							}
							break;
						default:
							emne = 'B';
							objectIcon = bruksenhet_ikon;
					}

					return (
						<div key={dId} className='pad2'>
							<button
								key={bId}
								className={sidemannClass + lukketClass}
								onClick={this.props.onVelgRad.bind(
									null,
									avvik.id,
									avvik.objektReferanseId,
									emne,
									avvikTekst
								)}>
								<table className='btntable'>
									<tbody>
										<tr>
											<td className='btntd0'>
												<NKIcon icon={lukketIcon} color='nk-black' />
											</td>
											<td className='btntd1'>{this.formatDato(avvik.dato)}</td>
											<td className='btntd3'>
												{avvik.status}{' '}
												<div className='fontNormal'>{gjelderBygg}</div>
											</td>
											<td className='btntd0'>
												<img
													height='24'
													width='24'
													src={objectIcon}
													className='app-preloader__img pull-left'
													alt=''
												/>
											</td>
										</tr>
									</tbody>
								</table>
								{moreInfo}
								<p key={beId} className='textleft'>
									{avvik.avvikType}
								</p>
							</button>
						</div>
					);
				}, this)}
			</div>
		);
	}
}

class ListeMedAnmerkninger extends React.Component {
	formatDato(datostring) {
		let date = new Date(datostring);
		var dateString =
			('0' + date.getDate()).slice(-2) +
			'.' +
			('0' + (date.getMonth() + 1)).slice(-2) +
			'.' +
			date.getFullYear();
		return dateString;
	}

	htmlToPlainText(html) {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = html;
		return tempElement.textContent || tempElement.innerText || '';
	}

	truncateText(text, maxLength) {
		if (text.length > maxLength) {
			return text.substring(0, maxLength) + '...';
		}
		return text;
	}

	getLukket(lukket) {
		let res = 'Lukket';
		if (!lukket) {
			res = 'Åpen';
		}
		return res;
	}

	render() {
		if (!this.props.liste) {
			return <div>Laster..</div>;
		}
		return (
			<div className='scroller w500'>
				{this.props.liste.map(function (anmerkning) {
					let sidemannClass = ' btn btn-default ';
					if (anmerkning.sidemannsKontroll) {
						sidemannClass = ' btn btn-info ';
					}

					var dId = 'd' + anmerkning.id.toString();
					var infoId = 'info' + anmerkning.id.toString();
					var bId = 'b' + anmerkning.id.toString();
					var beId = 'be' + anmerkning.id.toString();
					var lukketClass = 'malboksred ';
					var lukketIcon = 'ikke-valgt';
					if (anmerkning.lukket) {
						lukketClass = 'malboksgreen';
						lukketIcon = 'valgt';
					}
					var objectIcon = bruksenhet_ikon;
					var moreInfo = '';
					var emne = '';
					var identi = '';

					switch (anmerkning.objektType) {
						case 'Røykløp':
							emne = 'R';
							identi = anmerkning.identifikasjon;
							objectIcon = royk_ikon;
							if (anmerkning.identifikasjon) {
								moreInfo = (
									<div key={infoId} className='textright'>
										{identi}
									</div>
								);
							}
							break;
						case 'Ildsted':
							emne = 'I';
							identi = anmerkning.identifikasjon;
							objectIcon = ildsted_ikon;
							if (anmerkning.identifikasjon) {
								moreInfo = (
									<div key={infoId} className='textright'>
										{identi}
									</div>
								);
							}
							break;
						default:
							emne = 'B';
							objectIcon = bruksenhet_ikon;
					}
					return (
						<div key={dId} className='pad2'>
							<button
								key={bId}
								className={sidemannClass + lukketClass}
								onClick={this.props.onVelgRad.bind(
									null,
									anmerkning.id,
									anmerkning.objektReferanseId,
									emne
								)}>
								<table className='btntable'>
									<tbody>
										<tr>
											<td className='btntd0'>
												<NKIcon icon={lukketIcon} color='nk-black' />
											</td>
											<td className='btntd1'>
												{this.formatDato(anmerkning.dato)}
											</td>
											<td className='btntd3'>{anmerkning.kategori}</td>
											<td className='btntd0'>
												<img
													height='24'
													width='24'
													src={objectIcon}
													className='app-preloader__img pull-left'
													alt=''
												/>
											</td>
										</tr>
									</tbody>
								</table>
								{moreInfo}
								<p key={beId} className='textleft'>
									{this.truncateText(this.htmlToPlainText(anmerkning.beskrivelse), 100)}
								</p>
							</button>
						</div>
					);
				}, this)}
			</div>
		);
	}
}

class ListeMedAndreForhold extends React.Component {
	formatDato(datostring) {
		let date = new Date(datostring);
		var dateString =
			('0' + date.getDate()).slice(-2) +
			'.' +
			('0' + (date.getMonth() + 1)).slice(-2) +
			'.' +
			date.getFullYear();
		return dateString;
	}
	getLukket(lukket) {
		let res = 'Lukket';
		if (!lukket) {
			res = 'Åpen';
		}
		return res;
	}

  htmlToPlainText(html) {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = html;
		return tempElement.textContent || tempElement.innerText || '';
	}

	truncateText(text, maxLength) {
		if (text.length > maxLength) {
			return text.substring(0, maxLength) + '...';
		}
		return text;
	}

	render() {
		if (!this.props.liste) {
			return <div>Laster..</div>;
		}
		return (
			<div className='scroller w500'>
				{this.props.liste.map(function (anmerkning) {
					var dId = 'd' + anmerkning.id.toString();
					var infoId = 'info' + anmerkning.id.toString();
					var bId = 'b' + anmerkning.id.toString();
					var beId = 'be' + anmerkning.id.toString();
					var lukketClass = 'malboksred ';
					var lukketIcon = 'ikke-valgt';
					if (anmerkning.lukket) {
						lukketClass = 'malboksgreen';
						lukketIcon = 'valgt';
					}
					var objectIcon = bruksenhet_ikon;
					var moreInfo = '';
					var emne = '';
					var identi = '';

					switch (anmerkning.objektType) {
						case 'Røykløp':
							emne = 'R';
							identi = anmerkning.identifikasjon;
							objectIcon = royk_ikon;
							if (anmerkning.identifikasjon) {
								moreInfo = (
									<div key={infoId} className='textright'>
										{identi}
									</div>
								);
							}
							break;
						case 'Ildsted':
							emne = 'I';
							identi = anmerkning.identifikasjon;
							objectIcon = ildsted_ikon;
							if (anmerkning.identifikasjon) {
								moreInfo = (
									<div key={infoId} className='textright'>
										{identi}
									</div>
								);
							}
							break;
						default:
							emne = 'B';
							objectIcon = bruksenhet_ikon;
					}
					return (
						<div key={dId} className='pad2'>
							<button
								key={bId}
								className={'btn btn-default ' + lukketClass}
								onClick={this.props.onVelgRad.bind(
									null,
									anmerkning.id,
									anmerkning.objektReferanseId,
									emne
								)}>
								<table className='btntable'>
									<tbody>
										<tr>
											<td className='btntd0'>
												<NKIcon icon={lukketIcon} color='nk-black' />
											</td>
											<td className='btntd1'>
												{this.formatDato(anmerkning.dato)}
											</td>
											<td className='btntd3'>{anmerkning.kategori}</td>
											<td className='btntd0'>
												<img
													height='24'
													width='24'
													src={objectIcon}
													className='app-preloader__img pull-left'
													alt=''
												/>
											</td>
										</tr>
									</tbody>
								</table>
								{moreInfo}
								<p key={beId} className='textleft'>
                {this.truncateText(this.htmlToPlainText(anmerkning.beskrivelse), 100)}
								</p>
							</button>
						</div>
					);
				}, this)}
			</div>
		);
	}
}

class AlleAvvikAnmerkninger extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anmerkninger: [],
			anmerkningerCount: 0,
			avviker: [],
			avvikerrCount: 0,
			andreForhold: [],
			andreForholdCount: 0,
			valgtAnmerkningId: 0,
			valgtAvvikId: 0,
			valgtIdenti: '',
			valgtEmne: '',
			fyringsforbud: false,
			laster: true,
		};
		this.sorterEtterType = this.sorterEtterType.bind(this);
		this.getData = this.getData.bind(this);
		this.onLagred = this.onLagred.bind(this);

		this.dialogs = new DialogStore({
			owner: this,
			dialogs: modalDialogs,
		});
	}

	componentDidMount() {
		this.getData();
		debug('MANDAGX', this.props);
	}

	getData() {
		avvikApi.getAvvikOgAnmerkningByBruksenhetId(
			this.props.bruksenhetid,
			this.gotData.bind(this)
		);
		getFyringsforbudForBruksenhet(
			this.props.bruksenhetid,
			this.gotBruksenhetFyring
		);
	}

	gotData(err, data) {
		if (data !== undefined) {
			//   let avDato = data.avvikListe.sort(this.sortDato);
			this.setState({ avviker: data.avvikListe });
			this.setState({ avvikerCount: data.avvikListe.length });

			//   let anmDato = data.anmerkningListe.sort(this.sortDato);
			let anm = [];
			let andre = [];
			data.anmerkningListe.forEach((element) => {
				if (element.andreForhold) {
					andre.push(element);
				} else {
					anm.push(element);
				}
			});
			// this.setState({ anmerkninger: data.anmerkningListe });
			// this.setState({ anmerkningerCount: data.anmerkningListe.length });
			this.setState({ anmerkninger: anm });
			this.setState({ anmerkningerCount: anm.length });
			this.setState({ andreForhold: andre });
			this.setState({ andreForholdCount: andre.length });
		}
	}

	gotBruksenhetFyring = (err, res) => {
		console.log('fyring');
		this.setState({ fyringsforbud: res, laster: false });
	};

	gotDataOld(err, data) {
		//Man ønsker ikke sorteringen??
		if (data !== undefined) {
			let avDato = data.avvikListe.sort(this.sortDato);
			avDato = this.sorterEtterType(avDato);
			this.setState({ avviker: avDato });
			this.setState({ avvikerCount: data.avvikListe.length });

			let anmDato = data.anmerkningListe.sort(this.sortDato);
			anmDato = this.sorterEtterType(anmDato);
			this.setState({ anmerkninger: anmDato });
			this.setState({ anmerkningerCount: data.anmerkningListe.length });
		}
	}

	sortDato(a, b) {
		var dateA = new Date(a.dato).getTime();
		var dateB = new Date(b.dato).getTime();
		return dateA > dateB ? -1 : 1;
	}

	sorterEtterType(liste) {
		let result = [];
		for (let i = 0; i < liste.length; i++) {
			if (liste[i].objektType.toString().startsWith('B')) {
				result.push(liste[i]);
			}
		}
		for (let i = 0; i < liste.length; i++) {
			if (liste[i].objektType.toString().startsWith('R')) {
				result.push(liste[i]);
			}
		}
		for (let i = 0; i < liste.length; i++) {
			if (liste[i].objektType.toString().startsWith('I')) {
				result.push(liste[i]);
			}
		}

		return result;
	}

	radAvvikValgt(id, identi, emne, avvikTekst) {
		console.log('her ', id, identi, emne, avvikTekst);
		this.setState(
			{
				valgtIdenti: identi,
				valgtAvvikId: id.toString(),
				valgtEmne: emne,
				avvikTekst: avvikTekst,
			},
			this.openAvvik
		);
	}

	radAnmerkningValgt(id, identi, emne) {
		this.setState(
			{
				valgtIdenti: identi,
				valgtAnmerkningId: id.toString(),
				valgtEmne: emne,
			},
			this.openAnmerkning
		);
	}

	radAndreValgt = (id, identi, emne) => {
		this.setState(
			{
				valgtIdenti: identi,
				valgtAnmerkningId: id.toString(),
				valgtEmne: emne,
			},
			this.openAnmerkningAndre
		);
	};

	openAvvik() {
		this.dialogs.setOpenDialog('AvvikForm', {
			tittel: this.props.tittel,
			objektReferanseType: this.state.valgtEmne,
			adresse: this.props.adresse,
			objektReferanseId: this.state.valgtIdenti,
			bruksenhetid: this.props.bruksenhetid,
			id: this.state.valgtAvvikId,
			avvikTekst: this.state.avvikTekst,
			steng: this.props.onClose,
		});
	}

	openAnmerkning() {
		this.dialogs.setOpenDialog('AnmerkningForm', {
			tittel: this.props.tittel,
			objektReferanseType: this.state.valgtEmne,
			adresse: this.props.adresse,
			objektReferanseId: this.state.valgtIdenti,
			bruksenhetid: this.props.bruksenhetid,
			id: this.state.valgtAnmerkningId,
			type: 'Anmerkning',
		});
	}

	openAnmerkningAndre = () => {
		this.dialogs.setOpenDialog('AnmerkningForm', {
			tittel: this.props.tittel,
			objektReferanseType: this.state.valgtEmne,
			adresse: this.props.adresse,
			objektReferanseId: this.state.valgtIdenti,
			bruksenhetid: this.props.bruksenhetid,
			id: this.state.valgtAnmerkningId,
			type: 'Andre',
		});
	};

	// closeAvvik() {
	//     this.setState({ modalAvvikIsOpen: false });
	// }

	// closeAnmerkning() {
	//     this.setState({ modalAnmerkningIsOpen: false });
	// }

	onLagred() {
		debug('getData');
		this.getData();
	}

	updateBruksenhet = () => {
		this.setState({ fyringsforbud: !this.state.fyringsforbud }, () =>
			saveFyringsforbudForBruksenhet(
				this.props.bruksenhetid,
				this.state.fyringsforbud,
				this.fyringLagret
			)
		);
	};

	fyringLagret = () => {};

	fyringsInfo = () => {
		this.dialogs.setOpenDialog('OkDialog', {
			tittel: 'Fyringsforbud informasjon',
			melding:
				'Hvis du krysser av for dette valget, vil objektet vises med svart farge i kartet i planleggingsbildet, slik at man unngår å planlegge tiltak på disse bruksenhetene.' +
				'\nViktig: Endringene vil IKKE påvirke en eventuell risikovurdering av fyringsforbud registrert som avvik.',
		});
	};
	render() {
		if (this.state.laster) {
			return <div>Laster..</div>;
		}
		return (
			<div className='modal-content'>
				<div className='modal-header'>
					<button className='close' type='button' onClick={this.props.onClose}>
						<span>x</span>
						<span className='sr-only'>Lukk</span>
					</button>
					<h4 className='modal-title'> Avvik, anmerkninger og andre forhold</h4>
					<h4 className='modal-title'> {this.props.tittel}</h4>
				</div>
				<div className='modal-body'>
					<br />
					<div className='row'>
						<div className='col-sm-12'>
							<ul className='nav nav-pills m6'>
								<li className='active'>
									<a className='h48' data-toggle='pill' href='#avvik'>
										Avvik ({this.state.avvikerCount})
									</a>
								</li>
								<li>
									<a className='h48' data-toggle='pill' href='#anmerk'>
										{' '}
										Anmerkninger ({this.state.anmerkningerCount})
									</a>
								</li>
								<li>
									<a className='h48' data-toggle='pill' href='#andremerk'>
										{' '}
										Andre forhold ({this.state.andreForholdCount})
									</a>
								</li>
							</ul>
						</div>
					</div>
					<br />
					<div className='row'>
						<div className='col-sm-5'>
							<div
								className='form-group'
								style={{
									marginTop: '5px',
									marginLeft: '10px',
									marginBottom: '0px',
								}}>
								<label>
									<input
										className='checkbox20'
										type='checkbox'
										checked={this.state.fyringsforbud}
										onChange={(e) => {
											this.updateBruksenhet();
										}}
									/>
									<span className='checkboxtext14'>
										Fyringsforbud på bruksenhet
									</span>
								</label>
							</div>
							{(this.props.sidemannAvvik ||
								this.props.sidemannAnmerkninger) && (
								<div
									style={{
										color: 'blue',
										fontSize: '12px',
										marginLeft: '10px',
									}}>
									Blå farge = del av dokumentkontroll
								</div>
							)}
						</div>
						<div style={{ padding: 0 }} className='col-sm-7'>
							<div style={{ marginTop: '5px' }}>
								<span
									data-toggle='tooltip'
									title='Hvis du krysser av for dette valget, vil objektet vises med svart farge i kartet i planleggingsbildet, slik at man unngår å planlegge tiltak på disse bruksenhetene. Viktig: Endringene vil IKKE påvirke en eventuell risikovurdering av fyringsforbud registrert som avvik.'>
									<NKIcon
										onClick={this.fyringsInfo}
										className='pull-left'
										icon='info'
										color='nk-black'
										size='1.2'
									/>
								</span>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-sm-12'>
							<br />
							<div className='tab-content'>
								<div id='avvik' className='tab-pane fade in active'>
									<ListeMedAvvik
										liste={this.state.avviker}
										onVelgRad={this.radAvvikValgt.bind(this)}
										sidemannAvvik={this.props.sidemannAvvik}
									/>
								</div>
								<div id='anmerk' className='tab-pane fade'>
									<ListeMedAnmerkninger
										liste={this.state.anmerkninger}
										onVelgRad={this.radAnmerkningValgt.bind(this)}
										sidemannAnmerkninger={this.props.sidemannAnmerkninger}
									/>
								</div>

								<div id='andremerk' className='tab-pane fade'>
									<ListeMedAndreForhold
										liste={this.state.andreForhold}
										onVelgRad={this.radAndreValgt.bind(this)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.dialogs.renderOpenDialog()}
			</div>
		);
	}
}

export default AlleAvvikAnmerkninger;
