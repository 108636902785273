import React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import NKIcon from 'nka-icons';
import './ContextMenu.css';
import { debugFactory } from 'utils';
const debug = debugFactory('nk:MenuZ');

var Css = {
  black: {
    color: 'black',
    fontSize: '14px',
    cursor: 'pointer',
  },
  menu: {
    width: '24px',
    height: '24px',
    padding: 0,
  },
  div: {
    width: '20px',
    height: '20px',
    padding: 0,
  },
};

const ContextMenuItem = function({ ...props }) {
  return (
    <MenuItem
      className='padding2' //padding4
      text={props.children}
      {...props}
    />
  );
};
const ContextMenuDivider = function(props) {
  return (
    <ContextMenuItem divider {...props}>
      {props.children}
    </ContextMenuItem>
  );
};
const ContextMenuHeader = function(props) {
  return (
    <ContextMenuItem header {...props}>
      {props.children}
    </ContextMenuItem>
  );
};

class ContextMenuButton extends React.Component {
  state = {
    open: false,
  };

  onClickStopPropagation = (e) => {
    e.stopPropagation();

    //Lukk alle som er åpent  Rieni 24.06.2022
    var list = document.getElementsByName('menux');
    for (var item of list) {
      if (item.ariaExpanded === 'true' ){
        item.click();
      }
    }
  };

  render() {
    let { children } = this.props;

    return (
      <div onClick={this.onClickStopPropagation} style={Css.div}>
        <DropdownButton
          id='menu1'
          name='menux'
          className='dropdownButtonMenu'
          title={
            <div>
              <NKIcon icon='meny' color='nk-black' size='1' />
            </div>
          }
          noCaret>
          {children}
        </DropdownButton>
      </div>
    );
  }
}

export const ContextMenu = ({ children, ...props }) => (
  <DropdownButton {...props}>{children}</DropdownButton>
);
ContextMenu.Item = ContextMenuItem;
ContextMenu.Header = ContextMenuHeader;
ContextMenu.Divider = ContextMenuDivider;
ContextMenu.Button = ContextMenuButton;

export default ContextMenu;
