import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import Enheter from './Enheter';
import Enhet from './Enhet';
import Tiltakstyper from './Tiltakstyper';
import NyTiltakstype from './Tiltakstyper/NyTiltakstype.jsx';
import EndreTiltakstype from './Tiltakstyper/EndreTiltakstype.jsx';
import NyTiltakshistorikk from './Tiltakshistorikk/NyTiltakshistorikk.jsx';
import EndreTiltakshistorikk from './Tiltakshistorikk/EndreTiltakshistorikk.jsx';
import Tiltakshistorikk from './Tiltakshistorikk';
import Dialoghistorikk from './Dialoghistorikk';
import Arkiv from './Arkiv';
import { enhanceComponents } from 'utils';


const enhanced = enhanceComponents({
    Index,
    Enheter,
    Enhet,
    Tiltakstyper,
    NyTiltakstype,
    EndreTiltakstype,
    Tiltakshistorikk,
    NyTiltakshistorikk,
    EndreTiltakshistorikk,
    Dialoghistorikk,
    Arkiv
}, [
        asRouteComponent,
    ]);

export default (
    <Route path="gjennomforing" component={enhanced.Index} name="Gjennomføring" icon="gjennomforing" inMenu={true} login={true} roles={[]}>
        <Route path=":arbeidslisteId/:arbeidslisteNavn" component={enhanced.Enheter} name="Arbeidsliste">
            <Route path=":enhetId" component={enhanced.Enhet} name="Enhet">
                <Route path="tiltakstyper" component={enhanced.Tiltakstyper} name='Regelmessige tiltak'>
                    <Route path="Nytt" component={enhanced.NyTiltakstype} name='Nytt regelmessig tiltak' />
                    <Route path="Endre" component={enhanced.EndreTiltakstype} name='Endre regelmessig tiltak' />
                </Route>
                <Route path="tiltakshistorikk" component={enhanced.Tiltakshistorikk} name="Tiltakshistorikk">
                    <Route path="Nytt" component={enhanced.NyTiltakshistorikk} name='Nytt historisk tiltak' />
                    <Route path="Endre" component={enhanced.EndreTiltakshistorikk} name='Endre historisk tiltak' />
                </Route>
                <Route path="dialoghistorikk" component={enhanced.Dialoghistorikk} name="Innbyggerdialog" />
                <Route path="arkiv" component={enhanced.Arkiv} name="Arkiv"></Route>
            </Route>
        </Route>
    </Route>
);
