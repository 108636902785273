import React from 'react';
import { getBruksenhetMatrise, getBruksenhetMatriseFritid, getRoyklopmatrise, getRoyklopmatriseFritid } from 'api/risikoApi.js';

class Matrise extends React.Component {
  constructor(props) {
      super(props);
    }
state = {  };


  componentDidMount() {
    getBruksenhetMatrise(this.gotBruksenhetMatrise);
  }

  gotBruksenhetMatrise = (err, res) => {
    console.log(res);
  }

  render() {
    return (
      <div>
        <h2>Matrise</h2>
      </div>
    );
  }
}
export default Matrise;