/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import SmsEditor from 'components/Editor/SmsEditor.jsx';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
import VisKontaktinfoForSms from './VisKontaktinfoForSms.jsx';
import { sendGenerellSmsTilBruksenheter } from 'api/smsApi';
import { debugFactory } from 'utils';
import Spinner from 'components/generics/Spinner';
import NKModal from 'components/Dialogs/NKModal.jsx';

const debug = debugFactory('nk:KansellerDialog');
//kopier: import HurtigdelDialog from './KansellerDialog.jsx';
let tekst = '';
let alleUgyldige = true;

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  VisKontaktinfoForSms: ({ isOpen, onClose, dialogData, owner }) => (
    <VisKontaktinfoForSms
      isOpen={isOpen}
      onClose={onClose}
      kontaktListe={dialogData.kontaktListe}
      onOk={onClose(() => owner.okSend())}
    />
  )
};

const flettefelt = [
  {
    "funksjonId": 1,
    "navn": "Bruksenhetens adresse",
    "flettefelt": "#fullstendigadresse#",
    "defaultVerdi": "",
    "fletteEksempel": "Mumidalen 10 C H0101",
    "toveisSms": false
  },
  {
    "funksjonId": 1,
    "navn": "Organisasjon navn",
    "flettefelt": "#org_navn#",
    "defaultVerdi": "",
    "fletteEksempel": "Sydenfjord Interkommunale Brannvesen",
    "toveisSms": false
  },
  {
    "funksjonId": 1,
    "navn": "Organisasjon telefon",
    "flettefelt": "#org_tlf#",
    "defaultVerdi": "",
    "fletteEksempel": "99118899",
    "toveisSms": false
  },
  {
    "funksjonId": 1,
    "navn": "Saksbehandler",
    "flettefelt": "#saksbehandler#",
    "defaultVerdi": "",
    "fletteEksempel": "Hans Ødestrand",
    "toveisSms": false
  },
  {
    "funksjonId": 1,
    "navn": "Saksbehandler telefon",
    "flettefelt": "#saksbehandler_telefon#",
    "defaultVerdi": "",
    "fletteEksempel": "99887766",
    "toveisSms": false
  },
];


class SmsGenerellDialog extends Component {
  constructor(props) {
    super(props);
    this.smseditorRef = null;
    this.state = {
      valgteEnheter: [],
      isLoading: false,
      enheterMedEiere: [],
      editortext: '',
      tittel: '..'
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    console.log(this.props.valgteEnheter);
    switch (this.props.modus) {
      case 'multi':
        this.setState({ tittel: 'Send SMS til flere mottakere' });
        break;
      case 'single':
        this.setState({ tittel: 'Send SMS til enkel mottaker' });
        break;
      
      default:
        this.setState({ tittel: 'Send' });
    }
  }

  getEditorTekst = () => {
    let s = '';
    let editor = this.smseditorRef;
    if (editor) {
      s = editor.getMaltekst();
    }
    return s;
  };

  submit = () => {
    tekst = this.getEditorTekst();
    if (tekst === 'ERROR') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'For mange tegn i sms',
        melding:
          'For å unngå feil ved sending av sms og for å unngå ekstra kostnader håndterer vi en begrensing av 500 tegn.'
      });
      return;
    }
    if (tekst === '') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Sms tekst',
        melding:
          'Sms teksten er tom.'
      });
      return;
    }

    let bruksenhetids = [];
    if (this.props.modus === 'multi') {
      let liste = this.props.valgteEnheter;
      liste.forEach(element => {
        bruksenhetids.push(element.id.toString());
       });
    } else {
      bruksenhetids.push(this.props.valgteEnheter.toString());
    }
    
    getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakter);
    this.setState({ isLoading: true });
  };

  gotEierkontakter = (err, res) => {
    this.setState( { eierkontakter: res, isLoading: false });
    let kontaktListe = [];
    res.forEach(e => {
      e.kontakt.forEach(k => {
        let kont = {
          navn: k.navn,
          gjelderAdresse: e.bruksenhetAdresse,
          ugyldigTelefonNr: k.ugyldigTelefonNr,
          telefon: k.telefon
        };
        kontaktListe.push(kont);
        if (!k.ugyldigTelefonNr) {
          alleUgyldige = false;
        }
      });
    });
    this.dialogs.setOpenDialog('VisKontaktinfoForSms', {
      kontaktListe: kontaktListe
    });
  }

  okSend = () => {
      this.setState({ isLoading: true });
      let dto = {
        smsTekst: tekst,
        bruksenhetKontakt: this.state.eierkontakter 
      };
      sendGenerellSmsTilBruksenheter(dto, this.smsSent);
    // console.log(alleUgyldige);
    // if (alleUgyldige) {
    //   this.dialogs.setOpenDialog('OkDialog', {
    //     tittel: 'Sms mottakere',
    //     melding:
    //       'Ingen mottakere har gyldig telefonnummer'
    //   });
    // } 
    // } else {
    //   let dto = {
    //     smsTekst: tekst,
    //     bruksenhetKontakt: this.state.eierkontakter 
    //   };
    //   sendGenerellSmsTilBruksenheter(dto, this.smsSent);
    // } 
  }

  smsSent = (err, res) => {
    this.setState({ isLoading: false });
    if (this.props.modus === 'multi') {
      this.props.refreshArbeidsliste();
    } else {
      this.props.smsSent();
    }
    
  }
 
  render() {
    if (this.state.isLoading == true) {
      return (
        <div>
        <Spinner className='h4' />
      <div>{this.dialogs.renderOpenDialog()}</div>
      </div>
      );
    }

    return (
    
    <div className="modal-content">
        <div className='modal-header modal-header-info'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <table>
            <tbody>
              <tr>
                <td>
                  <h4 className='modal-title'>{this.state.tittel}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* BODY */}
        <div
          className='modal-body'
          style={{
            height: '90%',
            paddingTop: '4px',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingBottom: '4px'
          }}>
          <div>
            <React.Fragment>
              <p> </p>
                <SmsEditor
                  ref={el => {
                    this.smseditorRef = el;
                  }}
                  cols='70'
                  rows='6'
                  visTekstbiter= {true}
                  maltext={this.state.editortext}
                  flettefelt={flettefelt}></SmsEditor>   
            </React.Fragment>
            <p/>
            <p/>
          </div>
        </div>
        <div className="modal-footer">
          <button onClick={this.submit} className='btn btn-primary pull-right'>
            Send
          </button>
        </div>

        {this.dialogs.renderOpenDialog()}

      </div>
      
    );
  }
  
}

export default SmsGenerellDialog;
