import callApi from './api';

export function getGebyrHovedtall(eindomsIdent, callback) {
    callApi('GET', '/gebyrinnsyn/gebyrhovedtall/' + eindomsIdent, null, callback);
};

export function getGebyrDetaljer(kundeId, avtaleNr, callback) {
    callApi('GET', '/gebyrinnsyn/gebyrdetaljer/' + kundeId + '/' + avtaleNr, null, callback);
};

export function getGebyrForBruksenhet(bruksenhetfagid, callback) {
    callApi('GET', '/gebyrinnsyn/bruksenhet/' + bruksenhetfagid, null, callback);
};

export function postBruksenhetAvtaleKobling(bruksenhetAvtale, callback) {
    callApi('POST', '/gebyrinnsyn/bruksenhetavtalekobling/', bruksenhetAvtale, callback);
}

export function gebyravvikAvtaleMelding(dto, callback) {
    callApi('POST', '/gebyrinnsyn/gebyravvikAvtaleMelding/', dto, callback);
}

export function gebyravvikEiendomMelding(dto, callback) {
    callApi('POST', '/gebyrinnsyn/gebyravvikEiendomMelding/', dto, callback);
}

export function gebyrEiendom(matrikkelIds, callback) {
    callApi('POST', '/gebyrinnsyn/gebyreiendom/', matrikkelIds, callback);
}

export function getGebyrForMaid(maid, callback) {
    callApi('GET', '/gebyrinnsyn/bruksenhet/maid/' + maid, null, callback);
};






