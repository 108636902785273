import React, { useState } from 'react';
import okV from 'images/risiko/okV.png';
import errorV from 'images/risiko/errorV.png';
import neutral from 'images/risiko/ingenV.png';
import enhet from 'images/hjem.png';
import royklop from 'images/royklop.png';
import moment from 'moment';
import NKIcon from 'nka-icons';
import { objGetDeep } from 'utils';
import './InfoRad.css';

const InfoRad = React.forwardRef(({ ...props }, ref) => {
  const [visResultat, setVisResultat] = useState(false);

  const getImageSta = () => {
    if (objGetDeep(props, 'info.status')) {
      if (props.info.status.includes('SUCCESS')) {
        return okV;
      }
      if (props.info.status.includes('FAIL')) {
        return errorV;
      }
      return neutral;
    } else {
      return '';
    }
  };

  const getImageObj = () => {
    if (objGetDeep(props, 'info.objekt')) {
      if (props.info.objekt === 'ENHET') {
        return enhet;
      }
      if (props.info.objekt === 'ROYKLOP') {
        return royklop;
      }
    } else {
      return '';
    }
  };

  const makeRisikoVerdi = () => {
    let s = '';
    if (objGetDeep(props, 'info.analyseResultat.risikoverdi')) {
      s = props.info.analyseResultat.risikoverdi;
      if (s < 10) {
        return <span>&nbsp;&nbsp;{s.toString()}</span>;
      } else {
        return <span>{s.toString()}</span>;
      }
    } else {
      return '';
    }
  };

  const getStyle = () => {
    if (visResultat) {
      return {
        backgroundColor: '#F0F0F0',
        height: '30px',
        borderBottom: '1px solid lightGray',
      };
    } else {
      return { height: '30px', borderBottom: '1px solid lightGray' };
    }
  };

  return (
    <>
      <tr className='info-table' style={getStyle()}>
        <td>
          {' '}
          <span>
            <img
              height='18'
              width='18'
              src={getImageObj()}
              alt=''
              title={props.info.objekt}
            />
          </span>
        </td>
        <td>
          <span style={{ marginLeft: '10px', fontSize: '12px' }}>
            {objGetDeep(props, 'info.analysetype') ? props.info.analysetype: ''}
          </span>
        </td>
        <td>
          <span style={{ marginLeft: '8px' }}>
            <img
              height='18'
              width='18'
              src={getImageSta()}
              alt=''
              title= {objGetDeep(props, 'info.status') ? props.info.status: ''}
            />
          </span>
        </td>

        <td>
          <span> {objGetDeep(props, 'info.tidspunkt') ? props.info.tidspunkt: ''} </span>
        </td>
        <td>
          {' '}
          <span
            style={{
              fontWeight: 'bold',
              marginLeft: '18px',
              textAlign: 'right',
            }}>
            {makeRisikoVerdi()}
          </span>
        </td>
        <td>
          <span style={{ fontWeight: 'bold' }}>
            {objGetDeep(props, 'info.analyseResultat.datoNesteTiltak') 
              ? moment(props.info.analyseResultat.datoNesteTiltak).format(
                  'DD.MM.YYYY'
                )
              : ''}
          </span>
        </td>
        <td>
          <span>
            {objGetDeep(props, 'info.analyseResultat.tiltakType') 
              ? props.info.analyseResultat.tiltakType
              : ''}
          </span>
        </td>
        <td>
          <span style={{ marginLeft: '20px' }}>
            { objGetDeep(props, 'info.analyseResultat.hyppighetTekst') 
              ? props.info.analyseResultat.hyppighetTekst
              : ''}
          </span>
        </td>

        <td
          title='Vis / skjul resultat'
          style={{ cursor: 'hand' }}
          onClick={() => setVisResultat(!visResultat)}>
          {/*  <span style={{ color: '#337ab7', marginBottom: '6px' }}>Mer..</span> */}
          {visResultat ? (
            <NKIcon icon='opp' size='1.4' color='nk-black' />
          ) : (
            <NKIcon icon='ned' size='1.4' color='nk-black' />
          )}
        </td>
      </tr>
      {visResultat && (
        <tr>
          <td colspan='9'>
            <>
              <p>
                <div
                  style={{
                    marginLeft: '50px',
                    fontSize: '12px',
                    minHeight: '50px',
                    borderTop: '1px solid lightgray',
                  }}>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Faktor konsekvens:</span>
                  <br />
        
                   { objGetDeep(props, 'info.analyseResultat.faktorKonsekvens')  && (
                      <table>
                        <tbody>
                          {props.info.analyseResultat.faktorKonsekvens.map(
                            (faktor, i) => (
                              <tr>
                                <td style={{ width: '150px' }}>
                                  {faktor.faktor}
                                </td>
                                <td style={{ width: '10px' }}></td>
                                <td style={{ width: '150px' }}>
                                  {faktor.faktorTekst}
                                </td>
                                <td style={{ width: '10px' }}></td>
                                <td>Verdi:</td>
                                <td style={{ width: '4px' }}></td>
                                <td>{faktor.faktorverdi}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}

                  <br />
                </div>
              </p>

              <p>
                <div
                  style={{
                    marginLeft: '50px',
                    fontSize: '12px',
                    minHeight: '50px',
                    borderTop: '1px solid lightgray',
                  }}>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    Faktor sannsynlighet:
                  </span>
                  <br />
                  { objGetDeep(props, 'info.analyseResultat.faktorSannsynlighet')  && (
                      <table>
                        <tbody>
                          {props.info.analyseResultat.faktorSannsynlighet.map(
                            (faktor, i) => (
                              <tr>
                                <td style={{ width: '150px' }}>
                                  {faktor.faktor}
                                </td>
                                <td style={{ width: '10px' }}></td>
                                <td style={{ width: '150px' }}>
                                  {faktor.faktorTekst}
                                </td>
                                <td style={{ width: '10px' }}></td>
                                <td>Verdi:</td>
                                <td style={{ width: '4px' }}></td>
                                <td>{faktor.faktorverdi}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    )}
                  <br />
                </div>
              </p>

              <p>
                <div
                  style={{
                    marginLeft: '50px',
                    fontSize: '12px',
                    minHeight: '50px',
                    borderTop: '1px solid lightgray',
                  }}>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Minste risiko:</span>
                  <br />
                  { objGetDeep(props, 'info.analyseResultat.minsteRisiko')  && (
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: '150px' }}>
                              {props.info.analyseResultat.minsteRisiko.faktor}
                            </td>
                            <td style={{ width: '10px' }}></td>
                            <td style={{ width: '150px' }}>
                              {
                                props.info.analyseResultat.minsteRisiko
                                  .faktorTekst
                              }
                            </td>
                            <td style={{ width: '10px' }}></td>
                            <td>Verdi:</td>
                            <td style={{ width: '4px' }}></td>
                            <td>
                              {
                                props.info.analyseResultat.minsteRisiko
                                  .risikoverdi
                              }
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  <br />
                </div>
              </p>
            </>
          </td>
        </tr>
      )}
    </>
  );
});
export default InfoRad;
