import React from 'react';
import _ from 'lodash';
import KartDialog from 'components/Kart/KartDialog.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import ReactModal from 'react-modal';
import { getMatrikkelnummer, getAdresse } from 'modules/felles/AdresseHelper';
import ReactTable from '@norkart/react-table';
import ContextMenu from 'components/ContextMenu';
import moment from 'moment';
import DialogStore from 'lib/DialogStore';
import Debug from 'debug';
import FagKronRed from 'images/Annet/FagKronRed.png';
import FagKronYellow from 'images/Annet/FagKronYellow.png';
import FagKronGreen from 'images/Annet/FagKronGreen.png';
import GebyrDialog from 'modules/BraGe/GebyrDialog.jsx';
import {
  contextInnstillingerPerson,
  getContextGebyrInnstillinger,
} from 'context/contextInnstillinger.js';
import './fagdata.css';

const debug = Debug('nk:EnhetTabell');

const modalDialogs = {
  MatrikkelInfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ModalContainer
      size='medium'
      buttonText={'Lukk'}
      tittel='Matrikkelinfo'
      isOpen={isOpen}
      onClose={onClose}>
      <Matrikkelinfo id={dialogData.matrikkelinfoId} />
    </ModalContainer>
  ),
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose}
      itemId={dialogData.itemId}
      /* bruksenhetIdMa={dialogData.bruksenhetIdMa} */
      itemAdresse={dialogData.itemAdresse}
      eiendom={dialogData.matrikkelnummer}
      fraFiktive={false}
    />
  ),
  KartDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal
      isOpen={isOpen}
      onClose={onClose}
      className='FModal'
      contentLabel=''>
      <KartDialog
        visning='objekter-fagdata'
        onClose={onClose}
        data={dialogData.item}
        tittel={dialogData.tittel}
        adresse={dialogData.tittel}
        eiendom={dialogData.matrikkelnummer}
      />
    </ReactModal>
  ),
  GebyrDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrDialog
        isOpen={isOpen}
        onClose={onClose}
        adresse={dialogData.adresse}
        matrikkelId={dialogData.matrikkelId}
        fagbruksenhetId={dialogData.fagbruksenhetId}
        maid={dialogData.maid}
        fraEtablerFag={true}
      />
    </NKModal>
  ),
};

class EnhetTabell extends React.Component {
  constructor() {
    super();
    this.state = { visGebyr: false };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.rowFormat = this.rowFormat.bind(this);
  }

  componentDidMount = () => {
    //For å unngå en warning:
    //Warning: react-modal: App element is not defined.
    ReactModal.setAppElement('body');
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
  };

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  prepareKart(item) {
    let obj = {
      id: item._id,
      kommune: item.formatKommune,
      adresse: item.formatAdresse,
      avtaler: item.formatAvtaler,
      bruksenhetType: item.formatBruksenhetType,
      bygningStatus: item.formatBygningStatus,
      dato: item.formatDato,
      eiendom: item.formatEiendom,
      gebyrType: item.gebyrTypeKode,
    };
    this.dialogs.setOpenDialog('KartDialog', {
      item: [obj],
      tittel: item.formatAdresse,
      eiendom: item.formatEiendom,
    });
  }

  onClickSituasjon(item) {
    let enhetid = item._id;
    let adresse = item._source ? getAdresse(item) : 'null';
    let ei = getMatrikkelnummer(item._source);
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemId: enhetid,
      //  bruksenhetIdMa: item._source.id,
      itemAdresse: adresse,
      eiendom: ei,
    });
  }

  onClickMatr = (item) => {
    this.dialogs.setOpenDialog('MatrikkelInfoDialog', {
      matrikkelinfoId: item._id,
    });
  };

  visGebyr = (item) => {
    let adresse = item._source ? getAdresse(item) : 'null';
    let matrikkelId = item._source.matrikkelenhet.id;
    let maid = item._id;

    this.dialogs.setOpenDialog('GebyrDialog', {
      adresse: adresse,
      matrikkelId: matrikkelId,
      fagbruksenhetId: null,
      maid: maid,
    });
  };

  getColumns = (item) => {
    let columns = [];
    if (this.state.visGebyr) {
      columns = this.getColumnsGebyr(item);
    } else {
      columns = this.getColumnsOrg(item);
    }
    return columns;
  };

  getColumnsGebyr = (item) => {
    return [
      {
        id: 'kommune',
        name: 'Kommune',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let k = _.get(item, '_source.kommune.kommuneNavn');
          item.formatKommune = k;
          return k;
        },
      },
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'adresse',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let a = getAdresse(item._source);
          item.formatAdresse = a;
          return a;
        },
      },
      {
        id: 'opprettetDato',
        name: 'Oppr.dato',
        sortParams: 'opprettetDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let d = _.get(item, '_source.opprettetDato');
          if (d) {
            let dd = moment(item._source.opprettetDato).format('DD.MM.YYYY');
            item.formatDato = dd;
            return dd;
          } else {
            return '';
          }
        },
      },
      {
        id: 'eiendom',
        name: 'Eiendom',
        sortParams: 'eiendom',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let m = getMatrikkelnummer(item._source);
          item.formatEiendom = m;
          return m;
        },
      },
      {
        id: 'type',
        name: 'Br.type',
        sortParams: 'type',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.get(item, '_source.bruksenhetstype');
          item.formatBruksenhetType = s;
          return (
            <div title={s} className='ellipsisFag'>
              {s}
            </div>
          );
        },
      },
      {
        id: 'bygningstatus',
        name: 'Bygn.status',
        sortParams: 'bygningstatus',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.get(item, '_source.bygg.bygningstatus');
          item.formatBygningStatus = s;
          return (
            <div title={s} className='ellipsisFag'>
              {s}
            </div>
          );
        },
      },
      {
        id: 'avtalenr',
        name: 'Avtalenr.',
        sortParams: 'avtalenr',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          item.gebyrTypeKode = '';
          if (!this.props.hitsGebyr) {
            return '';
          }
          if (this.props.hitsGebyr === []) {
            return '';
          }
          if (_.get(item, '_source.matrikkelenhet.id')) {
            let m = item._source.matrikkelenhet.id;
            let obj = this.props.hitsGebyr.find(
              (o) => o.matrikkelenhetId === m
            );
            if (obj) {
              if (obj.avtaler !== '') {
                item.gebyrTypeKode = obj.gebyrTypeKode;
              }
              item.formatAvtaler = obj.avtaler;
              return (
                <div style={{ fontSize: '12px', maxWidth: '20px' }}>
                  {obj.avtaler}
                </div>
              );
            }
          }
        }.bind(this),
      },
      {
        id: 'gebyr',
        name: 'Gebyr',
        sortParams: 'gebyr',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (!item.gebyrTypeKode) {
            return '';
          }
          switch (item.gebyrTypeKode) {
            case 'G':
              return (
                <img
                  height='24'
                  width='24'
                  src={FagKronGreen}
                  alt=''
                  title='Alle aktive avtaler tilknyttet samme eiendom i KTF har minst en varelinje til forfall for feie- eller tilsynsgebyr.'
                />
              );

            case 'O':
              return (
                <img
                  height='24'
                  width='24'
                  src={FagKronYellow}
                  alt=''
                  title='Det er minst en aktiv avtale tilknyttet samme eiendom i KTF med minst en varelinje til forfall for feie- eller tilsynsgebyr.'
                />
              );

            case 'R':
              return (
                <img
                  height='24'
                  width='24'
                  src={FagKronRed}
                  alt=''
                  title='Ingen av de aktive avtalene på eiendommen har minst en varelinje til forfall for feie- eller tilsynsgebyr.'
                />
              );

            default:
              return '';
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let vurdert = _.some(this.props.vurderte, function(o) {
            return o === parseInt(item._id);
          });

          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              <ContextMenu.Item onClick={this.prepareKart.bind(this, item)}>
                Vis i kart
              </ContextMenu.Item>
              {/*   <ContextMenu.Item onClick={this.onVisMatrikkel.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item> */}
              {/* <ContextMenu.Item onClick={this.onClickSituasjon.bind(this, item)}>
                Vis situasjon
              </ContextMenu.Item> */}
              <ContextMenu.Item onClick={this.onClickMatr.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item>

              {this.state.visGebyr && (
                <ContextMenu.Item onClick={this.visGebyr.bind(this, item)}>
                  Vis gebyr
                </ContextMenu.Item>
              )}
              <ContextMenu.Divider />
              {!vurdert ? (
                <ContextMenu.Item onClick={this.vurdert.bind(this, item)}>
                  Sett til "Vurdert"
                </ContextMenu.Item>
              ) : (
                <ContextMenu.Item onClick={this.fjernVurdert.bind(this, item)}>
                  Sett til "Ikke vurdert"
                </ContextMenu.Item>
              )}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  getColumnsOrg = (item) => {
    return [
      {
        id: 'kommune',
        name: 'Kommune',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let k = _.get(item, '_source.kommune.kommuneNavn');
          item.formatKommune = k;
          return k;
        },
      },
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'adresse',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let a = getAdresse(item._source);
          item.formatAdresse = a;
          return a;
        },
      },
      {
        id: 'opprettetDato',
        name: 'Oppr.dato',
        sortParams: 'opprettetDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let d = _.get(item, '_source.opprettetDato');
          if (d) {
            let dd = moment(item._source.opprettetDato).format('DD.MM.YYYY');
            item.formatDato = dd;
            return dd;
          } else {
            return '';
          }
        },
      },
      {
        id: 'eiendom',
        name: 'Eiendom',
        sortParams: 'eiendom',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let m = getMatrikkelnummer(item._source);
          item.formatEiendom = m;
          return m;
        },
      },
      {
        id: 'type',
        name: 'Br.type',
        sortParams: 'type',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.get(item, '_source.bruksenhetstype');
          item.formatBruksenhetType = s;
          return (
            <div title={s} className='ellipsisFag'>
              {s}
            </div>
          );
        },
      },
      {
        id: 'bygningstatus',
        name: 'Bygn.status',
        sortParams: 'bygningstatus',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let s = _.get(item, '_source.bygg.bygningstatus');
          item.formatBygningStatus = s;
          return (
            <div title={s} className='ellipsisFag'>
              {s}
            </div>
          );
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let vurdert = _.some(this.props.vurderte, function(o) {
            return o === parseInt(item._id);
          });

          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              <ContextMenu.Item onClick={this.prepareKart.bind(this, item)}>
                Vis i kart
              </ContextMenu.Item>
              {/*   <ContextMenu.Item onClick={this.onVisMatrikkel.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item> */}
              {/* <ContextMenu.Item onClick={this.onClickSituasjon.bind(this, item)}>
                Vis situasjon
              </ContextMenu.Item> */}
              <ContextMenu.Item onClick={this.onClickMatr.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item>

              {contextInnstillingerPerson._currentValue.norkartAdmin && (
                <ContextMenu.Item onClick={this.visGebyr.bind(this, item)}>
                  Vis gebyr
                </ContextMenu.Item>
              )}
              <ContextMenu.Divider />
              {!vurdert ? (
                <ContextMenu.Item onClick={this.vurdert.bind(this, item)}>
                  Sett til "Vurdert"
                </ContextMenu.Item>
              ) : (
                <ContextMenu.Item onClick={this.fjernVurdert.bind(this, item)}>
                  Sett til "Ikke vurdert"
                </ContextMenu.Item>
              )}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  onVisMatrikkel(item) {
    debug('**matr', item);

    // this.dialogs.setOpenDialog( 'MatrikkelInfoDialog', { matrikkelinfoId: item._id });
  }

  vurdert(item) {
    this.props.vurdert(item);
  }

  fjernVurdert(item) {
    this.props.fjernVurdert(item);
  }

  rowFormat(item) {
    if (
      _.some(this.props.vurderte, function(o) {
        return o === parseInt(item._id, 10);
      })
    ) {
      return { className: 'blueBackgroundLight' };
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className='scrollerMedium80'>
          <ReactTable
            showIndex={false}
            items={this.props.hits}
            columns={this.getColumns()}
            selectableRows={true}
            onRowSelectionChange={(rows) => this.props.selectedRows(rows)}
            selectAllLabel='Alle'
            onRowClick={null}
            rowFormat={this.rowFormat}
            idProperty='_id'
            itemsPrPage={0}
          />
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}
export default EnhetTabell;
