import React from 'react';
import Tiltakstyper from './Tiltakstyper.jsx';

export default class extends React.Component {
    state = { breadcrumbname: 'Laster....' };

    render() {
        return <div>
            <Tiltakstyper />
        </div>;
    }
}