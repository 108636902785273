import React, { Component } from 'react';
import Control from 'react-leaflet-control';
import NKIcon from 'nka-icons';
import _ from 'lodash';
import moment from 'moment';
import * as getIcon from '../geticon.js';
import PlanClusterLayer from './PlanClusterLayer';
import '../EnhetPopup.css';
import { debugFactory, objGetDeep, promisifyApiCall } from 'utils';
/* import { getRisikoInfoForEnhetTilsyn } from 'api/risikoApi'; */
import { getTooltipForLeggTilOgSoekEnheter } from 'components/Kart/getTooltip.js';
import { getMatrikkelnummer, getAdresse } from 'modules/felles/AdresseHelper';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:plancluster'
);

var parentKart = null;

class PlanClusterLayerControl extends Component {
  state = {
    objekterIn: [],
    objekter: [],
    valgte: [],
    kunvalgte: false,
    visKunValgteColor: '',
    modus: 'Enheter',
   /*  visRisikoInfoPanel: false, */
    infoHtml: '',
  };

  componentWillReceiveProps = (nextProps) => {
    parentKart = this.props.kart;
    this.setState({ modus: nextProps.modus });
    let oppdater = false;
    if (
      !_.isEqual(_.sortBy(nextProps.objekter), _.sortBy(this.state.objekterIn))
    ) {
      this.setState({ objekterIn: nextProps.objekter });
      oppdater = true;
    }
    if (!_.isEqual(_.sortBy(nextProps.valgte), _.sortBy(this.state.valgte))) {
      this.setState({ valgte: nextProps.valgte });
      oppdater = true;
    }
    if (oppdater) {
      this.setData(nextProps.objekter, nextProps.valgte);
    }
  };

  onClickedGj = (e) => {
    // debug('gj klokko 51');
    this.props.onClickedGj('', e);
  };

  setData = (obj, valgte) => {
    let objekter = [];
    for (let i = 0; i < obj.length; i++) {
      let el = obj[i];
      if (objGetDeep(el, 'source.bygg.lokasjon.lat')) {
        //royklopId
        if (
          el.source.bygg.lokasjon.lat.toString() !== '0.0' &&
          el.source.bygg.lokasjon.lat.toString() !== '0'
        ) {
          let objekt = {
            adresse: el.source ? getAdresse(el.source) : 'ukjent adresse',
            royklopId: _.get(el, 'royklopId'),
            bruksenhetId: el.bruksenhetId,
            eiendom: getMatrikkelnummer(el.source),
            id: el.id,
            lokasjonLatitude: el.source.bygg.lokasjon.lat,
            lokasjonLongitude: el.source.bygg.lokasjon.lon,
            valgt: this.makeValgt(valgte, el.bruksenhetId, el),
            source: el.source,
            datoTiltakEnhet: this.makeDatoTiltakEnhet(el),
            datoTiltakRoyklop: this.makeDatoTiltakRoyklop(el),
            risiko: _.get(el, 'source.skybrannData.bruksenhet.risikovurdering'),
          };
          if (objekt.valgt === false && this.state.kunvalgte === true) {
          } else {
            objekter.push(objekt);
          }
        }
      } else {
        //debug('OJGETDEEP FEIL', el);
      }
    }
    this.setState({ objekter: objekter });
  };

  makeValgt = (valgte, bruksenhetId, element) => {
    let result = false;

    if (this.state.modus === 'Røykløp') {
      for (let i = 0; i < valgte.length; i++) {
        let valgt = valgte[i];
        if (element.royklopId === valgt.royklopId) {
          result = true;
          break;
        }
      }
    } else {
      for (let i = 0; i < valgte.length; i++) {
        let valgt = valgte[i];
        if (bruksenhetId === valgt.bruksenhetId) {
          result = true;
          break;
        }
      }
    }

    return result;
  };

  makeDatoTiltakEnhet = (item) => {
    if (_.get(item, 'source.skybrannData.bruksenhetTiltakType')) {
      let tiltak = _.head(
        _.orderBy(
          item.source.skybrannData.bruksenhetTiltakType,
          ['nesteDato'],
          ['asc']
        )
      );
      return tiltak.nesteDato
        ? moment(tiltak.nesteDato).format('DD.MM.YYYY') +
            '  ' +
            tiltak.tiltakType
        : '';
    } else {
      return '';
    }
  };

  makeDatoTiltakRoyklop = (item) => {
    if (this.state.modus === 'Enheter' || this.props.parent === 'SoekEnheter') {
      if (_.get(item, 'source.skybrannData.royklopTiltakType')) {
        let tiltak = _.head(
          _.orderBy(
            item.source.skybrannData.royklopTiltakType,
            ['nesteDato'],
            ['asc']
          )
        );
        return tiltak.nesteDato
          ? moment(tiltak.nesteDato).format('DD.MM.YYYY') +
              '  ' +
              tiltak.tiltakType
          : '';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  setClusterBounds = () => {
    let layer = this.refs.clusterLayer;
    if (parentKart !== undefined && parentKart !== null) {
      if (layer !== undefined && layer !== null) {
        let bounds = layer.getTheBounds();
        if (bounds !== null) {
          parentKart.fitBounds(bounds, { padding: [10, 10] });
        }
      }
    }
  };

  getTooltip = (data) => {
    const {
      adresse,
      eiendom,
      datoTiltakEnhet,
      datoTiltakRoyklop,
      royklopId,
    } = data;
    debug('Sigrid', datoTiltakEnhet);
    var ts = '';
    let royk = '';
    if (royklopId) {
      royk = this.makeRoyklopToolTip(data);
    }
    ts =
      adresse +
      '\n' +
      eiendom +
      royk +
      datoTiltakEnhet +
      '\n' +
      datoTiltakRoyklop;
    // }
    return ts;
  };

  makeRoyklopToolTip = (item) => {
    let rId = item.royklopId;
    let tiltakListe = _.get(item, 'source.skybrannData.royklopTiltakType');
    let royklopListe = _.get(item, 'source.skybrannData.royklop');
    let neste = '';

    let royklop = '';
    if (royklopListe) {
      for (let y = 0; y < royklopListe.length; y++) {
        const r = royklopListe[y];
        if (r.id === rId.toString()) {
          //zijn ze er?  plassering, type
          if (r.type) {
            royklop = royklop + 'Type: ' + r.type;
          } else {
            royklop = royklop + 'Type: ukjent';
          }
          if (r.plassering) {
            royklop = royklop + '  (Plass: ' + r.plassering + ')';
          } else {
            royklop = royklop + '  (Plass: ukjent';
          }
        }
      }
    }

    if (tiltakListe) {
      tiltakListe = _.orderBy(tiltakListe, ['nesteDato'], ['asc']);
      for (let i = 0; i < tiltakListe.length; i++) {
        const t = tiltakListe[i];
        let pil = '';
        if (t.royklopId === rId.toString()) {
          pil = ' <== ';
          if (t.nesteDato) {
            neste = neste + moment(t.nesteDato).format('DD.MM.YYYY');
          } else {
            neste = neste + 'Dato ukjent';
          }
          if (t.tiltakType) {
            neste = neste + '  ' + t.tiltakType + pil + '\n';
          } else {
            neste = neste + '  Tiltak ukjent' + pil + ' \n';
          }
        } else {
          if (t.nesteDato) {
            neste = neste + moment(t.nesteDato).format('DD.MM.YYYY');
          } else {
            neste = neste + 'Dato ukjent';
          }
          if (t.tiltakType) {
            neste = neste + '  ' + t.tiltakType + '\n';
          }
        }
      }
    }
    return '\n' + royklop + '\n' + neste;
  };

  goVelg = (data) => {
    this.props.valgtFraKart(data);
    this.props.kart.closePopup();
  };

  getMarkerWithCallback = (res) => {
    if (this.props.parent === 'SoekEnheter') {
      return this.getMarkerWithCallbackSoekEnheter(res);
    } else {
      return this.getMarkerWithCallbackPlanlegging(res);
    }
  };

  getMarkerWithCallbackPlanlegging = (res) => {
    //  this.setState({ visRisikoInfoPanel: false });
    let but = <div />;
    let visButton = true;

    if (!res.data.source.skybrannData) {
      visButton = false;
      but = (
        <div style={{ fontSize: '12px' }}>
          (Kan ikke velges, fagdata mangler)
        </div>
      );
    }

    if (res.data.valgt) {
      visButton = false;
      but = <div>(Allerede valgt)</div>;
    }

    if (visButton) {
      but = (
        <button
          onClick={() => this.goVelg(res.data)}
          style={{ width: '180px' }}
          className='btn btn-success'>
          Velg
        </button>
      );
    }

    let s = getTooltipForLeggTilOgSoekEnheter(
      res.data,
      this.props.modus,
      'Planlegging'
    );

/*     let infoHtml = '';
    if (this.state.visRisikoInfoPanel) {
      const d = new Date();
      let text = d.toString();
      infoHtml = <div dangerouslySetInnerHTML={{ __html: text }} />;
    } */
    /* 
     let infoHtml = '';
     let hasSkybrannData = _.get(res.data, 'source.skybrannData.bruksenhet');
    if (hasSkybrannData) {
      let id = res.data.source.skybrannData.bruksenhet.fagId;
      let time = moment();
      let test = '<div>Hopla' + time +'</div>';
      infoHtml = <div dangerouslySetInnerHTML={{ __html: test }} />;
      this.setState({ infoHtml });
    }  */

    let divX = <div dangerouslySetInnerHTML={{ __html: s }} />;

    return (
      <>
     {/*    {this.state.visRisikoInfoPanel && (
          <div>
            <button
              style={{ marginTop: '-20px', marginLeft: '-20px' }}
              type='button'
              className='btn btn-link'
              onClick={() => this.setState({ visRisikoInfoPanel: false })}>
              ← Tilbake
            </button>
            <br />
            <span style={{ fontWeight: 'bold', color: '#51a026' }}>
              {res.data.adresse}
            </span>
            <br />
            <label style={{ fontWeight: 'bold', fontSize: '12px' }}>
              Info om risikovurdering:
            </label>
            <br />
            <span>Her kommer mer info fra ny api-metode.</span>
            {infoHtml}
          </div>
        )} */}

     {/*    {!this.state.visRisikoInfoPanel && ( */}
          <div style={{ textAlign: 'left', fontSize: '14px' }}>
            {divX}
            {but}
{/*             {s.includes('Risiko') && this.props.visRisiko !== 'Ingen' && (
              <button
                style={{
                  width: '180px',
                  height: '28px',
                  fontSize: '12px',
                  marginTop: '2px',
                }}
                onClick={() => this.visRisikoInfo(res.data)}
                className='btn btn-default'>
                Info om risikovurdering
              </button>
            )} */}
          </div>
      {/*   )} */}
      </>
    );
  };


  getMarkerWithCallbackSoekEnheter = (res) => {
    let s = getTooltipForLeggTilOgSoekEnheter(
      res.data,
      this.props.modus,
      'SoekEnheter'
    );
    let hasSkybrannData = _.get(res.data, 'source.skybrannData.bruksenhet');
    let divX = <div dangerouslySetInnerHTML={{ __html: s }} />;


    return (

          <div style={{ textAlign: 'left', fontSize: '14px' }}>
            {divX}

            {this.props.kartfra === 'SoekenheterMobil' && (
              <div style={{ marginTop: '4px' }}>
                <button
                  style={{ width: '140px' }}
                  onClick={() => this.visDetaljerMobil(res.data)}
                  className='btn btn-default'>
                  Detaljer
                </button>
                {hasSkybrannData !== undefined && (
                  <button
                    style={{ width: '140px', marginTop: '2px' }}
                    onClick={() => this.visSituasjonInfo(res.data)}
                    className='btn btn-default'>
                    Situasjon
                  </button>
                )}
                <button
                  style={{ width: '140px', marginTop: '2px' }}
                  onClick={() => this.visMatrikkelInfo(res.data)}
                  className='btn btn-default'>
                  Matrikkelinfo
                </button>
              </div>
            )}

            {this.props.kartfra !== 'SoekenheterMobil' && (
              <div style={{ marginTop: '4px' }}>
                <button
                  style={{ width: '200px', height: '28px', fontSize: '12px' }}
                  onClick={() => this.visMatrikkelInfo(res.data)}
                  className='btn btn-default'>
                  Matrikkelinfo
                </button>
                {hasSkybrannData !== undefined && (
                  <button
                    style={{
                      width: '200px',
                      height: '28px',
                      fontSize: '12px',
                      marginTop: '2px',
                    }}
                    onClick={() => this.visSituasjonInfo(res.data)}
                    className='btn btn-default'>
                    Situasjon
                  </button>
                )}

                {this.props.visGebyr && (
                  <button
                    style={{
                      width: '200px',
                      height: '28px',
                      fontSize: '12px',
                      marginTop: '2px',
                    }}
                    onClick={() => this.visGebyr(res.data)}
                    className='btn btn-default'>
                    Vis gebyr
                  </button>
                )}

{/*                 {s.includes('Risiko') && this.props.visRisiko !== 'Ingen' && (
                  <button
                    style={{
                      width: '200px',
                      height: '28px',
                      fontSize: '12px',
                      marginTop: '2px',
                    }}
                    onClick={() => this.visRisikoInfo(res.data)}
                    className='btn btn-default'>
                    Info om risikovurdering
                  </button>
                )} */}
              </div>
            )}
          </div>

    );
  };

  onClusterReady = (bounds) => {
    this.props.goFit(bounds);
  };

  visKunValgte = () => {
    this.setState({ kunvalgte: !this.state.kunvalgte }, this.oppdaterKunValgte);
  };

  oppdaterKunValgte = () => {
    if (this.state.visKunValgteColor === '') {
      this.setState({ visKunValgteColor: '#e25653' });
    } else {
      this.setState({ visKunValgteColor: '' });
    }
    this.setData(this.state.objekterIn, this.state.valgte);
  };

  visMatrikkelInfo = (object) => {
    if (object) {
      this.props.visMatrikkelInfo(object);
    }
  };

  visSituasjonInfo = (object) => {
    if (object) {
      this.props.visSituasjonInfo(object);
    }
  };

  visArbeidslisteInfo = (object) => {
    if (object) {
      this.props.visArbeidslisteInfo(object);
    }
  };

  visDetaljerMobil = (object) => {
    if (object) {
      this.props.visDetaljerMobil(object);
    }
  };

  visGebyr = (object) => {
    debug('visgebyr', object);
    if (object) {
      this.props.onVisGebyr(object);
    }
  };

  visRisikoInfo = (object) => {
/*     if (object) {
      this.setState({ visRisikoInfoPanel: true });
    } */
   // this.props.onVisRisikoInfo(object);
  };

  render() {
    let latLngAccessor = (objekt) => [
      objekt.lokasjonLatitude,
      objekt.lokasjonLongitude,
      objekt.adresse,
    ];

    let position = 'topright';
    if (this.props.left === true) {
      position = 'topleft';
    }

    return (
      <div>
        <PlanClusterLayer
          ref='clusterLayer'
          data={this.state.objekter}
          latLngAccessor={latLngAccessor}
          markerIcon={getIcon.blueIcon}
          forbudIcon={getIcon.forbudIcon}
          alterIcon={getIcon.violetIcon}
          valgtIcon={getIcon.redIcon}
          markerPopup={this.getMarkerWithCallback}
          // getMarkerTooltip={this.getTooltip}
          onClusterReady={this.onClusterReady}
          modus={this.state.modus}
          parent={this.props.parent}
          onClickedGj={this.onClickedGj}
          kartfra={this.props.kartfra}
          visRisiko={this.props.visRisiko}
          // visTooltip={this.visTooltip}
        />

        <Control position={position}>
          <div>
            <button
              title='Sentrer alle objekter'
              type='button'
              className={'btnLag'}
              onClick={this.setClusterBounds}>
              <NKIcon icon='avslutt-fullskjerm' color='nk-black' />
            </button>
          </div>
        </Control>
        {this.props.parent === 'Planlegging' && (
          <Control position={position}>
            <div>
              <button
                style={{ background: this.state.visKunValgteColor }}
                title='Vis alle/kun arbeidsliste'
                type='button'
                className={'btnLag'}
                onClick={this.visKunValgte}>
                <NKIcon icon='start-rute' color='nk-black' />
              </button>
            </div>
          </Control>
        )}
      </div>
    );
  }
}

export default PlanClusterLayerControl;
