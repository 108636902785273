/* eslint-disable eqeqeq */
/* eslint-disable radix */
import React from 'react';
import { PropTypes } from 'prop-types';
import L from 'leaflet';
import './Cluster.css';
import { MapLayer, Popup } from 'react-leaflet';
import { getTooltipForGjennomforing } from 'components/Kart/getTooltip.js';
import * as getIcon from '../geticon.js';
import 'leaflet.markercluster';
import _ from 'lodash';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { debugFactory } from 'utils';
const debug = debugFactory('nk: GJLayer');

/* markers.on('clusterclick', function(e) {
  var data = "whatever";
  var pop = new L.popup().
      setLatLng(e.latlng).
      setContent('<div>test</div>');
  pop.addTo(map);
}); */

class GjMarkerClusterLayer extends MapLayer {
  constructor() {
    super();

    this.state = {
      popupObject: null,
      theBounds: null,
      huskPos: null,
    };

    this._clicked = this._clicked.bind(this);
    this._popupClosed = this._popupClosed.bind(this);
  }

  getChildContext() {
    return {
      layerContainer: this.leafletElement,
    };
  }

  getTheBounds() {
    return this.state.theBounds;
  }

  createLeafletElement() {
    let { clusterIcon, ...options } = this.props;
    if (!options.iconCreateFunction && clusterIcon) {
      options.iconCreateFunction = () => clusterIcon;
    }
    this.leafletElement = L.markerClusterGroup(options);
    return this.leafletElement;
  }

  componentDidMount() {
    super.componentDidMount();

    this._addMarkers(this.props);
    this.leafletElement.on('click', this._clicked);
    this.context.map.on('popupclose', this._popupClosed);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.leafletElement.off('click', this._clicked);
    this.context.map.off('popupclose', this._popupClosed);
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.data !== fromProps.data) {
      // Alle markers fjernes fra laget og legges til på nytt. Dette er
      // ganske raskt når addLayers() brukes for å legge til alle i en
      // operasjon. Hvis vi får ytelsesproblemer kan vi prøve med å
      // oppdatere lag-listen i stedet med individuelle removeLayer og
      // addLayer. Kanskje vi kan bruke noen grove estimat for å vurdere
      // hva som er raskest, feks. antall dataobjekter i listen.
      this.leafletElement.clearLayers();
      this._addMarkers(toProps);

      // fjern popup hvis objektet som popup-en viser ikke lenger finnes i
      // data-listen
      if (
        this.state.popupObject &&
        !_.includes(toProps.data, this.state.popupObject)
      ) {
        this.setState({ popupObject: null });
      }
    }
  }

  makeRisikoMarker = (obj) => {
    let icon;
    let risikoTall = 0;
    let flereRoyklop = false;
    let risikoOverstyrt = false;

    if (this.props.visRisiko === 'Tilsyn') {
      risikoTall = obj.risiko;
      if (_.get(obj, 'skybrannData.bruksenhet.risikoverdiOverstyr')) {
        risikoOverstyrt = obj.skybrannData.bruksenhet.risikoverdiOverstyr;
      }
    } else {
      //Her hentes info fra _source. Det kan være mange røykløp
      if (_.get(obj, 'source.skybrannData.royklop')) {
        if (obj.source.skybrannData.royklop.length === 1) {
          risikoTall = obj.source.skybrannData.royklop[0].risikoverdi;
          risikoOverstyrt =
            obj.source.skybrannData.royklop[0].risikoverdiOverstyr;
        } else {
          flereRoyklop = true;
          let tall = 0;
          for (let i = 0; i < obj.source.skybrannData.royklop.length; i++) {
            let r = obj.source.skybrannData.royklop[i];
            if (r.risikoverdi) {
              if (r.risikoverdi > tall) {
                tall = r.risikoverdi;
                risikoOverstyrt = r.risikoverdiOverstyr;
              }
            }
          }
          risikoTall = tall;
        }
      }
    }

    if (flereRoyklop) {
      //Flere røykløp
      if (!risikoOverstyrt) {
        if (risikoTall === 0) {
          icon = getIcon.risikoBluePlussIcon;
        } else if (this.inRange(risikoTall, 1, 4)) {
          icon = getIcon.risikoGreenPlussIcon;
        } else if (this.inRange(risikoTall, 5, 14)) {
          icon = getIcon.risikoYellowPlussIcon;
        } else if (this.inRange(risikoTall, 15, 25)) {
          icon = getIcon.risikoRedPlussIcon;
        } else {
          icon = getIcon.risikoBluePlussIcon;
        }
      } else {
        if (risikoTall === 0) {
          icon = getIcon.risikoBlueOverstyrtPlussIcon;
        } else if (this.inRange(risikoTall, 1, 4)) {
          icon = getIcon.risikoGreenOverstyrtPlussIcon;
        } else if (this.inRange(risikoTall, 5, 14)) {
          icon = getIcon.risikoYellowOverstyrtPlussIcon;
        } else if (this.inRange(risikoTall, 15, 25)) {
          icon = getIcon.risikoRedOverstyrtPlussIcon;
        } else {
          icon = getIcon.risikoBlueOverstyrtPlussIcon;
        }
      }
    } else {
      //En røykløp, en enhet
      if (!risikoOverstyrt) {
        if (risikoTall === 0) {
          icon = getIcon.risikoBlueIcon;
        } else if (this.inRange(risikoTall, 1, 4)) {
          icon = getIcon.risikoGreenIcon;
        } else if (this.inRange(risikoTall, 5, 12)) {
          icon = getIcon.risikoYellowIcon;
        } else if (this.inRange(risikoTall, 15, 25)) {
          icon = getIcon.risikoRedIcon;
        } else {
          icon = getIcon.risikoBlueIcon;
        }
      } else {
        //RisikoOverstyrt
        if (risikoTall === 0) {
          icon = getIcon.risikoBlueOverstyrtIcon;
        } else if (this.inRange(risikoTall, 1, 4)) {
          icon = getIcon.risikoGreenOverstyrtIcon;
        } else if (this.inRange(risikoTall, 5, 12)) {
          icon = getIcon.risikoYellowOverstyrtIcon;
        } else if (this.inRange(risikoTall, 15, 25)) {
          icon = getIcon.risikoRedOverstyrtIcon;
        } else {
          icon = getIcon.risikoBlueOverstyrtIcon;
        }
      }
    }

    return icon;
  };

  makeIcon = (nr, datotid, farge) => {
    let tidstring = '';
    let html = '';

    switch (this.props.markermodus) {
      case 'modus_icon':
        if (farge === 'green') {
          return getIcon.greenIcon;
        } else {
          if (farge === 'orange') {
            return getIcon.orangeIcon;
          } else {
            return getIcon.blueIcon;
          }
        }
      case 'modus_nummer':
        html = nr;
        break;
      case 'modus_tid':
        tidstring = 'tid-';
        html = datotid;
        break;
      default:
    }

    let icon = L.divIcon({
      className: 'gj-icon-' + tidstring + farge,
      html: html,
    });

    return icon;
  };

  //Return true dersom brøk 1/1 har lik teller og nevner
  //OG det har blitt send!!
  getGjennomfort(obj) {
    var index1 = '';
    var utf = '';
    var til = '';
    if (obj.tiltakBrukenhetStatus) {
      index1 = obj.tiltakBrukenhetStatus.indexOf('/');
      utf =
        // eslint-disable-next-line radix
        parseInt(obj.tiltakBrukenhetStatus.substring(0, index1), 10) +
        obj.antallLopPaaAlisteBesokt;
      til =
        parseInt(
          obj.tiltakBrukenhetStatus.substring(
            index1 + 1,
            obj.tiltakBrukenhetStatus.length
          ),
          10
        ) + obj.antallLopPaaAliste;
    }
    let farge = 'blue';
    if (utf == til && utf > 0 && obj.antallBruksenhetSendEier > 0) {
      farge = 'green';
    } else {
      if (utf == til) {
        farge = 'orange';
      }
    }
    return farge;
  }

  onClickGj(x, e) {
    this.props.onClickedGj(x);
    /*     this.leafletElement.eachLayer(function(layer) {
      if (layer._icon !== undefined && layer._icon != null) {
        layer._icon.style.width = '25px';
        layer._icon.style.height = '41px';
      }
    });
    e.layer._icon.style.width = '37px';
    e.layer._icon.style.height = '61px'; 
    let zx = e.layer._zIndex;
    e.layer.setZIndexOffset(zx + 100); */
  }

  //OnClick in gjennomføring-kart
  onClickGjzz(x, e) {
    debug('SPIDER', e.latlng);
    let LZ = e.latlng;
    this.props.onClickedGj(x);
    /*  this.leafletElement.eachLayer(function(layer) {
      if (layer._icon !== undefined && layer._icon != null) {
        if (( layer._latlng.lat === LZ.lat ) && ( layer._latlng.lng === LZ.lng )){
          layer._icon.style.width = '37px';
          layer._icon.style.height = '61px';
        } else {
          layer._icon.style.width = '25px';
          layer._icon.style.height = '41px';
        }

      }
    }); */
    this.leafletElement.eachLayer(function(layer) {
      if (layer._icon !== undefined && layer._icon != null) {
        debug('SPIDER 2', layer._icon);
        layer._icon.style.width = '25px';
        layer._icon.style.height = '41px';
        if (layer._latlng.lat === LZ.lat && layer._latlng.lng === LZ.lng) {
          debug('SPIDER 3 ja');
        }
      }
    });
    let xIcon = null; /*  */

    /*     this.leafletElement.eachLayer(function(layer) {
      if (layer._icon !== undefined && layer._icon != null) {
        if (( layer._latlng.lat === LZ.lat ) && ( layer._latlng.lng === LZ.lng )){
          debug('SPIDER 3', layer._icon);
          if (layer.getChildCount) {
            debug('SPIDER', layer._icon);
              layer.spiderfy();
            }
          
             xIcon = layer._icon;
        } 
      }
    }); */
    setTimeout(() => {
      if (xIcon) {
        xIcon.style.width = '37px';
        xIcon.style.height = '61px';
      }
    }, 500);

    let zx = e.layer._zIndex;
    e.layer.setZIndexOffset(zx + 100);
  }


  _addMarkers(props) {
    let enheter = props.enheter;
    if (!enheter || enheter.length === 0) {
      return;
    }
    let options = {
      interactive: Boolean(props.markerPopup),
    };
    let markers = enheter
      .map((obj) => {
        if (!obj.rekkefolgeNummer || obj.rekkefolgeNummer.toString() === '0') {
          obj.rekkefolgeNummer = '?';
        }
        let gjColor = this.getGjennomfort(obj);
        if (props.valgtIcon) {
          let x = obj.valgt;

          if (x === true) {
            options.icon = props.valgtIcon;
          }
        }

      /*   if (this.props.visRisiko === 'Ingen') { */
          switch (gjColor) {
            case 'blue':
              options.icon = this.makeIcon(
                obj.rekkefolgeNummer,
                obj.datotidkart,
                'blue'
              );
              break;
            case 'orange':
              options.icon = this.makeIcon(
                obj.rekkefolgeNummer,
                obj.datotidkart,
                'orange'
              );
              break;
            case 'green':
              options.icon = this.makeIcon(
                obj.rekkefolgeNummer,
                obj.datotidkart,
                'green'
              );
              break;
            default:
              options.icon = this.makeIcon(
                obj.rekkefolgeNummer,
                obj.datotidkart,
                'blue'
              );
          }
        /* } else {
          options.icon = this.makeRisikoMarker(obj);
        } */

        let position = this.props.latLngAccessor(obj);
        if (position[0] === undefined || position[1] === undefined) {
          return null;
        }
        if (position[0] === 0 || position[1] === 0) {
          return null;
        }

        let marker = L.marker(position, options);

        marker._popupData = obj;
        // marker.options.title = this.props.getMarkerTooltip(obj);

        //********************************************** TOOLTIP ********************************* */

        marker.bindPopup(getTooltipForGjennomforing(obj, 'GjMaster'), {
          closeButton: false,
          offset: [0, 30],
        });
        marker.on('mouseover', function(e) {
          this.openPopup();
        });
        marker.on('mouseout', function(e) {
          this.closePopup();
        });
        //********************************************** TOOLTIP END ********************************* */
        if (props.senterpunkt === true) {
          marker.options.draggable = true;
        }

        return marker;
      })
      .filter(function(item) {
        return item !== undefined;
      });
    if (this.leafletElement) {
      this.leafletElement.addLayers(markers);
      this.setState({ theBounds: this.leafletElement.getBounds() });
    }
  }
  render() {
    let { markerPopup } = this.props;
    let { popupObject } = this.state;
    if (!markerPopup || !popupObject) {
      return null;
    }

    let offset = [0, 30];

    //const popupPosition = this.props.latLngAccessor(popupObject);
    let popupPosition = [
      this.state.huskPos.latlng.lat,
      this.state.huskPos.latlng.lng,
    ];
    if (!popupPosition || popupPosition.length < 2) {
      return null;
    }

    return (
      <Popup
        position={popupPosition}
        offset={offset}
        closeOnClick={false}
        autoClose={false}>
        <this.props.markerPopup data={popupObject} />
      </Popup>
    );
  }

  _clicked(e) {
    this.setState({ huskPos: e });
    this.onClickGj(e.layer._popupData, e);
    return;
  }

  _popupClosed() {
    //close
    this.setState({ popupObject: null });
  }
}

GjMarkerClusterLayer.contextTypes = {
  layerContainer: PropTypes.shape({
    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
  }),
  map: PropTypes.instanceOf(L.Map),
};

GjMarkerClusterLayer.childContextTypes = {
  layerContainer: PropTypes.shape({
    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
  }),
};

GjMarkerClusterLayer.propTypes = {
  /**
   * The data set to generate markers from.
   * Optional (but nothing will be shown if null or empty).
   */
  data: PropTypes.array,

  /** Icon to use for marker clusters. Optional. */
  clusterIcon: PropTypes.instanceOf(L.Icon),

  /** Icon to use for individual markers. Optional. */
  markerIcon: PropTypes.instanceOf(L.Icon),

  /** Brukes i brannfoebygging  */
  brannIcon: PropTypes.instanceOf(L.Icon),
  brannBetingelse: PropTypes.any,
  brannVerdi: PropTypes.any,
  brannRisiko: PropTypes.any,
  brannRisikoMax: PropTypes.any,
  brannRisikoIcon: PropTypes.instanceOf(L.Icon),
  bulkfarge: PropTypes.any,

  /**
   * Popup content to show when a marker is clicked. The data object
   * corresponding to the clicked marker will be passed as property named
   * "data" to the content.
   * Optional.
   */
  markerPopup: PropTypes.any,
  markerTooltip: PropTypes.any,

  /**
   * Accessor function used to get geographic position of each item in "data".
   * Return value must on the form: [<latidute>, <longitude>].
   * Required.
   */
  latLngAccessor: PropTypes.func.isRequired,

  // MarkerClusterGroup options
  showCoverageOnHover: PropTypes.bool,
  zoomToBoundsOnClick: PropTypes.bool,
  spiderfyOnMaxZoom: PropTypes.bool,
  removeOutsideVisibleBounds: PropTypes.bool,
  animate: PropTypes.bool,
  animateAddingMarkers: PropTypes.bool,
  disableClusteringAtZoom: PropTypes.number,
  maxClusterRadius: PropTypes.number,
  polygonOptions: PropTypes.object,
  singleMarkerMode: PropTypes.bool,
  spiderLegPolylineOptions: PropTypes.object,
  spiderfyDistanceMultiplier: PropTypes.number,
  iconCreateFunction: PropTypes.func,
};

GjMarkerClusterLayer.defaultProps = {
  // MarkerClusterGroup options
  showCoverageOnHover: true,
  zoomToBoundsOnClick: true,
  spiderfyOnMaxZoom: true,
  removeOutsideVisibleBounds: true,
  animate: true,
  animateAddingMarkers: false,
  disableClusteringAtZoom: null,
  maxClusterRadius: 10,
  polygonOptions: {},
  singleMarkerMode: false,
  spiderLegPolylineOptions: {},
  spiderfyDistanceMultiplier: 1,
  iconCreateFunction: null,
};

export default GjMarkerClusterLayer;
