import React, { useState } from 'react';
import './BFButtonGroup.css';

/* Viktig:
<div
        style={{
          height: '40px',
          width: '250px',
          position: 'relative',
        }}>

          her...

        </div> */

const BFDropDownButton = ({
  buttonLabel,
  image,
  imageHover,
  buttonWidth,
  green,
  disabled,
  title,
}) => {
  const [imageSource, setImageSource] = useState(image);

  const changeHover = (l) => {
    if (image) {
      if (imageSource === image) {
        setImageSource(imageHover);
      } else {
        setImageSource(image);
      }
    }
  };

  return (
    <>
      <button
        disabled={disabled}
        onMouseOver={changeHover}
        onMouseOut={changeHover}
        title={title}
        style={{ marginRight: '4px', width: buttonWidth + 'px' }}
        data-toggle='dropdown'
        name={buttonLabel}
        className={
          disabled
            ? 'bfDropDownButtonDisabled'
            : green
            ? 'bfDropDownButtonGreen dropdown-toggle'
            : 'bfDropDownButton dropdown-toggle'
        }>
        <div className='pull-left' style={{ width: buttonWidth - 20 + 'px' }}>
          {(image && (
          <span className='pull-left' style={{ marginRight: '4px' }}>
            <img
              height='24'
              width='24'
              src={disabled ? imageHover : imageSource}
              alt=''
            />
          </span>
          ))}
          <span style={{ marginLeft: '6px' }} className='pull-left'>
            {buttonLabel}
          </span>
          <span
            className='caret pull-right'
            style={{ marginTop: '10px', borderWidth: '8px' }}></span>
        </div>
      </button>
    </>
  );
};

export default BFDropDownButton;
