/* eslint-disable react/jsx-key */
/* eslint-disable no-extra-semi */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import { promisifyApiCall } from 'utils';
import { withRouter } from 'lib/enhancers';
import sidemannskontroll from 'images/Annet/bruker_sidemann.png';
import {
  getOppgaverTilOppfolgingForBruker,
  getApneogPubliserteAvvikForBruker,
  getIkkePubliserteApneAvvikForBruker,
  slettBruksenhetOppgave,
  avbrytSidemannkontrollPaOppgave,
  avbrytSidemannkontrollPaAvvik,
} from 'api/smkApi.js';
import ContextMenu from 'components/ContextMenu';
import ReactTable from '@norkart/react-table';
import * as _ from 'lodash';
import { harSMK } from 'context/contextOppslag.js';
import Spinner from 'components/generics/Spinner';
import { getContextBrukere } from 'context/contextOppslag.js';
import BFButtonGroup from 'components/BFButtonGroup/BFButtonGroup.js';
import JaNeiDialog from 'components/Dialogs/JaNeiDialogFC.js';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ByttSidemann from './ByttSidemann.js';
import ByttSaksbehandler from './ByttSaksbehandler.js';
import situasjonSvart from 'images/situasjonSvart.png';
import SituasjonButton from 'modules/felles/SituasjonButton.jsx';
import NKIcon from 'nka-icons';
import {
  getSisteFaneBesoekt,
  setSisteFaneBesoekt,
  getValgtPerson,
  setValgtPerson
} from 'context/contextOppfolging.js';
import obs1 from 'images/Risk.png';
import obs2 from 'images/Info.png';
import { getInnloggetPerson } from 'context/contextInnstillinger.js';
import DeltPipe from 'images/deltpipe.png';

function index(props) {
  const buttonGroupA = useRef();
  const [isInit, setInit] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [dataFane1, setDataFane1] = useState([]);
  const [dataFane2, setDataFane2] = useState([]);
  const [dataFane3, setDataFane3] = useState([]);
  const [activeTable, setAvtiveTable] = useState(0);
  const [personer, setPersoner] = useState([]);
  const [person, setPerson] = useState('');
  const [personChanged, setPersonChanged] = useState(false);
  const [isFjernOppgaveOpen, setIsFjernOppgaveOpen] = useState(false);
  const [valgtItem, setValgtItem] = useState(null);
  const [modalByttSidemannOpen, setModalByttSidemannOpen] = useState(false);
  const [modalByttSaksbehandlerOpen, setModalByttSaksbehandlerOpen] = useState(
    false
  );
  const [saksbehandlerBytteType, setSaksbehandlerBytteType] = useState('avvik');
  const [sidemannBytteType, setSidemannBytteType] = useState('avvik');
  const [antallUnderBehandling, setAntallUnderBehandling] = useState(0);
  const [antallRapporter, setAntallRapporter] = useState(0);
  const [isFjernSidemannFane3Open, setIsFjernSidemannFane3Open] = useState(
    false
  );
  const [isFjernSidemannFane1Open, setIsFjernSidemannFane1Open] = useState(
    false
  );
  const [isAapneOppgaveOpen, setIsAapneOppgaveOpen] = useState(false);
  const [tempRad, setTempRad] = useState(null);
  const [harSmk, setHarSmk] = useState(false);
  const [harSmkInitiert, setHarSmkInitiert] = useState(false);

  useEffect(() => {
    if (isInit) {
      // ellers blir det en infin. loop
      setInit(false);
      getContextBrukere(function(brukere) {
        setPerson(brukere.paalogget);
        setPersoner(brukere.brukere);

        // Check: hvor kommer vi fra?
        let fra = '';
        if (
          props.location.state !== undefined &&
          props.location.state != null &&
          props.location.state.fra !== undefined
        ) {
          fra = props.location.state.fra;
        } else {
          fra = getSisteFaneBesoekt();
        }
        let tmp = getValgtPerson();
        //Tilbakestill i Context
        setSisteFaneBesoekt('');

        if (fra !== '') {
          // Kommer fra Klargjør rapporter, hent første og siste får å vise i badges
          if (fra === 'KlargjørRapport') {
            promisifyApiCall(getOppgaverTilOppfolgingForBruker)(
              brukere.paalogget
            ).then((data) => {
              setDataFane3(data);
              setIsLoading(false);
              setAvtiveTable(2);
              AntallRapporter(data);
              buttonGroupA.current.setSelectBtnIndex(2);
            });
            promisifyApiCall(getApneogPubliserteAvvikForBruker)(
              brukere.paalogget
            ).then((data) => { 
              setDataFane1(data);
              setIsLoading(false);
              AntallUnderBehandling(data);
            });
            // KOmmer fra ikke publiserte, hent data i alle faner pga badges
          } else if (fra === 'IkkePubliserte') {
            promisifyApiCall(getIkkePubliserteApneAvvikForBruker)(
              brukere.paalogget
            ).then((data) => {
              setDataFane2(data);
              setIsLoading(false);
              setAvtiveTable(1);
              buttonGroupA.current.setSelectBtnIndex(1);
            });
            promisifyApiCall(getApneogPubliserteAvvikForBruker)(
              brukere.paalogget
            ).then((data) => {
              setDataFane1(data);
              setIsLoading(false);
              AntallUnderBehandling(data);
            });
            promisifyApiCall(getOppgaverTilOppfolgingForBruker)(
              brukere.paalogget
            ).then((data) => {
              setDataFane3(data);
              setIsLoading(false);
              AntallRapporter(data);
            });
            // Kommer fra fane under behandling, hent data for første og siste pga badges
          } else if (fra === 'UnderBehandling') {
            let tmpPerson = getValgtPerson();
            setPerson(tmpPerson);
            promisifyApiCall(getApneogPubliserteAvvikForBruker)(
              tmpPerson
            ).then((data) => {
              setDataFane1(data);
              setIsLoading(false);
              AntallUnderBehandling(data);
            });
            promisifyApiCall(getOppgaverTilOppfolgingForBruker)(
              tmpPerson
            ).then((data) => {
              setDataFane3(data);
              setIsLoading(false);
              AntallRapporter(data);
            });
            // Init, henter for første fane, setter data i badge på siste ut ifra data sent i fra
          } else {
            setAntallRapporter(fra);

            promisifyApiCall(getApneogPubliserteAvvikForBruker)(
              brukere.paalogget
            ).then((data) => {
              setDataFane1(data);
              setIsLoading(false);
              AntallUnderBehandling(data);
            });
          }
          // init er tom, skal ikke vanligvis skje, men henter fførste og siste
        } else {
          promisifyApiCall(getApneogPubliserteAvvikForBruker)(
            brukere.paalogget
          ).then((data) => {
            setDataFane1(data);
            setIsLoading(false);
            AntallUnderBehandling(data);
          });
          promisifyApiCall(getOppgaverTilOppfolgingForBruker)(
            brukere.paalogget
          ).then((data) => {
            setDataFane3(data);
            setIsLoading(false);
            AntallRapporter(data);
          });
        }
      });
    }
  });

  useEffect(() => {
    harSMK(onHarSmk);
  }, []);

  const onHarSmk = (har) => {
    setHarSmk(har);
    setHarSmkInitiert(true);
  };

  const AntallUnderBehandling = (data) => {
    const ant = data.filter((x) => x.notification);
    setAntallUnderBehandling(ant.length);
  };

  const AntallRapporter = (data) => {
    const ant = data.filter((x) => x.notification);
    setAntallRapporter(ant.length);
  };

  const VelgRadFane1 = (item) => {
    setValgtPerson(person);
    //Åpen med eller uten SMK
    let pname = 'oppfolging/ub/';
    if (harSmk) {
      pname = 'oppfolging/ubsmk/';
    }
    props.router.push({
      pathname: pname + item.id,
      state: {
        adresse: item.bruksenhetAdresse,
        objektReferanseType: item.objektReferanseType,
        avvikTekst: item.avvikType,
        bruksenheter: item.bruksenhetIds,
        avvik: item,
      },
    });
  };

  const VelgRadFane2 = (item) => {
    props.router.push({
      pathname: 'oppfolging/ib/' + item.bruksenhetId,
      state: {
        brId: item.bruksenhetId,
        adresse: item.bruksenhetAdresse,
        eiendom: item.matrikkelenhet,
        arbListeId: null,
      },
    });
  };

  const VelgRadFane3 = (rad) => {
    let innlogget = '';
    if (rad.ansvarlig === 'Sidemann') {
      getInnloggetPerson((person) => {
        innlogget = person.navn;
      });

      if (rad.sidemann !== innlogget) {
        setTempRad(rad);
        setIsAapneOppgaveOpen(true);
      } else {
        fortsettVelgRadFane3(rad, false);
      }
    } else {
      fortsettVelgRadFane3(rad, false);
    }
  };

  const fortsettVelgRadFane3 = (rad, ikkeAnsvarlig) => {
    props.router.push({
      pathname: 'oppfolging/kr/' + rad.id,
      state: {
        oppgave: rad,
        ikkeAnsvarlig: ikkeAnsvarlig,
      },
    });
  };

  const handleAapneOppgaveClose = (svar) => {
    if (svar === 'ja') {
      fortsettVelgRadFane3(tempRad, true);
    }
    setIsAapneOppgaveOpen(false);
  };

  const rowFormat = (item) => {
    if (item.godkjent) {
      return { style: { backgroundColor: '#dcedc0' } };
    }
  };

  const handleAnsvarligChange = (item) => {
    setPerson(item.target.value);
    setPersonChanged(true);
    switch (activeTable) {
      case 1:
        setIsLoading(true);
        promisifyApiCall(getApneogPubliserteAvvikForBruker)(
          item.target.value
        ).then((data) => {
          setDataFane1(data);
          setIsLoading(false);
          AntallUnderBehandling(data);
        });
        setIsLoading(true);
        promisifyApiCall(getIkkePubliserteApneAvvikForBruker)(
          item.target.value
        ).then((data) => {
          setDataFane2(data);
          setIsLoading(false);
        });
        promisifyApiCall(getOppgaverTilOppfolgingForBruker)(
          item.target.value
        ).then((data) => {
          setDataFane3(data);
          setIsLoading(false);
          AntallRapporter(data);
        });
        break;

      default:
        setIsLoading(true);
        promisifyApiCall(getApneogPubliserteAvvikForBruker)(
          item.target.value
        ).then((data) => {
          setDataFane1(data);
          setIsLoading(false);
          AntallUnderBehandling(data);
        });

        promisifyApiCall(getOppgaverTilOppfolgingForBruker)(
          item.target.value
        ).then((data) => {
          setDataFane3(data);
          setIsLoading(false);
          AntallRapporter(data);
        });
        break;
    }
  };

  const refresh = () => {
    switch (activeTable) {
      case 0:
        setIsLoading(true);
        promisifyApiCall(getApneogPubliserteAvvikForBruker)(person).then(
          (data) => {
            setDataFane1(data);
            setIsLoading(false);
            AntallUnderBehandling(data);
          }
        );
        break;
      case 1:
        setIsLoading(true);
        promisifyApiCall(getIkkePubliserteApneAvvikForBruker)(person).then(
          (data) => {
            setDataFane2(data);
            setIsLoading(false);
          }
        );
        break;
      case 2:
        setIsLoading(true);
        promisifyApiCall(getOppgaverTilOppfolgingForBruker)(person).then(
          (data) => {
            setDataFane3(data);
            setIsLoading(false);
            AntallRapporter(data);
          }
        );
        break;

      default:
        break;
    }
  };

  // C O N T E X T   M E N Y  V A L G   F A N E  1

  const apneOppgaveFane1 = (item) => {
    VelgRadFane1(item);
  };

  const byttSaksbehandlerFane1 = (item) => {
    setValgtItem(item);
    setSaksbehandlerBytteType('avvik');
    setModalByttSaksbehandlerOpen(true);
  };

  const byttSidemannFane1 = (item) => {
    setValgtItem(item);
    setSidemannBytteType('avvik');
    setModalByttSidemannOpen(true);
  };

  // C O N T E X T   M E N Y  V A L G   F A N E 3

  const apneOppgave = (item) => {
    VelgRadFane3(item);
  };

  const apneUpubAvvik = (item) => {
    VelgRadFane2(item);
  };

  const fjernOppgave = (item) => {
    setValgtItem(item);
    setIsFjernOppgaveOpen(true);
  };

  const handleFjernOppgaveClose = (svar) => {
    if (svar === 'ja') {
      let param = {
        id: valgtItem.id,
        anmerkningListe: valgtItem.anmerkningListe,
        avvikListe: valgtItem.avvikListe,
      };
      promisifyApiCall(slettBruksenhetOppgave)(valgtItem.id, param).then(
        (data) => {
          console.log(data);
          setIsFjernOppgaveOpen(false);
          setIsLoading(true);
          promisifyApiCall(getOppgaverTilOppfolgingForBruker)(person).then(
            (data) => {
              setDataFane3(data);
              setIsLoading(false);
              AntallRapporter(data);
            }
          );
        }
      );
    } else {
      setIsFjernOppgaveOpen(false);
    }
  };

  const byttSaksbehandlerPaOppgave = (item) => {
    setValgtItem(item);
    setSaksbehandlerBytteType('oppgave');
    setModalByttSaksbehandlerOpen(true);
  };

  const byttSidemannPaOppgave = (item) => {
    setValgtItem(item);
    setSidemannBytteType('oppgave');
    setModalByttSidemannOpen(true);
  };

  const fjernSidemannFane3 = (item) => {
    setValgtItem(item);
    setIsFjernSidemannFane3Open(true);
  };

  const handleFjernSidemannFane3Close = (svar) => {
    if (svar === 'ja') {
      let param = {
        id: valgtItem.id,
        anmerkningListe: valgtItem.anmerkningListe,
        avvikListe: valgtItem.avvikListe,
      };
      promisifyApiCall(avbrytSidemannkontrollPaOppgave)(
        valgtItem.id,
        param
      ).then((data) => {
        console.log(data);
        setIsFjernSidemannFane3Open(false);
        setIsLoading(true);
        promisifyApiCall(getOppgaverTilOppfolgingForBruker)(person).then(
          (data) => {
            setDataFane3(data);
            setIsLoading(false);
            AntallRapporter(data);
          }
        );
      });
    } else {
      setIsFjernSidemannFane3Open(false);
    }
  };

  const fjernSidemannFane1 = (item) => {
    setValgtItem(item);
    setIsFjernSidemannFane1Open(true);
  };

  const handleFjernSidemannFane1Close = (svar) => {
    if (svar === 'ja') {
      promisifyApiCall(avbrytSidemannkontrollPaAvvik)(valgtItem.id).then(
        (data) => {  
          setIsFjernSidemannFane1Open(false);
          setIsLoading(true);
        //  promisifyApiCall(getOppgaverTilOppfolgingForBruker)(person).then(
            promisifyApiCall(getApneogPubliserteAvvikForBruker)(person).then(
            (data) => {
              setDataFane1(data);
              setIsLoading(false);
              AntallUnderBehandling(data);
            }
          );
        }
      );
    } else {
      setIsFjernSidemannFane1Open(false);
    }
  };

  const lukkByttSidemann = () => {
    setModalByttSidemannOpen(false);
    refresh();
  };

  const lukkByttSaksbehandler = () => {
    setModalByttSaksbehandlerOpen(false);
    refresh();
  };

  // F A N E  K N A P P E R
  const buttonValgt = (id, name) => {
    setAvtiveTable(id);

    switch (id) {
      case 0:
        if (dataFane1.length === 0 || personChanged) {
          setIsLoading(true);
          promisifyApiCall(getApneogPubliserteAvvikForBruker)(person).then(
            (data) => {
              setDataFane1(data);
              setIsLoading(false);
              AntallUnderBehandling(data);
            }
          );
        }
        break;
      case 1:
        if (dataFane2.length === 0 || personChanged) {
          setIsLoading(true);
          promisifyApiCall(getIkkePubliserteApneAvvikForBruker)(person).then(
            (data) => {
              setDataFane2(data);
              setIsLoading(false);
            }
          );
        }
        break;
      case 2:
        if (dataFane3.length === 0 || personChanged) {
          setIsLoading(true);
          promisifyApiCall(getOppgaverTilOppfolgingForBruker)(person).then(
            (data) => {
              setDataFane3(data);
              setIsLoading(false);
              AntallRapporter(data);
            }
          );
        }
        break;

      default:
        break;
    }
  };

  // T A B E L L   K O L O N N E R
  const getColumnsFane1 = () => {
    if (harSmk) {
      return getColumnsFane1_MedSmk();
    } else {
      return getColumnsFane1_UtenSmk();
    }
  };

  const getColumnsFane1_UtenSmk = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        isSorted: true,
        sortDirection: 'desc',
        formatter: function(item) {
          if (item.notification) {
            //return <img height="16" width="16" src={utropstegn_icon} alt="" />;
            let fdato = new Date(item.frist);
            let today = new Date();
            //if (today > fdato) {
            if (item.ansvarlig === 'Eier' && today > fdato) {
              return (
                <img
                  height='16'
                  width='16'
                  src={obs1}
                  alt=''
                  title='Eier er ansvarlig og frist er utgått'
                />
              );
            } else {
              return (
                <img
                  height='16'
                  width='16'
                  src={obs2}
                  alt=''
                  title='Du står som ansvarlig'
                />
              );
            }
          }
        },
      },
      {
        id: 'first',
        name: 'Frist',
        sortParams: 'id',
        isSorted: false,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.frist === null) {
            return '';
          } else {
            var dato = new Date(item.frist);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'saksbehandler',
        name: 'Saksbehandler',
        sortParams: 'saksbehandler',
        isSorted: false,
      },
      {
        id: 'ansvarlig',
        name: 'Ansvar nå',
        sortParams: 'ansvarlig',
        isSorted: true,
        sortable: true,
        formatter: function(item) {
          if (item.ansvarlig === 'Sidemann') {
            return 'Sideperson';
          } else {
            return (item.ansvarlig);
          }
        }
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        isSorted: false,
        sortable: true,
      },
      {
        id: 'avvikType',
        name: 'Avvik',
        sortParams: 'avvikType',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '12px' }}>{item.avvikType}</span>;
        },
      },
      {
        id: 'deltAvvik',
        name: '',
        sortParams: 'deltAvvik',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.bruksenhetIds.includes(',')) {
            return (
              <img
                height="24"
                width="24"
                src={DeltPipe}
                className="app-preloader__img pull-left mr8"
                alt=""
                title="Delt"
              />
            ); 
          };
          
        },
      },
      {
        id: 'situasjon',
        name: (
          <img
            src={situasjonSvart}
            title='Vis oversikt med enhet, røykløp og ildsted'
            width='24px'
            alt='Situasjon'
          />
        ),
        sortParams: 'situasjon',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <div style={{ maxWidth: '30px' }}>
              <SituasjonButton
                fra='avvikaapne'
                hoover={false}
                item={item}
                link={false}
              />
            </div>
          );
        },
      },
      {
        id: 'objektReferanseType',
        name: '',
        sortParams: 'objektReferanseType',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.objektReferanseType === 'R') {
            return (
              <NKIcon
                className='pull-left'
                icon='royklop'
                color='nk-black'
                size='0.8'
                title='Røykløp'
              />
            );
          } else if (item.objektReferanseType === 'I') {
            return (
              <NKIcon
                className='pull-left'
                icon='ildsted'
                color='nk-black'
                size='0.8'
                title='Ildsted'
              />
            );
          } else {
            return (
              <NKIcon
                className='pull-left'
                icon='hjem'
                color='nk-black'
                size='0.8'
                title='Bruksenhet'
              />
            );
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => apneOppgaveFane1(item)}>
                Åpne oppgave
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => byttSaksbehandlerFane1(item)}>
                Bytt saksbehandler
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        },
      },
    ];
  };

  //Her 1 kolonne ekstra for SMK
  const getColumnsFane1_MedSmk = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        isSorted: true,
        sortDirection: 'desc',
        formatter: function(item) {
          if (item.notification) {
            //return <img height="16" width="16" src={utropstegn_icon} alt="" />;
            let fdato = new Date(item.frist);
            let today = new Date();
            //if (today > fdato) {
            if (item.ansvarlig === 'Eier' && today > fdato) {
              return (
                <img
                  height='16'
                  width='16'
                  src={obs1}
                  alt=''
                  title='Eier er ansvarlig og frist er utgått'
                />
              );
            } else {
              return (
                <img
                  height='16'
                  width='16'
                  src={obs2}
                  alt=''
                  title='Du står som ansvarlig'
                />
              );
            }
          }
        },
      },
      {
        id: 'first',
        name: 'Frist',
        sortParams: 'id',
        isSorted: false,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.frist === null) {
            return '';
          } else {
            var dato = new Date(item.frist);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'saksbehandler',
        name: 'Saksbehandler',
        sortParams: 'saksbehandler',
        isSorted: false,
      },
      {
        id: 'sidemann',
        name: 'Sideperson',
        sortParams: 'sidemann',
        isSorted: false,
      },
      {
        id: 'ansvarlig',
        name: 'Ansvar nå',
        sortParams: 'ansvarlig',
        isSorted: true,
        sortable: true,
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        isSorted: false,
        sortable: true,
      },
      {
        id: 'avvikType',
        name: 'Avvik',
        sortParams: 'avvikType',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '12px' }}>{item.avvikType}</span>;
        },
      },
      {
        id: 'deltAvvik',
        name: '',
        sortParams: 'deltAvvik',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.bruksenhetIds) {
            if (item.bruksenhetIds.includes(',')) {
              return (
                <img
                  height="24"
                  width="24"
                  src={DeltPipe}
                  className="app-preloader__img pull-left mr8"
                  alt=""
                  title="Delt"
                />
              );      
            };
          };
        },
      },
      {
        id: 'situasjon',
        name: (
          <img
            src={situasjonSvart}
            title='Vis oversikt med enhet, røykløp og ildsted'
            width='24px'
            alt='Situasjon'
          />
        ),
        sortParams: 'situasjon',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <div style={{ maxWidth: '30px' }}>
              <SituasjonButton
                fra='avvikaapne'
                hoover={false}
                item={item}
                link={false}
              />
            </div>
          );
        },
      },
      {
        id: 'objektReferanseType',
        name: '',
        sortParams: 'objektReferanseType',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.objektReferanseType === 'R') {
            return (
              <NKIcon
                className='pull-left'
                icon='royklop'
                color='nk-black'
                size='0.8'
              />
            );
          } else if (item.objektReferanseType === 'I') {
            return (
              <NKIcon
                className='pull-left'
                icon='ildsted'
                color='nk-black'
                size='0.8'
              />
            );
          } else {
            return (
              <NKIcon
                className='pull-left'
                icon='hjem'
                color='nk-black'
                size='0.8'
              />
            );
          }
        },
      },
      {
        id: 'sidemannGodkjent',
        name: 'Status',
        sortParams: 'sidemannGodkjent',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          switch (item.ansvarlig) {
            case 'Eier':
              return 'Under behandling';
            case 'Saksbehandler':
              if (item.sidemannId == null || item.sidemannId === 0) {
                return 'Under behandling';
              } else {
                if (item.sidemannGodkjent) {
                  return 'Godkjent';
                } else {
                  return 'Ikke godkjent';
                }
              }
            case 'Sidemann':
              if (item.sidemannGodkjent) {
                return 'Godkjent';
              } else {
                return 'Under behandling';
              }
            default:
              break;
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => apneOppgaveFane1(item)}>
                Åpne oppgave
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => byttSaksbehandlerFane1(item)}>
                Bytt saksbehandler
              </ContextMenu.Item>
              {item.sidemannId != null && item.sidemannId !== 0 ? (
                <ContextMenu.Item onClick={() => byttSidemannFane1(item)}>
                  Bytt sideperson
                </ContextMenu.Item>
              ) : null}
              {item.sidemannId != null && item.sidemannId !== 0 ? (
                <ContextMenu.Item onClick={() => fjernSidemannFane1(item)}>
                  Avbryt kontroll
                </ContextMenu.Item>
              ) : null}
            </ContextMenu.Button>
          );
        },
      },
    ];
  };

  const getColumnsFane2 = () => [
    {
      id: 'bruksenhetAdresse',
      name: 'Bruksenhet adresse',
      sortParams: 'bruksenhetAdresse',
      isSorted: false,
    },
    {
      id: 'kommune',
      name: 'Kommune',
      sortParams: 'kommune',
      isSorted: false,
      sortable: true,
    },
    {
      id: 'matrikkelenhet',
      name: 'Eiendom',
      sortParams: 'matrikkelenhet',
      isSorted: false,
      sortable: true,
    },

    {
      id: 'meny',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        return (
          <ContextMenu.Button>
            <ContextMenu.Item onClick={() => apneUpubAvvik(item)}>
              Åpne avvik
            </ContextMenu.Item>
          </ContextMenu.Button>
        );
      },
    },
  ];

  const getColumnsFane3 = () => {
    if (harSmk) {
      return getColumnsFane3_MedSmk();
    } else {
      return getColumnsFane3_UtenSmk();
    }
  };

  const getColumnsFane3_MedSmk = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        isSorted: true,
        sortDirection: 'desc',
        formatter: function(item) {
          if (item.notification) {
            return (
              <img
                height='16'
                width='16'
                src={obs2}
                alt=''
                title='Du står som ansvarlig'
              />
            );
          }
        },
      },
      {
        id: 'registrertDato',
        name: 'Dato',
        sortParams: 'id',
        isSorted: true,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.registrertDato === null) {
            return '';
          } else {
            var dato = new Date(item.registrertDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'saksbehandler',
        name: 'Saksbehandler',
        sortParams: 'saksbehandler',
        isSorted: false,
      },
      {
        id: 'sidemann',
        name: 'Sideperson',
        sortParams: 'sidemann',
        isSorted: false,
        sortable: true,
      },
      {
        id: 'ansvarlig',
        name: 'Ansvar nå',
        sortParams: 'ansvarlig',
        isSorted: false,
        sortable: true
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        isSorted: false,
        sortable: true,
      },
      {
        id: 'dokumentnavn',
        name: 'Dokument',
        sortParams: 'dokumentnavn',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '12px' }}>{item.dokumentnavn}</span>;
        },
      },
      {
        id: 'sidemannGodkjent',
        name: 'Status',
        sortParams: 'sidemannGodkjent',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.ansvarlig === 'Sidemann') {
            if (item.sidemannGodkjent) {
              return 'Godkjent';
            } else {
              return 'Under behandling';
            }
            // Ikke sidemann inne
          } else if (item.sidemannId == null || item.sidemannId === 0) {
            return 'Under behandling';
            //
          } else {
            if (item.sidemannGodkjent) {
              return 'Godkjent';
            } else {
              return 'Ikke godkjent';
            }
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => apneOppgave(item)}>
                Åpne oppgave
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => fjernOppgave(item)}>
                Fjern oppgave
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={() => byttSaksbehandlerPaOppgave(item)}>
                Bytt saksbehandler
              </ContextMenu.Item>
              {item.ansvarlig === 'Sideperson' ? (
                <ContextMenu.Item onClick={() => byttSidemannPaOppgave(item)}>
                  Bytt sideperson
                </ContextMenu.Item>
              ) : null}
              {item.sidemannId == null || item.sidemannId === 0 ? (
                <ContextMenu.Item onClick={() => byttSidemannPaOppgave(item)}>
                  Velg sideperson
                </ContextMenu.Item>
              ) : (
                <ContextMenu.Item onClick={() => fjernSidemannFane3(item)}>
                  Avbryt kontroll
                </ContextMenu.Item>
              )}
            </ContextMenu.Button>
          );
        },
      },
    ];
  };

  const getColumnsFane3_UtenSmk = () => {
    return [
      {
        id: 'notification',
        name: '',
        sortParams: 'notification',
        isSorted: true,
        sortDirection: 'desc',
        formatter: function(item) {
          if (item.notification) {
            return (
              <img
                height='16'
                width='16'
                src={obs2}
                alt=''
                title='Du står som ansvarlig'
              />
            );
          }
        },
      },
      {
        id: 'registrertDato',
        name: 'Dato',
        sortParams: 'id',
        isSorted: true,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.registrertDato === null) {
            return '';
          } else {
            var dato = new Date(item.registrertDato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'saksbehandler',
        name: 'Saksbehandler',
        sortParams: 'saksbehandler',
        isSorted: false,
      },
      {
        id: 'ansvarlig',
        name: 'Ansvar nå',
        sortParams: 'ansvarlig',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.ansvarlig === 'Sidemann') {
            return 'Sideperson';
          } else {
            return (item.ansvarlig);
          }
        }
      },
      {
        id: 'bruksenhetAdresse',
        name: 'Adresse',
        sortParams: 'bruksenhetAdresse',
        isSorted: false,
        sortable: true,
      },
      {
        id: 'dokumentnavn',
        name: 'Dokument',
        sortParams: 'dokumentnavn',
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return <span style={{ fontSize: '12px' }}>{item.dokumentnavn}</span>;
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button>
              <ContextMenu.Item onClick={() => apneOppgave(item)}>
                Åpne oppgave
              </ContextMenu.Item>
              <ContextMenu.Item onClick={() => fjernOppgave(item)}>
                Fjern oppgave
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={() => byttSaksbehandlerPaOppgave(item)}>
                Bytt saksbehandler
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        },
      },
    ];
  };

  return (
    <div
      style={{
        margin: '4px',
        padding: '10px',
      }}>
      <div className='form'>
        <div className='row back-norkart-grey'>
          <p />
          <div className='form-group'>
            <span>
              <label className='top-margin-4'>
                &nbsp;&nbsp;Vis oppgaver for&nbsp;
              </label>
              <select
                className='h30 width220'
                value={person}
                id='type'
                onChange={handleAnsvarligChange}>
                {_.map(personer, function(o, i) {
                  return (
                    <option key={i} value={o.id}>
                      {o.navn}
                    </option>
                  );
                })}
              </select>
            </span>
            {harSmk && (
              <span
                style={{ marginRight: '10px', marginTop: '4px' }}
                className='pull-right'>
                <img
                  height='20'
                  width='20'
                  src={sidemannskontroll}
                  alt=''
                  title='Dokumentkontroll er aktivert'
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <br />

      <div>
        {harSmk ? (
          <BFButtonGroup
            ref={buttonGroupA}
            buttons={[
              // <span style={{ display: 'inline' }}>Under behandling {antallUnderBehandling > 0 ? <div><span className="badge">{antallUnderBehandling}</span></div> : null } </span>,
              <div>
                Under behandling{' '}
                <span className='badge'>{antallUnderBehandling}</span>
              </div>,
              'Ikke behandlet',
              <div>
                Klargjør rapport{' '}
                <span className='badge'>{antallRapporter}</span>
              </div>,
              // 'Publiserte åpne avvik',
              // 'Bruksenheter med upubliserte avvik',
              // 'Upubliserte avvik under behandling',
            ]}
            afterClick={buttonValgt}
            select={true}
            buttonWidth={200}></BFButtonGroup>
        ) : (
          <BFButtonGroup
            ref={buttonGroupA}
            buttons={[
              // <span style={{ display: 'inline' }}>Under behandling {antallUnderBehandling > 0 ? <div><span className="badge">{antallUnderBehandling}</span></div> : null } </span>,
              <div>
                Under behandling{' '}
                <span className='badge'>{antallUnderBehandling}</span>
              </div>,
              'Ikke behandlet',
              // 'Publiserte åpne avvik',
              // 'Bruksenheter med upubliserte avvik',
            ]}
            afterClick={buttonValgt}
            select={true}
            buttonWidth={200}></BFButtonGroup>
        )}
      </div>

      <br />
      {(isLoading || !harSmkInitiert) && (
        <React.Fragment>
          <Spinner className='h4' />
        </React.Fragment>
      )}
      {!isLoading && harSmkInitiert && (
        <div>
          {activeTable === 0 && (
            <div>
              <ReactTable
                items={dataFane1}
                showIndex={false}
                filterable={false}
                columns={getColumnsFane1()}
                selectableRows={false}
                onRowClick={VelgRadFane1}
                rowFormat={rowFormat}
              />
            </div>
          )}

          {activeTable === 1 && (
            <div>
              {/* <h3>Bruksenheter med ikke publiserte avvik/anmerkinger</h3> */}
              <ReactTable
                items={dataFane2}
                showIndex={false}
                filterable={false}
                columns={getColumnsFane2()}
                selectableRows={false}
                onRowClick={VelgRadFane2}
                rowFormat={rowFormat}
              />
            </div>
          )}

          {activeTable === 2 && (
            <div>
              <ReactTable
                items={dataFane3}
                showIndex={false}
                filterable={false}
                columns={getColumnsFane3()}
                selectableRows={false}
                onRowClick={VelgRadFane3}
                //rowFormat={rowFormat}
              />
            </div>
          )}
        </div>
      )}

      <JaNeiDialog
        tittel='Fjerne oppgave'
        tekst='Bekreft fjerning av oppgave'
        tekst2=''
        isOpen={isFjernOppgaveOpen}
        handleClose={handleFjernOppgaveClose}
        visClose={true}
      />

      <JaNeiDialog
        tittel='Åpne oppgave'
        tekst='Du er ikke ansvarlig sideperson til oppgaven.'
        tekst2='Ønsker du å åpne oppgaven likevell?'
        isOpen={isAapneOppgaveOpen}
        handleClose={handleAapneOppgaveClose}
        visClose={false}
      />

      <JaNeiDialog
        tittel='Fjern sideperson'
        tekst='Bekreft at kontrolloppgaven skal avbrytes'
        tekst2=''
        isOpen={isFjernSidemannFane3Open}
        handleClose={handleFjernSidemannFane3Close}
        visClose={true}
      />

      <JaNeiDialog
        tittel='Fjern sideperson'
        tekst='Bekreft at kontrolloppgaven skal avbrytes'
        tekst2=''
        isOpen={isFjernSidemannFane1Open}
        handleClose={handleFjernSidemannFane1Close}
        visClose={true}
      />

      <NKModal size='medium' isOpen={modalByttSidemannOpen}>
        <ByttSidemann
          onClose={lukkByttSidemann}
          tittel={'Velg sideperson'}
          valgtItem={valgtItem}
          type={sidemannBytteType}
        />
      </NKModal>

      <NKModal size='medium' isOpen={modalByttSaksbehandlerOpen}>
        <ByttSaksbehandler
          onClose={lukkByttSaksbehandler}
          tittel={'Velg saksbehandler'}
          valgtItem={valgtItem}
          type={saksbehandlerBytteType}
        />
      </NKModal>
    </div>
  );
}

export default withRouter(index);
