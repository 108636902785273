import 'bootstrap/dist/css/bootstrap.css';
import '@norkart/bootstrap-override/bootstrap-override.css';
import 'font-awesome/css/font-awesome.css';
import 'css/generated/Site.css';
import 'css/generated/bundle.css';
import 'css/generated/themingSearchkit/theme.css'; // Se https://github.com/searchkit/searchkit/blob/develop/theming/vars.scss
import 'css/generated/sokModal.css';
import bruker from 'images/Annet/bruker_standard.png';
import brukeradmin from 'images/Annet/bruker_admin.png';
import norkartbruker from 'images/Annet/bruker_norkart.png';
import pilotbruker from 'images/Annet/bruker_pilot.png';
import arkivadmin from 'images/Annet/bruker_arkiv.png';
import brukersidemann from 'images/Annet/bruker_sidemann.png';
import { withRouter } from 'lib/enhancers';

import Breadcrumbs from 'components/Breadcrumbs';
import LoadingOverlay, {
  LoadingFilter,
} from 'components/generics/LoadingOverlay';
import Nav from 'components/Nav/index.jsx';
// Redux
import { connect } from 'react-redux';
import * as appActions from 'redux/app/actions';
// Utils
import {
  contextInnstillingerPerson,
  contextDatoInnlogging,
  contextViaStart,
} from 'context/contextInnstillinger.js';
import norkartIdJs from 'auth/auth';

// fugly hack to make jQuery global available to bootstrap js
// TODO! remove any (and all) bootstrap js usage (there is a react-bootstrap for this)
global.jQuery = require('jquery');
require('bootstrap');


const mapStateToProps = (state, _ownProps) => ({
  isLoading: !!state.app.requestCount,
  exception: state.app.exception,
});

const MainLayout = (props) => {
  const year = new Date().getFullYear();
  const isLoading = props.isLoading;

  const loggInn = () => {
    norkartIdJs.logIn();
    props.router.replace('/');
    props.router.push('/');
  };

  const getIcon = () => {
    if (!contextInnstillingerPerson) {
      return <span />;
    }
    if (!contextInnstillingerPerson._currentValue) {
      return <span />;
    }
    /*     if (!contextInnstillingerPerson._currentValue.init) {
      return <span></span>;
    } */
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      return (
        <img
          style={{ marginBottom: '8px' }}
          height='18'
          width='18'
          src={norkartbruker}
          alt='NBruker'
          title='NK Bruker'
        />
      );
    }
    if (contextInnstillingerPerson._currentValue.organisasjonAdmin) {
      return (
        <img
          style={{ marginBottom: '8px' }}
          height='18'
          width='18'
          src={brukeradmin}
          alt='adminbruker'
          title='Amin Bruker'
        />
      );
    } else {
      return (
        <img
          style={{ marginBottom: '8px' }}
          height='18'
          width='18'
          src={bruker}
          alt='bruker'
          title='Bruker Brannforebygging'
        />
      );
    }
  };

  const getIconPilot = () => {
    if (!contextInnstillingerPerson) {
      return <span />;
    }
    if (!contextInnstillingerPerson._currentValue) {
      return <span />;
    }
    /*    if (!contextInnstillingerPerson._currentValue.init) {
      return <span></span>;
    } */
    if (contextInnstillingerPerson._currentValue.pilotBruker) {
      return (
        <img
          style={{ marginBottom: '8px' }}
          height='18'
          width='18'
          src={pilotbruker}
          alt='NBruker'
          title='Pilot Bruker'
        />
      );
    }
  };

  const getIconArkiv = () => {
    if (!contextInnstillingerPerson) {
      return <span />;
    }
    if (!contextInnstillingerPerson._currentValue) {
      return <span />;
    }

    if (contextInnstillingerPerson._currentValue.arkivAdmin) {
      //arkivAdmin  sidemannkontroll
      return (
        <img
          style={{ marginBottom: '8px' }}
          height='18'
          width='18'
          src={arkivadmin}
          alt='Ark'
          title='Arkivansvarlig'
        />
      );
    }
  };

  const getIconSidemann = () => {
    if (!contextInnstillingerPerson) {
      return <span />;
    }
    if (!contextInnstillingerPerson._currentValue) {
      return <span />;
    }

    if (contextInnstillingerPerson._currentValue.sidemannkontroll) {
      //arkivAdmin  sidemannkontroll
      return (
        <img
          style={{ marginBottom: '8px' }}
          height='18'
          width='18'
          src={brukersidemann}
          alt='Side'
          title='Sideperson'
        />
      );
    }
  };

  const hasBeenViaStart = () => {
    if (!contextViaStart._currentValue.start) {
      if (props.location.pathname !== '/') {
        console.log(
          '%c HOMEX ',
          'font-size: 14px; background: brown; color: white',
          'ALARM, ikke vært via START/HOME'
        );
        props.router.replace('/');
        props.router.push('/');
      }
    }
  };

  return props.exception ? (
    <pre>{JSON.stringify(props.exception, null, 2)}</pre>
  ) : (
    <div className='app'>
      <Nav routes={props.routes} name='Brannforebygging' />
      {hasBeenViaStart()}
      <LoadingOverlay
        isLoading={isLoading}
        marquee='Laster data, vennligst vent...'
      />

      <LoadingFilter
        isLoading={isLoading}
        className='container main body-content'>
        {/* *************************************************************************************** */}
        {contextDatoInnlogging._currentValue.usynk && (
          <div
            style={{
              border: '1px solid green',
              background: 'LemonChiffon',
              padding: '20px',
            }}>
            <br />
            <span
              style={{
                color: 'FireBrick',
                fontSize: '20px',
                fontWeight: 'bold',
              }}>
              Du må logge inn på nytt:
            </span>
            <span>
              {' '}
              <button
                style={{ marginLeft: '6px', fontSize: '16px' }}
                className='btn btn-warning '
                onClick={loggInn}>
                Logg inn
              </button>
            </span>
            <br />
            <span style={{ fontSize: '14px' }}>
              Beste praksis er å logge inn og ut hver dag. Da jobber du alltid
              med siste versjon.
            </span>
            <br />
            <br />
          </div>
        )}

        {/* *************************************************************************************** */}

        <Breadcrumbs routes={props.routes} params={props.params} />
        <div style={{ minHeight: '200px' }}>{props.children}</div>

        <div id='messages'>{props.messages}</div>
        <hr />
        <footer>
          {/*  <p
            style={{ color: '#2e2d30', marginBottom: '0', textAlign: 'right' }}>
            {' '}
            NORKART AS &nbsp; &copy; {year} &nbsp;
          </p> */}
          <div className='pull-right'>
            <contextInnstillingerPerson.Consumer>
              {(context) => (
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: '16px',
                          color: '#2e2d30',
                          textAlign: 'right',
                        }}>
                        Norkart &nbsp; &copy; {year} &nbsp;
                      </td>
                      <td />
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: '14px',
                          color: '#2e2d30',
                          textAlign: 'right',
                        }}>
                        {context.orgNavn}
                      </td>
                      <td>&nbsp; &nbsp;</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          fontSize: '14px',
                          color: '#2e2d30',
                          textAlign: 'right',
                          verticalAlign: 'center',
                        }}>
                        {getIcon()}
                        {getIconPilot()}
                        {getIconSidemann()}
                        {getIconArkiv()}&nbsp;<span>{context.navn}</span>
                        {/*      {getIcon()}
                        {getIconPilot()}&nbsp;<span>{context.navn}</span> */}
                      </td>
                      <td>&nbsp; &nbsp;</td>
                    </tr>
                    <tr>
                      <td
                        style={{
                          textAlign: 'right',
                          color: 'gray',
                          fontSize: '12px',
                        }}>
                        {contextDatoInnlogging._currentValue.dato}
                      </td>
                      <td>&nbsp; &nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </contextInnstillingerPerson.Consumer>
          </div>
        </footer>
      </LoadingFilter>
    </div>
  );
};

export default connect(mapStateToProps, appActions)(withRouter(MainLayout));
