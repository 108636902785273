import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import appInsights from 'lib/appInsights';
import { contextInnstillingerPerson } from 'context/contextInnstillinger.js';
import { debugFactory, objGetDeep, parseMultiLineString } from 'utils';
const debug = debugFactory('nk:error');


class ErrorBoundary extends React.Component {

    static propTypes = {
        noLogging: PropTypes.bool,
        fallbackUi: PropTypes.any.isRequired, // <- React Component
        handledAt: PropTypes.func.isRequired, // <- MUST return a string
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.handledAt = '<handledAt>';
    }

    logError(handledAt, error, info) {
        try {
            // Set the `customDimensions` to track        
            // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackexception
              /*  ${objGetDeep(this.props.store, 'userPermissions.orgNavn', '<orgNavn!>')} 
                (${objGetDeep(this.props.store, 'userPersonalia.navn', '<brukernavn!>')}) */ 
            let navn = contextInnstillingerPerson._currentValue.navn; 
            let orgNavn = contextInnstillingerPerson._currentValue.orgNavn;
            const user = parseMultiLineString( navn + ' ' + orgNavn);


            const customDimensions = {
                handledAt, // https://github.com/Microsoft/ApplicationInsights-JS/issues/569
                user,
                errorMessage: error.message,
                pathname: objGetDeep(this, 'props.router.location.pathname'),
                routename: last(objGetDeep(this, 'props.router.routes', [{}])).name, 
                // TODO! Get a proper `soureMapStack` from the (ugly) es5-transpiled `info.componentStack`
                componentStack: info.componentStack.replace(' ', '.'),
            };

            const measurements = {};
            const severityLevel = 2;

            debug('logError', { handledAt, customDimensions });         
            
            appInsights.trackException(error, handledAt, customDimensions, measurements, severityLevel);

        } catch (err) {
            // TODO! To blow up or fail silently, that is the question!
            console.error('BE VERY, VERY ALARMED! The exception-handler threw an exception!');
            throw (err);
        }
    }

    componentDidCatch(error, info) {
        const handledAt = this.props.handledAt({ ...this.props, error, info });

        if (!this.props.noLogging) {
            this.logError(handledAt, error, info);
        }
        // Don't use `setState` here!! (Why?)
        this.setState({ 
            hasError: true, 
            error, 
            info,
            handledAt,  
        });
    }

    render() {
       // debug('render', { ...this.props });
        const FallbackUi = this.props.fallbackUi;
        const { handledAt, hasError, error, info } = this.state;
        
        if (hasError) {
            return (
                <FallbackUi handledAt={handledAt} error={error} info={info} />
            );
        }
        return this.props.children;
    }
};
const ErrorBoundaryWithStore = ErrorBoundary;

export function createErrorBoundary({ handledAt, FallbackUi, noLogging }) {
    return (props) => (<ErrorBoundaryWithStore {...props} noLogging={noLogging} fallbackUi={FallbackUi} handledAt={handledAt} />);
}

export default ErrorBoundaryWithStore;