/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import { getIkkePubliserte } from 'api/avvikApi.js';
import { brukBreadcrumb } from 'components/Breadcrumbs.jsx';
import ReactTable from '@norkart/react-table';
import bruksenhet_ikon from 'images/48/hjem.png';
import royk_ikon from 'images/48/nk_075-royklop-A-48px.png';
import ildsted_ikon from 'images/48/nk_076-ildsted-A-48px.png';
import senddialogwhite from 'images/Annet/senddialogwhite.png';
import ContextMenu from 'components/ContextMenu';
import NKModal from 'components/Dialogs/NKModal.jsx';
import DialogStore from 'lib/DialogStore';
import { harSMK } from 'context/contextOppslag.js';
import AvvikForm from 'modules/felles/avvikAnmerkninger/AvvikForm.jsx';
import AnmerkningForm from 'modules/felles/avvikAnmerkninger/AnmerkningForm.jsx';
import EnhetForm from 'modules/Gjennomforing/Enhet/EnhetForm.jsx';
import EndreIldsted from 'modules/Gjennomforing/Ildsted/EndreIldsted.jsx';
import EndreRoyklop from 'modules/Gjennomforing/Royklop/EndreRoyklop.jsx';
import enhetApi from 'api/enhetApi';
import ildstedApi from 'api/ildstedApi';
import royklopApi from 'api/royklopApi';
import VerifiserEierkontakter2 from './VerifiserEierkontakter2.jsx';
import { debugFactory } from 'utils';
import { setSisteFaneBesoekt } from 'context/contextOppfolging';
import _ from 'underscore';
import DeltPipe from 'images/deltpipe.png';

const debug = debugFactory('nk:oppfølging:ikkepublisertBruksenhet');

// const withNorkartAdmin = withAuthorization(['norkartAdmin']);
// const NkAdminLiButton = withNorkartAdmin(ToolbarButtonRed);

class IkkePublisertBruksenhet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avvik: null,
      anmerkninger: null,
      valgteAvvik: null,
      valgteAnm: null,
      test: null,
      laster: false,
      visSidemannsKnapp: false,
      underSidemannsKontroll: false
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
    this.getData();
    this.ammendBreadCrumbs();
    setSisteFaneBesoekt('IkkePubliserte');
    this.skalSidemannsVises();
  }

  skalSidemannsVises= () => {
    harSMK(this.skalSidemannsVises2);
  };

  skalSidemannsVises2= (data) => {
    debug('MANDAG bbb', data);
    if ( data ){
      this.setState({ visSidemannsKnapp: true });
    }
  };

  ammendBreadCrumbs = () => {
    /*  brukBreadcrumbNameHelper(
      'Ikke-publiserte for bruksenhet',
      this.props.location.state.adresse
    ); */

    //============= REACT CONTEXT BREADCRUMBS ====================
    brukBreadcrumb(
      'Ikke-publiserte for bruksenhet',
      'oppfolging_ikkepublisert_adresse'
    );
  };

  getData = () => {
    getIkkePubliserte(this.props.location.state.brId, this.gotData);
  };

  gotData = (err, data) => {
    console.group('TIRSDAG');
    console.table(data);
    console.groupEnd('TIRSDAG');
    if (data !== undefined) {
      // fjern de som allerede er under behandling
      let tmp = _.filter(
        data.avvikListe,
        (item) => item.oppfolgingType === null
      );
      let avData = tmp.sort(this.sortDato);
      //let avData = data.avvikListe.sort(this.sortDato);
      avData = this.sorterEtterType(avData);
      this.setState({ avvik: avData, valgteAvvik: avData });
      this.setState({ avvikerCount: data.avvikListe.length });
      // fjern de som allerede er under behandling
      let tmp2 = _.filter(
        data.anmerkningListe,
        (item) => item.oppfolgingType === null
      );
      let anmData = tmp2.sort(this.sortDato);
      anmData = this.sorterEtterType(anmData);
      this.setState({ anmerkninger: anmData, valgteAnm: anmData });
      this.setState({
        anmerkningerCount: data.anmerkningListe.length,
      });
    }
    this.setState({ adresse: this.props.adresse });
  };

  sorterEtterType = (liste) => {
    let result = [];
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].objektType.toString().startsWith('B')) {
        result.push(liste[i]);
      }
    }
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].objektType.toString().startsWith('R')) {
        result.push(liste[i]);
      }
    }
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].objektType.toString().startsWith('I')) {
        result.push(liste[i]);
      }
    }
    //Under Sidemann
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].sidemannsKontroll === true) {
        this.setState({ underSidemannsKontroll: true });
      }
      if (liste[i].sidemannskontroll === true) {
        this.setState({ underSidemannsKontroll: true });
      }
    }
    return result;
  };

  endreAvvik = (avvik) => {
    var objRefType = 'B';
    if (avvik.objektType === 'Røykløp') {
      objRefType = 'R';
    }
    if (avvik.objektType === 'Ildsted') {
      objRefType = 'I';
    }
    this.dialogs.setOpenDialog('AvvikForm', {
      objektReferanseType: objRefType,
      objektReferanseId: avvik.objektReferanseId,
      bruksenhetid: this.props.location.state.brId,
      emne: this.props.emne,
      adresse: this.props.location.state.adresse,
      identi: this.props.identi,
      id: avvik.id,
      avvikTekst: avvik.avvikType,
      steng: this.props.onClose,
    });
  };

  endreAnm = (anm) => {
    var objRefType = 'B';
    if (anm.objektType === 'Røykløp') {
      objRefType = 'R';
    }
    if (anm.objektType === 'Ildsted') {
      objRefType = 'I';
    }
    this.dialogs.setOpenDialog('AnmerkningForm', {
      objektReferanseType: objRefType,
      objektReferanseId: anm.objektReferanseId,
      bruksenhetid: this.props.location.state.brId,
      emne: this.props.emne,
      adresse: this.props.location.state.adresse,
      identi: this.props.identi,
      id: anm.id,
    });
  };

  endreRoyklop = (royklop) => {
    console.log(royklop);
    this.dialogs.setOpenDialog('EndreRoyklop', {
      royklopId: royklop.objektReferanseId,
    });
  };

  oppdaterRoyklop = (endretRoyklop) => {
    royklopApi.updateRoyklop(
      endretRoyklop.id,
      endretRoyklop,
      this.royklopOppdatert
    );
    this.dialogs.closeDialog();
  };

  royklopOppdatert = () => {};

  endreBruksenhet = (bruksenhet) => {
    console.log(bruksenhet);
    this.dialogs.setOpenDialog('EnhetForm', {
      id: this.props.location.state.brId,
      eiendom: this.props.location.state.eiendom,
    });
  };

  oppdaterBruksenhet = (endretBruksenhet) => {
    enhetApi.updateBruksenhet(
      endretBruksenhet.id,
      endretBruksenhet,
      this.enhetOppdatert
    );
    this.dialogs.closeDialog();
  };
  enhetOppdatert = (err, oppdatertBruksenhet) => {};

  endreIldsted = (ildsted) => {
    console.log(ildsted);
    this.dialogs.setOpenDialog('EndreIldsted', {
      ildstedId: ildsted.objektReferanseId,
    });
  };

  oppdaterIldsted = (oppdatertIldsted) => {
    ildstedApi.updateIldsted(
      oppdatertIldsted.id,
      oppdatertIldsted,
      this.ildstedOppdatert
    );
    this.dialogs.closeDialog();
  };

  ildstedOppdatert = (err, ildsted) => {};

  onLagred = () => {
    getIkkePubliserte(this.props.location.state.brId, this.gotData);
  };

  avvikRowSelected = (rows) => {
    this.setState({ valgteAvvik: rows });
  };
  anmRowSelected = (rows) => {
    this.setState({ valgteAnm: rows });
  };

  // publiser = () => {
  //   this.dialogs.setOpenDialog('VerifiserEierkontakter', {
  //     valgteAvvik: this.state.valgteAvvik,
  //     valgteAnm: this.state.valgteAnm,
  //     brId: this.props.location.state.brId,
  //     arbeidslisteId: this.props.location.state.arbListeId
  //   });
  // };
  publiser2 = (sendtil) => {
    console.log(this.props.location.state.arbListeId);
    this.dialogs.setOpenDialog('VerifiserEierkontakter2', {
      valgteAvvik: this.state.valgteAvvik,
      valgteAnm: this.state.valgteAnm,
      brId: this.props.location.state.brId,
      arbeidslisteId: this.props.location.state.arbListeId,
      matrikkelenhet: this.props.location.state.eiendom,
      sendtil: sendtil,
    });
  };

  onFerdig = () => {
    console.log('onFerdig');
    this.dialogs.closeDialog();
    this.props.router.push({
      pathname: 'oppfolging/',
      state: { update: true, 
        fra: 'IkkePubliserte' },
    });
    // this.props.router.push({
    //   pathname: 'oppfolging/AvvikIkkePublisert/',
    //   state: { update: true },
    // });
  };

  // utvidColumnsMedSidemann = (col, ix) => {
  //   let sidemanCol = {
  //     id: 'sidemannsKontroll',
  //     name: (<span>
  //       <img
  //         title='Under sidemannskontroll'
  //         style={{ marginBottom: '8px' }}
  //         height='20'
  //         width='20'
  //         src={brukersidemann}
  //         alt='SideM.'
  //       />
  //   </span>),
  //     isSorted: false,
  //     sortable: false,
  //     formatter: function(item) {
  //       // if (item.sidemannsKontroll === true || item.sidemannskontroll === true){;
  //       //   return <span style={{ paddingLeft: '4px', fontStyle: 'bold' }}>X</span>;
  //       // }
  //       if (item.oppfolgingType !== null){;
  //         return <span style={{ paddingLeft: '4px', fontStyle: 'bold' }}>X</span>;
  //       }
  //     }
  //   };
  
  //   col.splice(ix, 0, sidemanCol);
  //   return col;
  // };

  render() {
    let sitKnappStyle = {
      float: 'left',
      marginLeft: '10px',
      marginTop: '4px',
    };

    if (!this.state.avvik) {
      return <div className='loader' />;
    }
    var avvikColumns = this.getAvvikColumns();
    var anmColumns = this.getAnmColumns();

    // if (this.state.visSidemannsKnapp) {
    //   avvikColumns = this.utvidColumnsMedSidemann(avvikColumns, 4);
    //   anmColumns = this.utvidColumnsMedSidemann(anmColumns, 5);
    // }
    
    return (
      <div>
        <h2>
          Oversikt over ikke-publiserte avvik og anmerkninger/andre forhold på{' '}
          {this.props.location.state.adresse}
        </h2>
        <br />
        <div>
          <h4>Avvik</h4>
          {this.state.avvik.length === 0 ? (
            <div> - Ingen avvik</div>
          ) : (
            <div>
              <ReactTable
                showIndex={false}
                items={this.state.avvik}
                columns={avvikColumns}
                selectableRows={true}
                onRowClick={null}
                onRowSelectionChange={this.avvikRowSelected}
                toggleAll={true}
                toggleAllDefault={true}
                sortable={false}
                multiSort={false}
                filterable={false}
              />
            </div>
          )}
        </div>
        <br />
        <div>
          {this.state.anmerkninger === null ? null : (
            <div>
              <h4>Anmerkninger og andre forhold</h4>
              {this.state.anmerkninger.length === 0 ? (
                <div> - Ingen anmerkninger/andre forhold</div>
              ) : (
                <div>
                  <ReactTable
                    showIndex={false}
                    items={this.state.anmerkninger}
                    columns={anmColumns}
                    selectableRows={true}
                    onRowClick={null}
                    onRowSelectionChange={this.anmRowSelected}
                    toggleAll={true}
                    toggleAllDefault={true}
                    sortable={false}
                    multiSort={false}
                    filterable={false}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {!this.state.visSidemannsKnapp && (
          <button
            onClick={this.publiser2.bind(this, 'eier')}
            className='btn btn-success pull-right btnBottomSend'>
            Send til eier
          </button>
        )}

        {this.state.visSidemannsKnapp && (
          <div className='pull-right'>
            {this.state.underSidemannsKontroll && (
              <span style={{ color: 'blue', fontSize: '12px' }}>Avvik/Anmerkninger er under <br/>behandling i dokumentkontroll</span>
            )}
            <div  style={{ height: '40px', width: '100%',  position: 'relative' }}> {/*  className='dropdown' */}
              <button
                style={{ height: '40px', width: '200px' }}
                className='btn btn-success dropdown-toggle'
                type='button'
                data-toggle='dropdown'>
                <span style={{ float: 'left', marginLeft: '10px' }}>
                  <img height='24' width='24' src={senddialogwhite} alt='' />
                </span>
                <span style={sitKnappStyle}>Send til ...</span>
                <span
                  className='caret pull-right'
                  style={{ marginTop: '10px', borderWidth: '8px' }}
                />
              </button>
              <ul className='dropdown-menu' style={{ width: '100%' }}>
                <li>
                  <a onClick={this.publiser2.bind(this, 'eier')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Eier og arkiv
                    </span>
                  </a>
                </li>
                <li>
                  <a onClick={this.publiser2.bind(this, 'side')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Kontroll
                    </span>
                  </a>
                </li>
                <li>
                  <a onClick={this.publiser2.bind(this, 'saks')}>
                    <span style={{ padding: '4px', cursor: 'pointer' }}>
                      Saksbehandler
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}

        <br />
        <br />
        <br />
        <br />
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  getAvvikColumns = (owner) => {
    return [
      {
        id: 'objektType',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var objectIcon = bruksenhet_ikon;
          switch (item.objektType) {
            case 'Røykløp':
              objectIcon = royk_ikon;
              break;
            case 'Ildsted':
              objectIcon = ildsted_ikon;
              break;
            default:
              objectIcon = bruksenhet_ikon;
          }
          return (
            <img
              height='24'
              width='24'
              src={objectIcon}
              className='app-preloader__img pull-left mr8'
              alt=''
            />
          );
        },
      },
      {
        id: 'delt',
        name: '',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
         if (item.bruksenhetIdsTilknyttet !== null) {
          return (
            <img
              height="24"
              width="24"
              src={DeltPipe}
              className="app-preloader__img pull-left mr8"
              alt=""
              title="Delt"
            />
          );       
         }
        },
      },
      {
        id: 'avvikType',
        name: 'Avvikstype',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var avvikstype = item.avvikType;
          return <p style={{ width: '200px' }}>{avvikstype}</p>;
        },
      },
      {
        id: 'objektplassering',
        name: 'Gjelder',
        filterable: false,
        isSorted: false,
        sortable: false,
      },
      {
        id: 'dato',
        name: 'Registrert dato',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.dato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          switch (item.objektType) {
            case 'Røykløp':
              return (
                <ContextMenu.Button
                  title='Meny'
                  icon='meny'
                  iconColor='nk-black'
                  className='inlinebtn'>
                  <ContextMenu.Item
                    onClick={this.endreRoyklop.bind(this, item)}>
                    Endre røykløp
                  </ContextMenu.Item>
                  <ContextMenu.Item onClick={this.endreAvvik.bind(this, item)}>
                    Endre avvik
                  </ContextMenu.Item>
                </ContextMenu.Button>
              );

            case 'Ildsted':
              return (
                <ContextMenu.Button
                  title='Meny'
                  icon='meny'
                  iconColor='nk-black'
                  className='inlinebtn'>
                  <ContextMenu.Item
                    onClick={this.endreIldsted.bind(this, item)}>
                    Endre ildsted
                  </ContextMenu.Item>
                  <ContextMenu.Item onClick={this.endreAvvik.bind(this, item)}>
                    Endre avvik
                  </ContextMenu.Item>
                </ContextMenu.Button>
              );
            default:
              return (
                <ContextMenu.Button
                  title='Meny'
                  icon='meny'
                  iconColor='nk-black'
                  className='inlinebtn'>
                  <ContextMenu.Item
                    onClick={this.endreBruksenhet.bind(this, item)}>
                    Endre bruksenhet
                  </ContextMenu.Item>
                  <ContextMenu.Item onClick={this.endreAvvik.bind(this, item)}>
                    Endre avvik
                  </ContextMenu.Item>
                </ContextMenu.Button>
              );
          }
        }.bind(this),
      },
    ];
  };

  getAnmColumns = () => {
    return [
      {
        id: 'objektType',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var objectIcon = bruksenhet_ikon;
          switch (item.objektType) {
            case 'Røykløp':
              objectIcon = royk_ikon;
              break;
            case 'Ildsted':
              objectIcon = ildsted_ikon;
              break;
            default:
              objectIcon = bruksenhet_ikon;
          }
          return (
            <img
              height='24'
              width='24'
              src={objectIcon}
              className='app-preloader__img pull-left mr8'
              alt=''
            />
          );
        },
      },
      {
        id: 'delt',
        name: '',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
         if (item.bruksenhetIdsTilknyttet !== null) {
          return (
            <img
              height="24"
              width="24"
              src={DeltPipe}
              className="app-preloader__img pull-left mr8"
              alt=""
              title="Delt"
            />
          );       
         }
        },
      },
      {
        id: 'type',
        name: 'Type',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.andreForhold) {
            return 'Andre forhold';
          } else {
            return 'Anmerkning';
          }
        },
      },
      {
        id: 'kategori',
        name: 'Kategori',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          var kategori = item.kategori;
          if (kategori == null || kategori === '') {
            return 'Ikke oppgitt';
          } else {
            return <p style={{ width: '200px' }}>{kategori}</p>;
          }
        },
      },
      {
        id: 'objektplassering',
        name: 'Gjelder',
        filterable: false,
        isSorted: false,
        sortable: false,
      },
      {
        id: 'dato',
        name: 'Registrert dato',
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.dato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          switch (item.objektType) {
            case 'Røykløp':
              return (
                <ContextMenu.Button
                  title='Meny'
                  icon='meny'
                  iconColor='nk-black'
                  className='inlinebtn'>
                  <ContextMenu.Item
                    onClick={this.endreRoyklop.bind(this, item)}>
                    Endre røykløp
                  </ContextMenu.Item>
                  <ContextMenu.Item onClick={this.endreAnm.bind(this, item)}>
                    Endre anmerkning
                  </ContextMenu.Item>
                </ContextMenu.Button>
              );

            case 'Ildsted':
              return (
                <ContextMenu.Button
                  title='Meny'
                  icon='meny'
                  iconColor='nk-black'
                  className='inlinebtn'>
                  <ContextMenu.Item
                    onClick={this.endreIldsted.bind(this, item)}>
                    Endre ildsted
                  </ContextMenu.Item>
                  <ContextMenu.Item onClick={this.endreAnm.bind(this, item)}>
                    Endre anmerkning
                  </ContextMenu.Item>
                </ContextMenu.Button>
              );
            default:
              return (
                <ContextMenu.Button
                  title='Meny'
                  icon='meny'
                  iconColor='nk-black'
                  className='inlinebtn'>
                  <ContextMenu.Item
                    onClick={this.endreBruksenhet.bind(this, item)}>
                    Endre bruksenhet
                  </ContextMenu.Item>
                  <ContextMenu.Item onClick={this.endreAnm.bind(this, item)}>
                    Endre anmerkning
                  </ContextMenu.Item>
                </ContextMenu.Button>
              );
          }
        }.bind(this),
      },
    ];
  };
}

const modalDialogs = {
  AvvikForm: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen}>
        <AvvikForm
          onClose={onClose}
          steng={dialogData.steng}
          tittel={dialogData.tittel}
          objektReferanseType={dialogData.objektReferanseType}
          adresse={dialogData.adresse}
          objektReferanseId={dialogData.objektReferanseId}
          bruksenhetid={dialogData.bruksenhetid}
          onLagred={owner.onLagred}
          id={dialogData.id}
          emne={dialogData.emne}
          avvikTekst={dialogData.avvikTekst}
          identi={dialogData.identi}
        />
      </NKModal>
    );
  },

  AnmerkningForm: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen}>
        <AnmerkningForm
          onClose={onClose}
          tittel={dialogData.tittel}
          objektReferanseType={dialogData.objektReferanseType}
          adresse={dialogData.adresse}
          objektReferanseId={dialogData.objektReferanseId}
          bruksenhetid={dialogData.bruksenhetid}
          onLagred={owner.onLagred}
          id={dialogData.id}
          emne={dialogData.emne}
          identi={dialogData.identi}
        />
      </NKModal>
    );
  },
  EnhetForm: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <EnhetForm
          onClose={onClose}
          endreEnhet={owner.oppdaterBruksenhet}
          id={dialogData.id}
          eiendom={dialogData.eiendom}
        />
      </NKModal>
    );
  },
  EndreIldsted: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <EndreIldsted
          onClose={onClose}
          ildstedId={dialogData.ildstedId}
          nyendre='endre'
          onEndreIldsted={owner.oppdaterIldsted}
        />
      </NKModal>
    );
  },
  EndreRoyklop: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='medium' isOpen={isOpen}>
        <EndreRoyklop
          onClose={onClose}
          onEndreRoyklop={owner.oppdaterRoyklop}
          royklopId={dialogData.royklopId}
        />
      </NKModal>
    );
  },
  // VerifiserEierkontakter: ({ isOpen, onClose, dialogData, owner }) => {
  //   return (
  //     <NKModal size="large" isOpen={isOpen}>
  //       <VerifiserEierkontakter
  //         onClose={onClose}
  //         brId={dialogData.brId}
  //         adresse={dialogData.adresse}
  //         valgteAvvik={dialogData.valgteAvvik}
  //         valgteAnm={dialogData.valgteAnm}
  //         arbeidslisteId={dialogData.arbeidslisteId}
  //         onFerdig={owner.onFerdig}
  //       />
  //     </NKModal>
  //   );
  // },
  VerifiserEierkontakter2: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <NKModal size='large' isOpen={isOpen}>
        <VerifiserEierkontakter2
          onClose={onClose}
          brId={dialogData.brId}
          adresse={dialogData.adresse}
          valgteAvvik={dialogData.valgteAvvik}
          valgteAnm={dialogData.valgteAnm}
          arbeidslisteId={dialogData.arbeidslisteId}
          matrikkelenhet={dialogData.matrikkelenhet}
          onFerdig={owner.onFerdig}
          sendtil={dialogData.sendtil}
        />
      </NKModal>
    );
  },
};

export default IkkePublisertBruksenhet;
