import React, { useState, useEffect } from 'react';
import NkaFormElementDropdown from 'nka-form-element-dropdown';

const oltyper = [
   { 
     value: 1,
     label: 'LAGER'
   },
  { 
    value: 2,
    label: 'IPA'
  },
  { 
    value: 3,
    label: 'STOUT'
  },
  { 
    value: 4,
    label: 'BLONDE'
  },
  { 
    value: 5,
    label: 'PALE ALE'
  },
];

function Test2() {
  const [typer, setTyper] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [valgtType, setValgtType] = useState();

  useEffect(() => {
    if (isLoading) {
      hentData();
    }
  });

  const hentData = () => {
    
    setIsLoading(false);
    setTyper(oltyper);
    console.log(oltyper);
  };

  const handleTypeChange = (e) => {
    console.log(e);
    setValgtType(e);
  };

  return (
    <div>
      <div style={{ marginLeft: '24px' }}>
        <h3>Øltyper</h3>
        <br />
        <div style={{ width: 400 }}>
          <NkaFormElementDropdown
            handleValueChange={handleTypeChange}
            value={valgtType}
            values={typer}
            other={{ placeholder: 'Velg type' }}
          />
        </div>
      </div>
      <hr/>
      {console.log(valgtType)}
      {valgtType ? <div>
        <p>
        Lager is a type of beer that is fermented and aged using a strain of yeast called Saccharomyces pastorianus, which ferments at lower temperatures than the yeast used to make ale. Lager yeast ferments at temperatures between 45-55°F (7-13°C), while ale yeast ferments at temperatures between 60-75°F (15-24°C). The lower fermentation temperature of lager yeast results in a slower fermentation process, which allows the beer to mature and develop a smoother, cleaner flavor.

Lagers are typically pale in color and have a crisp, refreshing taste. They are often described as having a clean, smooth, and balanced flavor, with a subtle hop character and a noticeable but not overpowering malt presence. Lagers can range in strength from light and easy-drinking to full-bodied and complex. Some common styles of lager include Pilsner, Oktoberfest, and Bock.

Lager has a longer history than ale, dating back to the 15th century in the Czech Republic. Today, lager is the most popular type of beer in the world, with many of the most well-known brands being lagers, such as Budweiser, Coors, and Corona.
        </p>
      </div> : <div>null</div>}
    </div>
  );
}

export default Test2;
