import React from 'react';
import NKCollapsible from 'nka-collapsible';
import 'nka-collapsible/dist/style.css';
import Test from './TestSteinComp1.jsx';
import Test2 from './TestSteinComp2.jsx';
function TestStein({ tittel }) {
  return (
    <div>
      <NKCollapsible
       style={{ height: '40px' }}
        title='Oppskrifter'
        icon='kart'
        color='nk-black'
        defaultCollapsed={true}>
        <div>
          <label>{tittel}</label>
          <br />
          <Test2/>
        </div>
      </NKCollapsible>
      <br/>
      <NKCollapsible
       style={{ height: '40px' }}
        title='Logg'
        icon='kart'
        color='nk-black'
        defaultCollapsed={true}>
        <div>
          <label>{tittel}</label>
          <br />
          <Test/>
        </div>
      </NKCollapsible>
    </div>
  );
}

export default TestStein;
