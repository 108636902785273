import React from 'react';
import * as _ from 'lodash';
import PanelBruksenheter from './PanelBruksenheter.jsx';
import PanelRoyklop from './PanelRoyklop.jsx';
import SidebarArbeidsliste from './SidebarArbeidsliste';
import PlanleggingsKart from 'components/Kart/PlanleggingsKart.jsx';
import LagreSokDialog from 'components/Search/LagreSok.jsx';
import { createSokLagret, getSokLagrede } from 'api/sokLagretApi';
import { findProperty } from 'components/Search/FindInArray';
import { getGeoObjekt } from 'components/Search/GeoAccessorHelper';
import Filters from 'components/Search/Filters.jsx';
import { getSortOptions } from 'components/Search/searchkitOptions';
import { GeoAccessor } from 'components/Search/Map/GeoAccessor.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer';
import { getVurderte } from 'api/grunndataApi';
import { hentFiltersIgjen } from 'context/contextFilters.js';
import {
  SearchkitProvider,
  Select,
  SortingSelector,
  PageSizeSelector,
  HitsStats,
  ResetFilters,
  GroupedSelectedFilters,
  Layout,
  LayoutBody,
  LayoutResults,
  ViewSwitcherHits,
  ActionBar,
  ActionBarRow,
  renderComponent,
  Pagination,
  Hits,
} from 'searchkit';
import { reloadSearch, search } from 'utils/searchKitHelper';

// Utils
import { parseHits, parsedHits2RoyklopHits } from '../lib';
import { debugFactory, objGetDeep } from 'utils';
import DialogStore from 'lib/DialogStore';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:pageobjekter:index'
);

Hits.prototype.render = function() {
  let hits = this.getHits();

  if (!this.isInitialLoading()) {
    const { listComponent, mod, className, itemComponent } = this.props;
    return renderComponent(listComponent, {
      hits,
      mod,
      className,
      itemComponent,
    });
  }

  return null;
};

var myTimer;

class PageObjekter extends React.Component {
  state = {
    tilsynChecked: true,
    feiingChecked: false,
    items: [],
    geoObjektFromSearchResult: null,
    selectedTiltak: [],
    showSidebar: true, // På arbeidsliste + valgte
    showFilters: true, // På filters
    accessorState: [],
    lagreSokDialogOpen: false,
    sokLagrede: [],
    removed: 0,
    fraLagredeMenu: false,
    visKart: 'col-sm-10',
    visSkjulKart: 'Skjul kart',
    visFilters: '',
    visSkjulFilters: 'Skjul filter',
    visArbeidsliste: 'col-md-2',
    visSkjulArbeidsliste: 'Skjul Arbeidsliste',
    visTabell: 'col-sm-2',
    visEnheter: '',
    visRoyklop: 'hide',
    wrapperClass: 'content-wrapper col-md-10',
    taMedChecked: true,
    vurderte: [],
    antallroyklopfunnet: '',
  };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  // accessor =  new GeoAccessor();

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    getVurderte((err, result) => {
      this.setState({ vurderte: result });
    });
    getSokLagrede(this.onGotSokLagrede);
    this.onGotKommuner();
  }

  componentDidUpdate(prevProps) {
    this.onGotKommuner();
  }

  onGotKommuner = () => {
    if (
      this.state.geoObjektFromSearchResult ||
      !this.props.searchkit ||
      _.isEmpty(this.props.store.orgKommuner)
    ) {
      return;
    }

    this.props.searchkit.addResultsListener((results) => {
      //this.setGeoObjektFromSearchResult(results);
      //  this.getAntallRoyklopFraResults1(results);
    });
  };

  getAntallRoyklopFraResults1 = (results) => {
    clearInterval(myTimer);
    if (this.state.antallroyklopfunnet !== '') {
      this.setState({ antallroyklopfunnet: '' });
    }
    myTimer = setInterval(
      this.getAntallRoyklopFraResults2.bind(null, results),
      2000
    );
  };

  getAntallRoyklopFraResults2 = (results) => {
    clearInterval(myTimer);

    if (results.aggregations) {
      if (results.aggregations.rehabilitert32) {
        if (results.aggregations.rehabilitert32.inner) {
          if (results.aggregations.rehabilitert32.inner.doc_count) {
            this.setState({
              antallroyklopfunnet:
                'Røykløp: ' +
                results.aggregations.rehabilitert32.inner.doc_count,
            });
          }
        }
      }
    }
  };

  // L A G R E  S Ø K
  onGotSokLagrede = (err, sokLagrede) => {
    this.setState({ sokLagrede: sokLagrede });
  };

  onDeletedSok = () => {
    getSokLagrede(this.onGotSokLagrede);
  };

  updateDimensions = () => {
    this.setState({
      showSidebar: window.matchMedia('(min-width: 1560px)').matches,
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  removeFromArbeidslisteWithId = (id) => {
    this.props.removeFromArbeidsliste(id, true);
  };

  sprett = (item) => {
    let tabell = this.refBruksenhetstabell;
    if (tabell !== null) {
      tabell.goSprett(item);
    } else {
      let tabell2 = this.refRoyklopstabell;
      if (tabell2 !== null) {
        tabell2.goSprett(item);
      }
    }
  };

  oppdaterLagredeIKart = () => {
    let tabell = this.refBruksenhetstabell;
    if (tabell !== null) {
      tabell.oppdaterLagrede();
    } else {
      let tabell2 = this.refRoyklopstabell;
      if (tabell2 !== null) {
        tabell2.oppdaterLagrede();
      }
    }
  };

  setGeoObjektFromSearchResult = (results) => {
    if (results.aggregations !== undefined) {
      if (results.aggregations.geo !== undefined) {
        const buckets = results.aggregations.geo.inner.areas.buckets;
        const total = results.aggregations.geo.doc_count;
        const geoObjektFromSearchResult = {
          total: total,
          bucketsCount: buckets.length,
          buckets: buckets,
        };
        /*    debug('setGeoObjektFromSearchResult', {
          results,
          geoObjektFromSearchResult,
        }); */
        this.setState({ geoObjektFromSearchResult });
      }
    }
  };

  itemsUpdated = () => {
    if (this.state.fraLagredeMenu) {
      this.oppdaterLagredeIKart();
    }
    this.setState({ fraLagredeMenu: false });
  };

  onClickSettTiltakstyper = () => {
    this.props.showNextPage();
  };

  onSelectedPerson = () => {
    reloadSearch(this.props.searchkit);
  };

  visLagreSokDialog = () => {
    this.setState({ lagreSokDialogOpen: true });
  };

  onLagreSok = (soklagre) => {
    soklagre.state = JSON.stringify(this.props.searchkit.accessors.getState());
    let polygon = findProperty(
      this.props.searchkit.query.query.query,
      'points'
    );
    polygon = _.last(polygon);
    soklagre.polygon = JSON.stringify(polygon);

    //Call API
    this.setState({ lagreSokDialogOpen: false });
    createSokLagret(soklagre, this.onSokLagret);
  };

  onSokLagret = (item) => {
    getSokLagrede(this.onGotSokLagrede);
    hentFiltersIgjen();
  };

  brukLagretSok = (item) => {
    //   debug('polygon index', item);
    this.setState({ fraLagredeMenu: true });
    this.props.searchkit.accessors.setState(JSON.parse(item.state));
    let geoAccessor = this.props.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    //  debug('polygon index', geoAccessor);
    if (item.polygon && geoAccessor) {
      let parsedPolygon = JSON.parse(item.polygon);
      let poly = getGeoObjekt(parsedPolygon.points);
      geoAccessor.setPolygon(null);
      geoAccessor.setPolygon(poly);
      //  debug('polygon index', poly);
    } else if (!item.polygon && geoAccessor) {
      geoAccessor.setPolygon(null);
      //  debug('polygon index', 'null');
    }
    reloadSearch(this.props.searchkit);
  };

  getValgteEnheter = (enheter, objektliste) => {
    objektliste = objektliste.filter((item) => item.slett === false);

    const valgteEnheter = enheter.filter((parsedHit) => {
      const bruksenhetId = objGetDeep(
        parsedHit,
        'source.skybrannData.bruksenhet.fagId'
      );
      const objekt = objektliste.find((objekt) => {
        // eslint-disable-next-line radix
        const found = parseInt(objekt.bruksenhetId) === parseInt(bruksenhetId);
        //debug('getValgteEnheter', { found, objekt, parsedHit})
        return found;
      });
      return !!objekt;
    });

    // debug('getValgteEnheter', { enheter, objektliste, valgteEnheter });

    return valgteEnheter;
  };

  // P A R S I N G  ( C O M P L E X  S T U F F )

  onSpretter = (row) => {
    let lok = {
      lokasjon: row.source.bygg.lokasjon,
    };

    this.refPlanleggingsKart.sprett(lok);
    this.refPlanleggingsKart.visKnappControl(row);
  };

  onSpretterRight = (row) => {
    let lok = null;
    if (_.get(row, 'lokasjonLatitude')) {
      lok = {
        lokasjon: { lat: row.lokasjonLatitude, lon: row.lokasjonLongitude },
      };
    }
    if (_.get(row, 'lokasjon.lat')) {
      lok = {
        lokasjon: { lat: row.lokasjon.lat, lon: row.lokasjon.lon },
      };
    }

    if (_.get(row, 'source.bygg.lokasjon.lat')) {
      lok = {
        lokasjon: { lat: row.source.bygg.lokasjon.lat, lon: row.source.bygg.lokasjon.lon },
      };
    }

    if (lok) {
      this.refPlanleggingsKart.sprett(lok);
    }

    this.refPlanleggingsKart.visKnappControl(null);
  };

  getMerInfo = (item) => {
    debug('FLINT 0', item);
  };

  onKopierTilRoyk = (item) => {
    this.getMerInfo(item);
    let rPanel = this.refRoyklopstabell;
    if (rPanel) {
      rPanel.addToWorklist(item);
    }
  };

  onBrukPolygon = (poly) => {
    // debug('kart poly1', poly);

    this.props.searchkit.geoAccessor.setPolygon(
      poly,
      this.onPolygonFilterRemoved
    );
    search(this.props.searchkit);
  };

  onPolygonFilterRemoved = () => {
    this.refPlanleggingsKart.fjernAllePolygonLayers();
  };

  onPolygonFjernet = () => {
    // debug('kart polyFjernet');
    this.props.searchkit.geoAccessor.setPolygon(null);
    search(this.props.searchkit);
  };

  // C O M P O N E N T S

  SidebarArbeidsliste = () => {
    return (
      <SidebarArbeidsliste
        {
          ...this.props /* Silver bullet =P */
        }
        onSpretterRight={this.onSpretterRight}
        onKopierTilRoyk={this.onKopierTilRoyk}
      />
    );
  };

  ResultatPanel = ({ hits }) => {
    // debug('kart hits', hits);
    const parsedHits = parseHits(hits, { debug });
    const royklopHits = parsedHits2RoyklopHits(parsedHits, { debug });
    const valgteEnheter = this.props.getTiltakListe({ bruksenheter: true });
    const valgteRoyklop = this.props.getTiltakListe({ royklop: true });

    return (
      <div className='container-fluid' style={{ padding: 0, width: '100%' }}>
        <div className='row'>
          <div className={this.state.visTabell} style={{ padding: 0 }}>
            <div className={this.state.visEnheter}>
              <PanelBruksenheter
                {
                  ...this.props /* Silver bullet =P */
                }
                ref={(ref) => (this.refBruksenhetstabell = ref)}
                removed={this.state.removed}
                enheter={parsedHits}
                vurderte={this.state.vurderte}
                geoObjektFromSearchResult={this.state.geoObjektFromSearchResult}
                reloadSearch={() => reloadSearch(this.props.searchkit)}
                onSpretter={this.onSpretter}
                //   antallroyklopfunnet= {this.state.antallroyklopfunnet}
              />
            </div>
            <div className={this.state.visRoyklop}>
              <PanelRoyklop
                {
                  ...this.props /* Silver bullet =P */
                }
                ref={(ref) => (this.refRoyklopstabell = ref)}
                removed={this.state.removed}
                enheter={royklopHits}
                geoObjektFromSearchResult={this.state.geoObjektFromSearchResult}
                reloadSearch={() => reloadSearch(this.props.searchkit)}
                onSpretter={this.onSpretter}
                //   antallroyklopfunnet= {this.state.antallroyklopfunnet}
              />
            </div>
            <Pagination className='pull-left' style={{ width: '120px' }} />
          </div>
          <div className={this.state.visKart} style={{ padding: 0 }}>
            <PlanleggingsKart
              ref={(ref) => (this.refPlanleggingsKart = ref)}
              enheter={parsedHits}
              valgteEnheter={valgteEnheter}
              royklop={royklopHits}
              valgteRoyklop={valgteRoyklop}
              kartfra='LeggTilObjekter'
              onBrukPolygon={this.onBrukPolygon}
              onPolygonFjernet={this.onPolygonFjernet}
              valgtFraKart={this.valgtFraKart}
            />
          </div>
        </div>
      </div>
    );
  };

  valgtFraKart = (data, visning) => {
    if (visning === 'Enheter') {
      this.refBruksenhetstabell.velgFraKart(data);
    } else {
      this.refRoyklopstabell.velgFraKart(data);
    }
  };

  visKart = () => {
    if (this.state.visKart === 'hide') {
      this.setState({ visKart: 'col-sm-10' });
      this.setState({ visSkjulKart: 'Skjul kart' });
      this.refBruksenhetstabell.setColumns('liten');
      this.refRoyklopstabell.setColumns('liten');
      this.setState({ visTabell: 'col-sm-2' });
    } else {
      this.setState({ visKart: 'hide' });
      this.refBruksenhetstabell.setColumns('stor');
      this.refRoyklopstabell.setColumns('stor');
      this.setState({ visSkjulKart: 'Vis kart' });
      this.setState({ visTabell: '' });
    }
    this.refPlanleggingsKart.onResize();
  };

  visSkjulTabell = (modus) => {
    if (modus === 'Enheter') {
      this.setState({ visEnheter: '' });
      this.setState({ visRoyklop: 'hide' });
      this.refPlanleggingsKart.swapVisning('Enheter');
    } else {
      if (modus === 'Royklop') {
        this.setState({ visEnheter: 'hide' });
        this.setState({ visRoyklop: '' });
        this.refPlanleggingsKart.swapVisning('Røykløp');
      }
    }
  };

  visSkjulFilters = () => {
    if (this.state.visFilters === 'hide') {
      this.setState({ visFilters: '' });
      this.setState({ visSkjulFilters: 'Skjul filter' });
    } else {
      this.setState({ visFilters: 'hide' });
      this.setState({ visSkjulFilters: 'Vis filter' });
    }
    this.refPlanleggingsKart.onResize();
  };

  visSkjulArbeidsliste = () => {
    if (this.state.visArbeidsliste === 'hide') {
      this.setState({ visArbeidsliste: 'col-md-2' });
      this.setState({ visSkjulArbeidsliste: 'Skjul arbeidsliste' });
      this.setState({ wrapperClass: 'content-wrapper col-md-10' });
    } else {
      this.setState({ visArbeidsliste: 'hide' });
      this.setState({ visSkjulArbeidsliste: 'Vis arbeidsliste' });
      this.setState({ wrapperClass: 'content-wrapper col-md-12' });
      this.setState({ showSidebar: false });
    }
    this.refPlanleggingsKart.onResize();
  };

  visSideoppsett = () => {
    this.dialogs.setOpenDialog('SideoppsettDialog');
  };

  taMedChanged = () => {
    this.setState({ taMedChecked: !this.state.taMedChecked });
  };

  render() {
    if (
      !this.props.searchkit ||
      _.isEmpty(this.props.store.arbeidslisteTiltakLister)
    ) {
      return null;
    }

    return (
      <div id='legg-til-bruksenheter'>
        <SearchkitProvider searchkit={this.props.searchkit}>
          <div className='row rowSok'>
            <div
              style={{ padding: 0 }}
              className={this.state.wrapperClass}
              id='content'>
              <Layout style={{ marginRight: 0, paddingRight: 0 }}>
                <LayoutBody style={{ marginRight: 0, paddingRight: 0 }}>
                  <div
                    style={{ minWidth: '200px', maxWidth: '250px' }}
                    className={this.state.visFilters}>
                    <Filters
                      sokLagrede={this.state.sokLagrede}
                      brukLagretSok={this.brukLagretSok}
                      onDeletedSok={this.onDeletedSok}
                      visLagreSokDialog={this.visLagreSokDialog}
                      onSelectedPerson={this.onSelectedPerson}
                    />
                  </div>
                  <LayoutResults style={{ marginRight: 0, paddingRight: 0 }}>
                    <ActionBar>
                      <ActionBarRow>
                        <HitsStats />
                        <SortingSelector
                          className='pull-right'
                          options={getSortOptions()}
                        />

                        <PageSizeSelector
                          className='pull-right'
                          options={[10, 20, 100, 250, 500, 1000]}
                          listComponent={Select}
                        />
                      </ActionBarRow>
                      <ActionBarRow>
                        <span>
                          {/*  <button
                            style={{ height: '32px' }}
                            className="btn btn-default"
                            onClick={this.visSkjulFilters}>
                            {this.state.visSkjulFilters}
                          </button> */}
                          <div className='btn-group' data-toggle='buttons'>
                            <label
                              className='btn btn-default active'
                              onClick={this.visSkjulTabell.bind(
                                this,
                                'Enheter'
                              )}>
                              <input
                                type='radio'
                                name='options'
                                id='option1'
                                defaultChecked
                              />{' '}
                              Enheter
                            </label>
                            <label
                              className='btn btn-default'
                              onClick={this.visSkjulTabell.bind(
                                this,
                                'Royklop'
                              )}>
                              <input type='radio' name='options' id='option2' />{' '}
                              Røykløp
                            </label>
                          </div>
                          &nbsp; &nbsp;
                          <button
                            style={{ height: '32px' }}
                            className='btn btn-default'
                            onClick={this.visSideoppsett}>
                            Sideoppsett
                          </button>
                          &nbsp;&nbsp;
                        </span>
                        <GroupedSelectedFilters
                          translations={{
                            true: 'Ja',
                            false: 'Nei',
                          }}
                        />
                        <ResetFilters
                          translations={{
                            'reset.clear_all': 'Nullstill filtre',
                          }}
                        />
                      </ActionBarRow>
                    </ActionBar>

                    <div className='searchkit-view-switcher'>
                      {/*  <ViewSwitcherToggle listComponent={Tabs} /> */}
                      <ViewSwitcherHits
                        /*  hitsPerPage={CONFIG.debugMode ? 10 : 250} */
                        hitsPerPage={250}
                        sourceFilter={[
                          'id',
                          'fromMatrikkel',
                          'adresse.id',
                          'adresse.gatenavn', //Todo: kan også være fiktiv
                          'adresse.husnummer',
                          'adresse.bokstav',
                          'adresse.type',
                          'bygg.lokasjon',
                          'bygg.bygningsnummer',
                          'matrikkelenhet.id',
                          'matrikkelenhet.gardsnummer',
                          'matrikkelenhet.bruksnummer',
                          'matrikkelenhet.festenummer',
                          'matrikkelenhet.seksjonsnummer',
                          'matrikkelenhet.bruksnavn',
                          'skybrannData.bruksenhet.risikovurdering',
                          'skybrannData.bruksenhet.risikoverdiOverstyr',
                          'skybrannData.bruksenhet.fagId',
                          'skybrannData.bruksenhet.fyringsforbud',
                          'lokasjon',
                          'etasjeplankode',
                          'bruksenhetstype',
                          'lopenummer',
                          'etasjenummer',
                          'kommune.kommuneNavn',
                          'skybrannData.royklop.plassering',
                          'skybrannData.royklop.feieadkomst',
                          'skybrannData.royklop.id',
                          'skybrannData.royklop.type',
                          'skybrannData.royklop.risikoverdi',
                          'skybrannData.royklop.risikoverdiOverstyr',
                          'skybrannData.royklopTiltakType.royklopId',
                          'skybrannData.royklopTiltakType.nesteDato',
                          'skybrannData.royklopTiltakType.tiltakType',
                          'skybrannData.bruksenhetTiltakType.nesteDato',
                          'skybrannData.bruksenhetTiltakType.tiltakType',
                          'skybrannData.ildsteder',
                        ]}
                        hitComponents={[
                          {
                            key: 'ResultatPanel',
                            title: 'ResultatPanel',
                            listComponent: this.ResultatPanel,
                          },
                        ]}
                        scrollTo='body'
                      />
                    </div>
                  </LayoutResults>
                </LayoutBody>
              </Layout>
            </div>

            <div id='sidebar' className={this.state.visArbeidsliste}>
              <button
                className='btn btn-primary pull-right'
                onClick={this.onClickSettTiltakstyper}>
                Neste
              </button>

              <h3> Arbeidsliste</h3>
              {/*  <div className="form-group" style={{ marginBottom: '0' }}>
                <label> 
                  <input
                    className={'checkbox30'}
                    checked={this.state.taMedChecked}
                    type='checkbox'
                    onChange={this.taMedChanged}
                    onClick={this.taMedChanged}
                  />
                  <span
                    className={'checkboxtext18'}>
                    Ta med røykløp
                  </span>
                </label>
              </div> */}

              <div className='scrollableArbeidsliste'>
                {this.SidebarArbeidsliste()}
              </div>
            </div>
          </div>
        </SearchkitProvider>
        {this.state.visArbeidsliste === 'hide' && (
          <div className='modal-footer'>
            <button
              className='btn btn-lg btn-primary'
              onClick={this.onClickSettTiltakstyper}>
              Neste
            </button>
          </div>
        )}
        <LagreSokDialog
          isOpen={this.state.lagreSokDialogOpen}
          onClose={() => this.setState({ lagreSokDialogOpen: false })}
          onLagreSok={this.onLagreSok}
        />
        {this.dialogs.renderOpenDialog()};
      </div>
    );
  }
}

export default PageObjekter;

const modalDialogs = {
  SideoppsettDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        size='small'
        isOpen={isOpen}
        onClose={onClose}
        buttonText='Lukk'
        /*  onSubmit={onClose(() => {
          owner.slettEnhet2(item, eierkontakt);
        })} */
        tittel={'Sideoppsett'}>
        <div>
          <div className='form-group' style={{ marginBottom: '4px' }}>
            <input
              className='checkbox30'
              type='checkbox'
              onClick={owner.visSkjulFilters}
              onChange={owner.visSkjulFilters}
              checked={owner.state.visFilters !== 'hide'}
            />
            <span className='checkboxtext18'>Vis søkefilter</span>
          </div>
          <div className='form-group' style={{ marginBottom: '4px' }}>
            <input
              className='checkbox30'
              type='checkbox'
              onClick={owner.visKart}
              onChange={owner.visKart}
              checked={owner.state.visKart !== 'hide'}
            />
            <span className='checkboxtext18'>Vis kart</span>
          </div>
          <div className='form-group' style={{ marginBottom: '4px' }}>
            <input
              className='checkbox30'
              type='checkbox'
              onClick={owner.visSkjulArbeidsliste}
              onChange={owner.visSkjulArbeidsliste}
              checked={owner.state.visArbeidsliste !== 'hide'}
            />
            <span className='checkboxtext18'>Vis arbeidsliste</span>
          </div>
        </div>
      </ModalContainer>
    );
  },
};
