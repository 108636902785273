import React from 'react';
import styled from 'styled-components';
import iconBrann from 'images/Brannforebygging.png'; /* NORKART_SYMBOL_negativ__digital.png */
import { AuthContext } from 'norkartidjs2';
import { useContext, useState, useEffect } from 'react';

import { debugFactory, tuplesToString } from 'utils';

const debug = debugFactory('nk:layouts:main');

const Overlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
`;

const ContentFrame = styled(Overlay)`
  display: flex;
  z-index: 7001;
  justify-content: center;
  align-items: center;
`;

const ContentBox = styled.div`
  text-align: center;
  background: #4d4d4d;
  color: #f6f6f6;
  border: 2px solid black;
  border-radius: 5px;
  backround-color: white;
  max-width: 40vw;
  min-width: 200px;
  padding: 10px;
  z-index: 1;
`;

export const LoadingFilter = styled.div`
  ${({ isLoading }) => tuplesToString(isLoading, ['filter: blur(5px);'])}
`;

export default ({ isLoading, marquee = 'Laster...' }) => {
  const authMsal = useContext(AuthContext);
/*   const [visBtn, setVisBtn] = useState(false);
  const visButton = () => {
    setVisBtn(true);
  }; */

  //Brukes ikke
/*   useEffect(() => {
    const timer = setTimeout(() => {
      visButton();
    }, 10000);
    return () => clearTimeout(timer);
  }, []); */

  return (
    isLoading && (
      <div>   {/* onClick={visButton} data-label='loading-overlay'> */}
        <ContentFrame>
          <ContentBox>
            <img
              src={iconBrann}
              style={{ width: '40px', height: '40px' }}
              alt='Vent..'
            />
            <br />
            <br />
            {marquee && <marquee>{marquee}</marquee>}
          {/*   {visBtn && (
              <button onClick={authMsal.logOut} className='btn btn-success'>
                Logg ut
              </button>
            )} */}
          </ContentBox>
        </ContentFrame>
      </div>
    )
  );
};
