import { Component } from 'react';
import CONFIG from 'lib/CONFIG';
import $ from 'jquery';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import './dokeditor.css';
import { debugFactory } from 'utils';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
//import 'froala-editor/css/plugins/code_view.min.css';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
//import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/css/plugins/table.min.css';
import 'froala-editor/js/languages/nb.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
//import 'froala-editor/js/third_party/spell_checker.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/align.min.js';

/* import 'codemirror/lib/codemirror.css';
import CodeMirror from 'codemirror/lib/codemirror.js';
import 'codemirror/mode/xml/xml.js'; */

import FroalaEditorComponent from 'react-froala-wysiwyg';
import Froalaeditor from 'froala-editor';
import LagreTekstbit from 'modules/felles/avvikAnmerkninger/LagreTekstbit.jsx';
import SoekTekstbit from 'modules/felles/avvikAnmerkninger/SoekTekstbit.jsx';
import MalsoekDialog from './MalerMalsoekDialog.jsx';
import EksempelDialog from './EksempelDialog.jsx';
import FlettefeltDialog from './FlettefeltDialog.jsx';
import TabellBorderDialog from './TabellBorderDialog.jsx';
import {
  checkEditorNormal,
  skjulTabellKantlinjer,
  visTabellKantlinjerIgjen,
  getHtmlBeforeSave,
} from './EditorNormal.js';
//import './aaa.css';

const debug = debugFactory('nk:MalEditor');

const FROALA_KEY = CONFIG.REACT_APP_FROALA;

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  TekstbiterDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <LagreTekstbit
        modul='avvik'
        merkettekst={dialogData.merkettekst}
        tekst={dialogData.tekst}
        onClose={onClose}
        onLagret={onClose}
      />
    </NKModal>
  ),
  TekstbiterSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <SoekTekstbit
        modul='avvik'
        onClose={onClose}
        onVelgTekst={onClose(owner.onInsertTekstbit)}
      />
    </NKModal>
  ),
  MalSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <MalsoekDialog
        onClose={onClose}
        onInsertMal={onClose(owner.onInsertMal)}
        modul={owner.props.gjelder}
        visFunksjon={false}
        visModulvalg={true}
      />
    </NKModal>
  ),
  EksempelDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      <EksempelDialog
        onClose={onClose}
        mal= {owner.props.mal}
        modus= 'Dokument'
        onInsertEksempelMal={onClose(owner.onInsertEksempelMal)}
      />
    </NKModal>
  ),
  FlettefeltDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='small' isOpen={isOpen} onClose={onClose}>
      <FlettefeltDialog
        onClose={onClose}
        onInsertFlettefelt={onClose(owner.onInsertFlettefelt)}
        flettefelt={owner.props.flettefeltListe}
      />
    </NKModal>
  ),
  BorderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      <TabellBorderDialog
        onClose={onClose}
        element={dialogData.element}
        elementNavn={dialogData.elementNavn}
      />
    </NKModal>
  ),
};

class MalEditor extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.froalaRef = null;
    this.state = {
      model: '',
      testModus: false,
      flettetTekst: '',
      flettefeltListe: [],
      test: 0,
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    debug('MMALL', this.props);
    //  appendScript('https://raw.githack.com/eKoopmans/html2pdf/master/dist/html2pdf.bundle.js');
    let m = this.props.maltekst;
    m = checkEditorNormal(m);
    this.setState(
      {
        model: m,
        flettefeltListe: this.props.flettefeltListe,
      },
      this.makeFlettefeltListe(this.props.flettefeltListe)
    );

  
  }

  goTest = (v) => {
    debug('VVVV', v);
    let t0 = 'Petter Strand Ødegaard';
    let t1 = 'Postveien 23 A <b>Bold</b> og <i>Italic</i>';
    let t2 =
      '<span style="font-family: Arial Black; font-size: 20px; color: blue;">Dette er Arial Black, stor og blå.</span>';
    let t3 =
      '<table><tbody><tr><td>AA</td><td>BB</td></tr><tr><td>AA</td><td>BB</td></tr><tr><td>AA</td><td>BB</td></tr></tbody></table>';
    let t4 =
      '<table style="color: red; font-size: 20px; font-family: Arial;"><tbody><tr><td>AA</td><td>BB</td></tr><tr><td>AA</td><td>BB</td></tr><tr><td>AA</td><td>BB</td></tr></tbody></table>';
    let t5 = '<div>Hva skjer dersom vi bruker en div?<br/>Og en break?</div>';
    let t6 =
      '<ol><li>Lillehammer</li><li>Hamar</li><li>Gj&oslash;vik</li><li>Brumunddal</li></ol>';
    let t7 =
      '<ol style="color: red; font-size: 20px; font-family: Arial;"><li>Lillehammer</li><li>Hamar</li><li>Gj&oslash;vik</li><li>Brumunddal</li></ol>';
    let val = '';
    switch (v) {
      case '0':
        val = t0;
        break;
      case 'v1':
        val = t1;
        break;
      case 'v2':
        val = t2;
        break;
      case 'v3':
        val = t3;
        break;
      case 'v4':
        val = t4;
        break;
      case 'v5':
        val = t5;
        break;
      case 'v6':
        val = t6;
        break;
      case 'v7':
        val = t7;
        break;
      default:
        val = t0;
    }

    if (this.state.model.includes('#test#')) {
      let model = this.state.model;
      model = this.goReplace(model, '#test#', val);
      this.setState({ model });
    } else {
      let editor = this.froalaRef.editor;
      editor.selection.restore();
      editor.html.insert(val);
    }
  };

  makeFlettefeltListe = (liste) => {
    let fletteOptions = {};
    if (liste) {
      for (let i = 0; i < liste.length; i++) {
        const el = liste[i];
        if (el.flettefelt) {
          fletteOptions[el.flettefelt + ' '] = el.navn;
        }
      }
    }
    debug('AVAV', fletteOptions);
    this.makeFroalaToolButtons(fletteOptions);
  };

  getMaltekst = () => {
    let h = getHtmlBeforeSave(this.state.model);
    return h;
  };

  handleModelChange = (model) => {
    this.setState({ model });
  };

  handleModelChangeTest = () => {
    let s = this.state.flettetTekst;
    this.setState({ flettetTekst: s });
  };

  test = () => {
    let test = this.state.testModus;
    if (!test) {
      let tekst = this.state.model;
      tekst = this.flettText(tekst);
      this.setState({ flettetTekst: tekst });
    }
    test = !test;
    this.setState({ testModus: test });
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replace(new RegExp(find, 'g'), repl);
    return str;
  };

  flettText = (s) => {
    if (!this.props.flettefeltListe) {
      return;
    }
    for (let i = 0; i < this.props.flettefeltListe.length; i++) {
      let feltRow = this.props.flettefeltListe[i];
      s = this.goReplace(s, feltRow.flettefelt, feltRow.fletteEksempel);
    }
    return s;
  };

  handleTekstbiter = (cmd, val) => {
    if (val === 'SettInn') {
      this.settInnTekstbit();
    } else {
      this.lagreTekstbit();
    }
  };

  onInsertTekstbit = (val) => {
    let editor = this.froalaRef.editor;
    editor.selection.restore();
    /* editor.html.insert(val); */
    editor.html.insert('<p>' + val + '</p>');
    editor.selection.save();
    this.handleModelChange(editor.html.get());
  };

  onInsertFlettefelt = (val) => {
    let editor = this.froalaRef.editor;
    editor.selection.restore();
    editor.html.insert(val);
  };

  removeHTML = (str) => {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || '';
  };

  lagreTekstbitOld = () => {
    let editor = this.froalaRef.editor;
    let s = editor.selection.text();
    s = this.removeHTML(s);
    if (s) {
      this.dialogs.setOpenDialog('TekstbiterDialog', {
        tekst: s,
        merkettekst: s,
      });
    }
  };

  lagreTekstbit = () => {
    let editor = this.froalaRef.editor;
    let selection = window.getSelection();
    if (selection.rangeCount > 0) {
      let range = selection.getRangeAt(0); // Get the Range object
      let container = document.createElement('div');
      container.appendChild(range.cloneContents()); // Clone the contents of the range
    
      this.dialogs.setOpenDialog('TekstbiterDialog', {
      tekst: editor.html.get(),
      merkettekst: container.innerHTML, // Get the HTML of the selected content
      });
    }
    };

  settInnTekstbit = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('TekstbiterSoekDialog');
  };

  velgMal = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('MalSoekDialog');
  };

  velgEksempel = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('EksempelDialog');
  };

  velgFlettefelt = () => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    this.dialogs.setOpenDialog('FlettefeltDialog');
  };

  sideskift = () => {
    let bre =
      '<p style="border-top: 1px solid #e0e0e0; page-break-before: always;"/>';
    let editor = this.froalaRef.editor;
    editor.selection.save();
    editor.selection.restore();
    editor.html.insert(bre);
  };

  settInnFlettefelt = (cmd, val) => {
    let editor = this.froalaRef.editor;
    editor.selection.save();
    editor.selection.restore();
    editor.html.insert(val);
  };

  onInsertMal = (val) => {
    let editor = this.froalaRef.editor;
    editor.selection.restore();
    editor.html.insert(val);
  };

  onInsertEksempelMal = (val) => {
    this.setState({ model: val});
  };

  getFunksjoner = (data) => {
    var distinct = ['Alle'];
    for (var i = 0; i < data.length; i++) {
      if (!distinct.includes(data[i].funksjon)) {
        distinct.push(data[i].funksjon);
      }
    }
    this.setState({ funksjoner: distinct });
  };

  settModel = () => {
    let editor = this.froalaRef.editor;
    let m = this.state.model;
    editor.html.set(m);
    this.makeFlettefeltListe(this.props.flettefeltListe);
  };

  makeFroalaToolButtons = (fletteOptions) => {
    // Froalaeditor.DefineIcon('tags', { NAME: 'tags', SVG_KEY: 'tags' });
    Froalaeditor.DefineIcon('tekstbiter', {
      NAME: 'Tekstbiter',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('tekstbiter', {
      title: 'Tekstbiter',
      type: 'dropdown',
      focus: true,
      undo: true,
      options: {
        SettInn: 'Sett inn tekstbit',
        TaVaere: 'Lagre tekstbit',
      },
      refreshAfterCallback: true,
      callback: (cmd, val) => {
        this.handleTekstbiter(cmd, val);
      },
    });

    Froalaeditor.DefineIcon('maltekst', {
      NAME: 'Velg mal',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('maltekst', {
      title: 'Velg mal',
      icon: 'maltekst',
      callback: () => {
        this.velgMal();
      },
    });

    Froalaeditor.DefineIcon('tilbake', {
      NAME: 'Tilbake',
      template: 'text',
    });
    Froalaeditor.RegisterCommand('tilbake', {
      title: 'Tilbake',
      icon: 'tilbake',
      callback: () => {
        this.test();
      },
    });

    Froalaeditor.DefineIconTemplate(
      'text_blue',
      '<span style="text-align: center; color: blue">[NAME]</span>'
    );
    Froalaeditor.DefineIcon('advarsel', {
      NAME: 'Flettet tekst eksempel (NB! Ikke reelle data!)',
      template: 'text_blue',
    });
    Froalaeditor.RegisterCommand('advarsel', {
      title: '',
      icon: 'advarsel',
    });

    Froalaeditor.DefineIcon('pageBreaker', {
      NAME: 'Sideskift',
      SVG_KEY: 'pageBreaker',
    });
    Froalaeditor.RegisterCommand('pageBreaker', {
      title: 'Sideskift',
      icon: 'pageBreaker',
      callback: () => {
        this.sideskift();
      },
    });

    Froalaeditor.DefineIcon('clear', { NAME: 'remove', SVG_KEY: 'remove' });
    Froalaeditor.RegisterCommand('clear', {
      title: 'Fjern alt',
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function() {
        this.html.set('');
        this.events.focus();
      },
    });

    Froalaeditor.DefineIcon('cellbordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'cellStyle',
    });
    Froalaeditor.RegisterCommand('cellbordersDialog', {
      title: 'Kantlinjer celle',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var celle = celleArray[0];
        this.dialogs.setOpenDialog('BorderDialog', {
          element: celle,
          elementNavn: ' celle',
        });
      },
    });

    Froalaeditor.DefineIcon('tablebordersDialog', {
      NAME: 'cog',
      SVG_KEY: 'tableStyle',
    });
    Froalaeditor.RegisterCommand('tablebordersDialog', {
      title: 'Kantlinjer tabell',
      callback: () => {
        this.froalaRef.editor.popups.hide('table.edit');
        var celleArray = document.getElementsByClassName('fr-selected-cell');
        var parentTable = celleArray[0].offsetParent;
        this.dialogs.setOpenDialog('BorderDialog', {
          element: parentTable,
          elementNavn: ' tabell',
        });
      },
    });

    if (this.props.norkartbruker) {
      Froalaeditor.DefineIcon('star', {
        NAME: 'star',
        SVG_KEY: 'star',
      });
      Froalaeditor.RegisterCommand('star', {
        title: 'Testknapp Norkart',
        icon: 'star',
        type: 'dropdown',
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: {
          v0: 'Simpel',
          v1: 'Simpel med b / i',
          v2: 'Arial Black 20px blue',
          v3: 'Table vanlig',
          v4: 'Table med egen stil',
          v5: 'Med en div',
          v6: 'Liste ',
          v7: 'Liste med egen stil',
        },
        callback: (cmd, val) => {
          this.goTest(val);
        },
      });
    }

 //  if (this.props.norkartbruker || this.props.pilotbruker) {
      Froalaeditor.DefineIconTemplate(
        'text_red',
        '<span style="text-align: center; color: red">[NAME]</span>'
      );

      Froalaeditor.DefineIcon('eksempel', {
        NAME: 'Velg eksempelmal',
        template: 'text',
      });
      Froalaeditor.RegisterCommand('eksempel', {
        title: 'Velg eksempelmal',
        icon: 'eksempel',
        callback: () => {
          this.velgEksempel();
        },
      });

      Froalaeditor.DefineIcon('test', {
        NAME: 'Test',
        template: 'text',
      });
      Froalaeditor.RegisterCommand('test', {
        title: 'Test',
        icon: 'test',
        callback: () => {
          this.test();
        },
      });

      Froalaeditor.DefineIcon('flettefelt', {
        NAME: 'Flettefelt',
        template: 'text',
      });
      Froalaeditor.RegisterCommand('flettefelt', {
        title: 'Flettefelt',
        icon: 'flettefelt',
        callback: () => {
          this.velgFlettefelt();
        },
      });
   
  };

  render() {
    let visHTML = '';
    let visStar = '';
    if (this.props.norkartbruker) {
      visHTML = 'html';
      visStar = 'star';
    }
    const config = {
      key: FROALA_KEY,
      useClasses: false,
      /*   html2pdf: window.html2pdf, */
      zIndex: 15000,
      /*  scrollableContainer: '#window1', */
      attribution: false,
      /*  placeholderText: 'Mal..', */
      autoFocus: true,
      fontFamilySelection: true,
      fontSizeSelection: true,
      /*   paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
      }, */
      paragraphFormatSelection: false,
      quickInsertEnabled: false,

      tableInsertHelper: false,
      tableEditButtons: [
        'tableHeader',
        'tableRemove',
        '|',
        'tableRows',
        'tableColumns',
        'tablebordersDialog',
        '-',
        'tableCells',
        'tableCellBackground',
        '|',
        'tableCellVerticalAlign',
        'tableCellHorizontalAlign',
        'cellbordersDialog',
      ],

      tableColors: [
        '#61BD6D',
        '#1ABC9C',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#CCCCCC',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#000000',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#FFFFFF',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
        'REMOVE',
      ],

      listAdvancedTypes: true,
      tabSpaces: 4,

      imagePaste: false,
      imageUpload: false,
      imageDefaultAlign: 'left',
      imageDefaultDisplay: 'inline-block',
      imageMaxSize: 5 * 1024 * 1024,
     imageAllowedTypes: ['jpeg', 'jpg', 'png'], 

      language: 'nb',
      heightMin: 50,
      heightMax: 'calc(100vh - 360px)',
      height: 'calc(100vh - 360px)',
      // height: '90%',
      lineheight: '1',
      charCounterCount: true,
      lineHeights: {
        Standard: '1',
        '1.15': '1.15',
        '1.5': '1.5',
        Dobbel: '2',
      },
      events: {
        contentChanged: function() {
          $('#preview').html(this.html.get());
        },
        'commands.before': function(e) {
          if (e === 'print') {
            return skjulTabellKantlinjer(this);
          }
        },
        'commands.after': function(e) {
          if (e === 'print') {
            return visTabellKantlinjerIgjen(this);
          }
        },
      },
      fontSize: [
        '8',
        '10',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '20',
        '24',
        '30',
        '60',
      ],
      fontSizeUnit: 'px',
      fontSizeDefaultSelection: '30',
      fontFamilyDefaultSelection: 'Tahoma',
      fontFamily: {
        'Arial,Helvetica,sans-serif': 'Arial',
        'Verdana,Geneva,sans-serif': 'Verdana',
        'Open Sans': 'Open Sans',
        'Calibri': 'Calibri',
        'Times New Roman,Times,serif': 'Times New Roman',
        'Roboto,sans-serif': 'Roboto',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        'Oswald,sans-serif': 'Oswald',
        'Montserrat,sans-serif': 'Montserrat',
        Handlee: 'Handlee',
      },
      //   quickInsertButtons: ['bold', 'italic', 'table', 'ul', 'ol', 'hr'],
      toolbarButtons: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',
            'fontFamily',
            '|',
            'fontSize',
            '|',
            'paragraphFormat',
            '|',
            'textColor',
            'backgroundColor',
          ],
          align: 'left',
          buttonsVisible: 3,
        },
        moreMisc: {
          buttons: [
            'undo',
            'redo',
            'eksempel',
            'maltekst',
            'tekstbiter',
            'insertTable',
            'specialCharacters',
            'print',
            'pageBreaker',
            visHTML,
            visStar,
          ],
          align: 'right',
          buttonsVisible: 2,
        },
        moreParagraph: {
          buttons: [
            'alignLeft',
            'formatOL',
            'formatUL',
            'alignCenter',
            'alignRight',
            'alignJustify',
            'lineHeight',
            'outdent',
            'indent',
          ],
          align: 'left',
          buttonsVisible: 3,
        },
        moreRich: {
          buttons: ['flettefelt', 'test'],
        },
      },
    };
    const configTest = {
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'Mal..',
      autoFocus: false,
      tabSpaces: 4,
      imagePaste: false,
      language: 'nb',
      heightMin: 50,
      heightMax: 'calc(100vh - 430px)',
      height: 'calc(100vh - 430px)',
      lineheight: '1',
      charCounterCount: true,
      lineHeights: {
        Standard: '1',
        '1.15': '1.15',
        '1.5': '1.5',
        Dobbel: '2',
      },
      events: {
        contentChanged: function() {
          $('#preview').html(this.html.get());
        },
        'commands.before': function(e) {
            if (e === 'print') {
            return skjulTabellKantlinjer(this);
          }
        },
        'commands.after': function(e) {
          if (e === 'print') {
            return visTabellKantlinjerIgjen(this);
          }
        },

        getHTML: function() {
          debug('VVALL XXXXXXXX');
        },
      },
      toolbarButtons: {
        moreRich: {
          buttons: ['advarsel'],
          align: 'left',
        },
        moreRich2: {
          buttons: ['tilbake', 'print', visHTML],
          align: 'right',
        },
      },
    };
    return (
      <div>
        {this.state.testModus && (
          <div contenteditable='false' style={{ maxHeight: '500px' }}>
            <FroalaEditorComponent
              ref={(el) => {
                this.froalaRefTest = el;
              }}
              id='editorTest'
              documentReady={false}
              useClasses={false}
              config={configTest}
              model={this.state.flettetTekst}
              onModelChange={this.handleModelChangeTest}
            />
          </div>
        )}
        {!this.state.testModus && (
          <FroalaEditorComponent
            ref={(el) => {
              this.froalaRef = el;
            }}
            id='editor'
            documentReady={false}
            useClasses={false}
            config={config}
            model={this.state.model}
            onModelChange={this.handleModelChange}
          />
        )}
        {this.dialogs.renderOpenDialog()}
        {/*  <header id='header'>Header</header>

        <footer id='footer'>footer</footer>

        <div id='content'>
          Here your long long content...
          <p style={{ pageBreakInside: 'avoid' }}>
            This text will not be broken between the pages
          </p>
        </div> */}
      </div>
    );
  }
}

export default MalEditor;
