import React from 'react';
import _ from 'lodash';
import KartDialog from 'components/Kart/KartDialog.jsx';
import ReactModal from 'react-modal';
import { hentLogo, lagreLogo } from 'api/storageblobApi';
import DialogStore from 'lib/DialogStore';
import OkDialog from 'components/Dialogs/OkDialog.jsx';
import NKIcon from 'nka-icons';
import './orgdetaljer.css';
import { debugFactory } from 'utils';
import { updateContextInnstillingerKart, updateOrganisasjonData, createOrganisasjonData } from 'context/contextInnstillinger.js';

const debug = debugFactory('nk:organisasjoner:orgdetaljer');

class OrgDetaljer extends React.Component {
  state = {
    organisasjon: null,
    logoFile: '',
    logoB64: '',
    logoUrl: '',
  };

  constructor(props) {
    super(props);

    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount() {
   
    let organisasjon = _.cloneDeep(this.props.organisasjon);
    debug('Hein XX', organisasjon);
   
    hentLogo(this.gotLogo);

    if (!organisasjon) {
      organisasjon = {
        ny: true,
        telefonBrukesVedPublisering: true,
        beskrivelse: 'Ny organisasjon',
        kategoriId: 1,
      };
    }
    this.setState({ organisasjon });
  }

  gotLogo = (err, data) => {
    let currentTimeInMilliseconds = Date.now();
    let logo;
    if (data.tekst){
      logo = data.tekst + '?' + currentTimeInMilliseconds;
    } else {
      logo = data + '?' + currentTimeInMilliseconds;
    }
  
    console.group('LOGOX GOTLOGO');
    console.log( data );
    console.log( logo );
    console.table(  );
    console.groupEnd();
    if (data) {
      this.setState({ logoUrl: logo });
      this.props.oppdaterLogo(logo);
    }
  };

  gotSenterpunkt = (lat, long, zoom) => {
    let org = this.state.organisasjon;
    org.latitude = lat;
    org.longitude = long;
    org.zoomLevel = zoom;
    this.setState({ organisasjon: org });
    this.setState({ brukKartIsOpen: false });
    this.dialogs.closeDialog();
  };

  validerOk = () => {
    let ok = true;
    if (_.isEmpty((this.state.organisasjon.navn || '').trim())) {
      ok = false;
    }
    if (_.isEmpty((this.state.organisasjon.kortnavn || '').trim())) {
      ok = false;
    }

    if (!ok) {
      this.dialogs.setOpenDialog('ManglerDialog');
    }
    var Exp = /^([0-9]|[a-z])+([0-9a-z]+)$/i;
    if (!this.state.organisasjon.smsAvsender.match(Exp)) {
      this.dialogs.setOpenDialog('SMSAvsenderDialog');
      ok = false;
    }

    return ok;
  };


  lagre = () => {
    let org = this.state.organisasjon;

    if (!org.smsTilbakemeldingInnbygger) {
      org.smsKvitteringVarsel = false;
    }
    if (this.validerOk()) {
      this.oppdaterKartInnstillingerContext();
      this.lagreOrganisasjon(org);
    }
  };

  lagreOrganisasjon= (org) => {
    updateOrganisasjonData(org, this.props.onOk);    
  };



  oppdaterKartInnstillingerContext = () => {
    updateContextInnstillingerKart({ 
      latitude: this.state.organisasjon.latitude, 
      longitude: this.state.organisasjon.longitude, 
      zoomLevel: this.state.organisasjon.zoomLevel });
  };

  purringEtterChange = (e) => {
    let organisasjon = this.state.organisasjon;
    let x = e.target.value;
    if (!e.target.value) {
      x = 1;
    }
    organisasjon.smsPurringVarselForsteDag = x;
    this.setState({ organisasjon });
  };

  purringAntallChange = (e) => {
    let organisasjon = this.state.organisasjon;
    let x = e.target.value;
    if (!e.target.value) {
      x = 1;
    }
    organisasjon.smsPurringVarselAntallganger = x;
    this.setState({ organisasjon });
  };

  onKeyPress = (event) => {
    event.preventDefault();
  };

  onLogoChange = (e) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    console.log('LOGOX  src', img);
    img.onload = () => {
      let w = img.width;
      let h = img.height;
      if (h > 100 || w > 600) {
        this.dialogs.setOpenDialog('LogoDialog', {
          tittel: 'Valgt logo..',
          melding:
            'Bildet som du valgte er (h) ' +
            h +
            ' x (b) ' +
            w +
            ' px.  Det justeres litt.',
        });
      }
    };
  
    var file = e.target.files[0];
    console.log('LOGOX  file', file);
    this.uploadFileToServer(file);
   /*  this.setState(
      { logoUrl: '' },
      this.uploadFileToServer(file)
    ); */
  };

  uploadFileToServer = (file) => {
    var reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function(e) {
      let b64 = btoa(reader.result);
      let objekt = {
        filnavn: file.name,
        filNavn: file.name,
        filData: b64,
      };
      console.log('LOGOX Objekt før lagre', objekt);
      lagreLogo(objekt, this.logoUploaded);
    }.bind(this);
  };

  logoUploaded = (err, result) => {
    console.log('LogoX LOGO UPLOADED Err', err);
    console.log('LogoX LOGO UPLOADED Result', result);

  //  hentLogo(this.gotLogo);

  //TRYAL:
    let currentTimeInMilliseconds = Date.now();
    let logo = '';

    if (result.tekst){
      logo = result.tekst + '?' + currentTimeInMilliseconds;
    } else {
      logo = result + '?' + currentTimeInMilliseconds;
    }
    
    console.group('LOGOX LOGO UPLOADED');
    console.log( logo );
    console.groupEnd();
    if (logo) {
      this.setState({ logoUrl: logo });
      this.props.oppdaterLogo(logo);
    }
  };

  changeFlereMottaker = (bool) => {
    let organisasjon = this.state.organisasjon;
    organisasjon.malDokumentPrMottaker = bool;
    this.setState({ organisasjon });
  };

  render() {
    debug('render', { ...this });

    let organisasjon, tittel;

    if (this.props.nyOrganisasjon) {
      tittel = 'Ny organisasjon';
      organisasjon = this.state.organisasjon || {};
    } else {
      tittel = 'Endre organisasjon';
      organisasjon = this.state.organisasjon || this.props.organisasjon;
    }

    const labelShroud = {
      height: '47px',
      width: '117px',
      position: 'absolute',
      cursor: 'pointer',
      padding: '0',
      margin: '0',
    };

    return (
      <div
        className='modal-content'
        style={{ minHeight: '780px', width: '660px' }}>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> {tittel} </h4>
        </div>
        <div className='modal-body'>
          <div className='panel panel-default'>
            <ul className='nav nav-tabs'>
              <li className='active'>
                <a data-toggle='tab' href='#orgtab'>
                  Organisasjon
                </a>
              </li>
              <li>
                <a data-toggle='tab' href='#smstab'>
                  Innstilling SMS
                </a>
              </li>
              <li>
                <a data-toggle='tab' href='#karttab'>
                  Kart
                </a>
              </li>

              <li>
                <a data-toggle='tab' href='#logotab'>
                  Logo/Topptekst
                </a>
              </li>

              <li>
                <a data-toggle='tab' href='#fonttab'>
                  Skrifttype
                </a>
              </li>
            </ul>
            <div className='panel-body'>
              <div className='tab-content'>
                {/* ******** TAB ************************************** */}
                <div id='orgtab' className='tab-pane fade in active'>
                  {/* *********/}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Navn</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        autoFocus
                        maxLength='250'
                        className='form-control'
                        type='text'
                        value={organisasjon.navn}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              navn: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Adresse</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='100'
                        className='form-control'
                        type='text'
                        value={organisasjon.adresse}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              adresse: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Postnr. og poststed</label>
                    </div>
                    <div className='col-sm-2 form-group'>
                      <input
                        maxLength='4'
                        className='form-control postnr'
                        type='text'
                        value={organisasjon.postnr}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              postnr: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className='col-sm-6 form-group'>
                      <input
                        maxLength='100'
                        className='form-control'
                        type='text'
                        value={organisasjon.poststed}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              poststed: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/* *********/}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Telefon</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                maxLength='12'
                                style={{
                                  width: '100px',
                                  marginRight: '50px',
                                }}
                                className='form-control'
                                type='text'
                                value={organisasjon.telefon}
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      telefon: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <div
                                className='tooltipI'
                                style={{ marginRight: '10px' }}>
                                <NKIcon size='1' color='nk-black' icon='info' />
                                <span className='tooltiptextI'>
                                  Telefonnummeret benyttes ved varsling. Fjern
                                  haken hvis du ønsker at feiernes mobilnummer
                                  benyttes istedenfor. Feiernes mobilnummer må
                                  da være lagt inn i feltet Mobil under
                                  Innstillinger\Brukere
                                </span>
                              </div>
                            </td>
                            <td>
                              <span style={{ marginRight: '10px' }}>
                                Bruk ved varsling
                              </span>
                            </td>
                            <td>
                              <input
                                className='checkbox30'
                                type='checkbox'
                                checked={
                                  organisasjon.telefonBrukesVedPublisering
                                }
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      telefonBrukesVedPublisering: !organisasjon.telefonBrukesVedPublisering,
                                    },
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* *********/}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Epost</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='100'
                        className='form-control'
                        type='text'
                        value={organisasjon.epost}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              epost: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Webside</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='100'
                        className='form-control'
                        type='text'
                        value={organisasjon.webside}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              webside: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* *********/}

                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Beskrivelse</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        maxLength='250'
                        className='form-control'
                        type='text'
                        value={organisasjon.beskrivelse || ''}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              beskrivelse: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Varsle flere eiere</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <input
                        className='checkbox30'
                        type='checkbox'
                        checked={!organisasjon.smsDialogEnEier}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              smsDialogEnEier: !organisasjon.smsDialogEnEier,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/*   {(this.props.isNorkartAdmin || this.props.isPilot) && ( */}
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Dokumentproduksjon:</label>
                    </div>
                    <div className='col-sm-8 form-group'>
                      <label>
                        <input
                          style={{ marginRight: '8px' }}
                          type='radio'
                          value={true}
                          checked={organisasjon.malDokumentPrMottaker}
                          onChange={(e) => {
                            this.changeFlereMottaker(true);
                          }}
                        />
                        Lag ett dokument per mottaker.
                      </label>
                      <br />
                      <label>
                        <input
                          style={{ marginRight: '8px' }}
                          type='radio'
                          value={false}
                          checked={!organisasjon.malDokumentPrMottaker}
                          onChange={(e) => {
                            this.changeFlereMottaker(false);
                          }}
                        />
                        Lag kun ett dokument.
                      </label>
                    </div>
                  </div>
                  {/* *********/}
                 {/*  <div className='row'>
                    <div className='col-sm-4' style={{ paddingTop: '8px' }}>
                      <label >Oppfølging avvik per bruksenhet</label>
                    </div>
                    <div className='col-sm-8 form-check ' style={{ paddingTop: '8px' }}>
                      <input
                        className='checkbox30'
                        type='checkbox'
                        checked={organisasjon.oppfolgingAvvikBruksenhet}
                        onChange={(e) => {
                          this.setState({
                            organisasjon: {
                              ...organisasjon,
                              oppfolgingAvvikBruksenhet: !organisasjon.oppfolgingAvvikBruksenhet,
                            },
                          });
                        }}
                      />
                    </div>
                  </div> */}
                </div>

                {/* ******** TAB ************************************** */}
                <div id='smstab' className='tab-pane fade'>
                  {/* *********/}
                  <div className='row'>
                    {/* <table style={{ marginLeft: '15px' }}>
                      <tbody>
                        <tr>
                          <td>
                            <input
                              className='checkbox30'
                              type='checkbox'
                              checked={!organisasjon.smsDialogEnEier}
                              onChange={(e) => {
                                this.setState({
                                  organisasjon: {
                                    ...organisasjon,
                                    smsDialogEnEier: !organisasjon.smsDialogEnEier,
                                  },
                                });
                              }}
                            />
                          </td>
                          <td>&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>
                              Varsle flere eiere
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table> */}

                    <div>
                      <table
                        style={{
                          marginLeft: '15px',
                        }}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className='checkbox30'
                                type='checkbox'
                                checked={
                                  organisasjon.smsTilbakemeldingInnbygger
                                }
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      smsTilbakemeldingInnbygger: !organisasjon.smsTilbakemeldingInnbygger,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}>
                                Eier kan svare med SMS
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {organisasjon.smsTilbakemeldingInnbygger && (
                        <React.Fragment>
                          <table style={{ marginLeft: '15px' }}>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    className='checkbox30'
                                    type='checkbox'
                                    checked={organisasjon.smsKvitteringVarsel}
                                    onChange={(e) => {
                                      this.setState({
                                        organisasjon: {
                                          ...organisasjon,
                                          smsKvitteringVarsel: !organisasjon.smsKvitteringVarsel,
                                        },
                                      });
                                    }}
                                  />
                                </td>
                                <td>&nbsp;&nbsp;&nbsp;</td>
                                <td>
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                    }}>
                                    Bruk kvitteringsmelding
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </React.Fragment>
                      )}
                      <table style={{ marginLeft: '15px' }}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className='checkbox30'
                                type='checkbox'
                                checked={organisasjon.smsPurringVarselInnbygger}
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      smsPurringVarselInnbygger: !organisasjon.smsPurringVarselInnbygger,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}>
                                Bruk purring
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {organisasjon.smsPurringVarselInnbygger && (
                        <React.Fragment>
                          <table style={{ marginLeft: '15px' }}>
                            <tbody>
                              <tr>
                                <td style={{ marginRight: '8px' }}>
                                  Bruk purring etter &nbsp;
                                </td>
                                <td>
                                  <input
                                    value={
                                      this.state.organisasjon
                                        ? this.state.organisasjon
                                            .smsPurringVarselForsteDag
                                        : 1
                                    }
                                    style={{
                                      paddingLeft: '2px',
                                      width: '44px',
                                    }}
                                    onChange={this.purringEtterChange}
                                    min='1'
                                    max='30'
                                    type='number'
                                    onKeyPress={this.onKeyPress.bind(this)}
                                  />
                                </td>
                                {/* <td>&nbsp; dager, og gjenta &nbsp;</td> */}
                                <td>&nbsp; dager. Antall purringer: &nbsp;</td>
                                <td>
                                  {' '}
                                  <input
                                    value={
                                      this.state.organisasjon
                                        ? this.state.organisasjon
                                            .smsPurringVarselAntallganger
                                        : 1
                                    }
                                    style={{
                                      paddingLeft: '2px',
                                      width: '40px',
                                    }}
                                    onChange={this.purringAntallChange}
                                    min='1'
                                    max='3'
                                    type='number'
                                    onKeyPress={this.onKeyPress.bind(this)}
                                  />
                                </td>
                                {/*  <td>&nbsp;ganger</td> */}
                              </tr>
                            </tbody>
                          </table>
                        </React.Fragment>
                      )}
                      <br />
                      <table style={{ marginLeft: '15px' }}>
                        <tbody>
                          <React.Fragment>
                            <tr>
                              <td>
                                <input
                                  className='checkbox30'
                                  type='checkbox'
                                  checked={organisasjon.smsPaminnelseInnbygger}
                                  onChange={(e) => {
                                    this.setState({
                                      organisasjon: {
                                        ...organisasjon,
                                        smsPaminnelseInnbygger: !organisasjon.smsPaminnelseInnbygger,
                                      },
                                    });
                                  }}
                                />
                              </td>
                              <td>&nbsp;&nbsp;&nbsp;</td>
                              <td>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                  }}>
                                  Send påminnelse på SMS dagen før
                                </span>
                              </td>
                            </tr>
                          </React.Fragment>
                        </tbody>
                      </table>
                      <br />
                      {this.props.isNorkartAdmin && (
                        <table
                          style={{
                            marginLeft: '15px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'red',
                          }}>
                          <tbody>
                            <React.Fragment>
                              <tr>
                                <td>
                                  <input
                                    className='checkbox30'
                                    type='checkbox'
                                    checked={organisasjon.varselOrganisasjoner}
                                    onChange={(e) => {
                                      this.setState({
                                        organisasjon: {
                                          ...organisasjon,
                                          varselOrganisasjoner: !organisasjon.varselOrganisasjoner,
                                        },
                                      });
                                    }}
                                  />
                                </td>
                                <td>&nbsp;&nbsp;&nbsp;</td>
                                <td>
                                  <span
                                    style={{
                                      color: 'red',
                                      fontWeight: 'bold',
                                    }}>
                                    Hent personer/roller fra Brreg
                                    &nbsp;&nbsp;&nbsp;
                                  </span>
                                </td>
                              </tr>
                            </React.Fragment>
                          </tbody>
                        </table>
                      )}
                      <table style={{ marginLeft: '15px' }}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className='checkbox30'
                                type='checkbox'
                                checked={organisasjon.smsAvvikLukkGjennomforing}
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      smsAvvikLukkGjennomforing: !organisasjon.smsAvvikLukkGjennomforing,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}>
                                Send SMS (eller SvarUt) ved lukk avvik i Gjennomføring
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table style={{ marginLeft: '15px' }}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                className='checkbox30'
                                type='checkbox'
                                checked={organisasjon.smsAvvikLukkOppfolging}
                                onChange={(e) => {
                                  this.setState({
                                    organisasjon: {
                                      ...organisasjon,
                                      smsAvvikLukkOppfolging: !organisasjon.smsAvvikLukkOppfolging,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <td>&nbsp;&nbsp;&nbsp;</td>
                            <td>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}>
                                Send SMS (eller SvarUt) ved lukk avvik i Oppfølging
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <div className='row'>
                        <div className='col-sm-3 labelmarg'>
                          <label>SMS avsender</label>
                        </div>
                        <div className='col-sm-3 form-group'>
                          <input
                            tooltip='Test'
                            maxLength='11'
                            className='form-control'
                            type='text'
                            value={organisasjon.smsAvsender}
                            onChange={(e) => {
                              console.log('onChange', e.target.value);
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  smsAvsender: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/*   {this.state.isNorkartAdmin && (
                        <table
                          style={{
                            marginLeft: '15px',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'red',
                          }}>
                          <tbody>
                            <React.Fragment>
                              <tr>
                                <td>
                                  <input
                                    className='checkbox30'
                                    type='checkbox' 
                                    checked={organisasjon.varselUtsettSending }
                                    onChange={this.utsettEtterChange}
                                  />
                                </td>
                                <td>&nbsp;&nbsp;&nbsp;</td>
                                <td>
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                    }}>
                                    Utsatt sending
                                    &nbsp;&nbsp;&nbsp;
                                  </span>
                                </td>
                                <td>&nbsp; Antall dager: &nbsp;</td>
                                <td>
                                  {' '}
                                  <input
                                    value={
                                      this.state.organisasjon
                                        ? this.state.organisasjon
                                            .varselUtsettSendingAntalldager
                                        : 0
                                    }
                                    style={{
                                      paddingLeft: '2px',
                                      width: '40px',
                                    }}
                                    onChange={this.utsettAntallChange}
                                    min='0'
                                    max='60'
                                    type='number'
                                    onKeyPress={this.onKeyPress.bind(this)}
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          </tbody>
                        </table>
                      )} */}
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id='karttab' className='tab-pane fade'>
                  <div className='row'>
                    <div className='col-sm-4 labelmarg'>
                      <label>Startlokasjon ved åpning av kart</label>
                    </div>

                    <div
                      className='col-sm-8 form-group'
                      style={{ marginBottom: '60px' }}>
                      <button
                        className='btn btn-default'
                        onClick={() =>
                          this.dialogs.setOpenDialog('KartDialog', {
                            organisasjon,
                          })
                        }>
                        <NKIcon
                          icon='sok'
                          color='nk-black'
                          style={{ marginRight: '6px' }}
                        />
                        Finn startlokasjon på kart
                      </button>
                      <br />
                      <br />
                      <div className='input-group'>
                        <span className='input-group-addon koordinat'>Lat</span>

                        <input
                          maxLength='250'
                          className='form-control'
                          type='text'
                          value={organisasjon.latitude}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                latitude: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className='input-group'>
                        <span className='input-group-addon koordinat'>Lng</span>
                        <input
                          maxLength='250'
                          className='form-control'
                          type='text'
                          value={organisasjon.longitude}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                longitude: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className='input-group'>
                        <span className='input-group-addon koordinat'>
                          Zoom
                        </span>
                        <input
                          maxLength='250'
                          className='form-control'
                          type='text'
                          value={organisasjon.zoomLevel}
                          onChange={(e) => {
                            this.setState({
                              organisasjon: {
                                ...organisasjon,
                                zoomLevel: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id='logotab' className='tab-pane fade'>
                  <div className='row' style={{ margin: '10px' }}>
                    <label>Logo/Topptekst som brukes i maler og brev</label>
                    <br />
                    <p>
                      <span>
                        Det anbefales et bilde med maks. høyde: 100px og maks.
                        bredde: 600 px.
                      </span>
                      <br />
                      <span>
                        Bilder som er større eller mindre enn dette blir
                        justert.
                      </span>
                      <br />
                      <span>Tillatte bilder: png, jpg, jpeg.</span>
                    </p>

                    <div className='previewComponent'>
                      <form>
                        <label htmlFor='hentfil' style={labelShroud} />

                        <button className='btn btn-default btn-large'>
                          <NKIcon icon='kommune' color='nk-black' />
                          Velg bilde
                        </button>
                        <span style={{ marginLeft: '20px' }}>
                          Velg et bilde og last opp
                        </span>
                        <input
                          accept='.jpg,.jpeg,.png'
                          type='file'
                          name='hentfil'
                          onChange={this.onLogoChange.bind(this)}
                          id='hentfil'
                          className='hide'
                        />

                        <br />
                      </form>
                      {/*  <br />
                      <div className='imgPreview'>{$imagePreview}</div> */}
                      <br /> <br />
                      <div className='imgPreview'>
                        <img
                          style={{ maxHeight: '100%' }}
                          src={this.state.logoUrl}
                          className='cover-image' alt=''
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* ******** TAB ************************************** */}
                <div id='fonttab' className='tab-pane fade'>
                  <div className='row' style={{ margin: '10px' }}>
                    <label>
                      Standard skrifttype som brukes i maler og brev
                    </label>
                    <br />
                    <div>
                      <span>
                        I maler og brev brukes det som standard: Arial,
                        størrelse 16px.
                      </span>
                      <br />
                      <span>
                        Obs! Størrelser er i pixler. Sammenlignet med "points" i
                        Word er det slik:
                        <ul style={{ marginLeft: '12px' }}>
                          <li>14 px. = 10,5 pts.</li>
                          <li>15 px. = 11 pts.</li>
                          <li>16 px. = 12 pts.</li>
                        </ul>
                      </span>
                      <br />
                      <div className='row'>
                        <div className='col-sm-4 labelmarg'>
                          <label>Skrifttype</label>
                          <br />
                          <select
                            className='form-control'
                            id='fontselect'
                            style={{
                              width: '100% !important',
                              maxWidth: '100%',
                            }}
                            value={organisasjon.fontType}
                            onChange={(e) => {
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  fontType: e.target.value,
                                },
                              });
                            }}>
                            <option value='Arial'>Arial</option>
                            <option value='Verdana'>Verdana</option>
                            <option value='Open Sans'>Open Sans</option>
                            <option value='Calibri'>Calibri</option>
                            {/*  <option value='Calibri'>Calibri</option>
                            <option value='Times New Roman'>
                              Times New Roman
                            </option> */}
                          </select>
                        </div>
                        <div className='col-sm-4 labelmarg'>
                          <label>Størrelse</label>
                          <br />
                          <select
                            className='form-control'
                            id='fontselect'
                            style={{
                              width: '100% !important',
                              maxWidth: '100%',
                            }}
                            value={organisasjon.fontSize}
                            onChange={(e) => {
                              this.setState({
                                organisasjon: {
                                  ...organisasjon,
                                  fontSize: e.target.value,
                                },
                              });
                            }}>
                            <option value='14px'>14 px.</option>
                            <option value='15px'>15 px.</option>
                            <option value='16px'>16 px.</option>
                            <option value='17px'>17 px.</option>
                          </select>
                        </div>
                      </div>
                      <br />
                      <div
                        style={{
                          width: '390px',
                          height: '130px',
                          border: '1px solid gray',
                          textAlign: 'center',
                          padding: '5px',
                          fontFamily: organisasjon.fontType,
                          fontSize: organisasjon.fontSize,
                          color: 'black',
                        }}>
                        The quick brown fox jumps over the lazy dog.
                        ABCDEFGHIJKLMNOPQRSTUVWXYZÅÆØ
                        abcdefghijklmnopqrstuvwxyzåæø
                        <br />
                        0123456789
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.dialogs.renderOpenDialog()}
          </div>
        </div>
        <div className='nk-modal-footer'>
          <div className='btn-toolbar pull-right' role='toolbar'>
            <button
              type='button'
              onClick={this.lagre.bind(this)}
              className='btn btn-primary h48'
              style={{ padding: '0 25px' }}>
              OK
            </button>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default OrgDetaljer;

// Declarative stuff

const modalDialogs = {
  KartDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal
      isOpen={isOpen}
      onClose={onClose}
      className='FModal'
      contentLabel=''>
      <KartDialog
        visning='startpunkt'
        onClose={onClose}
        objektIn={dialogData.organisasjon}
        objektUt={owner.gotSenterpunkt}
        tittel={'Startpunkt for ' + dialogData.organisasjon.navn}
        minimap={true}
      />
      {/* gotSenterpunkt={this.gotSenterpunkt}  */}
    </ReactModal>
  ),
  ManglerDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel='Fyll ut navn'
      melding='Både Navn og Kortnavn må fylles ut.'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  SMSAvsenderDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel='SMS avsender'
      melding='Avsender kan bare innholde bokstaver og tall'
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  LogoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OkDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};
