import React from 'react';
import AiTest1 from './AiTest1.jsx';


export default class extends React.Component {
  state = { breadcrumbname: 'Laster....' };

  render() {
    return (
      <div>
        <AiTest1/>
      </div>
    );
  }
}