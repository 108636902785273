import { debugFactory } from 'utils';

const debug = debugFactory('nk:search:defaultquery');

export function CreateDefaultQueryNew(query, kommuner) {
  kommuner = Array.from(kommuner);
  return query.addQuery({
    bool: {
      must: [
        {
          term: {
            fromMatrikkel: 'true',
          },
        },
      ],
      /* must_not: [
          {
          term: {
            bruksenhetstypekode: 'U'
          }
        },  
        {
          nested: {
            path: 'bygg',
            query: {
              terms: {
                'bygg.bygningstatuskode': ['RA', 'BU', 'BF', 'BA', 'BR'],
              },
            },
          },
        },
        {
          nested: {
            path: 'bygg',
            query: {
              exists: {
                field: 'bygg.lopenummer',
              },
            },
          },
        },
      ], */
      should: [
        {
          match_all: {},
        },
      ],
      filter: [
        {
          bool: {
           /*  must_not: [
              {term: { 'bruksenhetstypekode': 'U' }}
            ], */
            should: [
              {
                nested: {
                  path: 'skybrannData',
                  query: {
                    nested: {
                      path: 'skybrannData.bruksenhet',
                      query: {
                        bool: {
                          must: [
                            {
                              terms: {
                                'skybrannData.bruksenhet.organisasjonId': kommuner.map(
                                  ({ organisasjonsId }) => {
                                    return organisasjonsId;
                                  }
                                ),
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
              },
              {
                nested: {
                  path: 'kommune',
                  query: {
                    bool: {
                      must: [
                        {
                          terms: {
                            'kommune.kommuneId': kommuner.map(
                              ({ kommuneId }) => {
                                //  debug('CreateDefaultQuery', { kommuneId });
                                return kommuneId;
                              }
                            ),
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  });
}

export function CreateDefaultQueryOld(query, kommuner) { //onsdag
  kommuner = Array.from(kommuner);
  return query.addQuery({
    bool: {
      must: [
        {
          term: {
            fromMatrikkel: 'true',
          },
        },
      ],
       must_not: [
          {
          term: {
            bruksenhetstypekode: 'U'
          }
        },  
        {
          nested: {
            path: 'bygg',
            query: {
              terms: {
                'bygg.bygningstatuskode': ['RA', 'BU', 'BF', 'BA', 'BR'],
              },
            },
          },
        },
        {
          nested: {
            path: 'bygg',
            query: {
              exists: {
                field: 'bygg.lopenummer',
              },
            },
          },
        },
      ], 
      should: [
        {
          match_all: {},
        },
      ],
      filter: [
        {
          bool: {
           /*  must_not: [
              {term: { 'bruksenhetstypekode': 'U' }}
            ], */
            should: [
              {
                nested: {
                  path: 'skybrannData',
                  query: {
                    nested: {
                      path: 'skybrannData.bruksenhet',
                      query: {
                        bool: {
                          must: [
                            {
                              terms: {
                                'skybrannData.bruksenhet.organisasjonId': kommuner.map(
                                  ({ organisasjonsId }) => {
                                    return organisasjonsId;
                                  }
                                ),
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
              },
              {
                nested: {
                  path: 'kommune',
                  query: {
                    bool: {
                      must: [
                        {
                          terms: {
                            'kommune.kommuneId': kommuner.map(
                              ({ kommuneId }) => {
                                //  debug('CreateDefaultQuery', { kommuneId });
                                return kommuneId;
                              }
                            ),
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  });
}

export function CreateDefaultQueryMedUnummererte(query, kommuner) {  //Gamle MedUnummererte  DENNE ER I BRUK NÅ
  kommuner = Array.from(kommuner);
  //debug('CreateDefaultQuery', { kommuner });
  return query.addQuery({
    bool: {
      must: [
        {
          term: {
            fromMatrikkel: 'true',
          },
        },
      ],
      must_not: [
         /*  {
          term: {
            bruksenhetstypekode: 'U'
          }
        },  */
        {
          nested: {
            path: 'bygg',
            query: {
              terms: {
                'bygg.bygningstatuskode': ['RA', 'BU', 'BF', 'BA', 'BR'],
              },
            },
          },
        },
        {
          nested: {
            path: 'bygg',
            query: {
              exists: {
                field: 'bygg.lopenummer',
              },
            },
          },
        },
      ],
      should: [
        {
          match_all: {},
        },
      ],
      filter: [
        {
          bool: {
            should: [
              {
                nested: {
                  path: 'skybrannData',
                  query: {
                    nested: {
                      path: 'skybrannData.bruksenhet',
                      query: {
                        bool: {
                          must: [
                            {
                              terms: {
                                'skybrannData.bruksenhet.organisasjonId': kommuner.map(
                                  ({ organisasjonsId }) => {
                                    return organisasjonsId;
                                  }
                                ),
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
              },
              {
                nested: {
                  path: 'kommune',
                  query: {
                    bool: {
                      must: [
                        {
                          terms: {
                            'kommune.kommuneId': kommuner.map(
                              ({ kommuneId }) => {
                                //  debug('CreateDefaultQuery', { kommuneId });
                                return kommuneId;
                              }
                            ),
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        },
      ],
    },
  });
}

export function CreateDefaultQueryFiktive(query, kommuner) {
  return query.addQuery({
    nested: {
      path: 'matrikkelenhet',
      query: {
        bool: {
          must: [
            {
              terms: {
                'matrikkelenhet.kommunenummer': kommuner.map(
                  ({ kommuneId }) => {
                    return kommuneId;
                  }
                ),
              },
            },
          ],
        },
      },
    },
  });
}
