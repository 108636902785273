import React from 'react';
import { PropTypes } from 'prop-types';
import './Cluster.css';
import { getTooltipForMarkerClusterLayer } from 'components/Kart/getTooltip.js';
import L from 'leaflet';
import { MapLayer, Popup } from 'react-leaflet';
import 'leaflet.markercluster';
import _ from 'lodash';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:kart:MarkerCluster');

/**
 * Wrapper for MarkerClusterGroup fra "leaflet.markercluster". Må ligge inni et
 * Map eller MapLayer fra "react-leaflet" for å få riktig context.
 *
 * Alle options til MarkerClusterGroup kan gis som properties til denne
 * komponenten, men vil kun settes ved mount og blir ikke oppdatert dynamisk.
 *
 * Dette laget oppretter en L.Marker for hvert element i "data". Hvis
 * "markerPopup" er satt vil laget, ved klikk på en marker, rendre en
 * Popup ("react-leaflet") med "markerPopup" som innhold og med "data"-property
 * satt til data-objektet som tilhører marker-en et ble klikket på.
 */
class MarkerClusterLayer extends MapLayer {
  constructor() {
    super();

    this.state = {
      popupObject: null,
      theBounds: null,
      huskPos: null,
    };

    this._clicked = this._clicked.bind(this);
    this._popupClosed = this._popupClosed.bind(this);
  }

  getChildContext() {
    return {
      layerContainer: this.leafletElement,
    };
  }

  getTheBounds() {
    return this.state.theBounds;
  }

  createLeafletElement() {
    let { clusterIcon, ...options } = this.props;
    if (!options.iconCreateFunction && clusterIcon) {
      options.iconCreateFunction = () => clusterIcon;
    }
    this.leafletElement = L.markerClusterGroup(options);
    return this.leafletElement;
  }

  componentDidMount() {
    super.componentDidMount();

    this._addMarkers(this.props);
    this.leafletElement.on('click', this._clicked);
    this.context.map.on('popupclose', this._popupClosed);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.leafletElement.off('click', this._clicked);
    this.context.map.off('popupclose', this._popupClosed);
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.data !== fromProps.data) {
      // Alle markers fjernes fra laget og legges til på nytt. Dette er
      // ganske raskt når addLayers() brukes for å legge til alle i en
      // operasjon. Hvis vi får ytelsesproblemer kan vi prøve med å
      // oppdatere lag-listen i stedet med individuelle removeLayer og
      // addLayer. Kanskje vi kan bruke noen grove estimat for å vurdere
      // hva som er raskest, feks. antall dataobjekter i listen.
      this.leafletElement.clearLayers();
      this._addMarkers(toProps);

      // fjern popup hvis objektet som popup-en viser ikke lenger finnes i
      // data-listen
      if (
        this.state.popupObject &&
        !_.includes(toProps.data, this.state.popupObject)
      ) {
        this.setState({ popupObject: null });
      }
    }
  }

  //Return true dersom brøk 1/1 har lik teller og nevner
  getGjennomfort(obj) {
    var index1 = '';
    var utf = '';
    var til = '';
    if (obj.tiltakBrukenhetStatus) {
      index1 = obj.tiltakBrukenhetStatus.indexOf('/');
      utf =
        parseInt(obj.tiltakBrukenhetStatus.substring(0, index1)) +
        obj.antallLopPaaAlisteBesokt;
      til =
        parseInt(
          obj.tiltakBrukenhetStatus.substring(
            index1 + 1,
            obj.tiltakBrukenhetStatus.length
          )
        ) + obj.antallLopPaaAliste;
    }
    return utf === til;
  }

  //OnClick in gjennomføring-kart
  onClickGj(x, e) {
    this.props.onClickedGj(x);
    this.leafletElement.eachLayer(function(layer) {
      if (layer._icon !== undefined && layer._icon != null) {
        layer._icon.style.width = '25px';
        layer._icon.style.height = '41px';
      }
    });

    e.layer._icon.style.width = '37px';
    e.layer._icon.style.height = '61px';
    let zx = e.layer._zIndex;
    e.layer.setZIndexOffset(zx + 100);
  }

  //OnClick in gjennomføring-kart
  onClickVarsel(e) {
    this.props.onClickedVarsel(e);
  }

  makeIcon = (nr, hartelefon, visning) => {

    let s = 'gj-icon-green';

    if (visning === 'planlegging-varsel'){
      if (!hartelefon) {
        s = 'gj-icon-no-telefon';
      }
    }
/* 
    if (visning === 'planlegging-rekke'){
      s = 'gj-icon-green-stor';
    } */

    let html = nr.toString();

    let icon = L.divIcon({
      className: s,
      html: html,
    });
    return icon;
  };

  _addMarkers(props) {
    let { data } = props;
    if (!data || data.length === 0) {
      return;
    }
    let options = {
      interactive: Boolean(props.markerPopup),
    };

    if (props.markerIcon) {
      options.icon = props.markerIcon;
    }

    let itemsWithLocation = props.data;
    let markers = itemsWithLocation
      .map((obj) => {
      
        if ((props.visning === 'planlegging-varsel') || (props.visning === 'planlegging-rekke')) {
          options.icon = this.makeIcon(obj.index, obj.hartelefon, props.visning);
        } else {
          if (props.markerIcon) {
            options.icon = props.markerIcon;
          }

          if (props.valgtIcon) {
            let x = obj.valgt;

            if (x === true) {
              options.icon = props.valgtIcon;
            }
          }
        }

        //Vis icon green dersom alt gjennomført

        if ((props.visning !== 'planlegging-varsel') && (props.visning !== 'planlegging-rekke')){
          if (props.gjennomfortIcon) {
            let gjen = this.getGjennomfort(obj);
            if (gjen) {
              options.icon = props.gjennomfortIcon;
            } else {
              options.icon = props.markerIcon;
            }
          }
        }

        /*   if (obj[props.valgtBetingelse] === props.valgtVerdi) {
                    if (props.valgtIcon) {
                        options.icon = props.valgtIcon;
                    } 
                } else
                {
                    if (props.markerIcon) {
                        options.icon = props.markerIcon;
                    }
                };
                //Vis f.eks. enhet i en bestemt farge

              /*   if (obj[props.brannBetingelse] === props.brannVerdi) {
                    if (props.brannIcon) {
                        options.icon = props.brannIcon;
                    }
                } else
                {
                    if (props.markerIcon) {
                        options.icon = props.markerIcon;
                    }
                };

                //Risiko
              if (props.brannRisiko && props.brannRisikoIcon && props.brannRisikoMax) {
                    if (obj[props.brannRisiko] >  props.brannRisikoMax) {
                        options.icon = props.brannRisikoIcon;
                    };         
                }  */

        let position = this.props.latLngAccessor(obj);
        if (position[0] === undefined || position[1] === undefined) {
          return null;
        }
        if (position[0] === 0 || position[1] === 0) {
          return null;
        }

        let marker = L.marker(position, options);

        marker._popupData = obj;
        // marker.options.title = this.props.getMarkerTooltip(obj);

        //********************************************** TOOLTIP ********************************* */

        marker.bindPopup(
          getTooltipForMarkerClusterLayer(obj, this.props.visning),
          { closeButton: false, offset: [0, 30] }
        );
        marker.on('mouseover', function(e) {
          this.openPopup();
        });
        marker.on('mouseout', function(e) {
          this.closePopup();
        });
        //********************************************** TOOLTIP END ********************************* */

        if (props.senterpunkt === true) {
          marker.options.draggable = true;
        }
        return marker;
      })
      .filter(function(item) {
        return item !== undefined;
      });
    if (this.leafletElement) {
      this.leafletElement.addLayers(markers);
      this.setState({ theBounds: this.leafletElement.getBounds() });
    }
  }
  render() {
    let { markerPopup } = this.props;
    let { popupObject } = this.state;
    if (!markerPopup || !popupObject) {
      return null;
    }

    let offset = [0, 30];

    //const popupPosition = this.props.latLngAccessor(popupObject);
    let popupPosition = [ this.state.huskPos.latlng.lat, this.state.huskPos.latlng.lng ];
    if (!popupPosition || popupPosition.length < 2) {
      return null;
    }

    return (
      <Popup
        position={popupPosition}
        offset={offset}
        closeOnClick={false}
        autoClose={false}>
        <this.props.markerPopup data={popupObject} />
      </Popup>
    );
  }

  _clicked(e) {
    this.setState({ huskPos: e });
    if (this.props.visning === 'gjennomføring-kart') {
      this.onClickGj(e.layer._popupData, e);
      return;
    }

    if ((this.props.visning === 'planlegging-varsel') || (this.props.visning === 'planlegging-rekke')){
      if (this.props.sorterModus) {
        this.onClickVarsel(e.layer._popupData, e);
      }
      return;
    }

    this.props.onClickedGj(e);

    if (!this.props.markerPopup || !this.context.map || !e.layer._popupData) {
      return;
    }

    this.setState({ popupObject: e.layer._popupData });
  }

  _popupClosed() {
    //close
    this.setState({ popupObject: null });
  }
}

MarkerClusterLayer.contextTypes = {
  layerContainer: PropTypes.shape({
    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
  }),
  map: PropTypes.instanceOf(L.Map),
};

MarkerClusterLayer.childContextTypes = {
  layerContainer: PropTypes.shape({
    addLayer: PropTypes.func.isRequired,
    removeLayer: PropTypes.func.isRequired,
  }),
};

MarkerClusterLayer.propTypes = {
  /**
   * The data set to generate markers from.
   * Optional (but nothing will be shown if null or empty).
   */
  data: PropTypes.array,

  /** Icon to use for marker clusters. Optional. */
  clusterIcon: PropTypes.instanceOf(L.Icon),

  /** Icon to use for individual markers. Optional. */
  markerIcon: PropTypes.instanceOf(L.Icon),

  /** Brukes i brannfoebygging  */
  brannIcon: PropTypes.instanceOf(L.Icon),
  brannBetingelse: PropTypes.any,
  brannVerdi: PropTypes.any,
  brannRisiko: PropTypes.any,
  brannRisikoMax: PropTypes.any,
  brannRisikoIcon: PropTypes.instanceOf(L.Icon),
  bulkfarge: PropTypes.any,

  /**
   * Popup content to show when a marker is clicked. The data object
   * corresponding to the clicked marker will be passed as property named
   * "data" to the content.
   * Optional.
   */
  markerPopup: PropTypes.any,
  markerTooltip: PropTypes.any,

  /**
   * Accessor function used to get geographic position of each item in "data".
   * Return value must on the form: [<latidute>, <longitude>].
   * Required.
   */
  latLngAccessor: PropTypes.func.isRequired,

  // MarkerClusterGroup options
  showCoverageOnHover: PropTypes.bool,
  zoomToBoundsOnClick: PropTypes.bool,
  spiderfyOnMaxZoom: PropTypes.bool,
  removeOutsideVisibleBounds: PropTypes.bool,
  animate: PropTypes.bool,
  animateAddingMarkers: PropTypes.bool,
  disableClusteringAtZoom: PropTypes.number,
  maxClusterRadius: PropTypes.number,
  polygonOptions: PropTypes.object,
  singleMarkerMode: PropTypes.bool,
  spiderLegPolylineOptions: PropTypes.object,
  spiderfyDistanceMultiplier: PropTypes.number,
  iconCreateFunction: PropTypes.func,
};

MarkerClusterLayer.defaultProps = {
  // MarkerClusterGroup options
  showCoverageOnHover: true,
  zoomToBoundsOnClick: true,
  spiderfyOnMaxZoom: true,
  removeOutsideVisibleBounds: true,
  animate: true,
  animateAddingMarkers: false,
  disableClusteringAtZoom: null,
  maxClusterRadius: 10,
  polygonOptions: {},
  singleMarkerMode: false,
  spiderLegPolylineOptions: {},
  spiderfyDistanceMultiplier: 1,
  iconCreateFunction: null,
};

export default MarkerClusterLayer;
