import React, { Component } from 'react';
import CONFIG from 'lib/CONFIG';
import ReactModal from 'react-modal';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKIcon from 'nka-icons';
import './test.css';
import { debugFactory } from 'utils';
import TestEditorDialog from './TestEditorDialog.jsx';

import 'froala-editor/css/froala_style.min.css';
/* import 'froala-editor/css/froala_editor.pkgd.min.css'; */
import 'froala-editor/js/languages/nb.js';
/* import 'froala-editor/js/plugins.pkgd.min.js';   */
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/align.min.js';   
import 'froala-editor/js/plugins/emoticons.min.js'; 
import 'froala-editor/js/plugins/print.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
/* import 'froala-editor/js/plugins/image.min.js'; */
import FroalaEditorComponent from 'react-froala-wysiwyg';
const debug = debugFactory('nk:testSide');

const FROALA_KEY = CONFIG.REACT_APP_FROALA;


const modalDialogs = {
  TestDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal isOpen={isOpen} className={owner.state.TestClass}>
      <TestEditorDialog
        toggleKommentar={owner.toggleKommentar}
        onClose={onClose}
      />
    </ReactModal>
  ),
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
};

class Test1 extends Component {
  constructor(props) {
    super(props);
    this.state = { TestClass: 'testModal', model: 'Dette er en test!' };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {}

  openEditor = () => {
    this.setState(
      { TestClass: 'testModal' },
      this.dialogs.setOpenDialog('TestDialog', {})
    );
  };

  toggleKommentar = () => {
    let s = this.state.TestClass;
    if (s === 'testModal') {
      s = 'testModalKommentar';
    } else {
      s = 'testModal';
    }
    this.setState({ TestClass: s });
  };

  feil = () => {
    debug('logError start');
    //trigger en feil
  };

  handleModelChange = (model) => {
    this.setState({ model });
  };

  render() {
    const config = {
      toolbarInline: false,
      toolbarVisibleWithoutSelection: false,
      key: FROALA_KEY,
      attribution: false,
      placeholderText: 'Skriv kommentar..',
      autoFocus: true,
      tabSpaces: 4,
      imagePaste: false,
      language: 'nb',
      heightMin: '150px',
      heightMax: '200px',
      quickInsertEnabled: false,
      lineheight: '100%',
      emoticonsStep: 4,
      emoticonsSet: [{
        id: 'people',
        name: 'Ansikt',
        code: '1f642',
        emoticons: [
          { code: '1f642', desc: 'Smilende ansikt' },  
          { code: '1f641', desc: 'Trist ansikt' },
          { code: '1F609', desc: 'Winking face' },
          { code: '1f600', desc: 'Grinning face' },
          { code: '1f601', desc: 'Grinning face with smiling eyes' },
          { code: '1f602', desc: 'Face with tears of joy' },
          { code: '1f615', desc: 'Confused face' },
          { code: '1f604', desc: 'Smiling face with open mouth and smiling eyes' },
          { code: '1f605', desc: 'Smiling face with open mouth and cold sweat' },
        ]
      },
      {
      id: 'hands',
      name: 'Hånd',
      code: '1F44D',
      emoticons: [
        { code: '1F44D', desc: 'Tommel opp' },  
        { code: '1F44E', desc: 'Tommel ned' }, 
        { code: '1F44C', desc: 'Perfekt' }, 
        { code: '1F44E', desc: 'Tommel ned' }, 
        { code: '1F44F', desc: 'Applaus' }, 
        { code: '1F525', desc: 'Brann' },  
        { code: '26A0', desc: 'Varsel' },
      ]
    }
    ], 
      toolbarButtons: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            '|',
            'formatOL',
            'formatUL',
            '|',
            'specialCharacters',
            'emoticons',
          ],
          align: 'left',
          buttonsVisible: 9,
        },
      },
      toolbarButtonsMD: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            '|',
            'formatOL',
            'formatUL',
            '|',
            'specialCharacters',
            'emoticons',
          ],
          align: 'left',
          buttonsVisible: 6,
        },
      },
      toolbarButtonsXS: {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            '|',
            'formatOL',
            'formatUL',
            '|',
            'specialCharacters',
          ],
          align: 'left',
          buttonsVisible: 3,
        },
      },
      charCounterCount: false,
      events: {
        /* disable dersom det er Godkjent */
        initialized: (e) => {
          setTimeout(() => {
            let editor = this.froalaRef.editor;
            editor.toolbar.hide();
          }, 10);
        },
        focus: (e) => {
          setTimeout(() => {
            let editor = this.froalaRef.editor;
            editor.toolbar.show();
          }, 10);
        },
      }
    };
    return (
      <div>
        <h2>Test 1</h2>
        <label>Test av Dokumentkontroll i en editor-dialog</label>
        <br />
        <button onClick={() => this.openEditor()} className='btn btn-default'>
          Åpen editor
        </button>
        <br />
        <br />
        <button className='btn btn-info' onClick={this.feil}>
          Gi exception-feil
        </button>
        <br />
        <br />
        <br />
        <div style={{ backgroundColor: 'white', maxHeight: '200px' }}>
          <FroalaEditorComponent
           ref={(el) => {
            this.froalaRef = el;
          }}
            id='editor'
            config={config}
            useClasses={true}
            documentReady={true}
            model={this.state.model}
            onModelChange={this.handleModelChange}
          />
        </div>
        <br />
        <br />
        <br />
        <div><br/><br/><br/>

          <div className='scaleDiv' > 
            <span style={{ maxHeight: '100px' }} className='fr-view' dangerouslySetInnerHTML={{ __html: this.state.model }}></span>
        
          </div>
        </div>
        
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Test1;
