import React from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:VelgArsakDialog');

class VelgArsakDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
  };

  brukArsak = (arsak) => {
    this.props.onVelgArsak(arsak);
  };

  brukIkkeArsak = () => {
    this.props.onIkkeVelgArsak();
  };

  render() {
    return (
      <div style={{ height: '500px' }} className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Velg årsak til lukking </h4>
        </div>
        <div className='modal-body'>
          <button onClick={this.brukIkkeArsak} className='btn btn-default'>Lukk: Årsak ikke angitt</button>
          <br/><br/>
          <div
            className='list-group'
            style={{ maxHeight: '390px', overflow: 'auto' }}>
            {this.props.arsaker.map(function(arsak, inx) {
              let tekst = arsak.tekst;

              return (
                <button
                  key={inx}
                  type='button'
                  onClick={this.brukArsak.bind(this, arsak)}
                  className='list-group-item list-group-item-action'>
                  <span>{tekst}</span>
                </button>
              );
            }, this)}
          </div>
        </div>
      </div>
    );
  }
}

export default VelgArsakDialog;
