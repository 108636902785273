import React, { Component } from 'react';
import './diagram.css';
import {
  ResponsiveContainer,
  Cell,
  Tooltip,
  PieChart,
  Pie,
  Legend,
} from 'recharts';
import { exportComponentAsPNG } from 'react-component-export-image';

const COLORS = [
  '#51a026',
  '#F5AD4D',
  '#E25653',
  '#87A8E0',
  '#388A8D',
  '#A07A75',
  '#A1C438',
  'fuchsia',
  'bisque',
  'lavender',
  'pink',
];

class DonutsHypp extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = { ddata: [], visLabel: false };
  }

  componentDidMount = () => {
    this.makeData(this.props.data);
  };

  makeData = (data) => {
    for (let i = 0; i < data.length; i++) {
      const array = data[i].hyppighetAntall;
      for (let y = 0; y < array.length; y++) {
        const obj = array[y];
        obj.name = array[y].tekst1;
        if (!obj.name){
          obj.name = 'Ingen hypp.';
        }
        obj.value = parseInt(array[y].tekst2);
      }
    }
    this.setState({ ddata: data });
  };

  render() {
    return (
      <div style={{ maxWidth: '1400px', width: '90%' }}>
        <div className='row' ref={this.componentRef}>
          {this.state.ddata.map((hypp, inx) => {
            return (
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <React.Fragment>
                  <ResponsiveContainer width='100%' height={350}>
                    <PieChart height={350}>
                      <Pie
                        label={this.state.visLabel}
                        data={hypp.hyppighetAntall}
                        dataKey='value'
                        nameKey='name'
                        cx='50%'
                        cy='50%'
                        innerRadius={50}
                        outerRadius={100}
                        fill='#51a026'>
                        {hypp.hyppighetAntall.map((entry, index) => (
                          <Cell key={inx} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        layout='vertical'
                        verticalAlign='top'
                        align='right'
                      />
                      <text
                        x='30'
                        y='40'
                        dominantBaseline='hanging'
                        fontSize='18'
                        fontWeight='bold'>
                        {hypp.tiltak}
                      </text>
                    </PieChart>
                  </ResponsiveContainer>
                </React.Fragment>
              </div>
            );
          })}
        </div>
        <button
          className='btn btn-link'
          onClick={() => this.setState({ visLabel: !this.state.visLabel })}>
          Vis labels
        </button>
        <button
          className='btn btn-link'
          onClick={() =>
            exportComponentAsPNG(this.componentRef, {
              fileName: this.props.filnavn,
            })
          }>
          Last ned som bilde
        </button>
      </div>
    );
  }
}

export default DonutsHypp;
