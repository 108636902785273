import * as boundActions from 'redux/boundActions';
import * as appActions from 'redux/app/actions';
import * as planleggingActionTypes from 'redux/planlegging/actionTypes';

import arbeidslisteApi from 'api/arbeidslisteApi';
import * as enhetApi from 'api/enhetApi.js';
import * as personApi from 'api/personApi';
import * as kontaktApi from 'api/kontaktApi';
import _ from 'lodash';
import {
  debugFactory,
  objGetDeep,
  objOmitProps,
  promisifyApiCall
} from 'utils';

const debug = debugFactory('nk:redux:planlegging:actions');

export const fetchTiltakstyper = ({ forceFetch = false } = {}) => (
  dispatch,
  getState
) => {
  const actionType = planleggingActionTypes.TILTAKSTYPER_FETCHED;
  const state = getState();

  if (boundActions.shouldFetch(actionType, forceFetch)) {
    return dispatch(
      boundActions.fetch(actionType, enhetApi.getAlleTiltakstyper, {
        debugInstance: debug
      })
    ).then(data => {
      dispatch({
        type: actionType,
        payload: data
      });
      return data;
    });
  } else {
    return Promise.resolve(state.planlegging.tiltakstyper);
  }
};

export const fetchPersoner = ({ forceFetch = false } = {}) => (
  dispatch,
  getState
) => {
  const actionType = planleggingActionTypes.PERSONER_FETCHED;
  const state = getState();

  if (boundActions.shouldFetch(actionType, forceFetch)) {
    return dispatch(
      boundActions.fetch(actionType, personApi.getPersoner, {
        debugInstance: debug
      })
    ).then(data => {
      dispatch({
        type: actionType,
        payload: data
      });
      return data;
    });
  } else {
    return Promise.resolve(state.planlegging.personer);
  }
};

export const fetchBrukere = ({ forceFetch = false } = {}) => (
  dispatch,
  getState
) => {
  const actionType = planleggingActionTypes.BRUKERE_FETCHED;
  const state = getState();

  if (boundActions.shouldFetch(actionType, forceFetch)) {
    return dispatch(
      boundActions.fetch(actionType, personApi.getPersoner, {
        debugInstance: debug
      })
    ).then(data => {
      let brukere = {};
      let plId = 0;
      let pl = _.filter(data, item => item.paaLogget === true);
      if (pl && pl.length) {
        plId = pl[0].id;
      }
      brukere = { paalogget: plId, brukere: data };
      dispatch({
        type: actionType,
        payload: brukere
      });
      return brukere;
    });
  } else {
    return Promise.resolve(state.planlegging.brukere);
  }
};

export const fetchArbeidslister = ({ forceFetch = false } = {}) => (
  dispatch,
  getState
) => {
  const actionType = planleggingActionTypes.ARBEIDSLISTER_FETCHED;
  const state = getState();

  if (boundActions.shouldFetch(actionType, forceFetch)) {
    return dispatch(
      boundActions.fetch(
        actionType,
        arbeidslisteApi.getArbeidslistePlanlegging,
        {
          debugInstance: debug
        }
      )
    ).then(data => {
      dispatch({
        type: actionType,
        payload: data
      });
      return data;
    });
  } else {
    return Promise.resolve(state.planlegging.arbeidslister);
  }
};

export const fetchArbeidslisteEnheter = (id, { forceFetch = false } = {}) => (
  dispatch,
  getState
) => {
  const actionType = planleggingActionTypes.ARBEIDSLISTE_ENHETER_FETCHED;
  const state = getState();
  const prevId = objGetDeep(state, 'planlegging.selectedArbeidsliste.id');

  if (
    boundActions.shouldFetch(actionType, forceFetch) ||
    prevId !== parseInt(id)
  ) {
    return dispatch(
      boundActions.fetch(
        actionType,
        enhetApi.getArblisteBrukenheterPlanlegging,
        {
          apiArgs: [id],
          debugInstance: debug
        }
      )
    ).then(data => {
      dispatch({
        type: actionType,
        payload: data
      });
      return data;
    });
  } else {
    return Promise.resolve(state.planlegging.arbeidslisteEnheter);
  }
};

export const fetchEnheterMedEiere2 = dtoListe => (dispatch, getState) => {
  const actionType = planleggingActionTypes.ENHETER_MED_EIERE2_FETCHED;
  const state = getState();
  //debug('qqq dtoListe', dtoListe);
  if (boundActions.shouldFetch(actionType)) {
      return promisifyApiCall(kontaktApi.getKontakterForEnhetInfo)(
        dtoListe
        ).then(data => {
            dispatch({
              type: actionType,
              payload: data
            });
           // debug('qqq', data);
            return data;
          });
    
  } else {
    return Promise.resolve(state.planlegging.enheterMedEiere);
  }
};

export const fetchEnheterMedEiere = enheterListe => (dispatch, getState) => {
    const actionType = planleggingActionTypes.ENHETER_MED_EIERE_FETCHED;
    let liste = [];
    const state = getState();
    let enhet = enheterListe[0];
    let dtoListe = [];
    let bruksenhetDto = {
      BruksenhetFagId: enhet.id,
      BruksenhetMaId: enhet.bruksenhetIdMa,
      BruksenhetAdresse: enhet.adresse
    };
    dtoListe.push(bruksenhetDto);
  
    let teller = 0;
    let total = enheterListe.length;
    if (boundActions.shouldFetch(actionType)) {
      return _.map(enheterListe, function(enhet) {
        return promisifyApiCall(kontaktApi.getKontakterForEnhetInfo)(
          dtoListe
        ).then(result => {
          teller++;
          enhet.kontakter = result[0].kontakt;
          liste.push(enhet);
          // debug('eiere result liste', liste);
          dispatch({
            type: actionType,
            payload: liste
          });
          if (teller === total) {
            return liste;
          }
        });
      });
    } else {
      return Promise.resolve(state.planlegging.enheterMedEiere);
    }
  };

export const setSelectedArbeidsliste = id => (dispatch) => {
 // debug('setSelectedArbeidsliste', id);
  return dispatch(fetchArbeidslister()).then(data => {
    const item = data.find(item => item.id === parseInt(id));
  //  debug('setSelectedArbeidsliste:dispatch', { id, item, data });
    dispatch({
      type: planleggingActionTypes.ARBEIDSLISTE_SELECTED,
      payload: item
    });
    return item;
  });
};

export const toggleArbeidslisteAktiv = id => (dispatch, getState) => {
  const state = getState();
  const data = objGetDeep(state, 'planlegging.arbeidslister');
  const isActive = !data.find(item => item.id === parseInt(id)).aktiv;
  return promisifyApiCall(arbeidslisteApi.updateArbeidslisteStatus)(
    id,
    isActive
  ).then(() => {
    const props = { aktiv: isActive };
    debug('toggleArbeidslisteAktiv', { id, props });
    dispatch({
      type: planleggingActionTypes.UPDATE_ARBEIDSLISTE,
      payload: { id, props }
    });
    return { id, props };
  });
};

export const updateArbeidsliste = (id, props) => (dispatch) => {
 // debug('updateArbeidsliste:api-props', { id, props });

  return promisifyApiCall(arbeidslisteApi.updateArbeidsliste)(id, props).then(
    () => {
      // Remove what the api-call requires and rebuild what `fetchArbeidlister` delivers
      const selectedPersons = props.ansvarlige.filter(item => !item.slett);
      const payloadProps = {
        ...objOmitProps(props, 'ansvarlige'),
        ansvarligIds: selectedPersons
          .map(item => item.personId)
          .sort()
          .join(','),
        ansvarlig: selectedPersons
          .map(item => item.navn)
          .sort()
          .join(',')
      };

     // debug('updateArbeidsliste:payload-props', { id, payloadProps });

      dispatch({
        type: planleggingActionTypes.UPDATE_ARBEIDSLISTE,
        payload: { id, props: payloadProps }
      });
      dispatch({
        type: planleggingActionTypes.ARBEIDSLISTE_UPDATED
      });
      return { id, props };
    }
  );
};

export const removeArbeidsliste = id => (dispatch) => {
  return promisifyApiCall(arbeidslisteApi.deleteArbeidsliste)(id).then(() => {
  //  debug('removeArbeidsliste', { id });
    dispatch({
      type: planleggingActionTypes.REMOVE_ARBEIDSLISTE,
      payload: { id }
    });
    dispatch({
      type: planleggingActionTypes.ARBEIDSLISTE_UPDATED
    });
    return true;
  });
};

export const addNewArbeidsliste = partialItem => (dispatch) => {
  return promisifyApiCall(arbeidslisteApi.saveArbeidsliste)(partialItem)
    .then(({ id }) => {
      return promisifyApiCall(arbeidslisteApi.getArbeidslisteObjekt)(id);
    })
    .then(item => {
     // debug('addNewArbeidsliste', { item });
      dispatch({
        type: planleggingActionTypes.ADD_NEW_ARBEIDSLISTE,
        payload: { item }
      });
      dispatch({
        type: planleggingActionTypes.ARBEIDSLISTE_UPDATED
      });
      return item;
    });
};

export const initializeLeggTilObjekter = () => (dispatch) => {
 // debug('initializeLeggTilObjekter');
  return Promise.resolve(
    dispatch({
      type: planleggingActionTypes.INITIALIZE_LEGG_TIL_OBJEKTER
    })
  );
};

export const updateObjektLockReasons = updates => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: planleggingActionTypes.UPDATE_OBJEKT_LOCK_REASONS,
      payload: updates
    })
  );
};

export const setObjektLockReasons = objeckLockreasons => (
  dispatch
) => {
  return Promise.resolve(
    dispatch({
      type: planleggingActionTypes.SET_OBJEKT_LOCK_REASONS,
      payload: objeckLockreasons
    })
  );
};

// T I L T A K L I S T E R

export const fetchArbeidslisteTiltakLister = (
  id,
  { forceFetch = false } = {}
) => (dispatch, getState) => {
  const actionType = planleggingActionTypes.ARBEIDSLISTE_TILTAKSLISTER_FETCHED;
  const state = getState();
  const prevId = objGetDeep(state, 'planlegging.selectedArbeidsliste.id');

  if (forceFetch || boundActions.shouldFetch(actionType) || id !== prevId) {
    return dispatch(
      boundActions.fetch(
        actionType,
        arbeidslisteApi.getArbeidslisteBrukseneheterOgRoklop,
        {
          apiArgs: [id],
          debugInstance: debug
        }
      )
    ).then(data => {
      dispatch({
        type: actionType,
        payload: data
      });
      return data;
    });
  } else {
    return Promise.resolve(state.planlegging.tiltakLister);
  }
};

export const setArbeidslisteTiltakLister = tiltakLister => (
  dispatch
) => {
  //debug('setArbeidslisteTiltakLister', { tiltakLister });
  return Promise.resolve(
    dispatch({
      type: planleggingActionTypes.SET_ARBEIDSLISTE_TILTAKSLISTER,
      payload: tiltakLister
    })
  );
};

export const saveArbeidslisteTiltakLister = tiltakLister => (
  dispatch
) => {
  debug('saveArbeidslisteTiltakLister:api-props', { tiltakLister });
  return promisifyApiCall(arbeidslisteApi.saveTiltak)(tiltakLister).then(() =>
    dispatch({
      type: planleggingActionTypes.ARBEIDSLISTE_UPDATED
    })
  );
};

// S N A P S H O T S

export const takePageObjekterSnapshot = () => (dispatch, getState) => {
  const snapshotKey = '@@planlegging/leggtilobjekter/pageobjekter';
  const state = getState().planlegging;

  const snapshot = {
    arbeidslisteTiltakLister: state.arbeidslisteTiltakLister
  };

 // debug('takePageObjekterSnapshot', { snapshotKey, snapshot });

  return dispatch(appActions.setStateSnapshot(snapshotKey, snapshot));
};

export const applyPageObjekterSnapshot = (tiltakListeKey = undefined) => (
  dispatch,
  getState
) => {
  const snapshotKey = '@@planlegging/leggtilobjekter/pageobjekter';

  let snapshot = _.cloneDeep(getState().app.stateSnapshots[snapshotKey]);

  debug('applyPageObjekterSnapshot', { snapshotKey, tiltakListeKey, snapshot });

  const arbeidslisteTiltakLister = tiltakListeKey
    ? { [tiltakListeKey]: snapshot.arbeidslisteTiltakLister[tiltakListeKey] }
    : snapshot.arbeidslisteTiltakLister;

  dispatch(setArbeidslisteTiltakLister(arbeidslisteTiltakLister));
};

export const takePageTiltakSnapshot = () => (dispatch, getState) => {
  const snapshotKey = '@@planlegging/leggtilobjekter/tiltaklister';
  const state = getState().planlegging;

  const snapshot = {
    arbeidslisteTiltakLister: state.arbeidslisteTiltakLister
  };

 // debug('takePageTiltakSnapshot', { snapshotKey, snapshot });

  return dispatch(appActions.setStateSnapshot(snapshotKey, snapshot));
};

export const applyPageTiltakSnapshot = (tiltakListeKey = undefined) => (
  dispatch,
  getState
) => {
  let snapshotKey = '@@planlegging/leggtilobjekter/tiltaklister';

  let snapshot = _.cloneDeep(getState().app.stateSnapshots[snapshotKey]);

 // debug('applyPageTiltakSnapshot', { snapshotKey, tiltakListeKey, snapshot });

  const arbeidslisteTiltakLister = tiltakListeKey
    ? { [tiltakListeKey]: snapshot.arbeidslisteTiltakLister[tiltakListeKey] }
    : snapshot.arbeidslisteTiltakLister;

  dispatch(setArbeidslisteTiltakLister(arbeidslisteTiltakLister));
};
