import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ReactTable from '@norkart/react-table';
import NKIcon from 'nka-icons';
import { getBruksenhetKontakter2 } from 'api/kontaktApi.js';
//import { getOrganisasjonSvarUt  } from 'api/organisasjonApi';
import { getOrgArkivInfo, getOrgSvarUt } from 'context/contextInnstillinger.js';
import { getSakskategori, sjekkSakArkivStatus, getKodeliste } from 'api/arkivApi';
import { sendTilEierFraOppfPubliserBruksenhet } from 'api/innbyggerDialogApi.js';
import { uuid } from 'utils';
import _ from 'underscore';
import Spinner from 'components/generics/Spinner';

let bekontakter = null;

const kontaktString =
  '<!-- mott1 --><span contenteditable="false" style="background-color: yellow; color: blue;">Ved &quot;Send svar til eier&quot;<br>vises mottakerne her..<br>Vennligst ikke fjern.</span><!-- mott2 -->';

class SendAvvikTilEier extends React.Component {
  state = { kontakterUtenTlf: null, 
            kontakterMedTlf: null,
            valgteEiereMed: [],
            valgteEiereUten: [],
            svarUt: false,
            visSkjerming: false,
            unntatt: false,
            skjermingSatt: false,
            isLoading: false,
            arkivering: true,
            bruksenhetKontakt: null,
            bruksenhetKontakter: [] };

  componentDidMount() {
    this.setState({ isLoading: true });
    getOrgSvarUt(this.gotSvarUt);
    getOrgArkivInfo(this.gotArkivInfo);
    //this.init();
  }

  gotSvarUt = (res) => {
    console.log(res);
    this.setState({ svarUt: res.data });
  }

  gotArkivInfo = (res) => {
    this.init2();
    this.setState({ visSkjerming: res.data.arkivSkjerming, arkivering: !res.data.arkivIkkeAktiv });
    if (!res.data.arkivIkkeAktiv && res.data.arkivSkjerming) {
      getSakskategori('BF', null, this.gotKategori);
    }
  }

  gotKategori = (err, kat) => {
    if (kat != null) {
      if (
        kat.Tilgangsrestriksjon != null &&
        kat.Tilgangsrestriksjon != '' &&
        kat.Skjermingshjemmel != null &&
        kat.Skjermingshjemmel != ''
      ) {
        this.setState({
          skjermingSatt: true,
          tilgang: kat.Tilgangsrestriksjon,
          hjemmel: kat.Skjermingshjemmel,
        });
      } else {
        getKodeliste(
          'Tilgangsrestriksjon',
          uuid(),
          this.gotTilgangsrestriksjon
        );
      }
    }
  };

  gotTilgangsrestriksjon = (err, data) => {
    this.setState({ Tilgangsrestriksjoner: data, tilgang: data[0].kodeverdi });
    getKodeliste(
      'SkjermingsHjemmel',
      uuid(),
      this.gotSkjermingsHjemmel
    );
  };

  gotSkjermingsHjemmel = (err, data) => {
    this.setState({
      SkjermingsHjemmeler: data,      
      hjemmel: data[0].kodeverdi,
    });
  };

  sjekkMottakerFelt = () => {
    let liste = this.props.avvikListe;
    for (let i = 0; i < liste.length; i++) {
      const av = liste[i];
      if (av.publiserInnbyggerTekst !== null && av.publiserInnbyggerTekst !== undefined) {
        if (av.publiserInnbyggerTekst.includes(kontaktString)){
          av.publiserInnbyggerTekst = av.publiserInnbyggerTekst.replaceAll( kontaktString, '#mottaker#' );
        };
      }  
    };
    return liste;
  }

  init2 = () => {
   // let avListe = this.props.avvikListe;
   let avListe = this.sjekkMottakerFelt(); 
    let bruksenhetids = [];
    bruksenhetids.push(this.props.bruksenhetId.toString());
    avListe.forEach(element => {
      console.log(element);
      if (element.publiserInnbyggerDialogKlar) {
        if (element.bruksenhetIdsTilknyttet !== null) {
          if (element.bruksenhetIdsTilknyttet.includes(',')) {
            let temp = element.bruksenhetIdsTilknyttet.split(',');
            temp.forEach(tmp => {
            if (!bruksenhetids.includes(tmp)) {
              bruksenhetids.push(tmp);
            }
          });
        } else {
          if (!bruksenhetids.includes(element.bruksenhetIdsTilknyttet)) {
            if (element.bruksenhetIdsTilknyttet !== '') {
              bruksenhetids.push(element.bruksenhetIdsTilknyttet);
            }
          }
        }
      }
    }
    });
    getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakter);
  }

  gotEierkontakter = (err, res) => {
    console.log(res);
    bekontakter = res;
    this.setState({ bruksenhetKontakt: res[0] });
    this.setState({bruksenhetKontakter: res });

    let kontakterUtenTlf = [];
    let kontakterMedTlf = [];
    bekontakter.forEach(bekont => {
      let kontakter = bekont.kontakt;
      kontakter.forEach(element => {
        if (element.ugyldigTelefonNr) {
          element.bruksenhetAdresse =  bekont.bruksenhetAdresse;
          element.id=element.id+bekont.bruksenhetAdresse;
          kontakterUtenTlf.push(element);
        } else {
          kontakterMedTlf.push(element);
          element.bruksenhetAdresse =  bekont.bruksenhetAdresse;
          element.id=element.id+bekont.bruksenhetAdresse;
        }
      });
    });
    
    this.setState({ kontakterUtenTlf: kontakterUtenTlf });
    this.setState({ kontakterMedTlf: kontakterMedTlf });
    this.setState({ isLoading: false });
  }
  
  eiereMedRowSelected = rows => {
    this.setState({ valgteEiereMed: rows });
  };

  eiereUtenRowSelected = rows => {
    this.setState({ valgteEiereUten: rows });
  };

  unntattChange = () => {
    let unntatt = !this.state.unntatt;
    this.setState({ unntatt: unntatt });
  };

  hjemmelChange = (val) => {
    this.setState({ hjemmel: val.target.value });
  };

  tilgangChange = (val) => {
    this.setState({ tilgang: val.target.value });
  };

  onOk = () => {
    this.setState({ isLoading: true });

    let med = this.state.valgteEiereMed;
    let uten = this.state.valgteEiereUten;
    let bk = this.state.bruksenhetKontakter;

    // Set alle sms til smsutelat
    bk.forEach(element => {
      element.kontakt.forEach(e => {
        if (!e.ugyldigTelefonNr) {
          e.smsUtelat = true;
        }
      });
    });
    // Finn de som er valg og sett at sms skal sendes
    med.forEach(element => {
      bk.forEach(b => {
        let kontakt = b.kontakt;
        kontakt.forEach(k => {
          console.log(k.id);
          console.log(element.id);
          if (k.id == element.id) {
            k.smsUtelat = false;
          }
        });
      });
    });
    uten.forEach(element => {
      bk.forEach(b => {
        let kontakt = b.kontakt;
        kontakt.forEach(k => {
          if (k.id == element.id) {
            if (this.state.svarUt) {
              k.svarUtValgt = true;
            }
          }
        });
      });
    });

    this.setState({ bruksenhetKontakter: bk });

    let skjerming = null;
    if (this.state.unntatt) {
      skjerming = {
        skjermingshjemmel: this.state.hjemmel,
        tilgangsrestriksjon: this.state.tilgang
      };
    }

    let avvikKlare = [];
    this.props.avvikListe.forEach(element => {
      //Extra check om publiserInnbyggerDialogId er noe (ifm. lukk og ingen tekst);
      if (element.publiserInnbyggerDialogId === 'new'){
        delete element.publiserInnbyggerDialogId;
      }
      if (element.publiserInnbyggerDialogKlar) {
        avvikKlare.push(element);
      }
    });
    const param = {
      bruksenhetId: this.props.bruksenhetId,
      skjerming: skjerming,
      bruksenhetKontakt: this.state.bruksenhetKontakter,
      avvikListe: avvikKlare
    };
    sendTilEierFraOppfPubliserBruksenhet(param, this.publisertOk);
  }

  publisertOk = (err, res) => {
    this.setState({ isLoading: false });
    this.props.onOk();
  }

  render() {
    let loaderStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
    };

    var eiereMedColumns = this.getEiereMedColumns();
    var eiereUtenColumns = this.getEiereUtenColumns();
    var eiereUtenColumnsOgSvarut = this.getEiereUtenColumnsOgSvarut();

    if (this.state.isLoading) {
      return <div><Spinner className='h4' /></div>;
    } else {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title">Send til eier</h4>
        </div>
        <div className="modal-body" style={{ padding: "0 20px" }}>
          <br/>
            {this.state.kontakterMedTlf != null && this.state.kontakterMedTlf.length > 0 ? 
            <div>
              Du sender nå en generell SMS om oppdatering av avvik til følgende eiere:
              <div style={{
              border: '1px solid black'
              
            }}>
                <ReactTable
                  showIndex={false}
                  items={this.state.kontakterMedTlf}
                  columns={eiereMedColumns}
                  selectableRows={true}
                  onRowClick={null}
                  onRowSelectionChange={this.eiereMedRowSelected}
                  toggleAll={true}
                  toggleAllDefault={true}
                  sortable={false}
                  multiSort={false}
                  filterable={false}
                />
              </div>
            </div>
            : null
            }
            <br/>
            {this.state.kontakterUtenTlf != null && this.state.kontakterUtenTlf.length > 0 ? 
            <div>

              {this.state.svarUt
                    ? 'Telefonnummer ble ikke funnet for følgende eiere. Velg om informasjon skal sendes til SvarUt.'
                    : 'Telefonnummer ble ikke funnet for følgende eiere.'}
              
              {this.state.svarUt ? 
              <div>
                <div style={{ border: '1px solid black' }}>
                <ReactTable
                  showIndex={false}
                  items={this.state.kontakterUtenTlf}
                  columns={eiereUtenColumns}
                  selectableRows={true}
                  onRowClick={null}
                  onRowSelectionChange={this.eiereUtenRowSelected}
                  toggleAll={true}
                  toggleAllDefault={true}
                  sortable={false}
                  multiSort={false}
                  filterable={false}
                />
              </div>
              </div> 
              :
              <div>
                <div style={{ border: '1px solid black' }}>
                <ReactTable
                  showIndex={false}
                  items={this.state.kontakterUtenTlf}
                  columns={eiereUtenColumnsOgSvarut}
                  selectableRows={false}
                  onRowClick={null}
                  onRowSelectionChange={this.eiereUtenRowSelected}
                  toggleAll={true}
                  toggleAllDefault={true}
                  sortable={false}
                  multiSort={false}
                  filterable={false}
                />
              </div>
              </div>
              }
              
            </div>
            : null
            }
          
          <hr/>
          {this.state.visSkjerming && this.state.arkivering ? (
            <div>
              <p>
                Innbygger dialogen arkiveres når avviket lukkes.
                Hvis det er ønskelig å unnta denne journalposten fra
                offentligheten, kryss av under.
              </p>
              <div
                className='row idiv margintop8'
                  style={{
                    display: 'inline-block',
                    marginRight: '30px' }}>
                  <div
                    className='form-group'
                      style={{ marginBottom: '4px' }}>
                      <label>
                        <input
                          className='checkbox30'
                          type='checkbox'
                          onChange={this.unntattChange}
                          onClick={this.unntattChange}
                          checked={this.state.unntatt}/>
                          <span className='checkboxtext18'>
                            Unntatt offentlighet
                          </span>
                       </label>
                  </div>
                </div>
                <p />
              </div>
            ) : null}

          
          {this.state.unntatt ? 
          <div>
            {this.state.skjermingSatt ? (
              <div>
                  <p>
                    Du har valgt å unnta journalposten fra offentlighet når
                    den arkiveres.
                  </p>
                    Skjermingshjemmel: {this.state.hjemmel}
                  <br />
                  Tilgangsrestriksjon: {this.state.tilgang}
              </div>
              ) : (
                <div>
                  <p>
                    Du har valgt å unnta journalposten fra offentlighet når
                    den arkiveres. For å kunne gjøre det, må det velges
                    Skjermingshjemmel og Tilgangsrestriksjon.
                  </p>
                  <p />
                  <div className='row idiv'>
                    <div className='form-group '>
                      <label>Skjermingshjemmel</label>
                      <select
                        className='form-control'
                        id='hjemmel'
                        onChange={this.hjemmelChange}>
                        {_.map(this.state.SkjermingsHjemmeler, function(
                            o,
                            i
                          ) {
                            return (
                              <option key={i} value={o.kodeverdi}>
                                {o.kodeverdi} - {o.kodebeskrivelse}
                              </option>
                            );
                        })}
                      </select>
                    </div>
                  </div>
                    <div className='row idiv'>
                      <div className='form-group '>
                        <label>Tilgangsrestriksjon</label>
                        <select
                          className='form-control'
                          id='tilgang'
                          onChange={this.tilgangChange}>
                          {_.map(this.state.Tilgangsrestriksjoner, function(
                            o,
                            i
                          ) {
                            return (
                              <option key={i} value={o.kodeverdi}>
                                {o.kodeverdi} - {o.kodebeskrivelse}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <br/>
          </div> 
          : null }
          <div className="modal-footer">
              <div>
                <button
                  onClick={this.onOk}
                  className="btn btn-success pull-right btnBottomSend">
                  OK
                </button>
              </div>
          </div>
      </div>
      </div>
    );          
  }}

  getEiereMedColumns = () => {
    return [  
      {
        id: 'bruksenhetAdresse',
        name: 'Bruksenhet',
        isSorted: false,
        sortable: false,
      }, 
      {
        id: 'adresse',
        name: 'Send til',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'navn',
        name: 'Eier',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'telefon',
        name: 'Telefonnummer',
        isSorted: false,
        sortable: false,
      }
    ];
  };

  getEiereUtenColumns = () => {
    return [   
      {
        id: 'adresse',
        name: 'Send til Svarut',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'navn',
        name: 'Eier',
        isSorted: false,
        sortable: false,
      }
    ];
  };
  getEiereUtenColumnsOgSvarut = () => {
    return [   
      {
        id: 'adresse',
        name: 'Adresse',
        isSorted: false,
        sortable: false,
      },
      {
        id: 'navn',
        name: 'Eier',
        isSorted: false,
        sortable: false,
      }
    ];
  };

}

export default SendAvvikTilEier;
