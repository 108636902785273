import React from 'react';

const alertClosable = props => { 
  const { message, header, onClose } = props;
  if (!message){ return null;}
  const title = header && <strong>{header}.</strong>;
  return (
    <div className="alert alert-danger alert-dismissible fade in">
      {title} {message}
      <a href="#" onClick={onClose} className="close" data-dismiss="alert" aria-label="close">
        &times;
      </a>
    </div>
  );
};

export default alertClosable;