import React, { Component } from 'react';
import _ from 'lodash';
import L from 'leaflet';
import { Map } from 'react-leaflet';
import 'leaflet.pm';
import 'leaflet.pm/dist/leaflet.pm.css';
import 'components/Dialogs/NKFullModal.css';
import './KartEnheter.css';
import 'leaflet.fullscreen';
import $ from 'jquery';
import 'leaflet-minimap';
import './spretter2.js';
import * as getIcon from './geticon.js';
import 'leaflet-minimap/src/Control.MiniMap.css';
import ZoomControl from 'components/Kart/Controls/ZoomControl.jsx';
import FullscreenControl from 'components/Kart/Controls/FullscreenControl.jsx';
import LocateControl from 'components/Kart/Controls/LocateControl.jsx';
import PolygonControl from 'components/Kart/Controls/PolygonControl.jsx';
import LayerControl from 'components/Kart/Controls/LayerControl.jsx';
import OppmaalingControl from 'components/Kart/Controls/OppmaalingControl.jsx';
import {
/*   getContextInnstillingerKartNew, */
  contextInnstillingerKart,
} from 'context/contextInnstillinger.js';
import { debugFactory } from 'utils';
import PlanClusterLayerControl from 'components/Kart/Controls/PlanClusterLayerControl.jsx';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
delete L.Icon.Default.prototype._getIconUrl;

const debug = debugFactory('nk:objekter:enhetersoek');

var basisKart = null;
let spretter; //Ikke fjern!
let spretterLayer;

class SoekEnheterKart extends Component {
  state = {
    isMounted: false,
    zoomLevel: 13,
    lat: 63.9905722,
    lng: 12.3077975,
    points: [],
    enheter: [],
    polygonLayerInUse: null,
    brukPanorer: false,
    visGebyr: false,
    visRisiko: 'Ingen',
    visRisikoInfoPanel: false,
  };

  componentDidMount = () => {
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
    this.initierKart();
    this.initierKartEvents();
    this.setState({ isMounted: true });
    this.setState({ enheter: this.props.enheter });
    /*   setTimeout(() => {
      debug('ZAX 0', contextInnstillingerKart);
      if (this.props.visning === 'wmsoversikt') {
        getContextInnstillingerKartNew(this.getSenterPunkt);
      };
  }, 3000); */
  };

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.state.isMounted) {
      if (
        !_.isEqual(_.sortBy(nextProps.enheter), _.sortBy(this.state.enheter))
      ) {
        this.setState({ enheter: nextProps.enheter });
      }
    }
  };

  componentWillUnmount = () => {
    this.setState({ isMounted: false });
    basisKart = null;
  };

  getSenterPunkt = (data) => {
    debug('ZAX', data);
    this.setState({
      lat: data.latitude,
      lng: data.longitude,
      zoomLevel: data.zoomLevel,
    });
    let viewPosition = [data.latitude, data.longitude];
    basisKart.setView(viewPosition, data.zoomLevel);
  };

  initierKart = () => {
    basisKart = this.refs.basiskart.leafletElement;
    debug('RASTA 2', this.basisKart);

    $('.leaflet-control-zoom-fullscreen').hide();

    //Scale
    L.control
      .scale({ position: 'bottomleft', metric: true, imperial: false })
      .addTo(basisKart);
    //Attribution
    basisKart.attributionControl.setPrefix(false);
    basisKart.attributionControl.addAttribution('Norkart Brannforebygging');
    //Layer for spretter
    spretterLayer = new L.FeatureGroup();
    basisKart.addLayer(spretterLayer);
  };

  initierKartEvents = () => {
    basisKart.on('zoomend', () => {
      if (this.state.isMounted) {
        this.setState({ zoomLevel: basisKart.getZoom() });
      }
    });
    basisKart.on('click', (e) => {
      if (this.layerControl) {
        this.layerControl.gotClick(e);
      }
    });
  };

  sprett = (lok) => {
    spretterLayer.clearLayers();
    /* var sprettIcon = new L.divIcon({
      iconSize: new L.Point(20, 20),
      className: 'spretter',
      html: ''
    }); */
    if (lok.lokasjon === undefined || lok.lokasjon.lat === undefined) {
      return;
    }
    basisKart.panTo(new L.LatLng(lok.lokasjon.lat, lok.lokasjon.lon));
    //Vis spretter
    let zIcon = getIcon.sprettIcon;
    spretter = L.marker([lok.lokasjon.lat, lok.lokasjon.lon], {
      icon: zIcon,
      bounceOnAdd: true,
      bounceOnAddOptions: { duration: 1000, height: 200 },
    })
      .on('click', function() {
        spretterLayer.clearLayers();
      })
      .bindTooltip('Klikk for å fjerne')
      .addTo(spretterLayer);

    setTimeout(() => {
      spretterLayer.clearLayers();
    }, 3000);
  };

  setPolygonInUse = (layer) => {
    let poly = null;
    if (layer != null) {
      poly = layer;
    }
    this.setState({ polygonLayerInUse: poly });
  };

  goFit = (bounds) => {
    if (this.state.brukPanorer) {
      return;
    }
    if (this.state.polygonLayerInUse != null) {
      basisKart.fitBounds(this.state.polygonLayerInUse.getBounds(), {
        padding: [10, 10],
      });
    } else {
      basisKart.fitBounds(bounds, { padding: [10, 10] });
    }
  };

  fjernAllePolygonLayers = () => {
    this.setState({ polygonLayerInUse: null });
    this.refPolygonControl.fjernAllePolygonLayers();
  };

  setBrukPanorer = (bruk) => {
    this.setState({ brukPanorer: bruk });
  };

  getEnhetTooltip = (data) => {
    if (data === undefined) {
      return ' ';
    } else {
      let adresse = '';
      if (data.adresse !== undefined) {
        let gate =
          data.adresse.gatenavn === undefined
            ? ''
            : data.adresse.gatenavn + ' ';
        let nummer =
          data.adresse.husnummer === undefined
            ? ''
            : data.adresse.husnummer + ' ';
        let bokstav =
          data.adresse.bokstav === undefined ? '' : data.adresse.bokstav;
        adresse = gate + nummer + bokstav;
      }
      let eiendom = '';
      if (data.matrikkelenhet !== undefined) {
        eiendom =
          data.matrikkelenhet.gardsnummer +
          '/' +
          data.matrikkelenhet.bruksnummer +
          '/' +
          data.matrikkelenhet.festenummer +
          '/' +
          data.matrikkelenhet.seksjonsnummer;
      }
      let location = '';
      if (data.bygg !== undefined) {
        let lat = data.bygg.lokasjon.lat.toString().substring(0, 7);
        let lon = data.bygg.lokasjon.lon.toString().substring(0, 7);
        location = 'Lat: ' + lat + ' Long: ' + lon;
      }
      let risiko = '?';
      if (data.skybrannData !== undefined) {
        if (data.skybrannData.bruksenhet !== undefined) {
          risiko = data.skybrannData.bruksenhet.risikovurdering;
        }
      }
      if (data.risikovurdering !== undefined) {
        risiko = data.risikovurdering;
      }

      var ts =
        adresse + '\n' + eiendom + '\n' + location + '\nRisikoverdi: ' + risiko;
      return ts;
    }
  };

  goInvalidataSize = () => {
    debug('aa', 'aa');
  };

  onClickedGj = (s, e) => {
    //ifm wms
    if (this.layerControl) {
      this.layerControl.gotClick(e);
    }
  };

  onVisGebyr = (item) => {
    debug('visgebyr 1', item);
    this.props.onVisGebyr(item);
  };

  visRisikoFraControl = (mode) => {
    let enheter = this.state.enheter;
    this.setState({ enheter: [] });
    this.setState({ visRisiko: mode });
    setTimeout(() => {
      this.setState({ enheter });
    }, 1000);
  };

  render() {
    /* const position = [this.state.lat, this.state.lng]; */
    const position = [
      contextInnstillingerKart._currentValue.latitude,
      contextInnstillingerKart._currentValue.longitude,
    ];
    let map = (
      <Map
        style={{ height: 'calc(100vh - 245px)', width: '100%' }}
        center={position}
        maxZoom={20}
        zoom={contextInnstillingerKart._currentValue.zoomLevel}
        zoomControl={false}
        fullscreenControl={true}
        ref='basiskart'>
        <LayerControl
          ref={(ref) => (this.layerControl = ref)}
          kart={basisKart}
          kartfra={this.props.kartfra}
          modus={this.state.visning}
          visning={this.props.visning}
          visRisikoFraControl={this.visRisikoFraControl}
          visRisiko={this.state.visRisiko}
          visRisikoInfoPanel={this.state.visRisikoInfoPanel}
          /*    visWms={true}
          setWmsListefraControl = {this.setWmsListefraControl} */
        />
        <FullscreenControl />
        <LocateControl kart={basisKart} />
        {this.props.visning !== 'wmsoversikt' && (
          <PlanClusterLayerControl
            objekter={this.state.enheter}
            valgte={[]}
            kart={basisKart}
            kartfra={this.props.kartfra}
            visRisiko={this.state.visRisiko}
            goFit={this.goFit}
            modus='Enheter'
            parent='SoekEnheter'
            visMatrikkelInfo={this.props.visMatrikkelInfo}
            visSituasjonInfo={this.props.visSituasjonInfo}
            visArbeidslisteInfo={this.props.visArbeidslisteInfo}
            visDetaljerMobil={this.props.visDetaljerMobil}
            visGebyr={this.state.visGebyr}
            onClickedGj={this.onClickedGj}
            onVisGebyr={this.onVisGebyr}
            onVisRisikoInfo={this.onVisRisikoInfo}
          />
        )}
        {this.props.visning !== 'wmsoversikt' && (
          <PolygonControl
            ref={(ref) => (this.refPolygonControl = ref)}
            zoomLevel={this.state.zoomLevel}
            onBrukPolygon={this.props.onBrukPolygon}
            onPolygonFjernet={this.props.onPolygonFjernet}
            setPolygonInUse={this.setPolygonInUse}
            setBrukPanorer={this.setBrukPanorer}
            kart={basisKart}
          />
        )}
        <ZoomControl
          left={true}
          zoom={this.state.zoomLevel}
          /*   showZoom={this.props.visning === 'wmsoversikt'} */
          kart={basisKart}
        />

        {this.props.visning !== 'wmsoversikt' && <OppmaalingControl />}
      </Map>
    );

    return <div style={{ height: '100%', width: '100%' }}>{map}</div>;
  }
}

export default SoekEnheterKart;
