import React from 'react';
import * as _ from 'lodash';
import { debugFactory } from 'utils';
import { getMaler } from 'api/malerApi';
import './maleditor.css';

const debug = debugFactory('nk:DokEditor');

class FlettefeltDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    //debug('Fletters: ', this.props);
  };

  flettefeltValgt = (val) => {
    this.props.onInsertFlettefelt(val);
  };

  render() {
    let x = 0;
    return (
      <div style={{ height: '400px' }} className='modal-content'>
        <div className='modal-header'>
          <button className='close' type='button' onClick={this.props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'> Velg flettefelt </h4>
        </div>
        <div className='modal-body'>
          {this.props.flettefelt && (
            <div
              className='list-group'
              style={{ maxHeight: '320px', overflow: 'auto' }}>
              {_.map(this.props.flettefelt, function(felt, i) {
                var val = felt.flettefelt;
                return (
                  <button
                    key={i}
                    type='button'
                    onClick= { this.flettefeltValgt.bind(this, val) }
                    className='list-group-item list-group-item-action'>
                    {felt.navn}
                  </button>
                );
              }.bind(this))}
            </div>
          )}
          {!this.props.flettefelt && (
            <div style={{ margin: '4px' }}>
              Ingen flettefelt tilgjengelig for valgt funksjon. Sjekk funksjon.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FlettefeltDialog;
