import EnhetApp from 'modules/Gjennomforing/Enhet/EnhetApp.jsx';
import 'modules/Gjennomforing/gjennomforing.css';
import React from 'react';
import _ from 'underscore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { debugFactory } from 'utils';

const debug = debugFactory('nk:Enhetdialog');

class EnhetModal extends React.Component {
  constructor() {
    super();
    this.state = {
      alleTiltakstyper: [],
      visLeggTilTiltak: false,
      bruksenheterOgRoyklop: [],
    };
  }

  componentDidMount = () => {
    console.log('XXX Enhetmodal');
  };

  render() {
    if (!this.props.isOpen) {
      return <div></div>;
    }

    return (
      <NKModal size='large-wide' isOpen={this.props.isOpen}>
        <div className='modal-content'>
          <div className='modal-header modal-header-info'>
            <button
              className='close'
              onClick={this.props.onClose}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'>Situasjon</h4>
          </div>
          <div className='modal-body'>
            <EnhetApp
              enhetid={this.props.itemId}
              arbListeId={this.props.arbeidslisteId}
              arbeidslisteId={this.props.arbeidslisteId}
              adresse={this.props.itemAdresse}
              bruksenhetIdMa={this.props.bruksenhetIdMa}
              modal={true}
              eiendom={this.props.eiendom}
              matrikkelenhetId={this.props.matrikkelenhetId}
              fraFiktive= {this.props.fraFiktive}
            />
          </div>
          <div className='modal-footer'>
            <button
              style={{ margin: '2px', width: '80px', height: '40px' }}
              className='btn btn-primary pull-right'
              onClick={this.props.onClose}>
              Lukk
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}
export default EnhetModal;
