import React, { Component } from 'react';
import { pdfGenTest } from 'api/arkivApi';

// import VisBrukere from './VisBrukere.jsx';
// import VisSakskategori from './VisSakskategori.jsx';
var _ = require('underscore');
var obj;
let style1 = {
  height: '1px'
};
class PdfTest extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      html: ''
    };
  }

  componentDidMount() {
    // this.setState({ laster: true });
    // getAlleArkivInnstillinger('123', (err, res) => {
    //   console.log(res);
    //   this.setState({ kunder: res, laster: false });
    //   //this.hentData();
    // });
  }

  submit() {
    var DocumentHtml = {
      html: this.state.html 
    };
    console.log(DocumentHtml);
    pdfGenTest(DocumentHtml, this.svar);
  }
  svar = (err, svar) => {
    //console.log(svar);
    obj = document.createElement('object');
    obj.style.width = '100%';
    obj.style.height = '842pt';
    obj.type = 'application/pdf';
    obj.data = 'data:application/pdf;base64,' + svar.tekst;
    //document.body.appendChild(obj);
    document.getElementById('myDIV').appendChild(obj);
  }
  handleHtmlChange = (val) => {
    this.setState({ html: val.target.value });
  }
  render() {
    // if (this.state.laster) {
    //   return <div className="loader" />;
    // }
    return (
      <div style={{ margin: '30px' }}>
        <h1>Html til pdf generator test</h1>
        <hr/>
        <p>Tester konvertering av oppgitt html til pdf dokument ved hjelp av WAAPI Rapportgenerator</p>
        <p/>
        <div className='row'>
            <div className='col-sm-6'>
              <div className='form-group'>
                <label>Html</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Html string'
                  onChange={this.handleHtmlChange}
                />
              </div>
            </div>
          </div>
        <div id='myDIV'></div>
          
       
        
      </div>
    );
  }
}

export default PdfTest;
