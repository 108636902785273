import React from 'react';
import * as _ from 'lodash';
import ContextMenu from 'components/ContextMenu';
import ReactModal from 'react-modal';
import KartDialog from 'components/Kart/KartDialog.jsx';
import NKIcon from 'nka-icons';
import { PulseLoader } from 'halogenium';
import ReactTable from '@norkart/react-table';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import DeltePiperButton from 'modules/felles/DeltePiperButton.jsx';
import ModalDialogTable from 'modules/felles/avvikAnmerkninger/ModalDialogTable.jsx';
import Red3 from 'images/48/red3kant.png';
import Organisasjon from 'images/48/organisasjon.png';
import RedHvit from 'images/48/redhvitsirkle.png';
import GreenS from 'images/48/greensirkle.png';
import YellowS from 'images/48/yellowsirkle.png';
import SvarUtLogg from 'modules/felles/svarut/SvarUtLogg.jsx';
import {
  contextInnstillingerPerson,
  contextInnstillingerSvarut,
  getOrgSvarUt
} from 'context/contextInnstillinger.js';
import StatusDigitalButton from './StatusDigitalButton.jsx';
import green from 'images/Annet/legendaBlokGreen.png';
import orange from 'images/Annet/legendaBlokOrange.png';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose}
      itemId={dialogData.itemId}
      bruksenhetIdMa={dialogData.bruksenhetIdMa}
      itemAdresse={dialogData.itemAdresse}
      arbeidslisteId={dialogData.arbeidslisteId}
      eiendom={dialogData.eiendom}
      matrikkelenhetId={dialogData.matrikkelenhetId}
      fraFiktive={false}
    />
  ),
  ModalDialogTable: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen}>
      <ModalDialogTable
        onClose={onClose}
        adresse={dialogData.adresse}
        id={dialogData.id}
      />
    </NKModal>
  ),
  ModalDialogKart: ({ isOpen, onClose, dialogData, owner }) => (
    <ReactModal contentLabel='' isOpen={isOpen} className='FModal'>
      <KartDialog
        itemid={dialogData.itemid}
        arbeidsliste={owner.props.arbeidsliste}
        visning='planlegging-enhet'
        tittel={dialogData.tittel}
        onClose={onClose}
      />
    </ReactModal>
  ),
  Matrikkelinfo: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size='medium'
        buttonText={'Lukk'}
        tittel='Matrikkelinfo'
        onClose={onClose}>
        <Matrikkelinfo id={dialogData.id} />
      </ModalContainer>
    );
  },
  SvarUtOppgaver: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <ModalContainer
        isOpen={isOpen}
        size='xl'
        buttonText={'Lukk'}
        tittel='SvarUt logg'
        onClose={onClose}>
        <SvarUtLogg
          id={dialogData.id}
          alle={false}
          fra={'fraBruksenhetTabell'}
          adresse={dialogData.adresse}
        />
      </ModalContainer>
    );
  },
};

class BruksenheterTabell extends React.Component {
  state = {
    ider: null,
    enheter: [],
    svarskriv: 'Varsles via SkrivUt.',
    norkartAdminBruker: false,
    svarUt: false
  };

  constructor() {
    super();
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
  }

  componentDidMount = () => {
    //For å unngå en warning:
    //Warning: react-modal: App element is not defined.
    ReactModal.setAppElement('body');

    getOrgSvarUt(this.gotInfoOmSvarUt);

    this.setState({
      norkartAdminBruker: contextInnstillingerPerson._currentValue.norkartAdmin,
    });
  };

  gotInfoOmSvarUt = (res) => {
    if (res.data === true) {
      this.setState({ svarskriv: 'Varsles via SvarUt' });
      this.setState({ svarUt: true });
    }
  };

  testContext = () => {
    let norkartBruker = contextInnstillingerPerson._currentValue.norkartAdmin;

    if (!norkartBruker) {
      return '';
    } else {
      return (
        <ContextMenu.Item onClick={this.props.testContext}>
          <span style={{ color: 'red', opacity: '1' }}>Test Context</span>
        </ContextMenu.Item>
      );
    }
  };

  sokeVerdiChanged = (e) => {
    this.setState({ sokeVerdi: e.target.value });
  };

  onClickEndreTiltak = (item) => {
    console.log(item);
    if (item.oppgaver === 'Ferdig') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Endre tiltak',
        melding:
          'Alle tiltak er ferdige på denne bruksenheten og tiltak kan derfor ikke endres.',
      });
    } else {
      this.props.endreTiltak(item);
    }
  };

  onClickVarsle = (item) => {
    var itemArray = [item];
    this.props.varsleEnhet(itemArray);
  };

  onClickVisMer = (item) => {
    this.props.visMer(item);
  };

  openKart = (item, e) => {
    this.dialogs.setOpenDialog('ModalDialogKart', {
      tittel: this.props.arbeidsliste.navn + ': ' + item.adresse,
      itemid: item.id,
      data: [],
    });
  };

  onClickInfo = (item) => {
    this.props.besokMerknad(item);
  };

  onClickMatrikkel = (item) => {
    this.dialogs.setOpenDialog('Matrikkelinfo', {
      id: item.bruksenhetIdMa,
    });
  };

  onClickSvarUt = (item) => {
    this.dialogs.setOpenDialog('SvarUtOppgaver', {
      id: item.id,
      adresse: item.adresse,
    });
  };

  onClickSituasjon = (item) => {
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemId: item.id,
      bruksenhetIdMa: item.bruksenhetIdMa,
      itemAdresse: item.adresse,
      arbeidslisteId: this.props.arbeidsliste.id,
      eiendom: item.eiendom,
      matrikkelenhetId: item.matrikkelenhetId,
    });
  };

  onClickInnbyggerDialog = (item) => {
    this.dialogs.setOpenDialog('ModalDialogTable', {
      id: item.id,
      adresse: item.adresse,
    });
  };

  onClickMarkerVarsletNy = (item) => {
    this.props.markerVarsletNy(item);
  };

  flyttFjernTiltakIkkeMulig = () => {
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Flytt enhet',
      melding:
        'En eller flere planlagte tiltak er påstartet på denne bruksenheten og den kan derfor ikke flyttes/slettes fra arbeidslisten.',
    });
  };

  visFlytt = (item) => {
    /*  Flytt til arbeidsliste... vises kun dersom det fortsatt er noe å gjøre.. */
    if (item.oppgaver === 'IkkeStartet') {
      return (
        <ContextMenu.Item onClick={this.props.flyttEnhet.bind(this, item)}>
          {' '}
          {/* New */}
          Flytt til annen liste...
        </ContextMenu.Item>
      );
    }
    if (item.oppgaver === 'UnderArbeid') {
      return (
        <ContextMenu.Item onClick={this.props.flyttEnhet.bind(this, item)}>
          {' '}
          {/* New */}
          Flytt til annen liste...
        </ContextMenu.Item>
      );
    }
    if (item.oppgaver === 'Ferdig') {
      return;
    }
    return '';
  };

  visFjern = (item) => {
    /*  Flytt til arbeidsliste... vises kun dersom det fortsatt er noe å gjøre.. */
    if (item.oppgaver === 'IkkeStartet') {
      return (
        <ContextMenu.Item onClick={this.props.fjernEnhet.bind(this, item)}>
          Fjern enhet fra liste...
        </ContextMenu.Item>
      );
    }
    if (item.oppgaver === 'UnderArbeid') {
      return (
        <ContextMenu.Item onClick={this.props.fjernEnhet.bind(this, item)}>
          Fjern enhet fra liste...
        </ContextMenu.Item>
      );
    }
    if (item.oppgaver === 'Ferdig') {
      return '';
    }
    return '';
  };

  visSvarUt = (item) => {
    console.log('CONTEXT_X pokker', this.state.svarUt);
    if (!this.state.svarUt) {
      return;
    }

    let enhet = _.cloneDeep(item);
    let visSvarUt = false;
    // 4: ok 9: Person ikke funnet

    if (
      enhet.varselstatusId === 0 ||
      enhet.varselstatusId === 1 || //Blank
      //    enhet.varselstatusId == 2 ||  //varsel sendt
      enhet.varselstatusId === 5 || //OK-Manuell
      enhet.varselstatusId === 6 || //Mobilnr ikke funnet
      enhet.varselstatusId === 7 || //Ny avtale
      enhet.varselstatusId === 8 || //Ugyldig svar
      //    enhet.varselstatusId == 10 ||  //svarUt sender
      enhet.varselstatusId === 12 || //Varsel kansellert
      enhet.varselstatusId === 13 || //Mobilnr Ugyldig
      enhet.varselstatusId === 15 || //Svarut sendt
      enhet.varselstatusId === 16 //svarUt feilet
    ) {
      visSvarUt = true;
    }

    let res = '';
    if (visSvarUt) {
      res = (
        <ContextMenu.Item onClick={this.props.skrivSvarUt.bind(this, enhet)}>
          <span style={{ opacity: '1' }}>SvarUt</span>
        </ContextMenu.Item>
      );
    }
    return res;
  };

  visSvarutSkrivut = (item) => {
    let enhet = _.cloneDeep(item);

    return (
      <ContextMenu.Item onClick={this.props.pdfExport.bind(this, enhet)}>
        <span style={{ opacity: '1' }}>Skriv ut varsel</span>
      </ContextMenu.Item>
    );
  };

  visSvarUtLogg = (item) => {
   console.log('CONTEXT_X bb', contextInnstillingerSvarut._currentValue);
    if (!this.state.svarUt) {
      return;
    } else {
      if (
        contextInnstillingerPerson._currentValue.organisasjonAdmin ||
        contextInnstillingerPerson._currentValue.norkartAdmin
      ) {
        return (
          <ContextMenu.Item onClick={this.onClickSvarUt.bind(this, item)}>
            <span style={{ opacity: '1' }}>SvarUt logg</span>
          </ContextMenu.Item>
        );
      }
    }
  };

  onClickSlett = (item) => {
    this.props.slettEnhet(item);
  };

  getEnheter() {
    return this.props.enheter;
  }

  getColumns = (enheter, that) => {
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'adresse',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          return item.bruksenhetIdDelerLop &&
            item.bruksenhetIdDelerLop !== null ? (
            <div style={{ maxWidth: '300px' }}>
              <DeltePiperButton
                fraOppfolging={false}
                item={item}
                enheter={enheter}
              />
            </div>
          ) : (
            <div style={{ maxWidth: '300px' }}>{item.adresse}</div>
          );
        },
      },
      {
        id: 'digital',
        name: (
          <span>
            <NKIcon icon='lenke' size='0.8' color='nk-black' />
          </span>
        ),
        sortParams: 'digstatus',
        sortable: true,
        isSorted: false,
        sortDirection: 'asc',
        formatter: function(item) {
          if (item.extra) {
            return (
              <div style={{ maxWidth: '30px' }}>
                <StatusDigitalButton
                  item={item}
                  svarskriv={that.state.svarskriv}
                />
              </div>
            );
          }
        },
      },
      {
        id: 'eiendom',
        name: 'Eiendom',
        sortParams: 'eiendom',
        isSorted: false,
        sortDirection: 'asc',
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.tiltakBruksenhet === null && item.tiltakRoyklop === null) {
            return '';
          }
          if (item.tiltakRoyklop === null) {
            return item.tiltakBruksenhet;
          }
          if (item.tiltakBruksenhet === null) {
            return item.tiltakRoyklop;
          } else {
            return item.tiltakBruksenhet + ',' + item.tiltakRoyklop;
          }
        },
      },
      {
        id: 'status',
        name: 'Gj.ført',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: true,
      },
      {
        id: 'rekkefolgeNummer',
        name: 'Nr.',
        sortParams: 'rekkefolgeNummer',
        isSorted: true,
        sortable: true
      },

      /*   (that.state.norkartAdminBruker) && {
        id: 'rekkefolgeNummer',
        name: 'Nr.',
        sortParams: 'rekkefolgeNummer',
        isSorted: false,
        sortable: that.state.norkartAdminBruker,  // !!!!!!!!!!!!!!!!
      },
 */
      {
        id: 'datoTid',
        name: 'Planlagt',
        sortParams: 'planlagtDato',
        filterable: false,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          let f = item.planlagtBesokFra;
          let t = item.planlagtBesokTil;
          let ss = f + t;
          let hint = '';
          if (ss === '') {
            hint = 'Ingen klokkeslett';
          } else {
            hint = 'Kl. ' + f + ' - ' + t;
          }
          return (
            <span className='malspan' aria-label={hint}>
              {item.datoTid}
            </span>
          );
        },
      },
      {
        id: 'varselstatus',
        name: 'Varselstatus',
        sortParams: 'varselstatus',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'purringAntallganger',
        name: 'Purret',
        sortParams: 'purringAntallganger',
        filterable: false,
        isSorted: false,
        sortable: true,
      },
      {
        id: 'merknad',
        name: 'Meld.',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.merknadBesok && item.merknadBesok !== ' ' ? (
            <div style={{ maxWidth: '6px' }}>
              <NKIcon
                className='pull-left'
                icon='kommentar'
                color='nk-black'
                size='0.8'
              />
            </div>
          ) : (
            <div style={{ maxWidth: '6px' }} />
          );
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button title='Meny' icon='meny' iconColor='nk-black'>
              <ContextMenu.Item
                onClick={this.onClickEndreTiltak.bind(this, item)}>
                Endre tiltak...
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={this.onClickMarkerVarsletNy.bind(this, item)}>
                Marker som varslet
              </ContextMenu.Item>
              {this.visSvarUt(item)}
              {this.visSvarutSkrivut(item)}
              {/* Flytt til / Fjern arbeidsliste... vises kun dersom det fortsatt er noe å gjøre.. */}
              {this.visFlytt(item)}
              {this.visFjern(item)}
              <ContextMenu.Item onClick={this.onClickInfo.bind(this, item)}>
                Melding til feier...
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={this.onClickMatrikkel.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={this.onClickSituasjon.bind(this, item)}>
                Vis situasjon
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={this.onClickInnbyggerDialog.bind(this, item)}>
                Vis innbyggerdialog
              </ContextMenu.Item>
              {this.visSvarUtLogg(item)}
              <ContextMenu.Divider />
              <ContextMenu.Item onClick={this.openKart.bind(this, item)}>
                Vis i kart
              </ContextMenu.Item>
              {this.testContext()}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  rowFormat = (item) => {
    if (item.oppgaver === 'Ferdig') {
      if (item.antallBruksenhetSendEier > 0) {
        return { style: { backgroundColor: '#dcedc0' } };
      } else {
        return { style: { backgroundColor: '#f5d9a6' } };
      }
      
    }
  };

  rowSelected = (rows) => {
    this.props.enheterValgt(rows);
  };

  makeBrok = (item) => {
    var index1 = item.tiltakBrukenhetStatus.indexOf('/');
    //var index2 = item.tiltakRoyklopStatus.indexOf('/');
    var utf =
      parseInt(item.tiltakBrukenhetStatus.substring(0, index1)) +
      item.antallLopPaaAlisteBesokt;
    //parseInt(item.tiltakRoyklopStatus.substring(0, index2), 10);
    var til =
      parseInt(
        item.tiltakBrukenhetStatus.substring(
          index1 + 1,
          item.tiltakBrukenhetStatus.length
        )
      ) + item.antallLopPaaAliste;

    if (utf === 0) {
      item.oppgaver = 'IkkeStartet';
    } else {
      if (utf > 0 && utf === til) {
        item.oppgaver = 'Ferdig';
      } else {
        item.oppgaver = 'UnderArbeid';
      }
    }
    item.status = utf + '/' + til;

    return item;
  };

  makeItems = () => {
    let items = [];
    for (let i = 0; i < this.props.enheter.length; i++) {
      let item = this.props.enheter[i];
      if (item.planlagtDato) {
        var dato = new Date(item.planlagtDato);
        var dag = dato.getDate().toString();
        var mnd = (dato.getMonth() + 1).toString();
        var aar = dato.getFullYear().toString();
        var fra = item.planlagtBesokFra;
        if (fra) {
          fra = ' (' + item.planlagtBesokFra + ')';
        } else {
          fra = '';
        }

        if (dag.length === 1) {
          dag = '0' + dag;
        }
        if (mnd.length === 1) {
          mnd = '0' + mnd;
        }
        item.datoTid = dag + '.' + mnd + '.' + aar + fra;
      } else {
        item.datoTid = '';
      }

      item = this.makeBrok(item);

      items.push(item);
    }
    return items;
  };

  render() {
    /*    if (!this.props.rettigheter) {
      return <label>Vent..</label>;
    } */

    var itemsx = this.makeItems();
    var columns = this.getColumns(itemsx, this);
    var items = _.sortBy(itemsx, 'adresse');
    items = _.sortBy(items, 'adresse');
    if (this.props.laderStatus) {
      return (
        <div
          style={{
            marginLeft: '60px',
            marginTop: '60px',
            textAlign: 'center',
            width: '500px',
            fontSize: '16px',
          }}>
          <PulseLoader color='#26A65B' size='12px' margin='4px' />
          <br />
          <label>Vent.. henter opplysninger om digital status.</label>
        </div>
      );
    }
    return (
      <div>
        <ReactTable
          showIndex={false}
          items={items}
          columns={columns}
          selectableRows={true}
          selectAllLabel='Alle'
          onRowClick={null}
          rowFormat={this.rowFormat}
          onRowSelectionChange={this.rowSelected}
          noDataText='Dette er en tom arbeidsliste. Klikk på "Legg til objekter".'
        />

        {/*    {contextInnstillingerPerson._currentValue.norkartAdmin && ( */}
        <div>
          <span style={{ width: '300px' }}>
            <span /> <img height='20' width='20' src={GreenS} alt='' title='' />
            <span style={{ marginLeft: '6px', marginRight: '12px' }}>
              Varsles via SMS.
            </span>
          </span>
          <span style={{ width: '300px' }}>
            <span />{' '}
            <img height='20' width='20' src={YellowS} alt='' title='' />
            <span style={{ marginLeft: '6px', marginRight: '12px' }}>
              Varsles på ulik måte.
            </span>
          </span>

          <span style={{ width: '300px' }}>
            <span /> <img height='20' width='20' src={Red3} alt='' title='' />
            <span style={{ marginLeft: '6px', marginRight: '12px' }}>
              {this.state.svarskriv}
            </span>
          </span>

          <span style={{ width: '300px' }}>
            <span />{' '}
            <img height='20' width='20' src={Organisasjon} alt='' title='' />
            <span style={{ marginLeft: '6px', marginRight: '12px' }}>
              Organisasjon.
            </span>
          </span>
          <span style={{ width: '300px' }}>
            <span />{' '}
            <img height='20' width='20' src={RedHvit} alt='' title='' />
            <span style={{ marginLeft: '6px', marginRight: '12px' }}>
              Opplysninger mangler.
            </span>
          </span>
        </div>
        {/*     )} */}
        <hr/>
        <div><span><img height= '30px' src={orange} alt="" /></span><span style={{ marginLeft: '8px' }}>Oppgavene er gjennomført</span></div>
        <div style={{ marginTop: '4px' }}><span><img height= '30px' src={green} alt="" /></span><span style={{ marginLeft: '8px' }}>Rapporten har blitt publisert</span></div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default BruksenheterTabell;
