
export function getMatrikkelnummer(item) {
  if (item.matrikkelenhet !== undefined) {
    return (
      item.matrikkelenhet.gardsnummer +
      '/' +
      item.matrikkelenhet.bruksnummer +
      '/' +
      item.matrikkelenhet.festenummer +
      '/' +
      item.matrikkelenhet.seksjonsnummer
    );
  } else {
    if (item.id === undefined) {
      return 'fiktiv';
    }
    return 'Id ' + item.id;
  }
}

function getSeksjonsNrFraAdresseId(item) {
  if (item.adresse && item.adresse.id) {
    let id = item.adresse.id;
    if (id.length > 17) {
      let s = id.substring(17);
      s = s.replace(/^0+|0+$/g, '');
      if (s === '' || s === null) {
        s = '0';
      }
      return s;
    }
  }
  return '0';
}

export function getMatrikkelnummerNy(item) {
  if (item.matrikkelenhet !== undefined) {
    return (
      item.matrikkelenhet.gardsnummer +
      '/' +
      item.matrikkelenhet.bruksnummer +
      '/' +
      item.matrikkelenhet.festenummer +
      '/' +
      //item.matrikkelenhet.seksjonsnummer
      getSeksjonsNrFraAdresseId(item)
    );
  } else {
    if (item.id === undefined) {
      return 'fiktiv';
    }
    return 'Id ' + item.id;
  }
}

export function getAdresse(item) {
  if (!item.adresse || item.adresse.type === 'Matrikkeladresse') {
    let mAdresse =
      getMatrikkelnummerNy(item) +
      ' ' +
      getEtasjeplan(item) +
      ' ' +
      (item.matrikkelenhet && item.matrikkelenhet.bruksnavn);
      mAdresse = mAdresse.replace('undefined', '');
    return mAdresse;
  }
  if (item.adresse === undefined) {
    return 'Mangler adresse';
  }
  return (
    (item.adresse.gatenavn ? item.adresse.gatenavn : '') +
    ' ' +
    item.adresse.husnummer +
    (item.adresse.bokstav !== undefined ? item.adresse.bokstav : '') +
    ' ' +
    getEtasjeplan(item)
  );
}

function getEtasjeplan(item) {
  let etnr = '..';
  if (item.etasjenummer)  {
    etnr = ('00' + item.etasjenummer).substr(-2, 2);
  };
  let etlop = '..';
  if  (item.lopenummer)  {
    etlop = ('00' + item.lopenummer).substr(-2, 2);
  };
  return item.etasjeplankode
    ? item.etasjeplankode +
       etnr +
       etlop
    : '';
}

