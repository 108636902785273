import React, { Component } from "react";
import bruksenhet_ikon from "images/48/hjem.png";
import royk_ikon from "images/48/nk_075-royklop-A-48px.png";
import ildsted_ikon from "images/48/nk_076-ildsted-A-48px.png";
import DeltPipe from "images/deltpipe.png";
import {
  sendTilEierFraGjennomforing,
  sendTilEierFraOppf2,
} from "api/innbyggerDialogApi.js";
import {
  sendTilSmk,
  forhandsvisningSmk,
  sendTilSaksbehandlerUpublisert,
} from "api/smkApi.js";
//import { getOrganisasjonSvarUt } from 'api/organisasjonApi';
import { getOrgSvarUt } from "context/contextInnstillinger.js";
import { getBruksenhetKontakter2 } from "api/kontaktApi.js";
import { getIkkePubliserte } from "api/avvikApi.js";
import ReactTable from "@norkart/react-table";
import NKIcon from "nka-icons";
import "./AvvikAnmerkninger.css";
import enhetApi from "api/enhetApi";
import { debugFactory } from "utils";
import { getBruksenhetTiltakikkeutfort } from "api/enhetApi.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/nb";
import DialogStore from "lib/DialogStore";
import NKModal from "components/Dialogs/NKModal.jsx";
import Forhandsvisning from "components/Editor/Forhandsvisning.jsx";
import { getContextBrukere } from "context/contextOppslag.js";
import _ from "underscore";
import OkDialog from "components/Dialogs/OkDialog.jsx";
import enhet from "images/hjem.png";
import royklop from "images/royklop.png";
import {
  sjekkBruksenhetRisiko,
  getRisikoanalyseLogg,
  startRisikoanalyseBruksenhet,
} from "api/risikoApi";
import InfoRad from "modules/Gjennomforing/RisikovurderingLogg/InfoRad.js";

const debug = debugFactory("nk:gjennomforing:sendtileier");
let publiserAvvikListe = [];
let gjenstaaendeTiltak = 0;
let blank = {
  id: 0,
  navn: "-",
};

const modalDialogs = {
  ForhandsvisningDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size="medium" isOpen={isOpen} onClose={onClose}>
      <Forhandsvisning
        onClose={onClose}
        model={dialogData.model}
        //visToppTekstButton= {false}
      />
    </NKModal>
  ),
  SidemannDialog: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={owner.onCloseVelgSidemann}
        tittel={"Send til eier"}
        melding={"Du må velge sideperson"}
        onOk={owner.onCloseVelgSidemann}
      />
    );
  },
};

class SendTilEierMedRisiko extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      avvik: null,
      anmerkninger: null,
      valgteAvvik: [],
      valgteAnm: [],
      avvikMedEierkontakter: [],
      merketAnm: [],
      bruksenhetKontakt: [],
      kontakter: [],
      publiserer: false,
      step1Hide: "",
      step15Hide: "hide",
      step2Hide: "hide",
      eierInfoMangler: false,
      laster: false,
      adresse: "",
      tittel:
        "Velg avvik, anmerkninger og andre forhold som skal sendes til eier",
      eier: "",
      telefon: "",
      startDate: moment(this.props.planlagtDato),
      kontakterUtenTlf: null,
      kontakterMedTlf: null,
      svarUt: false,
      valgteEiereMed: [],
      valgteEiereUten: [],
      risiko: false,
      risikoInfo: [],
    };

    this.getData = this.getData.bind(this);
    this.sortDato = this.sortDato.bind(this);
    this.sorterEtterType = this.sorterEtterType.bind(this);
    this.publiser = this.publiser.bind(this);
    this.formatDato = this.formatDato.bind(this);
    this.sendTilEierDone = this.sendTilEierDone.bind(this);
  }

  componentDidMount() {
    console.log(this.props.orgRisiko);
    if (this.props.sidemann) {
      this.setState({
        tittel:
          "Velg avvik, anmerkninger og andre forhold som skal sendes til dokumentkontroll",
      });
      getContextBrukere(
        function (brukere) {
          console.log(brukere);
          let sidemenn = _.filter(
            brukere.brukere,
            (item) => item.sidemannkontroll === true && item.paaLogget === false
          );
          sidemenn.unshift(blank);
          console.log(sidemenn);
          this.setState({
            personer: sidemenn,
            valgtSidemannId: sidemenn[0].id,
          });
        }.bind(this)
      );
    }
    if (this.props.saksbehandler) {
      this.setState({
        tittel:
          "Velg avvik, anmerkninger og andre forhold som skal sendes til saksbehandler",
      });
    }
    publiserAvvikListe = [];
    this.getData();
    getOrgSvarUt(this.gotSvarUt);
    console.log(this.props.planlagtDato);
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  getData() {
    getIkkePubliserte(this.props.brId, this.gotData);
  }

  gotRisikoSjekk = (err, res) => {
    if (res) {
      this.setState({ risiko: res });
      getRisikoanalyseLogg(this.props.brId, (e, d) => {
        this.setState({ risikoInfo: d });
        console.log(d);
      });
    }
  };

  gotSvarUt = (res) => {
    this.setState({ svarUt: res.data });
  };

  gotData = (err, data) => {
    if (data !== undefined) {
      let avData = data.avvikListe.sort(this.sortDato);
      avData = this.sorterEtterType(avData);
      this.setState({ avvik: avData });
      this.setState({ avvikerCount: data.avvikListe.length });
      let anmData = data.anmerkningListe.sort(this.sortDato);
      anmData = this.sorterEtterType(anmData);
      this.setState({ anmerkninger: anmData });
      this.setState({
        anmerkningerCount: data.anmerkningListe.length,
      });
      // Fjerner muligheten for å velge avvik og anm
      //this.setState({ valgteAvvik: avData});
      //this.setState({ valgteAnm: anmData })
    }
    this.setState({ adresse: this.props.adresse });
    getBruksenhetTiltakikkeutfort(
      this.props.brId,
      this.props.arbeidslisteId,
      this.gotTiltakUtfortInfo
    );
  };

  gotTiltakUtfortInfo = (err, res) => {
    gjenstaaendeTiltak = res;

    if (this.props.previewDocFraSituasjon) {
      if (this.state.avvikerCount === 0 && this.state.anmerkningerCount === 0) {
        this.props.escapeFraSend();
      }
    }
  };

  formatDato(datostring) {
    let date = new Date(datostring);
    var dateString =
      ("0" + date.getDate()).slice(-2) +
      "." +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "." +
      date.getFullYear();
    return dateString;
  }

  sortDato(a, b) {
    var dateA = new Date(a.dato).getTime();
    var dateB = new Date(b.dato).getTime();
    return dateA > dateB ? 1 : -1;
  }

  sorterEtterType(liste) {
    let result = [];
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].objektType.toString().startsWith("B")) {
        result.push(liste[i]);
      }
    }
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].objektType.toString().startsWith("R")) {
        result.push(liste[i]);
      }
    }
    for (let i = 0; i < liste.length; i++) {
      if (liste[i].objektType.toString().startsWith("I")) {
        result.push(liste[i]);
      }
    }
    return result;
  }

  getDagensDato() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = dd + "." + mm + "." + yyyy;
    return today;
  }

  eiereMedRowSelected = (rows) => {
    this.setState({ valgteEiereMed: rows });
  };

  eiereUtenRowSelected = (rows) => {
    this.setState({ valgteEiereUten: rows });
  };

  publiser() {
    let med = this.state.valgteEiereMed;
    let uten = this.state.valgteEiereUten;
    let bk = this.state.bruksenhetKontakt;

    // Set alle sms til smsutelat
    bk.forEach((element) => {
      element.kontakt.forEach((e) => {
        if (!e.ugyldigTelefonNr) {
          e.smsUtelat = true;
        }
      });
    });
    // Finn de som er valg og sett at sms skal sendes
    med.forEach((element) => {
      bk.forEach((b) => {
        let kontakt = b.kontakt;
        kontakt.forEach((k) => {
          console.log(k.id);
          console.log(element.id);
          if (k.id == element.id) {
            k.smsUtelat = false;
          }
        });
      });
    });
    uten.forEach((element) => {
      bk.forEach((b) => {
        let kontakt = b.kontakt;
        kontakt.forEach((k) => {
          if (k.id == element.id) {
            if (this.state.svarUt) {
              k.svarUtValgt = true;
            }
          }
        });
      });
    });

    this.setState({ bruksenhetKontakt: bk });

    // til her
    this.setState({ publiserer: true });
    let dato = moment(this.state.startDate).add(1, "days").toDate();

    let param = {
      bruksenhetId: this.props.brId,
      arbeidslisteId: this.props.arbeidslisteId,
      bruksenhetKontakt: this.state.bruksenhetKontakt,
      avvikListe: publiserAvvikListe,
      anmerkningListe: this.state.valgteAnm,
      besokdato: dato,
    };

    if (this.props.previewDoc) {
      param.sidemann = false;
      param.previewDoc = true;
      param.previewDocTekst = this.props.previewDocTekst;
      param.sidemannkontrollId = this.props.sidemannkontrollId;

      forhandsvisningSmk(param, this.previewFerdig);
      return;
    }

    if (this.props.previewDocFraSituasjon) {
      param.previewDoc = true;
      sendTilEierFraGjennomforing(param, this.previewFerdig);
      return;
    }

    if (this.props.sidemann) {
      param.fra = this.props.fraModul;
      param.sidemannId = this.state.valgtSidemannId;
      sendTilSmk(param, this.sendTilSidemannDone);
    } else if (this.props.saksbehandler) {
      param.fra = this.props.fraModul;
      sendTilSaksbehandlerUpublisert(param, this.sendTilSaksbehandlerDone);
    } else {
      if (this.props.fraModul === "Oppfolging") {
        sendTilEierFraOppf2(param, this.sendTilEierDone);
      } else {
        sendTilEierFraGjennomforing(param, this.sendTilEierDone);
      }
    }
  }

  previewFerdig = (err, data) => {
    this.props.forhandsVisningFerdigForVisning(data);
  };

  sendTilSidemannDone = (err, data) => {
    this.props.onFerdigSmk();
  };

  sendTilSaksbehandlerDone = (err, data) => {
    this.props.onFerdigSaksbehandler();
  };

  sendTilEierDone(err, result) {
    this.props.onFerdig();
  }

  checkEierkontakter = (eierkontakter) => {
    let eierk = eierkontakter;
    //
    let kontakterUtenTlf = [];
    let kontakterMedTlf = [];
    var result = true;
    if (eierk !== null && eierk.length > 0) {
      eierk.forEach((ek) => {
        let kontakter = ek.kontakt;
        kontakter.forEach((ko) => {
          if (
            ko.telefon !== null &&
            ko.telefon !== "" &&
            ko.telefon !== undefined &&
            ko.ugyldigTelefonNr == false
          ) {
            result = false;
          }
          if (ko.telefon == null || ko.ugyldigTelefonNr) {
            ko.smsUtelat = true;
            //result = true;
          }
          if (ko.ugyldigTelefonNr) {
            kontakterUtenTlf.push(ko);
          } else {
            kontakterMedTlf.push(ko);
          }
        });
      });
    }
    this.setState({
      kontakterUtenTlf: kontakterUtenTlf,
      kontakterMedTlf: kontakterMedTlf,
    });

    this.setState({ eierInfoMangler: result });
    return eierk;
  };

  onCloseVelgSidemann = () => {
    this.dialogs.closeDialog();
  };

  refreshRisiko = () => {
    sjekkBruksenhetRisiko(this.props.brId, this.gotRisikoSjekk);
  };

  startRisiko = () => {
    startRisikoanalyseBruksenhet(this.props.brId, () => {
      this.refreshRisiko();
    });
  };

  nesteFraStep1 = () => {
    console.log(this.state.valgteAvvik);
    console.log(this.state.valgteAnm);
    if (this.props.sidemann && this.state.valgtSidemannId == 0) {
      this.dialogs.setOpenDialog("SidemannDialog");
    } else {
      if (this.props.orgRisiko) {
        sjekkBruksenhetRisiko(this.props.brId, this.gotRisikoSjekk);
        this.setState({ tittel: "Status risikovurdering" });
        this.setState({
          step1Hide: "hide",
          step15Hide: "",
          step2Hide: "hide",
        });
      } else {
        this.nesteFraStep15();
      }
    }
  };

  nesteFraStep15 = () => {
    if (this.state.avvikerCount == 0 && this.state.anmerkningerCount == 0) {
      this.SendIngenAvvikAnm();
    } else if (
      this.state.valgteAvvik.length == 0 &&
      this.state.valgteAnm.length == 0
    ) {
      this.SendIngenAvvikAnm();
    } else {
      if (
        this.state.valgteAvvik !== null &&
        this.state.valgteAvvik.length > 0
      ) {
        if (!this.props.sidemann && !this.props.saksbehandler) {
          console.log("ikke saksbehandler og sidemann");
          this.setState({
            step1Hide: "hide",
            step15Hide: "hide",
            step2Hide: "",
          });
        }

        this.setState({ laster: true });
        // Finne eiekontakterforbruksenheter
        let bruksenhetids = [];
        bruksenhetids.push(this.props.brId.toString());
        let avListe = this.state.valgteAvvik;
        // ikke send med bruksenheter tilknyttet hvis send til saksbehandler/sidemann
        if (!this.props.sidemann && !this.props.saksbehandler) {
          avListe.forEach((element) => {
            if (element.bruksenhetIdsTilknyttet !== null) {
              if (element.bruksenhetIdsTilknyttet.includes(",")) {
                let temp = element.bruksenhetIdsTilknyttet.split(",");
                temp.forEach((tmp) => {
                  if (!bruksenhetids.includes(tmp)) {
                    bruksenhetids.push(tmp);
                  }
                });
              } else {
                if (!bruksenhetids.includes(element.bruksenhetIdsTilknyttet)) {
                  if (element.bruksenhetIdsTilknyttet !== "") {
                    bruksenhetids.push(element.bruksenhetIdsTilknyttet);
                  }
                }
              }
            }
          });
        }
        console.log(bruksenhetids);
        publiserAvvikListe = this.state.valgteAvvik;

        this.setState({
          tittel: "Verifiser eierkontakt " + this.sidemannExtra(),
        });
        getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakter);
      } else {
        // Bare anmerkininger
        let bruksenhetids = [];
        bruksenhetids.push(this.props.brId.toString());
        getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakterBareAnm);
      }
      let merkStep1 = this.state.valgteAnm;
      this.setState({ merketAnm: merkStep1 });
    }
  };

  forrigeFraStep15 = () => {
    this.setState({
      step1Hide: "",
      step15Hide: "hide",
      step2Hide: "hide",
    });
    if (this.props.sidemann) {
      this.setState({
        tittel:
          "Velg avvik, anmerkninger og andre forhold som (først) skal sendes til dokumentkontroll",
      });
    } else if (this.props.saksbehandler) {
      this.setState({
        tittel:
          "Velg avvik, anmerkninger og andre forhold som skal sendes til saksbehandler",
      });
    } else {
      this.setState({
        tittel:
          "Velg avvik, anmerkninger og andre forhold som skal sendes til eier",
      });
    }
  };

  forrigeFraStep2 = () => {
    if (this.props.orgRisiko) { 
      this.setState({ tittel: "Status risikovurdering" });
    this.setState({
      step1Hide: "hide",
      step15Hide: "",
      step2Hide: "hide",
    });
    } else {
      this.forrigeFraStep15();
    }
    
  };

  visadresserForDelt = (item) => {
    let str = item.bruksenhetIdsTilknyttet;
    let array = str.split(",");
    enhetApi.postBruksenhetAdresser2(array, this.onAdresserHentet);
  };
  onAdresserHentet = (err, data) => {};

  gotEierkontakter = (err, eierData) => {
    this.setState({ bruksenhetKontakt: eierData });
    //let eierkontakter = eierData;
    let eierkontakter = this.checkEierkontakter(eierData);
    let avListe = this.state.valgteAvvik;
    avListe.forEach((av) => {
      av.eierkontakter = [];
      eierkontakter.forEach((ek) => {
        if (
          this.props.brId.toString().includes(ek.bruksenhetFagId.toString())
        ) {
          av.eierkontakter.push(ek);
        } else {
          if (av.bruksenhetIdsTilknyttet !== null) {
            if (av.bruksenhetIdsTilknyttet.includes(ek.bruksenhetFagId)) {
              av.eierkontakter.push(ek);
            }
          }
        }
      });
    });
    this.setState({ avvikMedEierkontakter: avListe, laster: false });

    //this.publiser();
    if (this.props.sidemann || this.props.saksbehandler) {
      this.publiser();
    } else {
      this.setState({
        step1Hide: "hide",
        step15Hide: "hide",
        step2Hide: "",
        tittel: "Verifiser kontaktinformasjon " + this.sidemannExtra(),
      });
    }
  };

  avvikRowSelected = (rows) => {
    this.setState({ valgteAvvik: rows });
  };
  anmRowSelected = (rows) => {
    this.setState({ valgteAnm: rows });
  };

  SendIngenAvvikAnm = () => {
    let bruksenhetids = [];
    publiserAvvikListe = [];
    bruksenhetids.push(this.props.brId.toString());
    getBruksenhetKontakter2(bruksenhetids, this.gotEierkontakterIngenAvvikAnm);
  };

  gotEierkontakterIngenAvvikAnm = (err, data) => {
    let eierkontakter = this.checkEierkontakter(data);

    this.setState({ bruksenhetKontakt: eierkontakter });
    if (eierkontakter.length > 0) {
      this.setState({ kontakter: eierkontakter[0].kontakt });
    }
    if (this.props.sidemann || this.props.saksbehandler) {
      this.publiser();
    } else {
      this.setState({
        step1Hide: "hide",
        step15Hide: "hide",
        step2Hide: "",
        tittel: "Verifiser kontaktinformasjon " + this.sidemannExtra(),
      });
    }
  };

  sidemannExtra = () => {
    let s = " (send til eier)";
    if (this.props.sidemann) {
      s = " (sidemannskontroll) ";
    }
    return s;
  };

  gotEierkontakterBareAnm = (err, data) => {
    //this.setState({ bruksenhetKontakt: data });
    let eierkontakter = this.checkEierkontakter(data);

    this.setState({ bruksenhetKontakt: eierkontakter });
    if (eierkontakter.length > 0) {
      this.setState({ kontakter: eierkontakter[0].kontakt });
    }
    if (this.props.sidemann || this.props.saksbehandler) {
      this.publiser();
    } else {
      this.setState({
        step1Hide: "hide",
        step2Hide: "",
        tittel: "Verifiser kontaktinformasjon " + this.sidemannExtra(),
      });
    }
  };

  handleDatePickerChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  //#region Forhåndsvisning
  forhandsvisning = () => {
    console.group("TTTF");
    //Steg 1:    Vi må kjøre en metode for å forberede henting av adresser i api
    this.setState({ publiserer: true });
    let bruksenhetids = [];
    bruksenhetids.push(this.props.brId.toString());
    getBruksenhetKontakter2(bruksenhetids, this.forhandsVisning2);
  };

  forhandsVisning2 = (err, kontakter) => {
    //Steg  2: Nå ber vi om forehåndsvisning.
    console.log("Steg 1");
    this.setState({ kontakter });
    getIkkePubliserte(this.props.brId.toString(), this.forhandsVisning3);
  };

  forhandsVisning3 = (err, liste) => {
    let avvikListe = this.state.valgteAvvik;
    let anmerkningListe = this.state.valgteAnm;
    console.log(this.state.valgteAvvik);
    console.log(this.state.valgteAnm);

    let dato = moment(this.state.startDate).add(2, "hours").toDate();

    let param = {
      bruksenhetKontakt: this.state.kontakter,
      avvikListe: avvikListe,
      anmerkningListe: anmerkningListe,
      bruksenhetId: this.props.brId.toString(),
      fraModul: "Gjennomforing", //Diskutabel
      arbeidslisteId: this.props.arbeidslisteId,
      besokdato: dato,
      previewDoc: true,
    };
    sendTilEierFraGjennomforing(param, this.forhandsVisning4);
  };

  forhandsVisning4 = (err, data) => {
    console.groupEnd();

    this.setState({ publiserer: false });

    if (data) {
      //Nyeste etter .net6
      if (data.tekst) {
        this.dialogs.setOpenDialog("ForhandsvisningDialog", {
          model: data.tekst,
          fra: "situasjon",
        });
      } else {
        //Gammel måte
        this.dialogs.setOpenDialog("ForhandsvisningDialog", {
          model: data,
          fra: "situasjon",
        });
      }
    }
  };

  handleSidemannChange = (val) => {
    this.setState({ valgtSidemannId: val.target.value });
  };

  //#endregion

  render() {
    if (!this.state.avvik || this.state.publiserer || this.state.laster) {
      return <div className="loader" />;
    }
    var avvikColumns = this.getAvvikColumns();
    var anmColumns = this.getAnmColumns();

    var eiereMedColumns = this.getEiereMedColumns();
    var eiereUtenColumns = this.getEiereUtenColumns();
    var eiereUtenColumnsOgSvarut = this.getEiereUtenColumnsOgSvarut();
    var risikoColumns = this.getRisikoColumns();

    return (
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close"
              type="button"
              onClick={this.props.onClose}
            >
              <span>x</span>
              <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">{this.state.tittel}</h4>
          </div>
          <div className="modal-body" style={{ padding: "0 20px" }}>
            {/* STEP1 */}
            <div className={this.state.step1Hide}>
              <h3>{this.props.adresse}</h3>
              <hr />
              <div className="row">
                <div className="col-sm-2">Besøksdato:</div>
                <div className="col-sm-3">
                  <DatePicker
                    className="width120Heigth32"
                    selected={this.state.startDate}
                    onChange={this.handleDatePickerChange}
                    isClearable={true}
                    showYearDropdown
                  />
                </div>
                {/* Sidemann, legg til valg av sidemann */}
                {this.props.sidemann ? (
                  <div>
                    <div className="col-sm-3">Velg sideperson:</div>
                    <div className="col-sm3">
                      <select
                        className="h30 width220"
                        value={this.state.valgtSidemannId}
                        id="type"
                        onChange={this.handleSidemannChange}
                      >
                        {_.map(this.state.personer, function (o, i) {
                          return (
                            <option key={i} value={o.id}>
                              {o.navn}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <br />
                    {/* <span style={{ marginLeft: '14px' }}>
                      Dokumentet blir sendt til sidemann for kontroll. Sidemann
                      vil kunne endre dokumentet og legge inn kommentarer.
                    </span> */}
                  </div>
                ) : null}
              </div>
              <hr />
              <div className="scrolldiv">
                {/* //Fange opp for ikke vise forhåndsvisning */}
                {this.state.avvikerCount === 0 &&
                this.state.anmerkningerCount === 0 ? (
                  <div>
                    {gjenstaaendeTiltak == 0 ? (
                      <div>
                        <div>
                          {this.props.sidemann || this.props.saksbehandler ? (
                            <div>
                              {this.props.sidemann ? (
                                <div>
                                  Det er ingen nye avvik og anmerkninger å sende
                                  til eier.
                                </div>
                              ) : (
                                <div>
                                  Det er ingen nye avvik og anmerkninger å sende
                                  til eier.
                                  <br />
                                  Trykk "Send til saksbehandler" for å sende til
                                  saksbehandler.
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              Det er ingen nye avvik og anmerkninger å sende til
                              eier.
                              <br />
                              Trykk "Neste" for å fortsette.
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        Besøk er gjennomført. Minst et av tiltakene har
                        registrert status 'ikke utført'.
                        <br />
                        Trykk 'Send til eier' for å informere innbyggeren dette.
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {gjenstaaendeTiltak != 0 ? (
                      <div>
                        <p>
                          Obs! Minst et av tiltakene har registrert status 'ikke
                          utført'.
                        </p>
                      </div>
                    ) : null}
                    <div />
                    <h4>Avvik</h4>
                    {this.state.avvik.length === 0 ? (
                      <div> - Ingen avvik</div>
                    ) : (
                      <div>
                        <ReactTable
                          showIndex={false}
                          items={this.state.avvik}
                          columns={avvikColumns}
                          selectableRows={true}
                          onRowClick={null}
                          onRowSelectionChange={this.avvikRowSelected}
                          toggleAll={true}
                          toggleAllDefault={true}
                          sortable={false}
                          multiSort={false}
                          filterable={false}
                        />
                      </div>
                    )}
                    <br />
                    {this.state.anmerkninger === null ? null : (
                      <div>
                        <h4>Anmerkninger og andre forhold</h4>
                        {this.state.anmerkninger.length === 0 ? (
                          <div> - Ingen anmerkninger / andre forhold</div>
                        ) : (
                          <div>
                            <ReactTable
                              showIndex={false}
                              items={this.state.anmerkninger}
                              columns={anmColumns}
                              selectableRows={true}
                              onRowClick={null}
                              onRowSelectionChange={this.anmRowSelected}
                              toggleAll={true}
                              toggleAllDefault={true}
                              sortable={false}
                              multiSort={false}
                              filterable={false}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* STEP1.5 */}
            <div className={this.state.step15Hide}>
              {this.state.risiko ? (
                <div>
                  <br />
                  <div className="btn-toolbar">
                    <button
                      style={{ width: "180px", height: "40px" }}
                      className="btn btn-default pull-left"
                      onClick={this.refreshRisiko}
                      title="Kjør risikoanalyse (igjen)"
                    >
                      <NKIcon icon={"lasting"} size="1" color="nk-black" />
                      <span style={{ marginLeft: "8px" }}>Oppdater</span>
                    </button>
                    <button
                      style={{ width: "180px", height: "40px" }}
                      className="btn btn-default pull-left"
                      onClick={this.startRisiko}
                      title="Kjør risikoanalyse (igjen)"
                    >
                      <NKIcon icon={"lasting"} size="1" color="nk-black" />
                      <span style={{ marginLeft: "8px" }}>
                        Start risikoanalyse
                      </span>
                    </button>
                  </div>
                  <br />
                  <div style={{ maxHeight: "600px", overflowY: "scroll" }}>
                    <table
                      className="info-table"
                      style={{ width: "95%", marginLeft: "8px" }}
                    >
                      <tbody>
                        <tr>
                          <th></th>
                          <th>Analyse</th>
                          <th>Status</th>
                          <th>Dato</th>
                          <th>Risikoverdi</th>
                          <th>Neste tiltak</th>
                          <th>Tiltak</th>
                          <th>Hyppighet</th>
                          <th></th>
                        </tr>

                        {this.state.risikoInfo.map((info, i) => (
                          <InfoRad info={info}></InfoRad>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <h3>
                  Bruksenheten er ikke underlagt risikovurdering. Trykk 'Neste'
                  for å fortsette.
                </h3>
              )}
            </div>

            {/* STEP2 */}
            <div className={this.state.step2Hide}>
              <h3>{this.props.adresse}</h3>
              Tilbakemelding om tiltakene blir publisert på Min Eiendom.
              {this.state.kontakterMedTlf != null &&
              this.state.kontakterMedTlf.length > 0 ? (
                <div>
                  Informasjon om tiltak sendes med SMS til følgende eiere:
                  <div
                    style={{
                      border: "1px solid black",
                      paddingRight: "10px",
                    }}
                  >
                    <ReactTable
                      showIndex={false}
                      items={this.state.kontakterMedTlf}
                      columns={eiereMedColumns}
                      selectableRows={true}
                      onRowClick={null}
                      onRowSelectionChange={this.eiereMedRowSelected}
                      toggleAll={true}
                      toggleAllDefault={true}
                      sortable={false}
                      multiSort={false}
                      filterable={false}
                    />
                  </div>
                </div>
              ) : null}
              {this.state.kontakterUtenTlf != null &&
              this.state.kontakterUtenTlf.length > 0 ? (
                <div>
                  {this.state.svarUt
                    ? "Telefonnummer ble ikke funnet for følgende eiere. Velg om informasjon skal sendes til SvarUt"
                    : "Telefonnummer ble ikke funnet for følgende eiere"}
                  {this.state.svarUt ? (
                    <div>
                      <div
                        style={{
                          border: "1px solid black",
                          paddingRight: "10px",
                        }}
                      >
                        <ReactTable
                          showIndex={false}
                          items={this.state.kontakterUtenTlf}
                          columns={eiereUtenColumns}
                          selectableRows={true}
                          onRowClick={null}
                          onRowSelectionChange={this.eiereUtenRowSelected}
                          toggleAll={true}
                          toggleAllDefault={true}
                          sortable={false}
                          multiSort={false}
                          filterable={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          border: "1px solid black",
                          paddingRight: "10px",
                        }}
                      >
                        <ReactTable
                          showIndex={false}
                          items={this.state.kontakterUtenTlf}
                          columns={eiereUtenColumnsOgSvarut}
                          selectableRows={false}
                          onRowClick={null}
                          onRowSelectionChange={this.eiereUtenRowSelected}
                          toggleAll={true}
                          toggleAllDefault={true}
                          sortable={false}
                          multiSort={false}
                          filterable={false}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <div />
            </div>
          </div>

          <div className="modal-footer">
            {!this.state.step1Hide && (
              <>
                <button
                  onClick={this.nesteFraStep1}
                  className="btn btn-primary pull-right btnBottomSend"
                >
                  <div>
                    {this.state.avvikerCount === 0 &&
                    this.state.anmerkningerCount === 0 ? (
                      <div>
                        {this.props.sidemann || this.props.saksbehandler ? (
                          <div>
                            {this.props.sidemann
                              ? "Send til sideperson"
                              : "Send til saksbehandler"}
                          </div>
                        ) : (
                          "Neste"
                        )}
                      </div>
                    ) : (
                      <div>
                        {this.props.sidemann || this.props.saksbehandler ? (
                          <div>
                            {this.props.sidemann
                              ? "Send til sideperson"
                              : "Send til saksbehandler"}
                          </div>
                        ) : (
                          "Neste"
                        )}
                      </div>
                    )}
                  </div>
                </button>
                <button
                  onClick={this.forhandsvisning}
                  style={{ marginRight: "6px" }}
                  className="btn btn-default"
                >
                  Forhåndsvisning
                </button>
              </>
            )}

            {!this.state.step15Hide && (
              <div>
                <div>
                  <button
                    onClick={this.nesteFraStep15}
                    className="btn btn-success pull-right btnBottomSend"
                  >
                    Neste
                  </button>
                </div>

                <button
                  onClick={this.forrigeFraStep15}
                  className="btn btn-default pull-right btnBottom"
                  style={{ marginRight: "6px" }}
                >
                  Forrige
                </button>
              </div>
            )}

            {!this.state.step2Hide && (
              <div>
                <div>
                  <button
                    onClick={this.publiser}
                    className="btn btn-success pull-right btnBottomSend"
                  >
                    Send og avslutt
                  </button>
                </div>

                <button
                  onClick={this.forrigeFraStep2}
                  className="btn btn-default pull-right btnBottom"
                  style={{ marginRight: "6px" }}
                >
                  Forrige
                </button>
              </div>
            )}
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }

  // Tabell kollonner
  getAvvikColumns = (owner) => {
    return [
      {
        id: "objektType",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          var objectIcon = bruksenhet_ikon;
          switch (item.objektType) {
            case "Røykløp":
              objectIcon = royk_ikon;
              break;
            case "Ildsted":
              objectIcon = ildsted_ikon;
              break;
            default:
              objectIcon = bruksenhet_ikon;
          }
          return (
            <img
              height="24"
              width="24"
              src={objectIcon}
              className="app-preloader__img pull-left mr8"
              alt=""
            />
          );
        },
      },
      {
        id: "delt",
        name: "",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.bruksenhetIdsTilknyttet !== null) {
            return (
              <img
                height="24"
                width="24"
                src={DeltPipe}
                className="app-preloader__img pull-left mr8"
                alt=""
                title="Delt"
              />
            );
          }
        },
      },

      {
        id: "avvikType",
        name: "Avvikstype",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          var avvikstype = item.avvikType;
          return <p style={{ width: "200px" }}>{avvikstype}</p>;
        },
      },
      {
        id: "dato",
        name: "Registrert dato",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.dato === null) {
            return "-";
          } else {
            var dato = new Date(item.dato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = "0" + dag;
            }
            if (mnd.length === 1) {
              mnd = "0" + mnd;
            }
            return dag + "." + mnd + "." + aar;
          }
        },
      },
    ];
  };

  getAnmColumns = () => {
    return [
      {
        id: "objektType",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          var objectIcon = bruksenhet_ikon;
          switch (item.objektType) {
            case "Røykløp":
              objectIcon = royk_ikon;
              break;
            case "Ildsted":
              objectIcon = ildsted_ikon;
              break;
            default:
              objectIcon = bruksenhet_ikon;
          }
          return (
            <img
              height="24"
              width="24"
              src={objectIcon}
              className="app-preloader__img pull-left mr8"
              alt=""
            />
          );
        },
      },
      {
        id: "delt",
        name: "",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.bruksenhetIdsTilknyttet !== null) {
            return (
              <img
                height="24"
                width="24"
                src={DeltPipe}
                className="app-preloader__img pull-left mr8"
                alt=""
                title="Delt"
              />
            );
          }
        },
      },
      {
        id: "type",
        name: "Type",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.andreForhold) {
            return "Andre forhold";
          } else {
            return "Anmerkning";
          }
        },
      },
      {
        id: "kategori",
        name: "Kategori",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          var kategori = item.kategori;
          if (kategori == null || kategori == "") {
            return "Ikke oppgitt";
          } else {
            return <p style={{ width: "200px" }}>{kategori}</p>;
          }
        },
      },
      {
        id: "dato",
        name: "Registrert dato",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.dato === null) {
            return "-";
          } else {
            var dato = new Date(item.dato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = "0" + dag;
            }
            if (mnd.length === 1) {
              mnd = "0" + mnd;
            }
            return dag + "." + mnd + "." + aar;
          }
        },
      },
    ];
  };

  getRisikoColumns = () => {
    return [
      {
        id: "image",
        name: "",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.objekt == "ENHET") {
            return (
              <img
                height="24"
                width="24"
                src={enhet}
                className="app-preloader__img pull-left mr8"
                alt=""
              />
            );
          } else {
            return (
              <img
                height="24"
                width="24"
                src={royklop}
                className="app-preloader__img pull-left mr8"
                alt=""
              />
            );
          }
        },
      },
      {
        id: "analysetype",
        name: "Analyse",
        isSorted: false,
        sortable: false,
      },
      {
        id: "status",
        name: "Status",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.status == "FAILED_MANUELL_SYSTEMENDRING") {
            return "FAILED";
          } else return item.status;
        },
      },
      {
        id: "tidspunkt",
        name: "Dato",
        isSorted: false,
        sortable: false,
      },
      {
        id: "analyseResultat",
        name: "Risikoverdi",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.analyseResultat) {
            return item.analyseResultat.risikoverdi;
          }
        },
      },
      {
        id: "nesteTiltak",
        name: "Neste tiltak",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.analyseResultat) {
            if (item.analyseResultat.datoNesteTiltak === null) {
              return "-";
            } else {
              var dato = new Date(item.analyseResultat.datoNesteTiltak);
              var dag = dato.getDate().toString();
              var mnd = (dato.getMonth() + 1).toString();
              var aar = dato.getFullYear().toString();
              if (dag.length === 1) {
                dag = "0" + dag;
              }
              if (mnd.length === 1) {
                mnd = "0" + mnd;
              }
              return dag + "." + mnd + "." + aar;
            }
          }
        },
      },
      {
        id: "tiltak",
        name: "Tiltak",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.analyseResultat) {
            return item.analyseResultat.tiltakType;
          }
        },
      },
      {
        id: "hyppighet",
        name: "Hyppighet",
        isSorted: false,
        sortable: false,
        formatter: function (item) {
          if (item.analyseResultat) {
            return item.analyseResultat.hyppighetTekst;
          }
        },
      },
    ];
  };

  getEiereMedColumns = () => {
    return [
      // {
      //   id: 'bruksenhetAdresse',
      //   name: 'Bruksenhet',
      //   isSorted: false,
      //   sortable: false,
      // },
      {
        id: "adresse",
        name: "Send til",
        isSorted: false,
        sortable: false,
      },
      {
        id: "navn",
        name: "Eier",
        isSorted: false,
        sortable: false,
      },
      {
        id: "telefon",
        name: "Telefonnummer",
        isSorted: false,
        sortable: false,
      },
    ];
  };
  getEiereUtenColumns = () => {
    return [
      {
        id: "adresse",
        name: "Send til Svarut",
        isSorted: false,
        sortable: false,
      },
      {
        id: "navn",
        name: "Eier",
        isSorted: false,
        sortable: false,
      },
    ];
  };
  getEiereUtenColumnsOgSvarut = () => {
    return [
      {
        id: "adresse",
        name: "Adresse",
        isSorted: false,
        sortable: false,
      },
      {
        id: "navn",
        name: "Eier",
        isSorted: false,
        sortable: false,
      },
    ];
  };
}
export default SendTilEierMedRisiko;
