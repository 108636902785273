import React, { Component } from 'react';
import { getSakskategorierForKunde } from 'api/arkivApi';
import ReactTable from '@norkart/react-table';
var _ = require('underscore');

class VisSakskategori extends Component {
  constructor(props) {
  super(props);
  this.state = { kat: null };
  }

componentDidMount() {
    getSakskategorierForKunde(
              this.props.kundeId,
              '123',
              (err, res) => {
                this.setState({ kat: res });
                console.log(res);
              }
            );
}

getColumns() {
  return [
    {
      id: 'Navn',
      name: 'Navn',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'Mappetype',
      name: 'Mappetype',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'StatusNySaksmappe',
      name: 'StatusNySaksmappe',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'StatusAvsluttSaksmappe',
      name: 'StatusAvsluttSaksmappe',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'Arkivdel',
      name: 'Arkivdel',
      filterable: false,
      isSorted: false,
      sortable: false
    },
    {
      id: 'KlasseListe',
      name: 'KlasseListe',
      filterable: false,
      isSorted: false,
      sortable: false,
      formatter: function(item) {
        console.log(item);
        return  item.KlasseListe != null && item.KlasseListe.length > 0 ? item.KlasseListe[0].Klassifikasjonssystem : null;
      }
    }
  ];
}

render() {
    if(!this.state.kat) { return null; }
   return (
    <div style={{ margin: '30px' }}>
        <h4>Sakskategorier</h4>
        <ReactTable
            items={this.state.kat}
            columns={this.getColumns()}
            showIndex={false}
          />
        {/* <table border='1'>
        {_.map(
            this.state.kat,
            function(k) {
              console.log(k);
              return (
                
                <div>
                  
                  <tr>
                    <td>Navn</td>
                    <td>{k.Navn}</td>
                  </tr>
                  <tr>
                    <td>Mappetype</td>
                    <td>{k.Mappetype}</td>
                  </tr>
                  <tr>
                    <td>StatusNySaksmappe</td>
                    <td>{k.StatusNySaksmappe}</td>
                  </tr>
                  <tr>
                    <td>StatusAvsluttSaksmappe</td>
                    <td>{k.StatusAvsluttSaksmappe}</td>
                  </tr>
                  <tr>
                    <td>Klasse</td>
                    <td>{k.KlasseListe != null && k.KlasseListe.length > 0 ? k.KlasseListe[0].Klassifikasjonssystem : null}</td>
                  </tr>
                  <tr>
                    <td>Arkivdel</td>
                    <td>{k.Arkivdel}</td>
                  </tr>
                
                  
                </div>
              );
            },
            this
          )}
        </table> */}
    </div>
    );
  }
}

export default VisSakskategori;