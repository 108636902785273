import callApi from './api';


export function startRisikoanalyseBruksenhet(brId, callback) {
    callApi('POST', '/risikoanalyse/startbruksenhet/' + brId, null, callback); 
};

export function startRisikoanalyseRoyklop(brId, roykId, callback) {
    callApi('POST', '/risikoanalyse/startroyklop/' + brId + '/' + roykId, null, callback); 
};

export function getRisikoanalyseLogg(brId, callback) {
    callApi('GET', '/risikoanalyse/risikoanalyserLog/' + brId, null, callback); 
};

export function loadGrunnlagsdataRisiko(orgid, fyringsanlegg, ikkeFritid, fritid, callback) {
    callApi('GET', '/risikoanalyse/organisasjonadmin/loadgrunnlagsdata/' + orgid + '/' + fyringsanlegg + '/' + ikkeFritid + '/' + fritid, null, callback);
}

export function getStatusLoadGrunnlagsdataRisiko(orgid, callback) {
    callApi('GET', '/risikoanalyse/organisasjonadmin/grunnlagsdatastatus/' + orgid, null, callback);
}

export function deleteGrunnlagsdataRisiko(orgid, callback) {
    callApi('DELETE', '/risikoanalyse/organisasjonadmin/deletegrunnlagsdata/' + orgid, null, callback);
}

export function sjekkBruksenhetRisiko(brId, callback) {
    callApi('GET', '/risikoanalyse/bruksenhetrisikovurderes/' + brId, null, callback);
}

export function sjekkRoyklopRisiko(id, brId, callback) {
    callApi('GET', '/risikoanalyse/roykloprisikovurderes/' + id + '/' + brId, null, callback);
}
// Status sjekker

export function statusKomtekVms(callback) {
    callApi('GET', '/status/komtekwms/', null, callback);
}
export function statusAnalyseplattform(callback) {
    callApi('GET', '/status/analyseplatform/', null, callback);
}
export function statusAnalyseplattformFmeservers(callback) {
    callApi('GET', '/status/risikoanalyseplatformfmeservers/', null, callback);
}
export function statusRisikoanalyser(callback) {
    callApi('GET', '/status/risikoanalyserfeiler/', null, callback);
}

export function getAnalyseDataStatus(orgid, callback) {
    callApi('GET', '/risikoanalyse/organisasjonadmin/analysedatastatus/' + orgid, null, callback);
}

// Risikomatriser

export function getBruksenhetMatrise(callback) {
    callApi('GET', '/risikoanalyse/bruksenhtmatrise/', null, callback);
}
export function getBruksenhetMatriseFritid(callback) {
    callApi('GET', '/risikoanalyse/bruksenhtmatrisefritid/', null, callback);
}
export function getRoyklopmatrise(callback) {
    callApi('GET', '/risikoanalyse/royklopmatrise/', null, callback);
}
export function getRoyklopmatriseFritid(callback) {
    callApi('GET', '/risikoanalyse/royklopmatrisefritid/', null, callback);
}

  export function getRisikoInfoForEnhetTilsyn(brId, callback) {  
    callApi('GET', '/risikoanalyse/risikoanalyserlogbruksenhet/' + brId, null, callback);
}
export function getRisikoInfoForEnhetFeiing(brId, callback) {
    callApi('GET', '/risikoanalyse/roykloprisikovurderes/' + brId, null, callback);
} 

export function startMassivAnalyse(orgId, analyseobjekt, fritid,  callback) {
    callApi('POST', '/risikoanalyse/startmassivanalyse/' + orgId + '/' + analyseobjekt + '/' + fritid, null, callback);
}
export function importMassiv(orgId, callback) {
    callApi('POST', '/risikoanalyse/importmassiv/' + orgId, null, callback);
}