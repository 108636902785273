import React from 'react';
import Risikomassiv from './Risikomassiv.js';
import { withRouter } from 'lib/enhancers';


class index extends React.Component {

    
  render() {
    if (this.props.location){
        return <Risikomassiv org= {this.props.location.state.org}></Risikomassiv>;
    }
  }
}
export default withRouter(index);