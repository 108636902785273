import React from 'react';
import _ from 'underscore';
import ReactTable from '@norkart/react-table';

let columns = [
  {
    id: 'navn',
    name: 'Navn',
    sortParams: 'navn',
    isSorted: false,
    sortDirection: 'asc'
  },
  {
    id: 'beskrivelse',
    name: 'Beskrivelse',
    sortParams: 'beskrivelse',
    isSorted: false,
    sortDirection: 'asc'
  },
  {
    id: 'kategori',
    name: 'Kategori',
    sortParams: 'kategori',
    isSorted: false,
    sortDirection: 'asc'
  },
  {
    id: 'id',
    name: 'Id',
    sortParams: 'id',
    isSorted: false,
    sortDirection: 'asc'
  }
];

class Organisasjoner extends React.Component {
  constructor() {
    super();
    this.state = {
      liste: []
    };
  }
  componentDidMount() {  
    //Vi ønsker å ha Norkart helt øverst i liste ved start
    let liste = this.props.organisasjoner; 
    let nyListeOpp = [];
    let nyListeNed = [];
    for (let i = 0; i < liste.length; i++) {
      const element = liste[i];
      if (element.kategori === 'Demo') {
        nyListeOpp.push(element);
      } else {
        nyListeNed.push(element);
      }
    }
    nyListeNed = nyListeNed.sort((a, b) => (a.navn > b.navn) ? 1 : -1)
    liste = nyListeOpp.concat(nyListeNed);
    this.setState({ liste: liste });
  }

  render() {
    return (
      <div className="panel panel-default">
        <div className="panel-body">
        <h4>Velg organisasjon du ønsker å koble til</h4>
          <div className="form">            
            <ReactTable
              items={this.state.liste}
              idProperty="id"
              showIndex={false}
              filterable={true}
              columns={columns}
              selectableRows={false}
              selectAllLabel="Alle"
              onRowClick={this.props.onOrganisasjonValgt}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default Organisasjoner;
