import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import Matrikkelenheter from './Matrikkelenheter.jsx';
import Enhetssok from './Enhetssok';
import Fiktive from './Fiktive';

import { debugFactory, enhanceComponents } from 'utils';

const debug = debugFactory('nk:objekter:routes');

const enhanced = enhanceComponents({
    Index,
    Enhetssok,
    Matrikkelenheter,
    Fiktive
}, [
        asRouteComponent,
    ]);

export default (
    <Route path="objekter" component={enhanced.Index} name="Objekter" icon="objekter" inMenu={true} login={true} roles={[]}>
        <Route path="enhetssok" component={enhanced.Enhetssok} name="Søk på enheter" />
        <Route path="enhetssokMed" component={enhanced.Enhetssok} name="Søk på enheter" />
        <Route path="matrikkelenheter" component={enhanced.Matrikkelenheter} name="Etabler" />
        <Route path="fiktiveEnheter" component={enhanced.Fiktive} name="Fiktive enheter" />
    </Route>
);