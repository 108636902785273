import callApi from './api';


export function sendSporsmalTilAi(dto, callback) {
     console.log('%c AI BF: API: sendSporsmalTilAi ', 'font-size: 14px; background: black; color: white',  dto );

/*     setTimeout(() => {
        callback('', 'Tilbakemelding fra sendSporsmalTilAi');
    }, 4000); */

    callApi('POST', '/ai/chat/', dto, callback);
}
