import { Accessor } from 'searchkit';
import {
  debugFactory
} from 'utils';

const debug = debugFactory('nk:GeoAccessor');
export class GeoAccessor extends Accessor {
  setArea(area) {
    this.area = area;
  }

  setPolygon(polygon, callback) {
    debug('Bounds Polygon-accessor setPolygon', polygon);
    this.onPolygonFilterRemoved = callback;  
    this.polygon = polygon;
  }

  setPrecision(precision) {
    this.precision = precision;
  }

  setResults(results) {
    super.setResults(results);

    // let significant = _.map(this.getAggregations(['geo', 'significant', 'buckets'], []) , 'key');
  }

  buildSharedQuery(query) {
    let selectedFilters = [];
    if (this.polygon !== null) {
      selectedFilters.push({
        name: 'Polygon',
        value: 'aktivt',
        id: 1,
        remove: () => {
          this.polygon = null;
          if (this.onPolygonFilterRemoved) {
            this.onPolygonFilterRemoved();
          }
        }
      });
    }
    if (this.polygon) {
      return query
        .addQuery({
          nested: {
            path: 'bygg',
            query: {
              bool: {
                must: {
                  match_all: {}
                },
                filter: {
                  geo_polygon: {
                    'bygg.lokasjon': {
                      points: this.polygon.location.coordinates
                    }
                  }
                }
              }
            }
          }
        })
        .addSelectedFilters(selectedFilters);
    } else if (this.area) {
      return query.addQuery({
        nested: {
          path: 'bygg',
          query: {
            bool: {
              must: {
                match_all: {}
              },
              filter: {
                geo_bounding_box: {
                  'bygg.lokasjon': this.area
                }
              }
            }
          }
        }
      });
    }
    return query;
  }
/* 
  buildOwnQuery(query) {
    return query.setAggs(
      new FilterBucket('geo', query.getFilters(), {
        inner: {
          nested: {
            path: 'bygg'
          },
          aggs: {
            areas: {
              geohash_grid: {
                field: 'bygg.lokasjon',
                precision: this.precision //4
              }
            }
          }
        }
      })
    );
  } */
}
