import React from 'react';
import { getRoyklopForFlyttIldsted } from 'api/royklopApi';
import { flyttIldsted } from 'api/ildstedApi';
import ReactTable from '@norkart/react-table';
import royklopApi from 'api/royklopApi';
import DialogStore from 'lib/DialogStore';
import OkDialog from 'components/Dialogs/OkDialog.jsx';

let tiltaktyper = [];
let objektId = null;

let columns = [
  {
    id: 'adresse',
    name: 'Adresse',
    sortParams: 'adresse',
    filterable: false,
    isSorted: false,
    sortable: true
  },
  {
    id: 'eiendom',
    name: 'Eiendom',
    sortParams: 'eiendom',
    isSorted: false
  },
  {
    id: 'plassering',
    name: 'Plassering',
    sortParams: 'plassering',
    isSorted: false
  },
  {
    id: 'type',
    name: 'Type',
    sortParams: 'type',
    isSorted: false
  }
];

const modalDialogs = {
  DialogRegelmessigeTiltak: ({ isOpen, onClose, dialogData, owner }) => {
    return (
      <OkDialog
        isOpen={isOpen}
        onClose={onClose}
        tittel="Regelmessige tiltak"
        melding="Tilknyttet røykløp har regelmessige tiltak som vil bli slettet hvis du fullfører endringen. Ønsker du å fortsette?"
        onOk={owner.tiltakSjekketLagre}
      />
    );
  }
};

class FlyttIldstedDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      royklop: null
    };
    this.flyttIldsted = this.flyttIldsted.bind(this);
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
  }
  componentDidMount() {
    let { ildsted } = this.props;
    getRoyklopForFlyttIldsted(
      ildsted.bruksenhetId,
      ildsted.royklopId,
      (err, res) => this.setState({ royklop: res })
    );
  }

  flyttIldsted(row) {
    let { ildsted } = this.props;
    objektId = {
      BruksenhetId: row.bruksenhetId,
      RoyklopId: row.royklopId,
      IldstedId: ildsted.id
    };

    let res = this.sjekkIldsteder();
    if (res == 1 || res == 0) {
      royklopApi.getRoyklopTiltakType(this.props.royklop.id, this.tiltakSjekket);
    } else {
      flyttIldsted(objektId, (err, res) => {
        this.props.selected();
        this.props.oppdaterSituasjon();
      });
    }
  }
  
  tiltakSjekket = (err, res) => {
    if (res.length > 0) {
      tiltaktyper = res;
      this.dialogs.setOpenDialog('DialogRegelmessigeTiltak');
    } else {
      flyttIldsted(objektId, (err, res) => {
      this.props.selected();
      this.props.oppdaterSituasjon();
    });
    }
  }

    // flyttIldsted(objektId, (err, res) => {
    //   this.props.selected();
    //   this.props.oppdaterSituasjon();
    // });
  
    tiltakSjekketLagre = () => {
      tiltaktyper.forEach(element => {
        royklopApi.slettRoyklopTiltakType(element.id, this.slettet);
      });
      flyttIldsted(objektId, (err, res) => {
      this.props.selected();
      this.props.oppdaterSituasjon();
    });
    }

    slettet = () => {}

  sjekkIldsteder = () => {
    let result = 0;
    let r = this.props.royklop;
    r.ildstedListe.forEach(i => {
      if (i.ibruk) {
        result = result + 1;
      }
    });
    return result;
  }

  render() {
    if (!this.state.royklop) {
      return <p>Laster...</p>;
    } else if (this.state.royklop.length === 0) {
      return (
        <p>
          Kan ikke flytte ildsted. Det er ingen andre registrerte røykløp på
          dette bygget.{' '}
        </p>
      );
    } else {
      return (
        <div>
        <ReactTable
          items={this.state.royklop}
          idProperty="royklopId"
          showIndex={false}
          filterable={false}
          columns={columns}
          selectableRows={false}
          onRowClick={this.flyttIldsted}
        />
        {this.dialogs.renderOpenDialog()}
        </div>
      );
    }
  }
}
export default FlyttIldstedDialog;
