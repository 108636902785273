import React, { Component } from 'react';
import {
  uuid
} from 'utils' ;
var arkivApi = require('api/arkivApi');
var InternKontakt = {
  Person: {
    Fornavn: null,
    Etternavn: null,
    Postadresse: null,
    Postnr: null,
    Poststed: null
  }
};


class NyEndreBrukerInnstilling extends Component {
  constructor(props) {
    super(props);
    this.handleBrukerChange = this.handleBrukerChange.bind(this);
    this.handleJournalenhetChange = this.handleJournalenhetChange.bind(this);
    this.handleAdmenhetChange = this.handleAdmenhetChange.bind(this);
    this.handleSaksbehandlerChange = this.handleSaksbehandlerChange.bind(this);
    this.onLagre = this.onLagre.bind(this);
    this.innstillingLagret = this.innstillingLagret.bind(this);
    this.state = {
      innstillinger: this.props.valgt,
      // kundeId: this.props.valgt != null ? this.props.valgt.KundeId : null,
      bruker: this.props.valgt != null ? this.props.valgt.Bruker : null,
      journalenhet:
        this.props.valgt != null ? this.props.valgt.Journalenhet : null,
      saksbehandler:
        this.props.valgt != null ? this.props.valgt.SaksbehandlerInit : null,
      admenhet:
        this.props.valgt != null
          ? this.props.valgt.AdministrativEnhetInit
          : null,
      visInternKontakt: false
    };
  }

  componentDidMount() {
    this.setState({ type: this.props.type });
    arkivApi.getArkivInnstillinger(uuid(), this.gotInnstillinger);
  }

  gotInnstillinger = (err, innstillinger) => {
    if (innstillinger.Arkivsystem == 'Documaster') {
      this.setState({ visInternKontakt: true });
      if (this.props.type == 'ny') {
        this.setState({ internFornavn: null, internEtternavn: null, internAdresse: null, internPostnr: null, internPoststed: null });
      } else {
        this.setState({ internFornavn: this.props.valgt.InternKontakt != null && this.props.valgt.InternKontakt.Person != null ? this.props.valgt.InternKontakt.Person.Fornavn : '',
                        internEtternavn: this.props.valgt.InternKontakt != null && this.props.valgt.InternKontakt.Person != null ? this.props.valgt.InternKontakt.Person.Etternavn : '',
                        internAdresse: this.props.valgt.InternKontakt != null && this.props.valgt.InternKontakt.Person != null ? this.props.valgt.InternKontakt.Person.Postadresse : '',
                        internPostnr: this.props.valgt.InternKontakt != null && this.props.valgt.InternKontakt.Person != null ? this.props.valgt.InternKontakt.Person.Postnr : '',
                        internPoststed: this.props.valgt.InternKontakt != null && this.props.valgt.InternKontakt.Person != null ? this.props.valgt.InternKontakt.Person.Poststed : '' });
      }
    }
  };

  onLagre() { 
    if (this.state.visInternKontakt) {
      InternKontakt.Person.Fornavn = this.state.internFornavn;
      InternKontakt.Person.Etternavn = this.state.internEtternavn;
      InternKontakt.Person.Postadresse = this.state.internAdresse;
      InternKontakt.Person.Postnr = this.state.internPostnr;
      InternKontakt.Person.Poststed = this.state.internPoststed;
    }

    if (this.state.type === 'ny') {    
      var nyBruker = {
        Bruker: this.state.bruker,
        Journalenhet: this.state.journalenhet,
        SaksbehandlerInit: this.state.saksbehandler,
        AdministrativEnhetInit: this.state.admenhet,
        InternKontakt: this.state.visInternKontakt ? InternKontakt : null
    };
      arkivApi.nyBrukerInnstilling(uuid(), nyBruker, this.innstillingLagret);
    } else {
      var oppdatert = this.props.valgt;
      oppdatert.Bruker = this.state.bruker;
      oppdatert.Journalenhet = this.state.journalenhet;
      oppdatert.SaksbehandlerInit = this.state.saksbehandler;
      oppdatert.AdministrativEnhetInit = this.state.admenhet;
      oppdatert.InternKontakt = this.state.visInternKontakt ? InternKontakt : null;
      arkivApi.endreBrukerInnstilling(uuid(), oppdatert, this.innstillingLagret);
    }
  }
  innstillingLagret() {
    this.props.nyLagret();
  }

  handleBrukerChange(val) {
    this.setState({ bruker: val.target.value });
  }
  handleJournalenhetChange(val) {
    this.setState({ journalenhet: val.target.value });
  }
  handleAdmenhetChange(val) {
    this.setState({ admenhet: val.target.value });
  }
  handleSaksbehandlerChange(val) {
    this.setState({ saksbehandler: val.target.value });
  }
  handleInternFornavnChange = val => {
    this.setState({ internFornavn: val.target.value });
  }
  handleInternEtternavnChange = val => {
    this.setState({ internEtternavn: val.target.value });
  }
  handleInternAdresseChange = val => {
    this.setState({ internAdresse: val.target.value });
  }
  handleInternPostnrChange = val => {
    this.setState({ internPostnr: val.target.value });
  }
  handleInternPoststedChange = val => {
    this.setState({ internPoststed: val.target.value });
  }
  
  render() {
    return (
      <div>
        <div className='modal-content'>
          <div className='modal-header'>
            <button
              className='close'
              onClick={this.props.onClose}
              type='button'>
              <span>x</span>
              <span className='sr-only'>Lukk</span>
            </button>
            <h4 className='modal-title'>
              {this.props.type === 'ny'
                ? 'Ny brukerinnstilling'
                : 'Endre brukerinnstilling'}
            </h4>
          </div>
          <div className='modal-body'>
            <div className='form'>
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Bruker</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.bruker}
                      onChange={this.handleBrukerChange}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Administrativ enhet initialer</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.admenhet}
                      onChange={this.handleAdmenhetChange}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Journalenhet</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.journalenhet}
                      onChange={this.handleJournalenhetChange}
                    />
                  </div>
                </div>
                <div className='col-sm-6'>
                  <div className='form-group'>
                    <label>Saksbehandler initialer</label>
                    <input
                      type='text'
                      className='form-control'
                      value={this.state.saksbehandler}
                      onChange={this.handleSaksbehandlerChange}
                    />
                  </div>
                </div>
                {this.state.visInternKontakt ? (
                  <div>
                    
                    <div className='col-sm-12'>
                    <hr/>
                    <h4><b>Documaster Intern kontakt</b></h4>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Fornavn</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.internFornavn}
                          onChange={this.handleInternFornavnChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Etternavn</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.internEtternavn}
                          onChange={this.handleInternEtternavnChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Adresse</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.internAdresse}
                          onChange={this.handleInternAdresseChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Postnr</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.internPostnr}
                          onChange={this.handleInternPostnrChange}
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group'>
                        <label>Poststed</label>
                        <input
                          type='text'
                          className='form-control'
                          value={this.state.internPoststed}
                          onChange={this.handleInternPoststedChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='pull-right'>
                    <input
                      type='button'
                      className='btn btn-primary'
                      value='OK'
                      onClick={this.onLagre}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NyEndreBrukerInnstilling;
