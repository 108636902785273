import React, { Component } from 'react';
import { getAlleArkivInnstillinger } from 'api/arkivApi';

import VisBrukere from './VisBrukere.jsx';
import VisSakskategori from './VisSakskategori.jsx';
var _ = require('underscore');

let style1 = {
  height: '1px'
};
class navn extends Component {
  constructor(props) {
    super(props);
    this.state = { kunder: null, laster: false, brukere: null };
  }

  componentDidMount() {
    this.setState({ laster: true });
    getAlleArkivInnstillinger('123', (err, res) => {
      console.log(res);
      this.setState({ kunder: res, laster: false });
      //this.hentData();
    });
  }

  render() {
    if (this.state.laster) {
      return <div className="loader" />;
    }
    return (
      <div style={{ margin: '30px' }}>
        <h1>Arkiv innstillinger for alle kunder</h1>
        <hr/>
        <p/>
        
          {_.map(
            this.state.kunder,
            function(kunde) {
              console.log(kunde);
              return (
                
                <div>
                  <h3>Arkivinnstillinger</h3>
                  <p>KundeId:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{kunde.KundeId}</p>
                  <p>Arkivsystem: {kunde.Arkivsystem}</p>
                  <p>Fagsystem: {kunde.Fagsystem}</p>
                  <p>Dokumenttype: {kunde.Dokumenttype}</p>
                  <nl/>
                  <p>KorrespondanseparttypeMapping</p>
                  <p>&nbsp;EksternMottakerType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.EksternMottakerType : null}</p>
                  <p>&nbsp;EksternAvsenderType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.EksternAvsenderType : null}</p>
                  <p>&nbsp;InternMottakerType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternMottakerType : null}</p>
                  <p>&nbsp;InternAvsenderType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternAvsenderType : null}</p>
                  <p>&nbsp;InternMottakerInngåendeJpType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternMottakerInngåendeJpType : null}</p>
                  <p>&nbsp;InternMottakerInterntNotatType: {kunde != null && kunde.KorrespondanseparttypeMapping != null ? kunde.KorrespondanseparttypeMapping.InternMottakerInterntNotatType : null}</p>
                  <hr/>
                  {/* <tr>
                    <td>KundeId</td>
                    <td><b>{kunde.KundeId}</b></td>
                  </tr>
                  <tr>
                    <td>Arkivsystem</td>
                    <td>{kunde.Arkivsystem}</td>
                  </tr>
                  <tr>
                    <td>AddAuthenticationHeader</td>
                    <td>{kunde.AddAuthorizationHeader}</td>
                  </tr>
                  <tr>
                    <td>Fagsystem</td>
                    <td>{kunde.Fagsystem}</td>
                  </tr>
                  <tr>
                    <td>Dokumenttype </td>
                    <td>{kunde.Dokumenttype}</td>
                  </tr>
                  <tr/> */}
                  { kunde.KundeId != null && kunde.KundeId != undefined ? 
                  <div>
                    <VisSakskategori kundeId={kunde.KundeId}/>
                  <hr/>
                  <VisBrukere kundeId={kunde.KundeId}/>
                  <hr className='hr' />
                  </div> : null}
                  
                  
                </div>
              );
            },
            this
          )}
       
        
      </div>
    );
  }
}

export default navn;
