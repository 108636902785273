import React from 'react';
import LoginButton from 'components/auth/LoginButton';
import norkart_logo from 'images/NORKART_SYMBOL_negativ__digital.png';
import brann from 'images/Brannforebygging.png';
import bilde from 'images/Annet/silhou3.png';


 export default function (props) {
    return (
        <div className="app-preloader">
            <div className="app-preloader__content">
              <img className="bilde" src={bilde} alt="" />
              <br/> <br/>
                <LoginButton>Logg inn</LoginButton>  
            </div>
        </div>  
    );
}; 

/* export default function (props) {
    return (
        <div className="app-preloader" style={{ backgroundColor: '#E5E5CB', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPositionX: 'center', backgroundPositionY: 'top', backgroundImage: `url(${bilde})`}}>
            <div className="app-preloader__content" >   
            
              <br/> <br/>
                <LoginButton>Logg inn</LoginButton>  
                
            </div>
        </div>  
    );
}; */
