import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import Arbeidsliste from './Arbeidsliste';
import LeggTilObjekter from './LeggTilObjekter';
import Kalender from './Kalender';
import { debugFactory, enhanceComponents } from 'utils';

const debug = debugFactory('nk:planlegging:routes');

const enhanced = enhanceComponents({
    Index,
    Arbeidsliste,
    LeggTilObjekter,
    Kalender
}, [
        asRouteComponent
    ]);

export default (
    <Route path="planlegging" component={enhanced.Index} name="Planlegging" icon="kalender" inMenu={true} login={true} roles={[]}>
        <Route path="kalender" component={enhanced.Kalender} name="Kalender" />
        <Route path=":arbeidslisteId" component={enhanced.Arbeidsliste} name={'Arbeidsliste'}>
            <Route path="leggtilobjekter" component={enhanced.LeggTilObjekter} name="Legg til objekter" />
            <Route path="leggtilobjektermed" component={enhanced.LeggTilObjekter} name="Legg til objekter" />
        </Route>
       
    </Route>
);