import React, { Component } from 'react';
import omradeSoneApi from 'api/omradeSonerApi';
import _ from 'underscore';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel="Kan ikke lagre"
      melding="Sone kan ikke lagres uten navn."
      isOpen={isOpen} 
      onClose={onClose}
      onOk={onClose}
  />
  )
};

class LagreRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      omrader: [],
      soner: [],
      omradeId: '0',
      omradeNavn: 'Nytt_område',
      sone: 'Ny sone',
      color: 'aqua',
      varsel: ''
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs
    });
    this.lagre = this.lagre.bind(this);
    this.omradeChange = this.omradeChange.bind(this);
    this.navnChange = this.navnChange.bind(this);
    this.colorChange = this.colorChange.bind(this);
    this.finnesSoneFraFor = this.finnesSoneFraFor.bind(this);
  }

  componentDidMount() {
    omradeSoneApi.getAlleOmrader(this.onGotAlleOmrader.bind(this));
    omradeSoneApi.getAlleSoner(this.onGotAlleSoner.bind(this));
  }

  onGotAlleOmrader(err, data) {
    let omrader = data;
    if (omrader !== undefined && omrader.length > 0) {
      omrader = _.sortBy(data, 'navn');
      this.setState({ omrader: omrader });
      this.setState({ omradeId: omrader[0].id });
      this.setState({ omradeNavn: omrader[0].navn });
    }
  }

  onGotAlleSoner(err, data) {
    let soner = data;
    console.log(data);
    if (soner !== undefined && soner.length > 0) {
      soner = _.sortBy(data, 'navn');
      this.setState({ soner: soner });
    }
  }

  finnesSoneFraFor(navn, omradeNavn) {
    let finnes = false;
    let collection = this.state.soner;

    for (let i = 0; i < collection.length; i++) {
      let sone = collection[i];
      if (sone.navn === navn && sone.omradeNavn === omradeNavn) {
        finnes = true;
      }
    }
    if (finnes === true) {
      this.setState({ varsel: 'Sone finnes fra før. Velg et annet navn.' });
    }
    return finnes;
  }

  lagre() {
    if (this.state.sone === null || this.state.sone.trim() === '') {
      this.dialogs.setOpenDialog('OkDialog');
      return;
    }

    let finnes = this.finnesSoneFraFor(this.state.sone, this.state.omradeNavn);
    if (!finnes) {
      this.props.onLagreRegionOk(
        this.state.omradeId,
        this.state.omradeNavn,
        this.state.sone,
        this.state.color
      );
    }
  }

  omradeChange(e) {
    let id = e.target.value;
    let navn = e.target.options[e.target.selectedIndex].text;
    this.setState({ omradeId: id });
    this.setState({ omradeNavn: navn });
  }

  navnChange(event) {
    this.setState({ sone: event.target.value });
    this.setState({ varsel: '' });
  }

  colorChange(event) {
    this.setState({ color: event.target.value });
  }

  keyDown = (event) => {
    const pressedChar = event.key;
     // Allow only numbers and letters (alphabets)
     if (!/[A-Za-z0-9\sÅÆØåæø]/.test(pressedChar)) {
     event.preventDefault();
   }
 }

  render() {
    return (
      <div className="modal-content height440">
        <div className="modal-header">
          <button className="close" type="button" onClick={this.props.onClose}>
            <span>x</span>
            <span className="sr-only">Lukk</span>
          </button>
          <h4 className="modal-title"> {this.state.sone} </h4>
        </div>
        <div className="modal-body">
          <div className="form-group ">
            <label>Område</label>
            <select
              className="form-control"
              id="type"
              onChange={this.omradeChange.bind(this)}>
              {_.map(this.state.omrader, function(o, i) {
                return (
                  <option key={i} value={o.id}>
                    {o.navn}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group">
            <label>Sone</label>
            <br />
            <input
              type="text"
              value={this.state.sone}
              className="form-control"
              id="sone"
              onChange={this.navnChange}
              onKeyDown={(evt) => {this.keyDown(evt);}}
            />
             <span style={{ color: 'blue', marginLeft: '8px',  marginTop: '4px', fontSize: '12px' }} className='pull-left'>Bruk kun bokstaver og nummer.</span>
            <br/>
          </div>

          <div className="form-group">
            <div className="redText">{this.state.varsel}</div>
          </div>

          <div className="form-group">
            <label>Farge</label>
            <br />
            <select
              value={this.state.color}
              onChange={this.colorChange}
              className="farger">
              <option value="cyan">Aqua</option>
              <option value="brown">Brun</option>
              <option value="transparent">Gjennomsiktig</option>
              <option value="grey">Grå</option>
              <option value="lime">Grønn</option>
              <option value="yellow">Gul</option>
              <option value="lawngreen">Lime</option>
              <option value="coral">Oransje</option>
              <option value="violet">Rosa</option>
              <option value="red">Rødt</option>
              <option value="blue">Violet</option>
            </select>
            <br />
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary bedit" onClick={this.lagre}>
            OK
          </button>
        </div>

        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default LagreRegion;
