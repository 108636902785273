import React, { Component } from 'react';
import './diagram.css';
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  exportComponentAsPNG
} from 'react-component-export-image';

const COLORS = ['#51a026', '#e96767'];


class DonutIldsted extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = { ddata: [], data5: [], count: 5 };
  }

  componentDidMount() {
    this.makeData(this.props.data);
  }

  makeData = (data) => {
    let ddata = [];
    let o = { name: 'i bruk', value: data.antallIbruk };
    ddata.push(o);
    o = { name: 'ikke i bruk', value: data.antallIkkeIbruk };
    ddata.push(o);
    this.setState({ ddata });
  };

  render() {
    return (
      <div
        style={{ maxWidth: '600px', width: '90%', height: '300px', marginBottom: '24px' }}
        ref={this.componentRef}>
          <span style={{ fontSize: '12px' }}>{this.props.periode}</span>
        <ResponsiveContainer width={'100%'}>
          <PieChart>
            <Pie
              label
              dataKey='value'
              data={this.state.ddata}
              cx='50%'
              cy='50%'
              innerRadius={50}
              outerRadius={100}
              fill='#8884d8'
              paddingAngle={2}>
              <Label
                value={this.props.data.antallIldsted}
                position='centerBottom'
                className='label-top'
                fontSize='20px'
              />
              <Label value='Ildsted' position='centerTop' className='label' />
              {this.state.ddata.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend layout="vertical" verticalAlign="top" align="right" />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        <button
          className='btn btn-link'
          onClick={() =>
            exportComponentAsPNG(this.componentRef, {
              fileName: 'RegistrerteIldsted',
            })
          }>
          Last ned som bilde
        </button>
      </div>
    );
  }
}

export default DonutIldsted;
