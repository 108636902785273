import React from 'react';
import { getMatrikkelnummer, getAdresse } from 'modules/felles/AdresseHelper';
import SoekEnheterKart from 'components/Kart/SoekEnheterKart.jsx';
import { GeoAccessor } from 'components/Search/Map/GeoAccessor.jsx';
import { SearchkitComponent } from 'searchkit';
import * as _ from 'lodash';
import moment from 'moment';
import EnhetModal from 'modules/felles/EnhetModal.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import ContextMenu from 'components/ContextMenu';
import enhetApi from 'api/enhetApi';
import '@norkart/react-table/dist/react-table.css';
import ReactTable from '@norkart/react-table';
import { Pagination } from 'searchkit';
import InfoDialog from '../Dialogs/InformationDialog.jsx';
import ModalContainer from '../Dialogs/ModalContainer.jsx';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import ArbeidslisteInfoModal from 'modules/felles/ArbeidslisteInfoModal.jsx';
import DialogStore from 'lib/DialogStore';
import { debugFactory } from 'utils';
import { search } from 'utils/searchKitHelper';
import GebyrDialog from 'modules/BraGe/GebyrDialog.jsx';
import { getContextGebyrInnstillinger } from 'context/contextInnstillinger.js';
const NKIcon = React.lazy(() => import('nka-icons'));

const debug = debugFactory('nk:grunndata:bruksenheter');

/* const TabellBoundary = createErrorBoundary({
  handledAt: () => 'Bruksenhetstabell:TabellBoundary',
  FallbackUi: () => <div>Visning av bruksenhetstabell feilet.</div>,
}); */

const modalDialogs = {
  EnhetDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <EnhetModal
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}
      itemId={dialogData.itemSituasjonId}
      bruksenhetIdMa={dialogData.matrikkelenhetId}
      itemAdresse={dialogData.itemsSituasjonAdresse}
      eiendom={dialogData.eiendom}
      matrikkelenhetId={dialogData.matrikkelenhetId}
      fraFiktive= {false}
    />
  ),
  InfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <InfoDialog
      isOpen={isOpen}
      onClose={onClose}
      tittel='Fagdata allerede etablert'
      melding='Det er allerede etablert fagdata for denne enheten. Søk på nytt for å vise oppdaterte data for bruksenheten.'
    />
  ),
  MatrikkelDialog: (
    { isOpen, onClose, dialogData, owner } //visMatrikkelinfo
  ) => (
    <ModalContainer
      size='medium'
      buttonText={'Lukk'}
      tittel='Matrikkelinfo'
      isOpen={isOpen}
      onClose={onClose(owner.onCloseDialog)}>
      <Matrikkelinfo
        id={dialogData.matrikkelinfoId}
        bygningsNr={dialogData.bygningsNr}
      />
    </ModalContainer>
  ),
  ArbeidslisteInfoDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <ArbeidslisteInfoModal
      isOpen={isOpen}
      onClose={onClose}
      id={dialogData.id}
      adresse={dialogData.adresse}
    />
  ),
  GebyrDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <GebyrDialog
        isOpen={isOpen}
        onClose={onClose}
        adresse={dialogData.adresse}
        matrikkelId={dialogData.matrikkelId}
        fagbruksenhetId={dialogData.fagbruksenhetId}
        fraEtablerFag={false}
        maid={dialogData.maid}
      />
    </NKModal>
  ),
};

/* export default class extends SearchkitComponent<any, any> { */
class Bruksenhetstabell extends SearchkitComponent<any, any> {
  accessor = new GeoAccessor();

  constructor(props) {
    super(props);
    this.state = {
      visAntatt: false,
      markers: [],
      visGebyr: false,
      hideMap: this.props.isMapCollapsed ? this.props.isMapCollapsed : false,
      previewObjekt: null,
      itemsSituasjonAdresse: null,
      itemSituasjonId: null,
      styleClass: 'missingSkybranndata',
    };
    this.dialogs = new DialogStore({
      owner: this,
      dialogs: modalDialogs,
    });
    this.mapWasCollapsed = false;
    this.listeMedEtablerte = [];
    this.valgteBruksenheter = [];
    this.rowClick = this.rowClick.bind(this);
    this.onVisKartClicked = this.onVisKartClicked.bind(this);
    this.visMatrikkelInfo = this.visMatrikkelInfo.bind(this);
    this.onBoundsFilter = this.onBoundsFilter.bind(this);
    this.onBrukPolygon = this.onBrukPolygon.bind(this);
    this.onPolygonFjernet = this.onPolygonFjernet.bind(this);
    this.onBoundsFilterBruk = this.onBoundsFilterBruk.bind(this);
    this.getValgteBruksenheter = this.getValgteBruksenheter.bind(this);
    this.shouldHideMap = this.shouldHideMap.bind(this);
    this.onPolygonFilterRemoved = this.onPolygonFilterRemoved.bind(this);
    this.onRegistrerCompleted = this.onRegistrerCompleted.bind(this);
    this.goSprett = this.goSprett.bind(this);
    this.oppdaterLagrede = this.oppdaterLagrede.bind(this);
    this.isRowSelectable = this.isRowSelectable.bind(this);
    this.rowFormat = this.rowFormat.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);
    this.onVisArbeidslisteinfo = this.onVisArbeidslisteinfo.bind(this);
    this.setGeoAccessor = this.setGeoAccessor.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.shouldHideMap);
    this.setGeoAccessor();
    getContextGebyrInnstillinger(this.gotGebyrInnstillinger);
  }

  gotGebyrInnstillinger = (innst) => {
    this.setState({ visGebyr: innst.gebyrkobling });
  };

  setGeoAccessor = () => {
    let geoAccessor = this.props.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    if (geoAccessor === undefined) {
      this.props.searchkit.accessors.add(this.accessor);
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.shouldHideMap);
    this.accessor.setPolygon(null);
  }

  onCloseDialog() {
    this.props.reloadSearch();
  }

  oppdaterLagrede() {
    let k = this.kart;
    k.oppdaterLagrede();
  }

  shouldHideMap() {
    if (this.mapWasCollapsed) {
      return;
    }
    this.setState({
      hideMap: !window.matchMedia('(min-width: 1200px)').matches,
    });
  }

  onVisKartClicked() {
    if (this.props.mapWasCollapsed) {
      this.mapWasCollapsed = true;
      this.props.mapWasCollapsed();
    }
    this.setState({
      hideMap: !this.state.hideMap,
    });
  }

  isVurdert = (itemId) => {
    if (!this.props.vurderte) {
      return false;
    }
    let i = parseInt(itemId);
    var inVurdert = this.props.vurderte.includes(i);
    return inVurdert;
  };

  rowFormat(item) {
    let ff = _.get(item, 'source.skybrannData.bruksenhet.fyringsforbud');
    
    let blueTekst = '';
    let blueClass = '';
   

    if (ff){
      blueClass = ' fyringsforbudBackground ';
      blueTekst = ' - Fyringsforbud';
    }

    let { bruksenheterVarslet } = this.props;

    if (!_.has(item, 'source.skybrannData.bruksenhet')) {
      if (this.isVurdert(item.id)) {
        blueClass = ' blueBackgroundLight ';
        blueTekst = ' - Vurdert';
      }
      return {
        tooltip:
          'Mangler fagdata. ' +  //For å kunne legge objektet til på arbeidsliste; velg først "Etabler fagdata" fra menyen på denne raden.
          blueTekst,
        className: this.state.styleClass + blueClass,
        
      };
    } else if (
      bruksenheterVarslet &&
      bruksenheterVarslet.some(
        (id) => id === item.source.skybrannData.bruksenhet.fagId
      )
    ) {
      return {
        tooltip:
          'Denne enheten kan ikke velges, da den allerede er varslet for tiltak.' +
          blueTekst,
        className: 'missingSkybranndata' + blueClass,
      };
    } else {
      return {
        tooltip: blueTekst,
        className: blueClass,
      };
    }
  }

  isRowSelectable(item) {
    //Todo: varselSendt
    let { bruksenheterVarslet } = this.props;
    let selectable = _.has(item, 'source.skybrannData.bruksenhet');
    if (selectable && bruksenheterVarslet) {
      selectable = !bruksenheterVarslet.some(
        (id) => id === item.source.skybrannData.bruksenhet.fagId
      );
    }
    return selectable;
  }

  //BOUNDSFILTER *********
  onBoundsFilter(boundsfilter) {
    //this.accessor.setArea(boundsfilter);
    search(this.searchkit);
  }
  onBoundsFilterBruk(bruk) {
    if (!bruk) {
      //this.accessor.setArea(null);
      search(this.searchkit);
    }
  }
  //POLYGON **************
  onBrukPolygon(poly) {
    let geoAccessor = this.props.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    /*   if (geoAccessor === undefined) {
      this.props.searchkit.accessors.add(this.accessor);
    }  */
    geoAccessor.setPolygon(poly, this.onPolygonFilterRemoved);
    search(this.searchkit);
  }
  onPolygonFjernet() {
    let geoAccessor = this.props.searchkit.accessors.accessors.find((item) => {
      return item instanceof GeoAccessor;
    });
    if (!geoAccessor) {
      return;
    }
    geoAccessor.setPolygon(null);
    search(this.searchkit);
  }

  onPolygonFilterRemoved() {
    let k = this.kart;
    if (k !== null) {
      k.fjernAllePolygonLayers();
    } else {
      console.log('Feil Bruksenhetstabell onPolygonFilterRemoved');
    }
  }

  //Preview spretter
  rowClick(row, e) {
    let bygg = row.bygg ? row.bygg : row.source.bygg;

    this.setState({ bygningsNr: bygg.bygningsnummer });
    if (this.props.onRowClick) {
      this.props.onRowClick(row.id, bygg.bygningsnummer);
    }

    let k = this.kart;
    if (!k) {
      return;
    }
    k.sprett(bygg);
  }

  goSprett(row) {
    let k = this.kart;
    k.sprett(row);
  }

  getValgteBruksenheter() {
    if (_.isEmpty(this.props.valgteobjekter)) {
      return [];
    }
    let valgteBruksenheter = _.filter(
      this.props.valgteobjekter.tiltakTypeBruksenhetListe,
      (item) => item.slett === false
    );

    valgteBruksenheter = _.filter(this.props.enheter, function(item) {
      return _.find(valgteBruksenheter, function(valgtObjekt) {
        return (
          valgtObjekt.bruksenhetIdMa === item.source.id ||
          (item.source.skybrannData &&
            item.source.skybrannData.bruksenhet &&
            valgtObjekt.bruksenhetId ===
              item.source.skybrannData.bruksenhet.fagId)
        );
      });
    });
    return valgteBruksenheter;
  }

  ItemIsRemovedFromArbeidsListe(id, fraBr) {
    //alert('Removed ' + id);
  }

  onClickSituasjon(item, fraKart) {
    let enhetid = null;
    let adresse = null;
    let matrikkelenhet = null;
    let eiendom = getMatrikkelnummer(item.source);

    
    if (fraKart) {
      debug('HHH fra Kart', item);
      enhetid = item.skybrannData.bruksenhet.fagId;
      adresse = item ? getAdresse(item) : 'null';
      matrikkelenhet = item.id;
    } else {
      debug('HHH ikke fra Kart', item);
      enhetid = item.source.skybrannData.bruksenhet.fagId;
      adresse = item.source ? getAdresse(item.source) : 'null';
      matrikkelenhet = item.id;
    }

    this.setState({
      itemSituasjonId: enhetid,
      matrikkelenhetId: matrikkelenhet,
      itemsSituasjonAdresse: adresse,
    });
    console.log(item);
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemSituasjonId: enhetid,
      matrikkelenhetId: item.source.adresse !== undefined ? item.source.adresse.id : item.source.matrikkelenhet.id,
      itemsSituasjonAdresse: adresse,
      eiendom: eiendom,
    });
  }
  onRegistrer(item) {
    if (
      _.some(this.listeMedEtablerte, function(o) {
        return o === parseInt(item.id);
      })
    ) {
      this.dialogs.setOpenDialog('InfoDialog');
      return;
    }
    let adresse = 'null';
    if (item.source) {
      if (item.source.adresse) {
        adresse = item.source.adresse.gatenavn;
      }
    }
    //let adresse = item.source ? item.source.adresse.gatenavn : 'null';
    this.setState({ itemsSituasjonAdresse: adresse });

    enhetApi.createBruksenhet(
      { BruksenhetIdMa: item.id },
      this.onRegistrerCompleted
    );
  }

  visMatrikkelInfo(item) {
    let bygg = item.bygg ? item.bygg : item.source.bygg;
    this.setState({
      bygningsNr: bygg.bygningsnummer,
    });
    this.dialogs.setOpenDialog('MatrikkelDialog', {
      matrikkelinfoId: item.id,
      bygningsNr: bygg.bygningsnummer,
    });
  }

  visArbeidslisteInfo(item) {
    this.onVisArbeidslisteinfo(item, true);
  }

  onVisArbeidslisteinfo(item, fraKart) {
   
    let adresse = null;
    let fagId = null;
    if (fraKart) {
      adresse = item ? getAdresse(item) : 'null';
      fagId = item.skybrannData.bruksenhet.fagId;
    } else {
      adresse = item.source ? getAdresse(item.source) : 'null';
      fagId = item.source.skybrannData.bruksenhet.fagId;
    }
    this.dialogs.setOpenDialog('ArbeidslisteInfoDialog', {
      id: fagId,
      adresse: adresse,
    });
  //  this.onVisArbeidslisteinfo(item, true);
  }

  visGebyr(item) {
    this.onVisGebyr(item, false);
  }

  onVisGebyrFraKart = (item) => {
    debug('visgebyr3', item);
    this.onVisGebyr(item, true);
  };

  onVisGebyr(item, fraKart) {
    debug('MAIDX bt', item);

    let enhetid = null;
    let adresse = null;
    let matrikkelenhet = null;
    let maid = null;
    if (fraKart) {
      enhetid =
        item.source.skybrannData !== undefined
          ? item.source.skybrannData.bruksenhet.fagId
          : null;
      adresse = item ? getAdresse(item) : 'null';
      matrikkelenhet = item.source.matrikkelenhet.id;
      if (!enhetid) {
        maid = item.source.id;
      }
    } else {
      enhetid =
        item.source.skybrannData !== undefined
          ? item.source.skybrannData.bruksenhet.fagId
          : null;
      adresse = item.source ? getAdresse(item.source) : 'null';
      matrikkelenhet = item.source.matrikkelenhet.id;
      if (!enhetid) {
        maid = item.source.id;
      }
    }
    this.dialogs.setOpenDialog('GebyrDialog', {
      adresse: adresse,
      matrikkelId: matrikkelenhet,
      fagbruksenhetId: enhetid,
      maid: maid,
    });
  }

  onRegistrerCompleted(err, result) {
    if (err) {
      return;
    }
    this.listeMedEtablerte.push(result.bruksenhetIdMa); //For å hindre at man skal kunne dobbeltregistrere
    this.setState({ itemSituasjonId: result.id });
    this.dialogs.setOpenDialog('EnhetDialog', {
      itemSituasjonId: result.id,
      matrikkelenhetId: this.state.matrikkelenhetId,
      itemsSituasjonAdresse: this.state.itemsSituasjonAdresse,
    });
  }

  getAdresseColumns() {
    if (this.props.kobleTilReelEnhet) {
      //Kommer fra fiktiv enhetstabell
      return this.getAdresseColumnsWithMenu();
    }
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          /*   let un = '';
          if (_.has(item, 'source.bruksenhetstype')) {
            if (item.source.bruksenhetstype === 'Unummerert bruksenhet') {
              un = '*';
            }
          } */
          return getAdresse(item.source);
        },
      },
    ];
  }

  //Fiktive enheter
  getAdresseColumnsWithMenu() {
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          /*  let un = '';
          if (_.has(item, 'source.bruksenhetstype')) {
            if (item.source.bruksenhetstype === 'Unummerert bruksenhet') {
              un = '*';
            }
          } */
          return getAdresse(item.source);
        },
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              {this.props.kobleTilReelEnhet ? (
                <ContextMenu.Item
                  onClick={this.props.kobleTilReelEnhet.bind(this, item)}>
                  Koble til denne bruksenheten
                </ContextMenu.Item>
              ) : (
                ''
              )}
              {item.source.skybrannData ? (
                <ContextMenu.Item
                  onClick={this.onClickSituasjon.bind(this, item, false)}>
                  Vis situasjon
                </ContextMenu.Item>
              ) : (
                ''
              )}
              {item.source.skybrannData && !this.props.kobleTilReelEnhet ? (
                <ContextMenu.Item onClick={this.onRegistrer.bind(this, item)}>
                  Etabler fagdata
                </ContextMenu.Item>
              ) : (
                ''
              )}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  }

  swapAntatt = () => {
    let a = this.state.visAntatt;
    this.setState({ visAntatt: !a });
  };

  /* getAllColumnsNew = (visAntatt) => {
    let array = this.getAllColumns();
    let resArray = [];

    if (visAntatt) {
      for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if ((element.id !== 'antattDato') && (element.id !== 'nesteTiltak')) {
          resArray.push(element);
        }
      }
      return resArray;
    }
    
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.id !== 'antattTest') {
        resArray.push(element);
      }
    }
    return resArray;
  }; */

  getAllColumns = () => {
    let doBold = (i) => {
      if (i === 0) {
        return 'bold';
      } else {
        return 'normal';
      }
    };
    let doColor = (d) => {
      const now = new Date();
      d = new Date(d);
      if (d < now) {
        return 'orangered';
      } else {
        return 'black';
      }
    };
    return [
      {
        id: 'kommune',
        name: 'Kommune',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.source.kommune.kommuneNavn;
        },
      },
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getAdresse(item.source);
        },
      },
      {
        id: 'gnr',
        name: 'Eiendom',
        sortParams: 'gnr',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return getMatrikkelnummer(item.source);
        },
      },

      {
        id: 'bretype',
        name: 'Bruksenhetstype',
        sortParams: 'bretype',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let bet = '';
          if (_.has(item, 'source.bruksenhetstype')) {
            bet = item.source.bruksenhetstype;
          }
          return bet;
        },
      },

      //******************************************************** */
      {
        id: 'antattTest',
        name: 'Antatt neste',
        sortParams: 'anytattTest',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (_.isEmpty(item.source.skybrannData)) {
            return '';
          } else {
            let liste = [];
            if (item.source.skybrannData.bruksenhetTiltakType) {
              for (
                let i = 0;
                i < item.source.skybrannData.bruksenhetTiltakType.length;
                i++
              ) {
                liste.push(item.source.skybrannData.bruksenhetTiltakType[i]);
              }
            }
            if (item.source.skybrannData.royklopTiltakType) {
              for (
                let i = 0;
                i < item.source.skybrannData.royklopTiltakType.length;
                i++
              ) {
                liste.push(item.source.skybrannData.royklopTiltakType[i]);
              }
            }
            if (liste.length > 0) {
              liste = _.orderBy(liste, ['nesteDato'], ['asc']);

              var rader = liste.map((rad, i) => (
                <tr
                  style={{ fontSize: '12px', fontWeight: doBold(i) }}
                  key={'tr_' + i}>
                  <td style={{ color: doColor(rad.nesteDato) }}>
                    {rad.nesteDato
                      ? moment(rad.nesteDato).format('DD.MM.YYYY')
                      : ''}
                  </td>
                  <td style={{ paddingLeft: '4px' }}>{rad.tiltakType}</td>
                </tr>
              ));

              return (
                <div>
                  <table>
                    <tbody>{rader}</tbody>
                  </table>
                </div>
              );
            }
          }
        },
      },

      //******************************************************** */

      /*   {
        id: 'nesteTiltak',
        name: 'Neste tiltakstype',
        sortParams: 'nesteTiltak',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (_.isEmpty(item.source.skybrannData)) {
            return '';
          } else {
            let liste = [];
            if (item.source.skybrannData.bruksenhetTiltakType) {
              for (
                let i = 0;
                i < item.source.skybrannData.bruksenhetTiltakType.length;
                i++
              ) {
                liste.push(item.source.skybrannData.bruksenhetTiltakType[i]);
              }
            }
            if (item.source.skybrannData.royklopTiltakType) {
              for (
                let i = 0;
                i < item.source.skybrannData.royklopTiltakType.length;
                i++
              ) {
                liste.push(item.source.skybrannData.royklopTiltakType[i]);
              }
            }
            if (liste.length > 0) {
              liste = _.orderBy(liste, ['nesteDato'], ['asc']);
              item.nesteDatoFix = liste[0].nesteDato;
              let tiltak = liste[0].tiltakType;
              let checkDato = liste[0].nesteDato;
              for (let y = 1; y < liste.length; y++) {
                let x = liste[y];
                if (x.nesteDato === checkDato) {
                  tiltak = tiltak + ', ' + x.tiltakType;
                }
              }
              return tiltak;
            }
          }
        },
      },
      {
        id: 'antattDato',
        name: 'Neste dato',
        sortParams: 'antattDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.nesteDatoFix
            ? moment(item.nesteDatoFix).format('DD.MM.YYYY')
            : '';
        },
      }, */
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title='Meny'
              icon='meny'
              iconColor='nk-black'
              className='inlinebtn'>
              {this.props.kobleTilReelEnhet ? (
                <ContextMenu.Item
                  onClick={this.props.kobleTilReelEnhet.bind(this, item)}>
                  Koble til denne bruksenheten
                </ContextMenu.Item>
              ) : (
                ''
              )}
              {item.source.skybrannData ? (
                <ContextMenu.Item
                  onClick={this.onClickSituasjon.bind(this, item, false)}>
                  Vis situasjon
                </ContextMenu.Item>
              ) : (
                <ContextMenu.Item onClick={this.onRegistrer.bind(this, item)}>
                  Etabler fagdata
                </ContextMenu.Item>
              )}

              <ContextMenu.Item
                onClick={this.visMatrikkelInfo.bind(this, item)}>
                Vis matrikkelinfo
              </ContextMenu.Item>

              <ContextMenu.Item
                onClick={this.onVisArbeidslisteinfo.bind(this, item, false)}>
                Vis arbeidslisteinfo
              </ContextMenu.Item>

              {this.state.visGebyr && (
                <ContextMenu.Item onClick={this.visGebyr.bind(this, item)}>
                  Vis gebyr
                </ContextMenu.Item>
              )}

              {/*               <ContextMenu.Divider />
              <ContextMenu.Item onClick={this.swapAntatt.bind(this)}>
                <span style= {{color: 'darkRed'}}>Test: Vis/skjul kolonne "Antatt neste"</span>
              </ContextMenu.Item> */}
            </ContextMenu.Button>
          );
        }.bind(this),
      },
      {
        //Ekstra space ifm meny-button i mobil-grid
        formatter: function() {
          return <div>&nbsp;</div>;
        },
      },
    ];
  };

  render() {
    let columns = '';
    if (this.state.hideMap) {
      columns = this.getAllColumns();
    } else {
      columns = this.getAdresseColumns();
    }

    return (
      <div>
        <div>
          <div className='panel panel-horizontal nopadding'>
            <div
              id='content2'
              style={{ padding: 0 }}
              className={this.state.hideMap ? 'col-md-12 ' : 'col-md-2 '}>
              <React.Suspense fallback={<div>Loading...</div>}>
                <span
                  className='hideButton pull-right'
                  id='visKart'
                  onClick={this.onVisKartClicked}>
                  {this.state.hideMap ? (
                    <NKIcon icon='venstrepil' size='1' color='nk-black' />
                  ) : (
                    <NKIcon icon='hoyrepil' size='1' color='nk-black' />
                  )}
                </span>
              </React.Suspense>

              <div className='soek-scroller font-14'>
                {/*    <span style= {{ height: '20px', fontSize: '14px', marginLeft: '16px'}}>{this.props.antallroyklopfunnet}</span> */}
                <ReactTable
                  showIndex={false}
                  items={this.props.enheter}
                  columns={columns}
                  rowFormat={this.rowFormat}
                  selectableRows={this.props.isRowsSelectable}
                  isRowSelectable={this.isRowSelectable}
                  onRowClick={this.rowClick}
                  idProperty='id'
                  itemsPrPage={0}
                />
              </div>
              <Pagination className='pull-left' style={{ width: '120px' }} />
            </div>

            <div
              className={this.state.hideMap ? 'collapsed ' : 'col-md-10 '}
              id='sidebar'
              style={{ padding: 0 }}>
              <SoekEnheterKart
                id='kart'
                ref={(ref) => (this.kart = ref)}
                enheter={this.props.enheter}
                onMarkerClick={(status, id) => this.markerClick(status, id)}
                kartfra='Soekenheter'
                onBrukPolygon={this.onBrukPolygon}
                onPolygonFjernet={this.onPolygonFjernet}
                onBoundsFilterBruk={this.onBoundsFilterBruk}
                visMatrikkelInfo={(item) => this.visMatrikkelInfo(item)}
                visArbeidslisteInfo={(item) => this.visArbeidslisteInfo(item)}
                visSituasjonInfo={(item) => this.onClickSituasjon(item, false)}
                onVisGebyr={this.onVisGebyrFraKart}
              />
              {/* <KartEnheter
                id="kart"
                ref={ref => (this.kart = ref)}
                addToWorklist={this.addToWorklist}
                enheter={this.props.enheter}
                geoObjekt={this.props.geoObjekt}   
                valgteobjekter={this.props.valgteobjekter}
                onMarkerClick={(status, id) => this.markerClick(status, id)}
                kartfra="Soekenheter"
                onBrukPolygon={this.onBrukPolygon}
                onBoundsFilter={this.onBoundsFilter}
                onPolygonFjernet={this.onPolygonFjernet}  
                onBoundsFilterBruk={this.onBoundsFilterBruk}
                onBrukPrecision={this.onBrukPrecision}
                visMatrikkelInfo={item => this.visMatrikkelInfo(item)}
                visSituasjonInfo={item => this.onClickSituasjon(item, true)}
                visArbeidsListeInfo= {item => this.onVisArbeidslisteinfo(item, true)}     
                visLeggTilKnapp={false}
                visMatrikkelKnapp={true}
                visSituasjonKnapp={true}
                visArbeidsListeKnapp={true}
              /> */}
            </div>
          </div>
        </div>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default Bruksenhetstabell;
