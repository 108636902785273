import React from 'react';
import NKModal from './NKModal.jsx';
import { PropTypes } from 'prop-types';

class infoDialog extends React.Component {
    static propTypes = {
        melding: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired
    };

    state = { isOpen: true };

    closeModal = () => {
        this.props.isOpen = false; 
    };

    render() {

        return (            
                <NKModal size='small'
                    isOpen={this.props.isOpen}                    
                    >
                    <div className="modal-content">
                        <div className="modal-header modal-header-info">
                            <h4 className="modal-title">{this.props.tittel}</h4>                            
                        </div>
                        <div className="modal-body">
                           <p>{this.props.melding} </p>
                        </div>
                        <div className="modal-footer">
                            <button style={{ width: '60px', height: '48px' }} className="btn btn-success pull-right" onClick={this.props.onClose}>OK</button>
                        </div>

                    </div>
                </NKModal>            
        );
    }
}

export default infoDialog;