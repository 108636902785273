/* eslint-disable radix */
import moment from 'moment';
import * as _ from 'lodash';
import React from 'react';
import { SearchkitComponent } from 'searchkit';
import ReactTable from '@norkart/react-table';
import '@norkart/react-table/dist/react-table.css';
import 'css/generated/sokModal.css';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import { getAdresse } from 'modules/felles/AdresseHelper';
import Matrikkelinfo from 'modules/felles/Matrikkelinfo.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import CONFIG from 'lib/CONFIG';
import { search } from 'utils/searchKitHelper';
import { Pagination } from 'searchkit';
import VelgRoyklop from './VelgRoyklop.jsx';
import DialogStore from 'lib/DialogStore';
// Utils
import { makeLockReasonsTooltip } from '../lib';
import { debugFactory, objGetDeep } from 'utils';

const debug = debugFactory(
  'nk:planlegging:arbeidsliste:leggtilobjekter:panelroyklop'
);

let kartTekst = 'Vis kart';

const modalDialogs = {
  VelgRoyklopDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen} onClose={onClose}>
      {' '}
      {/* //large-wide xl  ??*/}
      <VelgRoyklop isOpen={isOpen} onClose={onClose} item={dialogData.item} />
    </NKModal>
  ),
};

class PanelRoyklop extends SearchkitComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      hideMap: false,
      visKartTekst: kartTekst,
      visMatrikkelinfo: false,
      matrikkelinfoId: null,
      bygningsNr: null,
      columns: [],
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });

    this.rowClick = this.rowClick.bind(this);
    this.onVisKartClicked = this.onVisKartClicked.bind(this);
    this.onVisMatrikkelinfo = this.onVisMatrikkelinfo.bind(this);
    this.onBoundsFilter = this.onBoundsFilter.bind(this);
    this.onBrukPolygon = this.onBrukPolygon.bind(this);
    this.onPolygonFjernet = this.onPolygonFjernet.bind(this);
    this.onBoundsFilterBruk = this.onBoundsFilterBruk.bind(this);
    this.onBrukPrecision = this.onBrukPrecision.bind(this);
    this.addToWorklist = this.addToWorklist.bind(this);
    this.shouldHideMap = this.shouldHideMap.bind(this);
    this.onPolygonFilterRemoved = this.onPolygonFilterRemoved.bind(this);
    this.oppdaterLagrede = this.oppdaterLagrede.bind(this);
    // Flemming, quick & dirty
    this.RT_columns = get_RT_columns.call(this);
  }

  componentDidMount() {
    //  debug('componentDidMount');
    window.addEventListener('resize', this.shouldHideMap);
    this.setState({ columns: this.RT_columns.getAdresseColumns() });
  }

  componentWillUnmount() {
    // debug('componentWillUnmount');
    //  window.removeEventListener('resize', this.shouldHideMap);
    // this.props.searchkit.geoAccessor.setPolygon(null);
    // this.props.searchkit.reloadSearch(); // @rieni: fjern denne!
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.removed !== 0) {
      let k = this.kart;
      k.setMarkerBlueFraArbeidslisteRemoved(nextProps.removed);
    }
  }

  oppdaterLagrede() {
    let k = this.kart;
    k.oppdaterLagrede();
  }

  shouldHideMap() {
    this.setState({
      hideMap: !window.matchMedia('(min-width: 1200px)').matches,
    });
  }

  onPolygonFilterRemoved() {
    let k = this.kart;
    if (k !== null) {
      k.fjernAllePolygonLayers(); //Rieni
    } else {
      // debug('Feil PanelRøykløp', 'onPolygonFilterRemoved');
    }
  }

  onVisMatrikkelinfo(item) {
    let bygg = item.bygg ? item.bygg : item.source.bygg;
    this.setState({
      visMatrikkelinfo: true,
      matrikkelinfoId: item.matrikkelId,
      bygningsNr: bygg.bygningsnummer,
    });
  }

  onVisKartClicked() {
    this.setState({
      hideMap: !this.state.hideMap,
    });
  }

  //BOUNDSFILTER *********
  onBoundsFilter(boundsfilter) {
    //this.props.geoAccessor.setArea(boundsfilter);
    search(this.searchkit);
  }
  onBoundsFilterBruk(bruk) {
    if (!bruk) {
      //this.props.geoAccessor.setArea(null);
      search(this.searchkit);
    }
  }
  //POLYGON **************
  onBrukPolygon(poly) {
    this.props.searchkit.geoAccessor.setPolygon(
      poly,
      this.onPolygonFilterRemoved
    );
    search(this.searchkit);
  }
  onPolygonFjernet() {
    this.props.searchkit.geoAccessor.setPolygon(null);
    search(this.searchkit);
  }

  //geoHash Precision
  onBrukPrecision(p) {
    if (this.props.geoAccessor) {
      this.props.geoAccessor.setPrecision(p);
    }
  }

  //Preview spretter
  rowClick(row, e) {
    /*   if (!this.state.hideMap) {
      let k = this.kart;
      k.visPreviewObjekt(row, true);
    } */
    this.props.onSpretter(row);
  }
  // F L E M M I N G

  rowFormat = (row) => {
    let ff = _.get(row, 'source.skybrannData.bruksenhet.fyringsforbud');

    if (ff) {
      let blueClass = ' fyringsforbudBackground ';
      let blueTekst = ' - Fyringsforbud';
      return { tooltip: blueTekst, className: blueClass };
    }

    if (_.isUndefined(row.lockReasons)) {
      row.lockReasons = this.props.getObjektLockReasons(row);
    }
    if (row.lockReasons) {
      return {
        tooltip: makeLockReasonsTooltip(row.lockReasons),
        className: 'missingSkybranndata',
      };
    } else {
      return {
        className: 'harSkybranndata',
      };
    }
  };

  isRowSelectable = (row) => {
    // NOTE! See `rowFormat`
    return !row.lockReasons;
  };

  getValgteEnheter(objektliste) {
    objektliste = objektliste.filter((item) => item.slett === false);

    const valgteEnheter = this.props.enheter.filter((parsedHit) => {
      const objekt = objektliste.find((objekt) => {
        const found = !!(objekt.royklopId === parsedHit.royklopId);
        found &&
          debug(
            'getValgteEnheter',
            objekt.royklopId,
            parsedHit.royklopId,
            objekt.royklopId === parsedHit.royklopId,
            {
              objekt,
              parsedHit,
            }
          );
        return found;
      });
      //debug('getValgteEnheter', '-------');
      return !!objekt;
    });

    /*   debug('getValgteEnheter:done', {
      enheter: this.props.enheter,
      objektliste,
      valgteEnheter,
    }); */

    return valgteEnheter;
  }

  velgFraKart = (data) => {
    this.addToWorklist(data);
  };

  onRowSelectionChange = ({ prevSelectedRows, selectedRows }) => {
    /*   debug('onRowSelectionChange onRow prev', prevSelectedRows);
    debug('onRowSelectionChange onRow sel', selectedRows); */
    const objektliste = this.props.getTiltakListe({ royklop: true });
    let updatedObjektliste;

    /*     debug('onRowSelectionChange', {
      selectedRows: selectedRows.length,
      prevSelectedRows: prevSelectedRows.length,
    }); */

    if (selectedRows.length > prevSelectedRows.length) {
      // Adding newly selected
      const nyeObjekter = selectedRows
        .filter((row) => !this.props.getObjektLockReasons(row))
        .map((row) => {
          let objekter = objektliste.filter(
            // eslint-disable-next-line radix
            (objekt) => parseInt(objekt.royklopId) === parseInt(row.id)
          );

          if (!_.isEmpty(objekter)) {
            // Existing objekt flagged to delete
            objekter.forEach((objekt) => {
              // Reset to-delete flag
              objekt.slett = false;
            });
            // Return false to skip adding `row` to `nyeObjekter`
            return false;
          } else {
            // Make a new `objekt`
            const template = objektliste.find(
              (objekt) => objekt.bruksenhetId === row.bruksenhetId
            );
            debug('onRowSelectionChange row', row);
            const objekt = {
              ..._.cloneDeep(template),
              // `row` is parsed & transformed ES Searchkit-item (See "./LeggTilBruksenheter:PanelRoyklop")
              ..._.cloneDeep(row),
              // NOTE! Messy stuff below! Temper with it at your own risk, cause it might be used elsewhere "as-is"
              id: undefined,
              adresse: getAdresse(row),
              ny: true,
              slett: false,
              lokasjon:
                row.source !== undefined
                  ? row.source.bygg.lokasjon
                  : row.bygg.lokasjon,
              tiltakTypeId: undefined,
              tiltakTypetekst: undefined,
              // Delt royklop
              bruksenhetIdDelerLop: undefined,
            };
            return objekt;
          }
        })
        .filter((objekt) => objekt);

      /*     debug('onRowSelectionChange objekter', objektliste);
      debug('onRowSelectionChange nyeObjekter', nyeObjekter); */
      updatedObjektliste = objektliste.concat(nyeObjekter);
    } else if (selectedRows.length < prevSelectedRows.length) {
      // Removing deselected
      const removedRoyklopIds = _.uniqBy(
        _.difference(prevSelectedRows, selectedRows),
        'royklopId'
      ).map((row) => row.royklopId);

      updatedObjektliste = objektliste
        .map((objekt) => {
          if (this.props.getObjektLockReasons(objekt)) {
            // Locked => not deselectable
            return objekt; // Still selected
          }

          if (removedRoyklopIds) {
            if (removedRoyklopIds.includes(objekt.royklopId)) {
              // TODO! Not working (@Rieni)
              // this.kart.setMarkerBlueFraArbeidslisteRemoved(objekt.id);

              if (objekt.ny) {
                // Not in DB, return false to just filter it away next
                return false;
              } else {
                // Flag for Db deletion
                objekt.slett = true;
              }
            }
          }
          return objekt;
        })
        .filter((objekt) => objekt);
    }

    this.props.setArbeidslisteTiltakLister({
      tiltakTypeRoyklopListe: updatedObjektliste,
    });
  };

  addToWorklist(object) {
    let objektliste = this.props.getTiltakListe({ royklop: true });
    let rPlassering = '';
    let rType = '';
    if (_.get(object, 'source.skybrannData.royklop')) {
      let liste = object.source.skybrannData.royklop;
      for (let i = 0; i < liste.length; i++) {
        if (liste[i].id === object.royklopId.toString()) {
          rPlassering = liste[i].plassering;
          rType = liste[i].type;
          break;
        }
      }
    }

    if (!object.royklopId) {
      alert('Enheten har ikke røykløp tilknyttet..');
      return;
    }
    let row = {
      adresse: object.adresse,
      bruksenhetId: object.bruksenhetId,
      royklopId: object.royklopId,
      id: object.id,
      lockReasons: false,
      endre: false,
      ny: true,
      slett: false,
      valgt: true,
      plassering: rPlassering,
      type: rType,
     // source: object.source,
    };

    objektliste.push(row);
    this.props.setArbeidslisteTiltakLister({
      tiltakTypeRoyklopListe: objektliste,
    });
  }

  addToWorklistNew(object) {
    let objektliste = this.props.getTiltakListe({ royklop: true });
    let rId = null;
    if (_.get(object, 'source.skybrannData.royklop')) {
      if (object.source.skybrannData.royklop.length > 1) {
        this.dialogs.setOpenDialog('VelgRoyklopDialog', {
          item: object,
        });
      } else {
        if (object.source.skybrannData.royklop[0]) {
          rId = parseInt(object.source.skybrannData.royklop[0].id);
        }
        if (!rId) {
          alert('Enheten har ikke røykløp tilknyttet..');
          return;
        }
        let row = {
          adresse: object.adresse,
          bruksenhetId: object.bruksenhetId,
          royklopId: rId,
          id: rId,
          lockReasons: false,
          endre: false,
          ny: true,
          slett: false,
          valgt: true,
          source: object.source,
        };
        debug('onRowSelectionChange row', row);
        objektliste.push(row);
        debug('onRowSelectionChange liste', objektliste);
      }
    }

    this.props.setArbeidslisteTiltakLister({
      tiltakTypeRoyklopListe: objektliste,
    });
  }

  setColumns = (modus) => {
    if (modus === 'stor') {
      this.setState({ columns: this.RT_columns.getAllColumns() });
    } else {
      this.setState({ columns: this.RT_columns.getAdresseColumns() });
    }
  };

  render() {
    //   debug('render', { ...this });

    const objektliste = this.props.getTiltakListe({ royklop: true });

    const valgteEnheter = this.getValgteEnheter(objektliste);

    return (
      <div>
        <div
          className='font-14'
          style={{
            height: 'calc(100vh - 274px)',
            overflow: 'auto',
            overflowX: 'hidden',
          }}>
          {/*     <span style= {{ height: '20px', fontSize: '14px', marginLeft: '16px'}}>{this.props.antallroyklopfunnet}</span> */}
          <ReactTable
            idProperty='tableId'
            showIndex={false}
            items={this.props.enheter}
            columns={this.state.columns}
            selection={valgteEnheter}
            toggleAll={true}
            selectableRows={true}
            rowFormat={this.rowFormat}
            isRowSelectable={this.isRowSelectable}
            onRowSelectionChange={(selectedRows) =>
              this.onRowSelectionChange({
                selectedRows,
                prevSelectedRows: valgteEnheter,
              })
            }
            onRowClick={this.rowClick}
            itemsPrPage={0}
          />
        </div>

        {/* <Pagination className="pull-left" /> */}
        <ModalContainer
          size='medium'
          buttonText={'Lukk'}
          tittel='Matrikkelinfo'
          isOpen={this.state.visMatrikkelinfo}
          onClose={() => {
            this.setState({ visMatrikkelinfo: false });
            this.props.reloadSearch();
          }}>
          <Matrikkelinfo
            id={this.state.matrikkelinfoId}
            bygningsNr={this.state.bygningsNr}
          />
        </ModalContainer>
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default PanelRoyklop;

// Declarative stuff

const get_RT_columns = function() {
  function getAdresseColumns() {
    return [
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            // (CONFIG.debugMode ? `${item.tableId}: ` : '') +
            getAdresse(item.source)
          );
        },
      },
    ];
  }

  function getAllColumns() {
    let doColor = (id, rad) => {
      let res = 'black';
      if (id !== rad.royklopId) {
        res = 'gray';
      }
      return res;
    };
    let doStyle = (id, rad) => {
      let res = { color: 'black', fontWeight: 'bold' };
      if (id !== rad.royklopId) {
        res = { color: 'gray' };
      }
      return res;
    };
    return [
      {
        id: 'kommune',
        name: 'Kommune',
        sortParams: 'kommuneNavn',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.kommuneNavn;
        },
      },
      {
        id: 'adresse',
        name: 'Adresse',
        sortParams: 'gatenavn',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            // (CONFIG.debugMode ? `${item.tableId}: ` : '') +
            getAdresse(item.source)
          );
        },
      },

      {
        id: 'bretype',
        name: 'Bruksenhetstype',
        sortParams: 'bretype',
        filterable: true,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          let bet = '';
          if (_.has(item, 'source.bruksenhetstype')) {
            bet = item.source.bruksenhetstype;
          }
          return bet;
        },
      },

      {
        id: 'plassering',
        name: 'Plassering',
        sortParams: 'plassering',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.plassering;
        },
      },
      {
        id: 'type',
        name: 'Type',
        sortParams: 'type',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.type;
        },
      },
      {
        id: 'nesteTiltak',
        name: 'Tiltak',
        sortParams: 'nesteTiltak',
        filterable: false,
        isSorted: false,
        isVisible: false,
        sortable: false,
        formatter: function(item) {
          if (
            _.isEmpty(item.source.skybrannData) ||
            _.isEmpty(item.source.skybrannData.royklopTiltakType)
          ) {
            return '';
          }

          let id = item.royklopId.toString();

          if (item.source.skybrannData.royklopTiltakType.length > 1) {
            item.flere = item.source.skybrannData.royklopTiltakType;
          }
          let arrayTiltak = [];
          for (
            let i = 0;
            i < item.source.skybrannData.royklopTiltakType.length;
            i++
          ) {
            let x = item.source.skybrannData.royklopTiltakType[i];
            if (x.royklopId.toString() === id) {
              arrayTiltak.push(x);
            }
          }
          arrayTiltak = _.orderBy(arrayTiltak, ['nesteDato'], ['asc']);
          if (arrayTiltak === undefined || arrayTiltak.length === 0) {
            return '';
          } else {
            let tiltakstype = arrayTiltak[0].tiltakType;
            item.tiltakType = tiltakstype;
            item.nesteDato = arrayTiltak[0].nesteDato;
            return tiltakstype;
          }
        },
      },
      /*   {
        id: 'antattDato',
        name: 'Neste dato',
        sortParams: 'antattDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return item.nesteDato
            ? moment(item.nesteDato).format('DD.MM.YYYY')
            : '';
        }
      } */
      {
        id: 'antattDato',
        name: 'Neste dato',
        sortParams: 'antattDato',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          if (item.flere) {
            let id = item.royklopId.toString();
            let liste = _.orderBy(item.flere, ['nesteDato'], ['asc']);

            let filterliste = liste.filter((x) => x.royklopId === id);
            var rader = filterliste.map((rad, i) => (
              <tr key={'tr_' + i} title={'RøykløpId: ' + rad.royklopId}>
                <td>
                  {rad.nesteDato
                    ? moment(rad.nesteDato).format('DD.MM.YYYY')
                    : ''}
                </td>
                <td style={{ paddingLeft: '4px' }}>{rad.tiltakType}</td>{' '}
                {/* , color: doColor(id, rad) */}
              </tr>
            ));
            return (
              <div>
                <table>
                  <tbody>{rader}</tbody>
                </table>
              </div>
            );
          } else {
            return (
              <span>
                {item.nesteDato
                  ? moment(item.nesteDato).format('DD.MM.YYYY')
                  : ''}{' '}
                {item.tiltakType}
              </span>
            );
          }
        },
      },
    ];
  }

  return {
    getAdresseColumns,
    getAllColumns,
  };
};
