import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { createPersonInnlogging } from 'api/innloggingApi';
import * as _ from 'lodash';

class NyBruker extends React.Component {
  constructor() {
    super();
    this.state = {
      person: { aktiv: false }
    };    
    this.onPersonCreated = this.onPersonCreated.bind(this);
  }
  
  onPersonCreated(err, result) {
    if (!err) {
      alert(
        'Din bruker er nå registrert i brannforebygging.\nDu må kontakte administrator for aktivering av brukerkontoen.\nDu får denne meldingen fordi det er første gang du logger inn.'
      );
      this.props.closeModal();
    }
    else
    {
        alert('En feil oppstod ved lagreing, vennligst prøv på nytt.\n' + err.response);
    }
  }


  render() {
    let classNameButton = this.state.person.navn
      ? 'btn btn-primary'
      : 'btn btn-primary disabled';

    return (
      <NKModal size="medium" isOpen={this.props.isOpen}>
        <div className="modal-content">
          <div className="modal-header modal-header-info">
          <button className="close" onClick={this.props.onClose} type="button">
               <span>x</span>
               <span className="sr-only">Lukk</span>
            </button>
            <h4 className="modal-title">Registrer ny bruker</h4>
          </div>
          <div className="modal-body">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="form">
                  <div className="row">
                    <div className="col-sm-3">
                      <label>Navn</label>
                    </div>
                    <div className="col-sm-9 form-group">
                      <input                      
                        autoFocus                        
                        maxLength="250"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              navn: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3 form-group">
                      <label>Telefonnummer</label>
                    </div>
                    <div className="col-sm-9 form-group">
                      <input
                        placeholder="Eks: 111 22 333"
                        maxLength="50"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              telefon: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3 form-group">
                      <label>Mobilnummer</label>
                    </div>
                    <div className="col-sm-9 form-group">
                      <input
                        placeholder="Eks: 111 22 333"
                        maxLength="50"
                        className="form-control"
                        type="text"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              mobil: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3 form-group">
                      <label>Epost</label>
                    </div>
                    <div className="col-sm-9 form-group">
                      <input
                        placeholder="Eks: ola.nordmann@gmail.com"
                        maxLength="50"
                        className="form-control"
                        type="email"
                        onChange={e => {
                          this.setState({
                            person: {
                              ...this.state.person,
                              epost: e.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary pull-right"
              onClick={() =>
                createPersonInnlogging(this.state.person, this.onPersonCreated)
              }>
              Registrer
            </button>
          </div>
        </div>
      </NKModal>
    );
  }
}
export default NyBruker;
