import React from 'react';
import styled from 'styled-components';
import NKIcon from 'nka-icons';
import NkaButton from 'nka-button';
import ContextMenu from 'components/ContextMenu';
import { 
    debugFactory, 
    tuplesToString,
    objOmitProps, 
} from 'utils';

const debug = debugFactory('nk:components:generics:button');

export const Button = ({ color='black', icon, children, ...props }) => {
    debug(props);
    // Remove surplus props
    props = objOmitProps(props, 'noBorder', 'padIcon', 'buttonType');
    return (
        <button {...props}>
            {(icon && <NKIcon icon={icon} color={color} className="v-mid" />)}
            {(children && <span className="v-mid">{children}</span>)}
        </button>
    );
};

export const withButtonStyle = (wrappedComponent) => styled(wrappedComponent).attrs({
    className: ({ noBorder, children, buttonType }) => `
        pa2 bg-white 
        ${tuplesToString([children, 'pl0'])}
        ${buttonType == 'primary' ? 'white bg--nk-green b--nk-light-green' : 'black bg--nk-white b--nk-light-gray'}
        ba ${noBorder ? 'bw0' : 'bw1'}
    `
})`
    &:hover:not([disabled]) {
        ${({ buttonType }) => buttonType == 'primary' 
            ? 'border-color : var(--nk-green)'
            : 'border-color: var(--nk-dark-gray)'}          
    }
    &:disabled {
        opacity: 0.5;
    }
    &>svg {
        ${({ padIcon }) => tuplesToString(padIcon, `
            padding: 0 2px;   
            margin-left: 3px;
        `)}
    }
`;

let style = { marginLeft: '0px', marginTop: '0px', marginRight: '3px', marginBottom: '0px' };
let styleDrop = { marginLeft: '0px', marginTop: '0px', marginRight: '0px', marginBottom: '0px'  };
export const ToolbarButton = ({ onClick, children, icon, ...props }) => {
    return <NkaButton {...props} style={style} icon={icon} onClick={onClick}>{children}</NkaButton>;    
};

export const ToolbarButtonDrop = ({ onClick, children, icon, ...props }) => {
    return <NkaButton {...props} className='dropdown-toggle' style={styleDrop} icon={icon} onClick={onClick}>{children}</NkaButton>;    
};

let styleRed = { marginLeft: '0px', marginTop: '0px', marginRight: '3px', marginBottom: '0px', backgroundColor: '#f1abaa' };
export const ToolbarButtonRed = ({ onClick, children, icon, ...props }) => {
    return <NkaButton {...props} style={styleRed} icon={icon} onClick={onClick}>{children}</NkaButton>;    
};

let styleBlue = { marginLeft: '0px', marginTop: '0px', marginRight: '3px', marginBottom: '0px', backgroundColor: '#d0def5' };
export const ToolbarButtonBlue = ({ onClick, children, icon, ...props }) => {
    return <NkaButton {...props} style={styleBlue} icon={icon} onClick={onClick}>{children}</NkaButton>;    
};

let styleRedLi = { cursor: 'pointer', backgroundColor: 'red' };
export const LiButton = ({ onClick, children, ...props }) => {
    return <li {...props} style={styleRedLi} onClick={onClick}>{children}</li>;    
};

let styleRedDiv = { cursor: 'pointer', backgroundColor: 'red' };
export const ConItem = ({ onClick, children, ...props }) => {
    return <div style={styleRedDiv} onClick={onClick}>{children}</div>;    
};

/* let styleBlueDiv = { cursor: 'pointer', backgroundColor: 'd0def5' };
export const ConItemPilot = ({ onClick, children, ...props }) => {
    return <div style={styleBlueDiv} onClick={onClick}>{children}</div>;    
}; */

export const ToolbarButtonRed2 = ({ onClick, children, icon, ...props }) => {
    return <NkaButton {...props} icon={icon} onClick={onClick}>{children}</NkaButton>;    
};
export default withButtonStyle(Button);
