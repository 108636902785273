
import { debugFactory } from 'utils';

const debug = debugFactory('nk:component:router');

// Re-export for the simplicity of getting all we need with just one `import`-statement
export {
    Router,
    Route,
    withRouter
} from 'react-router';
export  { asRouteComponent } from 'lib/enhancers';

export const RouteWrapper = (props) => {
    debug('RouteWrapper', 'Used, but NEVER CALLED!', { ...props });
    return props.children || '';
}


