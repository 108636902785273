import React from 'react';
import IndexRoute from 'react-router/lib/IndexRoute';
import hashHistory from 'react-router/lib/hashHistory';
import { Router, Route } from 'components/Router';
import MainLayout from 'components/MainLayout';
import Home from 'modules/Home';
import { _404 } from 'components/exceptions/ErrorPages';
import LoginScreen from 'components/LoginScreen';
import LoadingOverlay from 'components/generics/LoadingOverlay';
import requiresAuth from 'auth/requiresAuth';
import PlanleggingRoutes from './modules/Planlegging/routes';
import GjennomforingRoutes from './modules/Gjennomforing/routes';
import OppfolgingRoutes from './modules/Oppfolging/routes';
import ObjekterRoutes from './modules/Objekter/routes';
import InnstillingerRoutes from './modules/Innstillinger/routes';
import AdminRoutes from './modules/Admin/routes';
import InfoRoutes from './modules/Infosider/routes';
import RapportRoutes from './modules/Rapporter/routes';
import ProsessloggRoutes from './modules/Prosesslogg/routes';
import Tilgang from 'modules/Tilgang';

const LoadingScreen = () => (
  <LoadingOverlay isLoading={true} marquee='Logger inn, vennligst vent...' />
);

export default (
  <Router history={hashHistory}>
    <Route
      path='/'
      name={'Brannforebygging'}
      component={requiresAuth(MainLayout, { LoadingScreen, LoginScreen })}>
      <IndexRoute component={Home} />
      {PlanleggingRoutes}
      {GjennomforingRoutes}
      {OppfolgingRoutes}
      {ObjekterRoutes}
      {InnstillingerRoutes}
      {RapportRoutes}
      {AdminRoutes}
      {InfoRoutes}
      {ProsessloggRoutes}
      
      <Route
        path='tilgang'
        //component={enhanced.Tilgang}
        component={Tilgang}
        name='Tilgang'
        icon='bruker'
        inMenu={false}
        login={true}
        roles={[]}
      />
      <Route path='*' name='404' component={_404} />
    </Route>
  </Router>
);
