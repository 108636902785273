import React from 'react';
import { withRouter } from 'lib/enhancers';
import ReactTable from '@norkart/react-table';
import 'moment/locale/nb';
import 'react-datepicker/dist/react-datepicker.css';
import ContextMenu from 'components/ContextMenu';
import royklopApi from 'api/royklopApi';
import enhetApi from 'api/enhetApi';
import NKIcon from 'nka-icons';
import MeldingDialog from 'modules/Gjennomforing/Tiltakstyper/melding.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import EndreTiltakshistorikk from './EndreTiltakshistorikk.jsx';
import NyTiltakshistorikk from './NyTiltakshistorikk.jsx';
import NyOppdaterTiltaktype from '../Tiltak/NyOppdaterTiltaktype.jsx';
import ModalContainer from 'components/Dialogs/ModalContainer.jsx';
import DialogStore from 'lib/DialogStore';
require('@norkart/react-table/dist/react-table.css');


var meldingHeader = '';
var melding = '';
var valgt = null;
var valgtId = null;

const modalDialogs = {
    'MeldingDialog': ({ isOpen, onClose, dialogData, owner }) => {
      return (
        <NKModal size="small" isOpen={isOpen}>
          <MeldingDialog
            onClose={onClose}
            onOK={owner.closeMelding}
            meldingHeader={dialogData.meldingHeader}
            melding={dialogData.melding}
          />
        </NKModal>
      );
    },
    modalEndreTiltakHistorikk: ({ isOpen, onClose, dialogData, owner }) => {
      return (
        <ModalContainer
          isOpen={isOpen}
          size="large"
          tittel="Endre tiltakhistorikk"
          onClose={onClose}>
          <EndreTiltakshistorikk id = {dialogData.id} valgt = {dialogData.valgt} type = {dialogData.type} onCloseModal={owner.closeModalFraEndre}/>
        </ModalContainer>
      );
    },
    modalNyTiltakHistorikk: ({ isOpen, onClose, dialogData, owner }) => {
      return (
        <ModalContainer
          isOpen={isOpen}
          size="large"
          tittel="Nytt tiltak bruksenhet"
          onClose={onClose}>
          <NyTiltakshistorikk id = {dialogData.id}  type = {dialogData.type} bruksEnhetId = {dialogData.bruksEnhetId} items = {dialogData.items} onCloseModal={owner.closeModalFraNy}/>
        </ModalContainer>
      );
    },
    'NyOppdaterTiltaktype': ({ isOpen, onClose, dialogData, owner }) => {
        return (
          <NKModal size="medium" isOpen={isOpen}>
            <NyOppdaterTiltaktype
              onClose={onClose}
              statusTiltaktype="slett"
              onNyEndreTiltaktypeOK={owner.onNyEndreTiltaktypeOK}
              lagretTiltak={null}
              tiltakType={dialogData.tiltakType}
              type={dialogData.type}
              tiltakNavn={dialogData.tiltakNavn}
            />
          </NKModal>
        );
      }
  };

class Tiltakshistorikk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      slett: false,
      tiltakType: null,
      tiltakNavn: null,
      laster: true
    };
    this.dialogs = new DialogStore({
        owner: this,
        dialogs: modalDialogs
      });
  }
  componentDidMount() {
    if (this.props.type === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakHistorikk(this.props.id, this.gotHistorikk);
    } else {
      royklopApi.getRoyklopTiltakHistorikk(this.props.id, this.gotHistorikk);
    }
  }

  gotHistorikk = (err, historikk) => {
    this.setState({ items: historikk });
    this.setState({ laster: false });
  };

  ny = () => {
    this.dialogs.setOpenDialog('modalNyTiltakHistorikk', { id: this.props.id, type: this.props.type, bruksEnhetId: this.props.id, items: this.state.items });
  };

  endre = item => {
    valgt = item;
    valgtId = item.id;
    this.setState({ tiltakNavn: item.tiltak });
    this.dialogs.setOpenDialog('modalEndreTiltakHistorikk', { id: this.props.id, valgt: valgt, type: this.props.type });
  
};

closeModalFraNy = (tiltakNavn, enhettiltaktype, risiko) => {
  this.dialogs.closeDialog();
  if (this.props.type === 'bruksenhet') {
    enhetApi.getBruksenhetTiltakHistorikk(this.props.id, this.gotHistorikk);
  } else {
    royklopApi.getRoyklopTiltakHistorikk(this.props.id, this.gotHistorikk);
  }
  if (risiko) {
    this.dialogs.setOpenDialog('MeldingDialog', { melding: 'Objektet er underlagt risikovurdering. Hyppighet og neste dato vil bli oppdatert på bakgrunn av dette.', meldingHeader: 'Tiltak' });
  } else {
    this.dialogs.setOpenDialog('MeldingDialog', { melding: 'Antatt dato for neste ' + tiltakNavn + ' er beregnet til ' + enhettiltaktype.nesteDato.substring(8, 10) + '.' + enhettiltaktype.nesteDato.substring(5, 7) + '.' + enhettiltaktype.nesteDato.substring(0, 4), meldingHeader: 'Tiltak' });
  }
}

closeModalFraEndre = (tiltakNavn, enhettiltaktype, risiko) => {
  this.dialogs.closeDialog();
  if (this.props.type === 'bruksenhet') {
    enhetApi.getBruksenhetTiltakHistorikk(this.props.id, this.gotHistorikk);
  } else {
    royklopApi.getRoyklopTiltakHistorikk(this.props.id, this.gotHistorikk);
  }
  if (risiko) {
    this.dialogs.setOpenDialog('MeldingDialog', { melding: 'Objektet er underlagt risikovurdering. Hyppighet og neste dato vil bli oppdatert på bakgrunn av dette.', meldingHeader: 'Tiltak' });
  }
  // Kommetert ut, har ikke neste dato her
  // else {
  //   this.dialogs.setOpenDialog('MeldingDialog', { melding: 'Antatt dato for neste ' + this.state.tiltakNavn + ' er beregnet til ' + enhettiltaktype.nesteDato.substring(8, 10) + '.' + enhettiltaktype.nesteDato.substring(5, 7) + '.' + enhettiltaktype.nesteDato.substring(0, 4), meldingHeader: 'Tiltak' });
  // }
}

  slett = item => {
    valgt = item;
    valgtId = item.id;
    this.setState({ tiltakNavn: item.tiltak });
    melding = 'Du sletter nå valgt historisk tiltak. Dette påvirker ikke eventuelle regelmessige tiltak på enheten. Vil du fortsette?';
    meldingHeader = 'Slett historisk tiltak';
    this.setState({ slett: true });
    this.dialogs.setOpenDialog('MeldingDialog', { melding: melding, meldingHeader: meldingHeader });
  };

  bekreftSlett = () => {
    if (this.props.type === 'bruksenhet') {
      enhetApi.slettTiltakBruksenhet(valgtId, this.onTiltakSlettet);
      // enhetApi.getTiltakTypeForBruksenhet(
      //   valgt.tiltakTypeId,
      //   this.props.id,
      //   this.tiltakTypeSjekket
      // );
    } else {
      royklopApi.slettTiltakRoyklop(valgtId, this.onTiltakSlettet);
      // enhetApi.getTiltakTypeForRoyklop(
      //   valgt.tiltakTypeId,
      //   this.props.id,
      //   this.tiltakTypeSjekket
      // );
    }
  };

  // tiltakTypeSjekket = (err, enhettiltaktype) => {
  //   this.setState({ tiltakType: enhettiltaktype });
  //   if (enhettiltaktype !== null) {
  //     this.dialogs.setOpenDialog('NyOppdaterTiltaktype', { tiltakType: this.state.tiltakType,
  //              type: this.props.type,
  //              tiltakNavn: this.state.tiltakNavn });
  //   }
  // };

  closeNyEndreTiltaktype = () => {
    this.dialogs.closeDialog();
  };

  onNyEndreTiltaktypeOK = () => {
    this.dialogs.closeDialog();
  };

  onTiltakSlettet = () => {
    if (this.props.type === 'bruksenhet') {
      enhetApi.getBruksenhetTiltakHistorikk(this.props.id, this.gotHistorikk);
    } else {
      royklopApi.getRoyklopTiltakHistorikk(this.props.id, this.gotHistorikk);
    }
  };

  closeMelding = () => {
    if (this.state.slett) {
      this.bekreftSlett();
    }
    this.setState({ slett: false });
    this.dialogs.closeDialog();
  };

  avbryt = () => {
    this.setState({ slett: false });
    this.dialogs.closeDialog();
  };

  getColumnsRoyklop = () => {
    return [
      {
        id: 'dato',
        name: 'Registrert',
        sortParams: 'dato',
        isSorted: false,
        filterable: false,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.dato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        }
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'utfort',
        name: 'Utført',
        sortParams: 'utfort',
        filterable: true,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.utfort) {
            return React.createElement(
              'div',
              { style: { color: 'green' } },
              'Ja'
            );
          } else {
            return React.createElement(
              'div',
              { style: { color: 'red' } },
              'Nei'
            );
          }
        }
      },
      {
        id: 'utfortAv',
        name: 'Utført av',
        sortParams: 'utfortAv',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'arsakIkkeUtfort',
        name: 'Årsak ikke utført',
        sortParams: 'aarsakIkkeUtfort',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'hyppighet',
        name: 'Hyppighet',
        sortParams: 'hyppighet',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'sotmengde',
        name: 'Sotmengde',
        sortParams: 'sotmengde',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'arbeidslisteInfo',
        name: 'ArbeidslisteInfo',
        sortParams: 'arbeidslisteInfo',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre...
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett...
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  };

  getColumnsBruksenhet = () => {
    return [
      {
        id: 'dato',
        name: 'Registrert',
        sortParams: 'dato',
        filterable: false,
        formatter: function(item) {
          if (item.dato === null) {
            return '-';
          } else {
            var dato = new Date(item.dato);
            var dag = dato.getDate().toString();
            var mnd = (dato.getMonth() + 1).toString();
            var aar = dato.getFullYear().toString();
            if (dag.length === 1) {
              dag = '0' + dag;
            }
            if (mnd.length === 1) {
              mnd = '0' + mnd;
            }
            return dag + '.' + mnd + '.' + aar;
          }
        }
      },
      {
        id: 'tiltak',
        name: 'Tiltak',
        sortParams: 'tiltak',
        filterable: true,
        isSorted: false,
        sortable: true
      },
      {
        id: 'utfort',
        name: 'Utført',
        sortParams: 'utfort',
        filterable: true,
        isSorted: false,
        sortable: true,
        formatter: function(item) {
          if (item.utfort) {
            return React.createElement(
              'div',
              { style: { color: 'green' } },
              'Ja'
            );
          } else {
            return React.createElement(
              'div',
              { style: { color: 'red' } },
              'Nei'
            );
          }
        }
      },
      {
        id: 'utfortAv',
        name: 'Utført av',
        sortParams: 'utfortAv',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'arsakIkkeUtfort',
        name: 'Årsak ikke utført',
        sortParams: 'aarsakIkkeUtfort',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'hyppighet',
        name: 'Hyppighet',
        sortParams: 'hyppighet',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'arbeidslisteInfo',
        name: 'Arbeidslisteinfo',
        sortParams: 'arbeidslisteInfo',
        filterable: false,
        isSorted: false,
        sortable: true,
        hideInVertical: false
      },
      {
        id: 'meny',
        filterable: false,
        isSorted: false,
        sortable: false,
        formatter: function(item) {
          return (
            <ContextMenu.Button
              title="Meny"
              icon="meny"
              iconColor="nk-black"
              className="inlinebtn">
              <ContextMenu.Item onClick={this.endre.bind(this, item)}>
                Endre
              </ContextMenu.Item>
              <ContextMenu.Item onClick={this.slett.bind(this, item)}>
                Slett
              </ContextMenu.Item>
            </ContextMenu.Button>
          );
        }.bind(this)
      },
      {
          //Ekstra space ifm meny-button i mobil-grid
          formatter: function() {
              return ( 
                 <div>&nbsp;</div>
               );
          }
      }
    ];
  };

  render() {
    if (this.state.laster) {
      return <div className='loader' />;
    }

    var columns = null;
    if (this.props.type === 'bruksenhet') {
      columns = this.getColumnsBruksenhet();
    } else {
      columns = this.getColumnsRoyklop();
    }
    let pType = this.props.type;
    if (pType === 'royklop') {
      pType = 'røykløp';
    }
    return (
      <div>
        <h2>Tiltakshistorikk {pType}</h2>
        <hr />
        {this.props.base_path !== undefined ? (
          <div className="btn-toolbar">
            <button
              className="btn btn-default button-arbliste-enhet"
              onClick={this.ny}>
              <span className="pull-left">
                <NKIcon className="pull-left" icon="pluss" color="nk-black" />
              </span>
              <span className="pull-left margin-left-10 ">Nytt</span>
            </button>
          </div>
        ) : (
          <div />
        )}
        <p/>
        <ReactTable
          items={this.state.items}
          idProperty="id"
          showIndex={false}
          filterable={false}
          columns={columns}
          onRowClick={null}
        />
        {this.dialogs.renderOpenDialog()}
        
      </div>
    );
  }
}

export default withRouter(Tiltakshistorikk);
