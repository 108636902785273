import React from 'react';
import NKModal from 'components/Dialogs/NKModal.jsx';
import anmerkningApi from 'api/anmerkningApi';
import NKIcon from 'nka-icons';
import './AvvikAnmerkninger.css';
import AnmerkningForm from 'modules/felles/avvikAnmerkninger/AnmerkningForm.jsx';
import DialogStore from 'lib/DialogStore';

const modalDialogs = {
    'AnmerkningForm': ({ isOpen, onClose, dialogData, owner }) => {
      return (
        <NKModal size="large" isOpen={isOpen}>
          <AnmerkningForm
            onClose={onClose}
            tittel={dialogData.tittel}
            objektReferanseType={dialogData.objektReferanseType}
            adresse={dialogData.adresse}
            objektReferanseId={dialogData.objektReferanseId}
            bruksenhetid={dialogData.bruksenhetid}
            onLagred={owner.onLagred}
            id={dialogData.id}
            emne={dialogData.emne}
            identi={dialogData.identi}
            type={dialogData.type}
          />
        </NKModal>
      );
    }
};

const htmlToPlainText = (html) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || '';
  };

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

class ListeMedAnmerkninger extends React.Component {

    formatDato(datostring) {
        let date = new Date(datostring);
        var dateString = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
        return dateString;
    }
    getLukket(lukket) {
        let res = 'Lukket';
        if (!lukket) {
            res = 'Åpen';
        };
        return res;
    }

    render() {
        if (!this.props.liste) {
            return (
                <div>Ingen anmerkninger registrert.</div>
            );
        }
        return (
            <div className='anm-scroller w500'>

                {
                    this.props.liste.map(function(anmerkning) {
                        var dId = 'd' + anmerkning.id.toString();
                        var bId = 'b' + anmerkning.id.toString();
                        var tId = 't' + anmerkning.id.toString();
                        var beId = 'be' + anmerkning.id.toString();
                        var ddId = 'dd' + anmerkning.id.toString();      
                        var lukketClass = 'malboksred ';
                        var lukketIcon = 'ikke-valgt';
                        if (anmerkning.lukket) {
                            lukketClass = 'malboksgreen';
                            lukketIcon = 'valgt';
                        };  
                        var buttonClass = 'btn btn-default ';
                        if (anmerkning.sidemannsKontroll){
                            buttonClass = 'btn btn-info ';
                        };
                        return <div key={dId} className='pad2'>
                            <button key={bId} className={ buttonClass + ' ' +  lukketClass} 
                                 onClick={this.props.onVelgRad.bind(null, anmerkning.id, anmerkning.andreForhold)}>
                                 <table className="btntable">
                                    <tbody>
                                        <tr>
                                            <td className="btntd0"> 
                                                <NKIcon icon={lukketIcon} color="nk-black" />
                                            </td>
                                            <td className="btntd1">{this.formatDato(anmerkning.dato)} {!anmerkning.andreForhold ? ' (A)' : ' (AF)' }</td>
                                            
                                            <td className="btntd2">{anmerkning.kategori}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p key={beId} className="textleft">
                                   {/*  {anmerkning.beskrivelse} */}
                                    {truncateText(htmlToPlainText(anmerkning.beskrivelse), 100)}
                                </p>
                            </button>
                        </div>;

                    }, this)}
            </div>
        );
    }
}

export default class AnmerkningListe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anmerkninger: [],
            anmerkningerCount: 0,
            valgtId: '0',
            alle: 'active'    
        };
        this.getAnmerkninger();
        this.dialogs = new DialogStore({
            owner: this,
            dialogs: modalDialogs
          });
    }

    getAnmerkninger() {
        var ttype = this.props.objektReferanseType;

        if (ttype === 'B')
        {  
            anmerkningApi.getAnmerkningByBruksenhetId(this.props.bruksenhetid, this.gotAnmerkninger.bind(this));
        }
        if (ttype === 'R')
        {
            anmerkningApi.getAnmerkningByRoyklopId(this.props.royklopid, this.gotAnmerkninger.bind(this));
        }
         if (ttype === 'I')
        {
            anmerkningApi.getAnmerkningByIldstedId(this.props.ildstedid, this.gotAnmerkninger.bind(this));
        }
    }

    gotAnmerkninger(err, anmerkninger) {
        this.setState({ anmerkninger: anmerkninger });
        if ((anmerkninger === null ) || (anmerkninger === undefined)) {
            this.setState({ anmerkningerCount: 0 });
        } else  {
            this.setState({ anmerkningerCount: anmerkninger.length });
        };
    }

    onLagred = () => {
        this.setState({ alle: 'active' });
        this.getAnmerkninger();
    }

    openAnmerkning() {
        this.dialogs.setOpenDialog('AnmerkningForm', { objektReferanseType: this.props.objektReferanseType, objektReferanseId: this.props.objektReferanseId, 
                              bruksenhetid: this.props.bruksenhetid, emne: this.props.emne, adresse: this.props.adresse, identi: this.props.identi,
                              id: this.state.valgtId, tittel: 'Ny anmerkning', type: 'Anmerkning' });
    }

    openAndreforhold() {
        this.dialogs.setOpenDialog('AnmerkningForm', { objektReferanseType: this.props.objektReferanseType, objektReferanseId: this.props.objektReferanseId, 
                              bruksenhetid: this.props.bruksenhetid, emne: this.props.emne, adresse: this.props.adresse, identi: this.props.identi,
                              id: this.state.valgtId, tittel: 'Andre forhold', type: 'Andre' });
    }

    radValgt(id, andreForhold) {
        console.log(andreForhold);
        this.setState({ valgtId: id.toString() }, andreForhold ? this.openAndreforhold : this.openAnmerkning); 
    }

    nyAnmerkning() {
        this.setState({ valgtId: '0' }, this.openAnmerkning);
    }

    nyAndreforhold = () => {
        this.setState({ valgtId: '0' }, this.openAndreforhold);
    }



    render() {

        var anmerkAntall = this.state.anmerkningerCount ==1 ? ' anmerkning/andre forhold ' : ' anmerkninger/andre forhold ';

        return (
                <div className="modal-content">
                    <div className="modal-header">
                        <button className="close" type="button" onClick={this.props.onClose}>
                            <span>x</span>
                            <span className="sr-only">Lukk</span>
                        </button>
                        <h4 className="modal-title"> 
                            {this.state.anmerkningerCount + anmerkAntall + this.props.adresse}
                        </h4>
                    </div>
                    <h4 className='m6'>{/* {this.props.info} */}</h4>
                    <div className="modal-body">
                           <button id="btnNew" className="btn btn-default h48" style={{marginLeft: '6px'}} onClick={this.nyAnmerkning.bind(this)}>
                                <span className="pull-left"><NKIcon icon="pluss" size="1" color="nk-black" /></span>
                                <span className='font16' style={{marginLeft: '5px'}}>Anmerkning</span>
                           </button>
                           <button id="btnNew" className="btn btn-default h48" style={{marginLeft: '6px'}} onClick={this.nyAndreforhold.bind(this)}>
                                <span className="pull-left"><NKIcon icon="pluss" size="1" color="nk-black" /></span>
                                <span className='font16' style={{marginLeft: '5px'}}>Andre forhold</span>
                           </button>
                            <br/>
                            <br/>
                        
                            <ListeMedAnmerkninger liste={this.state.anmerkninger} onVelgRad={this.radValgt.bind(this)} />

                    </div>
                    {this.dialogs.renderOpenDialog()}
                </div>

                
            );
    }
}
