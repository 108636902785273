import norkartIdJs from 'auth/auth';

export const reloadSearch = (searchkit) => { 
 
  if (searchkit) { 
    refreshAccessToken(searchkit);
    if (searchkit.reloadSearch) { 
      searchkit.reloadSearch();  
      return;
    } 
  } 
}

export const refreshAccessToken = (searchkit) => {
  
  if (searchkit) { 
    if (searchkit.options) {
      if (searchkit.options.httpHeaders) {
        norkartIdJs.getToken().then(token => {
          searchkit.options.httpHeaders['Authorization'] = token;
        })
      }
    }
  }
}

export const search = (searchkit) => {
  if (searchkit) { 
    refreshAccessToken(searchkit);
    if (searchkit.search) { 
      searchkit.search();  
      return;
    }  
  } 
}