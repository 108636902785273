import React, { useState, useEffect } from 'react';
import './InfoRad.css';
import Spinner from 'components/generics/Spinner';
import {
  sjekkBruksenhetRisiko,
  getRisikoanalyseLogg,
  startRisikoanalyseBruksenhet,
} from 'api/risikoApi';
import InfoRad from './InfoRad.js';
import Refresh from 'images/Annet/refresh.png';
import NKIcon from 'nka-icons';



const RisikovurderingLogg = React.forwardRef(({ ...props }, ref) => {
  const [analyseInfo, setAnalyseInfo] = useState(null);
  const [visMelding1, setVisMelding1] = useState(false);
  const [visMelding2, setVisMelding2] = useState(false);



  useEffect(() => {
    getRisikoanalyseLogg(props.id, gotInfo);
  }, []);

  const oppdater = () => {
    setAnalyseInfo(null);
    getRisikoanalyseLogg(props.id, gotInfo);
  };

  const startRisiko = () => {
    setAnalyseInfo(null);
    sjekkBruksenhetRisiko(props.id, gotRisikoSjekk);
  };

  const gotRisikoSjekk = (err, res) => {
    if (res) {
      setVisMelding1(true);
      startRisikoanalyseBruksenhet(props.id, () => {
        getRisikoanalyseLogg(props.id, gotInfo);
      });
    } else {
      setVisMelding2(true);
    }
  };

  const gotInfo = (err, info) => {
    setVisMelding1(false);
    if (!err) {
      setAnalyseInfo(info);
    }
  };

  return (
    <div
      style={{
        margin: '4px',
        padding: '10px',
      }}
    >
      <div className='modal-content' height={'600px'} minHeight={'600px'}>
        <div className='modal-header'>
          <button className='close' type='button' onClick={props.onClose}>
            <span>x</span>
            <span className='sr-only'>Lukk</span>
          </button>
          <h4 className='modal-title'>{'Risikoanalyse ' + props.adresse} </h4>
        </div>
        <div className='modal-body'>
          <div className='btn-toolbar'>
            <button
              style={{ width: '170px' }}
              className='btn btn-default pull-left'
              onClick={oppdater}
              title='Oppdater tabellen'
            >
              <img height='30px' width='30px' src={Refresh} alt='' />
              <span style={{ marginLeft: '4px' }}>Oppdater</span>
            </button>
            <button
              style={{ width: '180px', height: '44px' }}
              className='btn btn-default pull-left'
              onClick={startRisiko}
              title='Kjør risikoanalyse (igjen)'
            >
              <NKIcon icon={'lasting'} size='1' color='nk-black' />
              <span style={{ marginLeft: '8px' }}>Start risikoanalyse</span>
            </button>
          </div>
          <br />
          {!analyseInfo ? (
            <>
            {visMelding1 && (
              <label style={{ marginLeft: '12px', color: 'blue' }}>Risikoanalyse på objektet er nå startet.</label>
            )}
            {visMelding2 && (
              <label style={{ marginLeft: '12px', color: 'red' }}>Systemet er ikke satt opp til å kjøre risikoanalyse på denne enheten.</label>
            )}
              <Spinner className='h4' />
            </>
          ) : ( 
            <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
              <table
                className='info-table'
                style={{ width: '95%', marginLeft: '8px' }}
              >
                <tbody>
                  <tr>
                    <th></th>
                    <th>Analyse</th>
                    <th>Status</th>
                    <th>Dato</th>
                    <th>Risikoverdi</th>
                    <th>Neste tiltak</th>
                    <th>Tiltak</th>
                    <th>Hyppighet</th>
                    <th></th>
                  </tr>

                  {analyseInfo.map((info, i) => (
                    <InfoRad info={info}></InfoRad>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
     
    </div>


  );
});
export default RisikovurderingLogg;


