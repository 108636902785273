import React, { Component } from 'react';
import OKDialog from 'components/Dialogs/OkDialog.jsx';
import DialogStore from 'lib/DialogStore';
import * as _ from 'lodash';
import NKIcon from 'nka-icons';
import PromptDialogEkstra from 'components/Dialogs/PromptDialogEkstra.jsx';
import { getHurtigdeler, nyHurtigdel } from 'api/malerApi';
import LagreTekstbit from './LagreTekstbit.jsx';
import SoekTekstbit from './SoekTekstbit.jsx';
import NKModal from 'components/Dialogs/NKModal.jsx';
import { debugFactory } from 'utils';
import EksempelDialog from './EksempelDialog.jsx';
import './smsEditor.css';
const debug = debugFactory('nk:SmsEditor');

const modalDialogs = {
  OkDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <OKDialog
      tittel={dialogData.tittel}
      melding={dialogData.melding}
      isOpen={isOpen}
      onClose={onClose}
      onOk={onClose}
    />
  ),
  PromptDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <PromptDialogEkstra
      prompt='Tittel'
      tittel='Hurtigdel'
      ekstra={dialogData.ekstra}
      isOpen={isOpen}
      onOk={owner.nyHurtigdel}
      onClose={onClose}
    />
  ),
  EksempelDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      <EksempelDialog
        onClose={onClose}
        mal={owner.props.mal}
        modus='SMS'
        onInsertEksempelMal={onClose(owner.onInsertEksempelMal)}
      />
    </NKModal>
  ),
  TekstbiterDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='medium' isOpen={isOpen}>
      <LagreTekstbit
        merkettekst={dialogData.merkettekst}
        tekst={dialogData.tekst}
        onClose={owner.closeTekstbit.bind(this)}
        onLagret={owner.lagretTekstbit.bind(this)}
      />
    </NKModal>
  ),
  TekstbiterSoekDialog: ({ isOpen, onClose, dialogData, owner }) => (
    <NKModal size='large' isOpen={isOpen} onClose={onClose}>
      <SoekTekstbit
        onClose={onClose}
        onVelgTekst={owner.onValgtTekst.bind(this)}
      />
    </NKModal>
  ),
};

class SmsEditor extends Component {
  constructor(props) {
    super(props);
    this.areaRef = React.createRef();
    this.state = {
      tekst: '',
      tekstvalue: '',
      test: '',
      visMal: true,
      visEksempelknapp: true,
      flettetTekst: '',
      range: null,
      tekstbiter: [],
      antallTegn: 0,
      antallTegnColor: 'white',
      antallTegnLabel: 'black',
      eksempelmaler: null,
      merkettekst: '',
    };
    this.dialogs = new DialogStore({ owner: this, dialogs: modalDialogs });
  }

  componentDidMount() {
    this.setState({ tekst: this.props.maltext });
    this.sjekkAntalltegn(this.props.maltext);
    if (this.props.visTekstbiter) {
      getHurtigdeler(this.onGotTekstbiter);
    }
  }

  onGotTekstbiter = (err, data) => {
    data = _.sortBy(data, 'tittel');
    this.setState({ tekstbiter: data });
  };

  //  ****  Tekster ***********************************************************************
  areaSelected = () => {
    var ta = this.areaRef.current;
    var ss = ta.selectionStart;
    var se = ta.selectionEnd;
    var s = ta.value.substring(ss, se);
    this.setState({ merkettekst: s });
  };

  openTekstbit = () => {
    this.setState({ tekst: this.areaRef.current.value });
    this.dialogs.setOpenDialog('TekstbiterDialog', {
      tekst: this.areaRef.current.value,
      merkettekst: this.state.merkettekst,
    });
  };

  closeTekstbit = () => {
    console.log('************ close 2 ************************');
    this.dialogs.closeDialog();
    this.setState({ merkettekst: '' });
  };

  openTekstbitSoek = () => {
    this.dialogs.setOpenDialog('TekstbiterSoekDialog');
  };

  lagretTekstbit = () => {
    this.dialogs.closeDialog();
    //Update tekster
    this.setState({ merkettekst: '' });
  };

  onValgtTekst = (tekst) => {
    var box = document.getElementById('smsXeditor');
    this.insertTextAtCaret(box, tekst);
    this.dialogs.closeDialog();
  };

  closeTekstbit = () => {
    console.log('************ close 2 ************************');
    this.dialogs.closeDialog();
    this.setState({ merkettekst: '' });
  };

  lagretTekstbit = () => {
    this.dialogs.closeDialog();
    //Update tekster
    this.setState({ merkettekst: '' });
  };
  ////////////////////7
  nyHurtigdel = (obj) => {
    this.dialogs.closeDialog();
    let tittel = obj.result;
    let tekst = obj.ekstra;
    if (tittel.trim() === '') {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Tittel mangler',
        melding: 'En hurtigdel kan ikke lagres uten tittel.',
      });
      return;
    } else {
      obj.id = 0;
      obj.tittel = tittel;
      obj.tekst = tekst;
      nyHurtigdel(obj, this.hurtigdelOppdatert);
    }
  };

  hurtigdelOppdatert = (err, data) => {
    getHurtigdeler(this.onGotTekstbiter);
  };

  sjekkAntalltegn = (tekst) => {
    let antallTegn = tekst.length;
    this.setState({ antallTegn });
    this.setColors(antallTegn);
  };

  setColors = (antallTegn) => {
    let antallTegnColor = '#E25653';
    let antallTegnLabel = '#E25653';
    if (antallTegn < 500) {
      antallTegnColor = 'white';
      antallTegnLabel = 'black';
    }
    this.setState({ antallTegnColor });
    this.setState({ antallTegnLabel });
  };

  afterInsert = (ant) => {
    let antallTegn = this.state.antallTegn + ant;
    this.setState({ antallTegn });
    this.setColors(antallTegn);
  };

  areaChange = (e) => {
    let s = e.target.value;
    this.setState({ tekst: s });
    let tekstvalue = s; //.replace(/(\r\n|\n|\r)/gm, '#break#');
    //=SUBSTITUTE(XX,CHAR(13),"")
    this.setState({ tekstvalue });
    this.sjekkAntalltegn(tekstvalue);
  };

  saveTekst = () => {
    var box = document.getElementById('smsXeditor');
    let s = box.value;

    return s;
  };

  insertTextAtCaret = (el, text) => {
    text = text + ' ';
    el.focus();
    var val = el.value,
      endIndex,
      range;
    if (
      typeof el.selectionStart != 'undefined' &&
      typeof el.selectionEnd != 'undefined'
    ) {
      endIndex = el.selectionEnd;
      el.value = val.slice(0, el.selectionStart) + text + val.slice(endIndex);
      el.selectionStart = el.selectionEnd = endIndex + text.length;
    } else if (
      typeof document.selection != 'undefined' &&
      typeof document.selection.createRange != 'undefined'
    ) {
      el.focus();
      range = document.selection.createRange();
      range.collapse(false);
      range.text = text;
      range.select();
    }
    this.setState({ tekst: el.value }, this.wait(text.length));
  };

  wait = (ant) => {
    setTimeout(() => this.afterInsert(ant), 2000);
  };

  openTekstbit = () => {
    this.setState({ tekst: this.areaRef.current.value });
    this.dialogs.setOpenDialog('TekstbiterDialog', {
      tekst: this.areaRef.current.value,
      merkettekst: this.state.merkettekst,
    });
  };

  onVelgFlettefelt = (e) => {
    var box = document.getElementById('smsXeditor');
    this.insertTextAtCaret(box, e.target.value);
  };

  onVelgTekstbit = (e) => {
    var box = document.getElementById('smsXeditor');
    this.insertTextAtCaret(box, e.target.value);
  };

  tilFletteTest = () => {
    var box = document.getElementById('smsXeditor');
    var s = box.value;
    this.setState({ tekst: s });
    s = this.flettText(s);
    this.setState({ flettetTekst: s });
    this.setState({ visMal: false });
  };

  tilMalTekst = () => {
    this.setState({ visMal: true });
  };

  goReplace = (str, find, repl) => {
    if (!str) {
      return;
    }
    str = str.replace(new RegExp(find, 'g'), repl);
    return str;
  };

  flettText = (s) => {
    if (!this.props.flettefelt) {
      return;
    }
    for (let i = 0; i < this.props.flettefelt.length; i++) {
      let feltRow = this.props.flettefelt[i];
      s = this.goReplace(s, feltRow.flettefelt, feltRow.fletteEksempel);
    }
    return s;
  };

  getMaltekst = () => {
    let tekst = this.saveTekst();
    let ant = this.state.antallTegn;

    if (ant > 499) {
      return 'ERROR';
    } else {
      return tekst;
    }
  };

  lagreTekstbit = () => {
    var box = document.getElementById('smsXeditor');
    var s = box.value.substring(box.selectionStart, box.selectionEnd);
    if (s) {
      this.dialogs.setOpenDialog('PromptDialog', { ekstra: s });
    } else {
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Du har IKKE merket ut noe.',
        melding: 'Selekter teksten som du ønsker å ta vare på.',
      });
    }
  };

  lesmer = () => {
    this.dialogs.setOpenDialog('OkDialog', {
      tittel: 'Antall tegn i sms mal',
      melding:
        'For å unngå feil ved sending av sms og for å unngå ekstra kostnader håndterer vi en begrensing av 500 tegn.',
    });
  };

  areaPaste = (e) => {
    let s = e.clipboardData.getData('Text');
    s = s.toLowerCase();
    if (s.includes('https:/')) {
      let ss1 =
        'Du prøver å lime inn en tekst som inneholder en hyperlink (url). ' +
        '\nDette kan føre til feil.';
      this.dialogs.setOpenDialog('OkDialog', {
        tittel: 'Lime inn ble avbrutt',
        melding: ss1,
      });
    }
  };

  openEksempelmaler = (e) => {
    this.dialogs.setOpenDialog('EksempelDialog');
  };

  onInsertEksempelMal = (tekst) => {
    this.setState({ tekst });
  };

  render() {
    return (
      <div>
        {this.state.visMal && (
          <React.Fragment>
            <div>
              <span>
                <span style={{ marginRight: '8px' }}>Sett inn:</span>
                <span>
                  <select
                    style={{
                      height: '32px',
                      marginTop: '2px',
                      fontSize: '16px',
                      width: '200px',
                      verticalAlign: 'middle',
                    }}
                    value='0'
                    onChange={this.onVelgFlettefelt}>
                    <option value='0'>Flettefelt..</option>
                    {this.props.flettefelt.map((felt, i) => (
                      <option
                        style={{ fontSize: '16px', color: 'blue' }}
                        key={'key' + i}
                        value={felt.flettefelt}>
                        {felt.navn}
                      </option>
                    ))}
                    ;
                  </select>
                  <button
                    onClick={this.tilFletteTest}
                    style={{ marginLeft: '8px', height: '32px' }}
                    className='btn btn-default'>
                    Test
                  </button>
                </span>
              </span>

              {/*    {this.props.visTekstbiter && ( */}
              <span style={{ marginLeft: '8px' }}>
                <button
                  id='deleBtn'
                  ref='deleBtn'
                  type='button'
                  title='Lagre tekstbit'
                  className='btn btn-default  pull-right'
                  onClick={this.openTekstbit.bind(this)}>
                  <NKIcon icon='kopier' color='nk-black' />
                </button>
                <button
                  type='button'
                  title='Sett inn tekstbit'
                  className='btn btn-default pull-right '
                  onClick={this.openTekstbitSoek.bind(this)}>
                  <NKIcon icon='tekst' color='nk-black' />
                </button>
              </span>
              {/*   )} */}

              {!this.props.visTekstbiter && this.state.visEksempelknapp && (
                <span>
                  <button
                  style={{ marginRight: '6px' }}
                    onClick={this.openEksempelmaler}
                    /*  style={{ backgroundColor: 'crimson', color: 'white'}} */
                    className='btn btn-default pull-right'>
                    Sett inn Eksempelmal
                  </button>
                </span>
              )}

              <div style={{ marginTop: '4px' }}>
                <span
                  style={{
                    color: this.state.antallTegnLabel,
                  }}>
                  Antall tegn: {this.state.antallTegn}
                </span>
                <span
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    marginLeft: '10px',
                  }}
                  onClick={this.lesmer}>
                  Les mer om antall tegn..
                </span>
              </div>
            </div>
            <br />
            <div>
              <textarea
                id='smsXeditor'
                ref={this.areaRef}
                className='smseditor'
                wrap='hard'
                placeholder='Skriv sms tekst..'
                onChange={this.areaChange}
                onSelect={this.areaSelected.bind(this)}
                onPaste={this.areaPaste}
                style={{
                  fontSize: '18px',
                  /* resize: 'none', */
                  borderColor: 'green',
                  borderWidth: '1px',
                  padding: '4px',
                  backgroundColor: this.state.antallTegnColor,
                  borderStyle: 'solid',
                }}
                cols={this.props.cols}
                rows={this.props.rows}
                value={this.state.tekst}
                autoFocus={true}
              />
            </div>
          </React.Fragment>
        )}
        {!this.state.visMal && (
          <React.Fragment>
            <div style={{ marginTop: '1px' }}>
              <span style={{ color: 'blue' }}>
                Flettet tekst eksempel (NB! Ikke reelle data!)
              </span>
              <button
                onClick={this.tilMalTekst}
                style={{ marginLeft: '8px' }}
                className='btn btn-default'>
                Tilbake til mal tekst
              </button>
            </div>
            <br />
            <textarea
              id='smsXeditor2'
              readOnly
              className='smseditor'
              wrap='hard'
              style={{
                fontSize: '18px',
                resize: 'none',
                borderColor: 'green',
                color: 'green',
                padding: '4px',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              cols={this.props.cols}
              rows={this.props.rows}
              defaultValue={this.state.flettetTekst}
              autoFocus={false}
            />
          </React.Fragment>
        )}
        {this.dialogs.renderOpenDialog()}
      </div>
    );
  }
}

export default SmsEditor;
