import React from 'react';
import { Route, asRouteComponent } from 'components/Router';
import Index from './index';
import { debugFactory, enhanceComponents } from 'utils';


const enhanced = enhanceComponents({
    Index
}, [
        asRouteComponent
    ]);

export default (
    <Route path="Prosesslogg" component={enhanced.Index} name="Systemmelding" icon="info-transparent" inMenu={false} login={true} roles={[]}>
      
    </Route>
);