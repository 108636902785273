import Link from 'react-router/lib/Link';
import LoginComponent from 'components/LoginComponent';
import norkart_logo from 'images/NORKART_SYMBOL_negativ__digital.png';
import gjen_icon from 'images/48/gjennomforing-W-48.png';
import rapp_icon from 'images/48/statistikk-C-48px.png';
import grunn_icon from 'images/48/database-GW.png';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'norkartidjs2';

import NKIcon from 'nka-icons';
require('./style.css');
require('./menu.css');

function flattenRoutes(routes, basePath) {
  if (!basePath || basePath === '/') {
    basePath = '';
  }
  return routes
    .map(function(route) {
      var path;

      if (route.path && route.path !== '/') {
        path = basePath + '/' + route.path;
      } else if (route.path === '/') {
        path = basePath + route.path;
      } else if (!route.path) {
        path = route.path;
      }
      var ret = { route: route, path: path };
      if (!route.childRoutes) {
        return ret;
      }
      return [ret].concat(flattenRoutes(route.childRoutes, path));
    })
    .reduce((a, b) => a.concat(b), []);
}

const Nav = (props) => {
  const authMsal = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    authMsal.getUserData().then(res => setUser(res));
  }, [props.user]);

  const getLinks = (user) => {
    var indexRoute = props.routes.filter(function(route) {
      return route.path && route.path === '/';
    })[0];

    return flattenRoutes(indexRoute.childRoutes)
      .filter(function(route) {
        // todo add login && role filtering here
        return (
          route.route.inMenu &&
          route.route.name &&
          route.route.path &&
          route.route.icon &&
          (!route.route.login || user)
        );
      })
      .map(function({ path, route }) {
        return {
          path: path,
          name: route.name,
          icon: route.icon,
          menuPath: route.menuPath || false,
        };
      });
  };


  const renderLink = ({ path, name, icon, menuPath }, key) => {
    var fixIcon;
    switch (icon) {
      case 'gjennomforing':
        fixIcon = <img height='24' width='24' src={gjen_icon} alt='' />;
        break;
      case 'rapporter':
        fixIcon = <img height='24' width='24' src={rapp_icon} alt='' />;
        break;
      case 'objekter':
        fixIcon = <img height='24' width='24' src={grunn_icon} alt='' />;
        break;
      default:
        fixIcon = <NKIcon icon={icon} size='1' color='nk-white' />;
    }

    return (
      <li key={key}>
        <Link
          to={menuPath || path}
          activeClassName='active'
          onClick={hideNav}>
          {name}
          <span className='pull-right'>{fixIcon}</span>
        </Link>
      </li>
    );
  };

  const toggleNav = () => {
    setCollapsed(!collapsed);
  };

  const hideNav = () => {
    setCollapsed(false);
  };

  const showNav = () => {
    setCollapsed(true);
  };

  //Sørge for at user-string ikke blir for bred
  const makeUserString = (user) => {
    let str = '';
    if(user && user.userName) {
      str = user.userName;
      if (str.length > 15) {
        str = str.substring(0, 14) + '..';
      }
    }
    return str;
  };

    var links = getLinks(user);
    if (user) {
      // Use state.user.profile.given_name or state.user.userName here?
      // links.push({ path: '/Tilgang', name: props.user.userName, icon: 'bruker' });
      links.push({
        path: '/Tilgang',
        name: makeUserString(user),
        icon: 'bruker',
      });
    }
    return (
      <nav
        className='navbar navbar-inverse sidebar navbar-fixed-top'
        role='navigation'>
        <div className='container-fluid'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle'
              onClick={toggleNav}>
              <span className='sr-only'>Toggle navigation</span>
              <span className='icon-bar' />
              <span className='icon-bar' />
              <span className='icon-bar' />
            </button>
            <Link id='brannlogo' className='brannlogo' to='/'>
              <div >
                <img style={{marginRight:'8px', marginTop:'10px'}} height={'24px'} width={'24px'} className='pull-right sk-logo' src={norkart_logo} alt='' />
                <div style={{paddingTop: '6px', paddingLeft: '4px'}}>{props.name}</div>
              </div>
            </Link>
          </div>
          <div
            className={`collapse navbar-collapse ${
              collapsed ? 'in' : null
            }`}>
            <ul className='nav navbar-nav sb-navbar'>
              {links.map(renderLink)}
              <LoginComponent />
              <li>
                <div>
                  <a
                   /*  href='https://vimeopro.com/norkart/komtek-brannforebygging' https://komtek.zendesk.com/hc/no/sections/4986289245585-Brannforebygging   */ 
                   href='https://komtek.zendesk.com/hc/no/sections/4986289245585-Brannforebygging'
                    target='blank'>
                    <button className='hjelp_button'>
                      <div className='pull-left hjelp_div'>Kundesenter</div>
                      <span className='pull-right hjelp_span'>
                        <NKIcon icon='hjelp' size='1' color='nk-white' />
                      </span>
                    </button>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  };

export default Nav;
