
let sisteFilterBruker_IkkePub = 0;
let sisteFilterBruker_Aapne = 0;

let sisteFaneBesoekt= '';
let valgtPerson = '';

export const setSisteFilterBruker_IkkePub = (brukerId) => {
    sisteFilterBruker_IkkePub = brukerId;
  };
  
  export const getSisteFilterBruker_IkkePub = () => {
    return sisteFilterBruker_IkkePub;
  };

  export const setSisteFilterBruker_Aapne = (brukerId) => {
    sisteFilterBruker_Aapne = brukerId;
  };
  
  export const getSisteFilterBruker_Aapne = () => {
    return sisteFilterBruker_Aapne;
  };

  export const setSisteFaneBesoekt= (fra) => {
    sisteFaneBesoekt = fra;
  };
  
  export const getSisteFaneBesoekt = () => {
    return sisteFaneBesoekt;
  };

  export const setValgtPerson= (person) => {
    valgtPerson = person;
  };

  export const getValgtPerson= () => {
    return valgtPerson ;
  };