import React from 'react';
import { LinkList, LinkListItem } from 'components/LinkList';
import bilde from 'images/Annet/silhou2.png';
import brann from 'images/Brannforebygging.png';
import { getAvvikTilOppfolgingForSaksbehandler } from 'api/avvikApi.js';
//import { getAntallAnsvarSmk } from 'api/smkApi.js';
import { getAntallOppfolging } from 'api/smkApi.js';
import { getLoggprosessAntallByUser } from 'api/organisasjonApi.js';
import {
  getContextBrukere,
  getContextBrukereAlternativ,
  getContextOrgKommuner,
} from 'context/contextOppslag.js';
import { contextInnstillingerPerson, getOrganisasjonData, setViaStart } from 'context/contextInnstillinger.js';
import cookie from 'react-cookies';
import { getSystemMeldingFelles } from 'api/innloggingApi.js';
import ReactMarkdown from 'react-markdown';


let current_date = new Date();
let year = current_date.getFullYear();

//================================= LAST INFO COOKIE ==============================================================
const lastInfo = 21;
const lastInfoDate = new Date(2023, 5, 3 + 5);
const iDag = new Date();

//=================================================================================================================

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tikker: 0,
      avvikTilOppf: false,
      antallSmk: false,
      visEdgeVarsel: false,
      infoSkalVises: false,
      visTest: false,
      driftsmelding: null,
      prosessNotify: false
    };
  }

  componentDidMount() {
    //============= REACT CONTEXT ====================

    getAvvikTilOppfolgingForSaksbehandler(this.gotAvvikTilOppf);
    getAntallOppfolging(this.gotAntallOppfolging);

    getContextBrukere(this.gotData);
  
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      getOrganisasjonData(this.gotOrganisasjonForBrukerset);
    }
    getContextOrgKommuner(this.gotData);
    // this.sjekkBrowser();
    this.skalInfoVises();

    getSystemMeldingFelles(this.gotDriftsMelding);
    setViaStart();
    getLoggprosessAntallByUser(this.gotProsessAntallUser);  
  }

  gotData = (data) => {
  };

  gotOrganisasjonForBrukerset = (org) => {
      console.log('AAX', org);
      if (org.data){
        if (org.data.kategoriNavn === 'Demo'){
          this.brukNKBrukere();
        }
      }
      
  };

  gotDriftsMelding = (err, data) => {
    if (data) {
      if (data.systemDriftsmelding) {
        if (!data.systemDriftsmelding.historisk) {
          this.setState({ driftsmelding: data.systemDriftsmelding });
          return;
        }
      }
      if (data.systemInformasjon) {
        if (data.systemInformasjon.aktiv) {
          this.setState({ driftsmelding: data.systemInformasjon });
        }
      }
    }
  };



  //__________________________________________________________________________________________________________________________

  visTestKnapp = () => {
    if (!contextInnstillingerPerson) {
      return;
    }
  
    if (contextInnstillingerPerson._currentValue.norkartAdmin) {
      let host = window.location.hostname.toLowerCase();
      if (
        host.includes('test.brannforebygging.no') ||
        host.includes('localhost')
      ) {
        if (
          contextInnstillingerPerson._currentValue.navn === 'Rieni de Rijke' ||
          contextInnstillingerPerson._currentValue.navn === 'Stein Ørslien'
        ) {
          this.setState({ visTest: true });
        }
      }
    }
  };

  skalInfoVisesOld = () => {
    let last = cookie.load('lastInfo');
    if (!last) {
      this.setState({ infoSkalVises: true });
    } else {
      if (last < lastInfo) {
        this.setState({ infoSkalVises: true });
      }
    }
  };

  skalInfoVises = () => {

    if (lastInfoDate > iDag) {
      this.setState({ infoSkalVises: true });

    } else {
      this.setState({ infoSkalVises: false });
    }
  };

  infoClick = () => {
  };

  gotAvvikTilOppf = (err, res) => {
    if (err) {
      return;
    }
    if (res > 0) {
      this.setState({ avvikTilOppf: true });
    }
  };

  gotAntallOppfolging = (err, res) => {
    console.log(res);
    if (err) {
      return;
    }
    if ( res.antallApneAvvikPuplisert >= 1 || res.antallApneAvvikPuplisertFristUtgatt >= 1 || res.antallÅpneAvvikIkkePublsertUnderbehandling >= 1 ) {
      this.setState({ avvikTilOppf: true, antUnderBeh: res.antallÅpneAvvikIkkePublsertUnderbehandling });
    }
    this.setState({ status: res });
    // if (res > 0) {
    //   this.setState({ antallSmk: true });
    //   //Så lenge utropstegn dersom minst en av de 2 er true
    //   this.setState({ avvikTilOppf: true });
    // }
    
  };

  brukNKBrukere = () => {
    getContextBrukereAlternativ(this.gotAlternativeBrukere);
  };

  gotAlternativeBrukere = () => {
   // alert('Du har valgt å bruke NK-brukere.');
  };

  gotProsessAntallUser = (err, res) => {
    if (res > 0) {
      this.setState({ prosessNotify: true });
    }
  }

  render() {
    if (!contextInnstillingerPerson._currentValue) {
      return <div>Vent nå litt..</div>;
    }

    return (
      <div>
        <div className='row'>
          <div className='col-sm-12 col-md-8 col-lg-6'>
            <>
              {this.state.driftsmelding && (
                <div style={{ marginLeft: '40px', maxWidth: '800px' }}>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ verticalAlign: 'middle' }}>
                          {' '}
                          <img
                            src={brann}
                            style={{
                              verticalAlign: 'middle',
                              width: '36px',
                              height: '36px',
                            }}
                            alt=''
                          />
                        </td>
                        <td style={{ verticalAlign: 'bottom' }}>
                          <h2 style={{ marginLeft: '10px' }}>
                            Melding fra Brannforebygging
                          </h2>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div style={{ color: 'darkRed' }}>
                    <h3 style={{ marginTop: '10px' }}>
                      {this.state.driftsmelding.tittel}
                    </h3>
                    <h4>
                      <ReactMarkdown
                        children={this.state.driftsmelding.tekst}
                      />
                      {/*  {this.state.driftsmelding.tekst} */}
                      <br />
                      {this.state.driftsmelding.url &&
                        this.state.driftsmelding.urlLabel && (
                          <a href={this.state.driftsmelding.url} target='blank'>
                            {this.state.driftsmelding.urlLabel}
                          </a>
                        )}
                      {this.state.driftsmelding.url &&
                        !this.state.driftsmelding.urlLabel && (
                          <a href={this.state.driftsmelding.url} target='blank'>
                            Les mer...
                          </a>
                        )}
                    </h4>
                  </div>
                </div>
              )}
              <br />
            </>

            {/*   <>
              <div style={{ marginLeft: '20px' }}>
                <h3 style={{ color: 'blue' }}>Kundeundersøkelse</h3>
                <div>
                  Vi ønsker å forbedre Brannforebygging. <br />
                  Derfor er det viktig med tilbakemeldinger fra deg. <br />
                  Vennligst hjelp oss ved å svare på noen spørsmål.
                  <br />
                  Undersøkelsen er anonym.
                  <span style={{ marginLeft: '10px' }}>
                    <a
                      href='https://forms.office.com/r/HrCH8d0xKD'
                      target='blank'>
                      Start kundeundersøkelse
                    </a>
                  </span>
                </div>
              </div>
              <br />
            </> */}

            <LinkList title='Komtek Brannforebygging Boligsikkerhet' columns={1}>
          {/*     {contextInnstillingerPerson._currentValue.norkartAdmin && (
                <button
                  onClick={this.brukNKBrukere}
                  style={{ marginLeft: '10px' }}
                  className='btn btn-link'>
                  Bruk (aktive) NK-brukere isf. organisasjons brukere.
                </button>
              )} */}
              <LinkListItem
                title='Planlegging'
                path='/planlegging'
                icon='kalender'
              />
              <LinkListItem
                title='Gjennomføring'
                path='/gjennomforing'
                icon='gjennomforing'
              />
              <LinkListItem
                title='Oppfølging'
                old='true'
                path='/oppfolging'
                icon='kopier'
                tilOppf={this.state.avvikTilOppf}
                status={this.state.status}
                antUnderBeh={this.state.antUnderBeh}
              />
              <LinkListItem
                title='Søk på enheter'
                old='true'
                path='/objekter/enhetssok'
                icon='hjem'
              />
              <LinkListItem
                title='Systemmeldinger'
                path='/prosesslogg'
                icon='lasting'
                prosessNotify={this.state.prosessNotify}
              />
              {!this.state.infoSkalVises && (
                <LinkListItem
                  title='Info'
                  old='true'
                  path='/infosider'
                  icon='info-transparent'
                />
              )}
              {this.state.infoSkalVises && (
                <LinkListItem
                  info
                  title='Les om nyheter og oppdateringer!'
                  old='true'
                  path='/infosider'
                  icon='info-transparent'
                />
              )}
            </LinkList>
          </div>
        </div>
        {/* <br/>
        <div class="well" style={{
              marginLeft: '30px',
              width: '500px',
              marginTop: '10px',
        }}>
            <div>
            <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Oppfølging mine oppgaver:</span>
            <br/>
            <span style={{ fontSize: '14px' }}>Under behandling: 5 &nbsp;&nbsp;&nbsp; Klargjør rapport:&nbsp;2</span>
            
            </div>
              
              
        </div> */}

        <br />
        <span>
          <img className='bilde' src={bilde} alt='Norkart Brannforebygging' />
        </span>
      </div>
    );
  }
}
