import React, { Component } from 'react';
import { debugFactory } from 'utils';
import Tooltip from 'react-tooltip-lite';
import Tree from 'react-animated-tree';
import './tooltip.css';
import Red3 from 'images/48/red3kant.png';
import RedHvit from 'images/48/redhvitsirkle.png';
import Organisasjon from 'images/48/organisasjon.png';
import GreenS from 'images/48/greensirkle.png';
import YellowS from 'images/48/yellowsirkle.png';

const debug = debugFactory('nk:StatusDigitalButton');
//kopier: import StatusDigitalButton from './StatusDigitalButton.jsx';

/* ------------ Digital? ---------------------------------
1. Grønn - Alle er digital (Tut og kjør)
2. Gul En av innbyggerne er ikke digitale
Valg for å ikke sende til SvarUt for disse manuelt?
3. Rød - Ingen er digital
4.Organ - Én av disse er Org. */

class StatusDigitalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      tittel: '',
      tooltip: '',
    };
  }

  componentDidMount = () => {};

  onMouseOver = () => {
    if (this.props.item.extra) {
      this.makeTooltip();
    }
  };

  getInfoString = () => {
    let x = this.props.item.extra.status;
    let tooltip = '';
    switch (x) {
      case 1:
        tooltip = 'Varsles via SMS';
        break;
      case 2:
        tooltip = 'Varsles på ulik måte';
        break;
      case 3:
        tooltip = this.props.svarskriv;
        break;
      case 4:
        tooltip = 'Organisasjon';
        break;
      case 5:
        tooltip = 'Opplysninger mangler';
        break;
      default:
        tooltip = 'Opplysninger mangler';
        break;
    }
    return tooltip;
  };

  getColor = () => {
    let x = this.props.item.extra.status;
    let color = 'Black';
    switch (x) {
      case 1:
        color = 'Green';
        break;
      case 2:
        color = 'Orange';
        break;
      case 3:
        color = 'Red';
        break;
      case 4:
        color = 'Red';
        break;
      case 5:
        color = 'Gray';
        break;
      default:
        color = 'Black';
        break;
    }
    return color;
  };

  makeTooltip = () => {
    let extra = this.props.item.extra;
    let eiere = extra.eier;
    let res = (
      <div>
        <label style={{ color: this.getColor() }}>
          {this.getInfoString()}{' '}
        </label>
        {eiere.map(function(ei) {
          let tel = ei.telefon;
          if (!ei.telefon) {
            tel = 'mangler..';
          }
          let epost = ei.epost;
          if (!ei.epost) {
            epost = 'mangler..';
          }
          let info;
          if (ei.info) {
            info = (
              <div>
                <span style={{ wordWrap: 'break-all', whiteSpace: 'normal', display: 'block', width: '400px', fontSize: '12px', fontWeight: 'normal' }}> 
                  { ei.info }
                </span>
                <br />
              </div>
            );
          }
          return (
            <div>
              <Tree
                style={{ fontSize: '12px', fontWeight: 'bold', width: '430px' }}
                content={ei.navn}
                open={eiere.length < 2}>
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {ei.adresse}
                </span>
                <br />
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {ei.poststed}
                </span>
                <br />
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {'Telefon: ' + tel}
                </span>
                <br />
                <span style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  {'Epost: ' + epost}
                </span>
                <br />
                {info}
              </Tree>
            </div>
          );
        })}
      </div>
    );
    this.setState({ tooltip: res });
  };

  render = () => {
    let img = null;
    let item = this.props.item;
    if (!item) {
      return;
    }
    if (item.extra) {
      switch (item.extra.status) {
        case 1:
          img = GreenS;
          break;
        case 2:
          img = YellowS;
          break;
        case 3:
          img = Red3;
          break;
        case 4:
          img = Organisasjon;
          break;
        case 5:
          img = RedHvit;
          break;
        default:
          img = RedHvit;
          break;
      }

      return (
        <>
          <div onMouseOver={this.onMouseOver}>
            <Tooltip
              zIndex='99000'
              content={this.state.tooltip}
              distance={0}
              direction='down-start'
              className='target'
              tipContentClassName=''
              arrow={false}>
              <span style={{ marginRight: '8px' }}>
                <img
                  height='20'
                  width='20'
                  src={img}
                  alt=''
                  style={{ marginRight: '8px' }}
                />
              </span>
              {/*   <span>{item.eiendom}</span> */}
            </Tooltip>
          </div>
        </>
      );
    } /* else {
      return <span>{item.eiendom}</span>;
    } */
  };
}

export default StatusDigitalButton;
