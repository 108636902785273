import callApi from './api';


var getRoyklopById = function(id, callback) {
  callApi('GET', '/royklop/' + id, null, callback);
};

var getRoyklopForFlyttIldsted = function(bruksenhetsId, royklopId, callback) {
  callApi('GET', '/royklop/bygg/' + bruksenhetsId + '/false/' + royklopId + '/true', null, callback);
};

var getRoyklopForFlyttRoyklop = function(bruksenhetsId,  callback) {
  callApi('GET', '/royklop/bygg/' + bruksenhetsId + '/true', null, callback);
};

var saveRoyklop = function(royklop, callback) {
  callApi('POST', '/royklop/', royklop, callback);
};

var updateRoyklop = function(id, royklop, callback) {
  callApi('PUT', '/royklop/' + id, royklop, callback);
};

var deleteRoyklop = function(id, callback) {
  callApi('DELETE', '/Royklop/' + id, null, callback);
};

var getRoyklopOppslagdataNyEndre = function(callback) {
  callApi('GET', '/royklopoppslagdata/nyendre/', null, callback);
};

var getRoyklopSituasjonTreById = function(id, callback) {
  callApi('GET', '/royklop/situasjon/' + id, null, callback);
};

var getAntallBruksenheterTilknyttetLop = function(id, callback) {
  callApi('GET', '/royklop/antallbruksenhetertilknyttetlop/' + id, null, callback);
};

var fjernTilknytningTilBruksenhet = function(royklopId, bruksenhetId, callback) {
  callApi('POST', '/royklop/fjerntilknytningtilbruksenhet/' + royklopId + '/' + bruksenhetId, null, callback);
};

var risikooverstyrtRoyklop = function(id, callback) {
  callApi('GET', '/royklop/risikooverstyr/' + id, null, callback);
};

var getRoyklopBruksenhetTilknyttet = function(id, callback) {
  callApi('GET', '/royklop/bruksenhettilknyttetlist/' + id, null, callback);
};

// H I S T O R I K K

var getRoyklopObjekthistorikk = function(bruksEnhetId, callback) {
  callApi('GET', '/objektendringer/royklop/' + bruksEnhetId, null, callback);
};

// T I L T A K

var getRoyklopTiltakHistorikk = function(id, callback) {
  callApi('GET', '/roykloptiltak/historikk/' + id, null, callback);
};

var getRoyklopTiltakOppslagsdata = function(callback) {
  callApi('GET', '/royklopoppslagdata/tiltak/', null, callback);
};

var getRoyklopTiltakOppslagsdataForArbOgBr = function(arbListeId, brId, callback) {
  callApi('GET', '/royklopoppslagdata/tiltak/' + arbListeId + '/' + brId, null, callback);
};

var saveTiltakRoyklop = function(tiltak, callback) {
  callApi('POST', '/roykloptiltak/', tiltak, callback);
};

var getRoyklopNesteTiltakTypeById = function(id, callback) {
  callApi('GET', '/RoyklopTiltakType/neste/' + id, null, callback);
};
var getRoyklopTiltakType = function(roykid, callback) {
  callApi('GET', '/RoyklopTiltakType/' + roykid, null, callback);
};
var slettRoyklopTiltakType = function(tiltakid, callback) {
  callApi('DELETE', '/RoyklopTiltakType/' + tiltakid, null, callback);
};
var slettTiltakRoyklop = function(id, callback) {
  callApi('DELETE', '/roykloptiltak/' + id, null, callback);
};
var updateTiltakRoyklop = function(id, tiltak, callback) {
  callApi('PUT', '/roykloptiltak/' + id, tiltak, callback);
};
var getTiltakRoyklop = function(id, callback) {
  callApi('GET', '/roykloptiltak/' + id, null, callback);
};
var nyOppdaterRoyklopTiltakType = function(tiltaktype, callback) {
  callApi('POST', '/RoyklopTiltakType/createorupdate', tiltaktype, callback);
};

// A N N E T

export function getBruksenheteIderForDeltRoyklop(royklopId, bruksenhetId, callback) {
  callApi('GET', `/royklop/bruksenhettilknyttet/${royklopId}/${bruksenhetId}`, null, callback);
}

export {
  getRoyklopById,
  updateRoyklop,
  getRoyklopOppslagdataNyEndre,
  saveRoyklop,
  getRoyklopTiltakHistorikk,
  getRoyklopTiltakOppslagsdata,
  getRoyklopTiltakOppslagsdataForArbOgBr,
  saveTiltakRoyklop,
  getRoyklopSituasjonTreById,
  getRoyklopObjekthistorikk,
  getRoyklopNesteTiltakTypeById,
  slettTiltakRoyklop,
  updateTiltakRoyklop,
  getTiltakRoyklop,
  deleteRoyklop,
  getAntallBruksenheterTilknyttetLop,
  fjernTilknytningTilBruksenhet,
  getRoyklopForFlyttIldsted,
  getRoyklopForFlyttRoyklop,
  nyOppdaterRoyklopTiltakType,
  risikooverstyrtRoyklop,
  getRoyklopTiltakType,
  slettRoyklopTiltakType,
  getRoyklopBruksenhetTilknyttet
};

export default {
  getRoyklopById,
  updateRoyklop,
  getRoyklopOppslagdataNyEndre,
  saveRoyklop,
  getRoyklopTiltakHistorikk,
  getRoyklopTiltakOppslagsdata,
  getRoyklopTiltakOppslagsdataForArbOgBr,
  saveTiltakRoyklop,
  getRoyklopSituasjonTreById,
  getRoyklopObjekthistorikk,
  getRoyklopNesteTiltakTypeById,
  slettTiltakRoyklop,
  updateTiltakRoyklop,
  getTiltakRoyklop,
  deleteRoyklop,
  getAntallBruksenheterTilknyttetLop,
  fjernTilknytningTilBruksenhet,
  getRoyklopForFlyttIldsted,
  getRoyklopForFlyttRoyklop,
  nyOppdaterRoyklopTiltakType,
  risikooverstyrtRoyklop,
  getRoyklopTiltakType,
  slettRoyklopTiltakType,
  getRoyklopBruksenhetTilknyttet
};
