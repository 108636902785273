import React, { useState, useEffect } from 'react';

const Child2 = React.forwardRef(({ verdi, endreVerdi }, ref) => {

  const [inputString, setInputString] = useState('');



  useEffect(() => {
    //litt som componentDidMount


  });

  const handleInputChange = (e) => {
    setInputString(e.target.value);
  };


  return (
    <div style={{ margin: '4px', padding: '10px', border: '2px solid orange', width: '250px' }}>
      <h3>Child2</h3>
      <label>{verdi}</label>
      <br/>
      <button onClick={() => endreVerdi('Fra child2 OPQ')}>Endre verdi til OPQ</button>   
      <br/><br/><br/>
      <input type="text" id="iString" onChange={handleInputChange} value={ inputString }></input>
      <br/><br/>
        
      
    </div>
  );
});

export default Child2;