import React, { useState, useEffect, createContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import NkaFormElementTextInput from 'nka-form-element-text-input';
import 'nka-form-element-text-input/dist/style.css';
import './dialogs.css';


const PromptDialog = ({
  tittel,
  tekst,

  isOpen,
  handleClose
}) => {

  const [ value, setValue ] = useState('');




  return (
    <Dialog
      open={isOpen}
/*       sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        sx: {
          m: 10,
          top: 100,
          left: 200,
        },
      }} */
      onClose={handleClose}>
      <DialogTitle>
        <span style={{ fontSize: '16px', paddingTop: '24px' }}>{tittel}</span>

      </DialogTitle>
      <DialogContent style={{ width: '400px' }}>
        <DialogContentText style={{ fontSize: '16px' }}>
          {tekst}
          <br /> 
          <br /> 
          <NkaFormElementTextInput
                    handleValueChange={(e) => setValue(e.target.value)}
                    value={value}
                    type={'text'}
                    placeholder={'Skriv..'}
                    other={{
                        tabIndex: 1
                        }}
                />
        </DialogContentText>
      </DialogContent>
      
      <DialogActions style={{ padding: '0px' }}>  {/* //style={{ padding: '0px', borderTop: '1px solid #51a026' }} */}
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose(value)}
          buttontype='primary'
          autoFocus>
          <span>OK</span>
        </NkaButton>
        <NkaButton
          /* style={{ height: '38px' }} */
          onClick={() => handleClose(null)}
          buttontype='default'>
          Avbryt
        </NkaButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;

